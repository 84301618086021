import { OverlayTrigger, Popover } from 'react-bootstrap';
import { EmojiPicker } from 'app/components/EmojiIconBar/EmojiWrapper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const DisplayEmojiPicker = (props) => {
  const { showEmoji, setShowEmoji, handleEmojiSelect, isClassComponent } =
    props;

  const handleToggle = (isVisible) => {
    if (isClassComponent) {
      setShowEmoji();
    } else {
      if (isVisible !== undefined) {
        setShowEmoji(() => isVisible);
      } else {
        setShowEmoji((show) => !show);
      }
    }
  };

  return (
    <>
      <div
        className={'emoji-picker-btn-container'}
        onClick={() => handleToggle()}>
        <OverlayTrigger
          show={showEmoji}
          placement='auto'
          delay='250'
          overlay={
            <Popover
              id='emoji-popover'
              onClick={(event) => event.stopPropagation()}>
              <Popover.Body>
                <EmojiPicker
                  set='native'
                  previewPosition='auto'
                  onEmojiSelect={handleEmojiSelect}
                />
              </Popover.Body>
            </Popover>
          }>
          <button type={'button'}>
            <OrthoIcon name={'add-emoji'} dataHoverNotRequired={true} />
          </button>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default DisplayEmojiPicker;
