import React, { PureComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SharedCareSpacesView from 'app/components/CareSpace/SharedCareSpacesView';

class MultipleCareSpacesRoutes extends PureComponent {
  render() {
    return (
      <Routes>
        <Route path='/' exact element={<SharedCareSpacesView />} />
        <Route path='*' element={<Navigate to='/care' />} />
      </Routes>
    );
  }
}
export default MultipleCareSpacesRoutes;
