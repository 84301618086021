import Loader from 'react-loaders';
import useFetchPrescriptions from 'app/hooks/easyRX/useFetchPrescriptions';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const StatusList = ({ status }) => {
  const { loading, prescriptionsList } = useFetchPrescriptions({ status });

  const renderRow = (obj, index) => {
    return (
      <div className='visitors-table-row table-row-only' onClick={() => {}}>
        <div className='v-tb-block counter'>{index + 1}</div>
        <div className='v-tb-block cd-information'>{obj.name}</div>
        <div className='v-tb-block cd-information'>{obj.labName}</div>
        <div className='v-tb-block cd-information'>{obj.createdAt || '--'}</div>
        <div className='v-tb-block cd-information'>
          {obj.submittedAt || '--'}
        </div>
        <div className='v-tb-block cd-information'>
          <a href={obj.link} target='_blank' rel='noreferrer'>
            <OrthoIcon name='additionallink' />
          </a>
        </div>
      </div>
    );
  };

  const renderList = () => {
    return prescriptionsList.map(renderRow);
  };

  const renderHeaderRow = () => {
    return (
      <div className='visitors-table-row header-row'>
        <div className='v-tb-block counter-40'></div>
        <div className='v-tb-block cd-information'>Name</div>
        <div className='v-tb-block cd-information'>Laboratory Name</div>
        <div className='v-tb-block cd-information'>Created At</div>
        <div className='v-tb-block cd-information'>Submitted At</div>
        <div className='v-tb-block cd-information'>Link</div>
      </div>
    );
  };

  if (loading) return <Loader type='ball-triangle-path' active />;
  if (prescriptionsList.length === 0)
    return (
      <div className='text-center no-records-row'>
        There are no records present under this category
      </div>
    );
  return (
    <>
      {renderHeaderRow()}
      {renderList()}
    </>
  );
};

export default StatusList;
