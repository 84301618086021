import React, { PureComponent } from 'react';
import NavItem from 'app/components/NavLinks/NavItem';

import { CARE_NAV_LINKS_MAPPER } from './constants';

class CareSpaceNavList extends PureComponent {
  renderLink = (name) => {
    const { iconName, title, path } = CARE_NAV_LINKS_MAPPER[name];
    return (
      <NavItem
        key={name}
        link={name}
        title={title}
        url={path}
        iconName={iconName}
      />
    );
  };

  render() {
    return (
      <ul className='navbar-nav site-nav'>
        {this.props.list.map(this.renderLink)}
      </ul>
    );
  }
}

export default CareSpaceNavList;
