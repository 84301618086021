import PayWallView from 'app/components/NonSubscribedView/PayWallView';
import { openPartnerSpaceInviteModal } from 'app/actions/partnerSpaces';
import { useDispatch } from 'react-redux';
import OsBtn from 'app/components/OsBtn';

const PartnerSpace = () => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(openPartnerSpaceInviteModal());
  };
  const renderPrimaryCTA = () => {
    return (
      <OsBtn
        type={'button'}
        htmlTag={'button'}
        extraClass={'web-view-btn btn-save-info'}
        name={'BtnPrimary'}
        text={'Invite Partner Clinic'}
        onClick={onClick}
      />
    );
  };
  return (
    <PayWallView
      source='partnersDetail'
      forcePaywall
      extraClass={'col-os-12 position-unset pr-32'}
      renderPrimaryCTA={renderPrimaryCTA}
    />
  );
};

export default PartnerSpace;
