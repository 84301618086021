import { hexToRgb } from 'app/utils/colorHelper';
import { getAllLabelsThatHaveSubLabels } from 'app/utils/taskHelper';

import React from 'react';

function RenderSubLabels({ labels, handleLabelClick }) {
  return (
    <>
      {getAllLabelsThatHaveSubLabels(labels).map((label) => {
        return label.sub_labels.map((subLabel, index) => {
          return (
            <div>
              <div
                key={index}
                className='label'
                style={{
                  backgroundColor: `${hexToRgb(label.color)}`,
                }}
                onClick={() => {
                  handleLabelClick({
                    ...subLabel,
                    color: label.color,
                  });
                }}>
                <span className='labelText'>{subLabel.displayName}</span>
              </div>
            </div>
          );
        });
      })}
    </>
  );
}

export default RenderSubLabels;
