import { useLazyQuery } from '@apollo/client';
import { getKeyValueFromLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { loader as queryLoader } from 'graphql.macro';
import { isEmpty, sortBy, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const ALL_SCHEDULE_POST = queryLoader(
  'app/graphql/queries/Comments/ScheduledPosts.gql',
);

function useScheduledPosts({ widgetSourceObject }) {
  const navigate = useNavigate();
  const [getAllScheduledPosts, { data }] = useLazyQuery(ALL_SCHEDULE_POST);
  const [postsRefUpdated, setpostsRefUpdated] = useState(true);
  const postsRef = useRef([]);
  const pageDetailsRef = useRef({
    afterId: null,
    beforeId: null,
    afterHasMore: false,
    beforeHasMore: false,
    totalRecords: 0,
  });

  const deleteScheduleMessage = (postId) => {
    let postsCopy = [...postsRef.current];
    postsRef.current = postsCopy.filter((post) => post.id !== postId);
    if (postsRef.current.length === 0) {
      navigate(
        withWorkspaceIdentifier(
          `/spaces/${widgetSourceObject.nice_id}/discussions`,
        ),
      );
    }
  };

  const updateScheduledMessage = (post) => {
    postsRef.current = postsRef.current.map((p) =>
      p.id === post.id ? post : p,
    );
  };

  let previousPosition = 0;
  const fetchMoreResult = () => {
    const position = window.scrollY;
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isScrollingUp = position < previousPosition;
    previousPosition = position;
    const scrolledToBottom = scrollTop + windowHeight >= documentHeight - 1;
    const scrolledToTop = scrollTop >= Math.floor(windowHeight / 2);

    if (
      (scrolledToBottom && pageDetailsRef.current.afterHasMore == true) ||
      (isScrollingUp &&
        scrolledToTop &&
        pageDetailsRef.current.beforeHasMore == true)
    ) {
      getAllPosts(isScrollingUp);
    }
  };

  const getAllPosts = (isScrollingUp = false) => {
    getAllScheduledPosts({
      variables: {
        beforeId: isScrollingUp ? pageDetailsRef.current.beforeId : null,
        aroundId: getKeyValueFromLocalStorage('messageId'),
        afterId: !isScrollingUp ? pageDetailsRef.current.afterId : null,
        limit: 10,
        recordId: widgetSourceObject.id,
        recordType: 'Board',
        type: 'ScheduleMessagesListing',
      },
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
    if (!isEmpty(widgetSourceObject)) {
      getAllPosts(widgetSourceObject.nice_id);
    }
  }, [widgetSourceObject.nice_id]);

  useEffect(() => {
    if (!isEmpty(data)) {
      let uniqPosts = uniqBy(
        [...postsRef.current, ...data.records_pager.results],
        (obj) => `${obj.__typename}:${obj.id}`,
      );

      // postsRef.current = sortBy(uniqPosts, 'time');
      postsRef.current = uniqPosts;
      if (data.records_pager.total >= uniqPosts.length) {
        let beforeId = pageDetailsRef.current.beforeId;
        let afterId = pageDetailsRef.current.afterId;
        if (data.records_pager.before_has_more) {
          beforeId = data.records_pager.results[0].id;
        }
        if (data.records_pager.after_has_more) {
          afterId =
            data.records_pager.results[data.records_pager.results.length - 1]
              .id;
        }
        pageDetailsRef.current = {
          beforeId: beforeId,
          afterId: afterId,
          beforeHasMore: data.records_pager.before_has_more,
          afterHasMore: data.records_pager.after_has_more,
          totalRecords: data.records_pager.total,
        };
      }
      setpostsRefUpdated(!postsRefUpdated);
    }
  }, [data?.records_pager?.results.length]);

  useEffect(() => {
    window.addEventListener('scroll', fetchMoreResult);
    window.addEventListener('refetchScheduleList', getAllPosts);

    return () => {
      window.removeEventListener('scroll', fetchMoreResult);
      window.removeEventListener('refetchScheduleList', getAllPosts);
    };
  }, []);

  useEffect(() => {}, []);

  return {
    postsRef,
    postsRefUpdated,
    deleteScheduleMessage,
    updateScheduledMessage,
  };
}

export default useScheduledPosts;
