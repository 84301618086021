import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import OsField from "app/components/OsField/index";

const OsFileUploadField = (props) => {
  const [file, setFile] = useState(() => null);
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
    if (props.onChange) {
      props.onChange(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const getSourceObjectURL = () => {
    if (props.src && !file) {
      return props.src;
    } else if (file) {
      return URL.createObjectURL(file);
    } else return null;
  };

  return (
    <>
      <OsField {...props} osType="label" isWrapperRequired={false} />
      <div className={"banner-field"}>
        {props.previewRequired && (
          <div className={"banner-preview-box"}>
            {
              <img
                className={"banner-preview"}
                src={getSourceObjectURL()}
                alt={"banner"}
              />
            }
          </div>
        )}
        <div className={"banner-dropzone-box"} {...getRootProps()}>
          <input {...getInputProps()} />
          {props.description}
        </div>
      </div>
    </>
  );
};

export default OsFileUploadField;
