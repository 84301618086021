import React, { Component } from 'react';
import { connect } from 'react-redux';

import withAuthorize from 'app/components/HOC/withAuthorize';

import EventTracker from 'app/services/EventTracker';
import OsLink from 'app/components/OsLink';
import { setNavSearchOpen } from 'app/actions/navSearch';
import { getAuthorizeObject } from 'app/utils/entitiesHelper';

class NavSearchBox extends Component {
  openSearchModal = () => {
    EventTracker.track('Navigate - Search Box Clicked');
    this.props.openUpgradeModalIfUnauthorized(this.props.setNavSearchOpen);
  };

  render() {
    return (
      <li
        className='nav-search-block hidden-mobile'
        onClick={this.openSearchModal}>
        <OsLink title='Search' iconName='search' />
      </li>
    );
  }
}

NavSearchBox = connect(({}) => ({}), { setNavSearchOpen })(NavSearchBox);
NavSearchBox = withAuthorize(NavSearchBox);
NavSearchBox.defaultProps = {
  abilityObject: getAuthorizeObject('search', 'all'),
};
export default NavSearchBox;
