import React from 'react';

import Checkbox from './checkbox';
import Clapping from './clapping';
import Exclamation from './exclamation';
import Exploding from './exploding';
import Fire from './fire';
import Laughing from './laughing';
import LightBulb from './lightbulb';
import Love from './love';
import Ok from './ok';
import Percent from './percent';
import Progress from './progress';
import Question from './question';
import RaisingHands from './raisinghands';
import Refuse from './refuse';
import Sad from './sad';
import Scared from './scared';
import Smiling from './smiling';
import Tada from './tada';
import Thinking from './thinking';
import ThumbsDown from './thumbsdown';
import ThumbsUp from './thumbsup';

export const EMOJI_IDENTIFIER_COMPONENT_HASH = {
  thumbs_up: ThumbsUp,
  thumbs_down: ThumbsDown,
  clapping: Clapping,
  heart: Love,
  fire: Fire,
  smiling: Smiling,
  sad: Sad,
  laughing: Laughing,
  thinking: Thinking,
  exploding: Exploding,
  scared: Scared,
  ok: Ok,
  raising_hands: RaisingHands,
  tada: Tada,
  bulb: LightBulb,
  cross: Refuse,
  checkbox: Checkbox,
  progress: Progress,
  exclamation: Exclamation,
  question: Question,
  hundred_percent: Percent,
};
const Emoji = (props) => {
  const Element = EMOJI_IDENTIFIER_COMPONENT_HASH[props.name];
  return Element ? <Element {...props} /> : null;
};

export default Emoji;
