import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import OsBtn from 'app/components/OsBtn';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { translate } from 'app/actions/flashMessage';
import { lowerCase } from 'app/utils/osLodash';

import { ENTITY_NAME_MAPPER } from 'app/constants';
const MODAL_INITIATOR_FIELD_PLACEHOLDER = {
  feed: 'Add a new post',
};

class ExplorePostInitiator extends Component {
  getPlaceholderText() {
    const source =
      ENTITY_NAME_MAPPER[lowerCase(this.props.source)] ||
      lowerCase(this.props.source);
    return (
      MODAL_INITIATOR_FIELD_PLACEHOLDER[this.props.source] ||
      translate('NEW_COMMENT_MESSAGE', { source })
    );
  }
  renderAvatar() {
    return (
      <div className='post-avatar'>
        <Avatar
          {...avatarAttributes(this.props.currentUser.graph)}
          className='avatar avatar-24'
        />
      </div>
    );
  }

  render() {
    if (this.props.source === 'feed') {
      return (
        <OsBtn
          name='BtnIcon'
          icon='edit'
          extraClass='no-text add-post-btn'
          onClick={this.props.onClick}
        />
      );
    } else {
      return (
        <div className='col-12 post-mobile-top'>
          <div className='create-pulse' onClick={this.props.onClick}>
            {this.renderAvatar()}
            <span className='placeholder'>{this.getPlaceholderText()}</span>
          </div>
        </div>
      );
    }
  }
}

ExplorePostInitiator.propTypes = {
  onClick: PropTypes.func.isRequired,
};

ExplorePostInitiator.defaultProps = {
  onClick: () => {},
};

ExplorePostInitiator = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(ExplorePostInitiator);
export default ExplorePostInitiator;
