import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import { setNavSearchClose } from 'app/actions/navSearch';
import { isUrlOfSameDomain } from 'app/utils/urlHelper';

class SearchResult extends Component {
  onClick = () => {
    if (this.props.trackOnClick)
      this.props.trackOnClick(this.props.onClickParams);

    if (this.props.onClick) {
      this.props.onClick(this.props.onClickParams);
    } else if (!isUrlOfSameDomain(this.props.to)) {
      window.location.href = this.props.to;
    } else {
      this.props.navigate(this.props.to);
      document.activeElement.blur();
      this.props.setNavSearchClose();
    }
  };

  render() {
    return (
      <div
        className='search-result'
        key={this.props.key}
        onClick={this.onClick}
        onMouseDown={(event) => {
          event.preventDefault();
        }}>
        {this.props.children}
      </div>
    );
  }
}
SearchResult = withRouter(SearchResult);
SearchResult = connect(null, { setNavSearchClose })(SearchResult);
export default SearchResult;
