import ActionCable from 'app/actioncable/actioncable';
import { isUndefined, keys, pick } from 'app/utils/osLodash';
import { logout, updateCurrentUserGraph } from 'app/actions/authentication';
import { mergeWorkspaceData } from 'app/actions/workspace';
import { mergeAttributesInActiveConversation } from 'app/actions/activeConversation';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

import client from 'app/graphql/client';
import store from 'app/store';

import StoreUpdater from 'app/services/StoreUpdater';
import { sidebarCareNotificationAvailable } from 'app/actions/allSidebarCareNotifications';

const NOTIFICATION_ATTRIBUTES = [
  'unread_conversations_count',
  'unread_notifications_count',
  'total_unread_notifications_count',
  'unread_care_notifications_count',
  'unread_task_mentions_count',
  'unread_task_notifications_count',
  'unread_team_spaces_notifications_count',
];

class NotificationChannel {
  setup(entity = {}, callbacks = {}) {
    if (!isUndefined(ActionCable.notifications)) return;
    const identifier = getWorkspaceData().identifier;
    ActionCable.notifications = ActionCable.consumer.subscriptions.create(
      { channel: 'NotificationChannel', identifier },
      {
        initialized() {},
        connected() {},
        disconnected() {},
        received(data) {
          if (data['type'] === 'comment_viewed') {
            StoreUpdater.addViewedByInComment(data.entity);
          } else if (data['type'] === 'viewed_content') {
            this.updateSpaceLink(data);
          } else if (data.type === 'read') {
            this.markNotificationAsRead(data);
            this.notificationPushHandler(data);
          } else if (data.type === 'add') {
            if (
              !data.notification.source_id ||
              data.notification.source_id === +getWorkspaceData().id
            )
              this.addNotification(data.notification);
            this.notificationPushHandler(data);
          } else if (data.type === 'read_all') {
            this.markAllNotificationsAsRead();
            this.notificationPushHandler(data);
          } else if (data.type === 'update_unread_message_count') {
            if (data.seen_conversation_id) {
              StoreUpdater.markConversationAsRead(data.seen_conversation_id);
              this.mergeAttributesInActiveConversation({
                last_seen_at: +data.last_seen_at,
              });
              let entity = {
                __typename: 'Conversation',
                id: data.seen_conversation_id,
                last_seen_at: +data.last_seen_at,
              };
              StoreUpdater.updateEntityThroughWrapper(
                { entity: entity, attributeName: 'last_seen_at' },
                { customAction: 'assign' },
              );
            }
            this.notificationPushHandler(data);
          } else if (data.type === 'update_unread_notifications_count') {
            this.notificationPushHandler(data);
          } else if (data.type === 'refresh_browser') {
            window.location.reload();
          } else if (data.type === 'logout_user') {
            store.dispatch(logout());
          } else if (data.type === 'progress_update') {
            this.updateSpaceProgress(data);
          } else if (data.type === 'terminate_device_session') {
            // JSON.parse(data.notification).device_token
            // TODO: Logic needs to be defined as per above device token
          } else if (data.type === 'refetch_care_sidebar') {
            store.dispatch(sidebarCareNotificationAvailable(true));
          } else if (data.type === 'update_unread_task_count') {
            this.notificationPushHandler(data);
          } else if (data.type === 'update_unread_task_mentions_count') {
            this.notificationPushHandler(data);
          }
        },
        read(notification_id) {
          this.perform('read', { notification: notification_id });
        },
        read_all() {
          this.perform('read_all');
        },
        viewComment(comment_id) {
          this.perform('view_comment', { comment_id });
        },
        viewTask(task_id) {
          this.perform('view_task', { task_id });
        },

        //Additional Custom Event handlers
        addNotification(notification) {
          StoreUpdater.addNotificationInRecords(notification);
        },
        fetchNotificationIdsFromStore() {
          let storeData = client.cache.data.data;
          return keys(storeData)
            .filter(
              (key) => key.includes('Notification:') && !key.includes('.'),
            )
            .map((key) => key.split(':')[1]);
        },
        markAllNotificationsAsRead() {
          let notificationsIds = this.fetchNotificationIdsFromStore();
          notificationsIds.forEach((id) => {
            let notification = { id, __typename: 'Notification' };
            this.markNotificationAsRead({ notification });
          });
        },
        notificationPushHandler(data) {
          let workspace = store.getState().workspace.data;
          if (data.accessible_workspaces) {
            const fetchedWorkspace = data.accessible_workspaces.find(
              (w) => +workspace.id === +w.id,
            );
            if(fetchedWorkspace) {
              let entity = {
                __typename: 'Board',
                id: fetchedWorkspace.id,
                ...pick(fetchedWorkspace, NOTIFICATION_ATTRIBUTES),
              };
              store.dispatch(mergeWorkspaceData(entity));
              StoreUpdater.updateEntityThroughWrapper(
                { entity: entity, attributeNames: NOTIFICATION_ATTRIBUTES },
                { customAction: 'assign' },
              );
            }
            this.updateCurrentUserInStore({
              unread_notifications_count: data.unread_count,
            });
          }
        },
        markNotificationAsRead(data) {
          StoreUpdater.updateEntity(
            {
              entity: { ...data.notification, unread: false },
              attributeName: 'unread',
            },
            { customAction: 'assign' },
          );
        },
        mergeAttributesInActiveConversation(attributes) {
          store.dispatch(mergeAttributesInActiveConversation(attributes));
        },
        updateCurrentUserInStore(data) {
          store.dispatch(updateCurrentUserGraph(data));
        },
        updateSpaceLink(data) {
          StoreUpdater.updateEntity(
            { entity: data['entity'], attributeName: 'viewed_space_content' },
            { customAction: 'assign' },
          );
        },
        updateSpaceProgress(data) {
          StoreUpdater.updateEntityThroughWrapper(
            { entity: data['entity'], attributeName: 'can_claim_credits' },
            { customAction: 'assign' },
          );
          StoreUpdater.updateEntityThroughWrapper(
            {
              entity: data['entity'].active_user_subscription,
              attributeName: 'progress',
            },
            { customAction: 'assign' },
          );
          StoreUpdater.updateEntityThroughWrapper(
            {
              entity: data['entity'].active_user_subscription,
              attributeName: 'completed',
            },
            { customAction: 'assign' },
          );
        },
      },
    );
  }

  delete() {
    if (ActionCable.notifications) {
      ActionCable.consumer.subscriptions.remove(ActionCable.notifications);
      delete ActionCable.notifications;
    }
  }
}

export default new NotificationChannel();
