import React, { PureComponent } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import client from 'app/graphql/client';

import OsBtn from 'app/components/OsBtn';

import {
  closeInfoModal,
  openInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';
import { openPatientModal } from 'app/actions/patientModal';
import { setActiveResourceAfterSharing } from 'app/actions/activeResource';
import { translate } from 'app/actions/flashMessage';

import {
  isPersonActuallyAPatient,
  isSharedWithParty,
  isUserAuthenticated,
} from 'app/utils/spaces/careHelper';
import { timeWithFormat } from 'app/utils/timeHelper';

const SHARE_WITH_GUARDIAN_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithGuardian.gql',
);
const SHARE_WITH_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithPatient.gql',
);
const PATIENT_LINK = queryLoader(
  'app/graphql/Care/Queries/People/PatientLink.gql',
);

class ContactPartyShareButton extends PureComponent {
  state = {
    requestInProgress: false,
  };

  handleShare = () => {
    const isPatient = isPersonActuallyAPatient(
        this.props.space,
        this.contactParty(),
      ),
      handler = isPatient ? 'shareWithPatient' : 'shareWithGuardian';
    this.setState({ requestInProgress: true }, () => {
      this.props.updateInfoModalOptions({
        primaryCtaRequestInProgress: this.state.requestInProgress,
      });
    });
    this[handler]().then(({ data }) => {
      this.setState({ requestInProgress: false });
      this.props.closeInfoModal();
      if (data[handler].success) {
        this.props.openInfoModal(
          'care_space',
          'shared_with_patient_successfully',
        );
        this.props.setActiveResourceAfterSharing(data.shareWithGuardian.entity);
      } else {
        let error =
          data[handler].error === 'already_verified'
            ? translate('PATIENT_ALREADY_VERIFIED', {
                name: this.contactParty().name,
              })
            : translate('CREATE_ERROR');
        this.props.openInfoModal('care_space', 'patient_share_error', {
          contentInterpolations: { error },
        });
      }
    });
  };
  shareWithGuardian = () => {
    return this.props.shareWithGuardian({
      variables: {
        careSpaceId: this.props.space?.nice_id,
        personConnectionId: +this.contactParty().person_connection_id,
        reshare: false || this.reshareRequired(),
      },
    });
  };

  shareWithPatient = () => {
    return this.props.shareWithPatient({
      variables: {
        careSpaceId: this.props.space.nice_id,
        reshare: false || this.reshareRequired(),
      },
    });
  };

  contactParty() {
    return this.props.contactParty;
  }

  openConfirmationModal = () => {
    this.props.openInfoModal('care_space', 'shared_with_patient_confirmation', {
      onSuccess: this.handleShare,
      closeModalNotRequiredOnPrimaryClick: true,
    });
  };

  renderModal(data) {
    let contactParty = this.contactParty();
    this.props.openInfoModal(
      'care_space',
      'reshare_with_patient_confirmation',
      {
        onSuccess: this.handleShare,
        closeModalNotRequiredOnPrimaryClick: true,
        headingInterpolations: { name: contactParty.name },
        contentInterpolations: {
          date: timeWithFormat(
            contactParty.last_shared_on_date,
            'dddd, MMMM Do',
          ),
        },
        patientLink: data?.board.patient_url,
        loader: true,
      },
    );
  }

  openReshareConfirmationModal = () => {
    let person = this.props.contactParty,
      space = this.props.space;
    this.renderModal();
    client
      .query({
        query: PATIENT_LINK,
        fetchPolicy: 'network-only',
        variables: {
          id: space.id,
          personId: person.id,
        },
      })
      .then(({ data }) => {
        this.renderModal(data);
      });
  };

  getProps() {
    if (this.props.as === 'primary') {
      return {
        name: 'BtnPrimary',
        extraClass: 'share-wth-patient-btn',
      };
    } else {
      return {
        name: 'BtnIcon',
        icon: 'share',
      };
    }
  }

  isSharedWithParty() {
    return isSharedWithParty(
      this.props.space,
      {
        id: this.contactParty().user_id,
      },
      this.contactParty(),
    );
  }

  isPartyLoggedIn() {
    return isUserAuthenticated(this.props.space, {
      id: this.contactParty().user_id,
    });
  }

  reshareRequired() {
    return this.isSharedWithParty() && !this.isPartyLoggedIn();
  }

  render() {
    if (this.isSharedWithParty()) {
      return this.isPartyLoggedIn() ? null : (
        <OsBtn
          {...this.getProps()}
          text='Reshare Patient Space'
          onClick={this.openReshareConfirmationModal}
        />
      );
    } else {
      return (
        <OsBtn
          {...this.getProps()}
          text='Share Patient Space'
          onClick={this.openConfirmationModal}
        />
      );
    }
  }
}

ContactPartyShareButton = connect(null, {
  closeInfoModal,
  openInfoModal,
  openPatientModal,
  updateInfoModalOptions,
  setActiveResourceAfterSharing,
})(ContactPartyShareButton);

ContactPartyShareButton = graphql(SHARE_WITH_GUARDIAN_MUTATION, {
  name: 'shareWithGuardian',
})(ContactPartyShareButton);
ContactPartyShareButton = graphql(SHARE_WITH_PATIENT_MUTATION, {
  name: 'shareWithPatient',
})(ContactPartyShareButton);

export default ContactPartyShareButton;
