import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loader as queryLoader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractDropdown from './AbstractDropdown';
import AddTo from 'app/components/Shared/AddTo';
import ArchiveSpace from 'app/components/Shared/ArchiveSpace';
import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import Follow from 'app/components/Shared/Follow';
import OsBtn from 'app/components/OsBtn/';
import Share from 'app/components/Shared/Share';
import SpaceShareLinks from 'app/components/SpaceShareLinks';
import { translate } from 'app/actions/flashMessage';
import ConnectToSupport from 'app/components/ConnectToSupport';

import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

import withAuthenticate from 'app/components/HOC/withAuthenticate';
import { openEditImageModal } from 'app/actions/editImage';
import { openPatientModal } from 'app/actions/patientModal';
import { openInfoModal } from 'app/actions/infoModal';
import { isFeaturePermitted, isGuestUser } from 'app/utils/userHelper';
import {
  isAddToSpaceRequired,
  isArchiveSpaceRequired,
  isDeleteRequired,
  isEditImageRequired,
  isEditInformationRequired,
  isEditSpaceRequired,
  isNewSubspaceRequired,
  isShareLinkRequired,
  isShareRequired,
} from 'app/utils/featureHelper';
import {
  entityEditUrl,
  entityUrl,
  getAuthorizeObject,
  relativeEntityUrl,
} from 'app/utils/entitiesHelper';
import { isCareSpace, isCompanySpace } from 'app/utils/spaceHelper';
import { pick } from 'app/utils/osLodash';
import CopyToClipboard from 'react-copy-to-clipboard';

const SPACE_SHARE_LINK_FEATURE_IDENTIFIER = 'space_share_link_identifier';
const TOGGLE_COURSE_PURCHASE_MUTATION = queryLoader(
  'app/graphql/mutations/ToggleCoursePurchase.gql',
);

class SpaceDropdown extends AbstractDropdown {
  state = {};

  getSpace() {
    return this.props.space;
  }

  isOwnedByUser() {
    return (
      this.props.currentUser.graph &&
      +this.getSpace().user.id === +this.props.currentUser.graph.id
    );
  }

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  renderEditImageButton() {
    let extraClass = 'list-button ';
    extraClass += this.props.device.mobileDevice
      ? ' '
      : 'web-view-btn back-nav-btn ';
    return (
      <OsBtn
        name='BtnIcon'
        icon='image'
        text='Edit Image'
        extraClass={extraClass}
        onClick={this.props.openEditImageModal.bind(this, {
          space: this.getSpace(),
        })}
      />
    );
  }

  renderShareButton() {
    return (
      <Share
        type='Board'
        object={this.getSpace()}
        label={'Share'}
        extraClass='list-button'
        inDropdown={true}
      />
    );
  }

  renderAddToSpace() {
    return (
      <AddTo
        key='add_to_space'
        obj={this.getSpace()}
        extraClass='list-button'
      />
    );
  }

  getExtraClassForFollowButton() {
    let mobile = this.props.device.mobileDevice,
      extraClass = mobile ? 'd-block' : '';
    if (mobile) {
      extraClass += ' list-button ';
    }
    if (!mobile) {
      extraClass += ' v-align-middle';
    }

    return extraClass;
  }

  renderFollowButton() {
    let extraClass = this.getExtraClassForFollowButton();

    return (
      <Follow
        propagateClick={true}
        muteRequired={true}
        isNotFromDropdown={false}
        key={this.getSpace().followed_by_current_user ? 'unfollow' : 'follow'}
        extraClass={extraClass}
        type='board'
        obj={this.getSpace()}
        name='BtnIcon'
        labelRequired={true}
      />
    );
  }

  onNewSubspaceClick = () => {
    this.props.navigate(
      `${entityUrl(this.getSpace(), false, {
        skipDomainCheck: true,
      })}/subspaces/new`,
    );
  };

  renderNewSubspaceButton(space) {
    return (
      <AuthorizeComponent
        abilityObject={getAuthorizeObject('create', 'board')}
        onClick={this.onNewSubspaceClick}>
        <OsBtn
          text='Create Subspace'
          name='BtnIcon'
          icon='add-section'
          extraClass='list-button'
          associatedEntity={space}
        />
      </AuthorizeComponent>
    );
  }

  renderEdit() {
    return (
      <div key='edit_space'>
        <AuthorizeComponent
          abilityObject={getAuthorizeObject('update', 'board', {
            canEdit: this.isAuthorOrEditor(),
          })}
          onClick={this.onEditClick}>
          <OsBtn
            name='BtnIcon'
            extraClass='list-button'
            icon='edit'
            text='Edit'
            associatedEntity={this.getSpace()}>
            <Link
              to={entityEditUrl(
                pick(this.getSpace(), ['__typename', 'nice_url', 'nice_id']),
              )}
              className='btn-link-helper'></Link>
          </OsBtn>
        </AuthorizeComponent>
      </div>
    );
  }

  renderLeavingAction() {
    let space = this.getSpace();
    if (
      !this.isOwnedByUser() &&
      !(isCareSpace(space) && isCareWorkspaceView()) &&
      space.is_author_or_collaborator &&
      space.member_request_status === 'accepted'
    )
      return this.renderLeaveButton();
  }

  renderLeaveButton() {
    return (
      <DeclineRequest
        btnType='BtnIcon'
        confirmationRequired={true}
        icon={'logout'}
        extraClass='list-button'
        text='Leave'
        space={this.getSpace()}
        requestingUser={this.props.currentUser.graph}
        checkAccess={true}
      />
    );
  }

  openShareLinkModal = () => {
    this.setState({ shareLinkModalOpen: true });
  };

  closeShareLinkModal = () => {
    this.setState({ shareLinkModalOpen: false });
  };

  renderNewShareLink() {
    let space = this.getSpace();
    return (
      <OsBtn
        name='BtnIcon'
        text='Share a link'
        icon='link'
        extraClass='list-button'
        associatedEntity={space}
        onClick={this.openShareLinkModal}
      />
    );
  }

  patientModalSource() {
    return 'care-space-detail';
  }

  openPatientModal = () => {
    this.props.openPatientModal(this.getCareSpace().nice_id, {
      source: this.patientModalSource(),
    });
  };

  renderEditPatientButton() {
    if (this.getCareSpace()?.is_author_or_editor)
      return (
        <OsBtn
          name='BtnIcon'
          text='Edit information'
          icon='edit'
          extraClass='list-button'
          onClick={this.openPatientModal}
        />
      );
  }

  getCareSpace() {
    let { space } = this.props;
    return isCareSpace(space) ? space : null;
  }

  shareLinkRequired() {
    return (
      isFeaturePermitted(SPACE_SHARE_LINK_FEATURE_IDENTIFIER) &&
      this.isAuthorOrEditor() &&
      !isCompanySpace(this.getSpace())
    );
  }

  togglePurchase = () => {
    this.props.toggleCoursePurchase({
      variables: { id: this.getSpace().nice_id },
    });
  };

  renderPurchaseCourseToggle() {
    let space = this.getSpace(),
      text = space.course_purchase_allowed
        ? 'Disable Purchase'
        : 'Enable Purchase';
    return (
      <OsBtn
        name='BtnIcon'
        text={text}
        icon='edit'
        extraClass='list-button'
        associatedEntity={space}
        onClick={this.togglePurchase}
      />
    );
  }

  renderCopyLinkButton() {
    return (
      <CopyToClipboard text={relativeEntityUrl(this.props.space, true)}>
        <OsBtn
          key='copyLink'
          name='BtnIcon'
          text='Copy Link'
          extraClass='list-button'
          icon='document-empty'
        />
      </CopyToClipboard>
    );
  }

  renderCustomDropdown() {
    const space = this.props.space,
      user = this.props.currentUser.graph,
      { mobileDevice } = this.props.device;

    if (isGuestUser()) {
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='no-text v-align-top card-btn-more '
          onClick={this.props.redirectToDefault}
          icon='more'
        />
      );
    }

    return (
      <>
        <CustomDropdown
          osbtnClass={'px-8 '}
          className={`v-align-middle d-inline-block ms-2  position-relative ${this.props.extraClass}`}
          name='board_actions'
          obj={space}>
          {isShareRequired(space) && this.renderShareButton()}
          {!mobileDevice &&
            isEditImageRequired(space) &&
            this.renderEditImageButton()}
          {!mobileDevice &&
            isEditSpaceRequired(user, space) &&
            this.renderEdit()}
          {isAddToSpaceRequired(space) && this.renderAddToSpace()}
          {isDeleteRequired(user, space) && this.props.renderDelete()}
          {isArchiveSpaceRequired(space) && (
            <ArchiveSpace
              space={space}
              extraClass='list-button'
              name='BtnIcon'
              icon='archive'
            />
          )}
          {this.renderLeavingAction()}
          {/*Hiding option to mute a care space */}
          {/*{isFollowRequired(space) && this.renderFollowButton()}*/}
          {!mobileDevice &&
            isNewSubspaceRequired(space) &&
            this.renderNewSubspaceButton(space)}
          {isShareLinkRequired(space) && this.renderNewShareLink()}
          {!mobileDevice &&
            isEditInformationRequired(this.props.workspace) &&
            this.renderEditPatientButton()}
          {this.renderCopyLinkButton()}
        </CustomDropdown>
        {isShareLinkRequired(space) && this.state.shareLinkModalOpen && (
          <SpaceShareLinks entity={space} onClose={this.closeShareLinkModal} />
        )}
      </>
    );
  }

  render() {
    return this.renderCustomDropdown();
  }
}

SpaceDropdown = compose(
  graphql(TOGGLE_COURSE_PURCHASE_MUTATION, { name: 'toggleCoursePurchase' }),
)(SpaceDropdown);
SpaceDropdown = withApollo(SpaceDropdown);
SpaceDropdown = withRouter(SpaceDropdown);
SpaceDropdown = connect(
  ({ currentUser, device, systemConfig, workspace }) => ({
    currentUser,
    device,
    systemConfig,
    workspace,
  }),
  { openEditImageModal, openInfoModal, openPatientModal },
)(SpaceDropdown);
SpaceDropdown = withAuthenticate(SpaceDropdown);

export default SpaceDropdown;
