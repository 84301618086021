import { Component } from 'react';

import { isAdminOrPartOfSpace, isCareSpace } from 'app/utils/spaceHelper';
import {
  contentAccessibleTabs,
  getPermissionAttributeBasedOnUser,
} from './helper';
import { currentUser } from 'app/utils/userHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

const DEFAULT_TABS = [
  {
    content_addition_allowed: true,
    content_preview_listing: false,
    content_type: 'discussion',
    editor_permission: 'all_access',
    guest_permission: 'tabs_visible',
    info_content: null,
    kind: 'discussions',
    logged_in_permission: 'tabs_visible',
    member_permission: 'all_access',
    patient_permission: 'all_access',
    position: 2,
    search_required: false,
    visible_at: null,
    content_count: 0,
    name: 'All Activity',
    path: 'activity',
  },
  {
    content_type: 'listing',
    name: 'Shared Files',
    path: 'files',
    guest_permission: 'tabs_visible',
    editor_permission: 'all_access',
    member_permission: 'all_access',
    logged_in_permission: 'tabs_visible',
    patient_permission: 'all_access',
    info_content: null,
    position: 3,
    kind: 'content',
    search_required: true,
    content_count: 0,
    content_preview_listing: false,
    visible_at: null,
    content_addition_allowed: true,
    __typename: 'NavTab',
  },
];

const CARE_TEAM_EXTRA_LIST = [
  {
    name: 'Scheduled Posts',
    path: 'scheduled_posts',
    content_type: 'scheduled_posts',
    editor_permission: 'all_access',
    kind: 'scheduled_posts',
  },
  {
    name: 'Patient details',
    path: 'patient_details',
    content_type: 'patient_details',
  },
  {
    name: 'Patient Status',
    path: 'patient_status',
    content_type: 'patient_status',
  },
  {
    name: 'Treatment Progress',
    path: 'treatment_progress',
    content_type: 'TreatmentProgress',
  },
  { name: 'Related Task', path: 'related_tasks', content_type: 'tasks' },
  // { name: 'Care Summary', path: 'care_summary', content_type: 'care_summary' },
];

const CARE_WORKSPACE_EXTRA_LIST = [
  {
    content_type: 'scheduled_posts',
    editor_permission: 'all_access',
    name: 'Scheduled Posts',
    path: 'scheduled_posts',
    kind: 'scheduled_posts',
    __typename: 'NavTab',
  },
];

const PATIENT_EXTRA_LIST = [
  {
    name: 'Your Info',
    path: 'patient_details',
    content_type: 'patient_details',
  },
  {
    name: 'Treatment Progress',
    path: 'treatment_progress',
    content_type: 'TreatmentProgress',
  },
  {
    name: 'Clinic details',
    path: 'clinic_details',
    content_type: 'clinic_details',
  },
  // { name: 'Care Summary', path: 'care_summary', content_type: 'care_summary' },
];

class NavTabs extends Component {
  addExtraTabs(tabs) {
    const { device, space } = this.props;
    let tab;
    if (
      tabs &&
      (device.ipadPortrait || device.width < 1440) &&
      isCareSpace(space)
    ) {
      tab = tabs.concat(
        isCareWorkspaceView() ? PATIENT_EXTRA_LIST : CARE_TEAM_EXTRA_LIST,
      );
    }
    if (!isCareWorkspaceView() && isCareSpace(space)) {
      tabs = tabs.concat(CARE_WORKSPACE_EXTRA_LIST);
    }
    return tab || tabs;
  }

  getEntity() {
    if (isCareSpace(this.props.space)) {
      let tabs = DEFAULT_TABS;
      tabs = this.addExtraTabs(tabs);
      tabs = this.addSharedFilesTabSectionId(tabs);
      return { ...this.props.space, nav_tabs: tabs };
    } else {
      return this.props.space;
    }
  }

  addSharedFilesTabSectionId(tabs) {
    return tabs.map((tab) => {
      if (tab.name === 'Shared Files') {
        return { ...tab, id: this.props.space.content_tab?.id };
      }
      return tab;
    });
  }

  selectedFilter() {
    return this.props.match.params.filter;
  }

  selectedTab() {
    let tab =
      contentAccessibleTabs(this.getEntity(), 'nav_tabs').find(
        (tab) => tab.path === this.selectedFilter(),
      ) || {};
    if (tab === undefined || JSON.stringify(tab) === '{}') {
      if (isCareWorkspaceView()) {
        let result = PATIENT_EXTRA_LIST.find(
          (tab) => tab.path === this.selectedFilter(),
        );
        if (result !== undefined) tab = result;
      } else {
        let result = CARE_TEAM_EXTRA_LIST.find(
          (tab) => tab.path === this.selectedFilter(),
        );
        if (result !== undefined) tab = result;
      }
    }
    return tab;
  }

  firstAccessibleTab() {
    let accessibleTabs = contentAccessibleTabs(this.getEntity(), 'nav_tabs'),
      defaultTab = accessibleTabs[0],
      entity = this.getEntity(),
      tab;

    if (accessibleTabs.length) {
      if (this.props.unreadDiscussionPresent || isCareSpace(this.props.space)) {
        tab = accessibleTabs.find((tab) => tab.kind === 'discussions');
      } else if (isAdminOrPartOfSpace(entity)) {
        tab = accessibleTabs.find((tab) => tab.kind === 'content');
      }
      return tab || defaultTab;
    } else {
      return {};
    }
  }

  isContentAccessible(tab) {
    return (
      this.isAuthor() ||
      tab[getPermissionAttributeBasedOnUser(this.getEntity())] === 'all_access'
    );
  }

  isAuthor() {
    return +this.getEntity().user.id === +currentUser().id;
  }
}
export default NavTabs;
