import { connect } from 'react-redux';

import AbstractFeed from 'app/components/FeedView/AbstractFeed';
import ActivityBar from 'app/components/FeedView/ActivityBar';
import { timestampOnHover } from 'app/utils/timeHelper';

class ActivityLogView extends AbstractFeed {
  render() {
    return (
      <div className='position-relative'>
        <article
          className={`activity-listing-item w-100 mobile-no-style d-block activity-log-view`}
          id={`activity-feed-${this.props.feed.id}`}
          ref='containerRef'
          title={timestampOnHover(this.props.feed.visible_at)}>
          <ActivityBar
            {...this.props}
            showTimestamp={true}
            extraClass={'remove-background'}
            {...this.feedDetailsProps()}
          />
        </article>
      </div>
    );
  }
}

const ConnectedActivityLogView = connect(({ device }) => ({ device }))(
  ActivityLogView,
);

export default ConnectedActivityLogView;
