import { createContext, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import AuthenticatedLayout from 'app/components/Layouts/AuthenticatedLayout';
import CommandMenu from 'app/components/DnD/CmdMenu/CommandMenu';
import WorkspaceSubscription from 'app/components/Layouts/WorkspaceSubscription';
import InboxCountSubscription from 'app/components/Layouts/InboxCountSubscription';
const WORKSPACE_ADDITIONAL_DETAILS_QUERY = queryLoader(
  'app/graphql/queries/Workspace/AdditionalDetails.gql',
);

const TASK = 'task';
const Partner = 'partner';
const PERSON = 'person';
const GLOBAL = 'global';

const DEFAULT_VALUES = {
  all_labels: [],
  task_labels: [],
  partner_labels: [],
  person_labels: [],
  global_labels: [],
  all_members: [],
  all_templates: [],
  enabled_templates: [],
  subscriptionData: null,
};

const getSortedAndFilteredLabels = (labels, kind) => {
  return labels
    .filter(({ type, deleted_at }) => type === kind && !deleted_at)
    .map((label) => ({
      ...label,
      displayName: label.name,
      sub_labels: label.sub_labels
        ? label.sub_labels.map((subLabel) => ({
            ...subLabel,
            displayName: `${label.name}/${subLabel.name}`,
          }))
        : null,
    }))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
};

const getSortedAndFilteredTemplates = (template) => {
  return template
    .filter((t) => t.enabled)
    .sort((a, b) => a.title.localeCompare(b.title));
};
export const WorkspaceContext = createContext(DEFAULT_VALUES);

const WorkspaceLayout = (props) => {
  const location = useLocation();

  const isNavLocked = useSelector((state) => state.lockNavbar?.lockNav);

  const identifier = location.pathname.split('/')[1] || '';

  const { data, loading } = useQuery(WORKSPACE_ADDITIONAL_DETAILS_QUERY, {
    variable: {
      identifier: location.pathname.split('/')[1] || '',
    },
    skip: !identifier,
    fetchPolicy: 'network-only',
  });
  const [subscriptionData, setSubscriptionData] = useState({});

  const updateSubscriptionData = (data) => {
    setSubscriptionData(data);
  };

  const workspaceId = data?.workspace.id;

  const payload = useMemo(() => {
    if (loading) {
      return DEFAULT_VALUES;
    } else {
      return {
        ...data.workspace,
        task_labels: getSortedAndFilteredLabels(
          data.workspace.all_labels,
          TASK,
        ),
        person_labels: getSortedAndFilteredLabels(
          data.workspace.all_labels,
          PERSON,
        ),
        global_labels: getSortedAndFilteredLabels(
          data.workspace.all_labels,
          GLOBAL,
        ),
        partner_labels: getSortedAndFilteredLabels(
          data.workspace.all_labels,
          Partner,
        ),
        enabled_templates: getSortedAndFilteredTemplates(
          data.workspace.all_templates,
        ),
        subscriptionData,
      };
    }
  }, [data, loading, subscriptionData]);

  return (
    <WorkspaceContext.Provider value={payload}>
      <AuthenticatedLayout skipOutlet>
        {workspaceId && (
          <WorkspaceSubscription
            workspaceId={workspaceId}
            updateSubscriptionData={updateSubscriptionData}
          />
        )}
        {workspaceId && (
          <InboxCountSubscription
            workspaceId={workspaceId}
            updateSubscriptionData={updateSubscriptionData}
          />
        )}
        {!isNavLocked && <CommandMenu />}
        <Outlet />
      </AuthenticatedLayout>
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceLayout;
