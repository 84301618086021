import React, { createContext, useEffect } from 'react';
import useGetAllDomains from 'app/components/WorkspaceView/SettingView/IntegrationConfig/useGetAllDomains';
import useIntegration from 'app/components/WorkspaceView/SettingView/IntegrationConfig/useIntegration';
import IntegrationConfigView from 'app/components/WorkspaceView/SettingView/IntegrationConfig/IntegrationConfig';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

// Create context for IntegrationConfig
export const IntegrationConfigContext = createContext({});
const IntegrationConfig = () => {
  const { hostname, setHostname, integrationEnabled, setIntegrationEnabled } =
    useIntegration();
  const { allDomains, domainsLoading, allDomainsVariable } = useGetAllDomains();

  const value = {
    hostname,
    setHostname,
    integrationEnabled,
    setIntegrationEnabled,
    allDomains,
    allDomainsVariable,
  };

  useEffect(() => {
    if (!domainsLoading) {
      if (allDomains.records.results.length > 0) {
        setIntegrationEnabled(true);
      } else {
        setIntegrationEnabled(false);
      }
    }
  }, [domainsLoading, allDomains, setIntegrationEnabled]);

  return (
    <IntegrationConfigContext.Provider value={value}>
      <SetPageTitle
        source={'worksapce_settings'}
        interpolations={'Integration'}
      />
      <IntegrationConfigView />
    </IntegrationConfigContext.Provider>
  );
};

export default IntegrationConfig;
