import React from 'react';
import { connect } from 'react-redux';

import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';

import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import NavTabs from 'app/components/NavTabs';
import VerificationProcessNotionLink from 'app/components/Shared/VerificationProcessNotionLink';

import { openInfoModal } from 'app/actions/infoModal';

import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';
import {
  isGuestUser,
  userVerificationDataReceived,
} from 'app/utils/userHelper';
import { findIndex, isEmpty, isNull, last, map } from 'app/utils/osLodash';
import {
  isCareSpace,
  tabNotAvailableAsUserNotVerified,
} from 'app/utils/spaceHelper';

import {
  setCLVMovementDirection,
  setLectureEntity,
} from 'app/actions/lectureView';

class ClvTabListing extends NavTabs {
  constructor(props) {
    super(props);
    this.setInitailLectureEntity();
  }

  componentDidUpdate(prevProps) {
    if (this.isSelectedItemInList()) {
      if (this.props.lectureView.moveDirection === 'down') {
        this.selectNextItem();
      }

      if (this.props.lectureView.moveDirection === 'up') {
        this.selectPreviousItem();
      }
    }

    if (
      prevProps.openFirstContentTabId !== this.props.openFirstContentTabId &&
      +this.props.openFirstContentTabId === +this.props.idQuery
    ) {
      this.setInitailLectureEntity();
    }

    if (
      prevProps.openLastContentTabId !== this.props.openLastContentTabId &&
      +this.props.openLastContentTabId === +this.props.idQuery
    ) {
      let lastContent = last(this.props.results);
      this.setLectureEntity(lastContent);
    }
  }

  selectNextItem() {
    let selectedItemIndex = this.getSelectedItemIndex(
        this.props.lectureView.entity,
      ),
      nextItemInCurrentList = this.props.results[selectedItemIndex + 1];

    if (nextItemInCurrentList) {
      this.setLectureEntity(nextItemInCurrentList);
    } else {
      this.props.openNextSectionOrTab(this.props.idQuery);
    }
    this.props.setCLVMovementDirection();
  }

  selectPreviousItem() {
    let selectedItemIndex = this.getSelectedItemIndex(
        this.props.lectureView.entity,
      ),
      previousItemInList = this.props.results[selectedItemIndex - 1];

    if (previousItemInList) {
      this.setLectureEntity(previousItemInList);
    } else {
      this.props.openPreviousSectionOrTab(this.props.idQuery);
    }
    this.props.setCLVMovementDirection();
  }

  isSelectedItemInList() {
    return map(this.props.results, 'id').includes(
      this.props.lectureView.entity?.id,
    );
  }

  getEntity() {
    return this.props.entity;
  }

  setInitailLectureEntity() {
    let { results } = this.props,
      isCareSpaceView = isCareSpace(this.getEntity()),
      keyboardNavigation =
        +this.props.idQuery === +this.props.openFirstContentTabId;
    if (
      this.isContentAccessible(this.props.tab) &&
      results.length > 0 &&
      (isNull(this.props.lectureView.entity) || keyboardNavigation)
    ) {
      this.setLectureEntity(results[0], {
        initialPreview: !keyboardNavigation && !isCareSpaceView,
      });
    }
  }

  scrollLectureInViewIfNeeded(spaceLink) {
    let element = document.querySelectorAll(
      `[data-card-id='${spaceLink.__typename}:${spaceLink.id}']`,
    )[0];
    element &&
      element.scrollIntoViewIfNeeded &&
      element.scrollIntoViewIfNeeded();
  }

  getSelectedItemIndex(spaceLink) {
    return findIndex(this.props.results, (boardLink) => {
      return spaceLink.id === boardLink.id;
    });
  }

  setLectureEntity = (spaceLink, metaInfo = {}) => {
    this.scrollLectureInViewIfNeeded(spaceLink);
    if (spaceLink.linkable) spaceLink.linkable.autoplay = metaInfo.autoplay;
    this.props.setLectureEntity(spaceLink, {
      ...metaInfo,
      activeContentTabId: this.props.tab.id,
    });
  };

  isLinkSelected(spaceLink) {
    return (
      !isEmpty(this.props.lectureView.entity) &&
      +spaceLink.id === +this.props.lectureView.entity.id
    );
  }

  openRestrictedTabsModal = (filter) => {
    if (tabNotAvailableAsUserNotVerified(this.getEntity(), filter)) {
      this.showProfileIncompleteModal();
    }
  };

  showProfileIncompleteModal = () => {
    if (userVerificationDataReceived()) {
      this.props.openInfoModal(
        'general',
        'user_verification_awaited_for_approval',
        {
          contentInterpolations: {
            verification_process_link: <VerificationProcessNotionLink />,
          },
        },
      );
    } else {
      this.props.openInfoModal(
        'general',
        'user_verification_required_for_discussions',
        {
          contentInterpolations: {
            verification_process_link: <VerificationProcessNotionLink />,
          },
          onSuccess: this.props.openOnboardingVerificationModals,
        },
      );
    }
  };

  setLectureEntityIfAccessible = (spaceLink) => {
    if (this.isContentAccessible(this.props.tab)) {
      this.setLectureEntity(spaceLink, { autoplay: true });
    } else {
      if (isGuestUser()) {
        this.props.redirectToDefault();
      } else {
        this.props.openRestrictedModalIfNotAMember(
          this.getEntity(),
          this.openRestrictedTabsModal.bind(this, this.props.tab.path),
        );
      }
    }
  };

  renderList = (spaceLink, index) => {
    spaceLink['extraClass'] = this.isLinkSelected(spaceLink) ? 'active' : '';
    return (
      <OsGrid
        identifier='SpaceCLV:LinkCards'
        key={spaceLink.id}
        extraClass='px-0'>
        <OsCards
          size='extra-small'
          obj={spaceLink}
          onCardClick={this.setLectureEntityIfAccessible.bind(this, spaceLink)}
        />
      </OsGrid>
    );
  };

  getContextProps() {
    let entity = this.getEntity();
    return {
      isAttachmentRestricted: false,
      sourceObject: entity,
      contentPreviewListing: true,
      triggerOutsideClickElement: this.triggerOutsideClickElement,
    };
  }

  render() {
    return (
      <div className='toc-data-list'>
        <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
          {this.props.results.map(this.renderList)}
        </CARD_ACTION_CONTEXT.Provider>
        <span ref={(ele) => (this.triggerOutsideClickElement = ele)} />
      </div>
    );
  }
}

ClvTabListing = connect(
  ({ currentUser, lectureView }) => ({ currentUser, lectureView }),
  { openInfoModal, setCLVMovementDirection, setLectureEntity },
)(ClvTabListing);
ClvTabListing = withSpaceRestriction(ClvTabListing);
ClvTabListing = withAuthenticate(ClvTabListing);

export default ClvTabListing;
