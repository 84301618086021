import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import storeUpdater from 'app/services/StoreUpdater';

const DELETE_DOMAIN = queryLoader(
  'app/graphql/mutations/Integration/DeleteDomain.gql',
);

const useDeleteDomain = (allDomainsVariable) => {

  const [deleteDomain] = useMutation(DELETE_DOMAIN);

  const deleteDomainToWebsite = async (domainId) => {
    const response = await deleteDomain({
      variables: {
        clinicDomainId: domainId
      }
    })
    if (response.data.deleteClinicDomain.success) {
      const record = response.data.deleteClinicDomain.entity;
      storeUpdater.removeDataFromRecords(record, allDomainsVariable);
    }
  }

  return {
      deleteDomainToWebsite
  }
}

export default useDeleteDomain;