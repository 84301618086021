import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { APPOINTMENT_TYPE_MAPPER } from 'app/components/CaseRightSection/constants.js';

import { closeAppointmentInfoModal } from 'app/actions/appointmentInfoModal';
import { keys } from 'app/utils/osLodash';

export default class AppointmentInfoModal extends Component {
  renderAppointmentLegend = (appointmentType) => {
    return (
      <li key={appointmentType} className='cs-legend-list-item'>
        <OrthoIcon
          name={APPOINTMENT_TYPE_MAPPER[appointmentType].icon}
          dataHoverNotRequired={true}
        />
        <span>{APPOINTMENT_TYPE_MAPPER[appointmentType].name}</span>
      </li>
    );
  };

  renderBody() {
    return (
      <ul className='list-unstyled cs-legend-list'>
        {keys(APPOINTMENT_TYPE_MAPPER).map(this.renderAppointmentLegend)}
      </ul>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.appointmentInfoModal.open}
        onHide={this.props.closeAppointmentInfoModal}
        dialogClassName='os-grid-modal timeline-legend-modal p-y-30'
        animation={false}
        backdropClassName='modal-backdrop-custom '>
        <Modal.Header className='border-0'>
          <h4 className='modal-title'>Appointment Timeline Legend</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn'
            icon='close'
            label='Close Appointment legend modal'
            onClick={this.props.closeAppointmentInfoModal}
          />
        </Modal.Header>
        <Modal.Body className='px-0'>{this.renderBody()}</Modal.Body>
      </Modal>
    );
  }
}

AppointmentInfoModal = connect(
  ({ appointmentInfoModal }) => ({ appointmentInfoModal }),
  { closeAppointmentInfoModal },
)(AppointmentInfoModal);
