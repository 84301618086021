import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';

import Timer from 'app/components/Shared/Timer';
import OtpInput from './OtpInput';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import ConnectToSupport from 'app/components/ConnectToSupport';
import { Modal } from 'react-bootstrap';

import { lastNcharacters } from 'app/utils/stringHelper';
import { translate } from 'app/actions/flashMessage.js';

import logoVertical from 'app/images/cloudberry-logo.png';

const RESEND_OTP_TIMER_VALUE = 30;
const VERIFY_OTP_MUTATION = queryLoader('app/graphql/VerifyOtp.gql');

class PhoneAuthentication extends Component {
  state = {
    error: '',
    resendCount: 0,
    otpEntered: '',
    deviceTrusted: false,
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isCompleteOtpEntered()) {
      this.props
        .verifyOtpMutation({
          variables: {
            phone_number: this.props.phoneNumber,
            verification_code: this.state.otpEntered,
            country_code: this.props.countryCode,
            login_verification: true,
            device_trusted: this.state.deviceTrusted,
            email: this.props.email
          },
        })
        .then(({ data }) => {
          if (data.verifyOtp.success) {
            this.props.onSuccess(data.verifyOtp.token);
          } else {
            let error = data.verifyOtp.message;
            if (error !== 'Verification code is incorrect') {
              error = 'Code has expired';
            }
            this.setState({ error });
          }
        });
    }
  };

  saveOtp = (otpEntered) => {
    this.setState({ otpEntered });
  };

  activateResendLink = () => {
    this.setState({ resendLinkActive: true });
  };

  otpViaSMSRequired() {
    return (
      this.state.resendCount + 1 <
      +this.props.systemConfig.configs['resend_otp_limit_on_login'].value
    );
  }

  resendOtp = () => {
    let resendCount = this.state.resendCount + 1,
      channel = this.otpViaSMSRequired() ? 'sms' : 'call';
    this.props.resendOtp({ channel });
    this.setState({ resendLinkActive: false, resendCount });
  };

  renderResendLink() {
    var resendLimit =
      this.props.systemConfig.configs['resend_otp_limit_on_login'] || {};
    if (this.state.resendCount === +resendLimit.value) {
      return (
        <div className='text-right resend-text m-auto'>
          <span>Haven't received the code?. </span>
          <ConnectToSupport
            className='resend-text-link'
            text='Get in touch with the support team.'
          />
        </div>
      );
    } else {
      let text = this.otpViaSMSRequired()
        ? ' Resend the code.'
        : ' Resend the code via call.';
      let extraClass = 'lh-15 resend-text-link';
      extraClass += this.state.resendLinkActive ? '' : ' cursor-not-allowed';
      return (
        <div className='text-right resend-text'>
          <OsBtn
            name='BtnLink'
            onClick={this.state.resendLinkActive ? this.resendOtp : ''}
            extraClass={extraClass}
            text={text}
          />
          <Timer
            timerTime={this.state.resendLinkActive ? 0 : RESEND_OTP_TIMER_VALUE}
            timeUp={this.activateResendLink}
          />
        </div>
      );
    }
  }

  isCompleteOtpEntered() {
    return this.state.otpEntered.length === 4;
  }

  closeModal = () => {
    this.props.onClose();
  };

  onCheckboxChange = ({ target }) => {
    this.setState({ deviceTrusted: target.checked });
  };

  render() {
    return (
      <Modal
        show={true}
        className='login-update-modal login-2fa'
        key='login-modal-fields'
        onHide={this.closeModal}
        animation={false}
        backdropClassName='modal-backdrop-custom modal-backdrop-login'>
        <form onSubmit={this.onSubmit}>
          <Modal.Header>
            <img src={logoVertical} className='login-modal-logo me-2' alt='' />
            <span className='modal-title'>Two-step verification</span>
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn close-general-modal  p-0'
              icon='close'
              label='Close modal'
              onClick={this.closeModal}
            />
          </Modal.Header>
          <Modal.Body className='p-0'>
            {this.state.error && (
              <div className='alert alert-danger'> {this.state.error}</div>
            )}
            <h3 className='login-2fa-h3'>
              {translate('OTP_SENT_HEADING') +
                lastNcharacters(this.props.phoneNumber, 4)}
            </h3>
            <div className='d-flex mb-2 verify-text-link'>
              <span className='login-2fa-span'>Your Verification Code*</span>
              {this.renderResendLink()}
            </div>
            <OtpInput
              containerStyle={'form-group'}
              inputStyle={'form-control'}
              onChange={(otp) => this.saveOtp(otp)}
              numInputs={4}
              shouldAutoFocus={true}
            />

            <OsField
              osType='checkbox'
              name='device_trusted'
              label='Trust this device in the future'
              onChange={this.onCheckboxChange}
              formGroupExtraClass='check-listing  device-trust-checkarea'
            />
          </Modal.Body>
          <Modal.Footer
            className='p-0 d-flex justify-content-center'
            key='login-modal-buttons'>
            <OsBtn
              name='BtnPrimary'
              extraClass={
                'mt-4 ' +
                (this.isCompleteOtpEntered()
                  ? ''
                  : 'osbtn-disabled pointer-allow')
              }
              onClick={this.onSubmit}
              text='Confirm Code'
              type='submit'
              disabled={!this.isCompleteOtpEntered()}
              htmlTag='button'
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
PhoneAuthentication = withRouter(PhoneAuthentication);
PhoneAuthentication = graphql(VERIFY_OTP_MUTATION, {
  name: 'verifyOtpMutation',
})(PhoneAuthentication);

PhoneAuthentication = connect(
  ({ systemConfig }) => ({ systemConfig }),
  {},
)(PhoneAuthentication);
export default PhoneAuthentication;
