import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsGrid from 'app/components/OsGrid';
import RecordsView from 'app/components/RecordsView';

class RecentlyOnlineMembersWidget extends Component {
  state = {};
  renderHeading() {
    return (
      <div className='d-flex align-items-center sponsored-widget-heading mb-3'>
        <span className='widget-title'>Recently Online Members</span>
      </div>
    );
  }

  setCollectionTotal = (count) => {
    this.setState({ count });
    this.props.onContentLoad();
  };

  getSourceObject() {
    return this.props.widgetSourceObject;
  }

  getQueryProps() {
    return { type: 'RecentlyOnlineMembers', queryType: 'USERS_INDEX' };
  }

  render() {
    let sourceObject = this.getSourceObject();
    return (
      <OsGrid
        identifier='DetailRightSidebar:DoctorConnectBlock'
        extraClass={this.state.count ? '' : 'd-none'}>
        <div className={`sidebar-container ${this.props.sideBarClass || ''}`}>
          <div className='recently-online-widget'>
            {this.renderHeading()}
            <RecordsView
              {...this.getQueryProps()}
              idQuery={sourceObject.id}
              page={0}
              perPage={5}
              infiniteScroll={false}
              nextPageLoadRequired={false}
              showMoreNotRequired={true}
              size='small'
              cardGridIdentifier={'CareSpaceColumn:RightSidebarWidget'}
              setCollectionTotal={this.setCollectionTotal}
              collectionTotal={this.state.count}
            />
          </div>
        </div>
      </OsGrid>
    );
  }
}

RecentlyOnlineMembersWidget.defaultProps = {
  onContentLoad: () => {},
};

RecentlyOnlineMembersWidget = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(RecentlyOnlineMembersWidget);
export default RecentlyOnlineMembersWidget;
