import {
  MERGE_CURRENT_WORKSPACE_DATA,
  TOOGLE_WORKSPACE_DROPDOWN_STATE,
  UPDATE_CURRENT_WORKSPACE_DATA,
  UPDATE_GUEST_WORKSPACE_OWNER_DATA,
} from './types';

export function setWorkspaceData(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_WORKSPACE_DATA,
      payload: data,
    });
  };
}

export function mergeWorkspaceData(data) {
  return (dispatch) => {
    dispatch({
      type: MERGE_CURRENT_WORKSPACE_DATA,
      payload: data,
    });
  };
}

export function setGuestWorkspaceOwnerData(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GUEST_WORKSPACE_OWNER_DATA,
      payload: data,
    });
  };
}

export function toggleWorkspaceDropdown() {
  return {
    type: TOOGLE_WORKSPACE_DROPDOWN_STATE,
  };
}
