import { CLOSE_SHARE_MODAL, OPEN_SHARE_MODAL } from './types';

export function openShareModal(options) {
  return {
    type: OPEN_SHARE_MODAL,
    options,
  };
}

export function closeShareModal() {
  return { type: CLOSE_SHARE_MODAL };
}
