import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

const ACCEPT_CLINIC_INVITATION_MUTATION = queryLoader(
  'app/graphql/mutations/PartnerSpaces/AcceptClinicInvitation.gql',
);

const useAcceptClinicInvitation = ({
  token,
  onSuccessCallback,
  onFailureCallback,
}) => {
  const [acceptClinicInvitationMutation, { data, loading: requestInProgress }] =
    useMutation(ACCEPT_CLINIC_INVITATION_MUTATION);

  const acceptInvitation = useCallback(
    (variables) => {
      acceptClinicInvitationMutation({
        variables: {
          ...variables,
          token,
        },
      }).then(({ data }) => {
        if (data.acceptClinicInvitation.success) {
          onSuccessCallback?.(variables);
        } else {
          onFailureCallback?.();
        }
      });
    },
    [
      token,
      onSuccessCallback,
      onFailureCallback,
      acceptClinicInvitationMutation,
    ],
  );

  return {
    acceptInvitation,
    data,
    requestInProgress,
  };
};

export default useAcceptClinicInvitation;
