import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import Avatar from 'app/components/Shared/Avatar';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import RecordsView from 'app/components/RecordsView';
import UserRowInWidget from 'app/components/Shared/UserRowInWidget';

import { orderedDegrees } from 'app/utils/generalHelper';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';

export default class UsersInfoModal extends Component {
  listUsers() {
    if (this.props.type) {
      return (
        <RecordsView
          type={this.props.type}
          queryType={this.props.queryType}
          idQuery={this.props.idQuery}
        />
      );
    } else {
      return this.props.users.map((user) => (
        <UserRowInWidget user={user} key={user.id} />
      ));
    }

    return this.props.users.map((user) => {
      return (
        <li className='user-listing-row' key={user.id}>
          <span className='user-image'>
            <Avatar className='avatar' {...avatarAttributes(user)} />
          </span>
          <div className='user-name'>
            <OsLink
              to={entityUrl(user)}
              text={user.name}
              className='white-link hover-underline'
            />
            <span className='degree-list'>
              {orderedDegrees(user.degree_list).join(' ')}
            </span>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.closeModal}
        dialogClassName='modal-xl modal-users modal-chatgroup'
        animation={false}
        backdropClassName='modal-backdrop-custom'>
        <div className='modal-header'>
          <h4 className='card-title'>{this.props.heading}</h4>

          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn rel-pos close-char-participants'
            icon='close'
            label='Close chat participants modal'
            onClick={() => this.props.closeModal()}
          />
        </div>

        <ul className='user-listing list-unstyled chat-listing'>
          {this.listUsers()}
        </ul>
      </Modal>
    );
  }
}
