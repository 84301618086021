import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import { orderBy } from 'app/utils/osLodash';
import { timestamp } from 'app/utils/timeHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsCards from 'app/components/OsCards';
import ParseText from 'app/components/ParseText';

const renderAvatar = (entity) => {
  return (
    <Link to={entityUrl(entity)}>
      <Avatar className='avatar avatar-40' {...avatarAttributes(entity)} />
    </Link>
  );
};

const PinnedMessage = (props) => {
  const { conversation } = props;
  const currentUser = useSelector((state) => state.currentUser);

  const renderOsCard = (object, author) => {
    let fileAsset;
    if (object.__typename === 'MessageFile') {
      fileAsset = { ...object.file_asset, author: author };
    }
    return (
      <div className='col-12 mb-2' key={object.id}>
        <OsCards
          size='extra-small'
          obj={object.shareable || fileAsset || object}
        />
      </div>
    );
  };

  const sortedFiles = useMemo(() => {
    return orderBy(conversation.files, 'position');
  }, [conversation.files]);

  const renderList = (objects, author) => {
    return objects.map((s) => renderOsCard(s, author));
  };

  if (!conversation) {
    return null;
  }

  const isOwnMessage = () => {
    return +conversation.author.id === +currentUser.graph.id;
  };

  return (
    <div className='pinned-msg-block'>
      <div className='avatar'>{renderAvatar(conversation.author)}</div>
      <div className='info'>
        <div className='author'>
          <span className='name'>{conversation.author?.name}</span>
          <span className='created_at'>
            {timestamp(conversation.created_at)}
          </span>
          {conversation?.files.length > 0 && (
            <span className='icon'>
              <OrthoIcon name='attach' defaultClass='chat-content-icon' />
            </span>
          )}
        </div>
        <div className='content'>
          <span className='text'>
            <ParseText
              obj={conversation}
              className='conversation-message-text'
              linkClassName={isOwnMessage() ? 'white-link' : ''}
            />
          </span>

          {(sortedFiles.length > 0 || conversation?.message_links) && (
            <div className='pinned-chat-attachment-row'>
              <div className='w-100 attachment'>
                {sortedFiles &&
                  renderList(sortedFiles, conversation.author)}
                {conversation?.message_links &&
                  renderList(conversation.message_links, conversation.author)}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='action' onClick={() => props.unpinObject(conversation)}>
        <OrthoIcon name='clear' defaultClass='chat-content-icon' />
      </div>
    </div>
  );
};

export default PinnedMessage;
