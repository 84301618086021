import React from 'react';

import HTMLContentContainer from 'app/components/Shared/HTMLContentContainer';
import RecordsPager from 'app/components/RecordsPager';

const GroupInformation = ({ entity }) => {
  const { information, id } = entity;

  if (id)
    return (
      <>
        {information && (
          <div className='group-info'>
            <HTMLContentContainer content={information} />
          </div>
        )}
        <div className='group-heading'>Group members</div>
        <div className='group-members-list'>
          {id && (
            <RecordsPager
              queryType='SPACE_MEMBERS'
              recordId={id}
              displayType='SPACE_USER_LIST'
              windowNotRequired={true}
              recordType='Board'
              limit={8}
              loadingCards={false}
              space={entity}
              type='SpaceMemberCollaborators'
            />
          )}
        </div>
      </>
    );
};

export default GroupInformation;
