import ReactS3 from 'react-s3';
import OrthoPolicy from './OrthoPolicy';
import Signature from 'react-s3/lib/Signature';
import { xAmzDate, dateYMD } from 'react-s3/lib/Date';
import { throwError } from 'react-s3/lib/ErrorThrower';

export default class OrthoS3 extends ReactS3 {
  static async uploadFile(file, config, callbacks) {
    // Error Thrower :x:
    throwError(config, file);

    const fd = new FormData();
    const key = `${config.dirName ? config.dirName + '/' : ''}${file.name}`;
    const url = `https://${config.bucketName}.s3.amazonaws.com/`;
    fd.append('key', key);
    fd.append('acl', config.acl || 'public-read');
    fd.append('Content-Type', file.type);
    fd.append('x-amz-meta-uuid', '14365123651274');
    fd.append('x-amz-server-side-encryption', 'AES256');
    fd.append(
      'X-Amz-Credential',
      `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`,
    );
    fd.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
    fd.append('X-Amz-Date', xAmzDate);
    fd.append('x-amz-meta-tag', '');
    fd.append('Policy', OrthoPolicy.getPolicy(config));
    fd.append(
      'X-Amz-Signature',
      Signature.getSignature(config, dateYMD, OrthoPolicy.getPolicy(config)),
    );
    fd.append('file', file);
    try {
      let request = new XMLHttpRequest();
      request.open('POST', url);
      // upload progress event
      request.upload.addEventListener('progress', function (e) {
        // upload progress as percentage
        let percentageCompleted = (e.loaded / e.total) * 100;
        callbacks.onUploadProgress(file, percentageCompleted);
      });

      // request finished event
      request.addEventListener('load', function (e) {
        // HTTP status message (200, 404 etc)
        if (request.status === 204) {
          callbacks.onUploadComplete(
            {
              location: `${url}${config.dirName ? config.dirName + '/' : ''}${
                file.name
              }`,
            },
            file,
          );
        }
      });

      // send POST request to server
      request.send(fd);
    } catch (e) {
      console.log(e);
    }
  }
}
