export const EASY_RX_BASE_API_URL = process.env.REACT_APP_EASY_RX_BASE_API_URL;
export const EASY_RX_CLIENT_ID = process.env.REACT_APP_EASY_RX_CLIENT_ID;
export const EASY_RX_CALLBACK_URL =
  process.env.REACT_APP_EASY_RX_CALLBACK_URL + '/callback/easy-rx';

const PRESCRIPTION_STATUS_ALL = 'all';
const PRESCRIPTION_STATUS_SAVED = 'saved';
const PRESCRIPTION_STATUS_SUBMITTED = 'submitted';
const PRESCRIPTION_STATUS_CHECKED_IN = 'checked-in';
const PRESCRIPTION_STATUS_COMPLETED = 'completed';
const PRESCRIPTION_STATUS_SHIPPED = 'shipped';
const PRESCRIPTION_STATUS_RECEIVED = 'received';
const PRESCRIPTION_STATUS_DELIVERED = 'delivered';

export const PRESCRIPTION_STATUSES = [
  PRESCRIPTION_STATUS_SAVED,
  PRESCRIPTION_STATUS_SUBMITTED,
  PRESCRIPTION_STATUS_CHECKED_IN,
  PRESCRIPTION_STATUS_COMPLETED,
  PRESCRIPTION_STATUS_SHIPPED,
  PRESCRIPTION_STATUS_RECEIVED,
  PRESCRIPTION_STATUS_DELIVERED,
];

export const PRESCRIPTION_STATUS_INFO = {
  [PRESCRIPTION_STATUS_ALL]: { title: 'All' },
  [PRESCRIPTION_STATUS_SUBMITTED]: {
    title: 'Submitted',
    status: PRESCRIPTION_STATUS_SUBMITTED,
  },
  [PRESCRIPTION_STATUS_CHECKED_IN]: {
    title: 'Checked In',
    status: PRESCRIPTION_STATUS_CHECKED_IN,
  },
  [PRESCRIPTION_STATUS_COMPLETED]: {
    title: 'Completed',
    status: PRESCRIPTION_STATUS_COMPLETED,
  },
  [PRESCRIPTION_STATUS_SHIPPED]: {
    title: 'Shipped',
    status: PRESCRIPTION_STATUS_SHIPPED,
  },
  [PRESCRIPTION_STATUS_RECEIVED]: {
    title: 'Received',
    status: PRESCRIPTION_STATUS_RECEIVED,
  },
  [PRESCRIPTION_STATUS_DELIVERED]: {
    title: 'Delivered',
    status: PRESCRIPTION_STATUS_DELIVERED,
  },
};
