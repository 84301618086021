import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import SelectInput from 'app/components/Shared/SelectInput';

import { find } from 'app/utils/osLodash';

const STATES_QUERY = queryLoader('app/graphql/States.gql');

class StateSelect extends Component {
  getCurrentStateValue() {
    let state = find(
      this.props.data && (this.props.data.states || []),
      (state) => this.props.input.value === state.id,
    );
    return state ? { label: state.name, value: state.id } : {};
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.countryId && this.props.countryId !== nextProps.countryId)
      this.props.input.onChange('');
  }

  render() {
    return (
      <div className='form-group'>
        {this.props.label && (
          <label
            className={this.props.labelClassName}
            htmlFor={this.props.input.name}>
            {this.props.label}
          </label>
        )}

        <SelectInput
          {...this.props}
          isSearchable
          value={this.getCurrentStateValue()}
          options={((this.props.data && this.props.data.states) || []).map(
            (state) => ({ label: state.name, value: state.id }),
          )}
        />

        {this.props.meta.touched && this.props.meta.error && (
          <span className='form-error pull-right'>{this.props.meta.error}</span>
        )}
      </div>
    );
  }
}

StateSelect.defaultProps = {
  labelClassName: '',
};
StateSelect = graphql(STATES_QUERY, {
  skip: (props) => !props.countryId,
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      country_id: props.countryId,
    },
  }),
})(StateSelect);

export default StateSelect;
