import {
  MOVE_SPACE_LINKS_TO_NEW_SECTION,
  RESET_ACTIVE_DRAG_DETAILS,
  RESET_SELECTED_SPACE_LINKS,
  SET_ACTIVE_DRAG_DETAILS,
  SET_NEW_SECTION_ID,
  SET_SPACE_LINKS_DROP_OPTIONS,
  TOGGLE_SELECTED_SPACE_LINK,
  TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS,
} from 'app/actions/types';

import { indexOf } from 'app/utils/osLodash';

export default function spaceLinks(
  state = {
    activeDragType: '',
    dragId: null,
    dragLink: null,
    linksDropOptions: {},
    moveLinksToNewSectionOptions: {},
    newSectionId: null,
    requestInProgress: false,
    selectedLinkIds: [],
    selectedLinks: [],
  },
  action,
) {
  let { selectedLinkIds, selectedLinks } = state;

  switch (action.type) {
    case MOVE_SPACE_LINKS_TO_NEW_SECTION:
      return {
        ...state,
        moveLinksToNewSectionOptions: action.moveLinksToNewSectionOptions,
      };
    case RESET_ACTIVE_DRAG_DETAILS:
      return { ...state, activeDragType: '', dragId: null, dragLink: null };
    case RESET_SELECTED_SPACE_LINKS:
      return { ...state, selectedLinkIds: [], selectedLinks: [] };
    case SET_ACTIVE_DRAG_DETAILS:
      return { ...state, ...action.options };
    case SET_NEW_SECTION_ID:
      return { ...state, newSectionId: action.newSectionId };
    case SET_SPACE_LINKS_DROP_OPTIONS:
      return { ...state, linksDropOptions: action.linksDropOptions };
    case TOGGLE_SELECTED_SPACE_LINK:
      let linkId = action.object.link.id;
      if (selectedLinkIds.includes(linkId)) {
        selectedLinkIds.splice(indexOf(selectedLinkIds, linkId), 1);
        selectedLinks = selectedLinks.filter((object) =>
          selectedLinkIds.includes(object.link.id),
        );
      } else {
        selectedLinkIds.push(linkId);
        selectedLinks.push(action.object);
      }
      return { ...state, selectedLinkIds, selectedLinks };
    case TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS:
      return { ...state, requestInProgress: !state.requestInProgress };
    default:
      return state;
  }
}
