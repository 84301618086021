export const INSTRUCTED_COURSE_PATH = 'instructed_courses';
export const COURSES_FILTER_NAMES = [
  INSTRUCTED_COURSE_PATH,
  'attended_courses',
];
export const DEFAULT_FILTER = 'timeline';
export const GUEST_USER_NAV_LIST = [];

export const CARE_MY_NAV_LIST = [['NOTIFICATIONS', 'notifications']];

export const CARE_USER_NAV_LIST = [['TIMELINE', 'timeline']];

export const MY_NAV_LIST = [
  ['TIMELINE', 'timeline'],
  ['NOTIFICATIONS', 'notifications'],
];

export const USER_NAV_LIST = [['TIMELINE', 'timeline']];
