import { SET_SOURCE } from 'app/actions/types';
import { addClassToHTML } from 'app/utils/domHelper';

const source = (
  state = { value: 'workspace', isCare: false, isWorkspace: true },
  action,
) => {
  switch (action.type) {
    case SET_SOURCE:
      action.source && addClassToHTML(action.source);
      return {
        value: action.source,
        isCare: action.source === 'care',
        isWorkspace: action.source === 'workspace',
      };
    default:
      return state;
  }
};

export default source;
