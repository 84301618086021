//https://github.com/gurpreet013/threesixty
import { inRange, isNumber } from 'app/utils/osLodash';

export default class ThreeSixty {
  constructor(container, options) {
    this.container = container;
    this.options = options;
    this.init();
  }

  init() {
    this.setDefaultValues();
    this.bindEvents();
  }

  //############################################################################
  // SETUP DEFAULTS
  //############################################################################

  setDefaultValues() {
    this.setDefaultOptions();
    this.setContainerStyleValues();
    this.setBoundaryPoints();
    this.loopTimeoutId = null;
    this.looping = false;
  }

  setContainerStyleValues() {
    this.container.style.width = this.options.width + 'px';
    this.container.style.height = this.options.height + 'px';
    this.container.style.backgroundImage = 'url("' + this.options.image + '")';
    this.container.style.backgroundPosition = '0 0';
    this.container.style.backgroundSize = this.options.perRow * 100 + '%';
  }

  setDefaultOptions() {
    this.options.width = this.options.width || 300;
    this.options.height = this.options.height || 300;
    this.options.count = this.options.count || 0;
    this.options.perRow = this.options.perRow || 0;
    this.options.speed = this.options.speed || 100;
    this.options.dragTolerance = this.options.dragTolerance || 100;
    this.options.swipeTolerance = this.options.dragTolerance || 10;
    this.options.draggable = this.options.hasOwnProperty('draggable')
      ? this.options.draggable
      : true;
    this.options.swipeable = this.options.hasOwnProperty('swipeable')
      ? this.options.swipeable
      : true;
    this.options.loop = this.options.hasOwnProperty('loop')
      ? this.options.loop
      : true;
    this.options.keys = this.options.hasOwnProperty('keys')
      ? this.options.keys
      : true;
    this.options.prev = this.options.prev || false;
    this.options.next = this.options.next || false;
    this.options.inverted = this.options.inverted || false;
    this.options.visible = this.options.visible || false;
  }

  setBoundaryPoints() {
    this.boundaryPoints = {
      topLeft: { x: 0, y: 0 },
      topRight: { x: 0 + this.options.width, y: 0 },
      bottomLeft: { x: 0, y: 0 + this.options.height },
      bottomRight: { x: 0 + this.options.width, y: 0 + this.options.height },
    };
  }

  bindEvents() {
    if (this.options.draggable) {
      this.container.addEventListener('mousedown', this.onMousedown);
      document.addEventListener('mouseup', this.onMouseup);
      this.container.addEventListener('mousemove', this.onMousemove);
    }

    if (this.options.swipeable) {
      this.container.addEventListener('touchstart', this.onTouchstart);
      this.container.addEventListener('touchend', this.onTouchend);
      this.container.addEventListener('touchmove', this.onTouchmove);
    }

    if (this.options.keys) {
      document.addEventListener('keydown', this.onKeydown);
      document.addEventListener('keyup', this.onKeyup);
    }

    if (this.options.prev) {
      this.options.prev.addEventListener('mousedown', this.onPrevMousedown);
      this.options.prev.addEventListener('mouseup', this.onPrevMouseup);
      this.options.prev.addEventListener('touchstart', this.onPrevTouchstart);
    }

    if (this.options.next) {
      this.options.next.addEventListener('mousedown', this.onNextMousedown);
      this.options.next.addEventListener('mouseup', this.onNextMouseup);
      this.options.next.addEventListener('touchstart', this.onNextTouchstart);
    }
  }

  //############################################################################
  // EVENTS HANDLER
  //############################################################################

  onTouchstart = (e) => {
    this.dragOrigin = e.touches[0].clientX;
  };

  onTouchend = () => {
    this.dragOrigin = false;
  };

  onMousedown = (e) => {
    this.startCoordinates = { x: e.offsetX, y: e.offsetY };
    this.startingIndex = this.index;
    this.dragOrigin = e.pageX;
  };

  onMousemove = (e) => {
    if (this.options.original) {
      this.originalMousMoveHandler(e);
    } else {
      if (this.dragOrigin) {
        let endPoints = { x: e.offsetX, y: e.offsetY };

        let direction = this.findDirectionAndIntersectionPoint(
            this.startCoordinates,
            endPoints,
          ),
          coveredDistance =
            this.calculateDistanceBetween(this.startCoordinates, endPoints) -
            this.options.dragTolerance,
          newIndex = this.getNewIndex(direction, coveredDistance);

        coveredDistance = coveredDistance >= 0 ? coveredDistance : 0;
        if (coveredDistance) {
          if (this.options.vertical) {
            if (
              direction === 'verticalForward' ||
              direction === 'verticalBackward'
            ) {
              if (
                inRange(
                  this.index,
                  this.options.horizontalAxisInfo.min,
                  this.options.horizontalAxisInfo.max + 1,
                )
              ) {
                this.startingIndex = this.options.verticalAxisInfo.default;
                newIndex = this.startingIndex;
              } else if (newIndex < this.options.verticalAxisInfo.min) {
                newIndex = false;
              }
            } else if (
              direction === 'horizontalForward' ||
              direction === 'horizontalBackward'
            ) {
              if (
                inRange(
                  this.index,
                  this.options.verticalAxisInfo.min,
                  this.options.verticalAxisInfo.max + 1,
                )
              ) {
                this.startingIndex = this.options.horizontalAxisInfo.default;
                newIndex = this.startingIndex;
              } else if (newIndex > this.options.horizontalAxisInfo.max) {
                newIndex = false;
              }
            }
          }
          this.goTo(newIndex);
        }
      }
    }
  };

  originalMousMoveHandler(e) {
    if (
      this.dragOrigin &&
      Math.abs(this.dragOrigin - e.pageX) > this.options.dragTolerance
    ) {
      this.stop();
      this.dragOrigin > e.pageX ? this.prev() : this.next();
      this.dragOrigin = e.pageX;
    }
  }

  onTouchmove = (e) => {
    if (
      this.dragOrigin &&
      Math.abs(this.dragOrigin - e.touches[0].clientX) >
        this.options.swipeTolerance
    ) {
      this.stop();
      this.dragOrigin > e.touches[0].clientX ? this.prev() : this.next();
      this.dragOrigin = e.touches[0].clientX;
    }
  };

  onPrevMousedown = (e) => {
    e.preventDefault();
    this.play(true);
  };

  onPrevMouseup = (e) => {
    e.preventDefault();
    this.stop();
  };

  onPrevTouchstart = (e) => {
    e.preventDefault();
    this.prev();
  };

  onNextMousedown = (e) => {
    e.preventDefault();
    this.play();
  };

  onNextMouseup = (e) => {
    e.preventDefault();
    this.stop();
  };

  onNextTouchstart = (e) => {
    e.preventDefault();
    this.next();
  };

  onMouseup = () => {
    this.dragOrigin = false;
  };

  onKeydown = (e) => {
    if ([37, 39].includes(e.keyCode)) this.play(37 === e.keyCode);
  };

  onKeyup = (e) => {
    if ([37, 39].includes(e.keyCode)) this.stop();
  };

  //############################################################################
  // CORE METHODS
  //############################################################################

  next() {
    this.goTo(this.options.inverted ? this.index + 1 : this.index - 1);
    this.update();
  }

  prev() {
    this.goTo(this.options.inverted ? this.index - 1 : this.index + 1);
    this.update();
  }

  goTo(newIndex) {
    if (this.options.original) {
      this.originalGoTo(newIndex);
    } else {
      if ((newIndex || newIndex === 0) && newIndex >= 0 && isNumber(newIndex)) {
        if (this.options.loop) {
          newIndex = newIndex > this.options.count - 1 ? 0 : newIndex;
          this.index = newIndex < 0 ? this.options.count - 1 : newIndex;
        } else {
          // Added for preventing loop.
          newIndex =
            newIndex > this.options.count - 1
              ? this.options.count - 1
              : newIndex;
          this.index = newIndex < 0 ? 0 : newIndex;
        }

        this.update();
      }
    }

    //Method added to listen index update in react.
    if (this.options.onIndexUpdate) this.options.onIndexUpdate(this.index);
  }

  originalGoTo(newIndex) {
    newIndex = newIndex > this.options.count - 1 ? 0 : newIndex;
    this.index = newIndex < 0 ? this.options.count - 1 : newIndex;

    this.update();
  }

  update() {
    this.container.style.backgroundPositionX =
      -(this.index % this.options.perRow) * this.options.width + 'px';
    this.container.style.backgroundPositionY =
      -Math.floor(this.index / this.options.perRow) * this.options.height +
      'px';
  }

  play(reversed) {
    if (this.looping) return;

    this.loop(reversed);
    this.looping = true;
  }

  loop(reversed) {
    reversed ? this.prev() : this.next();
    this.loopTimeoutId = window.setTimeout(
      () => this.loop(reversed),
      this.options.speed,
    );
  }

  stop() {
    if (!this.looping) return;

    window.clearTimeout(this.loopTimeoutId);
    this.looping = false;
  }

  destroy() {
    this.stop();
    this.removeEvents();
    this.removeStyles();
  }

  //############################################################################
  // ADDITIONAL CUSTOM METHODS
  //############################################################################

  getNewIndex(direction, coveredDistance) {
    let totalDistance;
    switch (direction) {
      case 'horizontalForward':
        totalDistance =
          this.options.width -
          this.options.width * (this.startingIndex / this.options.count);
        return (
          Math.floor(
            ((this.options.count - this.startingIndex) * coveredDistance) /
              totalDistance,
          ) +
          this.startingIndex -
          1
        );
      case 'verticalForward':
        totalDistance =
          this.options.height -
          this.options.height * (this.startingIndex / this.options.count);
        return (
          Math.floor(
            ((this.options.count - this.startingIndex) * coveredDistance) /
              totalDistance,
          ) +
          this.startingIndex -
          1
        );
      case 'horizontalBackward':
        totalDistance =
          this.options.width * (this.startingIndex / this.options.count);
        return (
          this.startingIndex -
          Math.floor((this.startingIndex * coveredDistance) / totalDistance) -
          1
        );
      case 'verticalBackward':
        totalDistance =
          this.options.height * (this.startingIndex / this.options.count);
        return (
          this.startingIndex -
          Math.floor((this.startingIndex * coveredDistance) / totalDistance) -
          1
        );
      default:
        return null;
    }
  }

  findSlope(startPoints, endPoints) {
    // Formula Used -> slope=(y2-y1)/(x2-x1)
    return (endPoints.y - startPoints.y) / (endPoints.x - startPoints.x);
  }

  findDirectionAndIntersectionPoint(startPoints, endPoints) {
    let slope = this.findSlope(startPoints, endPoints),
      direction;
    this.intersectionPointWithBoundary = {};

    if (inRange(slope, 0, 1) || inRange(slope, 0, -1)) {
      if (endPoints.x > startPoints.x) {
        direction = 'horizontalForward';
        this.intersectionPointWithBoundary =
          this.calculateBoundaryIntersectionPoints(slope, startPoints, {
            x: this.boundaryPoints.topRight.x,
          });
      } else {
        direction = 'horizontalBackward';
        this.intersectionPointWithBoundary =
          this.calculateBoundaryIntersectionPoints(slope, startPoints, {
            x: this.boundaryPoints.topLeft.x,
          });
      }
    } else if (
      inRange(slope, -1, -Infinity) ||
      slope === -Infinity ||
      inRange(slope, 1, Infinity) ||
      slope === Infinity
    ) {
      if (endPoints.y < startPoints.y) {
        direction = 'verticalForward';
        this.intersectionPointWithBoundary =
          this.calculateBoundaryIntersectionPoints(slope, startPoints, {
            y: this.boundaryPoints.topLeft.y,
          });
      } else {
        direction = 'verticalBackward';
        this.intersectionPointWithBoundary =
          this.calculateBoundaryIntersectionPoints(slope, startPoints, {
            y: this.boundaryPoints.bottomLeft.y,
          });
      }
    }
    return direction;
  }

  calculateDistanceBetween(point1, point2) {
    // Formula Used -> (((y2-y1)^2) + (x2-x1)^2)^0.5
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
    );
  }

  calculateBoundaryIntersectionPoints(slope, point1, interectionPoint) {
    // Formula Used -> y-y1 = slope*(x-x1);

    if (interectionPoint.x === 0 || interectionPoint.x) {
      interectionPoint.y = slope * (interectionPoint.x - point1.x) + point1.y;
    } else {
      interectionPoint.x =
        Math.floor((interectionPoint.y - point1.y) / slope) + point1.x;
    }
    return interectionPoint;
  }

  removeEvents() {
    if (this.options.draggable) {
      this.container.removeEventListener('mousedown', this.onMousedown);
      document.removeEventListener('mouseup', this.onMouseup);
      this.container.removeEventListener('mousemove', this.onMousemove);
    }

    if (this.options.swipeable) {
      this.container.removeEventListener('touchstart', this.onTouchstart);
      this.container.removeEventListener('touchend', this.onTouchend);
      this.container.removeEventListener('touchmove', this.onTouchmove);
    }

    if (this.options.keys) {
      document.removeEventListener('keydown', this.onKeydown);
      document.removeEventListener('keyup', this.onKeyup);
    }

    if (this.options.prev) {
      this.options.prev.removeEventListener('mousedown', this.onPrevMousedown);
      this.options.prev.removeEventListener('mouseup', this.onPrevMouseup);
      this.options.prev.removeEventListener(
        'touchstart',
        this.onPrevTouchstart,
      );
    }

    if (this.options.next) {
      this.options.next.removeEventListener('mousedown', this.onNextMousedown);
      this.options.next.removeEventListener('mouseup', this.onNextMouseup);
      this.options.next.removeEventListener(
        'touchstart',
        this.onNextTouchstart,
      );
    }
  }

  removeStyles() {
    this.container.style.width = '';
    this.container.style.height = '';
    this.container.style.backgroundImage = '';
    this.container.style.backgroundPosition = '';
    this.container.style.backgroundSize = '';
  }
}
