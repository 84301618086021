import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "app/actions/authentication";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { withRouter } from 'app/components/HOC/Router/withRouter';


function Logout(props) {
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.currentUser)
  const removeHashFromUrl = () => navigate(window.location.pathname);

  useEffect(() => {
    if (window.location.hash) removeHashFromUrl();
    if (currentUser.token) setTimeout(props.logout, 100);
  }, [])

  return <div></div>;
}

Logout = connect(({ }) => ({}), { logout })(Logout);
Logout = withRouter(Logout);


export default Logout;