import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      fill='#E51919'
      d='M12.083,21c0,0,3.839-2.677,7.794-7.428c4.268-5.111,1.646-9.659-1.983-10.367
  c-3.261-0.636-5.8,2.317-5.8,2.317S9.438,2.451,6.179,3.087C2.56,3.794-0.062,8.342,4.207,13.465C8.162,18.217,12.083,21,12.083,21z
  '
    />
  </svg>
);

export default SVG;
