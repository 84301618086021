import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CareViewNoRecordsPresent from './CareViewNoRecordsPresent';
import MultipleCareSpacesRoutes from './MultipleCareSpacesRoutes';
import SingleCareSpaceRoutes from './SingleCareSpaceRoutes';

class ExploreView extends PureComponent {
  render() {
    const user = this.props.currentUser.graph;
    const { care_spaces_count, visible_care_spaces_count } = user;
    if (!!care_spaces_count || !!visible_care_spaces_count) {
      if (visible_care_spaces_count > 1) {
        return <MultipleCareSpacesRoutes />;
      } else if (
        visible_care_spaces_count === 1 ||
        (!visible_care_spaces_count && !!care_spaces_count)
      ) {
        return (
          <SingleCareSpaceRoutes
            primarySpace={user.primary_shared_care_space}
          />
        );
      } else {
        return <CareViewNoRecordsPresent />;
      }
    } else {
      return <CareViewNoRecordsPresent />;
    }
  }
}
ExploreView = connect(({ currentUser }) => ({ currentUser }), {})(ExploreView);
export default ExploreView;
