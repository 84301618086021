import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Accordion } from 'react-bootstrap';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

import PopularHashTagsModal from 'app/components/Shared/PopularHashTagsModal';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import withAuthorize from 'app/components/HOC/withAuthorize';

import { getAuthorizeObject, hashTagUrl } from 'app/utils/entitiesHelper';
import PopularTopics from 'app/components/Shared/PopularTopics';

class PopularHashTags extends Component {
  state = {
    collapsed: !!this.props.entity && isWorkspaceView(),
    showModal: false,
  };

  getEntity() {
    return this.props.entity || this.props.workspace.data;
  }

  setCollectionTotal = (count) => {
    this.setState({ count });
  };

  selectedHashTag() {
    return this.props.entity
      ? this.props.match.params.additionalFilter
      : this.props.match.params.filterBy;
  }

  isTagActive(tag) {
    return (
      this.selectedHashTag() &&
      this.selectedHashTag().toLowerCase() === tag.name.toLowerCase()
    );
  }

  setSelectedTag(tag) {
    this.props.navigate(
      hashTagUrl(this.isTagActive(tag) ? '' : tag.name, this.props.entity),
    );
  }

  openModal = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  renderWidgetHeader() {
    return (
      <Accordion.Header className='rt-ac-header widget-title' key=''>
        <span>Popular Tags</span>
        <OsBtn
          name='BtnIcon'
          icon='expand-2'
          extraClass='no-text tag-view-more pointer fs-12'
          onClick={this.openModal}
        />
      </Accordion.Header>
    );
  }

  render() {
    return (
      <>
        <OsGrid identifier='DetailRightSidebar:PopularHashTagBlock'>
          {/* <Accordion defaultActiveKey={this.state.collapsed ? "1" : "0"} className={`right-ac ${!this.state.count ? 'd-none' : ''} sidebar-container`}> */}
          <Accordion
            defaultActiveKey={'0'}
            className={`right-ac ${
              !this.state.count ? 'd-none' : ''
            } sidebar-container`}>
            <Accordion.Item eventKey='1' className='popular-topics'>
              {this.renderWidgetHeader()}
              <Accordion.Body>
                <PopularTopics
                  setCollectionTotal={this.setCollectionTotal}
                  entity={this.props.entity}
                  pageSource={this.props.source}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </OsGrid>
        {this.state.showModal && (
          <PopularHashTagsModal
            show={this.state.showModal}
            closeModal={this.closeModal}
            modalBodyClassName='modal-body'
            containerClass='modal-body-container'
            disabled={!this.state.selectedTag}
            setCollectionTotal={this.setCollectionTotal}
            entity={this.props.entity}
            pageSource={this.props.source}
          />
        )}
      </>
    );
  }
}

PopularHashTags = connect(({ currentUser, workspace }) => ({
  currentUser,
  workspace,
}))(PopularHashTags);
PopularHashTags = withAuthorize(PopularHashTags);
PopularHashTags = withRouter(PopularHashTags);
PopularHashTags.defaultProps = {
  abilityObject: getAuthorizeObject('index', 'explore'),
};
export default PopularHashTags;
