import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'app/utils/osLodash';
import { mergeWorkspaceData } from 'app/actions/workspace';

import {
  getWorkspaceEasyRXLinked,
  getWorkspaceNiceId,
} from 'app/selectors/workspace';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import Loader from 'react-loaders';

const UPDATE_EASY_RX_TOKEN = queryLoader(
  'app/graphql/mutations/Workspace/UpdateEasyRXToken.gql',
);

const withEasyRXAuthenticate = (Component) => {
  const WrappedComponent = (props) => {
    const dispatch = useDispatch();
    const [searcParams] = useSearchParams();
    const queryRefreshToken = searcParams.get('token');
    const navigate = useNavigate();
    const easyRxLinked = useSelector(getWorkspaceEasyRXLinked);
    const refreshToken = queryRefreshToken;
    const workspaceNiceId = useSelector(getWorkspaceNiceId);
    const [updateEasyRxToken, { data, loading }] =
      useMutation(UPDATE_EASY_RX_TOKEN);

    useEffect(() => {
      if (!loading && !isEmpty(data)) {
        dispatch(mergeWorkspaceData(data.setEasyRxRefreshToken.entity));
      }
    }, [data, loading]);

    useEffect(() => {
      if (workspaceNiceId && !easyRxLinked && !refreshToken)
        navigate(withWorkspaceIdentifier('/easy-rx/setup'));
    }, [navigate, workspaceNiceId, easyRxLinked, refreshToken]);

    useEffect(() => {
      if (workspaceNiceId && refreshToken && !easyRxLinked) {
        updateEasyRxToken({
          variables: {
            token: refreshToken,
          },
        });
      }
    }, [workspaceNiceId, refreshToken, navigate, easyRxLinked]);

    if (easyRxLinked) {
      return <Component />;
    } else {
      return (
        <Loader type='ball-triangle-path' active className='full-wh-loader' />
      );
    }
  };

  return WrappedComponent;
};

export default withEasyRXAuthenticate;
