import NotificationCardLayout from './NotificationCardLayout';

class AccountActivatedNotification extends NotificationCardLayout {
  renderAvatarCollection() {
    let { objects } = this.props;
    return objects && this.renderAvatar(objects[0]);
  }
}

export default AccountActivatedNotification;
