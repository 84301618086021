import React from 'react';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { humanize } from 'app/utils/stringHelper';
import ResetPasswordMailSent from 'app/components/LoginView/ResetPasswordMailSent';

const CHECK_USER_EXISTS_QUERY = queryLoader('app/graphql/CheckUserExists.gql');

const asyncValidate = (values, field) => {
  // ToDo: Check quick scenario
  if (field === 'email') {
    let value = values[field];
    return client
      .query({
        query: CHECK_USER_EXISTS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          attribute: field,
          value,
        },
      })
      .then(({ data: { user_exists } }) => {
        if (user_exists.success) {
          var returnErrors = {};
          if (field === 'email') {
            if (user_exists.error === 'preverified') {
              returnErrors[field] = {
                errorKey: 'PREVERIFIED_USER_ONBOARDING',
                interpolations: {
                  reset_mail: (
                    <ResetPasswordMailSent isPreVerified={true} email={value} />
                  ),
                },
              };
            } else {
              returnErrors[field] = {
                errorKey: 'NOT_PREVERIFIED_USER_ONBOARDING',
                interpolations: {
                  reset_mail: <ResetPasswordMailSent email={value} />,
                },
              };
            }
            return returnErrors[field];
          }
          throw returnErrors;
        }
      });
  } else {
    // Hack for asynvalidate called without field
    return new Promise(function (resolve) {
      setTimeout(resolve, 100);
    });
  }
};

export default asyncValidate;
