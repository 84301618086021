const DisplayMetricCard = (props) => {
  const { cardHeading, metric, unit, cardKey } = props;

  const TaskInfo = () => {
    if (props.totalOverdueTasks > 0) {
      return `(${props.totalOverdueTasks} overdue)`;
    } else if (props.taskToComplete > 0) {
      return `(${props.taskToComplete} pending this week)`;
    } else {
      return null;
    }
  };
  return (
    <>
      <div className={'metric-card-container'}>
        <div className='card-header'>
          <span className='card-icon'></span>
          <h3 className='card-heading'>{cardHeading}</h3>
        </div>
        <div className='card-metric'>
          <span className='metric-value'>{metric}</span>
          {unit && <span className='metric-unit'>{unit}</span>}
          {cardKey === 'messages' && (
            <span className={'messages'}>
              <span className='metric-extra-info'>
                ({props.lastWeek} last week)
              </span>
            </span>
          )}
          {cardKey === 'tasks' && (
            <span className={'tasks'}>
              <span className='metric-extra-info'>{TaskInfo()}</span>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default DisplayMetricCard;
