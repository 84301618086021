import { OPEN_UPGRADE_MODAL, CLOSE_UPGRADE_MODAL } from './types';

export function openUpgradeModal(source = '', meta = {}) {
  return {
    type: OPEN_UPGRADE_MODAL,
    source,
    meta,
  };
}

export function closeUpgradeModal(reset = true) {
  return {
    type: CLOSE_UPGRADE_MODAL,
    reset,
  };
}
