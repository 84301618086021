import { useSelector } from 'react-redux';
import { openInfoModal } from 'app/actions/infoModal';
import { useDispatch } from 'react-redux';

import { getWorkspaceFeatured } from 'app/selectors/workspace';
const useClinicSubscribedAccess = () => {
  const dispatch = useDispatch();
  const isFeatured = useSelector(getWorkspaceFeatured);
  const openSubscriptionModalIfNotSubscribed = (callback = () => {}) => {
    if (isFeatured) {
      callback();
    } else {
      dispatch(
        openInfoModal('space', 'non_subscribed_workspace', {
          onSuccess: () => {},
        }),
      );
    }
  };

  return {
    openSubscriptionModalIfNotSubscribed,
  };
};

export default useClinicSubscribedAccess;
