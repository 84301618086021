import OsBtn from 'app/components/OsBtn';

const DisplayFormButtons = ({ handleSubmit, closeModal }) => {
  let commonProps = {
    htmlTag: 'button',
    extraClass: 'web-view-btn btn-save-info',
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <OsBtn
          type='button'
          name='BtnSecondary'
          text='Cancel'
          onClick={closeModal}
          {...commonProps}
        />
        <OsBtn
          type='button'
          name='BtnPrimary'
          onClick={handleSubmit}
          text='Invite'
          {...commonProps}
        />
      </div>
    </>
  );
};

export default DisplayFormButtons;
