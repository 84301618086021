import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M2.508 19.99V4.01c0-.833.67-1.502 1.502-1.502h15.98c.833 0 1.502.67 1.502 1.502v15.98c0 .833-.67 1.502-1.502 1.502H4.01a1.505 1.505 0 0 1-1.502-1.502z'
      fill='#4781F5'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.01 3.017a.989.989 0 0 0-.993.993v15.98c0 .55.45.993.993.993h15.98a.989.989 0 0 0 .993-.993V4.01a.989.989 0 0 0-.993-.993H4.01zM2 4.01C2 2.896 2.896 2 4.01 2h15.98C21.104 2 22 2.896 22 4.01v15.98c0 1.114-.896 2.01-2.01 2.01H4.01C2.91 22 2 21.106 2 19.99V4.01z'
      fill='#4781F5'
    />
    <path
      d='M15.113 14.817S11.397 18.43 8 13.947a.217.217 0 0 0-.245-.074l-1.495.643c-.129.054-.182.204-.107.311.8 1.148 4.922 6.34 10.88 1.116M14.152 13.97l3.94 2.649c.139.085.32 0 .33-.161l.086-1.352.225-3.4c.01-.16-.16-.279-.3-.204l-4.25 2.103a.213.213 0 0 0-.031.364zM8.898 9.186s3.716-3.615 7.112.868c.053.075.16.108.245.075l1.495-.643a.208.208 0 0 0 .096-.311c-.8-1.158-4.912-6.339-10.87-1.116M9.86 10.033l-3.952-2.65c-.139-.085-.32 0-.33.162l-.076 1.351-.234 3.4c-.011.161.16.268.298.204l4.261-2.102c.15-.065.16-.268.032-.365z'
      fill='#F7F7F8'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M14.448 5.977c1.944.817 3.208 2.453 3.618 3.045a.476.476 0 0 1-.212.71l-.001.001-1.504.648a.484.484 0 0 1-.554-.167c-.823-1.086-1.65-1.663-2.418-1.934a3.624 3.624 0 0 0-2.131-.081 5.133 5.133 0 0 0-1.566.715 5.388 5.388 0 0 0-.476.357l.816.547c.265.2.279.662-.08.823l-4.262 2.102a.48.48 0 0 1-.677-.465l.235-3.399.075-1.352c.025-.383.445-.552.737-.372l.009.006.872.585c1.47-1.257 2.842-1.908 4.098-2.142a5.895 5.895 0 0 1 3.421.373zM7.391 8.056l1.353.907a2.587 2.587 0 0 1 .15-.13c.114-.095.28-.225.49-.365a5.664 5.664 0 0 1 1.731-.79 4.155 4.155 0 0 1 2.44.096c.876.31 1.777.953 2.644 2.088l1.385-.596c-.431-.609-1.602-2.064-3.342-2.795a5.364 5.364 0 0 0-3.118-.34c-1.114.208-2.365.781-3.733 1.925zm-1.553-.397-.07 1.256-.227 3.299 4.084-2.016L5.838 7.66zM19 11.724c.023-.35-.348-.64-.687-.46l.687.46zm0 0-.224 3.4-.086 1.35c-.025.383-.445.554-.737.373l-.009-.006-.865-.582c-1.468 1.256-2.84 1.905-4.096 2.138a5.91 5.91 0 0 1-3.425-.375c-1.946-.819-3.213-2.454-3.622-3.04-.189-.272-.035-.604.221-.713l1.506-.648a.484.484 0 0 1 .553.167c.824 1.086 1.651 1.663 2.418 1.934a3.623 3.623 0 0 0 2.132.082 5.133 5.133 0 0 0 1.566-.716 5.377 5.377 0 0 0 .475-.356l-.815-.548a.482.482 0 0 1 .073-.82h.002l4.246-2.1m-3.047 3.777-.016.014a5.92 5.92 0 0 1-.622.48 5.664 5.664 0 0 1-1.731.788 4.155 4.155 0 0 1-2.44-.095c-.876-.31-1.777-.952-2.644-2.088l-1.393.6c.434.607 1.607 2.056 3.344 2.787a5.379 5.379 0 0 0 3.122.343c1.115-.207 2.365-.78 3.731-1.921l-1.35-.909zm3.195-3.253-4.075 2.016 3.778 2.54.079-1.255.218-3.3z'
      fill='#F7F7F8'
    />
  </svg>
);

export default SVG;
