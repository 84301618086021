import {
  OPEN_DISABLE_CARE_SPACE_MODAL,
  CLOSE_DISABLE_CARE_SPACE_MODAL,
} from 'app/actions/types';

export default function disableCareSpaceModal(
  state = { open: false, obj: {} },
  action,
) {
  switch (action.type) {
    case OPEN_DISABLE_CARE_SPACE_MODAL:
      return { open: true, obj: action.obj };
    case CLOSE_DISABLE_CARE_SPACE_MODAL:
      return { open: false, obj: {} };
    default:
      return state;
  }
}
