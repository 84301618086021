import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { updateCurrentUserGraph, logout } from 'app/actions/authentication';
import { getUnreadNotificationCount } from 'app/utils/userHelper';
import { humanizeCount } from 'app/utils/textFormatting';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import NotificationChannel from 'app/channels/NotificationChannel';

import NavigationNotificationDropdown from 'app/components/NotificationsView/NavigationNotificationDropdown';

class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.toggleDropdownState = this.toggleDropdownState.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = {
      notificationDropdown: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('touchend', this.handleOutsideClick, false);
    NotificationChannel.setup();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchend', this.handleOutsideClick, false);
    NotificationChannel.delete();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.key !== prevProps.location.key &&
      this.state.notificationDropdown
    )
      this.closeDropdown();
  }

  currentUser() {
    return this.props.currentUser.graph;
  }

  toggleDropdownState() {
    if (!this.state.notificationDropdown) {
      this.setState({ notificationDropdown: true });
    } else {
      this.setState({ notificationDropdown: false });
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    //only click handle if dropdown is visible, so won't open dropdown if clicks are outside
    if (
      this.state.notificationDropdown &&
      !e.target.classList.contains('do-not-close-quick-view')
    )
      this.closeDropdown();
  }

  closeDropdown = () => {
    this.setState({ notificationDropdown: false });
  };

  renderNotificationIcon() {
    return (
      <div
        className='dropdown-toggle'
        onClick={this.toggleDropdownState}
        title='Notifications'>
        <OrthoIcon name='notification' dataHoverNotRequired={true} />
        {this.renderNotificationCount()}
      </div>
    );
  }

  isUnreadNotificationPresent() {
    return this.currentUser() && this.getUnreadNotificationCount() > 0;
  }

  getUnreadNotificationCount() {
    return getUnreadNotificationCount(this.currentUser());
  }

  renderNotificationCount() {
    if (this.isUnreadNotificationPresent())
      return (
        <span className='notifications-count'>
          {humanizeCount(this.getUnreadNotificationCount())}
        </span>
      );
  }

  renderNotificationsContainer() {
    return (
      <NavigationNotificationDropdown
        closeDropdown={this.closeDropdown}
        refetchData={this.state.notificationDropdown}
      />
    );
  }

  renderNotificationContainer() {
    return <div>{this.renderNotificationsContainer()}</div>;
  }

  render() {
    if (this.props.device.mobileDevice) {
      return <div />;
    } else {
      return (
        <div
          className={`nav-item dropdown notification-dropdown ${
            this.state.notificationDropdown ? 'active' : ''
          }`}
          ref={(node) => {
            this.node = node;
          }}>
          <div className='empty-class'>{this.renderNotificationIcon()}</div>
          <div
            className={`dropdown-menu ${
              this.state.notificationDropdown ? 'show' : ''
            }`}>
            {this.state.notificationDropdown &&
              this.renderNotificationContainer()}
          </div>
        </div>
      );
    }
  }
}

NotificationBar = withRouter(NotificationBar);
NotificationBar = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { logout, updateCurrentUserGraph },
)(NotificationBar);
export default NotificationBar;
