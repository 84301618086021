import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { Form, Field } from 'react-final-form';
import {
  composeValidators,
  fieldRequired,
  emailMustBeValid,
  passwordMustMatchValidityRules,
} from 'app/utils/validationHelper';
import { loader as queryLoader } from 'graphql.macro';
import { translate } from 'app/actions/flashMessage';

import ConnectToSupport from 'app/components/ConnectToSupport';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import Loader from 'react-loaders';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import PolicyAcceptCheckbox from 'app/components/PoliciesView/PolicyAcceptCheckbox';

import { getDevice } from 'app/selectors/device';
import {
  getWorkspaceGuestOwnerData,
  getWorkspaceIdentifer,
} from 'app/selectors/workspace';
import { login } from 'app/actions/authentication';

const CARE_ONBOARD_MUTATION = queryLoader(
  'app/graphql/mutations/Care/Onboard.gql',
);
const SHARE_REQUEST_TOKEN_QUERY = queryLoader(
  'app/graphql/Care/Queries/People/ShareRequest.gql',
);

const OnboardView = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [showPolicyCheckboxError, setPolicyCheckboxError] = useState(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const workspaceIdentifier = useSelector(getWorkspaceIdentifer);
  const guestOwnerData = useSelector(getWorkspaceGuestOwnerData);
  const device = useSelector(getDevice);
  const { data, loading } = useQuery(SHARE_REQUEST_TOKEN_QUERY, {
    variables: { token },
    fetchPolicy: 'network-only',
  });
  const [careOnboardMutation, { loading: requestInProgress }] = useMutation(
    CARE_ONBOARD_MUTATION,
  );
  const { email } = data?.share_request || {};

  const isEmailPresent = !!email;
  const renderCredentialsForm = () => {
    let formGroupExtraClass = 'login-email-group ';
    formGroupExtraClass = isEmailPresent ? 'has-disabled-input' : '';
    if (loading) return <Loader type='ball-triangle-path' active />;
    if (!token) return <Navigate to='/login' />;
    return (
      <Form
        onSubmit={submitForm}
        render={(props) => {
          return (
            <form name='signup' onSubmit={props.handleSubmit}>
              <div>
                <div className='text-center form-heading login-form-heading'>
                  Sign up
                </div>
                <div className='col-md-12'>
                  <Field
                    name='email'
                    type='email'
                    osType='input'
                    component={OsField}
                    label='Email*'
                    formGroupExtraClass={formGroupExtraClass}
                    initialValue={email}
                    disabled={isEmailPresent}
                    validate={composeValidators(
                      fieldRequired,
                      emailMustBeValid,
                    )}
                  />
                  {isEmailPresent && (
                    <div className='prefilled-email'>
                      <OrthoIcon
                        name='error'
                        defaultClass='me-1'
                        dataHoverNotRequired={true}
                      />
                      {translate(
                        'CARE_SPACE_PREFILLED_EMAIL_TEXT',
                        {
                          contact_team: (
                            <ConnectToSupport
                              text='contact clinic team'
                              extraClass='fs-12'
                            />
                          ),
                        },
                        false,
                      )}
                    </div>
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      name='firstName'
                      type='text'
                      osType='input'
                      component={OsField}
                      label='First Name*'
                      validate={fieldRequired}
                    />
                  </div>

                  <div className='col-md-6'>
                    <Field
                      name='lastName'
                      type='text'
                      osType='input'
                      component={OsField}
                      label='Last Name*'
                      validate={fieldRequired}
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <Field
                    name='password'
                    component={OsField}
                    osType='password'
                    label='New password'
                    hintRequired={true}
                    onFocus={clearError}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatchValidityRules,
                    )}
                    formGroupExtraClass='login_password reset_login_password required-label'>
                    <span className='reset-password-hint'>
                      {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                    </span>
                  </Field>
                </div>
                <div className='col-md-12'>
                  <PolicyAcceptCheckbox
                    name='policy'
                    togglePoliciesAccepted={togglePoliciesAccepted}
                    validate={fieldRequired}>
                    {(showPolicyCheckboxError ||
                      (props.touched.policy && props.errors.policy)) && (
                      <div className='with-error'>
                        <span className='form-error'>
                          {' '}
                          <OrthoIcon
                            name='error'
                            dataHoverNotRequired='true'
                            defaultClass='ms-3 me-1 cl-red'
                          />{' '}
                          Must be checked
                        </span>
                      </div>
                    )}
                  </PolicyAcceptCheckbox>
                </div>
              </div>

              <div className='text-center'>
                <OsBtn
                  name='BtnPrimary'
                  htmlTag='button'
                  text='sign up'
                  extraClass='btn__referral_signup web-view-btn'
                  type='submit'
                  disabled={requestInProgress}
                  loaderRequired={requestInProgress}
                />
              </div>

              <div
                className={`text-center ${
                  device.mobileDevice
                    ? 'mobile-signup-footer'
                    : 'hide-modal-only mt-4 pt-3'
                }`}>
                <p className='mb-2 me-1 os-caf'>Already have an account?</p>
                <OsLink
                  text='Log In'
                  className='semibold'
                  to={`/${workspaceIdentifier}/login`}
                />
              </div>
            </form>
          );
        }}
      />
    );
  };

  const togglePoliciesAccepted = ({ target }) => {
    setIsPolicyChecked(target.checked);
    setPolicyCheckboxError(!target.checked);
  };

  const clearError = () => {
    // this.setState({ error: null, emailError: null });
  };

  const submitForm = (values) => {
    if (isPolicyChecked) {
      onSubmit(values);
    } else {
      setPolicyCheckboxError();
    }
  };

  const onSubmit = (values) => {
    return careOnboardMutation({
      variables: {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        token,
      },
    }).then(({ data: { careOnboard } }) => {
      if (careOnboard.errors) {
        let errors = {};
        errors[careOnboard.errors[0].field] = careOnboard.errors[0].message;
        return { ...errors };
      } else {
        let variables = {
          token: careOnboard.token,
          remember: false,
          email: careOnboard?.entity.email,
          user: careOnboard?.entity,
        };
        dispatch(login(variables));
      }
    });
  };

  const renderWorkspaceWelcomeText = () => {
    if (guestOwnerData) {
      return (
        <div className='ws-auth-info-area'>
          <div className='auth-heading'>Committed to Better Care, Together</div>
          <div
            className='patient-wel-text'
            dangerouslySetInnerHTML={{
              __html: translate('WELCOME_TEXT_CARESPACE_WITH_OWNER', {
                clinic_name: guestOwnerData.name,
              }),
            }}
          />
        </div>
      );
    } else {
      return translate('WELCOME_TEXT_CARESPACE');
    }
  };

  if (!token || (!loading && !data?.share_request))
    return <Navigate to='/login' />;

  return (
    <ImageFormLayout
      leftBar={renderWorkspaceWelcomeText()}
      rightBar={renderCredentialsForm()}
      source='care_signup'
    />
  );
};
export default OnboardView;
