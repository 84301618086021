import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useContext } from 'react';
import { IntegrationConfigContext } from 'app/components/WorkspaceView/SettingView/IntegrationConfig/index';

const ADD_DOMAIN = queryLoader(
  'app/graphql/mutations/Integration/AddDomain.gql',
);

const useAddDomain = () => {
  const [addDomain] = useMutation(ADD_DOMAIN);
  const { setHostname } = useContext(IntegrationConfigContext);

  const addDomainToWebsite = async (hostname, enabled) => {
    const response = await addDomain({
      variables: {
        hostname,
        enabled,
      },
    });
    if (response.data.success) {
      setHostname('');
    }
  };

  return {
    addDomainToWebsite,
  };
};

export default useAddDomain;
