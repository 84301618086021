import React, { PureComponent } from 'react';

import DisplayLectureContent from './DisplayLectureContent';
import SpaceHeaderCaseLectureView from './SpaceHeaderCaseLectureView';
import SpaceHeroImage from './SpaceHeroImage';
import VideoPlayer from 'app/components/VideoPlayer';

import { isEmpty } from 'app/utils/osLodash';
import { isAudio } from 'app/utils/fileHelper';
import { clvViewRequired } from 'app/components/NavTabs/helper';
import { caseAsSpaceTitle } from 'app/utils/spaceHelper';

class SpaceHeader extends PureComponent {
  getSpace() {
    return this.props.space;
  }

  renderCLVContainer(space) {
    return <DisplayLectureContent space={space} />;
  }

  renderSpaceCaseLectureView(space) {
    return (
      <div className=''>
        <SpaceHeaderCaseLectureView
          kase={space.space_banner.case}
          spaceBannerTitle={caseAsSpaceTitle(space)}
        />
      </div>
    );
  }

  renderHeaderContent() {
    let space = this.getSpace(),
      spaceBanner = space.space_banner;
    if (clvViewRequired(space)) {
      return this.renderCLVContainer(space);
    } else if (!isEmpty(spaceBanner?.case)) {
      return this.renderSpaceCaseLectureView(space);
    } else if (!isEmpty(spaceBanner?.video_attachment)) {
      let video = spaceBanner.video_attachment;
      return (
        <div className=''>
          <VideoPlayer
            key={video.id}
            hlsOptions={{ url: video.adaptive_streaming_preview_url }}
            video={{
              url: video.preview_url,
              containerClassName: 'full-width',
              autoplay: true,
              nodownload: true,
              unmuted: true,
              poster: video.thumb_url,
              audio: isAudio(video),
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <SpaceHeroImage space={space} />
        </div>
      );
    }
  }

  render() {
    return this.renderHeaderContent();
  }
}

export default SpaceHeader;
