import React, { Component } from 'react';
import { connect } from 'react-redux';

import AttachmentPreview from 'app/components/AttachmentPreview';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import ParseText from 'app/components/ParseText';

import { isCareSpace } from 'app/utils/spaceHelper';
import {
  entityIsAdditionalLink,
  sortFilesAndGetAttachment,
  entityIsCase,
} from 'app/utils/entitiesHelper';
import { isLoomOrYoutubeUrl } from "app/utils/fileHelper"

const FILES_IN_SLIDER = ['image', 'video'];
const WORDS_TRUNCATE_COUNT = 150;

class UserContentDisplay extends Component {
  feedsView() {
    return this.props.source === 'feeds-view';
  }

  postDetailView() {
    return this.props.source === 'post-detail';
  }

  commentsView() {
    return this.props.source === 'comments-section';
  }

  author() {
    return this.props.object.author;
  }

  commentFiles() {
    return sortFilesAndGetAttachment(this.props.object.files);
  }

  getCommentSliderObjects() {
    let comment = this.props.object;
    let playableLinks = comment.links.filter((link) => isLoomOrYoutubeUrl(link.url));
    if (comment.images_and_videos) {
      return [...comment.images_and_videos, ...playableLinks];
    } else {
      let allFiles = this.commentFiles().filter(
        (file) => file && FILES_IN_SLIDER.includes(file.kind),
      );
      return [...allFiles, ...playableLinks];
    }
  }

  getCommentAttachmemtObjects() {
    return this.commentFiles();
  }

  sliderObjects() {
    let object = this.props.object;
    switch (object.__typename) {
      case 'Pulse':
        return object.images_and_videos;
      case 'Comment':
        return this.getCommentSliderObjects();
      default:
        return null;
    }
  }

  attachmentObjects() {
    let object = this.props.object;
    switch (object.__typename) {
      case 'Pulse':
        return object.attachments;
      case 'Comment':
        return this.getCommentAttachmemtObjects();
      default:
        return null;
    }
  }

  entityObjects() {
    return this.props.object.links || [];
  }

  cardSize() {
    return 'extra-small';
  }

  isUserDetailTimeline() {
    return ['MyActivities', 'NewUserActivities', 'UserActivities'].includes(
      this.props.listType,
    );
  }

  isCaseInCareSpace(obj) {
    return (
      this.commentsView() &&
      isCareSpace(this.props.parentEntity) &&
      ((entityIsAdditionalLink(obj) && entityIsCase(obj.entity)) ||
        entityIsCase(obj))
    );
  }

  renderOsCard(entity) {
    return (
      <OsCards
        size={this.cardSize()}
        obj={entity}
        sliderPreview={true}
        attachments={this.attachmentObjects()}
        source={this.props.source}
      />
    );
  }

  getGridIdentifier() {
    return this.commentsView() || this.isUserDetailTimeline()
      ? 'TimelineSpaceCommentCardColumn:Cardcols'
      : 'FeedCardColumn:Cardcols';
  }

  renderEntity = (entity) => {
    return (
      <OsGrid
        identifier={this.getGridIdentifier()}
        key={`${entity.__typename}:${entity.id}`}>
        {this.renderOsCard(entity)}
      </OsGrid>
    );
  };

  renderEntities() {
    return this.entityObjects().map(this.renderEntity);
  }

  renderAttachment = (object) => {
    let attachment = {
      ...object,
      author: this.author(),
    };

    return (
      <OsGrid
        identifier={this.getGridIdentifier()}
        key={`${attachment.__typename}:${attachment.id}`}>
        {this.renderOsCard(attachment)}
      </OsGrid>
    );
  };

  renderAttachments() {
    return this.attachmentObjects().map(this.renderAttachment);
  }

  renderAttachmentsAndEntities() {
    let objectsCount =
      this.attachmentObjects().length + this.entityObjects().length;

    if (objectsCount > 0)
      return (
        <div className='order-mobile row card-row-pulse row-col-space-16'>
          {this.renderAttachments()}
          {this.renderEntities()}
        </div>
      );
  }

  getParseTextClass() {
    let className = 'mb-0 ';
    className += this.commentsView() ? 'comment-text-helper' : '';
    return className;
  }

  renderContent() {
    let { object } = this.props,
      props = {
        className: this.getParseTextClass(),
        obj: object,
        parentEntity: this.props.parentEntity,
        ...this.props.parseTextProps,
      };
    if (this.feedsView())
      props = {
        ...props,
        readMoreRequired: true,
        truncateWordsUpto: WORDS_TRUNCATE_COUNT,
      };

    if (object.content && object.content.trim().length)
      return (
        <div className='w-100 feed-card-content order-mobile mt-3'>
          <ParseText {...props} />
        </div>
      );
  }

  renderSlider() {
    let withLessFeatures = this.feedsView() || this.commentsView(),
      props = {
        objects: this.sliderObjects(),
        author: this.author(),
        navigationSettings:
          withLessFeatures || this.props.device.mobileDevice
            ? { arrows: false, dots: true, dotsClass: 'pulse-slick-dots' }
            : {},
        subSliderNotRequired: withLessFeatures,
        containerClassName: withLessFeatures
          ? 'listing-slider'
          : 'detail-slider',
        sliderFocusRequired: !withLessFeatures,
        autoplayNotRequired: withLessFeatures,
        source: this.props.source,
      };

    return <AttachmentPreview {...props} />;
  }

  render() {
    return (
      <section className={this.props.source}>
        {this.renderSlider()}
        {this.props.children}
        {this.renderContent()}
        {this.renderAttachmentsAndEntities()}
      </section>
    );
  }
}

UserContentDisplay = connect(
  ({ device }) => ({ device }),
  {},
)(UserContentDisplay);
UserContentDisplay.defaultProps = {
  parseTextProps: {},
};
export default UserContentDisplay;
