import { OPEN_LOGIN_MODAL, CLOSE_LOGIN_MODAL } from 'app/actions/types';

const loginModal = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_LOGIN_MODAL:
      return { open: true, ...action };
    case CLOSE_LOGIN_MODAL:
      return { open: false };
    default:
      return state;
  }
};

export default loginModal;
