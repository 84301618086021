import React, { Component } from 'react';
import { connect } from 'react-redux';

import ApppointmentView from './ApppointmentView';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import RangeInput from 'app/components/Shared/RangeInput';
import NoAppointmentDataView from 'app/components/Shared/NoAppointmentDataView';
import screenfull from 'screenfull';

import {
  getFinalAppointment,
  getInitialAppointment,
  isCaseVideo,
  getAppointmentFiles,
  getFilesBasedOnRecordType,
  getFileIndexThrougName,
  getSameFileInAppointment,
} from './helpers';
import { translate } from 'app/actions/flashMessage';
import { isEmpty, isEqual } from 'app/utils/osLodash';

import { setRightSideAppointment } from 'app/actions/caseDetailInfo';

class SBSView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getSideStates('left'),
      ...this.getSideStates('right'),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.caseDetailInfo.mode !== this.props.caseDetailInfo.mode ||
      (!isEmpty(prevProps.caseDetailInfo.selectedRightSideAppointment) &&
        !isEqual(
          prevProps.caseDetailInfo.selectedRightSideAppointment,
          this.props.caseDetailInfo.selectedRightSideAppointment,
        )) ||
      (!isEmpty(prevProps.caseDetailInfo.selectedAppointment) &&
        !isEqual(
          prevProps.caseDetailInfo.selectedAppointment,
          this.props.caseDetailInfo.selectedAppointment,
        ))
    ) {
      let leftFile = getFilesBasedOnRecordType(
          getAppointmentFiles(this.props.caseDetailInfo.selectedAppointment),
          this.props.caseDetailInfo.mode,
        ),
        rightFile = getFilesBasedOnRecordType(
          getAppointmentFiles(
            this.props.caseDetailInfo.selectedRightSideAppointment,
          ),
          this.props.caseDetailInfo.mode,
        );

      if (leftFile.length > 0) {
        this.setState({
          leftFile: leftFile[0],
          leftMode: this.props.caseDetailInfo.mode,
        });
      }
      if (rightFile.length > 0) {
        this.setState({
          rightFile: rightFile[0],
          rightMode: this.props.caseDetailInfo.mode,
        });
      }
    }
    if (
      !isEmpty(prevProps.caseDetailInfo.currentSlide) &&
      isEmpty(this.props.caseDetailInfo.currentSlide)
    ) {
      this.setSelectedFile(
        'left',
        getAppointmentFiles(this.props.caseDetailInfo.selectedAppointment)[0],
        this.props.caseDetailInfo.selectedAppointment,
      );
      this.setSelectedFile(
        'right',
        getAppointmentFiles(
          this.props.caseDetailInfo.selectedRightSideAppointment,
        )[0],
        this.props.caseDetailInfo.selectedRightSideAppointment,
      );
    }
  }

  getSideStates(side) {
    let { kase } = this.props,
      isLeftSide = side === 'left',
      file,
      appointment;

    appointment = isLeftSide
      ? getInitialAppointment(kase.appointments)
      : getFinalAppointment(kase.appointments);
    file = this.getFirstDefaultFile(appointment);
    let values = {};
    values[`${side}File`] = file;
    values[`${side}Appointment`] = appointment;
    values[`${side}Mode`] = this.props.caseDetailInfo.mode;
    if (`${side}Appointment` === 'rightAppointment') {
      this.props.setRightSideAppointment(appointment);
    }
    return values;
  }

  getFirstDefaultFile(appointment) {
    return appointment.images[0] || appointment.videos[0];
  }

  setSelectedFile = (side, file, appointment) => {
    let state = this.state;
    state[`${side}File`] = file;
    if (this.props.moveBothSlider) {
      if (side === 'left') {
        state['rightFile'] =
          getSameFileInAppointment(
            file,
            this.props.caseDetailInfo.selectedRightSideAppointment ||
              this.state.rightAppointment,
          ) || state['rightFile'];
      } else {
        state['leftFile'] =
          getSameFileInAppointment(
            file,
            this.props.caseDetailInfo.selectedAppointment,
          ) || state['leftFile'];
      }
    }
    if (appointment) state[`${side}Appointment`] = appointment;
    this.setState(state);
    this.props.notifyParentContainer(file);
  };

  scrubberRequired() {
    return (
      isCaseVideo(this.state.leftFile) || isCaseVideo(this.state.rightFile)
    );
  }

  getCaseFileScrubberProps(axis) {
    let video = isCaseVideo(this.state.leftFile)
      ? this.state.leftFile
      : this.state.rightFile;
    if (axis === 'vertical') {
      return { axisInfo: video.vertical_axis_info };
    } else {
      return {
        axisInfo: video.horizontal_axis_info,
        mountUpdateRequired: true,
      };
    }
  }

  renderScrubber(axis) {
    if (this.scrubberRequired()) {
      let scrubberProps = this.getCaseFileScrubberProps(axis);
      if (scrubberProps.axisInfo.max)
        return (
          <RangeInput {...scrubberProps} orientation={axis} extraClass={axis} />
        );
    }
  }

  getSelectedAppointment(side) {
    return side === 'right'
      ? this.props.caseDetailInfo.selectedRightSideAppointment ||
          (this.state && this.state.rightAppointment)
      : this.props.caseDetailInfo.selectedAppointment;
  }

  areAppointmentEmpty() {
    return (
      getAppointmentFiles(this.props.caseDetailInfo.selectedAppointment)
        .length === 0 &&
      getAppointmentFiles(
        this.props.caseDetailInfo.selectedRightSideAppointment,
      ).length === 0
    );
  }

  renderAppointment(side) {
    let { kase } = this.props,
      state = this.state,
      selectedFile = state[`${side}File`],
      selectedAppointment = this.getSelectedAppointment(side),
      selectedMode = state[`${side}Mode`],
      fileIndex = getFileIndexThrougName(selectedFile, selectedAppointment),
      slideIndex = fileIndex > 0 ? fileIndex : 0;

    return (
      <div className={`sbs-col sbs-${side}`}>
        {selectedFile && (
          <ApppointmentView
            kase={kase}
            selectedMode={selectedMode}
            key={selectedAppointment.id}
            appointment={selectedAppointment}
            selectedFile={selectedFile}
            notifyParentContainer={this.setSelectedFile.bind(this, side)}
            slideIndex={slideIndex}
          />
        )}
      </div>
    );
  }

  renderBothSliderMovementIcon() {
    let { moveBothSlider } = this.props;
    return (
      <div className='both-movement-slider'>
        <span className='link-icon'>
          <OrthoIcon
            name={moveBothSlider ? 'linked' : 'unlinked'}
            defaultClass=''
            onClick={this.props.toggleBothSliderMovement}
          />
        </span>
        <div className='movement-link-tooltip'>
          <OrthoIcon name='info' dataHoverNotRequired={true} />
          {translate(
            moveBothSlider
              ? 'CASE_SBS_SLIDERS_MOVEMENT_ON_HINT'
              : 'CASE_SBS_SLIDERS_MOVEMENT_OFF_HINT',
          )}
        </div>
      </div>
    );
  }

  isScrubberModeSelected() {
    return (
      isCaseVideo(this.state.leftFile) || isCaseVideo(this.state.rightFile)
    );
  }

  render() {
    let scrubberRequired = this.scrubberRequired(),
      extraWrapperClass = this.isScrubberModeSelected()
        ? 'slide-with-scrubber'
        : '';

    if (this.areAppointmentEmpty()) {
      return (
        <NoAppointmentDataView
          bothAppointmentsEmpty={true}
          extraClass='single-col'
        />
      );
    }

    return (
      <div className='sbs-wrapper'>
        <div
          className={`sbs-spin-img-wrap ${
            scrubberRequired ? 'pl-16' : ''
          } ${extraWrapperClass}`}>
          {this.renderBothSliderMovementIcon()}
          {this.renderAppointment('left')}
          {this.renderAppointment('right')}
        </div>
        {this.renderScrubber('vertical')}
        {this.renderScrubber('horizontal')}
        {screenfull.isEnabled && (
          <div className={`cs-fullscreen ${scrubberRequired ? 'rt-16' : ''}`}>
            <OsBtn
              name='BtnIcon'
              extraClass='os-fullscreen-btn no-text'
              label={`Case slider full screen ${
                this.props.isFullScreen ? 'close' : 'open'
              }`}
              icon={this.props.isFullScreen ? 'collapse' : 'expand-2'}
              onClick={this.props.onClickFullscreen}
            />
          </div>
        )}
      </div>
    );
  }
}

SBSView = connect(({ caseDetailInfo }) => ({ caseDetailInfo }), {
  setRightSideAppointment,
})(SBSView);

export default SBSView;
