import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import withAuthenticate from 'app/components/HOC/withAuthenticate';

import EmojiIconMobileList from 'app/components/EmojiIconBar/MobileUserList';
import EmojiWithUserList from 'app/components/EmojiIconBar/EmojiWithUserList';
import EmojiUsers from 'app/components/EmojiIconBar/EmojiUsers';
import EmojiWrapper from 'app/components/EmojiIconBar/EmojiWrapper';
import StoreUpdater from 'app/services/StoreUpdater';

import { pluralizeWithNumber } from 'app/utils/generalHelper';
import { take } from 'app/utils/osLodash';

const ADD_EMOJI_REACTION_MUTATION = queryLoader(
  'app/graphql/mutations/AddReaction.gql',
);
const REMOVE_EMOJI_REACTION_MUTATION = queryLoader(
  'app/graphql/mutations/RemoveReaction.gql',
);

class EmojiList extends Component {
  state = {
    activeEmoji: null,
  };

  componentDidUpdate(prevProps,){
    if(prevProps.emojiReactionDetail !== this.props.emojiReactionDetail){
      this.renderSelectedEmojis();
    }
  }

  getVariables(identifier) {
    return {
      entityType: this.props.entityType,
      entityId: this.props.entityId,
      identifier,
    };
  }

  isSourceMessenger() {
    return this.props.entityType !== 'Comment';
  }

  addEmoji = (name) => {
    this.props.addReactionMutation({
      variables: this.getVariables(name),
      optimisticResponse: () => {
        return {
          addEmoji: {
            __typename: 'Response',
            success: true,
          },
        };
      },
      update: (proxy, { data: { addEmoji } }) => {
        addEmoji.success
          ? this.addEmojiInStore(name)
          : this.removeEmojiInStore(name);
      },
    });
  };

  removeEmoji = (name) => {
    this.props.removeReactionMutation({
      variables: this.getVariables(name),
      optimisticResponse: () => {
        return {
          removeEmoji: {
            __typename: 'Response',
            success: true,
          },
        };
      },
      update: (proxy, { data: { removeEmoji } }) => {
        removeEmoji.success
          ? this.removeEmojiInStore(name)
          : this.addEmojiInStore(name);
      },
    });
  };

  addEmojiInStore(name) {
    StoreUpdater.addEmojiInEntity({
      emojiName: name,
      entityType: this.props.entityType,
      entityId: this.props.entityId,
      user: this.props.user,
      reactionId: this.props.emojiReactionDetail.id,
      reactedByCurrentUser: true,
    });
  }

  setActiveEmoji = (activeEmoji) => {
    if (this.state.activeEmoji !== activeEmoji) {
      this.setState({ activeEmoji });
    }
  };

  closeMobileUserList = () => {
    this.setState({ activeEmoji: null });
  };

  removeEmojiInStore(name) {
    StoreUpdater.removeEmojiFromEntity({
      emojiName: name,
      entityType: this.props.entityType,
      entityId: this.props.entityId,
      user: this.props.user,
      reactionId: this.props.emojiReactionDetail.id,
    });
  }

  removeEmojiIfAllowed = (name) => {
    this.props.redirectToDefaultIfUnauthenticated(
      this.removeEmoji.bind(this, name),
    );
  };

  addEmojiIfAllowed = (name) => {
    if (!this.isCurrentUserEmojiSelectionPresent())
      this.props.redirectToDefaultIfUnauthenticated(
        this.addEmoji.bind(this, name),
      );
  };

  renderEmojiWithUserList = (data) => {
    const { emojiReactionUsers } = this.props.emojiReactionDetail;
    return (
      <EmojiWithUserList
        {...data}
        key={data.emojiName}
        users={emojiReactionUsers}
        removeEmoji={this.removeEmojiIfAllowed}
        addEmoji={this.addEmojiIfAllowed}
        setActiveEmoji={this.setActiveEmoji}
        userlistClass={this.props.userlistClass}
        popoverClass={this.props.popoverClass}
        isSourceMessenger={this.isSourceMessenger()}
      />
    );
  };

  getUserName(user) {
    let currentUser = this.props.currentUser.graph;
    return +currentUser.id === +user.id ? 'You' : user.name;
  }

  getEmojiListText() {
    const { emojiReactionUsers } = this.props.emojiReactionDetail,
      total = emojiReactionUsers.length;
    if (total > 0) {
      let text = this.getUserName(emojiReactionUsers[0].user);
      text +=
        total > 1
          ? ` and ${pluralizeWithNumber(total - 1, {
              singular: 'other',
              plural: 'others',
            })}`
          : '';
      return text;
    }
  }

  renderEmojiCountDetails(emojiList) {
    const isSourceMessenger = this.isSourceMessenger();
    if (isSourceMessenger) {
      let count = emojiList.length - 2;
      return (
        <>{count > 0 && <span className='emoji-counter'>+{count}</span>}</>
      );
    } else {
      let text = this.getEmojiListText();
      return <>{text && <span className='emoji-counter'>{text}</span>}</>;
    }
  }

  getEmojiVisibleLimit() {
    return this.isSourceMessenger()
      ? 2
      : this.isCurrentUserEmojiSelectionPresent()
      ? 4
      : 3;
  }

  renderPopover() {
    let className = this.props.popoverClass,
      users = this.props.emojiReactionDetail.emojiReactionUsers;

    className += users.length > 1 ? ' with-multiple-user' : ' with-single-user';

    return (
      <Popover className={className}>
        <Popover.Header>Reactions</Popover.Header>
        <Popover.Body>
          <EmojiUsers
            users={users}
            source={'feed'}
            userlistClass={this.props.userlistClass}
          />
        </Popover.Body>
      </Popover>
    );
  }

  renderEmojiCountText(emojiList) {
    if (this.isSourceMessenger()) {
      return this.renderEmojiCountDetails(emojiList);
    } else {
      return (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          delay='250'
          placement={'auto'}
          overlay={this.renderPopover()}>
          <span>{this.renderEmojiCountDetails(emojiList)}</span>
        </OverlayTrigger>
      );
    }
  }

  renderSelectedEmojis() {
    if (this.props.emojiReactionDetail) {
      const { list: emojiList } = this.props.emojiReactionDetail;
      const list = take(emojiList, this.getEmojiVisibleLimit());
      const selectedEmojis = <>
        { list.map(this.renderEmojiWithUserList) }
        { this.renderEmojiCountText(emojiList) }
      </>
      if(typeof this.props.renderSelectedEmojisCallback === 'function'){
        this.props.renderSelectedEmojisCallback(selectedEmojis, this.props.entityId);
      }
      return selectedEmojis;
    }
  }

  isMobileOrIpadUserListVisile() {
    const { ipad, mobileDevice } = this.props.device;
    return ipad || mobileDevice;
  }

  renderMobileUserList() {
    if (false && this.isMobileOrIpadUserListVisile())
      return (
        <EmojiIconMobileList
          visible={!!this.state.activeEmoji}
          onClose={this.closeMobileUserList}
          emojiReactionList={this.props.emojiReactionDetail.list}
          activeEmoji={this.state.activeEmoji}
        />
      );
  }

  isCurrentUserEmojiSelectionPresent() {
    return this.props.emojiReactionDetail?.list?.some((r) => r.active);
  }

  renderEmojiButtonIfRequired() {
    let selectedReactions = this.props.emojiReactionDetail?.list
        ?.filter((r) => r.active)
        ?.map((r) => r.emojiName),
      mobileDeviceOrIpad =
        this.props.device.mobileDevice || this.props.device.ipad;
    if (!this.isCurrentUserEmojiSelectionPresent()) {
      return (
        <EmojiWrapper
          mobileDeviceOrIpad={mobileDeviceOrIpad}
          entityType={this.props.entityType}
          entityId={this.props.entityId}
          selectedReactions={selectedReactions}
          addEmoji={this.addEmojiIfAllowed}
          removeEmoji={this.removeEmojiIfAllowed}
        />
      );
    } else {
      return <div className='blank-emoji-holder osbtn d-none'></div>;
    }
  }

  render() {
    return (
      <div className='emoji-bar'>
        <ul className='list-unstyled emoji-btns-group'>
          {this.renderEmojiButtonIfRequired()}
          {!this.props.hideDefaultSelectedEmoji && <li className='user-emoji-list'>{this.renderSelectedEmojis()}</li>}
        </ul>
        {this.renderMobileUserList()}
      </div>
    );
  }
}

EmojiList = compose(
  graphql(ADD_EMOJI_REACTION_MUTATION, { name: 'addReactionMutation' }),
  graphql(REMOVE_EMOJI_REACTION_MUTATION, { name: 'removeReactionMutation' }),
)(EmojiList);
EmojiList = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  null,
)(EmojiList);
EmojiList = withAuthenticate(EmojiList);
export default EmojiList;
