import React, { Component } from 'react';

import ApplianceLog from './ApplianceLog';
import ConnectToSupport from 'app/components/ConnectToSupport';
import OsBtn from 'app/components/OsBtn';

import { LOG_ORDER } from 'app/constants';
import { translate } from 'app/actions/flashMessage';
import { map, groupBy } from 'app/utils/osLodash';
import { getProcessedAppliancesFromLogs } from 'app/utils/appointmentHelper';

import EventTracker from 'app/services/EventTracker';

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.state = {
      open: false,
    };
    this.applianceLog = [];
  }

  toggleOpen() {
    map(this.props.applianceLogs, 'tool_type').forEach(
      (tool_type) =>
        this.applianceLog[tool_type] &&
        this.applianceLog[tool_type].setState({ open: !this.state.open }),
    );
    this.setState({ open: !this.state.open }, this.onExpandAllClick);
    // if(this.applianceLog['Software'])
    //   this.applianceLog['Software'] && this.applianceLog['Software'].setState({open: !this.state.open})
    // if(this.applianceLog['Equipment'])
    //   this.applianceLog['Equipment'] && this.applianceLog['Equipment'].setState({open: !this.state.open})
  }

  onExpandAllClick() {
    if (this.state.open) this.sendExpandAllEvent();
  }

  sendExpandAllEvent() {
    EventTracker.trackForEntity('appliance_logs_expand_all', this.props.kase);
  }

  sendExpandEvent(type) {
    EventTracker.trackForEntity('appliance_log_expand', {
      ...this.props.kase,
      type: type,
    });
  }

  toggleOpenIfRequired = (type) => {
    if (this.applianceLog[type] && this.applianceLog[type].state.open) {
      if (type === 'Fixed Appliance') type = 'Bracket';
      this.sendExpandEvent(type);
    }

    this.setState({
      open: map(this.props.applianceLogs, 'tool_type').some(
        (tool_type) =>
          this.applianceLog[tool_type] &&
          this.applianceLog[tool_type].state.open,
      ),
      // open: (map(this.props.applianceLogs, "tool_type").some(tool_type => this.applianceLog[tool_type] && this.applianceLog[tool_type].state.open)) ||
      //       (this.applianceLog['Equipment'] && this.applianceLog['Equipment'].state.open) ||
      //       (this.applianceLog['Software'] && this.applianceLog['Software'].state.open)
    });
  };

  renderExpandAllSection() {
    let extraClass = 'expand-all float-right';
    extraClass += this.state.open ? ' active' : '';
    return (
      <OsBtn
        name='BtnIcon'
        icon={this.state.open ? 'chevron-down' : 'chevron-right'}
        extraClass={extraClass}
        onClick={this.toggleOpen}
        text={this.state.open ? 'Collapse All' : 'Expand All'}
      />
    );
  }

  scrollIntoView = (type) => {
    let logsContainer = this.applianceLog[type].logsContainer;
    let scrollHeight = logsContainer.offsetTop - logsContainer.clientHeight;
    this.timelineContainer.parentElement.scrollTo(0, scrollHeight);
  };

  renderEquipmentAppliances() {
    // if(this.props.equipmentAppliances && this.props.equipmentAppliances.length > 0)
    //   return <AdditionalLogs
    //     setRef={this.setRef}
    //     label='Equipment'
    //     logs={this.props.equipmentAppliances}
    //     toggleOpenIfRequired={this.toggleOpenIfRequired}
    //     onExpand={this.scrollIntoView}
    //   />
  }

  renderSoftwareAppliances() {
    // if(this.props.softwareAppliances && this.props.softwareAppliances.length > 0)
    //   return <AdditionalLogs
    //     setRef={this.setRef}
    //     label='Software'
    //     logs={this.props.softwareAppliances}
    //     toggleOpenIfRequired={this.toggleOpenIfRequired}
    //     onExpand={this.scrollIntoView}
    //   />
  }

  setRef = (instance, logType) => {
    this.applianceLog[logType] = instance;
  };

  render() {
    if (this.props.isUnavailable) {
      return (
        <div className='treatment-data-msg'>
          {translate('TREATMENT_DATA_NOT_AVAILABLE')}
          <ConnectToSupport viewType='link' text='contact support' />
        </div>
      );
    }

    const startMonth = this.props.t1Age.years * 12 + this.props.t1Age.months,
      endMonth = this.props.t2Age.years * 12 + this.props.t2Age.months,
      duration = endMonth - startMonth,
      { applianceLogs, appointments, groupedActionsByVariantLog } = this.props,
      logs = getProcessedAppliancesFromLogs(
        applianceLogs,
        groupedActionsByVariantLog,
        appointments,
      );

    const groupedLogs = groupBy(logs, 'tool_type');

    return (
      <div
        className='timeline'
        ref={(timelineContainer) =>
          (this.timelineContainer = timelineContainer)
        }>
        <div className='timeline-block-container'>
          <div className='timeline-block-title'>
            <span className=''>Appliances</span>
            {this.renderExpandAllSection()}
          </div>
          <div className='timeline-block-holder'>
            {LOG_ORDER.map((type) => {
              return (
                <ApplianceLog
                  setRef={this.setRef}
                  key={type}
                  timelineStartMonth={startMonth}
                  timelineEndMonth={endMonth}
                  timelineDuration={duration}
                  logType={type}
                  logs={groupedLogs[type]}
                  toggleOpenIfRequired={this.toggleOpenIfRequired}
                  patientDob={this.props.patientDob}
                  appointments={this.props.appointments}
                  groupedActions={this.props.groupedActions}
                />
              );
            })}
          </div>
          {this.renderEquipmentAppliances()}
          {this.renderSoftwareAppliances()}
        </div>
      </div>
    );
  }
}
