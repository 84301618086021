import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';

class ReportedCard extends Component {
  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  render() {
    return (
      <div className={this.props.containerClasses}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            defaultClass='os-close-link'
            onClick={this.afterClose}
          />
        )}

        <div className='m-auto text-center white-spc-normal'>
          <div className='mb-2'>
            <OrthoIcon name='flag' dataHoverNotRequired={true} />
          </div>
          {this.props.obj.display_message}
        </div>
      </div>
    );
  }
}

export default ReportedCard;
