import React, { Component } from 'react';
import { connect } from 'react-redux';

import AbstractGalleryView from './AbstractGalleryView';
import {
  setCurrentSlide,
  setCaseViewType,
  setModeType,
} from 'app/actions/caseDetailInfo';
import { UPDATED_GRID_VIEW_IMAGES_INFO_HASH } from './Constant';

export default class CaseGridUpdatedView extends AbstractGalleryView {
  gridViewImageInfoHash = UPDATED_GRID_VIEW_IMAGES_INFO_HASH;
  imageContainerClass = 'cs-grid-col ';
  parentContainerClass = 'cs-gallery-grid-row';
}

CaseGridUpdatedView = connect(null, {
  setCurrentSlide,
  setCaseViewType,
  setModeType,
})(CaseGridUpdatedView);
