import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { sortBy, uniqBy } from 'app/utils/osLodash';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import StoreUpdater from 'app/services/StoreUpdater';
import { openTaskModal } from 'app/actions/taskModal';
import { getWorkspaceFeatured } from 'app/selectors/workspace';

const RELATED_TASKS = queryLoader(
  'app/graphql/queries/Task/RelatedTaskWidget.gql',
);

function useRelatedTaskWidget({ widgetSourceObject }) {
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const careSpaceId = widgetSourceObject?.id;
  const entity = `Space::Base-${careSpaceId}`;
  const { all_members } = useContext(WorkspaceContext);
  const listRef = useRef(null);

  const [tasks, setTasks] = useState([]);
  const [taskDetails, setTaskDetails] = useState({
    pageNumber: 0,
    totalTasks: 0,
  });

  const initialVariables = useMemo(
    () => ({
      page: 0,
      perPage: 10,
      type: 'EntityRelatedTasks',
      sortQuery: 'due_date_asc',
      additional_filters: JSON.stringify({ entity }),
    }),
    [entity],
  );

  const [getTasks, { data, loading }] = useLazyQuery(RELATED_TASKS, {
    ignoreResults: true,
  });

  const teamMembersData = useMemo(() => {
    return {
      workspace: { all_members },
    };
  }, [all_members]);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (
        Math.round(scrollTop) + clientHeight >= scrollHeight &&
        tasks.length < taskDetails.totalTasks &&
        !loading
      ) {
        getTasks({
          variables: { ...initialVariables, page: taskDetails.pageNumber },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-first',
        });
      }
    }
  };

  const handleOpenTaskModal = () => {
    dispatch(
      openTaskModal(undefined, {
        updateComponent: updateList,
        backendDataRequired: true,
        entity: widgetSourceObject,
      }),
    );
  };

  const redirectToAllTasks = () => {
    let route = `/${workspace.identifier}/tasks/team/status/list?filter=${widgetSourceObject.nice_id}`;
    navigate(route);
  };

  const updateList = (taskFromBackend) => {
    if (typeof taskFromBackend == 'object') {
      let task = tasks.find((task) => task.id == taskFromBackend.id);
      if (task === undefined) {
        createTask(taskFromBackend);
      } else {
        updateTask(taskFromBackend);
      }
    } else {
      deleteTask(taskFromBackend);
    }
  };

  const createTask = (task) => {
    let updatedTasksList = [...tasks, task];
    setTasks(updatedTasksList);
  };

  const updateTask = (taskFromBackend) => {
    let updatedTasks = tasks.map((task) => {
      if (task.id === taskFromBackend.id) {
        return taskFromBackend;
      } else {
        return task;
      }
    });

    setTasks(updatedTasks);
  };

  const deleteTask = (taskId) => {
    let updatedTasksList = tasks.filter((task) => task.id !== taskId);
    const taskDetails = tasks.find((task) => task.id === taskId);

    if (taskDetails) {
      StoreUpdater.removeRecord(taskDetails, initialVariables, {
        query: RELATED_TASKS,
      });
    }

    setTasks(updatedTasksList);
  };

  useEffect(() => {
    if (widgetSourceObject !== undefined && widgetSourceObject !== null) {
      setTasks([]);
      let entity = `Space::Base-${widgetSourceObject.id}`;

      setTimeout(() => {
        getTasks({
          variables: {
            ...initialVariables,
            additional_filters: JSON.stringify({ entity }),
          },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-first',
        });
      }, 1000);
    }
  }, [widgetSourceObject?.id]);

  useEffect(() => {
    if (data && data !== undefined) {
      let allTasks = uniqBy([...data.records.results, ...tasks], 'id');
      // sort all tasks by created_at attribute in the descending order
      allTasks = sortBy(allTasks, (obj) => obj.created_at).reverse();

      setTasks(allTasks);
      setTaskDetails({
        pageNumber: data.records.page + 1,
        totalTasks: data.records.total,
      });
    }
  }, [data]);

  return {
    dispatch,
    loading,
    listRef,
    tasks,
    data,
    teamMembersData,
    handleScroll,
    redirectToAllTasks,
    updateList,
    createTask,
    handleOpenTaskModal,
    deleteTask,
    setTasks,
  };
}

export default useRelatedTaskWidget;
