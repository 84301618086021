import { forwardRef, useEffect, useRef } from 'react';
import { currentUser } from 'app/utils/userHelper';
import { LABELS_POSSIBLE_COLORS } from 'app/components/LabelsModal/constants';
import { sample } from 'app/utils/osLodash';

const CanvasDraw = forwardRef((props, canvasRef) => {
  const { isRecording } = props;
  const backgroundColor = useRef(sample(Object.values(LABELS_POSSIBLE_COLORS)));
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let radius = 50;
    let isExpanding = true;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const image = new Image();
    image.src = currentUser().avatar;

    // Making the isAvatarPresent false to draw the user's name instead of the avatar.
    // Might need to change this later.
    // const isAvatarPresent = !!currentUser().avatar;
    const isAvatarPresent = false;

    const name = currentUser().first_name[0] + currentUser().last_name[0]; // user's name
    const imageRadius = 50;

    const draw = () => {
      // Clear the canvas and set the background color
      ctx.fillStyle = `#${backgroundColor.current}`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the expanding/collapsing circle
      if (isRecording) {
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.fill();
      }

      // Draw the image or text in the center of the circle
      const imageX = centerX - imageRadius;
      const imageY = centerY - imageRadius;
      ctx.save();
      ctx.beginPath();
      ctx.arc(centerX, centerY, imageRadius, 0, 2 * Math.PI);
      ctx.clip();
      if (isAvatarPresent) {
        ctx.drawImage(image, imageX, imageY, imageRadius * 2, imageRadius * 2);
      } else {
        ctx.fillStyle = 'black';
        ctx.font = 'bold 60px sans-serif';
        ctx.textAlign = 'center';
        ctx.fillText(name, centerX, centerY + 20);
      }
      ctx.restore();

      // Update the radius based on the expanding or collapsing direction
      if (isExpanding) {
        radius += 0.25;
        if (radius + 55 >= Math.min(centerX, centerY) / 2) {
          isExpanding = false;
        }
      } else {
        radius -= 0.5;
        if (radius <= 50) {
          isExpanding = true;
        }
      }

      // Request the next animation frame
      requestAnimationFrame(draw);
    };

    // Start the animation loop
    requestAnimationFrame(draw);
  }, [isRecording]);

  return <canvas ref={canvasRef} width={800} height={500} />;
});

export default CanvasDraw;
