import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import Loader from 'react-loaders';
import RedirectToDefault from 'app/components/RedirectToDefault';

import { loader as queryLoader } from 'graphql.macro';

import {
  POLICIES_URL_HASH,
  POLICY_LAST_MODIFIED_DATE_FORMAT,
} from 'app/constants';

import { timeWithFormat } from 'app/utils/timeHelper';

import HTMLContentContainer from 'app/components/Shared/HTMLContentContainer';
const ROLE_POLICIES = queryLoader('app/graphql/RolePolicies.gql');

class PoliciesPageView extends Component {
  componentDidMount() {
    document.body.classList.add('policy-not-accepted-view');
  }

  componentWillUnmount() {
    document.body.classList.remove('policy-not-accepted-view');
  }

  lastModifiedDate(policy) {
    return timeWithFormat(policy.updated_at, POLICY_LAST_MODIFIED_DATE_FORMAT);
  }

  getCurrentPolicy() {
    let { role_policies } = this.props.data;
    switch (this.props.match.params.type) {
      case 'privacy_policy':
        return role_policies.privacy_policy;
      case 'terms_of_use':
        return role_policies.terms_of_use;
      default:
        return role_policies.privacy_policy;
    }
  }

  validPolicyRequested(type) {
    type = type || `#${this.props.match.params.type}`;
    return POLICIES_URL_HASH.includes(type);
  }

  onContentClick = (e) => {
    let target =
      e.target.tagName.toLowerCase() === 'a'
        ? e.target
        : window.$(e.target).parent('a')[0];
    if (
      target.tagName.toLowerCase() === 'a' &&
      this.validPolicyRequested(target.hash)
    ) {
      e.stopPropagation();
      e.preventDefault();
      this.props.navigate(`/policies/${target.hash.replace('#', '')}`);
    }
  };

  content() {
    let policy = this.getCurrentPolicy();
    return (
      <article key='content' className='modal-body policy-scroll-content'>
        <div className='modified-info text-right pb-4' ref='policyModifiedDate'>
          Last modified:{' '}
          <span className='date-modified'>{this.lastModifiedDate(policy)}</span>
        </div>
        <div className='content-update-area'>
          <HTMLContentContainer
            content={policy.body}
            onInfoContentClick={this.onContentClick}
          />
        </div>
      </article>
    );
  }

  render() {
    if (!this.validPolicyRequested()) return <RedirectToDefault />;
    return (
      <>
        {this.props.data.loading && <Loader type='ball-triangle-path' active />}
        {!this.props.data.loading && this.content()}
      </>
    );
  }
}

PoliciesPageView = graphql(ROLE_POLICIES, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      role: props.role,
    },
  }),
})(PoliciesPageView);
PoliciesPageView = withRouter(PoliciesPageView);

export default PoliciesPageView;
