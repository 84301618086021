import React, { Component } from 'react';

import { groupBy, keys, snakeCase } from 'app/utils/osLodash';

import { VIEWING_MODES_MAPPER } from 'app/components/CaseLeftSection/constants';
import { CASE_IMAGE_HASH, CASE_VIEW_OPTIONS } from './Constant';

export default class AbstractGalleryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesHash: this.getSelectedAppointmentImages(),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedAppointment.id !== this.props.selectedAppointment.id
    ) {
      this.setState({ imagesHash: this.getSelectedAppointmentImages() });
    }
  }

  getSelectedAppointmentImages() {
    let selectedAppointmentImages =
      (this.props.selectedAppointment &&
        this.props.selectedAppointment.images) ||
      [];
    return groupBy(selectedAppointmentImages, (i) => snakeCase(i.name));
  }

  renderColumnImages(imageInfo, name, index) {
    let extraClass = imageInfo.childClass[index];

    return <div className={extraClass}>{this.renderImageFor(name)}</div>;
  }

  onImageClickHandler(imageObj) {
    this.props.setCurrentSlide(imageObj);
    this.props.setCaseViewType(keys(CASE_VIEW_OPTIONS)[1]);
    this.props.setModeType(keys(VIEWING_MODES_MAPPER)[0]);
  }

  renderImageFor(name) {
    if (this.state.imagesHash[name]?.length > 0) {
      const imageObj = this.state.imagesHash[name][0];
      return (
        <img
          src={imageObj.photo}
          alt={name}
          className=''
          onClick={this.onImageClickHandler.bind(this, imageObj)}
        />
      );
    } else if (name !== 'empty') {
      return (
        <div className='unavailable-text'>
          {CASE_IMAGE_HASH[name]} photo unavailable
        </div>
      );
    }
  }

  renderImageContainer(name) {
    const imageInfo = this.gridViewImageInfoHash[name];
    if (imageInfo.inColumn) {
      return imageInfo.name.map((name, index) =>
        this.renderColumnImages(imageInfo, name, index),
      );
    } else {
      return this.renderImageFor(name);
    }
  }

  renderImages = (name, index) => {
    let extraClass = this.gridViewImageInfoHash[name].class;
    extraClass += this.state.imagesHash[name]?.length > 0 ? '' : ' unavailable';

    return (
      <li className={`${this.imageContainerClass} ${extraClass}`}>
        {this.renderImageContainer(name)}
      </li>
    );
  };

  render() {
    return (
      <div className={`row ${this.parentContainerClass}`}>
        {keys(this.gridViewImageInfoHash).map(this.renderImages)}
      </div>
    );
  }
}
