import React from "react";
import { Field } from "react-final-form";
import OrthoIcon from "app/components/Shared/OrthoIcon";

const BACKSPACE = "Backspace";

function GetPinFields(fieldNamePrefix, pinLength, focus = true) {

  const focusElement = (elementId) => {
    document.getElementById(elementId)?.focus();
  }

  return <>
    {
      [...Array(pinLength)].map((_, index) => {
        return <Field name={`${fieldNamePrefix}${index}`}>
          {
            ({ input, meta, placeholder }) => (
              <div>
                <input
                  {...input}
                  autoFocus={fieldNamePrefix === "newPin" && index == 0 && focus ? true : false}
                  autoComplete="new-password"
                  id={`${fieldNamePrefix}${index}`}
                  placeholder={placeholder}
                  type={'password'}
                  maxLength={1}
                  style={{ textAlign: "center" }}
                  onKeyUp={(e) => {
                    if (e.key === BACKSPACE && input.value === '') {
                      focusElement(`${fieldNamePrefix}${index - 1}`);
                    } else if (input.value) {
                      focusElement(`${fieldNamePrefix}${index + 1}`);
                    }
                  }}
                />
                {meta.error && meta.touched && meta.submitFailed && <div className="form-error email-error">
                  <OrthoIcon name="error" defaultClass='pe-1 ms-3 ' />{meta.error}
                </div>}
              </div>
            )
          }
        </Field>
      })
    }
  </>;
}

export default GetPinFields;
