import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';

import StoreUpdater from 'app/services/StoreUpdater';

import { closeInfoModal, openInfoModal } from 'app/actions/infoModal';

const DELETE_APPOINTMENT = queryLoader(
  'app/graphql/mutations/Care/DeleteAppointment.gql',
);

class DeleteAppointment extends Component {
  state = {
    requestInProgress: false,
  };

  sendRequest = () => {
    this.setState({ requestInProgress: true });
    let { appointment, kaseId } = this.props;
    this.props
      .deleteAppointment({
        variables: { id: appointment.id },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: true });
        this.props.closeInfoModal();
        if (data.deleteAppointment.success) {
          StoreUpdater.modifyAppointmentInCase(appointment, kaseId, true);
        } else {
          this.props.openInfoModal('general', 'error_message', {
            contentInterpolations: { error: data.deleteAppointment.error },
          });
        }
      });
  };

  openConfirmationModal = () => {
    this.props.openInfoModal('care_space', 'delete_appointment', {
      contentInterpolations: { entityType: 'appointment' },
      onSuccess: this.sendRequest,
      primaryCtaRequestInProgress: this.state.requestInProgress,
      closeModalNotRequiredOnPrimaryClick: true,
    });
  };

  render() {
    let { appointment, extraClass } = this.props;
    return (
      <OsBtn
        name='BtnIcon'
        extraClass={extraClass}
        icon='delete'
        text='Delete'
        onClick={this.openConfirmationModal}
        associatedEntity={appointment}
      />
    );
  }
}

DeleteAppointment = graphql(DELETE_APPOINTMENT, { name: 'deleteAppointment' })(
  DeleteAppointment,
);
DeleteAppointment = connect(({}) => ({}), { closeInfoModal, openInfoModal })(
  DeleteAppointment,
);
DeleteAppointment.defaultProps = {
  appointment: {},
  extraClass: 'list-button',
};
export default DeleteAppointment;
