import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import Tag from 'app/components/Shared/Tag';
import { useSelector } from 'react-redux';

import { getWorkspaceFeatured } from 'app/selectors/workspace';

const NonSubscribedView = ({
  children,
  primaryCTAText,
  title,
  header: Header,
  headerNotRequired = false,
}) => {
  const isFeatured = useSelector(getWorkspaceFeatured);
  const renderHeading = () => {
    if (!headerNotRequired) {
      if (Header) {
        return <Header />;
      } else {
        return (
          <div
            className='row d-flex justify-content-between xl-52'
            style={{ paddingRight: '48px', paddingLeft: '48px' }}>
            <div className='workspace-space-page-heading col legend-access-tooltip'>
              <div className='d-inline-flex align-items-center'>
                <span className='mr-1' style={{ marginRight: '8px' }}>
                  {title}
                </span>
                <Tag color='orange' text='Featured' />
              </div>
            </div>
            <div className='page-heading-right col'>
              {primaryCTAText && (
                <OsBtn
                  text={primaryCTAText}
                  name='BtnPrimary'
                  extraClass='web-view-btn add-new-btn'
                  associatedEntity={{}}
                  disabled={true}
                />
              )}
            </div>
          </div>
        );
      }
    }
  };
  const renderNonSubscribedView = () => {
    return (
      <div className='collection-page pulses mt-4'>
        <div className='container-fluid workspace-page-head vs-heading'>
          {renderHeading()}
          <div className='comming-soon-section'>
            <div className='col'>
              <Icon name='changePassword' className='no-notifications-svg' />
              <h4 className='mb-3'>
                Become a Subscribed Member to get Access to all Features.
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return isFeatured ? children : renderNonSubscribedView();
};

export default NonSubscribedView;
