import {
  SPACE_MUTE_BANNER_KEY_PREFIX,
  SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX,
} from 'app/components/UniversalBanner/constants';

export function getSpaceMutedBannerKey(spaceId) {
  return `${SPACE_MUTE_BANNER_KEY_PREFIX}${spaceId}`;
}

export function getSpacePriorityGroupBannerKey(spaceId) {
  return `${SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX}${spaceId}`;
}
