import React from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  activeTeamNavTab,
  getTeamNavTabLink,
  getTeamNavTabs,
} from 'app/components/TeamNavBar/teamNavHelper';

const TeamNavTabs = ({ source, sourceId }) => {
  const navigate = useNavigate();
  const params = useParams();

  const goToSelectedTab = (path) => {
    return navigate(`${getTeamNavTabLink(source, sourceId)}/${path}`);
  };

  const renderNavList = (item) => {
    const activeTab = activeTeamNavTab(source, params.filter);
    let extraClass = item.path === activeTab.path ? ' active' : '';
    return (
      <li
        key={item.name}
        className={extraClass}
        onClick={() => goToSelectedTab(item.path)}>
        {item.name}
      </li>
    );
  };

  return <ul>{getTeamNavTabs(source).map((item) => renderNavList(item))}</ul>;
};

export default TeamNavTabs;
