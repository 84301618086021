import { OPEN_ANALYTICS_MODAL, CLOSE_ANALYTICS_MODAL } from 'app/actions/types';

export default function (
  state = {
    open: false,
  },
  action,
) {
  switch (action.type) {
    case OPEN_ANALYTICS_MODAL:
      return { open: true };
    case CLOSE_ANALYTICS_MODAL:
      return { open: false };

    default:
      return state;
  }
}
