import EmptyState from 'app/components/Task/EmptyState';
import Task from 'app/components/Task/Task';

const TaskCollectionView = ({ listType, ...props }) => {
  const tasks = props.results;
  const renderTasks = () => {
    return tasks.map((task) => {
      return (
        <div id={`task-Id-${task.id}`}>
          <Task key={task.id} task={task} listType={listType} />
        </div>
      );
    });
  };

  const renderEmptyView = () => {
    return <EmptyState />;
  };

  const renderCollection = () => {
    return tasks.length > 0 ? renderTasks() : renderEmptyView();
  };

  return <div className='taskListContainer'>{renderCollection()}</div>;
};

export default TaskCollectionView;
