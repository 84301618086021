import workSpaceLoginImage from 'app/images/workspaceLogin@2x.png';
import Logo from 'app/images/cloudberry-logo@2x.png';

import { MAIL_TO_SUPPORT } from 'app/constants';
import store from 'app/store';
import { logout } from 'app/actions/authentication';

const ErrorPageView = ({ clearError }) => (
  <article className='error__wrapper container-xl'>
    <div className='row'>
      <div className='col-sm-4 col-md-4'>
        <a className='navbar-brand' href='/'>
          <div className='logo'>
            <img src={Logo} alt='' className='logo-primary' />
          </div>
        </a>
      </div>
    </div>
    <div className='row m-column-reverse'>
      <div className='col-12 col-sm-5 col-md-4'>
        <div className='error__heading_info'>
          <h1>Oops, something went wrong..</h1>
          <p>
            An error has occured and we’re working to fix the problem. We’ll be
            up and running shortly.
            <br />
            <br />
            In the meantime try to refresh this page or relogin to your account.
            Feel free to{' '}
            <a
              href={MAIL_TO_SUPPORT}
              rel='noopener noreferrer'
              target='_blank'
              className='semibold'>
              contact our support team
            </a>{' '}
            if the problem persists.
            <br />
            <br />
            Thank you for your patience!
          </p>
          <button
            className='osbtn osbtn-secondary with-border'
            onClick={() => store.dispatch(logout())}>
            Log out from your account
          </button>
        </div>
      </div>
      <div className='col-sm-7 col-md-8'>
        <div className='error-img-wrap'>
          <img
            src={workSpaceLoginImage}
            alt='error illustration'
            className='error-illustration'
          />
        </div>
      </div>
    </div>
  </article>
);

export default ErrorPageView;
