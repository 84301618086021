import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo } from '@apollo/client/react/hoc';
import { Outlet } from 'react-router-dom';

import AbstractLayout from './AbstractLayout';
import { bindMobileFilter } from 'app/actions/device';

import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';

class EmptyLayout extends AbstractLayout {
  componentDidMount() {
    this.isUnauthorizedSession() && addClassToHTML('unauthorized-session');
    this.props.bindMobileFilter();
  }

  componentWillUnmount() {
    removeClassFromHTML('unauthorized-session');
    window.$('body').removeClass();
  }

  render() {
    return <div className='container login-wrap'>{<Outlet />}</div>;
  }
}

EmptyLayout = connect(({ currentUser, device }) => ({ currentUser, device }), {
  bindMobileFilter,
})(EmptyLayout);
EmptyLayout = withApollo(EmptyLayout);
EmptyLayout = withRouter(EmptyLayout);
export default EmptyLayout;
