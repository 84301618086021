import React from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import LeaveConfirmationModal from 'app/components/Shared/LeaveConfirmationModal';
import OsBtn from 'app/components/OsBtn/';
import SpaceRequests from './index';

import { flashError } from 'app/actions/flashMessage';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { isPrivateSpace } from 'app/utils/spaceHelper';

class DeclineRequest extends SpaceRequests {
  state = {
    leaving: false,
  };

  closeModal = () => {
    this.setState({ leaving: false });
  };

  isRedIconRequired() {
    return this.props.onlyIconRequired && this.props.icon === 'clear';
  }

  declineRequest = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.confirmationRequired) {
      this.setState({ leaving: true });
    } else {
      this.performAction(false, event);
    }
  };

  moveToSpaceIndex = () => {
    if (isPrivateSpace(this.props.space)) {
      let url = isWorkspaceView() ? '/explore' : '/spaces';
      this.props.navigate(url);
    }
  };

  render() {
    let extraClass = `${this.props.extraClass} ${
      this.isRedIconRequired() ? 'text-red' : ''
    } ${this.addDisabledClass()}`;
    return (
      <>
        <OsBtn
          name={this.getNameForButton()}
          text={this.getText()}
          label={this.props.label}
          icon={this.props.icon}
          extraClass={extraClass}
          onClick={this.declineRequest}
          associatedEntity={this.props.space}
          data-hover={!isTouchSupported()}
          disabled={this.state.requestInProgress}
        />
        {this.state.leaving && (
          <LeaveConfirmationModal
            entityType='space'
            onClose={this.closeModal}
            leaveEntity={this.performAction}
            entity={this.props.space}
            requestingUser={this.props.requestingUser}
            headerTitle={this.props.headerTitle}
            primaryButtonText={this.props.primaryButtonText}
            secondaryButtonText={this.props.secondaryButtonText}
            svgIcon={this.props.svgIcon}
            type={this.props.type || 'default'}
          />
        )}
      </>
    );
  }
}
DeclineRequest.defaultProps = {
  icon: 'clear',
  text: 'Ignore',
  onlyIconRequired: false,
  btnType: 'BtnIcon',
  onSuccess: () => {},
};
DeclineRequest = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { flashError, updateCurrentUserGraph },
)(DeclineRequest);
DeclineRequest = withApollo(DeclineRequest);
DeclineRequest = withRouter(DeclineRequest);
export default DeclineRequest;
