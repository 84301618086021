import { ApolloLink } from 'apollo-link';
import { from, HttpLink } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import possibleTypes from './schema/fragmentTypes.json';
import Authenticate from 'app/services/Authenticate.js';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import ActionCable from 'actioncable';
import PusherLink from 'graphql-ruby-client/subscriptions/PusherLink';
import Pusher from 'pusher-js';
import store from 'app/store';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import pako from 'pako';

import { logout } from 'app/actions/authentication';
import { updateBannerValues } from 'app/actions/universalBanner';
import { setCurrentUserSessionValidity } from 'app/actions/currentUserSessionValid';
import { openInfoModal } from 'app/actions/infoModal';
import { isArray, isNull } from 'app/utils/osLodash';
import { getAppSource } from 'app/utils/appHelper';

import { PATH_AFTER_UNAUTHORIZATION } from 'app/constants';
const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const USER_STATUS_URL_MAPPER = {
  'user-pending': 'pending',
  unauthorized: 'unauthorized',
  'user-restricted': 'restricted',
  'user-not-referred': 'not-referred',
};

const pusherLink = new PusherLink({
  pusher: pusherClient,
  decompress: function (compressed) {
    // Decode base64
    const data = atob(compressed)
      .split('')
      .map((x) => x.charCodeAt(0));
    // Decompress
    const payloadString = pako.inflate(new Uint8Array(data), { to: 'string' });
    // Parse into an object
    return JSON.parse(payloadString);
  },
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL_HOST + '/doctors/graphql',
});

const jwt_encode = require('jwt-encode');

const authorizationLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists

  const doctorToken =
    Authenticate.getItem('token') || Authenticate.getUserAuthValueFromCookie();
  const guestToken = Authenticate.getItem('guestToken');

  let tokenHeader = null,
    token;
  token = doctorToken || guestToken;
  if (token) tokenHeader = `Bearer ${token}`;

  const state = store.getState();

  // return the headers to the context so httpLink can read them
  operation.setContext({
    headers: {
      'Custom-Authorization': tokenHeader,
      'APP-VERSION': process.env.REACT_APP_VERSION,
      'Device-Identifier': state.device.token,
      'Workspace-Identifier': state.workspace.identifier || '',
      'Shared-link-data': jwt_encode(state.guestUser.metaInfo || {}, ''),
      'App-Source': getAppSource(),
    },
  });
  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, response, operation, forward, ...other }) => {
    let { pathname } = window.location,
      errorPath = '';
    if (['permission-denied', 'restricted'].includes(graphQLErrors)) {
      errorPath = PATH_AFTER_UNAUTHORIZATION;
    } else if (
      isArray(graphQLErrors) &&
      graphQLErrors.some((e) => e.message === 'workspace-access-denied')
    ) {
      return (window.location.href = `${process.env.REACT_APP_FRONTEND_APP_URL}/${window.location.pathname}`);
    } else if (
      isArray(graphQLErrors) &&
      graphQLErrors.some((e) => e.message === 'patient-workspace-access-denied')
    ) {
      return (window.location = '/care');
    } else if (USER_STATUS_URL_MAPPER[graphQLErrors]) {
      errorPath = '/status/' + USER_STATUS_URL_MAPPER[graphQLErrors];
    } else if (graphQLErrors === 'system-upgrade') {
      errorPath = '/system_upgrade';
    } else if (
      isArray(graphQLErrors) &&
      graphQLErrors.some((e) => e.message === 'not-found')
    ) {
      errorPath = '/not_found';
    } else if (graphQLErrors === 'access-revoke') {
      let redirectTo = window.location.pathname.split('/')[1],
        callback = store.getState().shareModal.open
          ? () => {
              window.location.reload();
            }
          : () => {
              window.location = `/${redirectTo}`;
            },
        options = { onSuccess: callback, onClose: callback };

      store.dispatch(openInfoModal('general', 'access_revoke', options));
    } else if (graphQLErrors === 'profile_incomplete') {
      errorPath = '/profile_incomplete';
    }

    if (errorPath && pathname !== errorPath) {
      window.location = errorPath;
    }
  },
);

const currentReleaseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response, a) => {
    let newRelease =
        +operation.getContext().response.headers.get('Current-Release') || 0,
      currentRelease =
        store.getState().systemConfig.configs['current_ortho_release'];
    if (currentRelease && currentRelease.value < newRelease) {
      store.dispatch(updateBannerValues('releaseLaunch', { active: true }));
    }
    return response;
  });
});

const currentUserSessionValidLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    let currentUserSessionValid = operation
      .getContext()
      .response.headers.get('Current-User-Session-Valid');
    currentUserSessionValid =
      currentUserSessionValid ||
      (isNull(currentUserSessionValid) &&
        String(isNull(currentUserSessionValid)));
    setTimeout(() => {
      store.dispatch(setCurrentUserSessionValidity(currentUserSessionValid));
    }, 1000);
    return response;
  });
});

const forceReloadLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response, a) => {
    const isForceReloadRequired =
      operation.getContext().response.headers.get('Force-Reload') === 'true' ||
      false;

    if (isForceReloadRequired) window.location.reload();

    return response;
  });
});

const handleUnauthorizedUserPendingLink = new ApolloLink(
  (operation, forward) => {
    return forward(operation).map((response) => {
      try {
        const data = response;
        if (data.errors && data.errors === 'unauthorized')
          store.dispatch(logout(true));
      } finally {
      }

      return response;
    });
  },
);

const cache = new InMemoryCache({ possibleTypes });

persistCache({
  cache,
  storage: window.localStorage,
});

const link = from([
  handleUnauthorizedUserPendingLink,
  currentUserSessionValidLink,
  currentReleaseLink,
  forceReloadLink,
  authorizationLink,
  errorLink,
  pusherLink,
  httpLink,
]);

const Client = new ApolloClient({
  link: link,
  cache,
});

export default Client;
