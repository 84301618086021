import { CLOSE_INFO_MODAL, OPEN_INFO_MODAL, UPDATE_INFO_MODAL } from './types';

export function openInfoModal(source, modalType, options = {}) {
  return {
    type: OPEN_INFO_MODAL,
    source,
    modalType,
    options,
  };
}

export function closeInfoModal() {
  return { type: CLOSE_INFO_MODAL };
}

export function updateInfoModalOptions(options = {}) {
  return { type: UPDATE_INFO_MODAL, options };
}
