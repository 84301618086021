import React, { useContext, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import CustomDropdown from 'app/components/Shared/CustomDropdown';

import { flashError } from 'app/actions/flashMessage';
import { isWorkspaceAuthorOrEditor } from 'app/utils/Workspace/generalHelper';
import LabelListing from 'app/components/LabelsModal/LabelListing';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { getLabelNameWithParent } from 'app/utils/labelHelper';

const ATTACH_LABEL_WITH_ENTITY_MUTATION = queryLoader(
  'app/graphql/mutations/AttachLabelWithEntity.gql',
);

const AssociateLabelDropdown = (props) => {
  const [closeDropdown, toggleDropdown] = useState('');
  const { person_labels } = useContext(WorkspaceContext);

  function onNewLabelSuccess(label) {
    attachLabel(label);
  }

  function attachLabelInfo() {
    if (props.entity.entity_label?.label) {
      let selectedLabel = props.entity.entity_label.label;
      return {
        title: getLabelNameWithParent(selectedLabel, person_labels),
        color: selectedLabel.color,
      };
    } else {
      return { title: 'Add Label' };
    }
  }
  const handleSubLabelClick = (subLabel) => {
    if (props.onLabelClick) {
      props.onLabelClick(subLabel);
    } else {
      attachLabel(subLabel);
    }
  };

  const attachLabel = (label) => {
    if (label?.sub_labels?.length > 0) return;
    props
      .attachLabelWithEntityMutation({
        variables: {
          labelId: +label.id,
          entityId: props.entity.id,
          entityType: props.entity.__typename,
        },
      })
      .then(({ data }) => {
        if (data.attachLabelWithEntity.success) {
          if (props.afterLabelClick) {
            props.afterLabelClick(label);
          }
        } else {
          flashError(data.attachLabelWithEntity.error, false);
        }
      });
  };

  function renderAllLabels() {
    return (
      <LabelListing
        labels={person_labels}
        onLabelClick={props.onLabelClick || attachLabel}
        onSubLabelClick={handleSubLabelClick}
        onNewLabelSuccess={onNewLabelSuccess}
        selectedLabel={props.entity?.entity_label?.label}
        position={props.position}
      />
    );
  }

  return (
    <CustomDropdown
      chevronIcon='chevron-caret'
      name='entiy_labels'
      dropdownInfo={attachLabelInfo()}
      className='cs-dropdown cs-file-dropdown'
      closeDropDown={closeDropdown}
      disableClick={!isWorkspaceAuthorOrEditor()}>
      {renderAllLabels()}
      <span className='d-none'>hi</span>
    </CustomDropdown>
  );
};

AssociateLabelDropdown.defaultProps = {
  entity: {},
};
const AssociateLabelDropdownContainer = graphql(
  ATTACH_LABEL_WITH_ENTITY_MUTATION,
  { name: 'attachLabelWithEntityMutation' },
)(AssociateLabelDropdown);
export default AssociateLabelDropdownContainer;
