import { createPortal } from 'react-dom';
import React, { useState } from 'react';
import parse from 'html-react-parser';

const DisplayContentPortal = (props) => {
  const { option } = props;
  const element = document.getElementsByClassName(
    'editor-template-selector',
  )[0];

  const [templatePreview, setTemplatePreview] = useState(false);

  const handleTemplatePreview = () => {
    setTemplatePreview(!templatePreview);
  };

  return (
    <div
      className={'template-option'}
      onMouseEnter={handleTemplatePreview}
      onMouseLeave={handleTemplatePreview}>
      <div>
        <span>{option.title}</span>
      </div>
      {templatePreview &&
        element &&
        createPortal(
          <div className={'template-description-overlay'}>
            <div className={'template-description-header'}>
              <span>{option.title}</span>
              <span>Preview</span>
            </div>
            <div className={'template-description-content'}>
              {parse(option.content)}
            </div>
          </div>,
          element,
        )}
    </div>
  );
};

export default DisplayContentPortal;
