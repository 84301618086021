import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import {
  avatarAttributes,
  getOtherParticipants,
} from 'app/utils/entitiesHelper';
import { pluralize } from 'app/utils/generalHelper';
import { replaceEmojiReactions } from 'app/utils/emojiHelper';
import { setActiveConversation } from 'app/actions/activeConversation';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import Avatar from 'app/components/Shared/Avatar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import 'app/components/DirectMessageListing/dm-conversattion-list.scss';

class DirectMessageRow extends Component {
  onFloaterListingRowClick(conversation) {
    this.props.setActiveConversation(conversation);
  }

  renderAvatar(conversation, entity) {
    return (
      <div className='avatar-wrapper'>
        {conversation.unread && <span className='oval' />}
        <Avatar
          {...avatarAttributes(entity)}
          className='avatar avatar-initials'
        />
      </div>
    );
  }

  renderAvatars(conversation) {
    const { kind } = conversation,
      otherParticipants = getOtherParticipants(conversation),
      otherParticipant = otherParticipants[0],
      isNormalGroupConversation = this.isNormalGroupConversation();

    return (
      <div className='user-avatar'>
        {kind === 'normal' && this.renderAvatar(conversation, otherParticipant)}
        {isNormalGroupConversation &&
          this.renderAvatar(conversation, otherParticipants[1])}
      </div>
    );
  }

  getConversationName(otherParticipants) {
    let { kind } = this.props,
      conversationName = '';
    if (kind === 'normal') {
      conversationName =
        otherParticipants[0].name || otherParticipants[0].email;
      if (otherParticipants.length > 1)
        conversationName += ` & ${
          otherParticipants.length - 1
        } team ${pluralize(otherParticipants.length - 1, {
          singular: 'member',
          plural: 'members',
        })}`;
    }

    return conversationName;
  }

  isNormalGroupConversation() {
    return (
      this.props.kind === 'normal' &&
      getOtherParticipants(this.props).length > 1
    );
  }

  getMessage(conversation) {
    if (
      conversation.participant_emoji_reaction_user &&
      conversation.latest_message &&
      conversation.participant_emoji_reaction_user.created_at >
        conversation.latest_message.created_at
    ) {
      return replaceEmojiReactions(
        conversation.participant_emoji_reaction_user.text,
      );
    } else if (conversation.latest_message) {
      return conversation.latest_message.highlighted_content;
    }
  }

  renderChatContent(conversation) {
    const otherParticipants = getOtherParticipants(conversation);
    let conversationName =
      otherParticipants &&
      otherParticipants.length &&
      this.getConversationName(otherParticipants);
    return (
      <>
        <div className='ac-user-name'>{conversationName}</div>
        {false &&
          conversation.unread &&
          conversation.unread_message_count > 0 && (
            <span className='lt-count pull-right'>
              {conversation.unread_message_count}
            </span>
          )}
      </>
    );
  }

  renderMobileChevron() {
    return (
      <div className={'conversion-arrow-icon'}>
        <OrthoIcon
          name='chevron-right'
          dataHoverNotRequired={true}
          defaultClass='sidebar-arrow-icon visible-mobile'
        />
      </div>
    );
  }

  renderFullPageListingRow(conversation) {
    return (
      <Link to={withWorkspaceIdentifier(`/team/dm/${conversation.id}`, true)}>
        {this.renderAvatars(conversation)}
        {this.renderChatContent(conversation)}
        {this.renderMobileChevron()}
      </Link>
    );
  }

  renderRow(conversation) {
    return this.renderFullPageListingRow(conversation);
  }

  renderConversationListItem = (conversation) => {
    return (
      <li
        key={conversation.id}
        id={'team-' + conversation.id}
        className={
          'conversation-list-row ' +
          classnames({
            active:
              conversation.id.toString() === this.props.activeConversationId,
            unread: conversation.unread,
          })
        }>
        {this.renderRow(conversation)}
      </li>
    );
  };

  render() {
    return this.renderConversationListItem(this.props);
  }
}
DirectMessageRow = withRouter(DirectMessageRow);
DirectMessageRow = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {
    setActiveConversation,
  },
)(DirectMessageRow);
export default DirectMessageRow;
