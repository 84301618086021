import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import ActionCable from 'app/actioncable/actioncable';

import Icon from 'app/components/Svg';
import NotificationCard from './NotificationCard';
import PasswordUpdateNotification from './PasswordUpdateNotification';

import { isSameDay } from 'app/utils/timeHelper';
import { translate } from 'app/actions/flashMessage';
import { getTotalUnreadNotificationCount } from 'app/utils/userHelper';

import { isUrlOfSameDomain, replaceCurrentDomain } from 'app/utils/urlHelper';

class NotificationsView extends Component {
  constructor(props) {
    super(props);
    this.notificationDateVisible = null;
  }

  readNotification(id) {
    ActionCable.notifications?.read(id);
  }

  readAllNotifications() {
    ActionCable.notifications?.read_all();
  }

  onClick = (event, notification) => {
    let link = event.target.href
        ? event.target.pathname + event.target.search + event.target.hash
        : notification.link,
      fullLink = event.target.href || notification.link;

    event.preventDefault();
    event.stopPropagation();
    notification.unread && this.readNotification(notification.id);

    if (isUrlOfSameDomain(fullLink)) {
      this.props.navigate(replaceCurrentDomain(link));
    } else {
      window.location.href = fullLink;
    }
  };

  renderMarknotificationsReadLink() {
    if (getTotalUnreadNotificationCount(this.props.currentUser.graph) > 0) {
      return (
        <span
          className='ms-auto a-link pointer'
          onClick={this.readAllNotifications}>
          Mark all as read
        </span>
      );
    } else {
      return <span className='ms-auto'>{translate('ALL_CAUGHT_UP')}</span>;
    }
  }

  renderNoNotificationsContainer() {
    return (
      <div className='no-cases-block notifications'>
        <div className='no-cases-image-block'>
          <Icon name='noNotifications' />
        </div>
        <div className='no-cases-message-block'>No notifications so far</div>
      </div>
    );
  }

  renderNotifications() {
    return this.props.results.map(this.renderNotificationCard);
  }

  isDateVisible(notification, index) {
    if (
      index === 0 ||
      !isSameDay(notification.visible_at, this.notificationDateVisible)
    ) {
      this.notificationDateVisible = notification.visible_at;
      return true;
    }
  }

  renderNotificationCard = (notification, index) => {
    return (
      <NotificationCard
        key={notification.id}
        {...notification}
        onClick={this.onClick}
        groupingDateVisible={this.isDateVisible(notification, index)}
        readNotification={this.readNotification}
      />
    );
  };

  render() {
    if (
      this.props.results.length === 0 &&
      !this.props.currentUser.graph.update_password_notification_required
    ) {
      return this.renderNoNotificationsContainer();
    } else {
      return (
        <div className='collection-page communication notifications-page pb-3'>
          <div className='nf-container'>
            {this.props.currentUser.graph
              .update_password_notification_required && (
              <PasswordUpdateNotification />
            )}
            <div className='nf-header d-flex align-items-center lh-normal mb-3 mt-2'>
              {this.renderMarknotificationsReadLink()}
            </div>
            {this.renderNotifications()}
          </div>
        </div>
      );
    }
  }
}

NotificationsView = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(NotificationsView);
NotificationsView = withApollo(NotificationsView);
NotificationsView = withRouter(NotificationsView);

export default NotificationsView;
