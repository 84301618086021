import OsBtn from 'app/components/OsBtn';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
// import patientPaywall from 'app/images/paywall/patient_paywall.svg';
import { useSelector } from 'react-redux';
import { getWorkspaceFeatured } from 'app/selectors/workspace';
import { isUnifiedEnabled } from 'app/utils/Workspace/generalHelper';
import { PaywallContext } from 'app/context/PaywallContext';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { getLoomEmbedUrl, isLoomUrl } from 'app/utils/fileHelper';
import {
  activityDashboardPaywallVideo,
  conversationPaywallVideo,
  foldersPaywallVideo,
  groupPaywallVideo,
  myInboxPaywallVideo,
  patientPaywallVideo,
  taskPaywallVideo,
  teamInboxPaywallVideo,
  partnerDetailIntroVideo,
} from 'app/components/NonSubscribedView/constant';

import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

import 'app/components/NonSubscribedView/paywall.scss';

const ALLOWED_SOURCE = [
  'partners',
  'partnersMobile',
  'tasks',
  'myTasksManager',
  'teamTasksManager',
  'tasksManager',
  'boardTasksManager',
  'space-detail-discussion-care-patientview',
  'unifiedMyTasksInbox',
  'unifiedTeamPartnersInbox',
  'unifiedInboxPartner',
];

const PayWallView = ({
  source,
  children,
  extraClass,
  forcePaywall = false,
  renderPrimaryCTA,
}) => {
  const user = useSelector((state) => state.currentUser);
  const [openPaywall, setOpenPaywall] = useState(false);
  const isFeatured = useSelector(getWorkspaceFeatured);
  const location = useLocation();
  const navigate = useNavigate();
  const identifier = getWorkspaceIdentifier();
  useEffect(() => {
    if (location.pathname) {
      setOpenPaywall(false);
    }
  }, [location.pathname]);

  const renderLoomVideo = (loomURL) => {
    if (isLoomUrl(loomURL)) {
      let embedUrl = getLoomEmbedUrl(loomURL);
      return (
        <div class='lo-emb-vid'>
          <iframe src={embedUrl} allowFullScreen title={source}></iframe>
        </div>
      );
    }
  };

  const displayRouteImage = () => {
    switch (source) {
      case 'folders':
      case 'unifiedFolders':
        return renderLoomVideo(foldersPaywallVideo);
      case 'teamWindow':
      case 'teamDirectMessenger':
        return renderLoomVideo(conversationPaywallVideo);
      case 'teamGroupMessenger':
        return renderLoomVideo(groupPaywallVideo);
      case 'exploreCareWorkspace':
        if (isUnifiedEnabled()) {
          return renderLoomVideo(activityDashboardPaywallVideo);
        }
        return renderLoomVideo(patientPaywallVideo);
      case 'unifiedInbox':
        return renderLoomVideo(myInboxPaywallVideo);
      case 'unifiedTeamInbox':
        return renderLoomVideo(teamInboxPaywallVideo);
      case 'userDirectory':
        return renderLoomVideo(patientPaywallVideo);
      case 'tasksManager':
        return renderLoomVideo(taskPaywallVideo);
      case 'partnersDetail':
        return renderLoomVideo(partnerDetailIntroVideo);
      default:
        return null;
    }
  };

  const shouldOpenPaywall = () => {
    if (!isFeatured) {
      return true;
    }
    return false;
  };

  const closePaywall = () => {
    setOpenPaywall(false);
  };

  const getHeading = () => {
    switch (source) {
      case 'folders':
      case 'unifiedFolders':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Instantly access all your files and folders!',
          bottom:
            'Store and access all your files and folders in one place making external filing sharing solutions a thing of the past. ',
        };
      case 'partnersDetail':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Effortlessly connect and collaborate with partners!',
          bottom:
            'Enhance your team’s efficiency by seamlessly working with other doctors and their teams in the same collaborative space.',
        };
      case 'teamWindow':
      case 'teamDirectMessenger':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Instantly chat with anyone on your team!',
          bottom: `Chat with anyone in your team instantly, and create tasks directly from your conversation. No more jumping from tool to tool!`,
        };
      case 'teamGroupMessenger':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Activate Seamless Group Interaction!',
          bottom: `Create smaller teams and groups to focus on projects at hand. Groups smartly organize your project files, tasks and communication in one place. `,
        };
      case 'exploreCareWorkspace':
        if (isUnifiedEnabled()) {
          return {
            top: `Hi ${user.graph.first_name}!`,
            middle: 'Stay informed with your Activity Dashboard!',
            bottom:
              'You will always be in the loop with real-time oversight of clinic activities for complete transparency and openness.',
          };
        }
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'You found the Patients section.',
          bottom:
            'Bring unlimited patients from your clinic on to Cloudberry today.',
        };
      case 'unifiedInbox':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'A Super powered Inbox for you to get things done!',
          bottom:
            'Give your attention to the things that matter the most to you by unlocking your smart inbox to never miss a beat and see every notification in one place.',
        };
      case 'unifiedTeamInbox':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Always stay on top of things as a team!',
          bottom:
            'Everything actionable for your team comes into a single place for everyone to see and anyone to complete. Learn more below.',
        };
      case 'userDirectory':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Make your Patient Engagement World Class!',
          bottom:
            'Engage with your patients right from Cloudberry in an instant. A text message brings them into your world of care like never before. You need to see it to believe it.',
        };
      case 'tasksManager':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Unlock unlimited Task Management',
          bottom:
            'Bring your entire team and manage all your tasks and workflows seamlessly together right here on Cloudberry today.',
        };
      case 'easyRX':
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Effortlessly connect and collaborate with EasyRX!',
          bottom: 'Access all your EasyRX Prescriptions in one place.',
        };
      default:
        return {
          top: `Hi ${user.graph.first_name}!`,
          middle: 'Its time to upgrade.',
          bottom: 'Bring all your team members on to Cloudberry today.',
        };
    }
  };

  // Redirect the user to the partners page if the workspace is not a subscriber member.
  useEffect(() => {
    if (!isFeatured && !isCareWorkspaceView()) {
      navigate(`/${identifier}/partners`, {
        relative: true,
      });
    }
  }, []);

  const claimTrial = () => {
    if (window.Intercom !== undefined) {
      window.Intercom(
        'showNewMessage',
        'Hi Cloudberry Team, We would like to claim our 3-month free trial',
      );
    }
  };

  let containerClass = 'paywall-container ';
  containerClass += extraClass || '';

  return (
    <PaywallContext.Provider
      value={{
        openPaywall,
        shouldOpenPaywall,
        setOpenPaywall,
      }}>
      {!forcePaywall &&
      !openPaywall &&
      (isFeatured || ALLOWED_SOURCE.includes(source)) ? (
        children
      ) : (
        <div className={containerClass}>
          <div className='p-wrapper'>
            {openPaywall && (
              <div onClick={closePaywall} className='close-paywall'>
                <OrthoIcon name='close' />
              </div>
            )}
            <h4 className='p-heading'>{getHeading().top}</h4>
            <h2 className='p-middle text-center'>{getHeading().middle}</h2>
            <h5 className='p-bottom'>{getHeading().bottom}</h5>
            {renderPrimaryCTA?.() || (
              <OsBtn
                type={'button'}
                htmlTag={'button'}
                extraClass={'web-view-btn btn-save-info'}
                name={'BtnPrimary'}
                text={'CLAIM 3-MONTH FREE TRIAL'}
                onClick={claimTrial}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            {displayRouteImage('folders')}
          </div>
        </div>
      )}
    </PaywallContext.Provider>
  );
};

export default PayWallView;
