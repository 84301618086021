import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import emptycarespace from 'app/images/emptycarespace.png';
import RedirectToDefault from 'app/components/RedirectToDefault';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { translate } from 'app/actions/flashMessage';
import { removeClassFromElement } from 'app/utils/domHelper';
import ConnectToSupport from 'app/components/ConnectToSupport';

class NoRecordsPresent extends PureComponent {
  constructor(props) {
    super(props);
    removeClassFromElement('login', document.body);
  }

  isAccessRevoked() {
    return window.location.pathname === '/care/access_revoked';
  }

  getHeader() {
    if (this.isAccessRevoked()) {
      return 'Your access has been revoked';
    } else {
      return 'No active workspaces';
    }
  }

  getIllustrationKey() {
    if (this.isAccessRevoked()) {
      return 'CARE_ACCESS_REVOKED_MESSAGE';
    } else {
      return isCareWorkspaceView()
        ? 'CARE_NO_ACCESS_MESSAGE'
        : 'CLINIC_NO_ACCESS_MESSAGE';
    }
  }

  render() {
    const { graph } = this.props.currentUser;
    if (!graph) return <RedirectToDefault />;
    const key = this.getIllustrationKey();

    return (
      <div className='collection-page pulses'>
        <div className='container-fluid px-52 xl-container '>
          <div className='row carenotpresent '>
            <div className='col'>
              <img
                src={emptycarespace}
                alt='WebP rules.'
                width='480'
                className='mb-4'
              />
              <h4 className='fwblack mb-3'>{this.getHeader()}</h4>
              <p>{translate(key)}</p>
              <ConnectToSupport
                text='Contact Support Team'
                viewType='button'
                extraClass='with-border osbtn osbtn-secondary with-bg without-underline'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
NoRecordsPresent = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {},
)(NoRecordsPresent);
export default NoRecordsPresent;
