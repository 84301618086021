import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Icon from 'app/components/Svg';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { sendForgotPassword } from 'app/actions/authentication';
import { translate } from 'app/actions/flashMessage';

import { login } from 'app/actions/authentication';
import { updateGuestUserData } from 'app/actions/guestUser';

import {
  fieldRequired,
  passwordMustMatch,
  passwordMustMatchValidityRules,
  composeValidators,
} from 'app/utils/validationHelper.js';
import { openInfoModal } from 'app/actions/infoModal';

const RESET_PASSWORD_MUTATION = queryLoader('app/graphql/ResetPassword.gql');
const RECOVERY_TOKEN_QUERY = queryLoader('app/graphql/RecoveryToken.gql');

class ResetPassword extends Component {
  state = {
    redirect: false,
    requestInProgress: false,
  };

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data.loading)
      this.props.updateGuestUserData(this.props.data.resetPasswordToken);
  }

  componentWillUnmount() {
    this.props.updateGuestUserData(null);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  redirectToLogin = () => {
    this.props.navigate('/login');
  };

  onSubmit = (values) => {
    var searchParams = new URLSearchParams(this.props.location.search);
    this.setState({ requestInProgress: true });
    return this.props
      .resetPasswordMutation({
        variables: {
          password: values.password,
          reset_password_token: searchParams.get('token'),
        },
      })
      .then(({ data: { resetPasswordNew } }) => {
        this.setState({ requestInProgress: false });
        if (resetPasswordNew.errors) {
          let errors = {};
          errors[resetPasswordNew.errors[0].field] =
            resetPasswordNew.errors[0].message;
          return errors;
        } else if (!resetPasswordNew.success) {
          this.props.openInfoModal('general', 'reset_password_link_expired', {
            onSuccess: this.redirectToLogin,
          });
        } else {
          let variables = {
            token: resetPasswordNew.token,
            remember: false,
            email: resetPasswordNew.entity.email,
            user: resetPasswordNew.entity,
          };
          this.props.login(variables);
        }
      });
  };

  loadingRender() {
    return (
      <div className='token-text'>
        <div className='token-text-helper m-auto'>
          Please wait while we are verifying your token.
        </div>
      </div>
    );
  }

  sendResetPasswordMail = () => {
    const searchParams = new URLSearchParams(this.props.location.search),
      email = searchParams.get('email'),
      phoneNumber = searchParams.get('phone_number');

    if(email || phoneNumber)
      this.props.sendForgotPassword(email || phoneNumber);
  };

  invalidRender() {
    return (
      <>
        <div className='token-text'>
          <div className=''>
            <div className='text-center form-heading login-form-heading'>
              Password reset link has expired
            </div>
            <div className='token-text-helper'>
              Sorry, the link you are trying to use has expired. Please request
              a new password using reset link below.
            </div>
          </div>
          <div className='reset-password-action'>
            <OsBtn
              name='BtnGhost'
              text='Reset Password'
              extraClass={'web-view-btn'}
              htmlTag='button'
              onClick={this.sendResetPasswordMail}
            />
          </div>
        </div>
      </>
    );
  }

  formRender() {
    const inactive =
      this.props.pristine || this.props.submitting || this.props.invalid;
    const btnClass = inactive ? 'osbtn-disabled disabled-only' : ' ';

    return (
      <>
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='text-center form-heading login-form-heading'>
                Set up new password
              </div>
              <div className=''>
                <div className=''>
                  <Field
                    name='password'
                    component={OsField}
                    osType='password'
                    label='New password*'
                    hintRequired={true}
                    onFocus={this.clearError}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatchValidityRules,
                    )}
                    formGroupExtraClass='login_password reset_login_password'>
                    <span className='reset-password-hint'>
                      {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                    </span>
                  </Field>
                </div>

                <div className=''>
                  <Field
                    name='password_validate'
                    component={OsField}
                    osType='password'
                    label='Confirm new password*'
                    onFocus={this.clearError}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatch,
                    )}
                    formGroupExtraClass='login_password'
                  />
                </div>
              </div>
              <div className='text-center reset-password-action pt-1'>
                <OsBtn
                  name='BtnPrimary'
                  text='Save New Password'
                  type='submit'
                  disabledView={inactive}
                  extraClass={`web-view-btn ${btnClass}`}
                  disabled={this.state.requestInProgress}
                  loaderRequired={this.state.requestInProgress}
                  htmlTag='button'
                />
              </div>
            </form>
          )}
        />
      </>
    );
  }

  renderContent() {
    if (this.props.data.loading) {
      return this.loadingRender();
    } else if (!this.props.data.resetPasswordToken) {
      return this.invalidRender();
    } else {
      return this.formRender();
    }
  }

  render() {
    if (
      this.props.currentUser.token ||
      this.state.redirect ||
      !this.props.location.search
    ) {
      return <Navigate to='/login' />;
    }
    return (
      <ImageFormLayout
        leftBar={
          <Icon
            name='changePassword'
            width='520'
            height='100%'
            style={{ maxWidth: '100%' }}
          />
        }
        rightBar={this.renderContent()}
        source='reset-password'
      />
    );
  }
}

ResetPassword = connect(
  ({ currentUser, systemConfig, device }) => ({
    currentUser,
    systemConfig,
    device,
  }),
  { login, openInfoModal, sendForgotPassword, updateGuestUserData },
)(ResetPassword);
ResetPassword = compose(
  graphql(RECOVERY_TOKEN_QUERY, {
    options: (props) => {
      var searchParams = new URLSearchParams(props.location.search);
      return {
        fetchPolicy: 'cache-and-network',
        variables: {
          reset_password_token: searchParams.get('token'),
        },
      };
    },
  }),

  graphql(RESET_PASSWORD_MUTATION, { name: 'resetPasswordMutation' }),
)(ResetPassword);

ResetPassword = withRouter(ResetPassword);
export default ResetPassword;
