import MixpanelResourceMapper from './MixpanelResourceMapper';

import { lowerCase, pick, snakeCase } from 'app/utils/osLodash';

// const mixpanel = require('mixpanel-browser');
const COMMON_ENTITY_ATTRIBUTES = ['entity_id', 'entity_type'];
const MIXPANEL_API_TOKEN = process.env.REACT_APP_MIXPANEL_API_TOKEN || 'test';

const MIXPANEL_REGISTER_ATTRIBUTES = ['email', 'id', 'name'];
const MIXPANEL_EVENT_RESOURCE_ATTRIBUTES_MAPPER = {
  show: {
    case: {
      attributes: ['case_id', 'case_title', 'doctor_name'],
      label: 'Show Case',
    },
    board: { attributes: ['space_name', 'doctor_name'], label: 'Show Space' },
    tool: { attributes: ['tool_name', 'tool_brand'], label: 'Show Tool' },
    user: { attributes: ['doctor_name'], label: 'Show Doctor' },
  },
  share: {
    case: { attributes: ['case_id', 'network'], label: 'Share Case' },
    board: { attributes: ['space_name', 'network'], label: 'Share Space' },
    tool: {
      attributes: ['tool_name', 'tool_brand', 'network'],
      label: 'Share Tool',
    },
    user: { attributes: ['doctor_name', 'network'], label: 'Share Doctor' },
  },
  follow: {
    case: { attributes: ['case_id'], label: 'Follow Case' },
    board: { attributes: ['space_name'], label: 'Follow Space' },
    tool: { attributes: ['tool_name', 'tool_brand'], label: ' Follow Tool' },
    user: { attributes: ['doctor_name'], label: 'Follow Doctor' },
  },
  like: {
    case: { attributes: ['case_id'], label: 'Like Case' },
    board: { attributes: ['space_name'], label: 'Like Space' },
    tool: { attributes: ['tool_name', 'tool_brand'], label: 'Like Tool' },
  },
  add_to_space: {
    case: { attributes: ['case_id', 'space_id'], label: 'Add Case To Space' },
    board: {
      attributes: ['space_name', 'doctor_name'],
      label: 'Add Space to Space',
    },
    tool: {
      attributes: ['tool_name', 'tool_brand'],
      label: 'Add Tool to Space',
    },
    user: { attributes: ['doctor_name'], label: 'Add Doctor to Space' },
  },
  show_related_cases: {
    case: { attributes: ['case_id'], label: 'Show All Related Cases' },
  },
  show_related_spaces: {
    case: { attributes: ['case_id'], label: 'Show All Related Spaces' },
  },
  show_related_case: {
    case: {
      attributes: ['case_id', 'selected_case_id'],
      label: 'Show Related Case',
    },
  },
  case_full_screen: {
    case: { attributes: ['case_id'], label: 'Case Fullscreen' },
  },
  show_related_space: {
    case: { attributes: ['case_id', 'space_id'], label: 'Show Related Space' },
  },
  appliance_logs_expand_all: {
    case: { attributes: ['case_id'], label: 'Case Expand All' },
  },
  appliance_log_expand: {
    case: { attributes: ['case_id', 'type'], label: 'Case-Expand' },
  },
  case_tags_all: {
    case: { attributes: ['case_id'], label: 'Case Tags All' },
  },
  case_tag_show: {
    case: { attributes: ['case_id', 'tag'], label: 'Case Tag Show' },
  },
  add_comment: {
    board: {
      attributes: ['entity_id', 'entity_type', 'type', 'category', 'threaded'],
      label: 'Comment',
    },
    case: {
      attributes: ['entity_id', 'entity_type', 'type', 'category', 'threaded'],
      label: 'Comment',
    },
    comparison: {
      attributes: ['entity_id', 'entity_type', 'type', 'category', 'threaded'],
      label: 'Comment',
    },
    pulse: {
      attributes: ['entity_id', 'entity_type', 'type', 'threaded'],
      label: 'Comment',
    },
  },
  comment_failed: {
    board: {
      attributes: ['entity_id', 'entity_type', 'reason'],
      label: 'Comment Failed',
    },
    case: {
      attributes: ['entity_id', 'entity_type', 'reason'],
      label: 'Comment Failed',
    },
    comparison: {
      attributes: ['entity_id', 'entity_type', 'reason'],
      label: 'Comment Failed',
    },
    pulse: {
      attributes: ['entity_id', 'entity_type', 'reason'],
      label: 'Comment Failed',
    },
  },
  add_to_toolbox: {
    tool: { attributes: ['tool_name', 'tool_brand'], label: 'Add to Toolbox' },
  },
  tool_detail_nav_action: {
    tool: {
      attributes: ['tool_name', 'tool_brand', 'tab'],
      label: 'Tool Action',
    },
  },
  space_show_more_description: {
    board: { attributes: ['space_name'], label: 'Space Show More Description' },
  },
  space_show_doctor: {
    board: {
      attributes: ['space_name', 'doctor_name'],
      label: 'Space Show Doctor',
    },
  },
  create_space_saved: {
    board: {
      attributes: ['space_name', 'doctor_name'],
      label: 'Create Space Saved',
    },
  },
  show_tool_brand: {
    tool: { attributes: ['tool_name', 'tool_brand'], label: 'Tool Show Brand' },
  },
};

const ENTITY_ATTRIBUTES_MAPPER = {
  Company: ['company_id', 'company_name'],
  Tool: ['tool_id', 'tool_name', 'tool_brand'],
  Case: ['case_id', 'case_title', 'doctor_name', 'doctor_id'],
  Comparison: [
    'comparison_id',
    'comparison_name',
    'first_case_id',
    'first_case_name',
    'first_case_doctor_id',
    'first_case_doctor_name',
    'second_case_id',
    'second_case_name',
    'second_case_doctor_id',
    'second_case_doctor_name',
  ],
  Board: ['space_id', 'space_name', 'doctor_name'],
  User: ['doctor_id', 'doctor_name'],
  Pulse: ['entity_id', 'author_id', 'author_name'],
  Attachment: ['attachment_name'],
};

const ENTITY_NAME_MAPPER = {
  pulse: 'post',
  user: 'doctor',
  board: 'space',
};

// mixpanel.init(MIXPANEL_API_TOKEN);

class MixpanelTracker {
  login(email) {
    this.track('Login', { email: email });
    // mixpanel.time_event('Active Session', { email: email });
  }

  logout(email) {
    this.track('Logout', { email: email });
    this.activeSession(email);
  }

  activeSession(email) {
    this.track('Active Session', { email: email });
  }

  register(user) {
    let data = pick(user, MIXPANEL_REGISTER_ATTRIBUTES);
    // mixpanel.register(data);
    // mixpanel.people.set(data);
  }

  identify(userId, initialData = {}) {
    // mixpanel.identify(userId, initialData);
  }

  track(eventName, data = {}) {
    // mixpanel.track(eventName, data);
  }

  trackOsBtn(eventName, objectOrProperties = {}) {
    this.track(eventName, this.getObjectProperties(objectOrProperties));
  }

  trackInputField(eventName, data = {}) {
    this.track(eventName, data);
  }

  trackLinkClicked(eventName, data = {}) {
    this.track(eventName, this.getObjectProperties(data));
  }

  trackDropdown(eventType, data = {}) {
    this.track(eventType, data);
  }

  trackFailure(eventName, data = {}) {
    this.track(eventName, data);
  }

  isAttachment(entity) {
    return entity.__typename === 'Attachment';
  }

  getKind(entity) {
    return entity.kind;
  }

  getEntityTypeName(entity) {
    return (
      entity.__typename ||
      (entity.obj && entity.obj.__typename) ||
      (entity.feed && entity.feed.objects[0].__typename)
    );
  }

  getReleventDataWithSpecificProperties(entity) {
    let entityTypename = this.getEntityTypeName(entity),
      properties = ENTITY_ATTRIBUTES_MAPPER[entityTypename];
    return pick(MixpanelResourceMapper.attributes(entity), properties);
  }

  trackEntityShow(entity) {
    let entityTypename = this.getEntityTypeName(entity),
      data = this.getReleventDataWithSpecificProperties(entity);
    if (this.isAttachment(entity)) {
      entityTypename = this.getKind(entity);
    }
    let eventName = `entity.show.${
      ENTITY_NAME_MAPPER[snakeCase(entityTypename)] || snakeCase(entityTypename)
    }`;
    this.track(eventName, data);
  }

  trackTabs(eventName, data = {}) {
    this.track(eventName, data);
  }

  trackForEntity(eventName, obj) {
    let mappedEntitiesWithAttributes =
      MIXPANEL_EVENT_RESOURCE_ATTRIBUTES_MAPPER[eventName];
    if (
      mappedEntitiesWithAttributes &&
      mappedEntitiesWithAttributes[lowerCase(obj.__typename)]
    ) {
      let mappedResourceData =
          mappedEntitiesWithAttributes[lowerCase(obj.__typename)],
        attributes = mappedResourceData.attributes,
        data = MixpanelResourceMapper.attributes(obj),
        name = mappedResourceData.label;

      this.track(name, pick(data, attributes));
    }
  }

  trackEntityDragged(eventName, draggedEntityId, entity) {
    let obj = this.getReleventDataWithSpecificProperties(entity),
      data = { ...obj, draggedEntityId };
    this.track(eventName, data);
  }

  trackGeneralAction(eventName, metaInfo, obj) {
    let data = {
      ...this.getReleventDataWithSpecificProperties(obj),
      ...metaInfo,
    };
    this.track(eventName, data);
  }

  getObjectProperties(objectOrProperties) {
    let properties = ENTITY_ATTRIBUTES_MAPPER[objectOrProperties.__typename];
    if (properties)
      return pick(
        MixpanelResourceMapper.attributes(objectOrProperties),
        properties.concat(COMMON_ENTITY_ATTRIBUTES),
      );
    return objectOrProperties;
  }
}

export default new MixpanelTracker();
