import React, { PureComponent } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {
  isCurrentDay,
  isDateInPreviousWeek,
  isDateInThisWeek,
  isYesterday,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { DAY_WINDOW_SEGREGATION } from 'app/constants';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import isArray from 'lodash/isArray';
import { getLabelNameWithParent } from 'app/utils/labelHelper';
import { withWorkspaceContext } from 'app/components/HOC/withWorkspaceContext';

class PatientSegregationList extends PureComponent {
  patientRecordsSegregatedByDate = (records = []) => {
    let recordsList = {
      Today: {
        records: [],
      },
      Yesterday: {
        records: [],
      },
      'Earlier this week': {
        records: [],
      },
      'Last week': {
        records: [],
      },
      'More than 2 weeks ago': {
        records: [],
      },
    };

    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      if (record) {
        const lastActivityOfRecordPerformAt =
          record.care_space?.last_activity_performed_at;
        if (isCurrentDay(lastActivityOfRecordPerformAt)) {
          recordsList.Today.records.push(record);
        } else if (isYesterday(lastActivityOfRecordPerformAt)) {
          recordsList.Yesterday.records.push(record);
        } else if (
          isDateInThisWeek(timeWithFormat(lastActivityOfRecordPerformAt))
        ) {
          recordsList['Earlier this week'].records.push(record);
        } else if (
          isDateInPreviousWeek(timeWithFormat(lastActivityOfRecordPerformAt))
        ) {
          recordsList['Last week'].records.push(record);
        } else {
          recordsList['More than 2 weeks ago'].records.push(record);
        }
      }
    }
    return { recordsList, windowKeys: DAY_WINDOW_SEGREGATION };
  };
  patientRecordsSegregatedByAlphabet = (records) => {
    let recordsList = {};
    let windowKeys = [];
    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      let firstLetter = record.last_name.charAt(0).toUpperCase();
      if (recordsList[firstLetter]) {
        recordsList[firstLetter].records.push(record);
      } else {
        recordsList[firstLetter] = {
          records: [record],
        };
        windowKeys.push(firstLetter);
      }
    }
    return { recordsList, windowKeys };
  };

  patientRecordsSegregatedByLabels = (records) => {
    let recordsList = {};
    let windowKeys = [];
    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      let label = getLabelNameWithParent(
        record.entity_label?.label,
        this.props.context.person_labels,
      );

      if (recordsList[label]) {
        recordsList[label].records.push(record);
      } else {
        recordsList[label] = {
          records: [record],
        };
        windowKeys.push(label);
      }
    }
    return { recordsList, windowKeys };
  };

  getSegregatedRecords = (collectionCards) => {
    const appliedSorting = this.props.sortQuery;
    switch (true) {
      case appliedSorting.startsWith('label_'):
        return this.patientRecordsSegregatedByLabels(collectionCards);
      case appliedSorting.startsWith('name_'):
        return this.patientRecordsSegregatedByAlphabet(collectionCards);
      default:
        return this.patientRecordsSegregatedByDate(collectionCards);
    }
  };
  render() {
    const collectionCards = this.props.collectionCards;
    let { recordsList: segregatedRecords, windowKeys } =
      this.getSegregatedRecords(collectionCards);
    let initialIndex = 0;
    let SEGREGATION_WINDOW = windowKeys || [];
    const fragmentKey = isArray(SEGREGATION_WINDOW)
      ? SEGREGATION_WINDOW.at(-1)
      : 'patient-accordion';
    return (
      <>
        <React.Fragment key={fragmentKey}>
          <Accordion
            as={'div'}
            bsPrefix={'patient-directory-accordion'}
            defaultActiveKey={SEGREGATION_WINDOW}
            alwaysOpen={true}>
            {SEGREGATION_WINDOW.map((day, index) => {
              if (segregatedRecords[day].records.length) {
                return (
                  <Accordion.Item eventKey={day}>
                    <Accordion.Header>
                      <span className={'section-title'}>{day}</span>
                      <OrthoIcon
                        name={`chevron-caret-up`}
                        defaultClass='ms-1'
                      />
                      <OrthoIcon
                        name={`chevron-caret-down`}
                        defaultClass='ms-1'
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      {segregatedRecords[day].records.map((card, index) => {
                        return this.props.renderCardContent(
                          card,
                          false,
                          initialIndex++,
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
              return null;
            })}
          </Accordion>
        </React.Fragment>
      </>
    );
  }
}

PatientSegregationList = withWorkspaceContext(PatientSegregationList);
export default PatientSegregationList;
