import React from 'react';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import useTimePicker from 'app/components/WorkspaceView/SettingView/TimePicker/useTimePicker';
import 'app/components/WorkspaceView/SettingView/TimePicker/time-picker.scss';
function TimePicker({ initialTime, hidePicker, setStartTime, submitBtnText }) {
  const { time, increase, decrease, addDateBtnHandler, error } = useTimePicker({
    initialTime,
    hidePicker,
    setStartTime,
  });
  return (
    <div className='custom-time-block-wrapper'>
      <div className='time-block'>
        <div className='time-block-container'>
          <div className='hour-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('hour')} />
            {time.hour < 10 ? `0${time.hour}` : time.hour}
            <OrthoIcon name='chevron-down' onClick={() => decrease('hour')} />
          </div>
          <div className='minute-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('minute')} />
            {time.minute < 10 ? `0${time.minute}` : time.minute}
            <OrthoIcon name='chevron-down' onClick={() => decrease('minute')} />
          </div>
          <div className='shift-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('shift')} />
            {time.shift}
            <OrthoIcon name='chevron-down' onClick={() => decrease('shift')} />
          </div>
        </div>
        <OsBtn
          name='BtnPrimary'
          text={submitBtnText}
          extraClass='px-16 web-view-btn'
          onClick={addDateBtnHandler}
        />
      </div>
    </div>
  );
}

export default TimePicker;
