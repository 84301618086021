import { useState } from 'react';

const useIntegration = () => {

  const [hostname, setHostname] = useState('');
  const [integrationEnabled, setIntegrationEnabled] = useState(true);

  return {
    hostname,
    setHostname,
    integrationEnabled,
    setIntegrationEnabled,
  }
}

export default useIntegration;