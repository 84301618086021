import React from 'react';
import { useSelector } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import MessageBlock from 'app/components/MessageBlock';

const SpaceReplies = (props) => {
  const device = useSelector((state) => state.device);

  const loadPreviousCTARequired = () => {
    return (
      !props.infiniteScroll &&
      !props.loading &&
      props.beforeHasMore() &&
      props.type === 'Replies'
    );
  };

  const renderLoadPreviousCTA = () => {
    return (
      <OsBtn
        name='BtnIcon'
        icon='chevron-up'
        extraClass='no-text prev-record-link with-border '
        onClick={props.loadMoreBefore}
        text=''
      />
    );
  };

  let count = props.results.length,
    titleText = device.mobileDevice
      ? `Show ${count} previous replies`
      : 'Load all replies';
  if (!count) return null;
  return (
    <div>
      {props.results.map((reply) => (
        <MessageBlock
          type='comment'
          key={reply.id}
          entity={reply}
          isSubReply={true}
          parentRef={props.parentRef}
          actionType={'reply'}
          space={props.entity}
        />
      ))}
      {loadPreviousCTARequired() && renderLoadPreviousCTA() && (
        <OsBtn
          name='BtnIcon'
          extraClass='px-16 btn-hide osbtn-tertiary'
          onClick={props.loadMoreBefore}
          text={titleText}
        />
      )}
    </div>
  );
};

export default SpaceReplies;
