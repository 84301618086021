import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import SiteLogo from 'app/components/Shared/SiteLogo';

class MarcomNavBar extends PureComponent {
  render() {
    let isWorkspace = this.props.workspace?.identifier;

    return (
      <nav className='navbar navbar-expand-lg navbar-unauth navbar-expand-xl os-main-nav marcom-nav'>
        <div className='os-main-nav-inner'>
          {(!isWorkspace || this.props.device.mobileDevice) && <SiteLogo />}

          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#basicExampleNav'
            aria-controls='basicExampleNav'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <i className='fa fa-bars'></i>
          </button>
          <div class='collapse navbar-collapse' id='basicExampleNav'>
            <ul className='nav navbar-nav pull-lg-right'>
              <li className='nav-item'>
                <OsLink
                  text='EDUcation'
                  htmlTag='a'
                  href='/education'
                  className='nav-link'
                />
              </li>
              <li className='nav-item'>
                <OsLink
                  text='Courses'
                  htmlTag='a'
                  href='/courses'
                  className='nav-link active'
                />
              </li>
              <li className='nav-item'>
                <OsLink
                  text='qlark case™'
                  htmlTag='a'
                  href='/case'
                  className='nav-link'
                />
              </li>
              <li className='nav-item'>
                <OsLink
                  text='Login'
                  htmlTag='a'
                  href='/login'
                  className='login-btn-marcom'
                />
              </li>
              <li className='nav-item text-center'>
                <OsBtn
                  name='BtnPrimary'
                  text='sign up'
                  href='/signup'
                  extraClass='signup-marcom'
                  htmlTag='a'
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
MarcomNavBar = connect(({ device }) => ({ device }))(MarcomNavBar);
export default MarcomNavBar;
