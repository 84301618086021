import { setGlobalSearchQuery } from 'app/actions/globalSearchQuery';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isFeaturePermitted } from 'app/utils/userHelper';
import OsField from 'app/components/OsField';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { isCareSpaceView } from 'app/selectors/source';

function GlobalSearchInputField() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isCareView = useSelector(isCareSpaceView);

  const globalSearchQuery = useSelector((state) => state.globalSearchQuery);
  const [searchQuery, setSearchQuery] = useState(globalSearchQuery.query);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;

      let query = value;
      if (query === '') {
        navigate(-1);
      } else {
        navigate(withWorkspaceIdentifier(`/search?query=${query}`), {
          replace: globalSearchQuery.query === '' ? false : true,
        });
      }

      dispatch(setGlobalSearchQuery(query));
    }
  };

  useEffect(() => {
    if (searchParams.has('query')) {
      dispatch(setGlobalSearchQuery(searchParams.get('query')));
      setSearchQuery(searchParams.get('query'));
    } else {
      dispatch(setGlobalSearchQuery(''));
      setSearchQuery('');
    }
  }, [searchParams]);

  useEffect(() => {
    const inputField = document.getElementById('globalsearchinputfield');

    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;
      const isInputField = activeElement.tagName.toLowerCase() === 'input';

      if (
        event.key === '/' &&
        !isInputField &&
        !activeElement.classList.contains('ProseMirror')
      ) {
        event.stopImmediatePropagation();
        event.preventDefault();
        inputField.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const renderSearchField = () => {
    let searchFeaturePermitted = isFeaturePermitted('global-search', {
        default: true,
      }),
      isSwitcherPage = location?.pathname?.includes('/profile/switcher');

    if (searchFeaturePermitted && isWorkspaceView() && !isSwitcherPage)
      return (
        <div className={'global-search-field'}>
          <OrthoIcon
            name='search'
            defaultClass='search-icon'
            dataHoverNotRequired={true}
          />
          <OsField
            type='text'
            osType='input'
            placeholder='Type here to search or press /'
            className='form-control global-search-field no-border'
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            value={searchQuery}
            id='globalsearchinputfield'
            name='search'></OsField>
        </div>
      );
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {!isCareView && renderSearchField()}
    </div>
  );
}

export default GlobalSearchInputField;
