import AttachmentDropdown from 'app/components/Shared/AttachmentDropdown';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { isBlob } from 'app/utils/fileHelper';
import { mapLocalFileWithAttachmentObject } from 'app/utils/entitiesHelper';
import OsGrid from 'app/components/OsGrid';
import OsCards from 'app/components/OsCards';
import VideoRecordButton from 'app/components/Shared/VideoRecordButton';
import { uniqBy } from 'app/utils/osLodash';
import OsBtn from 'app/components/OsBtn';

const OsTiptapOptions = (props) => {
  const {
    files,
    handleFiles,
    objects,
    handleObjects,
    isSubmitButtonRequired,
    isCancelButtonRequired,
    cancelButtonProps,
    submitButtonProps,
  } = props;
  const commentShareFiles = useSelector((state) => state.commentShare);

  const renderCardAndAttachments = () => {
    return files.map((file) =>
      isBlob(file) ? mapLocalFileWithAttachmentObject(file) : file,
    );
  };

  const removeFile = (file) => {
    handleFiles((prevState) => {
      return prevState.filter((f) => f.id !== file.id);
    });
    props.removeAssignObject(file);
  };

  const removeObject = (object) => {
    handleObjects((prevState) => {
      return prevState.filter(
        (obj) => obj.id !== object.id && obj.__typename === object.__typename,
      );
    });
  };

  useEffect(() => {
    if (
      commentShareFiles.status === 'closed' &&
      props.rowId === commentShareFiles.options.rowId
    ) {
      handleFiles((prevState) => {
        return uniqBy([...prevState, ...commentShareFiles.cards.files], 'id');
      });
      handleObjects((prevState) =>
        uniqBy([...prevState, ...commentShareFiles.cards.objects], 'id'),
      );
    }
  }, [commentShareFiles]);

  return (
    <>
      <div className={'options'}>
        <div className={'editor-actions'}>
          <div className={'action-button'}>
            <AttachmentDropdown
              leftAligned={true}
              textRequired={false}
              assignObject={props.assignObject}
            />

            <VideoRecordButton
              onClick={(event) => {
                event.stopPropagation();
                props.assignObject('video');
              }}
              extraClass={`no-text with-border web-view-btn`}
            />
          </div>
          <div>
            {isCancelButtonRequired && (
              <OsBtn
                type='button'
                name='BtnGhost'
                extraClass='web-view-btn btn-save-info'
                text={'Cancel'}
                onClick={cancelButtonProps.onClick}
                htmlTag='button'
              />
            )}
            {isSubmitButtonRequired && (
              <OsBtn
                disabled={submitButtonProps.disabled}
                type='button'
                name='BtnPrimary'
                extraClass='web-view-btn btn-save-info'
                text={
                  submitButtonProps.text
                    ? submitButtonProps.text
                    : submitButtonProps.loading
                    ? 'Saving'
                    : 'Save Template'
                }
                onClick={submitButtonProps.onClick}
                htmlTag='button'
              />
            )}
          </div>
        </div>
        {/*  Display Attachments. */}
        <div className={'editor-attachments'}>
          {renderCardAndAttachments().map((object) => {
            return (
              <OsGrid
                identifier={`DiscussionCard:Cardcols`}
                key={`${object.__typename}:${object.id}`}>
                <OsCards
                  size={'extra-small'}
                  disableCard={false}
                  obj={object}
                  selectionMode={false}
                  closeRequired={true}
                  afterClose={removeFile}
                  avoidPreviewModal={false}
                />
              </OsGrid>
            );
          })}
          {objects.map((object) => {
            return (
              <OsGrid
                identifier={`DiscussionCard:Cardcols`}
                key={`${object.__typename}:${object.id}`}>
                <OsCards
                  size={'extra-small'}
                  disableCard={false}
                  obj={object}
                  selectionMode={false}
                  closeRequired={true}
                  afterClose={removeObject}
                  avoidPreviewModal={false}
                />
              </OsGrid>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OsTiptapOptions;
