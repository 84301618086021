import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OsBtn from 'app/components/OsBtn';

import { entityUrl } from 'app/utils/entitiesHelper';
import { isSpaceArchived } from 'app/utils/spaceHelper';

import { closeInfoModal, openInfoModal } from 'app/actions/infoModal';
import { updateSpaceState } from 'app/actions/updateSpaceState';
import { updateCurrentUserGraph } from 'app/actions/authentication';

class ArchiveSpace extends Component {
  state = {
    requestInProgress: false,
  };

  afterSuccessAction = (space) => {
    this.setState({ requestInProgress: false });
    this.props.closeInfoModal();
    if (!space) {
      this.props.navigate('/spaces');
    } else if (isSpaceArchived(space)) {
      this.props.source === 'edit' &&
        this.props.navigate(entityUrl(this.props.space));
    } else {
      this.props.openInfoModal('space', 'space_unarchive', {
        contentInterpolations: { name: this.props.space.name },
      });
    }
  };

  archiveSpace = () => {
    this.setState({ requestInProgress: true });
    this.setState({ requestInProgress: true });
    this.props.updateSpaceState({
      archive: !isSpaceArchived(this.props.space),
      space: { id: this.props.space.id },
      afterSuccessAction: this.afterSuccessAction,
    });
  };

  openArchiveConfirmation = () => {
    if (isSpaceArchived(this.props.space)) {
      this.archiveSpace();
    } else {
      if (this.props.confirmationRequired) {
        this.props.openInfoModal('space', 'space_archive', {
          contentInterpolations: { name: this.props.space.name },
          onSuccess: this.archiveSpace,
          closeModalNotRequiredOnPrimaryClick: true,
          primaryCtaRequestInProgress: this.state.requestInProgress,
        });
      } else {
        this.archiveSpace();
      }
    }
  };

  getBtnText() {
    return isSpaceArchived(this.props.space) ? 'Unarchive' : 'Archive';
  }

  render() {
    let btnText = this.props.text || this.getBtnText();
    return (
      <OsBtn
        name={this.props.name}
        extraClass={this.props.extraClass}
        icon={this.props.icon}
        text={btnText}
        onClick={this.openArchiveConfirmation}
        associatedEntity={this.props.space}
      />
    );
  }
}
ArchiveSpace = withRouter(ArchiveSpace);

ArchiveSpace.defaultProps = {
  name: 'BtnSecondary',
  extraClass: 'web-view-btn arch-btn',
  source: 'dropdown',
  confirmationRequired: true,
};

ArchiveSpace = withRouter(ArchiveSpace);
ArchiveSpace = connect(null, {
  closeInfoModal,
  openInfoModal,
  updateSpaceState,
  updateCurrentUserGraph,
})(ArchiveSpace);

export default ArchiveSpace;
