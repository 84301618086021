import React from 'react';
import { connect } from 'react-redux';

import AbstractInviteMember from './AbstractInviteMember';

import { EMAIL_REGEX } from 'app/constants';
import { ROLES_LIST } from './constant';
import { filter } from 'app/utils/osLodash';
import { translate } from 'app/actions/flashMessage';

class MembersToInvite extends AbstractInviteMember {
  constructor(props) {
    super(props);
    this.variables = {
      page: 0,
      type: this.props.listType,
      perPage: 25,
      text_query: this.props.textQuery,
      id_query: this.props.idQuery,
    };
  }

  state = {
    nonPolarisUsers: [],
  };

  componentDidMount() {
    if (this.props.selectAllMembers) {
      this.props.onSelectAllMembers(this.props.results);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loading &&
      !this.props.loading &&
      this.props.selectAllMembers
    ) {
      // timeout added for update query(records view) to run.
      setTimeout(() => {
        this.props.onSelectAllMembers(this.props.results);
      }, 100);
    }
  }

  selectMemberToInvite = (member) => {
    this.props.setSelectedMembers && this.props.setSelectedMembers(member);
    if (!member.id) {
      let nonPolarisUsers = this.state.nonPolarisUsers;
      nonPolarisUsers.push(member);
      this.setState({ nonPolarisUsers });
    }
  };

  getSuggestedMembers = (member) => {
    return (
      <div className='suggest-member-list-item'>
        <label
          className='list-type-label'
          onClick={this.selectMemberToInvite.bind(this, member)}>
          {this.renderMembers(member)}
        </label>
      </div>
    );
  };

  getNonPolarisMember(searchText) {
    return {
      full_name: searchText,
      role: ROLES_LIST[0][1],
      avatarIcon: 'user',
      __typename: 'User',
    };
  }

  noSuggestionsFound() {
    if (this.isSourceWorkspace()) {
      return '';
    } else if (this.props.selectAllMembers) {
      return this.props.selectedMembers.length
        ? ''
        : translate('NO_MEMBERS_IN_PARENT_SPACE_FOUND');
    } else {
      return translate('NO_SUGGESTIONS_FOR_MEMBERS_FOUND');
    }
  }

  isSourceWorkspace() {
    return this.props.source === 'Workspace';
  }

  renderSuggestionList(suggestionList) {
    let searchText = this.props.textQuery,
      isEmailAddress = EMAIL_REGEX.test(searchText),
      isCurrentUserEmail = this.props.currentUser.graph.email === searchText,
      emailAlreadyAdded =
        isEmailAddress &&
        filter(this.props.selectedMembers, (selectedMember) => {
          return selectedMember.email === searchText;
        }).length;

    if (
      this.isSourceWorkspace() &&
      suggestionList.length === 0 &&
      searchText &&
      isEmailAddress &&
      !isCurrentUserEmail &&
      !emailAlreadyAdded
    ) {
      let member = this.getNonPolarisMember(searchText);
      if (
        !this.props.selectedMembers.filter(
          (user) => user.full_name === member.full_name,
        ).length
      ) {
        suggestionList.push(member);
      }
    }
    if (suggestionList.length > 0) {
      return (
        <div className='suggest-member-list-group'>
          {suggestionList.map(this.getSuggestedMembers)}
        </div>
      );
    } else {
      return (
        <div>
          <span className='no-results-text'>
            {searchText
              ? this.noResultFound(this.props.textQuery)
              : this.noSuggestionsFound()}
          </span>
        </div>
      );
    }
  }

  renderSuggestedMembers() {
    let suggestionList = this.props.results;
    return (
      <div className='suggest-member-group'>
        {this.renderSuggestionList(suggestionList)}
      </div>
    );
  }

  render() {
    return <div>{this.renderSuggestedMembers()}</div>;
  }
}

MembersToInvite.defaultProps = {
  alreadyInvitedEmails: [],
};

MembersToInvite = connect(({ device, currentUser }) => ({
  device,
  currentUser,
}))(MembersToInvite);

export default MembersToInvite;
