import React from 'react';
import SocialLink from 'app/components/Shared/SocialLink';
import { SOCIAL_LINKS_ACTIVE } from 'app/constants';

const SocialStrip = () => {
  const buttons = Object.keys(SOCIAL_LINKS_ACTIVE || {}).map((key) => (
    <li key={key} className='social-icon'>
      <SocialLink platform={key} />
    </li>
  ));

  return <ul className='social'>{buttons}</ul>;
};

export default SocialStrip;
