import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import RecordsView from 'app/components/RecordsView';

class PersonRelatedCareSpaces extends Component {
  renderCareSpaces() {
    return (
      <RecordsView
        type='PersonRelatedCareSpaces'
        queryType='VISTIOR_DIRECTORY'
        idQuery={this.props.obj.id}
        cardGridIdentifier={'RelatedCareSpacemodal:CardCols'}
      />
    );
  }

  render() {
    let { obj, onClose } = this.props;
    return (
      <Modal
        show={true}
        onHide={onClose}
        animation={false}
        dialogClassName='os-grid-modal modal-carespace-related'
        backdropClassName='modal-backdrop-custom modal-backdrop-general'>
        <Modal.Header className='border-0 p-0'>
          <h4 className='modal-title'>PatientSpaces related to {obj.name}</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn r-0'
            icon='close'
            label='Close modal'
            onClick={onClose}
          />
        </Modal.Header>
        <Modal.Body>{this.renderCareSpaces()}</Modal.Body>
      </Modal>
    );
  }
}

PersonRelatedCareSpaces.defaultProps = {
  onClose: () => {},
  obj: {},
};

export default PersonRelatedCareSpaces;
