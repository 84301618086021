import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { useContext } from 'react';

export function withWorkspaceContext(Component) {
  const WithWorkspaceContext = (props: any) => {
    const context = useContext(WorkspaceContext);
    return <Component {...props} context={context} />;
  };
  return WithWorkspaceContext;
}
