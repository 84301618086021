import React from 'react';

import AbstractField from './AbstractField';
import NumberFormat from 'react-number-format';
import OsField from './';

export default class OsNumberFormat extends AbstractField {
  render() {
    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        <NumberFormat
          format={this.props.format}
          {...this.getFieldProps()}
          type={'text'}
        />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsNumberFormat.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
  placeHolder: '( ___ ) ___ - ____',
  format: '(###) ###-####',
};
