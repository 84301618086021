import React, { Component } from 'react';

import GuestNavBar from './GuestNavBar';
import MarcomNavBar from './MarcomNavBar';

export default function NavBar(props) {
  switch (props.source) {
    case 'guest':
      return <GuestNavBar {...props} />;
    case 'marcom':
      return <MarcomNavBar {...props} />;
    default:
      return null;
  }
}
