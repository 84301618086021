import React from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import DropzoneFileUploader from 'app/components/Shared/DropzoneFileUploader';
import Icon from 'app/components/Svg';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { loader as queryLoader } from 'graphql.macro';

import AutoSearchSuggestInput from 'app/components/Shared/AutoSuggestSearchInput';
import Collection from './Collection';
import CardGridViewSelector from 'app/components/Shared/CardGridViewSelector';
import FoldersListing from 'app/components/FoldersView/Listing';
import MainNav from './MainNav';
import OsCards from 'app/components/OsCards';
import OsField from 'app/components/OsField';
import RecordsView from 'app/components/RecordsView';
import RenderCards from 'app/components/Cards/RenderCards';
import SpaceContentSortDropdown from 'app/components/Shared/SpaceContentSortDropdown';
import SpaceFilterDropdown from 'app/components/Shared/SpaceFilterDropdown';
import VideoRecord from 'app/components/VideoRecord';

import { PULSE_ATTACHMENTS_MAXIMUM_LIMIT } from 'app/constants';

import {
  commentShareAssign,
  commentShareChangeType,
  commentShareConsume,
} from 'app/actions/commentShare';
import { translate } from 'app/actions/flashMessage';
import { getSpaceCardGridIdentifier } from 'app/utils/osCardHelper';
import { capitalize, pluralizeString } from 'app/utils/stringHelper';
import {
  getFileSizeLimitFromPermissions,
  mapLocalFileWithAttachmentObject,
} from 'app/utils/entitiesHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { isURL } from 'app/utils/urlHelper';
import { TYPE_MODEL_MAPPER } from './constants';
import { isBlob } from 'app/utils/fileHelper';
import {
  debounce,
  each,
  filter,
  has,
  isEmpty,
  isUndefined,
  keys,
  lowerCase,
  omit,
  pick,
  reduceRight,
  reject,
  some,
  uniq,
} from 'app/utils/osLodash';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import {
  CARE_ENTITY_TABS,
  ENTITY_TABS,
} from 'app/components/CommentShareView/MainNav';
import { isObjectExist } from 'app/utils/generalHelper';

const ADD_ADDITIONAL_LINK_MUTATION = queryLoader(
  'app/graphql/mutations/AddAdditionalLink.gql',
);
const DELETE_FILE_ASSET_MUTATION = queryLoader(
  'app/graphql/DeleteFileAsset.gql',
);
const FILE_TYPES_ALLOWED = '*';
const VALIDATIONS_REQUIRED = [{ attribute: 'Size' }];

// modal to assign objects to comments
class CommentShareView extends DropzoneFileUploader {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'file',
      files: [],
      rejectedFiles: [],
      attachedCards: [],
      excludedCards: [],
      attachmentsLoader: {},
      progressMapper: {},
      requestInProgress: false,
      showVideoModal: false,
      inputActive: false,
      cardViewType: props.device.mobileDevice ? 'list' : 'big',
    };
    this.dropzoneSource = 'attachment-modal';
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.commentShare.status === 'closed' &&
      nextProps.commentShare.status === 'open'
    ) {
      this.resetModalState();

      if (nextProps.commentShare.source !== 'Space') {
        if (
          nextProps.commentShare.type === 'file' &&
          !this.canExternalShare(nextProps.commentShare.source)
        ) {
          this.props.commentShareChangeType('case');
        } else if (
          nextProps.commentShare.type !== 'file' &&
          !this.canInternalShare(nextProps.commentShare.source)
        ) {
          this.props.commentShareChangeType('file');
        }
      }
      this.assignExcludedObjects(nextProps.commentShare);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.commentShare.type !== this.props.commentShare.type &&
      this.props.commentShare.type === 'file' &&
      !this.props.commentShare.options.isDirectPaste
    ) {
      this.dropzoneRef.open();
    }
    if (
      prevProps.commentShare.status === 'closed' &&
      this.props.commentShare.status === 'open'
    ) {
      if (this.canExternalShare()) this.validateAndAddFiles();

      if (this.videoTabSelected()) this.setState({ showVideoModal: true });
    }
  }

  assignExcludedObjects(commentShare) {
    this.setState({ excludedCards: commentShare.objects });
  }

  objectsToExclude() {
    return this.props.commentShare.files.concat(this.state.excludedCards);
  }

  canExternalShare(source = '') {
    switch (source || this.props.commentShare.source) {
      case 'Comment':
        return this.props.currentUser.ability.can('external_share', 'comment');
      case 'Message':
        return this.props.currentUser.ability.can(
          'external_share',
          'conversation',
        );
      default:
        return true;
    }
  }

  canInternalShare(source = '') {
    switch (source || this.props.commentShare.source) {
      case 'Comment':
        return this.props.currentUser.ability.can('internal_share', 'comment');
      case 'Message':
        return this.props.currentUser.ability.can(
          'internal_share',
          'conversation',
        );
      case 'Post':
        return this.props.currentUser.ability.can('internal_share', 'pulse');
      default:
        return true;
    }
  }

  getMaxFileSizeAllowed() {
    let source = this.props.commentShare.source,
      modelName = '',
      commentableType = '';

    if (source === 'Message') {
      modelName = 'conversation';
    } else if (source === 'Post') {
      modelName = 'pulse';
    } else if (source === 'Comment') {
      modelName = 'comment';
      commentableType = lowerCase(
        this.props.commentShare.options.commentableType,
      );
    } else {
      modelName = lowerCase(source);
    }

    return getFileSizeLimitFromPermissions(
      this.props.currentUser,
      modelName,
      commentableType,
    );
  }

  isPostEntity() {
    return this.props.commentShare.source === 'Post';
  }

  filesCountValidationRequired() {
    return this.isPostEntity();
  }

  deleteUnattachedAssetsRequired() {
    return true;
  }

  attachedFilesCount(additionalFilesCount = 0) {
    return (
      (this.props.commentShare.options.addedFilesCount || 0) +
      this.state.files.length +
      additionalFilesCount
    );
  }

  filesLimitExceeded(additionalFilesCount = 0) {
    return (
      this.filesCountValidationRequired() &&
      this.attachedFilesCount(additionalFilesCount) >
        PULSE_ATTACHMENTS_MAXIMUM_LIMIT
    );
  }

  filesLimitReached() {
    return (
      this.filesCountValidationRequired() &&
      this.attachedFilesCount() >= PULSE_ATTACHMENTS_MAXIMUM_LIMIT
    );
  }

  validateAndAddFiles() {
    let files = this.props.commentShare.files;
    if (files.length > 0) {
      this.validateFiles(files);
      this.onDropRejected(filter(files, (file) => file.rejected));
      this.onCustomDrop(filter(files, (file) => !file.rejected));
    }
  }

  validateFiles(files) {
    each(VALIDATIONS_REQUIRED, (validation) => {
      let validationValue =
        validation.attribute === 'Size'
          ? this.getMaxFileSizeAllowed()
          : validation.value;
      this[`is${validation.attribute}Valid`](files, validationValue);
    });
  }

  showSizeError() {
    this.setState({ sizeError: true });
  }

  onDragEnter() {
    !this.filesLimitReached() && this.setState({ dropzoneActive: true });
  }

  onCustomDrop(files) {
    if (this.filesLimitExceeded(files.length)) {
      this.setState({ dropzoneActive: false });
    } else {
      this.onDrop(files);
    }
  }

  onDropRejected = (files) => {
    files.forEach((file) => (file.rejected = true));
    this.validateFiles(files);
    this.addToRejectedFiles(files);
    this.setState({ dropzoneActive: false });
  };

  addToRejectedFiles(files) {
    let rejectedFilesCollection = this.state.rejectedFiles;
    rejectedFilesCollection = rejectedFilesCollection.concat(files);

    rejectedFilesCollection = reduceRight(
      rejectedFilesCollection,
      (a, b) => {
        if (a.find((file) => file.name === b.name)) {
          return a;
        } else {
          return a.concat(b);
        }
      },
      [],
    );

    this.setState({ rejectedFiles: rejectedFilesCollection });
  }

  handleTagChange = ({ tagIds, genderTagId, range_query }) => {
    if (
      (!isEmpty(tagIds) ||
        !isUndefined(genderTagId) ||
        !isEmpty(range_query)) &&
      !this.state.clearSearchFilter
    )
      this.setState({
        tagIds,
        range_query,
        genderTagId,
        id_query: null,
        query_by: null,
        clearSearchFilter: true,
        clearAllFilter: false,
      });
  };

  clearAllFilter = () => {
    this.setState({ clearAllFilter: true });
  };

  defaultVisibleTags() {
    return {
      ageFactor: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      gender: [],
    };
  }

  updateTags = (results) => {
    let visibleTags = this.defaultVisibleTags();
    results.forEach((result) => {
      if (result.__typename === 'Case') {
        visibleTags.gender = visibleTags.gender.concat(result.gender);
        let factor = Math.ceil(result.age / 5);
        visibleTags.ageFactor[factor > 6 ? 6 : factor] = true;
      } else if (result.__typename === 'Comparison') {
        visibleTags.gender = visibleTags.gender.concat(result.cases_gender);
        result.cases_age.forEach((age) => {
          let factor = Math.ceil(age / 5);
          visibleTags.ageFactor[factor > 6 ? 6 : factor] = true;
        });
      }
    });
    visibleTags.gender = uniq(visibleTags.gender);
    this.setState({ visibleTags, filterRequired: true });
  };

  onCardClick = (obj) => {
    let files = this.state.files,
      attachedCards = this.state.attachedCards;
    if (isObjectExist(files, obj)) {
      files = files.filter((card) => card.id !== obj.id);
    } else if (isObjectExist(attachedCards, obj)) {
      attachedCards = attachedCards.filter((card) => card.id !== obj.id);
    } else if (this.isLatestVersion() && obj.__typename === 'Attachment') {
      files.push(obj);
    } else {
      attachedCards.push(obj);
    }
    this.setState({ files, attachedCards });
  };

  onSubmit = () => {
    let { customRequestOnClose } = this.props.commentShare.options;

    customRequestOnClose && this.setState({ requestInProgress: true });

    this.props.commentShareAssign(
      {
        files: this.state.files,
        objects: this.state.attachedCards.map((obj) =>
          omit(obj, 'onCardClick'),
        ),
      },
      customRequestOnClose,
    );

    !customRequestOnClose && this.resetModalState();
  };

  removeUnAttachedAttachments() {
    this.state.files.forEach(this.onRemoveAttachment);
  }

  onClose = () => {
    this.removeUnAttachedAttachments();
    this.resetModalState();
    this.props.commentShareConsume();
  };

  removeItem = (item) => {
    let items = this.state.attachedCards,
      newItems = reject(items, { __typename: item.__typename, id: item.id });

    this.setState({ attachedCards: newItems });
  };

  navDefaultState() {
    return {
      tagIds: [],
      genderTagId: null,
      range_query: [],
      query: '',
      clearSearchFilter: true,
      searchText: '',
      linkNotValidError: false,
    };
  }

  resetModalState = () => {
    this.setState({
      requestInProgress: false,
      attachedCards: [],
      files: [],
      rejectedFiles: [],
      selectedFolder: null,
      filterValues: [],
      ...this.navDefaultState(),
    });
  };

  resetNavState = () => {
    this.setState(this.navDefaultState());
  };

  getCurrentTabCards() {
    let { type } = this.props.commentShare,
      typename = TYPE_MODEL_MAPPER[type] || capitalize(type),
      files = this.isLatestVersion()
        ? this.state.files
        : this.state.attachedCards;
    return files.filter((obj) => obj.__typename === typename);
  }

  getRecordedFiles() {
    return this.state.files.filter((file) => file.recorded);
  }

  getFilesTabObject() {
    return this.state.files.filter((file) => !file.recorded);
  }

  getCardsfromFiles(files) {
    return files.map((file) => this.renderAttachment(file));
  }

  videoTabSelected() {
    return this.props.commentShare.type === 'video';
  }

  isLatestVersion() {
    return this.props.commentShare.options.latestVersion;
  }

  removeAttachedCard = (obj) => {
    if (obj.__typename === 'Attachment') {
      this.removeAttachment(obj);
    } else {
      this.removeItem(obj);
    }
  };

  renderAttachedCards() {
    if (this.filesTabSelected()) {
      return (
        <div className='cards row'>
          {this.getCardsfromFiles(this.getFilesTabObject())}
          {this.state.rejectedFiles.map((file) =>
            this.renderAttachment({ ...file, __typename: 'Attachment' }),
          )}
        </div>
      );
    } else if (this.videoTabSelected()) {
      return (
        <div className='cards row'>
          {this.getCardsfromFiles(this.getRecordedFiles())}
        </div>
      );
    } else {
      let cardProps = {
        selectionMode: true,
        onCardClick: this.removeItem,
        size: this.cardSize(),
        multiSelectMode: true,
        selected: true,
        cardClickRequired: true,
      };
      return (
        <RenderCards
          objects={this.getCurrentTabCards()}
          orderRowWise={true}
          key={this.state.attachedCards.length}
          {...cardProps}
          cardGridIdentifier={'NewAttachmentModal:Card'}
        />
      );
    }
  }

  setCollectionCount = (collectionCount) => {
    this.setState({ collectionCount });
  };

  onSearchResultClick = (data) => {
    this.setState({
      range_query: [],
      genderTagId: null,
      tagIds: [],
      id_query: data.id_query,
      query_by: data.query_by,
      clearSearchFilter: false,
      clearAllFilter: true,
    });
  };

  deleteFileAsset(fileId) {
    this.props.client.mutate({
      mutation: DELETE_FILE_ASSET_MUTATION,
      variables: { id: fileId },
    });
  }

  onSearchReset = () => {
    this.setState({
      id_query: null,
      query_by: null,
      clearSearchFilter: false,
      searchText: '',
    });
  };

  autoSearchSuggestInputType() {
    return pluralizeString(
      this.props.commentShare.type === 'user'
        ? 'doctor'
        : this.props.commentShare.type,
    );
  }

  setFilterSelected = (val) => {
    this.setState({ clearAllFilter: !val, clearSearchFilter: val });
  };

  isSubmitDisabled() {
    let isSubmitDisabled = true;
    if (this.state.attachedCards.length !== 0 || this.state.files.length !== 0)
      isSubmitDisabled = false;

    if (
      this.state.attachmentsLoader &&
      some(this.state.attachmentsLoader, (val, key) => val)
    )
      isSubmitDisabled = true;

    return this.state.requestInProgress || isSubmitDisabled;
  }

  filesTabSelected() {
    return this.props.commentShare.type === 'file';
  }

  onRemoveAttachment = (file) => {
    if (file.id && isBlob(file)) {
      this.deleteUnattachedAssetsRequired() && this.deleteFileAsset(file.id);
    }
  };

  renderAttachment(file) {
    let isFileUploaded = this.isAttachmentUploaded(file),
      fileObject = isFileUploaded
        ? mapLocalFileWithAttachmentObject(file)
        : file;
    return this.renderFileCard(fileObject);
  }

  fileStatusText(file) {
    if (this.state.attachmentsLoader[file.name]) {
      let percentage = this.state.progressMapper[file.name] || 0;
      return (
        <span className='file-uploading'>
          <OrthoIcon
            name='upload'
            dataHoverNotRequired={true}
            defaultClass='me-1'
          />{' '}
          {`${percentage}% uploaded`}
        </span>
      );
    } else if (file.rejected) {
      return (
        <span className='file-upload-error'>
          <OrthoIcon
            name='error'
            dataHoverNotRequired={true}
            defaultClass='me-1'
          />{' '}
          Can't upload. Try again
        </span>
      );
    } else {
      return (
        <span className='file-upload-successfull'>
          <OrthoIcon name='doubleTick' dataHoverNotRequired={true} /> Ready to
          Add
        </span>
      );
    }
  }

  cardSize() {
    return 'extra-small';
  }

  getAttachmentCardProps(file, isFileUploaded) {
    if (isFileUploaded) {
      return {
        closeRequired: true,
        afterClose: this.removeAttachment,
        avoidPreviewModal: true,
      };
    } else {
      return {
        selectionMode: true,
        onCardClick: this.removeAttachment,
        multiSelectMode: true,
        selected: true,
      };
    }
  }

  isAttachmentUploaded(file) {
    return isBlob(file) || file.rejected;
  }

  renderFileCard(file) {
    let isFileUploaded = this.isAttachmentUploaded(file);
    return (
      <div className='col-12 col-md-4 col-lg-3 mb-4 col-sm-6 col-xxl-4'>
        <OsCards
          size={this.cardSize()}
          obj={file}
          {...this.getAttachmentCardProps(file, isFileUploaded)}
        />
        {isFileUploaded && (
          <div className='file-status'>{this.fileStatusText(file)}</div>
        )}
      </div>
    );
  }

  renderFilesHint() {
    let message = '';

    if (this.filesCountValidationRequired()) {
      if (this.attachedFilesCount() === 0) {
        message = translate('PULSE_ATTACHMENTS_LIMIT_MESSAGE', {
          limit: PULSE_ATTACHMENTS_MAXIMUM_LIMIT,
        });
      } else if (this.attachedFilesCount() > 0 && !this.filesLimitReached()) {
        message = translate('PULSE_ATTACHMENTS_LIMIT_PROGRESSIVE_MESSAGE', {
          limit: PULSE_ATTACHMENTS_MAXIMUM_LIMIT - this.attachedFilesCount(),
        });
      } else {
        message = translate('PULSE_ATTACHMENTS_LIMIT_MODAL_ERROR', {
          limit: PULSE_ATTACHMENTS_MAXIMUM_LIMIT,
        });
      }
    } else {
      message = translate('ATTACHMENT_MODAL_FILE_TYPE_HINT');
    }

    return <small className='small small-file-info mt-3'>{message}</small>;
  }

  renderUploadSectionBody() {
    return (
      <div className='dropzone-instruction d-flex'>
        <div className='mx-auto mt-5'>
          <div className='mb-3'>
            <Icon name='dropFiles' />
          </div>

          {isTouchSupported() ? (
            <span>Upload your file from</span>
          ) : (
            <span>Drag and drop your files here</span>
          )}
          <div>
            or{' '}
            <OsLink
              className='a-link file-finder-btn pointer'
              onClick={() => {
                this.dropzoneRef.open();
              }}
              text='browse your files'
            />
          </div>
        </div>
        {this.renderFilesHint()}
      </div>
    );
  }

  onDialogInputClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  typesToBeDisplayed() {
    if (this.props.commentShare.source === 'Toolbox') {
      return ['tool'];
    } else if (this.props.commentShare.options.typesToBeDisplayed) {
      return this.props.commentShare.options.typesToBeDisplayed;
    } else {
      return keys(isCareWorkspaceView() ? CARE_ENTITY_TABS : ENTITY_TABS);
    }
  }

  getSearchAdditionalFilter() {
    if (has(this.props.commentShare.options, 'only_published')) {
      return { only_published: this.props.commentShare.options.only_published };
    } else {
      return { only_published: true };
    }
  }

  renderMainNav() {
    return (
      <div className='col-sm-12 add-message-nav-block'>
        <div className='object-header-bar'>
          <div className='add-message-primary-nav'>
            <MainNav
              key={this.showModal()}
              resetState={this.resetNavState}
              canExternalShare={this.canExternalShare()}
              canInternalShare={this.canInternalShare()}
              tabsNotRequired={this.props.commentShare.options.tabsNotRequired}
              typesToBeDisplayed={this.typesToBeDisplayed()}
              objects={this.allObjectsAdded()}
            />
          </div>
        </div>
      </div>
    );
  }

  addNewAdditionalLink = () => {
    if (isURL(this.state.searchText)) {
      this.props.client
        .mutate({
          mutation: ADD_ADDITIONAL_LINK_MUTATION,
          variables: {
            url: this.state.searchText,
          },
        })
        .then(({ data }) => {
          if (data.addAdditionalLink.success) {
            let additionalLinkCard = data.addAdditionalLink.entity;
            if (
              this.state.attachedCards.find(
                (card) =>
                  card.__typename === 'AdditionalLink' &&
                  card.id === additionalLinkCard.id,
              )
            ) {
              this.setState({ linkNotValidError: 'Already added' });
            } else {
              this.onCardClick(additionalLinkCard);
              this.setState({
                linkNotValidError: '',
                searchText: '',
                clearSearchFilter: true,
              });
            }
          } else if (data.addAdditionalLink.error) {
            this.setState({ linkNotValidError: data.addAdditionalLink.error });
          }
        });
    } else {
      this.setState({ linkNotValidError: 'Invalid link' });
    }
  };

  onNewFileBtnClick = () => {
    this.dropzoneRef.open();
  };

  addNewFileOrLinkButton() {
    let commonProps = { name: 'BtnSecondary' },
      tabSpecificProps = {};

    if (this.filesTabSelected()) {
      tabSpecificProps = {
        text: 'upload new file',
        onClick: this.onNewFileBtnClick,
        extraClass: `with-border ${this.filesLimitReached() ? 'disabled' : ''}`,
      };
    } else {
      tabSpecificProps = {
        text: 'Add new link',
        onClick: this.addNewAdditionalLink,
        extraClass: 'with-border',
      };
    }

    return (
      <aside className='search-aside'>
        <OsBtn {...commonProps} {...tabSpecificProps} />
      </aside>
    );
  }

  isFileOrLinkTabSelected() {
    return ['file', 'link'].includes(this.props.commentShare.type);
  }

  setSearchText = debounce((searchText) => {
    this.setState({ searchText });
  }, 200);

  getSearchPlaceholderUpload() {
    switch (this.props.commentShare.type) {
      case 'link':
        return `Search by link keywords ${
          this.props.device.mobileDevice
            ? 'or type a new address'
            : 'or simply type a new address here'
        }`;
      case 'file':
        return `Search by file name ${
          this.props.device.mobileDevice
            ? ''
            : 'or simply drag and drop files from your computer here'
        }`;
      default:
        return 'Search by file name or keywords';
    }
  }

  objectsAttached() {
    return this.allObjectsAdded().length > 0;
  }

  getAutoSuggestInputClass() {
    let className = 'autosuggest-search-container';
    className += this.objectsAttached() ? ' with-attached-cards' : '';
    return className;
  }

  renderSearchBar() {
    let onChangeProp = { onChange: this.setSearchText },
      { linkNotValidError } = this.state;
    return (
      <>
        <div
          className={`object-search-block ${
            linkNotValidError ? 'error-field-container' : ''
          }`}>
          <AutoSearchSuggestInput
            type={this.autoSearchSuggestInputType()}
            onClick={this.onSearchResultClick}
            clearSearchFilter={this.state.clearSearchFilter}
            onSearchReset={this.onSearchReset}
            additional_filters={this.getSearchAdditionalFilter()}
            className={this.getAutoSuggestInputClass()}
            dropDown={true}
            placeholder={this.getSearchPlaceholderUpload()}
            {...onChangeProp}>
            {this.isFileOrLinkTabSelected() && this.addNewFileOrLinkButton()}
          </AutoSearchSuggestInput>
          {linkNotValidError && (
            <div className='form-error'>{linkNotValidError}</div>
          )}
        </div>
      </>
    );
  }

  renderUploadSection() {
    let dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ';
    dropzoneInputContainerClassName += this.state.dropzoneActive
      ? 'dropzone-drag-active'
      : '';

    return (
      <>
        <section
          className={
            'dropzone-base-container ' +
            (this.state.files.length > 0 ? 'dropzone-files-present' : '')
          }>
          <Dropzone
            disableClick
            className={'modal-dropzone-area'}
            onDrop={this.onCustomDrop.bind(this)}
            onDragEnter={this.onDragEnter.bind(this)}
            onDragLeave={this.onDragLeave.bind(this)}
            ref={(node) => {
              this.dropzoneRef = node;
            }}
            maxSize={this.getMaxFileSizeAllowed()}
            onDropRejected={this.onDropRejected}>
            {(props) => (
              <div
                className={`modal-dropzone-area ${
                  this.state.dropzoneActive ? 'dropzone-active' : ''
                }`}>
                {this.renderUploadSectionBody()}

                <div
                  {...props.getRootProps({ onClick: this.onDialogInputClick })}
                  className={dropzoneInputContainerClassName}>
                  <input {...props.getInputProps()} />
                </div>
              </div>
            )}
          </Dropzone>
        </section>
        {this.renderCollection()}
      </>
    );
  }

  getAdditionalFilters() {
    let options = {};
    if (this.props.commentShare.options.excludeLinksOfSpaceId) {
      options['excludeLinksOfSpaceId'] =
        this.props.commentShare.options.excludeLinksOfSpaceId;
      options['idsAddedTemporarily'] = this.state.excludedCards
        .filter(
          (obj) => lowerCase(obj.__typename) === this.props.commentShare.type,
        )
        .map((obj) => obj.id);
    }

    if (this.props.commentShare.options.excludeLinksOfTabId) {
      options['excludeLinksOfTabId'] =
        this.props.commentShare.options.excludeLinksOfTabId;
    }

    if (this.props.commentShare.type !== 'tool') {
      let { only_published } = this.props.commentShare.options;
      options.only_published = isUndefined(only_published) || only_published;
    }
    return options;
  }

  allObjectsAdded() {
    return this.state.attachedCards.concat(this.state.files);
  }

  recordsToBeExcluded(type) {
    return this.objectsToExclude().filter((obj) => obj.__typename === type);
  }

  addFileCard = (file) => {
    let newFiles = this.state.files;
    newFiles.push(file);
    this.setState({ files: newFiles });
  };

  renderCollectionBasedOnType() {
    let { type } = this.props.commentShare,
      queryParams = {
        size: this.cardSize(),
        onCardClick: this.onCardClick,
        additional_filters: this.getAdditionalFilters(),
        parentContainer: this.collectionContainer,
      };
    if (type === 'video') {
      return (
        <OsBtn
          name='BtnSecondary'
          text='Record Video'
          onClick={this.showVideoModal}
          extraClass='with-border record-video-btn'
        />
      );
    }
    if (type === 'file') queryParams['onCardClick'] = this.addFileCard;
    switch (type) {
      case 'file':
      case 'case':
      case 'link':
      case 'board':
        queryParams = {
          ...pick(
            queryParams,
            'size',
            'onCardClick',
            'parentContainer',
            'additional_filters',
          ),
          textQuery: this.state.searchText,
          recordsNotToBeIncludes: this.recordsToBeExcluded(
            TYPE_MODEL_MAPPER[type]
              ? TYPE_MODEL_MAPPER[type]
              : capitalize(type),
          ),
        };
        break;
      case 'tool':
        queryParams = {
          ...queryParams,
          id_query: this.state.id_query,
          query_by: this.state.query_by,
          idsNotToBeListed: this.recordsToBeExcluded('Tool').map(
            (obj) => obj.id,
          ),
          setFilterSelected: this.setFilterSelected,
          object_filter: this.props.commentShare.options.object_filter,
        };
        break;
      default:
        queryParams = {
          ...queryParams,
          types: [this.props.commentShare.type],
          id_query: this.state.id_query || this.state.tagIds,
          query_by: this.state.query_by || 'tags',
          id_query2: this.state.genderTagId,
          range_query: this.state.range_query,
          objectsNotToBeListed: this.objectsToExclude(),
          updateTags: this.updateTags,
          setCollectionTotal: this.setCollectionCount,
        };
    }
    return <Collection type={type} queryParams={queryParams} />;
  }

  showVideoModal = () => {
    this.setState({ showVideoModal: true });
  };

  renderCollection() {
    return (
      <>
        <div className='top-row-cards'>{this.renderAttachedCards()}</div>
        <article className='add-message-tags-row'>
          <div className='dropzone-with-search'>
            {!this.videoTabSelected() && this.renderSearchBar()}
          </div>
        </article>
        <section
          className='modal-body-container'
          ref={(el) => (this.collectionContainer = el)}>
          {this.renderCollectionBasedOnType()}
        </section>
      </>
    );
  }

  getHeadingEntity() {
    return this.props.commentShare.source === 'Toolbox'
      ? 'Tools'
      : 'Attachments';
  }

  onRedirectToSupport = () => {
    this.onClose();
  };

  isCardsAvailable() {
    if (this.isLatestVersion()) {
      return this.state.files || this.state.attachedCards;
    } else if (this.filesTabSelected()) {
      return (
        this.getFilesTabObject().length ||
        this.state.rejectedFiles.length ||
        this.state.requestInProgress
      );
    } else if (this.videoTabSelected()) {
      return this.getRecordedFiles().length;
    } else {
      return this.getCurrentTabCards().length;
    }
  }

  renderNonOrthoBanner() {
    let { bannerKey } = this.props.commentShare.options;
    if (bannerKey)
      return (
        <div className='private-space-cmt mt-2'>{translate(bannerKey)}</div>
      );
  }

  getContainterClassName() {
    let className = 'comment-add-message-modal ',
      { type } = this.props.commentShare;
    className +=
      this.objectsAttached() || type !== 'file'
        ? 'attachment-available '
        : 'hide-footer ';
    className += this.isCardsAvailable() ? '' : 'no-attachment-available ';
    className += this.state.dropzoneActive ? 'dropzone-active-present ' : '';
    return className;
  }

  updateRecordedFilesState = (file) => {
    let oldFiles = this.state.files;
    oldFiles.push(file);
    this.setState({ files: oldFiles }, () => {
      if (this.state.files.length === 1) this.onSubmit();
    });
  };

  closeVideoModal = () => {
    this.setState({ showVideoModal: false }, () => {
      !this.showModal() && this.onSubmit();
    });
  };

  showModal() {
    let { commentShare } = this.props;
    return (
      commentShare.status === 'open' &&
      !commentShare.options.restrictModalOpening
    );
  }

  renderLegacyModalVersion() {
    let heading = this.getHeadingEntity(),
      open = this.showModal();

    return (
      <Modal
        show={open}
        animation={false}
        dialogClassName={this.getContainterClassName()}
        backdropClassName='modal-backdrop-custom backdrop-message-modal'>
        <div className={`${open ? 'assign-background' : ''}`}>
          <div
            className={`comment-share add-to-message-modal assign-overlay ${
              open ? 'assign-overlay--show' : ''
            }`}>
            <div className='container modal-xl modal-global add-message-modal assign-overlay__content'>
              <div className='add-modal-top-block'>
                <div className='modal-header add-message-header'>
                  <div className='row add-message-header-row'>
                    <div className='col-sm-12 add-message-title-row'>
                      <h4 className='card-title'>
                        Add {heading} to {this.props.commentShare.source}
                      </h4>
                      <OsBtn
                        name='BtnIcon'
                        extraClass='no-text os-header-btn web-view-btn y-center close-assign-overlay'
                        icon='close'
                        onClick={this.onClose}
                        label='Close add attachments modal'
                      />
                    </div>
                    {this.renderMainNav()}
                  </div>
                </div>
              </div>
              <article className='modal-body'>
                {this.filesTabSelected()
                  ? this.renderUploadSection()
                  : this.renderCollection()}
              </article>
            </div>
            <div
              className={
                'modal-footer user-board-modal-footer ' +
                (this.state.files.length > 0 ? 'dropzone-modal-footer' : '')
              }>
              <div className='container'>
                <div className='user-board-modal-footer-buttons'>
                  <div className='add-message-modal-footer-left'>
                    {this.renderNonOrthoBanner()}
                  </div>
                  <div className='add-message-modal-footer-right'>
                    <OsBtn
                      name='BtnPrimary'
                      text={
                        this.state.requestInProgress
                          ? 'Adding...'
                          : 'Add attachments'
                      }
                      htmlTag='button'
                      onClick={this.onSubmit}
                      loaderRequired={this.state.requestInProgress}
                      disabled={this.isSubmitDisabled()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderErrorModal(
          FILE_TYPES_ALLOWED,
          this.getMaxFileSizeAllowed(),
        )}
      </Modal>
    );
  }

  onChange = ({ target }) => {
    let searchText = target.value;
    searchText = (searchText && searchText.trim()) || '';
    this.setSearchText(searchText);
  };

  setInputActive = () => {
    this.setState({ inputActive: true });
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  onSearchChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  onCardViewSelect = (cardViewType) => {
    this.setState({ cardViewType });
  };

  onFilterValueChange = (filterValues) => {
    this.setState({ filterValues });
  };

  getCardViewType() {
    return this.state.cardViewType;
  }

  onSortChange = (sortQuery) => {
    this.setState({ sortQuery });
  };

  getKey() {
    return this.state.filter;
  }

  getSearchAndFilterOptions() {
    return {
      onSearchChange: this.onSearchChange,
      onCardViewSelect: this.onCardViewSelect,
      cardViewType: this.getCardViewType(),
      searchRequired: true,
      onFilterValueChange: this.onFilterValueChange,
      filterSource: this.getKey(),
      onSortChange: this.onSortChange,
      sortQuery: this.state.sortQuery,
      filterValues: this.state.filterValues,
    };
  }

  isFilterNotRequired() {
    return ['my_links', 'my_cases'].includes(this.state.selectedFolder?.id);
  }

  getSearchPlaceholder() {
    return this.state.selectedFolder
      ? 'Search this folder'
      : 'Search all content';
  }

  resetSearch = () => {
    this.setState({ searchText: '' });
  };

  disableFilter() {
    return !this.state.selectedFolder;
  }

  disableCardSize() {
    return !(this.state.selectedFolder || this.state.searchText);
  }

  disableSort() {
    return !this.state.selectedFolder && this.state.searchText;
  }

  renderSearchField() {
    const searchAndFilterOptions = this.getSearchAndFilterOptions();
    return (
      <div className='folder-views-area'>
        <div className='input-wrapper nav-search-holder input-focus space-search-container'>
          <div
            className={`nav-search-container ${
              this.state.inputActive ? 'input-focus-active' : ''
            }`}
            onFocus={this.setInputActive}
            onBlur={this.unsetInputActive}>
            <label className='position-relative m-0 w-100'>
              <OrthoIcon name='search' dataHoverNotRequired={true} />
              <OsField
                osType='input'
                type='text'
                placeholder={this.getSearchPlaceholder()}
                className='nav-search-input'
                name='search'
                value={this.state.searchText}
                onChange={searchAndFilterOptions.onSearchChange}
              />
              {this.state.searchText && (
                <div
                  className='clear-input ifill-os-clear'
                  onClick={this.resetSearch}></div>
              )}
            </label>
          </div>
        </div>
        <SpaceContentSortDropdown
          onChange={searchAndFilterOptions.onSortChange}
          selected={searchAndFilterOptions.sortQuery}
          onDropDownToggle={this.sortDropDownToggle}
          disabled={this.disableSort()}
        />
        {!this.isFilterNotRequired() && (
          <SpaceFilterDropdown
            onChange={searchAndFilterOptions.onFilterValueChange}
            filterSource={searchAndFilterOptions.filterSource}
            filterValues={searchAndFilterOptions.filterValues}
            disabled={this.disableFilter()}
            source='attachment-modal'
          />
        )}
        {this.renderCardSizeIcons()}
      </div>
    );
  }

  setSelectedFolder = (folder) => {
    this.setState({ selectedFolder: folder });
  };

  renderCardSizeIcons() {
    const searchAndFilterOptions = this.getSearchAndFilterOptions();
    return (
      <CardGridViewSelector
        viewType={searchAndFilterOptions.cardViewType}
        onSelect={searchAndFilterOptions.onCardViewSelect}
        disabled={this.disableCardSize()}
      />
    );
  }

  recordsCommonProps() {
    return {
      windowNotRequired: true,
      loadingCards: false,
      perPage: 24,
      threshold: 100,
      queryType: 'TAB_DETAILS',
    };
  }

  getContentProps() {
    const { device } = this.props;
    let cardSize = device.mobileDevice ? 'list' : this.state.cardViewType;
    return {
      additional_filters: { filter_values: this.state.filterValues },
      size: cardSize,
      sortQuery: this.state.sortQuery,
      cardGridIdentifier: getSpaceCardGridIdentifier(
        this.state.cardViewType,
        'NewAttachmentModal',
      ),
      cardProps: {
        onCardClick: this.onCardClick,
        selectionMode: true,
        size: cardSize,
        multiSelectMode: true,
        cardClickRequired: true,
      },
      selectedRecords: this.allObjectsAdded(),
      recordsNotToBeIncludes: this.objectsToExclude(),
      processCardObject: (card) => (card.linkable ? card.linkable : card),
    };
  }

  renderAllContents() {
    let className =
        this.getCardViewType() === 'list'
          ? 'space-links-container all'
          : 'modal-body-container',
      props = {
        type: 'AllFolderLinks',
        ...this.getContentProps(),
        searchQuery: this.state.searchText.trim(),
      };
    return (
      <div className={`${className} ${this.getCardViewType()}`}>
        <RecordsView {...props} {...this.recordsCommonProps()} />
      </div>
    );
  }

  renderFoldersOrContent() {
    if (this.state.selectedFolder) {
      return this.renderFolderContent();
    } else {
      return this.state.searchText
        ? this.renderAllContents()
        : this.renderAllFolders();
    }
  }

  getSearchProps() {
    const { __typename: type } = this.state.selectedFolder;
    if (type === 'FolderSpace') {
      return { searchQuery: this.state.searchText.trim() };
    } else {
      return { textQuery: this.state.searchText.trim() };
    }
  }

  renderFolderContent() {
    const { selectedFolder } = this.state;
    let className =
        this.getCardViewType() === 'list'
          ? 'space-links-container all'
          : 'modal-body-container',
      props = {
        type: selectedFolder.queryType || 'FolderLinks',
        idQuery: selectedFolder.id,
        ...this.getSearchProps(),
        ...this.getContentProps(),
      };
    return (
      <div className={`${className} ${this.getCardViewType()}`}>
        <RecordsView
          space={selectedFolder}
          {...props}
          {...this.recordsCommonProps()}
        />
      </div>
    );
  }

  getSearchQuery() {
    return this.state.searchText;
  }

  renderAllFolders() {
    let { searchText } = this.state;
    return (
      <div className='modal-body-container'>
        <FoldersListing
          onClick={this.setSelectedFolder}
          type='AccessibleMyFoldersWithContent'
          cardViewType={'extra-small'}
          searchQuery={searchText}
          sortQuery={this.state.sortQuery}
          cardGridIdentifier={'NewAttachmentModal:Card'}
          source={'modal'}
        />
      </div>
    );
  }

  renderFoldersScreenHeadingContent() {
    let { type } = this.props.commentShare,
      { mobileDevice } = this.props.device,
      heading,
      subText;
    if (type === 'add-to-folder') {
      if (mobileDevice) {
        heading = 'Add from folders';
        subText = translate('FOLDER_ADD_MOBILE_HEADING');
      } else {
        heading = 'Add content from my folders';
        subText = translate('FOLDER_ADD_HEADING');
      }
    } else {
      if (mobileDevice) {
        heading = 'Attach from folders';
        subText = translate('FOLDER_ATTACH_MOBILE_HEADING');
      } else {
        heading = 'Attach content from my folders';
        subText = translate('FOLDER_ATTACH_HEADING');
      }
    }
    return (
      <>
        <h4 className='card-title'>{heading}</h4>
        <h6 className='card-sm-title'>{subText}</h6>
      </>
    );
  }

  resetSelectedFolder = () => {
    this.setState({
      selectedFolder: null,
      cardViewType: 'big',
      files: [],
      attachedCards: [],
      filterValues: [],
    });
  };

  renderSelectedFolderName() {
    const { selectedFolder } = this.state;
    return (
      <h4 className='card-title'>
        <span
          className='os-49e cursor-pointer hover-underline'
          onClick={this.resetSelectedFolder}>
          Folders
        </span>{' '}
        <OrthoIcon
          name='chevron-right'
          defaultClass='px-1 fs-16'
          dataHoverNotRequired={true}
        />{' '}
        <span>{selectedFolder.name}</span>
      </h4>
    );
  }

  renderHeadingContent() {
    return this.state.selectedFolder
      ? this.renderSelectedFolderName()
      : this.renderFoldersScreenHeadingContent();
  }

  isTableView() {
    return this.state.selectedFolder && this.state.cardViewType === 'list';
  }

  renderHeadingIfRequired() {
    if (this.isTableView()) {
      return (
        <div className='row header-row'>
          <div className='col-12'>
            <div className='row table-card-row header'>
              <div className='sorting-option name-block'>File Name</div>
              <div className='sorting-option cd-date-added'>Date added</div>
              <div className='file-type'>File Type</div>
              <div className='sorting-option cd-last-used d-none'>
                Date Last Used
              </div>
              <div className='sorting-option cd-list-select ms-auto'>
                Select
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getAttachBtnText() {
    let { type } = this.props.commentShare,
      { mobileDevice } = this.props.device;
    return `${type === 'add-to-folder' ? 'Attach' : 'Add'} (${
      this.allObjectsAdded().length
    }) ${mobileDevice ? '' : 'Selected'} ${
      this.allObjectsAdded().length > 1 ? 'Files' : 'File'
    }`;
  }

  clearSelection = () => {
    this.setState({ files: [], attachedCards: [] });
  };

  renderLatestModalVersion() {
    let open = this.showModal();
    return (
      <Modal
        show={open}
        animation={false}
        dialogClassName={this.getContainterClassName()}
        backdropClassName='modal-backdrop-custom backdrop-message-modal'>
        <div className={`${open ? 'assign-background' : ''}`}>
          <div
            className={`comment-share add-to-message-modal assign-overlay add-folder-modal ${
              open ? 'assign-overlay--show' : ''
            }`}>
            <div className='container modal-xl modal-global add-message-modal assign-overlay__content '>
              <div className='add-modal-top-block'>
                <div className='modal-header add-message-header'>
                  <div className='row add-message-header-row'>
                    <div className='col-sm-12 add-message-title-row'>
                      {this.renderHeadingContent()}
                      <OsBtn
                        name='BtnIcon'
                        extraClass='no-text os-header-btn web-view-btn y-center close-assign-overlay'
                        icon='close'
                        onClick={this.onClose}
                        label='Close add attachments modal'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <article className={`modal-body folder-modal-body`}>
                {this.renderSearchField()}
                {this.renderHeadingIfRequired()}
                {this.renderFoldersOrContent()}
              </article>
            </div>
            <div
              className={
                'modal-footer user-board-modal-footer ' +
                (this.state.files.length > 0 ? 'dropzone-modal-footer' : '')
              }>
              <div className='container'>
                <div className='user-board-modal-footer-buttons'>
                  <div className='add-message-modal-footer-left'>
                    {this.renderNonOrthoBanner()}
                  </div>
                  <div className='add-message-modal-footer-right'>
                    {!this.state.requestInProgress && (
                      <OsBtn
                        name='BtnSecondary'
                        extraClass='with-border'
                        text='Clear Selection'
                        htmlTag='button'
                        onClick={this.clearSelection}
                        disabled={!this.objectsAttached()}
                      />
                    )}
                    <OsBtn
                      name='BtnPrimary'
                      text={
                        this.state.requestInProgress
                          ? 'Adding...'
                          : this.getAttachBtnText()
                      }
                      htmlTag='button'
                      onClick={this.onSubmit}
                      loaderRequired={this.state.requestInProgress}
                      disabled={this.isSubmitDisabled()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderModal() {
    return this.isLatestVersion()
      ? this.renderLatestModalVersion()
      : this.renderLegacyModalVersion();
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <VideoRecord
          isOnlyAudioRecording={
            this.props.commentShare?.options?.isOnlyAudioRecording
          }
          onSuccess={this.updateRecordedFilesState}
          showModal={this.state.showVideoModal}
          closeModal={this.closeVideoModal}
        />
      </>
    );
  }
}

CommentShareView = withRouter(CommentShareView);
CommentShareView = connect(
  ({ currentUser, commentShare, device }) => ({
    currentUser,
    commentShare,
    device,
  }),
  { commentShareChangeType, commentShareConsume, commentShareAssign },
)(CommentShareView);

CommentShareView = withApollo(CommentShareView);
export default CommentShareView;
