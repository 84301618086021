import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import client from 'app/graphql/client';

import EventTracker from 'app/services/EventTracker';
import OsBtn from 'app/components/OsBtn';
import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withAuthorize from 'app/components/HOC/withAuthorize';

import { isCareSpace } from 'app/utils/spaceHelper';
import { capitalize } from 'app/utils/stringHelper';
import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { setWorkspaceData } from 'app/actions/workspace';

import { openInfoModal } from 'app/actions/infoModal';
import { cloneDeep } from 'app/utils/osLodash';
const FOLLOW_BOARD_MUTATION = queryLoader('app/graphql/FollowBoard.gql');
const UNFOLLOW_BOARD_MUTATION = queryLoader('app/graphql/UnfollowBoard.gql');

const MUTATIONS = {
  followMutation: {
    board: FOLLOW_BOARD_MUTATION,
  },
  unfollowMutation: {
    board: UNFOLLOW_BOARD_MUTATION,
  },
};

class Follow extends Component {
  follow = () => {
    this.props.client
      .mutate({
        mutation: MUTATIONS['followMutation'][this.props.type],
        variables: {
          followeeId: this.props.obj.id,
        },
      })
      .then(() => {
        this.sendFollowEvent();
        this.setWorkspaceDataIfRequired(true);
      });
  };

  setWorkspaceDataIfRequired(followed_by_current_user) {
    if (this.props.obj.nice_id === this.props.workspace?.data?.nice_id) {
      this.props.setWorkspaceData({
        ...this.props.obj,
        followed_by_current_user,
      });
    }
  }

  sendFollowEvent() {
    EventTracker.trackForEntity('follow', this.props.obj);
  }

  unfollow = () => {
    this.props.client
      .mutate({
        mutation: MUTATIONS['unfollowMutation'][this.props.type],
        variables: {
          followeeId: this.props.obj.id,
        },
      })
      .then(() => {
        this.setWorkspaceDataIfRequired(false);
      });
  };

  handleFollow = (event) => {
    event.preventDefault();
    !this.props.propagateClick && event.stopPropagation();
    this.props.redirectToDefaultIfUnauthenticated(
      this.followEntity.bind(this, event),
    );
  };

  followEntity = (event) => {
    this.props.onAction();
    this.props.openUpgradeModalIfUnauthorized(this.follow);
  };

  openConfirmationIfRequired = () => {
    if (this.props.muteRequired) {
      let space_type = isCareSpace(this.props.obj) ? 'PatientSpace' : 'Space';
      this.props.openInfoModal('space', 'mute_confirmation', {
        contentInterpolations: { space_type },
        onSuccess: this.unfollow,
        closeIconNotRequired: true,
      });
    } else {
      this.unfollow();
    }
  };

  handleUnfollow = (event) => {
    event.preventDefault();
    !this.props.propagateClick && event.stopPropagation();
    this.props.onAction();
    this.props.openUpgradeModalIfUnauthorized(this.openConfirmationIfRequired);
  };

  getName() {
    return this.props.name || 'BtnSecondary';
  }

  getExtraClass(extraClass) {
    if (!this.props.iconNotRequired && !this.props.isNotFromDropdown) {
      extraClass += ' list-button ';
    } else {
      extraClass += '  ';
    }
    return extraClass;
  }

  getButtonText() {
    let obj = this.props.obj;
    if (this.props.muteRequired && isCareSpace(obj)) {
      return obj.followed_by_current_user
        ? 'Mute PatientSpace'
        : 'Unmute PatientSpace';
    } else if (this.props.muteRequired) {
      return obj.followed_by_current_user ? 'Mute' : 'Unmute';
    } else {
      return obj?.followed_by_current_user ? 'Unfollow' : 'Follow';
    }
  }

  getIconName() {
    let obj = this.props.obj,
      iconKey = 'follow';
    if (this.props.muteRequired) {
      iconKey = obj.followed_by_current_user ? 'mute' : 'unmute';
    }
    return iconKey;
  }

  isObj(type) {
    return this.props.obj.__typename === type;
  }

  render() {
    var following = this.props.obj?.followed_by_current_user,
      extraClass =
        following && !this.props.iconNotRequired && !this.props.muteRequired
          ? 'active '
          : '',
      label = this.getButtonText();

    if (this.props.extraClass) {
      extraClass += this.props.extraClass;
    }

    if (this.props.device.mobileDevice && !this.props.iconNotRequired) {
      return (
        <OsBtn
          name='BtnIcon'
          extraClass={extraClass}
          icon={this.getIconName()}
          onClick={(e) =>
            following ? this.handleUnfollow(e) : this.handleFollow(e)
          }
          associatedEntity={this.props.obj}
          label={label}>
          {this.props.labelRequired && label}
        </OsBtn>
      );
    } else {
      return (
        <OsBtn
          name={!this.props.iconNotRequired ? 'BtnIcon' : this.getName()}
          text={label}
          associatedEntity={this.props.obj}
          extraClass={this.getExtraClass(extraClass)}
          icon={!this.props.iconNotRequired && this.getIconName()}
          onClick={(e) =>
            following ? this.handleUnfollow(e) : this.handleFollow(e)
          }
        />
      );
    }
  }
}

Follow = withRouter(Follow);
Follow = withAuthorize(Follow);
Follow.defaultProps = {
  onAction: () => {},
  abilityObject: getAuthorizeObject('follow', 'all'),
};
Follow = connect(
  ({ device, currentUser, workspace }) => ({ device, currentUser, workspace }),
  {
    openInfoModal,
    setWorkspaceData,
  },
)(Follow);
Follow = withApollo(Follow);
Follow = withAuthenticate(Follow);
export default Follow;
