import {
  CLOSE_PARTNER_SPACE_MODAL,
  OPEN_PARTNER_SPACE_MODAL,
  SET_PARTNER_SPACE_LABEL,
} from 'app/actions/types';

const initialState = {
  open: false,
  selectedLabel: null,
};

const partnerSpaces = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PARTNER_SPACE_MODAL:
      return { ...state, open: true };
    case CLOSE_PARTNER_SPACE_MODAL:
      return { ...state, open: false };
    case SET_PARTNER_SPACE_LABEL:
      return { ...state, selectedLabel: action.payload.selectedLabel };
    default:
      return state;
  }
};

export default partnerSpaces;
