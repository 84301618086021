import React, { Component, createContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import * as compose from 'lodash.flowright';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loaders';
import scrollToElement from 'scroll-to-element';

import AttachmentsInDiscussionListing from 'app/components/Widgets/AttachmentsInDiscussionListing';
import CommentsListing from 'app/components/CommentSection/CommentsListing';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import RenderLoadingCards from 'app/components/Cards/RenderLoadingCards';
import RepliesListing from 'app/components/CommentSection/RepliesListing';
import GroupedListing from 'app/components/Shared/GroupedListing';
import SpaceMembersList from 'app/components/BoardView/SpaceMembersList';

import withInitialRecords from 'app/components/HOC/withInitialRecords';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { CANNOT_VIEW_COMMENTS_QUERIES } from 'app/constant/feed';

import { cardSize } from 'app/utils/osCardHelper';

import {
  difference,
  first,
  floor,
  indexOf,
  isNull,
  isUndefined,
  keys,
  last,
  map,
  uniqBy,
} from 'app/utils/osLodash';
import {
  getCommentOffset,
  isCenterInViewport,
  isInViewport,
} from 'app/utils/domHelper';

import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';
import SpaceMessageListing from 'app/components/GroupMessenger/SpaceMessageListing';
import SpaceReplies from 'app/components/GroupMessenger/SpaceReplies';
import SpaceMembers from 'app/components/GroupMessenger/SpaceMembers';
import SpaceMembersView from 'app/components/GroupMessenger/SpaceMembersView';
import SectionFolderList from 'app/components/SideBarFolderList/SectionFolderList';
import CollaboratingFolderList from 'app/components/SideBarFolderList/CollaboratingFolderList';
import FeedsView from 'app/components/FeedsView';

let QUERY_MAPPER_WITH_PAGE = {};

QUERY_MAPPER_WITH_PAGE['COMMENTS'] = queryLoader(
  'app/graphql/queries/Comments.gql',
);
QUERY_MAPPER_WITH_PAGE['SPACE_MESSAGES'] = queryLoader(
  'app/graphql/queries/TeamSpaceComments.gql',
);
QUERY_MAPPER_WITH_PAGE['SPACE_MEMBERS'] = queryLoader(
  'app/graphql/queries/SpaceMemberCollaborators.gql',
);
QUERY_MAPPER_WITH_PAGE['SPACE_DETAILS'] = queryLoader(
  'app/graphql/queries/Spaces/SpaceDetailsPager.gql',
);
QUERY_MAPPER_WITH_PAGE['USER_DETAILS'] = queryLoader(
  'app/graphql/queries/Users/UserDetailsPager.gql',
);
QUERY_MAPPER_WITH_PAGE['GUEST_USER_DETAILS'] = queryLoader(
  'app/graphql/queries/Users/GuestUserDetailsPager.gql',
);
QUERY_MAPPER_WITH_PAGE['COMMENTS_ACTIVITY_LISTING'] = queryLoader(
  'app/graphql/queries/Comments/ActivityListingPager.gql',
);
QUERY_MAPPER_WITH_PAGE['PARTNER_COMMENTS_ACTIVITY_LISTING'] = queryLoader(
  'app/graphql/queries/Comments/PartnerActivityListing.gql',
);

const VALID_QUERIES_TYPES = keys(QUERY_MAPPER_WITH_PAGE);

class RecordsPager extends Component {
  state = {
    scrollTop: 0,
    topMostRecordInViewport: false,
  };

  componentDidMount() {
    this.props.infiniteScroll &&
      this.props.infiniteUpwardsScroll &&
      this.addScrollEvent();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.removeScrollEvent();
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.setCollectionTotal &&
      nextProps.records_pager &&
      nextProps.records_pager.total !== nextProps.collectionTotal
    ) {
      nextProps.setCollectionTotal(nextProps.records_pager.total);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let prevRecords = prevProps.records_pager || {},
      newRecords = this.props.records_pager || {},
      prevResults = prevRecords.results,
      newResults = newRecords.results;

    if (
      prevResults &&
      newResults &&
      prevResults.length &&
      prevResults.length < newResults.length
    ) {
      if (indexOf(map(newResults, 'id'), first(prevResults).id) > 0) {
        // Note: Scroll to 1st record of the new batch loaded at the top
        let scrollToRecordId =
            +newResults[newResults.length - prevResults.length - 1].id,
          recordElement = this.getRecordElementById(scrollToRecordId);

        if (recordElement && !isInViewport(recordElement)) {
          recordElement.scrollIntoView();
          scrollToElement(recordElement, {
            ease: 'linear',
            offset: this.getOffset(),
            duration: 1,
          });
        }
      }
    }

    if (prevRecords.before_has_more !== newRecords.before_has_more) {
      this.props.updateBeforeHasMore(this.beforeHasMore());
      !newRecords.before_has_more && this.removeScrollEvent();
    }

    if (prevRecords.after_has_more !== newRecords.after_has_more)
      this.props.updateAfterHasMore(this.afterHasMore());

    if (
      this.props.infiniteScroll &&
      this.props.infiniteUpwardsScroll &&
      !prevState.topMostRecordInViewport &&
      this.state.topMostRecordInViewport
    )
      this.loadMoreBefore();

    if (
      this.props.hasMore &&
      !this.props.loading &&
      this.props.infiniteScroll
    ) {
      // Wait for the DOM to render and then check scroll possible to avoid extra query.
      setTimeout(() => {
        this.mounted && this.isScrollNotPossible() && this.loadNextPage();
      }, 1000);
    }

    if (
      prevProps.loading &&
      !this.props.loading &&
      this.props.updateCurrentUserAttribute
    ) {
      let values = {};
      values[this.props.updateCurrentUserAttribute] =
        this.props.records_pager.total;
      this.props.updateCurrentUserGraph(values);
    }

    if (this.props.highlightSearchTerms) this.props.highlightSearchTerms();
  }

  isScrollNotPossible() {
    if (this.props.windowNotRequired) {
      // Used to Invite user to sapce modal when one list is selected and then modal is closed. Parent container is coming as undefined.
      let parentContainer =
        this.props.parentContainer ||
        this.containerRef.scrollComponent.parentElement;
      return (
        parentContainer &&
        this.containerRef &&
        parentContainer.offsetHeight > 0 &&
        parentContainer.offsetHeight >=
          this.containerRef.scrollComponent.firstElementChild.offsetHeight
      );
    } else {
      return window.innerHeight >= document.body.scrollHeight;
    }
  }

  loadNextPage = () => {
    if (this.props.hasMore && !this.props.loading) {
      this.props.loadMoreEntries(this.props.limit);
    }
  };

  currentPage() {
    let {
      records_pager: { results },
      limit,
    } = this.props;
    // NOTE:GP -1 is added as Current Page is starting from 0;
    return floor(results.length / limit) - 1;
  }

  addScrollEvent() {
    window.addEventListener('scroll', this.handleScroll);
  }

  removeScrollEvent() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let records = this.props.records_pager || {},
      results = records.results,
      topMostRecord = first(results),
      element = topMostRecord && this.getRecordElementById(topMostRecord.id);

    element &&
      this.setState({ topMostRecordInViewport: isCenterInViewport(element) });
  };

  getOffset() {
    let offset = 0;
    if (
      this.props.queryType === 'COMMENTS' ||
      this.props.queryType === 'SPACE_MESSAGES'
    )
      offset = getCommentOffset(this.props.commentableType, this.props.device);
    return offset;
  }

  getRecordElementById(recordId) {
    switch (this.props.type) {
      case 'Comments':
      case 'Replies':
        return document.getElementById('comment-' + recordId);
      default:
        return null;
    }
  }

  metaInfo() {
    return this.props.records_pager && this.props.records_pager.meta_info
      ? JSON.parse(this.props.records_pager.meta_info)
      : {};
  }

  notInitialLoad() {
    return (
      this.props.records_pager && this.props.records_pager.results.length !== 0
    );
  }

  record() {
    return (this.props.records_pager || {}).record;
  }

  results() {
    if (this.props.records_pager) {
      // return this.props.records_pager.results;
      return this.isReverse()
        ? this.props.records_pager.results.reverse()
        : this.props.records_pager.results;
    } else {
      return [];
    }
  }

  secondary_results() {
    if (this.props.records_pager) {
      return this.props.records_pager.secondary_results;
    } else {
      return [];
    }
  }

  afterHasMore() {
    return this.props.records_pager && this.props.records_pager.after_has_more;
  }

  beforeHasMore() {
    return this.props.records_pager && this.props.records_pager.before_has_more;
  }

  commentProps() {
    let commentable = {
      ...(this.props.entity || {}),
      ...(this.props.commentable || {}),
    };
    return {
      commentable: commentable,
      commentableEntityOwner: this.props.commentableEntityOwner,
      commentableId: this.props.commentableId,
      commentableType: this.props.commentableType,
      editCommentId: this.props.editCommentId,
      entity: commentable,
      readOnly: this.props.readOnly,
      replyToCommentId: this.props.replyToCommentId,
      scrollToCommentId: this.props.scrollToCommentId,
      setEditCommentId: this.props.setEditCommentId,
      setScrollToCommentId: this.props.setScrollToCommentId,
      source: this.props.source,
    };
  }

  showPreviousLoading() {
    return this.props.loading && this.notInitialLoad() && this.beforeHasMore();
  }

  loadNextCTARequired() {
    return (
      !this.props.infiniteScroll && !this.props.loading && this.afterHasMore()
    );
  }

  showNextLoading() {
    return this.props.loading && this.notInitialLoad() && this.afterHasMore();
  }

  loadMoreBefore = () => {
    !this.props.loading &&
      this.beforeHasMore() &&
      this.props.loadMoreEntries(this.props.limit, true);
  };

  loadMoreAfter = () => {
    !this.props.loading &&
      this.afterHasMore() &&
      this.props.loadMoreEntries(this.props.limit);
  };

  beforeThreshold() {
    return this.props.beforeThreshold;
  }

  afterThreshold() {
    return this.props.afterThreshold;
  }

  fourPerRow() {
    return this.props.size === 'small-min';
  }

  getCardClass(card) {
    if (this.fourPerRow()) {
      return 'col-12 col-md-4 col-lg-3 mb-4';
    } else if (this.props.twoPerRow) {
      return 'col-12 col-md-4 col-lg-3 mb-4 col-sm-6  ';
    } else {
      return `col-12 col-md-4 col-lg-3 mb-4 col-sm-6  ${
        this.props.cardsCustomMarginRequired ? 'space-card-col' : ''
      }`;
    }
  }

  renderInitialLoader() {
    if (this.props.loadingCards) {
      let loadingCardsCount =
        this.props.loadingCardsCount ||
        this.props.initialLimit ||
        this.props.limit;
      return (
        <RenderLoadingCards
          count={loadingCardsCount}
          className={this.getCardClass()}
          size={cardSize({
            size: this.props.cardSize,
            width: this.props.device.width,
            mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
          })}
          cardGridIdentifier={this.props.cardProps.cardGridIdentifier}
        />
      );
    } else {
      return <Loader type='ball-triangle-path' active />;
    }
  }

  renderLoadingDots() {
    return <div className='home-loader'></div>;
  }

  refetchData = (object) => {
    if (this.props.refetchDataRequired) {
      this.props.refetch();
      this.props.onRefetch(object);
    }
  };

  getContextProps() {
    return {
      refetchData: this.refetchData,
    };
  }

  renderCollection() {
    let initialRequestInProgress = this.props.loading && !this.notInitialLoad(),
      dataNotLoaded = !this.props.records_pager;

    if (initialRequestInProgress || dataNotLoaded) {
      return this.renderInitialLoader();
    } else {
      let props = {
        afterHasMore: this.afterHasMore(),
        headerText: this.props.headerText,
        loading: this.props.loading,
        loadMoreAfter: this.loadMoreAfter,
        metaInfo: this.metaInfo(),
        noResultFound: this.props.noResultFound,
        aroundId: this.props.aroundId,
        queryType: this.props.queryType,
        record: this.record(),
        recordId: this.props.recordId,
        recordType: this.props.recordType,
        results: this.results(),
        scrollToBottom: this.isReverse(),
        secondary_results: this.secondary_results(),
        type: this.props.type,
        cardSize: this.props.cardSize,
        twoPerRow: this.props.twoPerRow,
        widgetAfterListItemNumber: this.props.widgetAfterListItemNumber,
        seeAllNotRequired:
          this.props.infiniteScroll || this.props.seeAllNotRequired,
        cardProps: this.props.cardProps,
        cardGridIdentifier: this.props.cardGridIdentifier,
      };

      switch (this.props.type) {
        case 'AttachmentsInDiscussions':
        case 'AttachmentsInCareDiscussions':
          return (
            <AttachmentsInDiscussionListing
              {...props}
              source={this.props.source}
            />
          );
        case 'Comments':
          return (
            <CommentsListing
              {...props}
              {...this.commentProps()}
              aroundId={this.props.aroundId}
              isBlurred={this.props.isBlurred}
              refetchOnPin={this.props.refetchOnPin}
              sortQuery={this.props.sortQuery}
            />
          );
        case 'GroupSpaceActivities':
          return (
            <SpaceMessageListing
              {...props}
              {...this.commentProps()}
              aroundId={this.props.aroundId}
              isBlurred={this.props.isBlurred}
              refetchOnPin={this.props.refetchOnPin}
              sortQuery={this.props.sortQuery}
              subscribeToMore={this.props.subscribeToMore}
              spaceId={this.props.commentable?.id}
            />
          );
        case 'PartnerSpaceActivities':
        case 'CareSpaceActivities':
          return (
            <FeedsView
              {...props}
              listType={this.props.type}
              feedType={'carespace-feed-view'}
              subscribeToMore={this.props.subscribeToMore}
            />
          );
        case 'Replies':
          if (this.props.queryType === 'SPACE_MESSAGES') {
            return (
              <SpaceReplies
                {...props}
                {...this.commentProps()}
                beforeHasMore={() => this.beforeHasMore()}
                loadMoreBefore={() => this.loadMoreBefore()}
              />
            );
          }
          return (
            <RepliesListing
              {...props}
              {...this.commentProps()}
              closeCommentThread={this.props.closeCommentThread}
              beforeHasMore={() => this.beforeHasMore()}
              loadMoreBefore={() => this.loadMoreBefore()}
              hideReplyButton={this.props.hideReplyButton}
              openParentReply={this.props.openParentReply}
              beforeId={this.props.beforeId}
              activityId={this.props.activityId}
            />
          );
        case 'SpaceMemberCollaborators':
          if (this.props.displayType === 'SPACE_USER_LIST') {
            return (
              <SpaceMembers
                {...props}
                space={this.props.space}
                isCurrentUserProfile={this.props.isCurrentUserProfile}
                initialLimit={this.props.initialLimit}
              />
            );
          }
          return (
            <SpaceMembersView
              {...props}
              space={this.props.space}
              displayType={this.props.displayType}
              searchText={this.props.textQuery}
            />
          );
        case 'SpaceAcceptedOrPendingCollaborators':
          return (
            <SpaceMembersList
              {...props}
              space={this.props.space}
              searchText={this.props.textQuery}
            />
          );
        case 'CollaboratoringFolders':
          if (this.props.displayType === 'SIDEBAR') {
            return (
              <CollaboratingFolderList {...props} title={this.props.title} />
            );
          }
          return (
            <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
              <GroupedListing
                {...props}
                isCurrentUserProfile={this.props.isCurrentUserProfile}
                user={this.props.user}
                initialLimit={this.props.initialLimit}
                dynamicTemplates={this.props.dynamicTemplates}
              />
            </CARD_ACTION_CONTEXT.Provider>
          );
        case 'PendingFolders':
          if (this.props.displayType === 'SIDEBAR') {
            return (
              <SectionFolderList
                {...props}
                title={this.props.title}
                count={this.props.records_pager.total}
              />
            );
          }
          return (
            <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
              <GroupedListing
                {...props}
                isCurrentUserProfile={this.props.isCurrentUserProfile}
                user={this.props.user}
                initialLimit={this.props.initialLimit}
                dynamicTemplates={this.props.dynamicTemplates}
              />
            </CARD_ACTION_CONTEXT.Provider>
          );
        case 'AccessibleMyFoldersWithContent':
        case 'MySpaces':
        case 'MyFolders':
        case 'SpaceAdminAndEditors':
        case 'SpaceCollaboratingClinics':
        case 'SpaceConnectedMembers':
        case 'SpaceNonConnectedMembers':
        case 'UserArchivedSpaces':
        case 'UserCompletedCoursesInYear':
        case 'UserCurrentCourses':
        case 'UserPastCourses':
        case 'UserUpcomingCourses':
        case 'UserSpaces':
          return (
            <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
              <GroupedListing
                {...props}
                isCurrentUserProfile={this.props.isCurrentUserProfile}
                user={this.props.user}
                initialLimit={this.props.initialLimit}
                dynamicTemplates={this.props.dynamicTemplates}
              />
            </CARD_ACTION_CONTEXT.Provider>
          );
        default:
          return null;
      }
    }
  }

  onLoadNextCTA = () => {
    this.loadMoreAfter();
    this.props.onLoadMoreCTAClick();
  };

  renderLoadNextCTA() {
    if (this.props.loadMoreButtonRequired) {
      switch (this.props.type) {
        case 'Replies':
          return (
            <OsLink
              className='btn-link-32 btn-view latest-record-link'
              onClick={this.onLoadNextCTA}
              text='SHOW LATEST COMMENTS'
            />
          );
        case 'MySpaces':
          let extraClass = '',
            text = 'See all';
          if (this.props.device.mobileDevice) {
            extraClass = 'mr-16 btn-cmn-all-spaces';
            text = 'View all';
          }
          return (
            <span className='see-all-parent'>
              <OsBtn
                name='BtnSecondary'
                extraClass={`px-3 with-border ${extraClass}`}
                onClick={this.onLoadNextCTA}
                text={text}
              />
            </span>
          );
        default:
          return null;
      }
    }
  }

  renderContent() {
    return (
      <>
        {false && this.showPreviousLoading() && this.renderLoadingDots()}
        {this.renderCollection()}
        {this.loadNextCTARequired() && this.renderLoadNextCTA()}
        {false && this.showNextLoading() && this.renderLoadingDots()}
      </>
    );
  }

  isReverse() {
    return !!this.props.isReverse;
  }

  renderInfiniteScroll() {
    let className = '';
    className += ['COMMENTS_ACTIVITY_LISTING', 'SPACE_MESSAGES'].includes(
      this.props.queryType,
    )
      ? 'list-messages'
      : '';

    // Avoid loader for reverse scroll
    let loader = !this.isReverse() ? this.renderLoadingDots() : <></>;
    return (
      <div className={className}>
        <InfiniteScroll
          loadMore={this.loadMoreAfter}
          hasMore={this.afterHasMore()}
          initialLoad={false}
          threshold={this.afterThreshold()}
          key={this.props.type}
          useWindow={!this.props.windowNotRequired}
          isReverse={this.isReverse()}
          ref={(el) => (this.containerRef = el)}
          loader={loader}>
          {this.renderContent()}
        </InfiniteScroll>
      </div>
    );
  }

  renderOnDemandLoading() {
    return (
      <div className='on-demand-content-container'>{this.renderContent()}</div>
    );
  }

  render() {
    return this.props.infiniteScroll
      ? this.renderInfiniteScroll()
      : this.renderOnDemandLoading();
  }
}

const requestConfig = {
  options: (props) => ({
    // Note: Avoid 'cache-and-network'
    fetchPolicy: props.fetchPolicy || 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      afterId: props.afterId || null,
      aroundId: props.aroundId || null,
      beforeId: props.beforeId || null,
      limit: props.initialLimit || props.limit,
      recordId: props.recordId,
      recordType: props.recordType,
      sortQuery: props.sortQuery,
      textQuery: props.textQuery,
      type: props.type,
      filterId: props.filterId,
      filterType: props.filterType,
      workspaceId: props.workspaceId,
      withRecord: props.withRecord,
    },
  }),
  props({
    data: { loading, fetchMore, records_pager, refetch, subscribeToMore },
    ownProps: { isReverse },
  }) {
    const isReverseApplied = !!isReverse;
    return {
      loading,
      refetch,
      records_pager,
      subscribeToMore,
      hasMore:
        !loading &&
        records_pager &&
        records_pager.results.length < records_pager.total,
      loadMoreEntries: (limit, beforeIdRequest = false) => {
        // Note: Additional check to avoid sending redundant requests
        if (
          records_pager &&
          records_pager.results.length >= records_pager.total
        )
          return;

        let afterId = (last(records_pager.results) || {}).id,
          beforeId = (first(records_pager.results) || {}).id;

        if (isReverseApplied) {
          afterId = (first(records_pager.results) || {}).id;
          beforeId = (last(records_pager.results) || {}).id;
        }

        try {
          fetchMore({
            variables: {
              afterId: !beforeIdRequest ? afterId : null,
              aroundId: null,
              beforeId: beforeIdRequest ? beforeId : null,
              limit,
              withRecord: false,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              let {
                after_has_more: prevAfterHasMore,
                before_has_more: prevBeforeHasMore,
                results: prevResults,
              } = previousResult.records_pager;

              let prevSecondaryResults =
                previousResult.records_pager?.secondary_results || [];

              let { after_has_more, before_has_more, results } =
                fetchMoreResult.records_pager;

              let secondary_results =
                fetchMoreResult.records_pager?.secondary_results || [];

              let afterHasMore =
                  !isNull(after_has_more) && !isUndefined(after_has_more)
                    ? after_has_more
                    : prevAfterHasMore,
                beforeHasMore =
                  !isNull(before_has_more) && !isUndefined(before_has_more)
                    ? before_has_more
                    : prevBeforeHasMore,
                combinedResults = uniqBy(
                  beforeIdRequest
                    ? [...results, ...prevResults]
                    : [...prevResults, ...results],
                  (obj) => `${obj.__typename}:${obj.id}`,
                ),
                combinedSecondaryResults = uniqBy(
                  beforeIdRequest
                    ? [...secondary_results, ...prevSecondaryResults]
                    : [...prevSecondaryResults, ...secondary_results],
                  (obj) => `${obj.__typename}:${obj.id}`,
                );

              return {
                records_pager: {
                  ...fetchMoreResult.records_pager,
                  __typename: 'Collection',
                  after_has_more: afterHasMore,
                  before_has_more: beforeHasMore,
                  results: combinedResults,
                  secondary_results: combinedSecondaryResults,
                },
              };
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
    };
  },
};

RecordsPager = compose(
  ...keys(QUERY_MAPPER_WITH_PAGE).map((type) =>
    graphql(QUERY_MAPPER_WITH_PAGE[type], {
      skip: (props) =>
        difference(VALID_QUERIES_TYPES, [type]).some(
          (type) => type === props.queryType,
        ),
      ...requestConfig,
    }),
  ),
)(RecordsPager);
RecordsPager = connect(({ device }) => ({ device }), {
  updateCurrentUserGraph,
})(RecordsPager);
RecordsPager.defaultProps = {
  afterId: null,
  afterThreshold: 500,
  aroundId: null,
  beforeId: null,
  beforeThreshold: 25,
  infiniteScroll: true,
  infiniteUpwardsScroll: false,
  initialRecords: null,
  limit: 10,
  loadMoreButtonRequired: true,
  onLoadMoreCTAClick: () => {},
  queryType: 'DEFAULT',
  recordId: null,
  recordType: null,
  sortQuery: null,
  textQuery: null,
  type: null,
  updateAfterHasMore: () => {},
  updateBeforeHasMore: () => {},
  withRecord: true,
  cardProps: {},
  dynamicTemplates: [],
};
RecordsPager = withRouter(RecordsPager);
RecordsPager = withInitialRecords(RecordsPager);

export const RecordsPagerContext = createContext({ canView: true });

const ConnectedRecordsPager = (props) => {
  const payload = useMemo(
    () => ({
      canView: !CANNOT_VIEW_COMMENTS_QUERIES.includes(props.type),
    }),
    [props.type],
  );

  return (
    <RecordsPagerContext.Provider value={payload}>
      <RecordsPager {...props} />
    </RecordsPagerContext.Provider>
  );
};
export default ConnectedRecordsPager;
