import { MAIN_NAV_SEARCH_ENTITIES, PLURALIZE_HASH } from 'app/constants';

import { lowerCase, upperFirst } from 'app/utils/osLodash';

const HTML_TAG_REGEX = /(<([^>]+)>)/gi;
const HTML_NBSP_REGEX = /&nbsp;/gi;

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalizeEveryWord(s) {
  if (typeof s !== 'string') return '';
  return s
    .split(' ')
    .map((string) => capitalize(string))
    .join(' ');
}

export function categoryFromUrl(url, categories) {
  let categoryList = categories || MAIN_NAV_SEARCH_ENTITIES;
  let category = url.split('/').filter((val) => val)[0];
  if (category === 'users') category = 'doctors';
  if (category === 'companies') category = 'tools';

  return categoryList.includes(category) ? category : categoryList[0];
}

export function humanize(text) {
  return text ? upperFirst(text.replace(/_|-/g, ' ')) : '';
}

export function isPresent(string) {
  return !!string && string.toString().trim().length > 0;
}

export function pluralizeString(string) {
  return PLURALIZE_HASH[lowerCase(string)];
}

export function randomHash() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function toSentence(array) {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    // ToDo: This should return string instead of an array.
    return array.slice(-1);
  } else {
    return (
      array.slice(0, array.length - 1).join(', ') + ' and ' + array.slice(-1)
    );
  }
}

export function lastNcharacters(string = '', charsCount) {
  string = string || '';
  return string.substr(string.length - charsCount);
}

export function base64EncodedString(string) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function parameterize(str) {
  return (str || '')
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
}

export function parseNonHtmlContent(string) {
  return (string || '')
    .replace(HTML_TAG_REGEX, '')
    .replace(HTML_NBSP_REGEX, ' ')
    .trim();
}

export function isHTMLContentPresent(string) {
  return isPresent(parseNonHtmlContent(string));
}
