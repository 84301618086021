export const LIST = 'list';
export const BOARD = 'board';
export const TASK = 'task';
export const UNIFIED = 'unified';
export const STATUS = 'status';
export const CURRENT_VIEW = 'currentView';
export const UNIFIED_LIST = 'unifiedList';
export const UNIFIED_BOARD = 'unifiedBoard';
export const DEFAULT_OPTIONS = [
  {
    value: 'all_tasks',
    label: 'All Tasks',
    name: 'all_tasks',
  },
  {
    value: 'inbox',
    label: 'Task Inbox',
    name: 'inbox',
  },
  {
    value: 'delegated',
    label: 'Assigned by you',
    name: 'delegated',
  },
];

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'weekend',
];

export const state = [
  {
    id: 1,
    type: 'CompletedTasks',
    sectionTitle: 'Done',
    page: 0,
    perPage: 10,
    queryType: 'TaskDirectory',
    idType: 'User',
  },
  {
    id: 2,
    type: 'UnscheduledTasks',
    sectionTitle: 'Unscheduled',
    page: 0,
    perPage: 10,
    queryType: 'TaskDirectory',
    idType: 'User',
  },
  {
    id: 3,
    type: 'OverDueTasks',
    sectionTitle: 'Overdue',
    page: 0,
    perPage: 10,
    queryType: 'TaskDirectory',
    idType: 'User',
  },
];

export const TASK_MODAL_REPEAT_OPTIONS = [
  { value: 'non_recursive', label: 'Do not repeat' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekdays_only', label: 'Weekdays Only' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

export const ALL_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const predefinedDates = [
  { value: '', label: 'Tomorrow' },
  { value: '', label: 'In 2 days' },
  { value: '', label: '1 week from now' },
  { value: '', label: 'MM/DD/YYYY' },
];

export const TASKS_TYPE = [
  'OverDueTasks',
  'CompletedTasks',
  'UnscheduledTasks',
  'InProgressTasks',
  'ViewedTasks',
  'NotViewedTasks',
  'CompletedUserCreatedTasksAssignedToOther',
  'NotCompletedUserCreatedTasksAssignedToOther',
  'GroupLabelTasks',
];

export const HIDE_DATE_BAR = [
  'Inbox',
  'By Status',
  'Overdue',
  'Upcoming',
  'Assigned By Me',
];

export const HIDE_VIEW_BAR = ['Inbox', 'Overdue', 'Upcoming', 'Assigned By Me'];

export const HIDE_TEAM_WIDGET_FOR_MY_TASKS_URLS = [
  'me/inbox',
  'me/today/list',
  'me/status/list',
  'me/overdue',
  'me/upcoming',
];

export const FORMAT_START_WITH_DATE_SLASH = 'DD/MM/YYYY';
export const FORMAT_START_WITH_MONTH_SLASH = 'MM/DD/YYYY';
export const FORMAT_START_WITH_DATE_DASH = 'DD-MM-YYYY';
export const FORMAT_START_WITH_MONTH_DASH = 'MM-DD-YYYY';
export const FORMAT_START_WITH_DATE = 'DDMMYYYY';
export const FORMAT_START_WITH_MONTH = 'MMDDYYYY';
export const FORMAT_START_WITH_YEAR = 'YYYY-MM-DD';

export const FORMAT_START_WITH_MONTH_SLASH_PLUS_TIME = 'MM/DD/YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm A';
export const DAYJS_STRING_TO_DATE_FORMAT = 'YYYY-MM-DD';
export const TASK_CREATED_SUCCESSFULLY = 'Task Added';
export const TASK_UPDATED_SUCCESSFULLY = 'Task Updated';
export const TASK_DELETED_SUCCESSFULLY = 'Task Deleted';
