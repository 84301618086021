import {
  CLOSE_APPOINTMENT_INFO_MODAL,
  OPEN_APPOINTMENT_INFO_MODAL,
} from './types';

export function openAppointmentInfoModal() {
  return {
    type: OPEN_APPOINTMENT_INFO_MODAL,
  };
}

export function closeAppointmentInfoModal() {
  return {
    type: CLOSE_APPOINTMENT_INFO_MODAL,
  };
}
