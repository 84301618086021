import { SET_CURRENT_SPIN_INDEX } from 'app/actions/types';

export default function spin(
  state = { currentIndex: null, previousVideoType: '' },
  action,
) {
  switch (action.type) {
    case SET_CURRENT_SPIN_INDEX:
      return {
        currentIndex: action.currentIndex,
        previousVideoType: action.previousVideoType,
      };
    default:
      return state;
  }
}
