import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export const PATIENT_OPTIONS_MAPPING = {
  all: {
    label: 'All Patients',
    queryType: 'AllPatientsOfClinic',
    countAttribute: '',
  },
  no_response: {
    label: 'No Response',
    countAttribute: '',
    queryType: 'NoResponseByPatientInCareSpaces',
  },
  not_accepted: {
    label: 'Not Accepted',
    countAttribute: '',
    queryType: 'NotAcceptedSpaces',
  },
  // waiting: {
  //   label: "Waiting for Response",
  //   countAttribute: "",
  //   queryType: "WaitingForResponseInClinic"
  // },
  scheduled: {
    label: 'Scheduled Messages',
    countAttribute: '',
    queryType: 'AllPatientsWithScheduledMessage',
  },
};

const PATIENT_DIRECTORY_BASE_URL = 'patient/directory/';

const PatientDirectoryList = (props) => {
  const { isActive, workspace, filter } = props;

  const navigate = useNavigate();
  const redirectToPage = (event, filter) => {
    event.stopPropagation();
    navigate(PATIENT_DIRECTORY_BASE_URL + filter);
  };

  const getActivePatientFilter = (option) => {
    return isActive && option === filter;
  };

  const getPatientFiltersCount = (option) => {
    return workspace?.[PATIENT_OPTIONS_MAPPING[option].countAttribute];
  };

  return (
    <>
      <Accordion alwasOpen={true} activeKey={['1']}>
        {/* Hide this feature for now*/}
        {/*<Accordion.Item eventKey={'0'} className={'patient-directory-option'}>*/}
        {/*<Accordion.Header>*/}
        {/*  <p>Patient Intake</p>*/}
        {/*  <span className='lt-count pull-right'>Coming Soon</span>*/}
        {/*</Accordion.Header>*/}
        {/*</Accordion.Item>*/}
        <Accordion.Item eventKey={'1'} className={'patient-directory-option'}>
          {/*<Accordion.Header*/}
          {/*  onClick={(event) =>*/}
          {/*    setPatientFilter(event, PATIENT_OPTIONS_LIST[0])*/}
          {/*  }>*/}
          {/*  <p>Active Patients</p>*/}
          {/*</Accordion.Header>*/}
          <Accordion.Body>
            <div className={'patients-list-menu'}>
              <div className={'patients-list-menu__options'}>
                {Object.keys(PATIENT_OPTIONS_MAPPING).map((filter) => (
                  <div
                    className={'patients-list-menu__option'}
                    key={filter}
                    style={{
                      backgroundColor: getActivePatientFilter(filter)
                        ? '#eff1f4'
                        : null,
                    }}
                    onClick={(event) => redirectToPage(event, filter)}>
                    <p
                      style={{
                        margin: '0',
                        fontSize: '12px',
                        color: '#5d7893',
                        fontWeight: 'normal',
                      }}>
                      {PATIENT_OPTIONS_MAPPING[filter].label}
                    </p>
                    <span className='lt-count pull-right'>
                      {getPatientFiltersCount(filter)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PatientDirectoryList;
