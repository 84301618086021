import parse from 'html-react-parser';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ClickPopupWrapper from 'app/components/Shared/ClickPopupWrapper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RenderTaskActionButton from 'app/components/Task/RenderTaskActionButton';
import useKanbanCard from 'app/components/Kanban/useKanbanCard';
import { timeWithFormat } from 'app/utils/timeHelper';
import { FORMAT_START_WITH_MONTH_SLASH } from 'app/components/Task/taskConstant';
import { getRGBValue } from 'app/utils/taskHelper';
import OsCards from 'app/components/OsCards/index';
import SelectInput from 'app/components/Shared/SelectInput';
import { StyleForKanban } from 'app/components/Shared/selectInputStyles';
import checkedCircle from 'app/images/task-manager/checkedCircle.svg';
import Tippy from '@tippyjs/react';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { openTaskPreviewModal } from 'app/actions/taskPreviewModal';
import RenderLabel from 'app/components/Task/RenderLabel';

const KanbanTaskCard = ({
  allTaskOpen,
  card,
  listType = 'AllTasks',
  setAllTaskOpen,
}) => {
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const dispatch = useDispatch();
  const {
    collapsed,
    allUserOptions,
    updateCache,
    refreshComponent,
    cardRef,
    handleCollapsed,
    markAsDone,
    markAsNotDone,
    getCardType,
    changeAssignee,
    changeDueDate,
    deleteTask,
    updateOverdueTasksOnEdit,
    openCard,
    collapseCard,
  } = useKanbanCard(card);

  useEffect(() => {
    allTaskOpen ? openCard() : collapseCard();
  }, [allTaskOpen]);

  const openPreviewModal = (taskId) => {
    let id = taskId.includes('_overdue')
      ? taskId.replace('_overdue', '')
      : taskId;
    dispatch(openTaskPreviewModal(id, {}));
  };

  if (collapsed) {
    return (
      <>
        <div className={`kanban-main-card`} ref={cardRef}>
          <div
            className={`kanban-card`}
            onClick={() => openPreviewModal(card?.id)}>
            <div className={`card-container`}>
              <div className={'left-container'}>
                {card.completed ? (
                  <div className={`card-completion`} onClick={markAsNotDone}>
                    <img
                      src={checkedCircle}
                      alt='checkedCircle'
                      width='18'
                      height='20'
                    />
                  </div>
                ) : (
                  <ClickPopupWrapper onClick={markAsDone}>
                    <div className={'click-popup-wrapper'}></div>
                  </ClickPopupWrapper>
                )}
                <div className={`card-title-info`}>
                  <span
                    className={`card-title`}
                    style={{
                      textDecoration: card.completed ? 'line-through' : 'none',
                    }}>
                    <span className={'task-card-type'}>{getCardType()}</span>{' '}
                    {card?.title}
                  </span>
                  <p className={'card-info created-info'}>
                    by {card?.created_by?.full_name} •{' '}
                    {timeWithFormat(
                      card?.created_at,
                      `${FORMAT_START_WITH_MONTH_SLASH} hh:mm A`,
                    )}
                  </p>
                  {card.completed && (
                    <p className={'card-info completed-info'}>
                      Completed by {card?.resolved_marked_by?.name} •{' '}
                      {timeWithFormat(
                        card?.completed_at,
                        `${FORMAT_START_WITH_MONTH_SLASH} hh:mm A`,
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className={`right-container`}>
                <div className={'card-action-button'}>
                  <RenderTaskActionButton
                    task={card}
                    deleteTask={deleteTask}
                    markAsDone={markAsDone}
                    markAsNotDone={markAsNotDone}
                    listType={listType}
                    changeDueDate={changeDueDate}
                    assigneeList={allUserOptions}
                    changeAssignee={changeAssignee}
                    backendDataRequired={false}
                    refreshComponent={refreshComponent}
                    updateCache={updateCache}
                    updateOverdueTasksOnEdit={updateOverdueTasksOnEdit}
                  />
                </div>
                <div className={`card-expand`} onClick={handleCollapsed}>
                  <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
                </div>
              </div>
            </div>
            <div>
              {card?.description?.length > 0 && (
                <Tippy
                  placement={'top-start'}
                  allowHTML={true}
                  content={parse(card?.description)}
                  theme={'light-border'}>
                  <p
                    className={`card-description`}
                    style={{
                      textDecoration: card.completed ? 'line-through' : 'none',
                    }}>
                    {parse(card?.description)}
                  </p>
                </Tippy>
              )}
            </div>
            {card?.entity && (
              <div
                className={'card-entity-post'}
                onClick={(e) => e.stopPropagation()}>
                <OsCards
                  size={'extra-small'}
                  obj={card?.entity}
                  shareRequired={false}
                  dateBlockRequired={true}
                  sliderPreview={true}
                  source='Comment'
                />
              </div>
            )}
            {card?.parent_entity && card?.parent_entity?.id && (
              <div
                style={{ marginTop: '8px' }}
                className='card-entity-post'
                onClick={(e) => e.stopPropagation()}>
                <OsCards
                  size={'extra-small'}
                  obj={card.parent_entity}
                  sliderPreview={true}
                  source={card.parent_entity.__typename}
                  shareRequired={false}
                  dateBlockRequired={true}
                />
              </div>
            )}
            <div className='taskLabel' onClick={(e) => e.stopPropagation()}>
              <div
                className='task-comment-count'
                onClick={() => openPreviewModal(card?.id)}>
                <OrthoIcon name='comments' />
                {card.comments_count}
              </div>
              <div className='teamMembersDropdown'>
                <SelectInput
                  isMulti={false}
                  name='assignee'
                  className='react-select-ortho-2'
                  formValue={card.assignee.id}
                  onChange={changeAssignee}
                  isDisabled={true}
                  selectedValueNameRequired={false}
                  style={StyleForKanban}
                  options={all_members}
                  optionProcessingRequired={true}
                />
              </div>
              {card.entity_labels.map((label, index) => {
                return (
                  <div
                    key={label.id}
                    className='taskLabelContainer'
                    style={{
                      backgroundColor: `${getRGBValue(label.label.color)}`,
                    }}>
                    <div className='singleTaskLabel'>
                      <RenderLabel
                        task={card}
                        label={label.label}
                        allLabels={task_labels}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={`kanban-main-card`}>
          <div
            className={`kanban-card`}
            onClick={() => openPreviewModal(card?.id)}>
            <div className={`card-container`}>
              <div className={'left-container'}>
                {card.completed ? (
                  <div className={`card-completion`} onClick={markAsNotDone}>
                    <img
                      src={checkedCircle}
                      alt='checkedCircle'
                      width='18'
                      height='20'
                    />
                  </div>
                ) : (
                  <ClickPopupWrapper onClick={markAsDone}>
                    <div className={'click-popup-wrapper'}></div>
                  </ClickPopupWrapper>
                )}
                <div className={`card-title-info`}>
                  <span
                    className={`card-title`}
                    style={{
                      textDecoration: card.completed ? 'line-through' : 'none',
                    }}>
                    <span className={'task-card-type'}>{getCardType()}</span>{' '}
                    {card?.title}
                  </span>
                  <div
                    className='taskLabel'
                    onClick={(e) => e.stopPropagation()}>
                    <div
                      className='task-comment-count'
                      onClick={() => openPreviewModal(card?.id)}>
                      <OrthoIcon name='comments' />
                      {card.comments_count}
                    </div>
                    {card.entity_labels.map((label, index) => {
                      return (
                        <div
                          key={label.id}
                          className='taskLabelContainer'
                          style={{
                            backgroundColor: `${getRGBValue(
                              label.label.color,
                            )}`,
                          }}>
                          <div className='singleTaskLabel'>
                            <RenderLabel
                              task={card}
                              label={label.label}
                              allLabels={task_labels}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={`right-container`}>
                <div className={`card-expand`} onClick={handleCollapsed}>
                  <OrthoIcon name='chevron-caret-right' defaultClass='ms-1' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default KanbanTaskCard;
