import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { closePartnerSpaceInviteModal } from 'app/actions/partnerSpaces';
import { PARTNERS_SPACE_LISTING } from 'app/components/Partners/usePartnersListing';
import { useNavigate } from 'react-router';
import { getWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
const ADD_PARTNER_CLINIC_SPACE = queryLoader(
  'app/graphql/mutations/PartnerSpaces/AddPartnerSpaces.gql',
);

const usePartnerClinicModal = () => {
  const partnerSpaces = useSelector((state) => state.partnerSpaces);
  const dispatch = useDispatch();
  const [addPartnerSpace] = useMutation(ADD_PARTNER_CLINIC_SPACE);
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(closePartnerSpaceInviteModal());
  };

  const onSubmit = async (values) => {
    let additionalEmails = values.additional_emails?.split(',') || [];
    let invitees = [];
    invitees = additionalEmails.map((email) => ({
      email: email.trim(),
      countryCode: values.countryCode,
      phoneNumber: values.phoneNumber,
      owner: false,
    }));
    invitees.push({
      email: values.email.trim(),
      countryCode: values.countryCode,
      phoneNumber: values.phoneNumber,
      owner: true,
    });

    try {
      await addPartnerSpace({
        variables: {
          name: values.clinic_name,
          content: values.message,
          invitees: invitees,
        },
        refetchQueries: [PARTNERS_SPACE_LISTING],
        onCompleted: (data) => {
          closeModal();
          navigate(
            `/${getWorkspaceIdentifier()}/partners/${
              data.addNewPartnerWithCollaborator.entity.nice_id
            }`,
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    partnerSpaces,
    onSubmit,
    closeModal,
  };
};

export default usePartnerClinicModal;
