import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { loader as queryLoader } from 'graphql.macro';

import { Link } from 'react-router-dom';

import ConnectToSupport from 'app/components/ConnectToSupport';
import OsField from 'app/components/OsField';
import ProfileImage from 'app/components/Shared/ProfileImage';

import { translate } from 'app/actions/flashMessage';
import {
  fieldRequired,
  passwordMustMatch,
  passwordMustMatchValidityRules,
  nameFieldValidation,
  composeValidators,
} from 'app/utils/validationHelper.js';
import OsBtn from 'app/components/OsBtn';

const UPDATE_USER_MUTATION = queryLoader('app/graphql/UpdateUser.gql');
const focusOnErrors = createDecorator();
// Modal to edit the user profile
class AccountView extends Component {
  state = {
    editPassword: false,
  };

  updateUser = (attributes) => {
    return this.props
      .updateUserMutation({
        variables: attributes,
      })
      .then(
        ({ data }) => {
          if (
            data.updateUser &&
            data.updateUser.errors &&
            data.updateUser.errors.length > 0
          ) {
            let errors = {};
            data.updateUser.errors.forEach(
              (error) => (errors[error.field] = error.message),
            );
            return data.updateUser.errors;
          } else {
            // Handle Success here.
            this.props.closeParentModal();
          }
          // this.props.closeParentModal();
        },
        ({ data }) => {
          // error
          return { old_password: 'Incorrect password' };
        },
      );
  };

  onSubmit = (values) => {
    return this.updateUser(values);
  };

  renderPasswordFields() {
    if (this.state.editPassword) {
      return (
        <div>
          <Link
            to='#'
            className='pull-right modal-profile-link a-link fs-12'
            onClick={() => {
              this.setState({ editPassword: false });
            }}>
            Cancel
          </Link>
          <Field
            name='old_password'
            type='password'
            component={OsField}
            osType='password'
            label='Current Password'
            validate={fieldRequired}
          />
          <Field
            name='password'
            type='password'
            component={OsField}
            osType='password'
            label='New Password'
            hintRequired={true}
            validate={composeValidators(
              fieldRequired,
              passwordMustMatchValidityRules,
            )}>
            <span className='password-hint'>
              {translate('PASSWORD_VALIDITY_HINT_TEXT')}
            </span>
          </Field>
          <Field
            name='confirm_password'
            type='password'
            component={OsField}
            osType='password'
            label='Confirm New Password'
            validate={passwordMustMatch}
          />
        </div>
      );
    } else {
      return (
        <div className='contact-action'>
          <OsBtn
            name='BtnSecondary'
            extraClass='with-border'
            onClick={() => {
              this.setState({ editPassword: true });
            }}
            text='Change Password'
            associatedEntity={this.props.user}
          />
        </div>
      );
    }
  }

  renderClinicDegreeInformation() {
    return (
      <div className='modal-form-block'>
        <div className='row mb-3'>
          <div className='col-sm-12'>
            <label for='clinic'>Clinic</label>
            <div className='clinic-address multiline-truncate '>
              {this.props.initialValues.clinic}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <label for='degrees'>DEGREES</label>
            <div className='degree-list text-truncate'>
              {this.props.initialValues.degree_list}
            </div>
            <div className='contact-action'>
              <ConnectToSupport
                viewType='button'
                buttonType='BtnSecondary'
                extraClass='with-border'
                text='Contact qlark to make changes'
                associatedEntity={this.props.user}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubmitButton() {
    let textClass = this.props.submitSucceeded
      ? 'btn-text-saved'
      : 'btn-text-save';
    return (
      <OsBtn
        name='BtnPrimary'
        extraClass={`${textClass}`}
        htmlTag='button'
        text={this.props.submitting ? 'Saving' : 'Save Changes'}
        disabled={this.props.submitting}
        associatedEntity={this.props.user}
        type='submit'
      />
    );
  }

  renderButtonText() {
    if (this.props.submitting) {
      return (
        <span>
          <i className='fa fa-spinner fa-spin' />
          &nbsp;Saving
        </span>
      );
    } else {
      return 'Save Changes';
    }
  }

  onCrop = (dimensions, originalDimensions) => {
    this.change('avatar_x', dimensions.x / originalDimensions.naturalWidth);
    this.change('avatar_y', dimensions.y / originalDimensions.naturalHeight);
    this.change('avatar_w', dimensions.width / originalDimensions.naturalWidth);
    this.change(
      'avatar_h',
      dimensions.height / originalDimensions.naturalHeight,
    );
  };

  render() {
    let { user } = this.props;
    return (
      <div className='detail-user-form'>
        <Form
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
          decorators={[focusOnErrors]}
          render={({ handleSubmit, form }) => {
            this.change = form.change;
            return (
              <form onSubmit={handleSubmit}>
                <div className='row my-edit-profile'>
                  <div className='col-md-6'>
                    <div className='my-edit-prf-title'>
                      Edit profile information
                    </div>
                    <div className='row'>
                      <div className='col-md-6 name-field'>
                        <Field
                          name='first_name'
                          type='text'
                          component={OsField}
                          osType='input'
                          label='First Name'
                          validate={composeValidators(
                            fieldRequired,
                            nameFieldValidation,
                          )}
                        />
                      </div>
                      <div className='col-md-6'>
                        <Field
                          name='last_name'
                          type='text'
                          component={OsField}
                          osType='input'
                          label='Last Name'
                          validate={composeValidators(
                            fieldRequired,
                            nameFieldValidation,
                          )}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-8 email-field'>
                        <label className='form-label' htmlFor='email'>
                          Email
                        </label>
                        <div className='email-input-text'>
                          {this.props.currentUser.graph.email}
                        </div>
                      </div>
                      <div className='col-md-4'>
                        {this.renderPasswordFields()}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-8 email-field'>
                        <Field
                          name='middle_initial'
                          type='number'
                          component={OsField}
                          osType='input'
                          label='Mobile phone number*'
                          validate={nameFieldValidation}
                        />
                      </div>
                      <div className='col-md-4'>
                        {this.renderPasswordFields()}
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6 detail-user-form-bio'>
                    <ProfileImage
                      defaultImageUrl={
                        this.props.currentUser.graph.large_avatar
                      }
                      onCrop={this.onCrop}
                      obj={this.props.currentUser.graph}
                    />
                    <Field
                      name='bio'
                      osType='textarea'
                      component={OsField}
                      label='Your Bio*'
                      className={'black-base-scroller'}
                    />
                  </div>
                </div>

                <div className='my-edit-prf-footer'>
                  <OsBtn
                    name='BtnSecondary'
                    htmlTag='button'
                    text='Cancel'
                    onClick={() => this.props.closeParentModal()}
                    associatedEntity={user}
                  />
                  {this.renderSubmitButton()}
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

AccountView = graphql(UPDATE_USER_MUTATION, { name: 'updateUserMutation' })(
  AccountView,
);
AccountView = connect(({ currentUser }) => ({ currentUser }), {})(AccountView);

export default AccountView;
