export const BASE_SUGGESTION_OPTIONS = {
  ADD_A_TEMPLATE: 'Add a Template',
  ADD_A_PATIENT: 'Add a Patient',
  RECORD_A_VIDEO: 'Record a Video',
  UPLOAD_A_FILE: 'Upload a File',
  SCHEDULE_AND_POST_LATER: 'Schedule and Post later',
  ADD_EMOJI: 'Add Emoji',
};

export const EDITOR_EXTENSION_SUGGESTION_OPTIONS = {
  ADD_A_TEMPLATE: 'Add a Template',
  RECORD_A_VIDEO: 'Record a Video',
  UPLOAD_A_FILE: 'Upload a File',
  SCHEDULE_AND_POST_LATER: 'Schedule and Post later',
  ADD_EMOJI: 'Add Emoji',
};

export const PARTNER_SPACES_EDITOR_EXTENSION_SUGGESTION_OPTIONS = {
  ADD_A_TEMPLATE: 'Add a Template',
  RECORD_A_VIDEO: 'Record a Video',
  UPLOAD_A_FILE: 'Upload a File',
  ADD_EMOJI: 'Add Emoji',
};

export const FEED_EXTENSION_SUGGESTION_OPTIONS = {
  ADD_A_TEMPLATE: 'Add a Template',
  RECORD_A_VIDEO: 'Record a Video',
  UPLOAD_A_FILE: 'Upload a File',
  ADD_EMOJI: 'Add Emoji',
};

export const NODE_SUGGESTION_OPTIONS_LIST = [
  BASE_SUGGESTION_OPTIONS.ADD_A_TEMPLATE,
];

export const OPTION_ICON_MAPPER = {
  [BASE_SUGGESTION_OPTIONS.ADD_A_TEMPLATE]: 'notes',
  [BASE_SUGGESTION_OPTIONS.RECORD_A_VIDEO]: 'video-filled',
  [BASE_SUGGESTION_OPTIONS.UPLOAD_A_FILE]: 'attach',
  [BASE_SUGGESTION_OPTIONS.SCHEDULE_AND_POST_LATER]: 'calendar',
  [BASE_SUGGESTION_OPTIONS.ADD_EMOJI]: 'add-emoji',
};
