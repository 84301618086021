import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  tree_heieracy,
  total_tools_count,
  category,
  categoryType,
  rightLabel,
  onClick,
  trackOnClick,
}) => {
  var link = `/search/${category}/tool_types/id/${id}`;
  let onClickParams = {
    query_by: 'tool_types',
    id_query: id,
    category: category,
    categoryType: categoryType,
  };

  return (
    <SearchResult
      key={`Tag:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>
          {rightLabel === 0 ? '-' : rightLabel}
        </span>
        {tree_heieracy}
      </div>
    </SearchResult>
  );
};
