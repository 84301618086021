import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { omit } from 'app/utils/osLodash';

const withGraphqlRequests = (query, graphqlAttributes, options = {}) => {
  return (WrappedComponent) => {
    class HOC extends React.Component {
      componentDidUpdate(prevProps) {
        if (
          this.props.data &&
          prevProps.data &&
          !prevProps.data.loading &&
          this.props.data.loading
        ) {
          this.setPageTitle();
        } else if (
          this.props.data &&
          prevProps.data &&
          prevProps.data.loading &&
          !this.props.data.loading
        ) {
          this.setPageTitle();
        }
      }

      setPageTitle() {
        // This is undefined in some case. Need to find those places and then remove this check.
        this.props.setPageTitle &&
          this.props.setPageTitle(this.props.data[options.objectIdentifier]);
      }

      renderLoader() {
        return <Loader type='ball-triangle-path' active />;
      }

      render() {
        if (
          options.loaderType &&
          this.props.data &&
          this.props.data.loading &&
          !this.props.data[options.objectIdentifier]
        ) {
          return this.renderLoader();
        } else {
          let data = { ...this.props.data, ...(this.props.customData || {}) };
          return <WrappedComponent {...omit(this.props, 'data')} data={data} />;
        }
      }
    }
    HOC = graphql(query, { ...graphqlAttributes })(HOC);

    return HOC;
  };
};

export default withGraphqlRequests;
