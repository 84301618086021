import React, { Component } from 'react';

import CollectionView from 'app/components/CollectionView';
import RecordsView from 'app/components/RecordsView';

class Collection extends Component {
  renderRecords(type, queryParams = {}) {
    return (
      <RecordsView
        type={type}
        queryType='USER_DETAIL'
        perPage={12}
        windowNotRequired={true}
        cardProps={{
          selectionMode: true,
          onCardClick: queryParams.onCardClick,
          size: queryParams.size,
          multiSelectMode: true,
        }}
        threshold={100}
        {...queryParams}
        cardGridIdentifier={'AttachmentModal:Files'}
      />
    );
  }

  render() {
    let { type, queryParams } = this.props;
    if (type) {
      switch (type) {
        case 'link':
          return this.renderRecords('AllAdditionalLinks', queryParams);
        case 'file':
          return this.renderRecords('AllShareableFiles', queryParams);
        case 'case':
          return this.renderRecords('ClinicCases', queryParams);
        case 'board':
          return this.renderRecords('CommentableSpaces', queryParams);
        default:
          return (
            <CollectionView
              {...queryParams}
              selectionMode={true}
              query_by2={'gender'}
              per_page={24}
              windowNotRequired={true}
              threshold={100}
              orderRowWise={true}
              setSearchTotalNotRequired={true}
              multiSelectMode={true}
              goToPage={false}
              noEntitiesFoundVisible={true}
              cardGridIdentifier={'AttachmentModal:Files'}
            />
          );
      }
    } else {
      return null;
    }
  }
}

export default Collection;
