import React, { Component } from 'react';

import withAuthorize from 'app/components/HOC/withAuthorize';

class AuthorizeComponent extends Component {
  onClick = (onClick, e) => {
    this.props.openUpgradeModalIfUnauthorized(onClick.bind(this, e));
  };

  render() {
    return React.cloneElement(this.props.children, {
      onClick: this.onClick.bind(this, this.props.onClick),
    });
  }
}

AuthorizeComponent = withAuthorize(AuthorizeComponent);
export default AuthorizeComponent;
