export const DIAGNOSIS_CHOICES = ['MILD', 'MODERATE', 'SEVERE'];
export const RANGE_DEFAULT_CHOICES = [12, 36];

function getTreatmentText(kase) {
  if (kase.appointments_count === 0) {
    return 'Awaiting Treatment';
  } else {
    return 'Active treatment';
  }
}

export const RIGHT_SECTION_BLOCKS = {
  activeTreatment: {
    textFunction: (kase) => getTreatmentText(kase),
    icon: 'service-fill',
  },
  tools: {
    text: 'appliances, software, equipment',
    icon: 'tools',
    extraClass: '',
  },
};

const SLIDER_OPTIONS_RESULTS_ONE = {
  MILD: 20,
  MODERATE: 30,
  SEVERE: 50,
};

const SLIDER_OPTIONS_RESULTS_TWO = {
  MILD: 20,
  MODERATE: 50,
  SEVERE: 30,
};

const SLIDER_OPTIONS_RESULTS_THREE = {
  MILD: 40,
  MODERATE: 40,
  SEVERE: 20,
};

export const RESULTS_WITH_DIAGNOSIS_MAPPER = {
  'Angle Class': {
    categoryOptionsResults: {
      'Class I': { value: 3, sliderResults: null },
      'Class II': { value: 97, sliderResults: null },
      'Class III': { value: 0, sliderResults: null },
    },
  },
  'MAXILLARY ARCH LENGTH': {
    categoryOptionsResults: {
      Crowding: {
        value: 3,
        sliderResults: {
          MILD: 100,
          MODERATE: 0,
          SEVERE: 0,
        },
      },
      Spacing: {
        value: 97,
        sliderResults: {
          MILD: 88,
          MODERATE: 12,
          SEVERE: 0,
        },
      },
    },
  },
  'Mandibular Arch Length': {
    categoryOptionsResults: {
      Crowding: {
        value: 97,
        sliderResults: {
          MILD: 90,
          MODERATE: 10,
          SEVERE: 0,
        },
      },
      Spacing: {
        value: 3,
        sliderResults: {
          MILD: 100,
          MODERATE: 0,
          SEVERE: 0,
        },
      },
    },
  },
  'Maxillary Alignment Irregularity': {
    categoryOptionsResults: {},
    sliderOptionsResults: {
      MILD: 45,
      MODERATE: 50,
      SEVERE: 5,
    },
  },
  'Mandibular Alignment Irregularity': {
    categoryOptionsResults: {},
    sliderOptionsResults: {
      MILD: 75,
      MODERATE: 23,
      SEVERE: 2,
    },
  },
  Overbite: {
    categoryOptionsResults: {
      Deep: {
        value: 100,
        sliderResults: {
          MILD: 2,
          MODERATE: 15,
          SEVERE: 83,
        },
      },
      Open: {
        value: 0,
        sliderResults: {
          MILD: 0,
          MODERATE: 0,
          SEVERE: 0,
        },
      },
    },
  },
  Overjet: {
    categoryOptionsResults: {
      Increased: {
        value: 100,
        sliderResults: {
          MILD: 3,
          MODERATE: 45,
          SEVERE: 52,
        },
      },
      Reversed: {
        value: 0,
        sliderResults: {
          MILD: 0,
          MODERATE: 0,
          SEVERE: 0,
        },
      },
    },
  },
  'Treatment Approach': {
    categoryOptionsResults: {
      'No Treatment': { value: 1, sliderResults: SLIDER_OPTIONS_RESULTS_ONE },
      'Limited Treatment': {
        value: 5,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
      'Comprehensive Treatment': {
        value: 87,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
      'Interdisciplinary Treatment': {
        value: 2,
        sliderResults: SLIDER_OPTIONS_RESULTS_TWO,
      },
      'Orthognathic Surgical Treatment': {
        value: 5,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
    },
  },
  'EXTRACTION APPROACH': {
    categoryOptionsResults: {
      'Non-Extraction': {
        value: 82,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
      'Permanent Teeth': {
        value: 12,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
      'Primary Teeth': { value: 3, sliderResults: SLIDER_OPTIONS_RESULTS_ONE },
      'Extraction U4S/L4S': {
        value: 2,
        sliderResults: SLIDER_OPTIONS_RESULTS_TWO,
      },
      'Extraction U4S/L5S': {
        value: 1,
        sliderResults: SLIDER_OPTIONS_RESULTS_THREE,
      },
      'Extraction U5S/L4S': {
        value: 0,
        sliderResults: SLIDER_OPTIONS_RESULTS_THREE,
      },
    },
  },
  'Primary Appliance': {
    categoryOptionsResults: {
      'Conventional Twin Labial Brackets': {
        value: 38,
        sliderResults: SLIDER_OPTIONS_RESULTS_THREE,
      },
      'Self-Ligating Labial Brackets': {
        value: 45,
        sliderResults: SLIDER_OPTIONS_RESULTS_TWO,
      },
      'Lingual Brackets': {
        value: 2,
        sliderResults: SLIDER_OPTIONS_RESULTS_TWO,
      },
      'Clear Aligners': { value: 8, sliderResults: SLIDER_OPTIONS_RESULTS_TWO },
      'Clear Aligner/Labial Bracket Combination': {
        value: 7,
        sliderResults: SLIDER_OPTIONS_RESULTS_ONE,
      },
    },
  },
  'Sagittal Mechanics': {
    categoryOptionsResults: {
      Elastics: { value: 38, sliderResults: SLIDER_OPTIONS_RESULTS_ONE },
      'Functional Appliance': {
        value: 27,
        sliderResults: SLIDER_OPTIONS_RESULTS_TWO,
      },
      'AuxilLary Appliance': {
        value: 35,
        sliderResults: SLIDER_OPTIONS_RESULTS_THREE,
      },
    },
  },
  Complexity: {
    categoryOptionsResults: {},
    sliderOptionsResults: {
      MILD: 3,
      MODERATE: 45,
      SEVERE: 52,
    },
  },
  'Treatment Time': {
    categoryOptionsResults: {},
    rangeResult: { value: [12, 36], mean: 25, standardDeviation: 5 },
  },
};

export const DIAGNOSIS_TREATMENT_OPTIONS_MAPPER = {
  'Angle Class': {
    categoryOptions: ['Class I', 'Class II', 'Class III'],
    sliderOptions: [],
    checkbox: false,
  },
  'MAXILLARY ARCH LENGTH': {
    categoryOptions: ['Crowding', 'Spacing'],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  'Mandibular Arch Length': {
    categoryOptions: ['Crowding', 'Spacing'],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  'Maxillary Alignment Irregularity': {
    categoryOptions: [],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  'Mandibular Alignment Irregularity': {
    categoryOptions: [],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  Overbite: {
    categoryOptions: ['Deep', 'Open'],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  Overjet: {
    categoryOptions: ['Increased', 'Reversed'],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  'Treatment Approach': {
    categoryOptions: [
      'No Treatment',
      'Limited Treatment',
      'Comprehensive Treatment',
      'Interdisciplinary Treatment',
      'Orthognathic Surgical Treatment',
    ],
    sliderOptions: [],
    checkbox: true,
  },
  'EXTRACTION APPROACH': {
    categoryOptions: [
      'Non-Extraction',
      'Permanent Teeth',
      'Primary Teeth',
      'Extraction U4S/L4S',
      'Extraction U4S/L5S',
      'Extraction U5S/L4S',
    ],
    sliderOptions: [],
    checkbox: true,
  },
  'Primary Appliance': {
    categoryOptions: [
      'Conventional Twin Labial Brackets',
      'Self-Ligating Labial Brackets',
      'Lingual Brackets',
      'Clear Aligners',
      'Clear Aligner/Labial Bracket Combination',
    ],
    sliderOptions: [],
    checkbox: true,
  },
  'Sagittal Mechanics': {
    categoryOptions: [
      'Elastics',
      'Functional Appliance',
      'AuxilLary Appliance',
    ],
    sliderOptions: [],
    checkbox: true,
  },
  Complexity: {
    categoryOptions: [],
    sliderOptions: DIAGNOSIS_CHOICES,
    checkbox: false,
  },
  'Treatment Time': {
    categoryOptions: [],
    sliderOptions: [],
    checkbox: false,
    range: [12, 36],
  },
};

export const APPOINTMENT_TYPE_MAPPER = {
  records_taken: { name: 'Records taken', icon: 'image' },
  tools_modification: { name: 'Tools added or removed', icon: 'tools' },
  procedure: { name: 'Procedure (referral or surgeon)', icon: 'procedure' },
  notes: { name: 'Notes only (brief check-in)', icon: 'notes' },
  emergency: { name: 'Emergency (unscheduled) ', icon: 'error' },
  other: { name: 'Unspecified', icon: 'case-heart' },
};

export const APPOINTMENT_TYPE_OTHER_ICON = 'case-heart';
