import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import OsLink from 'app/components/OsLink';
import UserInfo from 'app/components/SignupScreen/UserInfo';
import ResetPasswordMailSent from 'app/components/LoginView/ResetPasswordMailSent';

import LocalStorageManager from 'app/services/LocalStorageManager';

import { setSpaceToken } from 'app/utils/spaceHelper';
import { flashError } from 'app/actions/flashMessage';
import { signup } from 'app/actions/userMutations';
import { authModal } from 'app/actions/authentication';

const CHECK_USER_EXISTS = queryLoader('app/graphql/CheckUserExists.gql');
const SPACE_INVITE_QUERY = queryLoader('app/graphql/SpaceInvite.gql');

class SignupForm extends Component {
  state = this.defaultState();

  componentDidMount() {
    if (this.props.match?.params?.token)
      setSpaceToken(this.props.match.params.token);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data &&
      prevProps.data.loading &&
      this.props.data &&
      !this.props.data.loading &&
      this.props.data.space_invite
    ) {
      let spaceInviteData = this.props.data.space_invite;
      if (spaceInviteData.error) {
        LocalStorageManager.clear('space_invite_token');
        this.props.navigate('/login', { skip: true });
        this.props.flashError(spaceInviteData.error, false);
      } else {
        this.setState(
          {
            email: spaceInviteData.email,
            spaceInvitetoken: LocalStorageManager.get('space_invite_token'),
            spaceUrl: spaceInviteData.entity?.frontend_url,
            firstName: spaceInviteData.secondary_entity?.first_name,
            lastName: spaceInviteData.secondary_entity?.last_name,
          },
          () => {
            LocalStorageManager.clear('space_invite_token');
            this.change('email', this.state.email);
            this.change('firstName', this.state.firstName);
            this.change('lastName', this.state.lastName);
          },
        );
      }
    }
  }

  togglePoliciesAccepted = ({ target }) => {
    this.setState(
      { isPolicyChecked: target.checked },
      this.setPolicyCheckError,
    );
  };

  toggleRequestInProgress = () => {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  };

  defaultState() {
    return {
      requestInProgress: false,
      isPolicyChecked: false,
      showPolicyCheckboxError: false,
    };
  }

  setPolicyCheckError = () => {
    if (this.state.isPolicyChecked) {
      this.setState({ showPolicyCheckboxError: false });
    } else {
      this.setState({ showPolicyCheckboxError: true });
    }
  };

  onSubmit = (values) => {
    if (this.state.isPolicyChecked) {
      this.submitSignupForm(values);
    } else {
      this.setPolicyCheckError();
    }
  };

  submitSignupForm(values) {
    let email = values.email;
    this.setState({ requestInProgress: true });
    return this.props.client
      .query({
        query: CHECK_USER_EXISTS,
        fetchPolicy: 'network-only',
        variables: {
          attribute: 'email',
          value: email,
        },
      })
      .then(({ data: { user_exists } }) => {
        if (user_exists.success) {
          var returnErrors = {};
          if (user_exists.error === 'preverified') {
            returnErrors['email'] = {
              errorKey: 'PREVERIFIED_USER_ONBOARDING',
              interpolations: {
                reset_mail: (
                  <ResetPasswordMailSent isPreVerified={true} email={email} />
                ),
              },
            };
          } else {
            returnErrors['email'] = {
              errorKey: 'NOT_PREVERIFIED_USER_ONBOARDING',
              interpolations: {
                reset_mail: <ResetPasswordMailSent email={email} />,
              },
            };
          }
          return returnErrors;
        } else {
          this.proceedToSignup(values);
        }
      });
  }

  proceedToSignup(values) {
    const variables = {
      accept_space_request_token: this.state.spaceInvitetoken,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
    };
    this.props.signup(
      variables,
      this.afterSuccessActions,
      this.props.navigate,
      this.state.spaceUrl,
    );
  }

  afterSuccessActions = () => {
    this.setState(this.defaultState());
  };

  isOnSignupView() {
    return this.props.location.pathname === '/signup';
  }

  openLoginView = () => {
    if (this.props.device.mobileDevice && this.isOnSignupView()) {
      this.props.navigate('/login');
    } else {
      this.props.authModal({ authModal: 'login' });
    }
  };

  render() {
    return (
      <div className='m-auto'>
        <Form
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          render={(props) => {
            this.change = props.form.change;
            return (
              <form name='signup' onSubmit={props.handleSubmit}>
                <UserInfo
                  requestInProgress={this.state.requestInProgress}
                  togglePoliciesAccepted={this.togglePoliciesAccepted}
                  showPolicyCheckboxError={this.state.showPolicyCheckboxError}
                  emailDisabled={!!this.state.email}
                  extraClass={this.props.extraClass}
                  formProps={props}
                />
              </form>
            );
          }}
        />
        <div
          className={`text-center ${
            this.props.device.mobileDevice
              ? 'mobile-signup-footer'
              : 'hide-modal-only mt-4 pt-3'
          }`}>
          <p className='my-2 os-caf '>Already have an account?</p>
          <OsLink
            text='Log In'
            className='semibold'
            onClick={this.openLoginView}
          />
        </div>
      </div>
    );
  }
}

SignupForm = graphql(SPACE_INVITE_QUERY, {
  skip: (props) =>
    !(
      LocalStorageManager.get('space_invite_token') ||
      props.match?.params?.token
    ),
  options: (props) => ({
    variables: {
      token:
        LocalStorageManager.get('space_invite_token') ||
        props.match?.params?.token,
    },
    fetchPolicy: 'cache-and-network',
  }),
})(SignupForm);
SignupForm = connect(({ device }) => ({ device }), {
  authModal,
  flashError,
  signup,
})(SignupForm);
SignupForm = withApollo(SignupForm);
SignupForm = withRouter(SignupForm);

export default SignupForm;
