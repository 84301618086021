import { useEffect } from 'react';
import { addClassToHTML } from 'app/utils/domHelper';
import { useParams } from 'react-router';

import withEasyRXAuthenticate from 'app/hoc/easyRX/withEasyRXAuthenticate';
import StatusList from 'app/components/EasyRX/PrescriptionsSection/StatusList';

import { PRESCRIPTION_STATUS_INFO } from 'app/constant/easyRX';

const PrescriptionsSection = () => {
  const params = useParams();

  useEffect(() => {
    addClassToHTML('detail-page');
  }, []);

  const renderPrescriptionStatusSection = () => {
    return (
      <div>
        <span className='section-title'>
          EASY RX • {PRESCRIPTION_STATUS_INFO[params.filter]?.title}
        </span>
        <StatusList
          status={PRESCRIPTION_STATUS_INFO[params.filter]?.status}
          key={params.filter}
        />
      </div>
    );
  };

  return (
    <div className='main-container'>
      <div className='mt-4 vs-list'>
        <div className='table-wrap prescription-list'>
          {renderPrescriptionStatusSection()}
        </div>
      </div>
    </div>
  );
};

export default withEasyRXAuthenticate(PrescriptionsSection);
