import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import SelectInput from 'app/components/Shared/SelectInput';

import { find } from 'app/utils/osLodash';

const COUNTRIES_QUERY = queryLoader('app/graphql/Countries.gql');

class CountrySelect extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      let country = this.getCurrentCountryValue();
      this.props.onCountryChange && this.props.onCountryChange(country);
    }
  }

  getCurrentCountryValue(countryId) {
    let country = find(
      this.props.data.countries || [],
      (country) => (countryId || this.props.input.value) === country.id,
    );
    return country
      ? {
          label: country.name,
          value: country.id,
          statesRequired: country.states_required,
        }
      : {};
  }

  onChange = ({ value }) => {
    this.props.onCountryChange &&
      this.props.onCountryChange(this.getCurrentCountryValue(value));
  };

  render() {
    return (
      <div className='form-group'>
        {this.props.label && (
          <label
            className={this.props.labelClassName}
            htmlFor={this.props.input.name}>
            {this.props.label}
          </label>
        )}
        <SelectInput
          {...this.props}
          onChange={this.onChange}
          isSearchable
          value={this.getCurrentCountryValue()}
          options={(this.props.data.countries || []).map((country) => ({
            label: country.name,
            value: country.id,
          }))}
        />
        {this.props.meta.touched && this.props.meta.error && (
          <span className='form-error modal-form-error'>
            {this.props.meta.error}
          </span>
        )}
      </div>
    );
  }
}

CountrySelect.defaultProps = {
  labelClassName: '',
};
CountrySelect = graphql(COUNTRIES_QUERY, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      countriesWithPhoneCodeRequired: false,
    },
  }),
})(CountrySelect);

export default CountrySelect;
