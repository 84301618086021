import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import InboxSection from 'app/components/Inbox/InboxSection';
import RecordsView from 'app/components/RecordsView';
import StoreUpdater from 'app/services/StoreUpdater';

const TeamPartnersInboxComponent = (props) => {
  const { workspace } = props;
  const [
    processedPartnerSpaceCommentsCount,
    setProcessedPartnerSpaceCommentsCount,
  ] = useState();
  const [
    unprocessedPartnerSpaceCommentsCount,
    setUnprocessedPartnerSpaceCommentsCount,
  ] = useState();

  const markReadAndSetUnprocessedCareSpaceCommentsCount = (count) => {
    setUnprocessedPartnerSpaceCommentsCount(count);
    StoreUpdater.updateEntityThroughWrapper(
      {
        entity: { ...workspace.data, new_partner_activities_present: false },
        attributeName: 'new_partner_activities_present',
      },
      { customAction: 'assign' },
    );
  };
  const renderDefaultView = () => {
    return (
      <>
        {unprocessedPartnerSpaceCommentsCount !== 0 &&
          renderFeed({
            title: 'Pending • Partners Comments',
            recordType: 'UnprocessedPartnerSpaceComments',
            updateCount: markReadAndSetUnprocessedCareSpaceCommentsCount,
            markReadButton: workspace.data.is_care_member,
          })}
        {processedPartnerSpaceCommentsCount !== 0 &&
          renderFeed({
            title: 'Processed • Partners Comments',
            recordType: 'ProcessedPartnerSpaceComments',
            markReadButton: false,
            updateCount: setProcessedPartnerSpaceCommentsCount,
          })}
      </>
    );
  };

  const renderFeed = ({
    title,
    recordType,
    hideSection = false,
    markReadButton = true,
    updateCount,
  }) => {
    return (
      <InboxSection
        title={title}
        type={'team_partner_inbox'}
        hideSection={hideSection}
        markReadButton={markReadButton}
        markReadButtonText='Mark All as Done'
        showPendingFilter={!hideSection}>
        {(showBody) => {
          return (
            <RecordsView
              type={recordType}
              page={0}
              perPage={10}
              updateCount={updateCount}
              queryType={'COMMENTS_ACTIVITY_LISTING'}
              key={workspace?.data?.unprocessed_partner_actionable_items_count}
            />
          );
        }}
      </InboxSection>
    );
  };

  return (
    <>
      <div className='inbox-wrapper'>
        <div className='inbox'>{renderDefaultView()}</div>
      </div>
    </>
  );
};

const TeamPartnersInboxConnect = connect(({ workspace }) => ({ workspace }))(
  TeamPartnersInboxComponent,
);
const TeamPartnersInbox = withRouter(TeamPartnersInboxConnect);

export default TeamPartnersInbox;
