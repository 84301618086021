import OsBtn from 'app/components/OsBtn';
import React from 'react';

function Delete({ setShowModal }) {
  return (
    <>
      <OsBtn
        key='edit'
        name='BtnIcon'
        text='Delete task'
        extraClass='list-button'
        icon='delete'
        onClick={() => setShowModal(true)}
      />
    </>
  );
}

export default Delete;
