import store from 'app/store';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import EventTracker from 'app/services/EventTracker';
import StoreUpdater from 'app/services/StoreUpdater';

import { iCompanyTypeSpace } from 'app/utils/spaceHelper';
import { entityIsBoardLink, entityIsBoard } from 'app/utils/entitiesHelper';

import { VIEW_OBJECT } from './types';

const VIEW_OBJECT_MUTATION = queryLoader('app/graphql/ViewObject.gql');

export function viewObject(attributes) {
  return (dispatch) => {
    dispatch({ type: VIEW_OBJECT });
    let variables = {
        viewable_id: attributes.obj?.id,
        viewable_type: attributes.obj?.__typename,
      },
      isOfTypeBoardLink = entityIsBoardLink(attributes.obj);
    if (store.getState().currentUser.graph && attributes.obj?.id > 0) {
      return client
        .mutate({
          mutation: VIEW_OBJECT_MUTATION,
          variables: variables,
          optimisticResponse: () => {
            return {
              viewObject: {
                __typename: 'View',
                id: '-1',
                viewable_id: attributes.obj.id,
                viewable_type: attributes.obj.__typename,
                view_count: attributes.obj.view_count + 1,
                viewable: {
                  __typename: attributes.obj.__typename,
                  ...(entityIsBoard(attributes.obj) && {
                    id: attributes.obj.id,
                    followed_by_current_user:
                      attributes.obj.followed_by_current_user ||
                      iCompanyTypeSpace(attributes.obj),
                  }),
                },
              },
            };
          },
          update: (proxy, { data: { viewObject } }) => {
            if (isOfTypeBoardLink) {
              attributes.obj.viewed_space_content = {
                id: '-1',
                meta_info: '{}',
                __typename: 'ViewedSpaceContent',
              };
              StoreUpdater.updateEntityThroughWrapper(
                {
                  entity: attributes.obj,
                  attributeName: 'viewed_space_content',
                },
                { customAction: 'assign' },
              );
            } else {
              let customAction =
                viewObject.id === '-1' ? 'increment' : 'assign';
              StoreUpdater.updateEntityThroughWrapper(
                { entity: viewObject.viewable, attributeName: 'view_count' },
                { customAction },
              );
            }
          },
        })
        .then((data) => {
          EventTracker.trackForEntity('show', attributes.obj);
        });
    }
  };
}
