import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import ConnectToSupport from 'app/components/ConnectToSupport';
import OsLink from 'app/components/OsLink';
import PoliciesView from 'app/components/PoliciesView';
import EventTracker from 'app/services/EventTracker';
import { POLICIES_URL_HASH } from 'app/constants';

class FooterLinks extends Component {
  constructor(props) {
    super(props);
    let { hash } = props.location;
    this.state = {
      showPolicyModal: POLICIES_URL_HASH.includes(hash),
    };
  }

  closePolicyModal = () => {
    this.setState({ showPolicyModal: false });
  };

  openPolicyModal = () => {
    this.setState({ showPolicyModal: true });
  };

  onContactUsClick = () => {
    EventTracker.track('On Login - Contact Us');
  };

  onPrivacyPolicyClick = () => {
    EventTracker.track('On Login - Privacy Policy');
    this.openPolicyModal();
  };

  onTermsOfUseClick = () => {
    EventTracker.track('On Login - Terms of Use');
    this.openPolicyModal();
  };

  render() {
    return (
      <div className='contact-links'>
        <ConnectToSupport
          viewType='link'
          className='contact-us-mail a-link cursor-pointer'
          text='Contact us'
        />
        &nbsp;<span className=''>•</span>&nbsp;
        <OsLink
          href='#privacy_policy'
          className='privacy-policy a-link'
          onClick={this.onPrivacyPolicyClick}
          text='Privacy Policy'
        />
        &nbsp;<span className=''>•</span>&nbsp;
        <OsLink
          href='#terms_of_use'
          className='privacy-policy a-link'
          onClick={this.onTermsOfUseClick}
          text='Terms of Use'
        />
        {this.state.showPolicyModal && (
          <PoliciesView hideFooter={true} onClose={this.closePolicyModal} />
        )}
      </div>
    );
  }
}

FooterLinks = withRouter(FooterLinks);

export default FooterLinks;
