import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import EventTracker from 'app/services/EventTracker';
import AutoSuggestSearchInput from 'app/components/Shared/AutoSuggestSearchInput';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { MAIN_NAV_SEARCH_ENTITIES } from 'app/constants.js';

import { setNavSearchClose } from 'app/actions/navSearch';
import { categoryFromUrl } from 'app/utils/stringHelper';
import { startCase } from 'app/utils/osLodash';
import { genericSearchUrl } from 'app/utils/entitiesHelper';

const ICONS_MAPPER = {
  cases: 'cases',
  tools: 'tools',
  spaces: 'spaces',
  doctors: 'doctors',
};

// The search on the index pages
class NavSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      category: props.default,
    };
  }

  onSubmit = () => {
    EventTracker.track('Search - Enter Clicked');
    if (this.state.query) {
      this.props.setNavSearchClose();
      this.props.navigate(genericSearchUrl(this.state.query));
    }
  };

  handleQuery = (value) => {
    this.setState({ query: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname)
      this.setCategory(categoryFromUrl(this.props.location.pathname));
  }

  setCategory(category) {
    this.setState({ category });
  }

  renderOptions() {
    return this.props.list.map(this.renderSingleOption);
  }

  getDisplayName(category) {
    let currentUser = this.props.currentUser.graph;
    return currentUser && currentUser.is_general_user && category === 'doctors'
      ? 'Members'
      : startCase(category);
  }

  renderSingleOption = (category, index) => {
    return (
      <div
        key={index}
        className={
          'category-button ' +
          (this.state.category === category ? 'object-filter-active' : '')
        }
        onClick={() => this.setCategory(category)}>
        <OrthoIcon name={ICONS_MAPPER[category]} dataHoverNotRequired={true} />
        {this.getDisplayName(category)}
      </div>
    );
  };

  renderWithOptions = () => {
    if (this.props.list.length > 1) {
      return (
        <>
          <div className='category-buttons'>{this.renderOptions()}</div>
          <div className='nav-search-with-text'>with...</div>
        </>
      );
    }
  };

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit }) => (
          <form className='nav-search' onSubmit={handleSubmit}>
            {this.renderWithOptions()}
            <AutoSuggestSearchInput
              source='Nav Search'
              type={this.state.category || this.props.default}
              singleEntitySearch={this.props.list.length === 1}
              handleQuery={this.handleQuery}
              onHandleChange={this.handleQuery}
            />
          </form>
        )}
      />
    );
  }
}

NavSearch = connect(({ currentUser }) => ({ currentUser }), {
  setNavSearchClose,
})(NavSearch);

NavSearch.defaultProps = {
  list: MAIN_NAV_SEARCH_ENTITIES,
  default: MAIN_NAV_SEARCH_ENTITIES[0],
};
NavSearch = withRouter(NavSearch);
export default NavSearch;
