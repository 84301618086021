import crossCircle from 'app/images/task-manager/crossCircle.svg';
import { hexToRgb } from 'app/utils/colorHelper';
import { getLabelNameWithParent } from 'app/utils/labelHelper';
import { filter } from 'app/utils/osLodash';

const DisplayLabels = (props) => {
  const { labels, allTaskLabels, fieldName, removeLabel } = props;
  if (labels && labels.length > 0) {
    return (
      <div className={'task-modal-labels-container'}>
        {labels.map((labelItem) => (
          <div className='label' key={labelItem.value}>
            <div
              className='label__color'
              style={{
                backgroundColor: `${hexToRgb(labelItem.entity.color)}`,
              }}>
              <span className={'label__name'}>
                {getLabelNameWithParent(labelItem.entity, allTaskLabels)}
              </span>
              <img
                src={crossCircle}
                alt={'crossCircle'}
                height={'12px'}
                width={'12px'}
                onClick={() => {
                  removeLabel(
                    fieldName,
                    filter(labels, (label) => label.value !== labelItem.value),
                  );
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default DisplayLabels;
