export const schedulePostOptimisticResponse = (entity, variables, author) => {
  return {
    addScheduleMessage: {
      entity: {
        __typename: 'ScheduleMessage',
        id: '-1',
        content: variables.content,
        time: variables.time,
        nice_id: entity.nice_id,
        visible_to_group_ids: variables.visibleToGroupIds,
        created_by: author,
        files: variables.files,
        links: variables.links,
        mentionees: [],
        entity: entity,
      },
    },
  };
};
