import Mention from '@tiptap/extension-mention';

const CustomMention = Mention.extend({
  renderHTML({ node, HTMLAttributes }) {
    if (node.attrs?.typename === 'User') {
      return [
        'span',
        { ...HTMLAttributes, 'data-type': 'mention' },
        '@' + node.attrs?.label,
      ];
    } else {
      return [
        'span',
        { ...HTMLAttributes, 'data-type': 'mention' },
        '@' + node.attrs?.label,
      ];
    }
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-id': attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },

      typename: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-typename'),
        renderHTML: (attributes) => {
          if (!attributes.typename) {
            return {};
          }
          return {
            'data-typename': attributes.typename,
          };
        },
      },
    };
  },
});

export default CustomMention;
