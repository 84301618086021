import React from 'react';

import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { userDefaultRootRedirectPath } from 'app/utils/Workspace/generalHelper';
import { useEffect } from 'react';

import CareViewRoutes from 'app/routes/CareView/Routes';
import ConfirmEmail from 'app/components/ConfirmEmail';
import EasyRXSelectionScreen from 'app/components/EasyRX/SelectionScreen';
import EmailSourceUrl from 'app/components/EmailSourceUrl';
import EmptyLayout from 'app/components/Layouts/EmptyLayout';
import Logout from 'app/components/Logout/Logout';
import PoliciesPageView from 'app/components/PoliciesPageView';
import ProfileIncomplete from 'app/components/ProfileIncomplete';
import SelectClinicView from 'app/components/Partners/Invitation/SelectClinicView';
import StatusLayout from 'app/components/Layouts/StatusLayout';
import SystemUpgrade from 'app/components/SystemUpgrade';
import UpdatePasswordScreen from 'app/components/UpdatePasswordScreen';
import UpdateNumberScreen from 'app/components/UpdateNumberScreen';
import WorkspaceLaunchScreen from 'app/components/WorkspaceView/LaunchScreen';
import WorkSpaceViewRoutes from 'app/routes/WorkspaceView/Routes';
const WORKSPACE_TABS_PATHNAMES = [
  '',
  'contents',
  'members',
  'sub_space',
  'subspaces',
];
const TEAMSPACE_PATHNAMES = ['dm', 'group'];

const AuthenticatedRoutes = (props) => {
  const location = useLocation(),
    { pathname } = location,
    identifier = props.workspace?.identifier;

  useEffect(() => {
    function getPageClass(pathname) {
      let pathnames = pathname.split('/'),
        splittedPathname = pathnames[2];
      if (
        splittedPathname === 'team' &&
        (TEAMSPACE_PATHNAMES.includes(pathnames[3]) || pathnames.length === 3)
      ) {
        let pageClass = 'team-space ';
        if (pathname.includes('dm')) {
          pageClass += 'team-direct-messenger ';
        } else if (pathname.includes('group')) {
          pageClass += 'group-messenger ';
        }
        return pageClass;
      } else if (
        identifier &&
        WORKSPACE_TABS_PATHNAMES.includes(splittedPathname)
      ) {
        return 'spaces';
      } else {
        return splittedPathname;
      }
    }

    window.$('body').removeClass();
    let className = getPageClass(pathname);
    className = className || 'explore';
    window.$('body').addClass(className);
  }, [pathname, identifier]);

  return (
    <Routes>
      <Route
        path='/'
        exact
        element={<Navigate to={userDefaultRootRedirectPath()} />}
      />
      <Route element={<StatusLayout />}>
        <Route
          path='/update_number'
          exact
          element={<UpdateNumberScreen unauthenticated={true} />}
        />
        <Route
          path='/update_password'
          exact
          element={<UpdatePasswordScreen unauthenticated={true} />}
        />
        <Route path='/select-clinic/:token' element={<SelectClinicView />} />
        <Route path='/policies/:type' element={<PoliciesPageView />} />
        <Route
          path='/profile_incomplete'
          exact
          element={<ProfileIncomplete />}
        />
        <Route path='/callback/easy-rx' element={<EasyRXSelectionScreen />} />
        <Route path='/launch' element={<WorkspaceLaunchScreen />} />
        <Route path='/source_email/:url' element={<EmailSourceUrl />} />
        <Route path='/confirm_email/:token' element={<ConfirmEmail />} />
        <Route path='/logout' element={<Logout />} />
      </Route>
      <Route element={<EmptyLayout />}>
        <Route path='/system_upgrade' exact element={<SystemUpgrade />} />
      </Route>
      <Route path='/care/*' element={<CareViewRoutes />} />
      <Route path='*' element={<WorkSpaceViewRoutes />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
