import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export const WORKSPACE_NAV_LIST = [
  ['Security', 'security'],
  ['Notification Preferences', 'notification_preferences'],
];

export const NAV_LIST_SOURCE = 'account_settings';

export function getCurrentFilter(pathname) {
  return pathname.replace(withWorkspaceIdentifier('/account_settings/'), '');
}
