import React, { useEffect, useRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import lock from 'app/images/profile-switcher/lock.webp';
import { openInfoModal } from 'app/actions/infoModal';
import { lockNavbar, unlockNavbar } from 'app/actions/lockNavbar';
import { login } from 'app/actions/authentication';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import SelectInput from 'app/components/Shared/SelectInput';
import { setKeyValueInLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const BACKSPACE = 'Backspace';
const PROFILE_SWITCHER_LOGIN = queryLoader(
  'app/graphql/QuickSwitcher/ProfileSwitcherLogin.gql',
);

const SwitchProfile = ({ setProfileStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const workspace = useSelector((state) => state.workspace);
  const formRef = useRef(null);

  const [user, setUser] = useState(currentUser.graph);
  const [profileSwitcher, { data }] = useMutation(PROFILE_SWITCHER_LOGIN);

  const onSubmit = ({ passwordA, passwordB, passwordC, passwordD }, form) => {
    formRef.current = form;
    const password = passwordA + passwordB + passwordC + passwordD;
    profileSwitcher({
      variables: {
        userId: user.id,
        password: password,
      },
    });
  };

  const focusElement = (elementId) => {
    document.getElementById(elementId)?.focus();
  };

  const changeUser = (user) => {
    setUser(user);
  };

  const redirectToResetForm = () => {
    navigate(`/${workspace.identifier}/profile/reset_password`);
  };

  const formValidation = (values) => {
    const errors = {};
    if (
      !values.passwordA ||
      !values.passwordB ||
      !values.passwordC ||
      !values.passwordD
    ) {
      errors.passwordA = 'Pin is required';
    }
    return errors;
  };

  const keyUp = (input, nextElementId, form) => {
    if (form.getState().valid) onSubmit(form.getState().values, form);
    if (nextElementId) input.value && focusElement(nextElementId);
  };

  const keyDown = (e, input, previousElementId) => {
    if (previousElementId)
      input.value === '' &&
        e.key === BACKSPACE &&
        focusElement(previousElementId);
  };

  const getPinFields = (id, nextElementId, previousElementId, form) => {
    return (
      <Field name={id}>
        {({ input, meta, placeholder }) => (
          <>
            <input
              {...input}
              autoFocus={id === 'passwordA' ? true : false}
              autoComplete='new-password'
              id={id}
              placeholder={placeholder}
              type={'password'}
              maxLength={1}
              style={{ textAlign: 'center' }}
              onKeyUp={() => keyUp(input, nextElementId, form)}
              onKeyDown={(e) => keyDown(e, input, previousElementId)}
            />
            {meta.error && meta.touched && meta.submitFailed && (
              <div className='form-error email-error'>
                <OrthoIcon name='error' defaultClass='pe-1 ms-3 ' />
                {meta.error}
              </div>
            )}
          </>
        )}
      </Field>
    );
  };

  useEffect(() => {
    if (data !== undefined) {
      if (data.authenticateWorkspacePin.success === true) {
        dispatch(unlockNavbar());
        let variables = {
          token: data.authenticateWorkspacePin.value,
          email: data.authenticateWorkspacePin.user.email,
          user: data.authenticateWorkspacePin.user,
        };
        dispatch(login(variables));
        setKeyValueInLocalStorage('lockNav', false);
        setKeyValueInLocalStorage('storage', true);
        setKeyValueInLocalStorage(
          'currentUserId',
          data.authenticateWorkspacePin.user.id,
        );
        navigate(-1);
      } else {
        formRef.current.reset({});
        focusElement('passwordA');
        dispatch(
          openInfoModal(
            'general',
            data.authenticateWorkspacePin.message
              .split(' ')
              .join('_')
              .toLowerCase(),
          ),
        );
      }
    }
  }, [data]);

  // whenever params value change this useeffect will run and lock the screen if route container 'profile/switcher'
  useEffect(() => {
    if (
      params['*'].split('/')[1] === 'profile' &&
      params['*'].split('/')[2] === 'switcher'
    ) {
      dispatch(lockNavbar());
      setKeyValueInLocalStorage('storage', true);
      setKeyValueInLocalStorage('lockNav', true);
      setKeyValueInLocalStorage('identifier', workspace.identifier);
    }
  }, []);

  return (
    <div className='profileSwitcherForm'>
      <SetPageTitle
        source={'profile_switcher'}
        interpolations={'Lock Screen'}
      />
      <section>
        <div className='imageContainer'>
          {!workspace?.data?.owner?.owner?.logo_big ? (
            <img src={lock} alt='profile-lock' />
          ) : (
            <img src={workspace.data.owner.owner.logo_big} alt='profile-lock' />
          )}
        </div>
        <div className={'profile-switcher-card-view'}>
          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              let errors = formValidation(values);
              return errors;
            }}
            render={(props) => {
              return (
                <form
                  className={'profile-switcher-form'}
                  onSubmit={props.handleSubmit}>
                  <p className='profile-switcher-form-heading'>
                    <strong>Select a Profile</strong>
                  </p>
                  <div className='profile-switcher-form-user-field'>
                    <label for={'users'}>User*</label>
                    <div className={'select-user-profile'}>
                      <div className='users' name='user'>
                        <SelectInput
                          isMulti={false}
                          name='user'
                          className='react-select-ortho-2 os-menu-drop'
                          placeholder='Select'
                          formValue={user?.id}
                          onChange={(value) => changeUser(value.entity)}
                          async={true}
                          perPage={50}
                          queryType='ProfileSwitcherEnabledUsers'
                          selectedValueNameRequired={false}
                        />
                      </div>
                    </div>

                    <label style={{ marginTop: '24px' }}>Pin*</label>
                    <div className={'password-block'}>
                      {getPinFields('passwordA', 'passwordB', null, props.form)}
                      {getPinFields(
                        'passwordB',
                        'passwordC',
                        'passwordA',
                        props.form,
                      )}
                      {getPinFields(
                        'passwordC',
                        'passwordD',
                        'passwordB',
                        props.form,
                      )}
                      {getPinFields('passwordD', null, 'passwordC', props.form)}
                    </div>

                    <p style={{ marginTop: '30px' }}>
                      Can’t remember your pin? You can{' '}
                      <strong
                        style={{ cursor: 'pointer' }}
                        onClick={() => redirectToResetForm()}>
                        click here
                      </strong>{' '}
                      to reset it.
                    </p>
                  </div>
                  <div className='profileSwitcherPrimaryBtn'>
                    <OsBtn
                      name={'BtnPrimary'}
                      type={'submit'}
                      extraClass={'profile-switcher-btn'}
                      value='Enter Workspace'
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default SwitchProfile;
