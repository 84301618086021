import React, { Component } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';

import SuccessImage from 'app/images/success-image.png';

import { translate } from 'app/actions/flashMessage';

class PendingView extends Component {
  renderSuccessMessage() {
    let congratulationsClass = this.props.device.mobileDevice
        ? ' '
        : 'mt-4 pt-2',
      verifyClass = ' my-4 py-2',
      successfullyClass = this.props.device.mobileDevice ? 'lh-15' : '';

    if (this.props.device.mobileDevice) {
      verifyClass = this.props.device.mobileDevicePortrait
        ? 'mt-3 pt-2'
        : 'mt-3 pt-1';
    }

    let messageTranslationKey = 'ONBOARDING_PREFERED_DOCTOR_MESSAGE';
    return (
      <article className='onboard-success-message'>
        <div className='congratulations-block'>
          <div className='img-block'>
            <img
              src={SuccessImage}
              alt=''
              className='onboard-success-image m-auto'
            />
          </div>

          <div
            className={`text-block ${
              !this.props.device.mobileDevice && 'text-center'
            }`}>
            <h2 className={`head-1 lh-normal mb-3 ${congratulationsClass}`}>
              Congratulations!
            </h2>
            <div className={`head-4 ${successfullyClass}`}>
              {translate('ONBOARDING_SUCCESS_TITLE')}
            </div>
          </div>
        </div>

        <div className={verifyClass}>{translate(messageTranslationKey)}</div>
      </article>
    );
  }

  render() {
    return (
      <section className='onboard-success-container'>
        {this.renderSuccessMessage()}
      </section>
    );
  }
}

PendingView = connect(({ device, currentUser }) => ({ device, currentUser }))(
  PendingView,
);
export default PendingView;
