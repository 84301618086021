export const DAYS_IN_WEEK = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

export const TIME_ZONES = [
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-11:00</span> American Samoa
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-11:00</span> International Date
    Line West
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-11:00</span> Midway Island
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-10:00</span> Hawaii
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-09:00</span> Alaska
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-08:00</span> Pacific Time (US &
    Canada)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-08:00</span> Tijuana
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-07:00</span> Arizona
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-07:00</span> Chihuahua
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-07:00</span> Mazatlan
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-07:00</span> Mountain Time (US &
    Canada)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Central America
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Central Time (US &
    Canada)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Guadalajara
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Mexico City
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Monterrey
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-06:00</span> Saskatchewan
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-05:00</span> Bogota
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-05:00</span> Eastern Time (US &
    Canada)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-05:00</span> Indiana (East)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-05:00</span> Lima
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-05:00</span> Quito
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-04:00</span> Atlantic Time
    (Canada)
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-04:00</span> Caracas
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-04:00</span> Georgetown
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-04:00</span> La Paz
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-04:00</span> Santiago
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-03:30</span> Newfoundland
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-03:00</span> Brasilia
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-03:00</span> Buenos Aires
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-03:00</span> Greenland
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-03:00</span> Montevideo
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-02:00</span> Mid-Atlantic
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-01:00</span> Azores
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT-01:00</span> Cape Verde Is.
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> Casablanca
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> Dublin
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> Edinburgh
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> Lisbon
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> London
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> Monrovia
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+00:00</span> UTC
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Amsterdam
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Belgrade
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Berlin
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Bern
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Bratislava
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Brussels
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Budapest
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Copenhagen
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Ljubljana
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Madrid
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Paris
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Prague
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Rome
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Sarajevo
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Skopje
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Stockholm
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Vienna
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Warsaw
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> West Central Africa
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Zagreb
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+01:00</span> Zurich
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Athens
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Bucharest
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Cairo
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Harare
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Helsinki
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Jerusalem
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Kaliningrad
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Kyiv
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Pretoria
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Riga
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Sofia
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Tallinn
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+02:00</span> Vilnius
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Baghdad
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Istanbul
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Kuwait
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Minsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Moscow
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Nairobi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Riyadh
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> St. Petersburg
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:00</span> Volgograd
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+03:30</span> Tehran
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Abu Dhabi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Baku
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Muscat
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Samara
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Tbilisi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:00</span> Yerevan
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+04:30</span> Kabul
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:00</span> Ekaterinburg
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:00</span> Islamabad
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:00</span> Karachi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:00</span> Tashkent
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:30</span> Chennai
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:30</span> Kolkata
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:30</span> Mumbai
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:30</span> New Delhi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:30</span> Sri Jayawardenepura
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+05:45</span> Kathmandu
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+06:00</span> Almaty
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+06:00</span> Astana
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+06:00</span> Dhaka
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+06:00</span> Urumqi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+06:30</span> Rangoon
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+07:00</span> Bangkok
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+07:00</span> Hanoi
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+07:00</span> Jakarta
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+07:00</span> Krasnoyarsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+07:00</span> Novosibirsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Beijing
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Chongqing
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Hong Kong
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Irkutsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Kuala Lumpur
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Perth
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Singapore
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Taipei
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+08:00</span> Ulaanbaatar
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:00</span> Osaka
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:00</span> Sapporo
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:00</span> Seoul
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:00</span> Tokyo
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:00</span> Yakutsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:30</span> Adelaide
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+09:30</span> Darwin
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Brisbane
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Canberra
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Guam
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Hobart
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Melbourne
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Port Moresby
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Sydney
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+10:00</span> Vladivostok
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+11:00</span> Magadan
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+11:00</span> New Caledonia
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+11:00</span> Solomon Is.
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+11:00</span> Srednekolymsk
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:00</span> Auckland
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:00</span> Fiji
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:00</span> Kamchatka
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:00</span> Marshall Is.
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:00</span> Wellington
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+12:45</span> Chatham Is.
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+13:00</span> Nuku\'alofa
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+13:00</span> Samoa
  </div>,
  <div className={'timezone-option'}>
    <span className={'timezone-constant'}>GMT+13:00</span> Tokelau Is.
  </div>,
].map((tz) => {
  const value = tz.props.children[0].props.children + tz.props.children[1];
  return { value: value, label: tz };
});
