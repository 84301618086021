import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { groupBy, isEmpty, keys } from 'app/utils/osLodash';
import { isTouchSupported } from 'app/utils/deviceHelper';
import {
  fieldRequired,
  graduationYearValidity,
  urlMustBeValid,
  composeValidators,
} from 'app/utils/validationHelper';
import { VERIFICATION_PROCESS_NOTION_LINK } from 'app/constants';

const USER_ROLE = queryLoader('app/graphql/queries/Users/UserRoles.gql');

class UserSpecialityInfo extends Component {
  state = {
    subCategories: {},
  };

  selectedSpeciality = '';

  componentDidMount() {
    if (this.props.selectedSubCategories)
      this.setState({ subCategories: this.props.selectedSubCategories });
  }

  componentDidUpdate(prevProps) {
    if (this.state.error && !this.isSubmitDisabled())
      this.setState({ error: '' });
  }

  componentWillUnmount() {
    this.props.saveSelectedSubCategories(this.state.subCategories);
  }

  getUserRoles() {
    return this.props.data && this.props.data.user_roles;
  }

  getUserRole() {
    return this.getUserRoles().filter(
      (role) => role.name === this.props.selectedRole,
    )[0];
  }

  getSpecialitiesCategories() {
    return groupBy(this.getUserRole().specialities, 'parent_name');
  }

  getSelectedSpeciality() {
    return (
      this.selectedSpeciality &&
      this.getSpecialitiesCategories()[this.selectedSpeciality]
    );
  }

  renderSpecialities = (speciality) => {
    let labelTextClass = speciality.replace(' ', '_').toLowerCase();

    return (
      <label className={`cp_label m-0 ${labelTextClass}`}>
        <Field
          component='input'
          type='radio'
          name='speciality'
          id={speciality}
          value={speciality}
        />
        <div className='cp_label_helper speciality_label_heler'>
          <span className='m-auto'>{speciality}</span>
        </div>
      </label>
    );
  };

  getUrlLabel() {
    switch (this.props.selectedRole) {
      case 'doctor':
        return 'Clinic website URL*';
      case 'student_doctor':
        return this.selectedSpeciality === 'Orthodontic Resident'
          ? 'Residency program URL*'
          : 'University website URL*';
      case 'other':
        return `${
          this.isSelectedSpeciality('Clinic Team Member')
            ? 'Clinic'
            : 'Organization'
        } website URL*`;
      default:
        return '';
    }
  }

  renderUrl() {
    return (
      <div className='website-url'>
        <Field
          name='url'
          osType='input'
          component={OsField}
          label={this.getUrlLabel()}
          validate={composeValidators(fieldRequired, urlMustBeValid)}
          formGroupExtraClass={this.props.device.mobileDevice ? ' ' : 'mb-0'}
        />
      </div>
    );
  }

  onChange = (e) => {
    let subCategories = { ...this.state.subCategories };
    if (e.target.checked) {
      subCategories[e.target.id] = e.target.name;
    } else if (subCategories[e.target.id]) {
      delete subCategories[e.target.id];
    }
    this.setState({ subCategories: { ...subCategories } });
  };

  isSubCategoryChecked(speciality) {
    return keys(this.state.subCategories).includes(speciality.id);
  }

  renderSubCategories = (speciality) => {
    return (
      <OsField
        osType='checkbox'
        name={speciality.child_name}
        id={speciality.id}
        dataHoverRequired={!isTouchSupported()}
        label={speciality.child_name}
        onChange={this.onChange}
        isChecked={this.isSubCategoryChecked(speciality)}
      />
    );
  };

  subCategoriesRequired() {
    return (
      this.getSelectedSpeciality() &&
      this.getSelectedSpeciality()[0].sub_categories_present
    );
  }

  renderSubCategoriesIfRequired() {
    return (
      this.subCategoriesRequired() && (
        <div className='check-listing'>
          {this.getSelectedSpeciality().map(this.renderSubCategories)}
        </div>
      )
    );
  }

  isSelectedSpeciality(speciality) {
    return this.selectedSpeciality === speciality;
  }

  getInputLabel() {
    switch (this.props.selectedRole) {
      case 'doctor':
        return 'Please, specify you specialty*';
      case 'student_doctor':
        return 'Please, specify type of student doctor*';
      case 'other':
        return `Please, specify your ${
          this.isSelectedSpeciality('Clinic Team Member') ? 'role' : 'title'
        }*`;
      default:
        return '';
    }
  }

  renderGraduationYearIfRequired() {
    if (this.props.selectedRole === 'student_doctor') {
      return (
        <Field
          name='graduationYear'
          osType='input'
          type='number'
          pattern='\d*'
          component={OsField}
          label='Graduation year*'
          validate={composeValidators(fieldRequired, graduationYearValidity)}
        />
      );
    }
  }

  renderTextInput() {
    return (
      <Field
        name='textInput'
        osType='input'
        component={OsField}
        label={this.getInputLabel()}
        validate={fieldRequired}
      />
    );
  }

  textInputRequired() {
    return (
      this.getSelectedSpeciality() &&
      this.getSelectedSpeciality()[0].text_input_required
    );
  }

  renderTextInputIfRequired() {
    return this.textInputRequired() && this.renderTextInput();
  }

  isSpecialityOrthodontist() {
    return this.formValues.speciality === 'Orthodontist';
  }

  isSpecialityStudentDoctor() {
    return this.formValues.speciality === 'Orthodontic Resident';
  }

  getdoctorSpecialityIds() {
    if (
      this.isSpecialityOrthodontist() ||
      this.isSelectedSpeciality('Other Doctor')
    ) {
      return [this.getSelectedSpeciality()[0].id];
    } else {
      return keys(this.state.subCategories);
    }
  }

  getStudentDoctorSpecialityIds() {
    if (
      this.isSpecialityStudentDoctor() ||
      this.isSelectedSpeciality('Other Student')
    ) {
      return [this.getSelectedSpeciality()[0].id];
    } else {
      return keys(this.state.subCategories);
    }
  }

  getSpecialityIds() {
    switch (this.props.selectedRole) {
      case 'doctor':
        return {
          doctorSpecialityIds: this.getdoctorSpecialityIds(),
        };
      case 'student_doctor':
        return {
          studentDoctorSpecialityIds: this.getStudentDoctorSpecialityIds(),
        };
      case 'other':
        return {
          otherSpecialityId: this.getSelectedSpeciality()[0].id,
          otherSpeciality: this.formValues.textInput,
        };
    }
  }

  onSubmit = (values) => {
    if (this.isSubmitDisabled()) {
      this.setState({ error: 'Please select one field.' });
    } else {
      this.setState({ error: '' });
      let variables = {
        currentRole: this.props.selectedRole,
        website_url: values.url,
        graduation_year: values.graduationYear,
        ...this.getSpecialityIds(),
      };
      this.props.onSubmit(variables);
    }
  };

  goBack = () => {
    this.props.goBack();
  };

  isStudentDoctorRole() {
    return this.props.selectedRole === 'student_doctor';
  }

  isSubmitDisabled() {
    let values = this.formValues;
    return (
      values &&
      (!values.speciality ||
        (this.subCategoriesRequired() && isEmpty(this.state.subCategories)) ||
        (this.textInputRequired() && !values.textInput) ||
        !values.url ||
        (this.isStudentDoctorRole() && !values.graduationYear) ||
        this.props.disableCTA)
    );
  }

  renderError() {
    if (
      this.state.error &&
      (!this.formValues.speciality || this.renderSubCategoriesIfRequired())
    )
      return <span className='form-error'>{this.state.error}</span>;
  }

  openNotionURL() {
    window.open(VERIFICATION_PROCESS_NOTION_LINK, '_blank');
  }

  render() {
    if (!this.getUserRoles()) {
      return <Loader type='ball-triangle-path' active />;
    }
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          speciality: '',
          url: '',
          graduationYear: '',
          textInput: '',
        }}
        destroyOnUnmount={false}
        forceUnregisterOnUnmount={false}
        render={(props) => {
          this.selectedSpeciality = props.values.speciality;
          this.formValues = props.values;
          return (
            <form name='userSpecialityForm' onSubmit={props.handleSubmit}>
              <div className='user-specialty'>
                <div className='your-specialty-text'>
                  Your specialty*{' '}
                  <OrthoIcon
                    name='questions'
                    defaultClass='os-c66'
                    onClick={this.openNotionURL}
                  />
                </div>
                <div className='user-specialty-list c_p_list'>
                  {keys(this.getSpecialitiesCategories()).map(
                    this.renderSpecialities,
                  )}
                </div>
                <div>
                  {this.renderSubCategoriesIfRequired()}
                  {this.renderError()}
                  <div className='mt-4'>{this.renderTextInputIfRequired()}</div>
                </div>
                <div className='group-url'>
                  {this.renderUrl()}
                  {this.renderGraduationYearIfRequired()}
                </div>
                <div className='user-action-footer specialty-actions text-right'>
                  <OsBtn
                    name='BtnSecondary'
                    onClick={this.goBack}
                    text='back'
                    disabled={this.props.disableCTA}
                    extraClass='with-border web-view-btn'
                  />
                  <OsBtn
                    name='BtnPrimary'
                    type='submit'
                    text='next step'
                    disabledView={this.isSubmitDisabled()}
                    disabled={this.props.disableCTA}
                    extraClass='web-view-btn'
                    loaderRequired={this.props.disableCTA}
                    htmlTag='button'
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

UserSpecialityInfo = graphql(USER_ROLE, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
  }),
})(UserSpecialityInfo);

UserSpecialityInfo = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(UserSpecialityInfo);

export default UserSpecialityInfo;
