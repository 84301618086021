import React from 'react';

import AbstractCard from './AbstractCard';
import AddTo from 'app/components/Shared/AddTo';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Share from 'app/components/Shared/Share';
import Icon from 'app/components/Svg';
import { connect } from 'react-redux';
import { getHostName, isURLSecured } from 'app/utils/urlHelper';
import { viewObject } from 'app/actions/viewObject';

class AdditionalLinkCard extends AbstractCard {
  state = {
    renameFormVisibile: false,
  };

  onCardClick = (e) => {
    const link = this.props.obj;
    this.props.onCardClick(e, link);
  };

  getEntitySubTitle() {
    return this.props.obj.url;
  }

  getEntityTitle() {
    return this.getLinkTitle();
  }

  entityType() {
    return 'Link';
  }

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  // openGraphDataPresent() {
  //   let { openGraph } = this.props.ogResults;
  //   return openGraph && !openGraph.error
  // }

  getEntityImageUrl() {
    return this.getImageUrl();
  }

  getImageUrl() {
    return (
      (this.props.obj.processed && this.props.obj.image_url) ||
      this.getOpenGraphImageURL()
    );
  }

  renderImage() {
    let url = this.getImageUrl();
    if (url && isURLSecured(url)) {
      return <img src={url} alt='' className='card-attach-image' />;
    } else {
      return <OrthoIcon name='additionallink' className='card-link-svg' />;
    }
  }

  getOpenGraphTitle() {
    const { openGraph, hybridGraph } = this.props.ogResults || {};
    return openGraph?.title || hybridGraph?.title;
  }

  getOpenGraphImageURL() {
    const { openGraph, hybridGraph } = this.props.ogResults || {};
    return openGraph?.image?.url || hybridGraph?.image;
  }

  getLinkTitle() {
    return (
      this.getOpenGraphTitle() ||
      this.props.obj.title ||
      getHostName(this.props.obj.url)
    );
  }

  renderAddTo() {
    return (
      <AddTo key='add_to_space' obj={this.props.obj} extraClass='list-button' />
    );
  }

  renderShare() {
    return (
      <Share
        key='share'
        object={this.props.obj}
        label='Share'
        extraClass='list-button'
      />
    );
  }

  renderContent() {
    return (
      <div className='attachment-card-image'>
        <div className='img-parent'>
          {this.renderImage()}
          {this.renderDoubleTickIconIfRequired()}
        </div>
      </div>
    );
  }

  renameBtnRequired() {
    return (
      this.isSourceSpaceDetail() &&
      this.isAuthorOrEditorOfSourceObject() &&
      this.renameEntityPreferrableCardSize()
    );
  }

  renderDropDown() {
    return (
      <CustomDropdown
        name='link_action'
        className='card-dropdown-menu'
        onDropDownToggle={this.props.onDropDownToggle}>
        {this.renderAddTo()}
        {this.renderShare()}
        {this.markAsFinished()}
        {this.renderSelectButton()}
        {this.renderEntityRenameButton()}
        {this.renderRemoveButton()}
      </CustomDropdown>
    );
  }

  renderTitle() {
    return (
      <div className='attach-card-head'>
        <div className='left-attach-col'></div>

        <div className='right-attach-col'>{this.renderDropDown()}</div>
      </div>
    );
  }

  renderFooter() {
    return (
      <>
        <div
          className={
            'card-attachment-body' +
            (this.state.renameFormVisibile ? ' d-none' : '')
          }>
          <div className='cd-type-name'>Link</div>
          <div className='attachment-card-name multiline-truncate'>
            {this.props.obj.url}
          </div>
        </div>
        {this.renderEnityRenameForm()}
      </>
    );
  }

  renderCard() {
    let containerClasses = this.props.obj.extraClass,
      extraContainerClass = this.state.renameFormVisibile ? 'rename' : '';

    return (
      <div className={`${this.props.containerClasses} ${extraContainerClass}`}>
        <div className={containerClasses} onClick={this.onCardClick}>
          {this.props.closeRequired && (
            <OrthoIcon
              name='clear'
              defaultClass='os-close-link'
              onClick={this.afterClose}
            />
          )}
          {this.props.multiSelectMode && this.renderSelectBox()}
          {this.renderContent()}
          {this.renderTitle()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}
AdditionalLinkCard = connect(({ systemConfig }) => ({ systemConfig }), {
  viewObject,
})(AdditionalLinkCard);

AdditionalLinkCard.defaultProps = {
  ogResults: {},
};
export default AdditionalLinkCard;
