import React, { PureComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { entityUrl } from 'app/utils/entitiesHelper';

class SingleCareSpaceRoutes extends PureComponent {
  render() {
    const to = this.props.primarySpace
      ? entityUrl(this.props.primarySpace)
      : '/care/no_records';
    return (
      <Routes>
        <Route path='*' element={<Navigate to={to} />} />
      </Routes>
    );
  }
}
export default SingleCareSpaceRoutes;
