import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Outlet } from 'react-router-dom';

import SimpleNavList from 'app/components/SimpleNavList';
import StickyHeader from 'app/components/StickyHeader';

import {
  getCurrentFilter,
  WORKSPACE_NAV_LIST,
  NAV_LIST_SOURCE,
} from './tabsHelper';
import { PHI_FEATURE_IDENTIFER } from 'app/components/FeatureFlagging/constants';

class TabLayout extends Component {
  tabsAllowedMapper = {
    privacy: {
      featureIdentifier: PHI_FEATURE_IDENTIFER,
    },
  };
  onTabClick = (filter) => {
    this.props.navigate('/account_settings/' + filter);
  };

  navListProps() {
    return {
      currentFilter: getCurrentFilter(this.props.location.pathname),
      onFilterClick: this.onTabClick,
      scrollIntoViewOnOrientationChange: true,
      source: NAV_LIST_SOURCE,
    };
  }

  render() {
    return (
      <div className='account-setting'>
        <div className='collection-page'>
          <div className='container-fluid px-0 account-setting-container os-tab-style os-acctoun-tab-style'>
            <div className='container'>
              <div className='detail-header'>Account Settings</div>
            </div>
            <StickyHeader>
              <SimpleNavList
                {...this.navListProps()}
                list={WORKSPACE_NAV_LIST}
                checkTabFeatureAllowedMapper={this.tabsAllowedMapper}
              />
            </StickyHeader>
            <div className='container'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TabLayout = withRouter(TabLayout);

export default TabLayout;
