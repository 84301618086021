import React from 'react';
import { connect } from 'react-redux';

import AbstractCard from 'app/components/OsCards/AbstractCard';
import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import AddTo from 'app/components/Shared/AddTo';
import ArchiveSpace from 'app/components/Shared/ArchiveSpace';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import EntityEngagementFooter from 'app/components/Shared/EntityEngagementFooter';
import Icon from 'app/components/Svg';
import JoinSpace from 'app/components/SpaceRequests/JoinSpace';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import Share from 'app/components/Shared/Share';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import { openInfoModal } from 'app/actions/infoModal';

import { entityEditUrl, entityUrl } from 'app/utils/entitiesHelper';
import {
  canSpaceBeUnArchived,
  iCompanyTypeSpace,
  isContentTypeSpace,
  isOpenSpace,
  isSpaceArchived,
  getSpaceIconType,
  isFolderSpace,
} from 'app/utils/spaceHelper';
import { translate } from 'app/actions/flashMessage';
import { take } from 'app/utils/osLodash';
import { pluralize } from 'app/utils/generalHelper';
import { isNull } from 'app/utils/osLodash';

import { REJECTED_STATUS } from 'app/components/BoardView/constants';
const PENDING_STATUS = ['pending'];

class DefaultSpaceCard extends AbstractCard {
  onCardClick = (e, options = {}) => {
    this.props.onCardActionClick();
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  ownedByCurrentUser() {
    return (
      this.props.currentUser.graph && this.props.obj.user &&
      +this.props.obj.user.id === +this.props.currentUser.graph.id
    );
  }

  openSpaceEdit = (e) => {
    this.onCardClick(e, {
      url: entityEditUrl(this.props.obj, { skipWorkspace: true }),
    });
  };

  renderEdit() {
    return (
      <OsBtn
        key='Edit'
        name='BtnIcon'
        icon='edit'
        text='Edit'
        extraClass='list-button ms-0'
        onClick={this.openSpaceEdit}
        associatedEntity={this.props.obj}
      />
    );
  }

  renderAddTo() {
    return <AddTo obj={this.props.obj} extraClass='list-button' />;
  }

  isGuestUser() {
    return !this.props.currentUser.graph;
  }

  redirectToDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.redirectToDefault();
  };

  redirectToDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.redirectToDefault();
  };

  renderShare() {
    return (
      <Share
        type='Board'
        object={this.props.obj}
        label={'SHARE'}
        extraClass='list-button'
        inDropdown={true}
      />
    );
  }

  renderDropdown() {
    if (this.isGuestUser()) {
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='no-text v-align-top card-btn-more card-dropdown-menu '
          onClick={this.redirectToDefault}
          icon='more'
        />
      );
    }
    if (this.props.dropDownRequired)
      return (
        <CustomDropdown
          name='space_action'
          className='card-dropdown-menu'
          onDropDownToggle={this.props.onDropDownToggle}>
          {this.ownedByCurrentUser() &&
            !isFolderSpace(this.props.obj) &&
            this.renderEdit()}
          {this.renderSelectButton()}
          {isOpenSpace(this.props.obj) &&
            this.props.obj.is_author_or_collaborator &&
            this.renderShare()}
          {this.isRequestToJoinStatusPending() &&
            this.props.obj.member_requested_by_author && (
              <DeclineRequest
                extraClass='list-button'
                btnType='BtnIcon'
                text='Ignore'
                source='card'
                associatedEntity={this.props.obj}
                requestingUser={this.props.currentUser.graph}
                space={this.props.obj}
              />
            )}
          {canSpaceBeUnArchived(this.props.obj) && (
            <ArchiveSpace
              space={this.props.obj}
              extraClass='list-button'
              name='BtnIcon'
              icon='archive'
            />
          )}
          {this.renderRemoveButton()}
        </CustomDropdown>
      );
  }

  getWithoutImageClass(source) {
    if (source.includes('/assets/')) {
      return 'space-placeholder';
    } else {
      return '';
    }
  }

  isTallAndTallMin() {
    return this.props.size === 'tall' || this.props.size === 'tall-min';
  }

  isSmallOrSmallMin() {
    return this.isSmallMin() || this.isSmallCard();
  }

  isSmallMin() {
    return this.props.size === 'small-min';
  }

  isSmallCard() {
    return this.props.size === 'small';
  }

  countOfItemsAdded() {
    return this.props.obj.links_count
      ? `${this.props.obj.links_count} added content`
      : '';
  }

  getImages = (source) => {
    if (source) {
      return (
        <div className='collection-img-parent'>
          <img src={source} alt='' className='space-media' />
        </div>
      );
    } else {
      return (
        <div className='collection-img-parent'>
          <OrthoIcon name='image' dataHoverNotRequired={true} />
        </div>
      );
    }
  };

  getOwner(space) {
    return iCompanyTypeSpace(space) ? space.owner : space.user;
  }

  renderCommentCount() {
    let space = this.props.obj;
    return space.comments_count
      ? pluralize(space.comments_count, {
          singular: `${space.comments_count} comment`,
          plural: `${space.comments_count} comments`,
        })
      : '';
  }

  renderCardExtraInfo(space) {
    if (
      this.isExtraSmall() &&
      this.isNotAPartOfSpace() &&
      this.isRequestToJoinStatusPending() &&
      !space.member_requested_by_author
    ) {
      return 'Pending request to join';
    } else {
      let commentsCount = this.renderCommentCount(),
        linksCount = this.countOfItemsAdded();

      return `${commentsCount} ${
        commentsCount && linksCount && ' | '
      } ${linksCount}`;
    }
  }

  actionTagRequired() {
    let space = this.props.obj;
    return (
      this.isExtraSmall() &&
      this.isRequestToJoinStatusPending() &&
      space.member_requested_by_author
    );
  }

  renderSpaceJoinRequestActionTag() {
    if (this.actionTagRequired())
      return <span class='space-action-tag'>Action required</span>;
  }

  isCtaActionWithIcon() {
    return this.props.ctaIcons;
  }

  renderRequestToJoinWithIcon() {
    let space = this.props.obj;
    if (this.isCtaActionWithIcon() && this.isNotAPartOfSpace()) {
      if (this.joinSpaceActionRequired(space)) {
        let requestingUsers = this.isGuestUser()
          ? []
          : [this.props.currentUser.graph];
        return (
          <JoinSpace
            onlyIconRequired={true}
            icon='add'
            text=''
            viewType='primaryAction'
            source='card'
            name='BtnIcon'
            extraClass='web-view-btn'
            requestingUsers={requestingUsers}
            board={space}
            onAction={this.props.onCardActionClick}
            onSuccess={this.onJoinSpaceRequest}
          />
        );
      } else {
        return <OrthoIcon name='tick' dataHoverNotRequired={true} />;
      }
    }
  }

  renderUserInfo() {
    let space = this.props.obj;

    return (
      <div className='space-user-info'>
        <div className='space-detail'>
          <div className='space-name text-truncate' title={space.name}>
            <span className='extra-small-card-space-name'>{space.name}</span>
          </div>
          {this.renderSpaceJoinRequestActionTag()}
          <div className='space-user'>{this.renderCardExtraInfo(space)}</div>
        </div>
        {this.renderRequestToJoinWithIcon()}
      </div>
    );
  }

  renderHeaderImage() {
    return (
      <figure className='card-v2-hero'>
        <div className='img-parent'>
          <span className='hero-image-overlay'></span>
          <img src={this.props.obj.image} alt='' className='card-hero-image' />
          {this.renderDoubleTickIconIfRequired()}
        </div>
      </figure>
    );
  }

  getDefaultClass() {
    let klass = 'h-caption-icon fs-15 ';
    klass += !this.isTallAndTallMin() ? 'me-1 ' : '';
    return klass;
  }

  getSpaceCategoryName() {
    if (!isContentTypeSpace(this.props.obj)) {
      if (this.isSmallOrSmallMin()) {
        return this.props.obj.type.replace('Space', '').trim();
      } else {
        return this.props.obj.type;
      }
    } else {
      return this.isTall() ? 'Space' : 'ID';
    }
  }

  renderSpaceDateOrId(space, nameAttribute) {
    return `${this.getSpaceCategoryName(nameAttribute)} ${space.nice_id}`;
  }

  renderSpaceIcon(space) {
    const name = getSpaceIconType(space);
    return <OrthoIcon name={name} defaultClass={this.getDefaultClass()} />;
  }

  renderTitle() {
    let space = this.props.obj,
      nameAttribute = isContentTypeSpace(space) ? 'category_name' : 'type';
    return (
      <div className='caption'>
        {this.isTallAndTallMin() && this.renderSpaceIcon(space)}
        <span className='space-id'>
          {this.renderSpaceDateOrId(space, nameAttribute)}
        </span>
        {this.renderDropdown()}
      </div>
    );
  }

  getFooterSource() {
    return this.isSmallMin() ? 'space-card-min' : 'space-card';
  }

  likeShareCommentAction(space) {
    return (
      <EntityEngagementFooter
        obj={space}
        source={this.getFooterSource()}
        disableShareIcon={isSpaceArchived(space)}
      />
    );
  }

  pendingStatusActions() {
    let space = this.props.obj,
      btnCommonProps = {
        associatedEntity: space,
        extraClass: 'm-auto space-lg-y-4 web-view-btn',
        requestingUser: this.props.currentUser.graph,
        space: space,
        checkAccess: true,
        onAction: this.props.onCardActionClick,
      };

    if (space.member_requested_by_author) {
      return (
        <AcceptRequest
          {...btnCommonProps}
          btnType='BtnSecondary'
          text='Accept Invitation'
        />
      );
    } else {
      return (
        <DeclineRequest
          {...btnCommonProps}
          text='Cancel Request'
          source='card'
        />
      );
    }
  }

  joinSpaceActionRequired(space) {
    return (
      !iCompanyTypeSpace(space) &&
      (REJECTED_STATUS.includes(space.member_request_status) ||
        !space.member_request_status)
    );
  }

  getActiveUserSubscription() {
    return this.props.obj.active_user_subscription;
  }

  isRequestToJoinStatusPending() {
    return PENDING_STATUS.includes(this.props.obj.member_request_status);
  }

  getContainerClass() {
    let containerClasses = ` ${
        this.props.containerClasses
      } ${this.props.obj.category_name?.toLowerCase()?.replace(' ', '-')} `,
      ownedByCurrentUser = this.ownedByCurrentUser(),
      othersSpace = !ownedByCurrentUser,
      isMyDefaultSpace =
        ownedByCurrentUser && this.props.obj.objective !== 'normal';

    containerClasses += othersSpace ? ' others-space' : '';
    containerClasses += isMyDefaultSpace ? ' my-default-space d-block p-0' : '';
    containerClasses += this.isCtaActionWithIcon() ? ' cta-icons' : '';
    containerClasses += this.actionTagRequired() ? ' with-action-tag' : '';

    if (!this.isTall() && !this.isNotAPartOfSpace()) {
      containerClasses += ' space-without-action ';
    }
    return containerClasses;
  }

  isNotAPartOfSpace() {
    return (
      !this.ownedByCurrentUser() && !this.props.obj.is_author_or_collaborator
    );
  }

  onJoinSpaceRequest = () => {
    this.context &&
      this.context.refetchData &&
      this.context.refetchData(this.props.obj);
  };

  renderNonCourceActions(space) {
    if (this.isNotAPartOfSpace()) {
      if (this.isRequestToJoinStatusPending()) {
        return this.pendingStatusActions();
      } else if (this.joinSpaceActionRequired(space)) {
        let requestingUser = this.isGuestUser()
          ? []
          : [this.props.currentUser.graph];
        return (
          <JoinSpace
            viewType='primaryAction'
            source='card'
            name='BtnSecondary'
            extraClass='m-auto space-lg-y-4 web-view-btn'
            requestingUsers={requestingUser}
            board={space}
            onAction={this.props.onCardActionClick}
            onSuccess={this.onJoinSpaceRequest}
          />
        );
      }
    }
  }

  renderFooterCTAs() {
    let space = this.props.obj;
    if (!this.isCtaActionWithIcon()) {
      return this.renderNonCourceActions(space);
    }
  }

  renderFooter() {
    return (
      !this.isExtraSmall() && (
        <aside className='card-v2-footer space-card-footer'>
          {this.renderFooterCTAs()}
        </aside>
      )
    );
  }

  userInfoInFooter() {
    return !this.isTall() && !this.isNotAPartOfSpace();
  }

  render() {
    let containerClasses = this.getContainerClass(),
      isTall = this.isTall(),
      ownedByCurrentUser = this.ownedByCurrentUser(),
      footerClass = `card-content ${
        ownedByCurrentUser ? 'without-media' : ''
      } ${this.props.multiSelectMode ? 'text-center' : ''}`,
      space = this.props.obj;

    return (
      <div
        className={'space-v2-card ' + containerClasses}
        onClick={this.onCardClick}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            onClick={this.afterClose}
            defaultClass='os-close-link'
          />
        )}
        <div className='card-v2-header'>
          {this.renderHeaderImage()}
          {this.renderTitle()}
          {!isTall && this.isNotAPartOfSpace() && this.renderUserInfo()}
        </div>

        <article className={footerClass}>
          {this.userInfoInFooter() && this.renderUserInfo()}
          {isTall && this.renderUserInfo()}
          {this.renderFooterOrSelectBox()}
        </article>
      </div>
    );
  }
}

DefaultSpaceCard.defaultProps = {
  onCardActionClick: () => {},
  dropDownRequired: true,
};
DefaultSpaceCard = withAuthenticate(DefaultSpaceCard);
DefaultSpaceCard = connect(({ currentUser }) => ({ currentUser }), {
  openInfoModal,
})(DefaultSpaceCard);
DefaultSpaceCard = withAuthenticate(DefaultSpaceCard);
export default DefaultSpaceCard;
