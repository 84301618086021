import React, { PureComponent } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

class PhoneLink extends PureComponent {
  render() {
    let { phone, icon } = this.props;
    return (
      <div className='phone-text'>
        {icon && <OrthoIcon name='phone' dataHoverNotRequired={true} />}
        <OsLink
          href={`tel:${phone}`}
          className='white-link'
          text={phone}
          target='_blank'
        />
      </div>
    );
  }
}

PhoneLink.defaultProps = {
  icon: false,
};

export default PhoneLink;
