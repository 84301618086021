export const TITLE_MAPPER = {
  boards: {
    index: 'All Spaces',
    following: 'Followed Spaces',
    yours: 'Your Spaces',
    shared: 'Shared Spaces',
  },
  cases: {
    index: 'All Cases',
    following: 'Following Cases',
    locked: 'Locked Cases',
    unlocked: 'Unlocked Cases',
    yours: 'Your Cases',
    comparisons: 'All Comparisons',
    your_comparisons: 'Your Comparisons',
  },
  clinics: { index: 'All Clinics', yours: 'My Clinics' },
  courses: { index: 'All Courses', yours: 'My Courses' },
  doctors: {
    index: 'All Doctors',
    top_active: 'Top Active',
    following: 'Followed Doctors',
    followers: 'My Followers',
    connections: 'My Connections',
  },
  feeds: { my_feed: 'My feed', polaris_feed: 'Cloudberry feed' },
  tools: {
    index: 'All Tools',
    following: 'Followed Tools',
    yours: 'Your Toolbox',
    companies: 'Companies',
  },
  spaceContent: {
    all: 'All',
    all_content: 'All',
    cases: 'Cases',
    posts: 'Posts',
    comments: 'Comments',
    spaces: 'Space',
    tools: 'Tools',
    companies: 'Companies',
    files: 'Videos & files',
  },
};

export const ICON_MAPPER = {
  boards: {
    default: 'spaces',
    index: 'spaces',
    following: 'follow',
    yours: 'user',
    shared: 'user-referral',
  },
  cases: {
    default: 'cases',
    comparisons: 'comparison',
    index: 'cases',
    following: 'follow',
    locked: 'insights',
    unlocked: 'comments',
    yours: 'user',
    your_comparisons: 'user',
  },
  clinics: { index: 'company', yours: 'company' },
  courses: { index: 'course', yours: 'course' },
  doctors: {
    default: 'user',
    index: 'doctors',
    top_active: 'doctors',
    following: 'follow',
    followers: 'followers',
    connections: 'connections',
  },
  feeds: { my_feed: 'followers', polaris_feed: 'posts' },
  tools: {
    default: 'tools',
    companies: 'company',
    index: 'tools',
    following: 'follow',
    yours: 'user',
  },
  spaceContent: {
    all: 'all',
    all_content: 'all',
    cases: 'cases',
    posts: 'posts',
    comments: 'comments',
    spaces: 'spaces',
    tools: 'tools',
    companies: 'company',
    files: 'documents',
  },
};
