import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { avatarAttributes, getIconClass } from 'app/utils/entitiesHelper';
import { getCareAvatarIconProps } from 'app/utils/spaces/careHelper';
import Avatar from 'app/components/Shared/Avatar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { OPTION_ICON_MAPPER } from 'app/components/TipTapEditor/constant';

const SCROLL_VERTICALLY_BY_PIXELS = 32;

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { mention } = props?.items;
  const optionRef = useRef(null);

  const selectItem = (index) => {
    const item = props?.items.mention[index];
    if (item && item.__typename === 'User') {
      props?.command({
        id: item.id,
        label: item.name,
        typename: item.__typename,
      });
    } else if (item && item.__typename === 'Topic') {
      props?.command({ id: item.id, label: item.name });
    } else if (item && item.__typename === 'CommentableMentionGroup') {
      props?.command({
        id: item.identifier,
        label: item.display_name,
        typename: item.__typename,
      });
    } else if (item && item.__typename === 'Template') {
      props?.command({
        id: item.id,
        label: item.title,
        template: item,
        typename: item.__typename,
      });
    } else if (item && item.__typename === 'option') {
      props.command({
        id: item.id,
        item: item,
      });
    } else if (item && item.__typename === 'Person') {
      props?.command({
        id: item.id,
        label: item.first_name + ' ' + item.last_name,
        item: item,
        typename: item.__typename,
      });
    }
  };

  const generateItem = (label) => {
    props?.command({ id: -1, label: label });
  };

  useEffect(() => {
    if (selectedIndex === 0) {
      optionRef?.current.scrollTo(0, 0);
    }
    if (selectedIndex === props?.items?.mention?.length - 1) {
      optionRef?.current.scrollTo(0, optionRef?.current.scrollHeight);
    }
  }, [selectedIndex]);

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props?.items?.mention?.length - 1) %
        props?.items?.mention?.length,
    );
    optionRef.current.scrollBy(0, SCROLL_VERTICALLY_BY_PIXELS * -1);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props?.items?.mention?.length);
    if (selectedIndex >= 3) {
      optionRef?.current.scrollBy(0, SCROLL_VERTICALLY_BY_PIXELS);
    }
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (props?.items?.mention?.length > 0 && event.key === 'Enter') {
        enterHandler();
        return true;
      }

      if (
        props?.query.length > 0 &&
        (event.key === ' ' || event.key === 'Enter')
      ) {
        generateItem(props?.query);
        return true;
      }

      return false;
    },
  }));

  const displayOptions = (user) => {
    let name = user.name;
    if (
      user.related_workspace?.id &&
      +user.related_workspace?.id !== +props?.items?.workspaceId
    )
      name += ` (${user.related_workspace.name})`;
    switch (user.__typename) {
      case 'Person':
      case 'User':
        return (
          <>
            <Avatar
              style={{ alignItems: 'center' }}
              className='avatar avatar-24 suggest-avatar avatar-suggestion'
              {...avatarAttributes(
                user,
                getCareAvatarIconProps(props?.items?.entity, user),
              )}
            />
            {name}
          </>
        );
      case 'CommentableMentionGroup':
        return (
          <>
            <i className={`${getIconClass('loudspeaker')} mx-1 fs-16 `} />
            {'@' + user.display_name + ' ' + user.info_message}
          </>
        );
      case 'Topic':
        return user.name;
      case 'Template':
        return user.title;
      case 'option':
        return (
          <div className='custom-menu-option'>
            <OrthoIcon name={OPTION_ICON_MAPPER[user.optionLabel]} />
            <span>{user.optionLabel}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='editor-items' ref={optionRef}>
      {mention?.length > 0 &&
        mention.map((user, index) => {
          return (
            <div
              className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
              key={user.id}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => selectItem(index)}>
              {displayOptions(user)}
            </div>
          );
        })}
    </div>
  );
});
