import React, { PureComponent } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import IconsList from './IconsList';
import SvgList from './SvgList';
import { last } from 'app/utils/osLodash';

class TabLayout extends PureComponent {
  selectTab = (tab) => {
    this.props.navigate(`/designs/${tab}`);
  };

  render() {
    let defaultValue = last(this.props.location.pathname.split('/'));
    return (
      <Tabs
        defaultActiveKey={defaultValue}
        id='uncontrolled-tab-example'
        className='mb-3 icon-svg-tables'
        onSelect={this.selectTab}>
        <Tab eventKey='icons' title='Icons'>
          <IconsList />
        </Tab>
        <Tab eventKey='svg' title='SVG'>
          <SvgList />
        </Tab>
      </Tabs>
    );
  }
}

TabLayout = withRouter(TabLayout);

export default TabLayout;
