import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';
import scrollToElement from 'scroll-to-element';
import EventTracker from 'app/services/EventTracker';

import { DragOverlay } from '@dnd-kit/core';

import OsBtn from 'app/components/OsBtn';
import TabSection from './TabSection';
import TabSectionForm from './TabSectionForm';
import { DragAndDropContext } from 'app/components/DnD/DragAndDropContext';
import { Draggable } from 'app/components/DnD/Draggable';
import { Droppable } from 'app/components/DnD/Droppable';

import {
  resetActiveDragDetails,
  resetSpaceLinks,
  setActiveDragDetails,
  setSpaceLinksDropOptions,
  toggleSpaceLink,
  toggleSpaceLinksRequestInProgress,
} from 'app/actions/spaceLinks';

import StoreUpdater from 'app/services/StoreUpdater';

import { animateSupported, getSpaceSectionOffset } from 'app/utils/domHelper';
import { arrayMove } from 'app/utils/arrayHelper';
import { isInViewport } from 'app/utils/domHelper';
import { last, map } from 'app/utils/osLodash';

const DEFAULT_SECTION_OPEN_COUNT = 3;
const SPACE_SECTION_NOT_REQUIRED_IF_NO_LINKS = ['Pulse', 'Comment', 'Board'];
const UPDATE_TAB_SECTION_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateTabSection.gql',
);
const MOVE_TAB_LINKS_MUTATION = queryLoader(
  'app/graphql/mutations/MoveTabLinks.gql',
);

class SectionsListing extends Component {
  state = {
    newSectionFormOpen: false,
  };

  componentWillUnmount() {
    this.props.resetSpaceLinks();
  }

  canArrangeContent() {
    return this.getSpace().is_author_or_editor && this.props.device.desktop;
  }

  cardsCustomMarginRequired() {
    return this.canArrangeContent() || this.cardsSelectionModeEnabled();
  }

  cardsSelectionModeEnabled() {
    return this.canArrangeContent() && this.selectedLinkIds().length > 0;
  }

  getSpace() {
    return this.props.entity;
  }

  selectedLinkIds() {
    return this.props.spaceLinks.selectedLinkIds;
  }

  onToggleCardSelection = (object) => {
    this.props.toggleSpaceLink(object);
  };

  moveUpAllowed(index) {
    return index !== 0 && this.totalResults() > 1;
  }

  moveDownAllowed(index) {
    return index !== this.totalResults() - 1 && this.totalResults() > 1;
  }

  totalResults() {
    return this.props.results.length;
  }

  orderedSectionIds() {
    return map(this.props.results, 'id');
  }

  orderedSections() {
    return this.props.results;
  }

  addNewContentLinkNotRequired() {
    return false;
  }

  sendEvent(entityType, entityDraggedId) {
    EventTracker.trackEntityDragged(
      entityType,
      entityDraggedId,
      this.props.entity,
    );
  }

  handleDragStart = (event) => {
    let { active } = event;
    if (active && active.id) {
      // Format section:${sectionId}:link:${linkId}
      let [section, dragSectionId, link, dragLinkId] = active.id.split(':');
      this.props.setActiveDragDetails({
        activeDragType: link || section,
        dragId: dragLinkId || dragSectionId,
      });
    }
  };

  handleDragMove = (event) => {
    // let draggingRect = event.active.rect.current.initial,
    //     top = draggingRect.top,
    //     bottom = window.innerHeight - top - draggingRect.height,
    //     isHeadSticky = window.$('.head-sticky.fixed').length > 0,
    //     sectionDragActive = this.props.spaceLinks.activeDragType === 'section',
    //     topOffset = -getSpaceSectionOffset() + 10,
    //     bottomOffset = sectionDragActive ? 80 : 50,
    //     step = 17
    //
    // if(top < topOffset && isHeadSticky) {
    //   window.$(window).scrollTop(window.scrollY - step)
    // } else if(bottom < bottomOffset) {
    //   window.$(window).scrollTop(window.scrollY + step)
    // }
  };

  handleDragEnd = (event) => {
    if (this.props.spaceLinks.activeDragType === 'section') {
      this.handleSectionDragEnd(event);
    } else {
      this.handleLinkDragEnd(event);
    }
    this.props.resetActiveDragDetails();
  };

  handleSectionDragEnd = (event) => {
    let { active, over } = event;
    if (!over || this.totalResults() < 2) return;

    let orderedSectionIds = this.orderedSectionIds(),
      activeId = active.id.replace('section:', '').toString(),
      overId = over.id.replace('section:', '').toString();
    if (activeId === overId) return;

    let oldIndex = orderedSectionIds.indexOf(activeId),
      newIndex =
        overId === 'bottom'
          ? orderedSectionIds.length
          : orderedSectionIds.indexOf(overId),
      variables = { id: activeId };
    if (oldIndex < newIndex) newIndex -= 1;
    if (newIndex < 0) newIndex = 0;

    orderedSectionIds = arrayMove(orderedSectionIds, oldIndex, newIndex);
    StoreUpdater.updateTabSections(null, {
      reorder: orderedSectionIds,
      idQuery: this.props.navTabId,
      searchQuery: this.props.searchQuery,
      additionalFilters: this.props.additional_filters,
      sortQuery: this.props.sortQuery,
    });

    if (overId === 'bottom') {
      variables.moveToBottom = true;
    } else {
      variables.moveBeforeSectionId = overId;
    }
    this.sendEvent('section', activeId);

    this.props
      .updateTabSectionMutation({
        variables,
      })
      .then(({ data }) => {
        if (!data.updateTabSection.success)
          console.log(data.updateTabSection.error);
      });
  };

  handleLinkDragEnd = (event) => {
    let { active, over } = event,
      selectedLinkIds = this.selectedLinkIds();

    if (!selectedLinkIds.length) selectedLinkIds = [last(active.id.split(':'))];
    if (!over || (selectedLinkIds.length === 1 && active.id === over.id))
      return;

    let [section, destinationSectionId, link, linkId] = over.id
        .replace('temp', '')
        .split(':'),
      insertAtBottom = linkId === 'insert_at_bottom',
      lowerLinkId = linkId === 'insert_at_bottom' ? null : linkId,
      variables = {
        destinationSectionId,
        insertAtBottom,
        lowerLinkId,
        selectedLinkIds,
        searchQuery: this.props.searchQuery,
        additional_filters: JSON.stringify(this.props.additional_filters || {}),
      };

    this.props.toggleSpaceLinksRequestInProgress();
    linkId &&
      this.props.setSpaceLinksDropOptions({
        destinationSectionId,
        insertAtBottom,
        lowerLinkId,
      });

    setTimeout(() => {
      let linkElement = document.getElementById(`link-${selectedLinkIds[0]}`);
      if (linkElement) {
        let isLinkInViewport = isInViewport(linkElement),
          isHeadSticky = window.$('.head-sticky.fixed').length > 0;

        if (
          isLinkInViewport &&
          isHeadSticky &&
          linkElement.getBoundingClientRect().top < -getSpaceSectionOffset()
        )
          isLinkInViewport = false;

        !isLinkInViewport &&
          scrollToElement(linkElement, {
            ease: 'linear',
            offset: getSpaceSectionOffset(),
            duration: 100,
          });
      }
    }, 700);

    this.sendEvent('attachment', selectedLinkIds);
    this.props.resetSpaceLinks();

    this.props
      .moveTabLinksMutation({
        variables,
      })
      .then(({ data }) => {
        this.props.toggleSpaceLinksRequestInProgress();
        linkId && this.props.setSpaceLinksDropOptions({});
      });
  };

  tabSectionNotRequired(tabSection) {
    return (
      !this.getSpace().is_author_or_editor &&
      !tabSection.links_count &&
      SPACE_SECTION_NOT_REQUIRED_IF_NO_LINKS.includes(this.props.textQuery)
    );
  }

  addNewSectionBelow = () => {
    this.setState({ newSectionFormOpen: true });
  };

  closeNewSectionForm = () => {
    this.setState({ newSectionFormOpen: false });
  };

  dragOverlaySupported() {
    return animateSupported();
  }

  isSectionOpen(tabSection, index) {
    return this.props.searchQuery
      ? tabSection.links_count > 0
      : index < DEFAULT_SECTION_OPEN_COUNT;
  }

  renderSection = (tabSection, index) => {
    return (
      <TabSection
        {...this.props}
        canArrangeContent={this.canArrangeContent()}
        cardsCustomMarginRequired={this.cardsCustomMarginRequired()}
        cardsSelectionModeEnabled={this.cardsSelectionModeEnabled()}
        key={`space-section-${tabSection.id}`}
        moveDownAllowed={this.moveDownAllowed(index)}
        moveUpAllowed={this.moveUpAllowed(index)}
        onToggleCardSelection={
          this.canArrangeContent() && this.onToggleCardSelection
        }
        open={this.isSectionOpen(tabSection, index)}
        tabSection={tabSection}
        searchQuery={this.props.searchQuery}
        sortQuery={this.props.sortQuery}
      />
    );
  };

  renderDnDSection = (tabSection, index) => {
    if (this.tabSectionNotRequired(tabSection)) return null;

    let isDragTypeSection = this.props.spaceLinks.activeDragType === 'section',
      isDragging =
        isDragTypeSection && this.props.spaceLinks.dragId === tabSection.id,
      topDroppableRequired = isDragTypeSection && !isDragging,
      bottomDroppableRequired = topDroppableRequired,
      isDraggableHidden = this.dragOverlaySupported() && isDragging,
      draggableId = 'section:' + tabSection.id;

    if (topDroppableRequired) {
      let prevSection = this.orderedSections()[index - 1];
      if (prevSection && this.props.spaceLinks.dragId === prevSection.id)
        topDroppableRequired = false;
    }

    return (
      <>
        <Droppable
          id={'section:' + tabSection.id}
          disabled={!topDroppableRequired}
          className='section-droppable'
        />
        {isDraggableHidden && (
          <DragOverlay className='drag-active'>
            {this.renderSection(tabSection, index)}
          </DragOverlay>
        )}
        <Draggable
          key={draggableId}
          id={draggableId}
          disabled={this.cardsSelectionModeEnabled()}
          className={isDraggableHidden ? 'visibility-hidden' : ''}
          customDragHandle={true}>
          {this.renderSection(tabSection, index)}
        </Draggable>
        {index + 1 === this.orderedSections().length && (
          <Droppable
            id='section:bottom'
            disabled={!bottomDroppableRequired}
            className='section-droppable'
          />
        )}
      </>
    );
  };

  renderDnDContext() {
    return (
      <DragAndDropContext
        onDragStart={this.handleDragStart}
        onDragMove={this.handleDragMove}
        onDragEnd={this.handleDragEnd}>
        {this.orderedSections().map(this.renderDnDSection)}
      </DragAndDropContext>
    );
  }

  renderAllSections() {
    if (this.canArrangeContent()) {
      return this.renderDnDContext();
    } else {
      return this.orderedSections().map(this.renderSection);
    }
  }

  render() {
    let className = 'mt-4 ';
    className += this.canArrangeContent() ? ' manageable-sections ' : '';
    className += this.cardsSelectionModeEnabled()
      ? ' cards-selection-active '
      : '';

    return (
      <>
        <div className={className}>{this.renderAllSections()}</div>
        {this.state.newSectionFormOpen && (
          <TabSectionForm
            onSuccess={this.closeNewSectionForm}
            onClose={this.closeNewSectionForm}
            upperSection={last(this.orderedSections())}
            space={this.props.entity}
            searchQuery={this.props.searchQuery}
            tabId={this.props.navTabId}
            additionalFilters={this.props.additional_filters}
            sortQuery={this.props.sortQuery}
          />
        )}
        {this.canArrangeContent() && (
          <OsBtn
            name='BtnSecondary'
            extraClass='with-border spc-add-new-btn'
            text='Add section'
            onClick={this.addNewSectionBelow}
          />
        )}
      </>
    );
  }
}

SectionsListing = compose(
  graphql(UPDATE_TAB_SECTION_MUTATION, { name: 'updateTabSectionMutation' }),
  graphql(MOVE_TAB_LINKS_MUTATION, { name: 'moveTabLinksMutation' }),
)(SectionsListing);
SectionsListing = connect(
  ({ device, spaceLinks }) => ({ device, spaceLinks }),
  {
    resetActiveDragDetails,
    resetSpaceLinks,
    setActiveDragDetails,
    setSpaceLinksDropOptions,
    toggleSpaceLink,
    toggleSpaceLinksRequestInProgress,
  },
)(SectionsListing);
export default SectionsListing;
