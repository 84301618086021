import React, { Component } from 'react';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import OsLink from 'app/components/OsLink';

import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import SpaceDropdownListing from 'app/components/SideBarListing/SpaceDropdownListing';
import {
  isCurrentDay,
  isDateInPreviousWeek,
  isDateInThisWeek,
  isYesterday,
  timeWithFormat,
} from 'app/utils/timeHelper';
import SpaceRecentActivity from 'app/components/SideBarListing/SpaceRecentActivity';
import { sidebarCareNotificationAvailable } from 'app/actions/allSidebarCareNotifications';
import Tippy from '@tippyjs/react';
import { messageHelper } from 'app/utils/tippyMessage';

export const SECTION_DETAILS = [
  'Today',
  'Yesterday',
  'Earlier this week',
  'Last week',
  'More than 2 weeks ago',
];

class SpaceListing extends Component {
  constructor(props) {
    super(props);
    this.sectionIndexToOpen = React.createRef();
    this.sectionIndexToOpen.current = -1;
  }

  renderAvatar(entity) {
    return (
      <div className='user-avatar'>
        <Avatar {...avatarAttributes(entity)} className='avatar  ' />
      </div>
    );
  }

  renderContent(careSpace) {
    return (
      <div
        className='ac-user-name'
        style={{ justifyContent: 'space-between', display: 'flex' }}>
        <span> {careSpace.owner?.patient?.name}</span>
        {careSpace.user_unread_notifications_count > 0 && (
          <div style={{ display: 'inline' }}>
            {careSpace.mention_notification_present && (
              <span className={'direct-mention-notification'} />
            )}
            <span class='lt-count'>
              {careSpace.user_unread_notifications_count}
            </span>
          </div>
        )}
      </div>
    );
  }

  renderListItem = (careSpace) => {
    let className = careSpace.unseen_comments_present ? 'unread' : '';
    className +=
      careSpace.id === this.props.activeResource?.resource?.id ? ' active' : '';
    return (
      <li className={className}>
        <OsLink to={entityUrl(careSpace)}>
          {this.renderAvatar(careSpace.owner.patient)}
          {this.renderContent(careSpace)}
        </OsLink>
      </li>
    );
  };

  renderRow = (careSpace) => {
    if (careSpace?.last_activity_performed_at) {
      const {
        last_activity_type: activity,
        last_activity_performed_by: person,
      } = careSpace;
      return (
        <Tippy
          placement={'right'}
          content={messageHelper(activity, person?.name)}
          delay={20}
          animation={'fade'}>
          {this.renderListItem(careSpace)}
        </Tippy>
      );
    }
    return this.renderListItem(careSpace);
  };

  arrangeActivityByLastUpdate(results) {
    const spaceList = {
      Today: {
        carespace: [],
        carespaceCount: 0,
      },
      Yesterday: {
        carespace: [],
        carespaceCount: 0,
      },
      'Earlier this week': {
        carespace: [],
        carespaceCount: 0,
      },
      'Last week': {
        carespace: [],
        carespaceCount: 0,
      },
      'More than 2 weeks ago': {
        carespace: [],
        carespaceCount: 0,
      },
    };

    for (let i = 0; i < results.length; i++) {
      let carespace = results[i];
      if (isCurrentDay(carespace.last_activity_performed_at)) {
        spaceList['Today'].carespace.push(carespace);
        spaceList['Today'].carespaceCount +=
          carespace.user_unread_notifications_count;
      } else if (isYesterday(carespace.last_activity_performed_at)) {
        spaceList['Yesterday'].carespace.push(carespace);
        spaceList['Yesterday'].carespaceCount +=
          carespace.user_unread_notifications_count;
      } else if (
        isDateInThisWeek(timeWithFormat(carespace.last_activity_performed_at))
      ) {
        spaceList['Earlier this week'].carespace.push(carespace);
        spaceList['Earlier this week'].carespaceCount +=
          carespace.user_unread_notifications_count;
      } else if (
        isDateInPreviousWeek(
          timeWithFormat(carespace.last_activity_performed_at),
        )
      ) {
        spaceList['Last week'].carespace.push(carespace);
        spaceList['Last week'].carespaceCount +=
          carespace.user_unread_notifications_count;
      } else {
        spaceList['More than 2 weeks ago'].carespace.push(carespace);
        spaceList['More than 2 weeks ago'].carespaceCount +=
          carespace.user_unread_notifications_count;
      }
    }

    return spaceList;
  }

  render() {
    const { results } = this.props;
    const spaceListResults = this.arrangeActivityByLastUpdate(results);

    return (
      <div className={'left-care-space-section'}>
        {SECTION_DETAILS.map((day, index) => {
          if (spaceListResults[day].carespace.length === 0) {
            return <></>;
          } else {
            const makeSectionOpen = this.sectionIndexToOpen.current === -1;
            this.sectionIndexToOpen.current = index;
            return (
              <>
                <SpaceDropdownListing
                  key={index}
                  day={day}
                  section={spaceListResults[day]}
                  sectionOpen={makeSectionOpen}>
                  <SpaceRecentActivity
                    section={spaceListResults[day]}
                    day={day}
                    renderRow={this.renderRow}
                  />
                </SpaceDropdownListing>
              </>
            );
          }
        })}
      </div>
    );
  }
}

SpaceListing = connect(
  ({ activeResource, allSidebarCareNotifications }) => ({
    activeResource,
    allSidebarCareNotifications,
  }),
  { sidebarCareNotificationAvailable },
)(SpaceListing);

export default SpaceListing;
