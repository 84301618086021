import React from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import client from 'app/graphql/client';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractCard from './AbstractCard';
import AddTo from 'app/components/Shared/AddTo';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import CustomErrorFileView from 'app/components/CustomErrorFileView';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import Share from 'app/components/Shared/Share';

import { openAttachmentPreview } from 'app/actions/attachmentPreview';
import {
  fetchFileExtension,
  formatFileSize,
  getFileNameWithoutExtension,
  getFileType,
  isAudio,
  isDownloadable,
  isImage,
  isPDF,
  isPdfProcessed,
  isSvg,
  isVideo,
  isVideoProcessed,
  isZoomable,
} from 'app/utils/fileHelper';
import { openPlayerModal } from 'app/actions/playerModal';
import { viewObject } from 'app/actions/viewObject';
import { upperCase } from 'app/utils/osLodash';

const ATTACHMENT_QUERY = queryLoader('app/graphql/Attachment.gql');

class AttachmentCard extends AbstractCard {
  state = {
    renameFormVisibile: false,
  };

  openPreview = () => {
    this.openVideoPlayerModal(this.props.obj);
  };

  onCardClick = (e) => {
    let file = this.fileObject();
    if (
      !this.context.contentPreviewListing &&
      !this.props.contentNotAccessible &&
      !this.props.avoidPreviewModal &&
      !this.props.selectionMode &&
      !this.props.multiSelectMode
    )
      this.videoPlayerModeRequired()
        ? this.openVideoPlayerModal(file)
        : this.openAttachmentPreview(file);
    this.props.onCardClick && this.props.onCardClick(e);
  };

  getEntitySubTitle() {
    const file = this.fileObject(),
      isVideoType = isVideo(file);

    return isVideoType
      ? file.duration
      : file.file_size > 0
      ? formatFileSize(file.file_size)
      : '';
  }

  getEntityTitle() {
    return getFileNameWithoutExtension(this.fileName());
  }

  getEntityImageChildren() {
    return this.renderContent();
  }

  entityType() {
    return upperCase(this.getFileExtension()).replace(' ', '');
  }

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.fileObject());
  };

  isSmall() {
    return ['small', 'small-min'].includes(this.props.size);
  }

  isReaderViewAvailable() {
    return isZoomable(this.fileObject());
  }

  openAttachmentPreview = (attachment, additionalOptions = {}) => {
    let attachments = this.props.sliderPreview
        ? this.props.attachments
        : [attachment],
      { source, author } = attachment,
      options = { unmuted: !this.props.sliderPreview, ...additionalOptions };

    this.props.openAttachmentPreview(
      source,
      (attachments || [attachment]),
      attachment,
      author,
      options,
    );
  };

  openVideoPlayerModal(file) {
    this.props.openPlayerModal(
      { url: file.preview_url, title: file.name },
      { autoplay: true, nodownload: true, unmuted: true, audio: isAudio(file) },
    );
  }

  openDownloadUrl = () => {
    client
      .query({
        query: ATTACHMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: { id: this.fileObject().id },
      })
      .then(({ data }) => {
        window.location.href = data.attachment.download_url;
        this.viewObject();
      });
  };

  isVideoType() {
    return isVideo(this.fileObject());
  }

  fileObject() {
    // ToDo: Revamp comment section & messenger to avoid `file_asset` here
    return this.props.obj.file_asset || this.props.obj;
  }

  fileName() {
    let file = this.fileObject();
    return file.originalName || file.name;
  }

  videoPlayerModeRequired() {
    let file = this.fileObject();
    return isVideoProcessed(file);
  }

  isImageOrFileProcessed(file) {
    return isImage(file) || isVideoProcessed(file) || isPdfProcessed(file);
  }

  footerRequired() {
    return (
      !isVideo(this.fileObject()) ||
      ['tall', 'tall-min'].includes(this.props.size)
    );
  }

  containerClasses() {
    let file = this.fileObject(),
      isImageOrFileProcessed = this.isImageOrFileProcessed(file),
      className = this.props.obj.extraClass || '';

    className += isImageOrFileProcessed ? '' : ' attachment-link';
    className += isVideo(file) ? ' os-video-card' : '';
    return className;
  }

  isShareableAndSavable() {
    return (
      !this.context.restrictShareAndSave &&
      !(
        this.context.isAttachmentRestricted &&
        !isDownloadable(this.fileObject())
      )
    );
  }

  getFileExtension() {
    return fetchFileExtension(this.fileName());
  }

  renderAddTo() {
    return (
      <AddTo
        key='add_to_space'
        obj={this.fileObject()}
        extraClass='list-button'
      />
    );
  }

  renderShare() {
    return (
      <Share
        key='share'
        object={this.fileObject()}
        label={this.getLabel('Share')}
        extraClass='list-button'
        internalShareOnly={true}
      />
    );
  }

  renderDownload() {
    return (
      <OsBtn
        name='BtnIcon'
        extraClass='list-button'
        icon='download'
        text={this.getLabel('Download')}
        onClick={this.openDownloadUrl}
        associatedEntity={this.fileObject()}
      />
    );
  }

  renderFileErrorView(showOverlay) {
    return (
      <span className='attach-custom-file-icons'>
        <CustomErrorFileView />
        {this.renderDoubleTickIconIfRequired()}
        {showOverlay && <span className='overlay-span'></span>}
      </span>
    );
  }

  renderFileViewer(showOverlay) {
    return (
      <>
        {this.renderIcon(this.props)}
        {this.renderDoubleTickIconIfRequired()}
        {showOverlay && <span className='overlay-span'></span>}
      </>
    );
  }

  renderIcon() {
    let file = this.fileObject(),
      iconType = '';

    switch (fetchFileExtension(this.fileName())) {
      case 'pdf':
        iconType = 'pdf';
        break;
      case 'xls':
      case 'xlsx':
        iconType = 'xls';
        break;
      case 'doc':
      case 'docx':
        iconType = 'doc';
        break;
      default:
        iconType = 'document';
        break;
    }

    if (isImage(file)) {
      iconType = 'image';
    } else if (isAudio(file)) {
      iconType = 'audio';
    } else if (isVideo(file)) {
      iconType = 'lecture';
    }

    return (
      <OrthoIcon
        name={iconType}
        defaultClass='pe-2 image-icon'
        dataHoverNotRequired={true}
      />
    );
  }

  getAdditionalImageProps(file) {
    if (isImage(file)) {
      return {
        onError: (e) => {
          if (!this.srcModified) {
            this.srcModified = true;
            e.target.src = file.preview_url;
          }
        },
      };
    }
  }

  renderSvgOrImage(file) {
    if (isSvg(file)) {
      return (
        <svg width='90' height='90'>
          <image href={file.preview_url} width='90' height='90' />
        </svg>
      );
    } else {
      return (
        <img
          src={file.thumb_url}
          alt={this.fileName()}
          {...this.getAdditionalImageProps(file)}
          className='card-attach-image'
        />
      );
    }
  }

  renderContent(showOverlay) {
    let file = this.fileObject(),
      isDocFile = ['doc', 'docx', 'xls', 'xlsx'].includes(
        fetchFileExtension(this.fileName()),
      );
    if (this.isImageOrFileProcessed(file)) {
      return (
        <div className={'img-parent' + (isPDF(file) ? ' bg-white' : '')}>
          {this.renderSvgOrImage(file)}
          {this.renderDoubleTickIconIfRequired()}
          {isVideo(file) && this.renderPlayIcon()}
          {showOverlay && <span className='overlay-span'></span>}
        </div>
      );
    } else if (isPDF(file) || isDocFile) {
      return this.renderFileViewer(showOverlay);
    } else {
      return this.renderFileErrorView(showOverlay);
    }
  }

  renderFileType() {
    return <span>{getFileType(this.fileObject())}</span>;
  }

  isNewAppointmentSource() {
    return this.props.source === 'new-appointment-modal';
  }

  renameBtnRequired() {
    return (
      this.renameEntityPreferrableCardSize() &&
      ((this.isSourceSpaceDetail() && this.isAuthorOrEditorOfSourceObject()) ||
        this.isNewAppointmentSource() ||
        this.context.renameBtnRequired)
    );
  }

  renderDeleteButton() {
    let obj = this.fileObject();
    return (
      <OsBtn
        name='BtnIcon'
        extraClass='list-button'
        icon='delete'
        text='Delete'
        onClick={this.props.onDeleteClick.bind(this, obj)}
        associatedEntity={obj}
      />
    );
  }

  renderDropDown() {
    let isNewAppointmentSource = this.isNewAppointmentSource(),
      file = this.fileObject(),
      isShareableAndSavable =
        !isNewAppointmentSource && this.isShareableAndSavable();
    return (
      <CustomDropdown
        name='attachment_action'
        className='card-dropdown-menu'
        onDropDownToggle={this.props.onDropDownToggle}>
        {this.renderSelectButton()}
        {this.renderEntityRenameButton()}
        {isShareableAndSavable && this.renderAddTo()}
        {isShareableAndSavable && this.renderShare()}
        {!isNewAppointmentSource &&
          isDownloadable(file) &&
          this.renderDownload()}
        {this.markAsFinished()}
        {isNewAppointmentSource && this.renderDeleteButton()}
        {this.renderRemoveButton()}
      </CustomDropdown>
    );
  }

  renderTitle() {
    let file = this.fileObject(),
      isSmall = this.isSmallCard(),
      isVideoType = isVideo(file);

    return (
      <div className='attach-card-head'>
        <div className='left-attach-col'>
          {!isSmall && (
            <>
              {this.renderIcon()}
              {!this.isExtraSmall() && this.renderFileType()}
            </>
          )}

          {isVideoType && (isSmall || this.isExtraSmall()) && (
            <>
              <span className='cd-type-name'>
                {this.renderCardHeader(file.kind)}
              </span>
              <span
                className='pe-2 video-name'
                title={`${this.getEntityTitle()}`}>
                {this.getEntityTitle()}
              </span>
              {file.duration && (
                <div className='file-duration'>{this.getEntitySubTitle()}</div>
              )}
            </>
          )}
        </div>

        <div className='right-attach-col'>{this.renderDropDown()}</div>
      </div>
    );
  }

  entityInformationDetail() {
    let file = this.fileObject(),
      isSmall = this.isSmallCard(),
      isVideoType = isVideo(file);
    return (
      <div
        className={
          'card-attachment-body' +
          (this.state.renameFormVisibile ? ' d-none' : '')
        }>
        <span className='cd-type-name'>{this.renderCardHeader(file.kind)}</span>
        <div
          className='attachment-card-name'
          title={getFileNameWithoutExtension(this.fileName())}>
          {!isVideoType && isSmall && this.renderIcon()}
          {getFileNameWithoutExtension(this.fileName())}
        </div>
        {isVideoType && file.duration && (
          <div className='file-size'>{file.duration}</div>
        )}
        {!isVideoType && file.sub_type && !isImage(file) && (
          <div className='file-size text-truncate sub-type'>
            {file.sub_type}
          </div>
        )}
      </div>
    );
  }

  renderFooter() {
    return <>{this.entityInformationDetail()}</>;
  }

  renderCardHeader(kind) {
    if (kind === 'video') return 'VIDEO';
    else if (kind === 'image') return 'IMAGE';
    return 'DOCUMENT';
  }

  renderCard() {
    let file = this.fileObject(),
      showOverlay = !isVideo(file) && this.isSmall(),
      containerClass = this.state.renameFormVisibile ? 'rename' : '';

    return (
      <div className={`${this.props.containerClasses} ${containerClass}`}>
        <div className={this.containerClasses()} onClick={this.onCardClick}>
          {this.props.closeRequired && (
            <OrthoIcon
              name='clear'
              defaultClass='os-close-link'
              onClick={this.afterClose}
            />
          )}
          {this.props.multiSelectMode && this.renderSelectBox()}
          <div className='attachment-card-image'>
            {this.renderContent(!showOverlay)}
            {false && isVideo(file) && (
              <div className='progress progress-space'>
                <div className='progress-bar'></div>
              </div>
            )}
            {showOverlay && <span className='overlay-span'></span>}
          </div>
          {this.renderTitle()}

          {this.footerRequired() && this.renderFooter()}
          {this.renderEnityRenameForm()}
        </div>
      </div>
    );
  }
}
AttachmentCard = withRouter(AttachmentCard);
AttachmentCard = connect(null, {
  openAttachmentPreview,
  openPlayerModal,
  viewObject,
})(AttachmentCard);
export default AttachmentCard;
