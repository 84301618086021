import store from 'app/store';
import { APP_NAME } from 'app/constants';

import { getWorkspaceData } from './generalHelper';
import { isFeaturePermitted } from 'app/utils/userHelper';

export const TAB_DETAILS_MAPPER = {
  explore: {
    content_addition_allowed: true,
    content_preview_listing: false,
    content_type: 'feed',
    kind: 'feed',
    search_required: false,
  },
  folders: {
    content_addition_allowed: false,
    content_preview_listing: false,
    content_type: 'listing',
    kind: 'folders',
    search_required: true,
  },
  carespaces: {
    content_addition_allowed: false,
    content_preview_listing: false,
    content_type: 'listing',
    kind: 'carespaces',
    search_required: true,
  },
  team_spaces: {
    content_addition_allowed: false,
    content_preview_listing: false,
    content_type: 'listing',
    kind: 'team_spaces',
    search_required: true,
  },
  members: {
    content_addition_allowed: false,
    content_preview_listing: false,
    content_type: 'listing',
    kind: 'members',
    search_required: true,
    name: 'Workspace Members',
  },
  partners: {
    content_addition_allowed: true,
    content_preview_listing: false,
    content_type: 'feed',
    kind: 'partners',
    search_required: false,
  },
  my_tasks: {},
};

const ROOT_TAB = {
  icon_name: 'case-person-feed',
  key: 'explore',
  path: 'patient/activity',
  name: 'Patient',
  tabDetails: TAB_DETAILS_MAPPER['explore'],
  countAttribute: 'unread_care_notifications_count',
};

const UNIFIED_TAB = [
  {
    icon_name: 'service-fill',
    key: 'unified',
    path: 'inbox/my/tasks',
    name: 'Home',
    countAttributes: [
      'unread_task_inbox_count',
      'unread_group_inbox_count',
      'unread_conversation_inbox_count',
      'unread_patient_inbox_count',
      'unread_partner_inbox_count',
      'unprocessed_patient_actionable_items_count',
      'unprocessed_partner_actionable_items_count',
    ],
  },
];

export const NAV_TABS = [
  ROOT_TAB,
  {
    icon_name: 'case-person',
    key: 'carespaces',
    name: 'PatientSpaces',
    tabDetails: TAB_DETAILS_MAPPER['carespaces'],
  },
  {
    icon_name: 'teams',
    key: 'team',
    path: 'team',
    name: 'Team',
    countAttributes: [
      'unread_conversations_count',
      'unread_team_spaces_notifications_count',
    ],
  },
  {
    path: 'tasks/me/activity',
    key: 'my_tasks',
    name: 'Tasks',
    icon_name: 'task',
    tabDetails: TAB_DETAILS_MAPPER['my_tasks'],
    countAttributes: [
      'unread_task_mentions_count',
      'unread_task_notifications_count',
    ],
  },
  {
    icon_name: 'folder',
    key: 'folders',
    name: 'Folders',
    tabDetails: TAB_DETAILS_MAPPER['folders'],
    countAttribute: 'unread_folder_spaces_notifications_count',
  },
  {
    icon_name: 'surgeryAppointment',
    key: 'partners',
    name: 'Partners',
    path: 'partners',
    tabDetails: TAB_DETAILS_MAPPER['partners'],
    countAttribute: '',
  },
];

function filteredNavTabs(tabs) {
  return tabs.filter((tab) =>
    isFeaturePermitted(`workspace_${tab.key}_enabled`, { default: true }),
  );
}

export function getWorkspaceNavTabs(space, user) {
  return { navTabs: filteredNavTabs(NAV_TABS), rootTab: ROOT_TAB };
}

export function getUnifiedNavTabs() {
  return UNIFIED_TAB;
}

export function navWorkspacesRequired() {
  let currentUser = store.getState().currentUser.graph;
  return !!currentUser && currentUser.accessible_workspaces_count > 0;
}

export function navActiveIdentifier() {
  return getWorkspaceData().identifier || APP_NAME;
}

export function navAllOptions() {
  let currentUser = store.getState().currentUser.graph;
  return [
    {
      id: APP_NAME,
      identifier: APP_NAME,
      name: 'Cloudberry Education',
      nice_url_with_host: process.env.REACT_APP_FRONTEND_APP_URL,
    },
  ].concat(currentUser.accessible_workspaces);
}

export function navActiveOption() {
  return (
    navAllOptions().find(
      (option) => option.identifier === navActiveIdentifier(),
    ) || { name: getWorkspaceData().name }
  );
}

export function navDropdownOptions() {
  return navAllOptions().filter(
    (option) => option.identifier !== navActiveIdentifier(),
  );
}

export function unreadCountPresentFor(
  option,
  { communityUnreadConversationCount, communityUnreadNotificationCount },
) {
  return (
    (option.identifier === APP_NAME &&
      (communityUnreadConversationCount > 0 ||
        communityUnreadNotificationCount > 0)) ||
    option.unread_conversations_count > 0 ||
    option.unread_notifications_count > 0
  );
}
