import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsLink from 'app/components/OsLink';

import { sendForgotPassword } from 'app/actions/authentication';

class ResetPasswordMailSent extends Component {
  sendForgotPassword = () => {
    this.props.sendForgotPassword(this.props.email);
  };

  getLinkText() {
    return this.props.isPreVerified ? 'Click here' : 'Forgot your password?';
  }

  render() {
    return (
      <OsLink
        className='a-link cursor-pointer forgot-pass-btn'
        text={this.getLinkText()}
        onClick={this.sendForgotPassword}
      />
    );
  }
}

ResetPasswordMailSent = connect(({}) => ({}), { sendForgotPassword })(
  ResetPasswordMailSent,
);
export default ResetPasswordMailSent;
