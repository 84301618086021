import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { flashSuccess, flashError } from 'app/actions/flashMessage';

class CustomFlashMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      },
    };
  }

  onHide() {
    this.props.flashSuccess('');
    this.props.flashError('');
  }

  componentDidMount() {
    this.setState((prev) => ({
      options: {
        ...prev.options,
        onClose: () => {
          this.onHide();
        },
      },
    }));
  }

  componentDidUpdate(prevProps) {
    const { options } = this.state;
    if (
      prevProps.flashMessage.message !== this.props.flashMessage.message &&
      this.props.flashMessage.message
    ) {
      toast.success(this.props.flashMessage.message, options);
    }

    if (
      prevProps.flashMessage.error !== this.props.flashMessage.error &&
      this.props.flashMessage.error
    )
      toast.error(this.props.flashMessage.error, options);
  }

  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}

export default CustomFlashMessage = connect(
  ({ flashMessage }) => ({ flashMessage }),
  { flashSuccess, flashError },
)(CustomFlashMessage);
