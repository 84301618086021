import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';

import CustomDropdown from './CustomDropdown';
import OsBtn from 'app/components/OsBtn';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class AttachmentDropdown extends Component {
  render() {
    let attachmentBtnClass = ' at-bt-dpdowm only-icon attachment-icon',
      dropdownInfo = { title: 'Attachment', icon: 'attach' },
      { tabSection: section, leftAligned } = this.props,
      commonProps = {
        extraClass: 'list-button',
        name: 'BtnIcon',
        associatedEntity: section,
      };
    return (
      <CustomDropdown
        osbtnClass={'px-8 '}
        className={attachmentBtnClass}
        name='space_sections'
        obj={section}
        onDropdownOpen={this.openSection}
        icon='attach'
        dropdownInfo={this.props.textRequired && dropdownInfo}
        leftAligned={leftAligned}>
        {!isCareWorkspaceView() && (
          <OsBtn
            {...commonProps}
            icon='folders'
            text='Add from my folders'
            onClick={() => this.props.assignObject('attach-to-folder')}
          />
        )}
        <OsBtn
          {...commonProps}
          icon='share-fill'
          text='Upload new files'
          onClick={() => this.props.assignObject('file')}
        />
      </CustomDropdown>
    );
  }
}

AttachmentDropdown.defaultProps = {
  leftAligned: false,
};

AttachmentDropdown = withApollo(AttachmentDropdown);
AttachmentDropdown = connect(
  ({ device }) => ({ device }),
  {},
)(AttachmentDropdown);
export default AttachmentDropdown;
