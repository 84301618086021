import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { connect } from 'react-redux';

import EventTracker from 'app/services/EventTracker';

import NavSearch from 'app/components/NavSearch';
import OsBtn from 'app/components/OsBtn';

import { setNavSearchClose } from 'app/actions/navSearch';
import { getNavSearchProps } from 'app/utils/Workspace/generalHelper';

class NavSearchModal extends Component {
  closeSearchModal = () => {
    EventTracker.track('Search - Close Clicked');
    this.props.setNavSearchClose();
  };

  render() {
    return (
      <Modal
        show={
          !this.props.device.mobileDevice &&
          this.props.navSearch.showSearchContainer
        }
        onHide={this.closeSearchModal}
        dialogClassName='modal-xl modal-search'
        animation={false}
        backdropClassName='modal-backdrop-custom'>
        <div className='modal-header'>
          <h4 className='card-title'>What are you looking for?</h4>

          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-search-modal rel-pos'
            icon='close'
            label='Close global search modal'
            onClick={this.closeSearchModal}
          />
        </div>
        <div className='modal-body'>
          <div className='cases-search-block'>
            <NavSearch {...getNavSearchProps(this.props.location.pathname)} />
          </div>
        </div>
      </Modal>
    );
  }
}
NavSearchModal = withRouter(NavSearchModal);
NavSearchModal = connect(({ navSearch, device }) => ({ navSearch, device }), {
  setNavSearchClose,
})(NavSearchModal);

export default NavSearchModal;
