import LocalStorageManager from 'app/services/LocalStorageManager';
import {
  ADD_UNIVERSAL_BANNER,
  REMOVE_UNIVERSAL_BANNER,
  UNIVERSAL_BANNER_HIDE,
  UPDATE_BANNER_VALUES,
} from 'app/actions/types';

import {
  ADDITIONAL_BANNERS_PREFIXES,
  UNIVERSAL_BANNERS,
} from 'app/components/UniversalBanner/constants';
import { isUndefined } from 'app/utils/osLodash';

const getClosedBanners = () => {
  const closedBanners = {};
  UNIVERSAL_BANNERS.forEach(({ bannerKey }) => {
    const closed = LocalStorageManager.get(`ub_${bannerKey}`);
    if (closed) {
      closedBanners[bannerKey] = closed;
    }
  });

  ADDITIONAL_BANNERS_PREFIXES.forEach((data) => {
    let values = LocalStorageManager.getValuesWithPrefix(`ub_${data.key}`);
    values.forEach((keyPair) => {
      let keyWithoutPrefix = keyPair.key.replace(/^ub_/, '');
      if (keyPair.value + data.threshold < Date.now()) {
        delete closedBanners[keyWithoutPrefix];
        LocalStorageManager.clear(keyPair.key);
      } else {
        closedBanners[keyWithoutPrefix] = keyPair.value;
      }
    });
  });
  return closedBanners;
};

const initState = {
  closed: getClosedBanners(),
  banners: UNIVERSAL_BANNERS,
};

const universalBanner = (state = initState, action) => {
  const { bannerKey } = action;

  switch (action.type) {
    case UNIVERSAL_BANNER_HIDE:
      if (!state.closed[bannerKey]) {
        LocalStorageManager.set(`ub_${bannerKey}`, Date.now());
      }

      return {
        ...state,
        closed: getClosedBanners(),
      };
    case UPDATE_BANNER_VALUES:
      if (!isUndefined(action.options.active) && !action.options.active) {
        LocalStorageManager.clear(`ub_${bannerKey}`);
      }

      let { banners } = state;
      banners = banners.map((banner) => {
        if (banner.bannerKey === action.bannerKey) {
          return { ...banner, ...action.options };
        } else {
          return banner;
        }
      });

      return {
        ...state,
        banners,
        closed: getClosedBanners(),
      };
    case ADD_UNIVERSAL_BANNER:
      let { banners: updateBanners } = state;
      action.options.prepend
        ? updateBanners.unshift(action.bannerProps)
        : updateBanners.push(action.bannerProps);
      return { ...state, banners: updateBanners };

    case REMOVE_UNIVERSAL_BANNER:
      let { banners: allBanners } = state;
      let updatedBannersList = allBanners.filter(
        (banner) => banner.bannerKey !== action.bannerKey,
      );
      return { ...state, banners: updatedBannersList };
    default:
      return state;
  }
};

export default universalBanner;
