import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import withAuthorize from 'app/components/HOC/withAuthorize';

import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import { openShareModal } from 'app/actions/shareModal';
import { isTouchSupported } from 'app/utils/deviceHelper';
import {
  getAuthorizeObject,
  isEntityPublished,
} from 'app/utils/entitiesHelper';
import { isEmpty } from 'app/utils/osLodash';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareModal: false,
      shareableObject: {},
    };
  }

  openShareModal = () => {
    let shareableProps = {
      show: this.state.showShareModal,
      closeModal: this.closeShareModal,
      type: this.props.type || this.props.object.__typename,
      showResponse: this.handleResponse,
      engagementNotReq: true,
      internalShareOnly: this.props.internalShareOnly,
    };
    if (this.isShareableObjectNotPresent()) {
      shareableProps.shareableId = this.props.shareableId;
      shareableProps.shareableType = this.props.shareableType;
      shareableProps.onCompleted = this.onCompleted;
    } else {
      shareableProps.object = this.props.object;
    }
    this.props.openShareModal(shareableProps);
    this.setState({ showShareModal: true });
  };

  closeShareModal = (e) => {
    e && e.stopPropagation();
    this.setState({ showShareModal: false });
  };

  handleOpenShareModal = () => {
    this.props.openUpgradeModalIfUnauthorized(this.openShareModal);
  };

  isShareableObjectNotPresent() {
    return (
      isEmpty(this.props.object) &&
      this.props.shareableId &&
      this.props.shareableType
    );
  }

  handleResponse = (data) => {
    this.setState({
      response: data.shareEntityInConversation,
      showResponse: true,
    });
  };

  onCompleted = (data) => {
    this.setState({ shareableObject: data.share_object });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  openShareIfAllowed = (e) => {
    this.stopPropagation(e);
    e.preventDefault();
    this.props.redirectToDefaultIfUnauthenticated(this.handleOpenShareModal);
  };

  renderShareView() {
    let text = this.props.labelNotRequired
        ? ''
        : this.props.label || (this.props.device.mobileDevice ? '' : 'Share'),
      commonClass = this.props.inDropdown
        ? ''
        : this.props.device.mobileDevice
        ? 'os-share-button'
        : 'os-share-button v-align-top',
      extraClass = `${commonClass} ${this.props.extraClass}`,
      iconName = !this.props.iconNotRequired ? this.props.iconName : '',
      name = this.props.name,
      commonProps = {
        text,
        'data-hover': !isTouchSupported(),
        onClick: this.openShareIfAllowed,
        associatedEntity: this.props.object,
        disabled: this.props.disabled,
      };

    switch (this.props.viewType) {
      case 'button':
        return (
          <OsBtn
            {...commonProps}
            name={name}
            icon={iconName}
            extraClass={extraClass}
          />
        );
      case 'link':
        return (
          <OsLink
            {...commonProps}
            className={this.props.extraClass}
            text={this.props.text}
          />
        );
      default:
        return null;
    }
  }

  render() {
    if (!isCareWorkspaceView() && isEntityPublished(this.props.object)) {
      return this.renderShareView();
    } else {
      return null;
    }
  }
}

Share = withAuthorize(Share);
Share = withAuthenticate(Share);
Share.defaultProps = {
  onClick: () => {},
  extraClass: '',
  name: 'BtnIcon',
  text: 'Share',
  viewType: 'button',
  iconName: 'share',
  abilityObject: getAuthorizeObject('internal_share', 'conversation'),
};
Share = connect(({ device, currentUser }) => ({ device, currentUser }), {
  openShareModal,
})(Share);
Share = withApollo(Share);
Share = withRouter(Share);
export default Share;
