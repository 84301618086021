import TaskListView from 'app/components/Task/TaskListView';
import { GroupLabelViewContext, TaskContext } from 'app/context/TaskContext';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';

function GroupLabelListView() {
  const { groupLabelResponse, fetchNextPageRecords } = useContext(
    GroupLabelViewContext,
  );
  const { labelGroup } = useContext(TaskContext);
  useEffect(() => {}, [groupLabelResponse]);
  return (
    <div>
      {!isEmpty(groupLabelResponse) &&
        labelGroup.sub_labels.map((label, index) => {
          return (
            <TaskListView
              idType={'User'}
              totalRecords={
                groupLabelResponse[label.name]?.data?.records?.total || 0
              }
              results={
                groupLabelResponse[label.name]?.data?.records?.results || []
              }
              listType={'GroupLabelTasks'}
              sectionTitle={`${label.name.toUpperCase()} (${
                groupLabelResponse[label.name]?.data?.records?.total || 0
              })`}
              details={label}
              isListOpen={index < 4 ? true : false}
              fetchNextPageRecords={fetchNextPageRecords}
            />
          );
        })}
    </div>
  );
}

export default GroupLabelListView;
