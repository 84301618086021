import React, { Component } from 'react';
import { connect } from 'react-redux';

import Comment from 'app/components/Comments/Comment';
import OsBtn from 'app/components/OsBtn';

class RepliesListing extends Component {
  renderReply = (comment) => {
    return (
      <Comment
        author={comment.author}
        comment={comment}
        commentableEntityOwner={this.props.commentableEntityOwner}
        commentableId={this.props.commentableId}
        commentableType={this.props.commentableType}
        editCommentId={this.props.editCommentId}
        entity={this.props.entity}
        hideReplyButton={this.props.hideReplyButton}
        key={comment.id}
        openParentReply={this.props.openParentReply}
        readOnly={this.props.readOnly}
        replyToCommentId={this.props.replyToCommentId}
        scrollToCommentId={this.props.scrollToCommentId}
        setEditCommentId={this.props.setEditCommentId}
        setScrollToCommentId={this.props.setScrollToCommentId}
        source={this.props.source}
        beforeId={this.props.beforeId}
        activityId={this.props.activityId}
      />
    );
  };

  isFeedView() {
    return this.props.source === 'feeds-view';
  }

  loadPreviousCTARequired() {
    return (
      !this.props.infiniteScroll &&
      !this.props.loading &&
      this.props.beforeHasMore() &&
      this.props.type === 'Replies'
    );
  }

  renderLoadPreviousCTA() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='chevron-up'
        extraClass='no-text prev-record-link with-border '
        onClick={this.props.loadMoreBefore}
        text=''
      />
    );
  }

  render() {
    let count = this.props.results.length,
      titleText = this.props.device.mobileDevice
        ? `Show ${count} previous replies`
        : 'Load all replies';
    if (!count) return null;

    return (
      <div className='comment-reply-section'>
        <div
          className={
            'd-flex  show-prev' +
            (this.isFeedView() ? ' visibility-hidden' : '')
          }>
          {this.loadPreviousCTARequired() && this.renderLoadPreviousCTA() && (
            <OsBtn
              name='BtnIcon'
              extraClass='px-16 btn-hide osbtn-tertiary'
              onClick={this.props.loadMoreBefore}
              text={titleText}
            />
          )}
        </div>
        <ul className='list-unstyled reply-ul'>
          <div>{this.props.results.map(this.renderReply)}</div>
        </ul>
      </div>
    );
  }
}

RepliesListing = connect(({ device }) => ({ device }), {})(RepliesListing);

export default RepliesListing;
