import Board from '@asseinfo/react-kanban';
import React from 'react';
import KanbanTaskCard from 'app/components/Kanban/KanbanTaskCard';

const KanbanBoard = ({
  allTaskOpen,
  kBoard,
  moveCardToAnotherColumn,
  setAllTaskOpen,
}) => {
  const onCardDragEnd = (card, source, destination) => {
    moveCardToAnotherColumn(source.fromColumnId, destination.toColumnId, card);
  };

  const renderCard = (card) => {
    return (
      <KanbanTaskCard
        card={card}
        allTaskOpen={allTaskOpen}
        setAllTaskOpen={setAllTaskOpen}
      />
    );
  };
  return (
    <>
      <Board
        allowAddCard={{ on: 'top' }}
        disableColumnDrag={true}
        onCardDragEnd={onCardDragEnd}
        renderCard={renderCard}>
        {kBoard}
      </Board>
    </>
  );
};

export default KanbanBoard;
