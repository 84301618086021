import OsBtn from 'app/components/OsBtn';
import React from 'react';

function Mark({ taskId, markAsDone }) {
  return (
    <OsBtn
      key='markasdone'
      name='BtnIcon'
      text='Mark as done'
      extraClass='list-button'
      icon='tick-fill'
      onClick={(e) => markAsDone(e, taskId)}
    />
  );
}

export default Mark;
