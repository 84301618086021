import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import CasePatientInfo from './CasePatientInfo';
import EntityDropdown from 'app/components/EntityDropdown';
import MarketingTags from 'app/components/Shared/MarketingTags';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import {
  resetCaseInfo,
  setCaseViewType,
  setCaseDetailValuesToDefault,
  setRightSideAppointment,
  setSelectedAppointment,
} from 'app/actions/caseDetailInfo';
import { setRightSectionVisibility } from 'app/actions/lectureView';

import {
  isCaseVideo,
  getAppointmentFilesWithImagesOrVideos,
  getInitialAppointment,
  getFinalAppointment,
} from 'app/components/CaseLeftSection/helpers';
import { filter, isEmpty, keys } from 'app/utils/osLodash';
import { entityUrl } from 'app/utils/entitiesHelper';

import { CASE_VIEW_OPTIONS } from './Constant';
import { VIDEO_TYPES } from 'app/components/CaseLeftSection/constants';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewingOptionsOpen: false,
    };
  }

  caseIcon() {
    return this.props.obj.status === 'Private' ? (
      <OrthoIcon name='locked' dataHoverNotRequired={true} />
    ) : (
      <OrthoIcon name='cases' dataHoverNotRequired={true} />
    );
  }

  renderCaseAuthorInfo(kase) {
    let { doctor } = kase;
    return (
      <>
        <span className='visible-mobile or'>|</span>
        <span className=' do cate fs-12'>
          <UserQualificationInfo
            titleRequired={true}
            user={doctor}
            sliceUpto={2}
            linkRequired={true}
            osLinkClass=''
            className='mx-50'
          />
        </span>
      </>
    );
  }

  renderMarketingTags() {
    const kase = this.props.obj;
    return (
      <MarketingTags tags={kase.marketing_tags} source={'case'} limit={1} />
    );
  }

  goBack = () => {
    if (this.state.viewingOptionsOpen) {
      this.toggleViewingOptions();
    } else if (
      !isEmpty(this.props.lectureView.entity) &&
      !this.props.lectureView.rightSectionVisible
    ) {
      this.props.setRightSectionVisibility(true);
    } else {
      this.props.goBack();
    }
  };

  backButtonNeeded() {
    return (
      this.state.viewingOptionsOpen ||
      isEmpty(this.props.lectureView.entity) ||
      !this.props.lectureView.rightSectionVisible
    );
  }

  renderCaseNiceIDAndPatientInfo() {
    let isPrivate = this.props.obj.status === 'Private',
      spaceBannerView = this.props.spaceBannerView,
      kase = this.props.obj;

    return (
      <>
        {this.backButtonNeeded() && (
          <div className='visible-mobile'>
            <OsBtn
              name='BtnIcon'
              onClick={this.goBack}
              extraClass='no-text back-btn web-view-btn'
              icon='chevron-left'
            />
          </div>
        )}
        <div className='col-lg-6 col-md-6 col-7 ps-0'>
          <div className='cs-user-info'>
            {!spaceBannerView && (
              <CasePatientInfo kase={kase} extraClass='d-inline os-text' />
            )}
            {!spaceBannerView && this.renderMarketingTags()}
            {spaceBannerView && <span>{this.props.spaceBannerTitle}</span>}
          </div>
          <div className='cs-id-user-name'>
            <OsLink
              to={entityUrl(kase)}
              className='cs-id white-link'
              text={`${isPrivate ? 'Private ' : ''}Case ${kase.nice_id}`}
            />
            {this.renderCaseAuthorInfo(kase)}
            {spaceBannerView && (
              <CasePatientInfo kase={kase} extraClass='d-inline os-text' />
            )}
            {spaceBannerView && this.renderMarketingTags()}
          </div>
        </div>
      </>
    );
  }

  isResetClicked(option) {
    return option === 'Reset';
  }

  setCaseView = (option) => {
    if (
      option !== 'SBS' &&
      !isEmpty(this.props.caseDetailInfo.selectedRightSideAppointment)
    ) {
      this.props.setRightSideAppointment(null);
    }
    if (VIDEO_TYPES.includes(option)) {
      this.props.setRightSideAppointment(null);
      this.props.setSelectedAppointment(null);
    } else if (
      this.isResetClicked(option) ||
      isEmpty(this.props.caseDetailInfo.selectedAppointment)
    ) {
      this.props.resetCaseInfo(
        getInitialAppointment(this.props.obj.appointments),
        getFinalAppointment(this.props.obj.appointments),
      );
    }
    !this.isResetClicked(option) && this.props.setCaseViewType(option);
  };

  getActiveClass(option) {
    if (option === this.props.caseDetailInfo.viewType) {
      return 'active';
    } else if (
      this.props.caseDetailInfo.viewType === 'GALLERY_CLASSIC' &&
      option === 'GALLERY_UPDATED'
    ) {
      return 'active';
    }
  }

  isScrubberModeSelected() {
    return isCaseVideo(this.props.caseDetailInfo.currentSlide);
  }

  isOptionDisabled(option) {
    let kase = this.props.obj;
    if (VIDEO_TYPES.includes(option)) {
      let caseVideosWithOption = filter(kase.videos, (video) => {
        return video.viewing_mode === option;
      });
      return !caseVideosWithOption.length;
    }
  }

  renderViewingOptions = (option) => {
    let activeClass = this.getActiveClass(option),
      extraClass = CASE_VIEW_OPTIONS[option]['extraClass'] || '';
    return (
      <OsBtn
        key={option}
        name='BtnIcon'
        icon={CASE_VIEW_OPTIONS[option]['icon']}
        disabled={this.isOptionDisabled(option)}
        text={CASE_VIEW_OPTIONS[option]['btnText']}
        tooltipText={CASE_VIEW_OPTIONS[option]['text']}
        extraClass={`no-text cs-header-btn ${activeClass} ${extraClass}`}
        onClick={this.setCaseView.bind(this, option)}
      />
    );
  };

  isInProgress() {
    let kase = this.props.obj;
    return kase.is_in_progress && kase.workflow_state !== 'user_draft';
  }

  toggleViewingOptions = () => {
    this.setState({ viewingOptionsOpen: !this.state.viewingOptionsOpen });
  };

  ownedByCurrentUser() {
    let kase = this.props.obj,
      doctor = kase.doctor,
      currentUser = this.props.currentUser;
    return +currentUser.graph.id === +doctor.id;
  }

  renderDropdown() {
    let kase = this.props.obj;
    if (!this.props.careSpaceView && !this.state.viewingOptionsOpen)
      return (
        <div className='header-dropdown-buttons cs-right-dropdown'>
          <EntityDropdown entity={kase} />
        </div>
      );
  }
  renderViewingOptionsOnMobile() {
    if (this.state.viewingOptionsOpen) {
      return <>{keys(CASE_VIEW_OPTIONS).map(this.renderViewingOptions)}</>;
    } else {
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='no-text cs-header-btn active'
          icon={CASE_VIEW_OPTIONS[this.props.caseDetailInfo.viewType]['icon']}
          onClick={this.toggleViewingOptions}
        />
      );
    }
  }

  render() {
    let kase = this.props.obj,
      extraClass = this.state.viewingOptionsOpen ? 'full-width-header ' : '',
      headerRowClass = 'row case-card-detail-header align-items-end ',
      appointmentsAvailable =
        getAppointmentFilesWithImagesOrVideos(kase.appointments).length > 0;

    extraClass += appointmentsAvailable ? ' cs-header-btn-group' : '';
    headerRowClass += this.backButtonNeeded() ? 'back-btn-available' : '';

    return (
      <div className='cs-detail-header col-12 px-0'>
        <div className={headerRowClass}>
          {!this.props.careSpaceView && this.renderCaseNiceIDAndPatientInfo()}
          <div className={`col-lg-6 col-md-6 pe-0 ${extraClass}`}>
            {this.props.isCaseUnlocked &&
              !this.isInProgress() &&
              appointmentsAvailable && (
                <>
                  {!this.props.device.mobileDevice &&
                    keys(CASE_VIEW_OPTIONS).map(this.renderViewingOptions)}
                  {this.props.device.mobileDevice &&
                    this.renderViewingOptionsOnMobile()}
                </>
              )}
            {this.props.device.portrait && this.renderDropdown()}
          </div>
        </div>
      </div>
    );
  }
}

Header = withRouter(Header);
Header = connect(
  ({ caseDetailInfo, currentUser, device, lectureView }) => ({
    caseDetailInfo,
    currentUser,
    device,
    lectureView,
  }),
  {
    resetCaseInfo,
    setCaseViewType,
    setCaseDetailValuesToDefault,
    setRightSectionVisibility,
    setRightSideAppointment,
    setSelectedAppointment,
  },
)(Header);
export default Header;
