import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import Loader from 'react-loaders';

import OsGrid from 'app/components/OsGrid';
import UserRowInWidget from 'app/components/Shared/UserRowInWidget';

const SPACE_RECENT_JOINEES_QUERY = queryLoader(
  'app/graphql/queries/Spaces/RecentJoinees.gql',
);

class RecentJoinees extends Component {
  renderContent() {
    if (this.props.data.loading) {
      return <Loader type='ball-triangle-path' active />;
    } else {
      return (
        <>
          <div className='widget-title'>NEW MEMBERS</div>
          {this.renderJoineesList()}
        </>
      );
    }
  }

  renderUserRow = (user) => {
    return <UserRowInWidget user={user} />;
  };

  getRecentJoinees() {
    let { recent_joinees } = this.props.data.board || { recent_joinees: [] };
    return recent_joinees;
  }

  renderJoineesList() {
    let recentJoinees = this.getRecentJoinees();
    return (
      <ul className='recent-joiners-list'>
        {recentJoinees.map(this.renderUserRow)}
      </ul>
    );
  }

  render() {
    let recentJoinees = this.getRecentJoinees();
    if (!this.props.data.loading && recentJoinees.length === 0) return null;
    return (
      <OsGrid identifier='DetailRightSidebar:UpcomingEventCol'>
        <div className='sidebar-container'>
          <article className='doctor-list'>{this.renderContent()}</article>
        </div>
      </OsGrid>
    );
  }
}

RecentJoinees = graphql(SPACE_RECENT_JOINEES_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      id: props.widgetSourceObject.nice_id,
    },
  }),
})(RecentJoinees);
export default RecentJoinees;
