import React, { PureComponent } from 'react';
import CaseTag from 'app/components/Shared/CaseTag';
import Tag from 'app/components/Shared/Tag';

const TAG_ICON_HASH = {
  info: 'verified',
};

class MarketingTags extends PureComponent {
  renderTag = (tag) => {
    if (['person', 'space'].includes(this.props.source)) {
      return (
        <Tag
          {...tag}
          key={tag.name}
          space={this.props.space}
          className={this.props.className}
        />
      );
    } else {
      return (
        <CaseTag
          {...tag}
          color={tag.name}
          key={tag.name}
          iconName={TAG_ICON_HASH[tag.color]}
        />
      );
    }
  };

  render() {
    const { tags, limit } = this.props;
    if (tags.length > 0) {
      return tags.slice(0, limit).map(this.renderTag);
    } else {
      return null;
    }
  }
}

MarketingTags.defaultProps = {
  tags: [],
  limit: 10,
  source: 'space',
  className: '',
};

export default MarketingTags;
