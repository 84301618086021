import React, { Component } from 'react';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { lowerCase } from 'app/utils/osLodash';

export const ORTHO_ICON_CLASS_MAPPER = {
  // A
  accept_filled: 'ifill-os-checkmark-round',
  account: 'ifill-os-account',
  add: 'ifill-os-fill-add',
  'add-emoji': 'ifill-os-add-emoji',
  'add-new-section': 'ifill-os-add-new-section',
  'add-new': 'ifill-os-section',
  'add-section': 'ifill-os-section',
  adjustment: 'ifill-os-adjust',
  additionallink: 'ifill-os-addition-link',
  all: 'ifill-os-text-format-list-bullet',
  amex: 'ifill-os-credit-card-amex',
  annotation: 'ifill-os-annotation',
  appliance: 'ifill-os-appliance',
  appointment: 'ifill-os-calendar',
  'appts-add': 'ifill-os-outline-appts-add',
  archive: 'ifill-os-archive',
  attach: 'ifill-os-fill-attachment',
  'attach-90': 'ifill-os-fill-attachment',
  attended_courses: 'ifill-os-fill-course',
  audio: 'ifill-os-audio',
  // B
  back: 'ifill-os-back',
  boards: 'ifill-os-space-default',
  boothSpace: 'ifill-os-space-default',
  // C
  calendar: 'ifill-os-calendar',
  'calendar-asc': 'ifill-os-calendar-asc',
  'calendar-desc': 'ifill-os-calendar-desc',
  'calendar-fill': 'ifill-os-calendar',
  'case-acb': 'ifill-os-case-abc',
  'case-abc': 'ifill-os-case-abc',
  'case-big': 'ifill-os-case-big',
  'case-dicom': 'ifill-os-case-records-dicom',
  'case-extraoral-face-smiling': 'ifill-os-extraoral-face-smiling',
  'case-extraoral-face-profilesmiling':
    'ifill-os-extraoral-face-profilesmiling',
  'case-extraoral-face-profilerelaxed':
    'ifill-os-extraoral-face-profilerelaxed',
  'case-extraoral-face-34-smiling': 'ifill-os-extraoral-face-34-smiling',
  'case-extraoral-face-relaxed': 'ifill-os-extraoral-face-relaxed',
  'case-extraoral-lipssmiling': 'ifill-os-extraoral-lipssmiling',
  'case-extraoral-lipsrepose': 'ifill-os-extraoral-lipsrepose',
  'case-extraoral-overjet-left': 'ifill-os-extraoral-overjet-left',
  'case-extraoral-overjet-right': 'ifill-os-extraoral-overjet-right',
  'case-gallery': 'ifill-os-case-gallery',
  'case-hard-tissue': 'ifill-os-case-records-hard-tissue',
  'case-heart': 'ifill-os-case-heart',
  'case-intraoral-anterior-upper': 'ifill-os-intraoral-anterior-upper',
  'case-intraoral-anterior-lower': 'ifill-os-intraoral-anterior-lower',
  'case-intraoral-anterior-open': 'ifill-os-intraoral-anterior-upper',
  'case-intraoral-anterior-occlusal': 'ifill-os-intraoral-anterior-occlusal',
  'case-intraoral-buccal-right': 'ifill-os-intraoral-buccal-right',
  'case-intraoral-buccal-left': 'ifill-os-intraoral-buccal-left',
  'case-intraoral-occlusal-lower': 'ifill-os-intraoral-occlusal-lower',
  'case-intraoral-occlusal-upper': 'ifill-os-intraoral-occlusal-upper',
  'case-mnd': 'ifill-os-case-mnd',
  'case-models': 'ifill-os-case-records-models',
  'case-models-2': 'ifill-os-case-records-models-2',
  'case-person': 'ifill-os-case-person',
  'case-person-feed': 'ifill-os-case-hospital',
  'case-sbs': 'ifill-os-case-sbs',
  'case-single': 'ifill-os-case-single',
  'case-soft-tissue': 'ifill-os-case-records-soft-tissue',
  'case-table': 'ifill-os-case-table',
  'case-x-ray': 'ifill-os-case-records-x-ray',
  case: 'ifill-os-fill-case-2-d',
  cases: 'ifill-os-fill-case-2-d',
  'cases-fill': 'ifill-os-fill-case-2-d',
  checkbox: 'ifill-os-checkmark-checked',
  'checkbox-default': 'ifill-os-checkmark-default-white',
  'checkmark-dot': 'ifill-os-checkmark-dot',
  'checkmark-radiobutton': 'ifill-os-checkmark-radiobutton',
  'checkmark-round': 'ifill-os-checkmark-round',
  'checkmark-round-default': 'ifill-os-checkmark-round-default',
  'checkmark-round-grey': 'ifill-os-checkmark-round-grey',
  'chevron-caret-up': 'ifill-os-chevron-caret-up',
  'chevron-caret-down': 'ifill-os-chevron-caret-down',
  'chevron-caret-right': 'ifill-os-chevron-caret-right',
  'chevron-down': 'ifill-os-chevron-down',
  'chevron-left': 'ifill-os-chevron-left',
  'chevron-right': 'ifill-os-chevron-right',
  'chevron-up': 'ifill-os-chevron-up',
  clear: 'ifill-os-clear',
  clinicSpace: 'ifill-os-workspace',
  clock: 'ifill-os-clock',
  close: 'ifill-os-close',
  collapse: 'ifill-os-collapse',
  'comment-fill': 'ifill-os-comments',
  comments: 'ifill-os-comments',
  companies: 'ifill-os-company',
  company: 'ifill-os-company',
  'company-fill': 'ifill-os-filled-company',
  companySpace: 'ifill-os-company',
  comparisons: 'ifill-os-comparison',
  connections: 'ifill-os-connection',
  contentSpace: 'ifill-os-spaces',
  course: 'ifill-os-fill-course',
  courseFill: 'ifill-os-fill-course',
  courseSpace: 'ifill-os-fill-course',
  courses: 'ifill-os-fill-course',
  cursor: 'ifill-os-text-cursor',
  // D
  delete: 'ifill-os-delete',
  doc: 'ifill-os-doc',
  doctors: 'ifill-os-doctors',
  'doctors-outline': 'ifill-os-outline-doctor',
  documents: 'ifill-os-document-default',
  documentsFile: 'ifill-os-document-doc',
  docFill: 'ifill-os-document-default',
  'document-empty': 'ifill-os-fill-document-empty',
  doubleTick: 'ifill-os-double-tick',
  download: 'ifill-os-fill-download',
  // E
  edit: 'ifill-os-edit',
  educationalSpace: 'ifill-os-fill-course',
  'empty-activity': 'ifill-os-empty-activity',
  'empty-note': 'ifill-os-empty-note',
  'equipment-fill': 'ifill-os-fill-adjust',
  error: 'ifill-os-fill-error',
  eventSpace: 'ifill-os-space-event',
  everyone: 'ifill-os-everyone',
  'expand-2': 'ifill-os-filled-expand',
  // F
  facebook: 'ifill-os-facebook',
  files: 'ifill-os-document-default',
  filter: 'ifill-os-filter',
  flag: 'ifill-os-report',
  folder: 'ifill-os-fill-folder',
  folders: 'ifill-os-fill-folder',
  'folder-bookmark': 'ifill-os-fill-folders-bookmark',
  follow: 'ifill-os-follow ',
  follower: 'ifill-os-followers',
  followers: 'ifill-os-follower',
  'full-screen': 'ifill-os-full-screen',
  // G
  'get-started': 'ifill-os-get-started',
  'get-started-light-mode': 'ifill-os-get-started-light-mode',
  goal: 'ifill-os-goal',
  // H
  hashtag: 'ifill-os-hashtag',
  'heart-new': 'ifill-os-like',
  hierarchy: 'ifill-os-hierarchy',
  'hide-password': 'ifill-os-hide-password',
  home: 'ifill-os-home',
  // I
  image: 'ifill-os-image',
  info: 'ifill-os-info',
  insight: 'ifill-os-insight-single',
  insights: 'ifill-os-insights',
  'insight-fill': 'ifill-os-insights',
  'insights-popular2': 'ifill-os-insights-popular',
  invite: 'ifill-os-invite',
  // K
  'kanban-add-task-icon': 'ifill-os-add-task-icon',
  // L
  label: 'ifill-os-label',
  learning_hub: 'ifill-os-hub',
  lecture: 'ifill-os-lecture',
  like: 'ifill-os-like',
  'link-copy': 'ifill-os-link-copy',
  'like-fill': 'ifill-os-like',
  link: 'ifill-os-linked',
  linked: 'ifill-os-linked',
  linkedin: 'ifill-os-linkedin',
  loading: 'ifill-os-appts-stays',
  locked: 'ifill-os-locked',
  'locked-fill': 'ifill-os-locked',
  location: 'ifill-os-location',
  'location-fill': 'ifill-os-location',
  logout: 'ifill-os-logout',
  'logout-fill': 'ifill-os-logout',
  loudspeaker: 'ifill-os-loudspeaker',
  // M
  mail: 'ifill-os-mail',
  'mailbox-empty': 'ifill-os-mailbox-empty',
  marker: 'ifill-os-location',
  'mastercard-credit-card': 'ifill-os-credit-card-visa',
  mic: 'ifill-os-mic',
  members: 'ifill-os-members',
  mentioned: 'ifill-os-mentioned',
  message: 'ifill-os-message',
  messages: 'ifill-os-messages',
  'mic-support': 'ifill-os-support',
  minus: 'ifill-os-minus',
  'minus-with-circle': 'ifill-os-appts-remove',
  more: 'ifill-os-more',
  mov: 'ifill-os-fill-document-mov',
  mute: 'ifill-os-mute ',
  // N
  'name-a-z': 'ifill-os-sorting-a-z',
  'name-z-a': 'ifill-os-sorting-z-a',
  navigate: 'ifill-os-navigate',
  'new-message': 'ifill-os-new-message',
  'no-notification': 'ifill-os-no-notification',
  notes: 'ifill-os-note',
  'notes-fill': 'ifill-os-note',
  notification: 'ifill-os-notification',

  // P
  'partner-doctor': 'ifill-os-partner-doctor',
  passwordHide: 'ifill-os-hide-password',
  passwordShow: 'ifill-os-views',
  payment: 'ifill-os-payment',
  pdf: 'ifill-os-fill-document-pdf',
  phone: 'ifill-os-phone',
  photo: 'ifill-os-photo',
  pin: 'ifill-os-pin',
  platinum: 'ifill-os-follow',
  play: 'ifill-os-play',
  plus: 'ifill-os-plus',
  popular: 'ifill-os-popular',
  posts: 'ifill-os-post',
  procedure: 'ifill-os-procedure',
  promocode: 'ifill-os-promo',

  // Q
  'qlark-logo': 'ifill-os-qlark-logo',
  questions: 'ifill-os-questions',
  // R
  'radiobutton-selected': 'ifill-os-checkmark-radiobutton',
  recent: 'ifill-os-recent',
  referral: 'ifill-os-referral',
  reload: 'ifill-os-update',
  replacement: 'ifill-os-uploading',
  'reply-fill-left': 'ifill-os-reply',
  'reply-fill-right': 'ifill-os-reply-left',
  'round-check': 'ifill-os-checkmark-round',
  'round-mail': 'ifill-os-mail',
  roundCheck: 'ifill-os-checkmark-round',
  roundCheckDefault: 'ifill-os-checkmark-round-default',
  // S
  search: 'ifill-os-search',
  secure: 'ifill-os-secure',
  send: 'ifill-os-send',
  selfie: 'ifill-os-selfie',
  'service-fill': 'ifill-os-service',
  sessionSpace: 'ifill-os-lecture',
  setting: 'ifill-os-settings',
  'setting-filled': 'ifill-os-settings',
  share: 'ifill-os-share',
  shareConnection: 'ifill-os-connection',
  'share-fill': 'ifill-os-share',
  'software-fill': 'ifill-os-software',
  'sort-down': 'ifill-os-chevron-caret-down',
  'sort-up': 'ifill-os-chevron-caret-up',
  spaces: 'ifill-os-spaces',
  'spaces-fill': 'ifill-os-fill-course',
  submission: 'ifill-os-submissions',
  support: 'ifill-os-question-support',
  surgeryAppointment: 'ifill-os-referral',
  // T
  target: 'ifill-os-target',
  task: 'ifill-os-tasks',
  three_d_cases: 'ifill-os-case-3-d',
  tick: 'ifill-os-tick-single',
  'tick-fill': 'ifill-os-filled-accept',
  'tiny-grid': 'ifill-os-tiny-grid',
  'tool-fill': 'ifill-os-tool',
  toolbox: 'ifill-os-tool',
  'toolbox-box': 'ifill-os-toolbox',
  tools: 'ifill-os-tool',
  twitter: 'ifill-os-twitter',
  teams: 'ifill-os-teams',
  'task-light': 'ifill-os-task-icon',
  // U
  unavailable: 'ifill-os-unavailable',
  undo: 'ifill-os-undo',
  unlinked: 'ifill-os-link-broken',
  'unlocked-fill': 'ifill-os-unlock',
  unmute: 'ifill-os-mute ',
  update: 'ifill-os-update',
  upload: 'ifill-os-uploading',
  user: 'ifill-os-fill-user',
  'user-fill': 'ifill-os-fill-user',
  'user-referral': 'ifill-os-referral',
  user_network: 'ifill-os-follower',
  users: 'ifill-os-fill-user',
  // V
  variation: 'ifill-os-variation',
  verified: 'ifill-os-verified',
  video: 'ifill-os-video',
  'video-filled': 'ifill-os-video-message',
  view: 'ifill-os-views',
  visa: 'ifill-os-credit-card-visa',
  // W
  workspacelogo: 'ifill-os-workspace',
  workspaceSpace: 'ifill-os-workspace',
  // X
  xls: 'ifill-os-fill-document-xls',
  // Z
  zip: 'ifill-os-fill-document-zip',
  'zoom-in': 'ifill-os-zoom-in',
  'zoom-out': 'ifill-os-zoom-out',
};

export default class OrthoIcon extends Component {
  onClick = (e) => {
    if (!this.props.disabled) this.props.onClick && this.props.onClick(e);
  };

  render() {
    const { disabled } = this.props;
    return (
      <i
        className={`${
          ORTHO_ICON_CLASS_MAPPER[lowerCase(this.props.name)] ||
          ORTHO_ICON_CLASS_MAPPER[this.props.name]
        } ${this.props.defaultClass} ${disabled ? 'disabled' : ''}`}
        onClick={this.onClick}
        data-hover={!this.props.dataHoverNotRequired && !isTouchSupported()}
        title={this.props.title}>
        {this.props.children}
      </i>
    );
  }
}

OrthoIcon.defaultProps = {
  defaultClass: 'header-list-icon',
  dataHoverNotRequired: false,
  disabled: false,
  title: '',
};
