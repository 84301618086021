import {
  COMMENT_SHARE_OPEN,
  COMMENT_SHARE_CHANGE_TYPE,
  COMMENT_SHARE_ASSIGN,
  COMMENT_SHARE_CONSUME,
} from 'app/actions/types';

export default function commentShare(
  state = {
    status: 'closed',
    type: null,
    source: 'Message',
    files: [],
    objects: [],
    cards: { files: [], objects: [] },
    options: {},
  },
  action,
) {
  switch (action.type) {
    case COMMENT_SHARE_ASSIGN:
      let status = action.skipClose ? 'open' : 'closed';
      return { ...state, status, cards: action.payload };

    case COMMENT_SHARE_CONSUME:
      return {
        ...state,
        status: 'closed',
        type: null,
        objects: [],
        files: [],
        options: {},
        cards: { files: [], objects: [] },
      };

    case COMMENT_SHARE_OPEN:
      let objects = action.objects || state.objects;
      return {
        ...state,
        status: 'open',
        type: action.payload,
        source: action.source,
        files: state.files.concat(action.files),
        objects: objects,
        options: action.options,
      };

    case COMMENT_SHARE_CHANGE_TYPE:
      return { ...state, type: action.payload };

    default:
      return state;
  }
}
