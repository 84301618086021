import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
  timeWithFormat,
} from 'app/utils/timeHelper';
import {
  FORMAT_START_WITH_DATE_DASH,
  FORMAT_START_WITH_MONTH_SLASH,
  TASK_CREATED_SUCCESSFULLY,
  TASK_DELETED_SUCCESSFULLY,
  TASK_MODAL_REPEAT_OPTIONS,
  TASK_UPDATED_SUCCESSFULLY,
} from 'app/components/Task/taskConstant';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { addSpanTagForHashtag } from 'app/utils/TiptapEditor/editorHelper';
import { flashSuccess } from 'app/actions/flashMessage';
import { openInfoModal } from 'app/actions/infoModal';
import { RESTRICTED_OBJECT_TYPES } from 'app/constants';
import { checkTaskIfOverdue } from 'app/utils/taskHelper';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { debounce } from 'app/utils/osLodash';
import { useLocation } from 'react-router';

const TASK_BY_ID = queryLoader('app/graphql/queries/Task/TaskById.gql');
const CREATE_TASK = queryLoader('app/graphql/mutations/Task/TaskCreate.gql');
const UPDATE_TASK = queryLoader('app/graphql/mutations/Task/TaskUpdate.gql');
const DELETE_TASK = queryLoader('app/graphql/mutations/Task/TaskDelete.gql');
const USER_TYPE = 'User';

const getLabelAttributes = (l) => ({ ...l, label: l.name, value: l.id });

const useTaskModal = (props) => {
  const { task_labels } = useContext(WorkspaceContext);
  const taskModal = useSelector((state) => state.taskModal);
  const currentUser = useSelector((state) => state.currentUser);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [debounceTitle, setDebounceTitle] = useState('');
  const [labelInputField, setLabelInputField] = useState('');
  const [taskLabels, setTaskLabels] = useState(
    task_labels.map((l) => getLabelAttributes(l)) || [],
  );
  const location = useLocation();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const getInitialFormState = () => ({
    entity: null,
    labels: [],
    assignee: currentUser.graph.id,
    dueDate: getDateFromStringWithGivenFormat(
      currentTimeWithUserTimeZone(),
      FORMAT_START_WITH_MONTH_SLASH,
    ),
    repeat: 'non_recursive',
  });
  const [initialValues, setInitialValues] = useState(getInitialFormState);
  // Mutation hooks

  const [addTaskMutation] = useMutation(CREATE_TASK);
  const [updateTaskMutation] = useMutation(UPDATE_TASK);
  const [deleteTaskMutation] = useMutation(DELETE_TASK);
  const username = currentUser.graph.name;

  // Methods to use mutation hooks
  const addTask = async (
    payload,
    updateCache,
    updateComponent,
    backendDataRequired,
  ) => {
    const response = await addTaskMutation({
      variables: payload,
    });
    const data = response.data;
    if (data.createTask.success) {
      closeModal();
      flashSuccess(TASK_CREATED_SUCCESSFULLY, false)(dispatch);
      if (backendDataRequired) updateComponent(data.createTask.entity);
      else {
        if (data.createTask.entity.recursive_type === 'non_recursive')
          updateCache(data.createTask.entity, 'create');
        else updateComponent(data.createTask.entity, 'AddTasks');
      }
    } else {
      dispatch(openInfoModal('general', 'error_message', {}));
    }
  };

  const updateTask = async (
    payload,
    updateComponent,
    updateCache,
    backendDataRequired,
    updateOverdueTasksOnEdit,
  ) => {
    const response = await updateTaskMutation({
      variables: payload,
    });
    const data = response.data;
    if (data.updateTask.success) {
      closeModal();
      if (typeof updateOverdueTasksOnEdit === 'function') {
        updateOverdueTasksOnEdit(data.updateTask.entity);
      }
      if (backendDataRequired) {
        updateComponent(data.updateTask.entity);
      } else {
        if (data.updateTask.entity.recursive_type === 'non_recursive') {
          updateCache(data.updateTask.entity, 'update', payload.type);
        } else {
          updateComponent(data.updateTask.entity);
        }
      }
      flashSuccess(TASK_UPDATED_SUCCESSFULLY, false)(dispatch);
    } else {
      dispatch(openInfoModal('general', 'error_message', {}));
    }
  };

  const deleteTask = async (
    payload,
    updateCache,
    updateComponent,
    backendDataRequired,
  ) => {
    const response = await deleteTaskMutation({
      variables: {
        id: payload.taskId,
      },
    });
    const data = response.data;
    if (data.deleteTask.success) {
      setShowDeleteModal(false);
      closeModal();
      if (backendDataRequired) updateComponent(payload.taskId);
      else {
        if (data.deleteTask.entity.recursive_type === 'non_recursive')
          updateCache(
            {
              ...data.deleteTask.entity,
              entity_labels: initialValues.labels.map((l) => ({
                label: l.entity,
              })),
            },
            'delete',
            payload.type,
          );
        else
          updateComponent(
            {
              ...data.deleteTask.entity,
              entity_labels: initialValues.labels.map((l) => ({
                label: l.entity,
              })),
            },
            payload.type,
          );
      }
      flashSuccess(TASK_DELETED_SUCCESSFULLY, false)(dispatch);
    } else {
      dispatch(openInfoModal('general', 'error_message', {}));
    }
  };

  const handleDebounceTitle = debounce((value) => {
    setDebounceTitle(value);
  }, 500);

  const handleBackdrop = (values) => {
    if (values.hasOwnProperty('title') || values.hasOwnProperty('description'))
      setBackdrop(false);
    else setBackdrop(true);
  };

  const closeModal = () => {
    setIsSubmitting(false);
    dispatch({ type: 'CLOSE_TASK_MODAL' });
    setInitialValues(() => getInitialFormState());
    setDebounceTitle('');
  };

  const isEditView = () => {
    return taskModal.taskId;
  };

  const showDeleteModalView = (viewModal) => {
    setShowDeleteModal((prevState) => viewModal);
  };

  const isPartnerSpace = () => {
    return !!location.pathname.includes('partners');
  };

  const onTaskDelete = () => {
    const payload = {
      taskId: taskModal.taskId,
      type: taskModal.taskType,
    };
    const { updateCache, updateComponent, backendDataRequired } = taskModal;

    deleteTask(payload, updateCache, updateComponent, backendDataRequired);
  };

  const isEntityNotAllowed = (entity) => {
    return !RESTRICTED_OBJECT_TYPES.includes(entity.__typename);
  };

  const getRepeatValue = (value) => {
    return TASK_MODAL_REPEAT_OPTIONS.find((option) => option.value === value);
  };

  const getLabelIdsFromLabelArray = (labels) => {
    return labels.map(
      (label) => parseInt(label.value, 10) || parseInt(label.label.id, 10),
    );
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const payload = {
      title: values.title,
      description: addSpanTagForHashtag(values?.description || ''),
      labelIds: getLabelIdsFromLabelArray(values.labels),
      dueDate:
        values.dueDate === 'Invalid Date'
          ? ''
          : getDateFromStringWithGivenFormat(
              values.dueDate,
              FORMAT_START_WITH_DATE_DASH,
            ),
      recursiveType: values.repeat,
      assigneeType: USER_TYPE,
      assigneeId: values.assignee,
    };
    if (taskModal.taskId) payload.id = taskModal.taskId;
    if (taskModal.taskType) payload.type = taskModal.taskType;
    if (values.entity && isEntityNotAllowed(values.entity)) {
      payload.entityId = values.entity.id.toString();
      payload.entityType = values.entity.__typename;
    }
    if (payload.entityType === 'Person') {
      payload.entityId = values.entity.care_space.id;
      payload.entityType = values.entity.care_space.__typename;
    } else if (payload.entityType === 'PartnerSpace') {
      payload.entityType = 'Board';
    }

    const {
      updateCache,
      updateComponent,
      backendDataRequired,
      updateOverdueTasksOnEdit,
    } = taskModal;
    if (taskModal.duplicateTask) {
      addTask(payload, updateCache, updateComponent, backendDataRequired);
      return 0;
    }
    if (taskModal.taskId) {
      updateTask(
        payload,
        updateComponent,
        updateCache,
        backendDataRequired,
        updateOverdueTasksOnEdit,
      );
    } else {
      addTask(payload, updateCache, updateComponent, backendDataRequired);
    }
  };

  const getTaskDetails = async (taskId) => {
    const response = await client.query({
      query: TASK_BY_ID,
      variables: {
        id: taskId,
      },
    });
    const taskDetails = response.data.task;
    setInitialValues((prevState) => ({
      ...prevState,
      title: taskDetails.title,
      description: taskDetails.description, // for Tiptap editor
      initialContent: taskDetails.description,
      entity: taskDetails.entity,
      labels: taskDetails.entity_labels.map((labelDetails) => ({
        entity: labelDetails.label,
        value: labelDetails.label.id,
      })),
      assignee: taskDetails.assignee.id,
      repeat: getRepeatValue(taskDetails.recursive_type).value,
      dueDate: timeWithFormat(
        taskDetails.due_date,
        FORMAT_START_WITH_MONTH_SLASH,
      ),
      createdAt: taskDetails.created_at,
      createdBy: taskDetails.created_by?.full_name,
      isDatePickerDisabled: !!taskDetails.parent_id,
    }));
    setDebounceTitle(taskDetails.title);
  };

  //Effect runs when the taskLabels changes or someone types in the input
  useEffect(() => {
    if (labelInputField !== '') {
      setTaskLabels(
        taskLabels.filter((l) => {
          const lowercaseName = l.name.toLowerCase();
          const lowercaseInput = labelInputField.toLowerCase();
          return lowercaseName.includes(lowercaseInput);
        }),
      );
    } else {
      setTaskLabels(task_labels.map(getLabelAttributes));
    }
  }, [labelInputField, task_labels]);

  // This effect runs whenever the taskModal is opened or closed.
  useEffect(() => {
    // If the taskModal is open, focus on the inputRef element.
    if (taskModal.open) {
      inputRef.current.focus();
    }
  }, [inputRef, taskModal.open]);

  // Effects when taskModal is opened from different locations.
  useEffect(() => {
    if (taskModal.entity) {
      setInitialValues((prevState) => ({
        ...prevState,
        entity: taskModal.entity,
      }));
    }
    if (taskModal.userId) {
      setInitialValues((prevState) => ({
        ...prevState,
        assignee: taskModal.userId,
      }));
    }
    if (taskModal.dueDate) {
      setInitialValues((prevState) => ({
        ...prevState,
        dueDate: taskModal.dueDate,
      }));
    }
    if (taskModal.taskId) {
      getTaskDetails(taskModal.taskId).then((r) => r);
    }
  }, [taskModal]);

  return {
    initialValues,
    taskModal,
    showDeleteModal,
    username,
    backdrop,
    isSubmitting,
    inputRef,
    debounceTitle,
    handleDebounceTitle,
    taskLabels,
    labelInputField,
    handleBackdrop,
    onTaskDelete,
    showDeleteModalView,
    isEditView,
    closeModal,
    onSubmit,
    isPartnerSpace,
    setLabelInputField,
  };
};

export default useTaskModal;
