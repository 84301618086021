import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';
import * as compose from 'lodash.flowright';

import CaseCardDetailView from 'app/components/CaseView/CaseCardDetailView';

import transGif from 'app/images/trans-gif-1440x744.gif';

const CASE_QUERY = queryLoader('app/graphql/Case.gql');

class SpaceHeaderCaseLectureView extends Component {
  render() {
    if (!this.props.data.case) {
      return <Loader type='ball-triangle-path' active />;
    }
    return (
      <div className='case-ratio'>
        <img src={transGif} className='transgif' />
        <CaseCardDetailView
          kase={this.props.data.case}
          spaceBannerView={true}
          spaceBannerTitle={this.props.spaceBannerTitle}
        />
      </div>
    );
  }
}

SpaceHeaderCaseLectureView = graphql(CASE_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      id: props.kase.nice_id,
    },
  }),
})(SpaceHeaderCaseLectureView);

export default SpaceHeaderCaseLectureView;
