import React from 'react';
import ReactDOM from 'react-dom/client';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';

import ReactGA from 'react-ga';

import store from 'app/store';
import client from 'app/graphql/client';
import Routes from 'app/routes';
import ErrorPageView from 'app/components/ErrorPageView';
import InitialRequestContainer from 'app/components/InitialRequestContainer';
import ScrollToTop from 'app/routes/Shared/ScrollToTop';

import 'bootstrap/dist/css/bootstrap.css';
import 'app/styles/app.scss';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_FRONTEND_KEY || 'test',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_NAME_WITH_ENVIRONMENT || 'development',
  appVersion: process.env.REACT_APP_VERSION,
});

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary FallbackComponent={ErrorPageView}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <InitialRequestContainer>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </InitialRequestContainer>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>,
);
