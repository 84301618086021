import { SET_FLAGGED_FEATURES, SET_SYSTEM_CONFIG } from 'app/actions/types';
import { arrayToHash } from 'app/utils/generalHelper';

const systemConfig = (
  state = {
    configs: {},
    requestServed: false,
    flaggedFeatures: [],
    flaggedFeaturesFetched: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_SYSTEM_CONFIG:
      return {
        ...state,
        configs: arrayToHash(action.configs, 'var'),
        requestServed: true,
      };
    case SET_FLAGGED_FEATURES:
      return {
        ...state,
        flaggedFeatures: action.flaggedFeatures,
        flaggedFeaturesFetched: true,
      };
    default:
      return state;
  }
};

export default systemConfig;
