import store from 'app/store';

import { PLURALIZE_HASH } from 'app/constants';
import { keys } from 'app/utils/osLodash';
import { getCurrentYear } from 'app/utils/timeHelper';

export function orderedDegrees(degreeList = []) {
  return degreeList;
}

export function activateScroll(body = false) {
  setTimeout(() => {
    var assignBackground;
    if (body) {
      assignBackground = document.body;
      assignBackground.className = assignBackground.className.replace(
        /no-scroll/g,
        '',
      );
      assignBackground.ontouchmove = null;
    } else {
      assignBackground = document.getElementsByClassName('os-main-nav');
      if (assignBackground) {
        assignBackground[0].parentElement.className =
          assignBackground[0].parentElement.className.replace(/no-scroll/g, '');
        assignBackground[0].parentElement.ontouchmove = null;
      }
    }
  }, 200);
}

export function deactivateScroll(body = false) {
  setTimeout(() => {
    var assignBackground;
    if (body) {
      assignBackground = document.body;
      assignBackground.className += ' no-scroll';
      assignBackground.ontouchmove = function (e) {
        e.preventDefault();
      };
    } else {
      if (assignBackground) {
        assignBackground = document.getElementsByClassName('os-main-nav');
        assignBackground[0].parentElement.className += ' no-scroll';
        assignBackground[0].parentElement.ontouchmove = function (e) {
          e.preventDefault();
        };
      }
    }
  }, 200);
}

export function activateBodyScroll(body = false) {
  setTimeout(() => {
    window.$('html')[0].classList.remove('page-overflow-hidden');
  }, 200);
}

export function deactivateBodyScroll(body = false) {
  setTimeout(() => {
    window.$('html')[0].classList.add('page-overflow-hidden');
  }, 200);
}

export function pluralize(number, data) {
  return [1, 0].includes(+number)
    ? data.singular
    : PLURALIZE_HASH[data.singular] || data.plural;
}

export function pluralizeWithNumber(number, data) {
  return `${number} ${pluralize(number, data)}`;
}

export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  // TODO: Check aove eslint warning

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function arrayToHash(collection = [], key = 'id') {
  let hash = {};
  collection.forEach((item) => (hash[item[key]] = item));
  return hash;
}

export function encodeData(data) {
  return keys(data)
    .map(function (key) {
      return [key, data[key]].map(encodeURIComponent).join('=');
    })
    .join('&');
}

export function generateRandomNumber(numberOfDigits = 5) {
  let baseNumber = Math.pow(10, numberOfDigits - 1);
  return Math.floor(Math.random() * baseNumber) + baseNumber;
}

export function isSettingEnabled(settingName) {
  let systemConfig = store.getState().systemConfig,
    config = systemConfig.configs[settingName],
    value = config && config.value.toLowerCase() === 'true';
  return !systemConfig.requestServed || value;
}

export function getRoundOffFloorValue(count, minValue) {
  let roundOffFloorValue = Math.floor(count / minValue) * minValue;
  if (roundOffFloorValue > 0) {
    return roundOffFloorValue === count
      ? roundOffFloorValue
      : `${roundOffFloorValue}+`;
  }
}

export function isObjectExist(records, obj) {
  return records?.some(
    (card) => card.id === obj.id && card.__typename === obj.__typename,
  );
}

export const getNextFiveYears = () => {
  let currentYear = getCurrentYear();
  let years = [];
  for (let i = 0; i < 5; i++) {
    let year = parseInt(currentYear) + i;
    let obj = { value: year.toString(), label: year.toString() };
    years.push(obj);
  }
  return years;
};

export const getURLEncodedString = (string) => {
  return encodeURIComponent(string);
};
