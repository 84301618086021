import { Command } from 'cmdk';
import Loader from 'react-loaders';
import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import React, { useCallback, useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { debounce } from 'app/utils/osLodash';
import { PATIENT_CARESPACE } from 'app/components/DnD/CmdMenu/constants';
import { useQuery } from '@apollo/client';

const GET_PATIENTS = queryLoader('app/graphql/queries/VisitorDirectory.gql');

const PatientsCmdMenu = (props) => {
  const { handleNavigation, inputValue } = props;
  const [textQuery, setTextQuery] = useState('');

  const { data, loading } = useQuery(GET_PATIENTS, {
    variables: {
      type: 'OnlyPatientsOfClinic',
      page: 0,
      perPage: 10,
      text_query: textQuery,
      sortQuery: 'latest_activity_desc',
    },
    fetchPolicy: 'cache-and-network',
  });

  const handlePatientNavigation = (niceId) => {
    handleNavigation(PATIENT_CARESPACE, { niceId });
  };

  const debounceTextQuery = useCallback(
    debounce((value) => setTextQuery(value), 1000),
    [],
  );

  useEffect(() => {
    debounceTextQuery(inputValue);
  }, [debounceTextQuery, inputValue]);

  return (
    <>
      <Command.List>
        {loading && (
          <Command.Loading>
            <Loader type='ball-triangle-path' active />
          </Command.Loading>
        )}
        {data?.records?.results.map((patient) => {
          return (
            <Command.Item
              className={'command-item'}
              key={patient.id}
              value={patient.id + patient.name}
              onSelect={() =>
                handlePatientNavigation(patient.care_space.nice_id)
              }>
              <DisplayOption
                avatarRequired={true}
                label={patient.name}
                member={patient}
              />
            </Command.Item>
          );
        })}
        <Command.Empty>
          No Patient was found with that {inputValue}. Please try again.
        </Command.Empty>
      </Command.List>
    </>
  );
};

export default PatientsCmdMenu;
