import { hexToRgb } from 'app/utils/colorHelper';
import React from 'react';

const LabelOption = ({ option }) => {
  return (
    <div className={'label-option-container'}>
      <span
        className={'label-option-color'}
        style={{
          backgroundColor: `${hexToRgb(option.color)}`,
        }}></span>
      <span className='result-text-data'>{option.name}</span>
    </div>
  );
};

export default LabelOption;
