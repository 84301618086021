import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Form } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';

import ProfileImage from 'app/components/Shared/ProfileImage';
import OsBtn from 'app/components/OsBtn';

import { updateCurrentUserGraph } from 'app/actions/authentication';
import { setActiveResourcePatient } from 'app/actions/activeResource';

const UPDATE_PROFILE_IMAGE_MUTATION = queryLoader(
  'app/graphql/UpdateProfileImage.gql',
);

// Modal to simply edit the avatar for the user
class ImageEdit extends Component {
  state = {
    avatarUrl: null,
    deleted: false,
  };

  isPatient() {
    return this.props.obj?.__typename === 'Person';
  }

  getPatientObject(entity) {
    let patient = this.props.activeResource.resource.owner.patient;
    if (patient.id == entity.id) {
      return {
        ...patient,
        avatar: entity.avatar,
        large_avatar: entity.large_avatar,
      };
    } else {
      let updatedPatientConnection = patient.person_connections.map(
        (connection) => {
          if (connection.related_person.id == entity.id) {
            return {
              ...connection,
              related_person: {
                ...connection.related_person,
                avatar: entity.avatar,
                large_avatar: entity.large_avatar,
              },
            };
          } else {
            return connection;
          }
        },
      );

      return {
        ...patient,
        person_connections: updatedPatientConnection,
      };
    }
  }

  updateUser = (attributes) => {
    this.setState({ requestInProgress: true });
    this.props
      .updateProfileImageMutation({
        variables: attributes,
      })
      .then(
        ({ data }) => {
          !this.isPatient() &&
            this.props.updateCurrentUserGraph({
              avatar: data.updateProfileImage.avatar,
              large_avatar: data.updateProfileImage.large_avatar,
            });
          this.props.closeParentModal();
          this.props.setActiveResourcePatient(
            this.getPatientObject(data.updateProfileImage.entity),
          );
          this.setState({ requestInProgress: false });
        },
        ({ data }) => {
          this.setState({ requestInProgress: false });
        },
      );
  };

  onSubmit = (values) => {
    let user = this.props.obj;
    this.updateUser({
      entity_type: user.__typename,
      entity_id: user.id,
      ...values,
    });
  };

  componentDidMount() {
    var status = this.props.obj.avatar ? 'cropping' : 'empty';
    this.setState({ status: status });
  }

  onDeletePhotoClick = () => {
    this.change('remove_avatar', true);
    this.setState({ deleted: true, status: 'empty' });
  };

  onCrop = (dimensions, originalDimensions) => {
    this.change('avatar_x', dimensions.x / originalDimensions.naturalWidth);
    this.change('avatar_y', dimensions.y / originalDimensions.naturalHeight);
    this.change('avatar_w', dimensions.width / originalDimensions.naturalWidth);
    this.change(
      'avatar_h',
      dimensions.height / originalDimensions.naturalHeight,
    );
  };

  changeRemoveAvatar = () => {
    this.change('remove_avatar', false);
  };

  render() {
    let user = this.props.obj,
      defaultImageUrl = this.state.avatarUrl || user.large_avatar,
      { requestInProgress } = this.state,
      text = requestInProgress ? 'Saving Changes' : 'Save Photo';

    if (this.state.deleted) defaultImageUrl = null;

    return (
      <div className='detail-user-form image-edit'>
        <Form
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          render={({ handleSubmit, form }) => {
            this.change = form.change;
            return (
              <form
                onSubmit={handleSubmit}
                ref={(form) => {
                  this.form = form;
                }}>
                <div className='modal-header'>
                  <h4 className='card-title'>
                    {this.isPatient()
                      ? 'Edit patient photo'
                      : 'Edit Profile Image'}
                  </h4>
                  <OsBtn
                    name='BtnIcon'
                    extraClass='no-text os-header-btn web-view-btn close-edit-image-btn'
                    icon='close'
                    label='Close user profile image modal'
                    onClick={() => this.props.closeParentModal()}
                  />
                </div>

                <div className='modal-body image-edit'>
                  <div className='row'>
                    <div className='col-md-12 modal-edit-image-buttons-block'>
                      <ProfileImage
                        onCrop={this.onCrop}
                        defaultImageUrl={defaultImageUrl}
                        obj={user}
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer d-flex justify-content-between'>
                  {defaultImageUrl && (
                    <OsBtn
                      name='BtnIcon'
                      extraClass='with-border px-16 '
                      onClick={this.onDeletePhotoClick}
                      text='Delete Photo'
                      associatedEntity={user}
                    />
                  )}
                  <OsBtn
                    name='BtnPrimary'
                    type='submit'
                    text={text}
                    disabledView={requestInProgress}
                    extraClass='next_btn web-view-btn ml-auto '
                    disabled={requestInProgress}
                    loaderRequired={requestInProgress}
                    htmlTag='button'
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

ImageEdit = graphql(UPDATE_PROFILE_IMAGE_MUTATION, {
  name: 'updateProfileImageMutation',
})(ImageEdit);
ImageEdit = connect(
  ({ currentUser, activeResource }) => ({ currentUser, activeResource }),
  {
    updateCurrentUserGraph,
    setActiveResourcePatient,
  },
)(ImageEdit);

export default ImageEdit;
