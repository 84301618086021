export const CANNOT_VIEW_COMMENTS_QUERIES = [
  'MyUnreadPatientComments',
  'MyReadPatientComments',
  'MyReadPartnerComments',
  'MyUnreadPartnerComments',
  'UnprocessedCareSpaceComments',
  'ProcessedCareSpaceComments',
  'UnprocessedPartnerSpaceComments',
  'ProcessedPartnerSpaceComments',
];
