import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { translate } from 'app/actions/flashMessage';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';

const GuestInfoView = (props) => {
  const { guestOwnerData } = props.workspace;
  if (isCareWorkspaceView()) {
    if (guestOwnerData) {
      return (
        <>
          <div className='auth-heading'>Committed to Better Care, Together</div>
          <div
            className='patient-wel-text'
            dangerouslySetInnerHTML={{
              __html: translate('WELCOME_TEXT_CARESPACE_WITH_OWNER', {
                clinic_name: guestOwnerData.name,
              }),
            }}
          />
        </>
      );
    } else {
      return translate('WELCOME_TEXT_CARESPACE');
    }
  } else if (isWorkspaceView()) {
    return translate('WELCOME_TEXT_WORKSPACE', {
      clinic_name: guestOwnerData?.name,
    });
  } else {
    return (
      <Icon
        name='emptyCareSpace'
        width='720'
        height='100%'
        style={{ maxWidth: '100%' }}
      />
    );
  }
};

const GuestInfoViewContainer = connect(({ workspace }) => ({ workspace }))(
  GuestInfoView,
);

export default GuestInfoViewContainer;
