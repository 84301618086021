import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
      fill='#FFC933'
    />
    <path
      d='M19 13c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1zM5 13c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1z'
      fill='#FF9270'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.21 6.377c.114-.205.344-.26.514-.124l2.963 2.376c.1.08.162.215.165.36a.482.482 0 0 1-.15.37L8.02 11.735c-.165.146-.397.104-.518-.095-.121-.198-.086-.477.078-.623l2.256-1.999-2.523-2.023c-.17-.137-.216-.414-.103-.618z'
      fill='#222226'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.087 6.278c.159-.287.48-.364.72-.173l2.962 2.376c.14.113.227.301.23.504a.675.675 0 0 1-.21.517l-2.681 2.376c-.231.205-.556.146-.725-.132-.17-.277-.121-.668.11-.872l2.085-1.849-2.347-1.882c-.238-.191-.303-.579-.144-.865zm.555.123c-.102-.082-.24-.048-.308.075-.068.122-.04.288.061.37L9.92 8.87a.19.19 0 0 1 .066.144.193.193 0 0 1-.06.148L7.667 11.16a.304.304 0 0 0-.047.374c.073.119.212.144.31.056l2.682-2.376a.29.29 0 0 0 .09-.221.284.284 0 0 0-.098-.216L7.642 6.4zM16.79 6.377c.113.204.067.481-.103.618l-2.524 2.023 2.257 2c.164.145.2.424.078.622-.121.199-.353.24-.518.095L13.3 9.359a.482.482 0 0 1-.15-.37.474.474 0 0 1 .164-.36l2.963-2.376c.17-.136.4-.081.514.124z'
      fill='#222226'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.194 6.105c.238-.191.56-.114.719.173.159.286.094.674-.144.865l-2.347 1.882 2.085 1.849c.231.204.28.595.11.872-.17.277-.494.337-.725.132l-2.681-2.376a.675.675 0 0 1-.21-.517.663.663 0 0 1 .23-.504l2.963-2.376zm.472.37c-.068-.122-.206-.155-.308-.074l-2.963 2.377a.284.284 0 0 0-.099.216.29.29 0 0 0 .09.221l2.682 2.376c.099.088.238.063.31-.056a.304.304 0 0 0-.046-.374l-2.256-2a.193.193 0 0 1-.06-.147.19.19 0 0 1 .065-.144l2.524-2.024c.102-.082.13-.248.061-.37z'
      fill='#222226'
    />
    <path
      d='M12 15.5c1.5 0 2.5.5 4 1.5.5-.5 2-2.5 2-4H6c0 1.57 1.5 3.5 2 4 1.5-1 2.5-1.5 4-1.5z'
      fill='#121214'
    />
    <path
      d='M16 17c-.773-1.233-2.262-2-4-2s-3.227.767-4 2c1.031 1.178 2.444 2 4 2s2.969-.822 4-2z'
      fill='#ED5A8B'
    />
    <path
      d='M11.91 13c.05.16.09.32.09.5 0-.18.04-.34.09-.5h-.18z'
      fill='#F7F7F8'
    />
    <path
      d='M9.09 13c-.05.16-.09.32-.09.5 0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5c0-.18-.04-.34-.09-.5H9.09zM12 13.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5c0-.18-.04-.34-.09-.5h-2.82c-.05.16-.09.32-.09.5z'
      fill='#F7F7F8'
    />
    <path d='M11.91 13h.18l-.1.56-.08-.56z' fill='#F7F7F8' />
  </svg>
);

export default SVG;
