import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import OsInputField from 'app/components/OsField/OsInputField';
import React, { useEffect, useState } from 'react';
import DisplayContentPortal from 'app/components/Shared/DisplayContentPortal';
import Loader from 'react-loaders';
import { translate } from 'app/actions/flashMessage';
import { hexToRgb } from 'app/utils/colorHelper';
import PersonOption from 'app/components/Shared/BootstrapDropdown/DisplayOption/PersonOption';
import LabelOption from 'app/components/Shared/BootstrapDropdown/DisplayOption/LabelOption';
import Label from 'app/components/LabelsModal/Label';

const BootstrapDropdown = (props) => {
  const {
    extraClassName,
    title,
    items,
    inputFieldRequired,
    inputFieldProps,
    async,
    itemKey,
    emptyRecordsMessage,
    onClick,
    optionsLoading,
    labelBackground,
    show,
    allowToCreateLabel,
    handleNewLabel,
    onToggle,
    emptyState,
    subLabelOptionsRequired,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [dropdownItems, setDropdownItems] = useState(items || []);

  const handleQueryChange = (event) => {
    let inputFieldValue = event?.target.value.trim() || '';

    // set this value to the textQuery state after 300ms
    if (async) {
      setDropdownItems([]);
    } else {
      setDropdownItems(
        items.filter((item) => {
          return (
            item[itemKey] &&
            item[itemKey].toLowerCase().includes(inputFieldValue?.toLowerCase())
          );
        }),
      );
    }

    if (props.onChange) {
      props.onChange(event, inputFieldValue);
    }

    setInputValue(inputFieldValue);
  };

  useEffect(() => {
    if (!async) {
      setDropdownItems(items);
    }
  }, [items, async]);

  const onLabelClick = (label, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onClick(e, label);
  };

  const displayOption = (item) => {
    switch (item.__typename) {
      case 'Template':
        return <DisplayContentPortal option={item} />;
      case 'Label':
        if (subLabelOptionsRequired) {
          return (
            <Label
              obj={item}
              onLabelClick={() => {}}
              onSubLabelClick={onLabelClick}
              position={'right'}
            />
          );
        } else {
          return <LabelOption option={item} />;
        }
      case 'Person':
        return <PersonOption option={item} itemKey={itemKey} />;
      default:
        return item[itemKey];
    }
  };

  const displayEmptyMessage = () => {
    if (dropdownItems.length === 0 && emptyState) {
      return emptyState;
    } else if (!optionsLoading) {
      if (inputValue.length && !dropdownItems.length) {
        return (
          <>
            {translate('RESULTS_NOT_FOUND', { searchInput: inputValue })}
            <br />
            {allowToCreateLabel && (
              <div className='d-flex pt-3'>
                <span
                  className='osbtn osbtn-primary m-auto mt-2 text-center'
                  onClick={() => handleNewLabel(inputValue)}>
                  Create Label {inputValue}
                </span>
              </div>
            )}
          </>
        );
      } else if (!dropdownItems.length) {
        return emptyRecordsMessage;
      } else {
        return '';
      }
    } else if (!dropdownItems.length) {
      return <Loader type='ball-triangle-path' active />;
    } else return null;
  };

  const handleOnToggle = (isOpen) => {
    if (!isOpen) {
      handleQueryChange(null);
    }

    if (onToggle) {
      onToggle(isOpen);
    }
  };

  const onOptionClick = (e, item) => {
    if (subLabelOptionsRequired) {
      if (
        item.__typename === 'Label' &&
        item.sub_labels &&
        item.sub_labels.length > 0
      ) {
      } else {
        onClick(e, item);
      }
    } else {
      onClick(e, item);
    }
  };

  return (
    <span className={`bootstrap-dropdown ${extraClassName}`}>
      <Dropdown show={show} onToggle={handleOnToggle}>
        <Dropdown.Toggle
          variant='success'
          id='dropdown-autoclose-true'
          style={{
            backgroundColor: labelBackground
              ? `${hexToRgb(`#${labelBackground}`)}`
              : 'unset',
          }}>
          {title}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {inputFieldRequired && (
            <>
              <OsInputField
                {...inputFieldProps}
                value={inputValue}
                onChange={handleQueryChange}
                autoFocus={true}
              />
              <Dropdown.Divider />
            </>
          )}
          {dropdownItems.map((item, index) => {
            return (
              <DropdownItem
                key={index}
                as={'div'}
                onClick={(e) => onOptionClick(e, item)}>
                {displayOption(item)}
              </DropdownItem>
            );
          })}
          <div className={'empty-records-message'}>{displayEmptyMessage()}</div>
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};

export default BootstrapDropdown;
