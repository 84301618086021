import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { difference, keys } from 'app/utils/osLodash';
import { closeEntityPreviewModal } from 'app/actions/entityPreviewModal';

import Loader from 'react-loaders';
import { Modal } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';

// import { isFeaturePermitted } from 'app/utils/userHelper';
// import { CASE_DETAIL_FEAUTURE_IDENTIFIER } from 'app/components/FeatureFlagging/constants';

let QUERY_MAPPER_WITH_ENTITY = {};
QUERY_MAPPER_WITH_ENTITY['CASE_DETAIL'] = queryLoader('app/graphql/Case.gql');

const VALID_QUERIES_TYPES = keys(QUERY_MAPPER_WITH_ENTITY);

export default class EntityPreviewModal extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key)
      this.props.closeEntityPreviewModal();
  }

  renderCaseCardDetailView() {
    return <div>Add Case component here</div>
  }

  renderEntityContent() {
    switch (this.props.entityPreviewModal.queryType) {
      case 'CASE_DETAIL':
        return this.renderCaseCardDetailView();
      default:
        return <div />;
    }
  }

  renderBody() {
    if (!this.props.entityPreviewModal.queryType) {
      return null;
    } else if (this.props.data.loading) {
      return <Loader type='ball-triangle-path' active />;
    } else {
      return this.renderEntityContent();
    }
  }

  render() {
    return (
      <Modal
        show={this.props.entityPreviewModal.open}
        onHide={this.props.closeEntityPreviewModal}
        dialogClassName='modal-comparison entity-preview-modal p-y-30'
        animation={false}
        backdropClassName='modal-backdrop-custom '>
        <Modal.Body>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn'
            icon='close'
            label='Close edit image modal'
            onClick={this.props.closeEntityPreviewModal}
          />
          {this.renderBody()}
        </Modal.Body>
      </Modal>
    );
  }
}

EntityPreviewModal = compose(
  ...keys(QUERY_MAPPER_WITH_ENTITY).map((type) =>
    graphql(QUERY_MAPPER_WITH_ENTITY[type], {
      skip: (props) =>
        !props.entityPreviewModal.entityId ||
        difference(VALID_QUERIES_TYPES, [type]).some(
          (type) => type === props.entityPreviewModal.queryType,
        ),
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {
          id: props.entityPreviewModal.entityId,
        },
      }),
    }),
  ),
)(EntityPreviewModal);

EntityPreviewModal = withRouter(EntityPreviewModal);
EntityPreviewModal = connect(
  ({ entityPreviewModal }) => ({ entityPreviewModal }),
  { closeEntityPreviewModal },
)(EntityPreviewModal);
