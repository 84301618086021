import Authenticate from 'app/services/Authenticate.js';

import {
  LOGIN_SUCCESS,
  REQUEST_SERVED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  UPDATE_CURRENT_USER_GRAPH,
  UPDATE_CURRENT_USER_TOKEN,
  REFRESH_CURRENT_USER,
  SIGNUP_REQUEST,
} from 'app/actions/types';
import Ability from 'app/services/Ability';

export default function currentUser(state = null, action) {
  // Rehydrate
  if (!state) {
    state = {};
    const ability = new Ability({ permissions: [] });
    const token = Authenticate.getUserAuthValueFromCookie();
    if (!token) {
      const token = Authenticate.getItem('token') || undefined,
        timeout = Authenticate.getItem('timeout'),
        validityDays = timeout
          ? (new Date(parseInt(timeout)) - new Date()) / (24 * 3600 * 1000)
          : null;

      Authenticate.setUserTokenInCookie({ token, validityDays });
    }
    state = { token, ability };
  }

  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload };

    case REQUEST_SERVED:
      return { ...state, requestServed: true };

    case UPDATE_CURRENT_USER_GRAPH:
      let graph = { ...state.graph, ...action.payload };
      let ability = new Ability(graph);
      return { ...state, ability, graph: graph };

    case SIGNUP_SUCCESS:
      return action.payload;

    case SIGNUP_FAILURE:
      return { error: true, errorMessage: action.errorMessage };

    case REFRESH_CURRENT_USER:
      return { ...state, error: null };
    case UPDATE_CURRENT_USER_TOKEN:
      return { ...state, token: action.payload };
    case SIGNUP_REQUEST:
      return { error: false, errorMessage: '' };
    default:
      return state;
  }
}
