import React, { Component } from 'react';

import Icon from 'app/components/Svg';
import RenderCards from 'app/components/Cards/RenderCards';

class ConnectionDataListing extends Component {
  renderHeading() {
    if (this.props.totalRecords > 0) {
      let heading =
        this.props.listType === 'PendingConnections'
          ? 'Pending Connections'
          : 'Accepted Connections';
      return (
        <h3 className='cards-row-title'>
          {heading}
          <span className='action-link'>&nbsp;({this.props.totalRecords})</span>
        </h3>
      );
    }
  }

  renderCards() {
    if (this.props.totalRecords > 0) {
      return (
        <RenderCards
          objects={this.props.results}
          orderRowWise={true}
          goToPage={true}
        />
      );
    } else if (!this.props.hideNoResultsText) {
      return (
        <div className='no-cases-block case'>
          <div className='no-cases-image-block'>
            <Icon name='noConnections' />
          </div>
          <div className='no-cases-message-block'>
            No connections were found
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this.renderHeading()}
        {this.renderCards()}
      </>
    );
  }
}

export default ConnectionDataListing;
