import { has } from 'app/utils/osLodash';

const ATTRIBUTES = ['additionalFilters', 'idQuery', 'searchQuery', 'sortQuery'];

export function getVariablesForTabAndSections(props) {
  const { additionalFilters, idQuery, searchQuery, sortQuery } = props;
  if (ATTRIBUTES.every((attr) => has(props, attr)))
    return {
      id_query: idQuery,
      searchQuery: searchQuery,
      sortQuery: sortQuery,
      additional_filters: JSON.stringify(additionalFilters || {}),
    };
  throw new Error('Missing required arguments');
}
