import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import ProfileImage from 'app/components/Shared/ProfileImage';
import EditProfileSecurityView from 'app/components/Shared/EditProfileSecurityView';

import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';

import {
  fieldRequired,
  nameFieldValidation,
  composeValidators,
} from 'app/utils/validationHelper.js';
import { entityUrl } from 'app/utils/entitiesHelper';
import { pick } from 'app/utils/osLodash';

const EDIT_USER_QUERY = queryLoader('app/graphql/queries/Users/Edit.gql');
const UPDATE_USER_MUTATION = queryLoader('app/graphql/UpdateUser.gql');
const focusOnErrors = createDecorator();
// Modal to edit the user profile
class EditUserProfile extends Component {
  state = {
    submitting: false,
  };

  componentDidMount() {
    addClassToHTML('hide-footer');
    addClassToHTML('hide-floater');
  }

  componentWillUnmount() {
    removeClassFromHTML('hide-footer');
    removeClassFromHTML('hide-floater');
  }

  updateUser = (attributes) => {
    this.setState({ submitting: true });
    return this.props
      .updateUserMutation({
        variables: attributes,
      })
      .then(({ data }) => {
        this.setState({ submitting: false });
        if (
          data.updateUser &&
          data.updateUser.errors &&
          data.updateUser.errors.length > 0
        ) {
          let errors = {};
          data.updateUser.errors.forEach(
            (error) => (errors[error.field] = error.message),
          );
          return data.updateUser.errors;
        } else {
          this.redirectToUserPage();
        }
      });
  };

  onSubmit = (values) => {
    return this.updateUser(values);
  };

  renderButtonText() {
    if (this.state.submitting) {
      return (
        <span>
          <i className='fa fa-spinner fa-spin' />
          &nbsp;Saving
        </span>
      );
    } else {
      return 'Save Changes';
    }
  }

  renderSubmitButton() {
    let textClass = this.state.submitSucceeded
      ? 'btn-text-saved'
      : 'btn-text-save';
    return (
      <OsBtn
        name='BtnPrimary'
        extraClass={textClass}
        htmlTag='button'
        text={this.renderButtonText()}
        disabled={this.state.submitting}
        associatedEntity={this.props.data.user}
        type='submit'
      />
    );
  }

  redirectToUserPage = () => {
    this.props.navigate(entityUrl(this.props.data.user));
  };

  onCrop = (dimensions, originalDimensions) => {
    this.change('avatar_x', dimensions.x / originalDimensions.naturalWidth);
    this.change('avatar_y', dimensions.y / originalDimensions.naturalHeight);
    this.change('avatar_w', dimensions.width / originalDimensions.naturalWidth);
    this.change(
      'avatar_h',
      dimensions.height / originalDimensions.naturalHeight,
    );
  };

  render() {
    if (this.props.currentUser.graph?.nice_id !== this.props.match.params.id)
      return (
        <Navigate
          to={entityUrl({
            __typename: 'User',
            nice_id: this.props.match.params.id,
          })}
        />
      );

    if (this.props.data.loading)
      return <Loader type='ball-triangle-path' active />;
    let { user } = this.props.data;
    return (
      <>
        <div className='container'>
          <span className='back-link profile-back-link'>
            <OsBtn
              name='BtnIcon'
              icon='chevron-left'
              extraClass='web-view-btn back-nav-btn'
              onClick={this.redirectToUserPage}>
              <span className='fs-12 back-text'>back to profile</span>
            </OsBtn>
          </span>
        </div>
        <div className='detail-user-form'>
          <Form
            onSubmit={this.onSubmit}
            initialValues={pick(
              this.props.data.user,
              'first_name',
              'last_name',
              'bio',
            )}
            enableReinitialize={true}
            keepDirtyOnReinitialize={true}
            decorators={[focusOnErrors]}
            render={({ handleSubmit, form }) => {
              this.change = form.change;
              return (
                <form onSubmit={handleSubmit}>
                  <div className='container'>
                    <div className='row my-edit-profile mx-0'>
                      <div className='col-md-6'>
                        <div className='my-edit-prf-title'>
                          Edit profile information
                        </div>
                        <div className='row'>
                          <div className='col-md-6 name-field'>
                            <Field
                              name='first_name'
                              type='text'
                              component={OsField}
                              osType='input'
                              label='First Name*'
                              validate={composeValidators(
                                fieldRequired,
                                nameFieldValidation,
                              )}
                            />
                          </div>
                          <div className='col-md-6'>
                            <Field
                              name='last_name'
                              type='text'
                              component={OsField}
                              osType='input'
                              label='Last Name*'
                              validate={composeValidators(
                                fieldRequired,
                                nameFieldValidation,
                              )}
                            />
                          </div>
                        </div>
                        <EditProfileSecurityView />
                      </div>

                      <div className='col-md-6 detail-user-form-bio'>
                        <ProfileImage
                          defaultImageUrl={
                            this.props.currentUser.graph.large_avatar
                          }
                          onCrop={this.onCrop}
                          obj={this.props.currentUser.graph}
                        />
                        <Field
                          name='bio'
                          osType='textarea'
                          component={OsField}
                          label='Your Bio*'
                          className={'black-base-scroller'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='my-edit-prf-footer'>
                    <div className='container'>
                      <div className='row'>
                        <OsBtn
                          name='BtnSecondary'
                          htmlTag='button'
                          text='Cancel'
                          onClick={this.redirectToUserPage}
                          associatedEntity={user}
                          extraClass='with-border'
                        />
                        {this.renderSubmitButton()}
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </>
    );
  }
}

EditUserProfile = graphql(UPDATE_USER_MUTATION, { name: 'updateUserMutation' })(
  EditUserProfile,
);
EditUserProfile = graphql(EDIT_USER_QUERY, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: props.match.params.id,
    },
  }),
})(EditUserProfile);

EditUserProfile = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(EditUserProfile);
EditUserProfile = withRouter(EditUserProfile);
export default EditUserProfile;
