import { PluginKey } from 'prosemirror-state';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';
import client from 'app/graphql/client';
import { ENABLED_TEMPLATE_TYPE } from 'app/components/Templates/constants';
import { loader as queryLoader } from 'graphql.macro';
import {
  BASE_SUGGESTION_OPTIONS,
  NODE_SUGGESTION_OPTIONS_LIST,
} from 'app/components/TipTapEditor/constant';
const GET_ALL_TEMPLATES = queryLoader('app/graphql/Template/GetTemplates.gql');

const CustomShortcutMenuSuggestion = {
  char: '/',
  pluginKey: new PluginKey('customShortcutMenu'),
  command: ({ editor, range, props }) => {
    const { item } = props;
    const selectedOption =
      editor.options.editorProps?.customShortcut?.selectionOption;
    if (
      selectedOption === null &&
      NODE_SUGGESTION_OPTIONS_LIST.includes(item.optionLabel)
    ) {
      editor.options.editorProps?.handleShortcutOptions(
        editor,
        range,
        item.optionLabel,
      );
      editor.commands.deleteRange(range);
      editor.commands.insertContent('/');
    } else if (selectedOption === BASE_SUGGESTION_OPTIONS.ADD_A_TEMPLATE) {
      editor.commands?.deleteRange(range);
      editor.options.editorProps?.handleTemplate(props.template);
      editor.options.editorProps?.handleShortcutOptions(editor, range, null);
    } else if (item.optionLabel === BASE_SUGGESTION_OPTIONS.RECORD_A_VIDEO) {
      editor.commands?.deleteRange(range);
      editor.options.editorProps?.handleShortcutOptions(
        editor,
        range,
        item.optionLabel,
      );
    } else if (item.optionLabel === BASE_SUGGESTION_OPTIONS.ADD_EMOJI) {
      editor.commands?.deleteRange(range);
      editor.options.editorProps?.handleShortcutOptions(
        editor,
        range,
        item.optionLabel,
      );
    } else if (item.optionLabel === BASE_SUGGESTION_OPTIONS.UPLOAD_A_FILE) {
      editor.commands?.deleteRange(range);
      editor.options.editorProps?.handleShortcutOptions(
        editor,
        range,
        item.optionLabel,
      );
    } else if (
      item.optionLabel === BASE_SUGGESTION_OPTIONS.SCHEDULE_AND_POST_LATER
    ) {
      editor.commands.deleteRange(range);
      editor.options.editorProps?.handleShortcutOptions(
        editor,
        range,
        item.optionLabel,
      );
    }
  },
  startOfTheLine: false,
  allowSpaces: false,
  items: async ({ query, editor }) => {
    const selectedOption =
      editor.options.editorProps?.customShortcut.selectionOption;
    const extension = editor.options.extensions.filter(
      (extension) => extension.name === 'customShortcutMenu',
    );
    const optionsList = extension[0].options.options;
    // Iterate over BASE_OPTIONS and return an array of objects with the optionLabel and __typename
    let BASE_OPTIONS = Object.keys(optionsList).map((option) => ({
      optionLabel: BASE_SUGGESTION_OPTIONS[option],
      __typename: 'option',
    }));

    if (selectedOption === BASE_SUGGESTION_OPTIONS.ADD_A_TEMPLATE) {
      const { data } = await client.query({
        query: GET_ALL_TEMPLATES,
        variables: {
          page: 0,
          per_page: 10,
          text_query: query,
          type: ENABLED_TEMPLATE_TYPE,
          sortQuery: 'name_asc',
        },
      });
      BASE_OPTIONS = data.records.results;
    }

    return {
      mention: BASE_OPTIONS,
      entity: null,
      selectedOption: selectedOption,
    };
  },
  ...suggestionConfig,
};

export default CustomShortcutMenuSuggestion;
