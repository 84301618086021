import React from 'react';
import { useParams } from 'react-router';

import TipOfTheDay from 'app/components/Shared/Tip';
import AttachmentsInDiscussion from 'app/components/Widgets/AttachmentsInDiscussion';
import { activeTeamNavTab } from 'app/components/TeamNavBar/teamNavHelper';

const TeamNavContent = ({ source, children, entity }) => {
  const params = useParams();
  function selectedFilter() {
    return params.filter;
  }

  const renderContent = () => {
    let tab = activeTeamNavTab(source, selectedFilter());

    switch (tab.content_type) {
      case 'TipOfTheDay':
        return (
          <div className='team-nav-widget'>
            <TipOfTheDay widgetSourceObject={entity} source={source} />
          </div>
        );
      case 'AttachmentsInDiscussion':
        return (
          <div className='team-nav-widget'>
            <AttachmentsInDiscussion
              widgetSourceObject={entity}
              source={source}
            />
          </div>
        );
      default:
        return children;
    }
  };
  return <>{renderContent()}</>;
};

export default TeamNavContent;
