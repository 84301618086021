import { connect } from 'react-redux';
import UniversalBanner from 'app/components/UniversalBanner';
import { hideUniversalBanner } from 'app/actions/universalBanner';

const getBannerToShow = (universalBanner) => {
  const { banners = [], closed = {} } = universalBanner;

  const now = Date.now();

  return banners.find(
    (banner) =>
      banner.active &&
      (!banner.start || banner.start < now) &&
      (!banner.expire || now < banner.expire) &&
      !closed[banner.bannerKey],
  );
};

export default connect(
  ({ universalBanner }) => {
    return {
      ...getBannerToShow(universalBanner),
    };
  },
  { hideUniversalBanner },
)(UniversalBanner);
