import React, { useRef } from 'react';
import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import OsBtn from 'app/components/OsBtn';
import { useSelector } from 'react-redux';

const TIPTAP_EXTENSION_REQUIRED = {
  dm: [
    'StarterKit',
    'Placeholder',
    'CustomSubmit',
    'Underline',
    'Link',
    'NewHashtag',
    'Patients',
    'Template',
  ],
  groups: [
    'StarterKit',
    'Placeholder',
    'CustomSubmit',
    'CustomMention',
    'Underline',
    'Link',
    'NewHashtag',
    'Patients',
    'Template',
  ],
};

const NewMessageEditor = (props) => {
  const {
    placeholder,
    handleSubmit,
    isSubmitting,
    updateContent,
    initialContent,
    getMentionGroups = () => {},
    addPatientEntity,
    handleTemplate,
    isSubmitButtonDisabled,
    onFieldFocus,
    isFocused,
    onDrop,
    entity,
    editorRef,
    editorType = 'dm',
  } = props;

  const submitRef = useRef(null);
  const device = useSelector((state) => state.device);

  return (
    <>
      <OsTiptapEditorField
        ref={editorRef}
        placeholder={placeholder}
        initialContent={initialContent}
        textType={'html'}
        dragAndDrop={false}
        onKeyDown={() => submitRef.current.onClick()}
        onFocus={onFieldFocus}
        extensions={TIPTAP_EXTENSION_REQUIRED[editorType]}
        updateContent={updateContent}
        autoFocus={true}
        isMenuBarRequired={!device.mobileDevice}
        manualDragAndDrop={true}
        isSubmitClicked={isSubmitting}
        handleTemplate={handleTemplate}
        handlePatient={addPatientEntity}
        entity={entity}
        mentionGroups={getMentionGroups()}
        onDrop={onDrop}
      />
      <OsBtn
        ref={submitRef}
        disabled={isSubmitButtonDisabled}
        type='button'
        name='BtnPrimary'
        extraClass='web-view-btn btn-save-info me-auto'
        text={isSubmitting ? 'Sending...' : 'Send Message'}
        onClick={handleSubmit}
        htmlTag='button'
      />
    </>
  );
};

export default NewMessageEditor;
