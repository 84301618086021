import { OPEN_SIGNUP_MODAL, CLOSE_SIGNUP_MODAL } from 'app/actions/types';

const signup = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_SIGNUP_MODAL:
      return { ...action, open: true };
    case CLOSE_SIGNUP_MODAL:
      return { open: false };
    default:
      return state;
  }
};

export default signup;
