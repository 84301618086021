import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import { composeValidators } from 'app/utils/validationHelper.js';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import {
  fieldRequired,
  passwordMustMatch,
  passwordMustMatchValidityRules,
} from 'app/utils/validationHelper.js';

import { translate } from 'app/actions/flashMessage';

class PasswordUpdate extends Component {
  onSubmit = (values) => {
    let variables = {
      password: values.password,
    };
    this.props.onSubmit(variables);
  };

  isSubmitDisabled() {
    let values = this.formValues;
    return (
      (values && (!values.password || !values.confirm_password)) ||
      this.props.disableCTA
    );
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        initialValues={{
          password: '',
          confirm_password: '',
        }}
        render={(props) => {
          this.formValues = props.values;
          return (
            <form name='passwordUpdate' onSubmit={props.handleSubmit}>
              <div className='mb-4 pb-2 pt-2 mt-1'>
                {translate('USER_ONBOARDING_UPDATE_PASSWORD_CONTENT_TEXT')}
              </div>
              <Field
                name='password'
                osType='password'
                formGroupExtraClass='show--password'
                component={OsField}
                label='Password*'
                validate={composeValidators(
                  fieldRequired,
                  passwordMustMatchValidityRules,
                )}
              />
              <Field
                name='confirm_password'
                osType='password'
                formGroupExtraClass='show--password'
                component={OsField}
                label='Confirm Password*'
                validate={composeValidators(fieldRequired, passwordMustMatch)}
              />
              <div className='text-right profile-action-btn'>
                <OsBtn
                  name='BtnSecondary'
                  onClick={this.props.goBack}
                  text='back'
                  disabled={this.props.disableCTA}
                  extraClass='with-border web-view-btn'
                />
                <OsBtn
                  name='BtnPrimary'
                  type='submit'
                  text='save password'
                  disabledView={this.isSubmitDisabled()}
                  extraClass='web-view-btn'
                  disabled={this.props.disableCTA}
                  loaderRequired={this.props.disableCTA}
                  htmlTag='button'
                />
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default PasswordUpdate;
