import React from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import RecordsPager from 'app/components/RecordsPager';

const SpaceMembersModal = (props) => {
  return (
    <Overlay
      show={props.show}
      target={props.target}
      placement='bottom'
      container={props.spaceModalRef}
      containerPadding={20}
      rootClose
      onHide={props.onHide}>
      <Popover id='popover-contained'>
        <Popover.Body>
          <RecordsPager
            queryType='SPACE_MEMBERS'
            recordId={props.entity.id}
            windowNotRequired={true}
            recordType='Board'
            displayType='GROUP_MEMBERS'
            loadingCards={false}
            space={props.entity}
            type='SpaceMemberCollaborators'
          />
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default SpaceMembersModal;
