import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field } from 'react-final-form';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import PoliciesView from 'app/components/PoliciesView';

class PolicyAcceptCheckbox extends Component {
  state = {
    showPolicyModal: false,
  };

  togglePolicyModal = () => {
    this.setState({ showPolicyModal: !this.state.showPolicyModal }, () => {
      this.props.togglePolicyModal &&
        this.props.togglePolicyModal(this.state.showPolicyModal);
    });
  };

  getLinkClass() {
    return `fs-12 ${this.props.linkClass || ''}`;
  }

  renderPublicInsightPolicyLink() {
    return (
      <a
        href='#privacy_policy'
        className={this.getLinkClass()}
        onClick={this.togglePolicyModal}>
        Terms of Use
      </a>
    );
  }

  renderPolicyLinks() {
    if (this.props.onlyPublicInsight) {
      return this.renderPublicInsightPolicyLink();
    } else {
      return (
        <>
          <OsLink
            href='#terms_of_use'
            className={this.getLinkClass()}
            onClick={this.togglePolicyModal}
            text='User Agreement'
          />
          &{' '}
          <OsLink
            href='#privacy_policy'
            className={this.getLinkClass()}
            onClick={this.togglePolicyModal}
            text='Privacy Policy'
          />
        </>
      );
    }
  }

  render() {
    return (
      <div className={'subs-term' + (this.props.extraClass || '')}>
        <div className='d-flex'>
          <Field
            component={OsField}
            osType='checkbox'
            name={this.props.name}
            label='I agree with '
            onChange={this.props.togglePoliciesAccepted}
            className='subs-term-label'
            formGroupExtraClass='mb-0'
            checked={this.props.checked}
            validate={this.props.validate}
            showError={false}
          />
          <span className='ms-1'>{this.renderPolicyLinks()}</span>
        </div>
        {this.state.showPolicyModal && (
          <PoliciesView
            role={this.props.role}
            hideFooter={true}
            onClose={this.togglePolicyModal}
            onlyPublicInsight={this.props.onlyPublicInsight}
          />
        )}
        {this.props.children}
      </div>
    );
  }
}

PolicyAcceptCheckbox = connect(({ currentUser }) => ({ currentUser }))(
  PolicyAcceptCheckbox,
);

export default PolicyAcceptCheckbox;
