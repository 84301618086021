import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M10.818 14.99c-.58.042-1.076.415-1.509.799-.232.202-.453.415-.727.543-.022.01-.032.021-.053.021l-.01-.5c.073-1.78.01-3.57-.159-5.35a.942.942 0 0 0-.021-.17v-.042c-.032-.203-.063-.394-.148-.576-.116-.234-.358-.447-.622-.447a.76.76 0 0 0-.58.277v-.01c.01-.395-.032-.821-.264-1.151-.221-.32-.685-.501-1.033-.299-.37.213-.422.725-.422 1.162v.9c0-.234-.262-.655-.517-.72-.255-.063-.475-.074-.654.065-.264.202-.317.575-.348.916-.021.288-.032.565-.042.852-.064-.255-.311-.574-.696-.543-.264.021-.496.202-.633.426-.148.224-.211.49-.264.746-.095.5-.116 1.002-.116 1.513l.021 5.934c0 1.47 1.656 2.664 3.692 2.664 1.107 0 2.088-.362 2.774-.916.495-.32.928-.735 1.191-1.268.39-.778.454-1.747 1.055-2.376.454-.48 1.234-.8 1.266-1.47.031-.576-.612-1.023-1.181-.98z'
      fill='#FFC933'
    />
    <path
      d='M13.182 14.99c.58.042 1.076.415 1.509.799.232.202.453.415.727.543.022.01.032.021.053.021l.01-.5c-.073-1.78-.01-3.57.159-5.35 0-.052.01-.116.021-.17v-.042c.032-.203.063-.394.148-.576.116-.234.358-.447.622-.447a.76.76 0 0 1 .58.277v-.01c-.01-.395.032-.821.264-1.151.221-.32.685-.501 1.033-.299.37.213.422.725.422 1.162v.9c0-.234.262-.655.517-.72.255-.063.475-.074.654.065.264.202.317.575.348.916.021.288.032.565.042.852.064-.255.311-.574.697-.543.263.021.495.202.632.426.148.224.211.49.264.746.095.5.116 1.002.116 1.513l-.021 5.934c0 1.47-1.656 2.664-3.692 2.664-1.107 0-2.088-.362-2.774-.916-.495-.32-.928-.735-1.191-1.268-.39-.778-.454-1.747-1.055-2.376-.454-.48-1.234-.8-1.266-1.47-.031-.576.612-1.023 1.181-.98z'
      fill='#FFC933'
    />
    <path
      d='M7.01 9.545c.08.39.14.78.16 1.18.03.39.03.78.05 1.17-.01.39-.01.78-.05 1.17-.02.39-.08.78-.16 1.17 0 .01-.01.01-.01.01-.01.01-.01 0-.01-.01-.01-1.56-.01-3.13 0-4.69 0 0 0-.01.01-.01l.01.01zM3.713 11.218c.08.4.145.775.175 1.185.04.4.04.81.07 1.21-.01.4 0 .81-.03 1.21-.02.4-.07.81-.15 1.21 0 .01-.01.01-.01.01-.01 0 .015.015.015.015-.04-1.62-.06-3.23-.07-4.84.015.036.005.026 0 0zM8.53 16.365c-.28.11-.54.25-.79.39-.24.15-.47.3-.65.5-.21.18-.35.41-.46.66-.13.24-.2.52-.27.82 0 .01-.01.01-.01.01-.01 0-.01-.01-.01-.01-.04-.59.13-1.23.57-1.65.44-.43 1.02-.66 1.62-.75.01 0 .01 0 .01.01s0 .01-.01.02zM5.283 10.13c.09.41.15.81.19 1.22.04.4.04.81.07 1.21-.01.41 0 .81-.03 1.22-.02.41-.07.82-.15 1.22 0 .01-.01.01-.01.01-.01 0-.01-.01-.01-.01-.04-1.62-.07-3.24-.08-4.87 0-.01.01-.01.01-.01.01 0 .01.01.01.01zM16.99 9.545c-.08.39-.14.78-.16 1.18-.03.39-.03.78-.05 1.17.01.39.01.78.05 1.17.02.39.08.78.16 1.17 0 .01.01.01.01.01.01.01.01 0 .01-.01.01-1.56.01-3.13 0-4.69 0 0 0-.01-.01-.01l-.01.01zM20.287 11.218c-.08.4-.145.775-.175 1.185-.04.4-.04.81-.07 1.21.01.4 0 .81.03 1.21.02.4.07.81.15 1.21 0 .01.01.01.01.01.01 0-.015.015-.015.015.04-1.62.06-3.23.07-4.84-.016.036-.005.026 0 0zM15.47 16.365c.28.11.54.25.79.39.24.15.47.3.65.5.21.18.35.41.46.66.13.24.2.52.27.82 0 .01.01.01.01.01.01 0 .01-.01.01-.01.04-.59-.13-1.23-.57-1.65-.44-.43-1.02-.66-1.62-.75-.01 0-.01 0-.01.01s0 .01.01.02zM18.717 10.13c-.09.41-.15.81-.19 1.22-.04.4-.04.81-.07 1.21.01.41 0 .81.03 1.22.02.41.07.82.15 1.22 0 .01.01.01.01.01.01 0 .01-.01.01-.01.04-1.62.07-3.24.08-4.87 0-.01-.01-.01-.01-.01-.01 0-.01.01-.01.01z'
      fill='#C2350A'
    />
    <path
      d='M9.997 7.867 8.863 4.084c-.027-.075-.12-.108-.2-.065L6.062 5.363c-.08.033-.08.13-.014.174l3.735 2.439c.094.065.24-.011.214-.109zM12.148 6.925l1.838-3.78c.048-.067-.032-.145-.144-.145h-3.675c-.112 0-.192.078-.16.145l1.838 3.78c.048.1.255.1.303 0zM14.188 7.977l3.754-2.222c.075-.044.075-.133.012-.177L15.65 4.023c-.063-.045-.164-.023-.189.055l-1.449 3.777c-.05.1.076.189.177.122z'
      fill='#4781F5'
    />
  </svg>
);

export default SVG;
