import { SET_ACTIVE_GROUP } from 'app/actions/types';

export default function activeGroup(state = { payload: {} }, action) {
  switch (action.type) {
    case SET_ACTIVE_GROUP:
      return { ...action.payload };
    default:
      return state;
  }
}
