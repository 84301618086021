import {
  ASSIGN_ADD_OBJECTS,
  ASSIGN_CONSUME,
  ASSIGN_MESSAGE,
  ASSIGN_TYPE,
  ASSIGN_CREATE,
  ASSIGN_REMOVE_OBJECTS,
  ASSIGN_OBJETS_AND_CREATE,
} from 'app/actions/types';

import { reject } from 'app/utils/osLodash';

export default function assign(
  state = {
    status: 'none',
    type: null,
    objects: [],
    message: '',
    create: null,
  },
  action,
) {
  let obs;
  switch (action.type) {
    case ASSIGN_ADD_OBJECTS:
      obs = action.payload.objects;

      const type = action.payload.type || state.type;
      if (state.status !== 'none' || type === 'ComparisonModal') {
        obs = obs.concat(state.objects);
      }
      const status = type === 'ComparisonModal' ? '' : 'overlay';

      return { ...state, type, status, objects: obs };
    case ASSIGN_CONSUME:
      return {
        ...state,
        status: 'none',
        message: action.payload,
        type: null,
        objects: [],
      };

    case ASSIGN_MESSAGE:
      return { ...state, status: 'message', message: action.payload };

    case ASSIGN_TYPE:
      return { ...state, type: action.payload };

    case ASSIGN_CREATE:
      return {
        ...state,
        create: action.payload.objectType,
        objects: action.payload.objects,
        status: action.payload.status,
        type: action.payload.objectType,
        id: action.payload.id,
      };

    case ASSIGN_REMOVE_OBJECTS:
      obs = action.payload.objects;
      obs = reject(state.objects, { id: action.payload.objects[0].id });

      return {
        ...state,
        type: action.payload.type,
        status: 'none',
        objects: obs,
      };

    case ASSIGN_OBJETS_AND_CREATE:
      obs = action.payload.objects;
      if (
        (state.status !== 'none' && action.payload.type !== 'CasePage') ||
        action.payload.type === 'ComparisonModal'
      ) {
        obs = obs.concat(state.objects);
      }

      return {
        ...state,
        status: 'none',
        create: action.payload.objectType,
        objects: obs,
        id: action.payload.id,
      };
    default:
      return state;
  }
}
