import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

import { openInfoModal } from 'app/actions/infoModal';
import { flashSuccess } from 'app/actions/flashMessage';
import { MARKED_INBOX_AS_READ } from 'app/components/Inbox/constant';
import StoreUpdater from 'app/services/StoreUpdater';
import { setWorkspaceData } from 'app/actions/workspace';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

const MARK_AS_READ = queryLoader('app/graphql/mutations/ReadInboxEntity.gql');
const MARK_ALL_AS_READ = queryLoader(
  'app/graphql/mutations/ReadAllInboxEntity.gql',
);

let QUERY_MAPPER = {};
QUERY_MAPPER['MyUnreadConversations'] = queryLoader(
  'app/graphql/ConversationsInboxListing.gql',
);
QUERY_MAPPER['MyUnreadTasks'] = queryLoader(
  'app/graphql/queries/Task/TaskInboxListing.gql',
);
QUERY_MAPPER['MyUnreadGroups'] = queryLoader(
  'app/graphql/queries/Spaces/GroupInboxListing.gql',
);

const useInbox = () => {
  const [markInboxEntityAsRead] = useMutation(MARK_AS_READ);
  const [markAllInboxEntityAsRead] = useMutation(MARK_ALL_AS_READ);
  const dispatch = useDispatch();

  const markAllAsRead = (entityIdentifier) => {
    let workspaceData = { ...getWorkspaceData() };
    markAllInboxEntityAsRead({
      variables: {
        entityIdentifier,
      },
    }).then(({ data }) => {
      if (!data.readAllInboxEntity.success) {
        dispatch(openInfoModal('general', 'error_message'));
      } else {
        workspaceData = { ...workspaceData, ...data.readAllInboxEntity.entity };
        dispatch(setWorkspaceData(workspaceData));
        flashSuccess(MARKED_INBOX_AS_READ, false)(dispatch);
      }
    });
  };

  const markAsRead = (data) => {
    let workspaceData = { ...getWorkspaceData() };
    const { entity, entityId, entityType, type, queryType } = data;
    const variables = {
      entityId,
      entityType,
      type,
      ...getQueryExtraVariables(queryType),
    };
    markInboxEntityAsRead({
      variables,
    }).then(({ data }) => {
      if (!data.readInboxEntity.success) {
        dispatch(openInfoModal('general', 'error_message'));
      } else {
        workspaceData = { ...workspaceData, ...data.readInboxEntity.entity };
        dispatch(setWorkspaceData(workspaceData));
        flashSuccess(MARKED_INBOX_AS_READ, false)(dispatch);
        updateInboxCache(entity, queryType);
      }
    });
  };

  const getCacheVariables = (queryType) => {
    let variables = {
      page: 0,
      perPage: 25,
      type: queryType,
    };
    switch (queryType) {
      case 'MyUnreadTasks':
        return {
          remove: variables,
          add: { ...variables, type: 'MyReadTasks' },
        };
      case 'MyUnreadGroups':
        return {
          remove: variables,
          add: { ...variables, type: 'MyReadGroups' },
        };
      case 'MyUnreadConversations':
        return {
          remove: variables,
          add: { ...variables, type: 'MyReadConversations' },
        };
      case 'MyUnreadPatientComments':
        return {
          remove: { ...variables, perPage: 10 },
          add: { ...variables, perPage: 10, type: 'MyReadPatientComments' },
        };
      case 'MyUnreadPartnerComments':
        return {
          remove: { ...variables, perPage: 10 },
          add: { ...variables, perPage: 10, type: 'MyReadPartnerComments' },
        };
      case 'TeamPatientUnreadComments':
        return {
          remove: { ...variables, perPage: 10 },
          add: { ...variables, perPage: 10, type: 'TeamPatientReadComments' },
        };
      default:
        break;
    }
  };

  const getQueryExtraVariables = (queryType) => {
    let queryVars = {
      includeTask: false,
      includeGroup: false,
      includeConversation: false,
      includePatient: false,
      includePartner: false,
      includeTeamPatient: false,
      includeTeamPartner: false,
    };
    switch (queryType) {
      case 'MyUnreadGroups':
        queryVars.includeGroup = true;
        break;
      case 'MyUnreadTasks':
        queryVars.includeTask = true;
        break;
      case 'MyUnreadPatientComments':
        queryVars.includePatient = true;
        break;
      case 'MyUnreadPartnerComments':
        queryVars.includePartner = true;
        break;
      case 'TeamPatientUnreadComments':
        queryVars.includeTeamPatient = true;
        break;
      case 'MyUnreadConversations':
        queryVars.includeConversation = true;
        break;
      default:
        break;
    }
    return queryVars;
  };

  const updateInboxCache = (record, queryType) => {
    switch (queryType) {
      case 'MyUnreadGroups':
      case 'MyUnreadTasks':
      case 'MyUnreadPatientComments':
      case 'MyUnreadPartnerComments':
      case 'TeamPatientUnreadComments':
      case 'MyUnreadConversations':
        StoreUpdater.removeRecord(record, getCacheVariables(queryType).remove, {
          query: QUERY_MAPPER[queryType],
        });
        StoreUpdater.addRecord(record, getCacheVariables(queryType).add, {
          query: QUERY_MAPPER[queryType],
          isReverse: true,
        });
        break;
      default:
        break;
    }
  };

  return {
    markAsRead,
    markAllAsRead,
  };
};

export default useInbox;
