import Loader from 'react-loaders';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { useDispatch } from 'react-redux';

import InfoModal from 'app/components/InfoModal';
import WorkspaceSelectionScreen from 'app/components/Shared/Workspaces/SelectionScreen';

import { openInfoModal } from 'app/actions/infoModal';

import useAcceptClinicInvitation from 'app/hooks/partners/useAcceptClinicInvitation';
import useClinicShareRequest from 'app/hooks/partners/useClinicShareRequest';

const SelectClinicView = () => {
  const { data, loading, token } = useClinicShareRequest();
  const { name, initiated_by_workspace, partner_space } = data
    ? data.clinic_share_request || {}
    : {};

  const onSuccessCallback = (payload) => {
    window.location.href = `/${payload.identifier}/partners/${partner_space.nice_id}`;
  };
  const onFailureCallback = () => {
    dispatch(
      openInfoModal('general', 'error_message', {
        contentInterpolations: {
          error: 'Something went wrong, Please contact support.',
        },
      }),
    );
  };
  const { acceptInvitation, requestInProgress } = useAcceptClinicInvitation({
    token,
    onSuccessCallback,
    onFailureCallback,
  });
  const dispatch = useDispatch();

  if (loading) return <Loader type='ball-triangle-path' active />;
  if (!data.clinic_share_request) return <Navigate to='/launch' />;

  const confirmSelection = ({ id, identifier, name }) => {
    dispatch(
      openInfoModal('space', 'select_clinic_for_partner_space_invite', {
        contentInterpolations: {
          clinicName: name,
          initiatedByClinic: initiated_by_workspace?.name || 'Clinic',
        },
        onSuccess: () => acceptInvitation({ workspaceId: id, identifier }),
        primaryCtaRequestInProgress: requestInProgress,
      }),
    );
  };

  const initiatedByClinicName = initiated_by_workspace?.name;
  const initiatedByClinicId = initiated_by_workspace?.id;
  const subHeading = `${initiatedByClinicName} has invited ${name} to collaborate. Please select a Clinic Workspace which will connect with their team.`;
  return (
    <>
      <WorkspaceSelectionScreen
        heading='Accept Partner Space Invitation'
        subHeading={subHeading}
        onConfirmSelection={confirmSelection}
        blacklistedIds={[initiatedByClinicId]}
      />
      <InfoModal />
    </>
  );
};

export default SelectClinicView;
