import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import LocalStorageManager from 'app/services/LocalStorageManager';

import { userDefaultPage } from 'app/utils/userRoleHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

class RedirectToDefault extends Component {
  getInviteToken() {
    return LocalStorageManager.get('space_invite_token');
  }

  getPath() {
    if (this.props.currentUser.graph) {
      if (this.props.guestUser.action && this.props.guestUser.metaInfo) {
        return this.props.guestUser.metaInfo.url;
      } else {
        return userDefaultPage(
          this.props.currentUser.graph,
          this.props.systemConfig,
        );
      }
    } else {
      let token = this.getInviteToken();
      return token ? '/signup' : '/login';
    }
  }

  render() {
    return (
      <Navigate
        push
        to={{
          pathname: withWorkspaceIdentifier(this.getPath()),
          state: { from: this.props.location },
        }}
        skip={true}
      />
    );
  }
}
RedirectToDefault = withRouter(RedirectToDefault);

RedirectToDefault = connect(({ currentUser, guestUser, systemConfig }) => ({
  currentUser,
  guestUser,
  systemConfig,
}))(RedirectToDefault);
export default RedirectToDefault;
