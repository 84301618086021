import { Field, Form } from 'react-final-form';
import { isTouchSupported } from 'app/utils/deviceHelper';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useSelector } from 'react-redux';
import { pick } from 'app/utils/osLodash';
import OsField from 'app/components/OsField';
import {
  DAYS_IN_WEEK,
  TIME_ZONES,
} from 'app/components/WorkspaceView/SettingView/constants';
import OsBtn from 'app/components/OsBtn';
import { fieldRequired } from 'app/utils/validationHelper';
import StateSelect from 'app/components/Shared/StateSelect';
import CountrySelect from 'app/components/Shared/CountrySelect';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import { useDispatch } from 'react-redux';
import { mergeWorkspaceData } from 'app/actions/workspace';
import TimePicker from 'app/components/WorkspaceView/SettingView/TimePicker';

const UPDATE_WORKSPACE_BASIC_CONFIGURATION_MUTATION = queryLoader(
  'app/graphql/mutations/Workspace/UpdateWorkSpaceBasicConfiguration.gql',
);
const WORKSPACE_SETTING_QUERY = queryLoader(
  'app/graphql/queries/Workspace/Configuration.gql',
);

const BasicInformation = () => {
  const dispatch = useDispatch();
  const [updateWorkSpaceBasicConfiguration] = useMutation(
    UPDATE_WORKSPACE_BASIC_CONFIGURATION_MUTATION,
  );
  const workspace = useSelector((state) => state.workspace);
  const [submitting, setSubmitting] = useState(false);
  const [timePickerState, setTimePickerState] = useState({
    startTime: false,
    endTime: false,
  });

  // Make a call to the backend to fetch the initial data.
  const { data: configurationData } = useQuery(WORKSPACE_SETTING_QUERY, {
    variables: { id: workspace.identifier },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (configurationData?.workspace)
      dispatch(mergeWorkspaceData({ ...configurationData.workspace }));
  }, [configurationData, dispatch]);

  const handleSubmit = (values) => {
    setSubmitting(true);
    updateWorkSpaceBasicConfiguration({
      variables: values,
    }).then(({ data }) => {
      setSubmitting(false);
      if (!data.updateWorkSpaceBasicConfiguration.success) {
        let errors = {};
        const apiErrors = data.updateWorkSpaceBasicConfiguration.errors || [];
        apiErrors.forEach((item) => (errors[item.field] = item.message));
        return errors;
      }
    });
  };

  const getInitialValues = () => {
    if (configurationData?.workspace) {
      return {
        ...pick(
          configurationData.workspace.owner.owner,
          'name',
          'country_code',
          'phone_number',
        ),
        ...pick(
          configurationData.workspace.workspace_setting,
          'start_time',
          'end_time',
          'profile_switcher_enabled',
          'working_days',
          'time_zone',
          'logo_small',
          'email_banner_image',
        ),
        ...pick(
          configurationData.workspace.owner.owner.address_object,
          'address_line_1',
          'address_line_2',
          'city',
          'zipcode',
        ),
        state_id:
          configurationData.workspace.owner.owner.address_object.state?.id,
        country_id:
          configurationData.workspace.owner.owner.address_object.country?.id ||
          '91',
      };
    }
    return {};
  };

  const getDescription = () => {
    return (
      <>
        <p className={'description'}>Drag and drop your images here</p>
        <p className={'description'}>
          or <span>browse your files</span>
        </p>
        <p className={'description-limit'}>
          The size of the image file must not exceed 8MB.
        </p>
      </>
    );
  };

  return (
    <>
      <SetPageTitle
        source='worksapce_settings'
        interpolations={'Information'}
      />

      <div className='container configuration-view mt-4 p-0'>
        <div className='row-fluid'>
          <div className='col-14 basic-info-section'>
            <Form
              initialValues={getInitialValues()}
              enableReinitialize={true}
              onSubmit={handleSubmit}>
              {({ handleSubmit, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={'form-container'}>
                      <div className={'form-heading-field'}>
                        <h2 className={'form-secondary-heading'}>
                          Basic Information
                        </h2>
                        <p className={'form-secondary-explanation'}>
                          Please make sure to verify this information and
                          provide us with high resolution images
                        </p>
                      </div>
                      <div className={'workspace-logo-field'}>
                        <Field
                          name={'logo_small'}
                          osType={'fileUpload'}
                          label={'Workspace logo'}
                          previewRequired={true}
                          src={values.logo_small}
                          required={true}
                          description={getDescription()}
                          onChange={(file) => {
                            form.change('logo_small', file);
                          }}
                          component={OsField}
                        />
                      </div>
                      {/* CLINIC ADDRESS FIELDS */}
                      <div className={'clinic-address-field'}>
                        <div className={'form-field field-1'}>
                          <Field
                            name={'name'}
                            placeholder={'e.g. Jane'}
                            label={'Clinic Name'}
                            required={true}
                            osType={'input'}
                            component={OsField}
                          />
                        </div>
                        <div className={'form-field field-2'}>
                          <Field
                            name={'address_line_1'}
                            placeholder={'e.g. 2525 Street Avenue S'}
                            osType={'input'}
                            label={'Street Address'}
                            validate={fieldRequired}
                            component={OsField}
                          />
                        </div>
                        <div className={'form-field field-3'}>
                          <Field
                            name={'address_line_2'}
                            placeholder={'e.g. Apt #302'}
                            osType={'input'}
                            validate={fieldRequired}
                            label={'Apt, suite etc.'}
                            component={OsField}
                          />
                        </div>
                        <div className={'form-field field-4'}>
                          <Field
                            name={'city'}
                            placeholder={'e.g. Detroit'}
                            osType={'input'}
                            label={'City'}
                            component={OsField}
                          />
                        </div>
                        <div className={'form-field field-5'}>
                          <Field
                            name={'state_id'}
                            placeholder={'Select'}
                            label={'State'}
                            labelClassName={'not-mandatory'}
                            value={values['state_id']}
                            countryId={values['country_id']}
                            className={'react-select-ortho-2 os-menu-drop'}
                            component={StateSelect}
                          />
                        </div>
                        <div className={'form-field field-8'}>
                          <Field
                            name={'country_id'}
                            placeholder={'Select'}
                            label={'Country'}
                            labelClassName={'not-mandatory'}
                            className={'react-select-ortho-2 os-menu-drop'}
                            component={CountrySelect}
                          />
                        </div>
                        <div className={'form-field field-6'}>
                          <Field
                            name={'zipcode'}
                            placeholder={'e.g. 55404'}
                            label={'Zip code'}
                            osType={'input'}
                            component={OsField}
                          />
                        </div>
                        <div className={'form-field field-7'}>
                          <label htmlFor=''>Cell phone number</label>
                          <div className={'phone-number-field'}>
                            <Field
                              name={'country_code'}
                              osType={'country_code_select'}
                              component={OsField}
                              onChange={(field, value) =>
                                form.change(field, value)
                              }
                            />
                            <Field
                              name={'phone_number'}
                              placeholder={'(000) 000-0000'}
                              osType={'input'}
                              component={OsField}
                            />
                          </div>
                        </div>
                      </div>

                      {/* PATIENT EMAILS FIELDS */}
                      {/* Hiding it as long as we don't have backend functionality. */}
                      <div className={'patient-emails-field'}>
                        <h2 className={'form-secondary-heading'}>
                          Patient Emails
                        </h2>
                        <div>
                          <Field
                            name={'email_banner'}
                            osType={'fileUpload'}
                            label={'Email Banner'}
                            previewRequired={true}
                            required={true}
                            src={values.email_banner_image}
                            onChange={(file) =>
                              form.change('email_banner', file)
                            }
                            description={getDescription()}
                            component={OsField}
                          />
                        </div>
                      </div>

                      {/* TIMEZONE AND WORKDAY SETTINGS */}
                      <div className={'timezone-workday-settings'}>
                        <h2 className={'form-secondary-heading'}>
                          Timezone and Workday Settings
                        </h2>
                        <p className={'form-secondary-explanation'}>
                          Clinic work hours helps us make sure that we present
                          the correct information to your patients
                        </p>
                        <div className={'timezone-workday-settings-field'}>
                          <div className={'form-field field-1'}>
                            <Field
                              name={'working_days'}
                              osType={'selectInput'}
                              label={'Working Days'}
                              className='react-select-ortho-2 os-menu-drop'
                              isMulti={true}
                              options={DAYS_IN_WEEK}
                              onChange={(field, value) =>
                                form.change(field, value)
                              }
                              placeholder={'Select'}
                              component={OsField}
                            />
                          </div>
                          <div className={'form-field field-2'}>
                            <Field
                              name={'start_time'}
                              label={'Working Hours - Start Time'}
                              placeholder={'e.g. 9:00 AM'}
                              validate={fieldRequired}
                              required={true}
                              osType={'input'}
                              component={OsField}
                              input={{
                                value: values.start_time,
                              }}
                              onClick={() => {
                                setTimePickerState((prev) => ({
                                  ...prev,
                                  startTime: !prev.startTime,
                                  endTime: false,
                                }));
                              }}
                            />
                            {timePickerState.startTime && (
                              <TimePicker
                                initialTime={
                                  configurationData?.workspace
                                    ?.workspace_setting.start_time || '00:00 AM'
                                }
                                hidePicker={() =>
                                  setTimePickerState((prev) => ({
                                    ...prev,
                                    startTime: false,
                                  }))
                                }
                                setStartTime={(time) => {
                                  form.change('start_time', time);
                                }}
                                submitBtnText='Set Time'
                              />
                            )}
                          </div>
                          <div className={'form-field field-3'}>
                            <Field
                              name={'end_time'}
                              label={'Working Hours - End Time'}
                              osType={'input'}
                              validate={fieldRequired}
                              required={true}
                              placeholder={'e.g. 9:00 PM'}
                              component={OsField}
                              input={{
                                value: values.end_time,
                              }}
                              onClick={() => {
                                setTimePickerState((prev) => ({
                                  ...prev,
                                  endTime: !prev.endTime,
                                  startTime: false,
                                }));
                              }}
                            />
                            {timePickerState.endTime && (
                              <TimePicker
                                initialTime={
                                  configurationData?.workspace
                                    ?.workspace_setting.end_time || '00:00 AM'
                                }
                                hidePicker={() =>
                                  setTimePickerState((prev) => ({
                                    ...prev,
                                    endTime: false,
                                  }))
                                }
                                setStartTime={(time) => {
                                  form.change('end_time', time);
                                }}
                                submitBtnText='Set Time'
                              />
                            )}
                          </div>
                          <div className={'form-field field-4'}>
                            <Field
                              name={'time_zone'}
                              label={'Clinic Timezone'}
                              osType={'selectInput'}
                              className='react-select-ortho-2 os-menu-drop'
                              placeholder={'Select'}
                              options={TIME_ZONES}
                              formValue={values['time_zone']}
                              onChange={(field, val) => form.change(field, val)}
                              component={OsField}
                            />
                          </div>
                        </div>
                      </div>
                      {/* PROFILE SWITCHER */}
                      <div className={'profile-switcher-field'}>
                        <h2 className={'form-secondary-heading'}>PIN Login</h2>
                        <p className={'form-secondary-explanation'}>
                          The PIN Login allows you to quickly change the user
                          when using a shared computer within the clinic.
                        </p>
                        <span className={'toggle-field'}>
                          NO{' '}
                          <span className='notify-prefer-value'>
                            <label
                              className='switch'
                              data-hover={!isTouchSupported()}>
                              <Field
                                name={'profile_switcher_enabled'}
                                component='input'
                                type='checkbox'
                              />
                              <span className='slider round'></span>
                              <span className='cs-slider-after'></span>
                            </label>
                          </span>{' '}
                          YES
                        </span>
                      </div>
                      {/* Submit Button */}
                      <div
                        className={'submit-button'}
                        style={{ marginTop: '24px' }}>
                        <OsBtn
                          extraClass='m-auto'
                          type='submit'
                          disabled={submitting}
                          name='BtnPrimary'
                          value={submitting ? 'Saving Changes' : 'Save Changes'}
                          onClick={() => {}}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicInformation;
