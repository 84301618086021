import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';
import scrollToElement from 'scroll-to-element';
import { CSSTransition } from 'react-transition-group';

import AbstractListing from 'app/components/BoardView/AbstractListing';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import RecordsView from 'app/components/RecordsView';
import TabSectionForm from './TabSectionForm';
import { Droppable } from 'app/components/DnD/Droppable';

import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';
import { setNewSectionId } from 'app/actions/spaceLinks';

import {
  ADD_RESTRICTED_ENTITIES,
  SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
} from 'app/components/BoardView/constants';
import { getIconClass } from 'app/utils/entitiesHelper';
import { map, isEmpty, isNull } from 'app/utils/osLodash';
import { getSpaceSectionOffset } from 'app/utils/domHelper';
import { isCompanySpace } from 'app/utils/spaceHelper';
import { isGuestUser } from 'app/utils/userHelper';
import { openInfoModal } from 'app/actions/infoModal';

import LocalStorageManager from 'app/services/LocalStorageManager';
import StoreUpdater from 'app/services/StoreUpdater';

const DELETE_TAB_SECTION_MUTATION = queryLoader(
  'app/graphql/mutations/DeleteTabSection.gql',
);
const UPDATE_TAB_SECTION_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateTabSection.gql',
);

class TabSection extends AbstractListing {
  constructor(props) {
    super(props);
    let sectionId = this.props.tabSection.id,
      storedState = LocalStorageManager.getTabSectionState(sectionId),
      isSectionOpen = this.props.searchQuery
        ? this.props.open
        : isNull(storedState)
        ? !!this.props.open
        : storedState;

    isNull(storedState) && this.setLocalStorage(isSectionOpen);
    this.state = {
      assigningObject: false,
      edit: false,
      newSectionFormOpen: false,
      open: isSectionOpen,
      reloadKey: false,
      reloadKeyChangeRequired: true,
      sortKey: 'name',
      sortOrder: 'asc',
    };
  }

  componentDidMount() {
    if (this.props.tabSection.id === this.props.spaceLinks.newSectionId) {
      this.openSection();
      this.props.setNewSectionId();
    }
  }

  componentDidUpdate(prevProps) {
    let section = this.props.tabSection,
      prevSection = prevProps.tabSection,
      newCardsDroppedInClosedSection =
        this.props.spaceLinks.requestInProgress &&
        prevSection &&
        section &&
        !section.open &&
        prevSection.links_count < section.links_count,
      requestCompleted =
        prevProps.spaceLinks.requestInProgress &&
        !this.props.spaceLinks.requestInProgress,
      isDestinationSection =
        section &&
        this.props.spaceLinks.linksDropOptions.destinationSectionId ===
          section.id,
      cardsDroppedInSection = requestCompleted && isDestinationSection,
      cardsCountChanged =
        section &&
        prevSection &&
        section.links_count !== prevSection.links_count,
      cardsDroppedInSectionWithOnePage =
        cardsDroppedInSection &&
        section.links_count <= SPACE_SECTION_LINKS_REQUIRED_PER_PAGE;

    if (
      cardsCountChanged &&
      this.props.spaceLinks.requestInProgress &&
      section.links_count > SPACE_SECTION_LINKS_REQUIRED_PER_PAGE
    )
      cardsCountChanged = false;
    if (cardsDroppedInSectionWithOnePage || cardsCountChanged)
      this.state.reloadKeyChangeRequired
        ? this.reloadRecords()
        : this.setState({ reloadKeyChangeRequired: true });

    newCardsDroppedInClosedSection && this.openSection();

    if (
      isEmpty(prevProps.spaceLinks.moveLinksToNewSectionOptions) &&
      !isEmpty(this.props.spaceLinks.moveLinksToNewSectionOptions) &&
      this.isMovingLinksToNewSection()
    )
      this.addNewSectionBelow();
  }

  isMovingLinksToNewSection() {
    return (
      this.props.spaceLinks.moveLinksToNewSectionOptions.sourceSectionId ===
      this.props.tabSection.id
    );
  }

  readTabSectionLinksQuery() {
    let variables = {
      idQuery: this.props.tabSection.id,
      searchQuery: this.props.searchQuery,
      additionalFilters: this.props.additional_filters,
      sortQuery: this.sortQuery(),
    };

    return StoreUpdater.readTabSectionLinksQuery(variables);
  }

  newSectionFormLinksData() {
    if (!this.isMovingLinksToNewSection()) return { results: [] };

    let { cards, autofillCardsUpto } =
      this.props.spaceLinks.moveLinksToNewSectionOptions;
    return { results: cards, autofillCardsUpto };
  }

  reloadRecords = () => {
    this.setState({ reloadKey: !this.state.reloadKey });
  };

  tabSectionOptions() {
    let tabSection = this.props.tabSection;

    return {
      additional_filters: this.props.additional_filters,
      canArrangeContent: this.props.canArrangeContent,
      cardsCustomMarginRequired: this.props.cardsCustomMarginRequired,
      cardsSelectionModeEnabled: this.props.cardsSelectionModeEnabled,
      onToggleCardSelection: this.props.onToggleCardSelection,
      trackScrolling: true,
      autofillCardsUpto: tabSection.links_count,
      idQuery: tabSection.id,
      perPage: SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
      board: this.props.entity,
      tabSectionId: tabSection.id,
      tabSection: tabSection,
      addNewLinkNotRequired: true,
      nonLinkableTab: this.props.nonLinkableTab,
      afterLinkRemove: this.reloadRecords,
      onRemoveLink: this.changeReloadRequired,
      reloadRecords: this.props.reloadRecords,
      searchQuery: this.props.searchQuery,
      type: 'TabSectionLinks',
      queryType: isGuestUser() ? 'GUEST_LISTING' : 'TAB_DETAILS',
      contentNotAccessible: this.props.contentNotAccessible,
      navTab: this.props.navTab,
      size: this.props.size,
      sortQuery: this.sortQuery(),
    };
  }

  sortQuery() {
    return this.props.sortQuery;
  }

  isTableView() {
    return this.props.size === 'list';
  }

  getAddNewTabsNotRequired() {
    return isCompanySpace(this.getEntity())
      ? ADD_RESTRICTED_ENTITIES.concat(['company'])
      : ADD_RESTRICTED_ENTITIES;
  }

  changeReloadRequired = () => {
    this.setState({ reloadKeyChangeRequired: false });
  };

  renderHeadingIfRequired() {
    if (this.isTableView() && this.props.tabSection.links_count > 0) {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='row table-card-row sorting-options-bar'>
              <div className='sorting-option name-block'>Name</div>
              <div className='sorting-option cd-date-added'>Date added</div>
              <div className='file-type'>File Type</div>
              <div className='sorting-option cd-last-used d-none'>
                Date Last Used
              </div>
              <div className='cd-list-select'></div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderTabSection() {
    let section = this.props.tabSection;
    return (
      <div>
        {this.renderHeadingIfRequired()}
        <RecordsView
          {...this.tabSectionOptions()}
          key={`section-${section.id}-${this.state.reloadKey}`}
        />
      </div>
    );
  }

  openEdit = () => {
    this.setState({ edit: true });
  };

  removeSpaceSectionLinks() {
    let variables = {
        idQuery: this.props.tabSection.id,
        searchQuery: this.props.searchQuery,
        sortQuery: this.props.sortQuery,
        additionalFilters: this.props.additional_filters,
      },
      ids = map(this.newSectionFormLinksData().results, 'id'),
      spaceLinks = this.readTabSectionLinksQuery().records.results.filter(
        (link) => ids.includes(link.linkable.id),
      );
    StoreUpdater.removeTabSectionLinkFromListing(spaceLinks, variables);
  }

  sectionFormOnClose = () => {
    this.setState({ newSectionFormOpen: false });
  };

  sectionFormOnSuccess = () => {
    this.isMovingLinksToNewSection() && this.removeSpaceSectionLinks();
    this.setState({ newSectionFormOpen: false });
  };

  spaceId() {
    return this.props.entity.nice_id;
  }

  getEntity() {
    return this.props.entity;
  }

  addNewSectionBelow = () => {
    this.setState({ newSectionFormOpen: true });
  };

  updatePosition = (up) => {
    let section = this.props.tabSection,
      movement = up ? 'up' : 'down';
    this.props
      .updateTabSectionMutation({
        variables: {
          id: section.id,
          movement: movement,
        },
      })
      .then(({ data }) => {
        if (data.updateTabSection.success) {
          StoreUpdater.updateTabSections(section, {
            movement,
            idQuery: this.props.navTabId,
            searchQuery: this.props.searchQuery,
            additionalFilters: this.props.additional_filters,
            sortQuery: this.sortQuery(),
          });
          this.scrollSectionIntoView(section.id);
        } else {
          console.log(data.updateTabSection.error);
        }
      });
  };

  scrollSectionIntoView(id) {
    setTimeout(() => {
      let ele = document.getElementById(`tab-section-${id}`);
      ele &&
        scrollToElement(ele, {
          ease: 'linear',
          offset: getSpaceSectionOffset(),
          duration: 500,
        });
    }, 500);
  }

  deleteSection = () => {
    let section = this.props.tabSection;
    this.props
      .deleteTabSectionMutation({
        variables: {
          id: section.id,
          searchQuery: this.props.searchQuery,
          sortQuery: this.sortQuery(),
          additional_filters: JSON.stringify(
            this.props.additional_filters || {},
          ),
        },
      })
      .then(({ data }) => {
        if (data.deleteTabSection.success) {
          StoreUpdater.updateTabSections(section, {
            remove: true,
            idQuery: this.props.navTabId,
            searchQuery: this.props.searchQuery,
            additionalFilters: this.props.additional_filters,
            sortQuery: this.sortQuery(),
          });
          this.scrollSectionIntoView(data.deleteTabSection.entity.id);
        } else {
          console.log(data.updateSpaceSection.error);
        }
      });
  };

  renderAddContent() {
    let section = this.props.tabSection,
      commonProps = {
        extraClass: 'list-button',
        name: 'BtnIcon',
        associatedEntity: section,
      };
    return (
      <CustomDropdown
        osbtnClass={'px-8 '}
        className='v-align-middle d-inline-block ms-1 space-section-dropdown position-relative'
        name='space_sections'
        obj={section}
        onDropdownOpen={this.openSection}
        icon='add'>
        <OsBtn
          {...commonProps}
          icon='folders'
          text='Add from my folders'
          onClick={this.addNewLink.bind(this, 'add-to-folder')}
        />
        <OsBtn
          {...commonProps}
          icon='share-fill'
          text='Upload new files'
          onClick={this.addNewLink.bind(this, 'file')}
        />
      </CustomDropdown>
    );
  }

  renderDropdown() {
    let section = this.props.tabSection,
      commonProps = {
        extraClass: 'list-button',
        name: 'BtnIcon',
        associatedEntity: section,
      };

    return (
      <CustomDropdown
        osbtnClass={'px-8 '}
        className='v-align-middle d-inline-block ms-1 space-section-dropdown position-relative'
        name='space_sections'
        obj={section}
        onDropdownOpen={this.openSection}>
        {section.deletable && (
          <OsBtn
            {...commonProps}
            icon='edit'
            text='Rename Section'
            onClick={this.openEdit}
          />
        )}
        <OsBtn
          {...commonProps}
          icon='add-new'
          text='Add section below'
          onClick={this.addNewSectionBelow}
        />
        {this.props.moveUpAllowed && (
          <OsBtn
            {...commonProps}
            icon='chevron-up'
            text='Move section up'
            onClick={this.updatePosition.bind(this, true)}
          />
        )}
        {this.props.moveDownAllowed && (
          <OsBtn
            {...commonProps}
            icon='chevron-down'
            text='Move section down'
            onClick={this.updatePosition.bind(this, false)}
          />
        )}
        {section.deletable && (
          <OsBtn
            {...commonProps}
            icon='delete'
            text='Delete section'
            onClick={this.deleteSection.bind(this, false)}
          />
        )}
      </CustomDropdown>
    );
  }

  openSection = () => {
    this.setState({ open: true }, this.setLocalStorage);
  };

  setLocalStorage = (value = this.state.open) => {
    LocalStorageManager.setTabSectionState(this.props.tabSection.id, value);
  };

  toggleSection = () => {
    this.setState({ open: !this.state.open }, this.setLocalStorage);
  };

  isAuthorOrEditor() {
    return this.props.entity.is_author_or_editor;
  }

  closeEdit = () => {
    this.setState({ edit: false });
  };

  renderNameOrForm() {
    let tabSection = this.props.tabSection;
    if (this.state.edit) {
      return (
        <TabSectionForm
          additionalFilters={this.props.additional_filters}
          section={tabSection}
          onSuccess={this.closeEdit}
          space={this.getEntity()}
          searchQuery={this.props.searchQuery}
          size={this.props.size}
          sortQuery={this.sortQuery()}
        />
      );
    } else {
      return (
        <>
          <span className='os-category-text text-truncate'>
            {tabSection.name}
          </span>
          <span className='total-count'>{`(${tabSection.links_count})`}</span>
        </>
      );
    }
  }

  droppableSectionRequired() {
    return this.props.canArrangeContent;
  }

  contentAddtionAllowed() {
    return (
      this.props.navTab.content_addition_allowed &&
      this.props.tabSection.content_addition_allowed
    );
  }

  renderDroppableSectionTitle() {
    // Note: 'temp' link id used to generate different droppable id from the New link card
    return (
      <Droppable
        id={'section:' + this.props.tabSection.id + ':link:temp'}
        disabled={this.props.spaceLinks.activeDragType !== 'link'}>
        {this.renderSectionTitle()}
      </Droppable>
    );
  }

  renderSectionTitle() {
    return (
      <article className='os-toggle-category d-flex full-width cursor-pointer align-items-center'>
        <div className='os-categogry-heading' onClick={this.toggleSection}>
          <span className='os-category-block'>
            <i
              className={`${getIconClass('more')} vertical-more`}
              {...this.props.dragListenerProps}></i>
            {this.renderNameOrForm()}
          </span>
        </div>
        {this.isAuthorOrEditor() &&
          this.contentAddtionAllowed() &&
          this.renderAddContent()}
        <aside
          className='aside-toggle-action ms-auto'
          onClick={this.toggleSection}>
          <OsBtn
            name='BtnIcon'
            icon='chevron-caret-down'
            extraClass='toggle-click-icon no-text px-8'
          />
        </aside>
        {this.isAuthorOrEditor() && this.renderDropdown()}
      </article>
    );
  }

  isSectionOpen() {
    return this.state.open;
  }

  sectionDomId() {
    let tabSectionId = this.props.tabSection.id;
    return `tab-section-${tabSectionId}`;
  }

  renderDropableContainer() {
    return (
      <Droppable
        id={'section:' + this.props.tabSection.id + ':link:temp'}
        disabled={this.props.spaceLinks.activeDragType !== 'link'}>
        {this.renderContent()}
      </Droppable>
    );
  }

  renderContent() {
    let isSectionOpen = this.isSectionOpen();
    return (
      <article
        className={
          'os-toogle-block' +
          (isSectionOpen ? ' active-toggle' : ' section-closed')
        }
        id={this.sectionDomId()}>
        {this.renderSectionTitle()}
        <CSSTransition
          in={isSectionOpen}
          classNames='replies-fade'
          timeout={{ enter: 500, exit: 500 }}
          unmountOnExit>
          {this.renderTabSection()}
        </CSSTransition>
      </article>
    );
  }

  render() {
    let newSectionFormLinksData = this.newSectionFormLinksData();

    return (
      <>
        {this.droppableSectionRequired()
          ? this.renderDropableContainer()
          : this.renderContent()}
        {this.state.newSectionFormOpen && (
          <TabSectionForm
            onSuccess={this.sectionFormOnSuccess}
            onClose={this.sectionFormOnClose}
            upperSection={this.props.tabSection}
            spaceId={this.spaceId()}
            newSectionFormLinks={newSectionFormLinksData.results}
            autofillCardsUpto={newSectionFormLinksData.autofillCardsUpto}
            additionalFilters={this.props.additional_filters}
            startFromLinkId={
              this.props.spaceLinks.moveLinksToNewSectionOptions.startFromLinkId
            }
            isMovingLinksToNewSection={this.isMovingLinksToNewSection()}
            size={this.props.size}
            space={this.props.entity}
            searchQuery={this.props.searchQuery}
            tabId={this.props.navTabId}
            sortQuery={this.sortQuery()}
          />
        )}
      </>
    );
  }
}

TabSection = compose(
  graphql(DELETE_TAB_SECTION_MUTATION, { name: 'deleteTabSectionMutation' }),
  graphql(UPDATE_TAB_SECTION_MUTATION, { name: 'updateTabSectionMutation' }),
)(TabSection);
TabSection = connect(
  ({ currentUser, commentShare, spaceLinks }) => ({
    currentUser,
    commentShare,
    spaceLinks,
  }),
  { commentShareOpen, commentShareConsume, openInfoModal, setNewSectionId },
)(TabSection);
TabSection = withRouter(TabSection);
export default TabSection;
