import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { sidebarCareNotificationAvailable } from 'app/actions/allSidebarCareNotifications';
import TeamSpaceActivityList from 'app/components/SideBarTeamSpaceList/SpaceRecentActivity';
import { isUnifiedEnabled } from 'app/utils/Workspace/generalHelper';

const SideBarTeamSpaceList = (props) => {
  const activeGroup = useSelector((state) => state.activeGroup);
  const device = useSelector((state) => state.device);
  const params = useParams();
  const allSidebarCareNotifications = useSelector(
    (state) => state.allSidebarCareNotifications,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (allSidebarCareNotifications.isNotificationAvailable) {
      refetchResults();
      dispatch(sidebarCareNotificationAvailable(false));
    }
  }, [allSidebarCareNotifications]);

  const refetchResults = (data) => {
    props.refetch();
  };

  if (
    typeof device.mobileDevice !== 'undefined' &&
    !device.mobileDevice &&
    params.id === undefined &&
    !isUnifiedEnabled()
  ) {
    if (props.results.length > 0) {
      return <Navigate to={'/team/group/recent'} />;
    } else {
      // No Group found redirect to recent dm
      return <Navigate to={'/team/dm/recent'} />;
    }
  }

  return (
    <div className={'left-care-space-section'}>
      <TeamSpaceActivityList
        section={props.results}
        activeResourceId={activeGroup?.id}
      />
    </div>
  );
};

export default SideBarTeamSpaceList;
