import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';

import Comment from 'app/components/Comments/Comment';
import Icon from 'app/components/Svg';

import { filter, groupBy, keys, sortBy } from 'app/utils/osLodash';
import { startOfDay } from 'app/utils/timeHelper';
import FeedGroupedDate from 'app/components/FeedsView/FeedGroupedDate';

const UPDATE_COMMENTABLE_COMMENTS_LAST_SEEN_AT = queryLoader(
  'app/graphql/mutations/UpdateCommentableCommentsLastSeenAt.gql',
);

class CommentsListing extends Component {
  componentDidMount() {
    if (this.props.currentUser.graph)
      this.updateCommentableCommentsLastSeenAt();
  }

  updateCommentableCommentsLastSeenAt() {
    if (
      this.props.isBlurred ||
      ['comment-detail-view', 'feeds-view'].includes(this.props.source)
    )
      return;

    let { commentableId, commentableType } = this.props,
      variables = {
        commentable_id: commentableId,
        commentable_type: commentableType,
      };

    setTimeout(() => {
      this.props.updateCommentableCommentsLastSeenAt({ variables });
    }, 10000);
  }

  orderedComments() {
    let pinnedComments = filter(this.props.results, (c) => c.pinned),
      unpinnedComments = filter(this.props.results, (c) => !c.pinned),
      orderedPinnedComments = sortBy(pinnedComments, 'pinned_at').reverse();

    return orderedPinnedComments.concat(unpinnedComments);
  }

  groupedDateObject(date) {
    return {
      __typename: 'GroupedDate',
      date,
    };
  }

  groupByComments() {
    const feeds = this.orderedComments();
    const groupByFeeds = groupBy(feeds, (feed) =>
      feed.pinned ? 'Pinned' : startOfDay(feed.updated_at),
    );
    let allObjectsWithGroupedDate = [];
    keys(groupByFeeds).forEach((key) => {
      key !== 'Pinned' &&
        allObjectsWithGroupedDate.push(this.groupedDateObject(key));
      allObjectsWithGroupedDate.push(...groupByFeeds[key]);
    });
    return allObjectsWithGroupedDate;
  }

  renderComment = (comment) => {
    if (comment.__typename === 'GroupedDate') {
      return (
        <FeedGroupedDate
          key={comment.date}
          date={comment.date}
          infoIconText={'CARE_SPACE_FFED_INFO_TEXT'}
          isTimelineView={true}
        />
      );
    } else {
      let { can_pin_comments } = this.props.commentable;

      return (
        <Comment
          aroundId={this.props.aroundId}
          comment={{ ...comment, can_pin_comments }}
          commentableEntityOwner={this.props.commentableEntityOwner}
          commentableId={this.props.commentableId}
          commentableType={this.props.commentableType}
          editCommentId={this.props.editCommentId}
          entity={this.props.entity}
          key={comment.id}
          metaParentCommentId={this.props.metaInfo.parent_comment_id}
          onCommentIconClick={this.props.onCommentIconClick}
          readOnly={this.props.readOnly}
          refetchOnPin={this.props.refetchOnPin}
          replyToCommentId={this.props.replyToCommentId}
          scrollToCommentId={this.props.scrollToCommentId}
          setEditCommentId={this.props.setEditCommentId}
          setScrollToCommentId={this.props.setScrollToCommentId}
          sortQuery={this.props.sortQuery}
          source={this.props.source}
          processed={this.props.processed}>
          {this.props.children}
        </Comment>
      );
    }
  };

  renderNoCommentBlock() {
    return (
      <div className='text-center mt-5'>
        <Icon name='noComments' />
        <div className='pt-4 mt-2 no-comment-added'>
          <span className='semibold'>No comments were added</span>
        </div>
      </div>
    );
  }

  render() {
    if (!this.orderedComments().length) return this.renderNoCommentBlock();

    return (
      <ul className='list-unstyled comments-list'>
        {this.groupByComments().map(this.renderComment)}
      </ul>
    );
  }
}

CommentsListing = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(CommentsListing);
CommentsListing = graphql(UPDATE_COMMENTABLE_COMMENTS_LAST_SEEN_AT, {
  name: 'updateCommentableCommentsLastSeenAt',
})(CommentsListing);
export default CommentsListing;
