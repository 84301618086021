import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'app/components/HOC/Router/withRouter';

import DefaultSpaceCardImage from 'app/images/gradeint_cloudberry.png';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import RecordsView from 'app/components/RecordsView';
import SimpleNavList from 'app/components/SimpleNavList';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';
import { entityUrl, getEntityIcon } from 'app/utils/entitiesHelper';
import { getDynamicObjFromKey } from 'app/utils/dynamicTemplateHelper';
import { getSpaceCardGridIdentifier } from 'app/utils/osCardHelper';
import { isMobileExpandedNavTabEnabled } from 'app/utils/featureHelper';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import { isEmpty } from 'app/utils/osLodash';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import { MY_FOLDER_FILES } from 'app/routes/constants/folders';

const FOLDER_KEY_REGEX = /\/folders\/(.*)\/?/;
class DynamicFolderTemplateView extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    this.setState(this.getDefaultState());
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.device) && !isEmpty(this.props.device))
      this.setState(this.getDefaultState());
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState((prev) => ({ ...prev, obj: this.getObj() }));
    }
  }

  getDefaultState() {
    return {
      cardViewType: this.props.device.mobileDevice ? 'list' : 'big',
      filterValues: [],
      searchText: '',
      selectedFilter: this.props.device.mobileDevice ? null : 'content',
      obj: this.getObj(),
    };
  }

  renderSpaceNameAndDetails() {
    return (
      <div className='space-header-name pe-3'>
        <OrthoIcon
          name='folder'
          defaultClass='pe-2'
          dataHoverNotRequired={true}
        />
        <span className='span'>{this.state.obj.name}</span>
      </div>
    );
  }

  getKey() {
    return FOLDER_KEY_REGEX.exec(this.props.location.pathname)[1];
  }

  getObj() {
    return getDynamicObjFromKey(this.getKey(), this.props.currentUser.graph);
  }

  renderAuthorName() {
    return (
      <div className='pe-2 semibold lh-1 me-4'>
        <UserQualificationInfo
          user={this.props.currentUser.graph}
          osLinkClass='a-link white-link'
          sliceUpto={2}
          linkRequired={true}
        />
      </div>
    );
  }

  renderParentSpaceLink() {
    let btnExtraClass = 'sub-space-btn osbtn-tertiary ',
      parentSpace = getWorkspaceData();
    btnExtraClass += this.props.device.mobileDevice
      ? 'mobile-sub-space-btn'
      : 'ms-3';
    if (parentSpace.id)
      return (
        <>
          <OsLink
            to={entityUrl(parentSpace)}
            className='white-link me-3 '
            associatedEntity={parentSpace}>
            <OrthoIcon
              name={getEntityIcon(parentSpace)}
              dataHoverNotRequired={true}
            />
            &nbsp; {parentSpace.name}
          </OsLink>
          <span className='mobile-view-only'></span>
        </>
      );
  }

  renderSubHeader() {
    let mobile = this.props.device.mobileDevice,
      subHeaderClass = 'space-sub-header without-extra-info already-joined ';

    return (
      <>
        <article className={subHeaderClass}>
          <div className='d-flex justify-content-between header-title-row'>
            <div className='image-with-info'>
              <div className='image-figure'>
                <img
                  src={DefaultSpaceCardImage}
                  alt=''
                  className='img'
                  height={80}
                  width={120}
                />
              </div>
              <div className='folder-author-info'>
                {this.renderSpaceNameAndDetails()}
                {!mobile && (
                  <div
                    className={`d-flex align-items-center btn-with-title-bar ${
                      this.props.device.mobileDevice ? 'only-mobile-bar' : ''
                    } `}>
                    {this.renderAuthorName()}
                    {this.renderParentSpaceLink()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </article>
      </>
    );
  }

  setCollectionTotal = (contentCount) => {
    this.setState({ contentCount });
  };

  renderContent() {
    if (this.selectedFilter() || !isMobileExpandedNavTabEnabled(this.state.obj))
      return (
        <RecordsView
          perPage={20}
          loaderNotRequired={true}
          type={this.state.obj.queryType}
          queryType={'USER_DETAIL'}
          size={this.state.cardViewType}
          textQuery={this.state.searchText.trim()}
          cardGridIdentifier={getSpaceCardGridIdentifier(
            this.state.cardViewType,
          )}
          additional_filters={{ filter_values: this.state.filterValues }}
          sortQuery={this.state.sortQuery}
          setCollectionTotal={this.setCollectionTotal}
          collectionTotal={this.state.contentCount}
        />
      );
  }

  onSearchChange = (searchText) => {
    this.setState({ searchText });
  };

  onCardViewSelect = (cardViewType) => {
    this.setState({ cardViewType });
  };

  onFilterValueChange = (filterValues) => {
    this.setState({ filterValues });
  };

  getCardViewType() {
    return this.state.cardViewType;
  }

  getSearchAndFilterOptions() {
    return {
      onSearchChange: this.onSearchChange,
      onCardViewSelect: this.onCardViewSelect,
      cardViewType: this.getCardViewType(),
      searchRequired: true,
      onFilterValueChange:
        this.getKey() === MY_FOLDER_FILES ? this.onFilterValueChange : null,
      filterSource: this.getKey(),
      onSortChange: this.onSortChange,
      sortQuery: this.state.sortQuery,
    };
  }

  onSortChange = (sortQuery) => {
    this.setState({ sortQuery });
  };

  selectedFilter() {
    return this.state.selectedFilter;
  }

  onFilterClick = () => {
    this.setState({ selectedFilter: 'content' }, () => {
      addClassToHTML('mobile-space-view');
    });
  };

  renderNavList() {
    const list = [['Content', 'content']];
    return (
      <div className='container-fluid px-0 workspace-page-head'>
        <div className='row d-flex justify-content-between nav-relative'>
          <SimpleNavList
            list={list}
            onFilterClick={this.onFilterClick}
            currentFilter={this.selectedFilter()}
            searchAndFilterRequired={true}
            searchAndFilterOptions={this.getSearchAndFilterOptions()}
            filterCountMapper={{ content: this.state.contentCount }}
          />
        </div>
      </div>
    );
  }

  renderLegacyHeader() {
    return (
      <div className='non-clv-header'>
        <div className='container-fluid xl-container px-hd-40 px-sm-32 px-m-12 mb-4'>
          {this.renderSubHeader()}
        </div>
      </div>
    );
  }

  renderTopHeader() {
    if (
      !isMobileExpandedNavTabEnabled(this.state.obj) ||
      !this.selectedFilter()
    ) {
      return this.renderLegacyHeader();
    } else if (this.selectedFilter()) {
      return this.renderTabPageHeader();
    }
  }

  goBack = () => {
    this.setState({ selectedFilter: null }, () => {
      removeClassFromHTML('mobile-space-view');
    });
  };

  renderTabPageHeader() {
    return (
      <div className='tab-page-header'>
        <OsBtn
          name='BtnIcon'
          text='back'
          onClick={this.goBack}
          extraClass='back-btn web-view-btn'
          icon='back'
        />
        <div className='folder-space-name'>
          {this.state.obj.name}
          <div className='seleted-tab-name'>Content</div>
        </div>
      </div>
    );
  }

  render() {
    let parentClassName =
        'detail detail-board px-0 no-image course-unsubscribed content',
      sectionExtraClass = this.state.isHeadSticky ? 'hide-extra-action' : '';
    if (!this.props.device.mobileDevice)
      parentClassName += 'position-unset-imp pt-4';

    return (
      <div className={parentClassName}>
        <SetPageTitle
          source={'my_uploaded_files'}
          interpolations={this.state.obj.name}
        />
        {this.renderTopHeader()}

        <section
          className={`sticky-tab-with-dropdown tab-min-height ${sectionExtraClass}`}
          ref='scroll'>
          <div className='xl-container container-fluid px-hd-40 px-sm-32 px-m-12'>
            {this.renderNavList()}
            <div
              className={`space-links-container all ${this.getCardViewType()}`}>
              {this.state.sortQuery && this.renderContent()}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

DynamicFolderTemplateView = withRouter(DynamicFolderTemplateView);
DynamicFolderTemplateView = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(DynamicFolderTemplateView);

export default DynamicFolderTemplateView;
