import React, { useContext } from 'react';
import Board from '@asseinfo/react-kanban';
import KanbanTaskCard from 'app/components/Kanban/KanbanTaskCard';
import useGroupLabelBoard from 'app/components/Task/GroupLabelView/useGroupLabelBoard';
import 'app/components/Task/GroupLabelView/group-label-board-view.scss';
import { GroupLabelViewContext } from 'app/context/TaskContext';

function GroupLabelBoardView({ allTaskOpen, handleSetAllTaskOpen }) {
  const {
    groupLabelResponse,
    fetchNextPageRecords,
    updateViewAfterChangingLabel,
  } = useContext(GroupLabelViewContext);
  const { kBoard, moveCardToAnotherColumn } = useGroupLabelBoard({
    groupLabelResponse,
    fetchNextPageRecords,
    updateViewAfterChangingLabel,
  });
  const renderCard = (card) => {
    return (
      <KanbanTaskCard
        card={card}
        listType={'GroupLabelTasks'}
        allTaskOpen={allTaskOpen}
        handleSetAllTaskOpen={handleSetAllTaskOpen}
      />
    );
  };

  const onCardDragEnd = (card, source, destination) => {
    moveCardToAnotherColumn(source.fromColumnId, destination.toColumnId, card);
  };

  return (
    <div className='group-label-board'>
      {kBoard ? (
        <Board
          allowAddCard={{ on: 'top' }}
          disableColumnDrag={true}
          renderCard={(card) => renderCard(card)}
          onCardDragEnd={onCardDragEnd}>
          {kBoard}
        </Board>
      ) : (
        <></>
      )}
    </div>
  );
}

export default GroupLabelBoardView;
