import { OPEN_REPORT_MODAL, CLOSE_REPORT_MODAL } from 'app/actions/types';

export default function report(
  state = {
    openReportModal: false,
    object: null,
  },
  action,
) {
  switch (action.type) {
    case OPEN_REPORT_MODAL:
      return { openReportModal: true, object: action.object };
    case CLOSE_REPORT_MODAL:
      return { openReportModal: false, object: null };
    default:
      return state;
  }
}
