import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

class VideoRecordButton extends Component {
  render() {
    let { associatedEntity, extraClass, btnTitle, onClick } = this.props,
      btnText = '';
    return (
      <OsBtn
        name='BtnIcon'
        extraClass={extraClass}
        text={btnTitle || btnText}
        icon={this.props.icon}
        onClick={onClick}
        associatedEntity={associatedEntity}
      />
    );
  }
}

VideoRecordButton = connect(
  ({ device }) => ({ device }),
  {},
)(VideoRecordButton);

VideoRecordButton.defaultProps = {
  associatedEntity: {},
  onClick: () => {},
  extraClass: '',
  icon: 'video-filled',
};
export default VideoRecordButton;
