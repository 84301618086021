import useScheduledPosts from 'app/hooks/useScheduledPosts';
import React from 'react';
import SchedulePost from 'app/components/SpaceTabs/ScheduledPosts/SchedulePost';

function ScheduledPosts({ widgetSourceObject }) {
  const {
    postsRef,
    postsRefUpdated,
    deleteScheduleMessage,
    updateScheduledMessage,
  } = useScheduledPosts({
    widgetSourceObject,
  });

  return (
    <div key={postsRefUpdated}>
      {postsRef.current.map((post) => {
        return (
          <SchedulePost
            key={post.id}
            post={post}
            deleteScheduleMessage={deleteScheduleMessage}
            updateScheduledMessage={updateScheduledMessage}
          />
        );
      })}
    </div>
  );
}

export default ScheduledPosts;
