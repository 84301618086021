import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';

import AddSummaryButton from 'app/components/Shared/AddSummaryButton';
import RecordsView from 'app/components/RecordsView';
import OsGrid from 'app/components/OsGrid';
import {
  isWorkspaceView,
  isWorkspaceAuthorOrEditor,
} from 'app/utils/Workspace/generalHelper';
import { isCareSpace } from 'app/utils/spaceHelper';

class RecentCareSummaryDiscussions extends Component {
  getRequestType() {
    return `${this.props.widgetSourceObject.__typename}RecentDiscussions`;
  }

  renderContent() {
    return (
      <OsGrid identifier='DetailRightSidebar:RecentDiscussionsCol'>
        <Accordion
          defaultActiveKey={isWorkspaceView() ? '0' : '1'}
          className={`right-ac sidebar-container patient-detail-sidebar sidebar-widget popular-topics ${
            this.props.sideBarClass || 'recent-discussion-bar no-card-effect'
          }`}>
          <Accordion.Item eventKey='1' className='patient-detail-widget'>
            <div className='rt-ac-header widget-title'>
              <Accordion.Header
                className='rt-ac-header widget-title w-100'
                key=''>
                <span className='widget-title' style={{ color: '#7e91a5' }}>
                  Care Summary
                </span>
              </Accordion.Header>
              <div className='plus-btn'>
                {isWorkspaceAuthorOrEditor() && isWorkspaceView() && (
                  <AddSummaryButton
                    type='careSummary'
                    text=''
                    spaceId={this.props.widgetSourceObject.id}
                    extraClass='no-text tag-view-more pointer fs-20 ac-btn'
                  />
                )}
              </div>
            </div>
            <Accordion.Body classname='custom-class'>
              <RecordsView
                className='collection'
                type='BoardRecentDiscussions'
                queryType='COMMENTS_LISTING'
                idQuery={this.props.widgetSourceObject.id}
                perPage={1}
                textQuery='caresummary'
                infiniteScroll={false}
                showMoreNotRequired={true}
                size='small'
                viewType='List'
                cardGridIdentifier={'RecentDiscussions:CardCols'}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </OsGrid>
    );
  }

  render() {
    if (isCareSpace(this.props.widgetSourceObject)) {
      return this.renderContent();
    } else {
      return null;
    }
  }
}

export default RecentCareSummaryDiscussions;
