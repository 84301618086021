import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { FORM_ERROR } from 'final-form';

import OsField from 'app/components/OsField';
import OsBtn from 'app/components/OsBtn';

import { fieldRequired } from 'app/utils/validationHelper.js';
import { translate } from 'app/actions/flashMessage';

const CREATE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateLabel.gql',
);

const NewLabelForm = (props) => {
  const createLabel = (variables) => {
    return props
      .createLabelMutation({
        variables,
      })
      .then(({ data }) => {
        let response = data.updateLabel;
        if (response.success) {
          props.onSuccess(response.entity);
        } else {
          let nameError = (response.errors || []).find(
            (obj) => obj.field === 'name',
          );
          if (nameError) {
            let returnErrors = {};
            returnErrors['name'] = nameError.message;
            return returnErrors;
          } else {
            return { [FORM_ERROR]: translate('CREATE_ERROR') };
          }
        }
      });
  };

  return (
    <Form
      onSubmit={createLabel}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} name='lable_form' className='label-form'>
          <Field
            component={OsField}
            osType='input'
            placeholder='Label Name'
            name='name'
            validate={fieldRequired}
            autoFocus={true}
          />
          <div className='form-btns'>
            <OsBtn
              type='button'
              text='Save'
              name='BtnPrimary'
              extraClass='save-btn'
              onClick={() => {
                handleSubmit();
                form.change('name', '');
              }}
            />
            <OsBtn
              type='button'
              text='Cancel'
              name='BtnIcon'
              extraClass='cancel-btn'
              onClick={() => props.closeModal()}
            />
          </div>
        </form>
      )}
    />
  );
};

NewLabelForm.defaultProps = {
  onSuccess: () => {},
};
const NewLabelFormContainer = graphql(CREATE_LABEL_MUTATION, {
  name: 'createLabelMutation',
})(NewLabelForm);
export default NewLabelFormContainer;
