import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';
import { isEntityPublished, getEntityIcon } from 'app/utils/entitiesHelper';

const CHANGE_ENTITY_PRIVACY_MUTATION = queryLoader(
  'app/graphql/mutations/ChangeEntityPrivacy.gql',
);
class ChangePrivacy extends Component {
  sendChangeRequest(variables = {}) {
    let { entity } = this.props;

    this.props.changeEntityPrivacyMutation({
      variables: {
        entityId: entity.id,
        entityType: entity.__typename,
        ...variables,
      },
    });
  }

  changeToPrivate = () => {
    this.sendChangeRequest({ to: 'Private' });
  };

  changeToPublic = () => {
    this.sendChangeRequest({ to: 'Published' });
  };

  getDefaultBtnProps() {
    let { entity } = this.props,
      isPublished = isEntityPublished(entity);

    return {
      associatedEntity: entity,
      icon: isPublished
        ? 'locked'
        : getEntityIcon(entity, { forcePublished: true }),
      onClick: isPublished ? this.changeToPrivate : this.changeToPublic,
      text: isPublished ? 'Make Private' : 'Make Public',
      extraClass: 'list-button',
    };
  }

  getBtnProps() {
    let { entity } = this.props;
    switch (entity.__typename) {
      case 'Board':
        return {};
      default:
        return this.getDefaultBtnProps();
    }
  }

  render() {
    return <OsBtn name='BtnIcon' {...this.getBtnProps()} />;
  }
}

ChangePrivacy = graphql(CHANGE_ENTITY_PRIVACY_MUTATION, {
  name: 'changeEntityPrivacyMutation',
})(ChangePrivacy);
export default ChangePrivacy;
