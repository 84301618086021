import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { getWorkspaceIdentifer } from 'app/selectors/workspace';

import { PRESCRIPTION_STATUS_INFO } from 'app/constant/easyRX';

const EasyRXPrescriptionStatusList = () => {
  const navigate = useNavigate();
  const identifier = useSelector(getWorkspaceIdentifer);

  const redirectToPage = (event, filter) => {
    event.stopPropagation();
    navigate(`/${identifier}/easy-rx/prescriptions/${filter}`);
  };

  const renderPrescriptionStatusList = () => {
    return Object.keys(PRESCRIPTION_STATUS_INFO).map((key) => {
      return (
        <div
          className={'patients-list-menu__option'}
          onClick={(event) => redirectToPage(event, key)}>
          <p
            style={{
              margin: '0',
              fontSize: '12px',
              color: '#5d7893',
              fontWeight: 'normal',
            }}>
            {PRESCRIPTION_STATUS_INFO[key].title}
          </p>
        </div>
      );
    });
  };

  return (
    <Accordion alwasOpen={true} activeKey={['1']}>
      <Accordion.Item eventKey={'1'} className={'patient-directory-option'}>
        <Accordion.Body>
          <div className={'patients-list-menu'}>
            <div className={'patients-list-menu__options'}>
              {renderPrescriptionStatusList()}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EasyRXPrescriptionStatusList;
