import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { entityUrl } from 'app/utils/entitiesHelper';
import { AVATAR_CSS } from 'app/utils/colorHelper';
import { NON_LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR } from 'app/utils/colorHelper';

export default class Avatar extends Component {
  getPath() {
    return (
      this.props.url ||
      entityUrl({ nice_id: this.props.nice_id, __typename: 'User' })
    );
  }

  renderContent() {
    if (this.props.asLink) {
      return (
        <Link
          to={this.getPath()}
          className={this.props.linkClass}
          onClick={(e) => e.stopPropagation()}>
          {this.renderPicOrInitials()}
        </Link>
      );
    } else {
      return this.renderPicOrInitials();
    }
  }

  renderEmjoiIcon(subscriptionColorCode) {
    if (this.props.emojiIcon) {
      return (
        <span
          className='icon-span'
          style={{ backgroundColor: subscriptionColorCode }}>
          <em-emoji id={this.props.emojiIcon} skin={5} />
        </span>
      );
    }
  }

  getAvatarCss(subscriptionColorCode, suffixCode) {
    subscriptionColorCode =
      subscriptionColorCode || NON_LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR;
    return AVATAR_CSS[`${subscriptionColorCode}_${suffixCode}`];
  }

  renderPicOrInitials() {
    let {
        src,
        name,
        initials,
        className,
        nice_id,
        subscriptionColorCode,
        linkClass,
        avatarIcon,
        ...props
      } = this.props,
      avatarCssWithImage = this.getAvatarCss(
        subscriptionColorCode,
        'with_image',
      ),
      avatarCssWithoutImage = this.getAvatarCss(
        subscriptionColorCode,
        'without_image',
      );

    if (avatarIcon)
      return (
        <div
          {...props}
          className={className + ' avatar-user-icon'}
          style={avatarCssWithoutImage}>
          <OrthoIcon
            name={avatarIcon}
            dataHoverNotRequired={true}
            defaultClass={this.props.defaultIconClass}
          />
          {this.renderEmjoiIcon(subscriptionColorCode)}
        </div>
      );

    if (!src || 0 === src.length) {
      let avatarCss = this.getAvatarCss(subscriptionColorCode, 'without_image');
      return (
        <div
          className={className + ' avatar-initials'}
          {...props}
          style={avatarCssWithoutImage}>
          <OrthoIcon
            name='user'
            defaultClass='nav-profile__user-photo'
            dataHoverNotRequired={true}
          />
          {this.renderEmjoiIcon(subscriptionColorCode)}
        </div>
      );
    }
    return (
      <div {...props} className={className} style={avatarCssWithImage}>
        <img src={src} alt={initials} onError={this.props.onError} />
        {this.renderEmjoiIcon(subscriptionColorCode)}
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

Avatar.defaultProps = {
  linkClass: '',
  defaultIconClass: '',
  onError: () => {},
};
