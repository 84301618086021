import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import scrollToElement from 'scroll-to-element';

import { commentShareChangeType } from 'app/actions/commentShare';
import { debounce } from 'app/utils/osLodash';
import { openUpgradeModal } from 'app/actions/upgrade';

import { isInViewport, isInContainerViewPort } from 'app/utils/domHelper';

import AuthorizeTab from 'app/components/Shared/AuthorizeComponent';
import CardGridViewSelector from 'app/components/Shared/CardGridViewSelector';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import FeaturePermitted from 'app/components/FeatureFlagging/FeaturePermitted';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn/';
import OsField from 'app/components/OsField';
import SpaceContentSortDropdown from 'app/components/Shared/SpaceContentSortDropdown';
import SpaceFilterDropdown from 'app/components/Shared/SpaceFilterDropdown';

import EventTracker from 'app/services/EventTracker';

import { TITLE_MAPPER, ICON_MAPPER } from './dropdownInfoMapper';
import { entityUrl } from 'app/utils/entitiesHelper';
import { NAV_LIST_ABILITIES } from 'app/components/Shared/AbilityConstants';
import { getDefaultFilter, isValidNavFilter } from 'app/utils/navHelper';
import { getRoundOffFloorValue } from 'app/utils/generalHelper';
import { bindMobileFilter } from 'app/actions/device';
import {
  filter,
  find,
  indexOf,
  isArray,
  isNumber,
  isUndefined,
  map,
  snakeCase,
  startCase,
  upperCase,
} from 'app/utils/osLodash';
import RenderDropdown from 'app/components/Shared/RenderDropdown/RenderDropdown';
import {
  isCareWorkspaceView,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import { SPACE_ACTIVITY_FEED_FILTERS } from 'app/components/BoardView/constants';

// List view for the comment share
class SimpleNavList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownTitle: {},
      scrolling: false,
      showDropDown: false,
      keyToggler: true,
      searchText: props.searchAndFilterOptions.searchText || '',
      showFilterDropdown: false,
    };
    this.feedTypeRef = React.createRef();
    this.feedTypeRef.current =
      SPACE_ACTIVITY_FEED_FILTERS.feed_type_mapper[
        props.match.params.feedType
      ] || 'All Activity';
    this.dropDownData = [
      {
        name: 'Name (A-Z)',
        sort: 'name',
        sortOrder: 'asc',
      },
      {
        name: 'Name (Z-A)',
        sort: 'name',
        sortOrder: 'desc',
      },
      {
        name: 'Date Created (asc)',
        sort: 'sort_date',
        sortOrder: 'asc',
      },
      {
        name: 'Date Created (desc)',
        sort: 'sort_date',
        sortOrder: 'desc',
      },
      {
        name: 'Most Recently Updated',
        sort: 'updated_at',
        sortOrder: 'desc',
      },
      {
        name: 'Most Views',
        sort: 'view_count',
        sortOrder: 'desc',
      },
    ];
    this.iconMapper = {
      name_asc: 'name-a-z',
      name_desc: 'name-z-a',
      sort_date_asc: 'calendar-desc',
      sort_date_desc: 'calendar-asc',
      updated_at_desc: 'recent',
      view_count_desc: 'view',
    };
    this.props.list.map(this.setDropdownTitle);
    this.tabRefs = {};
  }

  setDropdownTitle = (list) => {
    let filter = list.filter(
        (entity) => entity[2] === this.props.currentFilter,
      ),
      title = this.state.dropdownTitle;
    if (filter.length) {
      title[list[0][0]] = filter[0][1];
      this.setState({ dropdownTitle: title });
    }
  };

  componentDidMount() {
    this.scrollTabIntoView(this.props.currentFilter);
    this.props.bindMobileFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentFilter !== this.props.currentFilter) {
      this.scrollTabIntoView(this.props.currentFilter);
    }

    if (
      prevProps.currentFilter !== 'activity' &&
      this.props.currentFilter === 'activity'
    ) {
      this.feedTypeRef.current =
        SPACE_ACTIVITY_FEED_FILTERS.feed_type_mapper[
          this.props.match.params.feedType
        ];
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.device.portrait !== nextProps.device.portrait &&
      nextProps.scrollIntoViewOnOrientationChange
    )
      this.scrollTabIntoView(nextProps.currentFilter);
  }

  resetDropdownPosition = (dropdownHeading, dropdown) => {
    let navContainer = window.$(this.navContainer),
      leftPosition = dropdownHeading.offset().left - navContainer.offset().left;

    this.resetScrolling();
    dropdown.css({ left: leftPosition });
  };

  resetScrolling() {
    this.setState({ scrolling: false });
  }

  setScrolling = (e) => {
    this.setState({ scrolling: true });
  };

  scrollTabIntoView(filter) {
    let tab = this.tabRefs[this.getFilterValue(filter)];
    // Dev ToDo: isInViewport logic not working properly on page load when the selected dropdown option is not in viewport!
    if (
      false &&
      tab &&
      (!isInViewport(tab) || !isInContainerViewPort(this.navContainer, tab))
    ) {
      scrollToElement(tab, { ease: 'linear', offset: -100, duration: 10 });
    }
  }

  onFilterClick = (filterValue, type, key, isDropdown = false) => {
    if (!isDropdown) {
      EventTracker.trackSelectTabEvent(snakeCase(type));
    }

    if (key) {
      let { dropdownTitle } = this.state;
      dropdownTitle[key] = type;
      this.setState({ dropdownTitle });
    }

    if (this.props.onFilterClick) {
      this.props.onFilterClick(filterValue, type, this.feedTypeRef.current);
    }
  };

  onIconClick = () => {
    if (this.props.onIconClick) this.props.onIconClick();
  };

  getIconName(filterValue) {
    return ICON_MAPPER[this.props.listing]
      ? ICON_MAPPER[this.props.listing][filterValue]
      : filterValue;
  }

  getFilterValue(filter) {
    if (filter) {
      filter = snakeCase(filter.toLowerCase());
    }
    return filter;
  }

  renderListItem(data) {
    return isArray(data[0])
      ? this.renderDropDownList(data)
      : this.listItem(...data);
  }

  roundOffCount(count, filter) {
    if (
      this.props.tabsRoundingCount.tab &&
      this.props.tabsRoundingCount.tab.includes(filter)
    ) {
      return getRoundOffFloorValue(
        count,
        this.props.tabsRoundingCount.minValue,
      );
    }
    return count;
  }

  filterCount(filter, key = '') {
    return (this.props.filterCountMapper || {})[
      filter || this.state.dropdownTitle[key] || key
    ];
  }

  setRef = (tab, value) => {
    this.tabRefs[this.getFilterValue(value)] = tab;
  };

  getTabAbilityObject(filterValue) {
    return (
      NAV_LIST_ABILITIES[this.props.source] &&
      NAV_LIST_ABILITIES[this.props.source][filterValue]
    );
  }

  renderDropDownListItem = (key, data) => {
    let abilityObject = this.getTabAbilityObject(data[2]);
    if (this.tabVisible(data[2]))
      return (
        <AuthorizeTab
          key={data[2]}
          onClick={this.onFilterClick.bind(this, data[2], data[1], key)}
          abilityObject={abilityObject}>
          <span
            key={data[2]}
            ref={(tab) => {
              this.setRef(
                window.$(tab).closest('.list-inline-item')[0],
                data[2],
              );
            }}>
            <OrthoIcon name={data[2]} className='header-list-icon' />
            &nbsp;
            {data[0]}{' '}
            {this.props.appendCountAfterTitle
              ? ` (${this.filterCount(data[0])})`
              : ''}
          </span>
        </AuthorizeTab>
      );
  };

  getActiveElementIndex(list) {
    let availableList = list.filter((tab) => this.tabVisible(tab[2]));
    return indexOf(
      map(availableList, (data) => data[2]),
      this.props.currentFilter,
    )
      ? indexOf(
          map(availableList, (data) => data[2]),
          this.props.currentFilter,
        )
      : 0;
  }

  getHighlightedClass(selectedItem, list) {
    if (selectedItem) {
      return find(
        map(list, (data) => data[2]),
        (dropdownOptions) => dropdownOptions === selectedItem[2],
      )
        ? 'object-filter-active'
        : '';
    }
    return '';
  }

  getFirstAvailableTab(list) {
    return (
      list.find((item) => {
        let permissionObject = this.getTabAbilityObject(item[2]);
        if (permissionObject) {
          let { action, entity, options } = permissionObject;
          return (
            this.props.currentUser.ability.can(action, entity, options) &&
            this.tabVisible(item[2])
          );
        } else {
          return this.tabVisible(item[2]);
        }
      }) || []
    );
  }

  renderDropDownList(list) {
    let selectedItem = this.props.currentFilter
        ? list.find((data) => data[2] === this.props.currentFilter) || []
        : list[0],
      highlightedClass = this.getHighlightedClass(selectedItem, list),
      count = this.props.appendCountAfterTitle
        ? this.filterCount(selectedItem[0], list[0][0])
        : null,
      title =
        selectedItem[1] ||
        this.state.dropdownTitle[list[0][0]] ||
        this.getFirstAvailableTab(list)[1],
      key = list[0][0];
    title += isNumber(count) ? ` (${count})` : '';

    return (
      <li
        key={key}
        className={`list-inline-item user-list-item ${highlightedClass}`}>
        <CustomDropdown
          name='entity_filter'
          chevronIcon='chevron-caret'
          activeElementIndex={this.getActiveElementIndex(list)}
          className={''}
          dropdownInfo={{ title }}
          resetDropdownPosition={this.resetDropdownPosition}
          closeDropDown={this.state.scrolling}>
          {list.map(this.renderDropDownListItem.bind(this, key))}
        </CustomDropdown>
      </li>
    );
  }

  tabVisible(filterValue) {
    let count = this.props.filterCountMapperForVisibilty[filterValue];
    if (
      filterValue === 'scheduled_posts' &&
      this.props.filterCountMapper.scheduled_posts === 0
    )
      return false;

    return (
      (!this.props.hideRowWithZeroCount && count !== -1) ||
      isUndefined(count) ||
      count > 0
    );
  }

  getTabClass(filterValue) {
    return `${filterValue}-nav-tab`;
  }

  listItem = (type, filterValue, pathname) => {
    let classNames = `list-inline-item ${this.getTabClass(pathname)} ${
        this.props.currentFilter === filterValue ||
        this.props.currentFilter === pathname ||
        this.props.list.length === 1
          ? 'object-filter-active'
          : ''
      }`,
      filterCount = this.filterCount(filterValue),
      abilityObject = this.getTabAbilityObject(filterValue),
      featureData = this.props.checkTabFeatureAllowedMapper[filterValue];
    filterCount = this.roundOffCount(filterCount, filterValue);

    let filterCountRequired =
      (filterCount > 0 || (filterCount === 0 && this.props.zeroCountVisible)) &&
      filterValue !== 'activity';

    if (this.tabVisible(filterValue)) {
      if (this.props.asDropDown) {
        let className = filterValue === this.props.currentFilter ? '' : '';
        return (
          <AuthorizeTab
            abilityObject={abilityObject}
            onClick={this.onFilterClick.bind(
              this,
              filterValue,
              type,
              '',
              true,
            )}>
            <FeaturePermitted
              featureData={featureData}
              onClick={this.onFilterClick.bind(
                this,
                filterValue,
                type,
                '',
                true,
              )}>
              <span
                className={className}
                key={type}
                ref={(tab) => {
                  this.setRef(tab, filterValue);
                }}>
                <OrthoIcon
                  name={this.getIconName(filterValue)}
                  defaultClass='header-list-icon'
                />
                {type}
              </span>
            </FeaturePermitted>
          </AuthorizeTab>
        );
      } else {
        return (
          <AuthorizeTab
            abilityObject={abilityObject}
            onClick={this.onFilterClick.bind(this, filterValue, type)}>
            <FeaturePermitted
              featureData={featureData}
              onClick={this.onFilterClick.bind(this, filterValue, type)}>
              <li
                key={type}
                className={classNames}
                ref={(tab) => {
                  this.setRef(tab, filterValue);
                }}>
                <div className='dropdown-info-title'>
                  <OrthoIcon
                    name='folders'
                    defaultClass='mb-visible nav-type-icon'
                  />
                  {filterValue === 'activity' && !isCareWorkspaceView()
                    ? this.renderDiscussionTab()
                    : upperCase(type)}
                  {filterCountRequired && (
                    <span className='list-item-count' data-count={filterCount}>
                      ({filterCount})
                    </span>
                  )}
                </div>
                <OrthoIcon
                  name='chevron-right'
                  defaultClass='mb-visible chevron-icon'
                />
              </li>
            </FeaturePermitted>
          </AuthorizeTab>
        );
      }
    }
  };

  toggleFilterDropdown = () => {
    if (this.props.currentFilter === 'activity')
      this.setState((prevState) => ({
        showFilterDropdown: !prevState.showFilterDropdown,
      }));
  };

  closeActivityFilterDropdown = () => {
    this.setState({ showFilterDropdown: false });
  };

  changeFeedFilter = (option) => {
    this.feedTypeRef.current = option.value;
  };

  renderDiscussionTab() {
    return (
      <div
        id='activity-tab'
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          position: 'relative',
        }}
        className='discussion-tab-container'
        onClick={this.toggleFilterDropdown}>
        {upperCase(this.feedTypeRef.current)}
        <OrthoIcon name='chevron-caret-down' />
        {this.state.showFilterDropdown && (
          <RenderDropdown
            options={SPACE_ACTIVITY_FEED_FILTERS.dropdown_options}
            changeFeedFilter={this.changeFeedFilter}
            selectedOption={this.feedTypeRef.current}
            closeModal={this.closeActivityFilterDropdown}
          />
        )}
      </div>
    );
  }

  renderMobileFilter() {
    if (this.props.icon && this.props.enableMobileFilter) {
      return (
        <li
          className='list-inline-item filters-block'
          onClick={this.props.enableMobileFilter}>
          <a href='javascript:' className='filters-icon-holder'>
            <OrthoIcon
              name={this.props.icon}
              dataHoverNotRequired={true}
              defaultClass={this.props.iconDefaultClass}
            />
          </a>
        </li>
      );
    }
  }

  redirectToToolbox = () => {
    this.props.navigate(`${entityUrl(this.props.currentUser.graph)}/toolbox`);
  };

  handleNewCaseClick = () => {
    if (this.props.currentUser.ability.can('create', 'case')) {
      this.props.navigate('/cases/new');
    } else {
      this.props.openUpgradeModal();
    }
  };

  renderIndexExtraContent() {
    if (
      this.props.casesIndexPage ||
      this.props.toolsIndexPage ||
      this.props.comparisonsIndexPage
    ) {
      let buttonContent,
        onClick,
        extraClass = '',
        abilityObject = this.getTabAbilityObject('primaryCta');

      if (this.props.casesIndexPage) {
        buttonContent = 'Submit Cases';
        onClick = this.handleNewCaseClick;
      } else if (this.props.comparisonsIndexPage) {
        buttonContent = 'Create a Comparison';
        onClick = this.props.handleComparisonNew;
      } else if (this.props.toolsIndexPage) {
        buttonContent = 'Manage Toolbox';
        onClick = this.redirectToToolbox;
      }

      return (
        <AuthorizeTab abilityObject={abilityObject} onClick={onClick}>
          <li className={'object-view-button-block ' + extraClass}>
            <OsBtn
              name='BtnPrimary'
              extraClass={this.props.btnExtraClass}
              text={buttonContent}
            />
          </li>
        </AuthorizeTab>
      );
    }
  }

  onDropDownToggle = (showDropDown) => {
    this.setState({ showDropDown });
  };

  renderDropdownInfo() {
    let title = TITLE_MAPPER[this.props.listing]
      ? TITLE_MAPPER[this.props.listing][this.props.currentFilter]
      : this.props.currentFilter;
    if (
      title &&
      this.props.currentUser.graph &&
      this.props.currentUser.graph.is_general_user
    )
      title = startCase(title.replace(/doctors/gi, 'members'));
    return { title };
  }

  renderToolTipIcon() {
    return (
      <i
        className={this.props.icon + ' heading-bar-icon'}
        onClick={this.onIconClick}></i>
    );
  }

  activeElementIndex(list) {
    return indexOf(
      map(list, (data) => data[1]),
      this.props.currentFilter,
    );
  }

  isIconSelected(data) {
    return (
      this.props.sort === data.sort && this.props.sortOrder === data.sortOrder
    );
  }

  getDropdownInfo() {
    let selectedFilter = filter(this.dropDownData, {
      sort: this.props.sort,
      sortOrder: this.props.sortOrder,
    });
    return {
      title: selectedFilter[0].name,
      icon: this.iconMapper[
        `${selectedFilter[0].sort}_${selectedFilter[0].sortOrder}`
      ],
    };
  }

  setSortOrder = (data) => {
    this.props.setSortOrder(data.sort, data.sortOrder, data.name);
    this.onDropDownToggle(false);
    this.setState({ showDropDown: false });
  };

  getDropDownClassName(isIconSelected) {
    let className = 'list-icon-text';
    className += isIconSelected ? ' active' : '';
    return className;
  }

  singleDropDownCell = (data, index) => {
    let isIconSelected = this.isIconSelected(data);
    return (
      <>
        <div
          className={this.getDropDownClassName(isIconSelected)}
          key={index}
          onClick={this.setSortOrder.bind(this, data)}>
          <OrthoIcon
            name={this.iconMapper[`${data.sort}_${data.sortOrder}`]}
            defaultClass='icon-sorting-secondary'
            dataHoverNotRequired={true}
          />
          <div className='sorting-dropdown-content'>
            {data.name}
            {isIconSelected && (
              <OrthoIcon
                name='tick'
                dataHoverNotRequired={true}
                defaultClass='sorting-check-icon'
              />
            )}
          </div>
        </div>
      </>
    );
  };

  renderDropdown() {
    return (
      <CustomDropdown
        name='sort'
        dropdownInfo={this.getDropdownInfo()}
        onDropDownToggle={this.onDropDownToggle}>
        {this.dropDownData.map(this.singleDropDownCell)}
      </CustomDropdown>
    );
  }

  renderLists() {
    let list = this.props.list;
    if (this.props.listFilterRequired)
      list = list.filter((args) => this.filterCount(args[1]));
    if (this.props.asDropDown) {
      return (
        <div>
          <CustomDropdown
            name={`${this.props.listing}_filter`}
            activeElementIndex={this.activeElementIndex(list)}
            dropdownInfo={this.renderDropdownInfo()}
            className={'page-header-dropdown-menu left-align-menu'}
            hideDropdown={this.props.hideDropdown}>
            {list.map((args) => this.renderListItem(args))}
          </CustomDropdown>
          {this.props.icon && (
            <i
              className={this.props.icon + ' heading-bar-icon action-link'}
              onClick={this.onIconClick}></i>
          )}
        </div>
      );
    } else {
      return (
        <ul className='object-filter list-inline page-header-dropdown-menu left-align-menu'>
          {list.map((args) => this.renderListItem(args))}
        </ul>
      );
    }
  }

  setSearchText = debounce((searchText) => {
    const options = this.props.searchAndFilterOptions;
    options.onSearchChange && options.onSearchChange(searchText);
  }, 200);

  onChange = (e) => {
    let searchText = e.target.value || '';
    this.setState({ searchText });
    this.setSearchText(searchText);
  };

  renderSearch() {
    let placeholderText =
      this.props.device.desktopSmall || this.props.device.ipadPortrait
        ? 'Search for content in this tab'
        : 'Click here to search for content in this tab';

    if (this.props.searchAndFilterOptions.searchRequired)
      return (
        <div className='input-wrapper nav-search-holder input-focus space-search-container'>
          <div className='nav-search-container'>
            <label className='position-relative m-0 w-100'>
              <OrthoIcon name='search' dataHoverNotRequired={true} />
              <OsField
                osType='input'
                type='text'
                placeholder={placeholderText}
                className='nav-search-input'
                name='search'
                onChange={this.onChange}
                formGroupExtraClass='m-0'
                value={this.state.searchText}
              />
            </label>
          </div>
        </div>
      );
  }

  sortDropDownToggle = (sortDropDownOpen) => {
    this.setState({ sortDropDownOpen });
  };

  renderViewButtons() {
    const searchAndFilterOptions = this.props.searchAndFilterOptions;
    return (
      <div className='folder-views-area'>
        {this.renderSearch()}
        {searchAndFilterOptions.onSortChange && (
          <SpaceContentSortDropdown
            onChange={searchAndFilterOptions.onSortChange}
            selected={searchAndFilterOptions.sortQuery}
            onDropDownToggle={this.sortDropDownToggle}
          />
        )}
        {searchAndFilterOptions.onFilterValueChange && (
          <SpaceFilterDropdown
            onChange={searchAndFilterOptions.onFilterValueChange}
            filterSource={searchAndFilterOptions.filterSource}
            filterValues={searchAndFilterOptions.filterValues}
            closeDropDown={this.state.sortDropDownOpen}
          />
        )}
        <CardGridViewSelector
          viewType={searchAndFilterOptions.cardViewType}
          onSelect={searchAndFilterOptions.onCardViewSelect}
        />
      </div>
    );
  }

  render() {
    if (
      this.props.verifyFilterAllowed &&
      !isValidNavFilter(this.props.list, this.props.currentFilter)
    ) {
      let filter = getDefaultFilter(this.props.list);
      this.props.onFilterClick(
        filter[filter.length - 1],
        filter[filter.length - 2],
      );
    }
    return (
      <article
        className='tab-nav-container'
        onScroll={this.setScrolling}
        ref={(el) => {
          this.navContainer = el;
        }}>
        <div
          className={
            'object-header-bar' + (this.props.asDropDown ? ' border-0' : '')
          }>
          {this.renderLists()}
          {this.props.sortDropDown && this.state.showDropDown && (
            <div className='transparent-overlay' />
          )}
          <ul className='list-inline object-view-switcher'>
            {this.props.sortDropDown && this.renderDropdown()}
            {this.renderMobileFilter()}
            {this.renderIndexExtraContent()}
          </ul>
          {this.props.searchAndFilterRequired && this.renderViewButtons()}
        </div>
      </article>
    );
  }
}

SimpleNavList.defaultProps = {
  casesIndexPage: false,
  comparisonsIndexPage: false,
  sortDropDown: false,
  searchAndFilterRequired: false,
  filterCountMapperForVisibilty: {},
  checkTabFeatureAllowedMapper: {},
  tabsRoundingCount: {},
  searchAndFilterOptions: {},
  searchText: '',
};
SimpleNavList = connect(
  ({ commentShare, device, currentUser }) => ({
    commentShare,
    device,
    currentUser,
  }),
  { commentShareChangeType, openUpgradeModal, bindMobileFilter },
)(SimpleNavList);

SimpleNavList = withRouter(SimpleNavList);

export default SimpleNavList;
