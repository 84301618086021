import React, { Component } from 'react';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import OsBtn from 'app/components/OsBtn/';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { avatarAttributes, getIconClass } from 'app/utils/entitiesHelper';
import { flatten, snakeCase } from 'app/utils/osLodash';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import EventTracker from 'app/services/EventTracker';
import { hexToRgb } from 'app/utils/colorHelper';

class CustomDropdown extends Component {
  state = {
    showDropDown: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('touchend', this.handleOutsideClick, false);

    if (
      this.dropdown &&
      (this.props.allowNonTextOption
        ? !this.dropdown.innerHTML.length
        : !this.dropdown.textContent.length)
    )
      this.setState({ hideMoreButton: true });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.closeDropDown &&
      this.props.closeDropDown &&
      this.state.showDropDown
    ) {
      this.toggleDropDownHandler();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchend', this.handleOutsideClick, false);
  }

  getListElementClassName(index, selected) {
    let className = `header-dropdown-list-item `;
    className +=
      index === this.props.activeElementIndex || selected
        ? 'list-selected'
        : '';
    return className;
  }

  getProps(index, node) {
    return {
      key: index,
      className: `${this.getListElementClassName(index, node.props.selected)} ${
        node.props.parentClassName || ''
      }`,
      ...(this.state.showDropDown && {
        onClick: this.optionClicked.bind(this, node),
      }),
    };
  }

  isChildrenPresent() {
    return flatten(this.props.children).filter((node) => node).length > 0;
  }

  renderItems() {
    return flatten(this.props.children)
      .filter((node) => node)
      .map((node, index) => <li {...this.getProps(index, node)}>{node}</li>);
  }

  optionClicked = (node, e) => {
    let key = node.key || node.props.label,
      clickedOption = snakeCase(key);
    EventTracker.trackDropdownClick(this.props.name, clickedOption);
    this.props.changeSort && this.props.changeSort(node.key);
    if (!this.props.skipToggleOnOptionSelect) this.toggleDropDownHandler(e);
  };

  hasItems() {
    return this.props.children.filter((a) => a).length >= 1;
  }

  toggleDropDownHandler = (e) => {
    if (this.props.disbaleClick) return;
    if (!this.props.avoidEventManipulation) {
      e && e.stopPropagation();
      e && e.preventDefault();
    }
    this.props.onClick(e);
    if (this.props.authenticationRequired) {
      this.props.redirectToDefaultIfUnauthenticated(
        this.toggleDropDown.bind(this, e),
      );
    } else {
      this.toggleDropDown(e);
    }
  };

  onClickHandler = (e) => {
    if (!this.props.skipToggleOnOptionSelect || !this.state.showDropDown)
      this.toggleDropDownHandler(e);

    if (this.props.flipTheDropdown !== undefined) {
      this.flipTheDropdown(e, this.dropdown);
    }
  };

  flipTheDropdown = (e, ref) => {
    if (
      Math.round(
        window.innerHeight - e.target.getBoundingClientRect().bottom,
      ) <= this.props.flipTheDropdown
    )
      ref.style.bottom = '40px';
  };

  onMouseOut = (e) => {
    if (!this.props.skipToggleOnOptionSelect && this.state.showDropDown)
      this.toggleDropDownHandler(e);
  };

  toggleDropDown = (e) => {
    if (this.hasItems() && !this.props.disabled) {
      let { showDropDown } = this.state;
      if (!showDropDown) {
        window.$('.header-dropdown-menu.dropdown-open').trigger('click');
        EventTracker.trackDropdownOpen(this.props.name);
        this.props.resetDropdownPosition &&
          this.props.resetDropdownPosition(
            window.$(this.node),
            window.$(this.dropdown),
          );
      }
      !showDropDown && this.props.onDropdownOpen();
      this.setState({ showDropDown: !showDropDown });
      if (this.state.showDropDown === true) this.dropdown.style.bottom = null;
      this.props.onDropDownToggle && this.props.onDropDownToggle(!showDropDown);
    }
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.state.showDropDown) {
      if (this.node.contains(e.target)) return;

      this.toggleDropDown();
      e.stopPropagation();
    }
  };

  getDropdownInfoName() {
    return (
      this.props.dropdownInfo.icon ||
      getIconClass(this.props.dropdownInfo.iconType)
    );
  }

  renderTitle() {
    if (this.props.dropdownInfo) {
      return (
        <>
          <OrthoIcon
            name={this.getDropdownInfoName()}
            defaultClass='sort-rel-icon first-elem-icon'
            dataHoverNotRequired={true}
          />
          <span className='pointer' key='chevron'>
            {this.props.dropdownInfo.title}
            {this.listRequired() && (
              <i
                className={`istoggleicon ifill-os-${this.props.chevronIcon}-${
                  this.state.showDropDown ? 'up' : 'down'
                }`}></i>
            )}
          </span>
        </>
      );
    } else if (this.props.viewType === 'navProfile') {
      return (
        <div
          className={`nav-item nav-profile__toggle ${
            this.state.showDropDown ? 'active' : ''
          }`}>
          <span className='nav-avatar-profile'>
            <Avatar
              className='avatar nav-profile__user-photo'
              {...avatarAttributes(this.props.user)}
            />
          </span>
          <OrthoIcon name='chevron-caret-down' />
        </div>
      );
    } else if (this.props.viewType === 'spaceActions') {
      let extraClass = this.props.extraClass ? this.props.extraClass : '';
      return (
        this.hasItems() && (
          <OrthoIcon name={this.props.icon} defaultClass={extraClass} />
        )
      );
    } else {
      let extraClass = this.props.osbtnClass || '';
      extraClass += 'no-text v-align-top card-btn-more ';
      extraClass += this.state.showDropDown ? 'osbtn-active ' : '';
      extraClass += this.props.extraClass ? this.props.extraClass : '';

      return (
        this.hasItems() && (
          <OsBtn
            name='BtnIcon'
            disabled={this.props.disabled}
            extraClass={extraClass}
            icon={this.props.icon}
          />
        )
      );
    }
  }

  hasMultipleChildren() {
    return (
      this.props.children && this.props.children.filter((a) => a).length > 1
    );
  }

  getTitleContainerClass() {
    let className = 'page-heading-title ';
    className += this.hasItems() && this.listRequired() ? 'cursor-pointer' : '';
    return className;
  }

  listRequired() {
    return !this.props.hideDropdown;
  }

  render() {
    let { className, dropdownClass, leftAligned } = this.props,
      { showDropDown } = this.state;

    let wrapperClassName = className
        ? `${className} header-dropdown-menu `
        : 'header-dropdown-menu ',
      dropdownClassName = `list-unstyled header-dropdown-list ${
        dropdownClass || ''
      }`;

    wrapperClassName += showDropDown ? 'dropdown-open' : 'dropdown-close';
    dropdownClassName += showDropDown ? ' ' : ' d-none';
    dropdownClassName += leftAligned ? ' left-align' : '';

    if (this.isChildrenPresent() && !this.state.hideMoreButton) {
      let style = this.props.dropdownInfo?.color
        ? {
            backgroundColor: `${hexToRgb(
              this.props.dropdownInfo?.color.replace(/#/g, ''),
            )}`,
          }
        : null;
      return (
        <div
          className={wrapperClassName}
          ref={(node) => {
            this.node = node;
          }}
          onClick={this.onClickHandler}>
          <div
            className={`position-relative dropdown-info-title ${this.props.titleContainerClasses}`}>
            <div
              className={
                'page-heading-title ' +
                (this.hasItems() ? 'cursor-pointer ' : ' ') +
                this.props.filterActive
              }>
              {style && <span className='badge-color' style={style}></span>}
              {this.renderTitle()}
            </div>
          </div>
          {this.listRequired() && (
            <ul
              className={dropdownClassName}
              ref={(dropdown) => (this.dropdown = dropdown)}>
              {this.renderItems()}
            </ul>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

CustomDropdown = withAuthenticate(CustomDropdown);
CustomDropdown = connect(({ currentUser }) => ({ currentUser }))(
  CustomDropdown,
);
CustomDropdown.defaultProps = {
  onDropdownOpen: () => {},
  onClick: () => {},
  chevronIcon: 'chevron',
  titleContainerClasses: ' ',
  skipToggleOnOptionSelect: false,
  disbaleClick: false,
  icon: 'more',
};
export default CustomDropdown;
