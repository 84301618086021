import React, { Component } from 'react';
import { connect } from 'react-redux';

import EventTracker from 'app/services/EventTracker';

import { commentShareChangeType } from 'app/actions/commentShare';
import { capitalize } from 'app/utils/stringHelper';
import { TYPE_MODEL_MAPPER } from './constants';
import { keys, reject, pick } from 'app/utils/osLodash';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

export const ENTITY_TABS = {
  case: 'Cases',
  // 'comparison': 'Comparisons',
  board: 'Spaces',
  // 'user': 'Users',
  tool: 'Tools',
  organization: 'Companies',
  file: 'Files',
  link: 'Links',
  video: 'Videos',
};

export const CARE_ENTITY_TABS = { ...pick(ENTITY_TABS, 'file', 'video') };

// List view for the comment share
class MainNav extends Component {
  tabRefs = {};

  onClick = (filterValue) => {
    this.props.commentShareChangeType(filterValue);
    this.props.resetState();
    EventTracker.trackSelectTabEvent(filterValue);
  };

  componentDidMount() {
    this.scrollToSelectedTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.device.width !== this.props.device.width)
      this.scrollToSelectedTab();
  }

  scrollToSelectedTab() {
    if (this.tabRefs[this.props.commentShare.type])
      this.tabRefs[this.props.commentShare.type].scrollIntoView({
        behaviour: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
  }

  objectsCount(type) {
    if (type === 'video') {
      return this.props.objects.filter((obj) => obj.recorded).length;
    }
    let typename = TYPE_MODEL_MAPPER[type] || capitalize(type);
    return this.props.objects.filter(
      (obj) => obj.__typename === typename && !obj.recorded,
    ).length;
  }

  listItem(filterValue) {
    let classNames = `list-inline-item ${
        this.props.commentShare.type === filterValue
          ? 'object-filter-active'
          : ''
      }`,
      type = ENTITY_TABS[filterValue],
      objectsCount = this.objectsCount(filterValue),
      displayCount = objectsCount > 0 ? `(${objectsCount})` : '';
    type =
      this.props.currentUser.graph.is_general_user && type === 'Users'
        ? 'Members'
        : type;
    return (
      <li
        key={type}
        className={classNames}
        ref={(tab) => (this.tabRefs[filterValue] = tab)}
        onClick={this.onClick.bind(this, filterValue)}>
        {type} <span className='os-orange'>{displayCount}</span>
      </li>
    );
  }

  listItemIfPermitted(filterValue) {
    if (
      filterValue !== 'case' ||
      this.props.currentUser.ability.can('index', 'case', {})
    )
      return this.listItem(filterValue);
  }

  getListItem(list) {
    return list.map((key) => this.listItemIfPermitted(key));
  }

  getTabs() {
    let tabs = this.props.typesToBeDisplayed;
    return tabs;
  }

  renderEntityTabs() {
    let tabsList = this.getTabs().filter((tab) => tab !== 'file');
    if (this.props.tabsNotRequired) {
      tabsList = reject(tabsList, (key) =>
        this.props.tabsNotRequired.includes(key),
      );
    }
    return this.getListItem(tabsList);
  }

  render() {
    return (
      <ul className='object-filter list-inline'>
        {this.props.canExternalShare &&
          this.props.typesToBeDisplayed.includes('file') &&
          this.listItem('file')}
        {this.props.canInternalShare && this.renderEntityTabs()}
      </ul>
    );
  }
}

MainNav.defaultProps = {
  typesToBeDisplayed: keys(
    isCareWorkspaceView() ? CARE_ENTITY_TABS : ENTITY_TABS,
  ),
};

MainNav = connect(
  ({ commentShare, device, currentUser }) => ({
    commentShare,
    device,
    currentUser,
  }),
  { commentShareChangeType },
)(MainNav);
export default MainNav;
