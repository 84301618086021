export const sidebarCareNotificationAvailable = (isNotification) => {
  if (isNotification) {
    return (dispatch) => {
      dispatch({
        type: 'NOTIFICATION_AVAILABLE',
        payload: {
          isNotificationAvailable: true,
        },
      });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: 'NOTIFICATION_UNAVAILABLE',
        payload: {
          isNotificationAvailable: false,
        },
      });
    };
  }
};
