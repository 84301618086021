import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';
import PostMessageField from 'app/components/Shared/PostMessageField';

import {
  fetchFileExtension,
  getFileNameWithoutExtension,
} from 'app/utils/fileHelper';
import { entityIsAttachment, getEntityName } from 'app/utils/entitiesHelper';

const RENAME_ENTITY_MUTATION = queryLoader(
  'app/graphql/mutations/RenameEntityObject.gql',
);

class RenameEntity extends Component {
  state = {
    renameEnabled: false,
    formNode: null,
  };
  el = document.createElement('div');

  componentDidMount() {
    let formNode = document.getElementById(this.props.formNode);
    this.setState({ formNode }, () => {
      this.state.formNode && this.state.formNode.appendChild(this.el);
    });
  }

  componentWillUnmount() {
    this.state.formNode && this.state.formNode.removeChild(this.el);
  }

  getEntityName(name) {
    let { obj } = this.props;
    return entityIsAttachment(obj)
      ? `${name}.${fetchFileExtension(obj.name)}`
      : name;
  }

  getEntityProps(name) {
    return { ...this.props.obj, name: name, title: name };
  }

  onSubmit = (values) => {
    let name = this.getEntityName(values.name);
    this.props
      .renameEntityMutation({
        variables: {
          entityType: this.props.entityType,
          entityId: this.props.entityId,
          name,
        },
      })
      .then(({ data }) => {
        this.closeRenameForm();
        this.props.onRename(data.renameEntity.entity);
      });
  };

  closeRenameForm() {
    this.setRenameFormVisibility(false);
  }

  openRenameForm = () => {
    this.setRenameFormVisibility(true);
  };

  setRenameFormVisibility(renameEnabled) {
    this.setState({ renameEnabled });
    this.props.onClick(renameEnabled);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  getPlaceHolderName() {
    return entityIsAttachment(this.props.obj)
      ? 'Write the file name...'
      : 'Write URL title...';
  }

  renderEntityName(obj) {
    return entityIsAttachment(obj)
      ? getFileNameWithoutExtension(obj.name)
      : getEntityName(obj);
  }

  formBody() {
    let { obj } = this.props;
    return (
      <div onClick={this.stopPropagation}>
        <PostMessageField
          autofocus={this.state.renameEnabled}
          onSubmit={this.onSubmit}
          form={this.props.formNode}
          name='name'
          iconName='tick'
          placeholder={this.getPlaceHolderName()}
          initialValues={{ name: this.renderEntityName(obj) }}
        />
      </div>
    );
  }

  renderForm() {
    if (this.state.formNode)
      return ReactDOM.createPortal(this.formBody(), this.el);
  }

  render() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='edit'
        associatedEntity={this.props.obj}
        extraClass={`list-button web-view-btn ${this.props.extraClass}`}
        text={this.props.label}
        onClick={this.openRenameForm}>
        {this.renderForm()}
      </OsBtn>
    );
  }
}

RenameEntity.defaultProps = {
  extraClass: '',
  label: 'Rename',
  onRename: () => {},
};
RenameEntity = graphql(RENAME_ENTITY_MUTATION, {
  name: 'renameEntityMutation',
})(RenameEntity);
export default RenameEntity;
