export function getDefaultFilter(list) {
  return getFlattenList(list)[0];
}

export function getFlattenList(list) {
  let flattenList = [];
  list.forEach((data) =>
    data[0].constructor === Array
      ? (flattenList = flattenList.concat(data))
      : flattenList.push(data),
  );
  return flattenList;
}

export function isValidNavFilter(list, filter) {
  return getFlattenList(list).some((data) => data[data.length - 1] === filter);
}
