import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';

import ContentWithSidebars from 'app/components/ContentWithSidebars';
import HTMLContentContainer from 'app/components/Shared/HTMLContentContainer';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';

import EventTracker from 'app/services/EventTracker';

import { setWorkspaceData } from 'app/actions/workspace';

import {
  canEditInlineInformation,
  getSpaceTabSource,
} from 'app/utils/spaceHelper';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

const UPDATE_INFORMATION_TAB_CONTENT_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateInformationTabContent.gql',
);
const UPDATE_SPACE_INFORMATION_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateSpaceInformation.gql',
);

class SpaceInformationView extends Component {
  state = {
    readOnly: true,
  };

  onInfoContentClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      e.stopPropagation();
      e.preventDefault();
      window.open(e.target.href);
      EventTracker.trackLinkClicked(e.target.text);
    }
  };

  getSpace() {
    let space = this.props.data.space || this.props.data.guestUserSpace;
    return space ? space : getWorkspaceData();
  }

  isGuestUser() {
    return !this.props.currentUser.graph;
  }

  toggleInlineEditing = () => {
    this.setState(
      {
        readOnly: !this.state.readOnly,
        resetContent: true,
        error: '',
        requestInProgess: false,
      },
      () => {
        setTimeout(() => {
          this.setState({ resetContent: false });
        }, 500);
      },
    );
  };

  updateWorkspaceInfoTabContentInReducer(infoTab) {
    let workspace = { ...getWorkspaceData() };
    if (workspace.id) {
      let infoNavTab = workspace.nav_tabs.find((tab) => tab.id === infoTab.id);
      if (infoNavTab) {
        infoNavTab.info_content = infoTab.info_content;
        this.props.setWorkspaceData(workspace);
      }
    }
  }

  saveInformation = () => {
    let isNavTabStructure = this.isNavTabStructure();
    this.setState({ requestInProgess: true });
    let mutationName = isNavTabStructure
        ? 'updateInformationTabContentMutation'
        : 'updateSpaceInformationMutation',
      variables = {
        information: ""
      };

    variables['id'] = isNavTabStructure
      ? this.props.tabDetails.id
      : this.getSpace().nice_id;
    this.props[mutationName]({
      variables,
    }).then(({ data }) => {
      isNavTabStructure &&
        this.updateWorkspaceInfoTabContentInReducer(
          data.updateInformationTabContent,
        );
      this.toggleInlineEditing();
    });
  };

  renderInformationHeader(space) {
    if (canEditInlineInformation(space)) {
      if (this.state.readOnly) {
        return (
          <div className='edit-info-head'>
            <OsBtn
              extraClass='edit-info-link ms-auto with-border'
              text='Edit'
              name='BtnSecondary'
              onClick={this.toggleInlineEditing}
              associatedEntity={space}
            />
          </div>
        );
      } else {
        let disabled = this.state.requestInProgess;
        return (
          <div className={`edit-info-head ${disabled ? 'event-none' : ''}`}>
            <OsBtn
              extraClass='ms-auto px-16'
              text='Cancel'
              onClick={this.toggleInlineEditing}
              associatedEntity={space}
              name='BtnIcon'
            />
            <OsBtn
              extraClass='ms-2'
              text={disabled ? 'Saving' : 'Save'}
              onClick={this.saveInformation}
              associatedEntity={space}
              name='BtnPrimary'
              disabled={disabled}
              loaderRequired={disabled}
            />
          </div>
        );
      }
    }
  }

  isNavTabStructure() {
    return !!this.props.tabDetails;
  }

  fullWidthRequired() {
    return (
      this.isNavTabStructure() && this.props.tabDetails.kind !== 'information'
    );
  }

  spaceExtraInfoRequired() {
    return this.props.tabDetails.kind === 'information';
  }

  renderContent(space) {
    let parentClass = 'information-tab editor-container mt-24 ',
      information = this.isNavTabStructure()
        ? this.props.tabDetails.info_content
        : space.information;

    if (this.state.error) parentClass += 'field-with-error ';

    if (this.state.readOnly) parentClass += 'info-read-only';

    return (
      <div className={parentClass}>
        {this.renderInformationHeader(space)}
        <HTMLContentContainer content={information} />
        {this.state.error && <div className='form-error'>Required</div>}
      </div>
    );
  }

  renderGridContainer(space) {
    return (
      <div className='xl-container container-fluid px-52'>
        <OsGrid identifier={'BoardDetail:InformationFullView'}>
          {this.renderContent(space)}
        </OsGrid>
      </div>
    );
  }

  renderContentWithSidebar(space) {
    let { is_author_or_collaborator } = space;

    return (
      <ContentWithSidebars
        childGridIdentifier={'BoardDetail:Timeline'}
        rightSidebarGridIndentifier={'BoardDetail:TimelineRightSidebar'}
        source={getSpaceTabSource(space, 'information', this.isGuestUser())}
        isAuthorOrCollaborator={is_author_or_collaborator}
        parentClass='activity-container xl-container container-fluid px-hd-40 px-sm-32 px-m-12 '
        sideBarClass=' sidebar-with-cards'
        options={{ widgetSourceObject: space }}
        widgetsRequiredOnMobile={true}>
        {this.renderContent(space)}
      </ContentWithSidebars>
    );
  }

  renderGridOrContentWithSidebar(space) {
    if (this.props.fullWidth) {
      return this.renderGridContainer(space);
    } else {
      return this.renderContentWithSidebar(space);
    }
  }

  render() {
    const space = this.getSpace();

    return <>{this.renderGridOrContentWithSidebar(space)}</>;
  }
}

SpaceInformationView.defaultProps = {
  scrollIfNeeded: () => {},
};
SpaceInformationView = compose(
  graphql(UPDATE_INFORMATION_TAB_CONTENT_MUTATION, {
    name: 'updateInformationTabContentMutation',
  }),
  graphql(UPDATE_SPACE_INFORMATION_MUTATION, {
    name: 'updateSpaceInformationMutation',
  }),
)(SpaceInformationView);
SpaceInformationView = connect(
  ({ currentUser, device, guestUser }) => ({ currentUser, device, guestUser }),
  { setWorkspaceData },
)(SpaceInformationView);
export default SpaceInformationView;
