import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { setActiveConversation } from 'app/actions/activeConversation';
import * as compose from 'lodash.flowright';

import Avatar from 'app/components/Shared/Avatar';
import NewMessage from 'app/components/TeamSpaceCommunicationsView/NewMessage';
import NoChatMessage from 'app/components/TeamSpaceCommunicationsView/NoChatMessage';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';
import RecordsView from 'app/components/RecordsView';
import UsersInfoModal from 'app/components/Shared/UsersInfoModal';

import EventTracker from 'app/services/EventTracker';
import LocalStorageManager from 'app/services/LocalStorageManager';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import { pluralize } from 'app/utils/generalHelper';
import {
  avatarAttributes,
  entityUrl,
  getOtherParticipants,
} from 'app/utils/entitiesHelper';
import { isEmpty } from 'app/utils/osLodash';

import {
  CONVERSATION_MESSAGES_BATCH_SIZE,
  PINNED_CONVERSATIONS_BATCH_SIZE,
} from 'app/constants';
import PinnedMessageModal from 'app/components/TeamSpaceCommunicationsView/PinnedMessageModal';
import { PinnedMessageContext } from 'app/context/PinnedMessageContext';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import TeamNavTabs from 'app/components/TeamNavBar/TeamNavTabs';
import TeamNavContent from 'app/components/TeamNavBar/TeamNavContent';
import { shouldShowTeamNavTab } from 'app/components/TeamNavBar/teamNavHelper';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';

import 'app/components/TeamSpaceCommunicationsView/dm-chat-window.scss';

const CONVERSATION_QUERY = queryLoader('app/graphql/Conversation.gql');
const PINNED_CONVERSATION = queryLoader(
  'app/graphql/queries/Conversations/ConversationPinnedChat.gql',
);

const REPLACEBALE_URL_IDS = ['recent', 'support'];

// Entire Conversation
class TeamChatWindowWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinnedChatModalVisible: false,
      pinnedChatTarget: null,
      pinnedMessages: [],
      pinnedMessageCount: props.data.conversation?.pinned_message_count || 0,
    };
    this.pinnedRef = React.createRef();
  }

  setPinnedMessages = (pinnedMessages = [], total = 0) => {
    this.setState((prev) => ({
      ...prev,
      pinnedMessages,
      pinnedMessageCount: total || pinnedMessages.length,
    }));
  };

  componentDidMount() {
    EventTracker.track('Show Conversation', {
      conversation_id: this.props.id,
      entity_type: this.props.entity_type,
      nice_id: this.props.nice_id,
    });
    // setTimeout(() => document.body.classList.add('chat-detail'), 100);
    if (!this.props.data.loading) this.setActiveConversationInLocalStorage();
    IntercomTracker.hideWidgetLauncher();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.pinnedMessageQuery.loading &&
      !nextProps.pinnedMessageQuery.loading
    ) {
      const { pinnedMessageQuery } = nextProps;
      this.setPinnedMessages(
        pinnedMessageQuery?.records?.results,
        pinnedMessageQuery?.records?.total,
      );
    }

    if (this.props.data.loading && !nextProps.data.loading) {
      let { conversation } = nextProps.data,
        { entity_type, nice_id, redirectPath, match } = nextProps;
      const { state = {} } = this.props.location;
      this.props.setActiveConversation(nextProps.data.conversation);

      if (isEmpty(conversation)) {
        // When conversation is not found
        if (match.params.id === 'user' && match.params?.filter) {
          this.props.navigate(`/team/dm/new?userId=${match.params.filter}`, {
            state,
            replace: true,
          });
        } else if (entity_type) {
          return this.props.goBack();
        } else {
          return this.props.navigate(withWorkspaceIdentifier(redirectPath));
        }
      } else if (match.params.id === 'user' && match.params?.filter) {
        // User found with existing conversation & URL has entity info
        this.props.navigate(
          withWorkspaceIdentifier(`/team/dm/${conversation.id}`, true),
          { replace: true, state, skip: true },
        );
      } else if (nice_id || REPLACEBALE_URL_IDS.includes(this.props.id)) {
        // When conversation is found & URL has entity info
        window.history.replaceState(
          state,
          '',
          withWorkspaceIdentifier(`/team/dm/${conversation.id}`, true),
        );
      }
    }
  }

  componentWillUnmount() {
    // document.body.classList.remove('chat-detail');
    this.props.setActiveConversation(null);
    IntercomTracker.showWidgetLauncher();
  }

  setActiveConversationInLocalStorage() {
    LocalStorageManager.setActiveConversation(this.props.data.conversation);
  }

  getConversationName(otherParticipants) {
    let { kind } = this.props.data.conversation,
      otherParticipant = otherParticipants[0];

    return (
      <>
        {kind === 'normal' && (
          <>
            <OsLink
              className='white-link white-link-blue'
              to={entityUrl(otherParticipant)}
              text={otherParticipant.name || otherParticipant.email}
            />
          </>
        )}
      </>
    );
  }

  showGroupInfo = () => {
    this.setState({ groupInfoVisible: true });
  };

  closeGroupInfo = () => {
    this.setState({ groupInfoVisible: false });
  };

  renderAvatar(entity) {
    return (
      <Link to={entityUrl(entity)} className='user-conversation-avatar'>
        <Avatar
          {...avatarAttributes(entity)}
          className='avatar  avatar-initials'
        />
      </Link>
    );
  }

  isGroupChat(otherParticipants) {
    return otherParticipants.length > 1;
  }

  renderChatHeader(otherParticipants) {
    let { conversation } = this.props.data,
      { kind } = conversation,
      otherParticipant = otherParticipants[0];
    const { pinnedMessageCount } = this.state;
    const { device } = this.props;
    return (
      <div className='dm-chat-header'>
        {otherParticipants.length < 2 && (
          <div className='user-details-single-user header-info'>
            <div className='left-header'>
              <Link
                to={withWorkspaceIdentifier('/team')}
                className='dm-back-btn'>
                <OrthoIcon name='chevron-left' dataHoverNotRequired={true} />
              </Link>
              {kind === 'normal' && this.renderAvatar(otherParticipant)}
              <div>
                <OrthoIcon name='square' dataHoverNotRequired={true} />
              </div>
              <div className='space-detail'>
                <span className='title'>
                  {this.getConversationName(otherParticipants)}
                </span>
                <div className='info'>
                  <span onClick={this.handlePinnedChat}>
                    <OrthoIcon name='pin' dataHoverNotRequired={true} />{' '}
                    <span className='pinned-count-text'>
                      {pinnedMessageCount} pinned{' '}
                      {pluralize(pinnedMessageCount, {
                        singular: 'message',
                        plural: 'messages',
                      })}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className='right-header'>
              {/* <OrthoIcon name="search" dataHoverNotRequired={true} />
          <OrthoIcon name="setting" dataHoverNotRequired={true} />
          <OrthoIcon name="more" dataHoverNotRequired={true} /> */}
            </div>
          </div>
        )}

        {shouldShowTeamNavTab(device) && (
          <div className='team-nav-list'>
            <TeamNavTabs
              source={'teamSpaceDirectMessenger'}
              sourceId={conversation.id}
            />
          </div>
        )}
      </div>
    );
  }

  handlePinnedChat = (event) => {
    this.setState({
      pinnedChatModalVisible: !this.state.pinnedChatModalVisible,
      pinnedChatTarget: event.target,
    });
  };

  hidePinnedChat = () => {
    this.setState((prev) => ({
      ...prev,
      pinnedChatModalVisible: false,
    }));
  };

  renderChatContainer = () => {
    let { conversation } = this.props.data,
      otherParticipants = getOtherParticipants(conversation),
      isGroupChat = this.isGroupChat(otherParticipants);
    return (
      <>
        <RecordsView
          type='ConversationMessagesListing'
          conversation={conversation}
          key={conversation.id}
          idQuery={conversation.id?.toString()}
          perPage={CONVERSATION_MESSAGES_BATCH_SIZE.fullView}
          windowNotRequired={true}
          isReverse={true}
          threshold={1}
          fetchPolicy='network-only'
          size='full-view-listing'
          className='list-messages'
          trackScrolling={true}
          parentContainer={this.parentContainer}
          isGroupChat={isGroupChat}
        />

        {conversation.can_participate && (
          <NewMessage
            conversation={conversation}
            conversationId={conversation.id}
            kind={conversation.kind}
            otherParticipants={otherParticipants}
            disabled={!conversation.can_participate}
            isFullPageListing={true}
          />
        )}
      </>
    );
  };

  renderContent() {
    let { conversation } = this.props.data;
    return (
      <TeamNavContent
        entity={conversation}
        source={'teamSpaceDirectMessenger'}
        ref={(el) => (this.parentContainer = el)}>
        {this.renderChatContainer()}
      </TeamNavContent>
    );
  }

  render() {
    if (this.props.data.loading)
      return (
        <Loader type='ball-triangle-path' active className='full-wh-loader' />
      );

    if (!this.props.data.conversation) return <NoChatMessage />;

    let { conversation } = this.props.data,
      otherParticipants = getOtherParticipants(conversation),
      isGroupChat = this.isGroupChat(otherParticipants);

    const { pinnedMessages, pinnedMessageCount } = this.state;
    const { setPinnedMessages } = this;

    return (
      <PinnedMessageContext.Provider
        value={{
          pinnedMessageCount,
          pinnedMessages,
          setPinnedMessages,
        }}>
        <div className='conversation conversation-chat-section with-top-border'>
          <SetPageTitle
            source={'direct_message'}
            interpolations={otherParticipants[0].full_name}
          />
          <div
            className={
              'dm-chat-window' + (isGroupChat ? ' group-message' : '')
            }>
            {this.renderChatHeader(otherParticipants)}
            {this.renderContent()}
          </div>

          <UsersInfoModal
            show={this.state.groupInfoVisible}
            users={otherParticipants}
            heading='Chat Participants'
            closeModal={this.closeGroupInfo}
          />
          <div ref={this.pinnedRef} className='pinned-tooltip'>
            <PinnedMessageModal
              show={this.state.pinnedChatModalVisible}
              pinnedRef={this.pinnedRef.current}
              target={this.state.pinnedChatTarget}
              entity={conversation}
              onHide={this.hidePinnedChat}
            />
          </div>
        </div>
      </PinnedMessageContext.Provider>
    );
  }
}

const TeamChatWindowConnect = connect(({ device }) => ({ device }), {
  setActiveConversation,
})(TeamChatWindowWrapper);
const TeamChatWindowRouter = withRouter(TeamChatWindowConnect);

const TeamChatWindow = compose(
  graphql(CONVERSATION_QUERY, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.id === 'user' ? null : props.id,
        entity_type: props.entity_type,
        nice_id: props.nice_id,
      },
    }),
  }),
  graphql(PINNED_CONVERSATION, {
    name: 'pinnedMessageQuery',
    options: (props) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id_query: props.id,
          page: 0,
          perPage: PINNED_CONVERSATIONS_BATCH_SIZE,
          type: 'ConversationPinnedMessagesListing',
        },
      };
    },
  }),
)(TeamChatWindowRouter);

export default TeamChatWindow;
