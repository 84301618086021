import { SET_NEW_POST, RESET_NEW_POST } from './types';

export function setNewPost(newPostId) {
  return {
    type: SET_NEW_POST,
    newPostId,
  };
}

export function resetNewPost() {
  return {
    type: RESET_NEW_POST,
  };
}
