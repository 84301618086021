import dayjs from 'dayjs';

const NON_RECURSIVE = 'non_recursive';
const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'weekend',
];
const useCardMovement = () => {
  const isCardMovementAllowed = (fromColumnId, toColumnId, card, dueDate) => {
    if (fromColumnId === toColumnId) {
      return false;
    } else if (card.recursive_type !== NON_RECURSIVE) {
      return false;
    } else if (toColumnId === 'overdue') {
      return false;
    } else if (
      fromColumnId === 'overdue' &&
      dayjs().isAfter(dueDate.format('YYYY-MM-DD'), 'day')
    ) {
      return false;
    }
    return true;
  };

  const moveCardAndReturnBoard = (fromColumnId, toColumnId, card, kBoard) => {
    const updateBoard = {};
    let updateFromColumnCards = [];
    if (fromColumnId === 'overdue') {
      updateFromColumnCards = kBoard.columns
        .find((col) => col.id === fromColumnId)
        .cards.filter((colCard) => colCard.id !== card.id + '_overdue');
    } else {
      updateFromColumnCards = kBoard.columns
        .find((col) => col.id === fromColumnId)
        .cards.filter((colCard) => colCard.id !== card.id);
    }
    const updateToColumnCards = [
      card,
      ...kBoard.columns.find((col) => col.id === toColumnId).cards,
    ];
    updateBoard.columns = kBoard.columns.map((col, index) => {
      if (col.id === fromColumnId) {
        return {
          ...col,
          cards: updateFromColumnCards,
        };
      }
      if (col.id === toColumnId) {
        return {
          ...col,
          cards: updateToColumnCards,
        };
      }
      return fromColumnId === 'overdue'
        ? { ...col, cards: col.cards.filter((task) => task.id !== card.id) }
        : col;
    });
    return updateBoard;
  };

  const isOverdueUpdateRequired = (toColumnId, fromColumnId, card) => {
    const currentDay = dayjs().day();
    const addCard =
      currentDay > days.indexOf(toColumnId) + 1 &&
      currentDay <= days.indexOf(fromColumnId) + 1;
    const removeCard =
      currentDay <= days.indexOf(toColumnId) + 1 &&
      currentDay > days.indexOf(fromColumnId) + 1;
    return {
      updateRequired: (addCard || removeCard) && !card?.completed,
      addCard,
      removeCard,
    };
  };

  const updateOverdueTasks = (overdue, setOverdue, card, addCard) => {
    if (addCard) {
      const cardWithUpdatedId = {
        ...card,
        id: card.id + '_overdue',
        completed: false,
      };
      setOverdue((prevState) => [...prevState, cardWithUpdatedId]);
    } else {
      setOverdue((prevState) =>
        prevState.filter(
          (overdueCard) =>
            overdueCard.id !== card.id.split('_')[0] + '_overdue',
        ),
      );
    }
  };

  return {
    isCardMovementAllowed,
    moveCardAndReturnBoard,
    isOverdueUpdateRequired,
    updateOverdueTasks,
  };
};

export default useCardMovement;
