import React, { Component } from 'react';

import Avatar from 'app/components/Shared/Avatar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import SelectInput from 'app/components/Shared/SelectInput';

import { ROLES_LIST, ROLES_OPTIONS } from './constant';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import { translate } from 'app/actions/flashMessage';
import { keys, map, sortBy } from 'app/utils/osLodash';
import { editorToggleColumnRequired } from 'app/utils/spaceHelper';

export default class AbstractInviteMember extends Component {
  memberRole() {
    return ROLES_LIST[0][1];
  }

  editorRole() {
    return ROLES_LIST[1][1];
  }

  getFilteredMembers(list) {
    let filteredList = list;
    if (this.state.searchText) {
      filteredList = filteredList.filter((member) => {
        let emailPresent = member.email
          ? member.email.toLowerCase().includes(this.state.searchText)
          : false;
        return (
          emailPresent ||
          member.full_name
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        );
      });
    }
    return filteredList;
  }

  getSortedList(list) {
    return sortBy(list, (obj) => obj.full_name.toLowerCase());
  }

  getDropdownInfo(member) {
    return { title: member.role };
  }

  assignRoleToSelectedMember = (selectedMember, role) => {
    if (
      selectedMember.id === role.value ||
      selectedMember.full_name === role.value
    ) {
      selectedMember.role = role.name;
    }
    return selectedMember;
  };

  setRole = (role) => {
    let selectedMembers = this.state.selectedMembers;
    map(selectedMembers, (selectedMember) => {
      this.assignRoleToSelectedMember(selectedMember, role);
    });
    this.setState({ selectedMembers }, () => {
      this.props.setInvitedMembers(this.state.selectedMembers);
    });
  };

  renderRoles = (role, member) => {
    return (
      <div
        className='list-type-label'
        onClick={this.setRole.bind(this, role, member)}>
        {role[1]}
      </div>
    );
  };

  getRoles(member) {
    let value = member.id || member.name,
      optionsNeeded = keys(ROLES_OPTIONS).map((option) => {
        return {
          value,
          label: (
            <span>
              <OrthoIcon name={ROLES_OPTIONS[option].iconName} />
              {ROLES_OPTIONS[option].label}
            </span>
          ),
          name: option,
        };
      }),
      options = [optionsNeeded[0]];
    if (member.role === 'Member') {
      options.unshift(optionsNeeded[2]);
    } else {
      options.unshift(optionsNeeded[1]);
    }
    return options;
  }

  onRoleBoxClick = (e) => {
    e.stopPropagation();
  };

  unselectMemberFromList = (selectedMember) => {
    let selectedMemberList = this.state.selectedMembers.filter((member) =>
      this.removeFromSelectedList(member, selectedMember),
    );
    this.setState(
      { selectedMembers: selectedMemberList, showInviteUsersHeading: true },
      () => {
        this.props.setInvitedMembers(this.state.selectedMembers);
      },
    );
  };

  settingRoleFromCheckbox = (member, e) => {
    let role = { value: member.id || member.name };
    if (e.target.checked) {
      role.name = 'Editor';
    } else {
      role.name = 'Member';
    }
    this.setRole(role);
  };

  optionChange = (member, optionClicked) => {
    let roles = ROLES_LIST.map((role) => role[1]);
    if (roles.includes(optionClicked.name)) {
      this.setRole({
        value: member.id || member.name,
        name: optionClicked.name,
      });
    } else {
      this.unselectMemberFromList(member);
    }
  };

  editorCheckboxRequired() {
    return !this.props.board || editorToggleColumnRequired(this.props.board);
  }

  renderMembers(member, selected = false, isGroupSpace = false) {
    let iconName = selected ? 'roundCheck' : 'roundCheckDefault',
      isEditor = member.role === this.editorRole();

    return (
      <>
        <div className='d-flex align-items-center invite-avtar-name'>
          <Avatar className='avatar avatar-32' {...avatarAttributes(member)} />
          <span className='doctor-name ps-3'>{member.full_name}</span>
        </div>
        <div
          className='ms-auto d-flex align-items-center three-dot-menu'
          onClick={this.onRoleBoxClick}>
          {false && selected && this.canEditRole() && (
            <SelectInput
              className='react-select-ortho-2 three-dot'
              options={this.getRoles(member)}
              onChange={this.optionChange.bind(this, member)}
              isSearchable={false}
              menuPortalTarget={true}
            />
          )}
        </div>
        {!selected && (
          <OrthoIcon
            name={iconName}
            defaultClass='icon-helper ms-auto'
            dataHoverNotRequired={true}
          />
        )}
        {selected && !isGroupSpace && this.canEditRole() && this.editorCheckboxRequired() && (
          <OsField
            osType='checkbox'
            onChange={this.settingRoleFromCheckbox.bind(this, member)}
            className='editor-custom-checkbox'
            label='Editor'
            isChecked={isEditor}
          />
        )}
        {selected && (
          <OrthoIcon
            name='close'
            onClick={this.unselectMemberFromList.bind(this, member)}
          />
        )}
      </>
    );
  }

  noResultFound(searchText) {
    return translate('RESULTS_NOT_FOUND', { searchInput: searchText }, true, {
      connector: '',
    });
  }
}
