import {
  CLOSE_PARTNER_SPACE_MODAL,
  OPEN_PARTNER_SPACE_MODAL,
} from 'app/actions/types';

export function openPartnerSpaceInviteModal() {
  return {
    type: OPEN_PARTNER_SPACE_MODAL,
  };
}

export function closePartnerSpaceInviteModal() {
  return {
    type: CLOSE_PARTNER_SPACE_MODAL,
  };
}
