import React, { Component } from 'react';
import { connect } from 'react-redux';

import CaseRightSection from 'app/components/CaseRightSection';
import CaseTreatmentSection from 'app/components/CaseTreatmentSection';
import ConnectToSupport from 'app/components/ConnectToSupport';
import EntityDropdown from 'app/components/EntityDropdown';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import { entityIsCase } from 'app/utils/entitiesHelper';

import { setRightSectionVisibility } from 'app/actions/lectureView';

export default class CaseRightSectionContainer extends Component {
  renderCTA = () => {
    return <EntityDropdown entity={this.props.kase} />;
  };

  renderGetInTouchButton() {
    return (
      <ConnectToSupport
        viewType='button'
        buttonType='BtnIcon'
        className='px-8'
        text='Get In Touch'
        associatedEntity={this.props.case}
        icon='support'
        extraClass='pull-right getin-touch-btn'
      />
    );
  }

  backToCurriculumButton() {
    if (
      this.props.lectureView.entity &&
      entityIsCase(this.props.lectureView.entity.linkable) &&
      !this.props.device.mobileDevice
    )
      return (
        <OsBtn
          name='BtnIcon'
          text='back to content'
          extraClass='back-to-curriculum'
          onClick={this.props.setRightSectionVisibility.bind(this, true)}
          icon='chevron-left'
        />
      );
  }

  renderTreatmentSection(kase) {
    let extraClass = 'case-timeline-right-section';
    extraClass += this.props.visible ? '' : ' d-none';
    return (
      <OsGrid identifier='CaseDetail:CaseRightSection' extraClass={extraClass}>
        {this.backToCurriculumButton()}
        <div className='case-timeline-right-section prioritized-listing'>
          {this.renderGetInTouchButton()}
          {this.renderCaseTreatmentSection(kase)}
        </div>
      </OsGrid>
    );
  }

  renderCaseTreatmentSection(kase) {
    return <CaseTreatmentSection case={kase} />;
  }

  render() {
    let { kase } = this.props;
    if (kase.is_in_progress && false) {
      return this.renderTreatmentSection(kase);
    } else {
      return (
        <CaseRightSection
          case={kase}
          visible={this.props.visible}
          careSpace={this.props.careSpace}
        />
      );
    }
  }
}

CaseRightSectionContainer.defaultProps = {
  careSpace: null,
  visible: true,
};

CaseRightSectionContainer = connect(
  ({ caseDetailInfo, currentUser, device }) => ({
    caseDetailInfo,
    currentUser,
    device,
  }),
  { setRightSectionVisibility },
)(CaseRightSectionContainer);
