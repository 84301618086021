import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import OsLink from 'app/components/OsLink';

import { slice, sortBy } from 'app/utils/osLodash';

import EventTracker from 'app/services/EventTracker';

export default class RelatedTagsSection extends Component {
  state = {
    viewAllTags: false,
  };

  renderViewAllLink() {
    let { tags } = this.props.case;
    if (tags.length > 10)
      return (
        <OsLink
          className='tag-view-more'
          onClick={this.onViewAllClick}
          text={this.state.viewAllTags ? 'See Less' : 'See All'}
        />
      );
  }

  onViewAllClick = () => {
    this.setState({ viewAllTags: !this.state.viewAllTags }, () => {
      if (this.state.viewAllTags)
        EventTracker.trackForEntity('case_tags_all', this.props.case);
    });
  };

  onTagClick = (tag) => {
    EventTracker.trackForEntity('case_tag_show', {
      ...this.props.case,
      tag: tag.name,
    });
  };

  renderTag = (tag) => {
    let text = `${this.props.hashTagRequired ? '#' : ''}${tag.name}`;
    return (
      <Link
        to={`/search/cases/tags/id/${tag.id}`}
        key={tag.id}
        onClick={this.onTagClick.bind(this, tag)}
        className='btn tag-item'>
        {text}
      </Link>
    );
  };

  renderTags() {
    let { tags } = this.props.case;
    tags = sortBy(tags, [
      (o) => {
        return -o.total_cases_count;
      },
    ]);
    let filterTags = this.state.viewAllTags ? tags : slice(tags, 0, 10);
    return filterTags.map(this.renderTag);
  }

  render() {
    let { tags } = this.props.case;
    if (!tags || (tags && tags.length === 0)) return null;

    return (
      <div className='cases-tags-block col-12'>
        <div className='related'>
          {this.props.headingRequired && (
            <h5>Tags ({this.props.case.tags.length})</h5>
          )}
          {this.props.viewAllLinkRequired && this.renderViewAllLink()}
        </div>
        <div className='cases-tags-wrap'>{this.renderTags()}</div>
      </div>
    );
  }
}

RelatedTagsSection.defaultProps = {
  viewAllLinkRequired: true,
  headingRequired: true,
  hashTagRequired: false,
};
