import OsBtn from 'app/components/OsBtn';

const Unmark = ({ taskId, markAsNotDone, setIsCalendarOpen }) => {
  return (
    <OsBtn
      key='markasnotdone'
      name='BtnIcon'
      text='Mark as Undone'
      extraClass='list-button'
      icon='tick-fill'
      onClick={(e) => markAsNotDone(e, taskId)}
    />
  );
};

export default Unmark;
