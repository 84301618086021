import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import immutableUpdate from 'immutability-helper';

import MessageBlock from 'app/components/MessageBlock';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import MarkRead from 'app/components/Inbox/MarkRead';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { getGroupURL } from 'app/utils/routeHelper';
import { cloneDeep, isArray, isEmpty } from 'app/utils/osLodash';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import NewSpaceMessage from 'app/components/GroupMessenger/NewSpaceMessage';

import 'app/components/Inbox/group-inbox.scss';
import { isGroupSpace } from 'app/utils/spaceHelper';

const INBOX_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/Inbox/WorkspaceInboxApiSubscription.gql',
);

const GroupsInboxList = (props) => {
  const { id: workspaceId } = useContext(WorkspaceContext);
  const currentUser = useSelector((state) => state.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    props.updateCount(props.totalRecords);
  }, [props.totalRecords]);

  useEffect(() => {
    let unsubscribe = () => {};
    const { id: userId } = currentUser.graph;
    if (workspaceId && props.listType === 'MyUnreadGroups') {
      unsubscribe = props.subscribeToMore({
        document: INBOX_SUBSCRIPTION,
        variables: { workspaceId: workspaceId, userId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } =
            subscriptionData.data.workspace_inbox_api_subscription;

          if (entity && isGroupSpace(entity)) {
            let updatedData = cloneDeep(prev);
            if (
              updatedData.records.results.some(
                (obj) => obj.id.toString() === entity.id.toString(),
              )
            ) {
              updatedData.records.results = updatedData.records.results.filter(
                (obj) => obj.id.toString() !== entity.id.toString(),
              );
            } else {
              updatedData.records.total += 1;
            }
            let payload = { records: {} };
            payload.records.results = { $unshift: [entity] };
            updatedData = immutableUpdate(updatedData, payload);
            return Object.assign({}, prev, updatedData);
          }
        },
      });
    }
    return () => unsubscribe();
  }, [workspaceId]);

  const isPreviousMessageIsSameAsAuthor = (commentList, comment, index) => {
    const previousComment = commentList[index - 1];
    return previousComment && previousComment.author.id === comment?.author.id;
  };

  const renderGroupBanner = (group) => {
    let { image } = group;
    return <img src={image} alt='' className='group-banner-img' />;
  };

  const renderAction = (group) => {
    return (
      <div className='d-inline-block ms-auto a-link fs-12'>
        <CustomDropdown
          name='discussion_actions'
          className='comment-more position-relative'
          flipTheDropdown={260}>
          <MarkRead
            entityId={group.nice_id}
            entityType={group.__typename}
            type={'my_inbox'}
            entity={group}
            queryType={props.listType}
          />
          <div></div>
        </CustomDropdown>
      </div>
    );
  };

  const handleHeaderClick = (group) => {
    navigate(withWorkspaceIdentifier(getGroupURL(group.nice_id)));
  };

  const renderWriteComment = (group) => {
    let commentable = group,
      commentsCount = group.comments_count;

    return (
      <NewSpaceMessage
        entity={group}
        commentableId={group.id}
        commentableType='Board'
        newComment={true}
      />
    );
  };

  const renderRow = (group) => {
    let comments = isArray(group.recent_mentioned_comments)
      ? group.recent_mentioned_comments
      : [group.recent_mentioned_comments];
    return (
      <div className='grp-container'>
        <div className='header' onClick={() => handleHeaderClick(group)}>
          <div className='info-box'>
            <div className='banner'>{renderGroupBanner(group)}</div>
            <div className='info'>
              <div className='title'>{group.name}</div>
              <div className='desc'>{group.user?.name}</div>
            </div>
          </div>
          {props.listType === 'MyUnreadGroups' && (
            <div className='header-menu'>{renderAction(group)}</div>
          )}
        </div>
        <div className='message-box'>
          {comments.map((comment, index) => (
            <MessageBlock
              type='comment'
              key={comment.id}
              entity={comment}
              parentRef={props.parentRef}
              actionType={props.actionType || 'group'}
              isParentNotRequired={true}
              previousMessageIsSameAsAuthor={isPreviousMessageIsSameAsAuthor(
                comments,
                comment,
                index,
              )}
              space={group}
              parentResults={[]}
              doNotViewComment={true}
            />
          ))}
        </div>
        {false && renderWriteComment(group)}
      </div>
    );
  };

  return (
    <div className='group-inbox-wrapper'>{props.results.map(renderRow)}</div>
  );
};

GroupsInboxList.defaultProps = {
  updateCount: () => {},
};

export default GroupsInboxList;
