import React, { Component } from 'react';
import Loader from 'react-loaders';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import RedirectToDefault from 'app/components/RedirectToDefault';

import { PATH_AFTER_UNAUTHORIZATION } from 'app/constants';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import { isEmpty, lowerCase } from 'app/utils/osLodash';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

const RESOURCE_MAPPER = {
  cases: 'case',
  clinics: 'board',
  companies: 'company',
  comparisons: 'comparison',
  explore: 'explore',
  folders: 'board',
  posts: 'pulse',
  tools: 'tool',
  users: 'user',
  spaces: 'board',
};

export default class Authorize extends Component {
  componentDidMount() {
    if (this.isShowPage()) {
      addClassToHTML('detail-page');
    } else {
      removeClassFromHTML('detail-page');
    }
  }

  componentWillUnmount() {
    removeClassFromHTML('detail-page');
  }

  isCollectionPage() {
    return (
      this.indexPage ||
      (this.props.match &&
        (isEmpty(this.props.match.params) ||
          this.props.location.pathname.startsWith('/search')))
    );
  }

  isEditAction() {
    let splittedUrl = this.splittedUrl();
    return splittedUrl && splittedUrl[3] === 'edit';
  }

  isNewAction() {
    let splittedUrl = this.splittedUrl();
    return splittedUrl && splittedUrl[2] === 'new';
  }

  onResourceLoadCallback() {}

  action() {
    if (this.isCollectionPage()) {
      return 'index';
    } else if (this.isNewAction()) {
      return 'new';
    } else if (this.isEditAction()) {
      return 'edit';
    } else {
      return 'show';
    }
  }

  isShowPage() {
    return this.action() === 'show';
  }

  abilityAction() {
    let action = this.action();
    if (action === 'index') {
      return 'index';
    } else if (action === 'show') {
      return 'read';
    } else if (action === 'edit') {
      return 'update';
    } else if (action === 'new') {
      return 'create';
    }
  }

  splittedUrl() {
    return (
      this.props.match && this.props.location.pathname.split('/').map(lowerCase)
    );
  }

  resource() {
    return RESOURCE_MAPPER[this.collectionPath()];
  }

  resourceNotPresent() {
    let resourceObject = this.resourceObject();
    return isEmpty(resourceObject);
  }

  resourceObject() {
    return this.props.data && this.props.data[this.resourceKey];
  }

  abilityOptions() {
    if (this.isCollectionPage() || this.resourceNotPresent()) {
      return {};
    } else {
      let resourceObject = this.resourceObject(),
        user = resourceObject.user || resourceObject.doctor;

      if (resourceObject.__typename === 'User') {
        return { userId: resourceObject.id };
      } else if (resourceObject.__typename === 'Pulse') {
        return { userId: resourceObject.author.id };
      } else if (['Board', 'Space'].includes(resourceObject.__typename)) {
        return { resource: resourceObject, userId: user.id };
      } else if (user) {
        return { userId: user.id };
      } else {
        return {};
      }
    }
  }

  collectionPath() {
    return this.splittedUrl()[1] || 'explore';
  }

  redirectToUnauthorized() {
    let { graph } = this.props.currentUser;
    if (graph && graph.is_general_user) {
      return <RedirectToDefault />;
    } else {
      return <Navigate to={PATH_AFTER_UNAUTHORIZATION} />;
    }
  }

  fetchGraphqlError() {
    return this.props.data.error.graphQLErrors[0];
  }

  isRestrictedErrorPresent() {
    let graphQLError = this.fetchGraphqlError();
    return graphQLError && graphQLError.message === 'restricted';
  }

  isPrivateAccessDenied() {
    let graphQLError = this.fetchGraphqlError();
    return graphQLError && graphQLError.message === 'private-access-denied';
  }

  isErrorPresent() {
    return this.props.data.error;
  }

  redirectToNotFoundScreen() {
    return (
      <Navigate
        to={{
          pathname: withWorkspaceIdentifier('/not_found'),
          state: { url: this.props.location.pathname },
        }}
        skip={true}
      />
    );
  }

  redirectToPrivateAccessDeniedScreen() {
    let niceUrl = this.props.match.params.id;

    return (
      <Navigate
        to={{
          pathname: withWorkspaceIdentifier('/private_access_denied'),
          state: { resourceType: this.resourceKey, niceUrl },
        }}
        skip={true}
      />
    );
  }

  authorize() {
    let isShowOrEditPage = this.isShowPage() || this.isEditAction(),
      { currentUser } = this.props;
    if (!this.isCollectionPage() && isShowOrEditPage && this.props.data.loading)
      return <Loader type='ball-triangle-path' active />;

    if (isShowOrEditPage && this.props.data) {
      if (this.isErrorPresent()) {
        if (this.isRestrictedErrorPresent()) {
          return this.redirectToUnauthorized();
        } else if (this.isPrivateAccessDenied()) {
          return this.redirectToPrivateAccessDeniedScreen();
        }
      } else if (this.resourceNotPresent()) {
        return this.redirectToNotFoundScreen();
      }
    }

    if (
      currentUser.graph &&
      !currentUser.ability.can(
        this.abilityAction(),
        this.resource(),
        this.abilityOptions(),
      )
    ) {
      return this.redirectToUnauthorized();
    }
  }
}
