import {
  CLOSE_APPOINTMENT_INFO_MODAL,
  OPEN_APPOINTMENT_INFO_MODAL,
} from 'app/actions/types';

export default function (state = { open: false }, action) {
  switch (action.type) {
    case OPEN_APPOINTMENT_INFO_MODAL:
      return { open: true };

    case CLOSE_APPOINTMENT_INFO_MODAL:
      return { open: false };
    default:
      return state;
  }
}
