import {
  ADD_CONTACT_PARTY,
  CLOSE_CONTACT_PARTY_MODAL,
  EDIT_CONTACT_PARTY,
} from 'app/actions/types';

const contactPartyModal = (state = { open: false }, action) => {
  switch (action.type) {
    case ADD_CONTACT_PARTY:
      return { open: true, space: action.space };
    case CLOSE_CONTACT_PARTY_MODAL:
      return { open: false };
    case EDIT_CONTACT_PARTY:
      return { open: true, ...action };
    default:
      return state;
  }
};

export default contactPartyModal;
