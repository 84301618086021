import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M8.967 6.22s.277 1.419-.895 1.52c-1.413.132-1.39-.983-1.425-1.52-.037-.547.676-3.181 4.023-3.637 2.936-.405 6.078.385 6.573 3.12.556 3.05-1.003 3.638-3.842 6.191l-.085 3.84s.049.7-.833.77c-1.716.132-1.426-.81-1.426-.81v-3.76s-.036-.891.894-1.468c.689-.426 4.314-2.32 3.226-4.681 0 0-.677-1.54-3.274-1.54-3.42.01-2.936 1.975-2.936 1.975z'
      fill='#E51919'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M14.566 3.391c-1.052-.423-2.413-.521-3.779-.333h-.001c-1.438.196-2.31.853-2.834 1.547-.264.35-.435.704-.538 1-.105.307-.127.522-.123.591l.007.105c.017.275.033.542.145.751.055.103.114.15.163.174.043.021.147.057.387.034h.005c.094-.008.138-.033.181-.079a.655.655 0 0 0 .142-.314 1.573 1.573 0 0 0 .008-.57v-.004a.993.993 0 0 1-.02-.113 1.778 1.778 0 0 1 .258-1.148c.205-.329.553-.655 1.115-.894.555-.237 1.282-.374 2.218-.377h.003c1.492 0 2.474.45 3.076.919.296.23.494.46.62.634a2.283 2.283 0 0 1 .175.285l.01.022.003.006m-1.22-2.236c1.023.412 2.361 1.48 2.582 2.7l-2.583-2.7zm3.316 2.247c-.275-1.514-1.3-2.533-2.705-3.098-1.377-.555-3.054-.65-4.624-.433-1.909.26-3.066 1.149-3.726 2.022a4.5 4.5 0 0 0-.664 1.235c-.122.356-.175.676-.162.88l.005.089c.013.254.037.702.236 1.077.12.226.323.466.668.63.35.169.772.223 1.238.18.492-.043.851-.233 1.085-.482.219-.232.316-.5.363-.72a2.297 2.297 0 0 0 .013-.842l-.003-.017-.001-.006V6.15l-.64.07.64-.07-.003-.009v-.005L9.6 6.131a1.003 1.003 0 0 1-.012-.202c.007-.141.042-.319.148-.489.102-.163.275-.33.584-.463.315-.134.81-.247 1.583-.25 1.104.001 1.76.322 2.145.622.198.155.331.31.414.424a1.377 1.377 0 0 1 .1.162l.002.004.002.003c.44.955-.048 1.846-.846 2.611-.674.648-1.49 1.13-1.958 1.406l-.223.134c-.599.372-.878.839-1.008 1.197a2.05 2.05 0 0 0-.12.594V11.938l-.02 3.71c-.007.042.001.12 0 .188 0 .131.026.325.154.522.135.208.37.4.732.517.348.113.772.147 1.27.109h.004c.728-.059 1.11-.401 1.277-.713a1.133 1.133 0 0 0 .133-.54l.08-3.659c.426-.378.817-.709 1.18-1.017.188-.158.368-.31.542-.46.54-.465 1.017-.9 1.386-1.363.768-.965 1.025-1.992.733-3.595z'
      fill='#E51919'
    />
    <path d='M14 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0z' fill='#E51919' />
  </svg>
);

export default SVG;
