import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import RedirectToDefault from 'app/components/RedirectToDefault';
import Security from 'app/components/OnboardingModals/Security';

import { translate } from 'app/actions/flashMessage';
import { removeClassFromElement } from 'app/utils/domHelper';

class UpdateNumberScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberChanged: false,
    };
    removeClassFromElement('login', document.body);
  }

  onOtpVerificationSuccess = () => {
    this.setState({ numberChanged: true });
  };

  renderSecurityView() {
    return (
      <Security
        otpSent={this.otpSent}
        closeModal={this.closeModal}
        onSuccess={this.onOtpVerificationSuccess}
        titleRequired={true}
      />
    );
  }

  renderNumberUpdateSuccessScreen() {
    return (
      <div>
        <div className='text-center'>
          <Icon name='passwordSuccess' className='password-success-svg' />
          <div className='password-success-text'>Success!</div>
          <p className='password-success-msg'>
            {translate('PHONE_NUMBER_SUCCESSFULLY_UPDATED_MESSAGE')}
          </p>
        </div>
      </div>
    );
  }

  renderBody() {
    return this.state.numberChanged
      ? this.renderNumberUpdateSuccessScreen()
      : this.renderSecurityView();
  }

  render() {
    if (!this.props.currentUser.graph?.number_update_required)
      return <RedirectToDefault />;

    return (
      <ImageFormLayout
        leftBar={
          <Icon
            name='privacy'
            width='480'
            height='100%'
            style={{ maxWidth: '100%', width: '480px' }}
          />
        }
        rightBar={this.renderBody()}
        source='update_number'
      />
    );
  }
}

UpdateNumberScreen = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(UpdateNumberScreen);

export default UpdateNumberScreen;
