import React, { useRef } from 'react';

import SectionFolderList from 'app/components/SideBarFolderList/SectionFolderList';
import RecordsPager from 'app/components/RecordsPager';
import DefaultSpaceCardImage from 'app/images/gradeint_cloudberry.png';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { MY_FOLDER_FILES, MY_FOLDER_LINKS } from 'app/routes/constants/folders';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

const SYSTEM_FOLDERS = [
  {
    __typename: 'DynamicTemplate',
    id: MY_FOLDER_FILES,
    category_name: 'Content Space',
    type: 'Folder',
    nice_id: MY_FOLDER_FILES,
    image: DefaultSpaceCardImage,
    name: 'My Uploaded Files',
    modifiedUrl: withWorkspaceIdentifier(`/folders/${MY_FOLDER_FILES}`),
    queryType: 'MyAttachments',
  },
  {
    __typename: 'DynamicTemplate',
    id: MY_FOLDER_LINKS,
    category_name: 'Content Space',
    type: 'Folder',
    nice_id: MY_FOLDER_LINKS,
    image: DefaultSpaceCardImage,
    name: 'My Uploaded Links',
    modifiedUrl: withWorkspaceIdentifier(`/folders/${MY_FOLDER_LINKS}`),
    queryType: 'AllAdditionalLinks',
  },
];
const SideBarFolderList = (props) => {
  const parentContainerRef = useRef(null);

  return (
    <div>
      <ul className='sidebar-folder-list' ref={parentContainerRef}>
        <SectionFolderList
          title={'System Folders'}
          results={SYSTEM_FOLDERS}
          isExpanded={true}
        />
        <RecordsPager
          queryType='SPACE_DETAILS'
          displayType={'SIDEBAR'}
          sortQuery={props.sortQuery}
          type={'PendingFolders'}
          limit={20}
          textQuery={props.searchQuery}
          fetchPolicy='cache-and-network'
          windowNotRequired={true}
          title={'Invited To Join'}
          key={getWorkspaceData()?.unread_folder_spaces_notifications_count}
        />
        <RecordsPager
          queryType='SPACE_DETAILS'
          displayType={'SIDEBAR'}
          sortQuery={props.sortQuery}
          type={'CollaboratoringFolders'}
          limit={20}
          textQuery={props.searchQuery}
          fetchPolicy='cache-and-network'
          windowNotRequired={true}
          title={'My Folders'}
        />
      </ul>
    </div>
  );
};

export default SideBarFolderList;
