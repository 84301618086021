import OrthoIcon from 'app/components/Shared/OrthoIcon';
import SmallScreenWidget from 'app/components/Task/SmallScreenWidget';
import { LIST, UNIFIED_LIST } from 'app/components/Task/taskConstant';
import React from 'react';
import spotify from 'app/images/task-manager/spotify.svg';
const RenderSearchBar = (props) => {
  const {
    showWidget,
    setShowWidget,
    changeInputSearchRoute,
    query,
    labelsData,
    allUserOptions,
    user,
    changeLabel,
    changeUserRoute,
    currentView,
    setShowSearchBar,
    autoFocus,
  } = props;

  return (
    <div
      className='filterInput'
      style={{ position: 'relative' }}
      onMouseEnter={() => setShowWidget(true)}
      onMouseLeave={() => setShowWidget(false)}>
      <img src={spotify} alt='spotify' width='16' height='16' />
      <input
        style={{ border: 'none' }}
        autoFocus={autoFocus}
        type='text'
        placeholder='start typing to filter tasks...'
        value={query}
        onFocus={() => setShowWidget(true)}
        onChange={(e) => {
          changeInputSearchRoute(e.target.value);
        }}
      />
      <OrthoIcon
        name='clear'
        defaultClass='visible-mobile clear-btn'
        onClick={() => setShowSearchBar(false)}
      />
      <div
        className={
          'smallScreenWidget ' +
          ((currentView === LIST || currentView === UNIFIED_LIST) &&
            'hidden_for_list_view')
        }>
        <SmallScreenWidget
          labelsData={labelsData}
          show={showWidget}
          setShow={setShowWidget}
          teamMembersData={allUserOptions}
          currentUser={user}
          showMembers={true}
          handleLabelClick={changeLabel}
          handleMemberClick={changeUserRoute}
        />
      </div>
    </div>
  );
};

export default RenderSearchBar;
