import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import scrollToElement from 'scroll-to-element';
import OsBtn from 'app/components/OsBtn';
import { MOBILE_HEADER_HEIGHT } from 'app/constants';
import { entityCommentsUrl } from 'app/utils/entitiesHelper';

class CommentIcon extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.history.location.hash === '#comments' &&
      this.props.location.hash !== nextProps.history.location.hash
    )
      this.scrollToCommentSection();
  }

  scrollToCommentSection() {
    var element = this.getCommentSectionElement(),
      offset = this.props.device.mobile ? -MOBILE_HEADER_HEIGHT - 10 : -10;

    if (element)
      scrollToElement(element, {
        ease: 'linear',
        offset: offset,
        duration: 500,
      });
  }

  getCommentSectionElement() {
    return window.$('#comments')[0];
  }

  goToCommentsUrl() {
    this.props.navigate(entityCommentsUrl(this.props.obj));
  }

  onCommentIconClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      this.props.onClickCallback();
      this.goToCommentsUrl();
    }
  };

  commentsCount() {
    return this.props.obj.__typename === 'Comment'
      ? this.props.obj.replies_count
      : this.props.obj.comments_count;
  }

  render() {
    let extraClass = this.props.extraClass
        ? `px-8 ${this.props.extraClass} `
        : 'px-8 ',
      comments_count = this.commentsCount(),
      label;

    if (comments_count) {
      if (this.props.labelRequired) {
        label =
          comments_count === 1
            ? `${comments_count} DISCUSSION`
            : `${comments_count} DISCUSSIONS`;
      } else {
        label = comments_count;
      }
    } else if (this.props.showLabelIfZero) {
      label = 'Comment';
    }

    extraClass += label ? '' : 'no-text';
    extraClass += this.props.disabled ? ' osbtn-disabled ' : '';
    return (
      <OsBtn
        key='like'
        name='BtnIcon'
        onClick={this.onCommentIconClick}
        icon={this.props.iconName}
        extraClass={extraClass}
        associatedEntity={this.props.obj}>
        {label}
      </OsBtn>
    );
  }
}

CommentIcon.defaultProps = {
  onClickCallback: () => {},
  iconName: 'comments',
};
CommentIcon = connect(({ device }) => ({ device }))(CommentIcon);
CommentIcon = withRouter(CommentIcon);
export default CommentIcon;
