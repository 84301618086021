import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { closeReportModal } from 'app/actions/report';
import { translate } from 'app/actions/flashMessage.js';

import { fieldRequired } from 'app/utils/validationHelper';

const REPORT_OBJECT_MUTATION = queryLoader('app/graphql/ReportContent.gql');

class ReportObjectModal extends Component {
  state = {
    submitting: false,
  };

  closeReportModal = () => {
    this.reset();
    this.props.closeReportModal();
  };

  componentDidMount() {
    if (this.textareaRef) this.autoHeight();
  }

  sendRequest = (attributes) => {
    this.setState({ submitting: true });
    this.props.client
      .mutate({
        mutation: REPORT_OBJECT_MUTATION,
        variables: {
          reportable_id: this.props.report.object.id,
          reportable_type: this.props.report.object.__typename,
          reason_type: attributes.report_type,
          reason_description: attributes.report_description,
        },
      })
      .then(({ data }) => {
        if (data.reportContent.errors.length) {
          this.closeReportModal();
        } else {
          this.submittedSuccessfully();
        }
      });
  };

  submittedSuccessfully() {
    this.setState({ submitting: false }, () => {
      setTimeout(() => {
        this.closeReportModal();
      }, 100);
    });
  }

  validateReport(values) {
    if (values.report_type === 'others' && !values.report_description) {
      return { report_description: 'Required' };
    }
  }

  autoHeight = () => {
    let element = this.textareaRef;
    if (element) {
      element.style.height = '5px';
      element.style.height = element.scrollHeight - 5 + 'px';
    }
  };

  saveTextAreaRef = (div) => {
    this.textareaRef = div;
  };

  renderReasonContent() {
    return (
      <Form
        onSubmit={this.sendRequest}
        enableReinitialize={true}
        validate={(values) => this.validateReport(values)}
        initialValues={{ report_description: '', report_type: 'spam' }}
        render={({ handleSubmit, values, form, errors }) => {
          this.reportInfo = values;
          this.reset = form.reset;
          this.errors = errors;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div className='modal-body-text'>
                  <div className='report-text'>{translate('REPORT_TEXT')}</div>
                  <div>
                    <label className='radio-label' htmlFor='spam'>
                      {translate('REPORT_SPAM')}
                      <Field
                        component={'input'}
                        type='radio'
                        className='custom-control-input form-control'
                        name='report_type'
                        id='spam'
                        value='spam'
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                  <div>
                    <label className='radio-label mb-1' htmlFor='other'>
                      {translate('REPORT_OTHER')}
                      <Field
                        component={'input'}
                        type='radio'
                        className='custom-control-input form-control'
                        name='report_type'
                        id='other'
                        value='others'
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='modal-body-textarea '>
                <Field
                  osType='textarea'
                  component={OsField}
                  row={'1'}
                  placeholder="Please, explain what's wrong with it"
                  name={'report_description'}
                  onChange={this.autoHeight}
                  saveInputRef={this.saveTextAreaRef}
                />
              </div>
              <div className='modal-footer p-0'>
                <div className='media-actions-buttons w-100 text-right m-0'>
                  {this.renderActions()}
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }

  renderActions() {
    let { object } = this.props.report;
    let disabledClass =
      this.reportInfo.report_type === 'spam'
        ? ''
        : this.reportInfo.report_description
        ? ' '
        : 'disabled-only  ';
    if (!this.state.submitting) {
      if (!this.props.submitSucceeded) {
        return (
          <>
            <OsBtn
              name='BtnIcon'
              text='Cancel'
              extraClass='px-16'
              onClick={this.closeReportModal}
              associatedEntity={object}
            />
            <OsBtn
              name='BtnPrimary'
              text='Yes, Report'
              type='submit'
              htmlTag='button'
              extraClass={`ms-3 ${disabledClass}`}
              associatedEntity={object}
            />
          </>
        );
      } else {
        return (
          <OsBtn
            name='BtnIcon'
            text='Report Sent'
            disabled={true}
            icon='tick'
            extraClass='osbtn-grey'
            associatedEntity={object}
          />
        );
      }
    } else {
      return (
        <OsBtn
          name='BtnPrimary'
          text='Sending'
          disabled={true}
          loaderRequired={true}
          associatedEntity={object}
        />
      );
    }
  }

  getObjectName() {
    let object = this.props.report.object;
    if (object) {
      switch (object.__typename) {
        case 'Pulse':
          return 'Post';
        default:
          return object.__typename;
      }
    }
  }

  renderContent() {
    return (
      <>
        <div className='header-tab-combo'>
          <div className='modal-header border-0 py-0'>
            <h4 className='modal-title' id='modalLabel'>
              Report {this.getObjectName()}
            </h4>

            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn close-report-btn'
              icon='close'
              label={`Close report ${this.getObjectName()} modal`}
              onClick={this.closeReportModal}
            />
          </div>
        </div>
        <div className='modal-body'>
          <div className='svg-image mb-4 text-center'>
            <Icon name='report' />
          </div>
          {this.renderReasonContent()}
        </div>
      </>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.report.openReportModal}
        onHide={this.closeReportModal}
        animation={false}
        dialogClassName={'pulse-report-modal report-modal'}
        backdropClassName='modal-backdrop-custom'>
        {this.renderContent()}
      </Modal>
    );
  }
}

ReportObjectModal = connect(({ report }) => ({ report }), { closeReportModal })(
  ReportObjectModal,
);
ReportObjectModal = withApollo(ReportObjectModal);
export default ReportObjectModal;
