import {
  SET_CURRENT_USER_SESSION_VALID,
  OPEN_PASSWORD_UPDATE_MODAL,
  CLOSE_PASSWORD_UPDATE_MODAL,
} from 'app/actions/types';

export default function currentUserSessionvalid(
  state = { valid: true },
  action,
) {
  switch (action.type) {
    case SET_CURRENT_USER_SESSION_VALID:
      return { ...state, valid: action.valid };

    case OPEN_PASSWORD_UPDATE_MODAL:
      return { ...state, openPasswordModal: true };

    case CLOSE_PASSWORD_UPDATE_MODAL:
      return { ...state, openPasswordModal: false };
    default:
      return state;
  }
}
