import { withRouter } from 'app/components/HOC/Router/withRouter';

import NewAction from './NewAction';

class Case extends NewAction {
  constructor(props) {
    super(props);
    this.newButtonText = 'Submit my first case';
  }

  addNewAction = () => {
    this.props.navigate(`/cases/new`);
  };

  render() {
    return this.renderAddNewButton();
  }
}

Case = withRouter(Case);

export default Case;
