import OsField from 'app/components/OsField';
import { isTouchSupported } from 'app/utils/deviceHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import React, { useState } from 'react';
import useUpdateDomain from 'app/components/WorkspaceView/SettingView/IntegrationConfig/useUpdateDomain';
import useDeleteDomain from 'app/components/WorkspaceView/SettingView/IntegrationConfig/useDeleteDomain';

const DomainItem = (props) => {
  const { domain, allDomainsVariable } = props;
  const { updateDomainToWebsite } = useUpdateDomain();
  const { deleteDomainToWebsite } = useDeleteDomain(allDomainsVariable);

  const [domainEnabled, setDomainEnabled] = useState(domain.enabled);

  return (
    <div
      className={'integration-form'}
      style={{
        display: 'flex',
        justifyContent: 'left',
        gap: '24px',
        marginBottom: '8px',
      }}>
      <OsField
        value={domain.hostname}
        editable={false}
        type={'input'}
        placeholder={'https://www.example.com'}
      />
      <OsField
        osType='checkbox'
        onChange={() => {
          setDomainEnabled((prevState) => !prevState);
          updateDomainToWebsite(
            domain.id,
            domain.hostname,
            !domain.enabled,
          ).then((r) => r);
        }}
        slider={true}
        dataHoverRequired={!isTouchSupported()}
        className='switch'
        isChecked={domainEnabled}
      />
      <OrthoIcon
        name={'delete'}
        defaultClass='ms-1'
        onClick={() => deleteDomainToWebsite(domain.id)}
      />
    </div>
  );
};

export default DomainItem;
