import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { Modal } from 'react-bootstrap';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AttachmentPreview from './index';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import ZoomableContent from 'app/components/Shared/ZoomableContent';

import { closeAttachmentPreview } from 'app/actions/attachmentPreview';
import { timeWithFormat } from 'app/utils/timeHelper';
import { formatFileSize, isDownloadable } from 'app/utils/fileHelper';
import { isUndefined } from 'app/utils/osLodash';
import { isMobileAppView } from 'app/utils/domHelper';

import { ATTACHMENT_PREVIEW_URL_HASH } from 'app/constants';

const ATTACHMENT_QUERY = queryLoader('app/graphql/Attachment.gql');
const ATTACHMENT_CREATED_DATE_FORMAT = 'MMM DD, YYYY';

class AttachmentPreviewModal extends Component {
  state = {
    activeObject: {},
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.attachmentPreview.open &&
      nextProps.attachmentPreview.open
    ) {
      nextProps.navigate(ATTACHMENT_PREVIEW_URL_HASH, { skip: true });
      this.setState({
        activeObject: nextProps.attachmentPreview.activeAttachment,
      });
    }

    if (
      this.props.location.hash === ATTACHMENT_PREVIEW_URL_HASH &&
      nextProps.location.hash !== ATTACHMENT_PREVIEW_URL_HASH
    ) {
      this.onClose();
      this.props.closeAttachmentPreview();
    }
  }

  isReaderView() {
    return this.props.attachmentPreview.options.readerView;
  }

  onClose() {
    this.props.attachmentPreview.options.onClose &&
      this.props.attachmentPreview.options.onClose();
  }

  closeAttachmentPreview = () => {
    this.onClose();
    !this.props.attachmentPreview.options.avoidBackOnClose &&
      this.props.goBack();
    this.props.closeAttachmentPreview();
  };

  getVariables(data) {
    return {
      download_url: data
        ? data.attachment.download_url
        : this.state.activeObject.download_url,
      preview_url: data
        ? data.attachment.preview_url
        : this.state.activeObject.preview_url,
      query: ATTACHMENT_QUERY,
    };
  }

  openAttachmentInNextTab = () => {
    this.refetchRecentAttachmentInfo('preview_url');
  };

  refetchRecentAttachmentInfo(attribute) {
    //https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    let isDownload = attribute === 'download_url',
      windowReferenceRequired = !isMobileAppView() && !isDownload,
      windowReference = windowReferenceRequired && window.open();

    this.props.client
      .query({
        query: this.getVariables().query,
        fetchPolicy: 'network-only',
        variables: { id: this.state.activeObject.id },
      })
      .then(({ data }) => {
        if (windowReference) {
          windowReference.location = this.getVariables(data)[attribute];
        } else {
          window.location.href = this.getVariables(data)[attribute];
        }
      });
  }

  fetchDownloadUrl = () => {
    this.refetchRecentAttachmentInfo('download_url');
  };

  getActiveAttachment() {
    return this.state.activeObject;
  }

  showFileSize() {
    let activeAttachment = this.getActiveAttachment(),
      size = activeAttachment.file_size;
    if (!isUndefined(size) && size > 0) {
      return (
        <div className='attachment-sidebar-row attachment-file-size'>
          {formatFileSize(size)}
        </div>
      );
    }
  }

  changeActiveObject = (activeObject) => {
    this.setState({ activeObject });
  };

  renderCTAs() {
    return (
      <div className='attachment-sidebar-row btns-row'>
        <OsBtn
          name='BtnPrimary'
          extraClass='attachment-sidebar-btns download-attachment-btn'
          text='Download'
          onClick={this.fetchDownloadUrl}>
          <OrthoIcon name='download' dataHoverNotRequired={true} />
        </OsBtn>

        <OsBtn
          name='BtnSecondary'
          extraClass='with-border attachment-sidebar-btns mt-md-3 mt-0 view-full-size-btn'
          text='View Full Size'
          onClick={this.openAttachmentInNextTab}>
          <OrthoIcon name='view' dataHoverNotRequired={true} />
        </OsBtn>
      </div>
    );
  }

  renderDetails() {
    let activeAttachment = this.getActiveAttachment(),
      author = this.props.attachmentPreview.author;

    return (
      <div className='attachments-content-sidebar'>
        <div className='attachments-top-content-holder'>
          <div className='attachment-sidebar-row file-name'>
            {activeAttachment.name}
          </div>
          <div className='attachment-sidebar-row file-created-at'>
            {activeAttachment.created_at && (
              <>
                Added on{' '}
                {timeWithFormat(
                  activeAttachment.created_at,
                  ATTACHMENT_CREATED_DATE_FORMAT,
                )}
              </>
            )}
            {author && <span> by {author.name} </span>}
          </div>
          {this.showFileSize()}
        </div>
        {isDownloadable(activeAttachment) && this.renderCTAs()}
      </div>
    );
  }

  renderHeader() {
    let activeAttachment = this.getActiveAttachment(),
      isReaderView = this.isReaderView();
    return (
      <div className='modal-header'>
        {isReaderView && this.props.device.mobileDevice && (
          <OrthoIcon
            name='chevron-left'
            onClick={this.closeAttachmentPreview}
            dataHoverNotRequired={true}
          />
        )}
        {isReaderView && <span>{activeAttachment.name}</span>}
        {(!isReaderView || !this.props.device.mobileDevice) && (
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-attachment-preview'
            icon='close'
            label='Close attachment preview modal'
            onClick={this.closeAttachmentPreview}
          />
        )}
      </div>
    );
  }

  renderBody() {
    let { attachments, author, options } = this.props.attachmentPreview,
      activeAttachment = this.getActiveAttachment();

    return (
      <div className='modal-body'>
        <AttachmentPreview
          activeObject={activeAttachment}
          author={author}
          notifyParentComponentOnChange={this.changeActiveObject}
          objects={attachments}
          readerView={this.isReaderView()}
          sliderFocusRequired={true}
          unmuted={options.unmuted}
        />
      </div>
    );
  }

  renderContent() {
    if (this.isReaderView()) {
      return (
        <ZoomableContent>
          {this.renderHeader()}
          {this.renderBody()}
        </ZoomableContent>
      );
    } else {
      return (
        <>
          {this.renderHeader()}
          {this.renderDetails()}
          {this.renderBody()}
        </>
      );
    }
  }

  render() {
    if (!this.props.attachmentPreview.open) return null;

    let isReaderView = this.isReaderView(),
      dialogClassName = isReaderView
        ? 'lecture-preview-modal'
        : 'modal-xl modal-search modal-attachment';

    return (
      <Modal
        show={true}
        onHide={this.closeAttachmentPreview}
        dialogClassName={dialogClassName}
        animation={false}
        backdropClassName='modal-backdrop-custom'
        backdrop='static'>
        {this.renderContent()}
      </Modal>
    );
  }
}

AttachmentPreviewModal = connect(
  ({ attachmentPreview, device }) => ({ attachmentPreview, device }),
  { closeAttachmentPreview },
)(AttachmentPreviewModal);
AttachmentPreviewModal = withApollo(AttachmentPreviewModal);
AttachmentPreviewModal = withRouter(AttachmentPreviewModal);

export default AttachmentPreviewModal;
