import { flattenDeep, isArray, isEmpty } from 'app/utils/osLodash';

import { entityUrl } from 'app/utils/entitiesHelper';
import { toSentence } from 'app/utils/stringHelper';
import { isUrlOfSameDomain, replaceCurrentDomain } from 'app/utils/urlHelper';

// TODO:GP Check ES Lint Warinng here.
const INTERPOLATION_PARSER_REGEX = /%{([^\}]*)}/gi,
  LINK_PRESENT_REGEX = /%{(\[link:(\w*)\])/g,
  HASH_PRESENT_REGEX = /(\[hash:(\w*)\])/g;

class InterpolationTextParser {
  isHashRequired(string) {
    return !isEmpty(string.match(HASH_PRESENT_REGEX));
  }

  isLinkRequired(string) {
    return !isEmpty(string.match(LINK_PRESENT_REGEX));
  }

  getEntityLink(
    object,
    attributeName,
    isHashRequired,
    linkAttributeName = 'nice_url',
    hashAttributeName,
  ) {
    let data = {
      frontend_url: object.frontend_url,
      nice_url: object[linkAttributeName],
      nice_id: object[linkAttributeName],
      type: object.type,
      collaborator: object.collaborator,
      __typename: object.__typename,
    },
      href = entityUrl(data, false, { skipWorkspaceIdentifer: false });

    if (isHashRequired) href += this.metaInfo[hashAttributeName] || '';

    if (href && href.startsWith('http') && isUrlOfSameDomain(href))
      href = replaceCurrentDomain(href);

    return `<a href='${href}' class='parsetext-link ${this.options.extraLinkClass || ''
      }'>${object[attributeName]}</a>`;
  }

  getLastAttributesAndSetAllAttributes(matchedString) {
    matchedString = matchedString.replace('}', '').replace('[hash]', '');

    let linkMatchedData = matchedString.match(LINK_PRESENT_REGEX),
      hashMatchedData = matchedString.match(HASH_PRESENT_REGEX);

    if (linkMatchedData)
      matchedString = matchedString.replace(linkMatchedData[0], '');

    if (hashMatchedData)
      matchedString = matchedString.replace(hashMatchedData[0], '');

    matchedString = matchedString.replace('%{', '');
    this.allAttributes = matchedString.split('.');
    return this.allAttributes.pop();
  }

  getAllEntities(object, index = 0) {
    if (!this.allAttributes[index])
      // NOTE: Check added to return info directly from meta information
      return [object];

    let data = isArray(object[this.allAttributes[index]])
      ? object[this.allAttributes[index]]
      : [object[this.allAttributes[index]]];
    return this.allAttributes.length > index + 1
      ? data.map((object) => this.getAllEntities(object, ++index))
      : data;
  }

  getParsedData(matchedString) {
    let isLinkRequired = this.isLinkRequired(matchedString),
      isHashRequired = this.isHashRequired(matchedString),
      attributeName = this.getLastAttributesAndSetAllAttributes(matchedString),
      linkAttributeName = (LINK_PRESENT_REGEX.exec(matchedString) || []).pop(),
      hashAttributeName = (HASH_PRESENT_REGEX.exec(matchedString) || []).pop();

    return toSentence(
      flattenDeep(this.getAllEntities(this.metaInfo)).map((object) => {
        return isLinkRequired
          ? this.getEntityLink(
            object,
            attributeName,
            isHashRequired,
            linkAttributeName,
            hashAttributeName,
          )
          : object[attributeName];
      }),
    );
  }

  parseText(text, metaInfo = {}, options = {}) {
    this.metaInfo = metaInfo;
    this.text = text;
    this.options = options;
    let machedGroups = text.match(INTERPOLATION_PARSER_REGEX) || [];
    machedGroups.forEach(
      (matchedString) =>
      (this.text = this.text.replace(
        matchedString,
        this.getParsedData(matchedString),
      )),
    );
    // Dev ToDo: (Refactor) Wrap in a div and bind click event on it. Handle Feeds and Notifications
    return this.text;
  }
}

export default new InterpolationTextParser();
