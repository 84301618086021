import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import XhrLoader from 'app/utils/HlsDecorator/XhrLoader';
import isSupported from 'app/utils/HlsDecorator/isSupported';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

export default class VideoPlayer extends Component {
  state = {
    videoPlay: this.props.play || this.props.video.autoplay,
    controls: this.props.controls,
    isMuted: this.props.video.autoplay && !this.props.video.unmuted,
  };

  componentDidMount() {
    let videoElement = this.videoElement,
      { autoplay, disablePip, seekTo } = this.props.video;

    if (videoElement && this.downloadNotAllowed())
      videoElement.controlsList = 'nodownload';

    if (videoElement && disablePip) videoElement.disablePictureInPicture = true;

    if (videoElement && autoplay) {
      // Note: Refer https://github.com/CookPete/react-player/issues/76
      videoElement.load();
      videoElement.play();
    }

    if (videoElement && seekTo) videoElement.currentTime = seekTo;

    this.videoTrigger();
  }

  videoTrigger = () => {
    if (this.videoElement && this.props.device.ipad) {
      window.$('video').trigger('load');
    }
  };

  downloadNotAllowed() {
    return this.props.video.nodownload;
  }

  onClick = () => {
    if (this.props.onClickToggleAudio) {
      this.setState({ isMuted: !this.state.isMuted });
    } else if (!this.props.device.mobileDevice) {
      // Above check is due to video not pausing on click in mobile.
      this.showPlayIconIfRequired();
      // this.setState({ videoPlay: !this.state.videoPlay }, this.showPlayIconIfRequired)
    }
  };

  onContextMenu = (e) => {
    if (this.downloadNotAllowed()) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  setPlayerRef = (reactPlayer) => {
    if (reactPlayer) {
      this.videoElement = reactPlayer?.player?.player?.player;
      this.videoElement && this.props.setPlayerRef(this.videoElement);
    }
  };

  configOptions(url) {
    let { poster, autoplay } = this.props.video;

    // NOTE:GP Adaptive streaming configuration needs to be looked into as its not working anymore.
    if (false && isSupported() && this.props.hlsOptions.url) {
      return this.hlsOptions(this.props.hlsOptions.url || '');
    } else {
      return { file: { attributes: { poster, autoplay } } };
    }
  }

  hlsOptions(url) {
    const urlParams = url.split('?')[1];
    return {
      file: {
        autoPlay: this.props.video.autoplay,
        poster: this.props.video.poster,
        forceHLS: true,
        hlsOptions: {
          pLoader: XhrLoader,
          fLoader: XhrLoader,
          loader: XhrLoader,
          startLevel: -1,
          xhrSetup: function (xhr, url) {
            return url.split('?')[0] + '?' + urlParams;
          },
        },
      },
    };
  }

  onPlayClick = () => {
    if (this.videoElement) {
      this.videoElement.play();
    }
  };

  enableControls = () => {
    this.setState({ controls: true });
  };

  getVideoUrl() {
    let url;
    if (isSupported() && this.props.hlsOptions) url = this.props.hlsOptions.url;
    return url || this.props.video.url;
  }

  showPlayIconIfRequired() {
    if (this.state.videoPlay) {
      this.playIcon && this.playIcon.classList.add('hidden');
    } else {
      this.playIcon && this.playIcon.classList.remove('hidden');
    }
  }

  pauseVideo() {
    this.setState({ videoPlay: false }, this.showPlayIconIfRequired);
  }

  playVideo() {
    this.setState({ videoPlay: true }, this.showPlayIconIfRequired);
  }

  render() {
    let { url, playIconRequired } = this.props.video,
      className = 'case-video ';

    playIconRequired =
      !this.state.videoPlay && playIconRequired && !this.props.video.audio;
    className += this.props.video.audio ? ' with-audio ' : '';
    className += this.props.video.containerClassName || '';

    return (
      <div
        className={className}
        onClick={this.onClick}
        onContextMenu={this.onContextMenu}>
        <div
          className='video-player-overlay'
          ref={(el) => (this.videoOverlay = el)}>
          <Icon name='loader' />
        </div>
        {playIconRequired && (
          <div
            className='lecture-loader hidden'
            ref={(el) => (this.playIcon = el)}
            onClick={this.onPlayClick}>
            <OrthoIcon name='play' dataHoverNotRequired={true} />
          </div>
        )}

        <ReactPlayer
          ref={this.setPlayerRef}
          url={this.getVideoUrl()}
          playing={this.state.videoPlay}
          loop={this.props.loop}
          progressInterval={500}
          playsinline
          muted={this.state.isMuted}
          controls={this.state.controls}
          config={this.configOptions(url)}
          volume={0.5}
          width='100%'
          height='100%'
          // onProgress={this.onProgress}
          className='d-inline-block video-player'
          onReady={(player) => {
            // if(this.player1 && !this.video1Ready){
            //   this.video1Ready = true;
            //   this.setVideoDuration(this.props);
            // }
            (!this.props.device.touchSupport || !playIconRequired) &&
              this.enableControls();
            this.videoOverlay.classList.add('hidden');
            this.showPlayIconIfRequired();
            this.setPlayerRef(player);
          }}
          onPlay={() => {
            this.enableControls();
            this.playVideo();
          }}
          onPause={() => {
            this.pauseVideo();
          }}
          onError={() => {
            this.props.video.onError && this.props.video.onError();
          }}
        />

        <br />
      </div>
    );
  }
}

VideoPlayer = connect(({ device }) => ({ device }), {})(VideoPlayer);
VideoPlayer.defaultProps = {
  controls: true,
  hlsOptions: {},
  loop: false,
  onClickToggleAudio: false,
  setPlayerRef: () => {},
};
