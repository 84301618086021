import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M2.457 20.033V3.967c0-.837.673-1.51 1.51-1.51h16.066c.837 0 1.51.673 1.51 1.51v16.066c0 .837-.673 1.51-1.51 1.51H3.967c-.837 0-1.51-.673-1.51-1.51z'
      fill='#079459'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.967 2.915c-.585 0-1.052.467-1.052 1.052v16.066c0 .585.467 1.052 1.052 1.052h16.066c.585 0 1.052-.467 1.052-1.052V3.967c0-.585-.467-1.052-1.052-1.052H3.967zM2 3.967C2 2.877 2.877 2 3.967 2h16.066C21.123 2 22 2.877 22 3.967v16.066c0 1.09-.877 1.967-1.967 1.967H3.967A1.963 1.963 0 0 1 2 20.033V3.967z'
      fill='#079459'
    />
    <path
      d='m18.42 8.461-.988-.963a.194.194 0 0 0-.276 0l-6.815 6.649-3.438-3.363a.194.194 0 0 0-.276 0L5.58 11.805a.183.183 0 0 0 0 .27l4.138 4.038.02-.02.425.415a.206.206 0 0 0 .286 0L18.42 8.73c.08-.077.08-.202 0-.27z'
      fill='#F7F7F8'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17.294 7a.76.76 0 0 1 .532.21l.98.956c.275.25.24.627.008.853l-7.971 7.777c-.287.28-.838.268-1.125-.011l-4.532-4.422a.573.573 0 0 1 0-.846l1.047-1.021a.76.76 0 0 1 .532-.21.76.76 0 0 1 .532.21l3.044 2.978 6.421-6.264a.76.76 0 0 1 .532-.21zm-7.576 8.44.02-.019.568.554 7.563-7.379-.575-.561-6.954 6.784-3.575-3.498-.635.62 3.588 3.5z'
      fill='#F7F7F8'
    />
  </svg>
);

export default SVG;
