import React, { Component } from 'react';
import EventTracker from 'app/services/EventTracker';
import { Link } from 'react-router-dom';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { isEmpty, omit, snakeCase } from 'app/utils/osLodash';
import { translate } from 'app/actions/flashMessage';

const BTN_CLASS_NAME = {
  BtnPrimary: 'osbtn-primary',
  BtnSecondary: 'osbtn-secondary',
  BtnOutline: 'osbtn-outline',
  BtnGhost: 'osbtn-ghost',
  BtnLink: 'osbtn-link',
  BtnIcon: '',
  BtnTertiary: 'osbtn-tertiary',
};
const NON_HTML_ATTRIBUTES = [
  'associatedEntity',
  'dataAttribute',
  'extraClass',
  'eventAttributes',
  'htmlTag',
  'iconDefaultClass',
  'isBigbtn',
  'leftIcon',
  'loaderRequired',
  'tooltipKey',
];

class OsBtn extends Component {
  onClick = (e) => {
    this.sendEvent();
    if (this.props.onClick) this.props.onClick(e);
  };

  getEventName() {
    let text = this.props.label || this.props.text,
      name = text ? snakeCase(text) : this.props.icon;
    return name;
  }

  sendEvent() {
    const eventName = this.getEventName();
    if (isEmpty(this.props.eventAttributes)) {
      EventTracker.trackOsBtnClick(eventName, this.props.associatedEntity);
    } else {
      EventTracker.trackOsBtnClick(eventName, this.props.eventAttributes);
    }
  }

  getElementClass() {
    let className = `osbtn ${BTN_CLASS_NAME[this.props.name]} ${
      this.props.extraClass || ''
    }`;
    className +=
      (this.props.name === 'BtnIcon' &&
        (this.props.isBigbtn ? ' osbtn-icon-big text-blue' : ' osbtn-icon')) ||
      '';
    className +=
      this.props.disabled || this.props.disabledView ? ' osbtn-disabled' : '';
    return className;
  }

  getElementProps() {
    return omit(
      {
        ...this.props,
        className: this.getElementClass(),
        onClick: null,
        type: this.props.type,
        value: this.props.value,
        title: this.props.title,
        ...this.props.dataAttribute,
      },
      NON_HTML_ATTRIBUTES,
    );
  }

  getElement() {
    if (this.props.htmlTag === 'Link') {
      return Link;
    } else {
      return this.props.htmlTag || 'span';
    }
  }

  renderTooltip() {
    let { tooltipKey, tooltipText } = this.props,
      text = tooltipKey ? translate(tooltipKey) : tooltipText;
    if (text)
      return (
        <div className='button-tooltip'>
          <OrthoIcon
            name='info'
            defaultClass='lt-red'
            dataHoverNotRequired={true}
          />
          {text}
        </div>
      );
  }

  isIconOrTertiary() {
    return ['BtnIcon', 'BtnTertiary'].includes(this.props.name);
  }

  render() {
    let Element = this.getElement();
    if (this.props.type === 'submit' && this.props.htmlTag !== 'button') {
      return (
        <input
          type='submit'
          onClick={this.onClick}
          className={this.getElementClass()}
          value={this.props.value}
          {...this.props.dataAttribute}
        />
      );
    } else {
      return (
        <Element {...this.getElementProps()}>
          {this.isIconOrTertiary() && this.props.leftIcon && (
            <OrthoIcon
              name={this.props.icon}
              defaultClass={`btn-icon-helper ${this.props.iconDefaultClass}`}
              dataHoverNotRequired={true}
            />
          )}
          {this.props.loaderRequired && (
            <i className='fa fa-spinner fa-spin me-1'></i>
          )}
          {this.props.text}
          {this.props.children}
          {this.renderTooltip()}
          {this.isIconOrTertiary() && !this.props.leftIcon && (
            <OrthoIcon
              name={this.props.icon}
              defaultClass={`btn-icon-helper righticon ${this.props.iconDefaultClass}`}
              dataHoverNotRequired={true}
            />
          )}
          {this.isIconOrTertiary() && this.props.isRightIcon && (
            <OrthoIcon
              name={this.props.rightIcon}
              defaultClass={`btn-icon-helper righticon ${this.props.iconDefaultClass}`}
              dataHoverNotRequired={true}
            />
          )}
          <div
            className='btn-link-helper'
            onClick={this.onClick}
            data-text={this.props.text}></div>
        </Element>
      );
    }
  }
}

export default OsBtn;

OsBtn.defaultProps = {
  associatedEntity: {},
  eventAttributes: {},
  title: '',
  leftIcon: true,
  dataAttribute: {},
  iconDefaultClass: '',
};
