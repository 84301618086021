import React, { PureComponent } from 'react';

import UserRowInWidget from 'app/components/Shared/UserRowInWidget';

class UserRowsInWidget extends PureComponent {
  renderUser = (user) => {
    return (
      <UserRowInWidget
        key={user.id}
        user={user}
        designationRequired={this.props.designationRequired}
      />
    );
  };

  userToShow() {
    return this.props.users;
  }

  renderList() {
    return this.userToShow().map(this.renderUser);
  }

  render() {
    return (
      <ul
        className='list-unstyled dr-connect-list mb-0'
        key='user-connect-list'>
        {this.renderList()}
      </ul>
    );
  }
}

export default UserRowsInWidget;
