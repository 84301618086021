import {
  CLOSE_INFO_MODAL,
  OPEN_INFO_MODAL,
  UPDATE_INFO_MODAL,
} from 'app/actions/types';

export default function inforModal(
  state = {
    open: false,
    source: '',
    type: '',
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_INFO_MODAL:
      return {
        open: true,
        source: action.source,
        type: action.modalType,
        options: action.options,
      };
    case CLOSE_INFO_MODAL:
      return { open: false, source: '', type: '', options: {} };
    case UPDATE_INFO_MODAL:
      return { ...state, options: { ...state.options, ...action.options } };
    default:
      return state;
  }
}
