import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

import OsField from 'app/components/OsField';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { getDeepLinkIntegrationEnabled } from 'app/selectors/workspace';

const UPDATE_WORKSPACE_SETTING_MUTATION = queryLoader(
  'app/graphql/mutations/Workspace/UpdateSpaceSetting.gql',
);
const DeepLinkIntegrationCheckbox = () => {
  const deepLinkEnabled = useSelector(getDeepLinkIntegrationEnabled);
  const [updateSetting] = useMutation(UPDATE_WORKSPACE_SETTING_MUTATION);
  const toggleIntegration = () => {
    updateSetting({
      variables: {
        deepLink: !deepLinkEnabled,
      },
    });
  };

  return (
    <div
      class='form-group'
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '24px',
        marginBottom: '8px',
      }}>
      <h3
        style={{
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '16px',
        }}>
        Enable Cloudberry Sidebar ON/OFF
      </h3>
      <OsField
        osType='checkbox'
        onChange={toggleIntegration}
        slider={true}
        dataHoverRequired={!isTouchSupported()}
        className='switch'
        isChecked={deepLinkEnabled}
      />
    </div>
  );
};

export default DeepLinkIntegrationCheckbox;
