import jwt_decode from 'jwt-decode';

export function decryptCredentials(text) {
  let { data } = jwt_decode(text, '');
  return data;
  // var crypto = require('crypto'),
  //     algorithm = ENCRYPTION_ALGORITHM,
  //     key = process.env.REACT_APP_ENCRYPTION_KEY,
  //     iv = process.env.REACT_APP_ENCRYPTION_IV;
  //
  // var cipher = crypto.createDecipheriv(algorithm, key, iv)
  // var decrypted_text = cipher.update(text, "base64", "utf-8")
  // decrypted_text += cipher.final("utf-8");
  // return decrypted_text;
}
