import React, { Component } from 'react';
import { Field } from 'react-final-form';
import OsField from 'app/components/OsField';

class PatientDetail extends Component {
  render() {
    let { patients } = this.props;
    return (
      <>
        {patients.map((person) => (
          <Field
            component={OsField}
            key={person.id}
            className='os-custom-checkbox'
            label={`${person.name} (${person.relation})`}
            osType='checkbox'
            type='checkbox'
            name={person.name + person.relation}
            id={person.id}
          />
        ))}
      </>
    );
  }
}

export default PatientDetail;
