import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxReset from 'redux-reset';
import createReducers from './reducers';
import LoggerMiddleware from 'app/middleware/Logger';
import MonitorReducerEnhancer from './enhancers/MonitorReducer';

const middlewareEnhancer = applyMiddleware(LoggerMiddleware, thunkMiddleware);
const composedEnhancers = compose(middlewareEnhancer, MonitorReducerEnhancer);

const Store = createStore(
  createReducers(),
  compose(reduxReset(), composeWithDevTools(composedEnhancers)),
);
export default Store;
