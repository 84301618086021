import React, { Component } from 'react';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import { entityUrl } from 'app/utils/entitiesHelper';

import OsLink from 'app/components/OsLink';

class UserAvatar extends Component {
  getPath() {
    return (
      this.props.url ||
      entityUrl({ nice_id: this.props.user.nice_id, __typename: 'User' })
    );
  }

  renderNameOrSpecialityIfRequired() {
    if (this.props.nameRequired || this.props.specialityRequired)
      return (
        <div className='instructor-info-text'>
          {this.props.nameRequired && (
            <OsLink
              className='head-3 mb-0 white-link semibold'
              to={this.getPath()}
              text={this.props.user.full_name}
            />
          )}

          {this.props.specialityRequired && (
            <div className='font-light multiline-truncate'>
              {this.props.user.teaching_speciality}
            </div>
          )}
        </div>
      );
  }

  render() {
    let { user } = this.props;
    return (
      <div className='ins-avtar-col'>
        <div className='avatar-info text-center'>
          <Avatar
            className={`avatar avatar-${this.props.size}`}
            {...avatarAttributes(user, {
              largeAvatarRequired: this.props.largeAvatarRequired,
            })}
            url={this.props.url}
            asLink={true}
          />
          {this.renderNameOrSpecialityIfRequired()}
        </div>
      </div>
    );
  }
}
UserAvatar = connect(({ currentUser }) => ({ currentUser }), {})(UserAvatar);

UserAvatar.defaultProps = {
  size: '200',
  specialityRequired: false,
  nameRequired: true,
  largeAvatarRequired: false,
};

export default UserAvatar;
