import React from 'react';
import TaskListView from 'app/components/Task/TaskListView';
import Loader from 'react-loaders';
import {
  isCurrentDay,
  isTomorrowDay,
  isYesterdayDay,
} from 'app/utils/timeHelper';

function TaskListWeekView({ user, date, searchQuery, activeTasks, variables }) {
  const getCountOfIncompleteTasks = (data) => {
    return data.filter((d) => d.completed === null).length;
  };

  const getSectionTitleForActiveTasks = (day) => {
    let sectionTitle = '';
    if (isCurrentDay(day.date)) {
      sectionTitle = 'Today';
    } else if (isYesterdayDay(day.date)) {
      sectionTitle = `Yesterday`;
    } else if (isTomorrowDay(day.date)) {
      sectionTitle = `Tomorrow`;
    } else {
      sectionTitle = day.key;
    }
    return (
      sectionTitle +
      ` (${getCountOfIncompleteTasks(day.records)}/${day.records.length})`
    );
  };

  if (!activeTasks) {
    return <Loader type='ball-triangle-path' active />;
  }
  return (
    <>
      {activeTasks?.kanban_tasks?.map((day, index) => {
        return (
          <div key={index}>
            <TaskListView
              results={day.records}
              key={day.key}
              listType='ActiveTasks'
              sectionTitle={getSectionTitleForActiveTasks(day)}
              date={date}
              user={user}
              day={day}
              searchQuery={searchQuery}
            />
          </div>
        );
      })}
    </>
  );
}

export default TaskListWeekView;
