import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Occurance from './Occurance';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RenderCards from 'app/components/Cards/RenderCards';

import { entityUrl } from 'app/utils/entitiesHelper';
import {
  currentTimeWithUserTimeZoneInSeconds,
  timeDifferenceFromNowInDetails,
} from 'app/utils/timeHelper';
import {
  setHoveredApplianceLog,
  unsetHoveredApplianceLog,
} from 'app/actions/caseApplianceLogHovered';
import { setActiveApplianceLogCardId } from 'app/actions/caseDetailInfo';

// Indiviual applaince type on the timeline
export default class ApplianceLog extends Component {
  constructor(props) {
    super(props);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.state = {
      open: this.isOpen(),
    };
  }

  componentDidMount() {
    if (this.state.open && this.props.caseDetailInfo.activeCardId) {
      setTimeout(this.scrollToCardFirstSimilarCard, 2000);
    }
    this.props.setRef(this, this.props.logType);
  }

  scrollToCardFirstSimilarCard = () => {
    if (this.cardsContainer) {
      let card = this.cardsContainer.querySelector(
        `[data-card-identifier="CaseApplianceLogCard:${this.props.caseDetailInfo.activeCardId}"]`,
      );
      card && card.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  isOpen() {
    let { activeCardId } = this.props.caseDetailInfo;
    return (
      !!this.props.logs &&
      this.props.logs.some((log) => +log.id === +activeCardId)
    );
  }

  toggleOpen() {
    this.setState({ open: !this.state.open }, this.onExpandClick);
  }

  onExpandClick() {
    if (this.props.toggleOpenIfRequired)
      this.props.toggleOpenIfRequired(this.props.logType);
  }

  getLogType() {
    return this.props.logType === 'Fixed Appliance'
      ? 'Brackets'
      : this.props.logType;
  }

  isSBSViewSelected() {
    return this.props.caseDetailInfo.viewType === 'SBS';
  }

  activeApplianceIds() {
    const { selectedAppointment } = this.props.caseDetailInfo;
    if (!selectedAppointment || this.isSBSViewSelected()) {
      return [];
    } else {
      return selectedAppointment.active_action_ids.map(
        (id) => this.props.groupedActions[id].variant_log_id,
      );
    }
  }

  onLogCardClick = (log) => {
    if (this.context?.oldVersion) {
      this.props.navigate(entityUrl(log.tool));
    } else {
      this.props.setActiveApplianceLogCardId(log.id);
    }
  };

  getCardObjects() {
    let activeApplianceIds = this.activeApplianceIds();
    return this.props.logs.map((log) => ({
      ...log,
      isActive: activeApplianceIds.includes(log.id),
      hideActiveApplianceTag: true,
    }));
  }

  addHover = (log) => {
    this.props.setHoveredApplianceLog(log);
  };

  removeHover = () => {
    this.props.unsetHoveredApplianceLog();
  };

  render() {
    var logType = this.getLogType();
    if (this.props.logs == null) return null;
    return (
      <div className='appliance-log' onClick={this.toggleOpen}>
        <div className='name'>
          {logType}
          <div className='appliance-log-count'>({this.props.logs.length})</div>
        </div>
        <div className='duration'>
          <div className='base-line' />

          {this.props.logs.map((log) => {
            return (
              <Occurance
                key={log.id}
                id={log.id}
                timelineStartMonth={this.props.timelineStartMonth}
                timelineDuration={this.props.timelineDuration}
                start_date={log.addedAppointment?.date}
                end_date={
                  log.removedAppointment?.date ||
                  currentTimeWithUserTimeZoneInSeconds()
                }
                start_age={
                  log.addedAppointment?.patient_age ||
                  timeDifferenceFromNowInDetails(this.props.patientDob)
                }
                end_age={
                  log.removedAppointment?.patient_age ||
                  timeDifferenceFromNowInDetails(this.props.patientDob)
                }
                status={this.props.caseApplianceLogHovered.obj?.id}
                hoverOn={this.addHover.bind(this, log)}
                hoverOff={this.removeHover}
              />
            );
          })}
          <div className={`show-icon ${this.state.open ? 'active' : ''}`}>
            <OrthoIcon
              name={this.state.open ? 'minus' : 'plus'}
              dataHoverNotRequired={true}
            />
          </div>
        </div>
        <div
          className='appliance-list'
          ref={(cardsContainer) => {
            this.cardsContainer = cardsContainer;
          }}>
          {this.state.open && (
            <RenderCards
              objects={this.getCardObjects()}
              orderRowWise={false}
              goToPage={false}
              size='extra-small'
              cardGridIdentifier='CsAppointmentSummary:CardCol'
              cardsRowClass=''
              onCardClick={this.onLogCardClick}
            />
          )}
        </div>
      </div>
    );
  }
}

ApplianceLog = withRouter(ApplianceLog);
ApplianceLog = connect(
  ({ caseApplianceLogHovered, caseDetailInfo }) => ({
    caseApplianceLogHovered,
    caseDetailInfo,
  }),
  {
    setActiveApplianceLogCardId,
    setHoveredApplianceLog,
    unsetHoveredApplianceLog,
  },
)(ApplianceLog);
