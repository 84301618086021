import React from 'react';
import { connect } from 'react-redux';

import CaseAbstractSlider from './CaseAbstractSlider';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import RangeInput from 'app/components/Shared/RangeInput';
import CaseSortButtons from 'app/components/CaseView/CaseSortButtons';
import NoAppointmentDataView from 'app/components/Shared/NoAppointmentDataView';
import screenfull from 'screenfull';
import { isCaseVideo } from './helpers';
import { filter, isEmpty, isEqual } from 'app/utils/osLodash';

class CaseSlider extends CaseAbstractSlider {
  constructor(props) {
    super(props);
    this.state = {
      sliderRef: { mainSlider: null, subSlider: null },
      sliderKey: true,
    };
    this.slider = 'case-appointment-slider';
  }

  currentFile() {
    return this.props.caseDetailInfo.currentSlide;
  }

  componentDidMount() {
    if (!this.props.slideIndex) this.setExpectedSelectedFile();

    super.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.files, this.props.files)) {
      setTimeout(this.reassignCenterModeClasses, 200);
      this.resizeSubSlider();
    }

    let modeChanged =
        prevProps.caseDetailInfo.mode !== this.props.caseDetailInfo.mode,
      appointmentChanged =
        prevProps.caseDetailInfo.selectedAppointment?.id !==
        this.props.caseDetailInfo.selectedAppointment?.id;

    if (modeChanged) {
      this.setExpectedSelectedFile();
    }

    if (modeChanged || appointmentChanged) {
      this.toggleSliderKey();
    }

    if (
      prevProps.caseDetailInfo.selectedAppointment?.id !==
      this.props.caseDetailInfo.selectedAppointment?.id
    ) {
      let files = this.getSliderFiles(),
        selectedFile = filter(files, (file) => {
          return file.name === this.props.caseDetailInfo.currentSlide.name;
        });
      this.setSelectedFile(
        selectedFile.length > 0 ? selectedFile[0] : files[0],
      );
    }
  }

  setExpectedSelectedFile() {
    return this.setSelectedFile(this.getSliderFiles()[0]);
  }

  isFullScreen() {
    return this.props.isFullScreen;
  }

  onSlideChange = (index) => {
    let selectedFile = this.getSliderFiles()[index];
    selectedFile && this.props.notifyParentContainer(selectedFile);
  };

  nextBtn = () => {
    this.state.sliderRef.mainSlider.slickNext();
  };

  previousBtn = () => {
    this.state.sliderRef.mainSlider.slickPrev();
  };

  renderSubSlider() {
    return (
      <div className='case-thumbnail-slider'>
        {this.renderSlider('subSlider')}
      </div>
    );
  }

  subSliderRequired() {
    return !!this.props.files.length && !this.props.subSliderNotRequired;
  }

  renderVerticalScrubber() {
    let activeObject = this.props.caseDetailInfo.currentSlide;
    if (isCaseVideo(activeObject)) {
      let verticalAxisInfo = activeObject.vertical_axis_info;
      if (verticalAxisInfo.max) {
        return (
          <RangeInput
            axisInfo={verticalAxisInfo}
            orientation='vertical'
            extraClass='vertical'
            videoType={activeObject.video_type}
          />
        );
      }
    }
  }

  renderHorizontalScrubber() {
    let activeObject = this.props.caseDetailInfo.currentSlide;
    if (isCaseVideo(activeObject)) {
      let horizontalAxisInfo = activeObject.horizontal_axis_info;

      if (horizontalAxisInfo.max) {
        return (
          <RangeInput
            axisInfo={horizontalAxisInfo}
            mountUpdateRequired={true}
            orientation='horizontal'
            extraClass='horizontal'
            videoType={activeObject.video_type}
          />
        );
      }
    }
  }

  onClickFullscreen = () => {
    this.props.onClickFullscreen();
    setTimeout(this.reassignCenterModeClasses, 300);
  };

  renderAppointmentChangeHint() {
    if (this.props.appointmentChangeHintText)
      return (
        <div className='appointment_hint_text'>
          {this.props.appointmentChangeHintText}
        </div>
      );
  }

  setSelectedFile = (selectedFile) => {
    let slideIndex = this.getSliderFiles().findIndex(
        (file) =>
          selectedFile.id === file.id &&
          selectedFile.__typename === file.__typename,
      ),
      { subSlider, mainSlider } = this.state.sliderRef;

    mainSlider && mainSlider.innerSlider && mainSlider.slickGoTo(slideIndex);
    subSlider && subSlider.innerSlider && subSlider.slickGoTo(slideIndex);
    this.props.notifyParentContainer(
      selectedFile,
      this.props.selectedAppointment,
    );
  };

  renderGroupFiles(groupFiles) {
    return groupFiles.map((file) => {
      let activeObject = this.props.caseDetailInfo.currentSlide,
        selected =
          activeObject.name === file.name &&
          activeObject.__typename === file.__typename;
      return (
        <div
          onClick={this.setSelectedFile.bind(this, file)}
          key={`sbs-view-file-dropdown-${file.name}`}
          selected={selected}>
          {file.name}
        </div>
      );
    });
  }

  renderGroupHeader(group) {
    return (
      <li
        parentClassName='dropdown-group-header'
        onClick={() => {}}
        key={group}>
        {group}
      </li>
    );
  }

  getSliderFiles() {
    return this.props.files;
  }

  renderFileNamesInGroups() {
    let options = [],
      groupFiles = this.getSliderFiles();

    if (groupFiles.length) {
      options = [
        ...options,
        this.renderGroupHeader(this.props.caseDetailInfo.mode),
        this.renderGroupFiles(groupFiles),
      ];
    }
    return options;
  }

  getSelectedFileName() {
    let activeObject = this.props.caseDetailInfo.currentSlide;
    return !isEmpty(activeObject) && activeObject.name;
  }

  isScrubberModeSelected() {
    return isCaseVideo(this.props.caseDetailInfo.currentSlide);
  }

  render() {
    let activeObject = this.props.caseDetailInfo.currentSlide,
      isCurrentSlideSpin = isCaseVideo(activeObject),
      extraWrapperClass = this.isScrubberModeSelected()
        ? 'slide-with-scrubber'
        : '';

    if (this.getSliderFiles().length === 0) {
      return <NoAppointmentDataView />;
    }

    return (
      <div className='case-left-wrapper'>
        <div className={`spin-slide-wrapper ${extraWrapperClass}`}>
          <CaseSortButtons
            kase={this.props.kase}
            selectedAppointment={this.props.selectedAppointment}
          />
          <div
            className={`slider-controll-buttons ${
              isCurrentSlideSpin ? 'spin-cntrol-btn' : ''
            }`}>
            <CustomDropdown
              name='appointments_dates'
              dropdownInfo={{ title: this.getSelectedFileName() }}
              className='cs-dropdown mw-88 cs-file-dropdown'>
              {this.renderFileNamesInGroups()}
            </CustomDropdown>
          </div>
          <div className='spin-slide-body'>
            <div
              className={`spin-content new-os-slider ${
                isCurrentSlideSpin ? 'new-os-spin' : ''
              }`}>
              {this.renderAppointmentChangeHint()}
              {this.renderSlider('mainSlider')}
              {this.renderVerticalScrubber()}
              {this.renderHorizontalScrubber()}
              {screenfull.isEnabled && (
                <div className='cs-fullscreen'>
                  <OsBtn
                    name='BtnIcon'
                    extraClass='os-fullscreen-btn no-text'
                    label={`Case slider full screen ${
                      this.props.isFullScreen ? 'close' : 'open'
                    }`}
                    icon={this.props.isFullScreen ? 'collapse' : 'expand-2'}
                    onClick={this.props.onClickFullscreen}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {this.subSliderRequired() && this.renderSubSlider()}
      </div>
    );
  }
}

CaseSlider = connect(
  ({ caseDetailInfo, device, spin }) => ({ caseDetailInfo, device, spin }),
  null,
)(CaseSlider);
CaseSlider.defaultProps = {
  appointmentChangeHintText: '',
  files: [],
  notifyParentContainer: () => {},
};
export default CaseSlider;
