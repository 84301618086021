import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import Authenticate from 'app/services/Authenticate.js';
import EventTracker from 'app/services/EventTracker';
import LocalStorageManager from 'app/services/LocalStorageManager';
import StoreUpdater from 'app/services/StoreUpdater';

import { getvalidityDaysSetting } from 'app/utils/settingHelper';
import { openInfoModal } from 'app/actions/infoModal';
import { openOnboardingVerificationModals } from 'app/actions/onboardingTips';

import { isCurrentUser, isGuestUser } from 'app/utils/userHelper';

import store from 'app/store';

import {
  FORGOT_PASSWORD_MAIL,
  LOGIN_SUCCESS,
  OPEN_AUTH_MODAL,
  REFRESH_CURRENT_USER,
  REQUEST_SERVED,
  UPDATE_CURRENT_USER_GRAPH,
  UPDATE_CURRENT_USER_TOKEN,
  VERIFY_USER_EMAIL,
  VERIFY_USER_EMAIL_BY_TOKEN,
} from './types';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';
import { removeKeyFromLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';

const FORGOT_PASSWORD_MUTATION = queryLoader('app/graphql/ForgotPassword.gql');
const VERIFY_USER_EMAIL_MUTATION = queryLoader(
  'app/graphql/mutations/VerifyUserEmailMutation.gql',
);
const VERIFY_USER_EMAIL_BY_TOKEN_MUTATION = queryLoader(
  'app/graphql/mutations/VerifyUserEmailByTokenMutation.gql',
);

export function authModal(options) {
  return {
    type: OPEN_AUTH_MODAL,
    ...options,
  };
}

const redirectToLogin = () => window.location.href = "/login"

export function sendForgotPassword(emailOrPhoneNumber) {
  return (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_MAIL });
    return client
      .mutate({
        mutation: FORGOT_PASSWORD_MUTATION,
        variables: { emailOrPhoneNumber },
      })
      .then(({ data }) => {
        store.dispatch(authModal({ authModal: '' }));
        if (data.forgotPassword.success) {
          store.dispatch(
            openInfoModal('general', 'reset_password_sent_successfully', {onSuccess: redirectToLogin}),
          );
        } else {
          store.dispatch(
            openInfoModal('general', 'reset_password_send_failed'),
          );
        }
      });
  };
}

export function verifyUserEmail() {
  return (dispatch) => {
    dispatch({ type: VERIFY_USER_EMAIL });
    return client
      .mutate({
        mutation: VERIFY_USER_EMAIL_MUTATION,
      })
      .then(({ data }) => {
        LocalStorageManager.clear('verifyEmailOnLoginId');
        let user = data.verifyUserEmail.entity;
        if (user && isCurrentUser({ id: user.id })) {
          store.dispatch(
            updateCurrentUserGraph({
              next_verification_step: user.next_verification_step,
            }),
          );
        }
      });
  };
}

export function verifyUserEmailWithToken(confirmationToken) {
  return (dispatch) => {
    dispatch({ type: VERIFY_USER_EMAIL_BY_TOKEN });

    return client
      .mutate({
        mutation: VERIFY_USER_EMAIL_BY_TOKEN_MUTATION,
        variables: { confirmationToken },
      })
      .then(({ data }) => {
        if (data.confirmUserEmail.message !== 'Other User logged in') {
          isGuestUser() &&
            dispatch(login({ token: data.confirmUserEmail.token }));
          let user = data.confirmUserEmail.entity;
          if (user && isCurrentUser({ id: user.id })) {
            store.dispatch(
              updateCurrentUserGraph({
                next_verification_step: user.next_verification_step,
              }),
            );
          }

          let openOnboardingVerificationModal = () => {
            store.dispatch(openOnboardingVerificationModals());
          };
          if (data.confirmUserEmail.success) {
            store.dispatch(
              openInfoModal('general', 'email_verification_success', {
                onSuccess: openOnboardingVerificationModal,
              }),
            );
          } else {
            store.dispatch(
              openInfoModal('general', 'email_verification_failed', {
                onSuccess: openOnboardingVerificationModal,
              }),
            );
          }
        }
      });
  };
}

export function login(values) {
  return (dispatch) => {
    let { token } = values;
    values['validityDays'] = getvalidityDaysSetting(values);
    if (token) {
      let validityDays = values.validityDays;
      Authenticate.setUserTokenInCookie({ token, validityDays });
      EventTracker.login(values.email);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token },
      });
      // dispatch(flashSuccess('SUCCESSFULLY_LOGIN_MESSAGE'))
    }
  };
}

export function requestServed() {
  return (dispatch) => {
    dispatch({
      type: REQUEST_SERVED,
    });
  };
}

export function updateCurrentUserToken(token) {
  return (dispatch) => {
    Authenticate.setUserTokenInCookie({ token });
    dispatch({
      type: UPDATE_CURRENT_USER_TOKEN,
      payload: token,
    });
  };
}

export function logout() {
  return (dispatch, getState) => {
    Authenticate.removeUserTokenFromCookie();
    Authenticate.removeItem('token');
    Authenticate.removeItem('timeout');
    LocalStorageManager.clearUserSpecificValues();
    LocalStorageManager.clearAllDraftKeys();
    // dispatch(flashSuccess('SUCCESSFULLY_LOGOUT_MESSAGE'))
    IntercomTracker.shutdownIntercom();
    if (getState().currentUser && getState().currentUser.graph)
      EventTracker.logout(getState().currentUser.graph.email);

    // dispatch({ type: RESET });
    LocalStorageManager.clearApolloCachePersist();

    removeKeyFromLocalStorage('HTTP_SESSION_TOKEN')
    removeKeyFromLocalStorage('lockNav')
    removeKeyFromLocalStorage('identifier')
    removeKeyFromLocalStorage('storage')

    // NOTE:GP If anyone removes this page reload logic please do uncomment above line so that after user logout redux state get refreshes.
    window.location = '/login';
  };
}

export function refreshCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: REFRESH_CURRENT_USER,
    });
  };
}

export function updateCurrentUserInStore(graph) {
  return (dispatch, getState) => {
    if (getState().currentUser.graph) StoreUpdater.updateCurrentUser(graph);
  };
}

export function updateCurrentUserGraph(graph) {
  return (dispatch) => {
    dispatch(updateCurrentUserInStore(graph));
    dispatch({
      type: UPDATE_CURRENT_USER_GRAPH,
      payload: graph,
    });
  };
}

export function setCurrentUserGraph(graph) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_USER_GRAPH,
      payload: graph,
    });
  };
}
