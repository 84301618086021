import { isSiteOpenedInIframe } from '../utils/deviceHelper';

window.Cookies = require('js-cookie');
const getUserAuthTokenVariableName = () =>
  `${process.env.REACT_APP_ENVIRONMENT}_token`;

class Authenticate {
  setItem(key, value) {
    if (isSiteOpenedInIframe()) {
      sessionStorage.setItem(key, value);
    } else if (this.localStorageAvailable()) {
      localStorage.setItem(key, value);
    } else {
      this.setValueInCookie(key, value);
    }
  }

  removeUserTokenFromCookie() {
    let options = {
      ...(process.env.REACT_APP_COOKIE_DOMAIN && {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      }),
    };
    this.removeValueFromCookie(getUserAuthTokenVariableName(), options);
  }

  setUserTokenInCookie(data) {
    let options = {};
    if (data.validityDays) options = { expires: data.validityDays };
    this.setValueInCookie(getUserAuthTokenVariableName(), data.token, options);
  }

  removeValueFromCookie(key, options = {}) {
    if (isSiteOpenedInIframe()) {
      sessionStorage.removeItem(key);
    } else {
      window.Cookies.remove(key, options);
    }
  }

  setValueInCookie(key, value, options = {}) {
    if (value) {
      if (isSiteOpenedInIframe()) {
        sessionStorage.setItem(key, value);
      } else {
        window.Cookies.set(key, value, {
          ...(process.env.REACT_APP_COOKIE_DOMAIN && {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          }),
          // secure: true,
          ...options,
        });
      }
    }
  }

  getUserAuthValueFromCookie() {
    if (isSiteOpenedInIframe()) {
      return sessionStorage.getItem(getUserAuthTokenVariableName());
    } else {
      return this.getValueFromCookie(getUserAuthTokenVariableName());
    }
  }

  getValueFromCookie(key) {
    return window.Cookies.get(key);
  }

  getItem(key) {
    if (isSiteOpenedInIframe()) {
      return sessionStorage.getItem(key);
    } else if (this.localStorageAvailable()) {
      return localStorage.getItem(key);
    } else {
      return this.getValueFromCookie(key);
    }
  }

  removeItem(key) {
    if (isSiteOpenedInIframe()) {
      sessionStorage.removeItem(key);
    } else if (this.localStorageAvailable()) {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    } else {
      this.removeValueFromCookie(key);
    }
  }

  localStorageAvailable() {
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
var authenticate = new Authenticate();

export default authenticate;
