import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import RecordsView from 'app/components/RecordsView';
import InboxSection from 'app/components/Inbox/InboxSection';

const TasksInboxComponent = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { workspace } = props;

  const updateUnreadCount = (count) => {
    setUnreadCount(count);
  };
  const renderUnreadTasks = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        updateCount={updateUnreadCount}
        type={'MyUnreadTasks'}
        queryType={'TASK_INBOX_LIST'}
        sectionTitle={'Unread'}
        key={workspace?.data?.unread_task_inbox_count}
      />
    );
  };

  const renderReadTasks = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        type={'MyReadTasks'}
        queryType={'TASK_INBOX_LIST'}
        sectionTitle={'Previously Read'}
        key={workspace?.data?.unread_task_inbox_count}
      />
    );
  };
  return (
    <div className='inbox-wrapper'>
      <div className='inbox'>
        <InboxSection
          title={'Unread • Tasks'}
          type={'task_inbox'}
          hideSection={unreadCount === 0}
          markReadButton={true}>
          {(showBody) => {
            return <>{renderUnreadTasks(showBody)}</>;
          }}
        </InboxSection>
        <InboxSection title={'Previously Read'} expandable={unreadCount > 0}>
          {(showBody) => {
            return <>{showBody && renderReadTasks()}</>;
          }}
        </InboxSection>
      </div>
    </div>
  );
};

const TasksInboxConnect = connect(({ workspace }) => ({ workspace }))(
  TasksInboxComponent,
);
const TasksInbox = withRouter(TasksInboxConnect);

export default TasksInbox;
