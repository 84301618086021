import React, { useEffect, useRef, useMemo } from 'react';
import 'app/components/GlobalSearchView/globalSearchView.scss';
import useGlobalSearch from 'app/components/GlobalSearchView/hooks/useGlobalSearch';
import useGlobalSearchCountMapping from 'app/components/GlobalSearchView/hooks/useGlobalSearchCountMapping';
import RecordsView from 'app/components/RecordsView';
import RecordsPager from 'app/components/RecordsPager';
import Mark from 'mark.js';
import Loader from 'react-loaders';

const GlobalSearchView = () => {
  const { tabs, globalSearchQuery, activeTab, setActiveTab } =
    useGlobalSearch();

  const { countsMapping, loading } = useGlobalSearchCountMapping();

  useEffect(() => {
    if (!loading) {
      if (!activeTab || !countsMapping[activeTab.key])
        setActiveTab(tabs.find((tab) => countsMapping[tab.key]));
    }
  }, [loading, countsMapping]);

  const markRef = useRef(null);

  const renderContent = () => {
    switch (activeTab?.key) {
      case 'patients':
        return (
          <div className='table-wrap'>
            <RecordsView
              type={'AllPatientsOfClinic'}
              textQuery={globalSearchQuery.query}
              searchQuery={''}
              queryType='VISTIOR_DIRECTORY'
              cardProps={{ size: 'list', contactsOpen: false }}
              sortQuery={'latest_activity_desc'}
              perPage={10}
              highlightSearchTerms={highlightSearchTerms}
            />
          </div>
        );
      case 'patientActivities':
        return (
          <div>
            <RecordsView
              queryType='COMMENTS_ACTIVITY_LISTING'
              widgetAfterListItemNumber={{}}
              type='PatientActivitiesSearch'
              textQuery={globalSearchQuery.query}
              perPage={10}
              highlightSearchTerms={highlightSearchTerms}
              isGroupedDateRequired={false}
            />
          </div>
        );
      case 'files':
        return (
          <div className={`space-links-container all list`}>
            <RecordsView
              type={'ClinicAccessibleFiles'}
              queryType={'USER_DETAIL'}
              size={'list'}
              textQuery={globalSearchQuery.query}
              cardGridIdentifier={'FolderDetail:TableView'}
              additional_filters={{ filter_values: [] }}
              sortQuery={'date_added_desc'}
              highlightSearchTerms={highlightSearchTerms}
            />
          </div>
        );
      case 'tasks':
        return (
          <div>
            <RecordsView
              page={0}
              perPage={10}
              type={'AllWorkspaceTasks'}
              queryType={'TaskDirectory'}
              searchQuery={globalSearchQuery.query}
              sortQuery={'last_assigned_at'}
              trackScrolling={false}
              highlightSearchTerms={highlightSearchTerms}
              searchListing={true}
            />
          </div>
        );
      case 'groups':
        return (
          <div>
            <RecordsView
              page={0}
              perPage={10}
              type={'GroupSpacesSearch'}
              queryType={'GROUP_INBOX_LIST'}
              textQuery={globalSearchQuery.query}
              highlightSearchTerms={highlightSearchTerms}
            />
          </div>
        );
      case 'messages':
        return (
          <div>
            <RecordsView
              page={0}
              perPage={10}
              type={'ConversationsListing'}
              queryType='CONVERSATIONS_SEARCH'
              textQuery={globalSearchQuery.query}
              highlightSearchTerms={highlightSearchTerms}
              searchListing={true}
            />
          </div>
        );
      case 'partners':
        return (
          <div>
            <RecordsView
              type='PartnerSpaceActivitiesSearch'
              page={0}
              perPage={10}
              queryType='COMMENTS_ACTIVITY_LISTING'
              isGroupedDateRequired={false}
              highlightSearchTerms={highlightSearchTerms}
              textQuery={globalSearchQuery.query}
            />
          </div>
        );
      case 'folders':
        return (
          <div>
            <RecordsPager
              queryType='SPACE_DETAILS'
              loadingCards={true}
              type={'CollaboratoringFolders'}
              infiniteScroll={true}
              sortQuery={null}
              cardSize={'small'}
              twoPerRow={false}
              textQuery={globalSearchQuery.query}
              // {...additionalProps}
              noResultFound={true}
              fetchPolicy='cache-and-network'
              highlightSearchTerms={highlightSearchTerms}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const highlightSearchTerms = () => {
    let instance = new Mark(markRef.current);
    instance.unmark();
    instance.mark(globalSearchQuery.query.split(' '), {
      separateWordSearch: true,
      accuracy: 'partially',
    });
  };

  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => countsMapping[tab.key]);
  }, [tabs, countsMapping]);

  const renderTab = (tab) => {
    return (
      <div
        key={tab.name}
        className='tab'
        onClick={() => setActiveTab(tab)}
        style={activeTab.name === tab.name ? { color: '#354554' } : {}}>
        <span className='tab-text'>{`${tab.name} (${
          countsMapping[tab.key] || 0
        })`}</span>
        <div
          className='underline'
          style={
            activeTab.name === tab.name
              ? { background: '#354554', borderRadius: '50%' }
              : {}
          }></div>
      </div>
    );
  };
  if (loading) return <Loader type='ball-triangle-path' active />;

  return (
    <div className='global-search-view-container'>
      <div className='global-search-view-wrapper'>
        {filteredTabs.length > 0 ? (
          <>
            <div className='header'>
              <div className='global-search-view-query-text'>
                Searching for{' '}
                <span className='global-text'>'{globalSearchQuery.query}'</span>
              </div>

              <div className='global-search-view-tab-section'>
                {filteredTabs.map(renderTab)}
              </div>
            </div>

            <div
              ref={markRef}
              id='global-search-tab-content'
              className='global-search-view-tab-content'>
              {renderContent(activeTab)}
            </div>
          </>
        ) : (
          <>
            <div className='empty-state'>
              <div className='global-search-view-query-text'>
                No results found for{' '}
                <span className='global-text'>'{globalSearchQuery.query}'</span>
              </div>
              <h2>Did you know?</h2>
              <p>
                You can start a new search from at any point in time by just
                pressing “/”
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GlobalSearchView;
