import React from 'react';
import { Field } from 'react-final-form';
import OsField from 'app/components/OsField';

const PartnerShareRequests = ({ requests }) => {
  return (
    <>
      {requests.map((request) => (
        <Field
          component={OsField}
          key={request.id}
          className='os-custom-checkbox'
          label={`${request.name} (${request.email})`}
          osType='checkbox'
          type='checkbox'
          name={`ClinicShareRequest:${request.id}`}
          id={request.id}
        />
      ))}
    </>
  );
};

export default PartnerShareRequests;
