export const getMappedSelectInputValue = (entity) => {
  let label, value;
  switch (entity.__typename) {
    case 'Label':
      label = entity.name;
      value = entity.name;
      break;
    default:
      label = entity.name;
      value = entity.name;
  }

  return { ...entity, value, label };
};
