import React, { Component } from 'react';

import Icon from 'app/components/Svg';

import {
  getDateAndTime,
  timeGreaterThanCurrentTime,
} from 'app/utils/timeHelper';
import { translate } from 'app/actions/flashMessage';

class CourseLectureNotAvailableInfo extends Component {
  render() {
    let { end_time, start_time } = this.props.space,
      infoText,
      title;

    if (end_time && !timeGreaterThanCurrentTime(end_time)) {
      let courseStartInfo = getDateAndTime(end_time);
      infoText = translate(
        'COURSE_END_INFORMATION',
        { date: courseStartInfo.date, time: courseStartInfo.time },
        false,
      );
    } else if (start_time) {
      let courseStartInfo = getDateAndTime(start_time);
      infoText = translate(
        'COURSE_START_INFORMATION',
        { date: courseStartInfo.date, time: courseStartInfo.time },
        false,
      );
      title = translate('COURSE_NOT_STARTED');
    } else {
      infoText = translate('COURSE_START_SOON_INFORMATION');
      title = translate('COURSE_NOT_STARTED');
    }

    return (
      <div className='text-center'>
        <div className='mt-5'>
          <Icon name='cannotOpen' />
        </div>
        <div className='semibold pb-3'>
          <span>{title}</span>
        </div>
        <div className='course-start-info font-light m-auto os-text-7'>
          <span>{infoText}</span>
        </div>
      </div>
    );
  }
}

CourseLectureNotAvailableInfo.defaultProps = {
  space: {},
};
export default CourseLectureNotAvailableInfo;
