import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { translate } from 'app/actions/flashMessage';

export default class AllCaughtUp extends Component {
  render() {
    return (
      <div className='seen-all-post text-center'>
        <OrthoIcon
          name='round-check'
          dataHoverNotRequired={true}
          defaultClass='attention fs-16'
        />
        {translate('ALL_CAUGHT_UP')}
      </div>
    );
  }
}
