import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';

const SpaceMembers = (props) => {
  const getMemberType = (kind) => {
    switch (kind) {
      case 'admin':
        return 'Administrator';
      case 'editor':
        return 'Editor';
      case 'member':
        return 'Member';
      default:
        return '';
    }
  };

  const renderMembers = (object) => {
    const { collaborator } = object;
    if (!collaborator) {
      return null;
    }

    return (
      <Link key={object.id} to={entityUrl(collaborator)} className='user-block'>
        <Avatar
          className='avatar avatar-40'
          {...avatarAttributes(collaborator, { entityImage: true })}
        />
        <div className='info'>
          <div className='text'>{collaborator.name}</div>
          <div className='role'>{getMemberType(object.kind)}</div>
        </div>
      </Link>
    );
  };

  const renderCollection = () => {
    let userList = props.results;

    return userList.map(renderMembers);
  };
  return <div className='space-members-modal'>{renderCollection()}</div>;
};

export default SpaceMembers;
