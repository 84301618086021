import ActionCable from 'actioncable';
import store from 'app/store';

class ActionCableProvider {
  constructor(props) {
    this.token = null;
    this.consumer = ActionCable.createConsumer(this.generateURL());

    try {
      store.subscribe && store.subscribe(this.storeChange);
    } catch (e) {
      console.log(e);
    }
  }

  generateURL() {
    return ActionCable.createWebSocketURL(
      `${process.env.REACT_APP_ACTIONCABLE_URL}?token=${this.token}`,
    );
  }

  storeChange = () => {
    const {
      currentUser: { token },
    } = store.getState();

    if (this.token !== token && token) {
      this.token = token;
      this.consumer.url = this.generateURL();
      this.consumer.connection.reopen();
    }
  };
}

export default new ActionCableProvider();
