import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import ArchiveSpace from 'app/components/Shared/ArchiveSpace';
import OsBtn from 'app/components/OsBtn';
import Icon from 'app/components/Svg';

import { canSpaceBeArchived } from 'app/utils/spaceHelper';

import { translate } from 'app/actions/flashMessage';
import { ENTITY_NAME_MAPPER } from 'app/constants';

class DeleteConfirmationModal extends Component {
  state = {
    requestInProgress: false,
  };

  toggleRequestInProgress = () => {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  };

  deleteEntity = (e) => {
    e.stopPropagation();
    this.toggleRequestInProgress();
    this.props.deleteEntity();
  };

  entityType() {
    return (
      ENTITY_NAME_MAPPER[this.props.entityType.toLowerCase()] ||
      this.props.entityType.toLowerCase()
    );
  }

  renderHeaderContent = () => {
    return (
      <div
        className='modal-header border-0 px-0 pt-0 header-tab-combo'
        key='headerAndTabs'>
        {this.props.title ? (
          <h4 className='modal-title' id='modalLabel'>
            {this.props.title}
          </h4>
        ) : (
          <h4 className='modal-title' id='modalLabel'>
            Delete my {this.entityType()}
          </h4>
        )}
        {/* <h4 className="modal-title" id="modalLabel">Delete my { this.entityType() }</h4> */}
        <OsBtn
          name='BtnIcon'
          extraClass='no-text os-header-btn web-view-btn r-0 close-del-confirmation'
          icon='close'
          label='Close delete confirmation modal'
          onClick={this.props.onClose}
        />
      </div>
    );
  };

  textContent = () => {
    return (
      <div className='modal-body-text'>
        {translate(this.props.text, { entityType: this.entityType() }, true, {
          connector: '',
        })}
      </div>
    );
  };

  renderArchiveButton() {
    if (this.entityType() === 'space') {
      return (
        canSpaceBeArchived(this.props.entity) && (
          <ArchiveSpace
            space={this.props.entity}
            source={this.props.source}
            confirmationRequired={false}
            text='No, archive instead'
            name='BtnPrimary'
          />
        )
      );
    }
  }

  renderButtons = () => {
    return (
      <div className='m-0'>
        <OsBtn
          name='BtnIcon'
          extraClass='px-16'
          disabled={this.state.requestInProgress}
          loaderRequired={this.state.requestInProgress}
          text={'Delete ' + this.entityType()}
          onClick={this.deleteEntity}
          associatedEntity={this.props.entity}
        />
        {this.props.archiveNeeded && this.renderArchiveButton()}
        {this.props.secondaryBtnNeeded && (
          <OsBtn
            name='BtnPrimary'
            text='No, keep it'
            onClick={this.props.onClose}
            associatedEntity={this.props.entity}
          />
        )}
      </div>
    );
  };

  renderSVGContent = () => {
    return (
      <div className='svg-image mb-4 text-center'>
        <Icon name='delete' />
      </div>
    );
  };

  handleModalClick = (e) => {
    e.stopPropagation();
  };

  render() {
    return (
      <div onClick={this.handleModalClick}>
        <Modal
          show={true}
          onHide={this.props.onClose}
          animation={false}
          dialogClassName='pulse-report-modal del-confirmation-modal'
          backdropClassName='modal-backdrop-custom'>
          {this.renderHeaderContent()}
          <div className='modal-body px-24'>
            {this.renderSVGContent()}
            {this.textContent()}
          </div>
          <div className='modal-footer p-0 justify-content-md-end justify-content-center'>
            {this.renderButtons()}
          </div>
        </Modal>
      </div>
    );
  }
}

DeleteConfirmationModal.defaultProps = {
  text: 'DELETE_ENTITY_TEXT',
  secondaryBtnNeeded: true,
};

export default DeleteConfirmationModal;
