import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import Emitter from 'app/services/Emitter';
import ParseText from 'app/components/ParseText';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';
import { setActiveResource } from 'app/actions/activeResource';

import LocalStorageManager from 'app/services/LocalStorageManager';

import { pluralize } from 'app/utils/generalHelper';
import { entityUrl } from 'app/utils/entitiesHelper';

import { PINNED_CONVERSATIONS_BATCH_SIZE } from 'app/constants';
import RecordsPager from 'app/components/RecordsPager';
import { COMMENTS_BATCH_SIZE } from 'app/components/CommentSection/constants';
import NewSpaceMessage from 'app/components/GroupMessenger/NewSpaceMessage';
import { PinnedMessageContext } from 'app/context/PinnedMessageContext';
import PinnedMessageModal from 'app/components/TeamSpaceCommunicationsView/PinnedMessageModal';
import SpaceMembersModal from 'app/components/GroupMessenger/SpaceMembersModal';
import { isCompanySpace } from 'app/utils/spaceHelper';
import { openEditImageModal } from 'app/actions/editImage';
import OsBtn from '../OsBtn';
import { isGuestUser } from 'app/utils/userHelper';
import GroupChatMenu from 'app/components/GroupMessenger/GroupChatMenu';
import JoinSpace from 'app/components/SpaceRequests/JoinSpace';
import { REJECTED_STATUS } from 'app/components/BoardView/constants';
import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';
import 'app/components/GroupMessenger/group-chat-window.scss';

const PINNED_COMMENTS = queryLoader('app/graphql/queries/PinnedComments.gql');
const COMMENTABLE_QUERY = queryLoader(
  'app/graphql/queries/CommentableQuery.gql',
);

const REPLACEBALE_URL_IDS = ['recent'];
// Entire Conversation
class SpaceChatWindowWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceMemberModalVisible: false,
      mentionees: [],
      isReplying: false,
      focusWriteComment: false,
      pinnedChatModalVisible: false,
      pinnedChatTarget: null,
      spaceModalTarget: null,
      pinnedMessages: [],
      pinnedMessageCount: props.data.conversation?.pinned_message_count || 0,
    };
    this.pinnedRef = React.createRef();
    this.spaceModalRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.data.loading) this.setActiveConversationInLocalStorage();

    Emitter.on('REPLY_TEAMSPACE', this.handleOnReplyClick);
    IntercomTracker.hideWidgetLauncher();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.pinnedMessageQuery.loading &&
      !nextProps.pinnedMessageQuery.loading
    ) {
      const { pinnedMessageQuery } = nextProps;
      this.setPinnedMessages(
        pinnedMessageQuery?.records?.results,
        pinnedMessageQuery?.records?.total,
      );
    }

    if (this.props.data.loading && !nextProps.data.loading) {
      let { entity } = nextProps;
      const { state = {} } = this.props.location;
      if (REPLACEBALE_URL_IDS.includes(this.props.nice_id)) {
        window.history.replaceState(
          state,
          '',
          withWorkspaceIdentifier(`/team/group/${entity.nice_id}`, true),
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data.loading)
      this.setActiveConversationInLocalStorage();
  }

  componentWillUnmount() {
    Emitter.removeListener('REPLY_TEAMSPACE', this.handleOnReplyClick);
    IntercomTracker.showWidgetLauncher();
  }

  setPinnedMessages = (pinnedMessages = [], total = 0) => {
    this.setState((prev) => ({
      ...prev,
      pinnedMessages,
      pinnedMessageCount: total || pinnedMessages.length,
    }));
  };

  handleOnReplyClick = (data) => {
    if (this.state.isReplying) {
      this.handleOnReplyClose();
    } else {
      this.setState((prev) => ({
        ...prev,
        replyObject: data,
        isReplying: true,
        focusWriteComment: true,
      }));
    }
  };

  handleOnReplyClose = () => {
    this.setState((prev) => ({
      ...prev,
      replyObject: {},
      isReplying: false,
      focusWriteComment: false,
    }));
  };

  setActiveConversationInLocalStorage() {
    LocalStorageManager.setActiveConversation(this.props.data.conversation);
  }

  getConversationName(otherParticipants) {
    let { kind } = this.props.data.conversation,
      otherParticipant = otherParticipants[0];

    return (
      <div className='user-name'>
        {kind === 'normal' && (
          <>
            <OsLink
              className='white-link white-link-blue'
              to={entityUrl(otherParticipant)}
              text={otherParticipant.name || otherParticipant.email}
            />
            {otherParticipants.length > 1 && (
              <span>
                {' '}
                &{' '}
                <a
                  onClick={this.showGroupInfo}
                  href='javascript:void(0)'
                  className='a-link font-weight-semibold'>
                  {otherParticipants.length - 1} more team{' '}
                  {pluralize(otherParticipants.length - 1, {
                    singular: 'member',
                    plural: 'members',
                  })}
                </a>
              </span>
            )}
          </>
        )}
      </div>
    );
  }

  showGroupInfo = () => {
    this.setState({ groupInfoVisible: true });
  };

  closeGroupInfo = () => {
    this.setState({ groupInfoVisible: false });
  };

  hideSpaceMembersModal = () => {
    this.setState({ spaceMemberModalVisible: false });
  };

  showSpaceMembersModal = (event) => {
    this.setState({
      spaceMemberModalVisible: !this.state.spaceMemberModalVisible,
      spaceModalTarget: event.target,
    });
  };

  handlePinnedChat = (event) => {
    this.setState({
      pinnedChatModalVisible: !this.state.pinnedChatModalVisible,
      pinnedChatTarget: event.target,
    });
  };

  hidePinnedChat = () => {
    this.setState((prev) => ({
      ...prev,
      pinnedChatModalVisible: false,
    }));
  };

  renderChatInfo(entity) {
    const { pinnedMessageCount } = this.state;
    const members_count = entity.all_members_count || 1;
    return (
      <div className='info'>
        <span onClick={this.showSpaceMembersModal}>
          {members_count}{' '}
          {pluralize(members_count, {
            singular: 'Member',
            plural: 'Members',
          })}
        </span>
        <span onClick={this.handlePinnedChat}>
          <OrthoIcon name='pin' dataHoverNotRequired={true} />{' '}
          <span className='pinned-count-text'>
            {pinnedMessageCount} pinned{' '}
            {pluralize(pinnedMessageCount, {
              singular: 'message',
              plural: 'messages',
            })}
          </span>
        </span>
      </div>
    );
  }

  getSpace() {
    return this.props.entity;
  }

  isAuthorOrEditor() {
    return this.props.entity.is_author_or_editor;
  }

  renderEditImageButton(source = '') {
    let isUnderDropdown = source === 'dropdown',
      extraClass =
        this.props.device.mobileDevice || isUnderDropdown
          ? ' list-button '
          : ' web-view-btn back-nav-btn ';
    return (
      <OsBtn
        name='BtnIcon'
        iconClass='icon-os-edit ifill-os-edit'
        iconDefaultClass='ifill-os-edit'
        text='Edit Image'
        extraClass={`edit-image-btn ${extraClass}`}
        onClick={this.props.openEditImageModal.bind(this, {
          space: this.getSpace(),
        })}
      />
    );
  }

  renderHeaderImage() {
    let { image_attachment } = this.getSpace().space_banner,
      mobile = this.props.device.mobileDevice;
    return (
      <div className='image-figure'>
        <img
          src={image_attachment.preview_url}
          alt=''
          className='space-hero-image'
        />
        {!mobile &&
          !isGuestUser() &&
          this.isAuthorOrEditor() &&
          this.renderEditImageButton()}
      </div>
    );
  }

  renderJoinSpace(extraClass) {
    return (
      <JoinSpace
        extraClass={extraClass}
        text={'Join'}
        viewType='primaryAction'
        requestingUsers={[this.props.currentUser.graph]}
        board={this.getSpace()}
        onSuccess={this.onSuccessActionOnRequest}
      />
    );
  }

  renderRequestResponse() {
    let space = this.getSpace(),
      status = space.member_request_status,
      extraClass = this.props.device.mobileDevice
        ? 'd-block web-view-btn'
        : ' request-join-btn';

    if (!status || REJECTED_STATUS.includes(status)) {
      if (!isCompanySpace(space)) {
        return this.renderJoinSpace(extraClass);
      }
    } else if (status === 'pending') {
      let btnProps = {
        extraClass: extraClass,
        space: space,
        requestingUser: this.props.currentUser.graph,
        checkAccess: true,
      };
      if (space.member_requested_by_author) {
        return (
          <>
            <AcceptRequest {...btnProps} text='accept invitation' />
            <DeclineRequest {...btnProps} />
          </>
        );
      } else {
        return (
          <DeclineRequest
            {...btnProps}
            btnType='BtnPrimary'
            text='Cancel Request'
            onSuccess={this.onSuccessActionOnRequest}
          />
        );
      }
    }
  }

  renderChatHeader() {
    const { entity } = this.props;

    return (
      <div className='chat-header'>
        <div className='header-info'>
          <div className='folders left-header'>
            <Link
              to={withWorkspaceIdentifier('/team')}
              className='group-back-btn'>
              <OrthoIcon name='chevron-left' dataHoverNotRequired={true} />
            </Link>
            {this.renderHeaderImage()}
            <div>
              <OrthoIcon name='square' dataHoverNotRequired={true} />
            </div>
            <div className='space-detail'>
              <span className='title'>{entity.name}</span>
              {this.renderChatInfo(entity)}
            </div>
          </div>
          <div className='right-header'>
            {/* <OrthoIcon name='search' dataHoverNotRequired={true} />
            <OrthoIcon name='setting' dataHoverNotRequired={true} /> */}

            {this.haveGroupAccess() ? (
              <GroupChatMenu
                position={'left'}
                spaceId={entity.nice_id}
                entity={entity}
              />
            ) : (
              this.renderRequestResponse()
            )}
          </div>
        </div>
      </div>
    );
  }

  haveGroupAccess() {
    return this.getSpace().is_author_or_collaborator;
  }

  commentable() {
    return (
      (this.props.data ? this.props.data.commentable : this.props.entity) || {}
    );
  }

  getEntityOwner() {
    let entity = this.props.entity;
    switch (entity.__typename) {
      case 'Board':
      case 'Comparison':
        return entity.user;
      case 'Case':
        return entity.doctor;
      case 'Pulse':
        return entity.author;
      default:
        return null;
    }
  }

  getEntity() {
    return { ...this.props.entity, ...(this.props.data?.commentable || {}) };
  }

  isReadOnly() {
    return this.props.readOnly || !this.commentable().can_user_comment;
  }

  additionalFilter() {
    return this.props.match.params.additionalFilter;
  }

  setAroundId = (aroundId) => {
    this.setState((prev) => ({ ...prev, aroundId }));
  };

  setScrollToCommentId = (scrollToCommentId) => {
    this.setState((prev) => ({
      ...prev,
      scrollToCommentId,
      replyToCommentId: scrollToCommentId && this.state.replyToCommentId,
    }));
  };

  refetchComments = ({ id: commentId }) => {
    this.setAroundId(commentId);
    this.setScrollToCommentId(commentId);
    this.setState({ keyToggler: !this.state.keyToggler });
  };

  renderComments() {
    let { commentableId, commentableType, currentUser } = this.props;
    return (
      <RecordsPager
        windowNotRequired={true}
        aroundId={this.state.aroundId}
        commentable={this.commentable()}
        commentableEntityOwner={this.getEntityOwner()}
        commentableId={commentableId}
        commentableType={commentableType}
        entity={this.getEntity()}
        limit={COMMENTS_BATCH_SIZE}
        queryType='COMMENTS_ACTIVITY_LISTING'
        isReverse={true}
        currentUser={currentUser}
        parentContainer={this.parentContainer}
        readOnly={this.isReadOnly()}
        recordId={commentableId}
        recordType={commentableType}
        replyToCommentId={this.state.replyToCommentId}
        sortQuery={'recent'}
        textQuery={this.additionalFilter()}
        type='GroupSpaceActivities'
        afterThreshold={1}
        updateBeforeHasMore={this.updateBeforeHasMore}
      />
    );
  }

  isCommentable(type) {
    return this.props.commentableType === type;
  }

  getCommonWriteCommentAttributes() {
    return {
      entity: this.state.replyObject?.entity || this.getEntity(),
      setScrollToCommentId: this.state.replyObject?.setScrollToCommentId,
      commentableId: this.state.replyObject?.commentableId,
      commentableType: this.state.replyObject?.commentableType,
      commentableEntityOwner: this.state.replyObject?.commentableEntityOwner,
    };
  }

  showReplyParent() {
    const { replyObject } = this.state;
    let parentClass = 'parent-msg-box ';
    return (
      <div className='conversation-message-box'>
        <div className='c-close'>
          <OrthoIcon name='close' onClick={this.handleOnReplyClose} />
        </div>
        <div className={parentClass}>
          <div className='c-author'>{replyObject.message.author.name}</div>
          <div className='c-text'>
            <ParseText
              obj={replyObject.message}
              className='conversation-message-text'
            />
          </div>
        </div>
      </div>
    );
  }

  renderWriteComment() {
    let commentable = this.commentable(),
      commentsCount = commentable.comments_count,
      refetchOnCreateRequired = commentsCount < 1 || this.state.beforeHasMore;
    return (
      <>
        {this.haveGroupAccess() && (
          <>
            <NewSpaceMessage
              {...this.getCommonWriteCommentAttributes()}
              aroundId={this.state.aroundId}
              disabled={!this.haveGroupAccess()}
              author={this.state.replyObject?.author}
              onClose={this.handleOnReplyClose}
              commentableEntityOwner={this.getEntityOwner()}
              commentableId={this.props.commentableId}
              commentableType={this.props.commentableType}
              focusWriteComment={this.state.focusWriteComment}
              newComment={true}
              refetchOnCreate={refetchOnCreateRequired && this.refetchComments}
              setScrollToCommentId={this.setScrollToCommentId}
              sortQuery={this.state.sortBy}
              textQuery={this.additionalFilter()}
              isReplying={this.state.isReplying}
              replyObject={this.state.replyObject}
              mentionees={this.state.replyObject?.mentionees}
              replyingToCommentId={
                this.state.replyObject?.replyObjectreplyingToCommentId
              }
              parentId={this.state.replyObject?.parentId}
              parentData={this.state.replyObject?.message}
              isFullPageListing={true}
            />
          </>
        )}
      </>
    );
  }

  render() {
    if (this.props.data.loading)
      return (
        <Loader type='ball-triangle-path' active className='full-wh-loader' />
      );

    const { pinnedMessages, pinnedMessageCount } = this.state;
    const { setPinnedMessages } = this;
    const { entity } = this.props;
    return (
      <PinnedMessageContext.Provider
        value={{
          pinnedMessageCount,
          pinnedMessages,
          setPinnedMessages,
        }}>
        <div className='chat-window-wrapper'>
          <div
            className='chat-window'
            ref={(el) => (this.parentContainer = el)}>
            {this.renderChatHeader(this.props)}

            {this.renderComments()}
            {this.renderWriteComment()}
          </div>

          <div ref={this.spaceModalRef} className='member-modal'>
            <SpaceMembersModal
              show={this.state.spaceMemberModalVisible}
              spaceModalRef={this.spaceModalRef.current}
              target={this.state.spaceModalTarget}
              entity={entity}
              onHide={this.hideSpaceMembersModal}
            />
          </div>

          <div ref={this.pinnedRef} className='pinned-tooltip'>
            <PinnedMessageModal
              show={this.state.pinnedChatModalVisible}
              pinnedRef={this.pinnedRef.current}
              target={this.state.pinnedChatTarget}
              entity={this.commentable()}
              onHide={this.hidePinnedChat}
              queryType={'PINNED_COMMENTS'}
              recordType={'PinnedComments'}
            />
          </div>
        </div>
      </PinnedMessageContext.Provider>
    );
  }
}

const SpaceChatWindowQuery = compose(
  graphql(COMMENTABLE_QUERY, {
    skip: (props) =>
      props.commentableType !== 'Board' || !props.currentUser.graph,
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        commentableId: props.commentableId,
        commentableType: props.commentableType,
        spaceId: props.commentableType === 'Board' ? props.commentableId : '',
      },
    }),
  }),
  graphql(PINNED_COMMENTS, {
    name: 'pinnedMessageQuery',
    options: (props) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id_query: props.commentableId,
          page: 0,
          perPage: PINNED_CONVERSATIONS_BATCH_SIZE,
          type: 'PinnedComments',
        },
      };
    },
  }),
)(SpaceChatWindowWrapper);

const SpaceChatWindowConnect = connect(({ device }) => ({ device }), {
  setActiveResource,
  openEditImageModal,
})(SpaceChatWindowQuery);
const SpaceChatWindowRouter = withRouter(SpaceChatWindowConnect);

export default SpaceChatWindowRouter;
