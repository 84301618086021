import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';
import { setWorkspaceData } from 'app/actions/workspace';

const INBOX_COUNT_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/Inbox/WorkspaceInboxApiSubscription.gql',
);

const InboxCountSubscription = ({ workspaceId, updateSubscriptionData }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const { data: subscriptionData, loading } = useSubscription(
    INBOX_COUNT_SUBSCRIPTION,
    {
      variables: { workspaceId: workspaceId, userId: currentUser.graph.id },
    },
  );

  useEffect(() => {
    if (!loading && subscriptionData) {
      const { workspace_inbox_api_subscription } = subscriptionData;
      if (workspace_inbox_api_subscription?.entity) {
        let workspaceData = { ...getWorkspaceData() };
        let dataEntity = workspace_inbox_api_subscription.entity;
        if((+dataEntity.id === +workspaceData.id) && dataEntity.__typename === 'Board') {
          workspaceData = {
            ...workspaceData,
            ...workspace_inbox_api_subscription.entity,
          };
          dispatch(setWorkspaceData(workspaceData));
          updateSubscriptionData(workspace_inbox_api_subscription?.entity);
        }
      }
    }
  }, [subscriptionData, loading]);
};

export default InboxCountSubscription;
