import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ALL_TABS = [
  {
    name: 'Patients',
    key: 'patients',
  },
  {
    name: 'Patient Activity',
    key: 'patientActivities',
  },
  {
    name: 'Files',
    key: 'files',
  },
  {
    name: 'Tasks',
    key: 'tasks',
  },
  {
    name: 'Groups',
    key: 'groups',
  },
  {
    name: 'DMs',
    key: 'messages',
  },
  {
    name: 'Partners',
    key: 'partners',
  },
  {
    name: 'Folders',
    key: 'folders',
  },
];

const useGlobalSearch = () => {
  const globalSearchQuery = useSelector((state) => state.globalSearchQuery);
  const [tabs, setTabs] = useState(ALL_TABS || []);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setTabs(ALL_TABS);
    setActiveTab(ALL_TABS[0]);
  }, [globalSearchQuery]);
  return { tabs, globalSearchQuery, activeTab, setActiveTab, setTabs };
};

export default useGlobalSearch;
