import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
      fill='#FFC933'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.008 16.516C8.31 15.025 10.054 14 12 14c1.946 0 3.69 1.025 3.992 2.516.046.222-.114.435-.355.477-.241.042-.473-.105-.518-.327-.198-.973-1.438-1.848-3.119-1.848-1.68 0-2.92.875-3.119 1.848-.045.222-.277.369-.518.327-.242-.042-.4-.255-.355-.477z'
      fill='#222226'
    />
    <path
      d='m8.609 8.067-1.529.987a.49.49 0 0 1 .202-.045c.375 0 .67.33.67.748 0 .419-.295.748-.67.748A.572.572 0 0 1 7 10.43c.12.912.496 1.57.952 1.57.55 0 1.005-1.002 1.005-2.243 0-.673-.134-1.271-.348-1.69zM15.338 8l1.528.987.08.06c.027.21.054.418.054.643 0 .239-.027.463-.054.673-.12.912-.496 1.57-.951 1.57-.55 0-1.006-1.002-1.006-2.243 0-.673.134-1.271.349-1.69z'
      fill='#222226'
    />
    <path
      d='M8 9.719c0-.426-.293-.76-.667-.76a.478.478 0 0 0-.2.045l-.08.06C7.013 9.279 7 9.49 7 9.72c0 .243.027.471.053.684a.56.56 0 0 0 .28.076c.374 0 .667-.335.667-.76zM16 9.65c0-.426-.293-.76-.667-.76a.479.479 0 0 0-.2.045l-.08.061c-.026.213-.053.426-.053.654 0 .244.027.472.053.685.08.045.174.076.28.076.374 0 .667-.335.667-.76z'
      fill='#F7F7F8'
    />
    <path
      d='M7 14.667C7 15.403 6.552 16 6 16s-1-.597-1-1.333S5.664 12 6 12c.336 0 1 1.93 1 2.667z'
      fill='#6495F7'
    />
  </svg>
);

export default SVG;
