import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConnectToSupport from 'app/components/ConnectToSupport';

import { entityUrl } from 'app/utils/entitiesHelper';
import { translate } from 'app/actions/flashMessage';
import { isClinicSpace, isCompanySpace } from 'app/utils/spaceHelper';
import {
  isLecturePreviewTab,
  isOnDiscussionsTab,
  getNavTab,
} from 'app/components/NavTabs/helper';
import {
  getDateAndTime,
  timeGreaterThanCurrentTime,
} from 'app/utils/timeHelper';

class SpaceBanner extends Component {
  getSpace() {
    return this.props.space;
  }

  guestUser() {
    return !this.props.currentUser.graph;
  }

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  isMemberAndNotEditor() {
    return (
      this.getSpace().is_author_or_member &&
      +this.props.currentUser.graph.id !== +this.getSpace().user.id
    );
  }

  renderClinicOrCompanyEditorRequestBanner() {
    if (false && !this.guestUser() && this.isMemberAndNotEditor()) {
      let space = this.getSpace();
      if (isClinicSpace(space) || isCompanySpace(space))
        return (
          <div className='hint-text space-banner'>
            {translate(
              'ORAGNIZATION_BECOME_EDITOR_NUDGE_MESSAGE',
              {
                support_link: (
                  <ConnectToSupport
                    text='here'
                    prefilledTextKey='ORGANIZATION_EDITOR_REQUEST_TO_SUPPORT_FLO_MESSAGE'
                    prefilledTextProps={{ link: entityUrl(space, true) }}
                  />
                ),
              },
              false,
            )}
          </div>
        );
    }
  }

  privateContentWarningRequired() {
    return (
      this.isAuthorOrEditor() &&
      this.getSpace().has_private_links &&
      this.props.selectedFilter === 'all'
    );
  }

  renderPrivateContentHint() {
    if (this.privateContentWarningRequired()) {
      return (
        <div className='hint-text space-banner'>
          {translate('PRIVATE_CONTENT_IN_SPACE')}
        </div>
      );
    }
  }

  ownerAndEditorHint() {
    if (
      this.isAuthorOrEditor() &&
      isLecturePreviewTab(this.props.selectedFilter, this.props.space)
    ) {
      return (
        <div className='hint-text space-banner'>
          {translate('OWNER_EDITOR_FOR_TAB_HINT')}
        </div>
      );
    }
  }

  guestDiscussionViewBanner() {
    if (
      this.guestUser() &&
      isOnDiscussionsTab(this.props.selectedFilter, this.props.space)
    ) {
      return (
        <div className='hint-text space-banner'>
          {translate('GUEST_USER_DISCUSSION_BANNER')}
        </div>
      );
    }
  }

  renderTabVisibleBanner() {
    let tab = getNavTab(this.props.space, this.props.selectedFilter);
    if (
      tab &&
      timeGreaterThanCurrentTime(tab.visible_at) &&
      this.isAuthorOrEditor()
    ) {
      let dateData = getDateAndTime(tab.visible_at);
      return (
        <div className='hint-text space-banner'>
          {translate('TAB_VISIBLE_FROM_BANNER', {
            time: `${dateData.date} @ ${dateData.time}.`,
          })}
        </div>
      );
    }
  }

  render() {
    return (
      <div className='xl-container container-fluid px-hd-40 px-sm-32 px-m-12'>
        {this.renderClinicOrCompanyEditorRequestBanner()}
        {this.renderPrivateContentHint()}
        {this.ownerAndEditorHint()}
        {this.guestDiscussionViewBanner()}
        {this.renderTabVisibleBanner()}
      </div>
    );
  }
}

SpaceBanner = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(SpaceBanner);
export default SpaceBanner;
