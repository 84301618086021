import { UPDATE_DRAFT, CLEAR_DRAFT } from 'app/actions/types';
import { DRAFT_CONTEXTS } from 'app/constants';

export function updateCommentDraft({
  content,
  objects,
  files,
  parentId,
  commentableType,
  commentableId,
  mentionees,
  commentCategoryIds,
  type: commentType,
  editorType,
}) {
  return (dispatch, getState) => {
    const context = DRAFT_CONTEXTS.comment;
    const { graph = {} } = getState().currentUser;
    const { id: userId } = graph;

    if (!userId) return;

    dispatch({
      type: UPDATE_DRAFT,
      context,
      userId,
      parentId,
      commentableType,
      commentableId,
      content,
      objects,
      files,
      mentionees,
      commentCategoryIds,
      commentType,
      editorType,
    });
  };
}

export function updatePostDraft({
  content,
  parentId,
  listType,
  objects,
  files,
  postVisibility,
}) {
  return (dispatch, getState) => {
    const context = DRAFT_CONTEXTS.post;
    const { graph = {} } = getState().currentUser;
    const { id: userId } = graph;

    if (!userId) return;

    dispatch({
      type: UPDATE_DRAFT,
      context,
      userId,
      parentId,
      content,
      listType,
      objects,
      files,
      postVisibility,
    });
  };
}

export function updateMessageDraft({
  content,
  parentId,
  conversationId,
  objects,
  files,
}) {
  return (dispatch, getState) => {
    const context = DRAFT_CONTEXTS.message;
    const { graph = {} } = getState().currentUser;
    const { id: userId } = graph;

    if (!userId) return;

    dispatch({
      type: UPDATE_DRAFT,
      context,
      userId,
      parentId,
      conversationId,
      content,
      objects,
      files,
    });
  };
}

export function clearDraft(key) {
  return (dispatch) => {
    if (key) {
      dispatch({ type: CLEAR_DRAFT, key });
    }
  };
}
