import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Delete from 'app/components/Task/Buttons/Delete';
import DeleteConfirmationModal from 'app/components/Shared/DeleteConfirmationModal';
import Duplicate from 'app/components/Task/Buttons/Duplicate';
import Edit from 'app/components/Task/Buttons/Edit';
import Mark from 'app/components/Task/Buttons/Mark';
import ReassigneTo from 'app/components/Task/Buttons/ReassigneTo';
import Reschedule from 'app/components/Task/Buttons/Reschedule';
import Unmark from 'app/components/Task/Buttons/Unmark';
import { useState } from 'react';

function RenderTaskActionButton({
  task,
  deleteTask,
  markAsDone,
  markAsNotDone,
  listType,
  updateCache,
  changeDueDate,
  assigneeList,
  changeAssignee,
  refreshComponent,
  backendDataRequired,
  updateOverdueTasksOnEdit,
  isSliceRequired = true,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className='d-inline-block ms-auto a-link fs-12'>
      <CustomDropdown
        name='discussion_actions'
        className='comment-more position-relative'
        flipTheDropdown={260}>
        <Edit
          taskId={task.id}
          listType={listType}
          updateCache={updateCache}
          refreshComponent={refreshComponent}
          backendDataRequired={backendDataRequired}
          updateOverdueTasksOnEdit={updateOverdueTasksOnEdit}
        />
        <ReassigneTo
          taskId={task.id}
          assigneeList={assigneeList}
          changeAssignee={changeAssignee}
          isSliceRequired={isSliceRequired}
        />
        {task.parent_id === null && (
          <Reschedule
            taskId={task.id}
            date={task.due_date}
            changeDueDate={changeDueDate}
          />
        )}
        {!task.completed ? (
          <Mark taskId={task.id} markAsDone={markAsDone} />
        ) : (
          <Unmark taskId={task.id} markAsNotDone={markAsNotDone} />
        )}
        <Duplicate
          taskId={task.id}
          updateCache={updateCache}
          refreshComponent={refreshComponent}
          backendDataRequired={backendDataRequired}
        />
        <Delete
          taskId={task.id}
          deleteTask={deleteTask}
          setShowModal={setShowModal}
        />
      </CustomDropdown>

      {showModal && (
        <DeleteConfirmationModal
          title='Delete Task'
          entityType='Task'
          onClose={() => setShowModal(false)}
          deleteEntity={() =>
            deleteTask({
              hideModal: () => setShowModal(false),
              listType: listType,
            })
          }
        />
      )}
    </div>
  );
}

export default RenderTaskActionButton;
