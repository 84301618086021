import {
  SET_ACTIVE_RESOURCE,
  SET_ACTIVE_RESOURCE_PATIENT,
  SET_ACTIVE_RESOURCE_AFTER_SHARING,
} from './types';

export function setActiveResource(resource) {
  return {
    type: SET_ACTIVE_RESOURCE,
    resource,
  };
}

export function setActiveResourcePatient(patient) {
  return {
    type: SET_ACTIVE_RESOURCE_PATIENT,
    patient,
  };
}

export function setActiveResourceAfterSharing(resource) {
  return {
    type: SET_ACTIVE_RESOURCE_AFTER_SHARING,
    resource,
  };
}
