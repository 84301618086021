import {
  SET_HOVERED_APPLIANCE_LOG,
  UNSET_HOVERED_APPLIANCE_LOG,
} from './types';

export function setHoveredApplianceLog(obj) {
  return {
    type: SET_HOVERED_APPLIANCE_LOG,
    obj,
  };
}

export function unsetHoveredApplianceLog() {
  return {
    type: UNSET_HOVERED_APPLIANCE_LOG,
  };
}
