import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import Avatar from 'app/components/Shared/Avatar';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn/';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';

import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import {
  avatarAttributes,
  entityConversationUrl,
  entityUrl,
  getAuthorizeObject,
} from 'app/utils/entitiesHelper';
import {
  editorToggleColumnRequired,
  isCurrentUserOwnedSpace,
  isWorkspaceType,
} from 'app/utils/spaceHelper';
import {
  timeDifferenceFromNowInMilliseconds,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { translate } from 'app/actions/flashMessage';
import { isCurrentUser } from 'app/utils/userHelper';
import InviteMemberBtn from 'app/components/Shared/InviteMemberBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const CHANGE_SPACE_COLLABORATOR_TYPE = queryLoader(
  'app/graphql/mutations/ChangeSpaceCollaboratorType.gql',
);
const CHANGE_COLLABORATOR_ROLE = queryLoader(
  'app/graphql/mutations/Workspace/ChangeCollaboratorRole.gql',
);
const TRANSFER_OWNERSHIP = queryLoader(
  'app/graphql/mutations/Members/TransferOwnership.gql',
);
const DATE_TIME_FORMAT = 'ddd, DD MMM YYYY hh:mm A';

class SpaceMembersList extends Component {
  renderEmailInfo(request, mobileWidth) {
    const { first_name, last_name } = request,
      name = `${first_name || ''} ${last_name || ''}`.trim();
    let avatarClass =
        this.props.device.ipadPortrait || mobileWidth
          ? 'avatar-24'
          : 'avatar-40',
      email = request.email,
      emailObject = {
        avatarIcon: 'user',
        name: email,
        __typename: 'User',
      };

    return (
      <aside className='detail-block invite-block'>
        <div className='invite-block-group d-flex align-items-center'>
          <Avatar
            className={`avatar ${avatarClass}`}
            {...avatarAttributes(emailObject)}
          />
          <div className='sp-user-name text-truncate'>{name || email}</div>
          {mobileWidth && (
            <div className='invite-status'>{this.getStatus(request)}</div>
          )}
        </div>
      </aside>
    );
  }

  renderMemberInfo(request, mobileWidth) {
    let avatarClass =
        this.props.device.ipadPortrait || mobileWidth
          ? 'avatar-24'
          : 'avatar-40',
      member = request.collaborator,
      userMember = member?.__typename === 'User';

    return (
      <aside className='detail-block invite-block'>
        <div className='invite-block-group d-flex align-items-center'>
          <OsLink
            to={entityUrl(member, false, { skipDomainCheck: true })}
            name={member.name}
            className='mh-40'>
            <Avatar
              className={`avatar ${avatarClass}`}
              {...avatarAttributes(member, { entityImage: true })}
            />
          </OsLink>
          <div className='sp-user-name'>
            {userMember && (
              <UserQualificationInfo
                user={member}
                linkRequired={true}
                sliceUpto={2}
                titleRequired={true}
                className='span-helper text-truncate'
              />
            )}
            {!userMember && (
              <OsLink
                to={entityUrl(member, false, { skipDomainCheck: true })}
                text={member.name}
                className='mh-40 hover-underline white-link '
              />
            )}
          </div>
          {mobileWidth && (
            <div className='invite-status'>{this.getStatus(request)}</div>
          )}
        </div>
      </aside>
    );
  }

  getStatus(request) {
    if (request.status === 'pending') {
      if (this.isJoinSpaceRequest(request)) {
        return 'Request to join';
      } else {
        return 'Pending invitation';
      }
    } else if (this.isRequestAccepted(request)) {
      return 'Joined';
    }
  }

  getTimeGap(lastTime) {
    let timeDiff = timeDifferenceFromNowInMilliseconds(lastTime) / 60000;
    if (timeDiff <= 1) {
      return (
        <span>
          <OrthoIcon name='checkmark-dot' defaultClass='lt-green me-1' />
          Online
        </span>
      );
    } else {
      return timeWithFormat(lastTime, DATE_TIME_FORMAT);
    }
  }

  renderDateInfo(request, mobileWidth) {
    return (
      !mobileWidth && (
        <>
          <div className='detail-block status-block'>
            {this.getStatus(request)}
          </div>
          <div className='detail-block date-block'>
            {request.user?.last_active_at
              ? this.getTimeGap(request.user?.last_active_at)
              : 'Never'}
          </div>
        </>
      )
    );
  }

  openMessage = (user) => {
    let url = entityConversationUrl(user);
    this.props.navigate(url);
  };

  renderMessageLinkOrIcon(user) {
    if (!isCurrentUser(user) && !isCareWorkspaceView() && isWorkspaceView()) {
      let extraClass = this.props.device.mobileDevice
          ? 'px-8 no-text web-view-btn'
          : 'px-3',
        btnCommonProps = {
          associatedEntity: user,
          label: 'Message',
          extraClass: extraClass,
        };
      if (!this.props.device.mobileDevice) {
        btnCommonProps = {
          ...btnCommonProps,
          ...{ name: 'BtnSecondary', text: 'Message' },
        };
      } else {
        btnCommonProps = {
          ...btnCommonProps,
          ...{ name: 'BtnIcon', icon: 'messages' },
        };
      }
      return (
        <AuthorizeComponent
          onClick={this.openMessage.bind(this, user)}
          abilityObject={getAuthorizeObject('create', 'conversation')}>
          <OsBtn {...btnCommonProps} />
        </AuthorizeComponent>
      );
    }
  }

  isJoinSpaceRequest(request) {
    return request.user && request.invited_by_id === +request.user.id;
  }

  isRequestAccepted(request) {
    return request.status === 'accepted';
  }

  isRequestPending(request) {
    return request.status === 'pending';
  }

  transferOwnershipToAnotherUser = async (request, options) => {
    try {
      let variables = {
        fromUserId: request.user?.id,
        toUserId: options.transferOwnershipUser.id,
      };
      const response = await this.props.transferOwnership({ variables });
      return response.success;
    } catch (error) {
      return false;
    }
  };

  renderDeclineRequest(request, props) {
    let adminRequest = request.kind === 'admin',
      currentLoggedInUser =
        this.props.currentUser.graph.id === request.collaborator?.id;

    if (
      !currentLoggedInUser &&
      !adminRequest &&
      !request.collaborator?.is_support_user
    ) {
      return (
        <DeclineRequest
          {...props}
          onAction={(options) => {
            this.transferOwnershipToAnotherUser.bind(this, request, options)();
          }}
          headerTitle={`Disable access for ${request.user?.full_name}`}
          primaryButtonText='disable access'
          secondaryButtonText='cancel, keep access'
          svgIcon={'cannotEdit'}
          type={'Members'}
          confirmationRequired={true}
        />
      );
    }
  }

  editorToggleColumnRequired() {
    return editorToggleColumnRequired(this.props.space);
  }

  renderActionButtons(request) {
    let extraProps = {},
      btnCommonProps = {
        space: this.props.space,
        requestingEmail: request.email,
        requestingUser: request.user,
        onlyIconRequired: this.props.device.mobileDevice,
        isMembersTab: true,
      };
    if (this.props.device.mobileDevice) {
      extraProps.extraClass = 'px-8 no-text web-view-btn';
      extraProps.icon = 'delete';
    } else {
      extraProps.extraClass = ' px-16 no-icon ';
      extraProps.icon = '';
    }

    btnCommonProps.extraClass = extraProps.extraClass;

    if (this.isRequestAccepted(request)) {
      let props = { ...btnCommonProps, text: 'Remove Access', ...extraProps };
      return (
        <>
          {request.user && this.renderMessageLinkOrIcon(request.user)}
          {this.renderDeclineRequest(request, props)}
        </>
      );
    } else if (this.isJoinSpaceRequest(request)) {
      return (
        <>
          <AcceptRequest {...btnCommonProps} btnType='BtnSecondary' />
          {this.renderDeclineRequest(request, btnCommonProps)}
        </>
      );
    } else {
      let btnText = this.props.device.ipadPortrait ? 'cancel' : 'cancel invite';
      let props = { ...btnCommonProps, text: btnText, ...extraProps };
      return (
        <>
          {request.user &&
            !this.isRequestPending(request) &&
            this.renderMessageLinkOrIcon(request.user)}
          {this.renderDeclineRequest(request, props)}
        </>
      );
    }
  }

  renderActions(request) {
    return (
      <div className='detail-block action-block ps-2'>
        {this.renderActionButtons(request)}
      </div>
    );
  }

  renderMembersAndEmailInfo(request, mobileWidth) {
    return request.collaborator
      ? this.renderMemberInfo(request, mobileWidth)
      : this.renderEmailInfo(request, mobileWidth);
  }

  setkind = (e, request) => {
    let isEditor = e.target.checked,
      kind = isEditor ? 'editor' : 'member',
      variables = {
        newType: kind,
        spaceId: this.props.space.nice_id,
      };
    if (request.collaborator) {
      variables.collaboratorId = +request.collaborator.id;
      variables.collaboratorType = request.collaborator.__typename;
    } else {
      variables.email = request.email;
    }
    this.props.changeSpaceCollaboratorType({
      variables,
    });
  };

  setRole = (e, request) => {
    let isCareMember = e.target.checked,
      role = isCareMember ? 'care_member' : '',
      variables = {
        role,
        collaboratorId: +request.id,
      };
    this.props.changeCollaboratorRole({
      variables,
    });
  };

  isAuthorOrEditor() {
    return this.props.space.is_author_or_editor;
  }

  isCurrentUserRequest(request) {
    return request.user?.id === this.props.currentUser.graph.id;
  }

  renderPermission(request) {
    let isAnEditor = request.kind === 'editor',
      isAnAdmin = request.kind === 'admin',
      disabled =
        isAnAdmin ||
        this.isCurrentUserRequest(request) ||
        (!isCurrentUserOwnedSpace(this.props.space) &&
          !this.isAuthorOrEditor()),
      isSupportUser = request.user?.is_support_user;
    return (
      <div className={`detail-block editor-block ${isAnAdmin ? '' : ''}`}>
        {isAnAdmin && <span className='os-caf'>Owner</span>}
        {!isAnAdmin && isSupportUser && <span className='os-caf'>Support</span>}
        {!isAnAdmin && !isSupportUser && (
          <OsField
            osType='checkbox'
            onChange={(e) => this.setkind(e, request)}
            slider={true}
            dataHoverRequired={!isTouchSupported()}
            className='switch'
            isChecked={isAnEditor}
            disabled={disabled}
          />
        )}
      </div>
    );
  }

  renderCareMemberCheckbox = (request) => {
    const isChecked = request.role === 'care_member';
    return (
      <div className={`detail-block role-block`}>
        <OsField
          osType='checkbox'
          onChange={(e) => this.setRole(e, request)}
          slider={true}
          dataHoverRequired={!isTouchSupported()}
          className='switch'
          isChecked={isChecked}
          disabled={!this.isAuthorOrEditor()}
        />
      </div>
    );
  };

  renderRow = (request) => {
    let device = this.props.device,
      mobileWidth = device.mobileDevice || device.mobile;
    return (
      <article className='space-details-table-row' key={request.id}>
        {this.renderMembersAndEmailInfo(request, mobileWidth)}
        {this.renderDateInfo(request, mobileWidth)}
        {this.editorToggleColumnRequired() && this.renderPermission(request)}
        {isWorkspaceType(this.props.space) &&
          this.renderCareMemberCheckbox(request)}
        {this.renderActions(request)}
      </article>
    );
  };

  renderNoContent() {
    return (
      <div className='no-cases-block'>
        <div className='no-cases-image-block'>
          <Icon name='workspace' />
        </div>
        <div className='no-cases-message-block'>
          {this.props.searchText
            ? translate(
                'RESULTS_NOT_FOUND',
                {
                  searchInput: this.props.searchText,
                },
                true,
                { connector: '' },
              )
            : 'No members were added'}
        </div>
      </div>
    );
  }

  renderHeader() {
    let { device, space } = this.props,
      mobileWidth = device.mobileDevice || device.mobile;
    return (
      <article className='space-details-table-header'>
        <div className='detail-block invite-block'>Invited members</div>
        {!mobileWidth && (
          <>
            <div className='detail-block status-block'>Status</div>
            <div className='detail-block date-block'>Last Active At</div>
            {this.editorToggleColumnRequired() && (
              <div className='detail-block editor-block'>Editor</div>
            )}
            {isWorkspaceType(space) && (
              <div className='detail-block role-block'>
                Shared Notifications
              </div>
            )}
          </>
        )}
        {!isWorkspaceType(space) && <InviteMemberBtn space={space} />}
      </article>
    );
  }

  render() {
    let acceptedOrPendingRequests = this.props.results.filter((request) =>
      ['accepted', 'pending'].includes(request.status),
    );

    return (
      <div className='space-pending-table'>
        {this.renderHeader()}
        {acceptedOrPendingRequests.length === 0 && this.renderNoContent()}
        {acceptedOrPendingRequests.map(this.renderRow)}
      </div>
    );
  }
}
SpaceMembersList = withRouter(SpaceMembersList);
SpaceMembersList = graphql(CHANGE_SPACE_COLLABORATOR_TYPE, {
  name: 'changeSpaceCollaboratorType',
})(SpaceMembersList);
SpaceMembersList = graphql(TRANSFER_OWNERSHIP, {
  name: 'transferOwnership',
})(SpaceMembersList);
SpaceMembersList = graphql(CHANGE_COLLABORATOR_ROLE, {
  name: 'changeCollaboratorRole',
})(SpaceMembersList);
SpaceMembersList = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(SpaceMembersList);
export default SpaceMembersList;
