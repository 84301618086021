import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import {
  composeValidators,
  fieldRequired,
  passwordMustMatch,
  passwordMustMatchValidityRules,
} from 'app/utils/validationHelper';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { loader as queryLoader } from 'graphql.macro';
import { translate } from 'app/actions/flashMessage';

import ConnectToSupport from 'app/components/ConnectToSupport';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import Loader from 'react-loaders';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import PolicyAcceptCheckbox from 'app/components/PoliciesView/PolicyAcceptCheckbox';
import { openInfoModal } from 'app/actions/infoModal';

import { login } from 'app/actions/authentication';

const RESET_PASSWORD_MUTATION = queryLoader('app/graphql/ResetPassword.gql');
const RECOVERY_TOKEN_QUERY = queryLoader('app/graphql/RecoveryToken.gql');

class SignupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'credentials',
      showSignupModal: false,
      error: '',
      requestInProgress: false,
      isPolicyChecked: false,
      showEmailError: false,
    };
  }

  toggleEmailError = () => {
    this.setState({ showEmailError: true });
  };

  clearError = () => {
    this.setState({ error: null, emailError: null });
  };

  onSubmit = (values) => {
    this.setState({ requestInProgress: true });
    return this.props
      .resetPasswordMutation({
        variables: {
          password: values.password,
          reset_password_token: this.props.match.params.token,
        },
      })
      .then(({ data: { resetPasswordNew } }) => {
        this.setState({ requestInProgress: false });
        if (resetPasswordNew.errors) {
          let errors = {};
          errors[resetPasswordNew.errors[0].field] =
            resetPasswordNew.errors[0].message;
          return { ...errors };
        } else if (!resetPasswordNew.success) {
          this.props.openInfoModal('general', 'reset_password_send_failed', {
            onSuccess: this.redirectToLogin,
          });
        } else {
          let variables = {
            token: resetPasswordNew.token,
            remember: false,
            email: resetPasswordNew.entity.email,
            user: resetPasswordNew.entity,
          };
          this.props.login(variables);
        }
      });
  };

  actionAfterClosingModal() {
    this.props.navigate('/');
  }

  closeLoginModal() {
    this.props.authModal({ authModal: '' });
  }

  setPolicyCheckError = () => {
    if (this.state.isPolicyChecked) {
      this.setState({ showPolicyCheckboxError: false });
    } else {
      this.setState({ showPolicyCheckboxError: true });
    }
  };

  togglePoliciesAccepted = ({ target }) => {
    this.setState(
      { isPolicyChecked: target.checked },
      this.setPolicyCheckError,
    );
  };

  submitForm = (values) => {
    if (this.state.isPolicyChecked) {
      this.onSubmit(values);
    } else {
      this.setPolicyCheckError();
    }
  };

  closeModal = () => {
    this.closeLoginModal();
    this.props.authentication.options &&
      this.props.navigate(this.props.authentication.options.onCloseRedirectTo);
    this.setState({ step: 'credentials' });
    this.props.authentication.actionAfterClosingModal &&
      this.actionAfterClosingModal();
  };

  openLoginView = () => {
    if (this.props.device.mobileDevice) {
      this.props.navigate('/login');
    } else {
      this.props.authModal({ authModal: 'login' });
    }
  };

  redirectToLoginScreen() {
    return <Navigate to='/login' />;
  }

  renderCredentialsForm() {
    if (this.props.data.loading)
      return <Loader type='ball-triangle-path' active />;
    if (!this.props.data.resetPasswordToken)
      return this.redirectToLoginScreen();

    return (
      <Form
        onSubmit={this.submitForm}
        render={(props) => {
          return (
            <form name='signup' onSubmit={props.handleSubmit}>
              <div>
                <div className='text-center form-heading login-form-heading'>
                  Sign up
                </div>
                <div className='col-md-12' onClick={this.toggleEmailError}>
                  <Field
                    name='email'
                    type='email'
                    osType='input'
                    component={OsField}
                    label='Email*'
                    formGroupExtraClass='login-email-group has-disabled-input'
                    initialValue={this.props.data.resetPasswordToken.email}
                    disabled
                  />
                  {this.state.showEmailError && (
                    <div className='prefilled-email'>
                      <OrthoIcon
                        name='error'
                        defaultClass='me-1'
                        dataHoverNotRequired={true}
                      />
                      {translate(
                        'CARE_SPACE_PREFILLED_EMAIL_TEXT',
                        {
                          contact_team: (
                            <ConnectToSupport
                              text='contact clinic team'
                              extraClass='fs-12'
                            />
                          ),
                        },
                        false,
                      )}
                    </div>
                  )}
                </div>
                <div className='col-md-12'>
                  <Field
                    name='password'
                    component={OsField}
                    osType='password'
                    label='New password'
                    hintRequired={true}
                    onFocus={this.clearError}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatchValidityRules,
                    )}
                    formGroupExtraClass='login_password reset_login_password required-label'>
                    <span className='reset-password-hint'>
                      {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                    </span>
                  </Field>
                </div>
                <div className='col-md-12'>
                  <Field
                    name='password_validate'
                    component={OsField}
                    osType='password'
                    label='Confirm Your Password'
                    onFocus={this.clearError}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatch,
                    )}
                    formGroupExtraClass='login_password required-label'
                  />
                </div>
                <div className='col-md-12'>
                  <PolicyAcceptCheckbox
                    name='policy'
                    togglePoliciesAccepted={this.togglePoliciesAccepted}
                    validate={fieldRequired}>
                    {(this.state.showPolicyCheckboxError ||
                      (props.touched.policy && props.errors.policy)) && (
                      <div>
                        <span className='form-error'>
                          {' '}
                          <OrthoIcon
                            name='error'
                            dataHoverNotRequired='true'
                            defaultClass='ms-3 me-1 cl-red'
                          />{' '}
                          Must be checked
                        </span>
                      </div>
                    )}
                  </PolicyAcceptCheckbox>
                </div>
              </div>

              <div className='text-center'>
                <OsBtn
                  name='BtnPrimary'
                  htmlTag='button'
                  text='sign up'
                  extraClass='btn__referral_signup web-view-btn'
                  type='submit'
                  disabled={this.props.requestInProgress}
                  loaderRequired={this.props.requestInProgress}
                />
              </div>

              <div
                className={`text-center ${
                  this.props.device.mobileDevice
                    ? 'mobile-signup-footer'
                    : 'hide-modal-only mt-4 pt-3'
                }`}>
                <p className='mb-2 me-1 os-caf'>Already have an account?</p>
                <OsLink
                  text='Log In'
                  className='semibold'
                  onClick={this.openLoginView}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }

  renderWorkspaceWelcomeText() {
    let { guestOwnerData } = this.props.workspace;
    if (guestOwnerData) {
      return (
        <div className='ws-auth-info-area'>
          <div className='auth-heading'>Committed to Better Care, Together</div>
          <div
            className='patient-wel-text'
            dangerouslySetInnerHTML={{
              __html: translate('WELCOME_TEXT_CARESPACE_WITH_OWNER', {
                clinic_name: guestOwnerData.name,
              }),
            }}
          />
        </div>
      );
    } else {
      return translate('WELCOME_TEXT_CARESPACE');
    }
  }

  render() {
    if (!this.props.match.params.token) return <Navigate to='/login' />;

    return (
      <ImageFormLayout
        leftBar={this.renderWorkspaceWelcomeText()}
        rightBar={this.renderCredentialsForm()}
        source='care_signup'
      />
    );
  }
}

SignupView = connect(
  ({ currentUser, systemConfig, workspace, device }) => ({
    currentUser,
    systemConfig,
    workspace,
    device,
  }),
  { login, openInfoModal },
)(SignupView);
SignupView = compose(
  graphql(RECOVERY_TOKEN_QUERY, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        reset_password_token: props.match.params.token,
      },
    }),
  }),

  graphql(RESET_PASSWORD_MUTATION, { name: 'resetPasswordMutation' }),
)(SignupView);
SignupView = withRouter(SignupView);
export default SignupView;
