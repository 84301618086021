import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { translate } from 'app/actions/flashMessage.js';

export default class OsErrorField extends Component {
  getError() {
    let error = this.props.error || this.props.submitError;
    if (typeof error === 'string') {
      return error;
    } else if (error && typeof error === 'object') {
      return translate(error.errorKey, error.interpolations, false);
    }
  }

  render() {
    let { touched } = this.props,
      error = this.getError();

    return (
      <div className={touched && error ? 'with-error ' : ''}>
        {touched && error && (
          <div className='form-error'>
            <OrthoIcon
              name='error'
              dataHoverNotRequired='true'
              defaultClass='me-1 lt-red ms-3'
            />
            {error}
          </div>
        )}
      </div>
    );
  }
}
