import { TaskContext } from 'app/context/TaskContext';

import TaskListView from 'app/components/Task/TaskListView';
import React, { useContext } from 'react';

function UnifiedListView() {
  const {
    completedTasks,
    unscheduledTasks,
    overdueTasks,
    inProgressTasks,
    fetchNextCompletedRecords,
    fetchNextOverdueRecords,
    fetchNextInprogressRecords,
    fetchNextUnscheduledRecords,
  } = useContext(TaskContext);
  return (
    <div>
      <TaskListView
        idType={'User'}
        results={completedTasks?.records.results || []}
        listType={'CompletedTasks'}
        sectionTitle={`Done (${completedTasks?.records.total || 0})`}
        loadMore={fetchNextCompletedRecords}
      />
      <TaskListView
        idType={'User'}
        results={unscheduledTasks?.records.results || []}
        listType={'UnscheduledTasks'}
        sectionTitle={`Unscheduled (${unscheduledTasks?.records.total || 0})`}
        loadMore={fetchNextUnscheduledRecords}
      />
      <TaskListView
        idType={'User'}
        results={overdueTasks?.records.results || []}
        listType={'OverDueTasks'}
        sectionTitle={`Overdue (${overdueTasks?.records.total || 0})`}
        loadMore={fetchNextOverdueRecords}
      />
      <TaskListView
        idType={'User'}
        results={inProgressTasks?.records.results || []}
        listType={'InProgressTasks'}
        sectionTitle={`In Progress (${inProgressTasks?.records.total || 0})`}
        loadMore={fetchNextInprogressRecords}
      />
    </div>
  );
}

export default UnifiedListView;
