import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import { openAnalyticsModal } from 'app/actions/analyticsModal';

export default class AnalyticsButton extends PureComponent {
  render() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='insight'
        text='Analytics'
        extraClass='list-button'
        onClick={this.props.openAnalyticsModal}
      />
    );
  }
}

AnalyticsButton = connect(null, { openAnalyticsModal })(AnalyticsButton);
