class MixpanelResourceMapper {
  attributes(obj) {
    switch (obj.__typename) {
      case 'Case':
        return this.caseAttributes(obj);
      case 'User':
        return this.userAttributes(obj);
      case 'Tool':
        return this.toolAttributes(obj);
      case 'Board':
        return this.boardAttributes(obj);
      case 'Comparison':
        return this.comparisonAttributes(obj);
      case 'Pulse':
        return this.pulseAttributes(obj);
      case 'Company':
        return this.companyAttributes(obj);
      case 'Attachment':
        return this.attachmentAttributes(obj);
      default:
        return {};
    }
  }

  boardAttributes(obj) {
    return {
      space_name: obj.name,
      space_id: obj.nice_id,
      entity_id: obj.nice_id,
      entity_type: obj.type,
      ...(obj.user && { doctor_name: obj.user.name }),
      ...obj,
    };
  }

  toolAttributes(obj) {
    return {
      entity_id: obj.nice_id,
      entity_type: obj.__typename,
      tool_id: obj.nice_url,
      tool_name: obj.product_name,
      tool_brand: obj.company.name,
      ...obj,
    };
  }

  companyAttributes(obj) {
    return {
      company_name: obj.name,
      company_id: obj.nice_url,
      entity_id: obj.nice_id,
      entity_type: obj.__typename,
      ...obj,
    };
  }

  userAttributes(obj) {
    return {
      doctor_name: obj.name,
      doctor_id: obj.nice_url,
      entity_id: obj.nice_id,
      entity_type: obj.__typename,
      ...obj,
    };
  }

  caseAttributes(obj) {
    return {
      case_id: obj.nice_id,
      case_title: obj.title,
      entity_id: obj.nice_id,
      entity_type: obj.__typename,
      ...(obj.doctor && { doctor_name: obj.doctor.name }),
      ...(obj.doctor && { doctor_id: obj.doctor.nice_url }),
      ...obj,
    };
  }

  getCases(obj) {
    return obj.cases.length && obj.cases;
  }

  getDoctorsInfoForComparisonCases(obj, caseNumber) {
    return (
      obj.cases[caseNumber].doctor && {
        first_case_doctor_id: obj.cases[caseNumber].doctor.nice_url,
        first_case_doctor_name: obj.cases[caseNumber].doctor.name,
      }
    );
  }

  getCasesInfo(obj) {
    let cases = this.getCases(obj),
      firstCaseDoctor = this.getDoctorsInfoForComparisonCases(obj, 0),
      secondCaseDoctor = this.getDoctorsInfoForComparisonCases(obj, 1);
    return (
      cases && {
        first_case_id: obj.cases[0].nice_id,
        first_case_name: obj.cases[0].name,
        ...firstCaseDoctor,
        second_case_id: obj.cases[1].nice_id,
        second_case_name: obj.cases[1].name,
        ...secondCaseDoctor,
      }
    );
  }

  comparisonAttributes(obj) {
    let casesInfo = this.getCasesInfo(obj);
    return {
      entity_id: obj.nice_id,
      entity_type: obj.__typename,
      comparison_id: obj.nice_id,
      comparison_name: obj.name,
      ...casesInfo,
      ...obj,
    };
  }

  attachmentAttributes(obj) {
    return {
      attachment_name: obj.name,
      entity_id: obj.id,
      ...obj,
    };
  }

  pulseAttributes(obj) {
    return {
      entity_id: obj.nice_id,
      entity_type: 'Post',
      ...(obj.author && { author_id: obj.author.nice_url }),
      ...(obj.author && { author_name: obj.author.name }),
      ...obj,
    };
  }
}

export default new MixpanelResourceMapper();
