import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { FOLLOW_OBJECT } from './types';

import EventTracker from 'app/services/EventTracker';
const FOLLOW_BOARD_MUTATION = queryLoader('app/graphql/FollowBoard.gql');

const MUTATIONS = { Board: FOLLOW_BOARD_MUTATION };

// only supports space follow
export function followObject(attributes, options = {}) {
  return (dispatch) => {
    dispatch({ type: FOLLOW_OBJECT });
    let { obj } = attributes,
      variables = { followeeId: obj.id };

    return client
      .mutate({
        mutation: MUTATIONS[obj.__typename],
        variables,
      })
      .then(() => {
        EventTracker.trackForEntity('follow', obj);
        options.onSuccess && options.onSuccess();
      });
  };
}
