import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import { openPatientModal } from 'app/actions/patientModal';
import { canEditPersonInfo } from 'app/utils/spaces/careHelper';

class CareSpaceEditPatientBtn extends PureComponent {
  openPatientModal = () => {
    const space = this.getSpace();
    this.props.openPatientModal(space.nice_id, {
      source: this.props.source,
      guardiansNotRequired: this.props.guardiansNotRequired,
      space,
    });
  };

  getSpace() {
    return this.props.space;
  }

  render() {
    let canEditInfo = this.getSpace()?.is_author_or_editor || canEditPersonInfo(this.getSpace()?.owner.patient)
    if (
      canEditInfo &&
      !this.props.device.mobileDevice
    ) {
      return (
        <OsBtn
          name='BtnIcon'
          text='Edit information'
          icon='edit'
          onClick={this.openPatientModal}
        />
      );
    } else {
      return null;
    }
  }
}

CareSpaceEditPatientBtn.defaultProps = {
  guardianNotRequired: true,
};

CareSpaceEditPatientBtn = connect(({ device }) => ({ device }), {
  openPatientModal,
})(CareSpaceEditPatientBtn);

export default CareSpaceEditPatientBtn;
