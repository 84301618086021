import React, { Component } from 'react';

import OsLink from 'app/components/OsLink';

import { orderedDegrees } from 'app/utils/generalHelper';
import { entityUrl } from 'app/utils/entitiesHelper.js';
import { connect } from 'react-redux';

class UserQualificationInfo extends Component {
  getDegreeSeparator() {
    return this.props.degreeSeparator || ' ';
  }

  getOrderedDegreeList() {
    if (this.props.sliceUpto) {
      return orderedDegrees(this.props.user.degree_list)
        .slice(0, this.props.sliceUpto)
        .join(this.getDegreeSeparator());
    } else {
      return orderedDegrees(this.props.user.degree_list).join(
        this.getDegreeSeparator(),
      );
    }
  }

  getNameElement() {
    let user = this.props.user,
      name = this.props.titleRequired ? user.full_name : user.name,
      osLinkClass = this.props.osLinkClass || 'white-link';

    name =
      this.props.pronounRequired &&
      +this.props.currentUser.graph.id === +user.id
        ? 'You'
        : name;
    if (this.props.linkRequired) {
      return (
        <OsLink
          to={entityUrl(user)}
          className={osLinkClass}
          text={name}
          onParentClick={(e) => e.stopPropagation()}
        />
      );
    } else {
      return <span className='user-name'>{name}</span>;
    }
  }

  renderDegreeOrDesignation(user) {
    if (
      !this.props.degreeNotRequired &&
      user?.degree_list &&
      user.degree_list.length > 0
    ) {
      return (
        <span
          className={
            this.props.degreeListClassName || 'user-title degree-text'
          }>
          {!this.props.nameNotRequired && user.degree_list.length > 0
            ? ','
            : ''}{' '}
          {this.getOrderedDegreeList()}
        </span>
      );
    } else if (this.props.designationRequired && user.new_designation) {
      return (
        <span
          className={
            this.props.degreeListClassName || 'user-title degree-text'
          }>
          {user.new_designation}
        </span>
      );
    }
  }

  render() {
    let user = this.props.user;
    return (
      <span className={this.props.className || 'span-helper user-name-tag'}>
        {!this.props.nameNotRequired && this.getNameElement()}
        {this.renderDegreeOrDesignation(user)}
      </span>
    );
  }
}

export default UserQualificationInfo = connect(({ currentUser }) => ({
  currentUser,
}))(UserQualificationInfo);
