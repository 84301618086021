import {
  DETAIL_PAGE_NAVLIST_HEIGHT,
  PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
} from 'app/components/SimpleNavList/constants';

export const PADDING_FACTOR_BETWEEN_WIDGETS = 24;
export const SPACE_PAGE_STICKY_VALUES = {
  stickyTop: 60,
  marginHeaderNavValue:
    DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
    PADDING_OF_STICKY_CONTENT_FROM_NAVLIST +
    PADDING_FACTOR_BETWEEN_WIDGETS,
};
export const PAGE_WISE_STICKY_OFFSET_VALUES = {
  'account-settings': {
    stickyTop: 60,
    marginHeaderNavValue:
      DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
      PADDING_OF_STICKY_CONTENT_FROM_NAVLIST +
      PADDING_FACTOR_BETWEEN_WIDGETS,
  },
  default: SPACE_PAGE_STICKY_VALUES,
  explore: {
    stickyTop: PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
    marginHeaderNavValue:
      PADDING_OF_STICKY_CONTENT_FROM_NAVLIST + PADDING_FACTOR_BETWEEN_WIDGETS,
  },
  'pulse-detail': {
    stickyTop: PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
    marginHeaderNavValue:
      PADDING_OF_STICKY_CONTENT_FROM_NAVLIST + PADDING_FACTOR_BETWEEN_WIDGETS,
  },
  'user-detail': {
    stickyTop: 60,
    marginHeaderNavValue:
      DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
      PADDING_OF_STICKY_CONTENT_FROM_NAVLIST +
      PADDING_FACTOR_BETWEEN_WIDGETS,
  },
  'space-page-sticky-max': {
    stickyTop: 60,
    marginHeaderNavValue:
      DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
      PADDING_OF_STICKY_CONTENT_FROM_NAVLIST +
      PADDING_FACTOR_BETWEEN_WIDGETS,
  },
};
