import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import { loader as queryLoader } from 'graphql.macro';

import Avatar from 'app/components/Shared/Avatar';
import Follow from 'app/components/Shared/Follow';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

import { orderedDegrees } from 'app/utils/generalHelper';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';

const LIKES_QUERY = queryLoader('app/graphql/Likes.gql');

// Holder for the board edit form
class LikesView extends Component {
  listUsers() {
    return this.props.data.likes.map((user) => {
      let userUrl = entityUrl(user);
      return (
        <li className='user-listing-row' key={user.id}>
          <span className='user-image'>
            <Link to={userUrl} className='a-link'>
              <Avatar
                className='avatar avatar-medium avatar-36'
                {...avatarAttributes(user)}
              />
            </Link>
          </span>
          <span className='user-name'>
            <OsLink
              to={userUrl}
              className='white-link text-truncate'
              text={user.name}
            />
            <span className='degree-list'>
              {orderedDegrees(user.degree_list).join(' ')}
            </span>
          </span>
          {user.id !== this.props.currentUser.graph.id && (
            <div className='follow-unfollow-block'>
              <Follow
                type='user'
                obj={user}
                iconNotRequired={false}
                extraClass='iconbtn-require px-8'
              />
            </div>
          )}
        </li>
      );
    });
  }

  renderLikesHeader() {
    return this.props.totalLikes === 1
      ? '1 Like'
      : `${this.props.totalLikes} Likes`;
  }

  render() {
    if (this.props.data && this.props.data.loading) {
      return (
        <Loader
          type='ball-triangle-path'
          active
          className='xy-center like-loader'
        />
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        <div className='modal-header'>
          <h4 className='card-title'>
            <OrthoIcon name='heart-new' dataHoverNotRequired={true} />
            {this.renderLikesHeader()}
          </h4>
          <OrthoIcon
            name='close'
            dataHoverNotRequired={true}
            onClick={this.props.closeModal}
          />
        </div>
        <ul className='user-listing'>{this.listUsers()}</ul>
      </div>
    );
  }
}

LikesView = connect(({ currentUser }) => ({ currentUser }), {})(LikesView);
LikesView = compose(
  graphql(LIKES_QUERY, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        likeable_id: props.likeable_id,
        likeable_type: props.likeable_type,
      },
    }),
  }),
)(LikesView);

export default LikesView;
