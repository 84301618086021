import React, { Component } from 'react';
import Slider from 'react-slick';

import SampleNextArrow from 'app/components/Shared/SampleNextArrow.js';
import SamplePrevArrow from 'app/components/Shared/SamplePreviousArrow.js';
import SpinModel from 'app/components/SpinModel';

import { isCaseVideo } from './helpers';
import {
  isInViewport,
  addClassToElement,
  removeClassFromElement,
} from 'app/utils/domHelper';
import { parameterize } from 'app/utils/stringHelper';
import { first } from 'app/utils/osLodash';

export default class CaseAbstractSlider extends Component {
  focusSlider(sliderType) {
    if (!this.props.device.mobileDevice) {
      let slider = this.state.sliderRef[sliderType];
      if (slider && slider.innerSlider) {
        slider.innerSlider.list.setAttribute('tabindex', 0);
        slider.innerSlider.list.focus();
      }
    }
  }

  toggleSliderKey = () => {
    this.setState({ sliderKey: !this.state.sliderKey });
  };

  resizeSubSlider() {
    let { subSlider } = this.state.sliderRef;
    setTimeout(() => {
      subSlider &&
        subSlider.innerSlider &&
        subSlider.innerSlider.resizeWindow();
      this.focusSlider('subSlider');
    }, 200);
  }

  componentDidMount() {
    setTimeout(this.reassignCenterModeClasses, 200);
    this.focusSlider('mainSlider');
    setTimeout(() => {
      this.resizeSubSlider();
    }, 2000);
  }

  reassignCenterModeClasses = () => {
    let slickContainers = window.$('.case-thumbnail-slider .slick-slider'),
      visibleSlicks = window.$('.case-thumbnail-slider .slick-track');

    slickContainers.each((index, slickContainer) => {
      let visibleSlick = visibleSlicks[index];
      if (slickContainer && visibleSlick) {
        if (
          Math.ceil(slickContainer.getBoundingClientRect().width) >=
          visibleSlick.offsetWidth
        ) {
          addClassToElement('thumb-center', slickContainer);
        } else {
          removeClassFromElement('thumb-center', slickContainer);
        }
      }
    });
  };

  renderSpin(video) {
    let activeObject = this.currentFile(),
      visible = isCaseVideo(activeObject) && activeObject.id === video.id;

    return (
      <article className='position-relative' key={video.id}>
        <div
          className={`spin-video ${parameterize(
            first(video.video_type.split('/')),
          )}`}>
          <SpinModel
            key={`${video.video_type}-${video.id}`}
            {...this.getSpinProps(video)}
            visible={visible}
          />
        </div>
      </article>
    );
  }

  getSpinProps(video) {
    return {
      url: video.spin_url,
      metaInfo: video.spin_meta_info,
      size: 'large',
      currentIndex: this.props.spin.currentIndex,
      previousVideoType: this.props.spin.previousVideoType,
      videoData: video,
      retainIndex: this.props.spin.retainIndex,
      videoType: video.video_type,
      currentVideoType: this.currentFile() && this.currentFile().video_type,
      resetOnUnmount: false,
    };
  }

  renderSlide = (file) => {
    if (isCaseVideo(file)) {
      return this.renderSpin(file);
    } else {
      return (
        <div className='slide-img-holder' key={file.id}>
          <img className='case-image-full' src={file.photo} alt='' />
        </div>
      );
    }
  };

  renderSubSlide = (file) => {
    let imgUrl = isCaseVideo(file) ? file.thumb_url : file.photo_small;
    return (
      <img className='case-image-small' src={imgUrl} alt='' key={file.id} />
    );
  };

  renderSlides(slider) {
    let files = this.getSliderFiles();
    if (slider === 'mainSlider') {
      return files.map(this.renderSlide);
    } else {
      return files.map(this.renderSubSlide);
    }
  }

  isFullScreen() {
    return this.props.isFullScreen;
  }

  subSlidesToShow() {
    let files = this.getSliderFiles(),
      n = this.slider === 'case-appointment-slider' ? 3 : 2;

    return Math.min(files.length, n);
  }

  getSubSliderSettings() {
    let defaultSettings = this.getDefaultSettings(),
      customSettings = this.props.subNavigationSettings || {},
      className = 'thumb-slider ';
    className += this.isFullScreen() ? 'fullscreen-thumb-slider' : '';

    return {
      ...defaultSettings,
      ...customSettings,
      asNavFor: this.state.sliderRef.mainSlider,
      slidesToShow: this.subSlidesToShow(),
      variableWidth: true,
      focusOnSelect: true,
      centerMode: true,
      className: className,
      nextArrow: (
        <SampleNextArrow
          parentDivClass='image-selector-next'
          label={`${this.slider}-thumb-slider-next`}
        />
      ),
      prevArrow: (
        <SamplePrevArrow
          parentDivClass='image-selector-prev'
          label={`${this.slider}-thumb-slider-prev`}
        />
      ),
    };
  }

  getInitialSlideIndex() {
    return this.props.slideIndex || 0;
  }

  getDefaultSettings() {
    return {
      arrows: true,
      dots: false,
      draggable: true,
      initialSlide: this.getInitialSlideIndex(),
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      swipe: true,
    };
  }

  notifyParent = () => {
    this.props.notifyParentContainer(this.currentFile());
  };

  getMainSliderSettings() {
    let defaultSettings = this.getDefaultSettings(),
      customSettings = this.props.navigationSettings || {},
      isChrome = this.props.device.browserName === 'Chrome',
      isVideoActiveObject = isCaseVideo(this.currentFile()),
      className = 'main-slider ';
    className += isChrome ? 'chrome-pip-dropdown-fix-slider' : '';

    return {
      ...defaultSettings,
      ...customSettings,
      arrows: false,
      draggable: !isVideoActiveObject,
      swipeToSlide: !isVideoActiveObject,
      swipe: !isVideoActiveObject,
      asNavFor: this.state.sliderRef.subSlider,
      afterChange: (i) => {
        this.onSlideChange(i);
      },
      className: className,
      lazyLoad: 'ondemand',
      useTransform: !isChrome,
      onInit: this.notifyParent,
      fade: true,
    };
  }

  setRef(slider, refName) {
    if (slider && this.state.sliderRef[refName] !== slider) {
      let sliderRef = this.state.sliderRef;
      sliderRef[refName] = slider;
      this.setState({ sliderRef });
    }
  }

  renderSlider(sliderName) {
    let settings =
      sliderName === 'mainSlider'
        ? this.getMainSliderSettings()
        : this.getSubSliderSettings();

    if (this.getSliderFiles().length) {
      return (
        <Slider
          ref={(slider) => this.setRef(slider, sliderName)}
          {...settings}
          key={`${this.slider}-${this.state.sliderKey}`}>
          {this.renderSlides(sliderName)}
        </Slider>
      );
    }
  }
}
