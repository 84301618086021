export const TEAM_NAV_TABS = {
  teamSpaceDirectMessenger: [
    {
      name: 'Message',
      path: '',
      content_type: 'message',
    },
    {
      name: 'Shared Files',
      path: 'shared_files',
      content_type: 'AttachmentsInDiscussion',
    },
  ],
  teamSubSpaceMessenger: [
    {
      name: 'Message',
      path: '',
      content_type: 'message',
    },
    {
      name: 'Tip Of The Day',
      path: 'tip_of_the_day',
      content_type: 'TipOfTheDay',
    },
    {
      name: 'Shared Files',
      path: 'shared_files',
      content_type: 'AttachmentsInDiscussion',
    },
  ],
};
