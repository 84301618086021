import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { Modal } from 'react-bootstrap';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper.js';
import * as compose from 'lodash.flowright';

import EmailVerification from './EmailVerification';
import OsBtn from 'app/components/OsBtn';
import PasswordUpdate from './PasswordUpdate';
import ProfileInfo from './ProfileInfo';
import Security from './Security';
import UserSpecialityInfo from './UserSpecialityInfo';
import VerificationProcessNotionLink from 'app/components/Shared/VerificationProcessNotionLink';

import { openInfoModal } from 'app/actions/infoModal';
import { translate } from 'app/actions/flashMessage';
import {
  updateCurrentUserToken,
  updateCurrentUserGraph,
} from 'app/actions/authentication';
import {
  closeOnboardingVerificationModals,
  resetOnboardingModalWithScreen,
} from 'app/actions/onboardingTips';

const UPDATE_USER_PROFILE_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateUserProfile.gql',
);
const SET_PASSWORD_MUTATION = queryLoader(
  'app/graphql/mutations/SetPassword.gql',
);

class OnboardingModals extends Component {
  state = this.defaultState();

  defaultState() {
    return {
      step: this.getDefaultStep(),
      selectedRole: this.props.currentUser.graph.current_role,
      disableCTA: false,
      otpSent: false,
    };
  }

  getDefaultStep() {
    return this.props.currentUser.graph.next_verification_step;
  }

  componentDidMount() {
    if (this.props.onboardingTips.openVerificationModals) {
      this.getDefaultStep()
        ? addClassToHTML('user_account_modal')
        : this.closeModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.onboardingTips.openVerificationModals &&
      nextProps.onboardingTips.openVerificationModals
    ) {
      addClassToHTML('user_account_modal');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentUser.graph.next_verification_step !==
      this.props.currentUser.graph.next_verification_step
    ) {
      this.setState({ step: this.getDefaultStep() });
    }
    const { screen } = this.props.onboardingTips;
    if (screen && screen !== prevProps.onboardingTips.screen) {
      this.setState({ step: screen });
    }

    if (
      this.props.onboardingTips.openVerificationModals &&
      !this.props.currentUser.graph.next_verification_step &&
      !screen
    ) {
      this.closeModal();
    }
  }

  closeModal = (force=false) => {
    if(!force && this.props.onboardingTips.hideCloseOptions) return
    this.props.closeOnboardingVerificationModals();
    removeClassFromHTML('user_account_modal');
    this.setState(this.defaultState());
  };

  otpSent = () => {
    this.setState({ otpSent: true });
  };

  onOtpVerificationSuccess = (data) => {
    this.setState({ otpSent: false });
    this.onSuccessAction(data);
  };

  renderSecurityScreen() {
    return (
      <Security
        otpSent={this.otpSent}
        closeModal={this.closeModal}
        onSuccess={this.onOtpVerificationSuccess}
      />
    );
  }

  setRole = (selectedRole) => {
    this.setState({ selectedRole });
  };

  updateUserProfile(variables) {
    this.props
      .updateUserProfileMutation({
        variables: variables,
      })
      .then(({ data }) => {
        if (data.updateUserProfile.success) {
          this.setState({ disableCTA: false });
          this.onSuccessAction(data.updateUserProfile);
        }
      });
  }

  onSuccessAction = (response) => {
    this.props.resetOnboardingModalWithScreen();
    this.props.updateCurrentUserGraph(response.entity);
    this.setState({ step: response.entity.next_verification_step }, () => {
      if (!this.state.step) {
        if (!response.entity.account_verified)
          this.props.openInfoModal('general', 'profile_info_submitted', {
            contentInterpolations: {
              verification_process_link: <VerificationProcessNotionLink />,
            },
          });
        this.closeModal();
      }
    });
  };

  setPassword(variables) {
    this.props
      .setPasswordMutation({
        variables: variables,
      })
      .then(({ data }) => {
        this.setState({ disableCTA: false });
        this.closeModal(true)
        this.onSuccessAction(data.setPassword);
        if (data.setPassword.token) {
          this.props.updateCurrentUserToken(data.setPassword.token);
        }
      });
  }

  onSubmit = (variables) => {
    this.setState({ disableCTA: true });
    if (this.state.step === 'password_update') {
      this.setPassword(variables);
    } else {
      this.updateUserProfile(variables);
    }
  };

  renderProfileInfo() {
    let initialValues = {
      role: this.props.currentUser.graph.current_role,
      avatar: this.props.currentUser.graph.large_avatar,
      avatar_w: 0,
      avatar_h: 0,
      avatar_x: 0,
      avatar_y: 0,
    };
    return (
      <ProfileInfo
        goBack={this.goBack}
        setRole={this.setRole}
        onSubmit={this.onSubmit}
        disableCTA={this.state.disableCTA}
        initialValues={initialValues}
      />
    );
  }

  getPreviousStep() {
    switch (this.state.step) {
      case 'profile_info':
        return 'mobile_verification';
      case 'speciality_info':
        return 'profile_info';
      case 'password_update':
        return 'speciality_info';
      default:
        return this.props.currentUser.graph.next_verification_step;
    }
  }

  saveSelectedSubCategories = (selectedSubCategories) => {
    this.setState({ selectedSubCategories });
  };

  renderUserSpecialityInfo() {
    return (
      <UserSpecialityInfo
        selectedRole={this.state.selectedRole}
        goBack={this.goBack}
        onSubmit={this.onSubmit}
        disableCTA={this.state.disableCTA}
        saveSelectedSubCategories={this.saveSelectedSubCategories}
        selectedSubCategories={this.state.selectedSubCategories}
      />
    );
  }

  renderPasswordUpdate() {
    return (
      <PasswordUpdate
        goBack={this.goBack}
        disableCTA={this.state.disableCTA}
        onSubmit={this.onSubmit}
      />
    );
  }

  renderEmailVerificationScreen() {
    return <EmailVerification closeModal={this.closeModal} />;
  }

  goBack = () => {
    this.setState({ step: this.getPreviousStep() });
  };

  renderContent() {
    switch (this.state.step) {
      case 'email_verification':
        return this.renderEmailVerificationScreen();
      case 'mobile_verification':
        return this.renderSecurityScreen();
      case 'profile_info':
        return this.renderProfileInfo();
      case 'speciality_info':
        return this.renderUserSpecialityInfo();
      case 'password_update':
        return this.renderPasswordUpdate();
      default:
        return null;
    }
  }

  getTitle() {
    switch (this.state.step) {
      case 'email_verification':
        return 'USER_ONBOARDING_EMAIL_VERIFICATION';
      case 'mobile_verification':
        return this.state.otpSent
          ? 'USER_ONBOARDING_SECURE_YOUR_ACCOUNT_HEADING'
          : 'USER_ONBOARDING_TWO_STEP_VERIFICATION_HEADING';
      case 'profile_info':
        return 'USER_ONBOARDING_PROFILE_INFORMATION';
      case 'speciality_info':
        return 'USER_ONBOARDING_PROFILE_SPECIALITY_INFORMATION';
      case 'password_update':
        return 'USER_ONBOARDING_UPDATE_PASSWORD_HEADING';
      default:
        return '';
    }
  }

  getModalHeader() {
    let className = 'no-text os-header-btn web-view-btn close-general-modal  p-0 '
    className += this.props.onboardingTips.hideCloseOptions ? 'd-none' : ''
    return (
      <>
        <span className='modal-title form-heading'>
          {translate(this.getTitle())}
        </span>
        <OsBtn
          name='BtnIcon'
          extraClass={className}
          icon='close'
          label='Close modal'
          onClick={this.closeModal}
        />
      </>
    );
  }

  render() {
    let modalClass = 'modal-general ';
    modalClass += this.state.step;
    let show = this.props.onboardingTips.openVerificationModals;

    return (
      <Modal
        show={show}
        onHide={this.closeModal}
        className={modalClass}
        animation={false}
        backdropClassName='modal-backdrop-custom modal-update-backdrop'>
        <Modal.Header>{this.getModalHeader()}</Modal.Header>
        <Modal.Body>{this.renderContent()}</Modal.Body>
      </Modal>
    );
  }
}

OnboardingModals = compose(
  graphql(UPDATE_USER_PROFILE_MUTATION, { name: 'updateUserProfileMutation' }),
  graphql(SET_PASSWORD_MUTATION, { name: 'setPasswordMutation' }),
)(OnboardingModals);

OnboardingModals = withApollo(OnboardingModals);

OnboardingModals = connect(
  ({ currentUser, device, onboardingTips }) => ({
    currentUser,
    device,
    onboardingTips,
  }),
  {
    closeOnboardingVerificationModals,
    resetOnboardingModalWithScreen,
    openInfoModal,
    updateCurrentUserToken,
    updateCurrentUserGraph,
  },
)(OnboardingModals);

export default OnboardingModals;
