import React from 'react';

import { secondsToTimeConverter, timeWithFormat } from 'app/utils/timeHelper';
import { APPOINTMENT_DATE_FORMAT } from 'app/components/CaseLeftSection/constants';

export default function (props) {
  const startMonth = props.start_age.years * 12 + props.start_age.months;
  const endMonth = props.end_age.years * 12 + props.end_age.months;
  var calcMargin =
    ((startMonth - props.timelineStartMonth) / props.timelineDuration) * 100;
  var calcWidth = ((endMonth - startMonth) / props.timelineDuration) * 100;

  if (calcMargin < 0) {
    calcWidth = calcWidth + calcMargin;
    calcMargin = 0;
  } else if (calcMargin > 100) {
    calcMargin = 100;
  }

  if (calcWidth < 0) {
    calcWidth = 0;
  } else if (calcWidth + calcMargin > 100) {
    calcWidth = 100 - calcMargin;
  }

  const marginLeft = `${calcMargin}%`;
  const width = `${calcWidth}%`;
  const isHovered = +props.status === +props.id;
  let durationLabel = null;
  if (props.end_date && props.start_date) {
    var duration = String(
        secondsToTimeConverter(props.end_date * 1000 || 0)
          .diff(secondsToTimeConverter(props.start_date * 1000 || 0), 'months')
          .toFixed(),
      ),
      dates = `(${timeWithFormat(
        props.start_date,
        APPOINTMENT_DATE_FORMAT,
      )}-${timeWithFormat(props.end_date, APPOINTMENT_DATE_FORMAT)})`;
    durationLabel =
      duration <= 1 ? `${duration} month ` : `${duration} months `;
  }

  return (
    <div
      onMouseEnter={props.hoverOn}
      onMouseLeave={props.hoverOff}
      className={`occurance ${props.className || ''} ${
        isHovered ? 'hovered' : ''
      }`}
      style={{ marginLeft, width }}>
      <div className='occurance-line'>
        <div className='case-treatment-tooltip without-label'>
          {isHovered && (
            <span className='case-treatment-tooltip-content'>
              <span>{durationLabel}</span>
              <span>{dates}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
