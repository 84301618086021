export const PAGE_TITLE_MAP = {
  folders: 'Cloudberry&ensp;&ndash;&ensp;Folders%{filter}',
  my_uploaded_files: 'Cloudberry&ensp;&ndash;&ensp;%{uploaded-files}',
  care_feed:
    'Cloudberry&ensp;&ndash;&ensp;Patient&ensp;Directory&ensp;&ndash;&ensp;%{filter}',
  team_spaces: 'Cloudberry&ensp;&ndash;&ensp;Team&ensp;Spaces%{filter}',
  launch: 'Cloudberry&ensp;&ndash;&ensp;Launch',
  partners: 'Cloudberry&ensp;&ndash;&ensp;Partners',
  team: 'Cloudberry&ensp;&ndash;&ensp;Team',
  direct_message:
    'Cloudberry&ensp;&ndash;&ensp;DM&ensp;&ndash;&ensp;%{username}',
  patient_carespace: 'Cloudberry&ensp;&ndash;&ensp;%{patient-name}',
  patient_carespace_tab: 'Cloudberry&ensp;&ndash;&ensp;%{filter}',
  explore: 'Cloudberry&ensp;&ndash;&ensp;Patient Activity',
  new_direct_message: 'Cloudberry&ensp;&ndash;&ensp;Direct&ensp;Message',
  account_setting:
    'Cloudberry&ensp;&ndash;&ensp;Account&ensp;Setting&ensp;&ndash;&ensp;%{filter}',
  member_view: 'Cloudberry&ensp;&ndash;&ensp;Member&ensp;View',
  tasks: 'Cloudberry&ensp;&ndash;&ensp;%{pageTitle}',
  my_profile: 'Cloudberry&ensp;&ndash;&ensp;My&ensp;Profile',
  worksapce_settings:
    'Cloudberry&ensp;&ndash;&ensp;Workspace&ensp;Settings&ensp;&ndash;&ensp;%{filter}',
  carespace_configuration:
    'Cloudberry&ensp;&ndash;&ensp;Carespace&ensp;Configuration',
  group_messenger:
    'Cloudberry&ensp;&ndash;&ensp;Group&ensp;&ndash;&ensp;%{group-name}',
  profile_switcher:
    'Cloudberry&ensp;&ndash;&ensp;Profile&ensp;Switcher&ensp;&ndash;&ensp;%{filter}',
};
