import React, { useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import OsBtn from 'app/components/OsBtn';

import {
  closeInfoModal,
  openInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';

import { CLINIC_SHARE_REQUEST } from './constant';

import { getWorkspaceID } from 'app/selectors/workspace';

const SHARE_WITH_PARTNER_SPACE_MEMBER_MUTATION = queryLoader(
  'app/graphql/mutations/PartnerSpaces/SharePartnerSpaceWithMembers.gql',
);
const ShareWithClinicButton = ({ partnerSpace }) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const dispatch = useDispatch();
  const currentWorkspaceId = useSelector(getWorkspaceID);
  const [sharePartnerSpaceWithMembers] = useMutation(
    SHARE_WITH_PARTNER_SPACE_MEMBER_MUTATION,
  );
  const isAuthorizedToShare = () => {
    return (
      partnerSpace.clinic_share_request.initiated_by_workspace_id ===
        currentWorkspaceId && !partnerSpace.unlocked_by_any_member
    );
  };

  const shareWithClinicMembers = (data) => {
    const ids = Object.keys(data).map(
      (requestId) => +requestId.replace(`${CLINIC_SHARE_REQUEST}:`, ''),
    );
    setRequestInProgress(true);
    dispatch(
      updateInfoModalOptions({
        primaryCtaRequestInProgress: true,
      }),
    );
    sharePartnerSpaceWithMembers({
      variables: {
        partnerSpaceId: partnerSpace.nice_id,
        shareWithMemberIds: ids,
      },
    }).then(({ data }) => {
      setRequestInProgress(false);
      dispatch(closeInfoModal());
      dispatch(
        openInfoModal('partner_space', 'shared_with_member_successfully'),
      );
    });
  };

  const openConfirmationModal = () => {
    dispatch(
      openInfoModal(
        'partner_space',
        'shared_with_partner_confirmation_with_list',
        {
          onSuccess: shareWithClinicMembers,
          partnerShareRequests: partnerSpace.clinic_share_requests,
          closeModalNotRequiredOnPrimaryClick: true,
        },
      ),
    );
  };

  if (isAuthorizedToShare()) {
    return (
      <OsBtn
        name='BtnPrimary'
        extraClass='share-wth-patient-btn'
        text='Reshare with Clinic'
        onClick={openConfirmationModal}
      />
    );
  } else {
    return null;
  }
};
export default ShareWithClinicButton;
