import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Avatar from 'app/components/Shared/Avatar';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';

import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import { getCareAvatarIconProps } from 'app/utils/spaces/careHelper';

class EmojiUsers extends Component {
  takeToUserProfile = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.navigate(entityUrl(user));
  };

  getAvatarIconProps(data) {
    const { source } = this.props;
    if (this.props.parentEntity) {
      return getCareAvatarIconProps(this.props.parentEntity, data.user, {
        bypassColor: true,
      });
    } else if (source === 'feed' || source === 'messenger') {
      return { emojiIcon: data.identifier };
    } else {
      return {};
    }
  }

  renderUser = (data) => {
    let avatarOptions = this.getAvatarIconProps(data);
    return (
      <li className='emoji-list-name' key={data.user.id}>
        <Avatar
          className={`avatar ${this.props.avatarClass}`}
          {...avatarAttributes(data.user, avatarOptions)}
          asLink={false}
        />
        <UserQualificationInfo
          user={data.user}
          titleRequired={true}
          degreeNotRequired={true}
          pronounRequired={true}
          linkRequired={false}
        />
      </li>
    );
  };

  renderUsers() {
    return this.props.users.map(this.renderUser);
  }

  renderHeadingIfRequired() {
    if (this.props.heading)
      return (
        <li key='heading' className='see-all-heading'>
          {this.props.heading}
        </li>
      );
  }

  renderSeeAllIfRequired() {
    if (this.props.seeAllRequired)
      return (
        <li key='see-all' className='see-all' onClick={this.props.onSeeAll}>
          See All
        </li>
      );
  }

  render() {
    let className = this.props.users.length > 0 ? ' multiple' : '';
    return (
      <div className={`${className} ${this.props.userlistClass}`}>
        <ul className='list-unstyled m-0'>
          <div className='listing-items-box'>
            {this.renderHeadingIfRequired()}
            {this.renderUsers()}
            {this.renderSeeAllIfRequired()}
          </div>
        </ul>
      </div>
    );
  }
}

EmojiUsers.defaultProps = {
  userlistClass: 'emoji-add-list',
  avatarClass: 'avatar-32',
  users: [],
};

EmojiUsers = withRouter(EmojiUsers);

export default EmojiUsers;
