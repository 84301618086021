import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import LoginOrLogoutLink from 'app/components/Shared/LoginOrLogoutLink';
import SiteLogo from 'app/components/Shared/SiteLogo';
import WorkspaceNavLinks from 'app/components/WorkspaceView/NavLinks';
import { getWorkspaceNavTabs } from 'app/utils/Workspace/navHelper';

export default class GuestNavBar extends PureComponent {
  renderNavLinks() {
    const { user, workspace } = this.props,
      identifier = workspace?.identifier;
    if (identifier) {
      if (workspace) {
        const { rootTab, navTabs } = getWorkspaceNavTabs(workspace, user);
        return (
          <WorkspaceNavLinks
            rootTab={rootTab}
            navTabs={navTabs}
            workspace={workspace}
            guestUser={true}
          />
        );
      }
    }
  }

  render() {
    let isWorkspace = this.props.workspace?.identifier;

    return (
      <nav className='navbar navbar-expand-lg navbar-unauth navbar-expand-xl os-main-nav'>
        <div className='os-main-nav-inner'>
          {(!isWorkspace || this.props.device.mobileDevice) && <SiteLogo />}
          {isWorkspace &&
            !this.props.device.mobileDevice &&
            this.renderNavLinks()}
          <LoginOrLogoutLink />
        </div>
      </nav>
    );
  }
}

GuestNavBar = connect(({ device }) => ({ device }))(GuestNavBar);
