import {
  OPEN_SUBSCRIPTION_MODAL,
  CLOSE_SUBSCRIPTION_MODAL,
} from 'app/actions/types';

export default function subscriptionModal(
  state = {
    open: false,
    step: 'plans',
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_SUBSCRIPTION_MODAL:
      return { open: true, step: action.step, options: action.options };
    case CLOSE_SUBSCRIPTION_MODAL:
      return { ...state, open: false };
    default:
      return state;
  }
}
