import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';

import RecordsView from 'app/components/RecordsView';

import { openInfoModal } from 'app/actions/infoModal';
import {
  setCLVMovementDirection,
  setLectureEntity,
} from 'app/actions/lectureView';

import {
  contentAccessibleTabs,
  getLecturePreviewTabs,
} from 'app/components/NavTabs/helper';
import { isLecturesAvailable } from 'app/utils/spaceHelper';
import { translate } from 'app/actions/flashMessage';
import { findIndex, values, sum } from 'app/utils/osLodash';

class LectureViewHeaderListing extends Component {
  state = {
    activeKey: (this.getTabs() && (this.getTabs()[0] || {}).id) || '',
    tabContentCount: {},
  };

  componentDidMount() {
    document.addEventListener('keydown', this.scrollResult);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.scrollResult);
  }

  getTabs() {
    return getLecturePreviewTabs(this.getSpace());
  }

  getSpace() {
    return this.props.space;
  }

  guestUser() {
    return !this.props.currentUser.graph;
  }

  linksOptions(tab) {
    const space = this.getSpace();
    return {
      type: 'TabSections',
      idQuery: tab.id,
      space: space,
      queryType: this.guestUser() ? 'GUEST_LISTING' : 'TAB_DETAILS',
    };
  }

  scrollResult = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.props.setCLVMovementDirection('down');
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.props.setCLVMovementDirection('up');
    }
  };

  isFilterValid(tabPath) {
    return contentAccessibleTabs(this.getSpace(), 'lecture_preview_tabs')
      .map((tab) => tab.path)
      .includes(tabPath);
  }

  isAuthor() {
    return (
      this.props.currentUser.graph &&
      +this.getSpace().user.id === +this.props.currentUser.graph.id
    );
  }

  setCollectionTotal = (tabId, count) => {
    let { tabContentCount } = this.state;
    tabContentCount[tabId] = count;
    this.setState({ tabContentCount }, () => {
      this.props.setCollectionTotal(sum(values(this.state.tabContentCount)));
    });
  };

  openNextTabFirstContent = () => {
    let tabs = this.getTabs(),
      tabIndex = findIndex(
        tabs,
        (tab) => +tab.id === +this.props.lectureView.activeContentTabId,
      ),
      nextTab = tabs[tabIndex + 1];

    if (nextTab) {
      this.setState(
        { openFirstContentTabId: nextTab.id },
        () => {
          this.setState({ openFirstContentTabId: null });
        },
        100,
      );
    }
  };

  openPreviousTabLastContent = () => {
    let tabs = this.getTabs(),
      tabIndex = findIndex(
        tabs,
        (tab) => +tab.id === +this.props.lectureView.activeContentTabId,
      ),
      previous = tabs[tabIndex - 1];

    if (previous) {
      this.setState(
        { openLastContentTabId: previous.id },
        () => {
          this.setState({ openLastContentTabId: null });
        },
        100,
      );
    }
  };

  renderTabContent(tab, index) {
    if (this.isFilterValid(tab.path)) {
      return (
        <RecordsView
          {...this.linksOptions(tab)}
          trackScrolling={true}
          scrollIfNeeded={this.props.scrollIfNeeded}
          clvHeaderView={true}
          setCollectionTotal={this.setCollectionTotal.bind(this, tab.id)}
          tab={tab}
          loaderNotRequired={true}
          nonLodedEmptyCardsRequired={true}
          scrollElementContainerId='clv-right-section-container'
          additional_filters={{ member_view: true }}
          collectionTotal={this.state.tabContentCount[tab.id]}
          openNextSectionOrTab={this.openNextTabFirstContent}
          openPreviousSectionOrTab={this.openPreviousTabLastContent}
          openFirstContentTabId={this.state.openFirstContentTabId}
          openLastContentTabId={this.state.openLastContentTabId}
        />
      );
    } else {
      return (
        <div className='content-not-accessible'>
          {translate('JOIN_SPACE_TO_ACCESS_CONTENT')}
        </div>
      );
    }
  }

  renderLecturePreviewTab = (tab, index) => {
    let extraClass =
      !this.isFilterValid(tab.path) || this.state.tabContentCount[tab.id]
        ? ''
        : 'd-none';
    return (
      <div key={tab.id} className={`new-clv-card ${extraClass}`}>
        {this.props.showTabName && <h2>{tab.name}</h2>}
        <Card>
          <Card.Body>{this.renderTabContent(tab, index)}</Card.Body>
        </Card>
      </div>
    );
  };

  render() {
    let space = this.getSpace();
    return (
      <div className='accordion-listing' id='clv-right-section-container'>
        {isLecturesAvailable(space) &&
          this.getTabs().map(this.renderLecturePreviewTab)}
      </div>
    );
  }
}

LectureViewHeaderListing.defaultProps = {
  showTabName: true,
  setCollectionTotal: () => {},
};
LectureViewHeaderListing = connect(
  ({ currentUser, lectureView }) => ({ currentUser, lectureView }),
  { openInfoModal, setCLVMovementDirection, setLectureEntity },
)(LectureViewHeaderListing);
LectureViewHeaderListing = withSpaceRestriction(LectureViewHeaderListing);
LectureViewHeaderListing = withAuthenticate(LectureViewHeaderListing);

export default LectureViewHeaderListing;
