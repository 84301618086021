import React from 'react';

import { addProtocolIfNotPresent, isURLSecured } from 'app/utils/urlHelper';

function getPodcastURL(url) {
  url = addProtocolIfNotPresent(url);
  const pattern = isURLSecured(url) ? 'https://' : 'http://';
  return [pattern, 'embed.', ...url.split(pattern)].join('');
}

const PodcastPlayer = (props) => {
  return (
    <iframe
      className='podcast-frame'
      title={props.title}
      allow='autoplay *; encrypted-media *; fullscreen *'
      frameborder='0'
      height='175'
      style={{ width: '100%', overflow: 'hidden', background: 'transparent' }}
      sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
      src={getPodcastURL(props.url)}></iframe>
  );
};

export default PodcastPlayer;
