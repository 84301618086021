import React, { Component } from 'react';
import { connect } from 'react-redux';

import CommentSection from 'app/components/CommentSection';
import ContentWithSidebars from 'app/components/ContentWithSidebars';

import { SHARED_SPACE_AUTHENTICATED } from 'app/components/BoardView/constants';
import {
  getSpaceTabSource,
  isCareSpace,
  isSpaceArchived,
} from 'app/utils/spaceHelper';
import { clone } from 'app/utils/osLodash';
import { currentTimeWithUserTimeZoneInSeconds } from 'app/utils/timeHelper';

class SpaceDiscussionsView extends Component {
  state = {};
  onCommentableLoad = (commentable) => {
    this.setState({
      commentable: { ...commentable, ...this.props.data.space },
    });
  };

  readOnly() {
    return (
      isSpaceArchived(this.props.data.space) ||
      this.props.guestUser.action === SHARED_SPACE_AUTHENTICATED
    );
  }

  getSourceOwnerObject() {
    let { space } = this.props.data;

    if (isCareSpace(space)) {
      let owner = clone(space.owner),
        { commentable } = this.state;

      if (commentable) {
        owner.patient.last_shared_on_date =
          commentable.owner.patient.last_shared_on_date;
        owner.patient.last_request = commentable.owner.patient.last_request;
        owner.patient.tags = commentable.owner.patient.tags;
        owner.patient.person_connections.forEach((pc, index) => {
          let commentableConnection =
            commentable.owner.patient.person_connections[index];
          pc.related_person.last_shared_on_date = commentableConnection
            ? commentableConnection.related_person.last_shared_on_date
            : currentTimeWithUserTimeZoneInSeconds();
          pc.related_person.last_request =
            commentableConnection?.related_person?.last_request;
          pc.related_person.tags = commentableConnection?.related_person?.tags;
        });
      }
      return { owner };
    } else {
      return {};
    }
  }

  renderCommentSectionWithCommentable() {
    const { space } = this.props.data,
      { is_author_or_collaborator } = space;
    let commentable = {
      ...(this.state.commentable || {}),
      ...space,
      ...this.getSourceOwnerObject(),
    };

    return (
      <ContentWithSidebars
        childGridIdentifier={'BoardDetail:Timeline'}
        rightSidebarGridIndentifier={'BoardDetail:TimelineRightSidebar'}
        source={getSpaceTabSource(space, 'discussion')}
        isAuthorOrCollaborator={is_author_or_collaborator}
        parentClass=''
        extraClass='board-dis-sec'
        options={{ widgetSourceObject: commentable }}
        widgetsRequiredOnMobile={true}>
        {this.renderCommentSection()}
      </ContentWithSidebars>
    );
  }

  renderCommentSection() {
    const { space } = this.props.data;
    return (
      <CommentSection
        entity={space}
        commentableId={space.nice_id}
        commentableType='Board'
        scrollIfNeeded={this.props.scrollIfNeeded}
        readOnly={this.readOnly()}
        onCommentableLoad={this.onCommentableLoad}
      />
    );
  }

  render() {
    const { space } = this.props.data,
      { source } = this.props;
    if (source.isWorkspace && isCareSpace(space)) {
      return this.renderCommentSection();
    } else {
      return this.renderCommentSectionWithCommentable();
    }
  }
}

SpaceDiscussionsView.defaultProps = {
  scrollIfNeeded: () => {},
};
SpaceDiscussionsView = connect(
  ({ guestUser, source }) => ({ guestUser, source }),
  null,
)(SpaceDiscussionsView);
export default SpaceDiscussionsView;
