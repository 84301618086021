import React from 'react';
import Board from '@asseinfo/react-kanban';
import KanbanTaskCard from 'app/components/Kanban/KanbanTaskCard';
import useUnifiedBoard from 'app/components/Kanban/useUnifiedBoard';

import 'app/components/Task/UnifiedBoard/unifiedBoard.scss';

function UnifiedBoardView({ allTaskOpen, setAllTaskOpen }) {
  const { kBoard, moveCardToAnotherColumn } = useUnifiedBoard();

  const renderCard = (card) => {
    return (
      <KanbanTaskCard
        card={card}
        allTaskOpen={allTaskOpen}
        setAllTaskOpen={setAllTaskOpen}
      />
    );
  };

  const onCardDragEnd = (card, source, destination) => {
    moveCardToAnotherColumn(source.fromColumnId, destination.toColumnId, card);
  };

  return (
    <div className='unified-board'>
      {kBoard ? (
        <Board
          allowAddCard={{ on: 'top' }}
          disableColumnDrag={true}
          renderCard={(card) => renderCard(card)}
          onCardDragEnd={onCardDragEnd}>
          {kBoard}
        </Board>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UnifiedBoardView;
