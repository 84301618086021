import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';

import { openOnboardingModalWithScreen } from 'app/actions/onboardingTips';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';
import ConnectToSupport from 'app/components/ConnectToSupport';
import SetProfileSwitcherPin from 'app/components/SwitchProfile/SetProfileSwitcherPin';
import { translate } from 'app/actions/flashMessage';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import { isMobileDevice } from 'app/utils/deviceHelper';

export default class SecurityView extends PureComponent {
  renderUpdatePasswordCTAIfRequired(user) {
    if (!user.next_verification_step)
      return (
        <OsBtn
          name='BtnSecondary'
          extraClass='with-border'
          onClick={this.props.openPasswordUpdateModal}
          text='Change Password'
          associatedEntity={user}
        />
      );
  }

  renderUpdateNumber(user, btnText) {
    const btnName = user.two_factor_enabled ? 'BtnSecondary' : 'BtnPrimary';
    return (
      <div>
        <OsBtn
          name={btnName}
          extraClass='with-border'
          onClick={this.props.openOnboardingModalWithScreen.bind(
            this,
            'mobile_verification',
          )}
          text={btnText}
          associatedEntity={user}
        />
        <p>We’ll send you a unique code</p>
      </div>
    );
  }

  renderWithout2FA(user) {
    return (
      <div className='security-box security-number-box'>
        <Icon name='privacy' />
        <div className='semibold s-heading-color mb-2 lh-1'>
          {translate('2FA_DISABLE_HEADER')}
        </div>
        <div className='mb-4 text-left'>{translate('2FA_DISABLE')}</div>
        {this.renderUpdateNumber(user, 'Set up 2fa')}
      </div>
    );
  }

  renderWith2FA(user) {
    const countryCode = user.country_code ? `+${user.country_code}` : '',
      phoneNumber = user.phone_number || '',
      messageKey = phoneNumber ? '2FA_ENABLE' : '2FA_ENABLE_WITHOUT_MESSAGE';
    return (
      <div className='security-box security-number-box'>
        <Icon name='privacy' />
        <div className='mb-4 text-left'>
          {translate(
            messageKey,
            {
              on: <span className='os-cfd'>on</span>,
              phone_number: `${countryCode}${phoneNumber}`,
            },
            false,
          )}
        </div>
        {this.renderUpdateNumber(user, 'Change Number')}
      </div>
    );
  }

  renderTwoStepVerification(user) {
    if (user.two_factor_enabled) {
      return this.renderWith2FA(user);
    } else {
      return this.renderWithout2FA(user);
    }
  }

  render() {
    const { graph: user } = this.props.currentUser;
    const profileSwitcherEnabled =
      this.props.workspace.data.profile_switcher_enabled;
    return (
      <div className=''>
        <SetPageTitle source='account_setting' interpolations={'Security'} />
        <div className='security-container'>
          <div className='security-box security-password-box'>
            <Icon name='verification' />
            <div className='mb-4 text-left'>
              {translate(
                'CHANGE_EMAIL',
                {
                  email: user.email,
                  connectToSupport: (
                    <span className='os-cfd'>
                      <ConnectToSupport
                        className='small-link text-underline os-text-6 cursor-pointer'
                        text='Contact Support'
                      />
                    </span>
                  ),
                },
                false,
              )}
            </div>
            <div>
              {this.renderUpdatePasswordCTAIfRequired(user)}
              <p className='d-none'>Last changed May 9, 2019</p>
            </div>
          </div>

          {this.renderTwoStepVerification(user)}
          {!isMobileDevice() && profileSwitcherEnabled && (
            <SetProfileSwitcherPin />
          )}
        </div>
      </div>
    );
  }
}
SecurityView = connect(
  ({ currentUser, workspace }) => ({ currentUser, workspace }),
  {
    openOnboardingModalWithScreen,
    openPasswordUpdateModal,
  },
)(SecurityView);
