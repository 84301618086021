import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = 'ok_svg',
  viewBox = '0 0 24 24',
}) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    className='ok_svg'>
    <path
      d='M19.93 14.216c.4 2.486-.944 5.092-3.088 6.44-2.523 2.094-7.149 1.38-9.293.292C5.406 19.861 3.97 17.255 4 14.89c.01-.513.083-1.056.38-1.49.287-.432.872-.875 1.385-.744.502.141.79.805.933 1.298.144.493.174 1.027.349 1.51.41 1.056 1.63 1.74 2.77 1.53 1.127-.202 2.03-1.269 2.03-2.406-.01-.463-.338-1.368-.708-1.76-.071-.071-.153-.162-.225-.222l3.026-.342c-.524-.604-.862-1.349-1.283-2.033-.42-.684-1.005-1.338-1.774-1.59-.133-.03-.256-.06-.39-.08h.01c-.215-.645-.502-1.168-.84-1.752-.34-.573-.637-1.207-.708-1.861-.062-.674.164-1.399.718-1.802.553-.392 1.456-.291 1.795.282.482.795.954 1.58 1.436 2.375 1.066 1.781 2.123 3.562 3.19 5.344l1.64-.795 1.857-.03a29.06 29.06 0 0 0 .339 3.894z'
      fill='#FFC933'
    />
    <path
      d='M18.31 4.143c.87-.03 1.66.815 1.548 1.68a27.559 27.559 0 0 0-.256 4.508l-1.857.03c-.297-1.408-.687-2.797-1.098-4.186-.205-.835.78-1.992 1.662-2.032z'
      fill='#FFC933'
    />
    <path
      d='m17.77 10.351-1.677.806c-1.066-1.781-2.123-3.563-3.19-5.344-.164-.614-.153-1.49-.092-2.113.052-.634.38-1.288.954-1.57.575-.292 1.405-.08 1.641.513a53.432 53.432 0 0 1 1.241 3.532c.421 1.39.825 2.767 1.123 4.176zM13.95 12.264l-3.026.342c-.677-.614-1.56-1.006-2.43-.805-.504.11-.955.372-1.447.533-.37.11-.8.161-1.149.03a.925.925 0 0 1-.308-.17c-.41-.303-.513-.876-.359-1.35.144-.462.513-.844.923-1.126.718-.483 1.56-.725 2.4-.936.637-.161 1.303-.302 1.95-.222.123.02.246.05.379.08.77.253 1.344.917 1.774 1.59.431.675.76 1.43 1.293 2.034z'
      fill='#FFC933'
    />
    <path
      d='M17.873 10.418c-.188-.342-.31-.692-.442-1.043l-.339-1.034c-.075-.351-.207-.693-.282-1.053-.075-.36-.15-.73-.15-1.126v-.01c.244.693.573 1.367.79 2.078.207.71.348 1.44.452 2.17 0 .009-.01.018-.02.018 0 .01-.009.01-.009 0zM15.835 10.719a8.886 8.886 0 0 1-.951-1.108c-.292-.388-.527-.794-.8-1.191-.226-.425-.47-.83-.668-1.265a9 9 0 0 1-.508-1.357c-.01 0 .009-.018 0 0 0-.01.009 0 0 0a262.03 262.03 0 0 1 2.955 4.912c.01.009 0 .018-.01.027 0-.009-.009-.009-.018-.018zM13.747 11.992c-.326-.32-.89-1.09-1.144-1.422-.245-.323-.272-.35-.507-.656 0 0-.354-.415-.617-.674-.254-.276-.593-.489-.979-.673 0-.01.01-.01.019-.01.414.056.81.203 1.139.462.33.24.602.563.828.895.452.684.727 1.191 1.29 2.078-.029 0 .009.009 0 0-.01.009-.016.012-.029 0z'
      fill='#C2350A'
    />
  </svg>
);

export default SVG;
