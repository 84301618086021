import { OPEN_ANALYTICS_MODAL, CLOSE_ANALYTICS_MODAL } from './types';

export function openAnalyticsModal() {
  return {
    type: OPEN_ANALYTICS_MODAL,
  };
}

export function closeAnalyticsModal() {
  return {
    type: CLOSE_ANALYTICS_MODAL,
  };
}
