import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContentWithSidebars from 'app/components/ContentWithSidebars';
import RecordsView from 'app/components/RecordsView';

import { ACTIVITY_FEEDS_BATCH_SIZE } from 'app/constants';
import { isCurrentUser } from 'app/utils/userHelper';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

class Timeline extends Component {
  isWidthForWidgetsIpad() {
    let device = this.props.device;
    return device.ipadPortrait || (device.width > 768 && device.width < 992);
  }

  renderWidgets() {
    return null;
  }

  render() {
    let props = {};
    if (isCurrentUser(this.props.user)) {
      props['type'] = 'MyActivities';
    } else {
      props['type'] = isWorkspaceView() ? 'NewUserActivities' : 'UserActivities';
      props['idQuery'] = this.props.user.id;
    }
    props['widgetAfterListItemNumber'] = {
      3: this.renderWidgets(),
    };
    return (
      <ContentWithSidebars
        childGridIdentifier={'UserProfile:Timeline'}
        rightSidebarGridIndentifier={'UserProfile:TimelineRightSidebar'}
        source={'user-detail'}
        extraClass='user-timeline-section'
        sideBarClass=' sidebar-with-cards'
        options={{ widgetSourceObject: this.props.user }}>
        <RecordsView
          {...props}
          allCaughtUpNotRequired={true}
          perPage={ACTIVITY_FEEDS_BATCH_SIZE}
        />
      </ContentWithSidebars>
    );
  }
}

Timeline = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  null,
)(Timeline);
export default Timeline;
