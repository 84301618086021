import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { flashSuccess } from 'app/actions/flashMessage';
import { useDispatch } from 'react-redux';
import { LABELS_POSSIBLE_COLORS } from 'app/components/LabelsModal/constants';
import { random } from 'app/utils/osLodash';
import StoreUpdater from 'app/services/StoreUpdater';
import { getWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

const UPDATE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateLabel.gql',
);

const useAddLabel = () => {
  const dispatch = useDispatch();
  const [addOrUpdateLabel, { data, loading, error }] = useMutation(
    UPDATE_LABEL_MUTATION,
  );
  const addNewLabel = async (label) => {
    // Select a random color from the LABELS_POSSIBLE_COLORS
    const randomColor =
      LABELS_POSSIBLE_COLORS[random(0, LABELS_POSSIBLE_COLORS.length)];

    try {
      return await addOrUpdateLabel({
        variables: {
          name: label.name,
          color: label.color || randomColor,
          type: label.type,
          subLabels: label.subLabels,
        },
        onCompleted: (data) => {
          // Need to add it in store Updater.
          StoreUpdater.addLabelInList(
            data.updateLabel.entity,
            { id: getWorkspaceIdentifier() },
            {},
          );
          dispatch(flashSuccess('Label Created', false));
        },
      });
    } catch (error) {
      console.log('error: ', error.message);
    }
  };
  return {
    addNewLabel,
  };
};

export default useAddLabel;
