import { useQuery } from '@apollo/client';
import { openEmptyTaskModal } from 'app/actions/taskModal';
import MemberLabel from 'app/components/Task/MemberLabel';
import {
  BOARD,
  CURRENT_VIEW,
  DEFAULT_OPTIONS,
  FORMAT_START_WITH_DATE_DASH,
  LIST,
  STATUS,
  UNIFIED_BOARD,
  UNIFIED_LIST,
} from 'app/components/Task/taskConstant';
import { BaseTaskContext } from 'app/context/TaskContext';
import { useLocalStorage } from 'app/hooks/useLocalStorage';
import storeUpdater from 'app/services/StoreUpdater';
import { debounce, floor, isEmpty, uniqBy } from 'app/utils/osLodash';
import {
  USER_TASKS_VIEW,
  changeRoute,
  changeWeekInRoute,
  checkTaskIfOverdue,
  getAllLabelsThatHaveSubLabels,
  getLabelListName,
} from 'app/utils/taskHelper';
import {
  checkWeek,
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
  jumpWeekFromCurrentDate,
} from 'app/utils/timeHelper';
import { loader as queryLoader } from 'graphql.macro';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getParentLabel } from 'app/utils/labelHelper';
import {
  COMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE,
  COMPLETED_TASK_BATCH_SIZE,
  INPROGRESS_TASK_BATCH_SIZE,
  NOTCOMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE,
  NOTVIEWED_TASK_BATCH_SIZE,
  OVERDUE_TASK_BATCH_SIZE,
  UNSCHEDULED_TASK_BATCH_SIZE,
  VIEWED_TASK_BATCH_SIZE,
} from 'app/constant/tasks';

const ACTIVE_TASKS = queryLoader('app/graphql/queries/Task/TaskByWeek.gql');
const OVERDUE_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const COMPLETED_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const UNSCHEDULED_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const IN_PROGRESS_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const VIEWED_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const NOT_VIEWED_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const COMPLETED_AND_ASSIGNED_TO_OTHER = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const NOT_COMPLETED_AND_ASSIGNED_TO_OTHER = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const useTask = (initialData) => {
  const { teamMembersData, labelsData, currentUser, workspace } =
    useContext(BaseTaskContext);
  const device = useSelector((state) => state.device);
  const [currentView, setCurrentView] = useLocalStorage(
    CURRENT_VIEW,
    initialData.view || LIST,
  );
  const [showWidget, setShowWidget] = useState(false);
  const [allUserOptions, setAllUserOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [groupLabelResponse, setGroupLabelResponse] = useState({});
  const [pageTitle, setPageTitle] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const completedAndAssignedToOtherRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const notCompletedAndAssignedToOtheRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const overdueTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const unscheduledTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const completedTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const inProgressTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const viewedTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const notViewedTaskRef = useRef({
    totalResults: 0,
    fetchedResults: 0,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [textQuery, setTextQuery] = useState(() => {
    if (!!initialData.label) {
      const selectedLabels = initialData.label.split(',');
      return labelsData?.filter((label) => selectedLabels.includes(label.name));
    }
    return [];
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [labelGroup, setLabelGroup] = useState(null);
  const [personFilter, setPersonFilter] = useState(() => {
    if (initialData.person) {
      return teamMembersData?.workspace?.all_members?.find(
        (member) => member.id === initialData.person,
      );
    }
    return null;
  });

  // Fetch In Progress Tasks
  const {
    data: inProgressTasks,
    loading: isInprogressLoading,
    refetch: refetchInProgress,
    fetchMore: fetchNextInprogressTask,
    variables: inprogressTaskVariables,
  } = useQuery(IN_PROGRESS_TASKS, {
    variables: {
      page: 0,
      perPage: INPROGRESS_TASK_BATCH_SIZE,
      type: 'InProgressTasks',
      id_query:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? personFilter?.id
            : null
          : initialData.user?.value,
      id_type: 'User',
      sortQuery: 'due_date_asc',
      searchQuery: initialData.query,
      text_query: null,
      additional_filters: JSON.stringify({
        label_ids: textQuery.map((label) => label.id),
      }),
    },
    fetchPolicy: 'network-only',
  });

  //Fetch Viewed Tasks
  const {
    data: viewedTasks,
    loading: isViewedLoading,
    refetch: refetchViewed,
    fetchMore: fetchNextViewedTask,
    variables: viewedTaskVariables,
  } = useQuery(VIEWED_TASKS, {
    variables: {
      page: 0,
      perPage: VIEWED_TASK_BATCH_SIZE,
      type: 'ViewedTasks',
      sortQuery: 'last_assigned_at',
      searchQuery: query,
      text_query:
        textQuery.length > 0
          ? textQuery.map((label) => label.name).join(',')
          : null,
    },
    fetchPolicy: 'network-only',
  });

  // Fetch Not Viewed Tasks
  const {
    data: notViewedTasks,
    loading: isNotViewedLoading,
    refetch: refetchNotViewed,
    fetchMore: fetchNextNotViewedTask,
    variables: notViewedTaskVariables,
  } = useQuery(NOT_VIEWED_TASKS, {
    variables: {
      page: 0,
      perPage: NOTVIEWED_TASK_BATCH_SIZE,
      type: 'NotViewedTasks',
      sortQuery: 'last_assigned_at',
      searchQuery: query,
      text_query:
        textQuery.length > 0
          ? textQuery.map((label) => label.name).join(',')
          : null,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: completedAndAssignedToOtherTasks,
    loading: isCompletedAndAssignedToOtherLoading,
    refetch: refetchCompletedAndAssignedToOther,
    fetchMore: fetchNextCompletedAndAssignedToOtherTask,
    variables: completedAndAssignedToOtherTaskVariables,
  } = useQuery(COMPLETED_AND_ASSIGNED_TO_OTHER, {
    variables: {
      page: 0,
      perPage: COMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE,
      type: 'CompletedUserCreatedTasksAssignedToOther',
      sortQuery: 'last_assigned_at',
      searchQuery: query,
      id_query: personFilter?.id || '',
      id_type: 'User',
      text_query:
        textQuery.length > 0
          ? textQuery.map((label) => label.name).join(',')
          : null,
    },
    fetchPolicy: 'network-only',
    // skip: query && query !== initialData.query,
  });

  const {
    data: notCompletedAndAssignedToOtherTasks,
    loading: isNotCompletedAndAssignedToOtherLoading,
    refetch: refetchNotCompletedAndAssignedToOther,
    fetchMore: fetchNextNotCompletedAndAssignedToOtherTask,
    variables: notCompletedAndAssignedToOtherTaskVariables,
  } = useQuery(NOT_COMPLETED_AND_ASSIGNED_TO_OTHER, {
    variables: {
      page: 0,
      perPage: NOTCOMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE,
      type: 'NotCompletedUserCreatedTasksAssignedToOther',
      sortQuery: 'last_assigned_at',
      searchQuery: query,
      id_query: personFilter?.id || '',
      id_type: 'User',
      text_query:
        textQuery.length > 0
          ? textQuery.map((label) => label.name).join(',')
          : null,
    },
    fetchPolicy: 'network-only',
    // skip: query && query !== initialData.query,
  });

  // Fetch Active Tasks
  const {
    data: activeTasks,
    variables: activeTaskVariables,
    refetch: refetchActive,
  } = useQuery(ACTIVE_TASKS, {
    variables: {
      date: getDateFromStringWithGivenFormat(
        initialData.date,
        FORMAT_START_WITH_DATE_DASH,
      ),
      labels: null,
      labelIds: textQuery.length > 0 ? textQuery?.map((label) => label.id) : [],
      assignees:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? [`User: ${personFilter?.id}`]
            : []
          : [`User: ${initialData.user?.value}`],
      query: initialData.query,
    },
    fetchPolicy: 'network-only',
    skip:
      (query && query !== initialData.query) ||
      initialData.isUserView ||
      initialData.isUnifiedView ||
      initialData.user?.label === 'Overdue' ||
      initialData.isUpcoming,
  });

  // Fetch Next Week Active Tasks
  const {
    data: nextWeekactiveTasks,
    variables: nextWeekActiveTaskVariables,
    refetch: refetchNextWeekActive,
  } = useQuery(ACTIVE_TASKS, {
    variables: {
      date: getDateFromStringWithGivenFormat(
        jumpWeekFromCurrentDate(1),
        FORMAT_START_WITH_DATE_DASH,
      ),
      labels: null,
      labelIds: textQuery.length > 0 ? textQuery?.map((label) => label.id) : [],
      assignees:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? [`User: ${personFilter?.id}`]
            : []
          : [`User: ${initialData.user?.value}`],
      query: initialData.query,
    },
    fetchPolicy: 'network-only',
    skip: (query && query !== initialData.query) || !initialData.isUpcoming,
  });

  const {
    data: dueInTwoWeekactiveTasks,
    variables: dueInTwoWeekActiveTaskVariables,
    refetch: refetchDueInTwoWeekActive,
  } = useQuery(ACTIVE_TASKS, {
    variables: {
      date: getDateFromStringWithGivenFormat(
        jumpWeekFromCurrentDate(2),
        FORMAT_START_WITH_DATE_DASH,
      ),
      labels: null,
      labelIds: textQuery.length > 0 ? textQuery?.map((label) => label.id) : [],
      assignees:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? [`User: ${personFilter?.id}`]
            : []
          : [`User: ${initialData.user?.value}`],
      query: initialData.query,
    },
    fetchPolicy: 'network-only',
    skip: (query && query !== initialData.query) || !initialData.isUpcoming,
  });

  const {
    data: dueInThreeWeekactiveTasks,
    variables: dueInThreeActiveTaskVariables,
    refetch: refetchDueInThreeWeekActive,
  } = useQuery(ACTIVE_TASKS, {
    variables: {
      date: getDateFromStringWithGivenFormat(
        jumpWeekFromCurrentDate(3),
        FORMAT_START_WITH_DATE_DASH,
      ),
      labels: null,
      labelIds: textQuery.length > 0 ? textQuery?.map((label) => label.id) : [],
      assignees:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? [`User: ${personFilter?.id}`]
            : []
          : [`User: ${initialData.user?.value}`],
      query: initialData.query,
    },
    fetchPolicy: 'network-only',
    skip: (query && query !== initialData.query) || !initialData.isUpcoming,
  });

  // Fetch overdue tasks
  const {
    data: overdueTasks,
    loading: isOverdueLoading,
    refetch: refetchOverdue,
    fetchMore: fetchNextOverdueTask,
    variables: overdueTaskVariables,
  } = useQuery(OVERDUE_TASKS, {
    variables: {
      page: 0,
      perPage: OVERDUE_TASK_BATCH_SIZE,
      type: 'OverDueTasks',
      id_query:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? personFilter?.id
            : null
          : initialData.user?.value,
      id_type: 'User',
      sortQuery: 'due_date_desc',
      searchQuery: initialData.query,
      text_query: null,
      additional_filters: JSON.stringify({
        label_ids: textQuery.map((label) => label.id),
      }),
    },
    fetchPolicy: 'network-only',
    skip:
      (query && query !== initialData.query) ||
      initialData.isUpcoming ||
      initialData.isUserView,
  });

  // Fetch unscheduled tasks
  const {
    data: unscheduledTasks,
    refetch: refetchUnscheduled,
    fetchMore: fetchNextUnscheduledTask,
    variables: unscheduledTaskVariables,
  } = useQuery(UNSCHEDULED_TASKS, {
    variables: {
      page: 0,
      perPage: UNSCHEDULED_TASK_BATCH_SIZE,
      type: 'UnscheduledTasks',
      id_query:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? personFilter?.id
            : null
          : initialData.user?.value,
      id_type: 'User',
      searchQuery: initialData.query,
      text_query: null,
      additional_filters: JSON.stringify({
        label_ids: textQuery.map((label) => label.id),
      }),
    },
    fetchPolicy: 'network-only',
    skip:
      (query && query !== initialData.query) ||
      initialData.isUpcoming ||
      initialData.user?.label === 'Overdue' ||
      initialData.isUserView,
  });

  const {
    data: completedTasks,
    variables: completedTaskVariables,
    refetch: refetchCompleted,
    fetchMore: fetchNextCompletedTask,
  } = useQuery(COMPLETED_TASKS, {
    variables: {
      page: 0,
      perPage: COMPLETED_TASK_BATCH_SIZE,
      type: 'CompletedTasks',
      id_query:
        initialData.user?.value === 'all_tasks'
          ? personFilter
            ? personFilter?.id
            : null
          : initialData.user?.value,
      id_type: 'User',
      sortQuery: 'due_date_desc',
      searchQuery: initialData.query,
      text_query: null,
      additional_filters: JSON.stringify({
        label_ids: textQuery.map((label) => label.id),
      }),
    },
    fetchPolicy: 'network-only',
    skip:
      (query && query !== initialData.query) ||
      initialData.user?.label === 'Overdue' ||
      initialData.isUserView ||
      initialData.isUpcoming,
  });

  // have doubt related to resetTaskList Function
  const handleLableGroupChange = (label) => {
    if (label) {
      setLabelGroup(label);
      searchParams.set('group', label.id);
    } else {
      setLabelGroup(null);
      searchParams.delete('group');
    }
    setSearchParams(searchParams);
  };

  const changeInputSearchRouteCallback = useCallback(
    debounce(() => {
      setSearchParams(searchParams);
    }, 500),
    [searchParams, location],
  );

  const getAllUserOptions = () => {
    if (!!teamMembersData) {
      const memberOptions = teamMembersData?.workspace?.all_members?.map(
        (member) => {
          return {
            ...member,
            value: member.id,
            label: <MemberLabel member={member} />,
          };
        },
      );
      const filteredMemberOptions = memberOptions;
      filteredMemberOptions?.sort((a, b) =>
        a.full_name.localeCompare(b.full_name),
      );
      // Provide My Tasks Options
      const baseOption = {
        ...currentUser.graph,
        label: 'My Tasks',
        value: currentUser.graph.id,
      };
      return [baseOption, ...DEFAULT_OPTIONS, ...filteredMemberOptions];
    }
    return [];
  };

  const fetchNextOverdueRecords = async () => {
    let perPage = OVERDUE_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = overdueTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextOverdueTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextCompletedRecords = async () => {
    let perPage = COMPLETED_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = completedTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextCompletedTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextUnscheduledRecords = async () => {
    let perPage = UNSCHEDULED_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = unscheduledTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextUnscheduledTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextInprogressRecords = async () => {
    let perPage = INPROGRESS_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = inProgressTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextInprogressTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextViewedRecords = () => {
    let perPage = VIEWED_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = viewedTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextViewedTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextNotViewedRecords = () => {
    let perPage = NOTVIEWED_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } = notViewedTaskRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextNotViewedTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextCompletedAssignedToOtherRecords = async () => {
    let perPage = COMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } =
      completedAndAssignedToOtherRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextCompletedAndAssignedToOtherTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const fetchNextNotCompletedAssignedToOtherRecords = async () => {
    let perPage = NOTCOMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE;
    const { totalResults, fetchedResults } =
      notCompletedAndAssignedToOtheRef.current;
    if (fetchedResults >= totalResults) return;
    const nextPage = floor(fetchedResults / perPage);

    fetchNextNotCompletedAndAssignedToOtherTask({
      variables: {
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          records: {
            ...previousResult.records,
            page: fetchMoreResult.records.page,
            total: fetchMoreResult.records.total,
            results: uniqBy(
              [
                ...previousResult.records.results,
                ...fetchMoreResult.records.results,
              ],
              (obj) => `${obj.__typename}:${obj.id}`,
            ),
          },
        };
      },
    });
  };

  const changeUserRoute = (user) => {
    if (USER_TASKS_VIEW.includes(user.value)) {
      setCurrentView(LIST);
    }

    let route = changeRoute({
      workspace,
      view: currentView,
      user: user,
      date: initialData.date || currentTimeWithUserTimeZone(),
      query,
      textQuery,
    });

    // Update Person filter
    if (user.id && user.id !== currentUser.graph.id) {
      setPersonFilter(user);
    } else {
      setPersonFilter(null);
    }

    navigate(route);
    setShowSearchBar(false);
  };

  const backToToday = () => {
    let route = changeRoute({
      workspace,
      view: currentView,
      user: initialData.user,
      date: currentTimeWithUserTimeZone(),
      query: '',
      textQuery,
    });
    navigate({
      pathname: route,
      search: location.search,
    });
  };

  const changeWeekHandler = (week) => {
    let route = changeWeekInRoute({
      workspace,
      view: currentView,
      user: initialData.user,
      week,
      date: initialData.date,
      textQuery,
    });
    navigate({
      pathname: route,
      search: location.search,
    });
  };

  const isLabelAlreadySelected = (label) => {
    // return true if label is already selected
    return textQuery.some((l) => l.id === label?.id);
  };

  const changeLabel = (labelObject) => {
    let { label = labelObject, addLabel = true } = labelObject;
    if (addLabel && (isLabelAlreadySelected(label) || textQuery.length > 4)) {
      return;
    }
    if (addLabel) {
      const existingLabels = searchParams.get('label')?.split(',') || [];
      if (existingLabels) {
        searchParams.set('label', [...existingLabels, label.id]);
      } else {
        searchParams.set('label', [label.name]);
      }
    } else {
      setTextQuery((prevState) => prevState.filter((l) => l.id !== label.id));
      const existingLabels = searchParams.get('label')?.split(',') || [];
      const newLabels = existingLabels.filter((l) => l !== label.id);
      if (newLabels?.length) {
        searchParams.set('label', newLabels);
      } else {
        searchParams.delete('label');
      }
    }

    setSearchParams(searchParams);
    setShowSearchBar(false);
  };

  const changeView = (view) => {
    let route = location.pathname;
    if (view === LIST) {
      route = route.replace(BOARD, LIST);
    } else {
      route = route.replace(LIST, BOARD);
    }
    navigate({
      pathname: route,
      search: location.search,
    });
  };

  const redirectToPriorityView = (view) => {
    let route = location.pathname;
    if (view === LIST) {
      route = route + '/me/today/list';
    } else if (view === STATUS) {
      route = route + '/me/status/list';
    } else {
      route = route + '/me/today/board';
    }
    navigate({
      pathname: route,
      search: location.search,
    });
  };

  const changeInputSearchRoute = (value) => {
    setQuery(value);
    if (value) {
      searchParams.set('filter', value);
    } else {
      searchParams.delete('filter');
    }
    changeInputSearchRouteCallback();
  };

  const removeTaskFromGroupLabelRecords = (task, listName) => {
    setGroupLabelResponse((prevState) => ({
      ...prevState,
      [listName]: {
        ...prevState[listName],
        data: {
          ...prevState[listName].data,
          records: {
            ...prevState[listName].data.records,
            total: prevState[listName].data.records.total - 1,
            results: prevState[listName].data.records.results.filter(
              (t) => t.id !== task.id,
            ),
          },
        },
      },
    }));
  };

  const addTaskInGroupLabelRecords = (task, listName) => {
    setGroupLabelResponse((prevState) => ({
      ...prevState,
      [listName]: {
        ...prevState[listName],
        data: {
          ...prevState[listName].data,
          records: {
            ...prevState[listName].data.records,
            total: prevState[listName].data.records.total + 1,
            results: [task, ...prevState[listName].data.records.results],
          },
        },
      },
    }));
  };

  const updateTaskInGroupLabelRecords = (task) => {
    let listName = getLabelListName(task, labelGroup, labelsData);
    setGroupLabelResponse((prevState) => ({
      ...prevState,
      [listName]: {
        ...prevState[listName],
        data: {
          ...prevState[listName].data,
          records: {
            ...prevState[listName].data.records,
            results: prevState[listName].data.records.results.map((t) =>
              t.id === task.id ? task : t,
            ),
          },
        },
      },
    }));
  };

  const updateCache = (task, action, taskType) => {
    if (action === 'create') {
      if (currentUser.graph.id === task.assignee.id) {
        if (initialData.isUpcoming) {
          // add in the upcoming view
          let week = checkWeek(task.due_date);
          switch (week) {
            case 1:
              storeUpdater.addTaskInRecord(task, nextWeekActiveTaskVariables, {
                query: ACTIVE_TASKS,
              });
              break;
            case 2:
              storeUpdater.addTaskInRecord(
                task,
                dueInTwoWeekActiveTaskVariables,
                {
                  query: ACTIVE_TASKS,
                },
              );
              break;
            case 3:
              storeUpdater.addTaskInRecord(
                task,
                dueInThreeActiveTaskVariables,
                {
                  query: ACTIVE_TASKS,
                },
              );
              break;
            default:
              break;
          }
        } else if (initialData.isOverdueView) {
          storeUpdater.addRecord(task, overdueTaskVariables, {
            query: OVERDUE_TASKS,
          });
        } else if (
          currentView === UNIFIED_LIST ||
          currentView === LIST ||
          currentView === UNIFIED_BOARD
        ) {
          if (task.due_date === null) {
            storeUpdater.addRecord(task, unscheduledTaskVariables, {
              query: UNSCHEDULED_TASKS,
            });
          } else if (checkTaskIfOverdue(task)) {
            storeUpdater.addRecord(task, overdueTaskVariables, {
              query: OVERDUE_TASKS,
            });
          } else {
            if (currentView === UNIFIED_LIST || currentView === UNIFIED_BOARD) {
              storeUpdater.addRecord(task, inprogressTaskVariables, {
                query: IN_PROGRESS_TASKS,
              });
            } else {
              storeUpdater.addTaskInRecord(task, activeTaskVariables, {
                query: ACTIVE_TASKS,
              });
            }
          }
        } else {
          if (checkTaskIfOverdue(task)) {
            storeUpdater.addRecord(task, overdueTaskVariables, {
              query: OVERDUE_TASKS,
            });
          } else {
            storeUpdater.addTaskInRecord(task, activeTaskVariables, {
              query: ACTIVE_TASKS,
            });
          }
        }
        if (labelGroup !== null) {
          const listName = getLabelListName(task, labelGroup, labelsData);
          if (listName) {
            addTaskInGroupLabelRecords(task, listName);
          }
        }
      } else {
        storeUpdater.addRecord(
          task,
          notCompletedAndAssignedToOtherTaskVariables,
          { query: NOT_COMPLETED_AND_ASSIGNED_TO_OTHER },
        );
      }
    }
    if (action === 'delete') {
      if (taskType === 'AllTasks') {
        if (currentView === UNIFIED_BOARD) {
          if (task.completed) {
            deleteSingleTaskFromListType(task, 'CompletedTasks');
          } else if (task.due_date === null) {
            deleteSingleTaskFromListType(task, 'UnscheduledTasks');
          } else if (checkTaskIfOverdue(task)) {
            deleteSingleTaskFromListType(task, 'OverDueTasks');
          } else {
            deleteSingleTaskFromListType(task, 'InProgressTasks');
          }
        } else {
          storeUpdater.removeTaskFromRecords(task, activeTaskVariables, {
            query: ACTIVE_TASKS,
          });
          storeUpdater.removeTaskInRecords(task, overdueTaskVariables, {
            query: OVERDUE_TASKS,
          });
        }
      } else {
        deleteSingleTaskFromListType(task, taskType);
      }
    }
    if (action === 'update') {
      if (labelGroup) updateTaskInGroupLabelRecords(task);
    }
    if (action === 'unscheduled_delete') {
      storeUpdater.removeTaskFromUnscheduledRecords(
        task,
        unscheduledTaskVariables,
        {
          query: UNSCHEDULED_TASKS,
        },
      );
    }
  };

  const openNewTaskModal = () => {
    dispatch(
      openEmptyTaskModal(
        updateCache,
        updateComponent,
        personFilter ? personFilter.id : currentUser.graph.id,
      ),
    );
  };

  const updateComponent = (task, listType) => {
    if (labelGroup !== null) {
      let listName = getLabelListName(task, labelGroup, labelsData);
      if (listType) {
        removeTaskFromGroupLabelRecords(task, listName);
      } else {
        if (listName) {
          let isTaskEdit = task.entity_labels.find(
            (l) => getParentLabel(l.label, labelsData).id === labelGroup.id,
          );

          if (isTaskEdit) {
            updateTaskInGroupLabelRecords(task);
          } else {
            addTaskInGroupLabelRecords(task, listName);
          }
        }
      }
    } else if (listType === 'AddTasks') {
      if (initialData.isUpcoming) {
        refetchTasksForListType('NextWeekActiveTasks');
        refetchTasksForListType('SecondWeekActiveTasks');
        refetchTasksForListType('ThirdWeekActiveTasks');
      } else if (initialData.isOverdueView) {
        refetchTasksForListType('OverDueTasks');
      } else if (
        initialData.isUserView &&
        currentUser.graph.id !== task.assignee.id
      ) {
        refetchTasksForListType('NotCompletedUserCreatedTasksAssignedToOther');
      } else if (currentView === UNIFIED_LIST || currentView === LIST) {
        if (task.due_date === null) {
          refetchTasksForListType('UnscheduledTasks');
        } else if (checkTaskIfOverdue(task)) {
          refetchTasksForListType('OverDueTasks');
        } else {
          if (currentView === UNIFIED_LIST) {
            refetchTasksForListType('InProgressTasks');
          } else {
            refetchTasksForListType('ActiveTasks');
          }
        }
      }
    } else if (listType === 'AllTasks') {
      if (currentView === UNIFIED_BOARD) {
        if (task.completed) {
          refetchTasksForListType('CompletedTasks');
        } else if (checkTaskIfOverdue(task.due_date)) {
          refetchTasksForListType('OverDueTasks');
        } else if (task.due_date === null) {
          refetchTasksForListType('UnscheduledTasks');
        } else {
          refetchTasksForListType('InProgressTasks');
        }
      } else {
        refetchOverdue().then((r) => r);
        refetchActive().then((r) => r);
      }
    } else {
      refetchTasksForListType(listType);
    }
  };

  const deleteSingleTaskFromListType = (task, type) => {
    switch (type) {
      case 'ActiveTasks':
        storeUpdater.removeTaskFromRecords(task, activeTaskVariables, {
          query: ACTIVE_TASKS,
        });
        break;
      case 'NextWeekActiveTasks':
        storeUpdater.removeTaskFromRecords(task, nextWeekActiveTaskVariables, {
          query: ACTIVE_TASKS,
        });
        break;
      case 'SecondWeekActiveTasks':
        storeUpdater.removeTaskFromRecords(
          task,
          dueInTwoWeekActiveTaskVariables,
          {
            query: ACTIVE_TASKS,
          },
        );
        break;
      case 'ThirdWeekActiveTasks':
        storeUpdater.removeTaskFromRecords(
          task,
          dueInThreeActiveTaskVariables,
          {
            query: ACTIVE_TASKS,
          },
        );
        break;
      case 'CompletedTasks':
        storeUpdater.removeTaskInRecords(task, completedTaskVariables, {
          query: COMPLETED_TASKS,
        });
        break;
      case 'UnscheduledTasks':
        storeUpdater.removeTaskInRecords(task, unscheduledTaskVariables, {
          query: UNSCHEDULED_TASKS,
        });
        break;
      case 'OverDueTasks':
        storeUpdater.removeTaskInRecords(task, overdueTaskVariables, {
          query: OVERDUE_TASKS,
        });
        break;
      case 'InProgressTasks':
        storeUpdater.removeTaskInRecords(task, inprogressTaskVariables, {
          query: IN_PROGRESS_TASKS,
        });
        break;
      case 'ViewedTasks':
        storeUpdater.removeTaskInRecords(task, viewedTaskVariables, {
          query: VIEWED_TASKS,
        });
        break;
      case 'NotViewedTasks':
        storeUpdater.removeTaskInRecords(task, notViewedTaskVariables, {
          query: NOT_VIEWED_TASKS,
        });
        break;
      case 'CompletedUserCreatedTasksAssignedToOther':
        storeUpdater.removeTaskInRecords(
          task,
          completedAndAssignedToOtherTaskVariables,
          {
            query: COMPLETED_AND_ASSIGNED_TO_OTHER,
          },
        );
        break;
      case 'NotCompletedUserCreatedTasksAssignedToOther':
        storeUpdater.removeTaskInRecords(
          task,
          notCompletedAndAssignedToOtherTaskVariables,
          {
            query: NOT_COMPLETED_AND_ASSIGNED_TO_OTHER,
          },
        );
      case 'GroupLabelTasks':
        let listName = getLabelListName(task, labelGroup, labelsData);
        removeTaskFromGroupLabelRecords(task, listName);
        break;
      default:
        break;
    }
  };

  const refetchTasksForListType = (listType) => {
    switch (listType) {
      case 'ActiveTasks':
        refetchActive().then((r) => r);
        break;
      case 'NextWeekActiveTasks':
      case 'SecondWeekActiveTasks':
      case 'ThirdWeekActiveTasks':
        refetchNextWeekActive().then((r) => r);
        refetchDueInTwoWeekActive().then((r) => r);
        refetchDueInThreeWeekActive().then((r) => r);
        break;
      case 'CompletedTasks':
        refetchCompleted().then((r) => r);
        break;
      case 'UnscheduledTasks':
        refetchUnscheduled().then((r) => r);
        break;
      case 'OverDueTasks':
        refetchOverdue().then((r) => r);
        break;
      case 'InProgressTasks':
        refetchInProgress().then((r) => r);
        break;
      case 'ViewedTasks':
        refetchViewed().then((r) => r);
        break;
      case 'NotViewedTasks':
        refetchNotViewed().then((r) => r);
        break;
      case 'CompletedUserCreatedTasksAssignedToOther':
        refetchCompletedAndAssignedToOther().then((r) => r);
        break;
      case 'NotCompletedUserCreatedTasksAssignedToOther':
        refetchNotCompletedAndAssignedToOther().then((r) => r);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (initialData.isUserView) {
      setCurrentView(LIST);
    } else {
      if (location.pathname === `/${workspace.identifier}/tasks`) {
        redirectToPriorityView(currentView);
      } else {
        const isCurrentViewList = location.pathname.includes(LIST);
        const isCurrentViewBoard = location.pathname.includes(BOARD);
        const isUnified = location.pathname.includes(STATUS);

        if (isUnified && isCurrentViewList) {
          setCurrentView(UNIFIED_LIST);
        } else if (isUnified && isCurrentViewBoard) {
          setCurrentView(UNIFIED_BOARD);
        } else if (isCurrentViewList && currentView !== LIST) {
          setCurrentView(LIST);
        } else if (isCurrentViewBoard && currentView !== BOARD) {
          setCurrentView(BOARD);
        }
      }
    }
    setQuery(searchParams.get('filter') || '');
  }, [currentView, location]);

  useEffect(() => {
    // Add event listener for ctrl + enter or cmd + enter to submit the form
    const handleKeyDown = (event) => {
      event.stopImmediatePropagation();
      if (event.altKey && event.keyCode === 84) {
        openNewTaskModal();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openNewTaskModal]);

  useEffect(() => {
    if (teamMembersData) {
      const updatedOptions = getAllUserOptions();
      if (updatedOptions) setAllUserOptions(updatedOptions);
    }
  }, [teamMembersData]);

  useEffect(() => {
    if (!!initialData.user.label) setPageTitle(initialData.user.label);
  }, [initialData.user]);

  useEffect(() => {
    if (!!overdueTasks) {
      let { records } = overdueTasks;
      if (records.results.length < 5) fetchNextOverdueRecords();
      overdueTaskRef.current.totalResults = records.total;
      overdueTaskRef.current.fetchedResults = records.results.length;
    }
  }, [overdueTasks]);

  useEffect(() => {
    if (!!completedTasks) {
      let { records } = completedTasks;
      completedTaskRef.current.totalResults = records.total;
      completedTaskRef.current.fetchedResults = records.results.length;
    }
  }, [completedTasks]);

  useEffect(() => {
    if (!!unscheduledTasks) {
      let { records } = unscheduledTasks;
      unscheduledTaskRef.current.totalResults = records.total;
      unscheduledTaskRef.current.fetchedResults = records.results.length;
    }
  }, [unscheduledTasks]);

  useEffect(() => {
    if (!!inProgressTasks) {
      let { records } = inProgressTasks;
      inProgressTaskRef.current.totalResults = records.total;
      inProgressTaskRef.current.fetchedResults = records.results.length;
    }
  }, [inProgressTasks]);

  useEffect(() => {
    if (!!viewedTasks) {
      let { records } = viewedTasks;
      viewedTaskRef.current.totalResults = records.total;
      viewedTaskRef.current.fetchedResults = records.results.length;
    }
  }, [viewedTasks]);

  useEffect(() => {
    if (!!notViewedTasks) {
      let { records } = notViewedTasks;
      notViewedTaskRef.current.totalResults = records.total;
      notViewedTaskRef.current.fetchedResults = records.results.length;
    }
  }, [notViewedTasks]);

  useEffect(() => {
    if (!!completedAndAssignedToOtherTasks) {
      let { records } = completedAndAssignedToOtherTasks;
      completedAndAssignedToOtherRef.current.totalResults = records.total;
      completedAndAssignedToOtherRef.current.fetchedResults =
        records.results.length;
    }
  }, [completedAndAssignedToOtherTasks]);

  useEffect(() => {
    if (!!notCompletedAndAssignedToOtherTasks) {
      let { records } = notCompletedAndAssignedToOtherTasks;
      notCompletedAndAssignedToOtheRef.current.totalResults = records.total;
      notCompletedAndAssignedToOtheRef.current.fetchedResults =
        records.results.length;
    }
  }, [notCompletedAndAssignedToOtherTasks]);

  useEffect(() => {
    let person =
      teamMembersData?.workspace?.all_members?.find(
        (member) => member.id === searchParams.get('user'),
      ) ?? null;

    let selectedLabels = initialData?.label.split(',');
    let labels = labelsData?.filter((label) =>
      selectedLabels.some((selectedLabel) => label.id === selectedLabel),
    );

    getAllLabelsThatHaveSubLabels(labelsData).forEach((l) => {
      l.sub_labels.map((subLabel) => {
        if (selectedLabels.includes(subLabel.id)) {
          labels.push({
            ...subLabel,
            color: l.color,
          });
        }
      });
    });

    setPersonFilter(person);
    if (!isEmpty(labels)) {
      setTextQuery(labels);
    }
    if (labelsData.length > 0 && initialData.labelGroupId !== null) {
      setLabelGroup(
        labelsData?.find((label) => label.id === initialData.labelGroupId),
      );
    }
  }, [searchParams, labelsData]);

  return {
    device,
    currentView,
    personFilter,
    showWidget,
    showSearchBar,
    setPersonFilter,
    textQuery,
    nextWeekactiveTasks,
    dueInTwoWeekactiveTasks,
    dueInThreeWeekactiveTasks,
    activeTasks,
    setShowWidget,
    setShowSearchBar,
    query,
    labelsData,
    teamMembersData,
    allUserOptions,
    activeTaskVariables,
    completedTaskVariables,
    overdueTaskVariables,
    unscheduledTaskVariables,
    inprogressTaskVariables,
    pageTitle,

    updateCache,
    updateComponent,
    completedTasks,
    fetchNextCompletedRecords,
    overdueTasks,
    fetchNextOverdueRecords,
    unscheduledTasks,
    fetchNextUnscheduledRecords,
    inProgressTasks,
    fetchNextInprogressRecords,
    changeInputSearchRoute,
    changeUserRoute,
    openNewTaskModal,
    getAllUserOptions,
    backToToday,
    changeWeekHandler,
    changeLabel,
    changeView,
    viewedTasks,
    fetchNextViewedRecords,
    notViewedTasks,
    fetchNextNotViewedRecords,
    completedAndAssignedToOtherTasks,
    fetchNextCompletedAssignedToOtherRecords,
    notCompletedAndAssignedToOtherTasks,
    fetchNextNotCompletedAssignedToOtherRecords,
    labelGroup,
    handleLableGroupChange,
    groupLabelResponse,
    setGroupLabelResponse,
    updateTaskInGroupLabelRecords,
    removeTaskFromGroupLabelRecords,
  };
};

export default useTask;
