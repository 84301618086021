import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { CARE_SPACE_ACTIONS } from './types';

const ADD_GUARDIAN_IN_PRIORITY_GROUP = queryLoader(
  'app/graphql/mutations/Care/AddGuardianInPriorityGroup.gql',
);

export function addGuardianInPriorityGroup(personConnectionId, options = {}) {
  return (dispatch) => {
    dispatch({ type: CARE_SPACE_ACTIONS });
    return client
      .mutate({
        mutation: ADD_GUARDIAN_IN_PRIORITY_GROUP,
        variables: { id: personConnectionId },
        optimisticResponse: () => {
          return {
            success: true,
            entity: {
              id: personConnectionId,
              notify: true,
              __typename: 'PersonRelation',
            },
            __typename: 'ResponseType',
          };
        },
      })
      .then(() => {
        options.onSuccess && options.onSuccess();
      });
  };
}
