import {
  EXPLORE_PAGE_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from 'app/constants';
import { SPACE_COMMENT_OFFSET_TOP } from 'app/components/CommentSection/constants';
import store from 'app/store';

export function animateSupported() {
  return typeof document.body.animate !== 'undefined';
}

export function isCenterInViewport(elem) {
  var bounding = elem.getBoundingClientRect(),
    viewportHeight =
      (window.visualViewport || {}).height ||
      window.innerHeight ||
      document.documentElement.clientHeight;

  return (
    bounding.top + bounding.height / 2 >= 0 &&
    bounding.top + bounding.height / 2 <= viewportHeight
  );
}

export function isInContainerViewPort(parent, elem) {
  let containerWidth = parent.offsetWidth,
    containerScrollLeft = parent.scrollLeft,
    containerWindowleft = window.$(parent).offset().left,
    elemOffSet = elem.offsetLeft,
    elemWidth = elem.offsetWidth,
    elemScrollLeft = window.$(elem).offset().left;
  return (
    elemOffSet + elemWidth > containerWidth ||
    containerScrollLeft > elemScrollLeft ||
    (window.$(elem).index() === 0 &&
      containerWindowleft > 0 &&
      containerWindowleft !== elemScrollLeft)
  );
}

export function isInViewport(elem, options = {}) {
  var bounding = elem.getBoundingClientRect(),
    viewportHeight =
      options.viewportHeight ||
      (window.visualViewport || {}).height ||
      window.innerHeight ||
      document.documentElement.clientHeight,
    viewportWidth =
      (window.visualViewport || {}).width ||
      window.innerWidth ||
      document.documentElement.clientWidth;

  return (
    bounding.top >= (options.offsetTop || 0) &&
    bounding.left >= 0 &&
    bounding.bottom <= viewportHeight &&
    bounding.right <= viewportWidth
  );
}

export function scrollFieldToBottom(elem) {
  setTimeout(() => {
    elem.scrollTop = elem.scrollHeight;
  }, 0);
}

export function classAddedToHTML(className) {
  return window.$('html')[0].classList.value.split(' ').includes(className);
}

export function addClassToHTML(className) {
  return window.$('html')[0].classList.add(className) || true;
}

export function removeClassFromHTML(className) {
  return window.$('html')[0].classList.remove(className) || true;
}

export function getCommentOffset(commentableType, device) {
  let { mobileDevice } = device,
    offset = -15;

  if (commentableType === 'Board') {
    let spaceOffsetForMembers = mobileDevice ? 100 : 80;
    offset -= mobileDevice
      ? SPACE_COMMENT_OFFSET_TOP.mobile + spaceOffsetForMembers
      : SPACE_COMMENT_OFFSET_TOP.nonMobile + spaceOffsetForMembers;
  } else {
    offset -= mobileDevice ? MOBILE_HEADER_HEIGHT : 0;
  }
  return offset;
}

export function getExplorePageHeaderOffset(isMobile) {
  let offset = -15;
  offset -= isMobile
    ? EXPLORE_PAGE_HEADER_HEIGHT.mobile
    : EXPLORE_PAGE_HEADER_HEIGHT.web;
  return offset;
}

export function addClassToElement(className, element) {
  return element.classList.add(className);
}

export function removeClassFromElement(className, element) {
  return element.classList.remove(className);
}

export function getSpaceSectionOffset() {
  let { mobileDevice } = store.getState().device,
    offset = -100;
  offset -= mobileDevice
    ? SPACE_COMMENT_OFFSET_TOP.mobile
    : SPACE_COMMENT_OFFSET_TOP.nonMobile;
  return offset;
}

export function isMobileAppView() {
  return !!window.$('.web-with-app').length;
}
