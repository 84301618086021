import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

export const PARTNERS_SPACE_LISTING = queryLoader(
  'app/graphql/queries/partnerSpaces/partnerSpacesListing.gql',
);

const usePartnersListing = () => {
  const { data, loading, error, refetch } = useQuery(PARTNERS_SPACE_LISTING, {
    variables: {
      afterId: null,
      beforeId: null,
      aroundId: null,
      limit: 50,
      recordId: null,
      recordType: null,
      sortQuery: 'recent',
      textQuery: null,
      type: 'PartnerSpacesListing',
    },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export default usePartnersListing;
