import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import gql from 'graphql-tag';
import scrollToElement from 'scroll-to-element';

import OsBtn from 'app/components/OsBtn';

import { flashError } from 'app/actions/flashMessage';
import { ceil, cloneDeep, now } from 'app/utils/osLodash';

const PIN_OBJECT_MUTATION = queryLoader('app/graphql/mutations/PinObject.gql');
const UNPIN_OBJECT_MUTATION = queryLoader(
  'app/graphql/mutations/UnpinObject.gql',
);

class Pin extends Component {
  getOptimisticResponseProps(pinned) {
    let props = {
      __typename: 'Response',
      success: true,
      error: '',
      entity: {
        ...this.props.obj,
        pinned,
        pinned_at: pinned ? ceil(now() / 1000) : null,
      },
    };
    return props;
  }

  scrollToContainerElement() {
    let element = this.props.containerRef;
    if (element) {
      element.scrollIntoView();
      scrollToElement(element, {
        ease: 'linear',
        offset: this.props.offset,
        duration: 1,
      });
    }
  }

  getFragment(pinned) {
    let fragmentVarName = pinned ? 'pinObject' : 'unpinObject',
      { __typename: entityType } = this.props.obj;
    return gql`
      fragment ${fragmentVarName} on ${entityType} {
        __typename
        id
        pinned
      }
    `;
  }

  handlePinObject = () => {
    this.props
      .pinObjectMutation({
        variables: {
          entityId: this.props.obj.id,
          entityType: this.props.obj.__typename,
        },
      })
      .then(({ data }) => {
        if (data.pinObject.success) {
          this.props.refetchOnSuccess
            ? this.props.refetchOnSuccess(data.pinObject.entity.id)
            : this.scrollToContainerElement();
        } else {
          // Open general modal here.
          let error = data.pinObject.error;
          this.props.flashError(error || 'CREATE_ERROR', !error.length);
        }
      });
  };

  handleUnpinObject = () => {
    this.props
      .unpinObjectMutation({
        variables: {
          entityId: this.props.obj.id,
          entityType: this.props.obj.__typename,
        },
      })
      .then(({ data }) => {
        if (!data.unpinObject.success) {
          // Open general modal here.
          let error = data.unpinObject.error;
          this.props.flashError(error || 'CREATE_ERROR', !error.length);
        }
      });
  };

  render() {
    let { pinned } = this.props.obj,
      text = pinned ? 'Unpin Post' : 'Pin Post',
      extraClass = this.props.extraClass || '';

    extraClass += pinned ? ' active ' : '';

    return (
      <OsBtn
        name='BtnIcon'
        icon='pin'
        text={text}
        extraClass={extraClass}
        onClick={
          this.props.obj.pinned ? this.handleUnpinObject : this.handlePinObject
        }
        associatedEntity={this.props.obj}
      />
    );
  }
}

Pin = connect(({ currentUser }) => ({ currentUser }), { flashError })(Pin);
Pin = compose(
  graphql(PIN_OBJECT_MUTATION, { name: 'pinObjectMutation' }),
  graphql(UNPIN_OBJECT_MUTATION, { name: 'unpinObjectMutation' }),
)(Pin);
Pin.defaultProps = {
  offset: 0,
};
export default Pin;
