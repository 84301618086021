import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo, graphql } from '@apollo/client/react/hoc';

import { FORM_ERROR } from 'final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import CountrySelect from 'app/components/Shared/CountrySelect';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import PersonHeaderInModal from 'app/components/PatientForm/PersonHeaderInModal';
import StateSelect from 'app/components/Shared/StateSelect';
import RecordsView from 'app/components/RecordsView';

import {
  closeInfoModal,
  openInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';
import { setActiveResourcePatient } from 'app/actions/activeResource';
import { closeContactPartyModal } from 'app/actions/contactPartyModal';
import { translate } from 'app/actions/flashMessage';
import { fillForm } from './FormHelper';

import {
  composeValidators,
  emailMustBeValid,
  emailMustNotEqualToUserEmail,
  fieldRequired,
  removeSpecialCharactersFromPhoneNumber,
} from 'app/utils/validationHelper.js';
import { camelCase, cloneDeep, keys, pick } from 'app/utils/osLodash';
import { capitalize } from 'app/utils/stringHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

const GUARDIAN_DETAIL_QUERY = queryLoader(
  'app/graphql/queries/Care/GuardianDetailQuery.gql',
);
const PERSON_DETAILS = queryLoader(
  'app/graphql/Care/Queries/People/PersonDetail.gql',
);
const SHARE_WITH_GUARDIAN_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithGuardian.gql',
);
const UPDATE_GURAIDAN_MUTATION = queryLoader(
  'app/graphql/mutations/Care/UpdateGuardianInformation.gql',
);

class GuardianForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.returnErrors = {};
  }

  defaultState() {
    return {
      requestInProgress: false,
      modalOpened: false,
      contact: {},
      searchText: '',
      autoFill: true,
    };
  }

  componentDidMount() {
    if (this.isEditView()) this.fetchPatientData();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.contactPartyModal.open &&
      this.props.contactPartyModal.open &&
      this.isEditView()
    )
      this.fetchPatientData();
  }

  careSpaceId() {
    return this.props.contactPartyModal.space.nice_id;
  }

  emailMustNotEqualToCurrentUserEmail = (value) => {
    if (!this.isResponseParty())
      return emailMustNotEqualToUserEmail(value, this.props.currentUser.graph);
  };

  fetchPatientData() {
    this.setState({ fetchingGuardianInformation: true });
    this.props.client
      .query({
        query: GUARDIAN_DETAIL_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          careSpaceId: this.careSpaceId(),
          personConnectionId: this.personConnectionId(),
        },
      })
      .then(({ data }) => {
        let contactParty = data.person_connection.related_person,
          personAttributes = [
            'first_name',
            'last_name',
            'preferred_name',
            'email',
            'country_code',
            'phone_number',
            'gender',
          ],
          formData = pick(contactParty, ...personAttributes),
          values = { address: {} },
          addressAttributes = [
            'address_line_1',
            'address_line_2',
            'city',
            'country_id',
            'id',
            'state_id',
            'zipcode',
          ];
        formData['address'] = pick(
          contactParty.address_object,
          ...addressAttributes,
        );
        formData = {
          ...formData,
          ...pick(data.person_connection, 'relation'),
        };
        keys(formData).forEach(
          (key) => (values[camelCase(key)] = formData[key]),
        );
        values.address = {};
        values.relatedPersonId = data.person_connection.related_person.id;
        keys(formData.address).forEach(
          (key) => (values.address[camelCase(key)] = formData.address[key]),
        );
        setTimeout(() => this.form.initialize(values), 100);
        this.setState({
          fetchingGuardianInformation: false,
          contact: contactParty,
        });
      });
  }

  fillContactDetails(value) {
    this.setState({ requestInProgress: true });
    this.props.client
      .query({
        query: PERSON_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          id: value.add_contacts,
        },
      })
      .then(({ data }) => {
        let person = data.person,
          formData = {};
        if (person) {
          formData = fillForm(formData, person);
          formData['phoneNumber'] = person.phone_contact_number;
        }
        this.form.initialize(formData);
        this.setState({
          searchText: '',
          autoFill: false,
          requestInProgress: false,
        });
      });
  }

  closeModal = () => {
    this.setState(this.defaultState());
    this.props.closeContactPartyModal();
  };

  sanitizeParams(values) {
    let params = cloneDeep(values);
    params.firstName = capitalize(values.firstName);
    params.lastName = capitalize(values.lastName);
    params.preferredName = capitalize(values.preferredName);
    params.phoneNumber = removeSpecialCharactersFromPhoneNumber(
      values.phoneNumber,
    );
    params.personConnectionId = this.personConnectionId();
    params.careSpaceId = this.careSpaceId();
    params.address = params.address || {};
    params.addressSameAsPatient = !!params.addressSameAsPatient;
    return params;
  }

  shareWithGuardian = (careSpaceId, personConnectionId) => {
    this.props.updateInfoModalOptions({ primaryCtaRequestInProgress: true });
    this.props
      .shareWithGuardian({
        variables: {
          careSpaceId,
          personConnectionId,
          reshare: false,
        },
      })
      .then(({ data }) => {
        this.props.closeInfoModal();
      });
  };

  showInfoCopyModalIfRequired(updatedEmail) {
    if (!this.isResponseParty()) {
      let { contact } = this.state;
      if (contact.user_onboarded) {
        this.props.openInfoModal('care_space', 'person_changes_success', {
          contentInterpolations: { name: contact.name },
        });
      } else if (this.isEmailChanged(updatedEmail)) {
        this.props.openInfoModal('care_space', 'email_changed_successfully', {
          contentInterpolations: { name: contact.name },
          onSuccess: this.shareWithGuardian.bind(
            this,
            this.careSpaceId(),
            +this.personConnectionId(),
          ),
          closeModalNotRequiredOnPrimaryClick: true,
        });
      }
    }
  }

  isEmailChanged(updatedEmail) {
    return updatedEmail && this.state.contact.email !== updatedEmail;
  }

  onSubmit = (values) => {
    this.setState({ requestInProgress: true });
    return this.props
      .updateGuardian({
        variables: {
          ...this.sanitizeParams(values),
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        if (data.updateGuardian.success) {
          this.isEditView() && this.showInfoCopyModalIfRequired(values.email);
          this.closeModal();

          this.props.setActiveResourcePatient(
            data.updateGuardian.entity.owner.patient,
          );
        } else {
          data.updateGuardian.errors.forEach((obj) => {
            if (
              [
                'first_name',
                'last_name',
                'preferred_name',
                'phone_number',
                'email',
              ].includes(obj.field)
            ) {
              this.returnErrors[camelCase(obj.field)] = obj.message;
            }
          });
          if (Object.keys(this.returnErrors).length) {
            console.log(this.returnErrors);
            return this.returnErrors;
          } else {
            return { [FORM_ERROR]: translate('CREATE_ERROR') };
          }
        }
      });
  };

  isOpen() {
    return this.props.contactPartyModal.open;
  }

  onCountryChange = (phoneNumberFieldName, field, value) => {
    this.setFieldValue(field, value);
    this.setFieldValue(phoneNumberFieldName, '');
  };

  setFieldValue = (field, value) => {
    this.form.change(field, value);
  };

  getGuardianFieldValue(property) {
    return this.form.getState().values[property];
  }

  getCountryId() {
    return this.form.getState().values?.address?.countryId;
  }

  renderAddressFormIfRequired() {
    let commonProps = { labelClassName: 'not-mandatory' };
    if (!this.form.getState().values?.addressSameAsPatient)
      return (
        <>
          <div className='col-7-70 col-12'>
            <Field
              name='address.addressLine1'
              osType='input'
              autoComplete='off'
              component={OsField}
              label='Street address'
              placeholder='e.g. 100 Broadway st.'
              {...commonProps}
            />
          </div>
          <div className='col-3-30 col-12 '>
            <Field
              name='address.addressLine2'
              osType='input'
              autoComplete='off'
              component={OsField}
              label='Apt, suite etc. (optional)'
              placeholder='e.g. Apt 100'
              {...commonProps}
            />
          </div>
          <div className='col-3-30 col-12'>
            <Field
              name='address.city'
              osType='input'
              autoComplete='off'
              component={OsField}
              label='City'
              placeholder='e.g. Minneapolis'
              {...commonProps}
            />
          </div>
          <div className='col-3-30 col-12 os-menu-drop only-option-text'>
            <Field
              name='address.stateId'
              component={StateSelect}
              label='State'
              countryId={this.getCountryId()}
              placeholder='Select'
              {...commonProps}
            />
          </div>
          <div className='col-2-20 col-12'>
            <Field
              name='address.zipcode'
              osType='input'
              autoComplete='off'
              component={OsField}
              label='Zip code'
              placeholder='e.g. 55408'
              {...commonProps}
            />
          </div>
          <div className='col-2-20 col-12 os-menu-drop only-option-text'>
            <Field
              name='address.countryId'
              component={CountrySelect}
              label='Country'
              placeholder='Select'
              {...commonProps}
            />
          </div>
        </>
      );
  }

  getCountryCodeFieldProps() {
    if (!this.isEditView()) return { setFormInitialValue: this.setFieldValue };
  }

  renderGuardianForm = () => {
    let isResponseParty = this.isResponseParty();
    return (
      <div className='row guardian_row row-col-space-16'>
        <div className='col-3-30 col-12 patient-name-field'>
          <Field
            name='firstName'
            component={OsField}
            osType='input'
            autoComplete='off'
            label='First name'
            placeholder='e.g. Jane'
            validate={fieldRequired}
          />
        </div>
        <div className='col-3-30 col-12 patient-name-field'>
          <Field
            name='lastName'
            autoComplete='off'
            component={OsField}
            osType='input'
            label='Last name'
            placeholder='e.g. Jones'
            validate={fieldRequired}
          />
        </div>
        <div className='col-4-40 col-12 patient-name-field'>
          <label className='not-mandatory'>Preferred name</label>
          <Field
            name='preferredName'
            autoComplete='off'
            component={OsField}
            osType='input'
            placeholder='e.g. Jones'
          />
        </div>

        <div className='col-3-30 col-12 os-menu-drop'>
          <Field
            osType='selectInput'
            component={OsField}
            label='Relationship to patient'
            name='relation'
            onChange={this.setFieldValue}
            validate={fieldRequired}
            async={true}
            queryType='RelatedPersonTypes'
            perPage={150}
            selectedValueNameRequired={true}
            placeholder='Select'
          />
        </div>

        <div className='col-3-30 col-12 os-menu-drop'>
          <label className='not-mandatory'>Cell phone number</label>
          <div className='cell-row'>
            <Field
              name='countryCode'
              osType='country_code_select'
              component={OsField}
              {...this.getCountryCodeFieldProps()}
              onChange={this.onCountryChange.bind(this, 'phoneNumber')}
              isDisabled={isResponseParty}
            />
            <Field
              name='phoneNumber'
              autoComplete='off'
              component={OsField}
              {...this.getPhoneNumberProps('countryCode')}
              disabled={isResponseParty}
            />
          </div>
        </div>
        <div className='col-4-40 col-12'>
          <Field
            name='email'
            component={OsField}
            autoComplete='off'
            osType='input'
            label='Email'
            placeholder='name@gmail.com'
            labelClassName='not-mandatory'
            validate={composeValidators(
              fieldRequired,
              emailMustBeValid,
              this.emailMustNotEqualToCurrentUserEmail,
            )}
            disabled={isResponseParty}
          />
        </div>

        <div className='col-3-30 switch-box notify-with-label'>
          <label className='not-mandatory'>Address</label>
          <Field
            component={OsField}
            className='os-custom-checkbox'
            label='Same as patient'
            osType='checkbox'
            name='addressSameAsPatient'
            id='address_same_as_patient'
            isChecked={this.getGuardianFieldValue('addressSameAsPatient')}
          />
        </div>
        {this.renderAddressFormIfRequired()}
      </div>
    );
  };

  onChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  renderSearch() {
    return (
      <div className='nav-search-container'>
        <label className='search-label-group not-mandatory'>
          <div className='mb-2'>
            Search for existing contacts<span className='level-6'>*</span>
          </div>
          <OsField
            osType='input'
            type='text'
            placeholder="Contact 's full name or email"
            className={'w-100'}
            name='search'
            onChange={this.onChange}></OsField>
        </label>
      </div>
    );
  }

  showExistingContacts() {
    let searchText = this.state.searchText,
      contacts = this.props.contactPartyModal.space.owner.patient,
      addedContacts = [];
    addedContacts.push({ id: contacts?.id });
    contacts?.person_connections?.forEach((person) =>
      addedContacts.push({ id: person.related_person.id }),
    );
    if (searchText) {
      return (
        <RecordsView
          type='AllUsersAddedByClinic'
          textQuery={searchText.trim()}
          queryType='USERS_BY_CLINIC'
          cardProps={{
            selectionMode: true,
            size: 'small',
            isPatient: false,
            name: 'add_contacts',
          }}
          cardGridIdentifier={'PatientModal:Perosn'}
          recordsNotToBeIncludes={addedContacts}
        />
      );
    }
  }

  renderGuardians() {
    if (this.state.autoFill && !this.isEditView()) {
      return (
        <div className='content-with-search'>
          {this.renderSearch()}
          {this.showExistingContacts()}
        </div>
      );
    } else {
      return Array(this.state.guardianCount)
        .fill(0)
        .map((_, index) => {
          return this.renderGuardianForm(index);
        });
    }
  }

  isEditView() {
    return this.personConnectionId();
  }

  getPhoneNumberProps(countryCodeFieldName) {
    let countryCodeValue = this.getGuardianFieldValue(countryCodeFieldName);
    if (countryCodeValue === '1') {
      return { osType: 'formatted_input' };
    } else {
      return { osType: 'input' };
    }
  }

  personConnectionId() {
    return this.props.contactPartyModal.contactParty?.person_connection_id;
  }

  getIntialValues() {
    if (this.isEditView()) {
      return {};
    } else {
      return { addressSameAsPatient: true };
    }
  }

  fillDataFromSearch() {
    let text = this.state.searchText,
      email = '',
      name = '',
      formData = {};
    !emailMustBeValid(text) ? (email = text) : (name = text.split(' '));
    formData['firstName'] = name[0];
    formData['lastName'] = name[1];
    formData['email'] = email;
    this.form.initialize(formData);
    this.setState({ autoFill: false });
  }

  isEmailOrPhonePresent(values) {
    return values.email || values.phoneNumber;
  }
  initialContactValueHasChanged(values) {
    return (
      this.state.contact.email !== values.email ||
      this.state.contact.phoneNumber !== values.phoneNumber
    );
  }
  openContactInfoWarningModal() {
    this.props.openInfoModal(
      'general',
      'patient_form_warning_on_email_or_phone_not_present',
    );
  }

  submitConfirmation = (values) => {
    if (this.isEditView()) {
      if (
        this.initialContactValueHasChanged(values) &&
        !this.isEmailOrPhonePresent(values)
      ) {
        this.openContactInfoWarningModal();
        return;
      }
    } else {
      if (!this.isEmailOrPhonePresent(values)) {
        this.openContactInfoWarningModal();
        return;
      }
    }

    if (this.isEditView()) {
      let { contact } = this.state,
        emailChanged = this.isEmailChanged(values.email),
        commonProps = {
          contentInterpolations: { name: contact.name },
          onSuccess: this.onSubmit.bind(this, values),
        };
      if (contact.user_onboarded || this.isResponseParty()) {
        return this.onSubmit(values);
      } else {
        if (emailChanged) {
          this.props.openInfoModal('care_space', 'email_change_confirmation', {
            ...commonProps,
          });
        } else {
          this.props.openInfoModal(
            'care_space',
            'patient_detail_change_confirmation',
            { ...commonProps },
          );
        }
      }
    } else {
      if (!this.state.autoFill) {
        return this.onSubmit(values);
      } else if (this.state.autoFill && this.state.searchText) {
        this.fillDataFromSearch();
      }
    }
  };

  getBtnText(values) {
    if (this.state.autoFill && !this.isEditView()) {
      if (values.add_contacts) {
        return 'Add To Contact';
      } else {
        return 'Add Contact Directly';
      }
    } else {
      return 'Save Information';
    }
  }

  onSubmitClick = (event) => {
    if (!this.state.autoFill) {
      this.submitForm(event);
    }
  };

  addDirectly = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.fillDataFromSearch();
  };

  renderButton(values) {
    let commonProps = {
      htmlTag: 'button',
      text: this.getBtnText(values),
      extraClass: 'web-view-btn',
      disabled: this.state.requestInProgress,
      loaderRequired: this.state.requestInProgress,
    };
    if (this.state.autoFill && !this.isEditView()) {
      if (values.add_contacts) {
        return (
          <OsBtn
            type='button'
            name='BtnPrimary'
            onClick={(e) => this.fillContactDetails(values)}
            {...commonProps}
          />
        );
      } else {
        return (
          <OsBtn
            type='button'
            name='BtnPrimary'
            onClick={this.addDirectly}
            {...commonProps}
          />
        );
      }
    } else {
      return (
        <OsBtn
          type='submit'
          name='BtnPrimary'
          onClick={this.onSubmitClick}
          {...commonProps}
        />
      );
    }
  }

  renderForm() {
    return (
      <Form
        onSubmit={this.submitConfirmation}
        initialValues={this.getIntialValues()}
        keepDirtyOnReinitialize={true}
        render={(props) => {
          this.returnErrors = {};
          this.form = props.form;
          this.submitForm = props.handleSubmit;
          this.formValues = props.values;
          return (
            <form
              name='addNewPatient'
              className='add_new_patient_form'
              onSubmit={this.submitForm}>
              <Modal.Body className=''>
                {props.submitError && (
                  <div className='form-error error'>{props.submitError}</div>
                )}
                <div className='guardian-row-height'>
                  {this.renderGuardians()}
                </div>
              </Modal.Body>
              <Modal.Footer className='justify-content-end'>
                {this.renderButton(this.formValues)}
              </Modal.Footer>
            </form>
          );
        }}
      />
    );
  }

  isResponseParty() {
    let space = this.props.contactPartyModal.space;
    return (
      isCareWorkspaceView() &&
      space &&
      !space.is_author_or_editor &&
      space.is_author_or_member
    );
  }

  renderHeader() {
    if (this.isEditView()) {
      let { contactParty, space } = this.props.contactPartyModal;
      return <PersonHeaderInModal obj={contactParty} space={space} />;
    } else {
      return <h4 className='modal-title'>Add new contact</h4>;
    }
  }

  render() {
    let open = this.isOpen();
    if (open) {
      return (
        <Modal
          show={open}
          animation={false}
          dialogClassName='add-new-guardian-modal'
          backdropClassName='modal-backdrop-custom backdrop-message-modal'>
          <Modal.Header className='border-0 modal-header py-0 m-0 d-flex align-items-center mb-4'>
            {this.renderHeader()}
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn r-0'
              icon='close'
              label='Close modal'
              onClick={this.closeModal}
            />
          </Modal.Header>
          {this.state.fetchingGuardianInformation && (
            <div className='overlay-modal'>
              <i className='fa fa-spinner fa-spin' />
            </div>
          )}
          {this.renderForm()}
        </Modal>
      );
    } else {
      return null;
    }
  }
}

GuardianForm = graphql(SHARE_WITH_GUARDIAN_MUTATION, {
  name: 'shareWithGuardian',
})(GuardianForm);
GuardianForm = graphql(UPDATE_GURAIDAN_MUTATION, { name: 'updateGuardian' })(
  GuardianForm,
);
GuardianForm = connect(
  ({ contactPartyModal, currentUser, workspace }) => ({
    contactPartyModal,
    currentUser,
    workspace,
  }),
  {
    closeInfoModal,
    closeContactPartyModal,
    openInfoModal,
    updateInfoModalOptions,
    setActiveResourcePatient,
  },
)(GuardianForm);
GuardianForm = withApollo(GuardianForm);
GuardianForm = withRouter(GuardianForm);
export default GuardianForm;
