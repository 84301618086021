import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import ConnectToSupport from 'app/components/ConnectToSupport';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';

import { closeUpgradeModal } from 'app/actions/upgrade';
import { openSubscriptionModal } from 'app/actions/subscriptionModal';

class UpgradeConfirmationView extends Component {
  closeUpgradeModal = (reset = true) => {
    this.props.closeUpgradeModal(reset);
  };

  reviewPlans = () => {
    this.closeUpgradeModal(false);
    this.props.openSubscriptionModal();
  };

  componentWillReceiveProps(nextProps) {
    let open = nextProps.upgrade.open,
      meta = nextProps.upgrade.meta || {};

    if (open && !meta.skipConfirmation) {
      document.body.classList.add('upgrade');
    } else {
      document.body.classList.remove('upgrade');
    }

    if (open && meta.skipConfirmation) {
      this.reviewPlans();
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.closeUpgradeModal();
    }
  }

  isVisible() {
    return (
      this.props.upgrade.open &&
      !(this.props.upgrade.meta || {}).skipConfirmation
    );
  }

  onTrialExpired() {
    return (
      this.props.currentUser.graph.active_subscription?.plan?.kind ===
      'trial_expired'
    );
  }

  render() {
    let onTrialExpired = this.onTrialExpired(),
      text = onTrialExpired
        ? 'Would you like to upgrade to have more features available?'
        : 'Contact support for more details';

    return (
      <Modal
        show={this.isVisible()}
        onHide={this.closeUpgradeModal}
        dialogClassName='os-grid-modal update-plan-limit-modal'
        animation={false}
        backdropClassName='modal-backdrop-custom'>
        <div className='modal-header border-0 px-0 pt-0 mb-0'>
          <h4 className='modal-title lh-normal fw-300'>
            You've reached your plan limit
          </h4>

          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-upgrade-feature'
            icon='close'
            label='Close upgrade modal'
            onClick={this.closeUpgradeModal}
          />
        </div>

        <div className='modal-body text-center'>
          <Icon name='warning' className='v-align-top' />

          <div className='my-4'>{text}</div>
        </div>

        <div className='text-right pt-3 pb-2'>
          {onTrialExpired && (
            <OsBtn
              name='BtnSecondary'
              text="No, I'm good"
              extraClass='me-2'
              onClick={this.closeUpgradeModal}
            />
          )}
          {onTrialExpired && (
            <OsBtn
              name='BtnPrimary'
              text='Yes, please'
              onClick={this.reviewPlans}
            />
          )}
          {!onTrialExpired && (
            <ConnectToSupport
              viewType='button'
              text='Contact Support'
              associatedEntity={this.props.currentUser.graph}
            />
          )}
        </div>
      </Modal>
    );
  }
}

UpgradeConfirmationView = withRouter(UpgradeConfirmationView);
UpgradeConfirmationView = connect(
  ({ currentUser, upgrade }) => ({ currentUser, upgrade }),
  { closeUpgradeModal, openSubscriptionModal },
)(UpgradeConfirmationView);
export default UpgradeConfirmationView;
