import { useParams, useSearchParams } from 'react-router-dom';
import RecordsView from 'app/components/RecordsView';
import { SPACE_ACTIVITY_FEED_FILTERS } from 'app/components/BoardView/constants';

const CareSpaceActivityLogs = ({ entity }) => {
  const { id, feedType } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <div className='container-xl px-52 '>
      <RecordsView
        type='CareSpaceActivities'
        perPage={25}
        idQuery={id}
        sourceObject={entity}
        queryType='COMMENTS_ACTIVITY_LISTING'
        idType={SPACE_ACTIVITY_FEED_FILTERS.feed_type_url_mapper[feedType]}
        textQuery={searchParams.get('filter')}
      />
    </div>
  );
};

export default CareSpaceActivityLogs;
