import React, { Component } from 'react';

import RecordsView from 'app/components/RecordsView';

class EntityListing extends Component {
  state = {};

  queryParams() {
    return {
      infiniteScroll: this.props.infiniteScroll,
      loadAllOnSeeMore: this.props.loadAllOnSeeMore,
      perPage: this.props.perPage,
      type: this.props.type,
      queryType: this.props.queryType,
      setCollectionTotal: this.setCollectionTotal,
      collectionTotal: this.state.recordsCount,
      size: this.props.size,
      showMoreNotRequired:
        this.props.showMoreNotRequired || this.props.seeAllLink,
      seeAllLink: this.props.seeAllLink,
      idQuery: this.props.idQuery,
      idType: this.props.idType,
      textQuery: this.props.textQuery,
    };
  }

  setCollectionTotal = (recordsCount) => {
    this.setState({ recordsCount });
    this.props.setCollectionTotal(recordsCount);
  };

  getCountIfRequired() {
    return (
      this.props.countRequired &&
      this.state.recordsCount &&
      `(${this.state.recordsCount})`
    );
  }

  render() {
    let className = `${this.props.extraClass} f-course-cmn-block`;
    className += this.state.recordsCount === 0 ? ' d-none' : '';

    return (
      <section className={className}>
        <div className={this.props.childContainer}>
          {this.props.text && (
            <div className='head-2 space-btm-32'>{`${this.props.text}${
              this.getCountIfRequired() || ''
            }`}</div>
          )}
          <RecordsView
            cardGridIdentifier={'CareSpaceColumn:CardCols'}
            {...this.queryParams()}
          />
        </div>
      </section>
    );
  }
}

EntityListing.defaultProps = {
  showMoreNotRequired: false,
  loadAllOnSeeMore: false,
  infiniteScroll: false,
  perPage: 3,
  extraClass: 'f-course-block',
  countRequired: false,
  setCollectionTotal: () => {},
};

export default EntityListing;
