import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import AppointmentVisibilityButton from './AppointmentVisibilityButton';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeleteAppointment from './DeleteAppointment';
import HTMLContentContainer from 'app/components/Shared/HTMLContentContainer';
import RenderCards from 'app/components/Cards/RenderCards';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { timeWithFormat } from 'app/utils/timeHelper';
import { getAppointmentIcon } from 'app/components/CaseLeftSection/helpers';
import { getProcessedAppliancesFromActions } from 'app/utils/appointmentHelper';
import {
  humanize,
  isHTMLContentPresent,
  parseNonHtmlContent,
} from 'app/utils/stringHelper';

import {
  setActiveApplianceLogCardId,
  setSelectedAppointment,
} from 'app/actions/caseDetailInfo';

const APPLIANCE_POSSIBLE_ACTIONS = ['added', 'removed', 'adjusted', 'replaced'];

export default class AppointmentBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.forceOpen,
      isActiveToolsOpen: false,
      isAttachmentsOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.forceOpen) {
      this.props.caseDetailInfo.activeCardId
        ? setTimeout(this.scrollToCardFirstSimilarCard, 1000)
        : setTimeout(this.scrollElementIntoView, 500);
    }
  }

  componentDidUpdate(prevProps) {
    let {
        selectedRightSideAppointment: prevRightAppointment,
        selectedAppointment: prevSelectedAppointment,
      } = prevProps.caseDetailInfo,
      { selectedRightSideAppointment, selectedAppointment } =
        this.props.caseDetailInfo,
      anyAppointmentChanged =
        selectedRightSideAppointment?.id !== prevRightAppointment?.id ||
        prevSelectedAppointment?.id !== selectedAppointment?.id;

    if (this.props.forceOpen && !prevProps.forceOpen && anyAppointmentChanged) {
      setTimeout(() => {
        this.setState({ active: true }, this.scrollElementIntoView);
      }, 500);
    }
    if (
      !this.props.forceOpen &&
      prevProps.forceOpen &&
      this.state.active &&
      anyAppointmentChanged
    ) {
      this.setState({ active: false });
    }
  }

  scrollToCardFirstSimilarCard = () => {
    if (this.parentContainer) {
      let card = this.parentContainer.querySelector(
        `[data-card-identifier="CaseApplianceLogCard:${this.props.caseDetailInfo.activeCardId}"]`,
      );
      card && card.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  scrollElementIntoView = () => {
    if (this.parentContainer && this.parentContainer.offsetTop) {
      let containerId =
          this.props.device.ipadLandscape || this.props.device.desktop
            ? 'appointment-scroll-container-web'
            : 'appointment-scroll-container-ipad',
        scrollableContainer = document.getElementById(containerId);

      if (scrollableContainer)
        scrollableContainer.scrollTop = this.parentContainer.offsetTop;
    }
  };

  getAppointmentIconName() {
    return getAppointmentIcon(this.props.appointment);
  }

  onIconClick = () => {
    // CaseAppointmentsButtons logic required here.
    // this.props.setSelectedAppointment(this.props.appointment)
  };

  getAppointmentIcon() {
    return (
      <OrthoIcon
        name={this.getAppointmentIconName()}
        defaultClass='pe-2 appt_icon'
        dataHoverNotRequired={true}
        onClick={this.onIconClick}
      />
    );
  }

  toggleActive = () => {
    this.props.setActiveApplianceLogCardId();
    if (this.anyRecordsPresent()) this.setState({ active: !this.state.active });
  };

  renderHeading(appointment) {
    return `${timeWithFormat(appointment.date, 'MMM D, YYYY')}`;
  }

  renderAppointmentNotes(appointment) {
    let content = parseNonHtmlContent(appointment.notes);
    if (isHTMLContentPresent(appointment.notes))
      return (
        content && (
          <div className='summary-text-block'>
            <HTMLContentContainer content={appointment.notes} />
          </div>
        )
      );
  }

  onLogCardClick = (log) => {
    this.props.setActiveApplianceLogCardId(log.id);
  };

  renderAppliances(appliances, props = {}) {
    return (
      <RenderCards
        objects={appliances.map((appliance) => ({ ...appliance, ...props }))}
        orderRowWise={false}
        goToPage={false}
        size='extra-small'
        cardGridIdentifier='CsAppointmentSummary:CardCol'
        cardsRowClass=''
        onCardClick={this.onLogCardClick}
      />
    );
  }

  toogleActiveTools = () => {
    this.setState({ isActiveToolsOpen: !this.state.isActiveToolsOpen });
  };

  renderActiveAppliancesBody() {
    if (this.state.isActiveToolsOpen) {
      const { activeActions, groupedVariantLogs } = this.props;
      return this.renderAppliances(
        getProcessedAppliancesFromActions(activeActions, groupedVariantLogs),
      );
    }
  }

  renderAttachmentCards() {
    if (this.state.isAttachmentsOpen) {
      return (
        <RenderCards
          objects={this.props.appointment.attachments}
          orderRowWise={false}
          goToPage={false}
          size='extra-small'
          cardGridIdentifier='CsAppointmentSummary:CardCol'
          cardsRowClass=''
        />
      );
    }
  }

  toggleAttachmentBlock = () => {
    this.setState({ isAttachmentsOpen: !this.state.isAttachmentsOpen });
  };

  renderAttachments() {
    let { attachments } = this.props.appointment;
    if (attachments.length > 0)
      return (
        <div className=''>
          <div
            onClick={this.toggleAttachmentBlock}
            className='cursor-pointer active-tool-btn text-uppercase'>
            <span>
              {this.state.isAttachmentsOpen
                ? 'HIDE attached documents'
                : 'Show attached documents'}
            </span>
            <OrthoIcon
              name={
                this.state.isAttachmentsOpen ? 'chevron-up' : 'chevron-down'
              }
              dataHoverNotRequired={true}
              defaultClass='ps-2 active-view-btn'
            />
          </div>
          {this.state.isAttachmentsOpen && this.renderAttachmentCards()}
        </div>
      );
  }

  renderActiveTools() {
    return (
      <div className=''>
        <div
          onClick={this.toogleActiveTools}
          className='cursor-pointer active-tool-btn'>
          {this.state.isActiveToolsOpen
            ? 'HIDE ACTIVE MECHANICS'
            : 'VIEW ACTIVE MECHANICS'}
          <OrthoIcon
            name={this.state.isActiveToolsOpen ? 'chevron-up' : 'chevron-down'}
            dataHoverNotRequired={true}
            defaultClass='ps-2 active-view-btn'
          />
        </div>
        {this.renderActiveAppliancesBody()}
      </div>
    );
  }

  getAppliances(type) {
    const { appointmentDetailHash, groupedVariantLogs } = this.props,
      actions = appointmentDetailHash.appliancesHash[`${type}Appliances`];

    return getProcessedAppliancesFromActions(actions, groupedVariantLogs);
  }

  renderAppliancesWithType = (type) => {
    return this.renderAppliances(this.getAppliances(type), {
      type,
      hideToolDuration: true,
    });
  };

  renderAllTypesOfAppliances() {
    return APPLIANCE_POSSIBLE_ACTIONS.map(this.renderAppliancesWithType);
  }

  renderBlockBody(appointment) {
    return (
      <>
        {this.renderAppointmentNotes(appointment)}
        {this.renderAllTypesOfAppliances()}
        {this.props.activeActions?.length > 0 && this.renderActiveTools()}
        {this.renderAttachments()}
      </>
    );
  }

  anyRecordsPresent() {
    const { appointmentDetailHash } = this.props,
      { notes, attachments } = this.props.appointment;
    return (
      appointmentDetailHash.recordsPresent ||
      this.props.activeActions.length > 0 ||
      notes ||
      attachments.length > 0
    );
  }

  renderKind(appointment) {
    return (
      <div>
        {appointment.label && humanize(appointment.label)}
        {appointment.label && appointment.kind && ' | '}
        {appointment.kind && humanize(appointment.kind)}
      </div>
    );
  }

  isAppointmentSelected() {
    let inFocusAppointment =
      this.props.caseDetailInfo.selectedRightSideAppointment ||
      this.props.caseDetailInfo.selectedAppointment;
    return inFocusAppointment?.id === this.props.appointment.id;
  }

  openEditModal = () => {
    this.props.onEditClick(this.props.appointment);
  };

  renderEditButton() {
    return (
      <OsBtn
        key='edit'
        name='BtnIcon'
        text='Edit'
        extraClass='list-button'
        icon='edit'
        onClick={this.openEditModal}
        associatedEntity={this.props.appointment}
      />
    );
  }

  renderVisibleToPatientButton() {
    return <AppointmentVisibilityButton appointment={this.props.appointment} />;
  }

  renderDeleteButton() {
    return (
      <DeleteAppointment
        appointment={this.props.appointment}
        kaseId={this.props.kase.nice_id}
      />
    );
  }

  renderCustomDropdown() {
    if (this.props.kase.can_update && this.props.isCareSpaceView) {
      return (
        <CustomDropdown name='appointment_actions'>
          {this.renderEditButton()}
          {this.renderVisibleToPatientButton()}
          {this.renderDeleteButton()}
        </CustomDropdown>
      );
    }
  }

  render() {
    const { appointment } = this.props,
      activeClass = this.isAppointmentSelected() ? 'active' : '',
      key = appointment.id,
      selectedClass = this.state.active ? 'chevron-down' : 'chevron-right';

    return (
      <>
        <div ref={(div) => (this.parent = div)}></div>
        <Accordion
          activeKey={this.state.active ? key : null}
          className={'appointment-card'}
          ref={(parent) => {
            this.parentContainer = parent;
          }}>
          <Card>
            <Accordion.Button
              as={Card.Header}
              className={activeClass}
              key={key}
              eventKey={key}
              onClick={this.toggleActive}>
              <span className='cs-appt-serial-no'>{this.props.serialNo}</span>
              {this.getAppointmentIcon()}
              <span className='cs-appt-head'>
                {this.renderHeading(appointment)} <br />
                <small className=''>{this.renderKind(appointment)}</small>
              </span>
              <span className='dot-with-chevron'>
                {this.renderCustomDropdown()}
                {this.anyRecordsPresent() && (
                  <OrthoIcon
                    defaultClass='cs-chev-icon-right ms-auto'
                    name={selectedClass}
                  />
                )}
              </span>
            </Accordion.Button>
            <Accordion.Collapse eventKey={key}>
              <Card.Body className=''>
                {this.renderBlockBody(appointment)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

AppointmentBlock = connect(
  ({ caseDetailInfo, device }) => ({ caseDetailInfo, device }),
  { setActiveApplianceLogCardId, setSelectedAppointment },
)(AppointmentBlock);
