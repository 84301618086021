import React, { Component } from 'react';
import { connect } from 'react-redux';
import { displayEntityName } from 'app/utils/entitiesHelper';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Accordion } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { openTaskModal } from 'app/actions/taskModal';

class TaskWidget extends Component {
  onSeeAllLink = () => {
    this.props.navigate('/tasks');
  };

  renderSeeAllLink() {
    return (
      <OsBtn
        name='BtnSecondary'
        extraClass='no-text tag-view-more pointer fs-12'
        text='see more'
        onClick={this.onSeeAllLink}
      />
    );
  }

  renderAllLink() {
    // if(this.props.afterHasMore){
    return (
      <span>
        <OsBtn
          name='BtnIcon'
          icon='filter'
          extraClass='no-text tag-view-more pointer fs-12'
        />
        <OsBtn
          name='BtnIcon'
          icon='add'
          extraClass='no-text tag-view-more pointer fs-12'
          onClick={this.openModal}
        />
      </span>
    );
    // }
  }

  openModal = () => {
    this.props.openTaskModal();
  };

  renderWidgetHeader() {
    if (this.props.source === 'widget') {
      return (
        <Accordion.Header className='rt-ac-header ' key=''>
          <h5>
            My Tasks <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
          </h5>
          {this.renderAllLink()}
        </Accordion.Header>
      );
    }
  }

  renderTasktList() {
    return (
      <>
        <div className='task-list-item'>
          <OrthoIcon name='checkmark-round-default' defaultClass='me-2' />
          <label className=''>Reorder tools</label>
          <div className='date-time'>Due 11/23/2022</div>
          <span className='badge bg-teal'>opperations</span>
          <div className='hashtag-label'>
            <OrthoIcon name='hashtag' defaultClass='me-2' />
            <span>Jolene Anderson</span>
          </div>
          <div className='task-msg-text'>
            hello I was wondering if it would be possible to reschedule Sara’s
            appoint...
          </div>
        </div>
      </>
    );
  }

  render() {
    if (this.props.results?.length > 0 || true) {
      return (
        <Accordion defaultActiveKey='0' className='right-ac sidebar-container'>
          <Accordion.Item eventKey='0'>
            {this.renderWidgetHeader()}
            <Accordion.Body>
              <div className='task-list' key='documents'>
                {this.renderTasktList()}
              </div>
              {this.renderSeeAllLink()}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    } else {
      return null;
    }
  }
}

TaskWidget.defaultProps = {
  source: 'widget',
};

TaskWidget = connect(({ taskModal }) => ({ taskModal }), { openTaskModal })(
  TaskWidget,
);
TaskWidget = withRouter(TaskWidget);

export default TaskWidget;
