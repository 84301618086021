import { DEVICE_VIEW, SET_DEVICE_TOKEN } from 'app/actions/types';
import {
  addClassToHTML,
  isMobileAppView,
  removeClassFromHTML,
} from 'app/utils/domHelper';
import {
  isMobileDevice,
  isIpadDevice,
  isLandscape,
  isPortrait,
  isTouchSupported,
  platformClasses,
  isSiteOpenedInIframe,
} from 'app/utils/deviceHelper';

import { each } from 'app/utils/osLodash';

const DEVICE_CLASSES_MAPPER = {
  portrait: {
    mustPresent: ['portrait'],
    mustNotPresent: ['landscape', 'desktop-small'],
  },
  landscape: { mustPresent: ['landscape'], mustNotPresent: ['portrait'] },
  mobileDevice: {
    mustPresent: ['mobile-device', 'touch-support'],
    mustNotPresent: ['ipad'],
  },
  ipad: {
    mustPresent: ['ipad', 'touch-support'],
    mustNotPresent: ['mobile-device'],
  },
  touchSupport: {
    mustPresent: ['touch-support'],
    mustNotPresent: ['no-touch-support'],
  },
  noTouchSupport: {
    mustPresent: ['no-touch-support'],
    mustNotPresent: ['touch-support'],
  },
  desktopSmall: {
    mustPresent: ['desktop-small'],
    mustNotPresent: ['desktop-hd', 'desktop-md'],
  },
  desktopMd: {
    mustPresent: ['desktop-small', 'desktop-md'],
    mustNotPresent: ['desktop-hd'],
  },
  desktopHd: {
    mustPresent: ['desktop-hd'],
    mustNotPresent: ['desktop-small', 'desktop-md'],
  },
};

export default function device(state = {}, action) {
  switch (action.type) {
    case DEVICE_VIEW:
      return {
        ...state,
        ...deviceInfo(),
      };
    case SET_DEVICE_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
}

function deviceInfo() {
  addPlatformClass();

  let height = window.$(window).height(),
    width = window.$(window).width(),
    outerWidth = window.$(window).outerWidth(),
    mobile = width <= 767 || isSiteOpenedInIframe(),
    touchSupport = isTouchSupported() || isSiteOpenedInIframe(),
    browserName = getBrowserName(),
    ipad =
      isIpadDeviceWithDesiredWidth() &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['ipad']),
    landscape =
      touchSupport &&
      isLandscape() &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['landscape']),
    mobileDevice =
      isMobileDevice() &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['mobileDevice']),
    portrait =
      touchSupport &&
      isPortrait() &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['portrait']),
    desktop = !ipad && !mobileDevice,
    mobileDevicePortrait = mobileDevice && portrait,
    mobileDeviceLandscape = mobileDevice && landscape,
    ipadPortrait = ipad && portrait,
    ipadLandscape = ipad && landscape,
    desktopHd =
      !mobileDevice &&
      !ipadPortrait &&
      outerWidth >= 1440 &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['desktopHd']),
    desktopSmall =
      !mobileDevice &&
      !ipadPortrait &&
      outerWidth < 1440 &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['desktopSmall']),
    desktopMd =
      !mobileDevice &&
      !ipadPortrait &&
      outerWidth >= 1250 &&
      outerWidth < 1440 &&
      addRequiredClasses(DEVICE_CLASSES_MAPPER['desktopMd']),
    nativeApp = isMobileAppView();

  touchSupport
    ? addRequiredClasses(DEVICE_CLASSES_MAPPER['touchSupport'])
    : addRequiredClasses(DEVICE_CLASSES_MAPPER['noTouchSupport']);
  browserName && addClassToHTML(browserName.toLowerCase());
  return {
    ipad,
    height,
    landscape,
    mobile,
    mobileDevice,
    mobileDevicePortrait,
    mobileDeviceLandscape,
    ipadPortrait,
    ipadLandscape,
    portrait,
    outerWidth,
    touchSupport,
    width,
    browserName,
    desktop,
    desktopHd,
    desktopSmall,
    desktopMd,
    nativeApp,
  };
}

function getBrowserName() {
  let browserName,
    userAgent = window.navigator.userAgent;

  if (userAgent.includes('Safari')) {
    browserName =
      userAgent.includes('Chrome') || userAgent.match('CriOS')
        ? 'Chrome'
        : 'Safari';
  } else if (userAgent.includes('Firefox')) {
    browserName = 'Firefox';
  }
  return browserName;
}

function isIpadDeviceWithDesiredWidth() {
  return isIpadDevice() && window.$(window).width() > 767;
}

function addPlatformClass() {
  each(platformClasses(), (name) => addClassToHTML(name));
}

function addRequiredClasses(data) {
  data.mustPresent.forEach((className) => addClassToHTML(className));
  data.mustNotPresent.forEach((className) => removeClassFromHTML(className));
  return true;
}
