import React from 'react';
import { Modal } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';

import { timeWithFormat } from 'app/utils/timeHelper';
import ArchiveGroupForm from 'app/components/GroupMessenger/GroupChatModal/ArchiveGroupForm';
import LeaveGroupForm from 'app/components/GroupMessenger/GroupChatModal/LeaveGroupForm';
import useGroupModal from 'app/components/GroupMessenger/GroupChatModal/useGroupModal';
import GroupInformation from 'app/components/GroupMessenger/GroupChatModal/GroupInformation';
import GroupCreateEditForm from 'app/components/GroupMessenger/GroupChatModal/GroupCreateEditForm';

const SPACE_CREATED_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
const GroupChatModal = (props) => {
  const {
    initialValues,
    onGroupBannerChange,
    selectedGroupImageUrl,
    teamSpaceModal,
    isSubmitDisabled,
    groupFormData,
    isSubmitting,
    isArchiving,
    isLeavingGroup,
    uploadPercentage,
    closeModal,
    onSubmit,
    archiveSpace,
    leaveGroup,
    isEditView,
  } = useGroupModal();

  const renderModalBody = ({ type }) => {
    switch (type) {
      case 'info':
        return <GroupInformation entity={initialValues} />;
      case 'new':
      case 'update':
        return (
          <GroupCreateEditForm
            type={type}
            groupFormData={groupFormData}
            onSubmit={onSubmit}
            entity={initialValues}
            teamSpaceModal={teamSpaceModal}
            isEditView={isEditView}
            closeModal={closeModal}
            onGroupBannerChange={onGroupBannerChange}
            groupBannerUrl={selectedGroupImageUrl}
            uploadPercentage={uploadPercentage}
            isSubmitDisabled={isSubmitDisabled}
            isSubmitting={isSubmitting}
          />
        );
      case 'archive':
        return (
          <ArchiveGroupForm
            entity={initialValues}
            archiveSpace={archiveSpace}
            isArchiving={isArchiving}
            closeModal={closeModal}
          />
        );
      case 'leave':
        return (
          <LeaveGroupForm
            entity={initialValues}
            leaveGroup={leaveGroup}
            isLeavingGroup={isLeavingGroup}
            closeModal={closeModal}
          />
        );
      default:
        return null;
    }
  };

  const renderInfoHeader = () => {
    return (
      <div className='team-modal-header'>
        {initialValues.image && (
          <img src={initialValues.image} alt='' className='space-hero-image' />
        )}

        <h4 className={'team-modal-heading'}>{initialValues.name}</h4>
      </div>
    );
  };

  const renderTextHeader = (text) => {
    return (
      <div className='team-modal-header'>
        <h4 className={'team-modal-heading'}>{text}</h4>
      </div>
    );
  };

  const renderModalHeader = ({ type }) => {
    switch (type) {
      case 'info':
        return renderInfoHeader();
      case 'new':
        return renderTextHeader('Create New Group');
      case 'archive':
        return renderTextHeader('Archive Group');
      case 'leave':
        return renderTextHeader('Leave Group');
      case 'update':
        return renderTextHeader('Edit Group');

      default:
        return null;
    }
  };

  const renderTimestamp = () => {
    const { user, created_at } = initialValues;
    return (
      <div className='group-timestamp'>
        Created by {user?.full_name} •{' '}
        {timeWithFormat(created_at, SPACE_CREATED_TIME_FORMAT)}
      </div>
    );
  };

  const renderModalFooter = ({ type }) => {
    switch (type) {
      case 'new':
        return null;
      case 'info':
      case 'archive':
      case 'leave':
        return <div className='group-footer'>{renderTimestamp()}</div>;
      default:
        return null;
    }
  };
  return (
    <>
      <div>
        <Modal
          show={teamSpaceModal.open}
          onHide={closeModal}
          dialogClassName={'team-modal'}>
          <Modal.Header className={'p-0'}>
            {renderModalHeader(teamSpaceModal)}

            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn'
              icon='close'
              label='Close share modal'
              onClick={closeModal}
            />
          </Modal.Header>
          <Modal.Body className={'p-0'}>
            <div className='group-body'>{renderModalBody(teamSpaceModal)}</div>
          </Modal.Body>
          {renderModalFooter(teamSpaceModal)}
        </Modal>
      </div>
    </>
  );
};

export default GroupChatModal;
