import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import immutableUpdate from 'immutability-helper';

import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';
import { cloneDeep, isEmpty } from 'app/utils/osLodash';
import { WorkspaceContext } from '../Layouts/WorkspaceLayout';

const INBOX_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/Inbox/WorkspaceInboxApiSubscription.gql',
);

const TasksInboxList = (props) => {
  const { id: workspaceId } = useContext(WorkspaceContext);
  const currentUser = useSelector((state) => state.currentUser);
  useEffect(() => {
    let unsubscribe = () => {};
    const { id: userId } = currentUser.graph;
    if (workspaceId && props.listType === 'MyUnreadTasks') {
      unsubscribe = props.subscribeToMore({
        document: INBOX_SUBSCRIPTION,
        variables: { workspaceId: workspaceId, userId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } =
            subscriptionData.data.workspace_inbox_api_subscription;

          if (entity && entity.__typename === 'Task') {
            let updatedData = cloneDeep(prev);
            if (
              updatedData.records.results.some(
                (obj) => obj.id.toString() === entity.id.toString(),
              )
            ) {
              updatedData.records.results = updatedData.records.results.filter(
                (obj) => obj.id.toString() !== entity.id.toString(),
              );
            } else {
              updatedData.records.total += 1;
            }
            let payload = { records: {} };
            payload.records.results = { $unshift: [entity] };
            updatedData = immutableUpdate(updatedData, payload);
            return Object.assign({}, prev, updatedData);
          }
        },
      });
    }
    return () => unsubscribe();
  }, [workspaceId]);

  useEffect(() => {
    props.updateCount(props.totalRecords);
  }, [props.totalRecords]);

  const renderTask = (task) => {
    let queryVariables = { perPage: props.perPage, type: props.listType };
    return (
      <div id={`task-Id-${task.id}`} key={task.id}>
        <div className='task-wrapper'>
          <TaskPreview
            task={task}
            listType={props.listType}
            hideCloseBtn={true}
            showSeeMoreButton={true}
            loaderNotRequired={true}
            nextPageLoadRequired={false}
            comments={task.recent_mentioned_comments}
            queryVariables={queryVariables}
          />
        </div>
      </div>
    );
  };

  return <>{!props.loading && <>{props.results?.map(renderTask)}</>}</>;
};

TasksInboxList.defaultProps = {
  updateCount: () => {},
};

export default TasksInboxList;
