export const ALL_APPOINTMENTS_FILTER_TEXT = 'All Appointments';
export const APPOINTMENT_DATE_FORMAT = 'MM/DD/YY';
export const ALL_RECORDS_TEXT = 'All Records';
export const VIEWING_MODES_MAPPER = {
  'All Records': { text: 'All' },
  Photos: { icon: 'image', type: 'Image' },
  'X-Ray': { names: ['PAN', 'CEPH'], icon: 'case-x-ray' },
  'Hard tissues': { names: ['Volume', 'Bone'], icon: 'case-hard-tissue' },
  'Soft tissues': {
    names: ['Soft tissue', 'Face', 'Airway full', 'Airway'],
    icon: 'case-soft-tissue',
  },
  Models: {
    names: ['Upper teeth', 'Lower teeth', 'Jaws', 'Maxilla', 'Mandible'],
    icon: 'case-models',
  },
  Sections: { names: ['Axial', 'Coronal', 'Sagittal'], icon: 'case-models-2' },
  dicom: { names: [], icon: 'case-dicom', extraClass: 'd-none' },
};
export const CASE_VIDEO_TYPES = ['SBS', 'SUP-ACB', 'SUP-MND'];
export const SBS_GROUP_HEADERS = [
  'Photos',
  'X-Ray',
  'Hard tissues',
  'Soft tissues',
  'Sections',
];
export const VIDEO_TYPES = ['SUP-ACB', 'SUP-MND'];
export const CASE_VIDEOS_MODES = [
  'Hard tissues',
  'Soft tissues',
  'Models',
  'Sections',
];
