import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

import Avatar from 'app/components/Shared/Avatar';
import SelectInput from 'app/components/Shared/SelectInput';

import {
  avatarAttributes,
  getOtherParticipants,
} from 'app/utils/entitiesHelper';
import { compact, debounce } from 'app/utils/osLodash';

const ALL_CONVERSATIONS_WITH_CONNECTIONS_QUERY = queryLoader(
  'app/graphql/queries/Conversations/AllConversationsWithConnections.gql',
);
const LOAD_ALL_DATA_CHARACTER_LIMIT = 3;

class ConversationMessageOptions extends Component {
  state = {
    searchText: '',
  };

  loadAllConversations() {
    this.setState({ requestInProgress: true });
    this.props.client
      .query({
        query: ALL_CONVERSATIONS_WITH_CONNECTIONS_QUERY,
        variables: {
          text_query: this.state.searchText,
        },
      })
      .then(({ data }) => {
        this.setState({
          allConversationsAndUsers: data.conversation_with_connections.results,
          requestInProgress: false,
        });
      });
  }

  filterOption = (option, inputValue) => {
    return true;
  };

  renderConversationAvatar(conversation) {
    let otherParticipants = getOtherParticipants(conversation);
    return (
      <>
        <Avatar
          className='avatar'
          {...avatarAttributes(otherParticipants[0])}
        />
        {otherParticipants.length > 1 && (
          <Avatar
            className='avatar'
            {...avatarAttributes(otherParticipants[1])}
          />
        )}
      </>
    );
  }

  renderAvatar(option) {
    if (option.__typename === 'User') {
      return (
        <Avatar
          className='avatar avatar-24 suggest-avatar me-2'
          {...avatarAttributes(option)}
        />
      );
    } else {
      return (
        <div className='avatar-combo'>
          {this.renderConversationAvatar(option)}
        </div>
      );
    }
  }

  getOptionLabel(option) {
    return (
      <span className='d-flex align-items-center'>
        {this.renderAvatar(option)}
        <div className='result-text-data'>{option.name}</div>
      </span>
    );
  }

  getUserSelectAllConversationsAndUsers() {
    let { allConversationsAndUsers } = this.state;
    if (allConversationsAndUsers && allConversationsAndUsers.length > 0) {
      return compact(
        allConversationsAndUsers.map((option) => {
          if (option.__typename !== 'Conversation' || option.can_participate)
            return {
              value: option.id,
              label: this.getOptionLabel(option),
              entity: option,
            };
        }),
      );
    } else {
      return [];
    }
  }

  filterConversations = (conversations) => {
    return conversations.filter((conversation) => conversation.can_participate);
  };

  loadData = debounce(() => {
    if (this.state.searchText.length >= LOAD_ALL_DATA_CHARACTER_LIMIT)
      this.loadAllConversations();
  }, 200);

  onInputChange = (searchText) => {
    this.setState({ searchText }, this.loadData);
  };

  getSelectInputProps() {
    if (this.state.searchText.length >= LOAD_ALL_DATA_CHARACTER_LIMIT) {
      return {
        defaultInputValue: this.state.searchText,
        defaultMenuIsOpen: this.state.searchText.length > 0,
        filterOption: this.filterOption,
        isLoading: this.state.requestInProgress,
        options: this.getUserSelectAllConversationsAndUsers(),
      };
    } else {
      let props = {
        async: true,
        filterRecords: this.filterConversations,
        inputValue: this.state.searchText,
        placeholder: "Start typing the doctor's name",
        queryType: 'ShareConversationsListing',
      };

      if (this.state.searchText.length > 0) props['menuIsOpen'] = true;
      return props;
    }
  }

  render() {
    let commonProps = {
      className: 'react-select-ortho-2',
      chevronRequired: true,
      isMulti: true,
      onChange: this.props.onChange,
      onInputChange: this.onInputChange,
      value: this.props.selectedValues,
      autoFocus: this.state.searchText.length > 0,
    };

    return <SelectInput {...commonProps} {...this.getSelectInputProps()} />;
  }
}

ConversationMessageOptions = withApollo(ConversationMessageOptions);
ConversationMessageOptions.defaultProps = {
  onChange: () => {},
  selectedValues: [],
};
export default ConversationMessageOptions;
