import React from 'react';
import { bindMobileFilter } from 'app/actions/device';
import { connect } from 'react-redux';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Outlet } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractLayout from './AbstractLayout';
import OnBoardingModals from 'app/components/OnboardingModals';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Footer from 'app/components/Shared/Footer';
import Logout from 'app/components/Shared/Logout';
import SiteLogo from 'app/components/Shared/SiteLogo';
import UniversalBanner from 'app/components/HOC/UniversalBanner';

import {
  setCurrentUserGraph,
  updateCurrentUserGraph,
} from 'app/actions/authentication';
import { isEmpty, isEqual } from 'app/utils/osLodash';
import { userDefaultPage } from 'app/utils/userRoleHelper';
import EventTracker from 'app/services/EventTracker';

const RESTRICTED_USER_QUERY = queryLoader('app/graphql/RestrictedUser.gql');
const CURRENT_USER_QUERY = queryLoader('app/graphql/CurrentUser.gql');
class StatusLayout extends AbstractLayout {
  state = {
    dropdownOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.data.restricted_user, this.props.data.restricted_user)
    )
      this.props.updateCurrentUserGraph(this.props.data.restricted_user);

    if (!isEqual(prevProps.userData.user, this.props.userData.user))
      this.props.updateCurrentUserGraph(this.props.userData.user);
    this.hideFooterIfRequired();

    if (this.props.userData.user) {
      EventTracker.register(this.props.userData.user);
    }
  }

  customComponentDidMount() {
    this.hideFooterIfRequired();
  }

  hideHeaderIfRequired() {
    if (
      this.props.device.mobileDevice &&
      this.props.location.pathname.match(/\/forgot_password|\/reset_password/)
    )
      return 'd-none';
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  renderBackground() {
    if (this.state.dropdownOpen) {
      return (
        <div
          className='nav-profile__dropdown-background active'
          onClick={this.onBackgroundClick}
          onTouchEnd={this.onBackgroundClick}
        />
      );
    } else {
      return <div />;
    }
  }

  hideFooterIfRequired = () => {
    setTimeout(() => {
      if (this.props.routeName === 'profileIncomplete') {
        document.getElementById('footer').classList.remove('hidden');
      } else {
        document.getElementById('footer').classList.add('hidden');
      }
    }, 500);
  };

  onBackgroundClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: false });
  };

  redirectToRootUrl(user) {
    return <Navigate to={userDefaultPage(user)} />;
  }

  render() {
    const restrictedData = this.props.data,
      {
        userData,
        currentUser: { graph },
      } = this.props,
      user = restrictedData.restricted_user || userData.user;
    if (restrictedData.loading || userData.loading) {
      return <div />;
      // } else if (
      //   !["launch", "updateNumber", "updatePassword"].includes(this.props.routeName) &&
      //   ((!restrictedData.restricted_user && !userData.user) ||
      //     (graph &&
      //       has(graph, "profile_incomplete_threshold_reached") &&
      //       !graph.profile_incomplete_threshold_reached))
      // ) {
      //   return this.redirectToRootUrl(graph);
    } else {
      return (
        <div className={`layout`}>
          <UniversalBanner />
          <nav
            className={
              'navbar navbar-expand-lg os-main-nav navbar-expand-xl navbar-unauth ' +
              this.hideHeaderIfRequired()
            }>
            <div className='os-main-nav-inner'>
              <SiteLogo workspaceDropdownNotRequired={true} />

              <CustomDropdown
                className='nav-profile'
                dropdownClass='os-dropdown user-profile__dropdown only-logout'
                name='user_profile_navigation'
                viewType='navProfile'
                user={user}>
                {[<Logout key='logout' />]}
              </CustomDropdown>
            </div>
          </nav>
          {!isEmpty(this.props.currentUser.graph) && <OnBoardingModals />}
          <div>
            <Outlet />
          </div>
          <Footer usePortal={true} />
        </div>
      );
    }
  }
}

StatusLayout = graphql(RESTRICTED_USER_QUERY, {
  options: (props) => ({
    variables: { token: props.currentUser.token.toString() },
  }),
})(StatusLayout);

StatusLayout = graphql(CURRENT_USER_QUERY, {
  options: (props) => {
    const { token } = props.currentUser;

    return {
      fetchPolicy: 'network-only',
      variables: { token: token.toString() },
    };
  },
  name: 'userData',
})(StatusLayout);

StatusLayout = withApollo(StatusLayout);
StatusLayout = connect(
  ({ currentUser, background, device }) => ({
    currentUser,
    background,
    device,
  }),
  { bindMobileFilter, updateCurrentUserGraph, setCurrentUserGraph },
)(StatusLayout);
StatusLayout = withRouter(StatusLayout);
export default StatusLayout;
