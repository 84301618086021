import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';

import RecordsView from 'app/components/RecordsView';

import { findIndex, isEqual, last } from 'app/utils/osLodash';
import { isInViewport } from 'app/utils/domHelper';

class ClvTabSectionsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: this.isFirstSectionOfFirstTab()
        ? this.orderedSections().length > 0 &&
          this.orderedSections()[0].id.toString()
        : '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.openFirstContentTabId !== this.props.openFirstContentTabId &&
      +this.props.openFirstContentTabId === +this.props.navTabId
    ) {
      let sectionToOpenId = this.orderedSections()[0].id;
      this.openSection(sectionToOpenId);
      this.setFirstContentSectionId(sectionToOpenId);
    }

    if (
      prevProps.openLastContentTabId !== this.props.openLastContentTabId &&
      +this.props.openLastContentTabId === +this.props.navTabId
    ) {
      let sectionToOpenId = last(this.orderedSections()).id;
      this.openSection(sectionToOpenId);
      this.setLastContentSectionId(sectionToOpenId);
    }
  }

  setFirstContentSectionId(firstContentSectionId) {
    this.setState(
      { firstContentSectionId },
      () => {
        this.setState({ firstContentSectionId: null });
      },
      100,
    );
  }

  setLastContentSectionId(lastContentSelectionId) {
    this.setState(
      { lastContentSelectionId },
      () => {
        this.setState({ lastContentSelectionId: null });
      },
      100,
    );
  }

  isFirstSectionOfFirstTab() {
    return (
      +(this.props.entity.lecture_preview_tabs || [])[0]?.id ===
      +this.props.tab.id
    );
  }

  orderedSections() {
    return this.props.results;
  }

  getSectionIndex(sectionId) {
    return findIndex(this.orderedSections(), (section) => {
      return +section.id === +sectionId;
    });
  }

  isSectionSelected(section) {
    return this.state.activeKey && +section.id === +this.state.activeKey;
  }

  openSection(id) {
    this.setState({ activeKey: id });
    setTimeout(() => {
      let ele = document.getElementById(`section-${id}`);
      ele &&
        !isInViewport(ele) &&
        ele.scrollIntoView({
          behaviour: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, 1000);
  }

  setActiveKey = (id) => {
    id = id.toString();
    if (this.state.activeKey === id) {
      this.setState({ activeKey: '' });
    } else {
      this.openSection(id);
    }
  };

  isOpen(section) {
    return section.id.toString() === this.state.activeKey.toString();
  }

  guestUser() {
    return !this.props.currentUser.graph;
  }

  openNextSection = (sectionId) => {
    let sectionIndex = this.getSectionIndex(sectionId),
      nextSection = this.orderedSections()[sectionIndex + 1];

    if (nextSection) {
      this.setActiveKey(nextSection.id);
      // Hack to render section and then set the lecture.
      setTimeout(() => {
        this.setFirstContentSectionId(nextSection.id);
      }, 100);
    } else {
      this.props.openNextSectionOrTab(sectionId);
    }
  };

  openPreviousSection = (sectionId) => {
    let sectionIndex = this.getSectionIndex(sectionId),
      previousSection = this.orderedSections()[sectionIndex - 1];

    if (previousSection) {
      this.openSection(previousSection.id);
      // Hack to render section and then set the lecture.
      setTimeout(() => {
        this.setLastContentSectionId(previousSection.id);
      }, 100);
    } else {
      this.props.openPreviousSectionOrTab(sectionId);
    }
  };

  renderSection = (section) => {
    let isOpen = this.isOpen(section),
      extraClass = isOpen ? ' open ' : '';
    return (
      <Card id={`section-${section.id}`}>
        <Accordion.Button
          className={extraClass}
          as={Card.Header}
          eventKey={section.id.toString()}
          onClick={this.setActiveKey.bind(this, section.id)}>
          {section.name}
        </Accordion.Button>
        <Accordion.Collapse eventKey={section.id.toString()}>
          <Card.Body>
            {isOpen && (
              <RecordsView
                type='TabSectionLinks'
                idQuery={section.id}
                trackScrolling={true}
                queryType={this.guestUser() ? 'GUEST_LISTING' : 'TAB_DETAILS'}
                space={this.props.entity}
                scrollIfNeeded={this.props.scrollIfNeeded}
                clvHeaderView={true}
                tab={this.props.tab}
                loaderNotRequired={true}
                nonLodedEmptyCardsRequired={true}
                scrollElementContainerId='clv-right-section-container'
                openNextSectionOrTab={this.openNextSection}
                openPreviousSectionOrTab={this.openPreviousSection}
                openLastContentTabId={this.state.lastContentSelectionId}
                openFirstContentTabId={this.state.firstContentSectionId}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  render() {
    return (
      <Accordion activeKey={this.state.activeKey.toString()}>
        {this.orderedSections().map(this.renderSection)}
      </Accordion>
    );
  }
}

ClvTabSectionsListing = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(ClvTabSectionsListing);
export default ClvTabSectionsListing;
