import OrthoIcon from 'app/components/Shared/OrthoIcon';
import React from 'react';
import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes } from 'app/utils/entitiesHelper';

const DisplayOption = (props) => {
  const { iconRequired, label, iconName, action, avatarRequired } = props;
  return (
    <>
      <div className={'command-option'}>
        {iconRequired && (
          <OrthoIcon name={iconName} dataHoverNotRequired={true} />
        )}
        {avatarRequired && (
          <Avatar
            className={'avatar avatar-16'}
            {...avatarAttributes({
              ...props.member,
            })}
          />
        )}
        <span className={'command-option-label'}>{label}</span>
      </div>
      <span className={'command-option-action'}>{action}</span>
    </>
  );
};

export default DisplayOption;
