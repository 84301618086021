import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Authorize from 'app/components/Authorize';
import CaseCardDetailView from './CaseCardDetailView';
import CommentableView from 'app/components/CommentableView';
import CommentSection from 'app/components/CommentSection';
import ContentWithSidebars from 'app/components/ContentWithSidebars';
import RecordsView from 'app/components/RecordsView';
import SimpleNavList from 'app/components/SimpleNavList';
import StickyHeader from 'app/components/StickyHeader';
import View from 'app/components/Shared/View';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';

import { SHARE_INSIGHT, CASE_VIEW_OPTIONS } from './Constant';
import { VIEWING_MODES_MAPPER } from 'app/components/CaseLeftSection/constants';

import { assignObjectAndCreate, assignConsume } from 'app/actions/assign';
import { openUpgradeModal } from 'app/actions/upgrade';
import { actionByGuestUser } from 'app/actions/guestUser';
import { setCaseViewType, setModeType } from 'app/actions/caseDetailInfo';

import { entityUrl } from 'app/utils/entitiesHelper';
import { isCommentsEnabledInWorkspace } from 'app/utils/Workspace/generalHelper';
import { keys } from 'app/utils/osLodash';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

import transGif from 'app/images/trans-gif-1440x744.gif';

const CASE_QUERY = queryLoader('app/graphql/Case.gql');
const NAV_LIST = [['Related PatientSpaces', 'related_care_spaces']];

// case detail view
class CaseView extends Authorize {
  constructor(props) {
    super(props);
    this.state = this.setDefaultState();
    this.resourceKey = 'case';
    this.setDefaultCaseDetailInfo();
  }

  setDefaultState() {
    return {
      viewAllTags: false,
      surveySkipped: false,
    };
  }

  componentDidMount() {
    this.props.assignConsume();
    super.componentDidMount();
    this.openShareInsightsIfNeeded();
    this.props.actionByGuestUser('');
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id)
      this.setState(this.setDefaultState());
  }

  setDefaultCaseDetailInfo() {
    this.props.setCaseViewType(keys(CASE_VIEW_OPTIONS)[0]);
    this.props.setModeType(keys(VIEWING_MODES_MAPPER)[0]);
  }

  openShareInsightsIfNeeded() {
    this.props.guestUser.action === SHARE_INSIGHT &&
      this.props.navigate(`${this.props.guestUser.metaInfo.url}#insights`, {
        replace: true,
      });
  }

  onClickBlur = () => {
    this.props.openUpgradeModal();
  };

  renderCommentSection() {
    return (
      <CommentSection
        entity={this.props.data.case}
        commentableId={this.props.match.params.id}
        commentableType='Case'
        isBlurred={this.areAdditionalSectionsBlurred()}
      />
    );
  }

  renderCommentsAndRelatedSection(kase) {
    let areAdditionalSectionsBlurred = this.areAdditionalSectionsBlurred(),
      extraClass = areAdditionalSectionsBlurred ? 'blurred-section' : '',
      onClick = areAdditionalSectionsBlurred ? this.onClickBlur : null;
    if (false)
      return (
        <div
          className={'row comment-content-section xl-container' + extraClass}
          onClick={onClick}>
          <ContentWithSidebars
            childGridIdentifier={'CommentSection:CaseCommentSectionCol'}
            rightSidebarGridIndentifier={
              'CommentSection:CaseCommentRightSidebarCol'
            }
            source={'case-detail'}
            parentClass='activity-container'
            extraClass='board-dis-sec'
            options={{ widgetSourceObject: kase }}
            widgetsRequiredOnMobile={true}>
            {this.renderCommentSection()}
          </ContentWithSidebars>
        </div>
      );
  }

  isGeneralUser() {
    return this.props.currentUser.graph.is_general_user;
  }

  onSkipSurveyClick = () => {
    this.setState({ surveySkipped: true });
  };

  lockedForUserAndNotPriortized() {
    return false;
  }

  isCaseInProgress() {
    return this.props.data.case.is_in_progress;
  }

  canUseAdditionalFeatures() {
    return this.props.currentUser.ability.can('additional_feature', 'case');
  }

  insightNotRequired() {
    let kase = this.props.data.case;
    return (
      kase.prioritized || +this.props.currentUser.graph.id === +kase.doctor.id
    );
  }

  areAdditionalSectionsBlurred() {
    return !this.canUseAdditionalFeatures() && this.insightNotRequired();
  }

  isCommentAndRelatedSectionVisible() {
    return (
      this.props.data.case &&
      this.props.data.case.can_user_comment &&
      (!this.lockedForUserAndNotPriortized() ||
        this.areAdditionalSectionsBlurred()) &&
      !this.isCaseInProgress() &&
      !this.isGeneralUser() &&
      isCommentsEnabledInWorkspace()
    );
  }

  getCaseDetailTooltipIdentifier() {
    return this.lockedForUserAndNotPriortized()
      ? 'case_show_locked'
      : 'case_show_unlocked';
  }

  renderLatestCaseCardDetailView() {
    const kase = this.props.data.case,
      isCommentIconVisible = this.isCommentAndRelatedSectionVisible();

    return (
      <CaseCardDetailView
        kase={kase}
        isCommentIconVisible={isCommentIconVisible}
        onSkipSurveyClick={this.onSkipSurveyClick}
      />
    );
  }

  renderCaseCardDetailView() {
    return (
      <>
        <img src={transGif} className='transgif' />
        {this.renderLatestCaseCardDetailView()}
      </>
    );
  }

  renderTabs(currentFilter) {
    return (
      <div className='tool-details-tab-content-section pt-4'>
        <RecordsView
          type='RelatedCareSpaces'
          queryType={'TAB_DETAILS'}
          idQuery={this.props.data.case.nice_id}
          size='small'
          cardGridIdentifier={'RelatedCaseWrap:CardCols'}
        />
      </div>
    );
  }

  renderTabsContent() {
    const currentFilter = 'related_care_spaces';
    return (
      <>
        <StickyHeader>
          <SimpleNavList list={NAV_LIST} currentFilter={currentFilter} />
        </StickyHeader>
        <div className='container-fluid xl-container px-hd-40 px-sm-32 px-m-12'>
          {this.renderTabs(currentFilter)}
        </div>
      </>
    );
  }

  render() {
    let content = this.authorize();
    if (content) return content;

    if (this.props.data.case && this.props.data.case.frontend_editable) {
      let kase = this.props.data.case,
        isCurrentUserCase = this.props.currentUser.graph.id === kase.doctor.id,
        url = isCurrentUserCase ? entityUrl(kase) : '/cases';

      return <Navigate to={url} />;
    }

    const kase = this.props.data.case,
      isCommentIconVisible = this.isCommentAndRelatedSectionVisible();

    return (
      <CommentableView entity={kase}>
          bannerExtraClass='case-detail-banner'
          identifier={this.getCaseDetailTooltipIdentifier()}
          onRef={(ref) => (this.tooltips = ref)}
        />
        <div className='container-fluid px-0 case-details-container pt-0  '>
          <View obj={this.props.data.case} key='view' extraClass='d-none' />
          <div className='detail detail-case'>
            <div className='case-details-content-block'>
              <div className='case-ratio'>
                {this.renderCaseCardDetailView()}
              </div>
              {isCommentIconVisible &&
                this.renderCommentsAndRelatedSection(kase)}
              {isWorkspaceView() && kase.live && this.renderTabsContent()}
            </div>
          </div>
        </div>
      </CommentableView>
    );
  }
}

CaseView = connect(
  ({ currentUser, device, guestUser }) => ({ currentUser, device, guestUser }),
  {
    actionByGuestUser,
    assignObjectAndCreate,
    assignConsume,
    openUpgradeModal,
    setCaseViewType,
    setModeType,
  },
)(CaseView);
CaseView = withApollo(CaseView);

CaseView = withGraphqlRequests(
  CASE_QUERY,
  {
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        id: props.match.params.id,
      },
    }),
  },
  {
    objectIdentifier: 'case',
  },
)(CaseView);
CaseView = withRouter(CaseView);

export default CaseView;
