import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import RecordsView from 'app/components/RecordsView';
import { TaskContext } from 'app/context/TaskContext';
import useTask from 'app/hooks/useTask';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { TASK_FEED_BATCH_SIZE } from 'app/constant/tasks';

import 'app/components/Task/task-feed-view.scss';
import { getRGBValue } from 'app/utils/taskHelper';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import Avatar from '../Shared/Avatar';

const TaskFeedListing = ({ activity, user }) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const device = useSelector((state) => state.device);
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const labelParams = searchParams.get('label');

  const initializeDemoHook = {
    user: user || { value: params.user, id: params.user } || null,
    label: labelParams || '',
    query: searchParams.get('filter'),
    person: searchParams.get('user') || '',
  };
  const selectedLabels = labelParams
    ? task_labels?.filter((label) => labelParams.includes(label.name))
    : [];
  const [personFilter, setPersonFilter] = useState(() => {
    if (initializeDemoHook.person) {
      return all_members?.find(
        (member) => member.id === initializeDemoHook.person,
      );
    }
    return null;
  });

  useEffect(() => {
    let person =
      all_members?.find((member) => member.id === searchParams.get('user')) ??
      null;

    setPersonFilter(person);
  }, [searchParams]);

  const { updateComponent, updateCache } = useTask(initializeDemoHook);

  const contextValue = {
    device,
    labelsData: task_labels,
    user: initializeDemoHook.user,
    updateComponent,
    updateCache,
  };

  const changeLabel = (label) => {
    const existingLabels = searchParams.get('label')?.split(',');
    const newLabels = existingLabels?.filter((l) => l !== label.name);
    if (newLabels?.length) {
      searchParams.set('label', newLabels);
    } else {
      searchParams.delete('label');
    }
    setSearchParams(searchParams);
  };

  const deleteUser = (user) => {
    searchParams.delete('user');
    setSearchParams(searchParams);
  };

  const renderLabelFilters = (label) => {
    return (
      <div
        key={label.id}
        className='labelMisc'
        style={{
          backgroundColor: `${getRGBValue(label.color)}`,
        }}>
        {label.name}
        <img
          src={crossCircle}
          alt='crossCircle'
          width='16'
          height='16'
          onClick={() => changeLabel(label, false)}
        />
      </div>
    );
  };

  return (
    <TaskContext.Provider value={contextValue}>
      <div className='task-activity-feed-wrapper'>
        {personFilter && (
          <div className='member'>
            <Avatar
              className='avatar avatar-16'
              {...avatarAttributes(personFilter)}
            />
            <span className='memberName'>{personFilter.full_name}</span>
            <img
              src={crossCircle}
              alt='crossCircle'
              width='16'
              height='16'
              style={{ cursor: 'pointer' }}
              onClick={deleteUser}
            />
          </div>
        )}
        {selectedLabels && (
          <div className='labels-container'>
            {selectedLabels.map(renderLabelFilters)}
          </div>
        )}
        <RecordsView
          queryType='TASK_FEED_LISTING'
          isTeamTaskFeed={activity === 'team'}
          type={activity === 'me' ? 'MyTasksFeed' : 'TeamTasksFeed'}
          perPage={TASK_FEED_BATCH_SIZE}
          additional_filters={{
            label_ids:
              selectedLabels.length > 0
                ? selectedLabels.map((label) => label.id)
                : [],
          }}
          filterType={initializeDemoHook.person ? 'User' : null}
          filterId={initializeDemoHook.person || null}
        />
      </div>
    </TaskContext.Provider>
  );
};

export default TaskFeedListing;
