import React, { useState } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import FolderListRow from 'app/components/SideBarFolderList/FolderListRow';

const SectionFolderList = (props) => {
  const [isListVisible, setIsListVisible] = useState(props.isExpanded);

  const switchListVisibility = () => {
    setIsListVisible((prevState) => !prevState);
  };

  let iconName = isListVisible ? 'chevron-down' : 'chevron-right';
  if (props.loading) return <>Loader</>;
  return (
    <>
      {props.results.length > 0 && (
        <div className={'container-section'}>
          <div
            onClick={switchListVisibility}
            className={'container-section__header'}>
            <h1 className={'title'}>{props.title || 'Folders'}</h1>{' '}
            {props.count && <span class='lt-count'>{props.count}</span>}
            <div className={'dropdown-icon'}>
              <OrthoIcon
                defaultClass='sidebar-arrow-icon'
                name={iconName}
                dataHoverNotRequired={true}
              />
            </div>
          </div>
          {isListVisible &&
            props.results.map((c) => (
              <FolderListRow
                key={c.id}
                folder={c}
                activeResourceId={props.activeResourceId}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default SectionFolderList;
