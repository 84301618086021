import React from 'react';
import { SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE } from './types';
import TRANSLATIONS from 'app/locale/en.js';
import { isUndefined } from 'app/utils/osLodash';
export const EXTRACT_TRANSLATION_KEY_REGEX = /%{[^}]+}/g;

export function flashSuccess(
  messageOrKey,
  translationRequired = true,
  interpolations = {},
) {
  var message = translationRequired
    ? translate(messageOrKey, interpolations)
    : messageOrKey;
  return (dispatch) => {
    dispatch({
      type: SHOW_SUCCESS_MESSAGE,
      message: message,
    });
  };
}

export function flashError(
  errorOrKey,
  translationRequired = true,
  interpolations = {},
) {
  var error = translationRequired
    ? translate(errorOrKey, interpolations)
    : errorOrKey;
  return (dispatch) => {
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      error: error,
    });
  };
}

export function translate(
  key,
  interpolations = {},
  stringRequired = true,
  options = {},
) {
  var translatedMessage = TRANSLATIONS[key] || key || '',
    interpolationKeys =
      translatedMessage.match(EXTRACT_TRANSLATION_KEY_REGEX) || [];

  let textValues = [translatedMessage];
  interpolationKeys.forEach((key) => {
    translatedMessage = textValues.pop();
    let splittedTexts = translatedMessage.split(key);
    for (let i = 0; i < splittedTexts.length; i++) {
      textValues.push(splittedTexts[i]);
      if (i != splittedTexts.length - 1) {
        textValues.push(interpolations[key.replace(/%{|}/g, '')]);
      }
    }
    // textValues.push(splittedTexts[0])
    // textValues.push(interpolations[key.replace(/%{|}/g, '')])
    // textValues.push(splittedTexts[1])
  });
  return stringRequired ? (
    textValues.join(isUndefined(options.connector) ? ' ' : options.connector)
  ) : (
    <>{textValues}</>
  );
}
