import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

const UPDATE_DOMAIN = queryLoader(
  'app/graphql/mutations/Integration/UpdateDomain.gql',
);
const useUpdateDomain = () => {
  const [updateDomain] = useMutation(UPDATE_DOMAIN);

  const updateDomainToWebsite = async (domainId, hostname, enabled) => {
    await updateDomain({
      variables: {
        clinicDomainId: domainId,
        hostname: hostname,
        enabled: enabled,
      },
    });
  };

  return {
    updateDomainToWebsite,
  };
};

export default useUpdateDomain;
