import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export const NAV_LIST = [
  ['Information', 'basic-info'],
  ['Members', 'members'],
  ['PatientSpaces', 'patientspaces'],
  ['Labels', 'labels'],
  ['Templates', 'templates'],
  // ['Patient Presets', 'presets'],
  ['Integration', 'integration'],
  // ['System Folders', 'system-folders'],
  // ['Billing Information', 'billing-info'],
];
export const DEFAULT_FILTER = NAV_LIST[0][1];
export const NAV_LIST_SOURCE = 'settings';

export function getCurrentFilter(pathname) {
  return pathname.replace(withWorkspaceIdentifier('/settings/'), '');
}
