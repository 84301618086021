import { PROTOCOL_REGEX, URL_REGEX } from 'app/constants';
import linkifyIt from 'linkify-it';
import { isCareSpace, isClinicSpace } from 'app/utils/spaceHelper';

const linkify = linkifyIt();

export function addProtocolIfNotPresent(url) {
  let isProtocolPresent = url.match(PROTOCOL_REGEX);
  return isProtocolPresent ? url : `http://${url}`;
}

export function getHostName(url) {
  let el = document.createElement('a');
  el.href = url;
  return el.host;
}

export function isURLSecured(url) {
  return url.match(/https:\/\//i);
}

// Stricter and more accurate than isURL() below.
export function getURL(text) {
  if (linkify.test(text) === false) return null;
  return linkify.match(text);
}

export function isURL(url) {
  return url && url.match(URL_REGEX);
}

function currentDomain(argument) {
  return window.location.pathname === '/'
    ? window.location.href.slice(0, -1)
    : window.location.href.replace(
        window.location.pathname + window.location.search,
        '',
      );
}

function sanitizeUrl(url) {
  if (
    process.env.REACT_APP_AUTH_USERNAME &&
    process.env.REACT_APP_AUTH_PASSWORD
  ) {
    return url?.replace(
      `${process.env.REACT_APP_AUTH_USERNAME}:${process.env.REACT_APP_AUTH_PASSWORD}@`,
      '',
    );
  } else {
    return url;
  }
}

export function isSameDomainSupportedType(obj) {
  return (
    ['Comment', 'Person'].includes(obj.__typename) ||
    isCareSpace(obj) ||
    isClinicSpace(obj)
  );
}

export function isUrlOfSameDomain(url) {
  return true;
  // return process.env.REACT_APP_SKIP_SAME_DOMAIN_CHECK || !url || !url.includes('https://') || sanitizeUrl(url).includes(currentDomain());
}

export function replaceCurrentDomain(url) {
  return sanitizeUrl(url)?.replace(currentDomain(), '');
}

export function getSearchParamValue(key, params = window.location.search) {
  let urlParams = new URLSearchParams(params);
  return urlParams.get(key);
}

export const removehashFromCurrentUrl = () => {
  var uri = window.location.toString();
  if (uri.indexOf('#') > 0) {
    var clean_uri = uri.substring(0, uri.indexOf('#'));
    window.history.replaceState({}, document.title, clean_uri);
  }
};

export const removeAllSearchParams = (searchParams) => {
  searchParams.forEach((value, key) => {
    searchParams.delete(key);
  });
};

export const appendModalNameInUrl = (modalName) => {
  window.history.pushState({}, '', `${window.location.href}#${modalName}`);
};
