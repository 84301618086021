import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';

import NoEntitiesFound from 'app/components/Shared/NoEntitiesFound';
import OsBtn from 'app/components/OsBtn';
import RenderCards from 'app/components/Cards/RenderCards';

import { CREATE_FOLDER_CONTEXT } from 'app/components/FoldersView/Listing';
import { CREATE_FOLDER_CONTEXT_MODAL } from 'app/components/AssignDrawer';

import { isInViewport } from 'app/utils/domHelper';
import { findKey, slice } from 'app/utils/osLodash';

const CARDS_LIMIT_IN_COLLAPSED_STATE = 3;

const NO_RESULT_TYPE_MAPPER = {
  folder: ['MyFolders', 'AccessibleMyFoldersWithContent'],
};

class GroupedListing extends Component {
  static contextType = CREATE_FOLDER_CONTEXT;
  constructor(props, context) {
    super(props, context);
    this.state = {
      collapsed: false,
      seeLessClicked: false,
      seeMoreClicked: false,
    };
  }

  onClickSeeLess = () => {
    this.setState({ collapsed: true, seeLessClicked: true });
  };

  onClickSeeMore = () => {
    !isInViewport(this.scrollToEl) &&
      scrollToElement(this.scrollToEl, { ease: 'linear', duration: 50 });
    if (this.state.seeMoreClicked) {
      this.setState({ collapsed: false });
    } else {
      this.props.loadMoreAfter();
    }

    this.setState({ seeMoreClicked: true });
  };

  results() {
    let limit = this.props.initialLimit || CARDS_LIMIT_IN_COLLAPSED_STATE;
    return this.state.collapsed
      ? slice(this.props.results, 0, limit)
      : this.props.results;
  }

  headerVisible() {
    return !!this.props.headerText && !!this.results().length;
  }

  getNoResultType() {
    return (
      findKey(NO_RESULT_TYPE_MAPPER, (value) =>
        value.includes(this.props.type),
      ) || 'course'
    );
  }

  renderCollection(props) {
    let results = this.results();
    if (
      !!results.length ||
      this.props.dynamicTemplates?.length > 0 ||
      props.createFolder
    ) {
      return (
        <RenderCards
          objects={results}
          orderRowWise={true}
          size={this.props.cardSize}
          twoPerRow={this.props.twoPerRow}
          widgetAfterListItemNumber={this.props.widgetAfterListItemNumber}
          cardGridIdentifier={this.props.cardGridIdentifier}
          dynamicTemplates={this.props.dynamicTemplates}
          {...this.props.cardProps}
          context={props}
        />
      );
    } else if (this.props.noResultFound) {
      if (this.props.cardProps.noEntityRequired) {
        return null;
      } else {
        return <NoEntitiesFound type={this.getNoResultType()} user={this.props.user} />;
      }
    }
  }

  render() {
    let collapsed = this.state.collapsed,
      seeMoreClicked = this.state.seeMoreClicked,
      { afterHasMore, loading, loadMoreAfter, seeAllNotRequired } = this.props,
      seeMoreVisible =
        !seeAllNotRequired &&
        !loading &&
        (seeMoreClicked ? collapsed : afterHasMore),
      seeLessVisible =
        !seeAllNotRequired && !loading && seeMoreClicked && !collapsed,
      loadMoreVisible =
        !seeAllNotRequired &&
        seeMoreClicked &&
        !collapsed &&
        !loading &&
        afterHasMore;

    return (
      <CREATE_FOLDER_CONTEXT_MODAL.Consumer>
        {(modal) => (
          <CREATE_FOLDER_CONTEXT.Consumer>
            {(tab) => {
              let props = modal ? modal : tab;
              return (
                <>
                  {this.headerVisible() && (
                    <div class='spc-tab-heading mb-4'>
                      {this.props.headerText}
                    </div>
                  )}
                  {seeMoreVisible && (
                    <OsBtn
                      name='BtnSecondary'
                      text='SEE MORE'
                      onClick={this.onClickSeeMore}
                      extraClass='see-more-btn with-border'
                    />
                  )}
                  {seeLessVisible && (
                    <OsBtn
                      name='BtnSecondary'
                      text='SEE LESS'
                      onClick={this.onClickSeeLess}
                      extraClass='see-more-btn with-border'
                    />
                  )}
                  {this.renderCollection(props)}
                  {loadMoreVisible && (
                    <div class='text-center pt-2 pb-4'>
                      <OsBtn
                        name='BtnPrimary'
                        text='SEE MORE'
                        onClick={loadMoreAfter}
                      />
                    </div>
                  )}
                  <div
                    ref={(el) => {
                      this.scrollToEl = el;
                    }}></div>
                </>
              );
            }}
          </CREATE_FOLDER_CONTEXT.Consumer>
        )}
      </CREATE_FOLDER_CONTEXT_MODAL.Consumer>
    );
  }
}
GroupedListing.defaultProps = {
  cardProps: {},
  noResultFound: false,
};
export default GroupedListing;
