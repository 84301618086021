import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import * as compose from 'lodash.flowright';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loaders';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import OsField from 'app/components/OsField';
import OtpInput from 'app/components/LoginView/OtpInput';
import Timer from 'app/components/Shared/Timer';
import SelectInput from 'app/components/Shared/SelectInput';

import { updateCurrentUserGraph } from 'app/actions/authentication';
import { translate } from 'app/actions/flashMessage';
import {
  fieldRequired,
  removeSpecialCharactersFromPhoneNumber,
  validPhoneNumber,
  composeValidators,
} from 'app/utils/validationHelper.js';
import { DEFAULT_TARGET_COUNTRIES } from 'app/constants';
import { clone, sortBy } from 'app/utils/osLodash';
import { lastNcharacters } from 'app/utils/stringHelper';

import EventTracker from 'app/services/EventTracker';

const COUNTRIES_QUERY = queryLoader('app/graphql/Countries.gql');
const SEND_OTP_MUTATION = queryLoader('app/graphql/SendOtp.gql');
const VERIFY_OTP_MUTATION = queryLoader('app/graphql/VerifyOtp.gql');
const RESEND_OTP_TIMER_VALUE = 30;
const ONLY_DIGIT_REGEX = /^\d+$/;
const ONLY_DIGIT_ERROR_TEXT = 'Must contain digits only';

class Security extends Component {
  state = {
    country: {},
    resendCount: 0,
    otpEntered: '',
    containerClass: '',
  };

  componentDidMount() {
    if (this.getCountries()) this.setDefaultCountry();

    if (this.formValues && this.formValues.country_code) {
      let country = this.getCountries().find(
        (country) => country.phone_code === this.formValues.country_code,
      );
      this.setState({ country });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countriesQuery.loading && !this.props.countriesQuery.loading)
      this.setDefaultCountry();
  }

  getCountries() {
    return this.props.countriesQuery.countries;
  }

  setDefaultCountry() {
    if (!this.formValues || !this.formValues.country_code) {
      let { initialValues } = this.props;
      let defaultCountry =
          this.getCountries().find(
            (country) => initialValues.country_code === country.phone_code,
          ) || this.getCountries().find((country) => country.default),
        country = this.getSelectArgument(defaultCountry);

      this.setState({ country }, () => {
        this.change('country_code', this.state.country.phoneCode);
        this.change('country', this.state.country.name);
      });
    }
  }

  setCountryCode = (country) => {
    country = country || {};
    this.change('country_code', country.phoneCode);
    this.change('country', country.name);
    this.change('phone_number', '');
    this.setState({ country });
    this.revertSentOTP();
  };

  renderConfirmationCodeError() {
    return this.renderConfirmationCodeErrorOrHint(
      this.state.otpVerificationError,
      true,
    );
  }

  renderConfirmationCodeHint() {
    return this.renderConfirmationCodeErrorOrHint(translate('OTP_EXPIRY_HINT'));
  }

  renderConfirmationCodeErrorOrHint(message, error = false) {
    let classes = 'fs-12 os-text-6 code-hint-text ';
    classes += error ? 'form-error' : '';

    return (
      <span className={classes}>
        <span>{message}</span>{' '}
        <OrthoIcon
          name='error'
          dataHoverNotRequired='true'
          defaultClass='me-1 lt-red ms-3'
        />
      </span>
    );
  }

  onOtpFieldChange = (otp) => {
    let otpVerificationError = otp ? '' : 'Required';

    this.setState({ otpVerificationError });
  };

  isCompleteOtpEntered() {
    return this.state.otpEntered.length === 4;
  }

  saveOtp = (otpEntered) => {
    this.setState({ otpEntered });
  };

  onCheckboxChange = (e) => {
    this.change('device_trusted', e.target.checked);
  };

  renderConfirmationField() {
    return (
      <>
        <div className='code-send-col'>
          <div className='d-flex align-items-center'>
            <label
              className='onboard-label mnd-label'
              htmlFor='confirmation_code'>
              Your verification code
            </label>
            <div className='ms-auto'>{this.renderResendLink()}</div>
          </div>
          <div className='otp-error-group'>
            <OtpInput
              containerStyle={`otp-form-group ${
                this.state.otpVerificationError ? 'error' : ''
              }`}
              inputStyle={'form-control'}
              onChange={this.saveOtp}
              numInputs={4}
              shouldAutoFocus={true}
              name='verification_code'
            />
            {this.renderConfirmationCodeError()}
          </div>
          <OsField
            osType='checkbox'
            name='device_trusted'
            label='Trust this device in the future'
            onChange={this.onCheckboxChange}
            formGroupExtraClass='check-listing  device-trust-checkarea'
          />
        </div>
      </>
    );
  }

  renderSendButton() {
    const inactive =
      this.props.pristine ||
      this.props.submitting ||
      this.props.invalid ||
      !this.isValidPhoneNumber();
    const loginClass = inactive
      ? 'osbtn-disabled pointer-allow disabled-only'
      : ' ';

    return (
      <div className='text-center verify-action-footer'>
        <OsBtn
          name='BtnPrimary'
          extraClass={`send-code-btn btn__login web-view-btn ${loginClass}`}
          type='submit'
          htmlTag='button'
          loaderRequired={this.state.sendingOTP}
          text={this.state.sendingOTP ? 'Sending' : 'Send Code'}
          disabled={this.state.sendingOTP}
        />
      </div>
    );
  }

  sendOtp = (values) => {
    return this.props.sendOtpMutation({
      variables: {
        phone_number: removeSpecialCharactersFromPhoneNumber(
          values.phone_number,
        ),
        country_code: values.country_code,
        check_user_existence: false,
        channel: this.otpViaSMSRequired() ? 'sms' : 'call',
      },
    });
  };

  notifyFailure = (error) => {
    EventTracker.trackFailure('confirmation_code_verification', error);
  };

  verifyOtp = () => {
    let values = this.formValues;
    values.phone_number = removeSpecialCharactersFromPhoneNumber(
      values.phone_number,
    );
    if (!this.isCompleteOtpEntered()) {
      this.setState({ otpVerificationError: 'Required' }, () => {
        this.notifyFailure({ otpVerificationError: 'Required' });
      });
      return;
    }
    if (
      !this.isCompleteOtpEntered() ||
      !ONLY_DIGIT_REGEX.test(this.state.otpEntered)
    ) {
      this.setState({ otpVerificationError: ONLY_DIGIT_ERROR_TEXT }, () => {
        this.notifyFailure({ otpVerificationError: ONLY_DIGIT_ERROR_TEXT });
      });
      return;
    }
    this.setState({ requestInProgress: true });
    return this.props
      .verifyOtpMutation({
        variables: {
          phone_number: values.phone_number,
          verification_code: this.state.otpEntered,
          country_code: values.country_code,
          login_verification: false,
          device_trusted: values.device_trusted || false,
        },
      })
      .then(({ data }) => {
        if (data.verifyOtp.success) {
          this.props.onSuccess(data.verifyOtp);
          this.props.updateCurrentUserGraph({ number_update_required: false });
          setTimeout(() => {
            this.setState({ otpVerificationError: '', otpSent: false });
          }, 1000);
        } else {
          this.setState({
            otpVerificationError: 'Please try again after few minutes.',
            requestInProgress: false,
          });
        }
      });
  };

  getSelectedCountry() {
    let country = clone(this.state.country);
    country.label = country.phone_code_format;
    return country;
  }

  getSelectArgument(country) {
    if (country) {
      return {
        label: country.name_with_phone_code,
        phoneCode: country.phone_code,
        value: country.id,
        name: country.name,
        phone_code_format: country.phone_code_format,
      };
    } else {
      return {};
    }
  }

  isValidPhoneNumber() {
    let phoneNumber = (this.formValues || {}).phone_number;
    phoneNumber = removeSpecialCharactersFromPhoneNumber(phoneNumber);
    return phoneNumber && !validPhoneNumber(phoneNumber);
  }

  onSubmit = (values) => {
    if (this.isValidPhoneNumber()) {
      this.setState({ sendingOTP: true });
      return this.sendOtp(values).then(({ data }) => {
        this.setState({
          sendingOTP: false,
          resendCount: this.state.resendCount + 1,
        });
        if (!data.sendOtp.success) {
          var returnErrors = {};
          returnErrors['phone_number'] = data.sendOtp.message;
          this.setState({ phoneNumberError: data.sendOtp.message });
          return returnErrors;
        } else {
          this.setState(
            { otpSent: true, resendLinkActive: false, phoneNumberError: '' },
            this.props.otpSent,
          );
        }
      });
    }
  };

  activateResendLink = () => {
    this.setState({ resendLinkActive: true });
  };

  otpViaSMSRequired() {
    return (
      this.state.resendCount + 1 <=
      +this.props.systemConfig.configs['resend_otp_limit_on_login'].value
    );
  }

  renderResendLink() {
    let disableClass =
        this.props.pristine || this.props.submitting || this.props.invalid
          ? 'osbtn-disabled'
          : '',
      text = this.state.sendingOTP
        ? 'Sending'
        : this.otpViaSMSRequired()
        ? 'Resend the code.'
        : 'Resend via Call';
    return (
      <>
        {!this.state.resendLinkActive && (
          <div className='fs-12 resend-text-span'>
            <Timer
              timerTime={
                this.state.resendLinkActive ? 0 : RESEND_OTP_TIMER_VALUE
              }
              timeUp={this.activateResendLink}
              text={'Try again in '}
            />
          </div>
        )}
        {this.state.resendLinkActive && (
          <OsLink
            name='OsLink'
            className={` cursor-pointer resend-code-link ${disableClass}`}
            type='submit'
            text={text}
            htmlTag=''
            disabled={this.state.sendingOTP}
            loaderRequired={this.state.sendingOTP}
          />
        )}
      </>
    );
  }

  getPhoneNumberFieldCommonProps() {
    return {
      name: 'phone_number',
      type: 'number',
      component: OsField,
      validate: composeValidators(
        fieldRequired,
        validPhoneNumber
      ),
      hintRequired: true,
      onChange: this.revertSentOTP,
    };
  }

  getPhoneNumberProps() {
    let formValues = this.formValues || {},
      otherProps;
    if (DEFAULT_TARGET_COUNTRIES.includes(formValues.country)) {
      otherProps = {
        osType: 'formatted_input',
      };
    } else {
      otherProps = {
        osType: 'input',
        pattern: 'd*',
      };
    }
    return { ...this.getPhoneNumberFieldCommonProps(), ...otherProps };
  }

  onPhoneNumberFocus = () => {
    this.setState({ containerClass: 'phone-number-focus' });
  };

  onPhoneNumberBlur = () => {
    this.setState({ containerClass: '' });
  };

  renderPhoneNumberField() {
    return (
      <Field
        {...this.getPhoneNumberProps()}
        onFocus={this.onPhoneNumberFocus}
        onBlur={this.onPhoneNumberBlur}
      />
    );
  }

  revertSentOTP = () => {
    if (this.state.otpSent) {
      this.setState({ otpSent: false });
      this.change('verification_code', null);
    }
  };

  isVerifyOtpButtonDisabled() {
    return (
      this.state.requestInProgress ||
      this.state.sendingOTP ||
      !this.isValidPhoneNumber()
    );
  }

  renderSendButtonContainer() {
    let className = 'pe-0 send-btn-col ';
    className += this.props.device.mobileDevice ? 'col-12' : 'col-md-4 col-6';
    if (!this.state.otpSent)
      return (
        <div className='d-flex justify-space-between align-items-center pt-2'>
          <div className={className}>{this.renderSendButton()}</div>
        </div>
      );
  }

  isPhoneNumberErrorRequired() {
    return (
      this.props.anyTouched &&
      (this.formSyncErrors.phone_number || !this.props.valid)
    );
  }

  getTitle() {
    return !this.state.otpSent
      ? 'UPDATE_PASSWORD_SECURE_YOUR_ACCOUNT_HEADING'
      : 'UPDATE_PASSWORD_TWO_STEP_VERIFICATION_HEADING';
  }

  renderContent() {
    return (
      <>
        {this.props.titleRequired && (
          <div className='modal-title form-heading'>
            {translate(this.getTitle())}
          </div>
        )}
        <div className='two-step-verify'>
          <Form
            onSubmit={this.onSubmit}
            forceUnregisterOnUnmount={false}
            asyncBlurFields={['phone_number']}
            initialValues={{
              device_trusted: false,
            }}
            render={(props) => {
              this.change = props.form.change;
              this.formValues = props.values;
              this.formSyncErrors = props.errors;
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className={this.state.containerClass}>
                    {!this.state.otpSent && (
                      <div className=''>
                        <div className='verify-text-info'>
                          {translate(
                            'USER_ONBOARDING_SECURE_YOUR_ACCOUNT_CONTENT',
                          )}
                        </div>
                        <Field
                          component={OsField}
                          osType='hidden'
                          name='country'
                          formGroupExtraClass='d-none'
                        />
                        <label
                          className='onboard-label mnd-label'
                          htmlFor='phone_number'>
                          <span className='heading'>Mobile phone number</span>
                        </label>
                        <div
                          className={`verify-mobile-group ${
                            this.isPhoneNumberErrorRequired() ? 'error' : ''
                          }`}>
                          <SelectInput
                            name='country_code'
                            className='react-select-ortho-2'
                            value={this.getSelectedCountry()}
                            options={sortBy(
                              this.getCountries().map((country) =>
                                this.getSelectArgument(country),
                              ),
                              'label',
                            )}
                            onChange={this.setCountryCode}
                            placeholder={''}
                            backspaceRemoves={false}
                            onFocus={this.onPhoneNumberFocus}
                            onBlur={this.onPhoneNumberBlur}
                          />
                          {this.renderPhoneNumberField()}
                        </div>
                      </div>
                    )}

                    {!this.props.device.mobileDevice &&
                      !this.state.otpSent &&
                      this.renderSendButton()}
                    {this.state.otpSent &&
                      translate(
                        'USER_ONBOARDING_TWO_STEP_VERIFICATION_CONTENT',
                      ) + lastNcharacters(this.formValues.phone_number, 4)}
                    {this.state.otpSent && this.renderConfirmationField()}
                  </div>

                  {this.props.device.mobileDevice &&
                    this.renderSendButtonContainer()}

                  {this.state.otpSent && (
                    <div className='text-center verify-action-footer'>
                      <OsBtn
                        name='BtnPrimary'
                        text={
                          this.state.requestInProgress
                            ? 'Confirming'
                            : 'Confirm Code'
                        }
                        extraClass={`verify-btn ${
                          this.isVerifyOtpButtonDisabled() ? 'disabled' : ''
                        }`}
                        onClick={this.verifyOtp}
                        loaderRequired={this.state.requestInProgress}
                        disabled={this.isVerifyOtpButtonDisabled()}
                      />
                    </div>
                  )}
                </form>
              );
            }}
          />
        </div>
      </>
    );
  }

  closeModal = () => {
    this.props.closeModal();
  };

  isDefaultView() {
    return this.props.viewType === 'default';
  }

  render() {
    if (!this.props.countriesQuery.countries) {
      if (this.isDefaultView()) {
        return <Loader type='ball-triangle-path' active />;
      }
      return <div />;
    }

    if (this.isDefaultView()) {
      return this.renderContent();
    }

    return (
      <Modal
        show={true}
        onHide={this.closeModal}
        className='modal-general modal-global user-secure-modal'
        animation={false}
        backdropClassName='modal-backdrop-custom modal-update-backdrop'>
        <Modal.Header>
          <span className='modal-title'>
            {this.state.otpSent
              ? translate('USER_ONBOARDING_SECURE_YOUR_ACCOUNT_HEADING')
              : translate('USER_ONBOARDING_TWO_STEP_VERIFICATION_HEADING')}
          </span>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-general-modal  p-0'
            icon='close'
            label='Close modal'
            onClick={this.closeModal}
          />
        </Modal.Header>
        <Modal.Body>{this.renderContent()}</Modal.Body>
      </Modal>
    );
  }
}

Security.defaultProps = {
  viewType: 'default',
  initialValues: {},
  otpSent: () => {},
  closeModal: () => {},
  onSuccess: () => {},
};

Security = compose(
  graphql(SEND_OTP_MUTATION, { name: 'sendOtpMutation' }),
  graphql(VERIFY_OTP_MUTATION, { name: 'verifyOtpMutation' }),
  graphql(COUNTRIES_QUERY, {
    name: 'countriesQuery',
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        countriesWithPhoneCodeRequired: true,
      },
    }),
  }),
)(Security);

Security = connect(({ device, systemConfig }) => ({ device, systemConfig }), {
  updateCurrentUserGraph,
})(Security);
export default Security;
