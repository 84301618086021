import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import DeleteConfirmationModal from 'app/components/Shared/DeleteConfirmationModal';
import EntityDropdown from 'app/components/EntityDropdown';

import OsBtn from 'app/components/OsBtn/';
import withAuthenticate from 'app/components/HOC/withAuthenticate';
import { openEditImageModal } from 'app/actions/editImage';
import { openInfoModal } from 'app/actions/infoModal';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { isGuestUser } from 'app/utils/userHelper';
import { updateCurrentUserGraph } from 'app/actions/authentication';

const DELETE_BOARD_MUTATION = queryLoader('app/graphql/DeleteBoard.gql');

class SpaceActions extends Component {
  state = {
    deleteInitiated: false,
  };

  getSpace() {
    return this.props.space;
  }

  toggleDelete = () => {
    this.setState({ deleteInitiated: !this.state.deleteInitiated });
  };

  renderDelete = () => {
    return (
      <div key='delete_space'>
        <AuthorizeComponent
          abilityObject={getAuthorizeObject('destroy', 'board', {
            userId: this.getSpace().user.id,
          })}
          onClick={this.toggleDelete}>
          <OsBtn
            name='BtnIcon'
            extraClass='list-button'
            icon='delete'
            text='Delete Space'
            associatedEntity={this.getSpace()}
          />
        </AuthorizeComponent>
      </div>
    );
  };

  deleteFullySpace = () => {
    this.props
      .deleteBoard({
        variables: {
          boardId: this.getSpace().id,
        },
      })
      .then(({ data }) => {
        if (data.deleteBoard.deleted) {
          this.props.navigate('/spaces');
        }
      });
  };

  render() {
    let space = this.props.space;
    if (isGuestUser()) {
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='no-text v-align-top card-btn-more '
          onClick={this.props.redirectToDefault}
          icon='more'
        />
      );
    }
    return (
      <>
        <EntityDropdown
          {...this.props}
          entity={space}
          renderDelete={this.renderDelete}
        />
        {this.state.deleteInitiated && (
          <DeleteConfirmationModal
            entityType='space'
            onClose={this.toggleDelete}
            deleteEntity={this.deleteFullySpace}
            entity={space}
          />
        )}
      </>
    );
  }
}

SpaceActions.defaultProps = {
  extraClass: '',
};

SpaceActions = compose(graphql(DELETE_BOARD_MUTATION, { name: 'deleteBoard' }))(
  SpaceActions,
);
SpaceActions = withApollo(SpaceActions);
SpaceActions = withRouter(SpaceActions);
SpaceActions = connect(
  ({ currentUser, device, systemConfig }) => ({
    currentUser,
    device,
    systemConfig,
  }),
  { openEditImageModal, openInfoModal, updateCurrentUserGraph },
)(SpaceActions);
SpaceActions = withAuthenticate(SpaceActions);

export default SpaceActions;
