// alwaysShowGuests, showLoggedInUsersOnly, delay - Needs to be implemented
const PROMOTION_BANNER = {
  bannerKey: 'promotionBanner',
  allowUserDismiss: true,
  alwaysShowGuests: true,
  showLoggedInUsersOnly: false,
  active: false,
  delay: 1000,
};

const QLARK_LAUNCH = {
  bannerKey: 'qlarkLaunch',
  start: new Date('2021-03-01').getTime(),
  expire: new Date('2021-12-01').getTime(),
  message:
    'Polaris is now Cloudberry, The learning network for your most challenging clinical problems!',
  ctaUrl: 'https://blog.qlark.com',
  ctaTextDesktop: 'Learn more about our rebranding.',
  ctaText: 'Learn more.',
  ctaOpenInNewTab: true,
  htmlContent: true,
  allowUserDismiss: true,
  alwaysShowGuests: true,
  showLoggedInUsersOnly: false,
  active: false,
  delay: 1000,
};

const RELEASE_LAUNCH = {
  bannerKey: 'releaseLaunch',
  messageKey: 'NEW_RELEASE_BANNER',
  allowUserDismiss: false,
  alwaysShowGuests: true,
  showLoggedInUsersOnly: false,
  active: false,
  delay: 1000,
};

const VERIFICATION_BANNER = {
  bannerKey: 'verificationBanner',
  messageKey: 'USER_ONBOARDING_COMPLETE_VERIFICATION_BANNER_MESSAGE',
  allowUserDismiss: false,
  alwaysShowGuests: true,
  ctaTextDesktop: 'Resend confirmation link',
  ctaText: 'Resend confirmation link',
  onCTAClick: () => {},
  showLoggedInUsersOnly: false,
  active: false,
  delay: 1000,
};

export const SPACE_MUTE_BANNER_TEMPLATE = {
  allowUserDismiss: true,
  ctaTextDesktop: 'Unmute',
  ctaText: 'Unmute',
  dismissThreshold: 3600 * 1000 * 24,
  onCTAClick: () => {},
  showLoggedInUsersOnly: true,
  active: false,
  delay: 1000,
};

export const SPACE_PRIORITY_GROUP_BANNER_TEMPLATE = {
  allowUserDismiss: true,
  ctaTextDesktop: 'Add me to the Priority Contact Group',
  ctaText: 'Add me to the Priority Contact Group',
  dismissThreshold: 3600 * 1000 * 24,
  onCTAClick: () => {},
  showLoggedInUsersOnly: true,
  active: false,
  delay: 1000,
};

export const SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX =
  'space_priority_group_banner_';
export const SPACE_MUTE_BANNER_KEY_PREFIX = 'space_mute_banner_';

export const ADDITIONAL_BANNERS_PREFIXES = [
  {
    key: SPACE_MUTE_BANNER_KEY_PREFIX,
    threshold: SPACE_MUTE_BANNER_TEMPLATE['dismissThreshold'],
  },
  {
    key: SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX,
    threshold: SPACE_PRIORITY_GROUP_BANNER_TEMPLATE['dismissThreshold'],
  },
];

export const UNIVERSAL_BANNERS = [
  RELEASE_LAUNCH,
  VERIFICATION_BANNER,
  PROMOTION_BANNER,
  QLARK_LAUNCH,
];
