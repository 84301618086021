import React, { Component } from 'react';

import AppointmentForm from 'app/components/AppointmentForm';
import OsBtn from 'app/components/OsBtn';

class NewAppointmentButton extends Component {
  state = {
    openAppointmentForm: false,
  };

  closeAppointmentModal = () => {
    this.setState({ openAppointmentForm: false });
  };

  render() {
    if (this.props.kase.can_update && this.props.kase.live) {
      return (
        <>
          <OsBtn
            name={this.props.name}
            onClick={() => {
              this.setState({ openAppointmentForm: true });
            }}
            extraClass={
              this.props.extraClass || 'add-appointment-btn web-view-btn'
            }
            title='Add New Appointment'
            text='Add New Appointment'
            icon='add'
          />
          {this.state.openAppointmentForm && (
            <AppointmentForm
              kase={this.props.kase}
              onClose={this.closeAppointmentModal}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  }
}

NewAppointmentButton.defaultProps = {
  kase: {},
};
export default NewAppointmentButton;
