export const StyleForKanban = {
  control: (provided, state) => {
    return {
      ...provided,
      color: '#ffffffe6',
      backgroundColor: '#303036',
      borderColor: '#303036',
      boxShadow: state.isFocused ? '0 0 0 0 #cae7fe' : 'none',
      minHeight: '40px',
    };
  },
  multiValueRemove: (base) => ({
    ...base,
    fontSize: '20px',
    lineHeight: '18px',
    color: 'rgba(255,255,255,.9)',
    backgroundColor: 'transparent',
    width: '18px',
    height: '18px',
    alignItems: 'center',
    padding: '0 2px',
    textAlign: 'center',
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'rgba(255,255,255,.9)',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#48acfd',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '25px',
    alignItems: 'center',
    padding: '0 5px',
    border: '2px solid red',
    '&:hover': { color: '#48acfd' },
    '&:focus': { color: 'rgba(255,255,255,.9)' },
  }),

  menu: (base) => ({
    ...base,
    margin: '0',
    padding: '0',
    className: 'invit-select',
    border: '2px solid pink',
  }),

  menuList: (base) => ({
    ...base,
    padding: '0',
    maxHeight: '320px',
    className: 'invit-select-list',
    border: '2px solid red',
  }),

  singleValue: (base) => ({
    ...base,
    border: '2px solid transparent',
    borderRadius: '24px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > div:first-child': {
        marginRight: '4px',
      },
      '& > div:last-child': {
        fontSize: '12px',
        fontWeight: '600',
      },
    },
    minWidth: '100%',
    backgroundColor: '#f4f7f9', // backgroundColor: "#edeff3",
    width: 'fit-content',
    color: 'rgba(255,255,255,.9)',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '1em',
    color: 'rgba(255,255,255,.6)',
    fontWeight: 400,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    border: '2px solid green',
    color: 'rgba(255,255,255,.9)',
    '&:hover': { background: '#48acfd33', color: '#48acfd' },
    '&:focus': { color: '#0077c3' },
  }),
};
