import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import { editContactParty } from 'app/actions/contactPartyModal';
import { canEditPersonInfo } from 'app/utils/spaces/careHelper';

class CareSpaceEditGuardianBtn extends PureComponent {
  editContactParty = () => {
    this.props.editContactParty({
      space: this.getSpace(),
      contactParty: this.props.contactParty,
    });
  };

  getSpace() {
    return this.props.space;
  }

  render() {
    let canEditInfo = this.getSpace()?.is_author_or_editor || canEditPersonInfo(this.props.contactParty)
    if (
      canEditInfo &&
      !this.props.device.mobileDevice
    ) {
      return (
        <OsBtn
          name='BtnIcon'
          text='Edit information'
          icon='edit'
          onClick={this.editContactParty}
        />
      );
    } else {
      return null;
    }
  }
}

CareSpaceEditGuardianBtn = connect(({ device }) => ({ device }), {
  editContactParty,
})(CareSpaceEditGuardianBtn);

export default CareSpaceEditGuardianBtn;
