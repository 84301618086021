import React, { useRef, useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import downFilledArrow from 'app/images/task-manager/downFilledArrow.svg';
import rightFilledArrow from 'app/images/task-manager/rightFilledArrow.svg';
import { isCurrentDay } from 'app/utils/timeHelper';
import Task from 'app/components/Task/Task';
import { TASKS_TYPE } from './taskConstant';
import EmptyState from './EmptyState';
import { isInViewport } from 'app/utils/domHelper';

const SECTION_OPEN_BY_DEFAULT = [
  'NotCompletedUserCreatedTasksAssignedToOther',
  'NotViewedTasks',
  'ViewedTasks',
  'InProgressTasks',
];

function TaskListView(props) {
  const tasks = props.results;
  let prevScrollHeight = 0;
  const elementAfterLastElement = useRef(null);

  const [isVisible, setIsVisible] = useState(
    props?.isListOpen || isCurrentDay(props?.day?.date),
  );

  useEffect(() => {
    if (SECTION_OPEN_BY_DEFAULT.includes(props.listType)) {
      setIsVisible(true);
    }
  }, [props.listType]);

  useEffect(() => {
    if (TASKS_TYPE.includes(props.listType) && isVisible) {
      document.addEventListener('scroll', () => {
        if (
          elementAfterLastElement.current &&
          isInViewport(elementAfterLastElement.current)
        ) {
          if (props.fetchNextPageRecords)
            props.fetchNextPageRecords(props.details.id);
          else props.loadMore();
        }
      });
    }
    return () => {
      if (TASKS_TYPE.includes(props.listType) && isVisible) {
        document.removeEventListener('scroll', () => {
          if (
            elementAfterLastElement.current &&
            isInViewport(elementAfterLastElement.current)
          ) {
            props.loadMore();
          }
        });
      }
    };
  }, [isVisible]);

  return (
    <>
      {
        <div className='taskListWrapper'>
          {props.headerRequired !== false && (
            <div className='header' onClick={() => setIsVisible(!isVisible)}>
              <div className='section-title'>{props.sectionTitle}</div>
              <button className='btn'>
                {isVisible ? (
                  <img
                    src={downFilledArrow}
                    alt='downFilledArrow'
                    width='16px'
                    height='16px'
                  />
                ) : (
                  <img
                    src={rightFilledArrow}
                    alt='downFilledArrow'
                    width='16px'
                    height='16px'
                  />
                )}
              </button>
            </div>
          )}
          {isVisible && (
            <div className='taskListContainer'>
              {tasks !== null &&
                tasks.map((task) => {
                  let listType = props.listType;
                  return (
                    <div id={`task-Id-${task.id}`}>
                      <Task key={task.id} task={task} listType={listType} />
                    </div>
                  );
                })}
              <div ref={elementAfterLastElement} />
              {tasks &&
                isCurrentDay(props?.day?.date || props?.date) &&
                tasks.length === 0 && <EmptyState />}
            </div>
          )}
        </div>
      }
    </>
  );
}

TaskListView = withApollo(TaskListView);

export default TaskListView;
