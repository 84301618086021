import { PluginKey } from 'prosemirror-state';
import { loader as queryLoader } from 'graphql.macro';
import client from 'app/graphql/client';
import { isOfTypeSpace, isOfPartnerTypeSpace } from 'app/utils/spaceHelper';
import { entityIsTask } from 'app/utils/entitiesHelper';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';

const USERS_SEARCH_QUERY = queryLoader('app/graphql/UsersSearch.gql');
const SEARCH_TEXT_UPTO_USER_SEARCH_LIMIT_REQUIRED = 2;
const USER_SEARCH_LIMIT = 50;

const mentionSuggestion = () => {
  const queryFunction = async ({ query, editor }) => {
    const {
      mentionGroups,
      entity,
      generalUsersRequired,
      isWorkspace,
      isInternalNote,
      workspaceId,
    } = editor.options?.editorProps;

    const { data } = await client.query({
      query: USERS_SEARCH_QUERY,
      variables: {
        query: query,
        limit:
          query.length > SEARCH_TEXT_UPTO_USER_SEARCH_LIMIT_REQUIRED
            ? 30
            : USER_SEARCH_LIMIT,
        generalUsersRequired: generalUsersRequired,
        filterBy: isInternalNote
          ? workspaceId
          : isOfTypeSpace(entity) ||
            isOfPartnerTypeSpace(entity) ||
            entityIsTask(entity)
          ? entity?.nice_id
          : null,
      },
    });
    const mentionUser = isWorkspace ? data.user_search.map((user) => user) : [];
    const broadCastSuggestions = query
      ? mentionGroups.filter((suggestion) => {
          return suggestion.display_name
            .toLowerCase()
            .includes(query.toLowerCase());
        })
      : [];

    return {
      mention:
        query.length > 0
          ? [...broadCastSuggestions, ...mentionUser]
          : [...mentionGroups, ...mentionUser],
      entity: entity,
      workspaceId,
    };
  };

  return {
    items: (query) => queryFunction(query),
    char: '@',
    pluginKey: new PluginKey('suggestion'),
    ...suggestionConfig,
  };
};

export default mentionSuggestion;
