import EventTracker from 'app/services/EventTracker';

export default function scrollToFirstError(errors, eventName = '') {
  const errorFields = getErrorFieldNames(errors);
  eventName && EventTracker.trackFailure(eventName, errors);
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = errorFields[i],
      domElement = document.querySelectorAll(`[name="${fieldName}"]`);

    // Checking if the marker exists in DOM
    if (domElement.length) {
      domElement[0].focus();
      break;
    }
  }
}

function getErrorFieldNames(obj) {
  const errorArr = [],
    name = '';
  if (obj) {
    errorArr.push(
      Object.keys(obj)
        .map((key) => {
          const next = obj[key];
          if (next) {
            if (typeof next === 'string') {
              return name + key;
            }
            // Keep looking
            if (next.map) {
              errorArr.push(
                next
                  .map((item, index) =>
                    getErrorFieldNames(item, `${name}${key}[${index}].`),
                  )
                  .filter((o) => o),
              );
            }
          }
          return null;
        })
        .filter((o) => o),
    );
  }
  return flatten(errorArr);
}

function flatten(arr) {
  return arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    [],
  );
}
