import React, { Component } from 'react';

import EmojiBtn from 'app/components/EmojiIconBar/EmojiBtn';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import EmojiUsers from 'app/components/EmojiIconBar/EmojiUsers';

const TOUCH_DURATION = 800;
class EmojiWithUserList extends Component {
  onTouchStart = (e) => {
    e.preventDefault();
    if (!this.timer) this.timer = setTimeout(this.onlongtouch, TOUCH_DURATION);
  };

  onTouchEnd = (e) => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  onlongtouch = () => {
    this.timer = null;
    this.setActiveEmoji();
  };

  setActiveEmoji = () => {
    this.props.setActiveEmoji(this.props.emojiName);
  };

  removeEmoji = () => {
    this.props.removeEmoji(this.props.emojiName);
  };

  addEmoji = () => {
    this.props.addEmoji(this.props.emojiName);
  };

  onUpdate = () => {
    return this.props.active ? this.removeEmoji() : this.addEmoji();
  };

  renderPopover() {
    let className = this.props.popoverClass;
    className += ' with-multiple-user';
    // className+= this.props.users.length > 1 ? ' with-multiple-user' : ' with-single-user'
    return (
      <Popover className={className}>
        <Popover.Header>Reactions</Popover.Header>
        <Popover.Body>
          <EmojiUsers
            users={this.props.users}
            source={'feed'}
            userlistClass={this.props.userlistClass}
          />
        </Popover.Body>
      </Popover>
    );
  }

  renderButton() {
    return (
      <div>
        <EmojiBtn emojiName={this.props.emojiName} active={this.props.active} />
      </div>
    );
  }

  renderEmojiButton() {
    if (this.props.isSourceMessenger) {
      return (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          delay='250'
          placement={'auto'}
          overlay={this.renderPopover()}>
          {this.renderButton()}
        </OverlayTrigger>
      );
    } else {
      return this.renderButton();
    }
  }

  render() {
    return (
      <div
        className='user-emoji-holder'
        onClick={this.onUpdate}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}>
        {this.renderEmojiButton()}
      </div>
    );
  }
}

EmojiWithUserList.defaultProps = {
  popoverClass: 'cloud-popover',
};

export default EmojiWithUserList;
