import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import AbstractCard from './AbstractCard';
import ConnectToSupport from 'app/components/ConnectToSupport';
import InviteToSpace from 'app/components/Shared/AddTo';
import Avatar from 'app/components/Shared/Avatar';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OsBtn from 'app/components/OsBtn';
import UserMessageCTA from 'app/components/Shared/UserMessageCTA';

import {
  avatarAttributes,
  userNameWithDegrees,
} from 'app/utils/entitiesHelper';
import { isResidentUser } from 'app/utils/userRoleHelper';
import { isCommunicationFeatureEnabled } from 'app/utils/featureHelper';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

class UserCard extends AbstractCard {
  onCardClick = (e, options = {}) => {
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  isCurrentUser() {
    return (
      this.props.currentUser.graph &&
      +this.props.obj.id === +this.props.currentUser.graph.id
    );
  }

  clinicOrProgramDetails() {
    let { graduation_year, specialty_program } =
      this.props.obj;

    if (isResidentUser(this.props.obj)) {
      return graduation_year && specialty_program
        ? `Resident | ${specialty_program}' ${graduation_year}`
        : '';
    }
  }

  renderInviteToSpace() {
    return (
      <InviteToSpace
        obj={this.props.obj}
        type='invite'
        label='Invite to Folder'
        extraClass='list-button ms-0'
      />
    );
  }

  renderDropdown() {
    if (!this.isCurrentUser() && !this.isSupportUser()) {
      return (
        <CustomDropdown
          name='user_action'
          className='card-dropdown-menu top-18'
          onDropDownToggle={this.props.onDropDownToggle}
          authenticationRequired={true}>
          {this.renderInviteToSpace()}
          {this.renderConnection(true)}
          {this.renderSelectButton()}
          {this.renderRemoveButton()}
        </CustomDropdown>
      );
    }
  }

  isSupportUser() {
    return this.props.obj.is_support_user;
  }

  viewProfileButton(buttonCommonProps, insideDropdown) {
    let extraProps = insideDropdown
      ? { name: 'BtnIcon', extraClass: 'list-button', icon: 'account' }
      : {};
    return (
      <OsBtn
        text='View Profile'
        name='BtnSecondary'
        onClick={this.onCardClick}
        {...buttonCommonProps}
        {...extraProps}
      />
    );
  }

  getConnectionProps(insideDropdown = false) {
    let alreadyConnected = isWorkspaceView(),
      props = {
        cardActions: true,
        user: this.props.obj,
        onCardActionClick: this.props.onCardActionClick,
      };
    if (alreadyConnected) {
      props.buttonType = 'BtnIcon';
      props.childButtonClassNotRequired = true;
      props.textNotRequired =
        insideDropdown || (!insideDropdown && this.isSmallCard());
      props.extraClass = '';
      props.icon = !this.isTall() && 'messages';
    } else {
      props.buttonType = this.isExtraSmall() ? 'BtnIcon' : 'BtnSecondary';
      props.iconsRequired = this.isExtraSmall();
      props.icon = 'add';
    }
    return props;
  }

  renderConnection(insideDropdown = false) {
    let buttonCommonProps = {
      associatedEntity: this.props.obj,
      extraClass: 'osbtn-with-border',
    };
    if (insideDropdown && !this.isExtraSmall() && isWorkspaceView())
      return null;

    if (
      (this.isCurrentUser() && !this.isExtraSmall()) ||
      !isCommunicationFeatureEnabled()
    )
      return this.viewProfileButton(buttonCommonProps, insideDropdown);

    if (this.isSupportUser()) {
      return (
        <ConnectToSupport
          icon='messages'
          text=''
          buttonType='BtnPrimary'
          viewType='button'
          {...buttonCommonProps}
          {...this.getConnectionProps(insideDropdown)}
          onRedirectToSupport={this.props.onCardActionClick}
        />
      );
    }

    if (isWorkspaceView())
      return <UserMessageCTA {...this.getConnectionProps(insideDropdown)} />;
    if (!insideDropdown || (insideDropdown && this.isExtraSmall())) {
      if (!this.isExtraSmall()) {
        return this.viewProfileButton(buttonCommonProps, insideDropdown);
      }
    }
  }

  companyNameAndDesignation() {
    let { company_name, designation } = this.props.obj;
    if (company_name || designation)
      return (
        <div className='general-user-detail'>
          {designation || ''}
          <br />
          {company_name || ''}
        </div>
      );
  }

  renderCustomDropdown() {
    return (
      <CustomDropdown
        name='user_actions'
        className='card-dropdown-menu top-18'
        onDropDownToggle={this.props.onDropDownToggle}
        authenticationRequired={true}>
        {this.renderConnection(true)}
      </CustomDropdown>
    );
  }

  renderFooter() {
    return (
      !this.isExtraSmall() && (
        <div className='doc-card-footer'>{this.renderConnection()}</div>
      )
    );
  }

  render() {
    let isTall = ['tall', 'tall-min'].includes(this.props.size),
      avatarClass = `avatar avatar-${isTall ? 120 : 64}`;

    return (
      <div
        key={this.props.key}
        className={this.props.containerClasses}
        onClick={this.onCardClick}>
        {this.props.closeRequired && (
          <span
            className='os-close-link ifill-os-clear'
            onClick={this.afterClose}></span>
        )}

        <div className='doc-card-body'>
          <div className='doc-info'>
            <Avatar
              className={avatarClass}
              {...avatarAttributes(this.props.obj, {
                largeAvatarRequired: isTall,
              })}
            />
            <div
              className={`clinic-text ${this.isCurrentUser() ? 'pe-0' : ''}`}>
              {userNameWithDegrees(this.props.obj)}
            </div>

            {!isTall && (
              <div className='academy-address'>
                {this.clinicOrProgramDetails()}
                {this.companyNameAndDesignation()}
              </div>
            )}
          </div>

          {isTall && (
            <div className='card-address'>
              {this.clinicOrProgramDetails()}
              {this.companyNameAndDesignation()}
            </div>
          )}
        </div>
        {this.renderDropdown()}
        {this.renderFooterOrSelectBox()}
      </div>
    );
  }
}
UserCard = withRouter(UserCard);
UserCard = connect(({ currentUser }) => ({ currentUser }))(UserCard);
export default UserCard;
