import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';

import AutoSuggestSearchInput from 'app/components/Shared/AutoSuggestSearchInput';
import RecordsPager from 'app/components/RecordsPager';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';

import { getSearchParamValue } from 'app/utils/urlHelper';
import { activateScroll } from 'app/utils/generalHelper';
import { isPrivateObject, entityUrl } from 'app/utils/entitiesHelper';
import { map, lowerCase } from 'app/utils/osLodash';

import EventTracker from 'app/services/EventTracker';

import {
  assignConsume,
  setAssignType,
  assignCreate,
  assignMessage,
} from 'app/actions/assign';
import { openInfoModal } from 'app/actions/infoModal';
import { openUpgradeModal } from 'app/actions/upgrade';
import {
  isNewSubSpaceAllowedInWorkspace,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { capitalize } from 'app/utils/stringHelper';
import {
  STICKY_WIDGET_LAYOUT_REQUIRED_COUNT,
  SPACES_LIMIT_WITHOUT_INFINITE_SCROLL,
} from 'app/components/FoldersView/constants';

const CREATE_FOLDER_CONTEXT_MODAL = React.createContext();
const ASSIGN_BOARD_MUTATION = queryLoader('app/graphql/AssignBoard.gql');
const INVITE_QUERY = queryLoader('app/graphql/JoinSpaceRequest.gql');
// Come up from the bottom of the screen when an object is selected to be assigned to other objects
class AssignDrawer extends Component {
  state = {
    active: 'Folder',
    toggleCreateButton: false,
    query: '',
  };

  renderNewButton() {
    if (!isWorkspaceView() || isNewSubSpaceAllowedInWorkspace())
      return (
        <OsBtn
          text={`Add To New ${this.state.active}`}
          name='BtnPrimary'
          key='link'
          onClick={this.handleNewButton}
          associatedEntity={this.props.assign.objects[0]}
        />
      );
  }

  handleNewButton = () => {
    this.setState({ toggleCreateButton: !this.state.toggleCreateButton });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.assign.status !== 'overlay' &&
      this.props.assign.status === 'overlay'
    )
      window.$('html').addClass('non-bs-modal-open');
  }

  handleBoardCreate = () => {
    activateScroll();
    if (isNewSubSpaceAllowedInWorkspace()) {
      this.props.navigate(
        `${entityUrl(this.props.workspace.data, false, {
          skipWorkspace: true,
        })}/subspaces/new`,
      );
      this.props.assignCreate('Board', this.props.assign.objects);
    } else if (this.props.currentUser.ability.can('create', 'board')) {
      this.props.navigate('/spaces/new');
      this.props.assignCreate('Board', this.props.assign.objects);
    } else {
      this.openUpgradeModal();
    }
  };

  openUpgradeModal() {
    this.onClose();
    this.props.openUpgradeModal();
  }

  onSearchReset = () => {
    this.setState({ query: '' });
  };

  onClose = () => {
    this.onSearchReset();
    this.props.assignCreate(null, []);
    activateScroll();
    setTimeout(() => {
      window.$('html').removeClass('non-bs-modal-open');
    }, 210);
  };

  handleChange = (query) => {
    this.setState({ query });
  };

  showPrivateContentWaringIfRequired(obj) {
    if (
      this.props.currentUser.graph.show_private_content_warning &&
      isPrivateObject(obj)
    ) {
      this.props.openInfoModal('space', 'private_content_added', {
        contentInterpolations: { entity_type: obj.__typename.toLowerCase() },
      });
    }
  }

  onCardClick = (obj) => {
    let object = this.props.assign.objects[0],
      mutation,
      variables,
      sucessText = `${
        this.isInvite() ? 'Invited' : 'Saved'
      } ${this.getObjectsInfo()} to ${this.state.active}`;

    if (this.isInvite()) {
      mutation = this.props.inviteQuery;
      variables = {
        spaceId: obj.nice_id,
        userIds: [+object.id],
      };
    } else {
      this.showPrivateContentWaringIfRequired(this.props.assign.objects[0]);
      mutation = this.props.assignBoardMutation;
      variables = {
        id: obj.id,
        object_ids: map(this.props.assign.objects, 'id'),
        object_types: map(this.props.assign.objects, '__typename'),
      };
    }

    if (mutation) {
      mutation({ variables }).then((data) => {
        this.sendAssignTrackEvent({
          ...this.props.assign.objects[0],
          space_id: obj.id,
        });
        activateScroll();
        this.props.assignMessage(
          <div className='saved-to-entity-modal'>
            {sucessText}
            <div className='assign-link pull-right'>
              <OsBtn
                name='BtnIcon'
                extraClass='px-16'
                htmlTag='button'
                text='CLOSE'
                onClick={this.onClose}
                associatedEntity={obj}
              />
              <OsBtn
                name='BtnPrimary'
                className='btn-link-helper'
                htmlTag='Link'
                onClick={this.onClose}
                to={entityUrl(obj)}
                text={`GO TO ${this.state.active}`}
                associatedEntity={obj}
              />
            </div>
          </div>,
        );
      });
    }
  };

  sendAssignTrackEvent(obj) {
    EventTracker.trackForEntity('add_to_space', obj);
  }

  getEntityName = () => {
    let object = this.props.assign.objects[0];
    switch (object.__typename) {
      case 'Case':
        return `Case ${object.nice_id}`;
      case 'Tool':
        return object.product_name;
      case 'Comment':
        return 'Comment';
      case 'AdditionalLink':
        return 'Link';
      case 'Pulse':
        return 'Post';
      default:
        return object.name;
    }
  };

  getAdditionalFiltersForData() {
    let filters = { only_content_space: true };
    if (this.isInvite()) filters.only_collaborating_spaces = true;
    return filters;
  }

  renderAutoSuggestInput() {
    return (
      <div className='assign-search-container'>
        <AutoSuggestSearchInput
          type='folders'
          onHandleChange={this.handleChange}
          className='autosuggest-search-container'
          dropDown={true}
          filter='your'
          additional_filters={this.getAdditionalFiltersForData()}
        />
      </div>
    );
  }

  isModalOpen() {
    return this.props.assign.status === 'overlay';
  }

  isInvite() {
    return this.props.assign.type === 'invite';
  }

  getObjectsInfo() {
    if (this.props.assign.objects.length === 1) {
      let name = this.getEntityName();
      return name.trim().length
        ? `"${name}"`
        : this.props.assign.objects[0].__typename;
    } else {
      return `${this.props.assign.objects.length} items`;
    }
  }

  getModalTitle() {
    return `${this.isInvite() ? 'Invite' : 'Add'} ${this.getObjectsInfo()} to ${
      this.state.active
    }`;
  }

  extractCategoryName(text) {
    return capitalize(lowerCase(text.split(' ')[1].slice(0, -1)));
  }

  onSelectCategory = (props) => {
    this.setState({ active: this.extractCategoryName(props.target.innerText) });
  };

  isActiveTab = (props) => {
    return this.state.active === props;
  };

  toggleCreate = () => {
    this.setState({ toggleCreateButton: !this.state.toggleCreateButton });
  };

  myFoldersCount() {
    return this.props.currentUser.graph.visible_folders_count;
  }

  stickyWidgetRequired() {
    return (
      !this.props.device.mobileDevice &&
      this.myFoldersCount() > STICKY_WIDGET_LAYOUT_REQUIRED_COUNT
    );
  }

  getCurrentSortFilterKey() {
    return getSearchParamValue('sort') || 'default';
  }

  renderCollection() {
    let newFolder = {
        createFolder: this.state.toggleCreateButton,
        toggleCreate: this.toggleCreate,
        onCardClick: this.onCardClick,
        space: this.props.workspace.data,
      },
      { mobileDevice } = this.props.device,
      infiniteScroll = this.stickyWidgetRequired(),
      sortFilter = this.getCurrentSortFilterKey(),
      mySpaceCardShow =
        this.props.device.width < 1900
          ? SPACES_LIMIT_WITHOUT_INFINITE_SCROLL
          : SPACES_LIMIT_WITHOUT_INFINITE_SCROLL + 1,
      additionalProps = {
        initialLimit: mySpaceCardShow,
        limit: STICKY_WIDGET_LAYOUT_REQUIRED_COUNT,
        seeAllNotRequired: true,
        cardProps: {
          orderRowWise: false,
          cardGridIdentifier: 'SpacesIndex:MySpaceList',
        },
      };

    return (
      <div className='modal-body'>
        <div className='modal-body-container'>
          <div
            className='modal-body-content y-scroll'
            ref={(el) => (this.collectionContainer = el)}>
            {this.state.active === 'Folder' && (
              <CREATE_FOLDER_CONTEXT_MODAL.Provider value={newFolder}>
                <RecordsPager
                  queryType='SPACE_DETAILS'
                  loadingCards={true}
                  type='CollaboratoringFolders'
                  infiniteScroll={true}
                  cardSize={'extra-small'}
                  textQuery={this.state.query}
                  sortQuery={sortFilter}
                  {...additionalProps}
                  windowNotRequired={true}
                />
              </CREATE_FOLDER_CONTEXT_MODAL.Provider>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    //ul tab used here has to be changed to simple nav list while working on tab mixPanel story.
    if (this.isModalOpen()) {
      let tabs = ['Folder'];
      return (
        <div className='assign-background'>
          <div className='comment-share add-to-message-modal pb-3 assign-overlay assign-overlay--show'>
            <div className='container modal-xl modal-global add-message-modal add-to-board-modal assign-overlay__content'>
              <div className='add-modal-top-block'>
                <div className='modal-header add-message-header'>
                  <div className='row add-message-header-row'>
                    <div className='col-sm-12 add-message-title-row'>
                      <h4 className='card-title'>
                        <OrthoIcon
                          name='add'
                          dataHoverNotRequired={true}
                          defaultClass='me-3'
                        />
                        {this.getModalTitle()}
                      </h4>
                      <OsBtn
                        name='BtnIcon'
                        extraClass='no-text os-header-btn web-view-btn y-center close-assign-overlay'
                        icon='close'
                        onClick={this.onClose}
                        associatedEntity={this.props.assign.objects[0]}
                        label='close assign modal'
                      />
                    </div>

                    <div className='col-sm-12 add-message-nav-block'>
                      <div className='object-header-bar add-message-primary-nav assign-drawer-modal'>
                        {this.renderAutoSuggestInput()}
                        {this.renderNewButton()}
                      </div>
                    </div>

                    <div className='add-message-secondary-nav-block'>
                      <div className='object-header-bar'>
                        <ul
                          className='object-filter list-inline'
                          onClick={this.onSelectCategory}>
                          {tabs.map((tab) => (
                            <li
                              className={`list-inline-item ${
                                this.isActiveTab(tab)
                                  ? 'object-filter-active'
                                  : ''
                              }`}
                              value={tab}>
                              Your {tab}s
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='object-search-block'></div>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderCollection()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

AssignDrawer = compose(
  graphql(ASSIGN_BOARD_MUTATION, { name: 'assignBoardMutation' }),
  graphql(INVITE_QUERY, { name: 'inviteQuery' }),
)(AssignDrawer);

AssignDrawer = connect(
  ({ currentUser, assign, workspace, device }) => ({
    currentUser,
    assign,
    workspace,
    device,
  }),
  {
    assignConsume,
    setAssignType,
    assignCreate,
    assignMessage,
    openInfoModal,
    openUpgradeModal,
  },
)(AssignDrawer);

AssignDrawer = withApollo(AssignDrawer);
AssignDrawer = withRouter(AssignDrawer);
export { CREATE_FOLDER_CONTEXT_MODAL };
export default AssignDrawer;
