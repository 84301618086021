import {
  buildFormattedIds,
  sortFilesAndGetAttachment,
} from 'app/utils/entitiesHelper';
import { map } from 'lodash';
import { useRef, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import {
  isCareWorkspaceView,
  isWorkspaceView,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import dayjs from 'dayjs';
import { openInfoModal } from 'app/actions/infoModal';
import { useDispatch } from 'react-redux';
import { isCareSpace } from 'app/utils/spaceHelper';

const PATIENT_CONTACT_MENTION_GROUP_IDENTIFIER = 'guardians';
const WRITE_COMMENT_MUTATION = queryLoader('app/graphql/WriteComment.gql');
const ADD_SCHEDULE_MESSAGE = queryLoader(
  'app/graphql/mutations/Comments/AddScheduleMessage.gql',
);
const SHARE_WITH_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithPatientAndResponseParties.gql',
);

function useTipTapEditorForMobile({ editor, entity, hideEditor }) {
  //State
  const [files, setFiles] = useState(sortFilesAndGetAttachment([]));
  const [objects, setObjects] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [toggled, setToggled] = useState(false);
  const [dropdownState, setDropdownState] = useState({
    schedule: false,
    calendar: false,
  });

  //Hooks
  let editorContent = useRef('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Mutations
  const [addPost, { data, loading, error }] = useMutation(
    WRITE_COMMENT_MUTATION,
  );
  const [addScheduleMessage] = useMutation(ADD_SCHEDULE_MESSAGE);
  const [shareWithPatient] = useMutation(SHARE_WITH_PATIENT_MUTATION);

  let isInternalNote = editor.type === 'team';

  const handleDropdownState = (key, value) => {
    setDropdownState({ ...dropdownState, [key]: value });
  };

  const selectCustomeTime = (e, time) => {
    if (time === 'custom') {
      setDropdownState({
        schedule: false,
        calendar: true,
      });
    } else {
      setSelectedTime(time);
      handleDropdownState('schedule', false);
    }
  };

  const clearSelectedTime = () => {
    setSelectedTime(null);
  };

  const handleDateChange = (date) => {
    handleDropdownState('calendar', false);
    setSelectedTime(dayjs(date).format('LLL'));
  };

  const getSubmitButtonText = () => {
    let text = '';
    if (isCareWorkspaceView()) {
      text = 'SEND MESSAGE';
    } else {
      if (selectedTime !== null) {
        if (editor.type === 'team') {
          text = 'SCHEDULE FOR INTERNAL';
        } else {
          text = 'SCHEDULE FOR PATIENT';
        }
      } else if (editor.type === 'team') {
        text = 'Add Internal Note';
      } else {
        text = 'SEND TO PATIENT';
      }
    }
    return text;
  };

  const handleContent = (content) => {
    editorContent.current = content;
  };

  const getMentionGroups = () => {
    if (isInternalNote) return [];
    return entity.mention_groups || [];
  };

  const getPatientContactMentionGroup = () => {
    return getMentionGroups().find(
      (group) => group.identifier === PATIENT_CONTACT_MENTION_GROUP_IDENTIFIER,
    );
  };

  const getPlaceholderText = () => {
    if (isInternalNote)
      return `Mention your team members with @ to bring their attention directly to an internal note.`;
    return `Add new posts here. Use '@patient' to mention the patient directly, '@Patient Contacts' to notify the patient and their contact group and ‘@clinicteam’ to get your colleagues attention.`;
  };

  const shareCareSpace = async () => {
    let shareableParties = [
      entity.owner.patient,
      ...entity.owner.patient.person_connections.flatMap(
        (s) => s.related_person,
      ),
    ]
      .filter((p) => p.email || p.phone_number)
      .map((p) => p.id);

    let response = await shareWithPatient({
      variables: {
        careSpaceId: this.props.entity.id,
        shareWithPersonIds: shareableParties,
      },
    });
  };

  const handleSubmit = async () => {
    if (selectedTime !== null) {
      scheduleMessage();
      return;
    }
    let variables = {
      commentableId: entity.nice_id,
      commentableType: 'Board',
      content: editorContent.current,
      type: 'Discussion',
      links: buildFormattedIds(objects),
      files: map(files, (file) => file.id),
    };

    if (isInternalNote) {
      variables.visibleAudienceGroupIds = [];
    }
    hideEditor();
    navigate(withWorkspaceIdentifier(`/spaces/${entity.nice_id}/activity/all`));
    if (
      !entity.shared_with_any_patient &&
      isCareSpace(entity) &&
      isWorkspaceView() &&
      !isInternalNote
    ) {
      dispatch(
        openInfoModal('general', 'care_space_not_shared_warning', {
          onSuccess: () => {
            shareCareSpace();
          },
        }),
      );
    }
    try {
      let response = await addPost({
        variables,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const scheduleMessage = async () => {
    let variables = {
      content: editorContent.current,
      entityId: entity.nice_id,
      entityType: entity.__typename,
      links: buildFormattedIds(objects),
      files: map(files, (file) => file.id),
      time: selectedTime,
      visibleToGroupIds: isInternalNote
        ? []
        : [parseInt(getPatientContactMentionGroup()?.id)],
    };

    try {
      let response = await addScheduleMessage({
        variables,
      });
      hideEditor();
      setSelectedTime(null);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    toggled,
    editorContent,
    files,
    objects,
    dropdownState,
    selectedTime,
    setFiles,
    setObjects,
    handleSubmit,
    handleContent,
    getPlaceholderText,
    getMentionGroups,
    handleDropdownState,
    selectCustomeTime,
    clearSelectedTime,
    handleDateChange,
    getSubmitButtonText,
  };
}

export default useTipTapEditorForMobile;
