import LocalStorageManager from 'app/services/LocalStorageManager';

import {
  DISABLE_PRESENTATION_MODE,
  ENABLE_PRESENTATION_MODE,
} from 'app/actions/types';

export default function presentationMode(state = {}, action) {
  let enabled = LocalStorageManager.get('presentationMode') || false;

  switch (action.type) {
    case ENABLE_PRESENTATION_MODE:
      return { ...state, enabled: enabled };
    case DISABLE_PRESENTATION_MODE:
      return { ...state, enabled: enabled };
    default:
      return { ...state, enabled: enabled };
  }
}
