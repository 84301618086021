import React, { Component } from 'react';
import Loader from 'react-loaders';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import scrollToElement from 'scroll-to-element';

import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';
import PopularHashTags from 'app/components/Widgets/PopularHashTags';
import RecordsPager from 'app/components/RecordsPager';
import WriteComment from 'app/components/Comments/WriteComment';

import { setActiveResource } from 'app/actions/activeResource';

import { entityCommentsUrl, hashTagUrl } from 'app/utils/entitiesHelper';
import { filter, isEqual, lowerCase } from 'app/utils/osLodash';
import { isInViewport } from 'app/utils/domHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { pluralize } from 'app/utils/generalHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

import {
  ATTACHMENT_PREVIEW_URL_HASH,
  ENTITY_NAME_MAPPER,
  MOBILE_HEADER_HEIGHT,
} from 'app/constants';
import {
  COMMENT_URL_HASH_FORMAT,
  COMMENT_WITH_NEW_REPLY_URL_HASH_FORMAT,
  COMMENTS_BATCH_SIZE,
  EDIT_COMMENT_URL_HASH_FORMAT,
  SPACE_COMMENT_ORDER_DROPDOWN_OFFSET_TOP,
  SORTING_PARAMETERS,
} from './constants';
import CareSpaceActivityLogs from 'app/components/CareSpace/ActivityLogs';
import { isCareSpace } from 'app/utils/spaceHelper';

const COMMENTABLE_QUERY = queryLoader(
  'app/graphql/queries/CommentableQuery.gql',
);

class CommentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aroundId: null,
      beforeHasMore: false,
      editCommentId: null,
      keyToggler: true,
      replyToCommentId: null,
      scrollToCommentId: null,
      sortBy: 'recent',
      sortedCommentsIds: [],
      searchParams: '',
    };
  }

  componentDidMount() {
    this.setScrollToCommentIdFromUrl();
    this.setAroundIdFromUrl();
    this.onCommentableLoad();
    if (this.additionalFilter()) {
      setTimeout(() => {
        this.scrollToSelectedTag();
      }, 2000);
    } else if (!this.props.data) {
      this.scrollToHashFromUrl();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data &&
      prevProps.data.loading &&
      this.props.data &&
      !this.props.data.loading
    ) {
      this.props.scrollIfNeeded();
      this.scrollToHashFromUrl();
      this.onCommentableLoad();
      this.updateActiveResourceIfRequired();
    }

    if (
      this.props.data &&
      !isEqual(this.props.data.commentable, prevProps.data.commentable)
    ) {
      this.updateActiveResourceIfRequired();
      this.onCommentableLoad();
    }

    if (
      prevProps.location.hash !== ATTACHMENT_PREVIEW_URL_HASH &&
      this.props.location.hash !== ATTACHMENT_PREVIEW_URL_HASH &&
      prevProps.location.hash !== this.props.location.hash
    ) {
      let commentIdPresentInHash = this.commentIdPresentInHash();
      if (commentIdPresentInHash) {
        // Note: If URL hash is changed and comment id is present in it, then refetch the comments around it
        this.refetchComments(commentIdPresentInHash);
      } else {
        this.scrollToHashFromUrl();
      }
    }

    if (
      prevProps.match.params.additionalFilter !== this.additionalFilter() &&
      this.selectedTagRef
    )
      this.scrollToSelectedTag();
  }

  updateActiveResourceIfRequired() {
    let {
        activeResource,
        data: { commentable },
      } = this.props,
      resource = activeResource?.resource;

    if (
      resource &&
      commentable.__typename === resource.__typename &&
      commentable.id === resource.id
    ) {
      this.props.setActiveResource({
        ...resource,
        ...commentable,
        owner: { ...resource.owner, ...commentable.owner },
      });
    }
  }

  onCommentableLoad() {
    const { commentable } = this.props.data || {};
    commentable && this.props.onCommentableLoad(commentable);
  }

  commentable() {
    return (
      (this.props.data ? this.props.data.commentable : this.props.entity) || {}
    );
  }

  replaceHistoryWithCurrentUrl() {
    this.props.navigate(this.props.location.pathname);
  }

  setAroundIdFromUrl() {
    this.props.location.hash && this.setAroundId(this.commentIdPresentInHash());
  }

  setScrollToCommentIdFromUrl() {
    if (this.props.isBlurred) return;

    this.props.location.hash &&
      this.setScrollToCommentId(this.commentIdPresentInHash());
  }

  setAroundId = (aroundId) => {
    this.setState({ aroundId });
  };

  setScrollToCommentId = (scrollToCommentId) => {
    this.setState(
      {
        scrollToCommentId,
        replyToCommentId: scrollToCommentId && this.state.replyToCommentId,
      },
      () => {
        this.state.scrollToCommentId && this.replaceHistoryWithCurrentUrl();
      },
    );
  };

  setEditCommentId = (editCommentId) => {
    this.setState({ editCommentId });
    this.setScrollToCommentId(editCommentId);
  };

  commentIdPresentInHash() {
    let commentId = null,
      hashFormat = this.props.location.hash.replace(/[0-9]/g, '');

    if (hashFormat === COMMENT_URL_HASH_FORMAT) {
      commentId = this.props.location.hash.replace(COMMENT_URL_HASH_FORMAT, '');
    } else if (hashFormat === COMMENT_WITH_NEW_REPLY_URL_HASH_FORMAT) {
      commentId = this.props.location.hash.replace(
        COMMENT_WITH_NEW_REPLY_URL_HASH_FORMAT,
        '',
      );
      commentId && this.setState({ replyToCommentId: commentId });
    } else if (hashFormat === EDIT_COMMENT_URL_HASH_FORMAT) {
      commentId = this.props.location.hash.replace(
        EDIT_COMMENT_URL_HASH_FORMAT,
        '',
      );
      commentId && this.setState({ editCommentId: commentId });
    }
    return commentId && +commentId;
  }

  scrollToHashFromUrl() {
    if (this.props.isBlurred) return;

    if (this.props.location.hash && !this.state.scrollToCommentId) {
      let element = window.$(this.props.location.hash)[0];
      this.replaceHistoryWithCurrentUrl();

      if (element && !isInViewport(element)) {
        let offset = this.props.device.mobileDevice
          ? -MOBILE_HEADER_HEIGHT - 15
          : -15;
        scrollToElement(element, {
          ease: 'linear',
          offset: offset,
          duration: 500,
        });
      }
    }
  }

  refetchComments = ({ id: commentId }) => {
    this.setAroundId(commentId);
    this.setScrollToCommentId(commentId);
    this.setState({ keyToggler: !this.state.keyToggler });
  };

  changeSort = (sortBy) => {
    this.setState({ sortBy });
    this.refetchComments();
    this.scrollToCommentsSection();
  };

  scrollToSelectedTag() {
    scrollToElement(this.selectedTagRef, {
      ease: 'linear',
      offset: -250,
      duration: 500,
    });
  }

  scrollToCommentsSection() {
    let offset;
    if (this.props.device.mobileDevice) {
      offset = -75;
      if (this.isCommentable('Board'))
        offset -= SPACE_COMMENT_ORDER_DROPDOWN_OFFSET_TOP.mobile;
    } else {
      offset = 30;
      if (this.isCommentable('Board')) {
        offset -= SPACE_COMMENT_ORDER_DROPDOWN_OFFSET_TOP.nonMobile;
      }
    }
    scrollToElement(this.commentsSectionRef, {
      ease: 'linear',
      offset,
      duration: 500,
    });
  }

  updateBeforeHasMore = (beforeHasMore) => {
    this.setState({ beforeHasMore });
  };

  isReadOnly() {
    return this.props.readOnly || !this.commentable().can_user_comment;
  }

  dropdownInfo() {
    let title = filter(SORTING_PARAMETERS, { value: this.state.sortBy })[0]
      .name;
    return { title };
  }

  getEntityOwner() {
    let entity = this.props.entity;
    switch (entity.__typename) {
      case 'Board':
      case 'Comparison':
        return entity.user;
      case 'Case':
        return entity.doctor;
      case 'Pulse':
        return entity.author;
      default:
        return null;
    }
  }

  isCommentable(type) {
    return this.props.commentableType === type;
  }

  additionalFilter() {
    const searchParams = new URLSearchParams(this.props.location.search);
    return searchParams.get('filter');
  }

  seeAllComments = () => {
    this.props.navigate(entityCommentsUrl(this.props.entity));
  };

  renderNoCommentBlock() {
    return (
      <div className='text-center mt-5'>
        <Icon name='noComments' />
        <div className='pt-4 mt-2 no-comment-added'>
          <span className='semibold'>No comments were added</span>
        </div>
      </div>
    );
  }

  commentableType() {
    return lowerCase(
      ENTITY_NAME_MAPPER[lowerCase(this.props.commentableType)] ||
        this.props.commentableType,
    );
  }

  renderSeletedTag() {
    return (
      <div
        className='hashtag-filter sticky-hashtag-filter'
        ref={(el) => (this.selectedTagRef = el)}>
        <span className='hashtag-filter-text'>
          Search within this {this.commentableType()}
        </span>
        <span className='tag-item sticky-tag-item'>
          <span className='tag-item-text'>#{this.additionalFilter()}</span>
          <OrthoIcon
            name='close'
            dataHoverNotRequired={true}
            defaultClass='fs-12 ps-2'
            onClick={this.seeAllComments}
          />
        </span>
        {!isCareWorkspaceView() && (
          <OsLink
            className='ms-3'
            to={hashTagUrl(this.additionalFilter())}
            text='SHOW ALL RESULTS'
          />
        )}
      </div>
    );
  }

  getFilters() {
    return this.props.currentUser.graph
      ? SORTING_PARAMETERS
      : SORTING_PARAMETERS.filter((tab) => !tab.guestUserNotAllowed);
  }

  renderHeader() {
    let commentsCount = this.commentable().comments_count,
      additionalFilter = this.additionalFilter();

    return (
      <div className=''>
        {!additionalFilter && !this.isCommentable('Board') && (
          <div className='heading'>
            {pluralize(commentsCount, {
              singular: 'Comment',
              plural: 'Comments',
            })}{' '}
            <span>({commentsCount})</span>
          </div>
        )}

        {!!additionalFilter && this.renderSeletedTag()}

        {!isCareSpace(this.props.entity) && commentsCount > 1 && (
          <CustomDropdown
            chevronIcon='chevron-caret'
            name='comment_sort'
            sortBy={this.state.sortBy}
            changeSort={this.changeSort}
            dropdownInfo={this.dropdownInfo()}
            className='comments-sort-dropdown ms-auto'>
            {this.getFilters().map((sort) => (
              <div
                key={sort.value}
                value={sort.value}
                data-hover={!isTouchSupported()}
                className={
                  'os-btn-link ' +
                  (this.state.sortBy === sort.value ? 'active' : '')
                }>
                <OrthoIcon name={sort.icon} defaultClass='sorting-icon' />{' '}
                {sort.name}
              </div>
            ))}
          </CustomDropdown>
        )}
      </div>
    );
  }

  renderWriteComment() {
    let containerClasses = `detail-comments-header ${
        this.props.extraClass || ''
      } `,
      commentable = this.commentable(),
      commentsCount = commentable.comments_count,
      refetchOnCreateRequired = commentsCount < 1 || this.state.beforeHasMore,
      entity = this.getEntity();

    containerClasses += this.isCommentable('Board')
      ? 'space-comments'
      : `${this.props.commentableType.toLowerCase()}-comments`;
    return (
      <div
        className={containerClasses}
        id='comments'
        style={{ marginBottom: '30px' }}>
        <WriteComment
          aroundId={this.state.aroundId}
          author={this.getEntityOwner()}
          commentableEntityOwner={this.getEntityOwner()}
          commentableId={this.props.commentableId}
          commentableType={this.props.commentableType}
          entity={entity}
          newComment={true}
          refetchOnCreate={refetchOnCreateRequired && this.refetchComments}
          setScrollToCommentId={this.setScrollToCommentId}
          sortQuery={this.state.sortBy}
          textQuery={this.additionalFilter()}
        />
      </div>
    );
  }

  isPopularHastagNeeded() {
    return (
      this.props.device.mobileDevice &&
      this.commentable().comments_count > 0 &&
      this.isCommentable('Board')
    );
  }

  getEntity() {
    return { ...this.props.entity, ...(this.props.data?.commentable || {}) };
  }

  renderComments() {
    let { commentableId, commentableType } = this.props;
    if (isCareSpace(this.props.entity)) {
      return <CareSpaceActivityLogs entity={this.getEntity()} />;
    }

    return (
      <RecordsPager
        aroundId={this.state.aroundId}
        commentable={this.commentable()}
        commentableEntityOwner={this.getEntityOwner()}
        commentableId={commentableId}
        commentableType={commentableType}
        editCommentId={this.state.editCommentId}
        entity={this.getEntity()}
        infiniteUpwardsScroll={true}
        isBlurred={this.props.isBlurred}
        key={this.state.keyToggler}
        limit={COMMENTS_BATCH_SIZE}
        queryType='COMMENTS'
        readOnly={this.isReadOnly()}
        recordId={commentableId}
        recordType={commentableType}
        refetchOnPin={this.state.beforeHasMore && this.refetchComments}
        replyToCommentId={this.state.replyToCommentId}
        scrollToCommentId={this.state.scrollToCommentId}
        setEditCommentId={this.setEditCommentId}
        setScrollToCommentId={this.setScrollToCommentId}
        sortQuery={this.state.sortBy}
        textQuery={this.additionalFilter()}
        type='Comments'
        updateBeforeHasMore={this.updateBeforeHasMore}
        scrollIfNeeded={this.props.scrollIfNeeded}
      />
    );
  }

  render() {
    if (this.props.data && this.props.data.loading)
      return (
        <Loader type='ball-triangle-path' active className='mt-5 hgt-100' />
      );

    let commentsCount = this.commentable().comments_count || 0;

    if (this.isReadOnly() && !commentsCount) return this.renderNoCommentBlock();

    return (
      <div id='comments' className='detail-comments px-0 col-12'>
        {!this.props.device.mobile && this.isPopularHastagNeeded() && (
          <PopularHashTags entity={this.props.entity} />
        )}
        {!this.isReadOnly() &&
          isCareWorkspaceView() &&
          !this.props.device.mobile &&
          this.renderWriteComment()}
        <div
          className={`discussion-thread-section ${
            this.props.replyBlockClass || ''
          }`}
          ref={(el) => (this.commentsSectionRef = el)}>
          {commentsCount > 0 && this.renderHeader()}
          {this.renderComments()}
        </div>
      </div>
    );
  }
}

CommentSection.defaultProps = {
  scrollIfNeeded: () => {},
  onCommentableLoad: () => {},
};

CommentSection = graphql(COMMENTABLE_QUERY, {
  skip: (props) =>
    props.commentableType !== 'Board' || !props.currentUser.graph,
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      commentableId: props.commentableId,
      commentableType: props.commentableType,
      spaceId: props.commentableType === 'Board' ? props.commentableId : '',
    },
  }),
})(CommentSection);

CommentSection = connect(
  ({ activeResource, currentUser, device }) => ({
    activeResource,
    currentUser,
    device,
  }),
  { setActiveResource },
)(CommentSection);
CommentSection = withRouter(CommentSection);
export default CommentSection;
