import React from 'react';

import ConnectToSupport from 'app/components/ConnectToSupport';
import NotificationCardLayout from './NotificationCardLayout';

class NotificationEntityUnpublishedCard extends NotificationCardLayout {
  renderBody() {
    let className = this.props.isSmallNotification
      ? ' support-notification-action '
      : '';
    return (
      <div className={className}>
        <ConnectToSupport
          viewType='button'
          text='Contact Support'
          onRedirectToSupport={this.readNotification}
        />
      </div>
    );
  }
}

export default NotificationEntityUnpublishedCard;
