import {
  MERGE_CURRENT_WORKSPACE_DATA,
  TOOGLE_WORKSPACE_DROPDOWN_STATE,
  UPDATE_CURRENT_WORKSPACE_DATA,
  UPDATE_GUEST_WORKSPACE_OWNER_DATA,
  UPDATE_PASSWORD_PRESENT_FIELD,
} from 'app/actions/types';

import { addClassToHTML } from 'app/utils/domHelper';

export default function workspace(state = null, action) {
  if (!state) {
    state = { identifier: null, data: {}, dropdownOpen: false };
    state.identifier && addClassToHTML('workspace');
  }
  const identifier = state.identifier || action.payload?.identifier;
  switch (action.type) {
    case UPDATE_CURRENT_WORKSPACE_DATA:
      return { ...state, data: action.payload, identifier };
    case TOOGLE_WORKSPACE_DROPDOWN_STATE:
      return { ...state, dropdownOpen: !state.dropdownOpen };
    case UPDATE_GUEST_WORKSPACE_OWNER_DATA:
      return { ...state, guestOwnerData: action.payload };
    case MERGE_CURRENT_WORKSPACE_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        identifier,
      };
    case UPDATE_PASSWORD_PRESENT_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          user_request: {
            ...state.data.user_request,
            password_present: action.payload,
          },
        },
      };
    default:
      return state;
  }
}
