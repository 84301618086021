import React, { Component } from 'react';

import GuestInfoView from 'app/components/WorkspaceView/GuestInfoView';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import SignupForm from './SignupForm';
import withWorkspaceRequest from 'app/components/HOC/withWorkspaceRequest';

class SignupScreen extends Component {
  render() {
    return (
      <ImageFormLayout
        leftBar={<GuestInfoView />}
        rightBar={<SignupForm extraClass='col-12' />}
        source='clinic_invite'
      />
    );
  }
}

SignupScreen = withWorkspaceRequest(SignupScreen);
export default SignupScreen;
