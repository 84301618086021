import {
  RESET_STATE,
  SET_CURRENT_SLIDE,
  SET_DEFAULT_STATE,
  SET_MODE_TYPE,
  SET_RIGHT_SIDE_SELECTED_APPOINTMENT,
  SET_SELECTED_APPOINTMENT,
  SET_VIEW_TYPE,
  SET_ACTIVE_APPLIANCE_LOG_CARD,
} from './types';

export function setModeType(modeType) {
  return {
    type: SET_MODE_TYPE,
    modeType,
  };
}

export function setCaseViewType(viewType) {
  return {
    type: SET_VIEW_TYPE,
    viewType,
  };
}

export function setCurrentSlide(currentSlide) {
  return {
    type: SET_CURRENT_SLIDE,
    currentSlide,
  };
}

export function setSelectedAppointment(selectedAppointment) {
  return {
    type: SET_SELECTED_APPOINTMENT,
    selectedAppointment,
  };
}

export function setRightSideAppointment(selectedAppointment) {
  return {
    type: SET_RIGHT_SIDE_SELECTED_APPOINTMENT,
    selectedAppointment,
  };
}

export function setCaseDetailValuesToDefault() {
  return {
    type: SET_DEFAULT_STATE,
  };
}

export function resetCaseInfo(initialAppointment, finalAppointment) {
  return {
    type: RESET_STATE,
    initialAppointment,
    finalAppointment,
  };
}

export function setActiveApplianceLogCardId(cardId) {
  return {
    type: SET_ACTIVE_APPLIANCE_LOG_CARD,
    cardId,
  };
}
