import React, { Component } from 'react';
import { connect } from 'react-redux';

import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import Login from './login';

import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { translate } from 'app/actions/flashMessage';

class LoginView extends Component {
  renderWorkspaceWelcomeText() {
    const { guestOwnerData } = this.props.workspace;
    if (isCareWorkspaceView()) {
      if (guestOwnerData) {
        return (
          <>
            <div className='auth-heading'>
              Committed to Better Care, Together
            </div>
            <div
              className='patient-wel-text'
              dangerouslySetInnerHTML={{
                __html: translate('WELCOME_TEXT_CARESPACE_WITH_OWNER', {
                  clinic_name: guestOwnerData.name,
                }),
              }}
            />
          </>
        );
      } else {
        return translate('WELCOME_TEXT_CARESPACE');
      }
    } else if (isWorkspaceView()) {
      return translate('WELCOME_TEXT_WORKSPACE', {
        clinic_name: guestOwnerData?.name,
      });
    } else {
      return (
        <>
          <div className='auth-heading'>Committed to Better Care, Together</div>
          <div className='patient-wel-text'>
            <b>Cloudberry</b> works with your doctor to bring better care during
            every step of your treatment. Using your own personal PatientSpace you
            can engage in your treatment, chat directly with the care team, and
            stay on top of updates relating to you or your loved one's care.
          </div>
        </>
      );
    }
  }

  render() {
    return (
      <ImageFormLayout
        leftBar={this.renderWorkspaceWelcomeText()}
        rightBar={<Login isWorkspace={isWorkspaceView()}/>}
        source='login_form'
      />
    );
  }
}

LoginView = connect(
  ({ currentUser, workspace, device }) => ({ currentUser, workspace, device }),
  {},
)(LoginView);

export default LoginView;
