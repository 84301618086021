export const getPartnerClinicName = (space) => {
  return getPartnerClinicOrRequest(space)?.name;
};

export const getPartnerClinicOrRequest = (space) => {
  return (
    space.clinic_share_request?.clinic_workspace || space.clinic_share_request
  );
};

export const getPartnerClinicLogo = (space) => {
  return getPartnerClinicOrRequest(space)?.owner?.owner?.logo_small;
};

export const convertTextToTitleCase = (text) => {
  return text
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
