import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import Icon from 'app/components/Svg';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { translate } from 'app/actions/flashMessage';
import SelectInput from 'app/components/Shared/SelectInput';

class LeaveConfirmationModal extends Component {
  state = {
    requestInProgress: false,
    transferOwnershipUser: null,
  };

  toggleRequestInProgress = () => {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  };

  leaveEntity = async (event) => {
    this.toggleRequestInProgress();
    this.props.leaveEntity(false, event, {
      transferOwnershipUser: this.state.transferOwnershipUser,
    });
  };

  entityType() {
    return this.props.entityType;
  }

  renderHeaderContent = () => {
    return (
      <div
        className='modal-header border-0 px-0 pt-0 header-tab-combo'
        key='headerAndTabs'>
        <h4 className='modal-title' id='modalLabel'>
          {this.props.headerTitle || <> Leave this {this.entityType()}</>}
        </h4>
        <OsBtn
          name='BtnIcon'
          extraClass='no-text os-header-btn web-view-btn r-0 close-del-confirmation'
          icon='close'
          label='Close leave confirmation modal'
          onClick={this.props.onClose}
        />
      </div>
    );
  };

  textContent = () => {
    switch (this.props.type) {
      case 'Members':
        return (
          <div className='modal-body-text'>
            <span className='title-primary'>
              {translate('REMOVE_MEMBER_CONFIRMATION')}
            </span>
            <br />
            <span className='title-secondary'>
              {translate('REMOVE_MEMBER_MIGRATE_USER_TEXT')}
            </span>
          </div>
        );
      default:
        return (
          <div className='modal-body-text'>
            {translate('LEAVE_SPACE_CONFIRMATION')}
          </div>
        );
    }
  };

  renderButtons = () => {
    return (
      <div className='m-0'>
        <OsBtn
          name='BtnIcon'
          extraClass='px-16'
          disabled={this.state.requestInProgress}
          loaderRequired={this.state.requestInProgress}
          text={this.props.secondaryButtonText || 'Leave ' + this.entityType()}
          onClick={this.props.onClose}
          associatedEntity={this.props.entity}
        />
        <OsBtn
          name='BtnPrimary'
          disabled={this.state.requestInProgress}
          text={this.props.primaryButtonText || 'Cancel'}
          onClick={this.leaveEntity}
          loaderRequired={this.state.requestInProgress}
          associatedEntity={this.props.entity}
        />
      </div>
    );
  };

  renderSVGContent = () => {
    return (
      <div className='svg-image mb-4 text-center'>
        <Icon name={this.props.svgIcon || 'delete'} />
      </div>
    );
  };

  selectMigrateUser = () => {
    return (
      <div style={{ margin: '8px 0 40px 0' }}>
        <SelectInput
          className={'os-menu-drop'}
          async={true}
          isMulti={false}
          perPage={10}
          placeholder={'Select a team member'}
          queryType={'PossibleConversationUsers'}
          formValue={this.state.transferOwnershipUser}
          onChange={(value) => {
            this.setState({ transferOwnershipUser: value?.entity });
          }}
          filterRecords={(records) => {
            return records.filter(
              (record) => record.id !== this.props.requestingUser.id,
            );
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={this.props.onClose}
        animation={false}
        dialogClassName='pulse-report-modal del-confirmation-modal'
        backdropClassName='modal-backdrop-custom'>
        {this.renderHeaderContent()}
        <div className='modal-body px-24'>
          {this.renderSVGContent()}
          {this.textContent()}
          {this.props.type === 'Members' && this.selectMigrateUser()}
        </div>
        <div className='modal-footer p-0 justify-content-md-end justify-content-center'>
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }
}

export default LeaveConfirmationModal;
