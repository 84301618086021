import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { UPDATE_SPACE_STATE } from './types';

const UPDATE_SPACE_STATE_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateSpaceState.gql',
);

export function updateSpaceState(attributes) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SPACE_STATE });
    let variables = {
      archive: attributes.archive,
      id: attributes.space.id,
    };

    return client
      .mutate({
        mutation: UPDATE_SPACE_STATE_MUTATION,
        variables: variables,
      })
      .then(({ data }) => {
        attributes.afterSuccessAction &&
          attributes.afterSuccessAction(data.updateSpaceState.entity);
      });
  };
}
