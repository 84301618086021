import { Field, Form } from 'react-final-form';
import { Modal } from 'react-bootstrap';
import DisplayFormButtons from 'app/components/AllModalsContainer/PartnerClinicModal/DisplayFormButtons';
import usePartnerClinicModal from 'app/components/AllModalsContainer/PartnerClinicModal/usePartnerClinicModal';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import {
  composeValidators,
  emailMustBeValid,
  fieldRequired,
} from 'app/utils/validationHelper';
import { useSelector } from 'react-redux';

const PartnerClinicModal = () => {
  const { partnerSpaces, onSubmit, closeModal } = usePartnerClinicModal();
  const workspace = useSelector((state) => state.workspace);
  const systemConfig = useSelector((state) => state.systemConfig);

  const defaultMessage =
    systemConfig.configs['partner_space_default_invite_message']?.value || '';
  return (
    <>
      <Modal
        className={'partner-spaces-modal'}
        show={partnerSpaces.open}
        backdrop={true}
        onHide={closeModal}>
        <Modal.Header>
          <h4>Invite Partner Clinic</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn'
            icon='close'
            label='Close Share modal'
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              countryCode: workspace.data?.owner?.owner?.country_code,
              message: defaultMessage,
            }}
            disableOnEnter={true}>
            {({ handleSubmit, form }) => {
              return (
                <form handleSubmit={handleSubmit}>
                  <div className='form-group-fields'>
                    <Field
                      name='clinic_name'
                      component={OsField}
                      osType='input'
                      required={true}
                      autoFocusRequired={true}
                      validate={fieldRequired}
                      placeholder='Clinic'
                      label='Clinic Name'
                    />
                    <Field
                      name='email'
                      component={OsField}
                      osType='input'
                      required={true}
                      validate={composeValidators(
                        fieldRequired,
                        emailMustBeValid,
                      )}
                      placeholder='address@clinic.com'
                      label='Owner or Admin Email'
                    />
                    <div className='form-group'>
                      <label className='not-mandatory'>Cell phone number</label>
                      <div className='cell-row'>
                        <Field
                          name='countryCode'
                          component={OsField}
                          osType='country_code_select'
                          placeholder='(123) 456-7890'
                          value={'91'}
                          onChange={(field, value) => form.change(field, value)}
                        />
                        <Field
                          name='phoneNumber'
                          autoComplete='off'
                          component={OsField}
                          osType='input'
                        />
                      </div>
                    </div>
                    <Field
                      name='additional_emails'
                      component={OsField}
                      formGroupExtraClass={'additional-email-field'}
                      osType='input'
                      placeholder='steve@clinic.com,john@clinic.com,dana@clinic.com'
                      label='Additional Emails to Invite'
                    />
                    <Field
                      required={true}
                      className='message-field'
                      name='message'
                      placeholder='Hi, I would like to invite you to join my clinic on ParikhOrtho. Please click the link below to join.'
                      component={OsField}
                      osType='textarea'
                      validate={fieldRequired}
                      label='Message'
                    />
                  </div>
                  <div>
                    <DisplayFormButtons
                      handleSubmit={handleSubmit}
                      closeModal={closeModal}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartnerClinicModal;
