import React from 'react';
import SearchResult from './SearchResult';

import { entityUrl } from 'app/utils/entitiesHelper';

const EDUCATORS_LIMIT_ON_MOBILE = 1;
const EDUCATORS_LIMIT_ON_WEB = 2;

export default ({
  id,
  name,
  user,
  category,
  nice_id,
  nice_url,
  categoryType,
  type,
  onClick,
  trackOnClick,
  frontend_url,
  ...rest
}) => {
  let onClickParams = { category: category, categoryType: categoryType },
    link,
    limit = rest.mobileDevice
      ? EDUCATORS_LIMIT_ON_MOBILE
      : EDUCATORS_LIMIT_ON_WEB,
    educatorsName = user.name;

  onClickParams = { ...onClickParams, query_by: 'boards', id_query: nice_url };

  if (category === 'spaces') {
    link = entityUrl({ frontend_url, type, nice_id, __typename: 'Board' });
  } else {
    link = `/search/${category}/spaces/id/${nice_url}`;
  }

  return (
    <SearchResult
      key={`Board:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>{educatorsName || user.name}</span>
        {name}
      </div>
    </SearchResult>
  );
};
