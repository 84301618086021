import React from 'react';

export default ({ query }) => {
  return (
    <div key='no-result-section'>
      <div>
        <span className='float-right'>0</span>
        {query}
      </div>
    </div>
  );
};
