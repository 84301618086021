import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MOBILE_HEADER_HEIGHT } from 'app/constants';

class StickyHeader extends Component {
  state = {
    isHeadSticky: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleSticky);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleSticky, false);
  }

  handleSticky = (e) => {
    if (this.props.stickyNotRequired) return;

    let stickyHeight = this.props.device.mobile ? MOBILE_HEADER_HEIGHT : 0,
      isHeadSticky =
        this.headTab &&
        window.$(this.headTab).offset().top -
          Math.ceil(window.$(window).scrollTop()) <=
          stickyHeight;

    this.setState(
      { isHeadSticky },
      this.props.isHeadSticky.bind(this, isHeadSticky),
    );
  };

  isHeadSticky() {
    return !this.props.stickyNotRequired && this.state.isHeadSticky;
  }

  setRef = (ref) => {
    this.headTab = ref;
    if (this.props.setRef) this.props.setRef(ref);
  };

  render() {
    return (
      <div
        className={
          'head-sticky ' +
          (this.isHeadSticky() ? 'fixed ' : '') +
          (this.props.extraClass || '')
        }
        ref={this.setRef}>
        <div className='sub-nav-background'>
          <span className='sticky-before'></span>
          <div
            className={
              'container-fluid xl-container ' +
              (this.props.containerClass || '')
            }
            style={{ position: 'relative' }}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

StickyHeader.defaultProps = {
  isHeadSticky: () => {},
};

StickyHeader = connect(({ device }) => ({ device }), {})(StickyHeader);

export default StickyHeader;
