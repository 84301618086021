import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrganizationVisitingCard from './OrganizationVisitingCard';

import { entityUrl } from 'app/utils/entitiesHelper';
import { cardSize } from 'app/utils/osCardHelper';
import { parameterize } from 'app/utils/stringHelper';
import { lowerCase, omit } from 'app/utils/osLodash';

import { ENTITY_NAME_MAPPER } from 'app/constants';

class VisitingCard extends Component {
  onCardClick = (e, options = {}) => {
    //insception due to additional link wrapper
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();

    let url = options.url || this.getObjectUrl();
    this.props.navigate(url, this.props.urlPushState || {});
  };

  getObject() {
    let { obj } = this.props;
    return obj;
  }

  getCardSize() {
    return cardSize({
      width: this.props.device.width,
      mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
      size: this.props.size,
    });
  }

  containerClasses() {
    let obj = this.getObject(),
      typename = obj.__typename,
      generic = `os-visiting-card-v2 `,
      cardSpecific = '',
      type = parameterize(
        lowerCase(ENTITY_NAME_MAPPER[lowerCase(typename)] || typename),
      );

    switch (this.getCardSize()) {
      case 'tall':
        generic += 'tall-card';
        cardSpecific += `os-lg-${type}`;
        break;
      case 'tall-min':
        generic += 'tall-card tall-min-card';
        cardSpecific += `os-lg-${type} ${type}-304`;
        break;
      case 'small':
        generic += 'small-card';
        cardSpecific += `os-sm-${type}`;
        break;
      case 'small-min':
        generic += 'small-card small-min-card';
        cardSpecific += `os-sm-${type} ${type}-240`;
        break;

      case 'extra-small':
        generic += 'extra-small-card extra-small-min-card';
        cardSpecific += `os-sm-${type}`;
        break;
      default:
    }

    generic += this.props.closeRequired ? ' with-cross ' : '';
    generic += this.props.selectionMode ? ' selection-mode ' : '';
    generic += this.props.insideModal ? ' inside-modal ' : '';
    generic += this.props.multiSelectMode ? ' multi-selection-mode ' : '';
    generic += this.props.avoidModalActions ? ' avoid-modal-actions ' : '';
    generic += this.props.disableCard ? ' pointer-none ' : '';
    // generic += this.isLectureCardOverlayReqiured() ? ' lecture-card' : ''

    return { generic, cardSpecific };
  }

  isSmall() {
    return ['small', 'small-min', 'extra-small'].includes(this.getCardSize());
  }

  cardProps() {
    return {
      containerClasses: this.containerClasses().cardSpecific,
      obj: this.getObject(),
      size: this.getCardSize(),
      onCardClick: this.onCardClick,
    };
  }

  renderCard() {
    let cardProps = this.cardProps(),
      object = this.getObject();
    if (object.__typename === 'Board') {
      if (
        object.owner?.__typename === 'Clinic' ||
        object.owner?.__typename === 'Company'
      ) {
        cardProps['obj'] = object.owner;
        return <OrganizationVisitingCard {...cardProps} />;
      } else {
        return null;
      }
    } else if (object.__typename === 'Clinic') {
      return <OrganizationVisitingCard {...cardProps} />;
    }
  }

  getObjectUrl() {
    let obj = omit(this.getObject(), 'frontend_url');
    return obj.modifiedUrl || entityUrl(obj);
  }

  render() {
    let obj = this.getObject();
    return (
      <a
        href={this.getObjectUrl()}
        className={this.containerClasses().generic}
        ref={(container) => (this.container = container)}
        data-card-id={`${obj.__typename}:${obj.id}`}>
        {this.renderCard()}
      </a>
    );
  }
}

VisitingCard = withRouter(VisitingCard);
const VisitingCardContainer = connect(({ device }) => ({ device }))(
  VisitingCard,
);

export default VisitingCardContainer;
