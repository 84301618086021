import React from 'react';
import RecordsView from 'app/components/RecordsView';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { PINNED_CONVERSATIONS_BATCH_SIZE } from 'app/constants';

const PinnedMessageModal = (props, ref) => {
  const { entity } = props;
  const queryType = props?.queryType || 'PINNED_CONVERSATION';
  const recordType = props?.recordType || 'ConversationPinnedMessagesListing';
  return (
    <Overlay
      show={props.show}
      target={props.target}
      placement='bottom'
      container={props.pinnedRef}
      containerPadding={20}
      rootClose={true}
      onHide={props.onHide}>
      <Popover id='popover-contained'>
        <Popover.Body>
          <RecordsView
            type={recordType}
            queryType={queryType}
            conversation={entity}
            key={entity.id}
            idQuery={entity.id?.toString()}
            perPage={PINNED_CONVERSATIONS_BATCH_SIZE}
            isReverse={true}
            threshold={1}
            size='full-view-listing'
            className=''
            trackScrolling={true}
          />
        </Popover.Body>
      </Popover>
    </Overlay>
  );
};

export default PinnedMessageModal;
