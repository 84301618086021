import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import DeleteConfirmationModal from 'app/components/Shared/DeleteConfirmationModal';

import {
  COMMENTS_PER_PAGE,
  REPLIES_PER_PAGE,
} from 'app/components/Comments/constant';
import StoreUpdater from 'app/services/StoreUpdater';

const REMOVE_COMMENT = queryLoader('app/graphql/mutations/RemoveEntity.gql');

// Todo: Clean up this file
class DeleteObject extends Component {
  state = {
    openDeleteModal: false,
  };

  getMetaInfo() {
    if (this.isComment()) {
      let isAReply = this.isAReply();
      return {
        recordId: isAReply ? this.props.object.parent_id : this.props.source.id,
        recordType: isAReply ? 'Comment' : this.props.source.type,
        limit: isAReply ? REPLIES_PER_PAGE : COMMENTS_PER_PAGE,
        type: isAReply ? 'Replies' : 'Comments',
        sortQuery: isAReply ? null : this.props.sortQuery,
        beforeId: this.isFeedView() ? this.props.beforeId : null,
      };
    }
  }

  removeFeedFromListing(pulse) {
    let meta = {
      type: this.props.listType,
      textQuery: this.props.match.params.filterBy,
    };

    if (this.props.listType === 'MyActivities') {
      pulse.activity &&
        StoreUpdater.removeFeedFromListing({ id: pulse.activity.id }, meta);
    } else {
      pulse.public_feed &&
        StoreUpdater.removeFeedFromListing({ id: pulse.public_feed.id }, meta);
    }
  }

  isAReply() {
    return this.props.object.type === 'Reply';
  }

  isComment() {
    return this.props.object.__typename === 'Comment';
  }

  isPost() {
    return this.props.object.__typename === 'Pulse';
  }

  isFeedView() {
    return this.props.listType === 'feeds-view';
  }

  openDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  };

  getOptimisticResponseEntity() {
    let response;
    if (this.isPost()) {
      response = {
        id: this.props.object.id,
        comments_count: this.props.object.comments_count,
        __typename: 'Pulse',
      };
    } else if (this.isComment()) {
      if (this.isAReply()) {
        response = {
          id: this.props.object.parent_id,
          replies_count: this.props.object.replies_count - 1,
          __typename: this.props.object.__typename,
        };
      } else {
        response = null;
      }
    }
    return response;
  }

  getOptimisticResponseSecondaryEntity() {
    let response;
    if (this.isPost()) {
      response = null;
    } else if (this.isComment()) {
      response = {
        comments_count: this.props.source.comments_count - 1,
        id: this.props.source.id,
        __typename: this.props.source.type,
      };
    }
    return response;
  }

  removeActivityFromListing() {
    if(this.isAReply()) {
      StoreUpdater.removeAdditionalRepliesInComment({ id: this.props.object.parent_id, __typename: 'Comment' }, this.props.object)
    } else {
      StoreUpdater.removeActivityFromCareSpaceActivities({ id: this.props.activityId }, { id_query: this.props.source.id })
    }
    this.closeDeleteModal();
  }

  deleteEntity = () => {
    let variables = {
      id: this.props.object.id,
      type: this.props.object.__typename,
    };
    this.props.removeEntity({
      variables: variables,
      update: (proxy, { data: { removeEntity } }) => {
        if (this.isComment()) {
          if (removeEntity.success) {
            if(this.props.activityId) {
              this.removeActivityFromListing()
            } else {
              let metaInfo = this.getMetaInfo();
              StoreUpdater.removeRecordsFromRecordPager(
                this.props.object,
                metaInfo,
              );
            }
          }
        } else if (this.isPost()) {
          if (!removeEntity.entity) {
            window.location.reload();
          } else {
            if (this.props.feedsView) {
              this.removeFeedFromListing(removeEntity.entity);
              this.closeDeleteModal();
            } else if (this.props.postListing) {
              StoreUpdater.removePostFromMyPostListing({
                id: removeEntity.entity.id,
              });
              this.closeDeleteModal();
            } else {
              this.props.navigate('/');
            }
          }
        }
      },
    });
  };

  render() {
    let extraClass = `add-to-space-button  ${this.props.extraClass}`;
    return (
      <>
        <OsBtn
          name='BtnIcon'
          extraClass={extraClass}
          icon='delete'
          text={this.props.label}
          onClick={this.openDeleteModal}
          associatedEntity={this.props.object}
        />
        {this.state.openDeleteModal && (
          <DeleteConfirmationModal
            entityType={this.props.object.__typename}
            onClose={this.closeDeleteModal}
            entity={this.props.object}
            deleteEntity={this.deleteEntity}
          />
        )}
      </>
    );
  }
}

DeleteObject.defaultProps = {
  extraClass: '',
  label: 'Delete',
};
DeleteObject = withRouter(DeleteObject);
DeleteObject = graphql(REMOVE_COMMENT, { name: 'removeEntity' })(DeleteObject);
DeleteObject = connect(({ workspace }) => ({ workspace }))(DeleteObject);

export default DeleteObject;
