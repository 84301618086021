import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { TaskContext } from 'app/context/TaskContext';
import { openInfoModal } from 'app/actions/infoModal';
import store from 'app/store';
import {
  checkTaskIfOverdue,
  checkTaskIfUnscheduled,
} from 'app/utils/taskHelper';
import storeUpdater from 'app/services/StoreUpdater';
import { flashSuccess } from 'app/actions/flashMessage';
import {
  TASK_DELETED_SUCCESSFULLY,
  UNIFIED_BOARD,
} from 'app/components/Task/taskConstant';

const TASK_COMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskProcessed.gql',
);
const TASK_UNCOMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskUnprocessed.gql',
);
const UPDATE_DUEDATE = queryLoader(
  'app/graphql/mutations/Task/TaskDueDateChange.gql',
);
const UPDATE_ASSIGNEE = queryLoader(
  'app/graphql/mutations/Task/TaskAssigneeChange.gql',
);
const OVERDUE_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const ACTIVE_TASKS = queryLoader('app/graphql/queries/Task/TaskByWeek.gql');
const DELETE_TASK = queryLoader('app/graphql/mutations/Task/TaskDelete.gql');
const TASK_TYPE = ['DAILY', 'WEEKLY', 'MONTHLY', 'WEEKDAYS'];
const ALL_TASK = 'all_tasks';

const useKanbanCard = (card) => {
  const [collapsed, setCollapsed] = useState(false);
  const cardRef = useRef(null);
  const {
    teamMembersData,
    allUserOptions,
    updateCache,
    user,
    updateComponent,
    activeTaskVariables,
    overdueTaskVariables,
    removeCardFromActiveTasks,
    currentView,
    labelGroup,
    updateTaskInGroupLabelRecords,
  } = useContext(TaskContext);
  const refreshComponent = updateComponent;
  const [markTaskProcessed] = useMutation(TASK_COMPLETED);
  const [markTaskUnprocessed] = useMutation(TASK_UNCOMPLETED);
  const [updateDueDate] = useMutation(UPDATE_DUEDATE);
  const [updateAssignee] = useMutation(UPDATE_ASSIGNEE);
  const [deleteCurrentTask] = useMutation(DELETE_TASK);
  const dispatch = useDispatch();

  const handleCollapsed = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setCollapsed((prevState) => !prevState);
  };

  const openCard = () => {
    setCollapsed(true);
  };

  const collapseCard = () => {
    setCollapsed(false);
  };

  const sanitizeCardId = () => {
    return card.id.split('_')[0];
  };

  const changeDueDate = (dueDate) => {
    updateDueDate({
      variables: {
        id: sanitizeCardId(),
        dueDate: dueDate,
      },
    }).then(({ data }) => {
      if (data?.changeDueDate?.success) {
        if (currentView === UNIFIED_BOARD) {
          updateCache(
            { ...card, ...data.changeDueDate.entity },
            'update',
            'OverDueTasks',
          );
        }
        // Only for kanban view.
        storeUpdater.moveTaskToAnotherDay(
          {
            ...card,
            ...data.changeDueDate.entity,
          },
          activeTaskVariables,
          {
            query: ACTIVE_TASKS,
            fromDate: card.due_date,
          },
        );
        if (checkTaskIfOverdue(card)) {
          updateCache(card, 'delete', 'AllTasks');
        }
        if (checkTaskIfUnscheduled(card)) {
          updateCache(card, 'unscheduled_delete', 'AllTasks');
        }
        if (labelGroup) {
          updateTaskInGroupLabelRecords({
            ...card,
            ...data.changeDueDate.entity,
          });
        }
      }
    });
  };

  const updateOverdueTasksOnEdit = () => {};

  const markAsDone = (e) => {
    e?.stopPropagation();
    const cardId = sanitizeCardId();
    markTaskProcessed({
      variables: { id: cardId },
    }).then(({ data }) => {
      if (labelGroup) {
        updateTaskInGroupLabelRecords({
          ...card,
          ...data.markTaskProcessed.entity,
        });
      }
      if (checkTaskIfOverdue(card)) {
        updateCache(
          { ...card, ...data.markTaskProcessed.entity },
          'delete',
          'OverDueTasks',
        );
      }
    });
  };

  const scrollToCard = () => {
    if (cardRef.current !== null) {
      cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const markAsNotDone = (e) => {
    e?.stopPropagation();
    const cardId = sanitizeCardId();
    markTaskUnprocessed({
      variables: { id: cardId },
    }).then(({ data }) => {
      if (labelGroup) {
        updateTaskInGroupLabelRecords({
          ...card,
          ...data.markTaskUnprocessed.entity,
        });
      }
    });
  };

  const changeAssignee = (selectedValue) => {
    if (card.assignee.id !== (selectedValue?.id || selectedValue?.entity?.id)) {
      updateAssignee({
        variables: {
          id: card.id,
          assigneeId: selectedValue?.id || selectedValue?.entity?.id,
          assigneeType: 'User',
        },
      }).then(({ data }) => {
        if (data.changeAssignee.success) {
          updateCache(card, 'delete', 'AllTasks');
          if (labelGroup) {
            updateTaskInGroupLabelRecords({
              ...card,
              ...data.changeAssignee.entity,
            });
          }
        }
      });
    }
  };

  const deleteTask = ({ hideModal, listType }) => {
    deleteCurrentTask({
      variables: {
        id: sanitizeCardId(),
      },
    }).then(({ data }) => {
      hideModal();
      if (!data.deleteTask.success) {
        store.dispatch(openInfoModal('general', 'error_message'));
      } else {
        if (data.deleteTask.entity.recursive_type !== 'non_recursive') {
          updateComponent(card, listType);
        } else {
          updateCache(card, 'delete', listType);
        }

        flashSuccess(TASK_DELETED_SUCCESSFULLY, false)(dispatch);
      }
    });
  };

  const getCardType = () => {
    switch (card.recursive_type) {
      case 'daily':
        return `${TASK_TYPE[0]}`;
      case 'weekly':
        return `${TASK_TYPE[1]}`;
      case 'monthly':
        return `${TASK_TYPE[2]}`;
      case 'weekdays_only':
        return `${TASK_TYPE[3]}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (collapsed) {
      scrollToCard();
    }
  }, [collapsed]);

  return {
    collapsed,
    openCard,
    collapseCard,
    teamMembersData,
    allUserOptions,
    refreshComponent,
    updateCache,
    cardRef,
    updateOverdueTasksOnEdit,
    changeDueDate,
    getCardType,
    handleCollapsed,
    markAsDone,
    markAsNotDone,
    changeAssignee,
    deleteTask,
    sanitizeCardId,
    scrollToCard,
  };
};

export default useKanbanCard;
