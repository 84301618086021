import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import ImageInput from 'app/components/Shared/ImageInput';

import { throttle } from 'app/utils/osLodash';

const ALLOWED_IMAGE_FORMATS = ['jpg', 'jpeg', 'png', 'bmp'];

class ProfileImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarUrl: this.props.defaultImageUrl,
      deleted: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultImageUrl !== this.props.defaultImageUrl) {
      this.setState({ avatarUrl: this.props.defaultImageUrl });
    }
  }

  setStatus = (status) => {
    if (status === 'cropping') {
      this.setState({ status, deleted: false });
      this.props.changeRemoveAvatar();
    } else {
      this.setState({ status: status });
    }
  };

  setAvatarValues = (avatarUrl, dimensions, originalDimensions) => {
    this.setState({ avatarUrl });
    this.props.setAvatarValues(dimensions, originalDimensions, avatarUrl);
  };

  onCrop = throttle((avatarUrl, dimensions, originalDimensions) => {
    this.props.onCrop(dimensions, originalDimensions);
  }, 200);

  getLabel() {
    let patient =
      this.props.obj.__typename === 'Person'
        ? 'patient profile'
        : 'your profile';
    return this.state.avatarUrl
      ? `Change ${patient} photo`
      : `Upload ${patient} photo`;
  }

  render() {
    let label = this.getLabel();

    return (
      <>
        <Field
          name='avatar'
          component={ImageInput}
          accept={ALLOWED_IMAGE_FORMATS.map((type) => `image/${type}`).join(
            ', ',
          )}
          label={label}
          defaultImageUrl={this.state.avatarUrl}
          status={this.state.status}
          setStatus={this.setStatus}
          setImageValues={this.setAvatarValues}
          labelPosition={'bottom-button'}
          onCrop={this.onCrop}
          obj={this.props.obj}
        />
      </>
    );
  }
}

ProfileImage.defaultProps = {
  setAvatarValues: () => {},
  changeRemoveAvatar: () => {},
  onCrop: () => {},
};

ProfileImage = connect(({ currentUser, device }) => ({ currentUser, device }))(
  ProfileImage,
);

export default ProfileImage;
