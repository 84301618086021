import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RecordsView from 'app/components/RecordsView';
import { useEffect } from 'react';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import NonSubscribedView from 'app/components/NonSubscribedView';
import { useSearchParams } from 'react-router-dom';

const SelectedTag = (props) => {
  function removeSelectedTag() {
    let url = '/patient/activity';
    props.navigate(url);
  }
  const tagName = props.searchParams.get('filter');

  return (
    tagName && (
      <div className='hashtag-filter sticky-hashtag-filter'>
        <span className='hashtag-filter-text'>Selected hashtag:</span>
        <span className='tag-item sticky-tag-item'>
          <span className='tag-item-text'>#{tagName}</span>
          <OrthoIcon
            name='close'
            onClick={removeSelectedTag}
            defaultClass='fs-12 ps-2'
          />
        </span>
      </div>
    )
  );
};

const SelectedTagContainer = withRouter(SelectedTag);

const CareFeeds = (props) => {
  return (
    <RecordsView
      queryType='EXPLORE'
      widgetAfterListItemNumber={{}}
      type='CareSpaceFeed'
      textQuery={props.searchParams.get('filter')}
      perPage={5}
    />
  );
};

const CareFeedsContainer = withRouter(CareFeeds);

const ExploreFeed = (props) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (props.location.pathname.split('/')[2] === 'explore') {
      props.navigate('/patient/activity');
    }
  }, []);

  return (
    <>
      <SetPageTitle source={'explore'} />
      <NonSubscribedView title={'PatientSpace Feed'}>
        <div className='activity-data-col'>
          <SelectedTagContainer searchParams={searchParams} />
          <CareFeedsContainer searchParams={searchParams} />
        </div>
      </NonSubscribedView>
    </>
  );
};

export default withRouter(ExploreFeed);
