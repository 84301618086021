import { clone } from './osLodash';

// Note: Copied from https://github.com/clauderic/dnd-kit/blob/master/packages/sortable/src/utilities/arrayMove.ts
export function arrayMove(array, from, to) {
  let newArray = array.slice();
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0],
  );

  return newArray;
}

export function moveUp(index, arr, upBy = 1) {
  if (index >= upBy && upBy > 0) {
    let modifiedArray = arr.slice(0, index - upBy);
    modifiedArray.push(arr[index]);
    modifiedArray = modifiedArray.concat(arr.slice(index - upBy, index));
    return modifiedArray.concat(arr.slice(index + 1));
  } else {
    return arr;
  }
}

export function moveDown(index, arr, downBy = 1) {
  if (index < arr.length - 1 && downBy > 0) {
    let modifiedArray = clone(arr);
    modifiedArray.splice(index, 1);
    modifiedArray = modifiedArray.slice(0, index + downBy);
    modifiedArray.push(arr[index]);
    return modifiedArray.concat(arr.slice(index + downBy + 1));
  } else {
    return arr;
  }
}

export function selectRandomElement(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}
