import React, { Component } from 'react';
import { Modal, TabPane, TabContainer, TabContent } from 'react-bootstrap';
import { connect } from 'react-redux';

import FooterLinks from 'app/components/AuthModal/FooterLinks';
import Login from 'app/components/LoginView/login';
import OsBtn from 'app/components/OsBtn';
import Signup from 'app/components/SignupScreen/SignupForm';

import { actionByGuestUser } from 'app/actions/guestUser';
import { authModal } from 'app/actions/authentication';

import { AUTH_ACTIONS } from 'app/constants';

class AuthModal extends Component {
  closeModal = () => {
    this.props.actionByGuestUser('');
    this.props.authModal({ authModal: '' });
  };

  render() {
    let showModal = !!this.props.authentication.authModal;
    if (!showModal) {
      return null;
    }
    return (
      <>
        <Modal
          show={showModal}
          className='login-update-modal only-login'
          key='login-modal-fields'
          onHide={this.closeModal}
          animation={false}
          backdropClassName='modal-backdrop-custom modal-backdrop-login'>
          <div className='modal-body-content onboard__modal'>
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn floating-close-btn close-general-modal p-0'
              icon='close'
              label='Close modal'
              onClick={this.closeModal}
            />
            <Modal.Body className='p-0'>
              <TabContainer
                activeKey={this.props.authentication.authModal}
                id='authWrapper'
                mountOnEnter>
                <TabContent>
                  <TabPane eventKey={AUTH_ACTIONS.login}>
                    <Login />
                  </TabPane>
                  <TabPane eventKey={AUTH_ACTIONS.signup}>
                    <Signup />
                  </TabPane>
                </TabContent>
              </TabContainer>
            </Modal.Body>
            <Modal.Footer>
              <FooterLinks />
            </Modal.Footer>
          </div>
        </Modal>
      </>
    );
  }
}

AuthModal = connect(({ authentication }) => ({ authentication }), {
  actionByGuestUser,
  authModal,
})(AuthModal);

export default AuthModal;
