import { SET_GLOBAL_SEARCH_QUERY } from 'app/actions/types';

const globalSearchQuery = (state = { query: '' }, action) => {
  switch (action.type) {
    case SET_GLOBAL_SEARCH_QUERY:
      return { ...state, query: action.payload };
    default:
      return state;
  }
};

export default globalSearchQuery;
