import React, { useState } from 'react';
import bold from 'app/images/tiptap/bold.svg';
import italic from 'app/images/tiptap/italic.svg';
import orderList from 'app/images/tiptap/olist.svg';
import unOrderList from 'app/images/tiptap/uolist.svg';
import underline from 'app/images/tiptap/underline.svg';

import DisplayEmojiPicker from 'app/components/TipTapEditor/DisplayEmojiPicker';

const MenuBar = ({ editor, showEmoji, setShowEmoji }) => {
  if (!editor) {
    return null;
  }

  const handleEmojiSelect = (emoji) => {
    editor.commands.insertContent(emoji.native);
    editor.commands.focus('end');
    setShowEmoji(false);
  };

  return (
    <div className={'menu-bar'}>
      <button
        type={'button'}
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}>
        <img src={bold} alt={'bold icon'} />
      </button>
      <button
        type={'button'}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}>
        <img src={italic} alt={'italic icon'} />
      </button>
      <button
        type={'button'}
        onClick={() => editor.commands.toggleUnderline()}
        className={editor.isActive('underline') ? 'is-active' : ''}>
        <img src={underline} alt={'underline icon'} />
      </button>
      <button
        type={'button'}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}>
        <img src={unOrderList} alt={'un-order list icon'} />
      </button>
      <button
        type={'button'}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}>
        <img src={orderList} alt={'order list icon'} />
      </button>
      <DisplayEmojiPicker
        showEmoji={showEmoji}
        setShowEmoji={setShowEmoji}
        handleEmojiSelect={handleEmojiSelect}
        isClassComponent={false}
      />
    </div>
  );
};

export default MenuBar;
