import {
  OPEN_ATTACHMENT_PREVIEW,
  CLOSE_ATTACHMENT_PREVIEW,
} from 'app/actions/types';

export default function attachmentPreview(
  state = {
    open: false,
    source: '',
    attachments: [],
    activeAttachment: null,
    author: null,
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_ATTACHMENT_PREVIEW:
      let { source, attachments, activeAttachment, author, options } = action;
      return {
        ...state,
        open: true,
        source,
        attachments,
        activeAttachment,
        author,
        options,
      };

    case CLOSE_ATTACHMENT_PREVIEW:
      return {
        ...state,
        open: false,
        source: '',
        attachments: [],
        activeAttachment: null,
        author: null,
        options: {},
      };

    default:
      return state;
  }
}
