import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SpaceListing from 'app/components/SideBarListing/SpaceListing';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { sidebarCareNotificationAvailable } from 'app/actions/allSidebarCareNotifications';
import { uniqBy } from 'app/utils/osLodash';

const SIDEBAR_SPACE_DETAILS = queryLoader(
  'app/graphql/queries/Spaces/AllSidebarCollection.gql',
);

const CareListingWrapper = (props) => {
  const { type } = props;
  const pageCount = useRef(0);
  const sidebarNotificationFetch = useRef(false);
  const [result, setResult] = useState([]);
  const sidebarNotification = useSelector(
    (state) => state.allSidebarCareNotifications,
  );
  const { data, refetch } = useQuery(SIDEBAR_SPACE_DETAILS, {
    variables: {
      page: pageCount.current,
      perPage: 50,
      type: type,
    },
    fetchPolicy: 'network-only',
  });

  // Fetching the next page when the user reaches the bottom of the scrollable parent
  useEffect(() => {
    const fetchNextPageOnReachingBottom = async () => {
      // Fetching the next page when the user reaches the bottom of the scrollable parent
      if (
        leftSideBar.scrollTop + leftSideBar.clientHeight >=
        leftSideBar.scrollHeight
      ) {
        // Fetching the next page
        pageCount.current = pageCount.current + 1;
        await refetch({
          page: pageCount.current,
        });
      }
    };
    // Adding a listener to the nearest scrollable parent
    const leftSideBar = document.getElementById('left_side_bar');
    leftSideBar.addEventListener('scroll', fetchNextPageOnReachingBottom);

    return () => {
      // Removing the listener when the component is unmounted
      leftSideBar.removeEventListener('scroll', fetchNextPageOnReachingBottom);
    };
  }, []);

  // Adding the fetched data to the result state
  useEffect(() => {
    if (data) {
      if (sidebarNotificationFetch.current) {
        setResult((prevState) =>
          uniqBy([...data.records.results, ...prevState], 'id'),
        );
        sidebarNotificationFetch.current = false;
      } else {
        setResult((prevState) =>
          uniqBy([...prevState, ...data.records.results], 'id'),
        );
      }
    }
  }, [data]);

  // Fetching latest 50 records when the sidebar notification is available.
  useEffect(() => {
    if (sidebarNotification.isNotificationAvailable) {
      // Fetch the latest 10 records.
      refetch({
        page: 0,
        perPage: 10,
      }).then((r) => r);
      // Set Sidebar Notification to false.
      sidebarNotificationFetch.current = true;
      sidebarCareNotificationAvailable(false);
    }
  }, [refetch, sidebarNotification]);

  if (result) {
    return <SpaceListing results={result} />;
  } else {
    return null;
  }
};

export default CareListingWrapper;
