import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from './';

class OsPasswordField extends Component {
  state = {
    inputType: 'password',
  };

  onPasswordToggleClick = () => {
    this.setState({
      inputType: this.isInputTypePassword() ? 'text' : 'password',
    });
  };

  isInputTypePassword() {
    return this.state.inputType === 'password';
  }

  eyeIconRequired() {
    return this.props.showPasswordIcon && this.props.input.value;
  }

  render() {
    return (
      <>
        <OsField
          {...this.props}
          osType='input'
          type={this.state.inputType}
          isWrapperRequired={false}
        />
        {this.eyeIconRequired() && (
          <OrthoIcon
            name={this.isInputTypePassword() ? 'passwordShow' : 'passwordHide'}
            defaultClass=''
            onClick={this.onPasswordToggleClick}
          />
        )}
      </>
    );
  }
}

OsPasswordField.defaultProps = {
  showPasswordIcon: true,
};

export default OsPasswordField;
