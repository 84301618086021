import {
  OPEN_DISABLE_CARE_SPACE_MODAL,
  CLOSE_DISABLE_CARE_SPACE_MODAL,
} from './types';

export function openDisableCareSpaceModal(obj) {
  return {
    type: OPEN_DISABLE_CARE_SPACE_MODAL,
    obj,
  };
}

export function closeDisableCareSpaceModal() {
  return {
    type: CLOSE_DISABLE_CARE_SPACE_MODAL,
  };
}
