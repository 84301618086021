import React, { Component } from 'react';
import OsBtn from 'app/components/OsBtn';

// Hold buttons in container that turns card link off so that the button can be pressed without doing the normal card action
export default class ChildButton extends Component {
  onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    let extraClass = this.props.extraClass || '';
    extraClass += this.props.childButtonClassNotRequired
      ? ''
      : this.props.buttonType === 'BtnIcon'
      ? ' list-button'
      : ' osbtn-with-border';
    return (
      <OsBtn
        name={this.props.buttonType || this.props.name || 'BtnSecondary'}
        text={this.props.text}
        onClick={this.onClick}
        extraClass={extraClass}
        associatedEntity={this.props.associatedEntity}
        icon={this.props.icon}
      />
    );
  }
}
