import { OPEN_SUBSCRIPTION_MODAL, CLOSE_SUBSCRIPTION_MODAL } from './types';

export function openSubscriptionModal(step = 'plans', options = {}) {
  return {
    type: OPEN_SUBSCRIPTION_MODAL,
    step: step,
    options: options,
  };
}

export function closeSubscriptionModal() {
  return {
    type: CLOSE_SUBSCRIPTION_MODAL,
  };
}
