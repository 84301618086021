import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import { keys } from 'app/utils/osLodash';

import {
  setModeType,
  setCaseDetailValuesToDefault,
  setSelectedAppointment,
} from 'app/actions/caseDetailInfo';

import {
  ALL_APPOINTMENTS_FILTER_TEXT,
  CASE_VIDEOS_MODES,
  VIEWING_MODES_MAPPER,
} from 'app/components/CaseLeftSection/constants';
import {
  allAppointmentFiles,
  caseVideosPresent,
  filterCaseVideos,
  getAppointmentDropdownTitle,
  getAppointmentThroughFile,
  getAppointmentFiles,
  getAppointmentFilesWithImagesOrVideos,
  getFilesBasedOnRecordType,
  getVideoTypeName,
  isCaseVideo,
} from 'app/components/CaseLeftSection/helpers';
import { isSBSViewTypeSelected } from 'app/utils/caseHelper';
import { isEmpty } from 'app/utils/osLodash';

class CaseSortButtons extends Component {
  getAvailableRecordTypes() {
    return keys(VIEWING_MODES_MAPPER).filter(
      (type) => !!this.getImagesAndVideos(type).length,
    );
  }

  isAllAppoinmentFilterSelected() {
    return this.props.caseDetailInfo.mode === ALL_APPOINTMENTS_FILTER_TEXT;
  }

  getImagesAndVideos(recordType) {
    let selectedRecordType = recordType || this.props.caseDetailInfo.mode,
      viewType = this.props.caseDetailInfo.viewType;

    let files = [];
    if (this.isAllAppoinmentFilterSelected()) {
      files = allAppointmentFiles(this.props.kase);
    } else if (this.props.selectedAppointment) {
      let { selectedAppointment } = this.props;
      files = getAppointmentFiles(selectedAppointment);
    } else {
      files = filterCaseVideos(viewType, this.props.kase);
    }
    return getFilesBasedOnRecordType(files, selectedRecordType);
  }

  setSelectedMode = (mode) => {
    if (
      !CASE_VIDEOS_MODES.includes(mode) &&
      isEmpty(this.props.caseDetailInfo.selectedAppointment)
    ) {
      this.props.setCaseDetailValuesToDefault();
      this.props.setSelectedAppointment(
        getAppointmentFilesWithImagesOrVideos(this.props.kase.appointments)[0],
      );
    }
    this.props.setModeType(mode);
  };

  isModeNotAllowed(mode) {
    return isSBSViewTypeSelected() && mode === 'Sections';
  }

  renderModeButton = (modeKey) => {
    let text = VIEWING_MODES_MAPPER[modeKey]['text'] || '',
      icon = VIEWING_MODES_MAPPER[modeKey]['icon'] || '',
      extraClass = VIEWING_MODES_MAPPER[modeKey]['extraClass'] || '';
    extraClass += this.props.caseDetailInfo.mode === modeKey ? ' active ' : '';
    return (
      !this.isModeNotAllowed(modeKey) && (
        <OsBtn
          name='BtnIcon'
          icon={icon}
          text={text}
          extraClass={`no-text cs-sort-btn ${extraClass}`}
          tooltipText={modeKey}
          onClick={this.setSelectedMode.bind(this, modeKey)}
        />
      )
    );
  };

  render() {
    return (
      <div className='cs-sort-btn-group'>
        {this.getAvailableRecordTypes().map(this.renderModeButton)}
      </div>
    );
  }
}

CaseSortButtons = connect(({ caseDetailInfo }) => ({ caseDetailInfo }), {
  setModeType,
  setCaseDetailValuesToDefault,
  setSelectedAppointment,
})(CaseSortButtons);

export default CaseSortButtons;
