import React, { useRef, useState } from 'react';
import Calendar from 'react-calendar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { currentTimeWithUserTimeZone } from 'app/utils/timeHelper';

function CustomCalendar({ selectCustomDate, closeModal, elementId }) {
  let currentHour = currentTimeWithUserTimeZone().hour();
  let currentMinute = currentTimeWithUserTimeZone().minute();

  let newTime = currentTimeWithUserTimeZone().hour(
    currentHour + 2 + (currentMinute > 30 ? 1 : 0),
  );

  const [date, setDate] = useState();
  const [hour, setHour] = useState(newTime.hour() % 12);
  const [minute, setMinute] = useState(currentMinute > 30 ? 0 : 30);
  const [shift, setShift] = useState(newTime.hour() < 12 ? 'AM' : 'PM');
  const calendarRef = useRef(null);

  const onChangeHandler = (date) => {
    const updatedDate = dayjs(date)
      .hour(shift === 'PM' && hour !== 12 ? 12 + hour : hour)
      .minute(minute)
      .second(0);
    setDate(updatedDate);
  };

  const clickListener = (e) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target))
      closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', clickListener);
    }, 100);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  return createPortal(
    <div className='calendar-wrapper' ref={calendarRef}>
      <Calendar
        onChange={onChangeHandler}
        minDate={new Date()}
        locale='en-US'
      />
      <TimeBlock
        date={date}
        hour={hour}
        minute={minute}
        shift={shift}
        setHour={setHour}
        setMinute={setMinute}
        setShift={setShift}
        selectCustomDate={selectCustomDate}
        onChangeHandler={onChangeHandler}
      />
    </div>,
    document.getElementById(elementId),
  );
}

const TimeBlock = (props) => {
  const {
    date,
    hour,
    minute,
    shift,
    setHour,
    setMinute,
    setShift,
    selectCustomDate,
    onChangeHandler,
  } = props;

  const [error, setError] = useState(false);
  const increase = (filter) => {
    switch (filter) {
      case 'hour':
        setHour((prev) => (prev % 12) + 1);
        break;
      case 'minute':
        setMinute((prev) => (prev + 30) % 60);
        break;
      case 'shift':
        setShift((prev) => (prev === 'PM' ? 'AM' : 'PM'));
        break;
      default:
        break;
    }
  };

  const decrease = (filter) => {
    const filters = {
      hour: () => {
        setHour(hour === 1 ? 12 : hour - 1);
      },
      minute: () => {
        setMinute(minute === 0 ? minute + 30 : minute - 30);
      },
      shift: () => {
        setShift(shift === 'PM' ? 'AM' : 'PM');
      },
    };
    filters[filter]();
  };

  const addDateBtnHandler = () => {
    if (dayjs(date).diff(dayjs(), 'hour') >= 2) {
      selectCustomDate(date);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    onChangeHandler(date);
  }, [hour, minute, shift]);

  return (
    <div className='time-block-wrapper'>
      <div className='time-block'>
        <div className='time-block-container'>
          <div className='hour-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('hour')} />
            {hour < 10 ? `0${hour}` : hour}
            <OrthoIcon name='chevron-down' onClick={() => decrease('hour')} />
          </div>
          <div className='minute-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('minute')} />
            {minute < 10 ? `0${minute}` : minute}
            <OrthoIcon name='chevron-down' onClick={() => decrease('minute')} />
          </div>
          <div className='shift-block'>
            <OrthoIcon name='chevron-up' onClick={() => increase('shift')} />
            {shift}
            <OrthoIcon name='chevron-down' onClick={() => decrease('shift')} />
          </div>
        </div>
        <OsBtn
          name='BtnPrimary'
          text='Add Date'
          extraClass='px-16 web-view-btn'
          onClick={addDateBtnHandler}
        />
      </div>
      {error && (
        <div className='time-error'>
          {' '}
          Please choose a time that is at least two hours from now.
        </div>
      )}
    </div>
  );
};
export default CustomCalendar;
