import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

import { isSpaceArchived } from 'app/utils/spaceHelper';
import { isEmpty, isEqual } from 'app/utils/osLodash';

import usePreviousValue from 'app/hooks/usePreviousValue';
import { setActiveGroup } from 'app/actions/activeGroup';

import SpaceChatWindow from 'app/components/GroupMessenger/SpaceChatWindow';
import View from 'app/components/Shared/View';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

import 'app/components/GroupMessenger/group-messenger.scss';

const TEAMSPACE_BY_ID = queryLoader(
  'app/graphql/queries/Spaces/Types/TeamSpace.gql',
);

// View to hold the conversations
const GroupMessenger = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const groupVariables = {
    id: params.id,
    type: params.id === 'recent' ? 'team' : null,
  };
  const { data = {}, loading } = useQuery(TEAMSPACE_BY_ID, {
    variables: groupVariables,
    fetchPolicy: 'network-only',
  });
  const previousData = usePreviousValue(data);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      dispatch(setActiveGroup(data.board));
    }
  }, [data]);

  const readOnly = () => {
    return isSpaceArchived(data.board);
  };

  const renderContent = () => {
    const { board } = data;
    const { id } = params;
    return (
      <div className='messenger'>
        {board && (
          <SpaceChatWindow
            key={board.nice_id}
            nice_id={id}
            currentUser={currentUser}
            entity={board}
            commentableId={board.nice_id}
            commentableType='Board'
            scrollIfNeeded={props.scrollIfNeeded}
            readOnly={readOnly()}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {!isEmpty(data) && (
        <SetPageTitle
          source={'group_messenger'}
          interpolations={data?.board?.name}
        />
      )}
      {!loading && <View obj={data.board} extraClass='d-none' />}
      {!loading && <div className='messenger-container'>{renderContent()}</div>}
    </>
  );
};

export default GroupMessenger;
