import React, { PureComponent } from 'react';
import Avatar from 'app/components/Shared/Avatar';

import { getCareAvatarIconProps } from 'app/utils/spaces/careHelper';
import { avatarAttributes } from 'app/utils/entitiesHelper';

class PersonHeaderInModal extends PureComponent {
  render() {
    const { space, obj } = this.props;
    return (
      <div className='d-flex justify-content-start'>
        <Avatar
          className='avatar avatar-48 me-3'
          {...avatarAttributes(obj, {
            largeAvatarRequired: false,
            ...getCareAvatarIconProps(
              space,
              { id: obj.user_id },
              { isPatientOrGuardian: true },
            ),
          })}
        />
        <div className='name-with-relation'>
          <div className='guardian-name'>{obj.name}</div>
          <div className='relation'>{obj.relation}</div>
        </div>
      </div>
    );
  }
}

export default PersonHeaderInModal;
