import { translate } from 'app/actions/flashMessage';
import { isCareSpace } from 'app/utils/spaceHelper';
import {
  entityIsBoard,
  entityIsCase,
  displayEntityName,
} from 'app/utils/entitiesHelper';
import { toSentence } from 'app/utils/stringHelper';
import { possibleBroadcastMentionGroups } from 'app/utils/widgetHelper';
import { isEmpty } from 'app/utils/osLodash';
import { isCareWorkspaceView } from './Workspace/generalHelper';

const DEFAULT_PLACEHOLDER_TEXT_FOR_SPACE =
  'Add your new post here - share your thoughts or questions with your team here. Use @ to mention your team members.';

export function getPlaceholderText({ entity, isReply, placeholder, isEdit }) {
  if (placeholder) {
    return placeholder;
  } else if (isEdit) {
    return '';
  } else if (isReply) {
    return translate('DISCUSSIONS_REPLY_PLACEHOLDER');
  } else if (
    isCareSpace(entity) &&
    (isCareWorkspaceView() ||
      possibleBroadcastMentionGroups(entity?.mention_groups).length > 0)
  ) {
    if (isCareWorkspaceView()) {
      return translate('DISCUSSIONS_FOR_PATIENT_PLACEHOLDER');
    } else {
      const mentionGroups = possibleBroadcastMentionGroups(
        entity?.mention_groups,
      );
      return `Add new posts here. Use ${toSentence(
        mentionGroups.map((group) =>
          translate(
            group.widget_description,
            { identifier: group.display_name.trim() },
            true,
            { connector: '' },
          ),
        ),
      )}.`;
    }
  } else if (entityIsBoard(entity) || isEmpty(entity)) {
    return DEFAULT_PLACEHOLDER_TEXT_FOR_SPACE;
  } else if (entityIsCase(entity)) {
    return 'Add new comments here — share your thoughts on this case, ask questions, or use @ to mention someone in the community.';
  } else {
    return `Share your thoughts on this ${displayEntityName(entity)}`;
  }
}

export function isInteralTeamComment(comment) {
  return comment?.visible_audience_ids?.length === 0;
}
