import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const ArchiveGroupForm = ({
  entity,
  closeModal,
  archiveSpace,
  isArchiving,
}) => {
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    setSubmitEnabled(checkbox1 && checkbox2 && checkbox3);
  }, [checkbox1, checkbox2, checkbox3]);

  const handleCheckboxChange = (checkboxNum, checked) => {
    switch (checkboxNum) {
      case 1:
        setCheckbox1(checked);
        break;
      case 2:
        setCheckbox2(checked);
        break;
      case 3:
        setCheckbox3(checked);
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {
    archiveSpace(entity);
  };

  return (
    <>
      <div className='group-icon'>
        <Icon name='archive_space' className='v-align-top' />
      </div>
      <div className='group-form'>
        <div>
          <Form
            onSubmit={onSubmit}
            destroyOnUnmount={true}
            forceUnregisterOnUnmount={true}
            enableReinitialize={true}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className=''>
                <p>
                  Archiving a Group can not be undone. Please, check all boxes
                  below to confirm:
                </p>
                <label className='group-archive-check-label'>
                  <input
                    className='archive-checkbox'
                    type='checkbox'
                    checked={checkbox1}
                    onChange={(e) => handleCheckboxChange(1, e.target.checked)}
                  />
                  <OrthoIcon
                    name='checkbox-default'
                    dataHoverNotRequired={true}
                  />
                  <OrthoIcon name='checkbox' dataHoverNotRequired={true} />
                  No one will be able to send messages in this Group anymore
                </label>
                <label className='group-archive-check-label'>
                  <input
                    className='archive-checkbox'
                    type='checkbox'
                    checked={checkbox2}
                    onChange={(e) => handleCheckboxChange(2, e.target.checked)}
                  />
                  <OrthoIcon
                    name='checkbox-default'
                    dataHoverNotRequired={true}
                  />
                  <OrthoIcon name='checkbox' dataHoverNotRequired={true} />
                  You will be able to unarchive this channel later
                </label>
                <label className='group-archive-check-label'>
                  <input
                    className='archive-checkbox'
                    type='checkbox'
                    checked={checkbox3}
                    onChange={(e) => handleCheckboxChange(3, e.target.checked)}
                  />
                  <OrthoIcon
                    name='checkbox-default'
                    dataHoverNotRequired={true}
                  />
                  <OrthoIcon name='checkbox' dataHoverNotRequired={true} />
                  Only you will be able to access previously posted messages
                </label>
                <p>Are you sure you want to archive ‘{entity.name}’ Group?</p>
                <div className='btn-group'>
                  <OsBtn
                    type='button'
                    name='BtnGhost'
                    onClick={closeModal}
                    text={'CANCEL, KEEP GROUP'}
                    htmlTag='button'
                    extraClass='web-view-btn btn-save-info'
                  />
                  <OsBtn
                    type='button'
                    name='BtnPrimary'
                    onClick={handleSubmit}
                    disabled={!submitEnabled || isArchiving}
                    text={
                      isArchiving ? 'Archiving Group' : 'YES, ARCHIVE GROUP'
                    }
                    htmlTag='button'
                    extraClass='web-view-btn btn-save-info'
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ArchiveGroupForm;
