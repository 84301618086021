/**
 * @description: It replaces mentionee name with mentionee id the given string.
 * @param userData
 * @returns {string|*}
 */

export function changeMentioneeNameWithId(userData) {
  const userId = [];

  // Regex to find all the anchor tags in the string
  const regex = new RegExp(/<span[^>]*>([^<]+)<\/span>/g);

  // Regex to find "data-id" attribute in the string.
  const dataIdRegex = new RegExp(/data-id="(.*?)"/);

  // Finding all the anchor tags in the string.
  const matchers = userData.match(regex);

  // Finding the relevant anchor tags (containing data-id attribute) in the list of all anchor tags.
  matchers?.forEach((tag) => {
    const value = tag.match(dataIdRegex);
    if (value) userId.push(value[1]);
  });

  // If no relevant anchor tags are found, return the string as it is.
  if (userId.length === 0) {
    return userData;
  }

  let newData = '',
    flag = false;

  // Iterating the given string and whenever the start of an anchor tag is found, flag is turned to true and on the end of tag flag is turned to false.
  // If flag is true, the string is replaced with the id of the user and flag is turned to false.
  for (let i = 0, pos = 0; i < userData.length; i++) {
    if (flag && userData[i] === '@' && userId[pos].toString() !== '-1') {
      newData += `@[User:${userId[pos++]}]<`;
      flag = false;
      for (let j = i + 1; j < userData.length; j++) {
        if (userData[j] === '<') {
          i = j;
          break;
        }
      }
    } else {
      newData += userData[i];
      if (
        userData[i] === '<' &&
        userData[i + 1] === 's' &&
        userData[i + 2] === 'p'
      ) {
        flag = true;
      }
    }
  }

  // returning the modified string.
  return newData;
}

export function addSpanTagForHashtag(content) {
  const wordList = content.split(' ');
  let updateWordList = wordList.map((word) => {
    if (word[0] === '#') {
      return `<span class='filter-task-kanban'>${word}</span>`;
    }
    return word;
  });
  return updateWordList.join(' ');
}

export function tokenNodePresent(content) {
  const regex = new RegExp(
    /<\s*span[^>]*data-type\s*=\s*["']tokenNode["'][^>]*>/g,
  );
  const matchers = content.match(regex);
  return matchers?.length > 0;
}

export function isEditorContentEmpty(content) {
  return content === '<p></p>' || !content;
}
