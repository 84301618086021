import Tippy from '@tippyjs/react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { hexToRgb } from 'app/utils/colorHelper';
import { getParentLabel } from 'app/utils/labelHelper';
import React, { useState } from 'react';

function LabelInputWithDropdown(props) {
  const [visible, setVisible] = useState(false);
  const onClickHandler = (labelObject) => {
    const { label, subLabel } = labelObject;
    if (subLabel) {
      let parentLabel = getParentLabel(label, props.allTaskLabels);
      let afterRemoving = props.labelValue.filter(
        (l) =>
          getParentLabel(l.entity, props.allTaskLabels).id !== parentLabel.id,
      );
      props.onClick('labels', [
        ...afterRemoving,
        { value: label.id, entity: label },
      ]);
      setVisible(false);
      return;
    }

    if (label.sub_labels.length > 0) return;
    if (!props.labelValue.some((l) => l.value === label.id)) {
      props.onClick('labels', [
        ...props.labelValue,
        { value: label.id, entity: label },
      ]);
    }
    setVisible(false);
  };
  return (
    <div className='input-with-dropdown-wrapper'>
      <label>Labels</label>
      <input
        className='input-field'
        placeholder={props.placeholder}
        onClick={() => setVisible(!visible)}
        value={props.inputValue}
        onChange={(e) => props.onChange(e.target.value)}
      />
      {visible && props.options.length > 0 && (
        <div className='dropdown-container'>
          {props.options.map((label) => {
            return (
              <Tippy
                content={
                  label.sub_labels.length > 0 && (
                    <div className='sub-label-dropdown'>
                      {label.sub_labels.map((subLabel) => {
                        return (
                          <div
                            key={subLabel.id}
                            className='sublabelContainer'
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickHandler({
                                label: {
                                  ...subLabel,
                                  color: label.color,
                                  sub_labels: [],
                                },
                                subLabel: true,
                              });
                            }}>
                            <span
                              className='labelColor'
                              style={{
                                backgroundColor: `${hexToRgb(label.color)}`,
                              }}></span>
                            <span className='result-text-data'>
                              {subLabel.name}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )
                }
                placement='right-start'
                allowHTML={true}
                arrow={false}
                interactive={true}
                appendTo={document.body}
                offset={0}>
                <div
                  key={label.id}
                  className='labelContainer'
                  onClick={() => onClickHandler({ label, subLabel: false })}>
                  <div className='label-info'>
                    <span
                      className='labelColor'
                      style={{
                        backgroundColor: `${hexToRgb(label.color)}`,
                      }}></span>
                    <span className='result-text-data'>{label.name}</span>
                  </div>
                  {label.sub_labels.length > 0 && (
                    <OrthoIcon name='chevron-right' />
                  )}
                </div>
              </Tippy>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default LabelInputWithDropdown;
