import React from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import AbstractCard from './AbstractCard';
import AddTo from 'app/components/Shared/AddTo';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import MarkEntityAsViewedOrUnViewed from 'app/components/Shared/MarkEntityAsViewedOrUnViewed';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import Share from 'app/components/Shared/Share';

import { entityIsAttachment, getEntityName } from 'app/utils/entitiesHelper';
import { selectRandomElement } from 'app/utils/arrayHelper';
import { isDownloadable } from 'app/utils/fileHelper';
import { isCareSpace } from 'app/utils/spaceHelper';

const ATTACHMENT_QUERY = queryLoader('app/graphql/Attachment.gql');

class SpaceLinkCard extends AbstractCard {
  spaceLink() {
    return this.props.obj;
  }

  linkEntity() {
    return this.spaceLink().linkable;
  }

  renderTitle() {
    return (
      <div className='toc-heading'>{getEntityName(this.linkEntity())}</div>
    );
  }

  getLinkUrl() {
    let entity = this.linkEntity();

    switch (entity.__typename) {
      case 'AdditionalLink':
        return entity.image_url;
      case 'Attachment':
        return entity.thumb_url;
      case 'Company':
        return entity.logo_small;
      case 'User':
        return entity.thumb_url;
      case 'Board':
        return entity.image;
      default:
        return '';
    }
  }

  renderImage() {
    let src = this.getLinkUrl(),
      link = this.spaceLink();

    if (src) {
      return (
        <div className='figure'>
          {this.renderDoubleTickIconIfRequired(link)}
          <img src={src} />
        </div>
      );
    } else {
      let randomBgClass = selectRandomElement(['', 'bg-2']);
      return (
        <div className={`figure image-bg ${randomBgClass}`}>
          {this.renderDoubleTickIconIfRequired(link)}
          <OrthoIcon name='doc' />
        </div>
      );
    }
  }

  renderAddTo() {
    return (
      <AddTo
        key='add_to_space'
        obj={this.linkEntity()}
        extraClass='list-button'
      />
    );
  }

  renderShare() {
    return (
      <Share
        key='share'
        object={this.linkEntity()}
        label='Share'
        extraClass='list-button'
      />
    );
  }

  onDropDownToggle = (dropdownOpen) => {
    this.context.triggerOutsideClickElement?.click();
    this.props.onDropDownToggle(dropdownOpen);
  };

  openDownloadUrl = () => {
    this.props.client
      .query({
        query: ATTACHMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: { id: this.linkEntity().id },
      })
      .then(({ data }) => {
        window.location.href = data.attachment.download_url;
        this.viewObject(this.spaceLink());
      });
  };

  isShareableAndSavable() {
    let obj = this.linkEntity();
    return (
      !entityIsAttachment(obj) ||
      !(this.context.isAttachmentRestricted && !isDownloadable(obj))
    );
  }

  isDownloadable() {
    let obj = this.linkEntity();
    return (
      !!this.props.currentUser.graph &&
      entityIsAttachment(obj) &&
      isDownloadable(obj)
    );
  }

  renderDownload() {
    return (
      <OsBtn
        name='BtnIcon'
        extraClass='list-button'
        icon='download'
        text='Download'
        onClick={this.openDownloadUrl}
        associatedEntity={this.linkEntity()}
      />
    );
  }

  renderCustomDropdown() {
    let link = this.spaceLink(),
      isShareableAndSavable = this.isShareableAndSavable(),
      space = this.context.sourceObject;

    return (
      <div className='right-attach-col'>
        <CustomDropdown
          name='link_action'
          className='card-dropdown-menu'
          onDropDownToggle={this.onDropDownToggle}>
          {isShareableAndSavable && !isCareSpace(space) && this.renderAddTo()}
          {isShareableAndSavable && !isCareSpace(space) && this.renderShare()}
          {this.isDownloadable() && this.renderDownload()}
          {!this.isAuthorOrEditorOfSourceObject() &&
            this.isPartOfSourceObject() &&
            !isCareSpace(space) && (
              <MarkEntityAsViewedOrUnViewed
                viewed={link.viewed_space_content}
                obj={link}
              />
            )}
        </CustomDropdown>
      </div>
    );
  }

  renderLinkDetails() {
    let { description, duration } = this.spaceLink();

    return (
      <>
        {description && <div className='card-small-content'>{description}</div>}
        {duration && <div className='toc-time'>{duration}</div>}
      </>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderImage()}
        <div className='card-info'>
          {this.renderTitle()}
          {this.renderCustomDropdown()}
          {this.renderLinkDetails()}
        </div>
      </>
    );
  }

  render() {
    let containerClasses = this.props.obj.extraClass || '';
    return (
      <div
        className={`${
          this.props.containerClasses
        } ${this.linkEntity().__typename.toLowerCase()}`}
        onClick={this.props.onCardClick}>
        <div
          className={`${containerClasses} card-wrapper`}
          onClick={this.onCardClick}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

SpaceLinkCard = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(SpaceLinkCard);
SpaceLinkCard = withApollo(SpaceLinkCard);
export default SpaceLinkCard;
