import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import asyncValidate from './asyncValidate';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { verifyUserEmail } from 'app/actions/authentication';
import { translate } from 'app/actions/flashMessage';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { fieldRequired, composeValidators } from 'app/utils/validationHelper';

const SEND_EMAIL_CONFIRMATION_INSTRUCTIONS_MUTATION = queryLoader(
  'app/graphql/mutations/SendEmailConfirmationInstructions.gql',
);

class EmailVerification extends Component {
  state = {
    requestInProgress: false,
  };

  onSubmit = (values) => {
    this.setState({ requestInProgress: true });
    this.props
      .sendEmailConfirmationInstructions({
        variables: {
          email: values.email,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        let response = data.sendEmailConfirmationInstructions;
        if (response.success) {
          this.props.updateCurrentUserGraph({
            next_verification_step: response.entity.next_verification_step,
          });
          this.props.closeModal();
        }
      });
  };

  emailExistence(values) {
    let error = asyncValidate({ email: values }, 'email');
    return error;
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        initialValues={{
          email: '',
        }}
        asyncBlurFields={['email']}
        render={({ handleSubmit }) => (
          <form name='emailVerificationForm' onSubmit={handleSubmit}>
            <div className='pt-2'>
              {translate(
                'USER_ONBOARDING_EMAIL_SENT_CONFIRMATION',
                {
                  email: <strong>{this.props.currentUser.graph.email}</strong>,
                },
                false,
              )}
              <br />
              <br />
            </div>
            <div>{translate('USER_ONBOARDING_RESEND_EMAIL_VERIFICATION')}</div>
            <div className='mt-3 pt-2'>
              <Field
                name='email'
                type='email'
                osType='input'
                component={OsField}
                label='Email'
                validate={composeValidators(fieldRequired, this.emailExistence)}
              />
            </div>
            <div className='email-verify-footer text-center'>
              <OsBtn
                name='BtnPrimary'
                type='submit'
                text='resend link'
                disabled={this.state.requestInProgress}
                className='email_resend_btn'
                loaderRequired={this.state.requestInProgress}
                htmlTag='button'
              />
            </div>
          </form>
        )}
      />
    );
  }
}

EmailVerification = graphql(SEND_EMAIL_CONFIRMATION_INSTRUCTIONS_MUTATION, {
  name: 'sendEmailConfirmationInstructions',
})(EmailVerification);

EmailVerification = connect((state) => ({ currentUser: state.currentUser }), {
  updateCurrentUserGraph,
  verifyUserEmail,
})(EmailVerification);

export default EmailVerification;
