import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getDefaultAppointmentDetailHash } from 'app/utils/appointmentHelper';
import AppointmentForm from 'app/components/AppointmentForm';
import OsBtn from 'app/components/OsBtn';

import AppointmentBlock from './AppointmentBlock';

export default class AppointmentListing extends Component {
  state = {
    openAppointmentForm: false,
  };
  getAdditionalProps(appointment) {
    const { caseDetailInfo, groupedActions } = this.props;

    let forceOpenAppointment = {};
    if (caseDetailInfo.activeCardId) {
      forceOpenAppointment = this.isActiveCardPresent(appointment)
        ? appointment
        : {};
    } else {
      forceOpenAppointment =
        caseDetailInfo.selectedRightSideAppointment ||
        caseDetailInfo.selectedAppointment;
    }

    return {
      forceOpen: forceOpenAppointment?.id === appointment.id,
      activeActions: appointment.active_action_ids.map(
        (id) => groupedActions[id][0],
      ),
    };
  }

  isActiveCardPresent(appointment) {
    let { activeCardId } = this.props.caseDetailInfo;
    const { appointmentsDetailHash } = this.props,
      hash = appointmentsDetailHash[appointment.id];
    return (
      hash &&
      hash.appliancesHash['addedAppliances'].some(
        (action) => +action.variant_log_id === +activeCardId,
      )
    );
  }

  onEditClick = (editAppointment) => {
    this.setState({ openAppointmentForm: true, editAppointment });
  };

  renderAppointmentBlock = (appointment, index) => {
    const { appointmentsDetailHash, groupedVariantLogs, kase } = this.props;
    return (
      <AppointmentBlock
        appointment={appointment}
        serialNo={index + 1}
        key={appointment.id}
        appointmentDetailHash={
          appointmentsDetailHash[appointment.id] ||
          getDefaultAppointmentDetailHash()
        }
        kase={kase}
        onEditClick={this.onEditClick}
        groupedVariantLogs={groupedVariantLogs}
        isCareSpaceView={this.props.isCareSpaceView}
        {...this.getAdditionalProps(appointment)}
      />
    );
  };

  renderAppointments() {
    return this.props.appointments.map(this.renderAppointmentBlock);
  }

  closeAppointmentModal = () => {
    this.setState({ openAppointmentForm: false, editAppointment: null });
  };

  render() {
    return (
      <>
        {this.renderAppointments()}
        {this.state.openAppointmentForm && (
          <AppointmentForm
            kase={this.props.kase}
            onClose={this.closeAppointmentModal}
            appointment={this.state.editAppointment}
          />
        )}
      </>
    );
  }
}

AppointmentListing = connect(({ caseDetailInfo }) => ({ caseDetailInfo }))(
  AppointmentListing,
);
