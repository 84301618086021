import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import hashtagSuggestion from 'app/components/TipTapEditor/hashtagSuggestion';
import NewHashtag from 'app/components/TipTapEditor/hashtag';
import mentionSuggestion from 'app/components/TipTapEditor/mentionSuggestion';
import Dropzone from 'react-dropzone';
import CustomMention from 'app/components/TipTapEditor/mention';
import { Link } from '@tiptap/extension-link';
import { Underline } from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { CustomSubmit } from 'app/components/TipTapEditor/CustomSubmit';
import { Heading } from '@tiptap/extension-heading';
import { useSelector } from 'react-redux';
import MenuBar from 'app/components/TipTapEditor/MenuBar';
import { NO_TEMPLATES_ENABLED } from 'app/components/Templates/constants';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import CustomShortcutMenu from 'app/components/TipTapEditor/CustomShortcutMenu';
import customShortcutMenuSuggestion from 'app/components/TipTapEditor/CustomShortcutMenuSuggestion';
import {
  BASE_SUGGESTION_OPTIONS,
  EDITOR_EXTENSION_SUGGESTION_OPTIONS,
  FEED_EXTENSION_SUGGESTION_OPTIONS,
  NODE_SUGGESTION_OPTIONS_LIST,
} from 'app/components/TipTapEditor/constant';

import {
  FULL_MESSAGE_TEXT,
  SECURE_MESSAGE_TEXT,
} from 'app/components/TipTapEditor/constants';
import TokenMention from 'app/components/Extensions/EditorNode/TokenMention';
import tokenSuggestion from 'app/components/Extensions/Suggestions/TokenSuggestion';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import DisplayContentPortal from 'app/components/Shared/DisplayContentPortal';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';

const customShortcutReducer = (state, action) => {
  switch (action.type) {
    case 'set_selected_option':
      return {
        ...state,
        selectionOption: action.option,
      };
    default:
      return state;
  }
};
const TipTapEditor = forwardRef((props, newRef) => {
  useImperativeHandle(newRef, () => {
    return {
      focusTiptapInputField: focusEditor,
      focusTokenNode: focusTokenNode,
      insertEmoji: insertEmoji,
    };
  });
  const {
    display,
    content,
    onDrop,
    onDirectPaste,
    placeholder,
    onDragEnter,
    editOrReplyContainer,
    assigningObject,
    editorType,
    assignObjectWithoutOpeningModal,
    disabled,
    generalUsersRequired,
    isMessageSecure,
    changeSecureStatus,
    onDragLeave,
    onDropRejected,
    handleEmojiPicker,
    extraProps = {},
    isDropzoneActive,
    mentionGroups,
    onInputFocus = () => {},
    onKeyDown,
    setRef = () => {},
    submitButtonToggled,
    inputActive,
    toggleScheduleDropdown,
    autoFocus,
    isInternalNote,
  } = props;

  const ref = useRef(null);
  const [customShortcutState, dispatch] = useReducer(customShortcutReducer, {
    selectionOption: null,
  });
  const [showEmoji, setShowEmoji] = useState(false);
  const source = useSelector((state) => state.source);
  const workspace = useSelector((state) => state.workspace);
  const { enabled_templates } = useContext(WorkspaceContext);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      CustomSubmit.configure({
        onKeyDown: onKeyDown,
      }),
      Underline.configure({}),
      Link.configure({
        autolink: true,
        openOnClick: false,
      }),
      CustomShortcutMenu.configure({
        HTMLAttributes: {},
        options: editOrReplyContainer()
          ? EDITOR_EXTENSION_SUGGESTION_OPTIONS
          : FEED_EXTENSION_SUGGESTION_OPTIONS,
        suggestion: customShortcutMenuSuggestion,
      }),
      TokenMention.configure({
        HTMLAttributes: {
          'data-type': 'tokenNode',
        },
        renderLabel: ({ node }) => {
          let label = node.attrs.label.split('}').join('').toUpperCase();
          return `{{${label}}}`;
        },
        suggestion: tokenSuggestion,
      }),
      CustomMention.configure({
        HTMLAttributes: {
          className: 'mention',
        },
        suggestion: mentionSuggestion(),
      }),
      NewHashtag.configure({
        HTMLAttributes: {
          className: 'hashtag',
        },
        renderLabel: ({ options, node }) => {
          return `#${node.attrs.label}`;
        },
        suggestion: hashtagSuggestion(),
      }),
    ],
    autofocus: autoFocus,
    editable: disabled !== false,
    content: content,
    onCreate: (props) => {
      // This is just a workaround and not a permanent solution.
      // Probably a better solution would be to have some priority attribute that works on prose mirror plugins as well.
      // For now, we don't have such thing in tip-tap, so we have to do this.
      const { state, view } = props.editor;

      if (state.plugins.length > 0) {
        const restOfPlugins = [];
        const suggestionPlugins = [];

        state.plugins.forEach((plugin) => {
          if (plugin.key.toString().includes('hashtag')) {
            suggestionPlugins.push(plugin);
          } else {
            restOfPlugins.push(plugin);
          }
        });
        view.updateState(
          state.reconfigure({
            plugins: [...suggestionPlugins, ...restOfPlugins],
          }),
        );
      }
    },
    onUpdate: ({ editor, transaction }) => {
      if (editor.getText().trim()) {
        display(editor.getHTML());
      } else {
        display('');
      }
    },
  });

  const clearEditor = () => {
    if (editor !== null) {
      editor.commands.clearContent();
    }
  };

  const insertEmoji = (emoji) => {
    if (editor) {
      editor.commands.insertContent(emoji.native);
    }
  };

  const handleShortcutOptions = (editor, range, option) => {
    // props?.assigningObject('video');
    // editor.commands.insertContentAt(range.from, '@');
    // Check if the option includes in the BASE_SUGGESTION_OPTIONS as key
    if (NODE_SUGGESTION_OPTIONS_LIST.includes(option) || option === null) {
      dispatch({ type: 'set_selected_option', option: option });
    } else if (option === BASE_SUGGESTION_OPTIONS.RECORD_A_VIDEO) {
      props?.assigningObject('video');
    } else if (option === BASE_SUGGESTION_OPTIONS.UPLOAD_A_FILE) {
      assigningObject('file');
    } else if (option === BASE_SUGGESTION_OPTIONS.SCHEDULE_AND_POST_LATER) {
      toggleScheduleDropdown();
    } else if (option === BASE_SUGGESTION_OPTIONS.ADD_EMOJI) {
      if (editOrReplyContainer()) {
        setShowEmoji(true);
      } else {
        handleEmojiPicker();
      }
    }
  };

  const focusTokenNode = () => {
    if (editor !== null) {
      editor.commands.focusToken();
    }
  };

  const focusEditor = (event) => {
    if (editor !== null) {
      const mention = onInputFocus();
      if (mention !== undefined && editor.getText().length === 0) {
        editor.commands.insertContent(mention);
      } else {
        editor.chain().focus().run();
      }
    }
    if (event) {
      // event.stopPropagation();
    }
  };

  const setReference = () => {
    setRef(ref);
    return ref;
  };

  const handleTemplate = (template) => {
    // setSelectedTemplate(template);
    let lastPosition = editor.state.doc.nodeSize - 3;
    editor?.commands.insertContentAt(lastPosition, template.content);
    if (template.files || template.links) {
      assignObjectWithoutOpeningModal(template.files, template.links);
    }

    editor?.chain().focus('end').run();
  };

  useEffect(() => {
    clearEditor();
  }, [submitButtonToggled]);

  useEffect(() => {
    if (editor !== null) {
      if (editorType === 'patient') focusEditor();
      editor.setOptions({
        editorProps: {
          entity: extraProps.entity,
          mentionGroups: mentionGroups,
          generalUsersRequired: generalUsersRequired,
          isWorkspace: source.isWorkspace,
          isInternalNote: isInternalNote,
          workspaceId: workspace?.data?.id,
          handleTemplate: handleTemplate,
          handleShortcutOptions: handleShortcutOptions,
          customShortcut: customShortcutState,
        },
      });
    }
  }, [editor, mentionGroups, customShortcutState]);

  const handlePasteEvent = (event) => {
    const items = event.clipboardData.items;
    for (let index in items) {
      let item = items[index];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        onDirectPaste([file]);
      }
    }
  };
  return (
    <>
      <div
        id={'editor-rect'}
        className={`layout-normal ${inputActive ? 'active-input' : ''}`}>
        {editOrReplyContainer() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            }}>
            <div>
              <MenuBar
                editor={editor}
                showEmoji={showEmoji}
                setShowEmoji={setShowEmoji}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={'editor-template-selector'}>
              {isWorkspaceView() && (
                <BootstrapDropdown
                  async={false}
                  title={'SELECT TEMPLATE'}
                  inputFieldRequired={true}
                  itemKey={'title'}
                  inputFieldProps={{
                    placeholder: 'Type here to filter',
                  }}
                  onClick={(e, template) => {
                    handleTemplate(template);
                  }}
                  items={enabled_templates}
                  emptyRecordsMessage={NO_TEMPLATES_ENABLED}
                />
              )}
            </div>
          </div>
        )}
        <Dropzone
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDropRejected={onDropRejected}
          noClick={true}
          disabled={extraProps.isSharing}
          onDrop={(acceptedFiles) => {
            onDrop(acceptedFiles);
          }}>
          {({ getInputProps, getRootProps }) => {
            return (
              <>
                <div
                  {...getRootProps({ onClick: (event) => focusEditor(event) })}
                  onPaste={handlePasteEvent}>
                  <EditorContent
                    editor={editor}
                    className={
                      editOrReplyContainer()
                        ? 'editor editor-style'
                        : 'reply-editor editor-style'
                    }
                  />
                  <input {...getInputProps()} />
                  {isDropzoneActive && !extraProps.isSharing && (
                    <div
                      className={
                        'filedrop-overlay elem-full-hw d-flex p-0 small-overlay'
                      }>
                      <span className='m-auto fs-16'>
                        <OrthoIcon
                          name='upload'
                          dataHoverNotRequired={true}
                          defaultClass=' me-2 blue3'
                        />{' '}
                        Drop files...
                      </span>
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Dropzone>
        {editOrReplyContainer() &&
          isWorkspaceView() &&
          editorType === 'patient' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
              }}>
              <span
                onClick={changeSecureStatus}
                className='message-secure-container'>
                <p>
                  {isMessageSecure ? SECURE_MESSAGE_TEXT : FULL_MESSAGE_TEXT}
                </p>
                <OrthoIcon
                  name={isMessageSecure ? 'locked' : 'passwordShow'}
                  dataHoverNotRequired={true}
                />
              </span>
            </div>
          )}
        <p ref={setReference()} className='referenceContainer' />
      </div>
    </>
  );
});

export default TipTapEditor;
