import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import InterpolationTextParser from 'app/services/InterpolationTextParser';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import EventTracker from 'app/services/EventTracker';

import { isUrlOfSameDomain } from 'app/utils/urlHelper';
import { isCareSpace } from 'app/utils/spaceHelper';
import { timestamp } from 'app/utils/timeHelper';
import LabelSelector from 'app/components/Partners/LabelSelector';
import { withWorkspaceContext } from 'app/components/HOC/withWorkspaceContext';
import { getLabelNameWithParent } from 'app/utils/labelHelper';

const ACTIVITY_ICON_MAPPER = {
  ActivateUserActivity: 'user-fill',
  CaseCommentActivity: 'comment-fill',
  CasePublishActivity: 'cases-fill',
  EntityFollowActivity: 'follow',
  InsightSubmissionActivity: 'insight-fill',
  PulseCommentActivity: 'reply-fill-left',
  PulseCreationActivity: 'case-person',
  SpaceCommentActivity: (space) =>
    isCareSpace(space) ? 'case-person' : 'comment-fill',
  SpaceContentAddedActivity: 'add',
  SpaceContentRemovedActivity: 'delete',
  SpaceMemberAddedActivity: 'members',
  UserSpaceJoinActivity: 'members',
};

export default class ActivityBar extends Component {
  onLinkClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      e.preventDefault();
      e.stopPropagation();
      EventTracker.trackLinkClicked(e.target.text);
      let href = e.target.getAttribute('href');

      if (href?.startsWith('http') && !isUrlOfSameDomain(href)) {
        window.location = href;
      } else {
        this.props.navigate(e.target.getAttribute('href'));
      }
    }
  };

  additionalText(type = 'template') {
    let text =
        type === 'template'
          ? this.props.feed.template_text
          : this.effectActivity().new_effect_text ||
            this.effectActivity().new_commentable_text,
      interpolationDataInfo =
        type === 'template'
          ? this.props.interpolationDataInfo
          : this.props.effectInterpolationDataInfo;

    return (
      text &&
      InterpolationTextParser.parseText(
        text,
        interpolationDataInfo,
        this.props.interpolationOptions,
      )
    );
  }

  effectActivity() {
    return this.props.feed.effect_activity;
  }

  entity() {
    const { entities } = this.props.feed;
    return entities ? entities[0] : {};
  }

  getIconName() {
    if (!!this.effectActivity() && !isCareSpace(this.entity())) {
      return 'reply-fill-left';
    } else {
      const { source_type } = this.props.feed;
      if (source_type === 'SpaceCommentActivity') {
        return ACTIVITY_ICON_MAPPER[source_type](this.entity());
      } else {
        return ACTIVITY_ICON_MAPPER[source_type];
      }
    }
  }

  renderActivityText() {
    if (!!this.effectActivity()) {
      return this.additionalText('effect') || this.additionalText('template');
    } else {
      return this.additionalText('template');
    }
  }

  renderTemplate(templateText) {
    return (
      <span className='' dangerouslySetInnerHTML={{ __html: templateText }} />
    );
  }

  renderIcon() {
    return (
      <OrthoIcon
        defaultClass=' me-2'
        name={this.getIconName()}
        dataHoverNotRequired={true}
      />
    );
  }

  render() {
    let templateText = this.renderActivityText();
    if (this.props.feed.source_type === 'LabelAddedActivity') {
      let label = this.props.feed.objects[0].label;
      templateText = templateText.replace(
        label.name,
        getLabelNameWithParent(label, this.props.context.person_labels),
      );
    }
    const {
      extraClass = '',
      isInternalNote = false,
      labelSelectorVisible,
      feed,
    } = this.props;
    if (templateText) {
      return (
        <div className={`group-by-space-name ${extraClass}`}>
          <div>
            {this.renderIcon()}
            <span className='username-space' onClick={this.onLinkClick}>
              {this.renderTemplate(templateText)}
            </span>
          </div>
          {this.props.showTimestamp && (
            <span className='timestamp'>
              &nbsp;•&nbsp;{timestamp(this.props.feed.visible_at)}
            </span>
          )}
          {isInternalNote && (
            <div>
              <OrthoIcon
                defaultClass=' me-2'
                name='view'
                dataHoverNotRequired={true}
              />
              <span>Clinic Team Only</span>
            </div>
          )}
          {labelSelectorVisible && <LabelSelector comment={feed.objects[0]} />}
        </div>
      );
    } else {
      return null;
    }
  }
}

ActivityBar.defaultProps = {
  extraClass: '',
};

ActivityBar = withRouter(ActivityBar);
ActivityBar = withWorkspaceContext(ActivityBar);
