export default {
  '2FA_DISABLE_HEADER': 'Your two-factor authentication is off',
  '2FA_DISABLE':
    'Two-factor authentification is a second step when you log in that adds an extra layer of security and helps protect your account.',
  '2FA_ENABLE':
    'Your two-factor authentication is %{on}. Your recovery number is %{phone_number}. Cloudberry will send you a unique verification code when you log in.',
  '2FA_ENABLE_WITHOUT_MESSAGE':
    'Your two-factor authentication is %{on}. Cloudberry will send you a unique verification code when you log in.',
  ADD_CATEGORY_ERROR_MESSAGE: 'Please select at least one hashtag',
  ADD_COMMENT_ERROR_MESSAGE: 'Please add %{type}',
  ADD_FILES_TO_CREATE_CASE:
    'No patient records were added. Please attach files to create a case.',
  ADD_IN_PRIORITY_GROUP_CONFIRMATION:
    'This means you will receive notifications about all treatment updates in this PatientSpace.',
  ALL_CAUGHT_UP: 'You’re all caught up!',
  ALREADY_ACTIVE_USERS: '%{verb} already active and using the system.',
  ARCHIVE_SPACE: 'Are you sure about archiving %{name} Space?',
  ARCHIVED_ENTITY_TEXT:
    'This %{entity} has been archived by the owner. You cannot access it anymore.',
  ATTACHMENT_MODAL_FILE_TYPE_HINT:
    "The size of the image file must not exceed 8 MB. The banner's aspect ratio is 8*1, we recommend using images that are at least 1920*240 pixels for the best display on high resolution devices.",
  CANCEL_SUBSCRIPTION_PLAN: 'Are you sure you want to cancel the subscription?',
  CAN_NOT_DELETE_POST_TEXT:
    "Because your publication already has comments, we can't delete it now. Get in touch with our",
  CANNOT_EDIT_POST_TEXT:
    "Because your post has already have comments, we can't edit it now. Get in touch with our",
  CANNOT_REPLY: 'Connect with %{doctor_name} to start a conversation',
  CARE_ACCESS_REVOKED_MESSAGE:
    'Looks like your administrator has marked your account as inactive. Please, contact your administrator to solve this issue.',
  CARE_NO_ACCESS_MESSAGE:
    "Looks like you don't have access to any workspaces. Please, check your account credentials or contact our support team to create a new workspace on Cloudberry.",
  CARE_SPACE_ACCESS_REVOKED:
    'PatientSpace access was successfully removed for this user.',
  CARE_SPACE_ACCESS_DECLINED_REASON:
    'PatientSpace access has been declined.%{reason}',
  CARE_SPACE_PREFILLED_EMAIL_TEXT:
    "You can't change this field. Please, %{contact_team} if you need to update it.",
  CARE_SPACE_NOT_SHARED_WARNING:
    'The Patient Space has not been shared. Would you like to share it with everyone now?',
  CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION:
    'Are you sure you want to share this PatientSpace with the patient or contact?',
  CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION_WITH_LIST:
    "You're going to share access to this PatientSpace with the following contacts:",
  CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION_WITH_VERIFICATION_MESSAGE:
    'There are some details missing in the patient or contacts. Please make sure to verify before sharing with patient.',
  CARE_SPACE_SHARED_WITH_PATIENT: 'PatientSpace successfully shared',
  PARTNER_SPACE_SHARED_WITH_MEMBERS: 'PartnerSpace successfully shared',
  CARE_SPACE_TREATMENT_TAB_NOT_AVAILABLE:
    'The treatment is disabled right now. It will be available if your appointments are added here.',
  CARE_SPACE_ACCESS_REQUEST:
    'You have rejected the access of this PatientSpace.',
  CARE_SPACE_DELETE_CONTACT:
    'Disable Access for %{name} in this PatientSpace <b>and</b> Remove them as a patient contact.',
  CARE_SPACE_DECLINED_TEXT:
    "You declined access to %{patient_name}'s PatientSpace, and you don’t have any other PatientSpaces available at the moment. Please, contact the clinic team if you declined it by mistake or have any other questions.",
  CARE_SPACE_FEED_INFO_TEXT:
    'This is a chronological activity feed from all PatientSpaces within the clinic. You can read updates, respond to new messages, and see replies from your team members or patients.',
  CARE_SPACE_PASSWORD_HINT_TEXT:
    'The %{clinic_name}’s team has shared %{patient_name}’s PatientSpace with you — enter the patient’s date of birth to unlock it.',
  CARE_SPACE_ONLY_REMOVE_COLLABORATOR:
    'Disable Access for %{name} in this PatientSpace. This person will remain as a patient contact.',
  CARE_SPACE_WARNING_TEXT: 'Warning: This cannot be undone.',
  CASE_ALREADY_PROCESSED:
    "Your case has been processed by the admin and can't be edited anymore. Please contact %{support_link} for more details.",
  CASE_CARD_SUBMITTED_DIAGNOSIS_SINGULAR_MESSAGE:
    '%{totalAttempts} Insight submitted',
  CASE_CARD_SUBMITTED_DIAGNOSIS_PLURAL_MESSAGE:
    '%{totalAttempts} Insights submitted',
  CASE_CREATED_SUCCESSFULLY_HEADER: 'Your case was successfully submitted',
  CASE_CREATED_SUCCESSFULLY_MESSAGE:
    'It may take up to 2 minutes for your submitted case to appear. Someone from Cloudberry team will contact you soon to get more details about your case. Stay tuned!',
  CASE_FORM_CBCT_FILES_INFO_MESSAGE:
    'Please upload DICOMS as ZIPPED folders, and .inv and .amg files only.',
  CASE_INSIGHT_SUBMISSION_NOT_PUBLIC:
    'This case is not available for public insights. Please contact support.',
  CASE_LEFT_SECTION_HOVER_HINT_TEXT:
    'Hover on this area to see records controls',
  CASE_LEFT_SECTION_HOVER_HINT_TEXT_FOR_TOUCH_SUPPORT:
    'Tap on this area to see records controls',
  CASE_LOCKED_CONTENT:
    'This case is locked. Share your insights to see the aggregate results of this case from the Cloudberry Doctor community.',
  CASE_SAVED_TEXT: 'The changes have been saved %{time_passed_text}',
  CASE_SBS_SLIDERS_MOVEMENT_HINT:
    'Records on the right and left sides progress%{type}',
  CASE_SBS_SLIDERS_MOVEMENT_ON_HINT:
    'Records that are only available for both appointments progress at the same time.',
  CASE_SBS_SLIDERS_MOVEMENT_OFF_HINT:
    'Records for both appointments progress separately.',
  CASE_UNLOCKED_BUT_ACCESS_DENIED_CONTENT:
    'Upgrade your plan to see the aggregate results of this case from the Cloudberry Doctor community.',
  CATEGORY_ENTITY_SEARCH_MESSAGE: 'to Find %{type} by Category',
  CLINIC_NO_ACCESS_MESSAGE:
    'You don’t have access to a clinic space right now. We are working to make this feature great for all users.',
  CLINIC_NO_CARE_SPACE_ACCESS_MESSAGE:
    'PatientSpaces are available by subscription only. Interested in signing up for this advanced clinic workspace feature?',
  CLINIC_NO_COLLABORATION_SPACE_ACCESS_MESSAGE:
    'Team Spaces are available by subscription only. Interested in signing up for this advanced clinic workspace feature? ',
  COMPLETE_VERIFICATION_FOR_DISCUSSIONS_ACCESS:
    'Discussions/Members Listing are only available for verified members. Please, finish your onboarding first to see them. %{verification_process_link}',
  CONNECT_WITH_SUPPORT: 'CONNECT WITH OUR SUPPORT TEAM',
  CONNECTION_REQUEST_MESSAGE_LABEL: 'Add a personal message',
  CONNECTION_REQUEST_MESSAGE_PLACEHOLDER: 'Ex: We know each other from...',
  CONNECTION_REQUEST_SENT: 'Connection request sent successfully',
  CONNECTION_REQUEST_UPDATED:
    'This connection request has already been accepted. Please go to %{name} %{profile_link} to remove the connection.',
  CONNECTION_STATUS_UPDATED:
    'Connection request has already been sent by %{name}',
  CONTENT_NOT_ACCESSIBLE:
    "You don't have access to the content. Please contact %{support_link}.",
  COURSE_ACCESS_DENIED: 'You do not have access to this course.',
  COURSE_CREDITS_UNAVAILABLE_CONTENT: '%{content}',
  COURSE_REQUEST: 'if you want to have a course on our platform',
  COURSE_ENDED: 'The course has ended.',
  COURSE_NOT_STARTED: 'The course hasn’t started yet',
  COURSE_END_INFORMATION: 'The course has ended on %{date} @ %{time}.',
  COURSE_START_INFORMATION:
    'The content for this course will appear here on %{date} @ %{time}.',
  COURSE_START_SOON_INFORMATION:
    'The content for this course will appear here soon.',
  COURSE_PAYMENT_DETAIL_HEADING: '2. Payment details (2/2)',
  COURSE_SUCCESSFULLY_SUBSCRIBED:
    'Congratulations! You successfully bought %{spaceTitle}.',
  COURSE_SURVEY_PASSED_CONTENT:
    'You successfully finished the course, and %{ceCredits} CE credits were added to your account.',
  CHANGE_EMAIL:
    'You’re using %{email} to login on Cloudberry. %{connectToSupport} if you want to change it.',
  CHANGE_PASSWORD_HEADING: 'Please, change your password',
  CHANGE_PASSWORD_CONTENT:
    "To keep Cloudberry safe and protect your and your patient's data, we ask each user to change their password once a month. Now it's time to change your password.",
  CREATE_ERROR: 'Some error occurred. Please contact support',
  CREATE_POST_PLACEHOLDER:
    'Create new posts here - ask questions, share clinical #pearls or use @ to mention someone',
  CREDITS_NOT_AVAILABLE_DUE_TO_INCOMPLETE_INFORMATION:
    'Please complete submitting the information we need to verify your account. This will enable you to claim your CE credit and access more features on the platform.',
  CREDITS_NOT_AVAILABLE_DUE_TO_PENDING_VERIFICATION:
    'Thank you for your patience. Your information has been submitted to our team for verification. This process usually takes 2 business days. On being a verified user, you will be able to claim your CE credit here and access more features on the platform.',
  COMING_SOON_PARTNER_SPACES:
    'Soon you will be able to Make referrals and connect with any partner outside your practice in an instant.',
  DECLINE_CARE_SPACE_ACCESS_TEXT:
    'Warning: this cannot be undone. Please, select a reason for declining:',
  DELETED_CARD_TEXT: 'This entity was deleted.',
  DELETE_ENTITY_CONFIRMATION:
    'Are you sure you want to remove this %{entity_type}?',
  DELETE_ENTITY_TEXT:
    "You're going to completely delete this %{entityType}. You can't undo this later. Are you sure you want to delete it?",
  DELETE_POST_TEXT:
    "You're going to completely delete this post. You can't undo this later. Are you sure you want to delete it?",
  DELETE_SCHEDULED_POST:
    'Cancelling a scheduled post will permanently delete it. Are you sure you want to cancel the post?',
  DELETE_SPACE_CONFIRMATION_TEXT:
    'Are you sure you want to completely remove the space from the platform? Keep in mind that all discussions, attached files, etc. will be removed as well.',
  DISABLE_DISCUSSION_INCLUDED_WARNING:
    "Are you sure you want to disable discussions from this space? The existing ones will be hidden, and space members won't be able to add any new comments.",
  DISCUSSIONS_REPLY_PLACEHOLDER: 'Write your reply here...',
  DISCUSSIONS_FOR_PATIENT_PLACEHOLDER:
    'Get in touch with the Clinic Team here. Have a question about your treatment? Add it to Discussions below and a team member will get in touch.',
  DO_NOT_HAVE_COURSE_SUBSCRIPTION:
    'To access and engage with the contents of the course, you need to buy it first.',
  DONT_SHOW_THIS_MESSAGE_AGAIN: 'Don’t show this message again',
  EDIT_BOARD_DESCRIPTION_LABEL: 'Add description here (optional)',
  EMAIL_CHANGE_CONFIRMATION:
    'Updating the email address for %{name} will delete the account associated with their previous email. If they have already logged in, the user will need to go through the sign up process again.',
  EMAIL_VERIFIED_SUCCESSFULLY: 'Your email has been successfully verified.',
  EMAIL_VERIFICATION_FAILED:
    'Your email has not been verified. Please contact %{support_link} for more details.',
  EMPTY_COURSE_LISTING_TEXT: 'Courses are coming soon',
  ENROLL_NOW_FOR_COURSE_TEXT: 'enroll now %{price}',
  ENTITY_ACCESS_REVOKE:
    'This entity is not accessible anymore. Please, get in touch with our %{support_link} for further queries.',
  ENTITY_NOT_ACCESSIBLE: 'This %{entity_type} is not accessible anymore.',
  ENTITY_SUCCESSFULLY_SHARED:
    'The %{entity_name} was shared successfully to %{emails}',
  EXISTING_USERS_INVITED_RESPONSE:
    '%{emails} %{verb} already been invited to the Cloudberry community by %{by}.',
  FEATURE_NOT_AVAILABLE_ON_MOBILE:
    'Sorry, this feature is not available on mobile right now.',
  FILE_PROCESSING_TEXT:
    'This file is being processed and will be available shortly.',
  FILE_SIZE_EXCEEDED:
    '%{file} cannot be uploaded as the size exceeds the max limit %{size}Please try again!',
  FIND_ENTITIES_MESSAGE: 'Find %{type}',
  FIND_ENTITIES_BY_CATEGORY_MESSAGE: 'Find %{type} by Category',
  FILE_NOT_SUPPORTED:
    'We accept files with only %{type} extensions. Please convert your files or get in touch with our %{support_link}.',
  FILE_NOT_SUPPORTED_AND_SIZE_EXCEEDED:
    'Files with only %{type} extensions and under %{size} MB are allowed',
  FILE_NOT_SUPPORTED_HEADER: 'We can’t upload %{types_text}',
  FILE_WITHOUT_EXTENSION_CONTENT:
    'We don’t accept files without any extension. Please add extension before uploading or get in touch with our %{support_link}.',
  FILE_WITHOUT_EXTENSION_ERROR: 'We can’t upload file without extension!',
  FOLDER_ADD_MOBILE_HEADING:
    'Select a folder to find your files, or you can search below to look through all content.',
  FOLDER_ADD_HEADING:
    'Select a folder to find the content you would like to add, or you can search below to look through all content.',
  FOLDER_ATTACH_MOBILE_HEADING:
    'Select a folder to find your attachment, or you can search below to look through all content.',
  FOLDER_ATTACH_HEADING:
    'Select a folder to find your attachment, or you can search below to look through all content.',
  FREE_COURSE_PAYMENT_DETAIL_HEADING: 'Get Access',
  GENERAL_ERROR_MESSAGE: '%{error}',
  LABEL_ERROR_MESSAGE:
    'Please make sure the labels are not associated with related entities. We are unable to delete labels which are in use.<br /><br />Please get in touch with support if you need more help.',
  GENERAL_USER_PRESENT_IN_SPACE:
    'We have non orthodontist users as a member of this space, please be sure of the content privacy before sharing it here.',
  GO_BACK_TO_COURSE:
    'Are you sure you want to cancel the submission? Answers marked will not be retained.',
  GUIDED_TOUR_MODAL_CONTENT:
    'We hope you’ve been enjoying your experience of Cloudberry! It’s such a great community of incredible doctors like you. To get the best experience of the community it’s vital to understand and use the innovative technology platform that supports it. We know some folk take to it faster than others and love to learn and experience things on their own. If you are not one of those folk, don’t worry, we’ve got you covered! You can sign up for one of our Guided Tours of the platform with a power user from our community to help you understand all the nuances from a doctor’s perspective. It’s fun, interactive and a great way to meet other members of the community!',
  GUIDED_TOUR_TEXT:
    "You can sign up for one of our Guided Tours of the platform with a power user from our community to help you understand all the nuances from a doctor's prespective. It's fun, interactive and a great way to meet other members of the community!",
  GUEST_USER_DISCUSSION_BANNER:
    'Create an account to join the community, contribute to discussions, and get access to more spaces.',
  GUEST_USER_EMAIL_EXISTS: 'Already submitted response for this email',
  IMAGE_NOT_AVAILABLE_MESSAGE: 'Image is not available for this view.',
  INFO_CHANGE_SUCCESS:
    'Personal information for %{name} has been updated for this PatientSpace. Once the user logs in and confirms these changes, their User Profile will be updated as well.',
  INFORMATION_TEXT: 'Learn more information about this workspace',
  INSIGHT_SUBMISSION_NOTIFY_MODAL_BODY_TEXT:
    'Here is a reminder that all the Insight submissions are shared with all other doctors on the Cloudberry platform and none of the answers can be changed after the submission. Please, make sure you check the answers before sharing.',
  INSIGHT_SUBMISSION_NOTIFY_MODAL_HEADER_TEXT:
    'Submit your Insight for the Case',
  INVALID_PASSWORD:
    'Please enter the correct PIN for your account to enter the workspace.',
  INVALID_OLD_PASSWORD:
    'Please enter the correct account password so we can update your account PIN. If you help resetting the account password, please logout and initiate the password reset flow.',
  INVITE_LIMIT_EXCEEDED:
    'Sorry, %{emails} %{verb} not invited as you have 0 invites available. To invite more of your friends/Colleagues to the platform, please send an Invite credit request from ',
  INVITE_LIMIT_INCREASE_REQUEST_ERROR:
    'Request could not be processed. Please try again',
  INVITE_LIMIT_INCREASE_REQUEST_SUCCESS:
    'Monthly invite increase request sent successfully',
  INVITE_USER_EMAIL_BODY:
    "Hi there,\n\nI know sharing cases and learning with a great group of committed orthodontists is something that matters to you! Here's your invitation to join me and be part of Cloudberry.\n\nUse the following invite code: %{code}.\n\nFollow this link: %{link}\n\nSee you on the other side!",
  INVITE_USER_EMAIL_SUBJECT:
    '%{full_name} invited you to be part of Cloudberry',
  IN_PROGRESS_CONTACT_SUPPORT_MESSAGE:
    'This case is under review by our team. We will let you know as soon as the case is available.',
  IN_PROGRESS_MESSAGE: 'In progress',
  INVALID_MAGIC_LINK_TOKEN:
    "The magic link is invalid. Please contact %{support_link} if you're facing issues to login.",
  INVITATION_MODAL_TEXT:
    "Want to invite someone who is not here? Copy and share your personal code below with your colleagues. We would like to keep Cloudberry safe, so, please, share the code with the doctors you know and don't post it on the Internet.",
  INVITE_CODE_EXPIRED: 'This invite code is no longer valid.',
  INVITE_DOCTORS_TEXT: 'Share your experience with other colleagues',
  INVITED_PENDING_DOCTOR:
    'Your code was used by the Doctor to signup on the platform but his/her account has not yet been activated by Cloudberry.',
  INFO_CHANGE_REQUESTS_TEXT:
    'Would you like to accept these changes? This information will be applied to your User Profile and any PatientSpaces associated with your account.',
  JOIN_SPACE_TO_ACCESS_CONTENT: "You don't have access to this content.",
  LEAVE_SPACE_CONFIRMATION: 'Do you want to leave this space?',
  REMOVE_MEMBER_CONFIRMATION:
    'Warning: this cannot be undone. You will have you to invite the user again.',
  REMOVE_MEMBER_MIGRATE_USER_TEXT:
    'Migrate Tasks, Folders and Groups to an existing User*',
  LOCKED_CARE_SPACE_TEXT:
    'A new PatientSpace has been shared with you by %{clinicName}.',
  LEGEND_ACCESSED:
    'This PatientSpace has been shared and accessed by the Patient/Contacts.',
  LEGEND_NOT_SHARED:
    'This PatientSpace has not been shared with the Patient/Contacts.',
  LEGEND_SHARED:
    'This PatientSpace has been shared but not accessed by the Patient/Contacts.',
  LEGEND_DECLINED: 'This PatientSpace has been declined by a Patient/Contact.',
  LEGEND_MUTED:
    'One or more members of this PatientSpace have their notifications muted.',
  LEGEND_NOT_VERIFIED: 'This user has not logged in to set up their account.',
  LEGEND_VERIFIED:
    'This user has finished logging in and setting up their account.',
  LEGEND_INFO_PENDING:
    "A Clinic Team Member has made a change to this Patient/Contacts's information which is waiting to be approved.",
  LOADING: 'Loading...',
  MAIL_SEND_TO_SIGN_TOU_SUCCESSFULLY:
    'An email with the link to sign the TOU was sent to your email id %{email}',
  MENTIONEE_WITHOUT_ACCESS_WARNING_IN_OPEN_SPACE:
    'You mentioned %{users} in this post but they won’t be able to see it because they are not part of this space. You can %{share} this space with them so that they can request to join it or ask the admin to invite them.',
  MENTIONEE_WITHOUT_ACCESS_WARNING_IN_PRIVATE_SPACE:
    'You mentioned %{users} in this post but they won’t be able to see it because they are not part of this private space. They need to be invited to this space by the admin.',
  MOBILE_COMPARISON_NOT_AVAILABLE:
    'Case Comparisons are not available on mobile devices yet. You can continue browsing on your laptop, desktop or your tablet.',
  MUTE_SPACE_CONFIRMATION_TEXT:
    'When your notifications are muted you will not receive any alerts from this %{space_type}.',
  NEW_APPOINTMENT_DRAG_FILES_HINT:
    'Drag and drop images to their proper location to upload. You can add additional records under the files tab.',
  NEW_APPOINTMENT_OPTIONAL_FILES_HINT:
    'Adding files on this tab is optional. You can edit this later if you want to.',
  NEW_CASE_DROP_TEXT: 'Drag and drop your patient records here',
  NEW_CASE_FILES_ALLOWED_HINT_HEADER:
    'For a complete patient record please upload:',
  NEW_CASE_FILES_ALLOWED_HINT_1: 'Patient Photos (.jpg or .png):',
  NEW_CASE_FILES_ALLOWED_HINT_1_A:
    'Please upload images as individual files, not a gallery collage.',
  NEW_CASE_FILES_ALLOWED_HINT_1_B:
    'Each image should be named so that it corresponds to acquisition date on your treatment card.',
  NEW_CASE_FILES_ALLOWED_HINT_2: 'Radiographs:',
  NEW_CASE_FILES_ALLOWED_HINT_2_A:
    'All 3D (DICOM, inv) or 2D (.jpg or .png) - Do NOT upload individual .dcm files, please zip first.',
  NEW_CASE_FILES_ALLOWED_HINT_3: 'Treatment Card:',
  NEW_CASE_FILES_ALLOWED_HINT_3_A:
    'Export a single file (pdf, doc, xls, csv) from your practice management system.',
  NEW_COMMENT_MESSAGE: 'Share your thoughts on this %{source}',
  NEW_RELEASE_BANNER:
    'We made some changes to make the experience even better. Please refresh to get the latest updates.',
  NEW_SPACE_SHARE_LINK_HINT:
    'This name will appear in your link settings if you need to make edits to it later.',
  NOT_VERIFIED_BLOCK_HEADING: 'Your verification is in progress',
  NOT_VERIFIED_BLOCK_CONTENT_FOR_NON_PREFERRED_USER:
    'To keep Cloudberry safe and protect the community we are verifying your doctor credentials. If you are from a country other than the USA and Canada you might have to wait a while longer as we are releasing Cloudberry one region at a time to ensure we support you as best as we can. Once you have been verified, you will be able to access everything we have to offer doctors – cases, courses, groups, and a great community!',
  NOT_VERIFIED_BLOCK_CONTENT_FOR_PREFERRED_USER:
    'To keep Cloudberry safe and protect the community we are verifying your doctor credentials. Once verified, you will be able to access everything we have to offer doctors - cases, courses, insights, groups, and a great community!',
  NO_ACTIVITY_BY_ME: 'All your activities will be displayed here.',
  NO_ACTIVITY_BY_USER: 'There is no activity from the user yet.',
  NO_ACTIVITY_YET: 'There is no activity from your connections yet.',
  NO_UNPROCESSED_COMMENTS_PRESENT: 'There are no pending comments present.',
  NO_TEMPLATES_ENABLED:
    'No Templates are currently active. Please add new templates from within the Clinic Settings.',
  NO_APPOINTMENTS_FILES_PRESENT:
    'There are no appointment for this patient yet',
  NO_COMMENTS_IN_BOARD_TEXT:
    'You can save all interesting comments from cases, comparisons, spaces and posts to this space',
  NO_LECTURES_MESSAGE:
    'The videos for this session will appear here after the session has been presented during the %{eventName} Live event',
  NON_MEMBER_GROUP_SPACE:
    'You do not have access as you are not a member of this space. Please request to join.',
  NOTIFICATION_PREFERENCE_INFO_MESSAGE:
    'Select where you would like to be informed of any activity which takes place in the system.',
  NO_IMAGES_AND_VIDEOS_PRESENT: 'This case is under review by our team.',
  NO_MESSAGES:
    'You have not sent or received messages. Click on New Message to start a conversation.',
  NO_CASE_RESPONSE_SUBMITTED_MESSAGE:
    'No answers were submitted for this case.',
  NO_MEMBERS_FOUND: 'No members found.',
  NO_MEMBERS_IN_PARENT_SPACE_FOUND:
    'Share your parent space with other users to invite them in subspace.',
  NO_SUGGESTIONS_FOR_MEMBERS_FOUND:
    "You don't have access to any member, to send an invite.",
  NON_PUBLISHED_COURSE_TOU_NOT_ACCEPTED:
    'An email with the link to sign the document was sent to your email %{email}. Please sign the document to make this course eligible for publishing. In case the email was not received, please click the Sign TOU button above.',
  NOT_PART_OF_WORKSPACE:
    'You are not part of the workspace. Please logout and try again.',
  NOT_PREVERIFIED_USER_ONBOARDING: 'Email is already taken. %{reset_mail}',
  NON_SUBSCRIBED_WORKSPACE:
    'You do not have access. Please contact support(support@mycloudberry.com) for demo.',
  ONBOARDING_NOT_PREFERED_DOCTOR_MESSAGE:
    'In order to keep Cloudberry safe and secure we will need to verify the information you have provided. If you are from a country other than the USA and Canada, there will be a longer wait as we are officially rolling out to various countries on a monthly basis. This is to ensure our adherence to data and privacy standards in healthcare for each specific country. Thank you for your patience.',
  ONBOARDING_PREFERED_DOCTOR_MESSAGE:
    'In order to keep Cloudberry safe and secure we will need to verify the information you have provided. If we need further information for your verification our team will be in touch very shortly. Right after you have been verified your account will be activated on Cloudberry. Thank you for your patience.',
  ONBOARDING_SUCCESS_TITLE:
    'You’ve successfully registered on Cloudberry. What’s next?',
  ONLY_CARE_TEAM_MEMBER_MESSAGE:
    'Only Care Team member will be able to update the status',
  ORAGNIZATION_BECOME_EDITOR_NUDGE_MESSAGE:
    'Want to add more information, content or invite your colleagues to Cloudberry. Request for Editor rights %{support_link}.',
  ORGANIZATION_EDITOR_REQUEST_TO_SUPPORT_FLO_MESSAGE:
    'Hi,\nI want to become editor of mentioned organization space, so that I can do the following.\n1. Invite my colleagues.\n2. Add more information about the organization.\n3. Add more informational content related to organization which will help in conversion like brochure testimonials etc.\n4. Replace the top banner with my organization banner.\n Here is the link of my organization. %{link}\n\n Thank you.',
  OTP_EXPIRY_HINT: 'Code will expire in 10 min.',
  OTP_SENT_HEADING:
    'Thanks for keeping your account secure. We sent you a 4-digit code to verify your account. Please, check your messages: (xxx) xxx-',
  OUTSIDE_WORKING_HOURS:
    'Thank you for your message! Our care team is in the office %{clinic_timing}. We will get back to you as soon as possible.<br /><br />If you need immediate help, please call our office for further instructions.',
  OWNER_EDITOR_FOR_TAB_HINT: 'This tab is visible for Owner or Editor',
  PARTNER_SPACE_SHARE_WITH_CLINIC_CONFIRMATION_WITH_LIST:
    "You're going to reshare access to this PartnerSpace with the following contacts:",
  PATIENT_ALREADY_VERIFIED:
    "%{name} has not unlocked this Patient Space using the Patient's Date of Birth. They will still receive notifications for posts made in the Patient Space but must unlock it first in order to respond and interact within the Patient Space.",
  PASSWORD_ALREADY_PRESENT:
    'Your account PIN is already present, hence we are unable to set this PIN. Please go to your Account settings if you want to update your PIN.',
  PATIENT_FORM_PHONE_OR_EMAIL_WARNING:
    'Please provide a valid contact method for the patient or guardian. You must enter either an email address or a phone number.',
  PATIENT_INFO_CONFIRMED_SUCCESS_TEXT:
    'Personal information has been updated for your PatientSpaces and User Profile.',
  PASSWORD_SUCCESSFULLY_UPDATED_MESSAGE:
    "The password has been successfully updated. You'll be logged out of all the other devices.",
  PASSWORD_VALIDITY_HINT_TEXT:
    'The password should be at least 8 symbols, including 1 UPPERCASE, 1 lowercase, and 1 special character',
  PATIENT_DETAIL_CHANGE_CONFIRMATION:
    'Personal information for %{name} will be updated for their PatientSpaces and User Profile.',
  PAYMENT_ERROR: "We can't proceed with your payment",
  PENDING_CASE_QUESTIONS_QUIZ_ERROR_MESSAGE:
    'To see the results, please complete these questions:',
  PENDING_QUIZ_ERROR_MESSAGE: 'Please complete these questions:',
  PERSON_EMAIL_CHANGED_SUCCESSFULLY:
    'The email address for for %{name} has been updated. You will need to share the PatientSpace with them again to give them access.',
  PHI_DISABLE_MESSAGE: 'Disable this to view PHI for your cases',
  PHI_ENABLE_MESSAGE: 'Enable this to hide PHI for your cases',
  PHONE_NUMBER_SUCCESSFULLY_UPDATED_MESSAGE:
    'Two-factor authentification has been successfully enabled for your account.',
  PHONE_NUMBER_VALIDITY_HINT_TEXT:
    'Phone number must be 7 to 15 digits long without country code.',
  POPULAR_ENTITY_SEARCH_MESSAGE: 'for Popular %{type}',
  PREVERIFIED_USER_FORGOT_PASSWORD_SUB_TEXT:
    'This pre validation process is only for users who have been intimated by Cloudberry or one of our partners of your preverified status.',
  PREVERIFIED_USER_ONBOARDING:
    'You are a pre verified user. %{reset_mail} to validate your email.',
  PRIVATE_CARD_TEXT: 'This is a private %{type}',
  PRIVATE_CONTENT_ADDED_SPACE_CONTENT:
    'You are going to add a private %{entity_type} to the shared space. Keep in mind that none of the private entities will be available for other users of the space.',
  PRIVATE_CONTENT_IN_SPACE:
    'Some of the entities in this space are private therefore they will not be available to the members.',
  PUBLISHED_COURSE_TOU_NOT_ACCEPTED:
    'We recently updated the TOU for this course, please accept the TOU to continue using all course features. An email with the link to sign the document has been sent to your email %{email}. In case the email was not received, please click the Sign TOU button above.',
  PROFILE_INFO_SUBMITTED: 'Your account is ready to use',
  PROFILE_SWITCHER_DISABLED_FOR_YOUR_WORKSPACE:
    'Please reach out to support@mycloudberry.com to enable support for PIN login within your Clinic Workspace.',
  PROFILE_SWITCHER_NOT_ENABLED_FOR_WORKSPACE:
    'Please reach out to support@mycloudberry.com to enable support for PIN login within your Clinic Workspace.',
  PULSE_ATTACHMENTS_LIMIT_ERROR:
    "We can't upload more than %{limit} files to 1 post. You can change the attachments here or create a new post.",
  PULSE_ATTACHMENTS_LIMIT_MESSAGE:
    'You can attach up to %{limit} items to one post, including text, photo, and video formats.',
  PULSE_ATTACHMENTS_LIMIT_MODAL_ERROR:
    "You've reached the limit of the attached files. You can only attach upto %{limit} items to one post. You can change the uploaded items or add a new post.",
  PULSE_ATTACHMENTS_LIMIT_PROGRESSIVE_MESSAGE:
    'You can attach %{limit} more items to one post, including text, photo, and video formats.',
  REGISTER_FOR_EVENT:
    'The %{entity_name} is only accessible to registered attendees.',
  RESET_PASSWORD_SEND_FAILED:
    'We are unable to find the account with the email or phone number you entered. Please contact %{support_link} for further details.',
  RESET_PASSWORD_SENT_SUCCESSFULLY:
    'Instructions for access have been sent to the email you entered.',
  REPORTED_ENTITY_TEXT:
    'This %{entity} has been removed, because it violates our term of use',
  REPORT_OTHER: "It shouldn't be on Cloudberry",
  REPORT_SPAM: "It's spam",
  REPORT_TEXT:
    "Thank you for helping us to keep the platform clean and safe! Help us understand what's wrong with it:",
  RESET_PASSWORD_LINK_EXPIRED:
    'The reset password token has expired. Please generate another link to reset your password.',
  REQUEST_FOR_COURSE_TEXT:
    'Hey there, I would like to have a course on the platform. Can you help me with it?',
  REQUEST_FOR_COURSE_EVENT_TEXT:
    'Hey there, I would like to have a course/event on the platform. Can you help me with it?',
  REQUEST_TO_JOIN_THE_SPACE_SENT_SUCCESSFULLY:
    'Request to join this space has been sent successfully.',
  RESHARE_CARE_SPACE_CONFIRMATION:
    'This PatientSpace has already been shared with this user on %{date}. Are you sure you want to share this PatientSpace again?',
  RESULTS_NOT_FOUND:
    "No matching results with '%{searchInput}' has been found. Check spelling or try more general words.",
  RESULTS_NOT_FOUND_ADD_NEW:
    "No matching results with '%{searchInput}' has been found. Check spelling or add this person as a new contact",
  REVIEW_COURSE_CART_WITH_PRICE: '1. Review your cart (1/2)',
  REVIEW_COURSE_CART_WITHOUT_PRICE: 'Review your cart',
  SAVE_NOTE_FOR_PREVIEW: 'Please save your note to preview this image.',
  SECURE_NOTIFICATION_VISIBILITY: `You have chosen “ <i class='ifill-os-views'></i> Deliver Full Message”. This means: <br />
  <ul>
    <li> The entire message will be sent in the email and text message to the patient and the contacts.</li>
    <li> This removes the security of having the information accessed only on the Patient Space in Cloudberry.</li>
    <li> If you choose to send this message in full to the Patient, to ensure HIPAA compliance, do not include
      any Protected Health Information (PHI) of the patient.</li>
  </ul>`,
  SELECT_CLINIC_FOR_PARTNER_SPACE_INVITE:
    'To select "%{clinicName}" for collaboration with %{initiatedByClinic}.',
  SEND_CONNECTION_REQUEST_HEADING: 'Send a connection request',
  SEEN_ALL_FEEDS: 'You’ve already seen all posts below',
  SESSION_SUCCESSFULLY_TERMINATED:
    'The device has been successfully logged out.',
  SESSION_UNSUCCESSFULLY_TERMINATED:
    'There was some issue while terminating the session. Please contact %{support_link} for further details.',
  SHARED_SUCCESSFULLY: 'The %{entity_type} has been successfully shared.',
  SINGLE_ENTITY_SEARCH_MESSAGE: 'for a Specific %{type}',
  SEARCH_MORE_GENERIC: 'Click here to search discussions, contents or members.',
  SHARE_COMMENT_EMAIL_SUBJECT:
    'Check out the new comment by %{doctor_name} on %{entity_name}',
  SHARE_COMMENT_TITLE:
    '%{doctor_name} commented on Cloudberry. See what they said on the %{entity_name}',
  SHARE_LINK_MODAL_CONTENT_HINT:
    "People who receive this shared link won't be able to edit the original space or add any comments until they sign up on the platform.",
  SHARE_PULSE_EMAIL_SUBJECT:
    'Check out the new post on Cloudberry by %{doctor_name}',
  SHARE_PULSE_TITLE:
    '%{doctor_name} posted on Cloudberry. Check out what was said.',
  SOMETHING_WENT_WRONG:
    'Something went wrong! Please refresh the page and try again. Get in touch with our %{support_link} if you still face any problem.',
  SOMETHING_WENT_WRONG_WHILE_OPENING_LINK:
    'Sorry, we can\'t open this link here due to the permissions set by the application. Please click on "Open Link" to open this link in a new tab.',
  SOMETHING_WENT_WRONG_WHILE_PLAYING_VIDEO:
    'Sorry, we can\'t play this video here! Please click on "Open Video" to play this video in a new tab.',
  SPACE_IMAGE_HINT_TEXT:
    'The size of the image file must not exceed %{size} MB. Use images that are at least 1128 x 360 pixels for the best display on high resolution devices.',
  SPACE_MUTE_BANNER_TEXT:
    '%{icon} Your notifications for this %{space_type} are muted.',
  SPACE_PRIORITY_GROUP_BANNER_TEXT:
    '%{icon} You are not a part of this patient’s %{bold_text}. This means that you will only receive notifications when you are directly mentioned.',
  SPACE_REQUEST_IGNORED_TEXT: 'This space join request was ignored.',
  SPACE_REQUEST_NOT_AVAILABLE: 'This space join request was withdrawn.',
  SPACE_REQUEST_PENDING: 'Your request to join the space is pending.',
  SPACE_REQUEST_PENDING_REQUESTED_BY_AUTHOR:
    'Request pending from your end. Please take required action to continue.',
  SUBMIT_INSIGHT_TEXT:
    'Share your insights to see the aggregate results of this case from the Cloudberry Doctor community.',
  SUBMIT_INSIGHT_TEXT_DEPRICATED:
    'Share your insights to see the aggregated results of this case from the Cloudberry community.',
  SUBMIT_NEW_CASE_HEADING: 'Submit new case details',
  SUBSCRIPTION_MODAL_CONFIRMATION_HEADING: 'Review your purchase (3/3)',
  SUBSCRIPTION_MODAL_PAYMENT_HEADING: 'Add your payment details (2/3)',
  SUBSCRIPTION_MODAL_PLANS_HEADING: 'Update your subscription plan (1/3)',
  SUBSCRIPTION_MODAL_UPDATE_CARD_HEADING: 'Your payment details',
  SUCCESSFULLY_INVITED: 'An invitation email has been sent to %{emails}.',
  SUCCESSFULLY_LOGIN_MESSAGE: 'You have been successfully logged in.',
  SUCCESSFULLY_LOGOUT_MESSAGE: 'You have been successfully logged out.',
  SUCCESSFULLY_REPORTED_TEXT:
    'Your request is under review. Thank you for helping us to keep the platform clean and safe',
  SUGGESTION_TITLE: 'Cloudberry is a community',
  SUGGESTION_HEADING:
    'To gain the most from the platform, connect with other doctors',
  SYSTEM_UPGRADE_HEADER_TEXT: 'We’re upgrading the platform!',
  SYSTEM_UPGRADE_SUB_HEADER_TEXT_1: 'We can’t wait for you to see it.',
  SYSTEM_UPGRADE_SUB_HEADER_TEXT_2: 'Please check back soon.',
  TAB_VISIBLE_FROM_BANNER: 'This tab will visible to members from %{time}',
  TEMPLATE_CARE_HEADING: 'Patient Presets',
  TEMPLATE_CARE_MESSAGE: `Define your communication structure for common patient requests, like <b>'I want to reschedule my appointment'</b>, to streamline interaction and improve response times.<br><br> Remember, use tokens in curly braces, such as <b>{date}</b> or <b>{time}</b>, to ensure patients provide necessary details within the preset.`,
  TEMPLATE_CLINIC_HEADING: 'Shared Team Templates',
  TEMPLATE_CLINIC_MESSAGE:
    'Here are all the message templates which are shared between your team easily available via a quick menu or via the `/` command',
  TIP_OF_THE_DAY_TITLE: 'Did you know?',
  TOKEN_ERROR_MESSAGE:
    'Please make sure that all values are filled in correctly in the template',
  TOU_NOT_ACCEPTED:
    'We recently updated the TOU for this %{entity}, please accept the TOU to continue using all course features.',
  TREATMENT_DATA_NOT_AVAILABLE:
    'Treatment for this case is not available right now. For more information please  ',
  TRY_AGAIN_CONTENT: 'Please, try again or get in touch with our',
  TRY_AGAIN_CONTENT_WITH_SUPPORT:
    'Please, try again or get in touch with our %{support_link}',
  UNARCHIVE_SPACE: '%{name} space has been unarchived.',
  UNARCHIVE_GROUP: " The Group '%{name}' has been unarchived.",
  UNAVAILABLE_CARD: 'This entity is unavailable.',
  UNLOCK_SPACE_ERROR:
    "We're unable to lock this PatientSpace. Please %{support_link} for more help.",
  UPDATE_PASSWORD_SECURE_YOUR_ACCOUNT_HEADING: 'Secure Your Account',
  UPDATE_PASSWORD_TWO_STEP_VERIFICATION_HEADING: 'Two-Step Verification',
  USER_ONBOARDING_COMPLETE_VERIFICATION_BANNER_MESSAGE:
    'Complete verification process to access more features on the platform.',
  USER_ONBOARDING_EMAIL_SENT_CONFIRMATION:
    'We sent you a confirmation link in the email to %{email}.',
  USER_ONBOARDING_EMAIL_VERIFICATION: 'Resend confirmation link',
  USER_ONBOARDING_PROFILE_INFORMATION: '2. Add Your Profile Information (2/2)',
  USER_ONBOARDING_PROFILE_SPECIALITY_INFORMATION: '3. Add Your Specialty (3/3)',
  USER_ONBOARDING_RESEND_EMAIL_VERIFICATION:
    'If you haven’t received it within 10 mins, please check your spam folder first. Still not there? Please, reenter your email address below. ',
  USER_ONBOARDING_SECURE_YOUR_ACCOUNT_HEADING: '1. Two-Step Verification (1/2)',
  USER_ONBOARDING_TWO_STEP_VERIFICATION_HEADING: '1. Secure Your Account (1/2)',
  USER_ONBOARDING_TWO_STEP_VERIFICATION_CONTENT:
    'We sent you a 4-digits code to verify your account. Please, check your messages: (xxx) xxx-',
  USER_ONBOARDING_UPDATE_PASSWORD_CONTENT_TEXT:
    'Please, set up a permanent password to keep your account safe and secure.  The password should be at least 8 symbols, including 1 UPPERCASE, 1 lowercase, and 1 special character',
  USER_ONBOARDING_SECURE_YOUR_ACCOUNT_CONTENT:
    'Thanks for keeping your Cloudberry account secure. We will send you a text message to verify your account.',
  USER_ONBOARDING_UPDATE_PASSWORD_HEADING: '2. Permanent Password Set Up (2/2)',
  VERIFICATION_APPROVAL_PENDING:
    'Please wait while we are verifying your details. It usually takes up to 2 business days. %{verification_process_link} ',
  VERIFICATION_PENDING_HEADING:
    'Your account needs to be verified for Cloudberry membership.',
  VERIFICATION_PENDING_MESSAGE:
    'You have signed up and become a member of Cloudberry with access to our platform. For us to continue to serve you, we need you to provide us with information to verify your account. Once you provide this information our team will do the rest. To make this process fast and easy, please provide us with accurate information. If needed we will be in touch with you but otherwise you can expect your account to be verified within 2 business days. Thank you.',
  WELCOME_TEXT_CARESPACE: 'Welcome to your PatientSpace!',
  WELCOME_TEXT_CARESPACE_WITH_OWNER:
    "<b>%{clinic_name}</b> has partnered with <b>Cloudberry</b> to bring better care during every step of your treatment. Using your own personal PatientSpace you can engage in your treatment, chat directly with the %{clinic_name} team, and stay on top of updates relating to you or your loved one's care.",
  WELCOME_TEXT_PARTNERSPACE:
    '<b>%{clinic_name}</b> has partnered with <b>Cloudberry</b> to bring better collaboration between both your teams to help you deliver the best care for your patients. Using the Partner Space you can engage in cross team conversations, and stay on top of updates relating to your patients.',
  WELCOME_TEXT_COMMUNITY:
    'Sharing ideas just got easier. Connect and grow with colleagues around the world.',
  WELCOME_TEXT_WORKSPACE:
    'Welcome to %{clinic_name} Workspace. The collaborative platform to provide excellent care.',
  WORKSPACE_UPGRADE_HEADING_FOR_PATIENT:
    'Your PatientSpace is getting an upgrade! ',
  WORKSPACE_UPGRADE_HEADING_FOR_TEAM: ' Your WorkSpace is getting an upgrade!',
  WORKSPACE_UPGRADE_MESSAGE_FOR_PATIENT:
    'We expect to be finished with all updates by 7/11/2022. Thank you for your patience as we work to make your care expierience even better.  If there is an urgent matter, please get in touch with your clinic team directly.',
  WORKSPACE_UPGRADE_MESSAGE_FOR_TEAM:
    'Thank you for your patience as we implement these changes. We expect it to be finished with all updates by 7/11/2022.',
};
