import React from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withApollo } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Outlet } from 'react-router-dom';

import AbstractLayout from './AbstractLayout';
import AttachmentPreviewModal from 'app/components/AttachmentPreview/AttachmentPreviewModal';
import AuthModal from 'app/components/AuthModal';
import Footer from 'app/components/Shared/Footer';
import InfoModal from 'app/components/InfoModal';
import NavBar from 'app/components/NavBar';
import PlayerModal from 'app/components/PlayerModal';

import { isSettingEnabled } from 'app/utils/generalHelper';
import { bindMobileFilter } from 'app/actions/device';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import { setWorkspaceData } from 'app/actions/workspace';
import { authModal } from 'app/actions/authentication';
import { AUTH_ACTIONS } from 'app/constants';
import UniversalBanner from 'app/components/HOC/UniversalBanner';

const CURRENT_USER_QUERY = queryLoader(
  'app/graphql/queries/GuestUserWorkspace.gql',
);

class GuestUserLayout extends AbstractLayout {
  componentWillReceiveProps(nextProps) {
    if (this.props.data.loading && !nextProps.data.loading) {
      nextProps.setWorkspaceData(nextProps.data.workspace);
    }
  }

  componentDidUpdate() {
    this.launchAuthModal();
  }

  customComponentDidMount() {
    addClassToHTML('guest-layout');
    document.getElementById('footer').classList.remove('hidden');
    this.launchAuthModal();
  }

  launchAuthModal() {
    let { action } = this.props;
    if (this.shouldOpenLoginModal()) {
      action = action || AUTH_ACTIONS.login;
      const options = { onCloseRedirectTo: '/', authModal: action };
      action && this.props.authModal({ options });
    }
  }

  shouldOpenLoginModal() {
    return this.props.action === AUTH_ACTIONS.login;
  }

  customComponentWillUnmount() {
    window.$('body').removeClass();
    removeClassFromHTML('guest-layout');
    document.getElementById('footer').classList.add('hidden');
  }

  isLandingPage() {
    return this.props.routeName === 'home';
  }

  renderGuestView() {
    const { user, workspace } = this.props.data;
    return (
      <div className='guest-user-view'>
        <UniversalBanner />
        <NavBar
          user={user}
          workspace={workspace}
          source={this.props.navBarSource}
        />
        <Outlet />
        <AttachmentPreviewModal />
        <AuthModal />
        <InfoModal />
        <PlayerModal />
        <Footer usePortal={true} />
      </div>
    );
  }

  render() {
    if (!this.props.systemConfig.requestServed)
      return <Loader type='ball-triangle-path' active />;

    if (isSettingEnabled('marcom_site_enabled') && this.isLandingPage()) {
      window.location.reload(true);
      return null;
    }

    if (this.props.currentUser.token) {
      let locationState = this.props.location.state,
        redirectPath = locationState ? locationState.from.pathname : '/';
      return <Navigate to={redirectPath} />;
    } else {
      return this.renderGuestView();
    }
  }
}

GuestUserLayout.defaultProps = {
  navBarSource: 'guest',
};

GuestUserLayout = graphql(CURRENT_USER_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only',
  }),
})(GuestUserLayout);

GuestUserLayout = connect(
  ({ currentUser, device, systemConfig }) => ({
    currentUser,
    device,
    systemConfig,
  }),
  { authModal, bindMobileFilter, setWorkspaceData },
)(GuestUserLayout);

GuestUserLayout = withRouter(GuestUserLayout);
GuestUserLayout = withApollo(GuestUserLayout);
export default GuestUserLayout;
