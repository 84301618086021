import React, { useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import immutableUpdate from 'immutability-helper';
import { loader as queryLoader } from 'graphql.macro';

import { cloneDeep, sortBy } from 'app/utils/osLodash';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import {
  avatarAttributes,
  getOtherParticipants,
} from 'app/utils/entitiesHelper';
import Avatar from 'app/components/Shared/Avatar';
import { timestamp } from 'app/utils/timeHelper';
import ParseText from 'app/components/ParseText';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import MarkRead from 'app/components/Inbox/MarkRead';
import { isEmpty } from 'app/utils/osLodash';
import { getConversationURL } from 'app/utils/routeHelper';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';

import 'app/components/Inbox/conversation-inbox.scss';

const INBOX_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/Inbox/WorkspaceInboxApiSubscription.gql',
);

const ConversationsInboxList = (props) => {
  const { id: workspaceId } = useContext(WorkspaceContext);
  const currentUser = useSelector((state) => state.currentUser);
  let conversationMessagesList = useMemo(() => {
    props.updateCount(props.totalRecords);
    const singleConversations = props.results.filter(
      (c) => c.participants.length < 3,
    );
    return sortBy(singleConversations, (data) => -1 * data.recently_updated_at);
  }, [props.results]);

  useEffect(() => {
    let unsubscribe = () => {};
    const { id: userId } = currentUser.graph;
    if (workspaceId && props.listType === 'MyUnreadConversations') {
      unsubscribe = props.subscribeToMore({
        document: INBOX_SUBSCRIPTION,
        variables: { workspaceId: workspaceId, userId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;

          const { entity } =
            subscriptionData.data.workspace_inbox_api_subscription;

          if (entity && entity.__typename === 'Conversation') {
            let updatedData = cloneDeep(prev);
            if (
              updatedData.records.results.some(
                (obj) => obj.id.toString() === entity.id.toString(),
              )
            ) {
              updatedData.records.results = updatedData.records.results.filter(
                (obj) => obj.id.toString() !== entity.id.toString(),
              );
            } else {
              updatedData.records.total += 1;
            }
            let payload = { records: {} };
            payload.records.results = { $unshift: [entity] };
            updatedData = immutableUpdate(updatedData, payload);
            return Object.assign({}, prev, updatedData);
          }
        },
      });
    }
    return () => unsubscribe();
  }, [workspaceId]);

  const renderAvatar = (conversation, entity) => {
    return (
      <div className='avatar-wrapper'>
        {conversation.unread && <span className='oval' />}
        <Avatar
          {...avatarAttributes(entity)}
          className='avatar avatar-initials'
        />
      </div>
    );
  };

  const renderAvatars = (conversation) => {
    const otherParticipants = getOtherParticipants(conversation),
      otherParticipant = otherParticipants[0];

    return (
      <div className='user-avatar'>
        {renderAvatar(conversation, otherParticipant)}
      </div>
    );
  };

  const getConversationName = (otherParticipants) => {
    return otherParticipants[0].name || otherParticipants[0].email;
  };

  const renderChatContent = (conversation) => {
    const { latest_message } = conversation;
    const otherParticipants = getOtherParticipants(conversation);
    let conversationName =
      otherParticipants &&
      otherParticipants.length &&
      getConversationName(otherParticipants);
    return (
      <div className='content'>
        <div className='c-name'>{conversationName}</div>
        {latest_message && (
          <>
            <div className='c-message'>
              {latest_message?.highlighted_content}
              <ParseText obj={latest_message} className='' />
            </div>
            <div className='c-timestamp'>
              {timestamp(latest_message?.created_at)}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderConversationAction = (conversation) => {
    return (
      <div className='d-inline-block ms-auto a-link fs-12'>
        <CustomDropdown
          name='discussion_actions'
          className='comment-more position-relative'
          flipTheDropdown={260}>
          <MarkRead
            entityId={conversation.id}
            entityType={conversation.__typename}
            type={'my_inbox'}
            entity={conversation}
            queryType={props.listType}
          />
          <div></div>
        </CustomDropdown>
      </div>
    );
  };

  const renderConversationList = (conversation) => {
    return (
      <div
        key={conversation.id}
        id={'-conversation-' + conversation.id}
        className={
          'conversation-list-row ' +
          classnames({
            unread: conversation.unread,
          })
        }>
        <Link
          to={withWorkspaceIdentifier(
            getConversationURL(conversation.id),
            true,
          )}>
          {renderAvatars(conversation)}
          {renderChatContent(conversation)}
          {props.listType === 'MyUnreadConversations' &&
            renderConversationAction(conversation)}
        </Link>
      </div>
    );
  };

  return (
    <div className='conversation-inbox-wrapper'>
      {props.results.length > 0 &&
        conversationMessagesList?.map(renderConversationList)}
    </div>
  );
};

ConversationsInboxList.defaultProps = {
  updateCount: () => {},
};

export default ConversationsInboxList;
