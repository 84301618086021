import { ReactRenderer } from '@tiptap/react';
import { MentionList } from './MentionList';
import tippy from 'tippy.js';
import { underline } from '@aeaton/prosemirror-schema';
import { NODE_SUGGESTION_OPTIONS_LIST } from 'app/components/TipTapEditor/constant';

export default {
  render: () => {
    let reactRenderer;
    let popup;

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('#editor-box', {
          getReferenceClientRect: props.clientRect,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },
      onUpdate(props) {
        reactRenderer?.ref !== null && reactRenderer.updateProps(props);
        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect,
        });
      },
      onKeyDown(props) {
        return reactRenderer.ref?.onKeyDown(props);
      },
      onExit(props) {
        try {
          const editor = props.editor;
          const selectedOption =
            editor.options.editorProps?.customShortcut?.selectionOption;
          if (NODE_SUGGESTION_OPTIONS_LIST.includes(selectedOption)) {
            editor.options.editorProps?.handleShortcutOptions(editor, {}, null);
          }
        } catch (error) {
          // we can ignore this error
        }
        if (popup) {
          popup[0] !== underline && popup[0]?.destroy();
        }
        reactRenderer?.destroy();
      },
    };
  },
};
