import React from 'react';
import { connect } from 'react-redux';

import AbstractDropdown from './AbstractDropdown';
import AnalyticsButton from 'app/components/CaseView/AnalyticsButton';
import ChangePrivacy from 'app/components/Shared/ChangePrivacy';
import CustomDropdown from 'app/components/Shared/CustomDropdown';

import { getEntityDownCaseName } from 'app/utils/entitiesHelper';
import { isFeaturePermitted } from 'app/utils/userHelper';

import { CASE_ANALYTICS_IDENTIFIER } from 'app/components/FeatureFlagging/constants';

class CaseDropdown extends AbstractDropdown {
  ownedByCurrentUser() {
    const {
      currentUser,
      entity: { doctor },
    } = this.props;
    return +currentUser.graph.id === +doctor.id;
  }

  renderChangePrivacy() {
    if (this.ownedByCurrentUser())
      return <ChangePrivacy entity={this.props.entity} />;
  }

  renderAnalysticsButton() {
    if (isFeaturePermitted(CASE_ANALYTICS_IDENTIFIER)) {
      return <AnalyticsButton />;
    }
  }

  renderCustomDropdown() {
    const entity = this.getEntity();
    return (
      <>
        <CustomDropdown
          name={`${getEntityDownCaseName(entity)}_actions`}
          className=''>
          {this.renderAnalysticsButton()}
          {this.renderChangePrivacy()}
          {this.renderAddTo(entity)}
          {this.renderShareButton(entity)}
        </CustomDropdown>
      </>
    );
  }

  renderRequired(entity) {
    return !this.props.guestCase && !entity.is_in_progress;
  }
}

CaseDropdown = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(CaseDropdown);

export default CaseDropdown;
