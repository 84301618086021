import React from "react";
import { Field, Form } from "react-final-form";
import lock from "app/images/profile-switcher/lock.webp";
import OsBtn from "app/components/OsBtn";
import OrthoIcon from "app/components/Shared/OrthoIcon";
import SelectInput from "app/components/Shared/SelectInput";
import GetPinFields from "app/components/SwitchProfile/GetPinFields";
import useProfileResetForm from "app/hooks/useProfileResetForm";
import Avatar from "app/components/Shared/Avatar";
import { avatarAttributes } from "app/utils/entitiesHelper";

function ProfileResetPassword(props) {
  const {
    title,
    workspace,
    user,
    changeUser,
    formValidation,
    handleSubmit
  } = useProfileResetForm(props)

  return <div className="profileSwitcherForm">
    <section>
      <div className='imageContainer'>
        {!workspace.data.owner.owner.logo_big || workspace.data.owner.owner.logo_big === undefined ?
          <img src={lock} alt="profile-lock" /> :
          <img src={workspace.data.owner.owner.logo_big} alt="profile-lock" />
        }
      </div>
      <div
        className={"profile-switcher-card-view"}>
        <Form
          onSubmit={handleSubmit}
          validate={values => {
            let errors = formValidation(values)
            return errors
          }}
          render={(props) => {
            return <form className={"profile-switcher-form"} onSubmit={props.handleSubmit}>
              <p className="profile-switcher-form-heading">
                <strong>{title} Pin</strong></p>
              <div className="profile-switcher-form-user-field">
                <label>User</label>
                <div className={'select-user-profile'}>
                  {title === 'Update' ?
                    <div className="userField">
                      <Avatar className='avatar' {...avatarAttributes(user)} />
                      <span>{user?.full_name}</span>
                    </div>
                    :
                    <div className='users' name="user">
                      <SelectInput
                        isMulti={false}
                        name="user"
                        className="react-select-ortho-2 os-menu-drop"
                        placeholder="Select"
                        formValue={user?.id}
                        onChange={(value) => changeUser(value.entity)}
                        async={true}
                        perPage={50}
                        queryType="ProfileSwitcherEnabledUsers"
                        selectedValueNameRequired={false}
                      />
                    </div>
                  }

                </div>

                <label className='label'>Password*</label>
                <div className={"password-verification"}>
                  <Field name={'verifyPassword'}>
                    {
                      ({ input, meta, placeholder }) => (
                        <div>
                          <input
                            autoFocus
                            {...input}
                            id={"verifyPassword"}
                            type={'password'}
                          />
                          {meta.error && meta.touched && meta.submitFailed && <div className="form-error email-error">
                            <OrthoIcon name="error" defaultClass='pe-1 ms-3 ' />{meta.error}
                          </div>}
                        </div>
                      )
                    }
                  </Field>
                </div>
                <label className='label' htmlFor={"users"}>Enter a new pin*</label>
                <div className={'password-block'}>
                  {GetPinFields('newPin', 4, false)}
                </div>
                <label className='label'>Confirm pin*</label>
                <div className={"password-block"}>
                  {GetPinFields('confirmPin', 4, false)}
                </div>
                {/* <p style={{ marginTop: "30px" }}>Please enter your password to verify your account. </p> */}
              </div>
              <div className="profileSwitcherPrimaryBtn">
                <OsBtn name={"BtnPrimary"} type={"submit"} value={`${title} Pin`} extraClass={"profile-switcher-btn"} />
              </div>
            </form>
          }}
        />
      </div>
    </section>
  </div>;
}

export default ProfileResetPassword;
