import { CLOSE_FOLDER_MODAL, OPEN_FOLDER_MODAL } from 'app/actions/types';

export function openCreateFolderModal(options = {}) {
  return {
    type: OPEN_FOLDER_MODAL,
    options: { ...options, type: 'new' },
  };
}

export function closeFolderModal(options = {}) {
  return {
    type: CLOSE_FOLDER_MODAL,
    options,
  };
}
