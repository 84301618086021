import React from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';

import SpaceRequests from './index';
import OsBtn from 'app/components/OsBtn/';

import { flashError } from 'app/actions/flashMessage';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { updateCurrentUserGraph } from 'app/actions/authentication';

class AcceptRequest extends SpaceRequests {
  render() {
    let extraClass = `${this.props.extraClass} ${
      this.props.onlyIconRequired ? 'text-blue' : ''
    } ${this.addDisabledClass()}`;

    return (
      <OsBtn
        name={this.getNameForButton()}
        text={!this.props.onlyIconRequired && this.getText()}
        label={this.props.label}
        icon={!this.props.onlyTextRequired && this.props.icon}
        extraClass={extraClass}
        onClick={this.performAction.bind(this, true)}
        associatedEntity={this.props.space}
        data-hover={!isTouchSupported()}
        disabled={this.state.requestInProgress}
      />
    );
  }
}
AcceptRequest.defaultProps = {
  icon: 'tick',
  text: 'Accept',
  onlyIconRequired: false,
  onlyTextRequired: false,
  btnType: 'BtnPrimary',
  onSuccess: () => {},
};
AcceptRequest = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { flashError, updateCurrentUserGraph },
)(AcceptRequest);
AcceptRequest = withApollo(AcceptRequest);
export default AcceptRequest;
