import React, { Component } from 'react';

import Avatar from 'app/components/Shared/Avatar';
import ConnectToSupport from 'app/components/ConnectToSupport';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsGrid from 'app/components/OsGrid';
import ShowMoreLess from 'app/components/Shared/ShowMoreLess';
import SimpleNavList from 'app/components/SimpleNavList';
import StickyHeader from 'app/components/StickyHeader';
import View from 'app/components/Shared/View';

import EventTracker from 'app/services/EventTracker';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { orderedDegrees } from 'app/utils/generalHelper';
import { map, upperCase } from 'app/utils/osLodash';
import { getUnreadNotificationCount } from 'app/utils/userHelper';
import { getTabList } from './helper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { DEFAULT_FILTER } from './constants';
import {
  isWorkspaceView,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

class AbstractUserView extends Component {
  isMobileDevice() {
    return this.props.device.mobileDevice;
  }

  isSupportUser() {
    return this.props.user.is_support_user;
  }

  actionsRequired() {
    return !this.isSupportUser() && !this.isGuestUser();
  }

  imageEditActionRequired() {
    return (
      this.isCurrentUser() && !this.isSupportUser() && !this.isMobileDevice()
    );
  }

  connectingToSupport() {
    return (
      !this.isCurrentUser() && !this.isMobileDevice() && this.isSupportUser()
    );
  }

  clinicsPresent() {
    return this.props.user?.clinics && !!this.props.user.clinics.length;
  }

  generalUserCompanyInfoRequired() {
    let { user } = this.props;
    return (
      user?.is_general_user &&
      user.degree_list &&
      !user.degree_list.length &&
      user.clinics &&
      !user.clinics.length
    );
  }

  getClinicNameAndAddress(clinic) {
    return [clinic.name, clinic.address].filter((data) => data).join(' | ');
  }

  renderClinics() {
    return (
      <div className='user-clinic-details'>
        <OrthoIcon name='location' dataHoverNotRequired={true} />
        <div className='address-block'>
          {map(this.props.user.clinics, (clinic) => (
            <div key={clinic.id}>
              {this.getClinicNameAndAddress(clinic)} <br />{' '}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderClinicsMobile() {
    return (
      <div className='user-clinic-details'>
        <OrthoIcon name='location' dataHoverNotRequired={true} />
        <div className='address-block'>
          {map(this.props.user.clinics.slice(0, 2), (clinic, index) => (
            <div key={clinic.id} className='user-clinics-block-mobile'>
              {1 === index ? clinic.name : clinic.name + ','}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderUserInfo() {
    let { user } = this.props;
    if (!this.isSupportUser()) {
      return (
        <p className='user-title d-inline'>
          <span className='user-degree-block'>
            {this.generalUserCompanyInfoRequired() && (
              <>
                {user.company_name}
                {user.company_name && user.designation && ' | '}
                {user.designation}
              </>
            )}
            {!this.generalUserCompanyInfoRequired() &&
              orderedDegrees(this.props.user?.degree_list).join(' ')}
          </span>
        </p>
      );
    }
  }

  displayClinicsAndView() {
    return (
      <>
        <div className='user-options-block engagement-new no-space-between'>
          {!this.isGuestUser() && (
            <View obj={this.props.user} extraClass='d-none' />
          )}
        </div>
        <span className='hidden-mobile'>
          {this.clinicsPresent() && this.renderClinics()}
        </span>
      </>
    );
  }

  renderClinicsView() {
    if (!this.isSupportUser()) {
      if (this.isMobileDevice()) {
        if (this.clinicsPresent())
          return (
            <span className='user-profile-clinics'>
              {this.renderClinicsMobile()}
            </span>
          );
      } else {
        return this.displayClinicsAndView();
      }
    }
  }

  renderHeader() {
    let { mobileDevice } = this.props.device;
    return (
      <div className='detail-header'>
        <div className='text-right user-edit-options-block right-align-dropdown'>
          {this.actionsRequired() && this.displayActions()}
        </div>

        <div className='user user-profile-header row'>
          <OsGrid
            identifier={
              this.isCurrentUser()
                ? 'UserProfile:AvatarContainer'
                : 'UserProfile:OtherUserAvatarContainer'
            }>
            <div className='user-profile-pic-block d-inline-block text-center'>
              {!this.isGuestUser() && this.renderImageEditAction()}
              <Avatar
                className='avatar avatar-168'
                {...avatarAttributes(this.props.user, {
                  largeAvatarRequired: true,
                })}
              />
              {!mobileDevice &&
                !this.isGuestUser() &&
                this.renderConnectionButton()}
              {!isWorkspaceView() &&
                this.connectingToSupport() &&
                !isCareWorkspaceView() && (
                  <ConnectToSupport extraClass='mt-4 mb-3' viewType='button' />
                )}
            </div>
          </OsGrid>
          <OsGrid
            identifier={
              this.isCurrentUser()
                ? 'UserProfile:UserInfoContainer'
                : 'UserProfile:OtherUserInfoContainer'
            }>
            <div className='user__info user-profile-info'>
              <div className='d-flex flex-column spce-left'>
                <h2>{this.props.user?.name}</h2>
                {this.renderUserInfo()}
              </div>
              {this.renderClinicsView()}
              {this.props.user?.bio && (
                <ShowMoreLess content={this.props.user.bio} charLimit={200} />
              )}

              <div className='col-12 text-center visible-mobile user-profile-btns'>
                {!this.isGuestUser() && this.renderConnectionButton()}
              </div>
            </div>
          </OsGrid>
        </div>
      </div>
    );
  }

  filterCountMapper() {
    return {
      notifications: getUnreadNotificationCount(this.props.currentUser.graph),
    };
  }

  onFilterClick = (filter) => {
    const eventData = {
      doctor_name: this.props.user?.name,
      tab: upperCase(filter),
    };
    this.props.navigate(
      withWorkspaceIdentifier(`/users/${this.props.user?.nice_id}/${filter}`),
    );
    EventTracker.track('Doctor Profile Action', eventData);
    this.setState({ filter });
  };

  getTabFilterValue() {
    return this.props.filter || DEFAULT_FILTER;
  }

  renderTabList() {
    return (
      <div>
        <SetPageTitle source='my_profile' />
        <StickyHeader containerClass={'tab-spacing'}>
          <SimpleNavList
            list={getTabList(this.props.user, this.props.source)}
            onFilterClick={this.onFilterClick}
            currentFilter={this.getTabFilterValue()}
            scrollIntoView={this.props.device.mobileDevice}
            filterCountMapper={this.filterCountMapper()}
            source={this.navListSource}
            verifyFilterAllowed={true}
            hideRowWithZeroCount={
              this.props.currentUser.graph &&
              this.props.currentUser.graph.is_general_user
            }
            filterCountMapperForVisibilty={this.filterCountMapperForVisibilty()}
          />
        </StickyHeader>
      </div>
    );
  }
}

export default AbstractUserView;
