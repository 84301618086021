import React, { PureComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

class CareViewNoRecordsPresentRoutes extends PureComponent {
  render() {
    return (
      <Routes>
        <Route path='*' element={<Navigate to='/care/no_records' />} />
      </Routes>
    );
  }
}
export default CareViewNoRecordsPresentRoutes;
