import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import * as compose from 'lodash.flowright';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import OsBtn from 'app/components/OsBtn';

import StoreUpdater from 'app/services/StoreUpdater';

import { LABELS_POSSIBLE_COLORS } from './constants';
import { findKey, keys } from 'app/utils/osLodash';
import { fieldRequired } from 'app/utils/validationHelper.js';
import { translate } from 'app/actions/flashMessage';
import { hexToRgb } from 'app/utils/colorHelper';
import Tippy from '@tippyjs/react';

const DELETE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/DeleteLabel.gql',
);
const UPDATE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateLabel.gql',
);

class Label extends Component {
  state = {
    edit: false,
  };

  toggleNameEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  updateLabel = (attributes) => {
    return this.props
      .updateLabelMutation({
        variables: {
          id: this.props.obj.id,
          ...attributes,
        },
      })
      .then(({ data }) => {
        let response = data.updateLabel;
        if (response.success) {
          this.setState({ edit: false });
        } else {
          let nameError = (response.errors || []).find(
            (obj) => obj.field === 'name',
          );
          if (nameError) {
            let returnErrors = {};
            returnErrors['name'] = nameError.message;
            return returnErrors;
          } else {
            return { [FORM_ERROR]: translate('CREATE_ERROR') };
          }
        }
      });
  };

  deleteLabel = () => {
    this.props
      .deleteLabelMutation({
        variables: { id: this.props.obj.id },
      })
      .then(({ data }) => {
        if (data.deleteLabel.success)
          StoreUpdater.removeLabelFromListing(this.props.obj);
      });
  };

  onColorChange = (color) => {
    this.updateLabel({ color: color.substr(1) });
  };

  renderNameForm() {
    return (
      <div className='label-list-item'>
        <Form
          onSubmit={this.updateLabel}
          initialValues={{ name: this.props.obj.name }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} name='lable_form'>
              <Field
                component={OsField}
                osType='input'
                placeholder='Label Name'
                name='name'
                onBlur={handleSubmit}
                validate={fieldRequired}
                autoFocus={true}
                className='mt-3'
              />
            </form>
          )}
        />
      </div>
    );
  }

  renderNamePillOrInputField() {
    let { name, color } = this.props.obj;
    if (this.state.edit) {
      return this.renderNameForm();
    } else {
      return (
        <div className='bd-pill'>
          <span className='badge pill' onClick={this.toggleNameEdit}>
            <span
              className='badge-color'
              style={{ backgroundColor: `${hexToRgb(color)}` }}></span>
            <span className='bd-text'>{name}</span>
          </span>
        </div>
      );
    }
  }

  renderColorOption(name, color) {
    return (
      <>
        <span
          className='lb-dot'
          style={{ backgroundColor: `${hexToRgb(color)}` }}
        />
        {name}
      </>
    );
  }

  labelColorCode() {
    return `#${this.props.obj.color}`;
  }

  renderColor = (name) => {
    let color = LABELS_POSSIBLE_COLORS[name],
      selected = color === this.labelColorCode();

    return (
      <div
        className='label-list-item'
        onClick={this.onColorChange.bind(this, color)}
        selected={selected}>
        {this.renderColorOption(name, color)}
      </div>
    );
  };

  getColorDropdownTitle() {
    return findKey(
      LABELS_POSSIBLE_COLORS,
      (value) => value === this.labelColorCode(),
    );
  }

  renderColorDropdown() {
    return (
      <CustomDropdown
        chevronIcon={'chevron-caret'}
        name='label_colors'
        dropdownInfo={{ title: this.getColorDropdownTitle() }}
        className='cs-dropdown mw-88 lb-dropdown cs-file-dropdown'>
        {keys(LABELS_POSSIBLE_COLORS).map(this.renderColor)}
      </CustomDropdown>
    );
  }

  renderEditOption() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='edit'
        extraClass='no-text px-8 checkmark-icon'
        onClick={this.toggleNameEdit}
      />
    );
  }

  renderDeleteOption() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='delete'
        extraClass='no-text px-8 checkmark-icon'
        onClick={this.deleteLabel}
      />
    );
  }

  renderColorWithLabelName() {
    let { color, name, sub_labels } = this.props.obj;
    return (
      <Tippy
        content={this.renderSubLabels()}
        placement={this.props.position}
        allowHTML={true}
        interactive={true}
        arrow={false}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <span>
            <span
              className='lb-dot'
              style={{ backgroundColor: `${hexToRgb(color)}` }}
            />
            {name}
          </span>
          {sub_labels.length > 0 && <OrthoIcon name='chevron-right' />}
        </div>
      </Tippy>
    );
  }

  renderSubLabels() {
    let { color, name, sub_labels } = this.props.obj;

    return (
      <>
        {sub_labels?.length > 0 && (
          <div className='sub-labels'>
            {sub_labels.map((subLabel) => {
              return (
                <div
                  key={subLabel.id}
                  className='sub-label'
                  onClick={(e) => {
                    this.props.onSubLabelClick({
                      ...subLabel,
                      color: color,
                    });
                  }}>
                  <span
                    className='lb-dot'
                    style={{ backgroundColor: `${hexToRgb(color)}` }}
                  />
                  {subLabel.name}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }

  render() {
    if (this.props.onLabelClick) {
      return (
        <div
          className={`label-list-item ${this.props.selected ? 'selected' : ''}`}
          onClick={this.props.onLabelClick.bind(this, this.props.obj)}>
          {this.renderColorWithLabelName()}
        </div>
      );
    } else {
      return (
        <div className='lb-list-modal'>
          {this.renderNamePillOrInputField()}
          {this.renderColorDropdown()}
          {this.renderEditOption()}
          {this.renderDeleteOption()}
        </div>
      );
    }
  }
}

Label = compose(
  graphql(DELETE_LABEL_MUTATION, { name: 'deleteLabelMutation' }),
  graphql(UPDATE_LABEL_MUTATION, { name: 'updateLabelMutation' }),
)(Label);
Label = connect(({ device }) => ({ device }), null)(Label);
Label.defaultProps = {
  obj: {},
};
export default Label;
