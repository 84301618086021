import React, { Component } from 'react';

class HTMLContentContainer extends Component {
  componentDidMount() {
    window.$('#carouselExampleIndicators').carousel({
      interval: 3000,
      cycle: true,
    });
  }
  render() {
    return (
      <div className={this.props.containerClass}>
        <div
          onClick={this.props.onInfoContentClick}
          dangerouslySetInnerHTML={{ __html: this.props.content }}
        />
        {this.props.children}
      </div>
    );
  }
}

HTMLContentContainer.defaultProps = {
  containerClass: '',
  content: '',
  onInfoContentClick: () => {},
};
export default HTMLContentContainer;
