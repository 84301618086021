import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';

class FileIcon extends Component {
  render() {
    switch (this.props.fileType) {
      case 'pdf':
        return (
          <OrthoIcon
            name='pdf'
            dataHoverNotRequired={true}
            defaultClass='icon'
          />
        );
      case 'xls':
      case 'xlsx':
        return (
          <OrthoIcon
            name='xls'
            dataHoverNotRequired={true}
            defaultClass='icon'
          />
        );
      case 'doc':
      case 'docx':
        return (
          <OrthoIcon
            name='documentsFile'
            dataHoverNotRequired={true}
            defaultClass='icon'
          />
        );
      default:
        return (
          <div className={this.props.extraClass || ''}>
            <OrthoIcon
              name='document-empty'
              defaultClass='fs-24'
              dataHoverNotRequired={true}
            />
          </div>
        );
    }
  }
}

export default FileIcon;
