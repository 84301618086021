import React from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

// Nice looking card for when it is still loading
export default (props) => (
  <div className={`v2-card v2-card--${props.size} card--loading`}>
    <article className='card__content'>
      <div className='card-top'>
        <OrthoIcon name='image' dataHoverNotRequired={true} />
      </div>

      <div className='card-body'>
        <span className='bar-1 bar'></span>
        <span className='bar-2 bar'></span>
        <span className='bar-3 bar'></span>
      </div>
    </article>
  </div>
);
