/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isEqual } from 'app/utils/osLodash';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import ActionCable from 'app/actioncable/actioncable';
import StoreUpdater from 'app/services/StoreUpdater';

import { sortBy } from 'app/utils/osLodash';
import DirectMessageRow from 'app/components/DirectMessageListing/DirectMessageRow';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import usePreviousValue from 'app/hooks/usePreviousValue';
import DirectMessageAccordionList from 'app/components/DirectMessageListing/DirectMessageAccordionList';

const DirectMessageListingComponent = (props) => {
  const [state, setState] = useState({
    subscription: null,
  });
  const activeConversation = useSelector((state) => state.activeConversation);
  const previousConversationsList = usePreviousValue(props.results);

  const conversationsList = useMemo(() => {
    const singleConversations = props.results.filter(
      (c) => c.participants.length < 3,
    );
    const sortedConversation = sortBy(
      singleConversations,
      (data) => -1 * data.recently_updated_at,
    );
    const sections = {
      joined: [],
      archived: [],
    };

    sortedConversation.forEach((item) => {
      const isArchived = !item.can_participate;
      if (isArchived) {
        sections.archived.push(item);
      } else {
        sections.joined.push(item);
      }
    });

    return sections;
  }, [props.results]);

  useEffect(() => {
    if (!isEqual(previousConversationsList, props.results)) {
      eventChannelConnect();

      return () => {
        eventChannelDisconnect();
      };
    }
  }, [props.results]);

  useEffect(() => {
    if (!state.subscription) eventChannelConnect();
  }, [state.subscription]);

  const eventChannelConnect = () => {
    eventChannelDisconnect();

    const subscription = ActionCable.consumer.subscriptions.create(
      { channel: 'ConversationsChannel' },
      {
        received: eventChannelReceive,
      },
    );

    setState((prev) => ({ ...prev, subscription }));
  };

  const eventChannelDisconnect = () => {
    state.subscription &&
      ActionCable.consumer.subscriptions.remove(state.subscription);
  };

  const eventChannelReceive = (conversation) => {
    const { workspace } = props;
    if (
      !conversation.source_id ||
      conversation.source_id === +workspace.data?.id
    ) {
      conversation.unread = conversation.latest_message?.emoji_reaction_detail
        ? conversation.unread
        : conversation.latest_message.author.id !== +props.currentUser.graph.id;
      conversation.pinned_message_count =
        conversation?.pinned_message_count || 0;
      conversation.unread_message_count =
        conversation?.unread_message_count || 0;
      StoreUpdater.addConversationInConversationsListing(conversation, {
        fullView: isFullPageListing(),
      });
    }
  };

  const isFullPageListing = () => {
    return props.size === 'full-view-listing';
  };

  return (
    <div className={'left-care-space-section'}>
      <div className='container-section'>
        <ul className='ac-user-list'>
          {conversationsList.joined.map((c) => (
            <DirectMessageRow
              key={c.id}
              {...c}
              activeConversationId={activeConversation.payload?.id}
            />
          ))}
          <DirectMessageAccordionList
            title={'Archived'}
            conversations={conversationsList.archived}
            activeConversationId={activeConversation.payload?.id}
          />
        </ul>
      </div>
    </div>
  );
};

const DirectMessageListingConnect = connect(
  ({ currentUser, device, workspace }) => ({
    currentUser,
    device,
    workspace,
  }),
)(DirectMessageListingComponent);
const DirectMessageListing = withRouter(DirectMessageListingConnect);

export default DirectMessageListing;
