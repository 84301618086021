import { useContext } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import OsBtn from 'app/components/OsBtn';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { hexToRgb } from 'app/utils/colorHelper';
import { Accordion } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import RenderSubLabels from 'app/components/Shared/RenderSubLabels';

function LabelWidget(props) {
  const { task_labels } = useContext(WorkspaceContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleLabelClick = (label) => {
    let existingLabels = searchParams.get('label')?.split(',') || [];

    if (existingLabels?.length > 4 || existingLabels.includes(label.id)) return;

    searchParams.set('label', [...existingLabels, label.id]);
    setSearchParams(searchParams);
  };

  return (
    <div className='labelsContainer'>
      <Accordion className={`right-ac sidebar-container container`}>
        <Accordion.Item>
          <Accordion.Header className='rt-ac-header widget-title header labelsContainerHeader'>
            <span className='headerTitle'>Filter by Task Labels</span>
            <div className='btnContainer' onClick={(e) => e.stopPropagation()}>
              <OsBtn
                name='BtnIcon'
                extraClass='no-text px-3 web-view-btn'
                icon='setting'
                onClick={() =>
                  props.navigate(withWorkspaceIdentifier('/settings/labels'))
                }
              />
            </div>
          </Accordion.Header>
          <Accordion.Body className='labels-container-body'>
            {task_labels.map((item) => {
              return (
                <div
                  key={item.id}
                  className='label'
                  style={{ backgroundColor: `${hexToRgb(item.color)}` }}
                  onClick={() => handleLabelClick(item)}>
                  <span className='labelText'>{item.displayName}</span>
                </div>
              );
            })}
            <RenderSubLabels
              handleLabelClick={handleLabelClick}
              labels={task_labels}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

LabelWidget = withRouter(LabelWidget);
export default LabelWidget;
