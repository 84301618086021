import { Command } from 'cmdk';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { getWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateTeamSpaceModal } from 'app/actions/teamSpaceModal';
import { isFeaturePermitted } from 'app/utils/userHelper';
import {
  ADD_NEW_PATIENT,
  ADD_NEW_TASK,
  ALL_TASKS,
  ALL_TEAM_MEMBERS,
  CLINIC_INFORMATION,
  CREATE_NEW_GROUP,
  DIRECT_CONVERSATION,
  DIRECT_CONVERSATION_NAVIGATION,
  DIRECT_CONVERSATION_PLACEHOLDER,
  FILTER_VALUES,
  GROUPS,
  GROUPS_NAVIGATION,
  GROUPS_PLACEHOLDER,
  LABELS,
  LATEST_PATIENT_ACTIVITY,
  MY_TASKS,
  PATIENT_CARESPACE,
  PATIENT_DIRECTORY,
  PATIENT_PLACEHOLDER,
  RECENT_COMMANDS,
  SEARCH_FOR_GROUPS,
  SEARCH_FOR_PATIENT,
  SHOW_CALENDAR_VIEW,
  SHOW_INBOX_VIEW,
  SHOW_LIST_VIEW,
  SWITCH_WORKSPACE,
  TEMPLATES,
  WORKSPACE_PLACEHOLDER,
  ALL_PARTNERS,
  INVITE_PARTNER,
  MY_INBOX,
  MY_INBOX_TASKS,
  MY_INBOX_GROUPS,
  MY_INBOX_DM,
  MY_INBOX_PATIENTS,
  MY_INBOX_PARTNERS,
  SHARED_OFFICE_INBOX,
  SHARED_OFFICE_INBOX_PATIENTS,
  SHARED_OFFICE_INBOX_PARTNERS,
} from 'app/components/DnD/CmdMenu/constants';

import { openPartnerSpaceInviteModal } from 'app/actions/partnerSpaces';

import useWorkspaceOperation from 'app/hooks/workspaces/useWorkspaceOperation';
import { openPatientModal } from 'app/actions/patientModal';
import { openTaskModal } from 'app/actions/taskModal';
import useOperation from 'app/components/DnD/CmdMenu/useOperation';
import GroupCmdMenu from 'app/components/DnD/CmdMenu/GroupCmdMenu';
import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import DisplayHeading from 'app/components/DnD/CmdMenu/DisplayHeading';
import PatientsCmdMenu from 'app/components/DnD/CmdMenu/PatientsCmdMenu';
import WorkspaceCmdMenu from 'app/components/DnD/CmdMenu/WorkspaceCmdMenu';
import { disableCommandMenu, enableCommandMenu } from 'app/actions/commandMenu';
import ConversationCmdMenu from 'app/components/DnD/CmdMenu/ConversationCmdMenu';
import { entityConversationUrl } from 'app/utils/entitiesHelper';
import RecentCmdMenu from 'app/components/DnD/CmdMenu/RecentCmdMenu';
import { uniqBy } from 'app/utils/osLodash';
import { useLocalStorage } from 'app/hooks/useLocalStorage';
import useClinicSubscribedAccess from 'app/hooks/useClinicSubscribedAccess';

const DEFAULT_PLACEHOLDER = 'Search for commands...';

const CommandMenu = () => {
  const [open, setOpen] = useState(false);
  const commandMenu = useSelector((state) => state.commandMenu);
  const [inputValue, setInputValue] = useState('');
  const [filterValue, setFilterValue] = useState('default');
  const [recentCommands, setRecentCommands] = useLocalStorage(
    RECENT_COMMANDS,
    [],
    { updateDataAcrossTab: true },
  );
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaces } = useWorkspaceOperation();
  const inputRef = useRef();
  const { handleAddTaskUpdateCache, isActionCommand } = useOperation();
  const { openSubscriptionModalIfNotSubscribed } = useClinicSubscribedAccess();

  const handleCommandMenu = () => {
    setOpen(!open);
  };

  const handleInputValue = (value) => {
    setInputValue(value);
  };

  const handleRecentSelected = (command) => {
    let commandObject = {
      command: command,
      action: isActionCommand(command),
    };
    let updatedCommands = [];
    if (recentCommands.length >= 3) {
      updatedCommands = uniqBy(
        [commandObject, ...recentCommands.slice(0, 2)],
        'command',
      );
    } else {
      updatedCommands = uniqBy([commandObject, ...recentCommands], 'command');
    }
    setRecentCommands(updatedCommands);
  };

  const handleNavigation = (selectOption, options = {}) => {
    const workspaceIdentifier = getWorkspaceIdentifier();
    switch (selectOption) {
      case LATEST_PATIENT_ACTIVITY.toLowerCase():
        handleRecentSelected(LATEST_PATIENT_ACTIVITY);
        navigate(`/${workspaceIdentifier}/patient/activity`);
        handleCommandMenu();
        break;
      case PATIENT_DIRECTORY.toLowerCase():
        handleRecentSelected(PATIENT_DIRECTORY);
        navigate(`/${workspaceIdentifier}/patient/directory/all`);
        handleCommandMenu();
        break;
      case ADD_NEW_TASK.toLowerCase():
        handleRecentSelected(ADD_NEW_TASK);
        dispatch(
          openTaskModal(null, { updateCache: handleAddTaskUpdateCache }),
        );
        handleCommandMenu();
        break;
      case MY_TASKS.toLowerCase():
        handleRecentSelected(MY_TASKS);
        navigate(`/${workspaceIdentifier}/tasks/list/me/today`);
        handleCommandMenu();
        break;
      case ALL_PARTNERS.toLowerCase():
        handleRecentSelected(ALL_PARTNERS);
        navigate(`/${workspaceIdentifier}/partners`);
        handleCommandMenu();
        break;
      case INVITE_PARTNER.toLowerCase():
        handleRecentSelected(INVITE_PARTNER);
        dispatch(openPartnerSpaceInviteModal());
        handleCommandMenu();
        break;
      case ALL_TASKS.toLowerCase():
        handleRecentSelected(ALL_TASKS);
        navigate(`/${workspaceIdentifier}/tasks/list/all/today`);
        handleCommandMenu();
        break;
      case SHARED_OFFICE_INBOX_PATIENTS.toLowerCase():
        handleRecentSelected(SHARED_OFFICE_INBOX_PATIENTS);
        navigate(`/${workspaceIdentifier}/inbox/team/patients`);
        handleCommandMenu();
        break;
      case SHARED_OFFICE_INBOX_PARTNERS.toLowerCase():
        handleRecentSelected(SHARED_OFFICE_INBOX_PARTNERS);
        navigate(`/${workspaceIdentifier}/inbox/team/partners`);
        handleCommandMenu();
        break;
      case MY_INBOX_PARTNERS.toLowerCase():
        handleRecentSelected(MY_INBOX_PARTNERS);
        navigate(`/${workspaceIdentifier}/inbox/my/partners`);
        handleCommandMenu();
        break;
      case MY_INBOX_PATIENTS.toLowerCase():
        handleRecentSelected(MY_INBOX_PATIENTS);
        navigate(`/${workspaceIdentifier}/inbox/my/patients`);
        handleCommandMenu();
        break;
      case MY_INBOX_DM.toLowerCase():
        handleRecentSelected(MY_INBOX_DM);
        navigate(`/${workspaceIdentifier}/inbox/my/conversations`);
        handleCommandMenu();
        break;
      case MY_INBOX_GROUPS.toLowerCase():
        handleRecentSelected(MY_INBOX_GROUPS);
        navigate(`/${workspaceIdentifier}/inbox/my/groups`);
        handleCommandMenu();
        break;
      case MY_INBOX_TASKS.toLowerCase():
        handleRecentSelected(MY_INBOX_TASKS);
        navigate(`/${workspaceIdentifier}/inbox/my/tasks`);
        handleCommandMenu();
        break;
      case SHOW_CALENDAR_VIEW.toLowerCase():
        handleRecentSelected(SHOW_CALENDAR_VIEW);
        navigate(`/${workspaceIdentifier}/tasks/board/me/today`);
        handleCommandMenu();
        break;
      case SHOW_LIST_VIEW.toLowerCase():
        handleRecentSelected(SHOW_LIST_VIEW);
        navigate(`/${workspaceIdentifier}/tasks/list/me/today`);
        handleCommandMenu();
        break;
      case SHOW_INBOX_VIEW.toLowerCase():
        handleRecentSelected(SHOW_INBOX_VIEW);
        navigate(`/${workspaceIdentifier}/tasks/list/inbox`);
        handleCommandMenu();
        break;
      case ADD_NEW_PATIENT.toLowerCase():
        handleRecentSelected(ADD_NEW_PATIENT);
        dispatch(openPatientModal(null, { source: 'visitors-listing' }));
        handleCommandMenu();
        break;
      case GROUPS.toLowerCase():
        handleRecentSelected(GROUPS);
        navigate(`/${workspaceIdentifier}/group`);
        handleCommandMenu();
        break;
      case LABELS.toLowerCase():
        handleRecentSelected(LABELS);
        navigate(`/${workspaceIdentifier}/settings/labels`);
        handleCommandMenu();
        break;
      case CLINIC_INFORMATION.toLowerCase():
        handleRecentSelected(CLINIC_INFORMATION);
        navigate(`/${workspaceIdentifier}/settings/basic-info`);
        handleCommandMenu();
        break;
      case ALL_TEAM_MEMBERS.toLowerCase():
        handleRecentSelected(ALL_TEAM_MEMBERS);
        navigate(`/${workspaceIdentifier}/settings/members`);
        handleCommandMenu();
        break;
      case SWITCH_WORKSPACE.toLowerCase():
        handleRecentSelected(SWITCH_WORKSPACE);
        setFilterValue(FILTER_VALUES.WORKSPACES);
        setInputValue('');
        setPlaceholder(WORKSPACE_PLACEHOLDER);
        inputRef.current?.focus();
        break;
      case TEMPLATES.toLowerCase():
        handleRecentSelected(TEMPLATES);
        navigate(`/${workspaceIdentifier}/settings/templates`);
        handleCommandMenu();
        break;
      case PATIENT_CARESPACE:
        navigate(`/${workspaceIdentifier}/spaces/${options.niceId}/activity`);
        handleCommandMenu();
        break;
      case GROUPS_NAVIGATION:
        navigate(`/${workspaceIdentifier}/team/group/${options.niceId}`);
        handleCommandMenu();
        break;
      case `${SEARCH_FOR_PATIENT.toLowerCase()}...`:
        handleRecentSelected(SEARCH_FOR_PATIENT + '...');
        setInputValue('');
        setFilterValue(FILTER_VALUES.PATIENTS);
        setPlaceholder(PATIENT_PLACEHOLDER);
        inputRef.current?.focus();
        break;
      case `${DIRECT_CONVERSATION.toLowerCase()}`:
        handleRecentSelected(DIRECT_CONVERSATION);
        setInputValue('');
        setFilterValue(FILTER_VALUES.CONVERSATION);
        setPlaceholder(DIRECT_CONVERSATION_PLACEHOLDER);
        inputRef.current?.focus();
        break;
      case DIRECT_CONVERSATION_NAVIGATION:
        let url = entityConversationUrl({
          nice_id: options.conversationId,
          __typename: options.typename,
        });
        navigate(url);
        handleCommandMenu();
        break;
      case `${SEARCH_FOR_GROUPS.toLowerCase()}...`:
        handleRecentSelected(SEARCH_FOR_GROUPS + '...');
        setInputValue('');
        setFilterValue(FILTER_VALUES.GROUPS);
        setPlaceholder(GROUPS_PLACEHOLDER);
        inputRef.current?.focus();
        break;
      case `${CREATE_NEW_GROUP.toLowerCase()}...`:
        handleRecentSelected(CREATE_NEW_GROUP + '...');
        dispatch(
          openCreateTeamSpaceModal({
            canUpdateGroup: true,
          }),
        );
        handleCommandMenu();
        break;
      default:
        return null;
    }
  };

  // This useEffect hook runs whenever the 'open' state changes
  useEffect(() => {
    // If the menu is closed, reset the filter, input, and textQuery
    if (!open) {
      setFilterValue(FILTER_VALUES.DEFAULT);
      setInputValue('');
      dispatch(disableCommandMenu());
    } else {
      dispatch(enableCommandMenu());
      setPlaceholder(DEFAULT_PLACEHOLDER);
    }
  }, [open]);

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    // Add event listener for ctrl + enter or cmd + enter to submit the form
    const openLauncher = () => {
      setOpen((prevState) => !prevState);
    };
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 75) {
        event.stopImmediatePropagation();
        event.preventDefault();
        openSubscriptionModalIfNotSubscribed(openLauncher);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const removeMenuFromScreen = (event) => {
      if (event.keyCode === 27 && open) {
        event.stopImmediatePropagation();
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('keydown', removeMenuFromScreen);
    }

    return () => {
      if (open) {
        document.removeEventListener('keydown', removeMenuFromScreen);
      }
    };
  }, [open]);

  const shouldFilterResult = () => {
    return !(
      filterValue === FILTER_VALUES.PATIENTS ||
      filterValue === FILTER_VALUES.GROUPS
    );
  };

  useEffect(() => {
    if (commandMenu.isCmdOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [commandMenu]);

  return (
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      defaultValue={''}
      shouldFilter={shouldFilterResult()}
      label='Global Command Menu'>
      <Command.Input
        ref={inputRef}
        placeholder={placeholder}
        value={inputValue}
        onValueChange={handleInputValue}
      />
      <Command.Separator
        className={'input-option-separator'}
        alwaysRender={true}
      />
      {filterValue === FILTER_VALUES.DEFAULT && (
        <>
          <RecentCmdMenu
            recentCommands={recentCommands}
            handleNavigation={handleNavigation}
            inputValue={inputValue}
          />
          <Command.List>
            <Command.Empty>No results found.</Command.Empty>
            <Command.Group
              heading={
                <DisplayHeading heading={MY_INBOX} iconName={'case-person'} />
              }>
              <Command.Item value={MY_INBOX_TASKS} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={MY_INBOX_TASKS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={MY_INBOX_PATIENTS}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={MY_INBOX_PATIENTS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item value={MY_INBOX_GROUPS} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={MY_INBOX_GROUPS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item value={MY_INBOX_DM} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={MY_INBOX_DM}
                  action={'shortcut'}
                />
              </Command.Item>
              {isFeaturePermitted('workspace_partners_enabled') && (
                <Command.Item
                  value={MY_INBOX_PARTNERS}
                  onSelect={handleNavigation}>
                  <DisplayOption
                    iconRequired={false}
                    label={MY_INBOX_PARTNERS}
                    action={'shortcut'}
                  />
                </Command.Item>
              )}
            </Command.Group>
            <Command.Group
              heading={
                <DisplayHeading
                  heading={SHARED_OFFICE_INBOX}
                  iconName={'clock'}
                />
              }>
              <Command.Item
                value={SHARED_OFFICE_INBOX_PATIENTS}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={SHARED_OFFICE_INBOX_PATIENTS}
                  action={'shortcut'}
                />
              </Command.Item>
              {isFeaturePermitted('workspace_partners_enabled') && (
                <Command.Item
                  value={SHARED_OFFICE_INBOX_PARTNERS}
                  onSelect={handleNavigation}>
                  <DisplayOption
                    iconRequired={false}
                    label={SHARED_OFFICE_INBOX_PARTNERS}
                    action={'shortcut'}
                  />
                </Command.Item>
              )}
            </Command.Group>
            <Command.Group
              heading={
                <DisplayHeading
                  heading={'patients'}
                  iconName={'case-person-feed'}
                />
              }>
              <Command.Item value={ADD_NEW_PATIENT} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={ADD_NEW_PATIENT}
                  action={'shortcut'}
                />
              </Command.Item>

              <Command.Item
                value={LATEST_PATIENT_ACTIVITY}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  iconName={'clinicSpace'}
                  label={LATEST_PATIENT_ACTIVITY}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={PATIENT_DIRECTORY}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={PATIENT_DIRECTORY}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={`${SEARCH_FOR_PATIENT}...`}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={`${SEARCH_FOR_PATIENT}...`}
                  action={'command'}
                />
              </Command.Item>
            </Command.Group>

            <Command.Group
              heading={<DisplayHeading heading={'tasks'} iconName={'task'} />}>
              <Command.Item value={ADD_NEW_TASK} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={ADD_NEW_TASK}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item value={ALL_TASKS} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={ALL_TASKS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item value={MY_TASKS} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  iconName={'clinicSpace'}
                  label={MY_TASKS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={SHOW_CALENDAR_VIEW}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={SHOW_CALENDAR_VIEW}
                  action={'command'}
                />
              </Command.Item>
              <Command.Item value={SHOW_INBOX_VIEW} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={SHOW_INBOX_VIEW}
                  action={'command'}
                />
              </Command.Item>
              <Command.Item value={SHOW_LIST_VIEW} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={SHOW_LIST_VIEW}
                  action={'command'}
                />
              </Command.Item>
            </Command.Group>

            <Command.Group
              heading={<DisplayHeading heading={'team'} iconName={'task'} />}>
              <Command.Item
                value={`${CREATE_NEW_GROUP}...`}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={`${CREATE_NEW_GROUP}...`}
                  action={'command'}
                />
              </Command.Item>
              <Command.Item
                value={DIRECT_CONVERSATION}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={DIRECT_CONVERSATION}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={`${SEARCH_FOR_GROUPS}...`}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={`${SEARCH_FOR_GROUPS}...`}
                  action={'shortcut'}
                />
              </Command.Item>
              {/* Hiding it because the route is not available. */}
              {/*<Command.Item value={GROUPS} onSelect={handleNavigation}>*/}
              {/*  <DisplayOption*/}
              {/*    iconRequired={false}*/}
              {/*    iconName={'clinicSpace'}*/}
              {/*    label={GROUPS}*/}
              {/*    action={'shortcut'}*/}
              {/*  />*/}
              {/*</Command.Item>*/}
            </Command.Group>

            <Command.Group
              heading={
                <DisplayHeading
                  heading={'workspace settings'}
                  iconName={'setting'}
                />
              }>
              <Command.Item
                value={ALL_TEAM_MEMBERS}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={ALL_TEAM_MEMBERS}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item
                value={CLINIC_INFORMATION}
                onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  iconName={'clinicSpace'}
                  label={CLINIC_INFORMATION}
                  action={'shortcut'}
                />
              </Command.Item>
              <Command.Item value={LABELS} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={LABELS}
                  action={'shortcut'}
                />
              </Command.Item>
              {workspaces?.length > 0 && (
                <Command.Item
                  value={SWITCH_WORKSPACE}
                  onSelect={handleNavigation}>
                  <DisplayOption
                    iconRequired={false}
                    label={SWITCH_WORKSPACE}
                    action={'shortcut'}
                  />
                </Command.Item>
              )}
              <Command.Item value={TEMPLATES} onSelect={handleNavigation}>
                <DisplayOption
                  iconRequired={false}
                  label={TEMPLATES}
                  action={'shortcut'}
                />
              </Command.Item>
            </Command.Group>
            {isFeaturePermitted('workspace_partners_enabled') && (
              <Command.Group
                heading={
                  <DisplayHeading
                    heading={'Partner'}
                    iconName={'surgeryAppointment'}
                  />
                }>
                <Command.Item
                  value={INVITE_PARTNER}
                  onSelect={handleNavigation}>
                  <DisplayOption
                    iconRequired={false}
                    label={INVITE_PARTNER}
                    action={'shortcut'}
                  />
                </Command.Item>
                <Command.Item value={ALL_PARTNERS} onSelect={handleNavigation}>
                  <DisplayOption
                    iconRequired={false}
                    label={ALL_PARTNERS}
                    action={'shortcut'}
                  />
                </Command.Item>
              </Command.Group>
            )}
          </Command.List>
        </>
      )}

      {filterValue === FILTER_VALUES.PATIENTS && (
        <PatientsCmdMenu
          handleNavigation={handleNavigation}
          inputValue={inputValue}
        />
      )}

      {filterValue === FILTER_VALUES.GROUPS && (
        <GroupCmdMenu
          handleNavigation={handleNavigation}
          inputValue={inputValue}
        />
      )}

      {filterValue === FILTER_VALUES.WORKSPACES && (
        <WorkspaceCmdMenu
          handleNavigation={handleNavigation}
          workspaces={workspaces}
          handleCommandMenu={handleCommandMenu}
        />
      )}

      {filterValue === FILTER_VALUES.CONVERSATION && (
        <ConversationCmdMenu
          handleNavigation={handleNavigation}
          inputValue={inputValue}
        />
      )}
    </Command.Dialog>
  );
};

export default CommandMenu;
