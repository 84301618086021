import { useEffect, useMemo, useState } from 'react';
import Loader from 'react-loaders';

import OsBtn from 'app/components/OsBtn';
import WorkspaceCardLogo from 'app/components/WorkspaceView/CardLogo';

import useWorkspaceOperation from 'app/hooks/workspaces/useWorkspaceOperation';

const SelectClinicView = ({
  heading,
  subHeading,
  onConfirmSelection = () => {},
  blacklistedIds = [],
  smartSelection = false,
}) => {
  const { workspaces, loading } = useWorkspaceOperation({
    fullSummaryRequired: true,
  });

  const [showSelection, setShowSelection] = useState(false);
  const filteredWorkspaces = useMemo(() => {
    return workspaces.filter((w) => !blacklistedIds.includes(w.id));
  }, [workspaces, blacklistedIds]);

  useEffect(() => {
    if (smartSelection) {
      if (filteredWorkspaces.length === 1) {
        onConfirmSelection(filteredWorkspaces[0]);
      } else {
        setShowSelection(true);
      }
    } else {
      setShowSelection(true);
    }
  }, [loading, filteredWorkspaces, smartSelection, onConfirmSelection]);

  const confirmSelection = ({ id, identifier, name }) => {
    onConfirmSelection({ id, identifier, name });
  };

  const renderWorkspace = (workspace) => {
    return (
      <div
        key={workspace.id}
        className='col-12 col-xl-3 col-lg-4 col-md-6 mb-4 ws-hero-box'>
        <div className='ws-enter-box'>
          <aside className='ws_logo_notificaiton'>
            <div
              className={`ws-logo ${
                workspace.owner.owner.logo_big ? 'with-img' : 'with-bg'
              }`}>
              <WorkspaceCardLogo workspace={workspace} />
            </div>
          </aside>

          <article className='ws-box-detail'>
            <div className='ws-small-title'>Clinic</div>
            <div className='ws-title'>{workspace.name}</div>
          </article>
          <OsBtn
            name='BtnSecondary'
            extraClass='with-border web-view-btn ws-enter-link'
            text='Select'
            onClick={() => confirmSelection(workspace)}
          />
        </div>
      </div>
    );
  };

  if (!showSelection || loading)
    return <Loader type='ball-triangle-path' active />;

  return (
    <section className='container'>
      <div className='ws-info'>
        <h1>{heading}</h1>
        <div>{subHeading}</div>
      </div>
      <article>
        <div className='without-ws-heading'>
          <div className='row justify-content-center'>
            {filteredWorkspaces.map(renderWorkspace)}
          </div>
        </div>
      </article>
    </section>
  );
};

export default SelectClinicView;
