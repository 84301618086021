import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import ActionCable from 'app/actioncable/actioncable';
import NotificationCard from './NotificationCard';

import { isUrlOfSameDomain, replaceCurrentDomain } from 'app/utils/urlHelper';

class UnreadNotifications extends Component {
  readNotification(id) {
    ActionCable.notifications.read(id);
  }

  onClick = (event, notification) => {
    let link = event.target.href
      ? event.target.pathname + event.target.search + event.target.hash
      : notification.link;

    event.preventDefault();
    event.stopPropagation();
    notification.unread && this.readNotification(notification.id);
    if (isUrlOfSameDomain(notification.link)) {
      this.props.navigate(replaceCurrentDomain(link));
    } else {
      window.location.href = notification.link;
    }
  };

  render() {
    let unreadNotifications = this.props.results;
    return (
      <div>
        {unreadNotifications.map((n) => (
          <NotificationCard
            key={n.id}
            {...n}
            onClick={this.onClick}
            size='small-noti-dropdown'
            readNotification={this.readNotification}
          />
        ))}
      </div>
    );
  }
}

UnreadNotifications = withRouter(UnreadNotifications);

export default UnreadNotifications;
