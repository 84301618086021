import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OsField from 'app/components/OsField';

const FILTER_OPTIONS = [
  { key: 'documents', text: 'Documents (pdf, docx)' },
  { key: 'images', text: 'Images (jpg, png)' },
  { key: 'videos', text: 'Video recordings' },
  { key: 'links', text: 'Links' },
  { key: 'cases', text: 'Cases' },
  { key: 'clear', text: 'Clear all filters' },
];

const FILTER_SOURCE_MAPPINGS = {
  my_files: ['documents', 'images', 'videos'],
};

class SpaceFilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: props.filterValues,
    };
  }

  componentDidUpdate(nextProps) {
    if (this.props.disabled !== nextProps.disabled) {
      this.resetFilter();
    }
  }

  resetFilter = () => {
    this.setState({ selectedValues: [] });
  };

  isChecked(key) {
    return this.state.selectedValues.includes(key);
  }

  onChange = (key, event) => {
    let { selectedValues } = this.state;
    if (key === 'clear') {
      selectedValues = [];
    } else {
      event.target.checked
        ? selectedValues.push(key)
        : (selectedValues = selectedValues.filter((k) => k !== key));
    }
    this.setState({ selectedValues });
    this.props.onChange(selectedValues);
  };

  getOptions() {
    const { filterSource } = this.props;
    if (FILTER_SOURCE_MAPPINGS[filterSource]) {
      return FILTER_OPTIONS.filter((obj) =>
        FILTER_SOURCE_MAPPINGS[filterSource].includes(obj.key),
      );
    } else {
      return FILTER_OPTIONS;
    }
  }

  isFilterActive() {
    return this.state.selectedValues.length ? 'active' : '';
  }

  renderOption = (data) => {
    let isChecked = this.isChecked(data.key),
      disable =
        !this.state.selectedValues.length && data.key === 'clear'
          ? true
          : false;
    return (
      <OsField
        osType='checkbox'
        isChecked={isChecked}
        onChange={this.onChange.bind(this, data.key)}
        className='os-custom-checkbox'
        label={data.text}
        id={`${this.props.source}-${data.key}`}
        selected={isChecked}
        disabled={disable}
      />
    );
  };

  render() {
    let titleText = 'Filter Results',
      { disabled } = this.props;
    return (
      <>
        <CustomDropdown
          chevronIcon='chevron-caret'
          avoidEventManipulation={true}
          name='space_links_filter'
          dropdownInfo={{ title: titleText, icon: 'filter' }}
          className={`cs-dropdown  cs-file-dropdown filter-dropdown with-list-sorting ${
            disabled ? 'disabled' : ''
          }`}
          filterActive={this.isFilterActive()}
          closeDropDown={this.props.closeDropDown}>
          {this.getOptions().map(this.renderOption)}
        </CustomDropdown>
      </>
    );
  }
}

SpaceFilterDropdown.defaultProps = {
  filterValues: [],
  disabled: false,
  source: 'folder-detail',
};
SpaceFilterDropdown = connect(
  ({ device }) => ({ device }),
  {},
)(SpaceFilterDropdown);

export default SpaceFilterDropdown;
