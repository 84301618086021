import React, { useContext, useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  getLabelNameWithParent,
  getParentLabel,
  getSiblingLabels,
} from 'app/utils/labelHelper';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import {
  changeSameParentLabel,
  getLabelListName,
  getTaskPayloadForLabelUpdateRequest,
  removeChildLabel,
} from 'app/utils/taskHelper';
import { GroupLabelViewContext, TaskContext } from 'app/context/TaskContext';

const UPDATE_TASK = queryLoader('app/graphql/mutations/Task/TaskUpdate.gql');

function RenderLabel(props) {
  const { task, label, allLabels, details = {} } = props;
  const { updateViewAfterChangingLabel } = useContext(GroupLabelViewContext);
  const {
    labelGroup,
    removeTaskFromGroupLabelRecords,
    updateTaskInGroupLabelRecords,
  } = useContext(TaskContext);
  const [isDropdownRequired, setIsDropdownRequired] = useState(false);
  const [siblingLabels, setSiblingLabels] = useState([]);
  const [updateTaskMutation] = useMutation(UPDATE_TASK);
  const [currentLabel, setCurrentLabel] = useState(label);

  const updateLabel = async (selectedLabel) => {
    if (task.entity_labels.find((l) => l.label.id === selectedLabel.id)) return;
    let labels, payload;
    if (selectedLabel.name === 'Remove Label') {
      let parentLabel = getParentLabel(selectedLabel, allLabels);
      labels = removeChildLabel(parentLabel, task).entity_labels.map((l) =>
        parseInt(l.label.id),
      );
      payload = getTaskPayloadForLabelUpdateRequest({
        task,
        labels,
      });
    } else {
      let parentLabel = getParentLabel(selectedLabel, allLabels);
      labels = changeSameParentLabel(
        task.entity_labels.map((l) => l.label),
        parentLabel,
        selectedLabel,
      );
      payload = getTaskPayloadForLabelUpdateRequest({
        task,
        labels,
      });
    }

    const response = await updateTaskMutation({
      variables: payload,
    });

    if (response.data.updateTask.success) {
      if (selectedLabel.name === 'Remove Label' && labelGroup) {
        let isTaskEdit = response.data.updateTask.entity.entity_labels.find(
          (l) => getParentLabel(l.label, allLabels).id === labelGroup.id,
        );
        if (isTaskEdit) {
          updateTaskInGroupLabelRecords(response.data.updateTask.entity);
        } else {
          let listName = getLabelListName(task, labelGroup, allLabels);
          removeTaskFromGroupLabelRecords(
            response.data.updateTask.entity,
            listName,
          );
        }
      } else {
        let parentLabel = getParentLabel(label, allLabels);
        if (updateViewAfterChangingLabel && parentLabel?.id === labelGroup.id)
          updateViewAfterChangingLabel(
            response.data.updateTask.entity,
            label.id,
            selectedLabel.id,
          );
        setCurrentLabel(selectedLabel);
      }
    }
  };

  useEffect(() => {
    if (allLabels) {
      let siblings = getSiblingLabels(label, allLabels);
      if (siblings?.length > 0) {
        setIsDropdownRequired(true);
        let parentLabel = getParentLabel(label, allLabels);
        setSiblingLabels([
          ...siblings.map((l) => ({ ...l, color: label.color })),
          {
            id: parentLabel.id,
            name: 'Remove Label',
            color: '000000',
            __typename: 'Label',
          },
        ]);
      }
    }
  }, [allLabels]);

  return (
    <>
      {!isDropdownRequired ? (
        getLabelNameWithParent(label, allLabels)
      ) : (
        <BootstrapDropdown
          async={false}
          title={getLabelNameWithParent(currentLabel, allLabels)}
          inputFieldRequired={false}
          onClick={(e, label) => {
            updateLabel({
              ...label,
              type: 'task',
              sub_labels: [],
              color: label.color,
            });
          }}
          items={siblingLabels}
          extraClassName={'label-dropdown'}
        />
      )}
    </>
  );
}

export default RenderLabel;
