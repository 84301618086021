import {
  EXTRACT_MENTIONEE_REGEX,
  EXTRACT_HASHTAG_MENTION_REGEX,
  USER_NICE_IDENTIFIER,
} from 'app/constants';

import { groupBy, keys, map, uniq } from 'app/utils/osLodash';

const MENTION_GROUP_REGEX =
  /@\[User:([a-z|_]+\d*(:[a-z|_]+\d*)?)\](,\[{[^}\]]+}\])?/gi;

export function removeMentioneeName(content) {
  let mentionees = content.match(EXTRACT_MENTIONEE_REGEX);

  if (mentionees) {
    mentionees.forEach((mentionee) => {
      let mentioneeInfo = mentionee.split(',');
      content = content.replace(mentionee, mentioneeInfo[0]);
    });
  }
  return content;
}

export function removeHashMarkup(content) {
  let hashMentions = content.match(EXTRACT_HASHTAG_MENTION_REGEX);
  if (hashMentions) {
    hashMentions.forEach((hash) => {
      let subStr = hash.substring(2, hash.length - 1);
      content = content.replace(hash, `#${subStr}`);
    });
  }
  return content;
}

export function getMentionGroupsAndIdentifierFromContent(
  content,
  mentionGroups = [],
) {
  let mapper = {},
    mentionGroupHash = groupBy(mentionGroups, 'identifier');
  const groups = content.match(MENTION_GROUP_REGEX) || [];
  groups.forEach((group) => {
    const matchedIdentifiers = group.match(/@\[User:(.+)\]/);
    if (matchedIdentifiers) {
      let displayTexts =
        matchedIdentifiers[matchedIdentifiers.length - 1].split(':');
      mapper[group] = mentionGroupHash[displayTexts[0]]
        ? mentionGroupHash[displayTexts[0]][0].display_name
        : displayTexts[displayTexts.length - 1];
    }
  });
  return mapper;
}

export function parseContentWithTag(obj) {
  let mentioneeTagRegex,
    { mention_groups, content, mentionees } = obj,
    mapper = getMentionGroupsAndIdentifierFromContent(content, mention_groups);
  keys(mapper).forEach((matchedGroup) => {
    content = content.replaceAll(
      matchedGroup,
      `${matchedGroup},[{${mapper[matchedGroup]}}]`,
    );
  });

  if (mentionees && mentionees.length) {
    map(mentionees, (mentionee) => {
      mentioneeTagRegex = generateMentioneeRegex(mentionee.id);
      content = content.replace(
        mentioneeTagRegex,
        getMentionInRequiredFormat(mentionee),
      );
    });
  }

  return content;
}

function getMentionInRequiredFormat(mentionee) {
  return `@[User:${mentionee.id}],[{${mentionee.name}}]`;
}

export function getFormattedMentionTextFromContent(
  { mentionees },
  selectedValue,
) {
  selectedValue = replaceFormattedMentionTextFromContent(
    mentionees,
    selectedValue,
  );
  selectedValue = replaceFormattedMentionTextFromContent(
    mentionees,
    selectedValue,
    'g',
  );
  return selectedValue;
}

function replaceFormattedMentionTextFromContent(
  mentionees,
  content,
  regExpFilter,
) {
  mentionees.forEach((mentionee) => {
    content = content.replace(
      new RegExp(`@${mentionee.name}`, regExpFilter),
      getMentionInRequiredFormat(mentionee),
    );
  });
  return content;
}

export function humanizeContentWithMentionees(mentionees, content) {
  let mentioneeTagRegex;

  if (mentionees && mentionees.length) {
    map(mentionees, (mentionee) => {
      mentioneeTagRegex = generateMentioneeRegex(mentionee.id);
      content = content.replace(mentioneeTagRegex, `@${mentionee.name}`);
    });
  }
  return content;
}

export function removeMentioneeIDData(content) {
  let mentionees = content.match(EXTRACT_MENTIONEE_REGEX);

  if (mentionees) {
    mentionees.forEach((mentionee) => {
      let mentioneeInfo = mentionee.split(','),
        mentioneeName = mentioneeInfo[1].substring(
          2,
          mentioneeInfo[1].length - 2,
        );
      content = content.replace(mentionee, `@${mentioneeName}`);
    });
  }
  return content;
}

export function parseMentionees(content, suggestions) {
  let mentioneesData = content.match(USER_NICE_IDENTIFIER),
    mentioneesIds = [];
  uniq(mentioneesData).forEach((mentioneeData) =>
    mentioneesIds.push(mentioneeData.split(':')[1].slice(0, -1)),
  );

  let mentionees = suggestions.filter((otherParticipant) =>
    mentioneesIds.includes(otherParticipant.id.toString()),
  );
  return mentionees;
}

export function generateMentioneeRegex(mentioneeId) {
  return new RegExp(`@\\[User:${mentioneeId}\\]`, 'g');
}
