import React, { useEffect } from 'react';

export const useLocalStorage = (keyName, defaultValue, options = {}) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const updateLocalStateValue = () => {
    const value = window.localStorage.getItem(keyName);
    try {
      if (value) {
        setStoredValue(JSON.parse(value));
      }
    } catch (err) {
      setStoredValue(defaultValue);
    }
  };

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  useEffect(() => {
    let listenerCallback = (event) => {
      if (event.key === keyName) {
        updateLocalStateValue();
      }
    };
    if (options.updateDataAcrossTab) {
      window.addEventListener('storage', listenerCallback);
    }
    return () => {
      window.removeEventListener('storage', listenerCallback);
    };
  }, []);

  return [storedValue, setValue];
};
