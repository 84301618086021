import React from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';

const NoChatMessage = () => {
    return <div className="nomessage-section">
      <OrthoIcon name="mailbox-empty" dataHoverNotRequired={true} defaultClass='os-orange' />
      <p className="quite-text">It's quiet in here..</p>
      <p className="text-center">You haven't started or received any messages yet.<br/>
      Click on New Message to start a conversation.</p>
    </div>
}

export default NoChatMessage;
