import {
  SET_NAV_SEARCH_QUERY,
  SET_NAV_SEARCH_CATEGORY,
  SET_NAV_SEARCH_TOTAL,
  SET_NAV_SEARCH_OPEN,
  SET_NAV_SEARCH_CLOSE,
} from './types';
import { SEARCH_RESULTS_ORDER } from 'app/constants.js';
import { keys } from 'app/utils/osLodash';

export function setNavSearchQuery(query) {
  return {
    type: SET_NAV_SEARCH_QUERY,
    payload: query,
  };
}

export function setNavSearchCategory(category) {
  if (!keys(SEARCH_RESULTS_ORDER).includes(category)) category = 'cases';
  return {
    type: SET_NAV_SEARCH_CATEGORY,
    payload: category,
  };
}

export function setNavSearchTotal(total) {
  return {
    type: SET_NAV_SEARCH_TOTAL,
    payload: total,
  };
}

export function setNavSearchOpen(value = true) {
  return {
    type: SET_NAV_SEARCH_OPEN,
    payload: value,
  };
}

export function setNavSearchClose(value = false) {
  return {
    type: SET_NAV_SEARCH_CLOSE,
    payload: value,
  };
}
