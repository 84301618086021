import React from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import { translate } from 'app/actions/flashMessage';

import ClickPopupWrapper from 'app/components/Shared/ClickPopupWrapper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const MARK_COMMENT_PROCESSED_MUTATION = queryLoader(
  'app/graphql/mutations/Comments/MarkCommentProcessed.gql',
);
const MARK_COMMENT_UNPROCESSED_MUTATION = queryLoader(
  'app/graphql/mutations/Comments/MarkCommentUnprocessed.gql',
);

const CommentProcessIcon = (props) => {
  const variables = { commentId: props.comment.nice_id },
    isCareMember = props.workspace?.data?.is_care_member,
    [markCommentProcessed] = useMutation(MARK_COMMENT_PROCESSED_MUTATION, {
      variables,
    }),
    [markCommentUnprocessed] = useMutation(MARK_COMMENT_UNPROCESSED_MUTATION, {
      variables,
    }),
    iconName = props.comment.processed ? 'roundCheck' : 'roundCheckDefault',
    disabled = !isCareMember;

  const changeStatus = () => {
    if (isCareMember && !props.comment.parent_id && !disabled)
      props.comment.processed
        ? markCommentUnprocessed()
        : markCommentProcessed();
  };

  if (props.comment.parent_id) return null;

  return (
    <span className='pateint-access-tooltip cm-process-btn'>
      <ClickPopupWrapper onClick={changeStatus} disabled={!isCareMember}>
        <OrthoIcon name={iconName} defaultClass='cm-process-icon' />
      </ClickPopupWrapper>
      {disabled && (
        <div className='tab-link-tooltip'>
          <OrthoIcon name='sort-down' defaultClass='tooltip-arrow-top' />
          <div className='tooltip-container'>
            <OrthoIcon name='info' dataHoverNotRequired={true} />
            <span>{translate('ONLY_CARE_TEAM_MEMBER_MESSAGE')}</span>
          </div>
        </div>
      )}
    </span>
  );
};

const ConnectedCommentProcessIcon = connect(({ workspace }) => ({ workspace }))(
  CommentProcessIcon,
);

export default ConnectedCommentProcessIcon;
