import { SET_CURRENT_SPIN_INDEX } from 'app/actions/types';

export function setSpinCurrentIndex(currentIndex, previousVideoType) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_SPIN_INDEX,
      currentIndex: currentIndex,
      previousVideoType: previousVideoType,
    });
  };
}
