import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';
import Dropzone from 'react-dropzone';
import MentionableField from 'app/components/Shared/MentionableField';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import RefetchQueriesManager from 'app/services/RefetchQueriesManager';
import SelectInput from 'app/components/Shared/SelectInput';
import VideoRecordButton from 'app/components/Shared/VideoRecordButton';
import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';
import { openUpgradeModal } from 'app/actions/upgrade';
import { openPoliciesModal } from 'app/actions/policiesModal';
import { pluralize } from 'app/utils/generalHelper';
import { mapLocalFileWithAttachmentObject } from 'app/utils/entitiesHelper';
import { removeMentioneeName } from 'app/utils/textParseHelper';
import { scrollFieldToBottom } from 'app/utils/domHelper';
import { keyboardUIAdditionalBarSupported } from 'app/utils/deviceHelper';
import { isBlob } from 'app/utils/fileHelper';
import { filter, find, isEqual, map, reject } from 'app/utils/osLodash';
import AttachmentDropdown from 'app/components/Shared/AttachmentDropdown';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const MULTICHAT = false;

const START_CONVERSATION_MUTATION = queryLoader(
  'app/graphql/StartConversation.gql',
);
const DropdownIndicator = () => <i className='icon-plus-search' />;
const IconClose = () => <i className='icon-remove' />;
const IndicatorSeparator = () => <span />;

const NewConversationViewWrapper = (props) => {
  const params = new URLSearchParams(props.location.search);
  const [state, setState] = useState({
    content: '',
    assigningObject: false,
    objects: [],
    files: [],
    dropzoneActive: false,
    selectedParticipants: [],
    btnDisabled: false,
    inputActive: false,
    defaultSearchedValue: params.get('userId'),
  });

  const participantsListDiv = useRef(null);
  const inputRef = useRef(null);
  const dropzoneRef = useRef(null);

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      scrollTextAreaIntoView();
    });
    document.body.classList.add('new-chat');
    fetchSelectedParamsFromUrl(props);
    return () => {
      document.body.classList.remove('new-chat');
    };
  }, []);

  useEffect(() => {
    if (state.assigningObject && props.commentShare.status === 'open') {
      setState((prev) => ({ ...prev, assigningObject: false }));
    }

    if (
      state.assigningObject &&
      !isEqual(props.commentShare.cards, props.commentShare.cards)
    ) {
      let files = state.files,
        objects = state.objects;
      files = files.concat(props.commentShare.cards.files);
      objects = objects.concat(props.commentShare.cards.objects);

      setState((prev) => ({ ...prev, files, objects, assigningObject: false }));
      props.commentShareConsume();
    }

    if (props.location.search) fetchSelectedParamsFromUrl(props);
  }, [props, state]);

  useEffect(() => {
    inputRef && inputRef.current.focus();
  }, [state.defaultSearchedValue]);

  useEffect(() => {
    if (participantsListDiv.current.lastElementChild.offsetHeight > 148)
      participantsListDiv.current.lastElementChild.lastElementChild.firstElementChild.scrollTo(
        0,
        1000,
      );
  }, [state.selectedParticipants.length]);

  useEffect(() => {
    scrollFieldToBottom(inputRef.current);
  }, [state.content]);

  const scrollTextAreaIntoView = () => {
    if (document.activeElement === inputRef.current) {
      window.setTimeout(() => {
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        );
      }, 500);
    }
  };

  const fetchSelectedParamsFromUrl = (props) => {
    let params = new URLSearchParams(props.location.search);

    setState((prev) => ({
      ...prev,
      defaultSearchedValue: params.get('userId'),
    }));
  };

  const selectUserArguments = (user) => {
    let label = user.name;
    if (user.is_support_user) label += ' (Only available for single chat)';
    return label;
  };

  const changeSelection = (values) => {
    let selectedMembers = values;
    if (!MULTICHAT) {
      selectedMembers = selectedMembers.slice(-1);
    }

    setState((prev) => ({
      ...prev,
      selectedParticipants: selectedMembers || [],
    }));
  };

  const onFieldFocus = () => {
    keyboardUIAdditionalBarSupported() &&
      window.$('html')[0].classList.add('keyboard-ui-additional-bar-visible');
    setInputActive();
  };

  const onFieldBlur = () => {
    setTimeout(() => {
      window
        .$('html')[0]
        .classList.remove('keyboard-ui-additional-bar-visible');
    }, 1);
    unsetInputActive();
  };

  const changeContent = (content) => {
    setState((prev) => ({ ...prev, content }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.altKey) {
        if (props.device.browserName !== 'Safari')
          setState((prev) => ({ ...prev, content: `${state.content}\n` }));
      } else if (
        !isBtnDisabled() &&
        !props.device.touchSupport &&
        !event.shiftKey
      ) {
        handleSubmit(event);
      }
    }
  };

  const onDragEnter = () => {
    setState((prev) => ({
      ...prev,
      dropzoneActive: true,
    }));
  };

  const onDragLeave = () => {
    setState((prev) => ({
      ...prev,
      dropzoneActive: false,
    }));
  };

  const onDrop = (files) => {
    files.length && handleExternalEntityShare(files);
    setState((prev) => ({ ...prev, dropzoneActive: false }));
  };

  const onClickOverlay = (event) => {
    inputRef.current.focus();
    event.stopPropagation();
  };

  const toggleBtnDisabled = () => {
    setState((prev) => ({ ...prev, btnDisabled: !state.btnDisabled }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let selfChat =
      state.selectedParticipants.length === 1 &&
      state.selectedParticipants[0].value === props.currentUser.graph.id,
      otherParticipantsCount = filter(
        state.selectedParticipants,
        (participant) => participant.value !== props.currentUser.graph.id,
      ).length;

    if (otherParticipantsCount === 0 && !selfChat) {
      return;
    } else if (
      otherParticipantsCount > 1 &&
      !props.currentUser.ability.can('group_chat', 'conversation')
    ) {
      props.openUpgradeModal();
      return;
    }

    sendRequest();
  };

  const sendRequest = () => {
    toggleBtnDisabled();
    let content = removeMentioneeName(state.content);
    props
      .startConversationMutation({
        variables: {
          userIds: map(state.selectedParticipants, 'value'),
          content: content,
          links: map(state.objects, (ob) => ob.__typename + ':' + ob.id),
          files: map(state.files, 'id'),
        },
      })
      .then(({ data }) => {
        props.navigate(`/team/dm/${data.startConversation.id}`);
        postRequestOperations();
      });
  };

  const postRequestOperations = () => {
    toggleBtnDisabled();
    if (state.objects.length > 0 || state.files.length > 0)
      RefetchQueriesManager.refetch('currentUser');
  };

  const canShare = () => {
    return (
      props.currentUser.ability.can('external_share', 'conversation') ||
      props.currentUser.ability.can('internal_share', 'conversation')
    );
  };

  const openCommentShare = (type, files = []) => {
    if (canShare()) {
      setState((prev) => ({ ...prev, assigningObject: true }));
      props.commentShareOpen(type, 'Message', files, state.objects);
    } else {
      props.openUpgradeModal();
    }
  };

  const assigningObject = (type) => {
    openCommentShare(type);
  };

  const handleExternalEntityShare = (files) => {
    openCommentShare('file', files);
  };

  const isBtnDisabled = () => {
    const { content, objects, files, selectedParticipants } = state;
    return (
      !(
        (content && content.trim()) ||
        files.length > 0 ||
        objects.length > 0
      ) ||
      selectedParticipants.length === 0 ||
      state.btnDisabled
    );
  };

  const removeObject = (object) => {
    let objects = state.objects,
      newObjects = reject(objects, {
        __typename: object.__typename,
        id: object.id,
      });

    setState((prev) => ({ ...prev, objects: newObjects }));
  };

  const removeAttachment = (file) => {
    let files = state.files,
      newFiles = reject(files, { name: file.name });

    setState((prev) => ({ ...prev, files: newFiles }));
  };

  const renderAttachmentIcon = () => {
    return <AttachmentDropdown assignObject={assigningObject} />;
  };

  const onSearchUserInputChange = (userSearchInput) => {
    setState((prev) => ({ ...prev, userSearchInput }));
  };

  const renderList = (objects, afterClose) => {
    return objects.map((object) => renderCard(object, afterClose));
  };

  const renderCard = (object, afterClose) => {
    return (
      <div
        key={`${object.__typename}:${object.id}`}
        className='conversation-attachment-file-holder attached-card'>
        <OsCards
          size='extra-small'
          obj={object}
          closeRequired={true}
          afterClose={afterClose}
          avoidPreviewModal={true}
        />
      </div>
    );
  };

  const isOptionSupportUser = (option) => {
    return option.entity.is_support_user;
  };

  const isSupportUserAdded = () => {
    return find(state.selectedParticipants, isOptionSupportUser);
  };

  const isOptionDisabled = (option) => {
    if (isSupportUserAdded()) {
      return !isOptionSupportUser(option);
    } else {
      return (
        state.selectedParticipants.length > 0 && isOptionSupportUser(option)
      );
    }
  };

  const setInputActive = () => {
    setState((prev) => ({ ...prev, inputActive: true }));
  };

  const unsetInputActive = () => {
    setState((prev) => ({ ...prev, inputActive: false }));
  };

  const { content, objects, dropzoneActive, selectedParticipants } = state;
  let objectsLength = objects.length,
    filesLength = state.files.length,
    totalItems = objectsLength + filesLength,
    files = state.files.map((file) =>
      isBlob(file) ? mapLocalFileWithAttachmentObject(file) : file,
    );

  let dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ';
  dropzoneInputContainerClassName += state.dropzoneActive
    ? 'dropzone-drag-active'
    : '';

  return (
    <form
      onSubmit={handleSubmit}
      className='new-conversation communication conversations-page'>
      <div className='form-group user-selection-row'>
        <div className='new-conversation-row'>
          <SetPageTitle source={'new_direct_message'} />
          <label className='new-conversation-label'>To:</label>
          <div className='new-conversation-input' ref={participantsListDiv}>
            <SelectInput
              placeholder={`Start typing user's name here...`}
              isMulti={true}
              name='participants'
              className='react-select-ortho-2 os-menu-drop'
              value={selectedParticipants}
              onChange={changeSelection}
              autoFocus={!selectedParticipants.length}
              onInputChange={onSearchUserInputChange}
              components={{
                DropdownIndicator,
                IndicatorSeparator,
                IconClose,
              }}
              isOptionDisabled={(option) => isOptionDisabled(option)}
              isClearable={false}
              async={true}
              defaultSearchedValue={state.defaultSearchedValue}
              modifiedResultText={selectUserArguments}
              queryType='PossibleConversationUsers'
            />
          </div>
        </div>
      </div>

      <div className='conversation-input-section new-conversation-block relative'>
        <div className='conversation-input-holder'>
          <Dropzone
            disableClick
            style={{ position: 'relative' }}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            ref={(node) => {
              dropzoneRef.current = node;
            }}>
            {(props) => (
              <div>
                <div
                  {...props.getRootProps({
                    onClick: (evt) => onClickOverlay(evt),
                  })}
                  className={dropzoneInputContainerClassName}>
                  <input {...props.getInputProps()} />
                  {dropzoneActive && (
                    <div className='filedrop-overlay elem-full-hw d-flex p-0 small-overlay'>
                      <span className='m-auto fs-16'>
                        <OrthoIcon
                          name='upload'
                          dataHoverNotRequired={true}
                          defaultClass='me-2 blue3'
                        />{' '}
                        Drop files...
                      </span>
                    </div>
                  )}
                </div>
                <div className='form-group messenger-input-container'>
                  <div className='media'>
                    <div
                      className={`media-body input-with-btn ${state.inputActive ? 'input-focus-active' : ''
                        }`}>
                      {totalItems > 0 && (
                        <div className='conversation-attachment-row'>
                          <div className='conversation-attachment-file-block'>
                            <div className='conversation-attachment-files-count'>
                              {totalItems}{' '}
                              {pluralize(totalItems, {
                                singular: 'Attachment',
                                plural: 'Attachments',
                              })}
                            </div>
                            {renderList(files, removeAttachment)}
                            {renderList(objects, removeObject)}
                          </div>
                        </div>
                      )}
                      <div className='conversation-input-row'>
                        <div className='input-parent-box'>
                          <MentionableField
                            setRef={(input) => (inputRef.current = input)}
                            className='new-conversation-textarea'
                            placeholder='Write a message'
                            value={content}
                            onFocus={onFieldFocus}
                            onBlur={onFieldBlur}
                            onChange={changeContent}
                            onKeyDown={handleKeyDown}
                          />
                        </div>

                        <div className='chat-action-group'>
                          {renderAttachmentIcon()}
                          <VideoRecordButton
                            onClick={() => assigningObject('video')}
                            btnTitle={' '}
                            extraClass={'no-text os-viedo-btn'}
                          />
                          <OsBtn
                            icon='send'
                            extraClass='no-text pointer-allow conversation-input-button'
                            type='submit'
                            disabled={isBtnDisabled()}
                            label='send_message'
                            htmlTag='button'
                            name='BtnIcon'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    </form>
  );
};

const NewConversationViewGraphql = graphql(START_CONVERSATION_MUTATION, {
  name: 'startConversationMutation',
  options: {
    update: () => { },
  },
})(NewConversationViewWrapper);

const NewConversationViewConnect = connect(
  ({ currentUser, commentShare, device }) => ({
    currentUser,
    commentShare,
    device,
  }),
  {
    commentShareOpen,
    commentShareConsume,
    openUpgradeModal,
    openPoliciesModal,
  },
)(NewConversationViewGraphql);

const NewConversationView = withRouter(NewConversationViewConnect);
export default NewConversationView;
