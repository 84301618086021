import store from 'app/store';

import {
  ceil,
  find,
  filter,
  groupBy,
  has,
  intersection,
  isEmpty,
  lowerCase,
  map,
  now,
  orderBy,
  reject,
  slice,
  some,
  truncate,
  values,
} from 'app/utils/osLodash';
import { generateRandomNumber, orderedDegrees } from 'app/utils/generalHelper';
import {
  getSpaceIconType,
  isOpenSpace,
  isLockedSpace,
  lockedSpacePath,
} from 'app/utils/spaceHelper';
import { capitalize, pluralizeString } from 'app/utils/stringHelper';
import { timeDifferenceFromNowInMilliseconds } from 'app/utils/timeHelper';
import { replaceCurrentDomain } from 'app/utils/urlHelper';

import { EDIT_COMMENT_URL_HASH_FORMAT } from 'app/components/CommentSection/constants';
import {
  DOWNCASE_ENTITY_NAME,
  ENTITY_NAME_MAPPER,
  RESTRICTED_OBJECT_TYPES,
} from 'app/constants';
import { NAV_LIST_ABILITIES } from 'app/components/Shared/AbilityConstants';
import { ORTHO_ICON_CLASS_MAPPER } from 'app/components/Shared/OrthoIcon';
import { PAGE_WISE_STICKY_OFFSET_VALUES } from 'app/components/ContentWithSidebars/constants';
import {
  isWorkspaceSpace,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import { getFileNameWithoutExtension } from 'app/utils/fileHelper';

const PUBLISHABLE_ENTITIES = ['Case', 'Comparison'];
const TIMELINE_VIEW_TYPES = [
  'MyActivities',
  'NewUserActivities',
  'UserActivities',
];
const RESTRICTED_ENTITES = ['RestrictedCase', 'RestrictedSpace'];

export function entityConversationUrl(entity) {
  let { nice_id, nice_url, __typename: type } = entity,
    url = '';

  if (['Board', 'RestrictedSpace'].includes(type)) {
    url = `/conversations/${entity.type.toLowerCase()}/${nice_id}`;
  } else if (type === 'Tool') {
    url = `/conversations/tool/${nice_id}`;
  } else if (type === 'User') {
    url = `/team/dm/user/${nice_id || nice_url}`;
  }

  return withWorkspaceIdentifier(url);
}

export function entityUrl(entity, full = false, options = {}) {
  let { __typename: type, url } = entity;
  if (type === 'AdditionalLink') return url;
  if (type === 'CaseApplianceLog') return entityUrl(entity.tool, full, options);
  if (type === 'Person') return `/people/${entity.nice_id}`;
  // if (type === 'Comment') return `${process.env.REACT_APP_FRONTEND_APP_URL}/${options.workspaceIdentifier}/spaces/${options.commentableId}/discussions#comment-${entity.id}`
  return relativeEntityUrl(entity, full, options);
}

export function entitySocialSharingUrl(entity) {
  let { __typename: type, id, url } = entity;
  if (type === 'Comment') {
    url = `/comments/${id}`;
  } else if (type === 'AdditionalLink') {
    return url;
  } else {
    url = relativeEntityUrl(entity);
  }
  return `${process.env.REACT_APP_SHARE_ENTITY_HOST_URL}/ss${url}`;
}

export function getOtherParticipants(conversation) {
  if (
    values(groupBy(conversation.participants, 'id')).some((a) => a.length >= 2)
  ) {
    return [
      find(
        conversation.participants,
        (u) =>
          u.id.toString() === store.getState().currentUser.graph.id.toString(),
      ),
    ].filter((a) => a);
  } else {
    return reject(
      conversation.participants,
      (u) =>
        u.id.toString() === store.getState().currentUser.graph.id.toString(),
    ).filter((a) => a);
  }
}

export function relativeEntityUrl(entity, full = false, options = {}) {
  let url;
  let {
    nice_id,
    nice_url,
    __typename: type,
    commentable_path,
    entity_path,
    frontend_editable,
  } = entity;
  nice_url = nice_url || nice_id;
  if (['Board', 'RestrictedSpace'].includes(type)) {
    if (entity.type === 'Clinic') {
      url = `/clinics/${nice_id}`;
    } else if (entity.type === 'Company') {
      url = `/companies/${nice_id}`;
    } else if (
      entity.type === 'Content' &&
      entity.category_type === 'community'
    ) {
      url = `/team/group/${nice_id}`;
    } else {
      if (isLockedSpace(entity)) {
        url = lockedSpacePath(entity);
      } else {
        url = `/spaces/${nice_id}`;
      }
    }
    url = isWorkspaceSpace(entity) && !options.skipWorkspace ? '/' : url;
  } else if (['PartnerSpace'].includes(type)) {
    url = `/partners`;
  } else if (['Case', 'RestrictedCase'].includes(type)) {
    url = `/cases/${nice_id}`;
    url = frontend_editable ? `${url}/edit` : url;
  } else if (['Company', 'RestrictedCompany'].includes(type)) {
    url = `/companies/${nice_url}`;
  } else if (type === 'Comparison') {
    url = `/comparisons/${nice_url}`;
  } else if (type === 'FolderSpace') {
    url = `/folders/${nice_id}`;
  } else if (
    type === 'ContentSpace' ||
    (entity.type === 'Content' && entity.category_type === 'community')
  ) {
    url = `/team/spaces/${nice_id}`;
  } else if (['Tool', 'RestrictedTool'].includes(type)) {
    url = `/tools/${nice_id}`;
  } else if (['RestrictedUser', 'User'].includes(type)) {
    url = `/users/${nice_url}`;
  } else if (type === 'Comment') {
    url = entity_path
      ? withWorkspaceIdentifier(entity_path)
      : replaceCurrentDomain(commentable_path || nice_url);
  } else if (type === 'ConversationMessage') {
    url = nice_url || replaceCurrentDomain(commentable_path || nice_url);
  } else if (type === 'Pulse') {
    url = `/posts/${nice_id}`;
  } else if (type === 'BoardCollaborator') {
    url = relativeEntityUrl(entity.collaborator);
  }
  url = options.skipWorkspaceIdentifer ? url : withWorkspaceIdentifier(url);
  if (full) {
    url = `${process.env.REACT_APP_FRONTEND_APP_URL}${url}`;
  }
  return url;
}

export function isEntityReported(entity) {
  return entity.__typename === 'ReportedObject';
}

export function getAvatarIcon(role) {
  if (role === 'doctor_verified') {
    return 'doctors-outline';
  } else {
    return '';
  }
}

export function avatarAttributes(obj, options = {}) {
  if (!obj) return {};
  let commonProps = {
    name: obj.name,
    nice_id: obj.nice_id || obj.nice_url,
    initials: null,
    subscriptionColorCode: has(options, 'subscriptionColorCode')
      ? options.subscriptionColorCode
      : obj.active_subscription_color_code,
    avatarIcon: obj.avatarIcon || options.avatarIcon,
    iconWithImage: options.iconWithImage || getAvatarIcon(obj.role_type),
    emojiIcon: options.emojiIcon,
  };
  if (
    ['GuestUser', 'Person', 'RestrictedUser', 'User'].includes(obj.__typename)
  ) {
    return {
      src: options.largeAvatarRequired
        ? obj.large_avatar
        : obj.avatar || obj.large_avatar,
      ...commonProps,
      initials: obj.initials,
    };
  } else if (['Board', 'RestrictedSpace'].includes(obj.__typename)) {
    let logoProps = { Clinic: 'workspacelogo', Company: 'company' },
      src = options.entityImage
        ? obj.image
        : obj.owner
        ? obj.owner.logo_small
        : '';

    return {
      src,
      ...commonProps,
      avatarIcon: src ? null : logoProps[obj.type],
    };
  } else if (['RestrictedCompany', 'Company'].includes(obj.__typename)) {
    let src = options.largeAvatarRequired
      ? obj.logo_big
      : obj.logo_small || obj.logo_big;
    return {
      src,
      ...commonProps,
      avatarIcon: src ? null : 'company',
    };
  } else if (obj.__typename === 'Tool') {
    return {
      src: obj.company ? obj.company.logo_small : '',
      ...commonProps,
    };
  } else {
    return {};
  }
}

export function isEntityPublished(obj) {
  if (obj?.__typename === 'Board') {
    return isOpenSpace(obj)
      ? obj.is_author_or_collaborator
      : +obj.user.id === +store.getState().currentUser.graph.id;
  } else {
    return (
      obj?.status === 'Published' ||
      !['Case', 'Comparison'].includes(obj?.__typename)
    );
  }
}

export function isTimelineView(listType) {
  return TIMELINE_VIEW_TYPES.includes(listType);
}

export function getApolloUniqueIdentifier(object) {
  return `${object.__typename}:${object.id}`;
}

export function getFileSizeLimitFromPermissions(
  user,
  modelName,
  commentableType,
) {
  let model = modelName + '_model',
    externalSharePermission = filter(
      user.graph.permissions,
      (permission) =>
        permission.action === 'external_share_action' &&
        permission.model === model,
    ),
    externalSharePermissionFeatureHashes = map(
      externalSharePermission,
      (permission) => JSON.parse(permission.feature_hash),
    ),
    fileSizePermission = find(
      externalSharePermissionFeatureHashes,
      (permissionHash) => permissionHash.length > 0,
    ),
    fileSize = fileSizePermission && fileSizePermission[0]['size'];

  if (typeof fileSize === 'object') fileSize = fileSize[commentableType];

  return fileSize ? fileSize * 1024 * 1024 : Infinity;
}

export function commentEditUrl(comment) {
  return (
    entityUrl(comment).split('#')[0] + EDIT_COMMENT_URL_HASH_FORMAT + comment.id
  );
}

export function entityCommentsUrl(entity, full = false) {
  switch (entity.__typename) {
    case 'Comment':
      return replaceCurrentDomain(entity.commentable_path);
    case 'Board':
    case 'Space':
      return replaceCurrentDomain(entityUrl(entity, full)) + '/activity/all';
    default:
      return entityUrl(entity, full) + '#comments';
  }
}

export function entityCommentUrl(entity, commentId, openReply = false) {
  return withWorkspaceIdentifier(`/comments/${commentId}`);
}

export function entityFilteredCommentsUrl(entity = {}, hashTag = '') {
  switch (entity.__typename) {
    case 'Board':
    case 'Space':
    case 'RestrictedSpace':
      return entityUrl(entity) + '/discussions/' + hashTag;
    default:
      return entityUrl(entity) + '/' + hashTag;
  }
}

export function mapLocalFileWithAttachmentObject(file) {
  return {
    ...file,
    __typename: 'Attachment',
    id: file.id || -1,
    download_url: file.preview,
    preview_url: file.preview,
    thumb_url: file.preview,
    media_type: file.type,
    sub_type: '',
    size: file.size,
    file_size: file.size,
    name: file.name,
    duration: '',
    kind: '',
    meta_info: '{}',
    position: 1,
    adaptive_streaming_preview_url: null,
    created_at: ceil(now() / 1000),
  };
}

export function getRemainingEditTime(created_at, systemConfigs) {
  var editSystemConfig = systemConfigs.configs['edit_comment_hour_limit'];
  if (editSystemConfig) {
    return (
      editSystemConfig.value * 60 * 60 * 1000 -
      timeDifferenceFromNowInMilliseconds(created_at)
    );
  } else {
    return 0;
  }
}

export function entityEditUrl(entity, options = {}) {
  return `${entityUrl(entity, false, options)}/edit`;
}

export function hashTagUrl(hashTag, entity = {}) {
  let url =
    isEmpty(entity) || isWorkspaceSpace(entity)
      ? `/explore/polaris_feed/${hashTag}`
      : entityFilteredCommentsUrl(entity, hashTag);
  return withWorkspaceIdentifier(url);
}

export function getFilteredCollection(collection, filter) {
  filter =
    filter === 'attachment' ? ['additionallink', 'attachment'] : [filter];
  return collection.filter((item) => {
    let itemTypes = [
      item.__typename.toLowerCase(),
      item.object && item.object.__typename.toLowerCase(),
    ];
    return intersection(itemTypes, filter).length > 0;
  });
}

export function getSupportUser(collection) {
  return collection.find((user) => user.is_support_user) || {};
}

export function isEditPage(url) {
  return url.match(/^\/(.+)\/(.+\/edit)\/?$/);
}

export function userNameWithDegrees(user, options = {}) {
  let { degree_list, name } = user,
    degreesUpto = options.degreesUpto || 2,
    text =
      degree_list && degree_list.length > 0
        ? `${name}, ${slice(orderedDegrees(degree_list), 0, degreesUpto).join(
            ' ',
          )}`
        : name;

  return options.truncateUpto
    ? truncate(text, { length: options.truncateUpto })
    : text;
}

export function isPatientInfoAvailable(kase) {
  let patient_age = kase.t1_age ? kase.t1_age.years || kase.t1_age.months : '';
  return kase.patient_sex || patient_age;
}

function getCaseIconClass(entity, options = {}) {
  if (isEntityPublished(entity) || options.forcePublished) {
    return entity.three_d ? 'three_d_cases' : entity.__typename;
  } else {
    return 'locked';
  }
}

function getComparisonIconClass(entity, options = {}) {
  return isEntityPublished(entity) || options.forcePublished
    ? entity.__typename
    : 'locked';
}

export function getEntityIcon(entity, options = {}) {
  let iconName;
  switch (entity.__typename) {
    case 'Case':
    case 'RestrictedCase':
      iconName = getCaseIconClass(entity, options);
      break;
    case 'Comparison':
      iconName = getComparisonIconClass(entity, options);
      break;
    case 'Board':
    case 'RestrictedSpace':
      iconName = getSpaceIconType(entity, options);
      break;
    default:
      iconName = entity.__typename;
  }
  return iconName;
}

export function getEntityIconClass(entity, options = {}) {
  return getIconClass(getEntityIcon(entity, options));
}

export function entityIsAdditionalLinkOrAttachment(entity) {
  return entityIsAdditionalLink(entity) || entityIsAttachment(entity);
}

export function entityIsAdditionalLink(entity) {
  return entity.__typename === 'AdditionalLink';
}

export function entityIsAttachment(entity) {
  return entity.__typename === 'Attachment';
}

export function entityIsBoard(entity) {
  return entity && entity.__typename === 'Board';
}

export function entityIsBoardLink(entity) {
  return entity && entity.__typename === 'BoardLink';
}

export function entityIsCase(entity) {
  return entity && entity.__typename === 'Case';
}

export function entityIsTask(entity) {
  return entity && entity.__typename === 'Task';
}

export function entityIsDeleted(entity) {
  return entity && entity.__typename === 'DeletedObject';
}

export function getIconClass(type) {
  return ORTHO_ICON_CLASS_MAPPER[pluralizeString(type) || type];
}

export function isPrivateObject(obj) {
  if (obj.__typename === 'Board') {
    return !isOpenSpace(obj) && !obj.is_author_or_collaborator;
  } else if (PUBLISHABLE_ENTITIES.includes(obj.__typename)) {
    return !isEntityPublished(obj);
  }
}

export function findFirstPrivateObject(objects) {
  return find(objects, (object) => isPrivateObject(object));
}

export function anyPrivateObjectPresent(objects) {
  return some(objects, (object) => isPrivateObject(object));
}

export function getEntityDownCaseName(entityName) {
  return DOWNCASE_ENTITY_NAME[entityName] || 'case';
}

export function getStickyValue(source, isAuthorOrCollaborator) {
  if (isAuthorOrCollaborator) {
    source = 'space-page-sticky-max';
  }
  return (
    PAGE_WISE_STICKY_OFFSET_VALUES[source] ||
    PAGE_WISE_STICKY_OFFSET_VALUES['default']
  );
}

export function getUsersWithIds(usersList) {
  return filter(usersList, (user) => user.id);
}

export function getUsersWithoutIds(usersList) {
  return filter(usersList, (user) => !user.id);
}

export function getAuthorizeObject(action, entity, options = {}) {
  return { action, entity, options };
}

export function isTabAuthorized(tabSource, filter, user) {
  let abilityObject =
      NAV_LIST_ABILITIES[tabSource] && NAV_LIST_ABILITIES[tabSource][filter],
    { action, entity, options } = abilityObject || {};

  return !abilityObject || user.ability.can(action, entity, options);
}

export function activeTreatmentDuration(kase, cardView) {
  let activeTreatment = kase.active_treatment,
    defaultDuration =
      cardView && !(kase.is_in_progress && kase.workflow_state !== 'user_draft')
        ? '0 mo'
        : '';

  return !isEmpty(activeTreatment)
    ? activeTreatment.years * 12 +
        activeTreatment.months +
        (cardView ? ' mo' : ' months')
    : defaultDuration;
}

export function sortFilesAndGetAttachment(files) {
  return map(orderBy(files, 'position'), 'file_asset');
}

export function buildFormattedIds(objects = []) {
  return map(objects, (object) => {
    return RESTRICTED_OBJECT_TYPES.includes(object.__typename)
      ? object.id
      : object.__typename + ':' + object.id;
  });
}

export function isObjectedDeleted(object) {
  return object && object.__typename === 'DeletedObject';
}

export function canAccessRestrictedEntity(type) {
  return RESTRICTED_ENTITES.includes(type);
}

export function displayEntityName(entity) {
  return (
    capitalize(ENTITY_NAME_MAPPER[lowerCase(entity.__typename)]) ||
    entity.__typename
  );
}

export function getEntityName(entity) {
  switch (entity.__typename) {
    case 'AdditionalLink':
    case 'Attachment':
      return getFileNameWithoutExtension(entity.name || entity.title);
    case 'Comment':
      return 'Comment';
    case 'Pulse':
      return `Post ${entity.nice_id}`;
    case 'Case':
    case 'RestrictedCase':
      return `Case ${entity.nice_id}`;
    default:
      return entity.name || entity.product_name;
  }
}

export function genericSearchUrl(searchValue, filter = 'discussions') {
  return withWorkspaceIdentifier(`/records/search/${filter}?q=${searchValue}`);
}

export function isDoubleTickOverlayRequired(sourceObject) {
  return (
    sourceObject &&
    !isWorkspaceSpace(sourceObject) &&
    !sourceObject.is_author_or_editor &&
    sourceObject.is_author_or_collaborator
  );
}

export function isCardViewed(link, sourceObject) {
  return (
    isDoubleTickOverlayRequired(sourceObject) && !!link?.viewed_space_content
  );
}

export function isCareSpaceTabRequired(workspace) {
  return workspace?.workspace_type === 'Clinic';
}

export function getEntityPreviewURL(obj) {
  return obj.thumb_url;
}

export function isPinnedSupported(entity) {
  switch (entity.commentable_type) {
    case 'Task':
      return false;
    default:
      return true;
  }
}
