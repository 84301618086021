import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import ReactDOM from 'react-dom';

import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import ArchiveSpace from 'app/components/Shared/ArchiveSpace';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import JoinSpace from 'app/components/SpaceRequests/JoinSpace';
import Like from 'app/components/Shared/Like';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import SpaceActions from './SpaceActions';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import {
  isContentTypeSpace,
  isClinicSpace,
  isCompanySpace,
  isOpenSpace,
  isPrivateSpace,
  isSubSpace,
  isFolderSpace,
  isSpaceArchived,
} from 'app/utils/spaceHelper';
import { entityUrl, getEntityIcon } from 'app/utils/entitiesHelper';
import { REJECTED_STATUS, SHARED_SPACE_AUTHENTICATED } from './constants';

import { openEditImageModal } from 'app/actions/editImage';
import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';

import {
  renameSpaceFormRequired,
  getRenameSpaceFormUniqueIdentifier,
} from 'app/utils/featureHelper';
import { openOnboardingVerificationModals } from 'app/actions/onboardingTips';
import { timeWithFormat } from 'app/utils/timeHelper';

import LocalStorageManager from 'app/services/LocalStorageManager';
import AbstractListing from './AbstractListing';

class SubHeader extends AbstractListing {
  state = {
    spaceUpdatesAfterRequest: {},
    renameFormVisibile: false,
  };

  el = document.createElement('div');

  componentDidMount() {
    if (this.removeLoginCTARequired()) {
      let loginSignUpElement = document.getElementById('loginSignUp');
      if (loginSignUpElement) {
        this.loginSignUpElementContent = loginSignUpElement.cloneNode(true);
        loginSignUpElement.innerHTML = '';
        loginSignUpElement.appendChild(this.el);
      }
    }

    if (!this.props.device.mobileDevice) {
      this.calculateStickyHeaderHeight();
      // this.calculateResize();
    }
  }

  componentWillUnmount() {
    if (this.removeLoginCTARequired() && this.loginSignUpElementContent) {
      document
        .getElementById('loginSignUp')
        .replaceWith(this.loginSignUpElementContent);
    }
  }

  removeLoginCTARequired() {
    let space = this.getSpace();
    return (
      this.props.isGuestUser &&
      !isCompanySpace(space) &&
      !isClinicSpace(space) &&
      !LocalStorageManager.get('space_invite_token') &&
      !(this.props.guestUser.action === SHARED_SPACE_AUTHENTICATED)
    );
  }

  calculateStickyHeaderHeight() {
    window.$('.sub-nav-background').height('unset');
    var stickyNavHeight =
      window.$('.sub-nav-background > .container-fluid').height() -
      parseInt(window.$('.tab-nav-container').css('marginTop'));
    window.$('.sub-nav-background').height(stickyNavHeight);
  }

  calculateResize() {
    var _this = this;
    window.$(window).resize(function () {
      _this.calculateStickyHeaderHeight();
    });
  }

  getSpace() {
    // HM: Issue: Values not updating after 2nd request in space.
    return { ...this.props.space, ...this.state.spaceUpdatesAfterRequest };
  }

  renderGenericPrimaryActionsForSpace() {
    if (!this.getSpace().is_author_or_collaborator)
      return this.renderRequestResponse();
  }

  renderRequestResponse() {
    let space = this.getSpace(),
      status = space.member_request_status,
      extraClass = this.props.device.mobileDevice
        ? 'd-block web-view-btn'
        : ' request-join-btn';

    if (!status || REJECTED_STATUS.includes(status)) {
      if (!isCompanySpace(space)) {
        return this.renderJoinSpace(extraClass);
      }
    } else if (status === 'pending') {
      let btnProps = {
        extraClass: extraClass,
        space: space,
        requestingUser: this.props.currentUser.graph,
        checkAccess: true,
      };
      if (space.member_requested_by_author) {
        return (
          <>
            <AcceptRequest {...btnProps} text='accept invitation' />
            <DeclineRequest {...btnProps} />
          </>
        );
      } else {
        return (
          <DeclineRequest
            {...btnProps}
            btnType='BtnPrimary'
            text='Cancel Request'
            onSuccess={this.onSuccessActionOnRequest}
          />
        );
      }
    }
  }

  onSuccessActionOnRequest = (space) => {
    this.setState({ spaceUpdatesAfterRequest: space });
  };

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  isMemberRequestStatusPending() {
    return this.getSpace().member_request_status === 'pending';
  }

  isMemberKindEditor() {
    return (
      this.getSpace().user_request &&
      this.getSpace().user_request.kind === 'editor'
    );
  }

  renderJoinSpace(extraClass) {
    return (
      <JoinSpace
        extraClass={extraClass}
        viewType='primaryAction'
        requestingUsers={[this.props.currentUser.graph]}
        board={this.getSpace()}
        onSuccess={this.onSuccessActionOnRequest}
      />
    );
  }

  renderRequestResponseForGuestUser() {
    let extraClass = this.props.device.mobileDevice
      ? 'd-block web-view-btn'
      : '';
    return ReactDOM.createPortal(this.renderJoinSpace(extraClass), this.el);
  }

  renderPrimaryActionsForGuestUser() {
    if (isOpenSpace(this.getSpace()))
      return this.renderRequestResponseForGuestUser();
  }

  renderUnArchiveCTA() {
    return <ArchiveSpace space={this.getSpace()} name='BtnPrimary' />;
  }

  renderPrimaryActions() {
    let space = this.getSpace();

    if (this.props.isGuestUser) {
      return this.renderPrimaryActionsForGuestUser();
    }

    if (isSpaceArchived(space)) {
      return this.renderUnArchiveCTA();
    } else if (isContentTypeSpace(space) || isCompanySpace(space)) {
      return this.renderGenericPrimaryActionsForSpace();
    }
  }

  renderAuthorNameForDifferentSpacesType() {
    return this.renderAuthorName();
  }

  renderAuthorName() {
    return (
      !this.getSpace().featured && (
        <div className='pe-2 semibold lh-1 me-3'>
          <UserQualificationInfo
            user={this.getSpace().user}
            osLinkClass='a-link white-link'
            sliceUpto={2}
            linkRequired={true}
          />
        </div>
      )
    );
  }

  renderSpaceNameAndDetails() {
    let space = this.getSpace();
    return (
      <div className='space-header-name pe-3'>
        {isFolderSpace(space) && (
          <OrthoIcon
            name='folder'
            defaultClass='pe-2'
            dataHoverNotRequired={true}
          />
        )}
        <span className='span'>{space.name}</span>
      </div>
    );
  }

  renderLike() {
    return (
      <Like
        obj={this.getSpace()}
        labelRequired={true}
        dropDownListing={true}
        showLabelIfZero={true}
        extraClass='mgl-12'
      />
    );
  }

  renderParentSpaceLink() {
    let parentSpace = this.getSpace().owner,
      btnExtraClass = 'sub-space-btn ';
    btnExtraClass += this.props.device.mobileDevice
      ? 'mobile-sub-space-btn'
      : 'me-3';

    return (
      <>
        <OsLink
          to={entityUrl(parentSpace)}
          className='white-link me-3 '
          associatedEntity={parentSpace}>
          <OrthoIcon
            name={getEntityIcon(parentSpace)}
            dataHoverNotRequired={true}
          />
          &nbsp; {parentSpace.name}
        </OsLink>
        <span className='mobile-view-only'></span>
      </>
    );
  }

  showSpaceActions() {
    return true;
  }

  renderBannerForFolderView() {
    return (
      <img
        src={this.props.space.image}
        alt=''
        className='img'
        height={80}
        width={120}
      />
    );
  }

  renderEditImageButton(source = '') {
    let isUnderDropdown = source === 'dropdown',
      extraClass =
        this.props.device.mobileDevice || isUnderDropdown
          ? ' list-button '
          : ' web-view-btn back-nav-btn ';
    return (
      <OsBtn
        name='BtnIcon'
        iconClass='icon-os-edit ifill-os-edit'
        iconDefaultClass='ifill-os-edit'
        text='Edit Image'
        extraClass={`edit-image-btn ${extraClass}`}
        onClick={this.props.openEditImageModal.bind(this, {
          space: this.getSpace(),
        })}
      />
    );
  }

  renderLastUpdate() {
    return (
      <span className='update-time'>
        Last Updated:{' '}
        {timeWithFormat(this.props.space.updated_at, 'MM/DD/YYYY [at] h:mm A')}
      </span>
    );
  }

  renderRenameForm() {
    //Form content will be rendered through Rename Entity Portal
    if (renameSpaceFormRequired(this.props.space)) {
      return (
        <div
          className={
            'rename-form' +
            (this.state.renameFormVisibile ? ' with-rename' : ' d-none')
          }>
          <div id={getRenameSpaceFormUniqueIdentifier(this.props.space)}></div>
        </div>
      );
    }
  }

  onRenameClick = (renameFormVisibile) => {
    this.setState({ renameFormVisibile });
  };

  renderSpaceActions() {
    return (
      <SpaceActions
        space={this.getSpace()}
        onRenameClick={this.onRenameClick}
      />
    );
  }

  renderContent() {
    let mobile = this.props.device.mobileDevice,
      isAuthorOrEditor = this.getSpace().is_author_or_editor,
      likeNotRequired =
        isPrivateSpace(this.getSpace()) && !this.getSpace().members_count,
      subHeaderClass = 'space-sub-header ';

    subHeaderClass +=
      !this.getSpace().is_author_or_collaborator &&
      !this.props.device.mobileDevice
        ? 'with-join-request '
        : 'already-joined ';
    subHeaderClass += 'without-extra-info';

    return (
      <>
        <article className={subHeaderClass}>
          <div className='d-flex justify-content-between header-title-row'>
            <div className='image-with-info'>
              <div className='image-figure'>
                {isFolderSpace(this.props.space) &&
                  this.renderEditImageButton()}
                {isFolderSpace(this.props.space) &&
                  this.renderBannerForFolderView()}
              </div>
              <div className='folder-author-info'>
                {!this.state.renameFormVisibile &&
                  this.renderSpaceNameAndDetails()}
                {this.renderRenameForm()}
                {!mobile && (
                  <div
                    className={`d-flex align-items-center btn-with-title-bar ${
                      this.props.device.mobileDevice ? 'only-mobile-bar' : ''
                    } `}>
                    {this.renderAuthorNameForDifferentSpacesType()}
                    {isSubSpace(this.getSpace()) &&
                      this.renderParentSpaceLink()}
                    {!likeNotRequired && this.renderLike()}
                    {isFolderSpace(this.props.space) && this.renderLastUpdate()}
                  </div>
                )}
              </div>
            </div>
            {!mobile && (
              <div className='text-nowrap space-header-right'>
                {this.renderPrimaryActions()}
                {isAuthorOrEditor && isFolderSpace(this.props.space) && (
                  <OsBtn
                    name='BtnPrimary'
                    extraClass='new-folder-btn me-5 d-none'
                    text='Add Content'
                    onClick={this.addNewLink}
                  />
                )}
                {this.showSpaceActions() && this.renderSpaceActions()}
              </div>
            )}
          </div>
        </article>

        {mobile && (
          <div className='my-3 px-3 r-p-action text-center '>
            {this.renderPrimaryActions()}
            {this.showSpaceActions() && this.renderSpaceActions()}
          </div>
        )}
      </>
    );
  }

  render() {
    return this.renderContent();
  }
}

SubHeader = withAuthenticate(SubHeader);
SubHeader = withRouter(SubHeader);
SubHeader = connect(
  ({ currentUser, device, guestUser, systemConfig, commentShare }) => ({
    currentUser,
    device,
    guestUser,
    systemConfig,
    commentShare,
  }),
  {
    openOnboardingVerificationModals,
    openEditImageModal,
    commentShareOpen,
    commentShareConsume,
  },
)(SubHeader);

export default SubHeader;
