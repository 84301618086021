export const AUTO_CLOSE = {
  care_space: {
    shared_with_patient_successfully: { time: 2000 },
  },
  partner_space: {
    shared_with_member_successfully: { time: 2000 },
  },
};

export const ACTION_BUTTON_TEXT = {
  partner_space: {
    shared_with_partner_confirmation_with_list: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
  },
  care_space: {
    access_successfuly_revoked: { primary: 'Ok' },
    add_in_proirity_group: { primary: 'Ok, got it', secondary: 'undo' },
    delete_appointment: { primary: 'Delete', secondary: 'Cancel' },
    shared_with_patient_confirmation: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
    shared_with_patient_confirmation_with_list: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
    shared_with_patient_confirmation_with_verification: {
      primary: 'Anyway Share',
      secondary: 'Verify Data',
    },
    reshare_with_patient_confirmation: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
    patient_share_error: { primary: 'Okay' },
    unlock_space_error: { primary: 'Okay' },
    copy_patient_link: { primary: 'Copy link', secondary: 'Cancel' },
    decline_access_error: { primary: 'Okay' },
    email_change_confirmation: { primary: 'Confirm', secondary: 'Cancel' },
    person_changes_success: { primary: 'Got it' },
    patient_detail_change_confirmation: {
      primary: 'Confirm',
      secondary: 'Cancel',
    },
    patient_info_confirmed_success: { primary: 'Okay' },
    email_changed_successfully: {
      primary: 'Share PatientSpace',
      secondary: "I'll do it later",
    },
    delete_scheduled_post: {
      primary: 'YES, CANCEL IT',
      secondary: 'NO, KEEP IT',
    },
  },
  general: {
    access_revoke: { primary: 'Ok, got it' },
    connection_request_updated: { primary: 'OK' },
    delete_confirmation: { primary: 'Remove', secondary: 'Cancel' },
    entity_not_found: { primary: 'Ok' },
    error_message: { primary: 'Ok' },
    label_error_message: { primary: 'Ok' },
    message_shared_successfully: {
      primary: 'View Message',
      secondary: 'Close',
    },
    post_shared_successfully: { primary: 'View Post', secondary: 'Close' },
    shared_in_discussion_successfully: {
      primary: 'View Discussion',
      secondary: 'Close',
    },
    sign_tou_mail: { primary: 'Ok' },
    email_verification_success: { primary: 'Ok' },
    email_verification_failed: { primary: 'Ok' },
    new_device_session_termination_success: { primary: 'Ok' },
    new_device_session_termination_failed: { primary: 'Ok' },
    invalid_magic_link: { primary: 'Ok' },
    reset_password_sent_successfully: { primary: 'Go to Login' },
    reset_password_send_failed: { primary: 'Ok' },
    user_verification_required_for_discussions: {
      primary: 'Finish sign up process',
    },
    reset_password_link_expired: { primary: 'Ok' },
    profile_info_submitted: {},
    user_verification_awaited_for_approval: {},
    invalid_password: { primary: 'Ok' },
    invalid_old_password: { primary: 'Ok' },
    profile_switcher_disabled_for_your_workspace: { primary: 'Ok' },
    not_part_of_workspace: { primary: 'Ok' },
    password_already_present: { primary: 'Ok' },
    profile_switcher_not_enabled_for_workspace: { primary: 'Ok' },
    care_space_not_shared_warning: { primary: 'Share', secondary: 'Close' },
    outside_working_hours: { primary: 'Okay' },
    patient_form_warning_on_email_or_phone_not_present: { primary: 'Close' },
    secure_notification_visibility: {
      primary: 'Yes, Send the full message without secure access',
      secondary: 'No, Secure and Send the message, it has PHI',
    },
  },
  space: {
    can_not_access_course: { primary: 'OK' },
    course_credits_unavailable: { primary: 'Ok' },
    course_purchase_error: { primary: 'Ok' },
    course_price_updated: { primary: 'Check new price' },
    course_subscription: { secondary: 'Cancel' },
    course_subscribed_successfully: { primary: 'Start Learning' },
    course_survey_passed: {
      primary: 'Start New Course',
      secondary: 'Download CE certificate',
    },
    event_group_non_member: { primary: 'Register Now', secondary: 'Cancel' },
    group_non_member: { primary: 'Request To Join', secondary: 'Cancel' },
    group_pending_request: { primary: 'Close' },
    group_pending_request_requested_by_author: { primary: 'Close' },
    mute_confirmation: { primary: 'Mute', secondary: 'Cancel' },
    non_subscribed_workspace: { primary: 'Ok, got it' },
    private_content_added: { primary: 'Ok, got it' },
    select_clinic_for_partner_space_invite: {
      primary: 'Yes',
      secondary: 'Change selection',
    },
    space_include_discussion: { primary: 'Confirm', secondary: 'Cancel' },
    space_archive: { primary: 'Confirm', secondary: 'Cancel' },
    space_unarchive: { primary: 'OK' },
    group_unarchive: { primary: 'OK' },
    back_from_survey: { primary: 'yes, I am sure' },
    request_to_join_space_sent: { primary: 'OK' },
    tou_not_accepted: { primary: 'Ok' },
    content_not_accessible: { primary: 'Ok' },
  },
};

export const CONTENT_KEY_MAPPER = {
  care_space: {
    access_successfuly_revoked: 'CARE_SPACE_ACCESS_REVOKED',
    add_in_proirity_group: 'ADD_IN_PRIORITY_GROUP_CONFIRMATION',
    delete_appointment: 'DELETE_ENTITY_TEXT',
    shared_with_patient_confirmation:
      'CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION',
    shared_with_patient_confirmation_with_list:
      'CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION_WITH_LIST',
    shared_with_patient_confirmation_with_verification:
      'CARE_SPACE_SHARE_WITH_PATIENT_CONFIRMATION_WITH_VERIFICATION_MESSAGE',
    shared_with_patient_successfully: 'CARE_SPACE_SHARED_WITH_PATIENT',
    reshare_with_patient_confirmation: 'RESHARE_CARE_SPACE_CONFIRMATION',
    patient_share_error: 'GENERAL_ERROR_MESSAGE',
    unlock_space_error: 'UNLOCK_SPACE_ERROR',
    decline_access_error: 'CREATE_ERROR',
    email_change_confirmation: 'EMAIL_CHANGE_CONFIRMATION',
    person_changes_success: 'INFO_CHANGE_SUCCESS',
    patient_detail_change_confirmation: 'PATIENT_DETAIL_CHANGE_CONFIRMATION',
    patient_info_confirmed_success: 'PATIENT_INFO_CONFIRMED_SUCCESS_TEXT',
    email_changed_successfully: 'PERSON_EMAIL_CHANGED_SUCCESSFULLY',
    delete_scheduled_post: 'DELETE_SCHEDULED_POST',
  },
  partner_space: {
    shared_with_member_successfully: 'PARTNER_SPACE_SHARED_WITH_MEMBERS',
    shared_with_partner_confirmation_with_list:
      'PARTNER_SPACE_SHARE_WITH_CLINIC_CONFIRMATION_WITH_LIST',
  },
  general: {
    access_revoke: 'ENTITY_ACCESS_REVOKE',
    connection_request_updated: 'CONNECTION_REQUEST_UPDATED',
    delete_confirmation: 'DELETE_ENTITY_CONFIRMATION',
    entity_not_found: 'ENTITY_NOT_ACCESSIBLE',
    error_message: 'GENERAL_ERROR_MESSAGE',
    label_error_message: 'LABEL_ERROR_MESSAGE',
    message_shared_successfully: 'ENTITY_SUCCESSFULLY_SHARED',
    post_shared_successfully: 'SHARED_SUCCESSFULLY',
    shared_in_discussion_successfully: 'SHARED_SUCCESSFULLY',
    sign_tou_mail: 'MAIL_SEND_TO_SIGN_TOU_SUCCESSFULLY',
    email_verification_success: 'EMAIL_VERIFIED_SUCCESSFULLY',
    email_verification_failed: 'EMAIL_VERIFICATION_FAILED',
    new_device_session_termination_success: 'SESSION_SUCCESSFULLY_TERMINATED',
    new_device_session_termination_failed: 'SESSION_UNSUCCESSFULLY_TERMINATED',
    invalid_magic_link: 'INVALID_MAGIC_LINK_TOKEN',
    reset_password_sent_successfully: 'RESET_PASSWORD_SENT_SUCCESSFULLY',
    reset_password_send_failed: 'RESET_PASSWORD_SEND_FAILED',
    user_verification_required_for_discussions:
      'COMPLETE_VERIFICATION_FOR_DISCUSSIONS_ACCESS',
    reset_password_link_expired: 'RESET_PASSWORD_LINK_EXPIRED',
    profile_info_submitted: 'PROFILE_INFO_SUBMITTED',
    user_verification_awaited_for_approval: 'VERIFICATION_APPROVAL_PENDING',
    invalid_password: 'INVALID_PASSWORD',
    invalid_old_password: 'INVALID_OLD_PASSWORD',
    profile_switcher_disabled_for_your_workspace:
      'PROFILE_SWITCHER_DISABLED_FOR_YOUR_WORKSPACE',
    not_part_of_workspace: 'NOT_PART_OF_WORKSPACE',
    password_already_present: 'PASSWORD_ALREADY_PRESENT',
    profile_switcher_not_enabled_for_workspace:
      'PROFILE_SWITCHER_NOT_ENABLED_FOR_WORKSPACE',
    care_space_not_shared_warning: 'CARE_SPACE_NOT_SHARED_WARNING',
    outside_working_hours: 'OUTSIDE_WORKING_HOURS',
    patient_form_warning_on_email_or_phone_not_present:
      'PATIENT_FORM_PHONE_OR_EMAIL_WARNING',
    secure_notification_visibility: 'SECURE_NOTIFICATION_VISIBILITY',
  },
  space: {
    can_not_access_course: 'COURSE_ACCESS_DENIED',
    course_credits_unavailable: 'COURSE_CREDITS_UNAVAILABLE_CONTENT',
    course_purchase_error: 'SOMETHING_WENT_WRONG',
    course_price_updated: 'COURSE_PRICE_UPDATED',
    course_subscription: 'DO_NOT_HAVE_COURSE_SUBSCRIPTION',
    course_subscribed_successfully: 'COURSE_SUCCESSFULLY_SUBSCRIBED',
    course_survey_passed: 'COURSE_SURVEY_PASSED_CONTENT',
    event_group_non_member: 'REGISTER_FOR_EVENT',
    group_non_member: 'NON_MEMBER_GROUP_SPACE',
    group_pending_request: 'SPACE_REQUEST_PENDING',
    group_pending_request_requested_by_author:
      'SPACE_REQUEST_PENDING_REQUESTED_BY_AUTHOR',
    mute_confirmation: 'MUTE_SPACE_CONFIRMATION_TEXT',
    non_subscribed_workspace: 'NON_SUBSCRIBED_WORKSPACE',
    private_content_added: 'PRIVATE_CONTENT_ADDED_SPACE_CONTENT',
    select_clinic_for_partner_space_invite:
      'SELECT_CLINIC_FOR_PARTNER_SPACE_INVITE',
    space_include_discussion: 'DISABLE_DISCUSSION_INCLUDED_WARNING',
    space_archive: 'ARCHIVE_SPACE',
    space_unarchive: 'UNARCHIVE_SPACE',
    group_unarchive: 'UNARCHIVE_GROUP',
    back_from_survey: 'GO_BACK_TO_COURSE',
    request_to_join_space_sent: 'REQUEST_TO_JOIN_THE_SPACE_SENT_SUCCESSFULLY',
    tou_not_accepted: 'TOU_NOT_ACCEPTED',
    content_not_accessible: 'CONTENT_NOT_ACCESSIBLE',
  },
};

export const DO_NOT_SHOW_CHECKBOX_ATTRIBUTE_MAPPER = {
  care_space: {},
  partner_space: {},
  general: {},
  space: {
    private_content_added: {
      attribute: 'show_private_content_warning',
      value: false,
    },
  },
  workspace: {},
};

export const HEADING_TEXT_MAPPER = {
  care_space: {
    access_successfuly_revoked: 'Access Revoked',
    add_in_proirity_group: 'You have been added as a Priority Contact',
    delete_appointment: 'Delete Appointment',
    shared_with_patient_confirmation:
      'Share PatientSpace with patient and contacts?',
    shared_with_patient_confirmation_with_list:
      'Share PatientSpace with patient and contacts?',
    shared_with_patient_confirmation_with_verification:
      'Verify patient data before sharing with patient',
    reshare_with_patient_confirmation: 'Reshare PatientSpace with %{name}?',
    copy_patient_link: 'Copy Patient Link From Here',
    email_change_confirmation: 'Confirm these changes?',
    person_changes_success: 'Information Update',
    patient_detail_change_confirmation: 'Confirm these changes?',
    patient_info_confirmed_success: 'Update Successful',
    email_changed_successfully: 'Update Successful',
    delete_scheduled_post: 'Are you sure?',
  },
  partner_space: {
    shared_with_partner_confirmation_with_list:
      'Share PartnerSpace with Clinic Members?',
  },
  general: {
    access_revoke: 'Access denied',
    delete_confirmation: 'Remove Confirmation',
    entity_not_found: 'Entity not found',
    message_shared_successfully: 'Successfully shared',
    post_shared_successfully: 'Successfully shared',
    shared_in_discussion_successfully: 'Successfully shared',
    user_verification_required_for_discussions: 'Verification required',
    profile_info_submitted: 'Account Ready',
    user_verification_awaited_for_approval: 'Verification Pending',
    invalid_password: 'Incorrect PIN',
    invalid_old_password: 'Invalid Password',
    profile_switcher_disabled_for_your_workspace: 'PIN Login is Disabled',
    not_part_of_workspace: 'Access Denied',
    password_already_present: 'PIN has already been set',
    profile_switcher_not_enabled_for_workspace: 'PIN Login is Disabled',
    care_space_not_shared_warning: 'Patient Space not shared',
    outside_working_hours: 'Sorry we missed you!',
    patient_form_warning_on_email_or_phone_not_present:
      'Contact Information is Required',
    secure_notification_visibility: 'Notification Visibility',
  },
  space: {
    can_not_access_course: 'Access denied',
    course_price_updated: 'Course Price Updated',
    course_subscription: "You don't have access",
    course_subscribed_successfully: 'Successfully Subscribed',
    course_survey_passed: 'You finished the course',
    event_group_non_member: 'Register Now',
    group_non_member: "You don't have access",
    group_pending_request: "You don't have access",
    group_pending_request_requested_by_author: "You don't have access",
    mute_confirmation: 'Are you sure want to mute?',
    non_subscribed_workspace: "You don't have access",
    private_content_added: 'Private content alert',
    select_clinic_for_partner_space_invite: 'Are you sure?',
    space_include_discussion: 'Want to disable discussion?',
    space_archive: 'Want to archive this space?',
    space_unarchive: 'Space Unarchived.',
    group_unarchive: 'Group Unarchived',
    back_from_survey: 'Are you sure?',
    request_to_join_space_sent: 'Successfully sent.',
  },
};

export const ICON_NAME_MAPPER = {
  cannotDelete: [
    'general:access_revoke',
    'general:entity_not_found',
    'general:invalid_password',
    'general:invalid_old_password',
    'general:profile_switcher_disabled_for_your_workspace',
    'general:not_part_of_workspace',
    'general:password_already_present',
    'general:profile_switcher_not_enabled_for_workspace',
  ],
  cannotEdit: [
    'space:group_non_member',
    'care_space:decline_access_error',
    'space:non_subscribed_workspace',
  ],
  cannotOpen: [
    'space:course_subscription',
    'space:group_pending_request',
    'space:group_pending_request_requested_by_author',
    'space:event_group_non_member',
    'space:can_not_access_course',
  ],
  collaborationSpace: [],
  collectionSpace: [],
  groupSpace: [],
  information: [
    'space:private_content_added',
    'care_space:patient_share_error',
    'care_space:person_changes_success',
    'care_space:patient_detail_change_confirmation',
  ],
  noUnreadNotifications: [
    'space:mute_confirmation',
    'care_space:add_in_proirity_group',
  ],
  openSpace: [],
  spaces: [],
  invite: [
    'care_space:shared_with_patient_confirmation',
    'care_space:shared_with_patient_confirmation_with_list',
    'care_space:reshare_with_patient_confirmation',
    'partner_space:shared_with_partner_confirmation_with_list',
  ],
  success: [
    'care_space:access_successfuly_revoked',
    'care_space:shared_with_patient_successfully',
    'partner_space:shared_with_member_successfully',
    'general:post_shared_successfully',
    'general:shared_in_discussion_successfully',
    'general:message_shared_successfully',
    'space:space_unarchive',
    'space:group_unarchive',
    'space:request_to_join_space_sent',
    'general:email_verification_success',
    'general:new_device_session_termination_success',
    'general:reset_password_sent_successfully',
    'space:course_subscribed_successfully',
    'general:profile_info_submitted',
    'care_space:patient_info_confirmed_success',
    'care_space:email_changed_successfully',
  ],
  verification: [
    'general:user_verification_required_for_discussions',
    'general:user_verification_awaited_for_approval',
  ],
  warning: [
    'general:care_space_not_shared_warning',
    'general:error_message',
    'general:label_error_message',
    'space:space_include_discussion',
    'space:back_from_survey',
    'space:select_clinic_for_partner_space_invite',
    'general:delete_confirmation',
    'space:space_archive',
    'general:connection_request_updated',
    'space:tou_not_accepted',
    'general:email_verification_failed',
    'general:new_device_session_termination_failed',
    'general:reset_password_send_failed',
    'general:invalid_magic_link',
    'space:course_price_updated',
    'space:course_purchase_error',
    'general:reset_password_link_expired',
    'space:course_credits_unavailable',
    'space:content_not_accessible',
    'care_space:shared_with_patient_confirmation_with_verification',
    'care_space:delete_appointment',
    'space:private_content_added',
    'care_space:unlock_space_error',
    'care_space:email_change_confirmation',
    'general:patient_form_warning_on_email_or_phone_not_present',
  ],
  delete: ['care_space:delete_scheduled_post'],
};
