import React, { Component } from 'react';

import OsContentEditableField from './OsContentEditableField';
import OsCheckboxField from './OsCheckboxField';
import OsCountryCodeSelect from './OsCountryCodeSelect';
import OsDateField from './OsDateField';
import OsEditorField from './OsEditorField';
import OsErrorField from './OsErrorField';
import OsHiddenField from './OsHiddenField';
import OsInputField from './OsInputField';
import OsLabel from './OsLabel';
import OsNumberFormat from './OsNumberFormat';
import OsPasswordField from './OsPasswordField';
import OsSelectInputField from './OsSelectInputField';
import OsTextAreaField from './OsTextAreaField';

import { pick } from 'app/utils/osLodash';
import OsReactDateField from './OsReactDateField';
import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import OsFileUploadField from 'app/components/OsField/OsFileUploadField';

export default class OsField extends Component {
  renderField() {
    switch (this.props.osType || this.props.type) {
      case 'checkbox':
        return <OsCheckboxField {...this.props} />;
      case 'contenteditable':
        return <OsContentEditableField {...this.props} />;
      case 'country_code_select':
        return <OsCountryCodeSelect {...this.props} />;
      case 'date':
        return <OsDateField {...this.props} />;
      case 'newDate':
        return <OsReactDateField {...this.props} />;
      case 'error':
        return (
          <OsErrorField
            {...pick(this.props.meta, 'error', 'touched', 'submitError')}
          />
        );
      case 'formatted_input':
        return <OsNumberFormat {...this.props} />;
      case 'hidden':
        return <OsHiddenField {...this.props} />;
      case 'input':
        return <OsInputField {...this.props} />;
      case 'label':
        return (
          <OsLabel
            htmlFor={this.props.input.name}
            label={this.props.label}
            required={this.props.required}
            className={this.props.labelClassName}
          />
        );
      case 'os-editor':
        return <OsEditorField {...this.props} />;
      case 'password':
        return <OsPasswordField {...this.props} />;
      case 'selectInput':
        return <OsSelectInputField {...this.props} />;
      case 'textarea':
        return <OsTextAreaField {...this.props} />;
      case 'tiptap-editor':
        return <OsTiptapEditorField {...this.props} />;
      case 'fileUpload':
        return <OsFileUploadField {...this.props} />;
      default:
        return null;
    }
  }

  renderFieldWithWrapper() {
    return (
      <div className={`form-group ${this.props.formGroupExtraClass}`}>
        {this.renderField()}
      </div>
    );
  }

  render() {
    return this.props.isWrapperRequired
      ? this.renderFieldWithWrapper()
      : this.renderField();
  }
}

OsField.defaultProps = {
  formGroupExtraClass: '',
  isWrapperRequired: true,
};
