import { TEAM_NAV_TABS } from 'app/components/TeamNavBar/constant';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export function getTeamNavTabs(source) {
  return TEAM_NAV_TABS[source] || [];
}

export function sourcePathSlug(source) {
  switch (source) {
    case 'teamSpaceDirectMessenger':
      return 'dm';
    case 'teamSubSpaceMessenger':
      return 'space';
    default:
      return '';
  }
}

export function activeTeamNavTab(source, filter = '') {
  const navTabs = getTeamNavTabs(source);
  return navTabs.find((tab) => tab.path === filter) || navTabs[0];
}

export function getTeamNavTabLink(source, sourceId) {
  return withWorkspaceIdentifier(`/team/${sourcePathSlug(source)}/${sourceId}`);
}

export function shouldShowTeamNavTab(device) {
  return device.ipadPortrait || device.width < 1440;
}
