import { LOCK_NAV, UNLOCK_NAV } from "app/actions/types";

const lockNavbar = (state = { lockNav: false }, action) => {
  switch (action.type) {
    case LOCK_NAV: return { ...state, lockNav: true }
    case UNLOCK_NAV: return { ...state, lockNav: false }
    default: return state;
  }
}

export default lockNavbar