import React from 'react';
import { connect } from 'react-redux';

import AbstractDropdown from './AbstractDropdown';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Follow from 'app/components/Shared/Follow';

import { isFollowRequired } from 'app/utils/featureHelper';
import { isSpaceArchived } from 'app/utils/spaceHelper';

class WorkspaceDropdown extends AbstractDropdown {
  getExtraClassForFollowButton() {
    let mobile = this.props.device.mobileDevice,
      extraClass = mobile ? 'd-block' : '';
    if (mobile) {
      extraClass += ' list-button ';
    }
    if (!mobile) {
      extraClass += ' v-align-middle';
    }

    return extraClass;
  }

  getSpace() {
    return this.props.space;
  }

  renderFollowButton() {
    let extraClass = this.getExtraClassForFollowButton();

    return (
      <Follow
        propagateClick={true}
        muteRequired={true}
        isNotFromDropdown={false}
        key={this.getSpace().followed_by_current_user ? 'unfollow' : 'follow'}
        extraClass={extraClass}
        type='board'
        obj={this.getSpace()}
        name='BtnIcon'
        labelRequired={true}
      />
    );
  }

  renderCustomDropdown() {
    const space = this.getSpace();
    if (!isSpaceArchived(space)) {
      return (
        <CustomDropdown name='folder_actions'>
          {this.renderFollowButton()}
          <span></span>
        </CustomDropdown>
      );
    } else {
      return null;
    }
  }
}

WorkspaceDropdown = connect(({ device }) => ({ device }))(WorkspaceDropdown);
export default WorkspaceDropdown;
