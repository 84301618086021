import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

const MAX_SCALE = 4;

class ZoomableContent extends Component {
  state = {
    scale:
      this.props.device.mobileDevice || this.props.device.ipadPortrait ? 1 : 2,
  };

  zoomIn = () => {
    this.state.scale < MAX_SCALE &&
      this.setState({ scale: this.state.scale + 1 });
  };

  zoomOut = () => {
    this.state.scale > 1 && this.setState({ scale: this.state.scale - 1 });
  };

  renderZoomCTAView() {
    return (
      <>
        {this.props.children}
        <div className='zoom-ctas'>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text zoom-btn'
            icon='zoom-out'
            label='Zoom out'
            onClick={this.zoomOut}
          />
          <OsBtn
            name='BtnIcon'
            extraClass='no-text zoom-btn zoom-in'
            icon='zoom-in'
            label='Zoom in'
            onClick={this.zoomIn}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={`zoomable-content scale-${this.state.scale}`}>
        {this.renderZoomCTAView()}
      </div>
    );
  }
}

ZoomableContent = connect(({ device }) => ({ device }))(ZoomableContent);
export default ZoomableContent;
