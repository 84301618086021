import React from 'react';
import OsBtn from 'app/components/OsBtn';
import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import useTipTapEditorForMobile from 'app/components/BoardView/Editor/useTipTapEditorForMobile';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import ScheduleDropdown from 'app/components/Comments/ScheduleDropdown';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import CustomCalendar from 'app/components/Comments/CustomCalendar';

const EXTENSION_REQUIRED = [
  'StarterKit',
  'Underline',
  'CustomSubmit',
  'CustomMention',
  'NewHashtag',
];

function TipTapEditorForMobile(props) {
  const { editor, entity, hideEditor } = props;
  const {
    toggled,
    editorContent,
    files,
    objects,
    dropdownState,
    selectedTime,
    setFiles,
    setObjects,
    handleSubmit,
    handleContent,
    getPlaceholderText,
    getMentionGroups,
    handleDropdownState,
    selectCustomeTime,
    clearSelectedTime,
    handleDateChange,
    getSubmitButtonText,
  } = useTipTapEditorForMobile({
    editor,
    entity,
    hideEditor,
  });
  let isClinic = !isCareWorkspaceView();
  return (
    <div className='tiptap-editor-wrapper' key={toggled} id={'editor-box'}>
      <div className='tiptap-editor-with-button' id='tiptap-editor'>
        <OsTiptapEditorField
          extensions={EXTENSION_REQUIRED}
          rowId={'patient-space-editor-field'}
          initialContent={editorContent.current}
          textType={'html'}
          autofocus={true}
          placeholder={getPlaceholderText()}
          dragAndDrop={true}
          updateContent={handleContent}
          files={files}
          handleFiles={setFiles}
          objects={objects}
          handleObjects={setObjects}
          entity={entity}
          mentionGroups={getMentionGroups()}
          generalUsersRequired={true}
          isInternalNote={editor.type === 'team'}
        />
        <div className='time-pill-and-submit'>
          {selectedTime !== null && (
            <div className='time-pill'>
              <span>{selectedTime}</span>
              <img
                src={crossCircle}
                alt='crossCircle'
                width='16'
                height='16'
                onClick={clearSelectedTime}
              />
            </div>
          )}
          <div className='submit-btn-container' id='submit-btn'>
            <OsBtn
              type='button'
              name='BtnPrimary'
              extraClass={`web-view-btn btn-save-info ${
                isClinic ? 'clinic-design' : ''
              }`}
              text={getSubmitButtonText()}
              htmlTag='button'
              onClick={handleSubmit}
            />
            {isClinic && (
              <div
                className={`scheduler-btn ${
                  (dropdownState.schedule || dropdownState.calendar) &&
                  'dropdown-active'
                }`}
                onClick={() => handleDropdownState('schedule', true)}>
                <OrthoIcon name='calendar' dataHoverNotRequired={true} />
              </div>
            )}
            <div>
              {dropdownState.schedule && (
                <ScheduleDropdown
                  onSelect={selectCustomeTime}
                  closeModal={() => handleDropdownState('schedule', false)}
                  elementId='submit-btn'
                />
              )}
            </div>
            <div>
              {dropdownState.calendar && (
                <CustomCalendar
                  selectCustomDate={handleDateChange}
                  closeModal={() => handleDropdownState('calendar', false)}
                  elementId='submit-btn'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TipTapEditorForMobile;
