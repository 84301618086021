import InboxSection from 'app/components/Inbox/InboxSection';
import React, { useState } from 'react';
import RecordsView from 'app/components/RecordsView';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

const PatientsInboxComponent = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { workspace } = props;

  const updateUnreadCount = (count) => {
    setUnreadCount(count);
  };

  return (
    <>
      <div className='inbox-wrapper'>
        <div className='inbox'>
          <InboxSection
            title={'Unread • Patient Comments'}
            type={'my_patient_inbox'}
            hideSection={unreadCount === 0}
            markReadButton={true}>
            {(showBody) => {
              return (
                <RecordsView
                  type={'MyUnreadPatientComments'}
                  page={0}
                  perPage={10}
                  updateCount={updateUnreadCount}
                  queryType={'COMMENTS_ACTIVITY_LISTING'}
                  key={workspace?.data?.unread_patient_inbox_count}
                />
              );
            }}
          </InboxSection>
          <InboxSection title={'Previously Read'} expandable={unreadCount > 0}>
            {(showBody) => {
              return (
                <>
                  {showBody && (
                    <RecordsView
                      type={'MyReadPatientComments'}
                      page={0}
                      perPage={10}
                      updateCount={() => {}}
                      queryType={'COMMENTS_ACTIVITY_LISTING'}
                      key={workspace?.data?.unread_patient_inbox_count}
                    />
                  )}
                </>
              );
            }}
          </InboxSection>
        </div>
      </div>
    </>
  );
};

const PatientsInboxConnect = connect(({ workspace }) => ({ workspace }))(
  PatientsInboxComponent,
);
const PatientsInbox = withRouter(PatientsInboxConnect);
export default PatientsInbox;
