import React from 'react';
import Dropzone from 'react-dropzone';
import { withApollo } from '@apollo/client/react/hoc';

import AutoSearchSuggestInput from 'app/components/Shared/AutoSuggestSearchInput';
import DropzoneFileUploader from 'app/components/Shared/DropzoneFileUploader';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsLink from 'app/components/OsLink';
import OsGrid from 'app/components/OsGrid';
import RecordsView from 'app/components/RecordsView';
import Icon from 'app/components/Svg';

import { isBlob } from 'app/utils/fileHelper';
import { mapLocalFileWithAttachmentObject } from 'app/utils/entitiesHelper';
import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';

class FilesTab extends DropzoneFileUploader {
  constructor(props) {
    super(props);
    this.folderName = props.folderName;
    this.dropzoneSource = props.dropzoneSource;
    this.state = {
      searchText: '',
      showPreviousFiles: false,
      ...this.defaultState(),
      files: props.files,
    };
  }

  renderPreviousFilesButton() {
    let textVerb = this.state.showPreviousFiles ? 'Hide' : 'Show';
    return (
      <aside className='search-aside'>
        <OsBtn
          name='BtnSecondary'
          text={`${textVerb} Previous Uploads`}
          onClick={this.togglePreviousFilesVisibility}
          extraClass='with-border web-view-btn'
        />
      </aside>
    );
  }

  renderSearchBar() {
    return (
      <div className='object-search-block'>
        <AutoSearchSuggestInput
          clearSearchFilter={!this.state.showPreviousFiles}
          onSearchReset={this.resetSearch}
          className='autosuggest-search-container'
          placeholder='Search previously uploaded files'
          onChange={this.setSearchText}>
          {this.renderPreviousFilesButton()}
        </AutoSearchSuggestInput>
      </div>
    );
  }

  togglePreviousFilesVisibility = () => {
    this.setState({ showPreviousFiles: !this.state.showPreviousFiles });
  };

  setSearchText = (searchText) => {
    this.setState({ searchText, showPreviousFiles: true });
  };

  resetSearch = () => {
    this.setState({ searchText: '' });
  };

  isAttachmentUploaded(file) {
    return isBlob(file) || file.rejected;
  }

  fileStatusText(file) {
    if (this.state.attachmentsLoader[file.name]) {
      let percentage = this.state.progressMapper[file.name] || 0;
      return (
        <span className='file-uploading'>
          <OrthoIcon
            name='upload'
            dataHoverNotRequired={true}
            defaultClass='elem-rotate me-1'
          />
          {`${percentage}% uploaded`}
        </span>
      );
    } else if (file.rejected) {
      return (
        <span className='file-upload-error'>
          <OrthoIcon name='error' dataHoverNotRequired={true} /> Can't upload.
          Try again
        </span>
      );
    } else {
      return (
        <span className='file-upload-successfull'>
          <OrthoIcon name='doubleTick' dataHoverNotRequired={true} /> Ready to
          Add
        </span>
      );
    }
  }

  removeFile = (file) => {
    let { files } = this.state;
    files = files.filter((obj) => obj.id !== file.id);
    this.setState({ files }, this.props.onRemoveAttachment.bind(this, file.id));
  };

  getContextProps() {
    return { onRename: this.onRename };
  }

  renderFileCard(file) {
    let isFileUploaded = this.isAttachmentUploaded(file);
    return (
      <OsGrid identifier='AppointmentModal:CardCols'>
        <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
          <OsCards
            size={'extra-small'}
            obj={file}
            source='new-appointment-modal'
            onDeleteClick={this.removeFile}
          />
        </CARD_ACTION_CONTEXT.Provider>
        {isFileUploaded && (
          <div className='file-status'>{this.fileStatusText(file)}</div>
        )}
      </OsGrid>
    );
  }

  renderAttachment = (file) => {
    let isFileUploaded = this.isAttachmentUploaded(file),
      fileObject = isFileUploaded
        ? mapLocalFileWithAttachmentObject(file)
        : file;

    return this.renderFileCard(fileObject);
  };

  renderUploadedFiles() {
    return this.state.files.map(this.renderAttachment);
  }

  onCardClick = (file) => {
    let { files } = this.state;
    files = files.concat(file);
    this.setState({ files }, this.onFileAssetUpload.bind(this, file));
  };

  onRename = (newFile, oldFile) => {
    let { files } = this.state,
      fileIndex = this.state.files.findIndex((file) => file.id === oldFile.id);

    files[fileIndex] = { ...files[fileIndex], ...newFile };
    this.setState({ files });
  };

  renderPreviousFiles() {
    return (
      <RecordsView
        type='AllShareableFiles'
        queryType='USER_DETAIL'
        cardGridIdentifier={'AppointmentModal:CardCols'}
        perPage={12}
        windowNotRequired={true}
        cardProps={{
          onCardClick: this.onCardClick,
          size: 'extra-small',
          selectionMode: true,
        }}
        threshold={100}
        textQuery={this.state.searchText}
        recordsNotToBeIncludes={this.state.files.filter((file) => !!file.id)}
      />
    );
  }

  onDialogInputClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onFileAssetUpload = (file) => {
    this.props.onAddAttachment(file.id);
  };

  renderDropzone() {
    let dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ';
    dropzoneInputContainerClassName += this.state.dropzoneActive
      ? 'dropzone-drag-active'
      : '';

    return (
      <Dropzone
        disableClick
        className='modal-dropzone-area'
        onDrop={this.onDrop.bind(this)}
        onDragEnter={this.onDragEnter.bind(this)}
        onDragLeave={this.onDragLeave.bind(this)}
        ref={(node) => {
          this.dropzoneRef = node;
        }}
        onDropRejected={this.onDropRejected}>
        {(props) => (
          <div className='modal-dropzone-area'>
            <div className='dropzone-instruction d-flex align-items-center'>
              <div className='browse-image-col'>
                <Icon name='dropFiles' />
              </div>
              <span className='drag-text'>Drag and drop your image here</span>
              <OsLink
                className='a-link file-finder-btn'
                onClick={() => {
                  this.dropzoneRef.open();
                }}
                text='Upload'
              />
            </div>
            <div
              {...props.getRootProps({ onClick: this.onDialogInputClick })}
              className={dropzoneInputContainerClassName}>
              <input {...props.getInputProps()} />
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  renderDropzoneOrFiles() {
    return this.state.showPreviousFiles
      ? this.renderPreviousFiles()
      : this.renderDropzone();
  }

  render() {
    let containerClass = this.state.files.length ? 'top-visible' : '';

    return (
      <article className={`pt-file-card-wrap ${containerClass}`}>
        <div className='top-selected-card-row '>
          <div className='row '>{this.renderUploadedFiles()}</div>
        </div>
        <div className='row pt-search-block'>
          <div className='col-12'>{this.renderSearchBar()}</div>
        </div>
        <div className='prev-cards-block'>{this.renderDropzoneOrFiles()}</div>
      </article>
    );
  }
}

FilesTab.defaultProps = {
  onAddAttachment: () => {},
  onRemoveAttachment: () => {},
};
FilesTab = withApollo(FilesTab);
export default FilesTab;
