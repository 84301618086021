import React, { PureComponent } from 'react';
import OsBtn from 'app/components/OsBtn';
import { keys } from 'app/utils/osLodash';

const TYPE_ICON_MAPPER = {
  big: {
    icon: 'spaces',
    label: 'Large cards view',
  },
  'extra-small': {
    icon: 'tiny-grid',
    label: 'Tiny cards view',
  },
  thumbnail: {
    icon: 'case-gallery',
    label: 'Small cards view',
  },
  list: {
    icon: 'case-table',
    label: 'Extra small cards view',
  },
};

class CardGridViewSelector extends PureComponent {
  renderIcons() {
    return keys(TYPE_ICON_MAPPER).map(this.renderIcon);
  }

  renderIcon = (type) => {
    let extraClass = 'web-view-btn no-text px-16';
    extraClass += this.props.viewType === type ? ' active' : '';
    return (
      <OsBtn
        name='BtnIcon'
        key={type}
        extraClass={extraClass}
        onClick={this.props.onSelect.bind(this, type)}
        icon={TYPE_ICON_MAPPER[type].icon}
        label={TYPE_ICON_MAPPER[type].label}
      />
    );
  };

  render() {
    let { disabled } = this.props;
    return (
      <div className={`grid-btn-group ${disabled ? 'disabled' : ''}`}>
        {this.renderIcons()}
      </div>
    );
  }
}

CardGridViewSelector.defaultProps = {
  disabled: false,
};

export default CardGridViewSelector;
