import { PluginKey } from 'prosemirror-state';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';

const PATIENT_SUGGESTION_LISTING = queryLoader(
  'app/graphql/queries/PatientSuggestionListing.gql',
);

const patientSuggestion = {
  char: '+',
  pluginKey: new PluginKey('patient'),
  command: ({ editor, range, props }) => {
    const { handlePatient } = editor.options.editorProps;
    handlePatient(props.item);
    // remove the last character (which is the trigger character)
    editor.commands.deleteRange({ from: range.from, to: range.to });
  },
  startOfTheLine: false,
  decorationTag: 'span',
  decorationClass: 'free',
  items: async ({ query, editor }) => {
    const { data } = await client.query({
      query: PATIENT_SUGGESTION_LISTING,
      variables: {
        page: 0,
        perPage: 10,
        type: 'OnlyPatientsOfClinic',
        text_query: query,
        sortQuery: '',
        searchQuery: '',
      },
    });
    return {
      mention: data.records.results,
      entity: null,
    };
  },
  ...suggestionConfig,
};
export default patientSuggestion;
