import React, { PureComponent } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

class EmailLink extends PureComponent {
  render() {
    let { email, icon } = this.props;
    return (
      <div className='mailinfo'>
        {icon && <OrthoIcon name='mail' dataHoverNotRequired={true} />}
        <OsLink
          href={`mailto:${email}`}
          className='whtie-link'
          text={email}
          target='_blank'
        />
      </div>
    );
  }
}

EmailLink.defaultProps = {
  icon: false,
};

export default EmailLink;
