import React, { Component } from 'react';

import OverallTimeline from 'app/components/CaseView/OverallTimeline';
import Timeline from 'app/components/Timeline';

import { getModifiedVariantLogs } from 'app/utils/appointmentHelper';

export default class CaseTreatmentSection extends Component {
  isTreatmentDataUnavailable() {
    let kase = this.props.case;
    return (
      !kase.t1_age ||
      !kase.t2_age ||
      !kase.variant_logs ||
      kase.variant_logs.length === 0
    );
  }

  isInprogressAndNotUserDraft() {
    let kase = this.props.case;
    return kase.is_in_progress && kase.workflow_state !== 'user_draft';
  }

  renderOverallTimeline() {
    const {
      case: kase,
      firstApplianceAddedAppointment,
      lastApplianceRemovedAppointment,
    } = this.props;

    return (
      <OverallTimeline
        firstApplianceAddedAppointment={firstApplianceAddedAppointment}
        lastApplianceRemovedAppointment={lastApplianceRemovedAppointment}
        isInprogressAndNotUserDraft={this.isInprogressAndNotUserDraft()}
        t1Age={kase.t1_age}
        t2Age={kase.t2_age}
        live={kase.live}
      />
    );
  }

  renderApplianceDetails(kase) {
    return (
      <div className='timeline-right-section'>
        <Timeline
          kase={kase}
          key={kase.id}
          applianceLogs={getModifiedVariantLogs(kase)}
          t1Age={kase.t1_age}
          t2Age={kase.t2_age}
          groupedActions={this.props.groupedActions}
          groupedActionsByVariantLog={this.props.groupedActionsByVariantLog}
          equipmentAppliances={[]}
          softwareAppliances={[]}
          isUnavailable={this.isTreatmentDataUnavailable()}
          patientDob={kase.patient_dob}
          appointments={kase.appointments}
        />
      </div>
    );
  }

  render() {
    let kase = this.props.case,
      kaseclassName = 'case-treatment-right-section ',
      dataUnavailable = this.isTreatmentDataUnavailable();

    kaseclassName += dataUnavailable ? 'treatment-data-unavailable' : ' ';

    return (
      <div className={kaseclassName}>
        {!dataUnavailable && this.renderOverallTimeline()}
        {this.renderApplianceDetails(kase)}
      </div>
    );
  }
}
