import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  nice_url,
  category,
  categoryType,
  rightLabel,
  name,
  onClick,
  trackOnClick,
}) => {
  let onClickParams = {
      category: category,
      query_by: 'companies',
      id_query: nice_url,
      categoryType: categoryType,
    },
    link = `/companies/${nice_url}`;

  return (
    <SearchResult
      key={`Company:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>
          {rightLabel === 0 ? '-' : rightLabel}
        </span>
        {name}
      </div>
    </SearchResult>
  );
};
