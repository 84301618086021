import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/core';
import { NodeSelection } from 'prosemirror-state';

const Token = Mention.extend({
  name: 'tokenNode',
  priority: 1000,
  draggable: false,
  atom: true,
  addAttributes() {
    return {
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      focusToken:
        () =>
        async ({ tr, commands }) => {
          const { state } = this.editor;
          const { doc } = state;
          let mentionPositions = [];

          doc.descendants((node, pos) => {
            if (node.type.name === this.name) {
              mentionPositions.push(pos);
            }
          });
          const nextMentionPos = mentionPositions[0];
          if (nextMentionPos) {
            await this.editor.chain().focus().run();
            this.editor
              .chain()
              .setTextSelection(nextMentionPos + 1)
              .run();
          }
          return true;
        },
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      [
        'strong',
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ],
    ];
  },
  renderText({ node, pos, index, parent }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        const { state } = this.editor;
        const { doc, selection } = state;
        let mentionPositions = [];

        doc.descendants((node, pos) => {
          if (node.type.name === this.name) {
            mentionPositions.push(pos);
          }
        });

        // Find the next mention node
        const currentPos = selection.$from.pos;

        const nextMentionPos = mentionPositions.find((pos) => pos > currentPos);
        if (nextMentionPos) {
          this.editor
            .chain()
            .focus()
            .setTextSelection(nextMentionPos + 1)
            .run();
        }
        return true;
      },
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText('', pos, pos + node.nodeSize);

              return false;
            }
          });

          return isMention;
        }),
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },
});

export default Token;
