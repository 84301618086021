import { getAuthorizeObject } from 'app/utils/entitiesHelper';

export const NAV_LIST_ABILITIES = {
  account_settings: {
    invitations: getAuthorizeObject('create', 'invitation'),
  },
  company_detail: {
    case: getAuthorizeObject('index', 'case'),
    tools: getAuthorizeObject('index', 'tool'),
    user: getAuthorizeObject('index', 'user'),
  },
  space_edit: {
    company: getAuthorizeObject('index', 'company'),
    tool: getAuthorizeObject('index', 'tool'),
  },
  tools: {
    companies: getAuthorizeObject('index', 'company'),
    primaryCta: getAuthorizeObject('manage', 'toolbox', { guest: true }),
  },
  tool_detail: {
    related_products: getAuthorizeObject('index', 'tool', { guest: true }),
    users: getAuthorizeObject('index', 'user', { guest: true }),
  },
  user_detail: {
    cases: getAuthorizeObject('index', 'case'),
    notes: getAuthorizeObject('create', 'note'),
    posts: getAuthorizeObject('index', 'pulse'),
    spaces: getAuthorizeObject('index', 'board'),
  },
};
