import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';

class CopyLinkSection extends Component {
  state = {
    copiedLinkId: null,
  };

  onLinkCopy = (copiedLinkId) => {
    clearTimeout(this.clearCopyMessage);
    this.setState({ copiedLinkId });
    this.clearCopyMessage = setTimeout(() => {
      this.setState({ copiedLinkId: null });
    }, 1500);
  };

  linkCopied(link) {
    return link.id === this.state.copiedLinkId;
  }

  render() {
    let link = { id: this.props.link, url: this.props.link };
    if(!this.props.link) return null
    return (
      <>
        <label className='modal-info-helper-text m-0 copy-link-b'>
          Copy invite link
        </label>
        {this.linkCopied(link) && (
          <span className='tooltip-copy'>Copied to clipboard!</span>
        )}
        {!this.props.link && (
          <span className='loader-percentage'>
            <i className='fa fa-spinner fa-spin' />
          </span>
        )}
        <div className='d-flex align-items-center justify-content-between  mb-4 pb-2 position-relative '>
          {this.props.link && (
            <CopyToClipboard
              text={link.url}
              onCopy={this.onLinkCopy.bind(this, link.id)}>
              <OsBtn text={link.url} extraClass='link-url-copy'>
                <OrthoIcon name='additionallink' />
              </OsBtn>
            </CopyToClipboard>
          )}
          <CopyToClipboard
            text={link.url}
            onCopy={this.onLinkCopy.bind(this, link.id)}>
            <OsBtn
              name='BtnIcon'
              icon='link-copy'
              extraClass='no-text icon-note ms-3 '
            />
          </CopyToClipboard>
        </div>
      </>
    );
  }
}

export default CopyLinkSection;
