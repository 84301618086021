import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { closeDisableCareSpaceModal } from 'app/actions/disableCareSpaceModal';
import { translate } from 'app/actions/flashMessage.js';

import { isSharedWithParty } from 'app/utils/spaces/careHelper';
import {
  setActiveResourceAfterSharing,
  setActiveResourcePatient,
} from 'app/actions/activeResource';
import { openInfoModal } from 'app/actions/infoModal';

const DISABLE_CARE_SPACE_ACCESS = queryLoader(
  'app/graphql/mutations/Care/DisableAccess.gql',
);

class DisableAccessModal extends Component {
  state = {
    submitting: false,
  };

  closeModal = () => {
    this.reset();
    this.props.closeDisableCareSpaceModal();
  };

  isAlreadyShared() {
    return isSharedWithParty(this.props.space, { id: this.props.obj.user_id }, this.props.obj);
  }

  sendRequest = (attributes) => {
    this.setState({ submitting: true });
    let type = attributes.type || 'delete_contact';
    this.props.client
      .mutate({
        mutation: DISABLE_CARE_SPACE_ACCESS,
        variables: {
          careSpaceId: this.props.space.nice_id,
          personConnectionId: this.props.obj.person_connection_id,
          type: type,
        },
      })
      .then(({ data }) => {
        this.setState({ submitting: false });
        this.closeModal();

        if (data.disableCareSpaceAccess.success)
          this.props.openInfoModal('care_space', 'access_successfuly_revoked');
        if (type == 'delete_contact') {
          this.props.setActiveResourcePatient(
            data.disableCareSpaceAccess.entity.owner.patient,
          );
        } else {
          this.props.setActiveResourceAfterSharing(
            data.disableCareSpaceAccess.entity,
          );
        }
      });
  };

  renderFooter() {
    return (
      <div className='modal-footer p-0'>
        <div className='media-actions-buttons w-100 text-right m-0'>
          {this.renderActions()}
        </div>
      </div>
    );
  }

  renderNotSharedBody() {
    const options = { name: this.props.obj.name };
    return (
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: translate('CARE_SPACE_DELETE_CONTACT', options),
          }}
        />
      </div>
    );
  }

  renderBody() {
    return (
      <Form
        onSubmit={this.sendRequest}
        enableReinitialize={true}
        initialValues={{ type: 'delete_contact' }}
        render={({ handleSubmit, values, form }) => {
          this.formValues = values;
          this.form = form;
          this.reset = form.reset;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div className='modal-body-text'>
                  <div className='report-text'>
                    {translate('CARE_SPACE_WARNING_TEXT')}
                  </div>
                  {this.isAlreadyShared()
                    ? this.renderAlreadySharedBody()
                    : null}
                </div>
              </div>
              {this.renderFooter()}
            </form>
          );
        }}
      />
    );
  }

  renderAlreadySharedBody() {
    const options = { name: this.props.obj.name };
    return (
      <>
        <div>
          <label className='radio-label'>
            <span
              dangerouslySetInnerHTML={{
                __html: translate(
                  'CARE_SPACE_ONLY_REMOVE_COLLABORATOR',
                  options,
                ),
              }}
              className='mb-0'
            />
            <Field
              component={'input'}
              type='radio'
              className='custom-control-input form-control'
              name='type'
              id='only_collaborator'
              value='only_collaborator'
            />
            <OrthoIcon
              name='checkmark-round-default'
              defaultClass='checkmark-icon'
            />
            <OrthoIcon
              name='checkmark-radiobutton'
              defaultClass='checkmark-icon checked'
            />
          </label>
        </div>
        <div>
          <label className='radio-label mb-1'>
            <span
              dangerouslySetInnerHTML={{
                __html: translate('CARE_SPACE_DELETE_CONTACT', options),
              }}
              className='mb-0'
            />
            <Field
              component={'input'}
              type='radio'
              className='custom-control-input form-control'
              name='type'
              id='delete_contact'
              value='delete_contact'
            />
            <OrthoIcon
              name='checkmark-round-default'
              defaultClass='checkmark-icon'
            />
            <OrthoIcon
              name='checkmark-radiobutton'
              defaultClass='checkmark-icon checked'
            />
          </label>
        </div>
      </>
    );
  }

  getCancelText() {
    return this.isAlreadyShared() ? 'Cancel, Keep access' : 'Cancel';
  }

  getConfirmText() {
    return this.isAlreadyShared() ? 'Disable Access' : 'Remove as Contact';
  }

  renderActions() {
    let disabledClass = this.form.getState().values['type']
      ? 'web-view-btn '
      : 'disabled-only web-view-btn ';
    return (
      <>
        <OsBtn
          name='BtnSecondary'
          text={this.getCancelText()}
          onClick={this.closeModal}
          extraClass='web-view-btn with-border'
        />
        <OsBtn
          name='BtnPrimary'
          text={this.getConfirmText()}
          type='submit'
          htmlTag='button'
          extraClass={disabledClass}
          onClick={this.handleOnClick}
        />
      </>
    );
  }

  getHeading() {
    let { obj } = this.props;
    return this.isAlreadyShared()
      ? `Disable Access for ${obj.name}?`
      : `Remove ${obj.name} as Patient Contact?`;
  }

  renderContent() {
    return (
      <>
        <div className='modal-header border-0 p-0 mb-3'>
          <h4 className='modal-title' id='modalLabel'>
            {this.getHeading()}
          </h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-report-btn'
            icon='close'
            label={`Close modal`}
            onClick={this.closeModal}
          />
        </div>
        <div className='modal-body'>
          <div className='svg-image mb-3 text-center'>
            <Icon name='cannotDelete' />
          </div>
          {this.renderBody()}
        </div>
      </>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.disableCareSpaceModal.open}
        onHide={this.closeModal}
        animation={false}
        dialogClassName={'pulse-report-modal report-modal disable-access-modal'}
        backdropClassName='modal-backdrop-custom'>
        {this.renderContent()}
      </Modal>
    );
  }
}

DisableAccessModal = connect(
  ({ disableCareSpaceModal }) => ({ disableCareSpaceModal }),
  {
    closeDisableCareSpaceModal,
    openInfoModal,
    setActiveResourceAfterSharing,
    setActiveResourcePatient,
  },
)(DisableAccessModal);
DisableAccessModal = withApollo(DisableAccessModal);
export default DisableAccessModal;
