import OsBtn from 'app/components/OsBtn';
import { useSelector } from 'react-redux';

const TALLY_ID_HASH = {
  careSummary: 'mY5Ajz',
};
const AddSummaryButton = ({
  extraClass,
  text = 'Add Care Summary',
  type,
  spaceId,
  title = '',
}) => {
  const token = useSelector((state) => state.currentUser.token);
  const workspaceId = useSelector((state) => state.workspace.data?.identifier);

  return (
    <OsBtn
      name='BtnIcon'
      icon='add'
      text={text}
      title={title}
      extraClass={extraClass}
      data-tally-open={TALLY_ID_HASH[type]}
      data-tally-layout='modal'
      data-tally-width='700'
      data-tally-align-left='1'
      data-tally-auto-close='0'
      data-base_url={process.env.REACT_APP_API_HOSTNAME}
      data-token={token}
      data-carespace_id={spaceId}
      data-workspace_id={workspaceId}></OsBtn>
  );
};

export default AddSummaryButton;
