import { openInfoModal } from 'app/actions/infoModal';
import AttachmentPreview from 'app/components/AttachmentPreview';
import CustomCalendar from 'app/components/Comments/CustomCalendar';
import { predefinedTimes } from 'app/components/Comments/constant';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import OsGrid from 'app/components/OsGrid';
import ParseText from 'app/components/ParseText';
import Avatar from 'app/components/Shared/Avatar';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { ScheduleContext } from 'app/components/SpaceTabs/ScheduledPosts/ScheduleContext';
import useSchedulePost from 'app/hooks/useSchedulePost';
import {
  avatarAttributes,
  entityUrl,
  sortFilesAndGetAttachment,
} from 'app/utils/entitiesHelper';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const FILES_IN_SLIDER = ['image', 'video'];

const TIPTAP_EXTENSION_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'CustomSubmit',
  'Underline',
  'Link',
  'NewHashtag',
  'CustomMention',
];

function SchedulePost({ post, deleteScheduleMessage, updateScheduledMessage }) {
  const {
    device,
    date,
    isInternalNote,
    isEdit,
    postContent,
    templateFiles,
    templateObjects,
    isPostEditable,
    showEditor,
    hideEditor,
    setPostContent,
    setTemplateFiles,
    setTemplateObjects,
    handleContent,
    handleSubmit,
    dispatch,
    getLeftDays,
    getMentionGroups,
    rescheduleMessage,
    updatePost,
    deletePost,
  } = useSchedulePost({
    post,
    deleteScheduleMessage,
    updateScheduledMessage,
  });

  let value = {
    post,
    device,
    date,
    isInternalNote,
    isEdit,
    postContent,
    templateFiles,
    templateObjects,
    isPostEditable,
    showEditor,
    hideEditor,
    setPostContent,
    setTemplateFiles,
    setTemplateObjects,
    handleContent,
    handleSubmit,
    dispatch,
    getLeftDays,
    getMentionGroups,
    rescheduleMessage,
    updatePost,
    deletePost,
  };
  return (
    <ScheduleContext.Provider value={value}>
      <article className='schedule-wrapper' id={`schedule-feed-${post.id}`}>
        <ScheduleActivityBar />
        <SchedulePostBody />
      </article>
    </ScheduleContext.Provider>
  );
}

function ScheduleActivityBar() {
  const { post, date, isInternalNote } = useContext(ScheduleContext);
  const authorName = post.created_by.full_name;
  const authorUrl = entityUrl(post?.created_by);
  return (
    <div
      className={`schedule-activity-bar ${
        isInternalNote ? 'internal-class' : ''
      }`}>
      <div className='schedule-activity-bar-content'>
        <OrthoIcon name='comment-fill' />
        <span className='activity-text'>
          Scheduled by&nbsp;
          <Link to={authorUrl}>
            <span className='name'>{authorName}</span>
          </Link>
          &nbsp;for&nbsp;
          <span className='time'>{date}</span>
        </span>
      </div>
      {isInternalNote && (
        <div className='clinic-team-text'>
          <OrthoIcon
            defaultClass=' me-2'
            name='view'
            dataHoverNotRequired={true}
          />
          Clinic Team Only
        </div>
      )}
    </div>
  );
}

function SchedulePostBody() {
  const {
    post,
    isInternalNote,
    isEdit,
    hideEditor,
    templateFiles,
    setTemplateFiles,
    templateObjects,
    setTemplateObjects,
    postContent,
    handleContent,
    handleSubmit,
    getMentionGroups,
  } = useContext(ScheduleContext);

  let obj = {
    content: postContent,
    mentionees: post.mentionees,
    mention_groups: post.entity.mention_groups,
    __typename: 'SchedulePost',
  };

  return (
    <div className='schedule-post-body'>
      <div className='author-details'>
        <AuthorInfoSection />
        <RenderActionButtons />
      </div>
      <div className='post-body-content'>
        {isEdit ? (
          <div
            id='editor-box'
            className='schedule-tiptap-editor'
            style={{ width: 'inherit', position: 'relative' }}>
            <OsTiptapEditorField
              rowId={post.id}
              initialContent={postContent}
              textType={'html'}
              dragAndDrop={true}
              updateContent={handleContent}
              files={templateFiles}
              extensions={TIPTAP_EXTENSION_REQUIRED}
              handleFiles={setTemplateFiles}
              objects={templateObjects}
              handleObjects={setTemplateObjects}
              autofocus={true}
              entity={post.entity}
              mentionGroups={getMentionGroups()}
              generalUsersRequired={true}
              isInternalNote={isInternalNote}
              isSubmitButtonRequired={true}
              isCancelButtonRequired={true}
              cancelButtonProps={{
                onClick: hideEditor,
              }}
              submitButtonProps={{
                disabled: false,
                loading: false,
                onClick: handleSubmit,
                text: 'Save',
              }}
            />
          </div>
        ) : (
          <>
            <RenderSlider />
            <ParseText obj={obj} />
            <RenderAttachmentsAndEntities />
          </>
        )}
      </div>
    </div>
  );
}

function AuthorInfoSection() {
  const { post, getLeftDays } = useContext(ScheduleContext);

  return (
    <div className='author-info-section'>
      <Link to={entityUrl(post.created_by)} className='avatar-listing'>
        <Avatar
          className={`avatar avatar-40 comments-secondary-avatar`}
          {...avatarAttributes(post.created_by)}
        />
      </Link>
      <div className='author-info'>
        <span className='author-name'>{post.created_by.full_name}</span>
        <span className='scheduled-time'>{getLeftDays()}</span>
      </div>
    </div>
  );
}

const RenderActionButtons = () => {
  const { post, isPostEditable } = useContext(ScheduleContext);

  return (
    <div
      className='d-inline-block ms-auto a-link fs-12'
      id={`action-btn-${post.id}`}
      style={{ position: 'relative' }}>
      <CustomDropdown
        name='discussion_actions'
        className='comment-more position-relative'>
        {isPostEditable() && <EditButton />}
        <RescheduleButton />
        <DeleteButton />
      </CustomDropdown>
    </div>
  );
};

const EditButton = () => {
  const { showEditor } = useContext(ScheduleContext);

  return (
    <OsBtn
      key='editScheduledPost'
      name='BtnIcon'
      text='Edit'
      extraClass='list-button'
      icon='edit'
      onClick={showEditor}
    />
  );
};

const RescheduleButton = () => {
  const { post, device, rescheduleMessage } = useContext(ScheduleContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const setDropdown = (val) => {
    setShowDropdown(val);
  };

  const closeCalendarModal = () => {
    setShowCalendar(false);
  };

  const showCalendarModal = () => {
    setShowCalendar(true);
  };

  const handleDateChange = (date) => {
    closeCalendarModal();
    setDropdown(false);
    rescheduleMessage(date);
  };

  return (
    <div id='reschedule-btn'>
      <OsBtn
        key='rescheduleScheduledPost'
        name='BtnIcon'
        text='Reschedule'
        extraClass='list-button reschedule-btn'
        icon='calendar'
        isRightIcon={true}
        rightIcon='chevron-right'
        onMouseEnter={() => setDropdown(true)}
        onMouseLeave={() => setDropdown(false)}
      />
      {showDropdown && (
        <RenderScheduleOption
          device={device}
          setDropdown={setDropdown}
          showCalendarModal={showCalendarModal}
          selectCustomDate={handleDateChange}
        />
      )}
      {showCalendar && (
        <CustomCalendar
          selectCustomDate={handleDateChange}
          closeModal={closeCalendarModal}
          elementId={`action-btn-${post.id}`}
        />
      )}
    </div>
  );
};

const RenderScheduleOption = ({
  device,
  setDropdown,
  showCalendarModal,
  selectCustomDate,
}) => {
  const onSelect = (time) => {
    if (time === 'custom') {
      showCalendarModal();
      setDropdown(false);
    } else {
      selectCustomDate(time);
    }
  };

  return (
    <div
      // style={device.mobileDevice && { right: '0px' }}
      className='schedule-dropdown-container'
      onMouseEnter={() => setDropdown(true)}
      onMouseLeave={() => setDropdown(false)}>
      {predefinedTimes.map((time) => {
        if (time.label !== 'Custom') {
          return (
            <div
              key={time.label}
              className='time-block'
              onClick={() => onSelect(time.value)}>
              <span>{time.label}</span>
            </div>
          );
        } else {
          return (
            <div
              key={time.label}
              className='time-block custom'
              onClick={() => onSelect(time.value)}>
              <span>
                <OrthoIcon name='Calendar' dataHoverNotRequired={true} />
                {time.label}
              </span>
            </div>
          );
        }
      })}
    </div>
  );
};

const DeleteButton = () => {
  const { dispatch, deletePost } = useContext(ScheduleContext);
  return (
    <OsBtn
      key='deleteScheduledPost'
      name='BtnIcon'
      text='Cancel Post'
      extraClass='list-button'
      icon='delete'
      onClick={() =>
        dispatch(
          openInfoModal('care_space', 'delete_scheduled_post', {
            onSuccess: deletePost,
          }),
        )
      }
    />
  );
};
const RenderSlider = () => {
  const { post, device } = useContext(ScheduleContext);

  const sliderObjects = () => {
    return sortFilesAndGetAttachment(post.files).filter(
      (file) => file && FILES_IN_SLIDER.includes(file.kind),
    );
  };

  let withLessFeatures = true;
  let props = {
    objects: sliderObjects(),
    author: post.created_by,
    navigationSettings:
      withLessFeatures || device.mobileDevice
        ? { arrows: true, dots: false, dotsClass: 'pulse-slick-dots' }
        : {},
    subSliderNotRequired: withLessFeatures,
    containerClassName: withLessFeatures ? 'listing-slider' : 'detail-slider',
    source: 'schedule-feed',
  };
  return (
    <div className='comment-section'>
      <AttachmentPreview {...props} />
    </div>
  );
};

const RenderAttachmentsAndEntities = () => {
  return (
    <div className='order-mobile row card-row-pulse row-col-space-16'>
      <RenderAttachments />
      <RenderLinks />
    </div>
  );
};

const RenderAttachments = () => {
  const { post, templateFiles } = useContext(ScheduleContext);

  return (
    <>
      {templateFiles.map((file) => {
        let attachment = {
          ...file,
          author: post.created_by,
        };
        return <RenderOsCard attachment={attachment} />;
      })}
    </>
  );
};

const RenderLinks = () => {
  const { post, templateObjects } = useContext(ScheduleContext);
  return (
    <>
      {templateObjects.map((link) => {
        let attachment = {
          ...link,
          author: post.created_by,
        };
        return <RenderOsCard attachment={attachment} />;
      })}
    </>
  );
};

const RenderOsCard = ({ attachment }) => {
  return (
    <OsGrid
      identifier={'FeedCardColumn:Cardcols'}
      key={`${attachment.__typename}:${attachment.id}`}>
      <OsCards size={'extra-small'} obj={attachment} sliderPreview={true} />
    </OsGrid>
  );
};

export default SchedulePost;
