import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import immutableUpdate from 'immutability-helper';
import Loader from 'react-loaders';

import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';
import { TaskContext } from 'app/context/TaskContext';
import { openEmptyTaskModal } from 'app/actions/taskModal';
import { isCurrentDay, timeWithFormat } from 'app/utils/timeHelper';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { cloneDeep, isEmpty, sortBy } from 'app/utils/osLodash';
import EmptyState from 'app/components/Task/EmptyState';
import { currentUser } from 'app/utils/userHelper';
import { mergeWorkspaceData } from 'app/actions/workspace';
import { isTaskCommentActivity } from 'app/utils/taskHelper';

import 'app/components/Task/task-feed-view.scss';
import { PaywallContext } from 'app/context/PaywallContext';

const TASKFEED_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/Task/TaskUpdateSubscription.gql',
);

const TaskFeedView = (props) => {
  const dispatch = useDispatch();
  const { id: workspaceId } = useContext(WorkspaceContext);
  const { updateCache, refreshComponent } = useContext(TaskContext);
  const { setOpenPaywall, shouldOpenPaywall } = useContext(PaywallContext);

  useEffect(() => {
    let unsubscribe = () => {};
    if (workspaceId) {
      unsubscribe = props.subscribeToMore({
        document: TASKFEED_SUBSCRIPTION,
        variables: { workspaceId: workspaceId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { activity } = subscriptionData.data.task_feed_updated;
          if (isTaskCommentActivity(activity)) {
            let workspaceObj = activity.entities[0]?.workspace;
            if (workspaceObj?.__typename === 'Board')
              dispatch(mergeWorkspaceData({ ...workspaceObj }));
          }
          let updatedData = cloneDeep(prev);
          if (
            updatedData.records.results.some(
              (obj) =>
                getActivityObject(activity).id.toString() ===
                getActivityObject(obj).id.toString(),
            )
          ) {
            updatedData.records.results = updatedData.records.results.filter(
              (obj) =>
                getActivityObject(activity).id.toString() !==
                getActivityObject(obj).id.toString(),
            );
          }
          let payload = { records: {} };
          payload.records.results = { $unshift: [activity] };
          updatedData = immutableUpdate(updatedData, payload);
          return Object.assign({}, prev, updatedData);
        },
      });
    }
    return () => unsubscribe();
  }, [workspaceId]);

  const getActivityObject = (activity) => {
    if (!activity) {
      return null;
    }

    const object = activity.objects && activity.objects[0];
    const entity = activity.entities && activity.entities[0];

    if (object && object.__typename === 'Task') {
      return { ...object, parent_entity: entity };
    } else if (entity) {
      return entity;
    }

    return null;
  };

  const renderTask = (task, activities) => {
    return (
      <div id={`task-Id-${task.id}`} key={task.id}>
        <div className='task-wrapper'>
          <TaskPreview
            task={task}
            hideCloseBtn={true}
            showSeeMoreButton={true}
            loaderNotRequired={true}
            nextPageLoadRequired={false}
            activities={activities}
          />
        </div>
      </div>
    );
  };

  const isTimeSection = (activity) => {
    return activity.source_type === 'TaskTimeSection';
  };

  const taskWithSections = useMemo(() => {
    const taskActivitySections = [];
    let currentDate = null;
    const results = sortBy(props.results, (data) => -1 * data.visible_at);
    for (let i = 0; i < results.length; i++) {
      const activity = results[i];
      const formattedDate = timeWithFormat(
        activity.visible_at,
        'MM/DD/YYYY',
        true,
        {
          todayFormatRequired: true,
          dayNameNotRequired: true,
        },
      );
      if (formattedDate !== currentDate) {
        let suffix =
          !props.isTeamTaskFeed && isCurrentDay(activity.visible_at)
            ? ' in your Tasks'
            : '';

        let sectionData = {
          id: activity.visible_at,
          source_type: 'TaskTimeSection',
          template_text: `${formattedDate}${suffix}`,
        };
        taskActivitySections.push(sectionData);
        currentDate = formattedDate;
      }

      taskActivitySections.push(activity);
    }
    return taskActivitySections;
  }, [props.results]);

  const openNewTaskModal = () => {
    if (shouldOpenPaywall()) {
      setOpenPaywall(true);
    } else {
      dispatch(openEmptyTaskModal(updateCache, refreshComponent));
    }
  };

  const renderRow = (activity, index) => {
    if (isTimeSection(activity)) {
      return (
        <div className='task-time-section' key={activity?.id}>
          <div className='title task-wrapper'>{activity.template_text}</div>
          {index === 0 && (
            <div className='new-task-btn'>
              <button onClick={openNewTaskModal} className='openTaskBtn'>
                <span className='openTaskBtnText'>NEW TASK</span>
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return renderTask(
        getActivityObject(activity),
        activity.additional_entities,
      );
    }
  };

  if (props.loading) return <Loader type='ball-triangle-path' active />;
  return (
    <>
      {!props.loading && (
        <>
          {taskWithSections.length > 0 ? (
            <>{taskWithSections?.map(renderRow)}</>
          ) : (
            <EmptyState user={currentUser} />
          )}
        </>
      )}
    </>
  );
};

export default TaskFeedView;
