import {
  SET_HOVERED_APPLIANCE_LOG,
  UNSET_HOVERED_APPLIANCE_LOG,
} from 'app/actions/types';

export default function (state = { obj: null }, action) {
  switch (action.type) {
    case SET_HOVERED_APPLIANCE_LOG:
      return { obj: action.obj };
    case UNSET_HOVERED_APPLIANCE_LOG:
      return { obj: null };
    default:
      return state;
  }
}
