import React, { useEffect, useState } from "react";
import { loader as queryLoader } from "graphql.macro";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { lockNavbar, unlockNavbar } from "app/actions/lockNavbar";
import { SHOW_SUCCESS_MESSAGE } from "app/actions/types";
import { openInfoModal } from "app/actions/infoModal";

const UPDATE_PROFILE_PIN = queryLoader("app/graphql/QuickSwitcher/UpdateProfilePin.gql");

function useProfileResetForm(props) {

  const dispatch = useDispatch()
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const workspace = useSelector((state) => state.workspace);
  const { title } = props
  const [user, setUser] = useState(currentUser.graph);
  const [updateNewPin, { data: updatePinData }] = useMutation(UPDATE_PROFILE_PIN);


  const handleSubmit = (attributes) => {
    const pin = attributes.newPin0 + attributes.newPin1 + attributes.newPin2 + attributes.newPin3;
    let variables = {
      userId: user.id,
      password: pin,
      oldPassword: attributes.verifyPassword
    }

    updateNewPin({
      variables: variables,
    })
  }

  const changeUser = (user) => setUser(user);


  const formValidation = (values) => {
    const errors = {};
    let pin = values.newPin0 + values.newPin1 + values.newPin2 + values.newPin3;
    let confirmPin = values.confirmPin0 + values.confirmPin1 + values.confirmPin2 + values.confirmPin3;

    if (!values.newPin0 || !values.newPin1 || !values.newPin2 || !values.newPin3) {
      errors.newPin0 = "Required"
    }
    if (!values.confirmPin0 || !values.confirmPin1 || !values.confirmPin2 || !values.confirmPin3) {
      errors.confirmPin0 = "Required"
    }
    if (pin !== confirmPin && Object.keys(values).length !== 0) {
      errors.confirmPin0 = "confirm pin is incorrect."
    }

    if (!values.verifyPassword) {
      errors.verifyPassword = 'Required'
    }
    return errors
  }

  useEffect(() => {
    if (updatePinData !== undefined) {
      if (updatePinData.updateWorkspaceProfilePin.success === true) {
        navigate(-1)
        dispatch(unlockNavbar())
        dispatch({ type: SHOW_SUCCESS_MESSAGE, message: `Profile Pin ${title} Successfully.` })
      } else {
        let errorKey = updatePinData.updateWorkspaceProfilePin.message.split(' ').join('_').toLowerCase()
        dispatch(openInfoModal('general', errorKey))
      }
    }
  }, [updatePinData])

  useEffect(() => {
    if (params["*"].split("/")[1] === "profile" && params["*"].split("/")[2] === "reset_password") {
      dispatch(lockNavbar())
    }
  }, [params])

  return {
    title,
    workspace,
    handleSubmit,
    changeUser,
    formValidation,
    user
  }
}

export default useProfileResetForm;