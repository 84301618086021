import React from 'react'
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { useNavigate } from 'react-router-dom'
import OsBtn from 'app/components/OsBtn';

function LogoutButton() {
  const navigate = useNavigate()
  const logout = () => navigate('/logout')

  return (
    <div className="switcherLogoutBtn">
      <OsBtn
        key="logout"
        name="BtnGhost"
        text="LOGOUT"
        onClick={logout}
      />
    </div>
  )
}
LogoutButton = withRouter(LogoutButton);
export default LogoutButton