import { useMemo } from 'react';
import GroupListRow from 'app/components/SideBarTeamSpaceList/GroupListRow';
import { isOpenSpace } from 'app/utils/spaceHelper';
import SectionGroupList from 'app/components/SideBarTeamSpaceList/SectionGroupList';

const TeamSpaceActivityList = ({ section, activeResourceId }) => {
  const groupSection = useMemo(() => {
    const sections = {
      joined: [],
      availableToJoin: [],
      archived: [],
    };

    section.forEach((item) => {
      const alreadyJoined = item.is_author_or_collaborator;
      const isArchived = item.workflow_state === 'archived';
      if (alreadyJoined) {
        if (isArchived) {
          sections.archived.push(item);
        } else {
          sections.joined.push(item);
        }
      } else {
        // Can join this group
        const publicSpace = isOpenSpace(item);
        const pendingRequest = item.member_request_status === 'pending';
        if (publicSpace) {
          sections.availableToJoin.push(item);
        } else if (pendingRequest) {
          // Is private group, check if invited
          sections.availableToJoin.push(item);
        }
      }
    });
    return sections;
  }, [section]);

  return (
    <div className={'container-section'}>
      <ul className='group-space-list'>
        {groupSection.joined.map((item) => (
          <GroupListRow
            key={item.id}
            teamspace={item}
            activeResourceId={activeResourceId}
          />
        ))}
        <SectionGroupList
          title={'Available to join'}
          spaces={groupSection.availableToJoin}
          activeResourceId={activeResourceId}
        />
        <SectionGroupList
          title={'Archived'}
          spaces={groupSection.archived}
          activeResourceId={activeResourceId}
        />
      </ul>
    </div>
  );
};

export default TeamSpaceActivityList;
