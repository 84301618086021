import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

import OrthoS3 from 'app/vendors/react-s3/OrthoS3';
import OsBtn from 'app/components/OsBtn';
import RenameEntity from 'app/components/RenameEntity';
import VideoPlayer from 'app/components/VideoPlayer';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { decryptCredentials } from 'app/utils/decryptionHelper';
import { mapLocalFileWithAttachmentObject } from 'app/utils/entitiesHelper';
import { isAudio, isBlob } from 'app/utils/fileHelper';
import { getCurrentDateTime } from 'app/utils/timeHelper';
import MediaRecorder from 'app/components/Recorder/MediaRecorder';

const ADD_FILE_ASSET_MUTATION = queryLoader('app/graphql/AddFileAsset.gql');
const DELETE_FILE_ASSET_MUTATION = queryLoader(
  'app/graphql/DeleteFileAsset.gql',
);
const SECRET_CREDENTIALS_QUERY = queryLoader(
  'app/graphql/SecretCredentials.gql',
);

class VideoRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show,
      renameFormVisibile: false,
    };
  }

  uploadFileToS3(file) {
    this.props.client
      .query({
        query: SECRET_CREDENTIALS_QUERY,
      })
      .then(({ data }) => {
        if (this.props.isOnlyAudioRecording) {
          file.name = 'RecordedAudio-' + getCurrentDateTime() + '.mp4';
        } else {
          file.name = 'RecordedVideo-' + getCurrentDateTime() + '.mp4';
        }
        this.uploadVideo(data.secret_credentials, file);
      });
  }

  uploadVideo(credentials, file) {
    var folderName = this.props.currentUser.graph.nice_id;
    var config = this.decryptedKeys(credentials, folderName);
    file.recorded = true;
    this.setState({ recordedVideo: file });
    OrthoS3.uploadFile(file, config, this.getUploadCallbacks());
  }

  decryptedKeys(credentials, folderName) {
    return {
      bucketName: decryptCredentials(credentials.bucket_name),
      dirName: folderName,
      region: decryptCredentials(credentials.region),
      accessKeyId: decryptCredentials(credentials.access_key_id),
      secretAccessKey: decryptCredentials(credentials.secret_access_key),
      acl: this.aclType || 'private',
    };
  }

  uploadUserAsset(fileUrl, file) {
    this.props.client
      .mutate({
        mutation: ADD_FILE_ASSET_MUTATION,
        variables: {
          fileUrl,
          fileName: file.name,
          fileSize: file.size,
          entityRequired: true,
        },
      })
      .then(({ data }) => {
        if (data.addFileAsset.success) {
          let file = data.addFileAsset.entity;
          file.recorded = true;
          this.setState({ recordedVideo: file });
        }
      });
  }

  getUploadCallbacks() {
    return {
      onUploadProgress: this.onUploadProgress,
      onUploadComplete: this.onUploadComplete,
    };
  }

  onUploadProgress = (file, percentageCompleted) => {
    let progress = percentageCompleted.toFixed(0);
    this.setState({ progress });
  };

  onUploadComplete = (data, file) => {
    this.setState({ progress: null });
    this.uploadUserAsset(data.location, file);
  };

  onRecordingComplete = (file) => {
    this.uploadFileToS3(file);
  };

  renderVideoRecorder() {
    return (
      <MediaRecorder
        onRecordingComplete={this.onRecordingComplete}
        closeModal={this.closeModal}
        isOnlyAudioRecording={this.props.isOnlyAudioRecording}
      />
    );
  }

  closeModal = () => {
    this.setState({ recordedVideo: null, progress: null });
    this.props.closeModal();
  };

  deleteFileAsset = () => {
    this.props.client
      .mutate({
        mutation: DELETE_FILE_ASSET_MUTATION,
        variables: { id: this.state.recordedVideo.id },
      })
      .then(() => {
        this.setState({ renameFormVisibile: false });
        this.closeModal();
      });
  };

  onAttachClick = () => {
    this.props.onSuccess(this.state.recordedVideo);
    this.setState({ renameFormVisibile: false });
    this.closeModal();
  };

  setRenameFormVisibility = (renameFormVisibile) => {
    this.setState({ renameFormVisibile });
  };

  getRenameFormUniqueIdentifier() {
    let { recordedVideo } = this.state;
    return `${recordedVideo.__typename}-${recordedVideo.id}`;
  }

  renderEnityRenameForm() {
    return (
      <div
        className={
          'card-attachment-body' +
          (this.state.renameFormVisibile ? ' with-rename' : ' d-none')
        }>
        <div id={this.getRenameFormUniqueIdentifier()}></div>
      </div>
    );
  }

  renameFormOrButton() {
    let { recordedVideo } = this.state;
    return (
      <div className='col-md-8 m-0 p-0 video-msg-edit'>
        {!this.state.renameFormVisibile && (
          <span className='video-msg-name'>{recordedVideo.name}</span>
        )}
        <RenameEntity
          obj={recordedVideo}
          entityId={recordedVideo.id}
          entityType={recordedVideo.__typename}
          formNode={this.getRenameFormUniqueIdentifier()}
          onClick={this.setRenameFormVisibility}
          onRename={this.onRename}
          extraClass={`web-view-btn ${
            this.state.renameFormVisibile ? 'd-none' : ''
          }`}
        />
        {this.renderEnityRenameForm()}
      </div>
    );
  }

  renderActionButtons() {
    return (
      <div className='modal-footer mt-3 px-0'>
        {this.renameFormOrButton()}
        <div className='col-md-4 m-0 btn-grp p-0'>
          <OsBtn
            name='BtnIcon'
            text='Discard'
            onClick={this.deleteFileAsset}
            extraClass='web-view-btn px-16'
          />
          <OsBtn
            name='BtnPrimary'
            text='Attach'
            onClick={this.onAttachClick}
            extraClass='web-view-btn'
          />
        </div>
      </div>
    );
  }

  onRename = (newFile) => {
    newFile.recorded = true;
    let recordedVideo = { ...this.state.recordedVideo, ...newFile };
    this.setState({ recordedVideo });
  };

  renderContent() {
    let { recordedVideo, progress } = this.state;
    if (recordedVideo) {
      let file = isBlob(recordedVideo)
        ? mapLocalFileWithAttachmentObject(recordedVideo)
        : recordedVideo;
      if (progress) {
        return (
          <div className='record-video-uploading'>
            <span className='file-uploading'>
              <OrthoIcon
                name='update'
                dataHoverNotRequired={true}
                defaultClass='elem-rotate me-1'
              />
              {`${progress}% uploaded`}
            </span>
          </div>
        );
      } else if (file.id) {
        let videoProps = {
          url: file.preview_url,
          autoplay: true,
          poster: file.thumb_url,
          playIconRequired: true,
          audio: isAudio(file),
          unmuted: true,
        };
        return (
          <div className=''>
            <div className='slide-video-holder'>
              <VideoPlayer
                video={videoProps}
                hlsOptions={{ url: file.adaptive_streaming_preview_url }}
                key={file.id}
                loop={false}
              />
            </div>
            {this.renderActionButtons()}
          </div>
        );
      }
    } else {
      return this.renderVideoRecorder();
    }
  }

  getHeadingText() {
    if (this.props.isOnlyAudioRecording) {
      return this.state.recordedVideo?.id
        ? 'Preview your audio'
        : 'Record a personalized audio message';
    } else {
      return this.state.recordedVideo?.id
        ? 'Preview your video'
        : 'Record a personalized video message';
    }
  }

  render() {
    let headingText = this.getHeadingText();

    return (
      <Modal
        show={this.props.showModal}
        dialogClassName='modal-xl video-recorder-modal'
        animation={false}
        backdropClassName='modal-backdrop-custom'>
        <div className='modal-header'>
          <h4 className='card-title'>{headingText}</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-edit-image-btn'
            icon='close'
            label='Close user recoed a video message modal'
            onClick={this.closeModal}
          />
        </div>
        <div className='modal-body '>{this.renderContent()}</div>
      </Modal>
    );
  }
}

VideoRecord.defaultProps = {
  showModal: false,
};
VideoRecord = withApollo(VideoRecord);
VideoRecord = connect(({ currentUser }) => ({ currentUser }), {})(VideoRecord);
export default VideoRecord;
