import {
  SET_NAV_SEARCH_QUERY,
  SET_NAV_SEARCH_TOTAL,
  SET_NAV_SEARCH_CATEGORY,
  SET_NAV_SEARCH_OPEN,
  SET_NAV_SEARCH_CLOSE,
} from 'app/actions/types';

export default function navSearch(
  state = {
    query: '',
    category: 'cases',
    total: 0,
  },
  action,
) {
  switch (action.type) {
    case SET_NAV_SEARCH_QUERY:
      return { ...state, query: action.payload };

    case SET_NAV_SEARCH_TOTAL:
      return { ...state, total: action.payload };

    case SET_NAV_SEARCH_CATEGORY:
      return { ...state, category: action.payload };

    case SET_NAV_SEARCH_OPEN:
      return { ...state, showSearchContainer: action.payload };

    case SET_NAV_SEARCH_CLOSE:
      return { ...state, showSearchContainer: action.payload };
    default:
      return state;
  }
}
