import React, { Component } from 'react';

import CareSpaceCard from './CareSpaceCard';
import DefaultSpaceCard from './Default';
import FolderSpaceCard from './FolderSpaceCard';
import LockedCareSpace from './LockedCareSpace';
import UnavailableCard from '../UnavailableCard';

import {
  isCareSpace,
  isClinicSpace,
  isFolderSpace,
  isGroupSpace,
  isLockedSpace,
} from 'app/utils/spaceHelper';

class SpaceCards extends Component {
  render() {
    const { obj } = this.props;
    if (isFolderSpace(obj) || isGroupSpace(obj)) {
      if(obj.id) return <FolderSpaceCard {...this.props} />;
    } else if (isCareSpace(obj)) {
      if (obj.owner.patient) {
        if (isLockedSpace(obj)) {
          return <LockedCareSpace {...this.props} />;
        } else {
          return <CareSpaceCard {...this.props} />;
        }
      } else {
        return <UnavailableCard {...this.props} />;
      }
    } else {
      return <DefaultSpaceCard {...this.props} />;
    }
  }
}

export default SpaceCards;
