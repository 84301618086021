import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSpinCurrentIndex } from 'app/actions/spin';
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

class RangeInput extends Component {
  onInput = (e) => {
    this.props.setSpinCurrentIndex(e.target.value, this.props.videoType);
  };

  componentDidMount() {
    if (this.props.mountUpdateRequired && this.props.axisInfo.default) {
      this.props.setSpinCurrentIndex(
        this.props.axisInfo.default,
        this.props.videoType,
      );
    }
  }

  getValue() {
    let axisInfo = this.props.axisInfo;
    if (
      this.props.spin.currentIndex > axisInfo.max ||
      this.props.spin.currentIndex < axisInfo.min
    ) {
      return axisInfo.default;
    } else {
      return this.props.spin.currentIndex;
    }
  }

  handleChangeStart = () => {
    // console.log('Change event started')
  };

  handleChange = (value) => {
    this.props.setSpinCurrentIndex(value, this.props.videoType);
  };

  handleChangeComplete = () => {
    // console.log('Change event completed')
  };

  render() {
    let axisInfo = this.props.axisInfo;
    return (
      <div
        className={`slider slider-threesixty ${this.props.extraClass || ''}`}>
        <Slider
          min={axisInfo.min}
          max={axisInfo.max}
          value={this.getValue()}
          onChangeStart={this.handleChangeStart}
          onChange={this.handleChange}
          onChangeComplete={this.handleChangeComplete}
          orientation={this.props.orientation}
        />
      </div>
    );
  }
}

RangeInput = connect(({ spin }) => ({ spin }), { setSpinCurrentIndex })(
  RangeInput,
);

export default RangeInput;
