import { LOCK_NAV, UNLOCK_NAV } from "app/actions/types"

export function lockNavbar() {
  return dispatch => {
    dispatch({
      type: LOCK_NAV
    })
  }
}
export function unlockNavbar() {
  return dispatch => {
    dispatch({
      type: UNLOCK_NAV
    })
  }
}