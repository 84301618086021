import { OPEN_POLICIES_MODAL, CLOSE_POLICIES_MODAL } from 'app/actions/types';

export default function policiesModal(
  state = {
    open: false,
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_POLICIES_MODAL:
      return { open: true, options: action.options };
    case CLOSE_POLICIES_MODAL:
      return { open: false };

    default:
      return state;
  }
}
