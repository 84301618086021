import React from 'react';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import { getRGBValue } from 'app/utils/taskHelper';

function SmallLabel({ name, color, handleClick }) {
  return (
    <div
      className='appliedLabel'
      style={{ backgroundColor: `${getRGBValue(color)}` }}>
      {name}
      <img
        src={crossCircle}
        alt='crossCircle'
        width='16'
        height='16'
        onClick={() => handleClick()}
      />
    </div>
  );
}

export default SmallLabel;
