import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { fieldRequired } from 'app/utils/validationHelper';

import OsField from 'app/components/OsField';

class PostMessageField extends Component {
  getInlineSubmitProps() {
    return {
      className: 'post-message-button',
      disabled: this.props.submitting,
      label: 'send_message',
      iconName: this.props.iconName,
      iconDefaultClass: 'post-btn-helper-icon',
      btnType: 'BtnIcon',
    };
  }

  onClick = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <Form
        onSubmit={this.props.onSubmit}
        destroyOnUnmount={true}
        initialValues={this.props.initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              autoFocusRequired={this.props.autofocus}
              osType='textarea'
              textareaWrapperClass='pmb-conatiner'
              onClick={this.onClick}
              ref={(text) => (this.text = text)}
              className='form-control post-form-control'
              placeholder={this.props.placeholder}
              type='textarea'
              name={this.props.name}
              component={OsField}
              validate={fieldRequired}
              inlineSubmitProps={this.getInlineSubmitProps()}
            />
          </form>
        )}
      />
    );
  }
}

export default PostMessageField;

PostMessageField.defaultProps = {
  autofocus: false,
  placeholder: 'Write your reply here...',
  name: 'content',
  iconName: 'send',
  initialValues: {},
};
