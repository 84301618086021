import React, { Component } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import Loader from 'react-loaders';
import * as compose from 'lodash.flowright';

import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import Icon from 'app/components/Svg';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { actionByGuestUser } from 'app/actions/guestUser';
import { fieldRequired } from 'app/utils/validationHelper';
import { timeWithFormat } from 'app/utils/timeHelper';
import { entityUrl } from 'app/utils/entitiesHelper';
import { SHARED_SPACE_AUTHENTICATED } from 'app/components/BoardView/constants';

const VALIDATE_SPACE_SHARED_ACCESS = queryLoader(
  'app/graphql/mutations/ValidateSharedSpaceAccess.gql',
);
const SHARED_SPACE_QUERY = queryLoader(
  'app/graphql/queries/Spaces/SharedLinkDetails.gql',
);

class AuthenticateSharedSpace extends Component {
  state = {
    authenticated: false,
    error: null,
  };

  validateAccess = (attributes) => {
    const { id, token } = this.props.match.params;
    return this.props
      .validateSpaceSharedUrlAccess({
        variables: { id, token, password: attributes.password },
      })
      .then(({ data: { validateSharedSpaceAccess } }) => {
        if (validateSharedSpaceAccess.success) {
          this.onSuccess(attributes.password);
        } else {
          this.setState({ error: validateSharedSpaceAccess.error });
        }
      });
  };

  onSuccess = (password) => {
    const { token } = this.props.match.params;
    this.props.actionByGuestUser(SHARED_SPACE_AUTHENTICATED, {
      password,
      url: this.getSpacePath(),
      token,
    });
    this.setState({ authenticated: true });
  };

  onViewSpaceClick = () => {
    this.onSuccess();
  };

  passwordRequired() {
    return !this.props.data.sharedSpace.open_link;
  }

  renderPasswordFields() {
    let { sharedSpace } = this.props.data;

    if (sharedSpace.open_link) {
      return (
        <div className='form-action-row text-center'>
          <OsBtn
            name='BtnPrimary'
            text='View Space'
            extraClass='web-view-btn btn__login'
            onClick={this.onViewSpaceClick}
          />
        </div>
      );
    } else {
      return (
        <div className='password-form-body'>
          <div className='lh-1 semibold pb-2'>
            Enter the password to access this space.
          </div>
          <Form
            onSubmit={this.validateAccess}
            enableReinitialize={false}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className='reset-password-form'>
                <Field
                  name='password'
                  component={OsField}
                  osType='password'
                  label='Password'
                  validate={fieldRequired}
                  className={this.state.error ? 'error' : ''}
                  formGroupExtraClass='login_password'
                />
                <div className='form-error'>{this.state.error}</div>
                <div className='form-action-row text-center'>
                  <OsBtn
                    name='BtnPrimary'
                    text='Done'
                    type='submit'
                    htmlTag={'button'}
                    extraClass='web-view-btn btn__login'
                  />
                </div>
              </form>
            )}
          />
        </div>
      );
    }
  }

  getSpacePath() {
    return entityUrl(
      {
        __typename: 'Board',
        nice_id: this.props.match.params.id,
      },
      false,
      { skipWorkspaceIdentifer: true },
    );
  }

  alreadyLoggedIn() {
    return this.props.currentUser.graph;
  }

  renderContent() {
    let sharedSpace = this.props.data?.sharedSpace;
    if (sharedSpace) {
      return (
        <div>
          <div className='form-heading text-center'>
            You have been invited to view {sharedSpace.space.name}
          </div>
          <div
            className={this.passwordRequired() ? ' with-password-form' : ' '}>
            <div className='login-form-hint'>
              Through this link you will be able to view the contents of the
              space.&nbsp;
              <br />
              <br />
              Your access will expire on{' '}
              {timeWithFormat(sharedSpace.expired_at, 'MM/DD/YYYY')}
            </div>
            {this.renderPasswordFields()}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='form-heading text-center'>
            The link to this space is no longer active
          </div>

          <div className=''>
            <div className='login-form-hint'>
              Contact the sender of this link.
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { authenticated } = this.state;
    if (this.props.data && this.props.data.loading)
      return <Loader type='ball-triangle-path' active />;

    if (this.alreadyLoggedIn() || authenticated) {
      return <Navigate to={this.getSpacePath()} skip={true} />;
    }

    return (
      <ImageFormLayout
        leftBar={
          <Icon
            name='invite'
            width='480'
            height='100%'
            style={{ maxWidth: '100%' }}
          />
        }
        rightBar={this.renderContent()}
        source='shared_space'
      />
    );
  }
}

AuthenticateSharedSpace = compose(
  graphql(SHARED_SPACE_QUERY, {
    skip: (props) => !!props.currentUser.graph,
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        token: props.match.params.token,
      },
    }),
  }),
  graphql(VALIDATE_SPACE_SHARED_ACCESS, {
    name: 'validateSpaceSharedUrlAccess',
  }),
)(AuthenticateSharedSpace);
AuthenticateSharedSpace = withRouter(AuthenticateSharedSpace);
AuthenticateSharedSpace = connect(({ currentUser }) => ({ currentUser }), {
  actionByGuestUser,
})(AuthenticateSharedSpace);
export default AuthenticateSharedSpace;
