import TaskListView from 'app/components/Task/TaskListView';
import { TaskContext } from 'app/context/TaskContext';
import React, { useContext } from 'react';

function UpcomingSection() {
  const {
    nextWeekactiveTasks,
    dueInTwoWeekactiveTasks,
    dueInThreeWeekactiveTasks,
  } = useContext(TaskContext);

  const getWeekTasksInList = (weekTasks) => {
    let tasksInList = [];
    for (let i = 0; i < weekTasks.length; i++) {
      let dayTasks = weekTasks[i];
      for (let j = 0; j < dayTasks.records.length; j++) {
        tasksInList.push(dayTasks.records[j]);
      }
    }

    return tasksInList;
  };

  return (
    <div>
      <TaskListView
        idType={'User'}
        totalRecords={0}
        results={getWeekTasksInList(nextWeekactiveTasks?.kanban_tasks || [])}
        listType={'NextWeekActiveTasks'}
        sectionTitle={'Due Next Week'}
        details={{}}
        loadMore={() => {}}
        isListOpen={true}
      />

      <TaskListView
        idType={'User'}
        totalRecords={0}
        results={getWeekTasksInList(
          dueInTwoWeekactiveTasks?.kanban_tasks || [],
        )}
        listType={'SecondWeekActiveTasks'}
        sectionTitle={`Due in 2 weeks`}
        details={{}}
        loadMore={() => {}}
        isListOpen={true}
      />

      <TaskListView
        idType={'User'}
        totalRecords={0}
        results={getWeekTasksInList(
          dueInThreeWeekactiveTasks?.kanban_tasks || [],
        )}
        listType={'ThirdWeekActiveTasks'}
        sectionTitle={`Due in 3 weeks`}
        details={{}}
        loadMore={() => {}}
        isListOpen={true}
      />
    </div>
  );
}

export default UpcomingSection;
