import React from 'react';
import OsBtn from 'app/components/OsBtn/';

export default (props) => {
  const { style, onClick, setRef, parentDivClass, label } = props;
  return (
    <OsBtn
      name='BtnIcon'
      label={label}
      extraClass={parentDivClass || 'signup-colleague-next'}
      ref={(div) => setRef && setRef(div)}
      style={{ ...style }}
      onClick={onClick}
      icon='chevron-right'
    />
  );
};
