import React, { useState } from 'react';
import { LABELS_POSSIBLE_COLORS } from 'app/components/LabelsModal/constants';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import { keys } from 'app/utils/osLodash';
import OsBtn from 'app/components/OsBtn';
import { hexToRgb } from 'app/utils/colorHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

function LabelRow({
  listType,
  label,
  updateSelectedLabel,
  deleteSelectedLabel,
  isEdit = false,
  showSaveButton = false,
  reRenderList,
}) {
  const {
    id,
    name,
    color,
    tasks_count,
    patients_count,
    comments_count,
    sub_labels,
  } = label;

  const [edit, setEdit] = useState(isEdit);
  const [labelName, setLabelName] = useState(label.name);
  const [subLabelName, setSubLabelName] = useState('');
  const [labelOptions, setLabelOptions] = useState(sub_labels || []);
  const [labelColor, setLabelColor] = useState(label.color);
  const handleColorChange = (color) => {
    setLabelColor(color);
    if (id === -1) return;
    let label = {
      id: id,
      color: color,
      name: labelName.name,
      subLabels: labelOptions,
    };
    updateSelectedLabel(label, listType);
  };

  const handleChange = (e) => {
    if (e.target.value.slice(-1) === '/') return;
    setLabelName(e.target.value);
  };

  const addLabel = () => {
    if (labelName === '') return;
    let subLabels;
    if (
      subLabelName === '' ||
      labelOptions.some((l) => l.name === subLabelName)
    ) {
      subLabels = labelOptions;
    } else {
      subLabels = [...labelOptions, getSubLabelObject(subLabelName)];
    }
    let label = {
      id: id,
      color: labelColor,
      name: labelName,
      subLabels: subLabels,
    };
    setLabelOptions(subLabels);
    setSubLabelName('');
    updateSelectedLabel(label, listType);
    setEdit(false);
  };

  const getUsageCount = () => {
    switch (listType) {
      case 'task':
        return tasks_count;
      case 'partner':
        return comments_count;
      default:
        return patients_count;
    }
  };

  const getSubLabelObject = (name) => {
    return { id: Math.random(), name: name };
  };

  const onCancelClick = () => {
    if (label.id === -1) {
      deleteSelectedLabel(label, listType);
    } else {
      setEdit(false);
      reRenderList();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 188 || e.keyCode === 9) {
      if (
        labelOptions.some((l) => l.name === e.target.value) ||
        e.target.value === '' ||
        isLabelNameEmpty(e.target.value)
      )
        return;
      setLabelOptions([...labelOptions, getSubLabelObject(e.target.value)]);
      setSubLabelName('');
    }
  };

  const isLabelNameEmpty = (str) => {
    let lastChar = str[str.length - 1];
    if (lastChar === ',') return true;
    return false;
  };

  const deleteSubLabel = (subLabelId) => {
    setLabelOptions(labelOptions.filter((option) => option.id !== subLabelId));
  };

  // JSX part
  const renderLabelInput = () => {
    if (edit) {
      return (
        <form>
          <input
            className='label-field'
            autoFocus
            value={labelName}
            onChange={(e) => handleChange(e)}
            placeholder='Enter a Label Name'
            style={{ width: '100%', height: '100%', borderRadius: '8px' }}
          />
        </form>
      );
    } else {
      return (
        <div
          className='label-name'
          style={{ backgroundColor: hexToRgb(color) }}>
          {name}
        </div>
      );
    }
  };

  const renderLabelOptionInput = () => {
    return (
      <div className='label-options'>
        {labelOptions.map((option) => {
          return (
            <span
              key={option.id}
              className='option'
              style={{
                backgroundColor: hexToRgb(labelColor),
                display: 'flex',
                gap: '4px',
              }}>
              <span>{option.name}</span>
              {edit && (
                <OrthoIcon
                  name='minus-with-circle'
                  onClick={() => deleteSubLabel(option.id)}
                />
              )}
            </span>
          );
        })}
        {edit && (
          <>
            <input
              className='label-option-field'
              value={subLabelName}
              onChange={(e) => {
                if (isLabelNameEmpty(e.target.value)) return;
                setSubLabelName(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              placeholder='Enter Sub Label'
              style={{
                width: '25%',
                minWidth: '120px',
                height: '100%',
                borderRadius: '8px',
              }}
            />
          </>
        )}
      </div>
    );
  };

  const renderColor = (name) => {
    let color = LABELS_POSSIBLE_COLORS[name],
      selected = color === label.color;

    return (
      <div
        className='label-list-item'
        onClick={() => handleColorChange(color)}
        selected={selected}>
        {renderColorOption(name, color)}
      </div>
    );
  };

  const renderColorOption = (name, color) => {
    return (
      <>
        <span className='lb-dot' style={{ backgroundColor: hexToRgb(color) }} />
        {name}
      </>
    );
  };

  const findKeyByValue = () => {
    return Object.keys(LABELS_POSSIBLE_COLORS).find(
      (key) =>
        LABELS_POSSIBLE_COLORS[key] === labelColor.toString() ||
        LABELS_POSSIBLE_COLORS[key] === `#${labelColor}`,
    );
  };

  const getColorDropdownTitle = () => {
    let colorName = findKeyByValue();
    return (
      <span className='selected-color'>
        <span
          style={{
            width: '8px',
            height: '8px',
            background: hexToRgb(labelColor),
            borderRadius: '50%',
          }}></span>
        {colorName}
      </span>
    );
  };

  return (
    <div className='table-body-row' id={`label-${id}`}>
      <div className='label-info-container'>
        <div className='labels-name-wrapper'>{renderLabelInput()}</div>
        <div className='color-dropdown'>
          <CustomDropdown
            chevronIcon={'chevron-caret'}
            name='label_colors'
            dropdownInfo={{ title: getColorDropdownTitle() }}
            className='cs-dropdown mw-88 lb-dropdown cs-file-dropdown'
            flipTheDropdown={400} //flipTheDropdown prop takes a number in pixels to determine whether flipping the dropdown is necessary if there is not enough space at the bottom.
          >
            {keys(LABELS_POSSIBLE_COLORS).map(renderColor)}
          </CustomDropdown>
        </div>
        <div className='usage-count'>{getUsageCount()}</div>
        <div className='option-container'>{renderLabelOptionInput()}</div>
      </div>
      <div className='action-btn'>
        {edit ? (
          <>
            <OsBtn
              name='BtnPrimary'
              text='Save'
              extraClass={'web-view-btn'}
              htmlTag='button'
              onClick={addLabel}
            />
            <OsBtn
              name='BtnGhost'
              text='Cancel'
              extraClass={'web-view-btn'}
              htmlTag='button'
              onClick={onCancelClick}
            />
          </>
        ) : (
          <>
            <OsBtn
              name='BtnIcon'
              icon='edit'
              extraClass='no-text px-8 checkmark-icon'
              onClick={() => setEdit(true)}
            />
            <OsBtn
              name='BtnIcon'
              icon='delete'
              extraClass='no-text px-8 checkmark-icon'
              onClick={() => deleteSelectedLabel(label, listType)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default LabelRow;
