import React, { Component } from 'react';
import StoreUpdater from 'app/services/StoreUpdater';
import { isArray } from 'app/utils/osLodash';

const withInitialRecords = (WrappedComponent) => {
  class HOC extends Component {
    state = {
      recordsUpdated: false,
      updateInProgress: false,
      data: {},
    };

    requestMetaInfo() {
      const { parentEntity } = this.props,
        total =
          parentEntity?.__typename === 'Comment'
            ? parentEntity.replies_count
            : this.props.initialRecords.length;
      return {
        afterId: this.props.afterId || null,
        aroundId: this.props.aroundId || null,
        beforeId: this.props.beforeId || null,
        limit: this.props.initialLimit || this.props.limit,
        recordId: this.props.recordId,
        recordType: this.props.recordType,
        sortQuery: this.props.sortQuery,
        textQuery: this.props.textQuery,
        type: this.props.type,
        withRecord: this.props.withRecord,
        total,
        queryType: this.props.queryType,
      };
    }

    updateRecordsInStoreUpdater() {
      if (isArray(this.props.initialRecords)) {
        setTimeout(() => {
          try {
            this.setState({ updateInProgress: true });
            const { entity, initialRecords, parentEntity } = this.props;
            let data = StoreUpdater.setInitialDataInRecordPager(
              initialRecords,
              parentEntity || entity,
              this.requestMetaInfo(),
            );
            this.setState({
              updateInProgress: false,
              recordsUpdated: true,
              data,
            });
          } catch (e) {
          } finally {
            this.setState({ updateInProgress: false, recordsUpdated: true });
          }
        }, 1);
        return true;
      } else {
        return false;
      }
    }

    render() {
      if (this.state.recordsUpdated) {
        return <WrappedComponent {...this.props} fetchPolicy='cache-only' />;
      }
      if (this.state.updateInProgress || this.updateRecordsInStoreUpdater()) {
        return null;
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  }
  return HOC;
};

export default withInitialRecords;
