import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';

import RecordsView from 'app/components/RecordsView';
import withAuthorize from 'app/components/HOC/withAuthorize';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { parameterize } from 'app/utils/stringHelper';

import EventTracker from 'app/services/EventTracker';

import { translate } from 'app/actions/flashMessage';
import { CONVERSATIONS_BATCH_SIZE } from 'app/constants';
import NoChatMessage from 'app/components/TeamSpaceCommunicationsView/NoChatMessage';

// View to hold the conversations
const DirectMessageListComponent = (props) => {
  const [state, setState] = useState({
    query: '',
    isSearchFocussed: false,
    conversationsCount: null,
    inputActive: false,
    keyToggler: false,
  });
  const parentContainerRef = useRef(null);

  const [parentContainer, setparentContainer] = useState(null);

  useEffect(() => {
    setparentContainer(parentContainerRef.current);

    EventTracker.track('Navigate', { page: 'Messages' });
  }, []);

  const showNoChatMessage = () => {
    let key = state.query ? 'RESULTS_NOT_FOUND' : 'NO_MESSAGES',
      noSearchResultMessage = translate(key, { searchInput: state.query });

    if (props.device.mobileDevice) {
      return key === 'NO_MESSAGES' ? <NoChatMessage /> : noSearchResultMessage;
    } else if (key !== 'NO_MESSAGES') {
      return noSearchResultMessage;
    }
  };

  const renderNoResultsFound = () => {
    return <p className='text-center loading-font'>{showNoChatMessage()}</p>;
  };

  const setConversationsCount = (conversationsCount) => {
    setState({ conversationsCount });
  };

  const renderContent = () => {
    let { conversationsCount } = state;

    return (
      <div
        data-id={'dm'}
        className='spacelist-section'
        ref={parentContainerRef}>
        {conversationsCount === 0 && renderNoResultsFound()}
        <RecordsView
          type='ConversationsListing'
          key={parameterize(state.query) || 'ConversationsListing'}
          perPage={CONVERSATIONS_BATCH_SIZE.fullView}
          textQuery={state.query}
          windowNotRequired={true}
          source={props.source}
          threshold={100}
          size='full-view-listing'
          parentContainer={parentContainer}
          setCollectionTotal={setConversationsCount}
          collectionTotal={conversationsCount}
        />
      </div>
    );
  };

  return <>{renderContent()}</>;
};

const DirectMessageListApollo = withApollo(DirectMessageListComponent);
const DirectMessageListRoute = withRouter(DirectMessageListApollo);
const DirectMessageListAutorize = withAuthorize(DirectMessageListRoute);

const DirectMessageList = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(DirectMessageListAutorize);

export default DirectMessageList;
