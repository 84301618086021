import React from 'react';
import { useLocation } from 'react-router';

import OsLink from 'app/components/OsLink';
import 'app/components/SideBarFolderList/sidebar-folder-list.scss';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

const FolderListRow = ({ folder, activeResourceId }) => {
  const location = useLocation();
  const renderContent = (folder) => {
    return (
      <div className='ac-user-name'>
        <span className='text'>{folder?.name} </span>

        {folder.user_unread_notifications_count > 0 && (
          <span className='lt-count pull-right'>
            {folder.user_unread_notifications_count}
          </span>
        )}
      </div>
    );
  };

  const renderTeamSpaceImage = (teamspace) => {
    let { image } = teamspace;
    return (
      <>
        <img src={image} alt='' className='space-hero-image' />
      </>
    );
  };

  const getFolderLink = (nice_id) => {
    return withWorkspaceIdentifier(`/folders/${nice_id}`, true);
  };

  let className = folder.unseen_comments_present ? 'unread' : '';
  className += location.pathname.includes(folder.nice_id) ? ' active' : '';

  return (
    <li className={className} key={folder.id}>
      <OsLink to={getFolderLink(folder.nice_id)}>
        {renderTeamSpaceImage(folder)}
        {renderContent(folder)}
      </OsLink>
    </li>
  );
};

export default FolderListRow;
