import React, { Component } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import {
  POLICIES_URL_HASH,
  POLICY_LAST_MODIFIED_DATE_FORMAT,
} from 'app/constants';
import { POLICIES_KIND_HASH_MAPPER } from 'app/components/PoliciesView/constants';

import { timeWithFormat } from 'app/utils/timeHelper';
import { userDefaultPage } from 'app/utils/userRoleHelper';

import HTMLContentContainer from 'app/components/Shared/HTMLContentContainer';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';

import EventTracker from 'app/services/EventTracker';

const ACCEPT_SPACE_SUBSCRIBER_POLICY = queryLoader(
  'app/graphql/mutations/Spaces/AcceptSubscriberPolicy.gql',
);
const ACCEPT_POLICIES = queryLoader('app/graphql/AcceptPolicies.gql');
const CARE_POLICY = queryLoader(
  'app/graphql/Care/Queries/Users/CarePolicy.gql',
);
const PUBLIC_INSIGHT_POLICY = queryLoader(
  'app/graphql/PublicInsightPolicy.gql',
);
const ROLE_POLICIES = queryLoader('app/graphql/RolePolicies.gql');
const SPACE_SUBSCRIBER_POLICY = queryLoader(
  'app/graphql/queries/Spaces/SubscriberPolicy.gql',
);

class PoliciesView extends Component {
  state = {
    activePolicy: this.getPolicyType(),
    termsOfUseAccepted: false,
    privacyPolicyAccepted: false,
  };

  componentDidMount() {
    this.updatePolicyBasedOnHash();
    document.body.classList.add('policy-not-accepted-view');
    window.addEventListener('hashchange', this.updatePolicyBasedOnHash);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.loading &&
      !this.props.data.loading &&
      !this.props.forceToAccept &&
      this.props.data.role_policies
    ) {
      let { terms_of_use_accepted, privacy_policy_accepted } =
        this.props.data.role_policies;
      this.setState({
        termsOfUseAccepted: terms_of_use_accepted,
        privacyPolicyAccepted: privacy_policy_accepted,
      });

      if (terms_of_use_accepted && !privacy_policy_accepted) {
        this.updatePolicy('privacy_policy');
      } else if (!terms_of_use_accepted && privacy_policy_accepted) {
        this.updatePolicy('terms_of_use');
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('policy-not-accepted-view');
    window.removeEventListener('hashchange', this.updatePolicyBasedOnHash);
  }

  getPolicyType() {
    return (
      this.props.location.hash.replace('#', '') ||
      this.props.type ||
      'privacy_policy'
    );
  }

  sendAcceptPoliciesRequest() {
    return this.props.client.mutate({
      mutation: ACCEPT_POLICIES,
      variables: { kind: this.props.kind },
    });
  }

  sendSpaceSubscriberAcceptanceRequest() {
    return this.props.client.mutate({
      mutation: ACCEPT_SPACE_SUBSCRIBER_POLICY,
      variables: {
        id: this.props.entityId,
        documentId: this.getPrivacyPolicyObject().id,
      },
    });
  }

  sendAccpetanceRequest() {
    return this.isSpaceSubscriberPolicy()
      ? this.sendSpaceSubscriberAcceptanceRequest()
      : this.sendAcceptPoliciesRequest();
  }

  acceptPolicies = () => {
    this.toggleProcessing();
    this.sendAccpetanceRequest().then(({ data }) => {
      const error =
        data.acceptPolicies?.error || data.acceptSpaceSubscriberPolicy?.error;
      this.setState({ error });
      error ? this.toggleProcessing() : this.onSuccessOperations();
    });
  };

  onClickBackButton = () => {
    this.onClose(true);
    this.props.navigate(userDefaultPage(this.props.currentUser.graph));
  };

  onSuccessOperations() {
    this.updateURLHash();
    this.props.onSubmit && this.props.onSubmit();
  }

  toggleProcessing = () => {
    this.setState({ processing: !this.state.processing });
  };

  requestInProgress() {
    return this.state.processing || this.props.processing;
  }

  isSpaceSubscriberPolicy() {
    return this.props.kind === POLICIES_KIND_HASH_MAPPER['subscriber_policy'];
  }

  isCarePolicy() {
    return this.props.kind === POLICIES_KIND_HASH_MAPPER['care_policy'];
  }

  singlePolicyView() {
    return (
      this.props.onlyPublicInsight ||
      this.isSpaceSubscriberPolicy() ||
      this.isCarePolicy()
    );
  }

  singlePolicyHeading() {
    if (this.props.onlyPublicInsight || this.isSpaceSubscriberPolicy()) {
      return 'Terms of use';
    } else if (this.isCarePolicy()) {
      return 'Privacy Policy';
    }
  }

  updatePolicyBasedOnHash = () => {
    if (POLICIES_URL_HASH.includes(window.location.hash))
      this.updatePolicy(window.location.hash.replace('#', ''));
  };

  termsOfUseActive() {
    return this.state.activePolicy === 'terms_of_use';
  }

  privacyPolicyActive() {
    return this.state.activePolicy === 'privacy_policy';
  }

  onChangeTab = (policy) => {
    EventTracker.trackSelectTabEvent(policy);
    this.updatePolicy(policy);
  };

  updatePolicy = (policy) => {
    this.updateURLHash(policy);
    this.refs.policyModifiedDate &&
      this.refs.policyModifiedDate.scrollIntoView();
    this.setState({ activePolicy: policy });
  };

  renderTabs() {
    return this.singlePolicyView()
      ? this.renderSingleTab()
      : this.renderBothTabs();
  }

  renderBothTabs() {
    return (
      <ul className='modal-policies-tabs-list'>
        <li
          className={
            'pl-tab-item ' + (this.privacyPolicyActive() ? 'active-item' : '')
          }
          onClick={(e) => this.onChangeTab('privacy_policy')}>
          <span className='pl-tab-link'>Privacy policy</span>
        </li>
        <li
          className={
            'pl-tab-item ' + (this.termsOfUseActive() ? 'active-item' : '')
          }
          onClick={(e) => this.onChangeTab('terms_of_use')}>
          <span className='pl-tab-link'>Terms of use</span>
        </li>
      </ul>
    );
  }

  renderSingleTab() {
    return (
      <ul className='modal-policies-tabs-list '>
        <li className={'pl-tab-item active-item d-none'}>
          <Link to='#privacy_policy' className='pl-tab-link'>
            {this.singlePolicyHeading()}
          </Link>
        </li>
      </ul>
    );
  }

  renderHeader() {
    if (this.singlePolicyView()) {
      return this.singlePolicyHeading();
    } else {
      return 'Policies and agreements';
    }
  }

  headerAndTabs() {
    return (
      <div key='headerAndTabs' className='header-tab-combo'>
        <div className='modal-header border-0'>
          <h4 className='modal-title' id='modalLabel'>
            {this.renderHeader()}
          </h4>

          {!this.props.hideCloseLink && (
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn rel-pos close-policy-modal'
              icon='close'
              label='Close policies and agreements'
              onClick={this.onClose}
              htmlTag='button'
            />
          )}

          {this.state.error && <div>{this.state.error}</div>}
        </div>
        {this.renderTabs()}
      </div>
    );
  }

  lastModifiedDate() {
    let role_policies = this.props.data.role_policies;
    return timeWithFormat(
      this.termsOfUseActive()
        ? role_policies?.terms_of_use?.updated_at
        : this.getPrivacyPolicyObject()?.updated_at,
      POLICY_LAST_MODIFIED_DATE_FORMAT,
    );
  }

  getPrivacyPolicyObject() {
    if (this.isSpaceSubscriberPolicy()) {
      return this.props.data.space.subscriber_policy;
    } else if (this.isCarePolicy()) {
      return this.props.data.role_policies.care_policy;
    } else if (this.props.data.role_policies) {
      let { privacy_policy, public_insight } = this.props.data.role_policies;
      if (this.props.onlyPublicInsight) {
        return public_insight;
      } else {
        return privacy_policy;
      }
    }
  }

  content() {
    let role_policies = this.props.data.role_policies;
    return (
      <article key='content' className='modal-body policy-scroll-content'>
        <div className='modified-info text-right pb-4' ref='policyModifiedDate'>
          Last modified:{' '}
          <span className='date-modified'>{this.lastModifiedDate()}</span>
        </div>
        <div className='content-update-area'>
          {this.privacyPolicyActive() && (
            <HTMLContentContainer
              content={this.getPrivacyPolicyObject()?.body}
            />
          )}
          {this.termsOfUseActive() && (
            <HTMLContentContainer content={role_policies?.terms_of_use.body} />
          )}
        </div>
      </article>
    );
  }

  onChange = (event) => {
    if (this.termsOfUseActive()) {
      this.setState({ termsOfUseAccepted: event.currentTarget.checked });
    } else {
      this.setState({ privacyPolicyAccepted: event.currentTarget.checked });
    }
  };

  policyAccepted() {
    return (
      this.singlePolicyView() ||
      (this.termsOfUseActive()
        ? this.state.termsOfUseAccepted
        : this.state.privacyPolicyAccepted)
    );
  }

  updateURLHash(policy) {
    let { pathname, search } = window.location,
      hash = policy ? '#' + policy : '';
    this.props.navigate(
      {
        pathname,
        search,
        hash,
      },
      {
        skip: true,
      },
    );
  }

  onClose = (forceClosed = false) => {
    this.updateURLHash();
    this.props.onClose && this.props.onClose(forceClosed);
  };

  onAccept = () => {
    if (this.singlePolicyView()) {
      this.acceptPolicies();
    } else if (
      this.state.termsOfUseAccepted &&
      this.state.privacyPolicyAccepted
    ) {
      this.props.forceToAccept
        ? this.onSuccessOperations()
        : this.acceptPolicies();
    } else if (this.termsOfUseActive()) {
      this.updatePolicy('privacy_policy');
    } else {
      this.updatePolicy('terms_of_use');
    }
  };

  acceptButtonText() {
    return this.singlePolicyView() ? 'I Accept' : 'Accept';
  }

  checkboxRequired() {
    return !this.singlePolicyView();
  }

  renderCheckbox() {
    return (
      <label className='modal-policy-label'>
        <input
          className='policy-checkbox'
          type='checkbox'
          checked={this.policyAccepted()}
          onChange={this.onChange}
        />
        <OrthoIcon name='checkbox-default' dataHoverNotRequired={true} />
        <OrthoIcon name='checkbox' dataHoverNotRequired={true} />
        {this.termsOfUseActive() && 'I agree with Terms of use'}
        {this.privacyPolicyActive() && 'I agree with Privacy policy'}
      </label>
    );
  }

  renderFooter() {
    const { goBackRequired } = this.props;
    return (
      <section key='footer' className='modal-policy-footer'>
        <article className='d-flex justify-content-between align-items-center'>
          <div>{this.checkboxRequired() && this.renderCheckbox()}</div>

          <div>
            {goBackRequired && (
              <OsBtn
                name='BtnIcon'
                extraClass='px-16'
                text='Go Back'
                onClick={this.onClickBackButton}
              />
            )}
            <OsBtn
              name='BtnPrimary'
              text={this.acceptButtonText()}
              extraClass=''
              disabled={!this.policyAccepted() || this.requestInProgress()}
              onClick={this.onAccept}
            />
          </div>
        </article>
      </section>
    );
  }

  renderContent() {
    return [
      this.headerAndTabs(),
      this.content(),
      !this.props.hideFooter && this.renderFooter(),
    ];
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={this.onClose}
        dialogClassName='policies-modal'
        animation={true}
        backdrop='static'
        backdropClassName='modal-backdrop-custom'>
        {this.props.data.loading && <Loader type='ball-triangle-path' active />}
        {!this.props.data.loading && this.renderContent()}
      </Modal>
    );
  }
}

PoliciesView.defaultProps = {
  kind: '',
};

PoliciesView = compose(
  graphql(ROLE_POLICIES, {
    skip: (props) =>
      props.onlyPublicInsight ||
      props.kind === POLICIES_KIND_HASH_MAPPER['subscriber_policy'] ||
      props.kind === POLICIES_KIND_HASH_MAPPER['care_policy'],
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        role: props.role,
      },
    }),
  }),
  graphql(PUBLIC_INSIGHT_POLICY, {
    skip: (props) => !props.onlyPublicInsight,
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(SPACE_SUBSCRIBER_POLICY, {
    skip: (props) =>
      props.kind !== POLICIES_KIND_HASH_MAPPER['subscriber_policy'],
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.entityId,
      },
    }),
  }),
  graphql(CARE_POLICY, {
    skip: (props) => props.kind !== POLICIES_KIND_HASH_MAPPER['care_policy'],
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  }),
)(PoliciesView);
PoliciesView = connect(({ currentUser }) => ({ currentUser }))(PoliciesView);
PoliciesView = withRouter(PoliciesView);

PoliciesView = withApollo(PoliciesView);

export default PoliciesView;
