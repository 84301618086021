import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import {
  fieldRequired,
  composeValidators,
  validEmailOrPhoneNumberInput
} from 'app/utils/validationHelper';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import {
  sendForgotPassword,
} from 'app/actions/authentication';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      requestInProgress: false,
    };
    this.formSyncErrors = {};
    this.emailOrPhoneNumber = '';
  }

  isEmailFieldValid() {
    return !this.formSyncErrors.emailOrPhoneNumber;
  }

  sendForgotPassword = () => {
    if (this.emailOrPhoneNumber && this.isEmailFieldValid()) {
      this.props.sendForgotPassword(this.emailOrPhoneNumber);
    } else {
      this.emailRef.focus();
    }
  };

  renderEmailFieldError() {
    if (this.state.emailError && this.isEmailFieldValid()) {
      return (
        <div className='form-error email-error'>
          <OrthoIcon name='error' defaultClass='pe-1 ms-3 ' />
          {this.state.emailError}
        </div>
      );
    }
  }

  renderCredentialsForm() {
    return (
      <Form
        onSubmit={this.sendForgotPassword}
        render={(props) => {
          this.emailOrPhoneNumber = props.values.emailOrPhoneNumber;
          this.formSyncErrors = props.errors;
          return (
            <form name='login' onSubmit={props.handleSubmit}>
              <div className='text-center form-heading login-form-heading'>
                Reset your Password
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Field
                    name='emailOrPhoneNumber'
                    type='text'
                    osType='input'
                    component={OsField}
                    label='Email or Phone Number'
                    saveInputRef={(ref) => (this.emailRef = ref)}
                    validate={composeValidators(
                      fieldRequired,
                      validEmailOrPhoneNumberInput,
                    )}
                    formGroupExtraClass='login-email-group'
                    labelClassName='mnd-label'
                    className={this.state.emailError ? 'error' : ''}
                  />
                  {this.renderEmailFieldError()}
                  <div className='login-form-hint pt-2'>
                    Please enter your email or phone number and we will send you a password reset email and sms if your account is found.
                  </div>
                </div>
              </div>

              <div className='text-center'>
                <OsBtn
                  name='BtnPrimary'
                  htmlTag='button'
                  text='Reset Password'
                  extraClass='btn__login'
                  type='submit'
                  loaderRequired={this.state.requestInProgress}
                  disabled={this.state.requestInProgress}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }

  renderForm() {
    return this.renderCredentialsForm();
  }

  render() {
    if (this.props.currentUser.token) {
      let locationState = this.props.location.state,
        redirectPath = locationState ? locationState.from.pathname : '/';

      return <Navigate to={redirectPath} />;
    }

    return this.renderForm();
  }
}

ForgotPasswordForm = withRouter(ForgotPasswordForm);
ForgotPasswordForm = connect(
  ({ currentUser }) => ({
    currentUser,
  }),
  { sendForgotPassword },
)(ForgotPasswordForm);

export default ForgotPasswordForm;
