import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import { isEmpty } from 'app/utils/osLodash';
import OsField from 'app/components/OsField';
import { fieldRequired } from 'app/utils/validationHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import GroupModalFormButtons from 'app/components/GroupMessenger/GroupChatModal/GroupModalFormButtons';

const DESCRIPTION_PLACEHOLDER = 'Add a description';

const GroupCreateEditForm = ({
  type,
  closeModal,
  onSubmit,
  onGroupBannerChange,
  entity,
  isEditView,
  isSubmitDisabled,
  isSubmitting,
  groupFormData,
  teamSpaceModal,
  groupBannerUrl,
  uploadPercentage,
}) => {
  const [inviteUsers, setInviteUsers] = useState(false);
  const renderType = (categoryData) => {
    let isDisabled = false; // isPrivateSpace(teamSpaceModal.workspace_owner);
    const { name, value } = categoryData;

    return (
      <div key={value} className={value + '-space'}>
        <label
          className={
            'list-type-label d-flex ' +
            (isDisabled ? 'pointer-not-allowed' : '')
          }>
          <Field
            component='input'
            type='radio'
            name='category'
            validate={fieldRequired}
            id={value}
            value={value}
            defaultValue={'open'}
            disabled={isDisabled}
            className='radio-input-element'
          />
          <div className='radio-block'>
            {value === 'open' ? (
              <OrthoIcon name='members' dataHoverNotRequired={true} />
            ) : (
              <OrthoIcon name='locked' dataHoverNotRequired={true} />
            )}

            <span className='label-helper'>
              {value === 'open' ? 'Open' : name} Group
            </span>
          </div>
        </label>
      </div>
    );
  };

  const getDescription = () => {
    return (
      <>
        <p className={'description'}>Drag and drop your image here</p>
        <p className={'description'}>
          or <span>browse your files</span>
        </p>
        <p className={'description-limit'}>
          The size of the image file must not exceed 8MB.
        </p>
      </>
    );
  };

  const fileUploadStatusText = (file) => {
    const showProgress = uploadPercentage > 0;

    if (showProgress) {
      return (
        <div class='file-status'>
          {uploadPercentage < 100 ? (
            <span className='file-uploading'>
              <OrthoIcon
                name='update'
                dataHoverNotRequired={true}
                defaultClass='elem-rotate me-1'
              />
              {`${uploadPercentage}% uploaded`}
            </span>
          ) : (
            <span className='file-upload-successfull'>
              <OrthoIcon name='doubleTick' dataHoverNotRequired={true} />{' '}
              Successfully uploaded
            </span>
          )}
        </div>
      );
    }
  };
  const initialValues = { ...entity, initialInformation: entity.information };
  const showForm = teamSpaceModal?.canUpdateGroup
    ? teamSpaceModal.canUpdateGroup && !isEmpty(groupFormData)
    : true;
  return (
    <>
      {showForm && (
        <Form
          onSubmit={onSubmit}
          disableOnEnter={true}
          destroyOnUnregister={true}
          keepDirtyOnReinitialize={true}
          initialValues={initialValues}>
          {
            // Form
            ({ handleSubmit, values, form }) => {
              return (
                <form
                  className={'teamspace-modal-form'}
                  onSubmit={handleSubmit}>
                  <div className={'form-container'}>
                    {/* Title Field of the form */}

                    <div className='group-name'>
                      {values.image && (
                        <img
                          src={values.image}
                          alt=''
                          className='space-hero-image'
                        />
                      )}
                      <Field
                        name={'name'}
                        type={'text'}
                        component={OsField}
                        osType={'input'}
                        placeholder={'Group Name'}
                        validate={fieldRequired}
                        autoFocus={true}
                      />
                    </div>

                    {/* Description Field */}
                    <div className={'description-field'}>
                      <Field
                        name={'information'}
                        textType={'html'}
                        component={OsField}
                        osType={'tiptap-editor'}
                        initialContent={values.initialInformation || ''}
                        updateContent={(value) => {
                          form.change('information', value);
                        }}
                        placeholder={DESCRIPTION_PLACEHOLDER}
                      />
                    </div>
                    {type === 'new' && (
                      <div className={'space-type-list '}>
                        <label htmlFor={'space_type'}>Group Type</label>
                        <div className='radio-button-group'>
                          {groupFormData.space_categories?.map(renderType)}
                        </div>
                      </div>
                    )}
                    {type === 'new' && values.category === 'open' && (
                      <label className='group-archive-check-label'>
                        <input
                          className='archive-checkbox'
                          type='checkbox'
                          name='inheritCollaboratorsFromOwner'
                          checked={inviteUsers}
                          onChange={(e) => {
                            form.change(
                              'inheritCollaboratorsFromOwner',
                              e.target.checked,
                            );
                            setInviteUsers(e.target.checked);
                          }}
                        />
                        <OrthoIcon
                          name='checkbox-default'
                          dataHoverNotRequired={true}
                        />
                        <OrthoIcon
                          name='checkbox'
                          dataHoverNotRequired={true}
                        />
                        <span>
                          Invite New Team Members automatically to your Group
                        </span>
                      </label>
                    )}
                    <div className={'group-banner-field'}>
                      <Field
                        name={'image'}
                        osType={'fileUpload'}
                        label={'Group image'}
                        previewRequired={true}
                        src={groupBannerUrl}
                        required={false}
                        description={getDescription()}
                        onChange={onGroupBannerChange}
                        component={OsField}
                      />
                      {fileUploadStatusText()}
                    </div>
                    {/* Render Submit buttons */}
                    <GroupModalFormButtons
                      teamSpaceModal={teamSpaceModal}
                      initialValues={entity}
                      isSubmitting={isSubmitting}
                      isEditView={isEditView()}
                      user={initialValues?.user}
                      handleSubmit={handleSubmit}
                      closeModal={closeModal}
                      isDisabled={isSubmitDisabled}
                    />
                  </div>
                </form>
              );
            }
          }
        </Form>
      )}
    </>
  );
};

export default GroupCreateEditForm;
