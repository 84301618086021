export const TASK_ACTIVITY_BATCH_SIZE = 10;
export const TASK_FEED_BATCH_SIZE = 10;
export const VIEWED_TASK_BATCH_SIZE = 10;
export const NOTVIEWED_TASK_BATCH_SIZE = 10;
export const INPROGRESS_TASK_BATCH_SIZE = 50;
export const COMPLETED_TASK_BATCH_SIZE = 50;
export const UNSCHEDULED_TASK_BATCH_SIZE = 10;
export const OVERDUE_TASK_BATCH_SIZE = 10;
export const COMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE = 10;
export const NOTCOMPLETED_AND_ASSIGNED_TO_OTHER_TASK_BATCH_SIZE = 10;
