import React, { Component, useEffect, useRef } from 'react';

import { Picker } from 'emoji-mart';
import EmojiIcon from 'app/components/Svg/emoji';
import OsBtn from 'app/components/OsBtn';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { keys } from 'app/utils/osLodash';

import { EMOJI_IDENTIFIER_COMPONENT_HASH } from 'app/components/Svg/emoji';

export function EmojiPicker(props) {
  const ref = useRef();

  useEffect(() => {
    const data = async () => {
      const response = await fetch(
        'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
      );

      return response.json();
    };
    new Picker({ ...props, data, ref });
  }, []);

  return <div ref={ref} />;
}

class EmojiWrapper extends Component {
  state = {
    active: false,
    show: false,
  };
  additionalProps() {
    if (this.props.mobileDeviceOrIpad) {
      return {
        onClick: this.markActive,
      };
    }
  }

  markActive = () => {
    this.setState({ active: true });
  };

  markInActive = () => {
    this.setState({ active: false });
  };

  onEmojiSelect = ({ id }) => {
    (this.props.selectedReactions || []).includes(id)
      ? this.props.removeEmoji(id)
      : this.props.addEmoji(id);
  };

  renderEmoji = (name) => {
    return (
      <li
        className='emoji-icon-list'
        key={name}
        onClick={() => {
          this.setState({ active: false });
          this.updateReaction(name);
        }}>
        <EmojiIcon name={name} />
      </li>
    );
  };

  renderEmojis() {
    return keys(EMOJI_IDENTIFIER_COMPONENT_HASH).map(this.renderEmoji);
  }

  renderEmojiListWrapper() {
    let className = 'emoji-wrapper';
    return (
      <div className={className}>
        <div className='add-reaction-mob'>Add reaction</div>
        <ul className='list-unstyled'>
          <EmojiPicker
            set='native'
            previewPosition='none'
            onEmojiSelect={this.onEmojiSelect}
          />
        </ul>
      </div>
    );
  }

  onToggle = (show) => {
    this.setState({ show });
  };

  disableOverlay = () => {
    this.setState({ show: false });
  };

  render() {
    let extraClass = 'emoji-btn add-emoji-btn no-text';
    extraClass += this.state.active ? ' active' : '';
    return (
      <li className='emoji-btn-icons'>
        <OverlayTrigger
          trigger={['click', 'focus']}
          onToggle={this.onToggle}
          show={this.state.show}
          placement='auto'
          delay='250'
          overlay={
            <Popover className='emoji-wrapper-popover'>
              {this.renderEmojiListWrapper()}
            </Popover>
          }>
          <OsBtn
            name='BtnIcon'
            icon='add-emoji'
            text=''
            extraClass={extraClass}
            {...this.additionalProps()}
          />
        </OverlayTrigger>
        {this.state.show && (
          <div className='emoji-overlay' onClick={this.disableOverlay}></div>
        )}
      </li>
    );
  }
}

export default EmojiWrapper;
