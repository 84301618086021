import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';
import EventTracker from 'app/services/EventTracker';

import { avatarAttributes, getIconClass } from 'app/utils/entitiesHelper';
import {
  keysPressedNonAlphabeticNonNumeric,
  isTabClicked,
} from 'app/utils/mixpanelKeyboardHelper';
import { parseMentionees } from 'app/utils/textParseHelper';
import { debounce, differenceBy } from 'app/utils/osLodash';
import { isOfTypeSpace } from 'app/utils/spaceHelper';
import { getCareAvatarIconProps } from 'app/utils/spaces/careHelper';

const USERS_SEARCH_QUERY = queryLoader('app/graphql/UsersSearch.gql');
const USER_SEARCH_LIMIT = 7;
const SEARCH_TEXT_UPTO_USER_SEARCH_LIMIT_REQUIRED = 2;
const POPULAR_TOPICS_QUERY = queryLoader('app/graphql/PopularTopics.gql');

// NOTE: React mentions package forked locally to use this patch https://github.com/signavio/react-mentions/pull/477
// Current react mention version(v4.1.1)
// As soon as this patch is merged we can use the original package and remove this forked version
const {
  Mention,
  MentionsInput,
} = require('vendors/react-mentions/dist/react-mentions.cjs.js');

// For dynamic suggestion `mentionedSuggestions` are compulsory props.
class MentionableField extends Component {
  state = {
    value: !this.isOpenThread() && '',
    suggestions: [],
    keyPressed: [],
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value });
    }
  }

  isBroadcastMention(obj) {
    return this.props.mentionGroups.some(
      (group) => group.identifier === obj.id,
    );
  }

  isOpenThread() {
    const { participants = [] } = this.props;
    return participants.length === 0;
  }

  onOptionClick = (mentioneeId) => {
    this.moveCursorAtTheEnd();
    if (
      this.isOpenThread() &&
      this.props.onMentioneeAdd &&
      !this.isBroadcastMention({ id: mentioneeId })
    ) {
      let mentionee = this.state.suggestions.find(
        (suggestion) => +suggestion.id === +mentioneeId,
      );
      mentionee && this.props.onMentioneeAdd(mentionee);
    }
  };

  renderHashSuggestions = (
    hash,
    search,
    highlightedDisplay,
    index,
    focused,
  ) => {
    return (
      <li
        key={hash.id}
        className={`user-suggestion-listings-row ${focused ? 'focused' : ''}`}>
        {<span>{hash.name}</span>}
      </li>
    );
  };

  renderUserSuggestion = (user, search, highlightedDisplay, index, focused) => {
    let isBroadcastMention = this.isBroadcastMention(user);
    return (
      <li
        key={user.id}
        className={`user-suggestion-listings-row ${
          isBroadcastMention ? 'd-flex align-items-center' : ''
        } ${focused ? 'focused' : ''}`}>
        {!isBroadcastMention && (
          <Avatar
            className='avatar avatar-24 suggest-avatar'
            {...avatarAttributes(
              user,
              getCareAvatarIconProps(this.props.entity, user),
            )}
          />
        )}
        {isBroadcastMention && (
          <i className={`${getIconClass('loudspeaker')} mx-1 fs-16 `} />
        )}
        {
          <span
            className={`${isBroadcastMention ? 'mx-1 mention-all-m' : ''}`}>{`${
            isBroadcastMention ? '@' : ''
          }${user.display} ${
            isBroadcastMention ? user.info_message : ''
          }`}</span>
        }
      </li>
    );
  };

  onRemoveMention(mention) {
    this.props.onRemove && this.props.onRemove(mention);
  }

  isMentionRemoved(oldContent, newContent) {
    if (this.props.mentionedSuggestions) {
      let previousMentionees = parseMentionees(
          oldContent,
          this.props.mentionedSuggestions,
        ),
        newMentionees = parseMentionees(
          newContent,
          this.props.mentionedSuggestions,
        ),
        removedMentionees = differenceBy(previousMentionees, newMentionees);

      if (removedMentionees.length) {
        this.onRemoveMention(removedMentionees[0]);
      }
    }
  }

  handleChange = ({ target }) => {
    this.props.onChange && this.props.onChange(target.value);
    this.isMentionRemoved(this.state.value || '', target.value);
    this.setState({ value: target.value });
  };

  moveCursorAtTheEnd() {
    // Hack to move the cursor at the end of a text field
    setTimeout(() => {
      if (this.inputField)
        this.inputField.selectionStart = this.inputField.selectionEnd =
          this.getInputValue().length;
    }, 0);
  }

  setRef = (ref) => {
    this.inputField = ref;
    if (this.props.setRef) this.props.setRef(ref);
  };

  createUserSuggestionsData(suggestions) {
    return suggestions.map((user) => ({
      ...user,
      display: user.full_name || user.name,
    }));
  }

  createHashSuggestionsData(suggestions) {
    return suggestions.map((hash) => ({ ...hash, display: hash.name }));
  }

  addBroadCastSuggestions(suggestions, query) {
    let broadCastSuggestions = [],
      { mentionGroups } = this.props;
    if (mentionGroups)
      broadCastSuggestions.push(
        ...mentionGroups.map((group) => ({
          id: group.identifier,
          name: group.display_name,
          info_message: group.info_message,
        })),
      );
    broadCastSuggestions = broadCastSuggestions.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(query.toLowerCase()),
    );
    suggestions = broadCastSuggestions.concat(suggestions);
    return suggestions;
  }

  performHashSearch = debounce((query, callback) => {
    let entity = this.props.entity;
    return this.props.client
      .query({
        query: POPULAR_TOPICS_QUERY,
        variables: {
          topicsLimit: 5,
          entityId: entity && entity.id,
          entityType: entity && entity.__typename,
          search_query: query,
        },
      })
      .then(({ data }) => {
        let suggestions = data.popular_topics;
        this.setState({ suggestions });
        return this.createHashSuggestionsData(suggestions);
      })
      .then(callback);
  }, 200);

  performSearchRemote = debounce((query, callback) => {
    return this.fetchData(query)
      .then(({ data }) => {
        let suggestions = data.user_search
          ? data.user_search.filter(
              (obj) => obj.id !== this.props.currentUser.graph.id,
            )
          : [];
        suggestions = this.addBroadCastSuggestions(suggestions, query);
        this.setState({ suggestions });
        return this.createUserSuggestionsData(suggestions);
      })
      .then(callback);
  }, 200);

  performSearchLocal = (query, callback) => {
    const suggestions = this.props.participants.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(query.toLowerCase()),
    );
    this.setState({ suggestions });
    return callback(suggestions);
  };

  limitedGroupThread() {
    return this.props.participants && this.props.participants.length > 0;
  }

  oneOnOneThread() {
    return this.props.participants && this.props.participants.length === 1;
  }

  performSearch = (query, callback) => {
    switch (true) {
      case this.oneOnOneThread():
        return callback([]);
      case this.limitedGroupThread():
        return this.performSearchLocal(query, callback);
      default:
        this.performSearchRemote(query, callback);
    }
  };

  getHashSuggestions = (query, callback) => {
    if (query.startsWith(' ')) {
      return callback([]);
    }

    const { suggestions } = this.state;
    if (query.endsWith(' ') && suggestions && suggestions.length === 1) {
      this.mentionInput && this.mentionInput.selectFocused();
      return callback([]);
    }
    return this.performHashSearch(query, callback);
  };

  getSuggestions = (query, callback) => {
    if (query.startsWith(' ')) {
      return callback([]);
    }
    const { suggestions } = this.state;
    if (
      query.endsWith(' ') &&
      suggestions &&
      (suggestions.length === 1 || suggestions[0].name === query.trim())
    ) {
      this.mentionInput && this.mentionInput.selectFocused();
      return callback([]);
    }

    return this.performSearch(query, callback);
  };

  fetchData = (query) => {
    return this.props.client.query({
      query: USERS_SEARCH_QUERY,
      variables: {
        query: query,
        limit:
          query.length > SEARCH_TEXT_UPTO_USER_SEARCH_LIMIT_REQUIRED
            ? null
            : USER_SEARCH_LIMIT,
        generalUsersRequired: !!this.props.generalUsersRequired,
        filterBy: isOfTypeSpace(this.props.entity)
          ? this.props.entity?.nice_id
          : null,
      },
    });
  };

  onBlur = (e) => {
    EventTracker.trackInputBlurEvent(e.target.name, {
      charLength: e.target.value.length,
    });
    let { mobileDevice } = this.props.device;
    if (mobileDevice) window.$('html')[0].classList.remove('keyboard-open');
    this.props.onBlur && this.props.onBlur(e);
  };

  onFocus = (e) => {
    EventTracker.trackInputFocusEvent(e.target.name);
    this.props.onFocus && this.props.onFocus();
    this.moveCursorAtTheEnd();
    let { mobileDevice } = this.props.device;
    if (mobileDevice) window.$('html')[0].classList.add('keyboard-open');
  };

  getInputValue() {
    return this.state.value || this.props.value;
  }

  onKeyUp = (e) => {
    EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
    this.setState({ keyPressed: [] });
    this.props.onKeyUp && this.props.onKeyUp(e);
  };

  onKeyDown = (e) => {
    let key = e.key === 'Meta' ? 'cmd' : e.key,
      keyPressed = keysPressedNonAlphabeticNonNumeric(
        this.state.keyPressed,
        key,
      );
    this.setState({ keyPressed });

    if (isTabClicked(key)) {
      EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
      this.setState({ keyPressed: [] });
    }
    this.props.onKeyDown && this.props.onKeyDown(e, keyPressed);
  };

  displayTransform = (value) => {
    return `#${value}`;
  };

  render() {
    return (
      <>
        <MentionsInput
          value={this.getInputValue()}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
          autoFocus={this.props.autoFocus}
          inputRef={this.setRef}
          className={`mention-textarea-wrap ${this.props.className || ''}`}
          allowSuggestionsAboveCursor={true}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          allowSpaceInQuery={true}
          name='content'
          onKeyUp={this.onKeyUp}
          disabled={this.props.disabled}
          ref={(input) => (this.mentionInput = input)}>
          <Mention
            trigger={this.props.trigger}
            data={this.getSuggestions}
            appendSpaceOnAdd={true}
            renderSuggestion={this.renderUserSuggestion}
            markup='@[User:__id__],[{__display__}]'
            className='mention-tag'
            onAdd={this.onOptionClick}
          />
          {this.props.includeHashInMentions && (
            <Mention
              trigger={'#'}
              data={this.getHashSuggestions}
              appendSpaceOnAdd={true}
              renderSuggestion={this.renderHashSuggestions}
              markup='#{__display__}'
              className='mention-tag'
              onAdd={this.onOptionClick}
              displayTransform={this.displayTransform}
            />
          )}
        </MentionsInput>
      </>
    );
  }
}

MentionableField.defaultProps = {
  mentionGroups: [],
};

MentionableField = withApollo(MentionableField);
MentionableField = withRouter(MentionableField);
MentionableField = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {},
)(MentionableField);
export default MentionableField;
