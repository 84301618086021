import React, { PureComponent } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import {
  closeInfoModal,
  openInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';
import { openPatientModal } from 'app/actions/patientModal';

const SHARE_WITH_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithPatientAndResponseParties.gql',
);

class ShareWithPatientButton extends PureComponent {
  state = {
    requestInProgress: false,
  };

  shareWithPatient = (values) => {
    let persons = this.getPersonDetail(),
      getIds = [];
    persons.forEach((person) => {
      if (values[person.name + person.relation]) {
        getIds.push(person.id);
      }
    });
    this.setState({ requestInProgress: true }, () => {
      this.props.updateInfoModalOptions({
        primaryCtaRequestInProgress: this.state.requestInProgress,
      });
    });
    this.props
      .shareWithPatient({
        variables: {
          careSpaceId: this.props.objId,
          shareWithPersonIds: getIds,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        this.props.closeInfoModal();
        this.props.openInfoModal(
          'care_space',
          'shared_with_patient_successfully',
        );
      });
  };

  openEditPatientModal = () => {
    this.props.openPatientModal(this.props.objId, {
      source: this.props.source,
    });
  };

  getPersonDetail() {
    let person = [];
    let { patient } = this.props,
      connections = patient.person_connections;
    if (patient.email || patient.phone_number) {
      person.push({
        id: patient.id,
        name: patient.name,
        relation: 'Patient',
      });
    }
    connections.forEach((p) => {
      person.push({
        id: p.related_person.id,
        name: p.related_person.name,
        relation: p.relation,
      });
    });
    return person;
  }

  getInitialValues() {
    let persons = this.getPersonDetail();
    let initialValue = {};
    persons.forEach(
      (person) => (initialValue[person.name + person.relation] = true),
    );
    return initialValue;
  }

  openConfirmationModal = () => {
    if (this.props.dataVerificationRequired) {
      this.props.openInfoModal(
        'care_space',
        'shared_with_patient_confirmation_with_verification',
        {
          onSuccess: this.shareWithPatient,
          onSecondaryActionClick: this.openEditPatientModal,
          patientDetails: this.getPersonDetail(),
          initialValue: this.getInitialValues(),
          closeModalNotRequiredOnPrimaryClick: true,
        },
      );
    } else {
      let patients = this.getPersonDetail();
      if (patients.length === 0) {
        this.props.openInfoModal(
          'care_space',
          'shared_with_patient_confirmation',
          {
            onSuccess: this.shareWithPatient,
            closeModalNotRequiredOnPrimaryClick: true,
          },
        );
      }
      this.props.openInfoModal(
        'care_space',
        'shared_with_patient_confirmation_with_list',
        {
          onSuccess: this.shareWithPatient,
          patientDetails: this.getPersonDetail(),
          initialValue: this.getInitialValues(),
          closeModalNotRequiredOnPrimaryClick: true,
        },
      );
    }
  };

  getProps() {
    if (this.props.as === 'primary') {
      return {
        name: 'BtnPrimary',
        extraClass: 'share-wth-patient-btn',
      };
    } else {
      return {
        name: 'BtnIcon',
        icon: 'share',
      };
    }
  }

  getText() {
    if (this.props.person) {
      if (this.props.person.user_id) {
        return 'Reshare';
      } else {
        return 'Share with patient';
      }
    } else {
      return 'Share with patient';
    }
  }

  render() {
    if (this.props.accessible) {
      return (
        <OsBtn
          {...this.getProps()}
          text={this.getText()}
          onClick={this.openConfirmationModal}
        />
      );
    } else {
      return null;
    }
  }
}

ShareWithPatientButton = connect(null, {
  closeInfoModal,
  openInfoModal,
  openPatientModal,
  updateInfoModalOptions,
})(ShareWithPatientButton);

ShareWithPatientButton = graphql(SHARE_WITH_PATIENT_MUTATION, {
  name: 'shareWithPatient',
})(ShareWithPatientButton);
export default ShareWithPatientButton;
