import React from 'react';
import Dropzone from 'react-dropzone';
import { withApollo } from '@apollo/client/react/hoc';

import DropzoneFileUploader from 'app/components/Shared/DropzoneFileUploader';

import { each, filter, isEqual, last } from 'app/utils/osLodash';
import { cloneFileWithRandomName } from 'app/utils/fileHelper';

import ClosedAnteriorImage from 'app/images/appointment-placeholder-image/closed-anterior.png';
import FrontalRelaxedImage from 'app/images/appointment-placeholder-image/frontal-relaxed.png';
import FrontalSimilingImage from 'app/images/appointment-placeholder-image/frontal-smiling.png';
import PartialSimilingImage from 'app/images/appointment-placeholder-image/partial-smiling.png';
import LeftBuccalImage from 'app/images/appointment-placeholder-image/left-buccal.png';
import RightBuccalImage from 'app/images/appointment-placeholder-image/right-buccal.png';
import UpperOcclusalImage from 'app/images/appointment-placeholder-image/upper-occlusal.png';
import LowerOcclusalImage from 'app/images/appointment-placeholder-image/lower-occlusal.png';
import PanImage from 'app/images/appointment-placeholder-image/pan.png';
import CephImage from 'app/images/appointment-placeholder-image/ceph.png';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const IMAGES_TYPES_ALLOWED = '.jpg, .jpeg, .png';
const NON_LANDSCAPE_IMAGE_NAMES = [
  'Frontal relaxed',
  'Frontal smiling',
  '3/4 smiling',
];
const VALIDATIONS_REQUIRED = [
  { attribute: 'Type', value: IMAGES_TYPES_ALLOWED },
];

const PLACEHOLDER_IMAGE_MAPPER = {
  '3/4 smiling': PartialSimilingImage,
  'Closed anterior': ClosedAnteriorImage,
  'Frontal relaxed': FrontalRelaxedImage,
  'Frontal smiling': FrontalSimilingImage,
  'Left buccal': LeftBuccalImage,
  'Right buccal': RightBuccalImage,
  'Upper occlusal': UpperOcclusalImage,
  'Lower occlusal': LowerOcclusalImage,
  PAN: PanImage,
  CEPH: CephImage,
};

class ImageBlock extends DropzoneFileUploader {
  constructor(props) {
    super(props);
    this.folderName = props.folderName;
    this.dropzoneSource = props.dropzoneSource;
    this.state = {
      ...this.defaultState(),
      files: props.files,
    };
  }

  onDialogInputClick = (e) => {
    this.dropzoneRef.open();
    e.preventDefault();
    e.stopPropagation();
  };

  onFileAssetUpload(file) {
    let obj = {};
    obj[this.props.fileName] = file.id;
    this.props.onSuccessFullAssetUpload(obj);
  }

  onDropRejected = (files) => {
    each(VALIDATIONS_REQUIRED, (validation) => {
      this[`is${validation.attribute}Valid`](files, validation.value);
    });
  };

  onCrossClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ files: [] }, () => {
      this.props.onRemoveImageClick(this.props.fileName);
    });
  };

  renderImage() {
    let image = last(this.state.files);

    if (image) {
      let percentage = this.state.progressMapper[image.name] || 0,
        loading = this.state.attachmentsLoader[image.name];
      return (
        <>
          {loading && (
            <div className='loader-active percentage'>
              <span className='loader-percentage'>
                <OrthoIcon
                  name='upload'
                  dataHoverNotRequired={true}
                  defaultClass='elem-rotate me-1'
                />
                {`${percentage}%`}
              </span>
              <span className='span-overlay'></span>
            </div>
          )}
          <img
            alt={this.props.fileName}
            src={image.preview}
            className='appoint-img'
          />
          {!loading && (
            <OrthoIcon
              name='clear'
              onClick={this.onCrossClick}
              defaultClass='appoint-img-cross'
            />
          )}
        </>
      );
    } else {
      let imageSrc = PLACEHOLDER_IMAGE_MAPPER[this.props.fileName];
      return (
        <>
          <img
            alt={this.props.fileName}
            src={imageSrc}
            className='appoint-img opacity-1'
          />
          <span className='span-overlay'></span>
        </>
      );
    }
  }

  render() {
    let { fileName } = this.props,
      extraClass = NON_LANDSCAPE_IMAGE_NAMES.includes(fileName)
        ? ''
        : 'landscape-col',
      dropzoneActiveClass = this.state.dropzoneActive ? 'active' : '',
      dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ';

    return (
      <>
        <Dropzone
          className={'modal-dropzone-area'}
          onDrop={this.onDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          ref={(node) => {
            this.dropzoneRef = node;
          }}
          onDropRejected={this.onDropRejected}
          accept={IMAGES_TYPES_ALLOWED}
          name={fileName}
          key={fileName}>
          {(props) => (
            <div
              className={`col-12 col-md-4 appoint-image-col ${extraClass}`}
              key={fileName}>
              <div
                className={`modal-dropzone-area appoint-image-block ${dropzoneActiveClass}`}>
                {this.renderImage()}
                <span className='img-label'>{fileName}</span>
              </div>
              <div
                {...props.getRootProps({ onClick: this.onDialogInputClick })}
                className={dropzoneInputContainerClassName}>
                <input {...props.getInputProps()} />
              </div>
            </div>
          )}
        </Dropzone>
        {this.renderErrorModal(IMAGES_TYPES_ALLOWED, null)}
      </>
    );
  }
}

ImageBlock.defaultProps = {
  fileName: '',
  onRemoveImageClick: () => {},
  onSuccessFullAssetUpload: () => {},
};
ImageBlock = withApollo(ImageBlock);
export default ImageBlock;
