import React, { Component } from 'react';

import OsLink from 'app/components/OsLink';

import { VERIFICATION_PROCESS_NOTION_LINK } from 'app/constants';

class VerificationProcessNotionLink extends Component {
  render() {
    return <span></span>;
  }
}

VerificationProcessNotionLink.defaultProps = {
  text: 'Learn more about our verification process here.',
};
export default VerificationProcessNotionLink;
