import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { getIconClass } from 'app/utils/entitiesHelper';
import EventTracker from 'app/services/EventTracker';

import { snakeCase } from 'app/utils/osLodash';

import { translate } from 'app/actions/flashMessage';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

class OsLink extends Component {
  onClick = (e) => {
    let eventName = snakeCase(this.props.name || this.props.text);
    EventTracker.trackLinkClicked(eventName, this.props.associatedEntity);
    this.props.onClick && this.props.onClick(e);
  };

  goToPage() {
    if (this.props.href) {
      return { href: this.props.href };
    } else if (this.props.to) {
      return {
        to: this.props.skipWorkspace
          ? this.props.to
          : withWorkspaceIdentifier(this.props.to),
      };
    }
  }

  getElement() {
    if (this.props.to) {
      return Link;
    } else if (this.props.href) {
      return 'a';
    } else {
      return 'span';
    }
  }

  renderIcon() {
    if (this.props.iconName)
      return (
        <i
          className={`${getIconClass(this.props.iconName)} ${
            this.props.iconDefaultClass
          }`}
        />
      );
  }

  renderTooltip() {
    let { tooltipKey, tooltipText } = this.props,
      text = tooltipKey ? translate(tooltipKey) : tooltipText;
    if (text)
      return (
        <div className='button-tooltip'>
          <OrthoIcon
            name='info'
            defaultClass='lt-red'
            dataHoverNotRequired={true}
          />
          {text}
        </div>
      );
  }

  render() {
    let Element = this.getElement(),
      urlProps = this.goToPage(),
      className = `os-btn-link ${this.props.className} ${
        this.props.disabled ? 'pointer-not-allowed' : ''
      }`,
      props = {
        className: className,
        target: this.props.openInNewTab && '_blank',
        text: this.props.text,
        rel: this.props.openInNewTab && 'noopener noreferrer',
        title: this.props.title,
        onMouseEnter: this.props.onMouseEnter,
        onMouseLeave: this.props.onMouseLeave,
        ...urlProps,
      };
    if (this.props.type === 'submit' && this.props.htmlTag !== 'button') {
      return (
        <input
          type='submit'
          onClick={this.onClick}
          className={props.className}
          value={this.props.text}
        />
      );
    } else {
      return (
        <Element
          {...props}
          data-hover={!isTouchSupported()}
          onClick={this.props.onParentClick}>
          {this.renderIcon()}
          {props.text}
          {this.props.children}
          {this.renderTooltip()}
          <span
            className={`btn-link-helper ${this.props.linkHelperClass}`}
            onClick={this.onClick}
            data-text={this.props.text}></span>
        </Element>
      );
    }
  }
}

OsLink.defaultProps = {
  className: '',
  associatedEntity: {},
  linkHelperClass: '',
  onParentClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  iconDefaultClass: '',
  skipWorkspace: false,
};

export default OsLink;
