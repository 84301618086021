import React from 'react';
import img from 'app/images/svg-thumbnail/mentioned.png';

const SVG = ({
  style = {},
  fill = '',
  width = '190',
  height = '190',
  className = '',
  viewBox = '0 0 190 190',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    <image href={img} />
  </svg>
);

export default SVG;
