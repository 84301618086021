import { Modal } from 'react-bootstrap';
import useTaskModal from 'app/components/TaskModal/useTaskModal';
import OsBtn from 'app/components/OsBtn';
import { Field, Form } from 'react-final-form';
import OsField from 'app/components/OsField';
import OsCards from 'app/components/OsCards';
import calendar from 'app/images/task-manager/calendar.svg';
import DisplayLabels from 'app/components/TaskModal/DisplayLabels';
import React, { useState } from 'react';
import { fieldRequired } from 'app/utils/validationHelper';
import DisplayFormButtons from 'app/components/TaskModal/DisplayFormButtons';
import {
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
} from 'app/utils/timeHelper';
import {
  FORMAT_START_WITH_MONTH_SLASH,
  TASK_MODAL_REPEAT_OPTIONS,
} from 'app/components/Task/taskConstant';
import DeleteConfirmationModal from 'app/components/Shared/DeleteConfirmationModal';
import { isTitleTippyVisible } from 'app/components/TaskModal/tippyHelper';
import LabelInputWithDropdown from 'app/components/TaskModal/LabelInputWithDropdown';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import useFetechAllPatients from 'app/hooks/patients/useFetchAllPatients';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { getPartnerSpaceData } from 'app/utils/Workspace/generalHelper';

const DESCRIPTION_PLACEHOLDER =
  'Add your task notes here. Using the controls above, you can add a link to your task or modify the text style.';
const TIPTAP_EXTENSIONS_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'CustomSubmit',
  'Underline',
  'Link',
];
const TaskModal = (props) => {
  const {
    initialValues,
    taskModal,
    showDeleteModal,
    username,
    isSubmitting,
    backdrop,
    inputRef,
    debounceTitle,
    handleDebounceTitle,
    taskLabels,
    labelInputField,
    handleBackdrop,
    onTaskDelete,
    closeModal,
    onSubmit,
    isEditView,
    showDeleteModalView,
    setLabelInputField,
    isPartnerSpace,
  } = useTaskModal();

  const { patients, patientsLoading, setQuery } = useFetechAllPatients();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div>
        <Modal
          show={taskModal.open}
          onHide={closeModal}
          backdrop={backdrop}
          dialogClassName={'task-modal'}>
          <Modal.Header className={'p-0'}>
            <h4 className={'modal-title-pe2 task-modal-heading'}>
              {taskModal?.taskId ? 'Edit Task' : 'Create Task'}
            </h4>
            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn'
              icon='close'
              label='Close share modal'
              onClick={closeModal}
            />
          </Modal.Header>
          <Modal.Body className={'p-0'}>
            <Form
              onSubmit={onSubmit}
              disableOnEnter={true}
              destroyOnUnregister={true}
              keepDirtyOnReinitialize={true}
              initialValues={initialValues}>
              {({ handleSubmit, values, form }) => {
                handleBackdrop(values);
                return (
                  <form className={'task-modal-form'} onSubmit={handleSubmit}>
                    <div className={'title-field'}>
                      <Field
                        name={'title'}
                        type={'text'}
                        component={OsField}
                        osType={'input'}
                        saveInputRef={(ref) => (inputRef.current = ref)}
                        onChange={({ target: { value } }) =>
                          handleDebounceTitle(value)
                        }
                        placeholder={'Type title here...'}
                        validate={fieldRequired}
                      />
                      <BootstrapDropdown
                        async={false}
                        items={patients}
                        itemKey={'name'}
                        title={
                          <span className={'title-component'}>
                            <OrthoIcon
                              name={'add'}
                              defaultClass={`btn-icon-helper`}
                              dataHoverNotRequired={true}
                            />
                            <span>PATIENTS</span>
                          </span>
                        }
                        inputFieldRequired={true}
                        optionsLoading={patientsLoading}
                        inputFieldProps={{
                          placeholder: 'Search for patient...',
                        }}
                        extraClassName={'task-modal-patient-dropdown'}
                        show={showMenu || isTitleTippyVisible(debounceTitle)}
                        onToggle={(show) => setShowMenu(show)}
                        onClick={(event, patient) => {
                          let updatedTitle = debounceTitle;
                          if (debounceTitle.at(-1) === '+')
                            updatedTitle = debounceTitle.slice(0, -1);
                          form.change('entity', patient);
                          form.change('title', updatedTitle);
                          inputRef.current.focus();
                          handleDebounceTitle(updatedTitle || '');
                          setShowMenu(false);
                        }}
                        onChange={(event, textValue) => setQuery(textValue)}
                      />
                    </div>

                    {/* Attach entity to the modal */}
                    {values.entity && (
                      <div className={'entityWrapper task-modal-entity'}>
                        <div className={'entity-clear-icon'}>
                          <OsBtn
                            name='BtnIcon'
                            extraClass='no-text os-header-btn web-view-btn'
                            icon='clear'
                            label='Close share modal'
                            onClick={() => form.change('entity', null)}
                          />
                        </div>
                        <OsCards
                          size={'extra-small'}
                          obj={values.entity}
                          sliderPreview={true}
                          shareRequired={false}
                          dateBlockRequired={true}
                          closeRequired={false}
                          isTaskModal={true}
                          hideExpandAction={true}
                        />
                      </div>
                    )}

                    {/* Description Field */}
                    <div className={'description-field'}>
                      <Field
                        name={'description'}
                        textType={'html'}
                        component={OsField}
                        osType={'tiptap-editor'}
                        initialContent={values.initialContent || ''}
                        updateContent={(value) => {
                          form.change('description', value);
                        }}
                        extensions={TIPTAP_EXTENSIONS_REQUIRED}
                        placeholder={DESCRIPTION_PLACEHOLDER}
                        autofocus={false}
                      />
                    </div>

                    {/* Labels Dropdown */}
                    <div className={'labels-dropdown'}>
                      <DisplayLabels
                        fieldName={'labels'}
                        labels={values.labels}
                        allTaskLabels={taskLabels}
                        removeLabel={(field, value) =>
                          form.change(field, value)
                        }
                      />
                      <LabelInputWithDropdown
                        name={'labels'}
                        className={'react-select-ortho-2'}
                        label={'Labels'}
                        allTaskLabels={taskLabels}
                        labelValue={values.labels || []}
                        inputValue={labelInputField}
                        options={taskLabels}
                        onClick={(field, value) => {
                          values.labels.length <= 4 &&
                            form.change(field, value);
                        }}
                        onChange={(value) => {
                          setLabelInputField(value);
                        }}
                        placeholder={'Select'}
                      />
                    </div>

                    <div className={'calendar-repeat-container'}>
                      <div className={'date-calendar'}>
                        <Field
                          name={'dueDate'}
                          osType={'newDate'}
                          label={'Due Date'}
                          minDate={
                            values.repeat !== 'non_recursive' &&
                            currentTimeWithUserTimeZone()
                          }
                          clearIcon={null}
                          disabled={!!values.isDatePickerDisabled}
                          onChange={(value) =>
                            form.change(
                              'dueDate',
                              getDateFromStringWithGivenFormat(
                                value,
                                FORMAT_START_WITH_MONTH_SLASH,
                              ),
                            )
                          }
                          formValues={values.dueDate}
                          calendarIcon={
                            <div>
                              <img src={calendar} alt={'calendar-icon'} />
                            </div>
                          }
                          component={OsField}
                        />
                      </div>
                      <div className={'repeat-dropdown'}>
                        <Field
                          name={'repeat'}
                          className={'react-select-ortho-2 os-menu-drop'}
                          label={'Repeat Task?'}
                          osType={'selectInput'}
                          placeholder={'Select'}
                          options={TASK_MODAL_REPEAT_OPTIONS}
                          onChange={(field, value) => form.change(field, value)}
                          component={OsField}
                        />
                      </div>
                    </div>

                    {/* Assignee dropdown */}
                    <div className={'assignee'}>
                      <Field
                        osType='selectInput'
                        className='react-select-ortho-2 os-menu-drop'
                        component={OsField}
                        label='Assignee*'
                        formValue={values.assignee}
                        name='assignee'
                        validate={fieldRequired}
                        async={true}
                        idQuery={getPartnerSpaceData()?.nice_id}
                        queryType={
                          isPartnerSpace()
                            ? 'AllSpaceMembers'
                            : 'PossibleConversationUsers'
                        }
                        selectedValueNameRequired={false}
                        perPage={100}
                        placeholder='Select'
                        onChange={(field, value) => {
                          form.change(field, value);
                        }}
                      />
                    </div>

                    {/* Render Submit buttons */}
                    <DisplayFormButtons
                      taskModal={taskModal}
                      initialValues={initialValues}
                      isSubmitting={isSubmitting}
                      isEditView={isEditView()}
                      username={username}
                      handleSubmit={handleSubmit}
                      closeModal={closeModal}
                      showDeleteModalView={showDeleteModalView}
                    />
                  </form>
                );
              }}
            </Form>
            {}{' '}
          </Modal.Body>
        </Modal>
        {showDeleteModal && (
          <DeleteConfirmationModal
            entityType={'Task'}
            onClose={() => showDeleteModalView(false)}
            deleteEntity={onTaskDelete}
          />
        )}
      </div>
    </>
  );
};

export default TaskModal;
