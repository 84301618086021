import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { loader as queryLoader } from 'graphql.macro';
import { uniqBy, groupBy, keys } from 'app/utils/osLodash';
import StoreUpdater from 'app/services/StoreUpdater';

const ADD_EMOJI_REACTION_MUTATION = queryLoader(
  'app/graphql/mutations/AddReaction.gql',
);
const REMOVE_EMOJI_REACTION_MUTATION = queryLoader(
  'app/graphql/mutations/RemoveReaction.gql',
);

export const useEmoji = ({ entityType, entityId, emojiReactionDetail }) => {
  const { graph: user } = useSelector((state) => state.currentUser);
  const [addReactionMutation] = useMutation(ADD_EMOJI_REACTION_MUTATION);

  const [removeReactionMutation] = useMutation(REMOVE_EMOJI_REACTION_MUTATION);

  function getModifiedEmojiReactionDetail() {
    if (emojiReactionDetail) {
      const uniqReactions = uniqBy(
          emojiReactionDetail.emoji_reaction_users,
          'id',
        ),
        list = [],
        groupedReactions = groupBy(uniqReactions, 'identifier');
      keys(groupedReactions).forEach((emojiName) => {
        list.push({
          emojiName,
          users: groupedReactions[emojiName],
          active: groupedReactions[emojiName].some(
            (s) => s.reacted_by_current_user,
          ),
        });
      });
      return {
        id: emojiReactionDetail.id,
        list,
        emojiReactionUsers: emojiReactionDetail.emoji_reaction_users,
      };
    } else {
      return null;
    }
  }

  function getVariables(identifier) {
    return {
      entityType: entityType,
      entityId: entityId,
      identifier,
    };
  }

  function addEmojiInStore(name) {
    StoreUpdater.addEmojiInEntity({
      emojiName: name,
      entityType: entityType,
      entityId: entityId,
      user: user,
      reactionId: emojiReactionDetail.id,
      reactedByCurrentUser: true,
    });
  }

  function removeEmojiInStore(name) {
    StoreUpdater.removeEmojiFromEntity({
      emojiName: name,
      entityType: entityType,
      entityId: entityId,
      user: user,
      reactionId: emojiReactionDetail.id,
    });
  }

  const addEmoji = (name) => {
    addReactionMutation({
      variables: getVariables(name),
      optimisticResponse: () => {
        return {
          addEmoji: {
            __typename: 'Response',
            success: true,
          },
        };
      },
      update: (proxy, { data: { addEmoji } }) => {
        addEmoji.success ? addEmojiInStore(name) : removeEmojiInStore(name);
      },
    });
  };

  const removeEmoji = (name) => {
    removeReactionMutation({
      variables: getVariables(name),
      optimisticResponse: () => {
        return {
          removeEmoji: {
            __typename: 'Response',
            success: true,
          },
        };
      },
      update: (proxy, { data: { removeEmoji } }) => {
        removeEmoji.success ? removeEmojiInStore(name) : addEmojiInStore(name);
      },
    });
  };

  return {
    getModifiedEmojiReactionDetail,
    addEmoji,
    removeEmoji,
  };
};
