import { IS_DIRECT_WORKSPACE_LOGIN, NOT_DIRECT_WORKSPACE_LOGIN } from "app/actions/types"

const directWorkSpaceLogin = (state = { isDirectWorkSpaceLogin: false }, action) => {
  switch (action.type) {
    case IS_DIRECT_WORKSPACE_LOGIN: return { ...state, isDirectWorkSpaceLogin: true }
    case NOT_DIRECT_WORKSPACE_LOGIN: return { ...state, isDirectWorkSpaceLogin: false }
    default: return state;
  }
}

export default directWorkSpaceLogin