import Authenticate from 'app/services/Authenticate.js';

import {
  ACTION_BY_GUEST_USER,
  UPDATE_GUEST_USER,
  UPDATE_GUEST_USER_DATA,
} from './types';

export function updateGuestUser(token) {
  return (dispatch) => {
    Authenticate.setItem('guestToken', token);
    dispatch({ type: UPDATE_GUEST_USER });
  };
}

export function updateGuestUserData(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GUEST_USER_DATA,
      data,
    });
  };
}

export function actionByGuestUser(action, metaInfo) {
  return (dispatch) => {
    dispatch({
      type: ACTION_BY_GUEST_USER,
      action,
      metaInfo,
    });
  };
}
