import React, { useContext, useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import StoreUpdater from 'app/services/StoreUpdater';
import { TaskContext } from 'app/context/TaskContext';
import dayjs from 'dayjs';
import { ceil } from 'lodash';

const TASK_COMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskProcessed.gql',
);
const UPDATE_DUEDATE = queryLoader(
  'app/graphql/mutations/Task/TaskDueDateChange.gql',
);
const COMPLETED_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const UNSCHEDULED_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
const OVERDUE_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const IN_PROGRESS_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);

const NON_RECURSIVE = 'non_recursive';

function useUnifiedBoard() {
  const {
    completedTaskVariables,
    unscheduledTaskVariables,
    overdueTaskVariables,
    inprogressTaskVariables,
    completedTasks,
    unscheduledTasks,
    overdueTasks,
    inProgressTasks,
    fetchNextCompletedRecords,
    fetchNextUnscheduledRecords,
    fetchNextOverdueRecords,
    fetchNextInprogressRecords,
  } = useContext(TaskContext);

  //Mutations
  const [markTaskProcessed] = useMutation(TASK_COMPLETED);
  const [updateDueDate] = useMutation(UPDATE_DUEDATE);

  //State
  const [kBoard, setKBoard] = useState(null);
  const [kColumns, setKColumns] = useState(null);

  const addTaskInInProgressColumn = (card) => {
    StoreUpdater.addRecord(
      { ...card, due_date: dayjs().format('DD-MM-YYYY') },
      inprogressTaskVariables,
      {
        query: IN_PROGRESS_TASKS,
      },
    );
    changeDueDate(card, dayjs().format('DD-MM-YYYY'));
  };

  const addTaskInUnscheduledColumn = (card) => {
    StoreUpdater.addRecord(
      { ...card, dueDate: null },
      unscheduledTaskVariables,
      {
        query: UNSCHEDULED_TASKS,
      },
    );
    changeDueDate(card, '');
  };

  const addTaskInCompletedColumn = (card) => {
    StoreUpdater.addRecord(
      { ...card, completed: true, completed_at: new Date() },
      completedTaskVariables,
      {
        query: COMPLETED_TASKS,
      },
    );

    markAsDone(card);
  };

  const removeTaskFromInProgressColumn = (card) => {
    StoreUpdater.removeRecord(card, inprogressTaskVariables, {
      query: IN_PROGRESS_TASKS,
    });
  };

  const removeTaskFromOverdueColumn = (card) => {
    StoreUpdater.removeRecord(card, overdueTaskVariables, {
      query: OVERDUE_TASKS,
    });
  };

  const removeTaskFromUnscheduledColumn = (card) => {
    StoreUpdater.removeRecord(card, unscheduledTaskVariables, {
      query: UNSCHEDULED_TASKS,
    });
  };

  const RemoveTaskFunctionMapper = {
    unscheduled: removeTaskFromUnscheduledColumn,
    overdue: removeTaskFromOverdueColumn,
    inProgress: removeTaskFromInProgressColumn,
  };

  const AddTaskFunctionMapper = {
    done: addTaskInCompletedColumn,
    unscheduled: addTaskInUnscheduledColumn,
    inProgress: addTaskInInProgressColumn,
  };

  const updateKanbanBoard = () => {
    let newBoard = {
      columns: [
        {
          id: 'inProgress',
          title: (
            <div className='unified-column-header'>
              In Progress ({inProgressTasks?.records.total})
            </div>
          ),
          cards: [...(inProgressTasks?.records.results || [])],
        },
        {
          id: 'overdue',
          title: (
            <div className='unified-column-header'>
              Overdue ({overdueTasks?.records.total})
            </div>
          ),
          cards: [...(overdueTasks?.records.results || [])],
        },
        {
          id: 'unscheduled',
          title: (
            <div className='unified-column-header'>
              Unscheduled ({unscheduledTasks?.records.total})
            </div>
          ),
          cards: [...(unscheduledTasks?.records.results || [])],
        },
        {
          id: 'done',
          title: (
            <div className='unified-column-header'>
              Done ({completedTasks?.records.total})
            </div>
          ),
          cards: [...(completedTasks?.records.results || [])],
        },
      ],
    };
    setKBoard(newBoard);
  };

  const moveCardToAnotherColumn = (fromColumnId, toColumnId, card) => {
    if (!isCardMovementValid(fromColumnId, toColumnId, card)) return;
    RemoveTaskFunctionMapper[fromColumnId](card);
    AddTaskFunctionMapper[toColumnId](card);
  };

  const isCardMovementValid = (fromColumnId, toColumnId, card) => {
    if (fromColumnId === toColumnId) return false;
    if (toColumnId === 'overdue') return false;
    if (fromColumnId === 'done') return false;
    if (
      fromColumnId === 'overdue' &&
      toColumnId !== 'done' &&
      card.recursive_type !== NON_RECURSIVE
    )
      return false;
    return true;
  };

  const changeDueDate = (card, dueDate) => {
    updateDueDate({
      variables: {
        id: card.id,
        dueDate: dueDate,
      },
    }).then(({ data }) => {});
  };

  const markAsDone = (card) => {
    const cardId = card.id;
    markTaskProcessed({
      variables: { id: cardId },
    }).then(({ data }) => {});
  };

  const fetchNextRecords = (e) => {
    if (
      Math.abs(
        Math.round(e.scrollTop) +
          Math.round(e.clientHeight) -
          Math.round(e.scrollHeight),
      ) <= 1
    ) {
      let columnName = e.getAttribute('data-rbd-draggable-id').split('-')[2];

      switch (columnName) {
        case 'done':
          fetchNextCompletedRecords();
          break;
        case 'inProgress':
          fetchNextInprogressRecords();
          break;
        case 'overdue':
          fetchNextOverdueRecords();
          break;
        default:
          fetchNextUnscheduledRecords();
          break;
      }
    }
  };

  useEffect(() => {
    updateKanbanBoard();
  }, [completedTasks, overdueTasks, unscheduledTasks, inProgressTasks]);

  useEffect(() => {
    if (kColumns) {
      for (let i = 0; i < kColumns.length; i++) {
        kColumns[i].addEventListener('scroll', () =>
          fetchNextRecords(kColumns[i]),
        );
      }
    }

    return () => {
      if (kColumns) {
        for (let i = 0; i < kColumns.length; i++) {
          kColumns[i].removeEventListener('scroll', () =>
            fetchNextRecords(kColumns[i]),
          );
        }
      }
    };
  }, [
    kColumns,
    fetchNextInprogressRecords,
    fetchNextUnscheduledRecords,
    fetchNextOverdueRecords,
    fetchNextCompletedRecords,
  ]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-kanban-column');
    if (elements !== undefined && elements.length !== 0) {
      setKColumns(elements);
    }
  });

  return {
    kBoard,
    moveCardToAnotherColumn,
  };
}

export default useUnifiedBoard;
