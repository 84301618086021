import { getEasyRXAuthorizeURL } from 'app/components/EasyRX/utils';
import OsBtn from 'app/components/OsBtn';

const AuthorizeButton = () => {
  const onClick = () => {
    window.location.href = getEasyRXAuthorizeURL();
  };
  return (
    <OsBtn
      type={'button'}
      htmlTag={'button'}
      extraClass={'web-view-btn btn-save-info'}
      name={'BtnPrimary'}
      text={'Connect'}
      onClick={onClick}
    />
  );
};

export default AuthorizeButton;
