import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { setNavSearchCategory, setNavSearchOpen } from 'app/actions/navSearch';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import Collection from 'app/components/CollectionView';
import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { isSearchFeatureEnabled } from 'app/utils/featureHelper';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';

class ResourceUnavailableView extends Component {
  constructor(props) {
    super(props);
    let locationState = props.location.state;
    this.state = {
      showRelatedItemsText: false,
      resourceType: locationState?.resourceType,
      niceUrl: locationState?.niceUrl,
      url: locationState?.url,
    };
  }

  privateAccessDenied() {
    return (
      this.props.location.pathname ===
      withWorkspaceIdentifier('/private_access_denied')
    );
  }

  notFound() {
    return (
      this.props.location.pathname === withWorkspaceIdentifier('/not_found')
    );
  }

  collectionTotalHandler = (count) => {
    count > 0 && this.setState({ showRelatedItemsText: true });
  };

  renderIcon() {
    return (
      <div className='svg-icon-block'>
        {this.notFound() && <Icon name='svg404' />}
        {this.privateAccessDenied() && <Icon name='privacy' />}
      </div>
    );
  }

  renderHeading() {
    const searchText = isSearchFeatureEnabled()
      ? 'Would you like to search for something else'
      : '';
    if (this.notFound()) {
      return (
        <div className='heading-404'>
          <div className='big px-3 px-md-0'>
            The page you requested couldn’t be found
          </div>
          <div className='small-text px-3 px-md-0'>
            This content is no longer available.
            <br />
            {searchText}
          </div>
        </div>
      );
    } else if (this.privateAccessDenied()) {
      return (
        <div className='heading-404'>
          <div className='big px-3 px-md-0'>
            The privacy settings have been changed
          </div>
          <div className='small-text px-3 px-md-0'>
            This content is no longer available for public access.
            <br />
            Would you like to search for something else
          </div>
        </div>
      );
    }
  }

  isRelatedItemsRequired() {
    return (
      this.privateAccessDenied() &&
      !this.props.currentUser.graph.is_general_user
    );
  }

  renderRelatedItems() {
    return (
      <div className='container'>
        <Collection
          sort='view_count'
          sortOrder='desc'
          types={[this.state.resourceType]}
          query_by='related'
          id_query={this.state.niceUrl}
          setCollectionTotal={this.collectionTotalHandler}
        />
      </div>
    );
  }

  shouldRedirectToRoot() {
    return (
      this.privateAccessDenied() &&
      !this.state.resourceType &&
      !this.state.niceUrl
    );
  }

  renderSearchContainer() {
    return (
      <label className='label-404-search'>
        <OrthoIcon name='search' dataHoverNotRequired={true} />
        <AuthorizeComponent
          onClick={this.props.setNavSearchOpen}
          abilityObject={getAuthorizeObject('search', 'all')}>
          <button className='form-control cursor-pointer'>
            Click here to search...
          </button>
        </AuthorizeComponent>
      </label>
    );
  }

  render() {
    if (this.shouldRedirectToRoot()) return <Navigate to='/' />;

    return (
      <div className='resource-unavailable'>
        <article className='text-center'>
          {this.renderIcon()}
          {this.renderHeading()}

          {isSearchFeatureEnabled() && this.renderSearchContainer()}

          {this.state.showRelatedItemsText &&
            !this.props.currentUser.graph.is_general_user && (
              <div className='mb-4 pb-2'>Or explore other related content</div>
            )}
        </article>

        {this.isRelatedItemsRequired() && this.renderRelatedItems()}
      </div>
    );
  }
}
ResourceUnavailableView = withRouter(ResourceUnavailableView);

ResourceUnavailableView = connect(({ currentUser }) => ({ currentUser }), {
  setNavSearchCategory,
  setNavSearchOpen,
})(ResourceUnavailableView);
export default ResourceUnavailableView;
