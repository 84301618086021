import React, { useRef, useState } from 'react';

const angleTools = {
  getAngle: function (t, n) {
    let a = n.x - t.x,
      e = n.y - t.y;
    return (Math.atan2(e, a) / Math.PI) * 180;
  },
  getDistance: function (t, n) {
    let a = t.x - n.x,
      e = t.y - n.y;
    return Math.sqrt(a * a + e * e);
  },
  moveOnAngle: function (t, n) {
    let a = angleTools.getOneFrameDistance(t, n);
    t.x = t.x + a.x;
    t.y = t.y + a.y;
  },
  getOneFrameDistance: function (t, n) {
    return {
      x: n * Math.cos((t.rotation * Math.PI) / 180),
      y: n * Math.sin((t.rotation * Math.PI) / 180),
    };
  },
};

const r = (a, b, c) => {
  return parseFloat(
    (Math.random() * ((a ? a : 1) - (b ? b : 0)) + (b ? b : 0)).toFixed(
      c ? c : 0,
    ),
  );
};

const ClickPopupWrapper = ({ children, onClick, disabled = false }) => {
  const requestRef = useRef();
  const [isDisabled, setDisabled] = useState(disabled);

  const onClickHandler = (e) => {
    if (!isDisabled) {
      setDisabled(true);
      onClick(e);
      cancelAnimationFrame(requestRef.current);
      explode(e);
    }
  };

  const explode = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    let c = document.createElement('canvas');
    let ctx = c.getContext('2d');
    let ratio = window.devicePixelRatio;
    let particles = [];

    document.body.appendChild(c);

    c.style.position = 'absolute';
    c.style.left = x - 100 + 'px';
    c.style.top = y - 100 + 'px';
    c.style.pointerEvents = 'none';
    c.style.width = 200 + 'px';
    c.style.height = 200 + 'px';
    c.style.zIndex = 2;
    c.width = 200 * ratio;
    c.height = 200 * ratio;

    const particle = () => {
      return {
        x: c.width / 2,
        y: c.height / 2,
        radius: r(20, 30),
        color: 'rgb(' + [r(224, 254), r(82, 196), r(82, 87)].join(',') + ')',
        rotation: r(0, 360, true),
        speed: r(8, 12),
        friction: 0.9,
        opacity: r(0, 0.5, true),
        yVel: 0,
        gravity: 0.1,
      };
    };

    for (let i = 0; ++i < 25; ) {
      particles.push(particle());
    }

    const render = () => {
      ctx.clearRect(0, 0, c.width, c.height);

      particles.forEach((p, i) => {
        angleTools.moveOnAngle(p, p.speed);

        p.opacity -= 0.01;
        p.speed *= p.friction;
        p.radius *= p.friction;

        p.yVel += p.gravity;
        p.y += p.yVel;

        if (p.opacity < 0) return;
        if (p.radius < 0) return;

        ctx.beginPath();
        ctx.globalAlpha = p.opacity;
        ctx.fillStyle = p.color;
        ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
        ctx.fill();
      });
    };

    (function renderLoop() {
      requestRef.current = window.requestAnimationFrame(renderLoop);
      render();
    })();

    setTimeout(function () {
      cancelAnimationFrame(requestRef.current);
      setDisabled(false);
      document.body.removeChild(c);
    }, 500);
  };

  return React.cloneElement(children, { onClick: onClickHandler });
};

export default ClickPopupWrapper;
