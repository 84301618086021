import React, { Component } from 'react';

import LoadingCard from './LoadingCard';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsGrid from 'app/components/OsGrid';

export default class RenderLoadingCards extends Component {
  renderCard({ size, className, index }) {
    const { cardGridIdentifier } = this.props;
    if (cardGridIdentifier) {
      return (
        <OsGrid identifier={cardGridIdentifier}>
          <LoadingCard size={size} />
        </OsGrid>
      );
    } else {
      return (
        <div
          key={index}
          className={
            className +
            (this.props.canArrangeContent &&
            (this.props.previousCardsCount + index + 1) % 3 === 0
              ? ' next-elem-after'
              : '')
          }>
          <LoadingCard size={size} />
        </div>
      );
    }
  }

  renderCards() {
    let { count = 24, initialLoad = true, className, size } = this.props;
    className = className || 'col-xs-12 col-md-6 col-lg-4 ';
    return (
      <>
        {initialLoad &&
          [...Array(count)].map((_, index) => (
            <>
              {false &&
                this.props.canArrangeContent &&
                this.props.previousCardsCount + index !== 0 &&
                (this.props.previousCardsCount + index) % 3 === 0 && (
                  <div className='add-new-section'>
                    <OrthoIcon
                      name='add-new-section first'
                      dataHoverNotRequired={true}
                    />
                  </div>
                )}
              {false && this.props.cardsCustomMarginRequired && (
                <div className='add-new-section second'>
                  <OrthoIcon
                    name='add-new-section'
                    dataHoverNotRequired={true}
                  />
                </div>
              )}
              {this.renderCard({ size, className, index })}
            </>
          ))}
        {!initialLoad && <div className='home-loader'></div>}
      </>
    );
  }

  render() {
    let containerClass = this.props.containerClass || '';
    return this.props.siblings ? (
      this.renderCards()
    ) : (
      <div className={'cards row ' + containerClass}>{this.renderCards()}</div>
    );
  }
}
