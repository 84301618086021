import React, { Component } from 'react';

import OsLink from 'app/components/OsLink';

import { isUndefined } from 'app/utils/osLodash';

class ShowMoreLess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  isMoreLessRequired() {
    return this.props.content.length > this.props.charLimit;
  }

  getContent(isMoreLessRequired) {
    if (isMoreLessRequired) {
      if (this.state.showMore) {
        return this.props.content;
      } else {
        return this.props.content.slice(0, this.props.charLimit);
      }
    } else {
      return this.props.content;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.onClick && !isUndefined(nextProps.showMore)) {
      this.setState({ showMore: nextProps.showMore });
    }
  }

  onClick = () => {
    if (this.props.onClick) this.props.onClick(!this.state.showMore);
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    let isMoreLessRequired = this.isMoreLessRequired(),
      content = this.getContent(isMoreLessRequired);
    return (
      <div className={this.props.extraClass}>
        <div className={this.props.childClass}>
          <div className='bio-text-info'>{content}</div>
          {isMoreLessRequired &&
            this.props.threeDotsRequired &&
            !this.state.showMore && <span className='desc-ellipsis'> ...</span>}
          {isMoreLessRequired && (
            <OsLink
              href='javascript:'
              onClick={this.onClick}
              className='a-link'
              text={this.state.showMore ? 'show less' : 'show more'}
            />
          )}
        </div>
      </div>
    );
  }
}

ShowMoreLess.defaultProps = {
  extraClass: 'user-profile-bio',
  childClass: 'my-3',
  threeDotsRequired: true,
};

export default ShowMoreLess;
