import React, { PureComponent } from 'react';

import EmojiUsers from 'app/components/EmojiIconBar/EmojiUsers';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import UsersInfoModal from 'app/components/Shared/UsersInfoModal';
import { pluralizeWithNumber } from 'app/utils/generalHelper';

class SeenByIcon extends PureComponent {
  state = {
    usersInfoVisible: false,
  };

  onSeeAll = () => {
    this.setState({ usersInfoVisible: true });
  };

  closeModal = () => {
    this.setState({ usersInfoVisible: false });
  };

  hideText = () => {
    return !!this.props.hideText;
  };

  render() {
    if (this.props.viewedBy.length > 0) {
      const { viewCount } = this.props,
        membersText = pluralizeWithNumber(viewCount, {
          singular: 'member',
          plural: 'members',
        });
      return (
        <>
          <OsLink name='BtnSecondary' className='seen-icon-tag'>
            <OrthoIcon name='doubleTick' defaultClass='tick-icon' />
            <span className='count'>{membersText}</span>
            <EmojiUsers
              users={this.props.viewedBy}
              avatarClass={'avatar-24'}
              onSeeAll={this.onSeeAll}
              seeAllRequired={viewCount > 5}
              heading='Seen by'
              parentEntity={this.props.parentEntity}
            />
          </OsLink>
          {this.state.usersInfoVisible && (
            <UsersInfoModal
              show={this.state.usersInfoVisible}
              type='SeenByUsers'
              queryType='SEEN_BY_LISTING'
              heading='Seen By'
              idQuery={this.props.id}
              closeModal={this.closeModal}
            />
          )}
        </>
      );
    } else if (!this.hideText()) {
      let text = (this.props.id == -1) ? 'Posting...' : 'Not seen yet'
      return <span className='not-seen-text'>{text}</span>;
    }
  }
}

export default SeenByIcon;
