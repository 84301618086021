import React from 'react';

import AbstractField from './AbstractField';
import OsField from './';
import SelectInput from 'app/components/Shared/SelectInput';

export default class OsInputField extends AbstractField {
  onChange = (value) => {
    let fieldValue;
    let fieldLabel;
    if (this.props.async) {
      if (this.props.isMulti) fieldValue = value;
      else {
        fieldValue = this.props.selectedValueNameRequired
          ? value.entity.name
          : value.entity.id;
        fieldLabel = value.entity.name;
        // fieldValue = this.props.selectedValueNameRequired ? value.entity.name : value.entity.id
      }
    } else {
      if (this.props.isMulti && this.props.setValueDirectly) fieldValue = value;
      else {
        fieldValue = this.props.isMulti
          ? value.map(({ value }) => value)
          : value.value;
        fieldLabel = this.props.isMulti
          ? value.map(({ entity }) => entity?.name)
          : value.entity?.name;
      }
    }
    this.props.onChange(this.props.input.name, fieldValue, fieldLabel);
  };

  render() {
    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        <SelectInput
          {...this.getFieldProps()}
          onChange={this.onChange}
          formValue={this.props.input.value}
          selectedValueNameRequired={this.props.selectedValueNameRequired}
        />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsInputField.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
  selectedValueNameRequired: false,
};
