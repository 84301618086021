import dayjs from 'dayjs';
import React, { useState } from 'react';
const pattern = /^(0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
export default function useTimePicker({
  initialTime,
  hidePicker,
  setStartTime,
}) {
  let shift, hour, minute;

  if (pattern.test(initialTime)) {
    shift = initialTime.split(' ')[1];
    hour = parseInt(initialTime.split(' ')[0].split(':')[0]);
    minute = parseInt(initialTime.split(' ')[0].split(':')[1]);
  } else {
    hour = parseInt(initialTime?.split(':')[0]);
    minute = parseInt(initialTime?.split(':')[1]);
    shift = hour < 12 ? 'AM' : 'PM';
  }

  const [error, setError] = useState(false);
  const [time, setTime] = useState({
    hour: isNaN(hour) ? 0 : hour % 12,
    minute: isNaN(minute) ? 0 : minute,
    shift: shift,
  });

  const increase = (filter) => {
    switch (filter) {
      case 'hour':
        setTime((prev) => {
          return { ...prev, hour: (prev.hour % 12) + 1 };
        });
        break;
      case 'minute':
        setTime((prev) => {
          return { ...prev, minute: (prev.minute + 30) % 60 };
        });
        break;
      case 'shift':
        setTime((prev) => {
          return {
            ...prev,
            shift: prev.shift === 'PM' ? 'AM' : 'PM',
          };
        });
        break;
      default:
        break;
    }
  };

  const decrease = (filter) => {
    const filters = {
      hour: () => {
        setTime((prev) => {
          return {
            ...prev,
            hour: prev.hour === 0 ? 12 : prev.hour - 1,
          };
        });
      },
      minute: () => {
        setTime((prev) => {
          return {
            ...prev,
            minute: prev.minute === 0 ? prev.minute + 30 : prev.minute - 30,
          };
        });
      },
      shift: () => {
        setTime((prev) => {
          return {
            ...prev,
            shift: prev.shift === 'PM' ? 'AM' : 'PM',
          };
        });
      },
    };
    filters[filter]();
  };

  const addDateBtnHandler = () => {
    let timeString = '';
    if (time.hour === 0 && time.minute === 0) {
      timeString = `12:00 ${time.shift}`;
    } else {
      const paddedHour = time.hour.toString().padStart(2, '0');
      const paddedMinute = time.minute.toString().padStart(2, '0');
      timeString = `${paddedHour}:${paddedMinute} ${time.shift}`;
    }
    setStartTime(timeString);
    hidePicker();
  };

  return {
    time,
    increase,
    decrease,
    addDateBtnHandler,
    error,
  };
}
