import React from 'react';

import AdditionalLink from './additionallink';
import AnnualCe from './annualce';
import ArchiveSpace from './archive_space';
import CannotDelete from './cannotDelete';
import CannotEdit from './cannotEdit';
import CannotOpen from './cannotOpen';
import ChangePassword from './password';
import CollaborationSpace from './collaborationSpace';
import CollectionSpace from './collectionSpace';
import CreditCard from './creditCard';
import Delete from './delete';
import DeleteInfo from './delete_info';
import DropFiles from './dropFiles';
import EmptyAddressCard from './emptyAddressCard';
import EmptyCareSpace from './emptyCareSpace';
import EmptyUserCard from './emptyUserCard';
import Etiquette from './etiquette';
import Information from './information';
import Invite from './invite';
import Loader from './Loader';
import Login from './login';
import NoBoards from './noBoard';
import NoCases from './noCases';
import NoComments from './noComments';
import NoFiles from './noFiles';
import NoNotes from './noNotes';
import NoNotification from './noNotification';
import NoPulse from './noPulse';
import NoResults from './noResults';
import NoSpaces from './noSpaces';
import NoTools from './noTools';
import NoUnreadNotification from './NoUnreadNotification';
import NoUsers from './noUsers';
import OnBoardSignup from './onBoardSignup';
import PasswordSucess from './passwordSuccess';
import PasswordDisable from './passwordSetUpDisable';
import PasswordSetup from './passwordSetUp';
import Privacy from './privacy';
import Report from './report';
import Spaces from './spaces';
import Success from './success';
import SuccessfullyCreated from './successfullyCreated';
import Svg404 from './svg404';
import TipOfTheDay from './tipOfTheDay';
import Verification from './verification';
import Warning from './warning';
import WarningBlue from './warning_blue';
import Workspace from './workspace';

import { EMOJI_IDENTIFIER_COMPONENT_HASH } from './emoji';

export const SVG_ICONS = {
  additionallink: AdditionalLink,
  annualce: AnnualCe,
  archive_space: ArchiveSpace,
  cannotEdit: CannotEdit,
  cannotOpen: CannotOpen,
  cannotDelete: CannotDelete,
  changePassword: ChangePassword,
  collectionSpace: CollectionSpace,
  collaborationSpace: CollaborationSpace,
  creditCard: CreditCard,
  dropFiles: DropFiles,
  delete: Delete,
  delete_info: DeleteInfo,
  etiquette: Etiquette,
  emptyCareSpace: EmptyCareSpace,
  emptyUserCard: EmptyUserCard,
  emptyAddressCard: EmptyAddressCard,
  information: Information,
  invite: Invite,
  loader: Loader,
  login: Login,
  noCases: NoCases,
  noBoards: NoBoards,
  noPosts: NoPulse,
  noSpaces: NoSpaces,
  noComments: NoComments,
  noFiles: NoFiles,
  noNotes: NoNotes,
  noNotifications: NoNotification,
  noResults: NoResults,
  noUsers: NoUsers,
  noTools: NoTools,
  noUnreadNotifications: NoUnreadNotification,
  onBoardSignup: OnBoardSignup,
  passwordSuccess: PasswordSucess,
  passwordDisable: PasswordDisable,
  passwordSetup: PasswordSetup,
  privacy: Privacy,
  report: Report,
  success: Success,
  spaces: Spaces,
  successfullyCreated: SuccessfullyCreated,
  svg404: Svg404,
  tipOfTheDay: TipOfTheDay,
  verification: Verification,
  warning: Warning,
  warningBlue: WarningBlue,
  workspace: Workspace,
  ...EMOJI_IDENTIFIER_COMPONENT_HASH,
};

const Icon = (props) => {
  let Element = SVG_ICONS[props.name];
  return Element ? <Element {...props} /> : '';
};

export default Icon;
