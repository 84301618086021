import OrthoIcon from 'app/components/Shared/OrthoIcon';
import React from 'react';

const DisplayHeading = (props) => {
  const { iconName, heading } = props;
  return (
    <div className={'group-heading'}>
      <OrthoIcon name={iconName} dataHoverNotRequired={true} />
      <span>{heading}</span>
    </div>
  );
};

export default DisplayHeading;
