import { isEmpty, isObject, isUndefined } from 'app/utils/osLodash';

const FEATURE_HASH_FUNCTIONS_MAPPER = {
  'can_update_own_profile?': 'associatedRecord',
  'can_manage_own_profile?': 'associatedRecord',
  'associated_record?': 'associatedRecord',
  'can_associated_record_be_liked?': 'isInstanceOfAssociatedRecord',
  'can_associated_record_be_unliked?': 'isInstanceOfAssociatedRecord',
  'can_be_unliked?': 'isInstanceOf',
  'can_be_liked?': 'isInstanceOf',
  'can_be_followed?': 'isInstanceOf',
  'can_be_unfollowed?': 'isInstanceOf',
  'can_be_private?': 'isEntityCanBePrivate',
  'count_smaller_then?': 'isCountSmallerThan',
  'can_update_own_conversation?': 'associatedRecord',
  'can_destroy_own_conversation?': 'associatedRecord',
  'can_read?': 'notRestricted',
  'can_edit?': 'canEdit',
  'can_comment?': 'canComment',
  'community_only?': 'onlyCommunity',
  'can_view_index?': 'canViewListing',
};

const defaultCreateError = () => new Error('Authorization error');

export default class Ability {
  constructor(user, options) {
    options = options || {};
    this.user = user;
    this.abilities = [];
    this.createError = options.createError || defaultCreateError;
    this.init();
  }

  init() {
    let { permissions } = this.user;

    (permissions || []).forEach((permission) =>
      this.allow(
        permission.action,
        permission.model,
        JSON.parse(permission.conditional_hash),
        JSON.parse(permission.feature_hash),
      ),
    );
  }

  allow(action, target, conditionalHash, featureHash) {
    if (
      typeof conditionalHash !== 'undefined' &&
      typeof conditionalHash !== 'function' &&
      !isObject(conditionalHash)
    ) {
      throw new TypeError(
        `Expected conditionalHash to be object or function, got ${typeof conditionalHash}`,
      );
    }

    if (
      typeof featureHash !== 'undefined' &&
      typeof featureHash !== 'function' &&
      !isObject(featureHash)
    ) {
      throw new TypeError(
        `Expected featureHash to be object or function, got ${typeof featureHash}`,
      );
    }

    action = action.replace(/_action/g, '');
    target = target.replace(/_model/g, '');
    this.abilities.push({ action, target, conditionalHash, featureHash });
  }

  can(action, target, options = {}) {
    return true;
    return this.abilities
      .filter(
        (ability) => ability.target === 'all' || target === ability.target,
      )
      .filter(
        (ability) => ability.action === 'manage' || action === ability.action,
      )
      .some(
        (ability) =>
          !ability.featureHash ||
          ability.featureHash.length === 0 ||
          ability.featureHash.some((feature) => {
            let methodName = FEATURE_HASH_FUNCTIONS_MAPPER[feature['name']];

            return (
              this[methodName] &&
              this[methodName]({ ...options, target }, feature['arguments'])
            );
          }),
      );
  }

  cannot() {
    return !this.can.apply(this, arguments);
  }

  authorize() {
    if (this.cannot.apply(this, arguments)) {
      const err = this.createError.apply(null, arguments);
      throw err;
    }
  }

  associatedRecord = (frontendArguments, backendArguments) =>
    +this.user.id === +frontendArguments['userId'];
  canEdit = (frontendArguments, backendArguments) =>
    !!frontendArguments['canEdit'];
  canComment = (frontendArguments, backendArguments) =>
    frontendArguments['canComment'];
  isInstanceOf = (frontendArguments, backendArguments) =>
    backendArguments['type'] === frontendArguments['type'];
  isInstanceOfAssociatedRecord = (frontendArguments, backendArguments) => {
    return (
      this.isInstanceOf(frontendArguments, backendArguments) &&
      this.associatedRecord(frontendArguments, backendArguments)
    );
  };
  isEntityCanBePrivate = (frontendArguments, backendArguments) =>
    !!backendArguments['private'];
  isCountSmallerThan = (frontendArguments, backendArguments) =>
    this.user[`${backendArguments['association']}_count`] <
      backendArguments['count'] || isEmpty(backendArguments);
  notRestricted = (frontendArguments, backendArguments) =>
    !frontendArguments.resource.restricted;
  onlyCommunity = (frontendArguments, backendArguments) => false;
  canViewListing = (frontendArguments, backendArguments) => {
    let attributeValue =
      this.user[
        `${frontendArguments.target}${backendArguments['attribute_suffix']}`
      ];
    return isUndefined(attributeValue) || attributeValue;
  };
}
