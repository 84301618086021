import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import Result from 'app/components/NavSearch/Result';

import EventTracker from 'app/services/EventTracker';

import {
  setNavSearchClose,
  setNavSearchQuery,
  setNavSearchCategory,
} from 'app/actions/navSearch';
import { translate } from 'app/actions/flashMessage';
import { debounce, drop, orderBy, startCase, take } from 'app/utils/osLodash';

import { capitalize, humanize } from 'app/utils/stringHelper';
import { genericSearchUrl } from 'app/utils/entitiesHelper';

import {
  SEARCH_RESULTS_ORDER,
  INITIAL_SEARCH_RESULT_GROUP_COUNT,
  SEARCH_RESULTS_ORDERING_ATTRIBUTE_MAPPER,
} from 'app/constants.js';
import { isSearchPageRequired } from 'app/utils/featureHelper';

const COUNT_ATTRIBUTES = [
  'total_cases_count',
  'cases_count',
  'total_tools_count',
  'total_users_count',
  'total_boards_count',
  'users_count',
  'boards_count',
];
const SEARCH_QUERY = queryLoader('app/graphql/Search.gql');
const SEARCH_CATEGORY_MAPPER = {
  doctors: 'users',
  spaces: 'boards',
};

class AutoSearchSuggestInput extends Component {
  state = {
    query: '',
    inputActive: false,
    dropdownOpen: false,
  };

  getRightLabel(obj) {
    switch (this.props.navSearch.category) {
      case 'cases':
        return this.getRightLabelForCases(obj);
      case 'doctors':
        return this.getRightLabelForDoctors(obj);
      case 'spaces':
        return this.getRightLabelForBoards(obj);
      case 'tools':
        return this.getRightLabelForTools(obj);
      default:
        return '';
    }
  }

  getRightLabelForCases(obj) {
    if (obj.__typename === 'Tool') {
      return (
        obj.company.name +
        ' - ' +
        (obj.total_cases_count || obj.cases_count || '0')
      );
    } else {
      return obj.total_cases_count || obj.cases_count || '';
    }
  }

  getRightLabelForDoctors(obj) {
    if (obj.__typename === 'Tool') {
      return (
        obj.company.name +
        ' - ' +
        (obj.total_users_count || obj.users_count || '0')
      );
    } else {
      return obj.total_users_count || obj.users_count || '';
    }
  }

  getRightLabelForBoards(obj) {
    if (obj.__typename === 'Tool') {
      return (
        obj.company.name +
        ' - ' +
        (obj.total_boards_count || obj.boards_count || '0')
      );
    } else {
      return obj.total_boards_count || obj.boards_count || '';
    }
  }

  getRightLabelForTools(obj) {
    if (obj.__typename === 'Tool') {
      return obj.company.name;
    } else {
      return obj.total_tools_count;
    }
  }

  getSearchOrder() {
    let searchResult = SEARCH_RESULTS_ORDER[this.props.navSearch.category];
    if (isSearchPageRequired()) {
      searchResult = searchResult && ['spaces'];
    }
    return searchResult;
  }

  isSourceMainSearch() {
    return this.props.source === 'Nav Search';
  }

  isTrackingEnabled() {
    return this.isSourceMainSearch();
  }

  trackSearchTerm() {
    if (this.isTrackingEnabled() && this.state.query)
      EventTracker.track('Search - Suggest', {
        search_term: this.state.query,
        entity: capitalize(this.props.type),
      });
  }

  trackTabChange() {
    if (this.isTrackingEnabled() && this.state.query)
      EventTracker.track('Search Results - Tab Select', {
        search_term: this.state.query,
        title: capitalize(this.props.type),
      });
  }

  trackSearchSuggestionClick(type) {
    if (this.isTrackingEnabled())
      EventTracker.track('Search - Suggestion Click', {
        search_term: this.state.query,
        type: type,
        entity: capitalize(this.props.type),
      });
  }

  componentDidMount() {
    if (!this.props.dropDown) this.search.focus();
    this.props.setNavSearchCategory(this.props.type);
    if (this.props.clearSearchFilter) this.resetSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) {
      this.props.setNavSearchCategory(this.props.type);
      this.trackTabChange();
      this.setState({ viewMore: null });
    }

    if (!prevProps.clearSearchFilter && this.props.clearSearchFilter)
      this.resetSearch();
  }

  setSearchText = debounce((searchText) => {
    this.setState({ query: searchText, viewMore: null });
    if (this.props.onChange) {
      this.props.onChange(searchText);
    } else {
      this.performSearch(searchText);
      if (searchText === '') this.resetSearch();
    }
  }, 200);

  handleChange = (event) => {
    if (!this.isFolderType()) this.setSearchText(event.target.value);
    this.props.onHandleChange(event.target.value);
  };

  performSearch = debounce((value) => {
    this.trackSearchTerm();
    this.props.setNavSearchQuery(value);
  }, 200);

  renderGroup = (name) => {
    var objects = this.props.data.search[name];
    let results = this.filteredCollection(objects),
      groupData = this.groupData(name);

    if (results.length === 0) return null;

    let moreResults = [];
    if (this.state.viewMore !== name) {
      moreResults = drop(results, INITIAL_SEARCH_RESULT_GROUP_COUNT);
      results = take(results, INITIAL_SEARCH_RESULT_GROUP_COUNT);
    }

    return (
      <div key={name} className='search-group'>
        {this.renderHeader(groupData)}
        {results.map((result) => this.renderResult(result, groupData))}
        {this.renderViewAllLabel(moreResults, name)}
      </div>
    );
  };

  renderResult = (result, groupData) => {
    return (
      <Result
        obj={result}
        key={`${result.__typename}:${result.id}`}
        category={this.props.navSearch.category}
        categoryType={groupData.name}
        rightLabel={this.getRightLabel(result)}
        onClick={this.props.onClick ? this.onSearchResultClick : null}
        trackOnClick={this.isTrackingEnabled() ? this.trackOnClick : null}
        mobileDevice={this.props.device.mobileDevice}
      />
    );
  };

  trackOnClick = (data) => {
    this.trackSearchSuggestionClick(data.categoryType);
  };

  onSearchResultClick = (data) => {
    if (this.props.dropDown) this.search.blur();

    if (this.props.onClick) this.props.onClick(data);
  };

  renderHeader(groupData) {
    return (
      <div className='search-group-headding text-uppercase'>
        <i className={`${groupData.icon} search-group-icon`}></i>
        {groupData.name}
        <span className='search-group-info-icon float-right'>
          <OrthoIcon name='info' defaultClass='action-link-info' />

          <span className='search-group-info-tooltip'>{groupData.name}</span>
        </span>
      </div>
    );
  }

  renderViewAllLabel(moreResults, name) {
    let displayName = name === 'boards' ? 'spaces' : name;
    return (
      <div className='search-view-all' onMouseDown={(e) => e.preventDefault()}>
        {moreResults.length > 0 && (
          <OsLink
            onClick={this.onViewAllClick.bind(this, name)}
            text={`View ${moreResults.length} More ${humanize(displayName)}`}
          />
        )}
      </div>
    );
  }

  onViewAllClick = (name) => {
    this.setState({ viewMore: name });
  };

  searchIcon(categoryName, groupName) {
    if (
      this.props.navSearch.category === categoryName ||
      categoryName === 'categories'
    ) {
      return groupName && groupName.includes('WITH ID')
        ? 'ifill-os-hashtag'
        : 'icon-tags';
    } else if (categoryName === 'tools') {
      return 'ifill-os-toolbox';
    } else if (categoryName === 'doctors') {
      return 'ifill-os-doctors';
    } else if (categoryName === 'companies') {
      return 'ifill-os-company';
    } else if (categoryName === 'cases') {
      return 'ifill-os-fill-case-2-d';
    }
  }

  groupNewName(name) {
    if (name === 'tags') {
      return this.props.navSearch.category === 'cases'
        ? this.getDisplayCategoryName() + ' with Tag'
        : this.getDisplayCategoryName() + ' by Cases';
    } else if (name === 'tool_tags') {
      return this.props.navSearch.category === 'cases'
        ? this.getDisplayCategoryName() + ' with Tool Tag'
        : this.getDisplayCategoryName() + ' with Tag';
    } else if (name === 'tools' && this.props.navSearch.category !== name) {
      return this.getDisplayCategoryName() + ' using ' + humanize(name);
    } else if (name === this.props.navSearch.category) {
      return this.getDisplayCategoryName() + ' with ID';
    } else {
      return this.getDisplayCategoryName() + ' by ' + humanize(name);
    }
  }

  getDisplayCategoryName() {
    const { category } = this.props.navSearch;
    return startCase(category);
  }

  groupData(name) {
    let displayName, icon, groupName;
    let order = this.getSearchOrder();
    if (name === 'companies') {
      displayName = name;
    } else if (name === 'tool_tags') {
      displayName = 'categories';
    } else if (name === 'tags') {
      displayName = 'cases';
    } else if (name === 'boards') {
      displayName = 'spaces';
    } else {
      displayName = name;
    }

    if (['cases', 'tools'].includes(this.props.navSearch.category)) {
      groupName = this.groupNewName(name);
    }
    icon = this.searchIcon(displayName, groupName);
    if (['cases', 'tools'].includes(this.props.navSearch.category)) {
      return { name: groupName, icon: icon };
    }
    // If it is not the first in the list add XXXX by to the front
    if (order[0] === displayName) {
      return { name: displayName, icon: icon };
    }
    return {
      name: this.getDisplayCategoryName() + ' by ' + humanize(displayName),
      icon: icon,
    };
  }

  genericResultRequired() {
    return !this.isSourceMainSearch();
  }

  getDefaultData() {
    if (this.genericResultRequired()) {
      return [];
    } else {
      return [this.renderMoreGenericSearchRow()];
    }
  }

  isFolderType() {
    return this.props.type === 'folders';
  }

  renderResults() {
    let resultsContent = [],
      data = this.getDefaultData(),
      collection = [];
    const order = this.getSearchOrder();
    if (this.props.data && this.props.data.search)
      order.forEach((name) => collection.push(this.props.data.search[name]));

    let anyCollectionPresent = collection.some(
      (list) => list && this.isFilteredCollectionPresent(list),
    );

    if (!anyCollectionPresent) {
      if (this.props.navSearch.query) {
        if (this.genericResultRequired()) data = [this.renderNoResult()];
      } else {
        return <div className='search-results'></div>;
      }
    } else {
      data = data.concat([order.map(this.renderGroup)]);
    }

    resultsContent.push(data);
    return <div className='search-results'>{resultsContent}</div>;
  }

  redirectToGenericSearch = () => {
    this.props.setNavSearchClose();
    this.props.navigate(genericSearchUrl(this.props.navSearch.query));
  };

  renderMoreGenericSearchRow() {
    return (
      <div className='search-group'>
        <div
          key='no-results'
          className='search-group-headding text-uppercase'
          onClick={this.redirectToGenericSearch}>
          <i className='icon-tags search-group-icon'></i>
          Specific Search
        </div>
        <div className='search-result' onClick={this.redirectToGenericSearch}>
          {translate('SEARCH_MORE_GENERIC')}
        </div>
      </div>
    );
  }

  renderNoResult() {
    return (
      <div key='no-results' className='search-group'>
        <div className='search-group-headding'>
          {translate('RESULTS_NOT_FOUND', {
            searchInput: this.props.navSearch.query,
          })}
        </div>
      </div>
    );
  }

  searchResultNotPresent() {
    return !(
      (this.props.data && this.props.data.search) ||
      this.props.navSearch.query.length
    );
  }

  dropDownOpenRequired() {
    return (
      (this.state.dropdownOpen && this.props.dropDown) || !this.props.dropDown
    );
  }

  isFilteredCollectionPresent(list) {
    return this.filteredCollection(list).length > 0;
  }

  getListSortOrderAttribute(object) {
    if (object && object.__typename) {
      let objectOrderingMapper =
        SEARCH_RESULTS_ORDERING_ATTRIBUTE_MAPPER[object.__typename];
      return (
        objectOrderingMapper &&
        (objectOrderingMapper[this.props.navSearch.category] ||
          objectOrderingMapper['all'])
      );
    }
  }

  filteredCollection(list = []) {
    let orderingAttribute = this.getListSortOrderAttribute(list[0]);
    list = list.filter((obj) => this.isResultsPresent(obj));
    return orderingAttribute ? orderBy(list, orderingAttribute, 'desc') : list;
  }

  isResultsPresent(result) {
    return (
      this.isSameObjectAsCategory(result) ||
      !COUNT_ATTRIBUTES.some((attr) => +result[attr] === 0)
    );
  }

  isSameObjectAsCategory(result) {
    return (
      (this.props.navSearch.category === 'tools' &&
        result.__typename === 'Tool') ||
      (this.props.navSearch.category === 'doctors' &&
        result.__typename === 'User') ||
      (this.props.navSearch.category === 'spaces' &&
        result.__typename === 'Board') ||
      (this.props.navSearch.category === 'cases' &&
        result.__typename === 'Case')
    );
  }

  resetSearch = () => {
    this.search.value = '';
    this.setState({ query: '' });
    this.props.setNavSearchQuery('');
    this.props.onHandleChange('');
    //As this is a generic component don't want to change this cross to OS Button
    EventTracker.trackOsBtnClick('clear');
    if (this.props.onSearchReset)
      this.props.onSearchReset({
        isFocused: this.search === document.activeElement,
      });
  };

  getDisplayCategory() {
    let currentUser = this.props.currentUser.graph,
      category = this.isFolderType() ? 'Folder' : this.props.navSearch.category;

    return currentUser && currentUser.is_general_user && category === 'doctors'
      ? 'members'
      : category;
  }

  placeholder() {
    if (this.props.singleEntitySearch) return 'Start typing keywords here';

    let displayCategory = this.getDisplayCategory(),
      searchableEntities =
        this.props.navSearch.category === 'tools'
          ? 'Tools and Companies'
          : capitalize(displayCategory);

    return `Start typing keywords here to find ${searchableEntities} Cards...`;
  }

  setInputActive = () => {
    this.setState({ inputActive: true });
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className='input-wrapper nav-search-holder input-focus'>
          <div
            className={`nav-search-container ${
              this.state.inputActive ? 'input-focus-active' : ''
            }`}
            onFocus={this.setInputActive}
            onBlur={this.unsetInputActive}>
            <label className='position-relative m-0 w-100'>
              <OrthoIcon name='search' />
              <OsField
                osType='input'
                type='text'
                placeholder={this.props.placeholder || this.placeholder()}
                saveInputRef={(search) => {
                  this.search = search;
                }}
                className={'nav-search-input'}
                onChange={this.handleChange}
                onFocus={() => this.setState({ dropdownOpen: true })}
                onBlur={() => this.setState({ dropdownOpen: false })}
                isWrapperRequired={false}
                name='search'
              />
              <div
                className='clear-input ifill-os-clear'
                onClick={this.resetSearch}></div>
            </label>

            {this.props.children}
          </div>
          {!this.isFolderType() &&
            !this.searchResultNotPresent() &&
            this.dropDownOpenRequired() &&
            this.state.query &&
            this.renderResults()}
        </div>
      </div>
    );
  }
}

AutoSearchSuggestInput = withRouter(AutoSearchSuggestInput);

AutoSearchSuggestInput = compose(
  graphql(SEARCH_QUERY, {
    skip: (props) => props.navSearch.query.trim().length < 3,
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        query: props.navSearch.query,
        category:
          SEARCH_CATEGORY_MAPPER[props.navSearch.category] ||
          props.navSearch.category,
        casesRequired: props.navSearch.category === 'cases',
        usersRequired: props.navSearch.category === 'doctors',
        toolsRequired: props.navSearch.category === 'tools',
        boardsRequired: props.navSearch.category === 'spaces',
        companiesRequired:
          props.navSearch.category === 'tools' ||
          props.navSearch.category === 'companies',
        toolTypesRequired: props.navSearch.category === 'tools',
        comparisonsRequired: props.navSearch.category === 'comparisons',
        filter: props.filter || '',
        additional_filters: JSON.stringify(
          !!props.additional_filters ? props.additional_filters : {},
        ),
        object_filter:
          props.navSearch.category === 'tools' ? props.object_filter : '',
      },
    }),
  }),
)(AutoSearchSuggestInput);

AutoSearchSuggestInput = connect(
  ({ navSearch, currentUser, device }) => ({ navSearch, currentUser, device }),
  {
    setNavSearchQuery,
    setNavSearchCategory,
    setNavSearchClose,
  },
)(AutoSearchSuggestInput);
AutoSearchSuggestInput.defaultProps = {
  handleQuery: () => {},
  onHandleChange: () => {},
};
AutoSearchSuggestInput = withRouter(AutoSearchSuggestInput);
export default AutoSearchSuggestInput;
