import {
  CLOSE_EDIT_IMAGE_MODAL,
  OPEN_EDIT_IMAGE_MODAL,
} from 'app/actions/types';

export default function editImage(
  state = {
    open: false,
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_EDIT_IMAGE_MODAL:
      return { open: true, options: action.options };
    case CLOSE_EDIT_IMAGE_MODAL:
      return { open: false, options: {} };
    default:
      return state;
  }
}
