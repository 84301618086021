import React, { PureComponent } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

class AddressLink extends PureComponent {
  render() {
    let { address, icon } = this.props;
    return (
      <address>
        {icon && <OrthoIcon name='location-fill' dataHoverNotRequired={true} />}
        <OsLink
          href={`http://maps.google.com/maps?q=${address}`}
          className='white-link'
          text={address}
          target='_blank'
        />
      </address>
    );
  }
}

AddressLink.defaultProps = {
  icon: false,
};

export default AddressLink;
