import React, { Component } from 'react';

import { translate } from 'app/actions/flashMessage';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

class PrivateCard extends Component {
  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  getCopy = () => {
    const [type] = (this.props.obj.id || '').split(':');
    switch (type) {
      case 'ConversationMessage':
        return 'Message from Private Conversation';
      case 'Comment':
        return 'Comment from Private Group';
      default:
        return translate('PRIVATE_CARD_TEXT', {
          type: `${this.props.obj.type || 'attachment'}.`,
        });
    }
  };

  render() {
    return (
      <div className={this.props.containerClasses}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            defaultClass='os-close-link'
            onClick={this.afterClose}
          />
        )}

        <div className='m-auto text-center white-spc-normal'>
          <div className='mb-2'>
            <OrthoIcon name='locked' dataHoverNotRequired={true} />
          </div>
          {this.getCopy()}
        </div>
      </div>
    );
  }
}

export default PrivateCard;
