import storeUpdater from 'app/services/StoreUpdater';
import { loader as queryLoader } from 'graphql.macro';
import {
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
} from 'app/utils/timeHelper';
import {
  FORMAT_START_WITH_DATE_DASH,
  TASK,
  STATUS,
} from 'app/components/Task/taskConstant';
import { currentUser } from 'app/utils/userHelper';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  CREATE_NEW_GROUP,
  SEARCH_FOR_PATIENT,
  SHOW_CALENDAR_VIEW,
  SHOW_INBOX_VIEW,
  SHOW_LIST_VIEW,
} from 'app/components/DnD/CmdMenu/constants';
const ACTIVE_TASKS = queryLoader('app/graphql/queries/Task/TaskByWeek.gql');
const IN_PROGRESS_TASKS = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);

const useOperation = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const handleAddTaskUpdateCache = (entity) => {
    if (!location.pathname.includes(TASK)) {
      return null;
    } else if (location.pathname.includes(STATUS)) {
      // This block handles the caching update for the STATUS view
      let idQuery = location.pathname.includes('me')
        ? entity.assignee.id
        : searchParams.get('user') || null;
      const variables = {
        page: 0,
        type: 'InProgressTasks',
        perPage: 50,
        text_query: null,
        id_query: idQuery,
        id_type: 'User',
        searchQuery: null,
        sortQuery: 'due_date_asc',
      };
      storeUpdater.addDataInRecords(entity, variables, {
        query: IN_PROGRESS_TASKS,
      });
    } else {
      // this block handles the caching update for the list view and the calendar view
      // Only for the active task and not for any other section
      let assignees = [];
      if (location.pathname.includes('me')) {
        assignees = [`User: ${currentUser().id}`];
      } else if (!!searchParams.get('user')) {
        assignees = [`User: ${searchParams.get('user')}`];
      }
      const variables = {
        date: getDateFromStringWithGivenFormat(
          currentTimeWithUserTimeZone(),
          FORMAT_START_WITH_DATE_DASH,
        ),
        labels: null,
        assignees: assignees,
        query: null,
      };
      storeUpdater.addTaskInRecord(entity, variables, {
        query: ACTIVE_TASKS,
      });
    }
  };

  const isActionCommand = (command) => {
    return [
      CREATE_NEW_GROUP,
      SEARCH_FOR_PATIENT,
      SHOW_CALENDAR_VIEW,
      SHOW_INBOX_VIEW,
      SHOW_LIST_VIEW,
    ].includes(command);
  };

  return {
    handleAddTaskUpdateCache,
    isActionCommand,
  };
};

export default useOperation;
