import { openPartnerSpaceInviteModal } from 'app/actions/partnerSpaces';
import usePartnersListing from 'app/components/Partners/usePartnersListing';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { uniqBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  convertTextToTitleCase,
  getPartnerClinicLogo,
  getPartnerClinicName,
} from 'app/utils/spaces/partnerHelper';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { hexToRgb } from 'app/utils/colorHelper';
import Avatar from 'app/components/Shared/Avatar';
import { SET_PARTNER_SPACE_LABEL } from 'app/actions/types';
import { removeAllSearchParams } from 'app/utils/urlHelper';
import { useLazyQuery } from '@apollo/client';
import { getDevice } from 'app/selectors/device';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { loader as queryLoader } from 'graphql.macro';

import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export const PARTNER_SPACE_NOTIFICATION_COUNT = queryLoader(
  'app/graphql/queries/partnerSpaces/PartnerSpaceNotificationCount.gql',
);

const PARTNER_SPACE = 'partner_space';

const PartnerSpacesHeader = ({ item }) => {
  const {
    headerOnClick,
    name,
    niceId,
    showPinnedIcon,
    logo,
    notificationCount,
    mentionNotificationPresent,
    isCurrentViewing,
  } = item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pinPartnerSpace = () => {
    // Need backend support.
  };

  const handleHeaderClick = () => {
    if (headerOnClick) {
      switch (name) {
        case 'invite_partner_clinic':
          dispatch(openPartnerSpaceInviteModal());
          break;
        case 'partner_space':
          navigate(`partners/${niceId}`);
          dispatch({
            type: SET_PARTNER_SPACE_LABEL,
            payload: {
              selectedLabel: null,
            },
          });
          break;
        default:
          return null;
      }
    }
  };
  return (
    <Accordion.Header onClick={handleHeaderClick}>
      {logo ? (
        <Avatar
          className='avatar avatar-40 partner-space-listing-avatar me-1'
          name={logo}
          src={logo}
        />
      ) : (
        <OrthoIcon
          name={item.icon}
          defaultClass={`me-1 ${showPinnedIcon && 'default-doctor-icon'}`}
        />
      )}
      {showPinnedIcon && (
        <OrthoIcon
          name={'pin'}
          defaultClass={'me-1 pinned-icon'}
          onClick={pinPartnerSpace}
        />
      )}
      <span className={'ac-heading'}>{item.header}</span>
      {!isCurrentViewing && notificationCount > 0 && (
        <>
          {mentionNotificationPresent && (
            <span className={'direct-mention-notification'} />
          )}
          <span className='lt-count'>{notificationCount}</span>
        </>
      )}
    </Accordion.Header>
  );
};

const PartnerSpaceItemList = ({ item }) => {
  let { linkedEntities } = item;
  const uncategorizedNotificationCount =
    item.notificationCount -
    linkedEntities.reduce((count, data) => {
      count += data.notifications_count;
      return count;
    }, 0);

  const mentionNotificationPresent =
    item.mentionNotificationPresent &&
    linkedEntities.some(
      (linkedEntity) => !linkedEntity.mention_notification_present,
    );

  return (
    <Accordion.Body>
      <div className='partner-subspace-list'>
        {linkedEntities.map((data) => {
          return <PartnerSpaceItemSubLabel entity={data} item={item} />;
        })}
        <PartnerSpaceItemSubLabel
          entity={{
            id: '-1',
            entity: {
              id: 'uncategorized',
              name: 'Uncategorized',
              __typename: 'Label',
            },
            notifications_count: uncategorizedNotificationCount,
            mention_notification_present: mentionNotificationPresent,
          }}
          item={item}
        />
      </div>
    </Accordion.Body>
  );
};

const PartnerSpaceItemSubLabel = ({ entity, item }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSetOnLoad = useRef(false);
  const handleSubMenuOption = (event, { __typename, id }) => {
    event.stopPropagation();
    removeAllSearchParams(searchParams);
    searchParams.set('id', `${__typename}:${id}`);
    setSearchParams(searchParams);
    navigate({
      pathname: `partners/${item.niceId}`,
      search: createSearchParams(searchParams).toString(),
    });
    dispatch({
      type: SET_PARTNER_SPACE_LABEL,
      payload: {
        selectedLabel: entity.entity,
      },
    });
  };
  const labelType = entity?.entity?.__typename;
  const labelColor = entity.entity?.color;

  useEffect(() => {
    if (
      searchParams.get('id') ===
        `${entity.entity.__typename}:${entity.entity.id}` &&
      !isSetOnLoad.current
    ) {
      isSetOnLoad.current = true;
      dispatch({
        type: SET_PARTNER_SPACE_LABEL,
        payload: {
          selectedLabel: entity.entity,
        },
      });
    }
  }, []);

  const selectedEntityId = searchParams.get('id');
  return (
    <div
      key={entity.id}
      className={`subspace-list-row ${
        selectedEntityId ===
          `${entity.entity.__typename}:${entity.entity.id}` && 'active'
      }`}
      onClick={(event) => handleSubMenuOption(event, entity.entity)}>
      {labelType === 'Person' && (
        <div className={'person-option-container'}>
          <Avatar
            className='avatar avatar-40 partner-space-listing-avatar me-1'
            name={entity.entity?.large_avatar}
            src={entity.entity?.large_avatar}
          />
        </div>
      )}
      {labelColor && (
        <span
          className={'label-option-color'}
          style={{
            backgroundColor: `${hexToRgb(`#${labelColor}`)}`,
          }}></span>
      )}
      <span className='list-name flex-1'>{entity.entity?.name}</span>
      {entity.mention_notification_present && (
        <span className={'direct-mention-notification'} />
      )}
      <span className='lt-count pull-right'>
        {entity.notifications_count > 0 && entity.notifications_count}
      </span>
    </div>
  );
};

const PartnerSpacesAccordian = (props) => {
  const { accordionItems, stickyTop, className, accordionClass } = props;
  const { data, loading } = usePartnersListing();
  const [accordionListItems, setAccordionListItems] = useState(accordionItems);
  const [currentActiveKey, setCurrentActiveKey] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const device = useSelector(getDevice);
  const { locationState = {} } = useLocation();

  const [fetchNotificationsCount, { data: countsData }] = useLazyQuery(
    PARTNER_SPACE_NOTIFICATION_COUNT,
  );
  useEffect(() => {
    if (!id && currentActiveKey[0] && !device.mobileDevice) {
      window.history.replaceState(
        locationState,
        '',
        withWorkspaceIdentifier(`/partners/${currentActiveKey[0]}`, true),
      );
    }
  }, [id, currentActiveKey, navigate, device.mobileDevice]);

  const mappedEntitiesNotificationMapping = useMemo(() => {
    if (countsData?.space) {
      return countsData.space.mapped_entities.reduce((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [countsData]);

  const getAccordionList = (item) => {
    const className =
      item.name === 'invite_partner_clinic' ? 'hidden-mobile' : '';
    return (
      <Accordion.Item
        key={item.id}
        eventKey={item.niceId}
        className={className}>
        <PartnerSpacesHeader item={item} />
        {item.name === PARTNER_SPACE && <PartnerSpaceItemList item={item} />}
      </Accordion.Item>
    );
  };

  useEffect(() => {
    if (!loading) {
      setCurrentActiveKey((prevState) => {
        if (prevState.length > 0) {
          return prevState;
        } else {
          return [data.records_pager.results?.[0]?.nice_id?.toString()];
        }
      });
    }
  }, [loading, data]);

  useEffect(() => {
    if (currentActiveKey.length > 0 && data?.records_pager) {
      setAccordionListItems((prevState) => {
        let partnerSpaces = data.records_pager.results?.map((clinic, index) => {
          return {
            id: clinic.id,
            activeKeyValue: index.toString(),
            niceId: clinic.nice_id,
            isCurrentViewing:
              currentActiveKey && currentActiveKey[0] === clinic.nice_id,
            header: convertTextToTitleCase(getPartnerClinicName(clinic)),
            headerAction: false,
            showPinnedIcon: false,
            icon: 'partner-doctor',
            logo: getPartnerClinicLogo(clinic),
            actionRequired: false,
            key: 'TeamSpace',
            name: PARTNER_SPACE,
            headerOnClick: true,
            mentionNotificationPresent: clinic?.mention_notification_present,
            notificationCount: clinic?.notifications_count,
            linkedEntities: clinic.mapped_entities.map((entity) => ({
              ...entity,
              ...(mappedEntitiesNotificationMapping[entity.id] || {}),
            })),
          };
        });
        return uniqBy(
          [...accordionItems, ...partnerSpaces, ...prevState],
          'id',
        );
      });
    }
  }, [currentActiveKey, data]);

  useEffect(() => {
    if (id) setCurrentActiveKey(() => [id]);
  }, [id]);

  useEffect(() => {
    if (currentActiveKey[0]) {
      fetchNotificationsCount({
        variables: {
          id: currentActiveKey[0],
        },
      });
    }
  }, [currentActiveKey, fetchNotificationsCount]);

  return (
    <article
      className={className}
      id={'left_side_bar'}
      style={{ top: stickyTop }}>
      <Accordion
        alwaysOpen={false}
        activeKey={currentActiveKey}
        className={`${accordionClass} partners`}>
        {accordionListItems.map(getAccordionList)}
      </Accordion>
    </article>
  );
};

export default PartnerSpacesAccordian;
