import { reduce, keys } from 'app/utils/osLodash';

export const ADD_RESTRICTED_ENTITIES = ['board', 'user', 'comment', 'pulse'];
export const ALL_LINKS_TAB_TEXT = 'Added content';
export const SHARED_SPACE_AUTHENTICATED = 'sharedSpaceAuthenticated';
export const CONTENT_ACCESSIBLE_PERMISSION_TYPES = [
  'all_access',
  'content_visible',
];
export const EDUCATORS_NAMES_MAX_LIMIT_FOR_WEB = 3;
export const EDUCATORS_NAMES_MAX_LIMIT_FOR_MOBILE = 1;
export const MEMBER_COUNT_MIN_LIMIT_TO_DISPLAY = 50;
export const NAV_LIST_DETAILS_MAPPER = {
  Information: { pathname: 'information' },
  // 'Added content': {
  //   attribute: 'added_contents_count',
  //   pathname: 'all',
  //   type: 'all',
  // },
  // 'All Contents': {
  //   attribute: 'added_contents_count',
  //   pathname: 'all_contents',
  //   type: 'all',
  // },
  Cases: { attribute: 'cases_count', pathname: 'cases', type: 'Case' },
  Posts: { attribute: 'pulses_count', pathname: 'posts', type: 'Pulse' },
  Comments: {
    attribute: 'associated_comments_count',
    pathname: 'comments',
    type: 'Comment',
  },
  Spaces: { attribute: 'boards_count', pathname: 'spaces', type: 'Board' },
  Comparisons: {
    attribute: 'comparisons_count',
    pathname: 'comparisons',
    type: 'Comparison',
  },
  Tools: { attribute: 'tools_count', pathname: 'tools', type: 'Tool' },
  Companies: {
    attribute: 'companies_count',
    pathname: 'companies',
    type: 'Company',
  },
  'Videos & files': {
    attribute: 'attachments_count',
    pathname: 'files',
    type: 'Attachment',
  },
  Discussions: { attribute: 'unseen_comments_count', pathname: 'discussions' },
  Members: { attribute: 'members_count', pathname: 'members' },
  Followers: { attribute: 'following_users_count', pathname: 'followers' },
  Lectures: {
    attribute: 'lectures_count',
    pathname: 'lectures',
    type: 'Attachment',
  },
  'Sub Spaces': {
    attribute: 'sub_spaces_count',
    pathname: 'subspaces',
    type: 'SubSpaces',
  },
  Speakers: {
    attribute: 'speakers_count',
    pathname: 'speakers',
    type: 'EventSpaceSpeakers',
    addNewLinkNotRequired: true,
  },
  Sponsors: {
    attribute: 'sponsors_count',
    pathname: 'sponsors',
    type: 'SpaceSponsors',
    addNewLinkNotRequired: true,
  },
  Showcase: {
    attribute: 'showcase_count',
    pathname: 'showcase',
    type: 'Attachment',
  },
  'Products/Services': {
    attribute: 'products_count',
    pathname: 'products',
    type: 'Product',
  },
  KOL: { attribute: 'kol_count', pathname: 'kol', type: 'SpaceKOL' },
  Team: { attribute: 'members_count', pathname: 'team' },
  Documents: {
    attribute: 'attachments_count',
    pathname: 'documents',
    type: 'Attachment',
  },
  Education: {
    attribute: 'boards_count',
    pathname: 'education',
    type: 'Board',
  },
};
export const NAV_LIST_PATHNAME_DETAILS_MAPPER = reduce(
  keys(NAV_LIST_DETAILS_MAPPER),
  (hash, title) => {
    hash[NAV_LIST_DETAILS_MAPPER[title].pathname] = {
      ...NAV_LIST_DETAILS_MAPPER[title],
      title,
    };
    return hash;
  },
  {},
);
export const SPACE_LINKS_COUNT_ATTRIBUTES = [
  'boards_count',
  'cases_count',
  'associated_comments_count',
  'companies_count',
  'comparisons_count',
  'pulses_count',
  'tools_count',
  'attachments_count',
];

export const SPACE_DROPDOWN_NAV_LIST = [
  [`${ALL_LINKS_TAB_TEXT}`, `${ALL_LINKS_TAB_TEXT}`, 'all'],
  ['Cases', 'Cases', 'cases'],
  ['Posts', 'Posts', 'posts'],
  ['Comments', 'Comments', 'comments'],
  ['Spaces', 'Spaces', 'spaces'],
  // ['Comparisons', 'Comparisons', 'comparisons'],
  ['Tools', 'Tools', 'tools'],
  ['Companies', 'Companies', 'companies'],
  ['Videos & files', 'Videos & files', 'files'],
];

export const SPACE_DETAIL_RECORDS_PER_PAGE = 12;
export const SPACE_SECTION_LINKS_REQUIRED_PER_PAGE = 12;
export const TABS_WITH_ADDITIONAL_URL_FILTER = ['discussions'];
export const PENDING_STATUS = ['pending'];
export const REJECTED_STATUS = ['canceled', 'expired', 'rejected'];

export const SPACE_NAV_LIST_FOR_TEAM_MOBILE = [
  {
    icon: 'send',
    label: 'MESSAGE YOUR PATIENT',
    filter: 'message_your_patient',
  },
  { icon: 'locked', label: 'ADD INTERNAL NOTE', filter: 'add_internal_note' },
  {
    icon: 'case-heart',
    label: 'SHOW ACTIVITY',
    filter: 'activity/all',
    countRequired: true,
  },
  {
    icon: 'calendar',
    label: 'SCHEDULED POSTS',
    filter: 'scheduled_posts',
    countRequired: true,
  },
  { icon: 'add', label: 'Add NEW TASK', filter: 'new task' },
  { icon: 'task', label: 'RELATED TASKS', filter: 'related_tasks' },
  { icon: 'case-person', label: 'PATIENT DETAILS', filter: 'patient_details' },
  { icon: 'folder', label: 'FILES', filter: 'files', countRequired: true },
];

export const SPACE_NAV_LIST_FOR_PATIENT_MOBILE = [
  {
    icon: 'send',
    label: 'MESSAGE THE CLINIC',
    filter: 'message_the_clinic',
  },
  { icon: 'case-heart', label: 'SHOW ACTIVITY', filter: 'activity/all' },
  { icon: 'folder', label: 'FILES', filter: 'files' },
  { icon: 'case-person', label: 'PATIENT DETAILS', filter: 'patient_details' },
  {
    icon: 'clinicSpace',
    label: 'CLINIC INFORMATION',
    filter: 'clinic_details',
  },
];

export const SPACE_ACTIVITY_FEED_FILTERS = {
  dropdown_options: [
    {
      label: 'Show All Activity',
      value: 'All Activity',
      url: 'all',
    },
    {
      label: 'Only Patient Activity',
      value: 'Patient Activity',
      url: 'patient',
    },
    {
      label: 'Only Internal Activity',
      value: 'Internal Activity',
      url: 'internal',
    },
  ],
  feed_type_mapper: {
    all: 'All Activity',
    patient: 'Patient Activity',
    internal: 'Internal Activity',
  },

  feed_type_url_mapper: {
    all: null,
    patient: 'patient_activity',
    internal: 'internal_activity',
  },
  path_mapper: {
    'All Activity': 'activity/all',
    'Patient Activity': 'activity/patient',
    'Internal Activity': 'activity/internal',
  },
};
