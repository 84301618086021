import { OPEN_ATTACHMENT_PREVIEW, CLOSE_ATTACHMENT_PREVIEW } from './types';

import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';

export function openAttachmentPreview(
  source,
  attachments,
  activeAttachment,
  author,
  options = {},
) {
  return (dispatch) => {
    addClassToHTML('fullscreen-modal-active');
    dispatch({
      type: OPEN_ATTACHMENT_PREVIEW,
      attachments,
      activeAttachment,
      author,
      options,
      source,
    });
  };
}

export function closeAttachmentPreview() {
  return (dispatch) => {
    removeClassFromHTML('fullscreen-modal-active');
    dispatch({
      type: CLOSE_ATTACHMENT_PREVIEW,
    });
  };
}
