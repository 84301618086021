import {
  COMMENT_SHARE_OPEN,
  COMMENT_SHARE_CHANGE_TYPE,
  COMMENT_SHARE_ASSIGN,
  COMMENT_SHARE_CONSUME,
} from './types';
import { isSourceWorkspace } from 'app/utils/Workspace/generalHelper';

export function commentShareOpen(
  type,
  source = 'Message',
  files = [],
  objects = [],
  options = {},
) {
  return (dispatch) => {
    options.latestVersion =
      isSourceWorkspace() &&
      ['add-to-folder', 'attach-to-folder'].includes(type);
    options.tabsNotRequired = isSourceWorkspace()
      ? (options.tabsNotRequired || []).concat([
          'organization',
          'tool',
          'case',
          'video',
        ])
      : options.tabsNotRequired;
    dispatch({
      type: COMMENT_SHARE_OPEN,
      payload: type,
      source: source,
      files: files,
      objects: objects,
      options,
    });
  };
}

export function commentShareChangeType(type) {
  return (dispatch) => {
    dispatch({
      type: COMMENT_SHARE_CHANGE_TYPE,
      payload: type,
    });
  };
}

export function commentShareAssign(cards, skipClose = false) {
  return (dispatch) => {
    dispatch({
      type: COMMENT_SHARE_ASSIGN,
      payload: cards,
      skipClose: skipClose,
    });
  };
}

export function commentShareConsume() {
  return (dispatch) => {
    dispatch({
      type: COMMENT_SHARE_CONSUME,
    });
  };
}
