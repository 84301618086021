import Mention from '@tiptap/extension-mention';

const CustomShortcutMenu = Mention.extend({
  name: 'customShortcutMenu',
  priority: 1000,
  atom: true,
  draggable: false,
});

export default CustomShortcutMenu;
