import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRGBValue, getTaskDueDate } from 'app/utils/taskHelper';
import {
  getDifferenceInDays,
  timeWithFormat,
  timestampOnHover,
} from 'app/utils/timeHelper';
import { FORMAT_START_WITH_MONTH_SLASH, TIME_FORMAT } from './taskConstant';
import { TaskContext } from 'app/context/TaskContext';
import parse from 'html-react-parser';
import checkedCircle from 'app/images/task-manager/checkedCircle.svg';
import dueDate from 'app/images/task-manager/dueDate.svg';
import SelectInput from 'app/components/Shared/SelectInput';
import ClickPopupWrapper from 'app/components/Shared/ClickPopupWrapper';
import RenderTaskActionButton from 'app/components/Task/RenderTaskActionButton';
import OsCards from 'app/components/OsCards/index';
import useKanbanCard from '../Kanban/useKanbanCard';
import ActionCable from 'app/actioncable/actioncable';
import useOnScreen from 'app/components/Task/useOnScreen';
import OsGrid from 'app/components/OsGrid';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { openTaskPreviewModal } from 'app/actions/taskPreviewModal';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RenderLabel from 'app/components/Task/RenderLabel';

function Task({ task, listType }) {
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const dispatch = useDispatch();
  const { updateCache, updateComponent, changeLabel } = useContext(TaskContext);
  const {
    allUserOptions,
    markAsDone,
    markAsNotDone,
    changeAssignee,
    deleteTask,
    changeDueDate,
    getCardType,
  } = useKanbanCard(task);
  const refreshComponent = updateComponent;
  const [isCollapsed, setIsCollapsed] = useState(!!task.completed);
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (listType === 'NotViewedTasks' && isOnScreen) {
      ActionCable?.notifications?.viewTask(task.id);
    }
  }, [isOnScreen]);

  const openPreviewModal = (taskId) => {
    dispatch(openTaskPreviewModal(taskId, {}));
  };

  const renderExpandIcon = () => {
    return (
      <div
        className={'task-chevron'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsCollapsed(!isCollapsed);
        }}>
        {!isCollapsed ? (
          <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
        ) : (
          <OrthoIcon name='chevron-caret-right' defaultClass='ms-1' />
        )}
      </div>
    );
  };

  return (
    <div
      className={`taskWrapper ${task.completed && 'task-completed'}`}
      onClick={() => openPreviewModal(task?.id)}
      key={task.id}>
      <div ref={ref} className='taskInformation'>
        <div className='taskTitleAndDueDate'>
          {task.completed ? (
            <div onClick={markAsNotDone} className={'checked-image-container'}>
              <img
                src={checkedCircle}
                alt='checkedCircle'
                width='20'
                height='20'
              />
            </div>
          ) : (
            <ClickPopupWrapper onClick={markAsDone}>
              <div className='emptyCircle click-popup-wrapper'></div>
            </ClickPopupWrapper>
          )}
          <div className='title-wrapper'>
            <div className={`title ${task.completed && 'line-through'}`}>
              <span className={'task-card-type'}>{getCardType()}</span>{' '}
              {task.title}
            </div>
            {listType === 'OverDueTasks' && (
              <div className='dueDate' title={timestampOnHover(task.due_date)}>
                <span>
                  Due {getDifferenceInDays(task.due_date)}{' '}
                  {getDifferenceInDays(task.due_date) > 1 ? 'Days' : 'Day'} Ago
                </span>
                <img src={dueDate} alt='dueDate' width='16' height='16' />
              </div>
            )}
            {listType === 'CompletedTasks' && (
              <div className='dueDate'>
                {timeWithFormat(task.due_date, FORMAT_START_WITH_MONTH_SLASH)}
              </div>
            )}
            {[
              'ActiveTasks',
              'NotCompletedUserCreatedTasksAssignedToOther',
              'NotViewedTasks',
              'InProgressTasks',
              'PatientSpaceFeed',
              'ViewedTasks',
            ].includes(listType) && (
              <div className='dueDate'>{getTaskDueDate(task.due_date)}</div>
            )}
          </div>
        </div>
        <div className='taskLabel' onClick={(e) => e.stopPropagation()}>
          <RenderTaskActionButton
            task={task}
            listType={listType}
            assigneeList={
              listType === 'PatientSpaceFeed' ? all_members : allUserOptions
            }
            changeDueDate={changeDueDate}
            markAsDone={markAsDone}
            markAsNotDone={markAsNotDone}
            deleteTask={deleteTask}
            changeAssignee={changeAssignee}
            updateCache={updateCache}
            refreshComponent={refreshComponent}
            backendDataRequired={false}
          />
          {renderExpandIcon()}
        </div>
      </div>

      {!isCollapsed && (
        <>
          <div
            className={`taskDescription ${task.completed && 'line-through'}`}>
            {parse(task.description || '')}
          </div>
          <div className={'additional-links'}>
            {task.links &&
              task.links.map((linkObject) => (
                <>
                  <OsGrid
                    key={linkObject.id}
                    identifier={'TimelineSpaceCommentCardColumn:Cardcols'}>
                    <OsCards size={'extra-small'} obj={linkObject} />
                  </OsGrid>
                </>
              ))}
          </div>

          {task?.entity && (
            <div
              className='card-entity-post'
              onClick={(e) => e.stopPropagation()}>
              <OsCards
                size={'extra-small'}
                obj={task.entity}
                sliderPreview={true}
                source={task.entity.__typename}
                shareRequired={false}
                dateBlockRequired={true}
              />
            </div>
          )}

          {task?.parent_entity && task?.parent_entity?.id && (
            <div
              style={{ marginTop: '8px' }}
              className='card-entity-post'
              onClick={(e) => e.stopPropagation()}>
              <OsCards
                size={'extra-small'}
                obj={task.parent_entity}
                sliderPreview={true}
                source={task.parent_entity.__typename}
                shareRequired={false}
                dateBlockRequired={true}
              />
            </div>
          )}
        </>
      )}

      <div className='task-bottom-space'>
        <div className='taskLabel' onClick={(e) => e.stopPropagation()}>
          <div
            className='task-comment-count'
            onClick={() => openPreviewModal(task?.id)}>
            <OrthoIcon name='comments' />
            {task.comments_count}
          </div>
          <div className='teamMembersDropdown'>
            <SelectInput
              isMulti={false}
              name='assignee'
              className='react-select-ortho-2'
              formValue={task.assignee.id}
              onChange={changeAssignee}
              isSearchable={false}
              options={all_members}
              optionProcessingRequired={true}
              selectedValueNameRequired={false}
            />
          </div>
          {task.entity_labels.map((label, index) => {
            return (
              <div
                key={label.id}
                className='taskLabelContainer'
                style={{
                  backgroundColor: `${getRGBValue(label.label.color)}`,
                }}>
                <div className='singleTaskLabel'>
                  <RenderLabel
                    task={task}
                    label={label.label}
                    allLabels={task_labels}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <span className={'task-information'}>
          {task.completed && (
            <span>
              Completed by {task?.resolved_marked_by?.name} •{' '}
              {timeWithFormat(
                task?.completed_at,
                `${FORMAT_START_WITH_MONTH_SLASH} ${TIME_FORMAT}`,
              )}
            </span>
          )}
          <span>
            by {task.created_by.full_name} •{' '}
            {timeWithFormat(
              task.created_at,
              `${FORMAT_START_WITH_MONTH_SLASH} ${TIME_FORMAT}`,
            )}
          </span>
        </span>
      </div>
    </div>
  );
}

export default Task;
