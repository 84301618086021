import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
      fill='#FFC933'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.413 14.252c.123-.021.237.072.257.208.222 1.552 1.607 2.792 3.33 2.792s3.108-1.24 3.33-2.792c.02-.136.134-.23.257-.208.122.022.205.15.186.286C15.51 16.377 13.9 17.75 12 17.75c-1.9 0-3.51-1.374-3.773-3.213-.02-.136.064-.264.186-.286z'
      fill='#222226'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.378 14.006c.245-.043.475.143.513.415.202 1.41 1.476 2.582 3.109 2.582s2.907-1.172 3.109-2.582c.039-.272.268-.458.513-.415.244.043.411.299.372.571C15.711 16.559 13.99 18 12 18c-1.99 0-3.711-1.441-3.994-3.423-.04-.272.128-.527.372-.57z'
      fill='#222226'
    />
    <path
      d='M19 14c1.105 0 2-.672 2-1.5s-.895-1.5-2-1.5-2 .672-2 1.5.895 1.5 2 1.5zM5 14c1.105 0 2-.672 2-1.5S6.105 11 5 11s-2 .672-2 1.5.895 1.5 2 1.5z'
      fill='#FF9270'
    />
    <path
      d='M8 7a.601.601 0 0 0-.318.11c.182.12.318.32.318.55 0 .37-.295.67-.66.67a.585.585 0 0 1-.26-.06C7.023 8.5 7 8.74 7 9c0 1.1.443 2 1 2 .557 0 1-.9 1-2s-.455-2-1-2zM16 7a.637.637 0 0 0-.318.11c.182.12.318.32.318.55 0 .37-.296.67-.66.67a.585.585 0 0 1-.26-.06c-.057.23-.08.47-.08.73 0 1.1.443 2 1 2 .557 0 1-.9 1-2s-.454-2-1-2z'
      fill='#222226'
    />
    <path
      d='M8 7.67c0-.24-.125-.44-.318-.55-.284.18-.512.61-.614 1.16.08.03.17.06.261.06.364-.01.67-.31.67-.67zM16 7.67c0-.24-.125-.44-.318-.55-.284.19-.5.62-.614 1.17.08.03.17.06.261.06.364-.02.67-.32.67-.68z'
      fill='#F7F7F8'
    />
  </svg>
);

export default SVG;
