import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentFilter, NAV_LIST, NAV_LIST_SOURCE } from './tabsHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import SimpleNavList from 'app/components/SimpleNavList';
import StickyHeader from 'app/components/StickyHeader';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onTabClick = (filter) => {
    navigate(withWorkspaceIdentifier('/settings/' + filter));
  };

  const navListProps = () => {
    return {
      currentFilter: getCurrentFilter(location.pathname),
      onFilterClick: onTabClick,
      scrollIntoViewOnOrientationChange: true,
      source: NAV_LIST_SOURCE,
    };
  };

  const getNavList = () => {
    return NAV_LIST;
  };

  return (
    <div className='account-setting'>
      <div className='collection-page'>
        <div className='container-fluid px-0 account-setting-container os-tab-style'>
          <div className='container'>
            <div className='detail-header'>Workspace Settings</div>
          </div>
          <StickyHeader>
            <SimpleNavList {...navListProps()} list={getNavList()} />
          </StickyHeader>
          <div className='container'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
