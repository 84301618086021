import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AppointmentInfoModal from 'app/components/CaseLeftSection/AppointmentInfoModal';
import Avatar from 'app/components/Shared/Avatar';
import CaseAppointmentFooter from 'app/components/CaseView/CaseAppointmentFooter';
import CaseGridClassicView from 'app/components/CaseView/CaseGridClassicView';
import CaseGridUpdatedView from 'app/components/CaseView/CaseGridUpdatedView';
import CaseSlider from './CaseSlider';
import ComingSoonSection from 'app/components/CaseView/ComingSoonSection';
import Header from 'app/components/CaseView/Header';
import NoAppointmentDataView from 'app/components/Shared/NoAppointmentDataView';
import OsField from 'app/components/OsField';
import OsGrid from 'app/components/OsGrid';
import SBSView from './SBSView';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import { flatten, isEmpty, isEqual, keys } from 'app/utils/osLodash';
import {
  ALL_APPOINTMENTS_FILTER_TEXT,
  CASE_VIDEO_TYPES,
  VIDEO_TYPES,
  VIEWING_MODES_MAPPER,
} from './constants';
import {
  appointmentHintText,
  caseVideosPresent,
  filterCaseVideos,
  getAppointmentThroughFile,
  getAppointmentFiles,
  getAppointmentFilesForGalleryView,
  getAppointmentFilesWithImagesOrVideos,
  getFilesBasedOnRecordType,
} from './helpers';
import { isTouchSupported } from 'app/utils/deviceHelper';

import { setSpinCurrentIndex } from 'app/actions/spin';
import {
  setCaseViewType,
  setCurrentSlide,
  setSelectedAppointment,
  setCaseDetailValuesToDefault,
} from 'app/actions/caseDetailInfo';
import { translate } from 'app/actions/flashMessage';

class CaseLeftSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveBothSBSSlider: true,
      hoverHintRequired: this.isHoverHintRequired(),
      selectAnotherAppointmentHint: false,
    };
    this.setDefaultAppointment();
    this.setViewType();
  }

  componentDidMount() {
    this.state.hoverHintRequired && this.setHoverHintTime();
    this.selectAnotherAppointmentHintRequired();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.caseDetailInfo.viewType !== ALL_APPOINTMENTS_FILTER_TEXT &&
      this.isAllAppoinmentFilterSelected()
    ) {
      this.setAppointmentHintText();
    }

    if (
      this.isSBSViewSelected(prevProps) &&
      this.props.caseDetailInfo.viewType === 'All Appointments' &&
      !isEmpty(this.props.caseDetailInfo.selectedAppointment)
    ) {
      this.setDefaultAppointment();
    }

    if (
      !isEqual(
        prevProps.caseDetailInfo.selectedAppointment,
        this.props.caseDetailInfo.selectedAppointment,
      ) ||
      !isEqual(
        prevProps.caseDetailInfo.selectedRightSideAppointment,
        this.props.caseDetailInfo.selectedRightSideAppointment,
      ) ||
      !isEqual(
        prevProps.caseDetailInfo.viewType,
        this.props.caseDetailInfo.viewType,
      )
    ) {
      this.selectAnotherAppointmentHintRequired();
    }
  }

  componentWillUnmount() {
    this.props.setSpinCurrentIndex(null, '');
    this.props.setCaseDetailValuesToDefault();
  }

  defaultAppointment() {
    return getAppointmentFilesWithImagesOrVideos(
      this.props.kase.appointments,
    )[0];
  }

  setDefaultAppointment() {
    return this.props.setSelectedAppointment(this.defaultAppointment());
  }

  setViewType() {
    this.props.setCaseViewType('SBS');
  }

  isCaseUnlocked() {
    return true;
  }

  selectAnotherAppointmentHintRequired() {
    if (
      this.isSBSViewSelected() &&
      isEqual(
        this.props.caseDetailInfo.selectedRightSideAppointment,
        this.props.caseDetailInfo.selectedAppointment,
      )
    ) {
      this.setState({ selectAnotherAppointmentHint: true });
      setTimeout(() => {
        this.setState({ selectAnotherAppointmentHint: false });
      }, 5000);
    }
  }

  isHoverHintRequired() {
    return (
      this.isSliderRequired() &&
      !this.props.device.mobileDevice &&
      getAppointmentFilesWithImagesOrVideos(this.props.kase.appointments)
        .length > 0
    );
  }

  setHoverHintTime() {
    setTimeout(() => {
      this.setState({ hoverHintRequired: false });
    }, 3000);
  }

  setAppointmentHintText() {
    let allAppointments = this.getAppointments();

    this.setState(
      {
        appointmentChangeHintText: appointmentHintText(
          this.props.caseDetailInfo.selectedAppointment,
          allAppointments,
        ),
      },
      this.resetHint,
    );
  }

  getInitialSelectedView() {
    return this.isCaseUnlocked() ? this.firstAvailableViewType() : '';
  }

  firstAvailableViewType() {
    return CASE_VIDEO_TYPES.find((type) =>
      caseVideosPresent(type, this.props.kase),
    );
  }

  renderCaseInformation(kase) {
    return (
      <Header
        obj={kase}
        isCaseUnlocked={this.isCaseUnlocked()}
        spaceBannerView={this.props.spaceBannerView}
        spaceBannerTitle={this.props.spaceBannerTitle}
        careSpaceView={this.props.careSpaceView}
      />
    );
  }

  renderCaseAuthorInfo(kase) {
    let { doctor } = kase;
    return (
      <div className='user case-details-user-block ps-0'>
        <div className='d-flex align-items-center '>
          <Link to={entityUrl(doctor)} className='lh-1 me-2'>
            <Avatar
              className='avatar avatar-24'
              {...avatarAttributes(doctor)}
            />
          </Link>
          <Link
            to={entityUrl(doctor)}
            className=' os-btn-link doc-name-case text-truncate  '>
            <UserQualificationInfo
              user={doctor}
              linkRequired={true}
              sliceUpto={2}
              osLinkClass='os-text-6 fs-12'
              className='mx-50'
            />
          </Link>
        </div>
      </div>
    );
  }

  isSBSViewSelected(props = this.props) {
    return props.caseDetailInfo.viewType === 'SBS';
  }

  getAvailableRecordTypes() {
    return keys(VIEWING_MODES_MAPPER).filter(
      (type) => !!this.getImagesAndVideos(type).length,
    );
  }

  isDateSuffixRequired() {
    let { device } = this.props;
    return !(device.mobileDevice || device.ipadPortrait);
  }

  getAvailableVideoTypes() {
    return CASE_VIDEO_TYPES.filter((type) =>
      caseVideosPresent(type, this.props.kase),
    );
  }

  getAppointments() {
    return getAppointmentFilesWithImagesOrVideos(this.props.kase.appointments);
  }

  getAllFiles() {
    return flatten(
      this.getAppointments().map((appointment) =>
        getAppointmentFiles(appointment),
      ),
    );
  }

  isAllAppoinmentFilterSelected() {
    return this.props.caseDetailInfo.viewType === ALL_APPOINTMENTS_FILTER_TEXT;
  }

  getImagesAndVideos(recordType) {
    let appointments = this.getAppointments(),
      selectedRecordType = recordType || this.props.caseDetailInfo.mode,
      viewType = this.props.caseDetailInfo.viewType;

    let files = [];
    if (VIDEO_TYPES.includes(viewType)) {
      files = filterCaseVideos(viewType, this.props.kase);
    } else if (viewType) {
      let { selectedAppointment } = this.props.caseDetailInfo;
      files = getAppointmentFiles(
        selectedAppointment || this.defaultAppointment(),
      );
    } else if (appointments.length) {
      let { selectedAppointment } = this.props.caseDetailInfo;
      files = getAppointmentFiles(selectedAppointment);
    }
    let filteredFilesbyType = getFilesBasedOnRecordType(
      files,
      selectedRecordType,
    );
    return filteredFilesbyType?.length ? filteredFilesbyType : files;
  }

  notifyParentContainer = (currentSlide) => {
    let previousSlide = this.props.caseDetailInfo.currentSlide,
      appointmentChangeHintText = '';

    if (!isEmpty(previousSlide) && currentSlide) {
      let appointments = this.getAppointments(),
        previousAppointment = getAppointmentThroughFile(
          previousSlide,
          appointments,
        ),
        currentAppointment = getAppointmentThroughFile(
          currentSlide,
          appointments,
        );

      if (
        previousAppointment &&
        currentAppointment &&
        previousAppointment.id !== currentAppointment.id
      )
        appointmentChangeHintText = appointmentHintText(
          currentAppointment,
          appointments,
        );
    }
    this.setState({ appointmentChangeHintText }, this.resetHint);
    currentSlide && this.props.setCurrentSlide(currentSlide);
  };

  resetHint = () => {
    this.resetHintTimeout && clearTimeout(this.resetHintTimeout);
    this.resetHintTimeout = setTimeout(() => {
      this.setState({ appointmentChangeHintText: '' });
    }, 3000);
  };

  getInitialSlideIndex(files) {
    let { currentSlide } = this.props.caseDetailInfo,
      index;

    if (currentSlide) {
      index = files.findIndex((file) => currentSlide.name === file.name);
    } else {
      index = 0;
    }
    return index > 0 ? index : 0;
  }

  toggleBothSliderMovement = () => {
    this.setState({ moveBothSBSSlider: !this.state.moveBothSBSSlider });
  };

  onClickFullScreen = () => {
    this.setState(
      { appointmentChangeHintText: '' },
      this.props.onClickFullScreen,
    );
  };

  isSliderRequired() {
    let { kase } = this.props;

    if (this.isCaseUnlocked()) {
      return (kase.appointments || []).length > 0;
    } else {
      return (
        !!kase.appointments.length &&
        this.props.caseDetailInfo.selectedAppointment
      );
    }
  }

  isUpdatedGalleryView() {
    return this.props.caseDetailInfo.viewType === 'GALLERY_UPDATED';
  }

  isClassicGalleryView() {
    return this.props.caseDetailInfo.viewType === 'GALLERY_CLASSIC';
  }

  switchGalleryView = (e) => {
    let galleryView = e.target.checked ? 'GALLERY_CLASSIC' : 'GALLERY_UPDATED';
    this.props.setCaseViewType(galleryView);
  };

  renderGalleryView() {
    let extraClass = this.isClassicGalleryView() ? 'classic-view' : '',
      appointmentFiles = getAppointmentFilesForGalleryView(
        this.props.caseDetailInfo.selectedAppointment,
      );

    if (appointmentFiles.length === 0) {
      return <NoAppointmentDataView />;
    }
    return (
      <div className={`cs-gallery-grid ${extraClass}`}>
        <div className={`switch-classic-mode`}>
          <OsField
            osType='checkbox'
            dataHoverRequired={!isTouchSupported()}
            label='Classic Grid'
            onChange={this.switchGalleryView}
            isChecked={!this.isUpdatedGalleryView()}
            slider={true}
            sliderType='square'
            className='switch'>
            <span className='toogle-control'></span>
          </OsField>
        </div>

        {this.isUpdatedGalleryView() ? (
          <CaseGridUpdatedView
            selectedAppointment={this.props.caseDetailInfo.selectedAppointment}
          />
        ) : (
          <CaseGridClassicView
            selectedAppointment={this.props.caseDetailInfo.selectedAppointment}
          />
        )}
      </div>
    );
  }

  renderImagesAndVideos() {
    const { kase } = this.props;

    if (!this.isSliderRequired()) {
      return (
        <ComingSoonSection
          className='video-inprogress'
          kase={this.props.kase}
        />
      );
    } else if (this.isSBSViewSelected()) {
      return (
        <SBSView
          kase={kase}
          onClickFullscreen={this.onClickFullScreen}
          isFullScreen={this.props.isFullScreen}
          currentSlide={this.props.caseDetailInfo.currentSlide}
          currentAppointment={this.props.caseDetailInfo.selectedAppointment}
          notifyParentContainer={this.notifyParentContainer}
          toggleBothSliderMovement={this.toggleBothSliderMovement}
          moveBothSlider={this.state.moveBothSBSSlider}
        />
      );
    } else if (this.isUpdatedGalleryView() || this.isClassicGalleryView()) {
      return this.renderGalleryView();
    } else {
      let files = this.getImagesAndVideos();
      return (
        <CaseSlider
          files={files}
          kase={kase}
          selectedAppointment={this.props.caseDetailInfo.selectedAppointment}
          onClickFullscreen={this.onClickFullScreen}
          isFullScreen={this.props.isFullScreen}
          notifyParentContainer={this.notifyParentContainer}
          slideIndex={this.getInitialSlideIndex(files)}
          appointmentChangeHintText={this.state.appointmentChangeHintText}
        />
      );
    }
  }

  renderHoverHint() {
    return (
      <div className='case-hover-hint'>
        {isTouchSupported()
          ? translate('CASE_LEFT_SECTION_HOVER_HINT_TEXT_FOR_TOUCH_SUPPORT')
          : translate('CASE_LEFT_SECTION_HOVER_HINT_TEXT')}
      </div>
    );
  }

  renderSelectAnotherAppointmentHint() {
    return (
      <div className='appointment-sbs-hint'>
        Select another appointment to compare
      </div>
    );
  }

  render() {
    let {
      kase,
      device: { ipadPortrait },
    } = this.props;
    let caseDetailClass = ' case-details-video-section';
    caseDetailClass += this.isSliderRequired() ? ' ' : ' no-ap-cs-card';
    caseDetailClass += this.props.careSpaceView ? ' clv_with_case' : '';
    return (
      <>
        <OsGrid
          identifier='CaseDetail:CaseLeftSection'
          extraClass={caseDetailClass}>
          {this.renderCaseInformation(kase)}
          {this.renderImagesAndVideos()}
          {this.state.hoverHintRequired && this.renderHoverHint()}
          {this.state.selectAnotherAppointmentHint &&
            this.renderSelectAnotherAppointmentHint()}
          {ipadPortrait && <CaseAppointmentFooter kase={kase} />}
        </OsGrid>
        <AppointmentInfoModal />
      </>
    );
  }
}

CaseLeftSection.defaultProps = {
  setSelectedAppointment: () => {},
};

CaseLeftSection = connect(
  ({ caseDetailInfo, device }) => ({ caseDetailInfo, device }),
  {
    setCaseViewType,
    setCurrentSlide,
    setSelectedAppointment,
    setCaseDetailValuesToDefault,
    setSpinCurrentIndex,
  },
)(CaseLeftSection);
export default CaseLeftSection;
