import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { assignConsume, assignCreate } from 'app/actions/assign';
import OsBtn from 'app/components/OsBtn';

// Popup to tell people a message or create a new board/comparison after the assign drawer has done its thing
class AssignMessage extends Component {
  constructor(props) {
    super(props);
    this.onBodyClick = this.onBodyClick.bind(this);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    window.$('body').on('click', this.onBodyClick);
  }

  componentWillUnmount() {
    window.$('body').off('click', this.onBodyClick);
  }

  onBodyClick(event) {
    if (this.refs.popup) {
      this.props.assignConsume();
    }
  }

  closeModal = () => {
    this.props.assignCreate(null, []);
    window.$('html').removeClass('non-bs-modal-open');
  };

  render() {
    if (this.props.assign.status === 'message') {
      return (
        <Modal
          show={true}
          onHide={this.closeModal}
          dialogClassName={
            'os-grid-modal add-space-modal assign-message-mdaoal'
          }
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-assign-modal-btn'
            icon='close'
            label='Close message modal'
            onClick={this.closeModal}
          />

          <Modal.Body className='px-0'>{this.props.assign.message}</Modal.Body>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

AssignMessage = connect(({ assign }) => ({ assign }), {
  assignConsume,
  assignCreate,
})(AssignMessage);

export default AssignMessage;
