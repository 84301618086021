import { pick } from 'app/utils/osLodash';
import {
  getWorkspaceIdentifier,
  isCareWorkspaceView,
} from 'app/utils/Workspace/generalHelper';

const INTERCOM_LINKS = 'https://api-iam.intercom.io';

class IntercomTracker {
  register(user = {}) {
    const attributes = pick(user, 'name', 'email', 'created_at');
    window.Intercom('boot', {
      api_base: INTERCOM_LINKS,
      app_id: process.env.REACT_APP_INTERCOM_CODE,
      ...attributes,
      email: user.nice_id,
      user_hash: user.intercom_hash,
      is_care: isCareWorkspaceView(),
      workspace_id: getWorkspaceIdentifier(),
      sponsored: !user.non_sponsored,
    });
  }

  shutdownIntercom() {
    window.Intercom('shutdown');
  }

  openWidget() {
    window.Intercom('show');
  }

  hideWidgetLauncher() {
    window.Intercom('update', { hide_default_launcher: true });
  }

  showWidgetLauncher() {
    window.Intercom('update', { hide_default_launcher: false });
  }
}

export default new IntercomTracker();
