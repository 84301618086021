import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { setSource } from 'app/actions/source';

import AccountSettingView from 'app/components/AccountSettingView';
import AuthenticatedLayout from 'app/components/Layouts/AuthenticatedLayout';
import CareSpaceActivityLogs from "app/components/CareSpace/ActivityLogs"
import CareUserView from 'app/components/CareSpace/UserView';
import CareViewDefaultRoutes from 'app/routes/CareView/DefaultRoutes';
import ConfirmEmail from 'app/components/ConfirmEmail';
import BoardView from 'app/components/BoardView';
import CaseView from 'app/components/CaseView';
import CCPAPolicyView from 'app/components/CCPAPolicyView';
import Designs from 'app/components/Designs';
import DownloadAsset from 'app/components/DownloadAsset';
import EditUserProfile from 'app/components/EditUserProfile';
import EmailSourceUrl from 'app/components/EmailSourceUrl';
import LockedSpaceView from 'app/components/LockedSpaceView';
import NoRecordsPresent from 'app/components/SharedCareClinic/NoRecordsPresent';
import RedirectToDefault from 'app/components/RedirectToDefault';
import ResourceUnavailableView from 'app/components/ResourceUnavailableView';
import TerminateSession from 'app/components/TerminateSession';
import UnauthenticatedLayout from 'app/components/Layouts/UnauthenticatedLayout';
import UserView from 'app/components/UserView';

const CareViewRoutes = (props) => {
  const { setSource } = props;
  useEffect(() => {
    setSource('care');
  }, [setSource]);

  return (
    <Routes>
      <Route element={<UnauthenticatedLayout />}>
        <Route path='no_records' element={<NoRecordsPresent />} />
        <Route path='access_revoked' element={<NoRecordsPresent />} />
      </Route>
      <Route element={<AuthenticatedLayout />}>
        <Route path='source_email/:url' element={<EmailSourceUrl />} />
        <Route path='confirm_email/:token' element={<ConfirmEmail />} />
        <Route path='users/:id/' exact element={<UserView />} />
        <Route path='users/:id/*' exact element={<UserView />} />
        <Route
          path='users/:id/notifications'
          exact
          element={<CareUserView />}
        />
        <Route path='locked_space/:id' element={<LockedSpaceView />} />
        <Route path='not_found' element={<ResourceUnavailableView />} />
        <Route path='policies/ccpa' element={<CCPAPolicyView />} />
        <Route
          path='private_access_denied'
          element={<ResourceUnavailableView />}
        />
        <Route path='cases/:id' exact={true} element={<CaseView />} />
        <Route
          path='terminate_session/:user_id/:device_token'
          element={<TerminateSession />}
        />
        <Route path='designs' element={<Designs />} />
        <Route path='default' element={<RedirectToDefault />} />
        <Route path='account_settings/*' element={<AccountSettingView />} />
        <Route
          path='download_assets/:query_by/:id'
          element={<DownloadAsset />}
        />
        <Route
          path='spaces/:id/activity_logs'
          element={<CareSpaceActivityLogs />}
        />
        <Route
          path='users/:id/edit'
          exact
          element={<EditUserProfile floaterMessageNotRequired={true} />}
        />
        <Route
          path='spaces/:id/:filter/:additionalFilter'
          element={<BoardView />}
        />
        <Route path='spaces/:id/:filter' element={<BoardView />} />
        <Route path='spaces/:id' element={<BoardView />} />
        <Route path='patient/space/:id/:filter/:additionalFilter' element={<BoardView />} />
        <Route path='patient/space/:id/:filter' element={<BoardView />} />
        <Route path='patient/space/:id' element={<BoardView />} />
        <Route path='*' element={<CareViewDefaultRoutes />} />
      </Route>
    </Routes>
  );
};

const CareViewRoutesContainer = connect(null, { setSource })(CareViewRoutes);
export default CareViewRoutesContainer;
