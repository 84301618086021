import {
  SET_ACTIVE_RESOURCE,
  SET_ACTIVE_RESOURCE_PATIENT,
  SET_ACTIVE_RESOURCE_AFTER_SHARING,
} from 'app/actions/types';

export default function activeResource(
  state = {
    resource: null,
  },
  action,
) {
  switch (action.type) {
    case SET_ACTIVE_RESOURCE:
      return { ...state, resource: action.resource };
    case SET_ACTIVE_RESOURCE_PATIENT:
      return {
        ...state,
        resource: {
          ...state.resource,
          owner: { ...state?.resource?.owner, patient: action.patient },
        },
      };
    case SET_ACTIVE_RESOURCE_AFTER_SHARING:
      return {
        ...state,
        resource: {
          ...state.resource,
          shared_with_person_ids: action.resource.shared_with_person_ids,
          shared_with_user_ids: action.resource.shared_with_user_ids,
          shared_with_any_patient: action.resource.shared_with_any_patient,
          notification_enabled_for_patient_or_guardian_ids:
            action.resource.notification_enabled_for_patient_or_guardian_ids,
        },
      };
    default:
      return state;
  }
}
