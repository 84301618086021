import {
  CLOSE_ENTITY_PREVIEW_MODAL,
  OPEN_ENTITY_PREVIEW_MODAL,
} from 'app/actions/types';

export default function entityPreviewModal(state = { open: false }, action) {
  switch (action.type) {
    case OPEN_ENTITY_PREVIEW_MODAL:
      return {
        open: true,
        queryType: action.queryType,
        entityId: action.entityId,
      };

    case CLOSE_ENTITY_PREVIEW_MODAL:
      return { open: false };
    default:
      return state;
  }
}
