export const getWorkspace = (state) => state.workspace;
export const getWorkspaceID = (state) => state.workspace?.data?.id;
export const getWorkspaceNiceId = (state) => state.workspace?.data?.nice_id;
export const getWorkspaceClinic = (state) => state.workspace?.data?.owner;
export const getWorkspaceFeatured = (state) => state.workspace?.data?.featured;
export const getDeepLinkIntegrationEnabled = (state) =>
  state.workspace?.data?.workspace_setting.deep_link_integration_enabled;
export const getWorkspaceEasyRXLinked = (state) =>
  state.workspace?.data?.workspace_setting?.easy_rx_linked;
export const getWorkspaceIdentifer = (state) => state.workspace?.identifier;
export const getWorkspaceGuestOwnerData = (state) =>
  state.workspace?.guestOwnerData;
