import React, { Component } from 'react';
import { connect } from 'react-redux';

import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import OsGrid from 'app/components/OsGrid';

import { pick } from 'app/utils/osLodash';
import PayWallView from 'app/components/NonSubscribedView/PayWallView';

import 'app/components/ContentWithSidebars/content-with-sidebar.scss';
const FULL_VIEW_SOURCES = ['easyRX', 'userDirectory'];
const SPACE_DETAIL_SIDEBAR_CONDITIONS = {
  leftSideBar: false,
  rightSideBar: true,
  middleContainer: true,
};
const SIDEBAR_CONDITIONS = {
  careSpaceDetail: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  exploreCareWorkspace: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  easyRX: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  userDirectory: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  teamWindow: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  teamDirectMessenger: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  teamGroupMessenger: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  partnersMobile: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  noPartners: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  partners: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  tasks: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  tasksManager: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  myTasksManager: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  teamTasksManager: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: true,
  },
  boardTasksManager: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedFolders: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedInbox: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedMyTasksInbox: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedTeamInbox: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedTeamPartnersInbox: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
  unifiedInboxPartner: {
    leftSideBar: true,
    middleContainer: true,
    rightSideBar: false,
  },
};

const MOBILE_CONDITIONS = {
  exploreCareWorkspace: {
    leftSideBar: true,
    middleContainer: false,
    rightSideBar: true,
  },
  teamWindow: {
    leftSideBar: true,
    middleContainer: false,
    rightSideBar: true,
  },
  tasks: {
    leftSideBar: true,
    middleContainer: false,
    rightSideBar: false,
  },
  partnersMobile: {
    leftSideBar: true,
    middleContainer: false,
    rightSideBar: false,
  },
};

class ContentWithSidebars extends Component {
  getSidebarConditions() {
    const sidebarConditions = this.props.device.mobileDevice
      ? MOBILE_CONDITIONS[this.props.source]
      : SIDEBAR_CONDITIONS[this.props.source];
    return sidebarConditions || SPACE_DETAIL_SIDEBAR_CONDITIONS;
  }

  leftSidebarRequired() {
    return this.getSidebarConditions().leftSideBar;
  }

  middleContainerRequired() {
    return this.getSidebarConditions().middleContainer;
  }

  rightSidebarRequired() {
    let { mobileDevice } = this.props.device;
    return (
      this.getSidebarConditions().rightSideBar &&
      (!mobileDevice || (mobileDevice && this.props.widgetsRequiredOnMobile))
    );
  }

  getCardGridIdentifier() {
    return `${this.props.rightSidebarGridIndentifier}Card`;
  }

  renderLeftSidebar(props) {
    let leftSidebarClass = '';
    leftSidebarClass = this.props.device.mobileDevice
      ? 'leftbar-mobile-container'
      : '';

    if (this.leftSidebarRequired())
      return (
        <OsGrid identifier={this.props.leftSidebarGridIndentifier}>
          <LeftSidebar
            cardGridIdentifier={this.getCardGridIdentifier()}
            sideBarClass={this.props.sideBarClass}
            extraClass={leftSidebarClass}
            {...props}
          />
        </OsGrid>
      );
  }

  renderMiddleContent() {
    if (this.middleContainerRequired())
      return (
        <OsGrid identifier={this.props.childGridIdentifier} extraClass=''>
          {this.props.children}
        </OsGrid>
      );
  }

  renderRightSidebar(props) {
    if (this.rightSidebarRequired())
      return (
        <OsGrid identifier={this.props.rightSidebarGridIndentifier}>
          <RightSidebar
            cardGridIdentifier={this.getCardGridIdentifier()}
            {...props}
            sideBarClass={this.props.sideBarClass}
          />
        </OsGrid>
      );
  }

  renderBody(props) {
    if (this.props.device.mobileDevice) {
      return (
        <PayWallView
          source={this.props.source}
          extraClass={'position-unset pr-32'}>
          {this.renderLeftSidebar(props)}
          {this.renderMiddleContent()}
          {this.renderRightSidebar(props)}
        </PayWallView>
      );
    } else {
      return (
        <>
          {this.renderLeftSidebar(props)}
          <PayWallView
            source={this.props.source}
            extraClass={'col-os-8 position-unset pr-32'}>
            {this.renderMiddleContent()}
            {this.renderRightSidebar(props)}
          </PayWallView>
        </>
      );
    }
  }

  render() {
    let props = pick(
      this.props,
      'data',
      'isAuthorOrCollaborator',
      'source',
      'blocksRequired',
      'options',
    );

    let middlebarExtraClass = '';
    middlebarExtraClass =
      this.props.source === 'boardTasksManager' ? 'board-tasks-manager ' : '';
    middlebarExtraClass += FULL_VIEW_SOURCES.includes(this.props.source)
      ? 'full-view '
      : '';
    return (
      <section className={`d-block ${this.props.parentClass || ''}`}>
        <div
          className={`row no-margin-m s ${this.props.extraClass} ${middlebarExtraClass}`}>
          {this.renderBody(props)}
        </div>
      </section>
    );
  }
}

ContentWithSidebars = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(ContentWithSidebars);
ContentWithSidebars.defaultProps = {
  widgetsRequiredOnMobile: false,
};
export default ContentWithSidebars;
