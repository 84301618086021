import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { sum } from 'app/utils/osLodash';

import MobileNav from 'app/components/NavLinks/MobileNav';
import NavItem from 'app/components/NavLinks/NavItem';
import MobileNavSearch from 'app/components/NavSearch/MobileNavSearch';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import SiteLogo from 'app/components/Shared/SiteLogo';
import WorkspaceToggleDropdown from 'app/components/WorkspaceView/WorkspaceToggleDropdown';

import EventTracker from 'app/services/EventTracker';
import {
  getNavSearchProps,
  isUnifiedEnabled,
  isWorkspaceSpace,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import {
  getUnifiedNavTabs,
  navActiveIdentifier,
  navActiveOption,
} from 'app/utils/Workspace/navHelper';

import { mergeWorkspaceData } from 'app/actions/workspace';
import { setNavSearchClose } from 'app/actions/navSearch';

import { APP_NAME } from 'app/constants';
import {
  MOBILE_NAV_LINKS_FOR_WORKSPACE,
  NAV_LINKS_FOR_WORKSPACE,
} from './Constants';
import { MOBILE_MORE_EXPANDED_PROFILE_LINKS } from 'app/components/NavLinks/constants';

import Logo from 'app/images/qlark_logo.png';
import { isCareSpace } from 'app/utils/spaceHelper';

const CURRENT_USER_WORKSPACE_NOTIFICATION_COUNT_QUERY = queryLoader(
  'app/graphql/queries/Workspace/NotificationDetails.gql',
);

const TABS_NOT_REQUIRED = ['carespaces'];
const UNIFIED_ADDITIONAL_TABS = ['partners'];
const EXPLORE_ALTERNATE_KEY = ['carespaces'];

class WorkspaceNavLinks extends Component {
  state = {
    workspacesSidebarOpen: false,
  };

  componentDidMount() {
    if (this.props.data.workspace)
      this.props.mergeWorkspaceData(this.props.data.workspace);
  }

  componentWillUnmount() {
    this.removeHiddenMoreClass();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data.loading)
      this.props.mergeWorkspaceData(this.props.data.workspace);
  }

  toggleWorkspacesSidebar = () => {
    this.setState({ workspacesSidebarOpen: !this.state.workspacesSidebarOpen });
  };

  isTabActive = (key) => {
    let { pathname } = this.props.location,
      resource = this.props.activeResource.resource || {},
      pathnameMatches = pathname.startsWith(withWorkspaceIdentifier(`/${key}`));

    if (key === 'unified') {
      return (
        pathnameMatches ||
        pathname.includes('/carespaces') ||
        pathname.includes('/folders') ||
        pathname.includes('/explore') ||
        pathname.includes('/patient') ||
        pathname.includes('/spaces') ||
        pathname.includes('/team') ||
        pathname.includes('/groups') ||
        pathname.includes('/conversations') ||
        pathname.includes('/tasks')
      );
    } else if (key === 'subspaces') {
      return pathnameMatches || isWorkspaceSpace(resource.owner || {});
    } else if (key === 'team') {
      return pathnameMatches;
    } else if (key === 'onlySubSpaceMember') {
      return pathname === '/members';
    } else if (key === 'my_tasks') {
      return pathname.includes('/tasks');
    } else if (key === 'explore') {
      return (
        pathnameMatches ||
        EXPLORE_ALTERNATE_KEY.some((key) =>
          pathname.startsWith(withWorkspaceIdentifier(`/${key}`)),
        ) ||
        isCareSpace(resource)
      );
    } else {
      return pathnameMatches;
    }
  };

  getTabLinks() {
    if (isUnifiedEnabled()) {
      let navLinks = this.props.navTabs.filter((tab) =>
        UNIFIED_ADDITIONAL_TABS.includes(tab.key),
      );
      let unifiedLinks = getUnifiedNavTabs();
      return [...unifiedLinks, ...navLinks];
    }
    return this.props.navTabs.filter(
      (tab) => !TABS_NOT_REQUIRED.includes(tab.key),
    );
  }

  removeHiddenMoreClass() {
    window.$('html')[0].classList.remove('hidden-more');
  }

  renderWebNavLinks() {
    const tabLinks = this.getTabLinks();
    return (
      <>
        <SiteLogo />
        <ul className='navbar-nav site-nav'>
          {tabLinks.map(this.renderWebNavLink)}
        </ul>
      </>
    );
  }

  renderWebNavLink = ({
    path,
    key,
    name,
    icon_name: iconName,
    countAttribute,
    countAttributes = [],
  }) => {
    const { data, identifier } = this.props.workspace;
    let count = 0;

    if (countAttributes.length > 0) {
      count = sum(countAttributes.map((a) => data[a]));
    } else {
      count = data[countAttribute];
    }

    return (
      <NavItem
        key={key}
        identifier={key}
        link={name}
        title={name}
        iconName={iconName}
        url={`/${identifier}/${path || key}`}
        isTabActive={this.isTabActive}
        count={count}
      />
    );
  };

  setSearchBoxState = () => {
    this.props.setNavSearchClose();
  };

  renderMobileNavSearchField() {
    return (
      <div className='mobile-header-search'>
        <MobileNavSearch
          {...getNavSearchProps(this.props.location.pathname)}
          notifyParentComponent={this.setSearchBoxState}
        />
      </div>
    );
  }

  toggleNavDropdown = () => {
    !this.state.navDropdownActive &&
      EventTracker.trackDropdownOpen('mobile_nav_more');
    this.setState(
      {
        navDropdownActive: !this.state.navDropdownActive,
        workspacesSidebarOpen: false,
      },
      () => {
        if (this.state.navDropdownActive) {
          window.$('html')[0].classList.add('hidden-more');
        } else {
          window.$('html')[0].classList.remove('hidden-more');
        }
      },
    );
  };

  toggleActiveDropdown = () => {
    this.state.navDropdownActive && this.toggleNavDropdown();
  };

  renderMobileNavLink = (link) => {
    if (NAV_LINKS_FOR_WORKSPACE[link])
      return (
        <MobileNav
          workspace={this.props.workspace}
          workspaceLinks={this.tabsForMobile()}
          abilityObject={NAV_LINKS_FOR_WORKSPACE[link].abilityObject}
          link={link}
          onClick={this.toggleActiveDropdown}
          toggleNavDropdown={this.toggleNavDropdown}
          navDropdownActive={this.state.navDropdownActive}
        />
      );
  };

  tabsForMobile() {
    let links = MOBILE_NAV_LINKS_FOR_WORKSPACE,
      membersTab = this.props.navTabs.filter(
        (option) => option.key === 'members',
      );

    if (membersTab[0] && !links.includes('Members')) {
      links.splice(2, 0, 'Members');
    }
    return links;
  }

  renderMobileLinks() {
    let links = this.tabsForMobile();
    return links.map(this.renderMobileNavLink);
  }

  renderMobileNavLinks() {
    return (
      <div className='mobile-nav-bar os-main-nav'>
        <ul className='navbar-nav site-nav'>{this.renderMobileLinks()}</ul>
        {this.props.navSearch.showSearchContainer &&
          this.renderMobileNavSearchField()}
        {this.state.navDropdownActive &&
          !this.state.workspacesSidebarOpen &&
          this.renderMobileNavDropdownMenu()}
        {this.state.navDropdownActive &&
          this.state.workspacesSidebarOpen &&
          this.renderWorkspaces()}
      </div>
    );
  }

  renderProfileActions() {
    return MOBILE_MORE_EXPANDED_PROFILE_LINKS.map(this.renderMobileNavLink);
  }

  getMoreLinksOption() {
    let tabsListNotRequiredNavTabs = [
        'conversations',
        'explore',
        'team',
        'my_tasks',
        'partners',
      ],
      moreLinkOptions = this.props.navTabs
        .filter((option) => !tabsListNotRequiredNavTabs.includes(option.key))
        .map((option) => option.name);
    return moreLinkOptions;
  }

  renderMorePages() {
    let expandLinks = this.getMoreLinksOption();
    return expandLinks.map(this.renderMobileNavLink);
  }

  renderWorkspaces() {
    return (
      <div className='nav-more-dropdown'>
        <div
          className='workspace-title-list'
          onClick={this.toggleWorkspacesSidebar}>
          <OrthoIcon name='chevron-left' dataHoverNotRequired={true} />
          Workspaces
        </div>
        <WorkspaceToggleDropdown />
      </div>
    );
  }

  renderWorkspaceLogo(name) {
    return (
      <>
        <OrthoIcon
          name='workspacelogo'
          defaultClass=' workspace-icon-logo'
          dataHoverNotRequired={true}
        />
        <span className='text-truncate'>{name}</span>
      </>
    );
  }

  renderActiveOption() {
    return (
      <div
        className='workspace-title-list d-flex align-items-center'
        onClick={this.toggleWorkspacesSidebar}>
        {navActiveIdentifier() === APP_NAME ? (
          <img src={Logo} alt='logo' className='' />
        ) : (
          this.renderWorkspaceLogo(navActiveOption().name)
        )}
        <OrthoIcon name='chevron-right' dataHoverNotRequired={true} />
      </div>
    );
  }

  renderMobileNavDropdownMenu() {
    return (
      <div className='nav-more-dropdown'>
        <ul className='more-dropdown-list'>{this.renderMorePages()}</ul>
        <ul className='more-dropdown-list'>
          {this.renderProfileActions()}
          <li className='nav-item'></li>
        </ul>
      </div>
    );
  }

  render() {
    return this.props.device.mobileDevice
      ? this.renderMobileNavLinks()
      : this.renderWebNavLinks();
  }
}

WorkspaceNavLinks = graphql(CURRENT_USER_WORKSPACE_NOTIFICATION_COUNT_QUERY, {
  skip: (props) => !props.workspace.identifier,
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdentifier: props.workspace.identifier.toString(),
    },
  }),
})(WorkspaceNavLinks);

WorkspaceNavLinks = connect(
  ({ activeResource, device, navSearch, workspace }) => ({
    activeResource,
    device,
    navSearch,
    workspace,
  }),
  { mergeWorkspaceData, setNavSearchClose },
)(WorkspaceNavLinks);
WorkspaceNavLinks = withRouter(WorkspaceNavLinks);
export default WorkspaceNavLinks;
