import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { getWorkspaceIdentifer } from 'app/selectors/workspace';
import { useSelector } from 'react-redux';

const GLOBAL_SEARCH_COUNT_QUERY = queryLoader(
  'app/graphql/queries/Search/Count.gql',
);

const useGloablSearchCountMapping = () => {
  const [searchParams] = useSearchParams();
  const workspaceIdentifier = useSelector(getWorkspaceIdentifer);

  const { data, loading } = useQuery(GLOBAL_SEARCH_COUNT_QUERY, {
    variables: {
      text_query: searchParams.get('query'),
      workspace_id: workspaceIdentifier,
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    attachments_count,
    folders_count,
    patients_count,
    tasks_count,
    patient_activities_count,
    group_spaces_count,
    conversations_count,
    partner_activities_count,
  } = loading ? {} : data?.search_query_count || {};

  return {
    loading,
    countsMapping: {
      patients: patients_count,
      patientActivities: patient_activities_count,
      files: attachments_count,
      tasks: tasks_count,
      groups: group_spaces_count,
      messages: conversations_count,
      partners: partner_activities_count,
      folders: folders_count,
    },
  };
};

export default useGloablSearchCountMapping;
