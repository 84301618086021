import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.271 2.16A.882.882 0 0 1 5.786 2h3.79c.177 0 .363.05.514.16.151.108.294.305.268.56L9.316 16.39l-.002.015c-.059.411-.469.594-.78.594H6.786a.851.851 0 0 1-.497-.13.636.636 0 0 1-.302-.506L5.003 2.718a.617.617 0 0 1 .268-.559zm8.639 0a.882.882 0 0 1 .514-.16h3.79c.178 0 .364.05.515.16.15.108.294.305.268.56l-1.042 13.67-.002.015c-.059.411-.47.594-.78.594h-1.748a.851.851 0 0 1-.497-.13.636.636 0 0 1-.302-.506l-.984-13.645a.617.617 0 0 1 .268-.559z'
      fill='#E51919'
    />
    <path
      d='M9 20.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM18 20.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'
      fill='#E51919'
    />
  </svg>
);

export default SVG;
