import React from 'react';

import ConnectedUserActions from './ConnectedUserActions';
import NotificationCardLayout from './NotificationCardLayout';

const MESSAGE_SHOW_STATES = ['pending', 'accepted'];

export default class NotificationConnectionRequestCard extends NotificationCardLayout {
  onClick = (event) => {
    this.props.onClick(event, this.props);
  };

  isCTARequiredOnSmallNotification() {
    return true;
  }

  renderCanceledText() {
    if (this.props.isSmallNotification) {
      return (
        <div className='n-d-action'>
          <span className='a-non-link abs-link'>Withdrawn</span>
        </div>
      );
    } else {
      return <div className='mt-3'>This request is no longer available.</div>;
    }
  }

  renderRejectedText() {
    if (this.props.isSmallNotification) {
      return (
        <div className='n-d-action'>
          <span className='a-non-link abs-link'>Declined</span>
        </div>
      );
    } else {
      return <div className='mt-3'>Request Declined</div>;
    }
  }

  getUserConnectionObject() {
    return this.props.objects[0];
  }

  getActor() {
    return this.getUserConnectionObject().user;
  }

  renderActions() {
    let object = this.getUserConnectionObject(),
      connectionStatus = object.status,
      user = this.getActor();

    switch (connectionStatus) {
      case 'rejected':
        return this.renderRejectedText();
      case 'accepted':
        return (
          <ConnectedUserActions
            {...this.props}
            user={user}
            isSmallNotification={this.props.isSmallNotification}
          />
        );
      case 'canceled':
        return this.renderCanceledText();
      default:
        return null;
    }
  }

  renderConnectionMessage() {
    let object = this.getUserConnectionObject();

    if (object.message && MESSAGE_SHOW_STATES.includes(object.status))
      return <div className='nf-text-info'>'{object.message}'</div>;
  }

  renderBody() {
    return (
      <div>
        <div className='pointer' onClick={this.onClick}>
          {this.renderConnectionMessage()}
        </div>
        {this.renderActions()}
      </div>
    );
  }
}
