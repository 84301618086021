import React, { Component } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import LocalStorageManager from 'app/services/LocalStorageManager';

import { verifyUserEmail } from 'app/actions/authentication';

import { userDefaultPage } from 'app/utils/userRoleHelper';
import { isCurrentUser, isGuestUser } from 'app/utils/userHelper';

class EmailSourceUrl extends Component {
  sendVerifyEmailMutation(user) {
    setTimeout(() => {
      if (isGuestUser()) {
        LocalStorageManager.set('verifyEmailOnLoginId', user.id.toString());
      } else if (isCurrentUser(user)) {
        this.props.verifyUserEmail();
      }
    });
  }

  getUrlData() {
    let url, redirect_path;
    try {
      let data = jwt_decode(this.props.match.params.url, '');
      !data.user_confirmed &&
        this.sendVerifyEmailMutation({ id: data.user_id });
      url = data.path;
      redirect_path = data.redirect_path;
    } catch (e) {
      console.log(`Invalid email source token ${this.props.match.params.url}`);
      url = userDefaultPage(this.props.currentUser.graph);
    }
    return { url, redirect_path };
  }

  render() {
    let routeData = this.getUrlData(),
      info = routeData.url.split('?'),
      search = info[1] ? `?${info[1]}` : '',
      pathname = info[0];

    return (
      <Navigate
        skip={true}
        to={{
          pathname,
          search,
          state: { redirect_path: routeData.redirect_path },
        }}
      />
    );
  }
}
EmailSourceUrl = withRouter(EmailSourceUrl);

EmailSourceUrl = connect(({ currentUser }) => ({ currentUser }), {
  verifyUserEmail,
})(EmailSourceUrl);
export default EmailSourceUrl;
