import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { openInfoModal } from 'app/actions/infoModal';

const TERMINATE_SESSION_MUTATION = queryLoader(
  'app/graphql/mutations/TerminateSessionMutation.gql',
);

class TerminateSession extends Component {
  state = {
    requestInProgress: false,
  };

  componentDidMount() {
    this.terminateSession();
  }

  terminateSession() {
    this.setState({ requestInProgress: true });
    this.props
      .terminateSessionMutation({
        variables: {
          token: this.props.match.params.device_token,
          userId: this.props.match.params.user_id,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        if (data.terminateSession.success) {
          this.props.openInfoModal(
            'general',
            'new_device_session_termination_success',
          );
        } else {
          this.props.openInfoModal(
            'general',
            'new_device_session_termination_failed',
          );
        }
      });
  }

  render() {
    if (this.state.requestInProgress)
      return <Loader type='ball-triangle-path' active />;

    return <Navigate to='/default' />;
  }
}

TerminateSession = withRouter(TerminateSession);
TerminateSession = connect(null, { openInfoModal })(TerminateSession);
TerminateSession = graphql(TERMINATE_SESSION_MUTATION, {
  name: 'terminateSessionMutation',
})(TerminateSession);
export default TerminateSession;
