import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import SectionFolderList from 'app/components/SideBarFolderList/SectionFolderList';

const CollaboratingFolderList = (props) => {
  const currentUser = useSelector((state) => state.currentUser);

  const foldeSections = useMemo(() => {
    const sections = {
      myFolders: [],
      sharedFolders: [],
    };

    props.results.forEach((item) => {
      if (item.user.id.toString() === currentUser.graph.id.toString()) {
        sections.myFolders.push(item);
      } else {
        sections.sharedFolders.push(item);
      }
    });

    return sections;
  }, [props.results]);

  return (
    <>
      <SectionFolderList
        title={'My Folders'}
        results={foldeSections.myFolders}
      />
      <SectionFolderList
        title={'Shared Folders'}
        results={foldeSections.sharedFolders}
      />
    </>
  );
};

export default CollaboratingFolderList;
