import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';

import { translate } from 'app/actions/flashMessage';
import { openOnboardingVerificationModals } from 'app/actions/onboardingTips';

class ProfileIncomplete extends PureComponent {
  onClick = () => {
    this.props.openOnboardingVerificationModals();
  };

  render() {
    let { mobileDevice } = this.props.device,
      congratulationsClass = mobileDevice ? ' ' : 'mt-4 pt-2',
      verifyClass = ' my-4 py-2',
      successfullyClass = mobileDevice ? 'lh-15' : '';

    return (
      <div className='onboard-success-container'>
        <article className='onboard-success-message'>
          <div className='congratulations-block verification-block-pg'>
            <div className='img-block'>
              <Icon
                name='cannotDelete'
                className='onboard-success-image m-auto'
              />
            </div>

            <div className='text-block text-center'>
              <h2 className={`head-1 lh-normal mb-3 ${congratulationsClass}`}>
                Verification Required!
              </h2>
              <div className={`head-4 ${successfullyClass}`}>
                {translate('VERIFICATION_PENDING_HEADING')}
              </div>
            </div>
          </div>

          <div className={verifyClass}>
            {translate('VERIFICATION_PENDING_MESSAGE')}
          </div>

          <div className='action-col mt-2 text-center'>
            <OsBtn
              name='BtnPrimary'
              text='Submit Verification Information'
              extraClass='ls-05'
              onClick={this.onClick}
            />
          </div>
        </article>
      </div>
    );
  }
}

ProfileIncomplete = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { openOnboardingVerificationModals },
)(ProfileIncomplete);

export default ProfileIncomplete;
