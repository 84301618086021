import { keys } from 'app/utils/osLodash';

class RefetchQueriesManager {
  constructor() {
    this.queries = {};
    this.usersToConnectWithQueries = {};
  }

  addQueries(key, callback) {
    this.queries[key] = callback;
  }

  refetch(key, data = {}) {
    return this.queries[key](data);
  }

  addUsersToConnectWithQueries(key, callback) {
    this.usersToConnectWithQueries[key] = callback;
  }

  removeUsersToConnectWithQueries(key) {
    delete this.usersToConnectWithQueries[key];
  }

  refetchAllUsersToConnectWithQueries(data = {}) {
    keys(this.usersToConnectWithQueries).forEach((key) =>
      this.usersToConnectWithQueries[key](data),
    );
  }
}

export default new RefetchQueriesManager();
