import { translate } from 'app/actions/flashMessage';
import FeedView from 'app/components/FeedView';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { COMMENTS_ACTIVITY_TYPE } from 'app/constants';
import React, { useEffect } from 'react';
import { compact } from 'app/utils/osLodash';

function PatientSpaceInboxFeed(props) {
  const renderFeeds = () => {
    if (!props.results.length) {
      return renderNoFeedMessage();
    } else {
      const feeds = props.results,
        actionFeeds = feeds.map((feed) => {
          if (['Feed', 'PublicFeed', 'Activity'].includes(feed.__typename)) {
            return feed;
          }
        });

      return compact(actionFeeds).map((feed, index) => {
        return <>{renderFeed(feed, index)}</>;
      });
    }
  };

  useEffect(() => {
    props.updateCount(props.totalRecords);
  }, [props.totalRecords]);

  const renderFeed = (feed, index) => {
    if (feed) {
      return (
        <FeedView
          feed={feed}
          key={feed.id}
          isInboxFeed={COMMENTS_ACTIVITY_TYPE.includes(props.listType)}
          listType={props.listType}
          entity={props.entity}
          feedType={props.feedType}
        />
      );
    }
  };

  const noActivityMessage = () => {
    switch (props.listType) {
      case 'MyActivities':
        return translate('NO_ACTIVITY_BY_ME');
      case 'NewUserActivities':
      case 'UserActivities':
        return translate('NO_ACTIVITY_BY_USER');
      case 'UnprocessedCareSpaceComments':
      case 'UnprocessedPartnerSpaceComments':
        return translate('NO_UNPROCESSED_COMMENTS_PRESENT');
      default:
        return translate('NO_ACTIVITY_YET');
    }
  };

  const renderNoFeedMessage = () => {
    return (
      <div className='no-cases-block'>
        <OrthoIcon
          name='empty-activity'
          dataHoverNotRequired={true}
          defaultClass='icon-no-content'
        />
        <div className='no-cases-message-block'>{noActivityMessage()}</div>
      </div>
    );
  };

  return (
    <div>
      <ul
        className='activity-listing list-unstyled mb-0'
        style={{ position: 'relative' }}>
        {renderFeeds()}
      </ul>
    </div>
  );
}

export default PatientSpaceInboxFeed;
