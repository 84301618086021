import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import lock from 'app/images/profile-switcher/lock.webp';
import { avatarAttributes } from 'app/utils/entitiesHelper';

import { openInfoModal } from 'app/actions/infoModal';
import {
  SHOW_SUCCESS_MESSAGE,
  UPDATE_PASSWORD_PRESENT_FIELD,
} from 'app/actions/types';

import Avatar from 'app/components/Shared/Avatar';
import GetPinFields from 'app/components/SwitchProfile/GetPinFields';
import OsBtn from 'app/components/OsBtn';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const SET_PROFILE_PIN = queryLoader(
  'app/graphql/QuickSwitcher/SetProfilePin.gql',
);

function ProfileSetAndUpdateForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const workspace = useSelector((state) => state.workspace);

  const isSetPinForm = params['*'].split('/')[2] === 'set_password';
  const [setNewPin, { data: newPinData }] = useMutation(SET_PROFILE_PIN);

  const handleSubmit = (attributes) => {
    const pin =
      attributes.newPin0 +
      attributes.newPin1 +
      attributes.newPin2 +
      attributes.newPin3;
    setNewPin({
      variables: {
        pin: pin,
      },
    });
  };

  const formValidation = (values) => {
    const errors = {};
    let pin = values.newPin0 + values.newPin1 + values.newPin2 + values.newPin3;
    let confirmPin =
      values.confirmPin0 +
      values.confirmPin1 +
      values.confirmPin2 +
      values.confirmPin3;

    if (
      !values.newPin0 ||
      !values.newPin1 ||
      !values.newPin2 ||
      !values.newPin3
    ) {
      errors.newPin0 = 'Required';
    }
    if (
      !values.confirmPin0 ||
      !values.confirmPin1 ||
      !values.confirmPin2 ||
      !values.confirmPin3
    ) {
      errors.confirmPin0 = 'Required';
      return errors;
    }
    if (pin !== confirmPin && Object.keys(values).length !== 0) {
      errors.confirmPin0 = 'confirm pin is incorrect.';
    }
    return errors;
  };

  useEffect(() => {
    if (newPinData !== undefined) {
      if (newPinData.setWorkspaceProfilePin.success === true) {
        navigate(-1);
        dispatch({ type: SHOW_SUCCESS_MESSAGE, message: 'Profile Pin set.' });
        dispatch({ type: UPDATE_PASSWORD_PRESENT_FIELD, payload: true });
      } else {
        dispatch(openInfoModal('general', 'error_message'));
      }
    }
  }, [newPinData]);

  return (
    <div className='profileSwitcherForm'>
      <SetPageTitle source={'profile_switcher'} interpolations={'Set Pin'} />
      <section>
        <div className='imageContainer'>
          {workspace.data?.owner?.owner?.logo_big ? (
            <img src={workspace.data.owner.owner.logo_big} alt='profile-lock' />
          ) : (
            <img src={lock} alt='profile-lock' />
          )}
        </div>
        <div className={'profile-switcher-card-view'}>
          <Form
            onSubmit={handleSubmit}
            validate={(values) => {
              let errors = formValidation(values);
              return errors;
            }}
            render={(props) => {
              return (
                <form
                  className={'profile-switcher-form'}
                  onSubmit={props.handleSubmit}>
                  <p className='profile-switcher-form-heading'>
                    <strong>
                      {isSetPinForm ? 'Set Pin (2/2)' : 'Reset Pin (2/2)'}
                    </strong>
                  </p>
                  <div className='profile-switcher-form-user-field'>
                    <label for={'users'}>User</label>
                    <div
                      className={'select-user-profile'}
                      style={{ marginBottom: '24px' }}>
                      <div className='userField'>
                        <Avatar
                          className='avatar'
                          {...avatarAttributes(currentUser.graph)}
                        />
                        <span>{currentUser?.graph.full_name}</span>
                      </div>
                    </div>
                    <label htmlFor={'users'}>Enter a new pin*</label>
                    <div className={'password-block'}>
                      {GetPinFields('newPin', 4)}
                    </div>
                    <label style={{ marginTop: '24px' }}>Confirm Pin*</label>
                    <div className={'password-block'}>
                      {GetPinFields('confirmPin', 4)}
                    </div>
                  </div>
                  <div className='profileSwitcherPrimaryBtn'>
                    <OsBtn
                      name={'BtnPrimary'}
                      type={'submit'}
                      value={isSetPinForm ? 'Set Pin' : 'Reset Pin'}
                      extraClass={'profile-switcher-btn'}
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default ProfileSetAndUpdateForm;
