import {
  VIDEO_FILE_TYPES,
  VIEWABLE_FILE_EXTENSIONS,
  ZOOMABLE_FILE_EXTENSIONS,
} from 'app/constants';

export const FILE_EXTENSION_REGEX = /.*\.(\w+)$/;

export function cloneFileWithRandomName(originalFile) {
  var newFile = new Blob([originalFile], { type: originalFile.type });
  newFile.originalName = originalFile.name;
  newFile.name = `${getFileNameWithoutExtension(
    sanitizeName(originalFile.name),
  )}_${+new Date()}.${fetchFileExtension(originalFile.name)}`;
  newFile.lastModifiedDate = originalFile.lastModifiedDate;
  newFile.preview = URL.createObjectURL(originalFile);
  return newFile;
}

export function cloneFileWithSameName(originalFile) {
  if (originalFile) {
    var newFile = new Blob([originalFile], { type: originalFile.type });
    newFile.originalName = originalFile.originalName;
    newFile.name = originalFile.name;
    newFile.lastModifiedDate = originalFile.lastModifiedDate;
    newFile.preview = originalFile.preview;
    newFile.id = originalFile.id;
    return newFile;
  }
}

export function fetchFileExtension(fileName) {
  let results = FILE_EXTENSION_REGEX.exec(fileName);
  return results && results.length ? results[1] : '';
}

export function formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    sizeIndex = Math.floor(Math.log(bytes) / Math.log(1000));
  return parseInt(bytes / Math.pow(1000, sizeIndex)) + ' ' + sizes[sizeIndex];
}

export function getFileExtensionFromUrl(url) {
  return fetchFileExtension(getFilePathFromUrl(url).split('/').pop());
}

export function getFileNameWithoutExtension(filename) {
  if (filename) {
    let data = filename.split('.');
    return data.length > 1 ? data.slice(0, -1).join('.') : data[0];
  }
}

export function getFilePathFromUrl(url) {
  if (url) {
    let urlObject = new URL(url);
    return urlObject.pathname;
  }
  return '';
}

export function getFileType(file) {
  if (isImage(file)) {
    return 'Image';
  } else if (isAudio(file)) {
    return 'Audio';
  } else if (isVideo(file)) {
    return 'Video';
  } else {
    return 'Document';
  }
}

export function isBlob(file) {
  return (
    file.constructor.name === 'Blob' ||
    (file.preview_url && file.preview_url.startsWith('blob'))
  );
}

export function isDownloadable(file) {
  return !!file.download_url;
}

export function isViewable(file) {
  return (
    isImage(file) ||
    isSupportedByFileViewer(file) ||
    isVideoProcessed(file) ||
    isAudio(file) ||
    isSTL(file) ||
    isPodcastURL(file)
  );
}

export function isFileTypeInvalid(file, validTypes) {
  if (isImage(file) && validTypes.includes('image/*')) return false;

  let extension = fetchFileExtension(file.name);
  return !(extension && validTypes.includes(extension));
}

export function isFileSizeInvalid(file, maxSize) {
  return file.size > maxSize;
}

export function isAudio(file) {
  return (
    file.kind === 'audio' ||
    (file.media_type && !!file.media_type.match(/^audio\//))
  );
}

export function isImage(file) {
  let fileType = String(file.type || file.media_type);
  return (
    file.kind === 'image' ||
    (fileType.startsWith('image') &&
      (!file.sub_type || !file.sub_type.includes('photoshop')))
  );
}

export function isPDF(file) {
  return fetchFileExtension(file.name) === 'pdf';
}

export function isPdfProcessed(file) {
  return (
    isPDF(file) &&
    file.meta_info &&
    JSON.parse(file.meta_info)['attachment_thumbnail_processed']
  );
}

export function isPodcastURL(file) {
  return file && file.url?.match(/podcasts.apple.com/);
}

export function isSTL(file) {
  return fetchFileExtension(file.name)?.toLowerCase() === 'stl';
}

export function isSvg(file) {
  return fetchFileExtension(file.name)?.toLowerCase() === 'svg';
}

export function isSupportedByFileViewer(file) {
  let extension = getFileExtensionFromUrl(file.preview_url);
  return extension && VIEWABLE_FILE_EXTENSIONS.includes(extension);
}

export function isVideo(file) {
  let fileType = String(file.type || file.media_type),
    fileSubType = String(file.sub_type).toLowerCase();

  return (
    file.__typename === 'Video' ||
    file.kind === 'video' ||
    fileType.startsWith('video') ||
    VIDEO_FILE_TYPES.includes(fileSubType)
  );
}

export function isVideoProcessed(file) {
  return (
    isVideo(file) &&
    ((file.meta_info &&
      (JSON.parse(file.meta_info)['both_processed'] ||
        JSON.parse(file.meta_info)['mp4_processed'])) ||
      !!file.preview_url)
  );
}

function sanitizeName(name) {
  return name.replace(/\+/g, '-');
}

export function isZoomable(file) {
  let extension = getFileExtensionFromUrl(file.preview_url);
  return extension && ZOOMABLE_FILE_EXTENSIONS.includes(extension);
}

export function rejectVideoFiles(files) {
  return files.filter((file) => !isVideo(file));
}

export function isLoomUrl(url) {
  return !!url?.match(/loom.com/);
}

export function isYoutubeUrl(url) {
  return !!url?.match('^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$');
}

export function isLoomOrYoutubeUrl(url) {
  return isLoomUrl(url) || isYoutubeUrl(url)
}

export function getLoomEmbedUrl(url) {
  return url ? url.replace('share', 'embed') : ""
}
