import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import AbstractCard from 'app/components/OsCards/AbstractCard';
import Avatar from 'app/components/Shared/Avatar';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import ShareWithPatientButton from 'app/components/Shared/ShareWithPatientButton';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { isEditInformationRequired } from 'app/utils/featureHelper';

import { openPatientModal } from 'app/actions/patientModal';
import {
  getCareSpaceCardAvatarIconProps,
  getNotifiablePersonConnection,
  isSharedWithUser,
  isUserAuthenticated,
  shareWithPatientCommonProps,
} from 'app/utils/spaces/careHelper';

class CareSpaceCard extends AbstractCard {
  onCardClick = (e, options = {}) => {
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  patientModalSource() {
    return 'care-space-card';
  }

  openPatientModal = () => {
    this.props.openPatientModal(this.props.obj.nice_id, {
      source: this.patientModalSource(),
    });
  };

  renderEditInformation() {
    return (
      <OsBtn
        name='BtnIcon'
        text='Edit information'
        icon='edit'
        extraClass='list-button'
        onClick={this.openPatientModal}
      />
    );
  }

  renderReferToAnotherDoctor() {
    return (
      <OsBtn
        name='BtnIcon'
        text='Refer to Another Doctor'
        icon='referral'
        extraClass='list-button'
      />
    );
  }

  renderShareWithPatient() {
    return (
      <ShareWithPatientButton
        {...shareWithPatientCommonProps(this.props.obj)}
        source={this.patientModalSource()}
        patient={this.props.obj.owner.patient}
      />
    );
  }

  renderDropdown() {
    return (
      <CustomDropdown
        name='care_space_action'
        className='card-dropdown-menu care-space-card-dropdown top-18'
        onDropDownToggle={this.props.onDropDownToggle}>
        {isEditInformationRequired(this.props.workspace) &&
          this.renderEditInformation()}
        {this.renderSelectButton('patient')}
        {false && this.renderReferToAnotherDoctor()}
        {this.renderShareWithPatient()}
      </CustomDropdown>
    );
  }

  renderContactInformation(obj) {
    let sourceObject = obj,
      notifiablePersonConnection = getNotifiablePersonConnection(obj),
      relation;

    if (notifiablePersonConnection) {
      sourceObject = notifiablePersonConnection.related_person;
      relation = notifiablePersonConnection.relation;
    }
    return (
      <>
        {relation && (
          <div className='relation'>{`${relation} : ${sourceObject.name}`}</div>
        )}
        <div className='pt-1'>
          {sourceObject.email && (
            <div className='mailinfo'>{sourceObject.email}</div>
          )}
          {sourceObject.phone_number && (
            <div className='phone-number'>{sourceObject.phone_number}</div>
          )}
        </div>
      </>
    );
  }

  renderPatientMailInfo(obj) {
    const { date_of_birth } = obj;

    return (
      <>
        <div className='dob'>{date_of_birth}</div>
        {this.renderContactInformation(obj)}
      </>
    );
  }

  renderPatientMessage() {
    return (
      <div className='patient-message d-none'>
        Hi Sean! Please, take a look at this patient. He is in your area for a
        business trip and he needs an emergency appt. to replace LL2 bracket.
        Can you please take a look? Thanks!
      </div>
    );
  }

  renderSharedWithPatientIcon() {
    let { obj } = this.props,
      iconName,
      title;
    const user = { id: obj.owner.patient.user_id };
    if (isUserAuthenticated(obj, user)) {
      iconName = 'checkmark-round';
      title = 'Accepted';
    } else if (isSharedWithUser(obj, user)) {
      iconName = 'checkmark-round-grey';
      title = 'Shared';
    } else if (!obj.shared_with_any_patient) {
      iconName = 'error';
      title = 'Declined';
    } else {
      iconName = 'checkmark-round-default';
      title = 'Not shared';
    }
    // if (obj.authenticated_user_ids.length > 0) {
    //   iconName = obj.all_primary_people_muted ? 'mute' : '';
    // } else {
    //   iconName = obj.shared_with_any_patient ? 'checkmark-round' : 'error';
    // }

    // if (iconName)
    return (
      <span className='misccase_tags only-icon'>
        <OrthoIcon
          name={iconName}
          defaultClass=''
          dataHoverNotRequired={true}
          title={title}
        />
      </span>
    );
  }

  renderSharedWithClinicIcon() {
    if (false)
      return (
        <span className='misccase_tags'>
          <OrthoIcon
            name='tool-fill'
            defaultClass=''
            dataHoverNotRequired={true}
          />
        </span>
      );
  }

  render() {
    let isTall = ['tall', 'tall-min'].includes(this.props.size),
      avatarClass = `avatar avatar-${isTall ? 64 : 64}`,
      { obj } = this.props,
      { patient, clinic } = obj.owner;

    return (
      <div
        key={this.props.key}
        className={this.props.containerClasses}
        onClick={this.onCardClick}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            onClick={this.afterClose}
            defaultClass='os-close-link'
          />
        )}

        <div className='patient-card-body'>
          <div className='patient-card-info'>
            <Avatar
              className={avatarClass}
              {...avatarAttributes(patient, {
                largeAvatarRequired: isTall,
                ...getCareSpaceCardAvatarIconProps(obj, patient.user),
              })}
            />
            <div className='patient-cd-name'>
              {patient.name}
              {this.renderSharedWithPatientIcon()}
              {this.renderSharedWithClinicIcon()}
            </div>
            <div className='patient-cd-address'>
              {this.renderPatientMailInfo(patient)}
            </div>
            <div className='patient-cd-clinic-name pt-0'>
              {this.props.source.isCare && clinic.name}
            </div>
          </div>
          {isTall && this.renderPatientMessage()}
        </div>
        {this.props.multiSelectMode && this.renderSelectBox()}
        {this.props.obj.is_author_or_editor && this.renderDropdown()}
      </div>
    );
  }
}

CareSpaceCard = withRouter(CareSpaceCard);

CareSpaceCard = connect(
  ({ currentUser, source, workspace }) => ({ currentUser, source, workspace }),
  {
    openPatientModal,
  },
)(CareSpaceCard);
export default CareSpaceCard;
