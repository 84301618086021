import {
  CLOSE_ONBOARDING_VERIFICATION_MODALS,
  OPEN_ONBOARDING_VERIFICATION_MODALS,
  OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
  RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
  SET_ONBOARDING_TIP_REF,
  SET_ONBOARDING_TIP_VISIBILITY,
  UNSET_ONBOARDING_TIP,
} from './types';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

export function setOnboardingTipRef(tooltipRef) {
  return (dispatch) => {
    dispatch({
      type: SET_ONBOARDING_TIP_REF,
      tooltipRef,
    });
  };
}

export function setOnboardingTipVisibility(visible) {
  return (dispatch) => {
    dispatch({
      type: SET_ONBOARDING_TIP_VISIBILITY,
      visible,
    });
  };
}

export function unsetOnboardingTip() {
  return (dispatch) => {
    dispatch({
      type: UNSET_ONBOARDING_TIP,
    });
  };
}

export function openOnboardingVerificationModals(hideCloseOptions = false) {
  return {
    type: OPEN_ONBOARDING_VERIFICATION_MODALS,
    hideCloseOptions,
    openVerificationModals: !isCareWorkspaceView(),
  };
}

export function openOnboardingModalWithScreen(screen) {
  return {
    type: OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
    screen,
  };
}

export function resetOnboardingModalWithScreen() {
  return {
    type: RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
  };
}

export function closeOnboardingVerificationModals() {
  return {
    type: CLOSE_ONBOARDING_VERIFICATION_MODALS,
  };
}
