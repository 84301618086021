import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  nice_id,
  doctor,
  category,
  categoryType,
  rightLabel,
  onClick,
  trackOnClick,
}) => {
  let onClickParams = { category: category, categoryType: categoryType },
    link;
  onClickParams = { ...onClickParams, query_by: 'cases', id_query: nice_id };

  if (category === 'cases') {
    link = `/cases/${nice_id}`;
  } else {
    link = `/search/${category}/cases/${nice_id}`;
  }

  return (
    <SearchResult
      key={`Case:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>{rightLabel}</span>
        {nice_id}
      </div>
    </SearchResult>
  );
};
