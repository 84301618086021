import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M6.066 8.015c-.37 1.188-.061 2.506.523 3.597.215.378.482.766.872.94C6.825 8.608 8.67 4.3 11.91 2c-.43.8-.133 1.825.318 2.592.38.659.872 1.242 1.436 1.728.739.637 1.62 1.123 2.154 1.955.225.345.369.745.43 1.166l.041-.01c.267-1.264 1.375-2.074 2.533-2.463-.39 1.199-.153 2.527.206 3.747.358 1.21.85 2.409.953 3.672a6.777 6.777 0 0 1-.553 3.186c-.76 1.793-2.482 3.359-4.256 3.985-2.195.626-5.436.68-7.435-.453-2.892-1.631-3.333-3.262-3.682-5.627-.359-2.473 1.087-5.152 2.01-7.463z'
      fill='#F34A16'
    />
    <path
      d='M15 8.013c1.31 1.201.82 4.41.94 4.725.02.06.09.08.14.04.941-.739 1.321-2.747 1.431-3.416.01-.07.11-.079.15-.01 2.052 3.505 1.421 6.015.26 7.64-1.35 1.909-3.242 2.874-5.603 2.992-3.843.207-5.604-1.615-6.795-3.564-.36-.61-.6-2.195-.5-3.002.11-.827.78-2.59.77-2.54 0 .305.02 1.979.42 2.54.561.807 2.442.325 2.112-.886-.94-3.416 1.871-7.472 2.712-8.506.05-.059.15-.01.13.069-.73 2.727.47 4.499.81 4.784C14.66 11.055 14.9 7.924 15 8.013z'
      fill='#FA754C'
    />
    <path
      d='M6.222 15.35c.57.684 2.438.276 2.113-.76C7.4 11.634 9.899 7.86 10.71 7.016c.051-.048.133.019.102.133-.792 2.318.853 4.058 1.188 4.305 2.682 1.872 2.915-.818 3.017-.742 1.31 1.036.822 3.783.944 4.058.01.048.082.067.122.039.945-.647 1.33-2.405 1.442-2.947.01-.057.092-.066.122-.019 2.062 3.023 1.442 5.17.274 6.577-1.35 1.634-3.24 2.461-5.606 2.566-3.839.18-5.606-1.388-6.794-3.06-.366-.533-.6-1.882-.498-2.576.122-.703.792-2.224.792-2.176-.01.256.01 1.692.406 2.176z'
      fill='#FFC933'
    />
    <path
      d='M8.698 17.771c.325.392 1.393.158 1.208-.434-.534-1.689.893-3.845 1.358-4.328.029-.027.075.01.058.076-.453 1.325.487 2.319.679 2.46 1.532 1.07 1.665-.467 1.724-.424.748.592.47 2.162.54 2.32.005.026.046.037.069.021.54-.37.76-1.374.824-1.684.006-.032.052-.037.07-.01 1.178 1.727.824 2.954.156 3.758-.772.934-1.851 1.406-3.203 1.466-2.194.103-3.204-.793-3.883-1.749-.209-.304-.342-1.075-.284-1.471.07-.402.452-1.271.452-1.244-.005.146.006.967.232 1.244z'
      fill='url(#imps8pwgva)'
    />
    <defs>
      <linearGradient
        id='imps8pwgva'
        x1='12'
        y1='21'
        x2='11.891'
        y2='13.002'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#fff' />
        <stop offset='1' stop-color='#fff' stop-opacity='0' />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
