import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import AbstractUserView from './AbstractUserView';
import Header from './Header';

import { isCurrentUser } from 'app/utils/userHelper';

class Layout extends AbstractUserView {
  filterCountMapperForVisibilty() {
    let mapper = {};
    return mapper;
  }

  isCurrentUserProfile() {
    return isCurrentUser(this.props.user);
  }

  render() {
    return (
      <div className='user-container px-0'>
        <div className='detail detail-user'>
          <div className='container-fluid xl-container  px-hd-40 px-sm-32 px-m-12'>
            <Header user={this.props.user} />
          </div>
          <div className='user-details-tabs-block sticky-tab-with-dropdown'>
            {this.renderTabList()}
            <div
              className={`user-tab-container container-fluid px-52 xl-container ${
                this.getTabFilterValue() || ''
              }`}>
              {<Outlet />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Layout = withRouter(Layout);
Layout = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  null,
)(Layout);
export default Layout;
