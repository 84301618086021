import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'app/utils/osLodash';

import { openUpgradeModal } from 'app/actions/upgrade';

const withAuthorize = (WrappedComponent) => {
  class HOC extends Component {
    isGeneralUser() {
      return !!(
        this.props.currentUser.graph &&
        this.props.currentUser.graph.is_general_user
      );
    }

    isGuestUser() {
      return !this.props.currentUser.graph;
    }

    isUserPermitted() {
      let { action, entity, options = {} } = this.props.abilityObject || {};
      return (
        (this.isGuestUser() && options.guest) ||
        !this.props.abilityObject ||
        (!isEmpty(this.props.currentUser) &&
          this.props.currentUser.ability &&
          this.props.currentUser.ability.can(action, entity, options))
      );
    }

    openUpgradeModalIfUnauthorized = (callBack) => {
      if (!this.isUserPermitted()) {
        this.props.openUpgradeModal();
      } else {
        callBack();
      }
    };

    renderAllowed() {
      const { abilityObject = { options: {} } } = this.props,
        hideComponent =
          abilityObject.options.hideIfNotAllowed ||
          (abilityObject.action === 'index' && abilityObject.entity === 'case');

      if (hideComponent) {
        return this.isUserPermitted();
      } else {
        return !(this.isGeneralUser() && !this.isUserPermitted());
      }
    }

    render() {
      if (this.renderAllowed()) {
        return (
          <WrappedComponent
            openUpgradeModalIfUnauthorized={this.openUpgradeModalIfUnauthorized}
            {...this.props}
          />
        );
      } else {
        return null;
      }
    }
  }

  HOC = connect(({ currentUser }) => ({ currentUser }), { openUpgradeModal })(
    HOC,
  );
  return HOC;
};

export default withAuthorize;
