import {
  CLOSE_TASK_PREVIEW_MODAL,
  OPEN_TASK_PREVIEW_MODAL,
} from 'app/actions/types';

export default function taskPreviesModal(
  state = { open: false, taskId: null, options: {} },
  action,
) {
  switch (action.type) {
    case OPEN_TASK_PREVIEW_MODAL:
      return { open: true, taskId: action.taskId, ...action.options };
    case CLOSE_TASK_PREVIEW_MODAL:
      return { open: false, taskId: null, source: null };
    default:
      return state;
  }
}
