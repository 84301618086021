import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import useTaskPreview from 'app/components/TaskPreviewModal/useTaskPreview';

import { openTaskPreviewModal } from 'app/actions/taskPreviewModal';
import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';

const TaskPreviewModal = () => {
  const { closeModal, taskPreviewModal } = useTaskPreview({});
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const TASK_URL_HASH_FORMAT = '#task-';
    const hashFormat = location.hash.replace(/[0-9]/g, '');

    if (hashFormat === TASK_URL_HASH_FORMAT) {
      let taskPreviewId = location.hash.replace(TASK_URL_HASH_FORMAT, '');
      dispatch(openTaskPreviewModal(taskPreviewId, {}));
    }
  }, []);

  return (
    <Modal
      show={taskPreviewModal.open}
      onHide={closeModal}
      dialogClassName={'task-preview-modal'}>
      <TaskPreview isModal={true} />
    </Modal>
  );
};

export default TaskPreviewModal;
