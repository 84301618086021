import React, { useEffect } from 'react';
import {
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { FORMAT_START_WITH_MONTH_SLASH } from 'app/components/Task/taskConstant';
import OsBtn from 'app/components/OsBtn';

const DisplayFormButtons = (props) => {
  const {
    handleSubmit,
    initialValues,
    isEditView,
    username,
    closeModal,
    showDeleteModalView,
    isSubmitting,
  } = props;
  useEffect(() => {
    // Add event listener for ctrl + enter or cmd + enter to submit the form
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
        event.stopImmediatePropagation();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const commonProps = {
    htmlTag: 'button',
    extraClass: 'web-view-btn btn-save-info',
  };

  return (
    <div className={'modal-footer p-0'}>
      <div>
        {isEditView ? (
          <span className='createdByText'>
            by {initialValues.createdBy} •{' '}
            {timeWithFormat(
              initialValues.createdAt,
              `${FORMAT_START_WITH_MONTH_SLASH} hh:mm a`,
            )}
          </span>
        ) : (
          <span className='createdByText'>
            by {username} •{' '}
            {getDateFromStringWithGivenFormat(
              currentTimeWithUserTimeZone(),
              `${FORMAT_START_WITH_MONTH_SLASH} hh:mm a`,
            )}
          </span>
        )}
      </div>
      <div className={'task-modal-buttons'}>
        {isEditView && (
          <OsBtn
            type='button'
            name='BtnOutline'
            text='Delete Task'
            extraClass='me-auto'
            onClick={() => showDeleteModalView(true)}
            {...commonProps}
          />
        )}
        <OsBtn
          type='button'
          name='BtnSecondary'
          text='Cancel'
          onClick={closeModal}
          {...commonProps}
        />
        <OsBtn
          id={'task-modal-submit-button'}
          type='button'
          name='BtnPrimary'
          onClick={handleSubmit}
          disabled={isSubmitting}
          text={isSubmitting ? 'Saving' : 'Save'}
          {...commonProps}
        />
      </div>
    </div>
  );
};

export default DisplayFormButtons;
