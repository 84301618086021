import store from 'app/store';
import { isNewSubSpaceAllowed } from 'app/utils/spaceHelper';
import {
  currentUser,
  isFeaturePermitted,
  isGuestUser,
} from 'app/utils/userHelper';
import { categoryFromUrl } from '../stringHelper';
import { isMobileDevice } from 'app/utils/deviceHelper';

const SEARCH_AVAILABLE_OUTSIDE_COMMUNITY = ['spaces'];

export function isCareWorkspaceView() {
  return store.getState().source.isCare;
}

export function isSourceWorkspace() {
  return store.getState().source.isWorkspace;
}

export function isWorkspaceUpgradeRequired({ value }) {
  return value.split(',').includes(getWorkspaceData().identifier);
}

export function getWorkspaceData() {
  return isWorkspaceView() ? store.getState().workspace.data : {};
}

export function getPartnerSpaceData() {
  return store.getState().activeResource?.resource;
}

export function getWorkspaceIdentifier() {
  return getWorkspaceData().identifier;
}

export function isWorkspaceMember() {
  return !!getWorkspaceData().is_author_or_member;
}

export function isWorkspaceAuthorOrEditor() {
  return !!getWorkspaceData().is_author_or_editor;
}

export function isWorkspaceMemberOrEditor() {
  return isWorkspaceMember() || isWorkspaceAuthorOrEditor();
}

export function isWorkspaceSpaceSponsored() {
  return isWorkspaceView() && store.getState().workspace.sponsored;
}

export function isWorkspaceView() {
  return store.getState().workspace && !!store.getState().workspace.identifier;
}

export function isWorkspaceSpace(entity) {
  return getWorkspaceData().nice_id === entity.nice_id;
}

export function isAdminOfWorkspace(space) {
  return (
    !isGuestUser() &&
    isWorkspaceSpace(space) &&
    +getWorkspaceData().author_id === +store.getState().currentUser.graph.id
  );
}

export function isAuthorOrEditorOfWorkspace() {
  return isWorkspaceView() && getWorkspaceData().is_author_or_editor;
}

export function isNewSubSpaceAllowedInWorkspace() {
  return (
    isAuthorOrEditorOfWorkspace() && isNewSubSpaceAllowed(getWorkspaceData())
  );
}

export function getWorkspaceViewUrl() {
  return getWorkspaceData().nice_url_with_host;
}

export function isWorkspaceAccessible() {
  let workspace = getWorkspaceData();
  return (
    isAdminOfWorkspace(workspace) || workspace.is_member_of_space_or_sub_space
  );
}

export function isClinicTypeWorkspace() {
  return getWorkspaceData().workspace_type === 'Clinic';
}

export function isCommentsEnabledInWorkspace() {
  return true;
}

export function isWorkspaceInception() {
  return false;
}

export function isWorkspaceViewLoggedIn() {
  return isWorkspaceView() && !isGuestUser();
}

export function getNavSearchProps(currentPath) {
  return {
    list: SEARCH_AVAILABLE_OUTSIDE_COMMUNITY,
    default: categoryFromUrl(currentPath, SEARCH_AVAILABLE_OUTSIDE_COMMUNITY),
  };
}

export function withWorkspaceIdentifier(url) {
  if (url) {
    const identifier = getWorkspaceIdentifier() || 'care';
    return url.startsWith(`/${identifier}`) ? url : `/${identifier}${url}`;
  } else {
    return url;
  }
}

export function isUnifiedEnabled() {
  return !isMobileDevice();
}

export function userDefaultRootRedirectPath() {
  let user = currentUser(),
    { guestOwnerData } = store.getState().workspace;
  if (guestOwnerData) {
    return `/${guestOwnerData.identifier}`;
  } else if (!getWorkspaceData()?.featured)
    return withWorkspaceIdentifier('/') + 'partners';
  else if (user.accessible_workspaces_count > 1) {
    return '/launch';
  } else if (user.accessible_workspaces_count === 1) {
    return isUnifiedEnabled()
      ? withWorkspaceIdentifier('/inbox/my/tasks')
      : withWorkspaceIdentifier('/');
  } else {
    return '/care';
  }
}
