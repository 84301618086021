import OsField from 'app/components/OsField';
import { isTouchSupported } from 'app/utils/deviceHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import integrationDisabled from 'app/images/Integration/integration.svg';
import React, { useContext } from 'react';
import useAddDomain from 'app/components/WorkspaceView/SettingView/IntegrationConfig/useAddDomain';
import { IntegrationConfigContext } from 'app/components/WorkspaceView/SettingView/IntegrationConfig/index';
import DomainItem from 'app/components/WorkspaceView/SettingView/IntegrationConfig/DomainItem';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';
import 'app/components/WorkspaceView/SettingView/IntegrationConfig/Styles/Integration.scss';
import DeepLinkIntegrationCheckbox from 'app/components/WorkspaceView/SettingView/IntegrationConfig/DeepLinkIntegrationCheckbox';

const IntegrationConfigView = () => {
  const {
    hostname,
    setHostname,
    integrationEnabled,
    setIntegrationEnabled,
    allDomains,
    allDomainsVariable,
  } = useContext(IntegrationConfigContext);
  const { addDomainToWebsite } = useAddDomain();
  const workspaceData = getWorkspaceData();
  return (
    <>
      <div className='container configuration-view mt-4 p-0'>
        <div className='row-fluid'>
          <div className='col-14 basic-info-section'>
            <div
              className={'integration-section-header'}
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3
                style={{
                  fontWeight: '700',
                  lineHeight: '24px',
                  fontSize: '20px',
                  fontStyle: 'medium',
                  height: '24px',
                  width: '193px',
                  padding: 'auto 0',
                }}>
                Website Integration
              </h3>
              <OsField
                osType='checkbox'
                onChange={(e) =>
                  setIntegrationEnabled((prevState) => !prevState)
                }
                slider={true}
                dataHoverRequired={!isTouchSupported()}
                className='switch'
                isChecked={integrationEnabled}
                disabled={false}
              />
            </div>
            <p
              className={'integration-section-description'}
              style={{ marginTop: '24px' }}>
              This integration allows you to add a Cloudberry Banner to your
              existing website to bring existing and new patients directly into
              Cloudberry.
            </p>
            {integrationEnabled ? (
              <>
                <div
                  className={'integration-section-content'}
                  style={{ display: 'flex', marginTop: '24px', gap: '8px' }}>
                  <div
                    className={'left-side-setting'}
                    style={{ minHeight: '80px', flex: '1', padding: '4px' }}>
                    <DeepLinkIntegrationCheckbox />
                    <h3
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '16px',
                      }}>
                      Add your Website
                    </h3>
                    <div
                      className={'integration-form'}
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        gap: '24px',
                        marginBottom: '8px',
                      }}>
                      <OsField
                        type={'input'}
                        value={hostname}
                        onChange={({ target }) => setHostname(target.value)}
                        placeholder={'https://www.example.com'}
                      />
                      <p
                        style={{
                          marginLeft: '11px',
                          marginRight: '3px',
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '16px',
                          color: '#5D7893',
                        }}
                        onClick={() => addDomainToWebsite(hostname, true)}>
                        DONE
                      </p>
                      <OrthoIcon
                        name={'delete'}
                        defaultClass='ms-1'
                        disabled={true}
                      />
                    </div>
                    {allDomains?.records?.results.map((domain, index) => {
                      return (
                        <DomainItem
                          key={domain.id}
                          domain={domain}
                          allDomainsVariable={allDomainsVariable}
                        />
                      );
                    })}
                  </div>
                  <div
                    className={'right-side-description'}
                    style={{ minHeight: '80px', flex: '1', padding: '4px' }}>
                    <h3
                      style={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '16px',
                      }}>
                      Install your Cloudberry Tag
                    </h3>
                    <p
                      style={{
                        fontStyle: 'normal',
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: '400',
                      }}>
                      You can use a website builder or content management system
                      (CMS) design mode, or install your tag manually. To ensure
                      you get the banner everywhere, make sure your tag is
                      installed on every page of your site.
                      <br />
                      Please add the following code snippet to the &lt;HEAD&gt;
                      tag on every page.
                    </p>
                    <div
                      className={'code-block'}
                      style={{
                        padding: '16px',
                        fontSize: '16px',
                        lineHeight: '25px',
                        backgroundColor: '#F4F7F9',
                        borderRadius: '8px',
                      }}>
                      &lt;script
                      src="https://integrations.mycloudberry.com/clinic/js/latest/banner.min.js"&gt;&lt;/script&gt;
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ margin: '56px 50px 80px', position: 'relative' }}>
                <img src={integrationDisabled} alt='integration-disabled' />
                <p
                  style={{
                    position: 'absolute',
                    top: '80px',
                    left: '50%',
                    color: 'white',
                    transform: 'translateX(-50%)',
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: '400',
                    textDecoration: 'underline',
                  }}>
                  Patient Portal? Login Here
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationConfigView;
