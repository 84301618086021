import React, { useContext, useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import { TaskContext } from 'app/context/TaskContext';
import { getTaskPayloadForLabelUpdateRequest } from 'app/utils/taskHelper';
import { ceil } from 'lodash';

const UPDATE_TASK = queryLoader('app/graphql/mutations/Task/TaskUpdate.gql');

function useGroupLabelBoard({
  groupLabelResponse,
  fetchNextPageRecords,
  updateViewAfterChangingLabel,
}) {
  const [kBoard, setKBoard] = useState(null);
  const [kColumns, setKColumns] = useState(null);
  let prevScrollHeight = 0;

  const { labelGroup } = useContext(TaskContext);
  const [updateTaskMutation] = useMutation(UPDATE_TASK);

  const updateKanbanBoard = () => {
    let newBoard;
    if (labelGroup !== null) {
      let columns = [];
      for (let i = 0; i < labelGroup.sub_labels.length; i++) {
        let labelDetails =
          groupLabelResponse[labelGroup.sub_labels[i].name]?.data?.records;
        columns.push({
          id: labelGroup.sub_labels[i].id,
          title: (
            <div className='unified-column-header'>
              {`${labelGroup.sub_labels[i].name} (${labelDetails?.total || 0})`}
            </div>
          ),
          cards: labelDetails?.results || [],
        });
      }
      newBoard = {
        columns,
      };
    }
    setKBoard(newBoard);
  };

  const getLabelIdsFromLabelArray = (labels, columnId) => {
    return labels
      .filter((l) => l.label.id !== columnId)
      .map((l) => parseInt(l.label.id));
  };
  const moveCardToAnotherColumn = async (fromColumnId, toColumnId, card) => {
    let payload = getTaskPayloadForLabelUpdateRequest({
      task: card,
      labels: [
        ...getLabelIdsFromLabelArray(card.entity_labels, fromColumnId),
        parseInt(toColumnId),
      ],
    });
    let response = await changeLabel(payload);
    updateViewAfterChangingLabel(
      response?.data?.updateTask?.entity,
      fromColumnId,
      toColumnId,
    );
  };

  const changeLabel = async (payload) => {
    let response = await updateTaskMutation({
      variables: payload,
    });
    return response;
  };

  const fetchNextRecords = (e) => {
    if (
      Math.abs(
        Math.round(e.scrollTop) +
          Math.round(e.clientHeight) -
          Math.round(e.scrollHeight),
      ) <= 1 &&
      prevScrollHeight !== e.scrollHeight
    ) {
      prevScrollHeight = e.scrollHeight;
      let labelId = e.getAttribute('data-rbd-draggable-id').split('-')[2];
      if (labelId) {
        fetchNextPageRecords(labelId);
      }
    }
  };

  useEffect(() => {
    if (groupLabelResponse !== undefined && groupLabelResponse !== null) {
      updateKanbanBoard();
    }
  }, [groupLabelResponse, labelGroup?.id]);

  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName('react-kanban-column');
      if (elements) {
        setKColumns(elements);
      }
    }, 1000);
  }, [labelGroup]);

  useEffect(() => {
    if (kColumns) {
      for (let i = 0; i < kColumns.length; i++) {
        kColumns[i].addEventListener('scroll', () =>
          fetchNextRecords(kColumns[i]),
        );
      }
    }
  }, [kColumns]);

  return {
    kBoard,
    moveCardToAnotherColumn,
  };
}

export default useGroupLabelBoard;
