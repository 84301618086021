import ReactGA from 'react-ga';
import { pick } from 'app/utils/osLodash';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_APP_ID, {
  titleCase: false,
  debug: false,
});
const EVENT_ACTIONS_MAPPER = {
  course_purchased: 'User purchased the course',
};
const REGISTER_ATTRIBUTES = ['email', 'id', 'name', 'nice_id'];

class GoogleAnalyticsTracker {
  register(user) {
    ReactGA.set(pick(user, REGISTER_ATTRIBUTES));
  }

  track(category, data = {}) {
    if (EVENT_ACTIONS_MAPPER[category])
      ReactGA.event({
        category,
        action: EVENT_ACTIONS_MAPPER[category],
        label: this.getLabel(category, data),
      });
  }

  getLabel(category, data = {}) {
    switch (category) {
      case 'course_purchased':
        return `${data.name} purchased from ${data.source}`;
      default:
        return null;
    }
  }
}

export default new GoogleAnalyticsTracker();
