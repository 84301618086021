import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import EntityListing from 'app/components/EntityListing';

import { removeClassFromHTML } from 'app/utils/domHelper';
import { perPageValueForDevice } from 'app/utils/requestHelper';

class SharedCareSpacesView extends PureComponent {
  componentDidMount() {
    removeClassFromHTML('detail-page');
  }

  getQueryParams(type) {
    const { device } = this.props;
    switch (type) {
      case 'myCareSpaces':
        return {
          type: 'MyCareSpaces',
          queryType: 'SPACES_INDEX',
          perPage: perPageValueForDevice(6, device),
          text: 'My Treatment',
          size: 'small',
          extraClass: 'mt-4',
        };
      case 'myPatientCareSpaces':
        return {
          type: 'MyPatientsCareSpaces',
          queryType: 'SPACES_INDEX',
          perPage: perPageValueForDevice(6, device),
          text: 'My Dependents',
          size: 'small',
        };
      default:
        return {};
    }
  }

  render() {
    return (
      <section className='collection-page'>
        <div className='container'>
          <EntityListing {...this.getQueryParams('myCareSpaces')} />
          <EntityListing {...this.getQueryParams('myPatientCareSpaces')} />
        </div>
      </section>
    );
  }
}

SharedCareSpacesView = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {},
)(SharedCareSpacesView);

export default SharedCareSpacesView;
