import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractFeed from './AbstractFeed';
import Avatar from 'app/components/Shared/Avatar';
import FeedDetails from './FeedDetails';
import OsLink from 'app/components/OsLink';
import ParseText from 'app/components/ParseText';
import WriteComment from 'app/components/Comments/WriteComment';
import Comment from 'app/components/Comments/Comment';

import {
  avatarAttributes,
  entityCommentUrl,
  entityUrl,
  getRemainingEditTime,
  sortFilesAndGetAttachment,
} from 'app/utils/entitiesHelper';
import { map, sortBy } from 'app/utils/osLodash';

import StoreUpdater from 'app/services/StoreUpdater';

const WORDS_TRUNCATE_COUNT = 30;

class CommentFeed extends AbstractFeed {
  constructor(props) {
    super(props);
    this.state = {
      replyingToComment: this.getObject(),
      useRecordsPager: false,
      editComment: false,
      editLinkValid: false,
      threadOpen: true,
      defaultReply: true,
    };
  }

  componentDidMount() {
    if (this.isCurrentUserComment()) {
      let remainingEditTime = getRemainingEditTime(
        this.getObject().created_at,
        this.props.systemConfig,
      );
      this.setState({ editLinkValid: remainingEditTime > 0 });
      this.editLinkValidTimeout = setTimeout(
        this.editCommentInvalid,
        remainingEditTime,
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.editLinkValidTimeout);
  }

  editCommentInvalid = () => {
    this.setState({ editLinkValid: false });
  };

  isEntityLocked() {
    return false;
  }

  lockedEntityReadMoreLink() {
    return entityUrl(this.getEntity()) + '#insights';
  }

  getModifiedEntity(entity) {
    let object = this.getObject();
    return { ...entity, modifiedUrl: entityCommentUrl(entity, object.id) };
  }

  parseTextProps() {
    let props = {
      obj: this.getObject(),
      quoteText: false,
      className: 'activity-listing-content',
      linkClassName: 'a-link',
    };
    if (this.isEntityLocked())
      props = {
        ...props,
        readMoreRequired: true,
        readMoreLinkUrl: this.lockedEntityReadMoreLink(),
        truncateWordsUpto: WORDS_TRUNCATE_COUNT,
      };
    return props;
  }

  replies() {
    return sortBy(this.props.feed.additional_entities || [], 'created_at');
  }

  onAddingNewReply = (reply) => {
    StoreUpdater.addCommentInFeed(reply, {
      feedId: this.props.feed.id,
      type: this.props.listType,
      textQuery: this.props.match.params.filterBy,
    });
  };

  setReplyingToComment = (reply) => {
    this.setState({ replyingToComment: reply });
  };

  renderReplyLink() {
    if (!this.isEntityLocked()) {
      let object = this.getObject(),
        entity = this.getEntity();

      return (
        <div className='your-reply-area'>
          Click{' '}
          <OsLink
            to={entityCommentUrl(entity, object.id, true)}
            className='hover-underline reply-link link-primary'
            text='here'
          />{' '}
          to reply.
        </div>
      );
    }
  }

  renderCards() {
    return (
      this.getObject().links.length > 0 &&
      map(this.getObject().links, this.renderCard)
    );
  }

  renderComment() {
    return (
      <>
        <ul className='list-unstyled mb-0 activity-comment-listing'>
          <li className='activity-comment-listing-item'>
            <ParseText {...this.parseTextProps()} />
            {this.renderReplyLink()}
          </li>
        </ul>
        <div className={this.cardsContainerClasses()}>{this.renderCards()}</div>
      </>
    );
  }

  renderAvatarDetails() {
    let user = this.props.currentUser.graph,
      avatarSize = this.props.device.mobileDevice ? 32 : 40;

    return (
      <Link className='comments-primary-avatar' to={entityUrl(user)}>
        <Avatar
          className={`align-top avatar avatar-${avatarSize}`}
          {...avatarAttributes(user)}
        />
      </Link>
    );
  }

  showMarkAsRead() {
    return (
      this.props.isInboxFeed &&
      [
        'MyUnreadPatientComments',
        'TeamPatientUnreadComments',
        'MyUnreadPartnerComments',
        'TeamPartnerUnreadComments',
      ].includes(this.props.listType)
    );
  }

  getInboxType() {
    if (this.props.isInboxFeed) {
      if (
        ['MyUnreadPatientComments', 'MyUnreadPartnerComments'].includes(
          this.props.listType,
        )
      ) {
        return 'my_inbox';
      }
      return 'team_inbox';
    }
  }

  renderFeedTypePreview() {
    let comment = this.getObject(),
      commentable = this.props.entity || this.getEntity(),
      commentableType = commentable.__typename;

    return (
      <Comment
        author={comment.author}
        listType={this.props.listType}
        comment={comment}
        showMarkAsRead={this.showMarkAsRead()}
        inboxType={this.getInboxType()}
        commentableId={commentable.nice_id}
        commentableType={commentableType}
        entity={commentable}
        openParentReply={this.setReplyingToComment}
        key={comment.id}
        feedType={this.props.feedType}
        source='feeds-view'
        activityId={this.props.feed.id}
        markReadEntity={this.props.feed}
      />
    );
  }

  toggleEdit = () => {
    this.setState({ editComment: !this.state.editComment });
  };

  renderCommentContainer() {
    if (this.state.editComment) {
      let commentable = this.props.entity || this.getEntity(),
        comment = this.getObject();

      return (
        <div className='comment-reply-container br-none'>
          <WriteComment
            {...comment}
            entity={commentable}
            commentableId={commentable.id}
            commentableType={commentable.__typename}
            closeEdit={this.toggleEdit}
            closeReply={this.toggleEdit}
            editComment={true}
            files={sortFilesAndGetAttachment(comment.files)}
            activityId={this.props.feed.id}
          />
        </div>
      );
    } else {
      if (!this.getObject().parent_id) {
        return (
          <div className='detail-comments px-0 col-12'>
            {this.renderFeedTypePreview()}
          </div>
        );
      } else {
        return this.renderComment();
      }
    }
  }

  isCurrentUserComment() {
    return +this.props.currentUser.graph.id === +this.getObject().author.id;
  }

  editObject() {
    return this.isCurrentUserComment() && this.state.editLinkValid;
  }

  render() {
    let isCurrentUserComment = this.isCurrentUserComment();
    return (
      <>
        <FeedDetails
          {...this.feedDetailsProps()}
          footerAvailable={true}
          deleteObject={isCurrentUserComment}
          editObject={this.editObject()}
          reportObject={!isCurrentUserComment}
          toggleEdit={this.toggleEdit}>
          {this.renderCommentContainer()}
        </FeedDetails>
        {this.props.children}
      </>
    );
  }
}

CommentFeed = withRouter(CommentFeed);
CommentFeed = connect(({ currentUser, device, systemConfig }) => ({
  currentUser,
  device,
  systemConfig,
}))(CommentFeed);
export default CommentFeed;
