import React, { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';

import { getUsersWithIds, getUsersWithoutIds } from 'app/utils/entitiesHelper';
import { flashError } from 'app/actions/flashMessage';
import { actionByGuestUser } from 'app/actions/guestUser';
import { openInfoModal } from 'app/actions/infoModal';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { isGroupSpace } from 'app/utils/spaceHelper';

const INVITE_QUERY = queryLoader('app/graphql/JoinSpaceRequest.gql');

class JoinSpace extends Component {
  state = {
    requestInProgress: false,
  };

  isCreatingOrEditingSpace() {
    return this.props.source === 'createOrEditSpace';
  }

  setInvitingMembersBeforeSaving() {
    let invitedMembers = this.props.requestingUsers;
    if (isGroupSpace(this.props.board)) {
      invitedMembers = this.props.requestingUsers.map((member) => {
        member.role = 'Editor';
        return member;
      });
    }
    this.props.setInvitedMembers &&
      this.props.setInvitedMembers(invitedMembers);
    this.closeModalIfRequired();
  }

  closeModalIfRequired() {
    this.props.closeModal && this.props.closeModal();
  }

  getSelectedMembersIds() {
    let requestingUsersWithId = getUsersWithIds(this.props.requestingUsers);
    return requestingUsersWithId.map((member) => {
      let userWithId = `${member.role || 'Member'}:${member.id}`;
      if (isGroupSpace(this.props.board)) {
        userWithId = `Editor:${member.id}`;
      }
      return userWithId;
    });
  }

  getSelectedMembersEmailIds() {
    let requestingUsersWithoutId = getUsersWithoutIds(
      this.props.requestingUsers,
    );
    return requestingUsersWithoutId.map((member) => {
      let userWithoutId = `${member.role || 'Member'}:${member.full_name}`;
      if (isGroupSpace(this.props.board)) {
        userWithoutId = `Editor:${member.full_name}`;
      }
      return userWithoutId;
    });
  }

  actionsAfterSuccess() {
    this.closeModalIfRequired();
    this.props.onSuccess();
  }

  showWarningIfRequired() {
    this.props.warningForNoUser && this.props.warningForNoUser();
  }

  isRequestSentFromModal() {
    return this.props.viewType === 'modal';
  }

  isRequestSentFromCard() {
    return this.props.source === 'card';
  }

  sendRequest = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();

    if (
      this.props.requestingUsers.filter((requestingUser) => requestingUser)
        .length
    ) {
      if (this.isCreatingOrEditingSpace()) {
        this.setInvitingMembersBeforeSaving();
      } else {
        this.props.onAction();
        this.isRequestSentFromCard() && e && e.stopPropagation();
        this.setState({ requestInProgress: true });
        this.props
          .inviteQuery({
            variables: {
              spaceId: this.props.board.nice_id,
              userIds: this.getSelectedMembersIds(),
              emailIds: this.getSelectedMembersEmailIds(),
            },
          })
          .then(({ data }) => {
            if (data.joinSpaceRequest.success) {
              this.props.onSuccess();
              this.actionsAfterSuccess();
            } else {
              this.props.flashError(data.joinSpaceRequest.error, false);
            }
            this.setState({ requestInProgress: false });
          });
      }
    } else {
      this.showWarningIfRequired();
    }
  };

  sendRequestIfAuthenticated = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.redirectToDefaultIfUnauthenticated(
      this.sendRequest.bind(this, e),
    );
  };

  renderJoinButton() {
    let newBoard = this.props.source === 'createOrEditSpace';
    if (this.props.viewType === 'primaryAction') {
      let disabledClass = this.state.requestInProgress ? ' disabled-only ' : '',
        extraClass = ` ${this.props.extraClass} ${disabledClass} `;

      return (
        <OsBtn
          name={this.props.name}
          extraClass={extraClass}
          icon={this.props.icon}
          text={this.props.text}
          associatedEntity={this.props.board}
          onClick={this.sendRequestIfAuthenticated}
          disabled={this.state.requestInProgress}
        />
      );
    } else if (this.props.viewType === 'modal') {
      let text = this.state.requestInProgress
        ? 'Sending'
        : newBoard
        ? 'Add'
        : 'Send Invite';
      return (
        <OsBtn
          name={this.props.name}
          text={text}
          loaderRequired={this.state.requestInProgress}
          extraClass='web-view-btn remind-me-btn'
          onClick={this.sendRequest}
          disabled={this.state.requestInProgress}
        />
      );
    }
  }

  render() {
    return this.renderJoinButton();
  }
}

JoinSpace = graphql(INVITE_QUERY, { name: 'inviteQuery' })(JoinSpace);

JoinSpace.defaultProps = {
  name: 'BtnPrimary',
  extraClass: '',
  text: 'request to join',
  icon: '',
  onAction: () => {},
  onSuccess: () => {},
};

JoinSpace = connect(
  ({ currentUser, device, guestUser }) => ({ currentUser, device, guestUser }),
  { actionByGuestUser, openInfoModal, flashError, updateCurrentUserGraph },
)(JoinSpace);

JoinSpace = withApollo(JoinSpace);
JoinSpace = withAuthenticate(JoinSpace);
JoinSpace = withRouter(JoinSpace);
JoinSpace = withSpaceRestriction(JoinSpace);

export default JoinSpace;
