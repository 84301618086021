import React, { Component } from 'react';

import UserRowInWidget from 'app/components/Shared/UserRowInWidget';
import VisitingCard from 'app/components/VisitingCard';
import OsBtn from 'app/components/OsBtn';

class ClinicTeamWithUsers extends Component {
  renderUser = (user) => {
    return (
      <UserRowInWidget key={user.id} user={user} designationRequired={true} />
    );
  };

  renderList() {
    return this.props.results.map(this.renderUser);
  }

  render() {
    const { record, title, inviteModalButtonRequired } = this.props;
    return (
      <div className='sidebar-container care-team-widget with-listing'>
        {record && (
          <div className='your-care-team-header'>
            <VisitingCard obj={record} />
          </div>
        )}
        {!!this.props.results.length && (
          <div className='doctor-list'>
            <div className='widget-title pt-4' key='user-connect-heading'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                {title || 'Recently online care team'}
                {inviteModalButtonRequired && (
                  <OsBtn
                    name='BtnIcon'
                    extraClass='no-text px-3 web-view-btn'
                    icon='add'
                    label='add patient btn'
                    onClick={this.props.inviteModal}
                  />
                )}
              </div>
            </div>
            <ul
              className='list-unstyled dr-connect-list mb-0'
              key='user-connect-list'>
              {this.renderList()}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default ClinicTeamWithUsers;
