import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { Command } from 'cmdk';
import Loader from 'react-loaders';
import React, { useCallback, useEffect, useState } from 'react';
import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import { GROUPS_NAVIGATION } from 'app/components/DnD/CmdMenu/constants';
import { debounce } from 'app/utils/osLodash';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

const GET_GROUP_DETAILS = queryLoader(
  'app/graphql/queries/Spaces/GroupSidebarCollection.gql',
);

const GroupCmdMenu = (props) => {
  const { handleNavigation, inputValue } = props;

  const [textQuery, setTextQuery] = useState('');
  const workspace = getWorkspaceData();

  const { data, loading } = useQuery(GET_GROUP_DETAILS, {
    variables: {
      page: 0,
      perPage: 10,
      type: 'SubSpaces',
      text_query: textQuery,
      id_query: workspace.nice_id,
    },
  });
  const handleGroupNavigation = (niceId) => {
    handleNavigation(GROUPS_NAVIGATION, { niceId });
  };

  const debounceTextQuery = useCallback(
    debounce((value) => setTextQuery(value), 1000),
    [],
  );

  useEffect(() => {
    debounceTextQuery(inputValue);
  }, [debounceTextQuery, inputValue]);

  return (
    <Command.List>
      {loading && (
        <Command.Loading>
          <Loader type='ball-triangle-path' active />
        </Command.Loading>
      )}
      {data?.records?.results.map((group) => {
        return (
          <Command.Item
            className={'command-item'}
            key={group.id}
            value={group.id + group.name}
            onSelect={() => handleGroupNavigation(group.nice_id)}>
            <DisplayOption iconRequired={false} label={group.name} />
          </Command.Item>
        );
      })}
      <Command.Empty>
        No Group was found with that inputValue. Please try again.
      </Command.Empty>
    </Command.List>
  );
};

export default GroupCmdMenu;
