import React, { Component } from 'react';
import { displayEntityName } from 'app/utils/entitiesHelper';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Accordion } from 'react-bootstrap';

import OsCards from 'app/components/OsCards';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { pluralizeString } from 'app/utils/stringHelper';

class AttachmentsInDiscussion extends Component {
  onSeeAllLink = () => {
    let { recordId, recordType } = this.props;
    recordType = displayEntityName({ __typename: recordType });
    let seeAllLink = `/records/${pluralizeString(
      recordType,
    )}/${recordId}/discussions/attachments`;
    this.props.navigate(seeAllLink);
  };

  renderSeeAllLink() {
    if (this.props.afterHasMore)
      return (
        <OsBtn
          name='BtnIcon'
          icon='expand-2'
          extraClass='no-text tag-view-more pointer fs-12'
          onClick={this.onSeeAllLink}
        />
      );
  }

  renderCareDocumentList() {
    let { results } = this.props;
    return results.map(this.renderAttachment);
  }

  renderAttachment = (attachment) => {
    if (this.props.source === 'widget') {
      return (
        <div className='col-12 mb-2' key={attachment.id}>
          <OsCards size='extra-small' obj={attachment} />
        </div>
      );
    } else {
      return (
        <OsGrid identifier='CardColumn:Card' key={attachment.id}>
          <OsCards size='extra-small' obj={attachment} />
        </OsGrid>
      );
    }
  };

  renderWidgetHeader() {
    if (this.props.source === 'widget') {
      return (
        <Accordion.Header
          className='rt-ac-header related case-doc-title'
          key='related-documents'>
          <h5>Shared Files</h5>
          {this.renderSeeAllLink()}
        </Accordion.Header>
      );
    }
  }

  renderAllLink() {
    if (this.props.afterHasMore)
      return (
        <span>
          <OsBtn
            name='BtnIcon'
            icon='expand-2'
            extraClass='no-text tag-view-more pointer fs-12'
            onClick={this.onSeeAllLink}
          />
        </span>
      );
  }

  render() {
    return (
      <Accordion defaultActiveKey='0' className='right-ac'>
        <Accordion.Item eventKey='0'>
          {this.renderWidgetHeader()}
          {this.props.results.length > 0 && (
            <Accordion.Body>
              <div className='row document-list' key='documents'>
                {this.renderCareDocumentList()}
              </div>
            </Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>
    );
  }
}

AttachmentsInDiscussion.defaultProps = {
  source: 'widget',
};
AttachmentsInDiscussion = withRouter(AttachmentsInDiscussion);

export default AttachmentsInDiscussion;
