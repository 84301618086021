import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='exploding_svg'
    viewBox='0 0 24 24'>
    <path
      d='M21.357 4.88a1.14 1.14 0 0 0 .149-.596c-.03-.655-.663-1.174-1.493-1.309 0-.048.01-.086.01-.134-.04-.799-.95-1.405-2.047-1.347-.603.028-1.127.25-1.473.577-.425-.49-1.117-.808-1.918-.808-.534 0-1.018.144-1.414.375a.658.658 0 0 1-.652 0 2.708 2.708 0 0 0-1.414-.375c-.652 0-1.236.221-1.66.558-.307-.52-1.029-.856-1.85-.818-.84.038-1.542.462-1.799 1.02-.059 0-.118-.02-.188-.02-1.304 0-2.362.847-2.362 1.886 0 .202.05.385.118.568-.8.317-1.364.99-1.364 1.78 0 1.077 1.058 1.962 2.363 1.962.435 0 .83-.106 1.176-.27a3 3 0 0 0 .613.116c.495.462 1.305.76 2.215.76.356 0 .682-.057.998-.144V13h6.11V8.719c.06-.01.129-.02.188-.039a.783.783 0 0 1 .366 0c.306.087.642.135.998.135.92 0 1.73-.308 2.225-.789a2.6 2.6 0 0 0 .385.029c1.305 0 2.363-.847 2.363-1.886 0-.5-.247-.952-.643-1.289z'
      fill='#F7F7F8'
    />
    <path
      d='m19.22 11.12-1.41-2.144c-.03-.051-.1-.062-.15-.01l-2.259 2.329-3.13-3.225c-.09-.093-.464-.093-.554 0l-2.875 3.214-2.45-2.514a.047.047 0 0 0-.07 0l-1.539 2.35-2.27-1.587c-.01-.01-.02 0-.03.01-.65 2.092-.7 4.462.19 6.924 1.03 2.844 3.29 5.11 6.1 6.018C15.67 24.7 22 19.465 22 12.695c0-1.102-.17-2.163-.48-3.152 0-.01-.02-.02-.03-.01l-2.27 1.587z'
      fill='#FFC933'
    />
    <path
      d='M19.2 16c.994 0 1.8-.448 1.8-1s-.806-1-1.8-1-1.8.448-1.8 1 .806 1 1.8 1zM4.8 16c.994 0 1.8-.448 1.8-1s-.806-1-1.8-1-1.8.448-1.8 1 .806 1 1.8 1z'
      fill='#FF9270'
    />
    <path
      d='M12 17c.91 0 1.68.77 1.92 1.82.05-.26.08-.53.08-.82 0-1.66-.9-3-2-3s-2 1.34-2 3c0 .29.04.56.08.82.24-1.05 1-1.82 1.92-1.82z'
      fill='#121214'
    />
    <path
      d='M12 21c.91 0 1.68-.93 1.92-2.18-.24-1.05-1-1.82-1.92-1.82-.92 0-1.68.77-1.92 1.82.24 1.26 1 2.18 1.92 2.18z'
      fill='#ED5A8B'
    />
    <path
      d='M16 12a.61.61 0 0 0-.32.107.66.66 0 0 1-.347 1.227.86.86 0 0 1-.266-.054c-.04.227-.067.467-.067.72 0 1.107.453 2 1 2 .547 0 1-.893 1-2s-.453-2-1-2zM8 12a.61.61 0 0 0-.32.107.66.66 0 0 1-.347 1.227.861.861 0 0 1-.266-.054c-.04.227-.067.467-.067.72 0 1.107.453 2 1 2 .547 0 1-.893 1-2s-.453-2-1-2z'
      fill='#1C1C1F'
    />
    <path
      d='M8 12.667c0-.24-.133-.44-.32-.56-.28.2-.507.626-.613 1.173.08.027.173.053.266.053A.66.66 0 0 0 8 12.667zM16 12.667c0-.24-.133-.44-.32-.56-.28.186-.507.613-.613 1.16.08.04.173.066.266.066a.66.66 0 0 0 .667-.666z'
      fill='#F7F7F8'
    />
  </svg>
);

export default SVG;
