import { CLOSE_REPORT_MODAL, OPEN_REPORT_MODAL } from './types';

export function openReportModal(object) {
  return {
    type: OPEN_REPORT_MODAL,
    object,
  };
}

export function closeReportModal() {
  return {
    type: CLOSE_REPORT_MODAL,
  };
}
