import { DEVICE_VIEW, SET_DEVICE_TOKEN } from './types';
import { isMobileDevice, isLandscape } from 'app/utils/deviceHelper';

export const bindMobileFilter = () => {
  return (dispatch) => {
    window.addEventListener('resize', function () {
      dispatch({ type: DEVICE_VIEW });
    });
    window.addEventListener('orientationchange', function () {
      var activeElement = document.activeElement;

      if (isMobileDevice() && isLandscape()) {
        if (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA'
        ) {
          activeElement.blur();
        }
      }
      dispatch({ type: DEVICE_VIEW });
    });
    dispatch({ type: DEVICE_VIEW });
  };
};

export const setDeviceToken = (token) => {
  return {
    type: SET_DEVICE_TOKEN,
    token,
  };
};
