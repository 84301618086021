import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import { flashSuccess } from 'app/actions/flashMessage';
const WORKSPACE_INVITATION_MUTATION = queryLoader(
  'app/graphql/mutations/Workspace/InviteWorkspaceMember.gql',
);

const useWorkspaceInvite = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const workspaceInviteModal = useSelector(
    (state) => state.workspaceInviteModal,
  );

  // Mutation hooks
  const [workspaceInviteMutation] = useMutation(WORKSPACE_INVITATION_MUTATION);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    /* API HERE*/
    await workspaceInviteMutation({
      variables: values,
    });

    flashSuccess('Invitation sent successfully', false)(dispatch);

    setIsSubmitting(false);
    props.hideModal();
  };

  return {
    onSubmit,
    isSubmitting,
    setIsSubmitting,
    workspaceInviteModal,
  };
};

export default useWorkspaceInvite;
