import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import Icon from 'app/components/Svg';

import { translate } from 'app/actions/flashMessage';

class SystemUpgrade extends Component {
  render() {
    let systemConfigs = this.props.systemConfig;
    if (systemConfigs.requestServed) {
      return <Navigate to='/' />;
    }

    return (
      <div className='system-upgrade-container'>
        <Icon
          name='warningBlue'
          className='v-align-top'
          width={'312'}
          height={'250'}
        />
        <h1 className='head-1'>{translate('SYSTEM_UPGRADE_HEADER_TEXT')}</h1>
        <div>
          {translate('SYSTEM_UPGRADE_SUB_HEADER_TEXT_1')}
          <br />
          {translate('SYSTEM_UPGRADE_SUB_HEADER_TEXT_2')}
        </div>
      </div>
    );
  }
}

SystemUpgrade = connect(
  ({ systemConfig }) => ({ systemConfig }),
  {},
)(SystemUpgrade);
export default SystemUpgrade;
