import React from 'react';
import { connect } from 'react-redux';

import CaseAbstractSlider from './CaseAbstractSlider';
import CaseSortButtons from 'app/components/CaseView/CaseSortButtons';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import NoAppointmentDataView from 'app/components/Shared/NoAppointmentDataView';

import { isEmpty, isEqual } from 'app/utils/osLodash';
import {
  getAppointmentDropdownTitle,
  getAppointmentFiles,
  getFilesBasedOnRecordType,
  getAppointmentFilesWithImagesOrVideos,
} from './helpers';

class ApppointmentView extends CaseAbstractSlider {
  constructor(props) {
    super(props);
    this.state = {
      appointment: props.appointment,
      selectedFile: props.selectedFile,
      sliderRef: { mainSlider: null, subSlider: null },
      sliderKey: true,
    };
    this.slider = 'case-sbs-slider';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.appointment.id !== this.props.appointment.id) {
      setTimeout(this.reassignCenterModeClasses, 200);
    }

    if (
      (prevProps.slideIndex !== this.props.slideIndex &&
        this.props.slideIndex >= 0) ||
      !isEqual(prevProps.appointment, this.props.appointment)
    ) {
      let { mainSlider } = this.state.sliderRef,
        { slideIndex } = this.props,
        selectedFile = getAppointmentFiles(this.props.appointment)[slideIndex];

      this.setState({ selectedFile });
      mainSlider && mainSlider.innerSlider && mainSlider.slickGoTo(slideIndex);
      this.resizeSubSlider();
    }

    if (!isEqual(this.props.selectedFile, prevProps.selectedFile))
      this.setState({ selectedFile: this.props.selectedFile });
  }

  currentFile() {
    return this.state.selectedFile;
  }

  setSelectedApppointment = (appointment) => {
    let appointmentFiles = getAppointmentFiles(appointment),
      selectedFile =
        appointmentFiles.find(
          (file) => file.name === this.state.selectedFile.name,
        ) || appointmentFiles[0];
    this.setState({ appointment }, () => {
      this.setSelectedFile(selectedFile);
    });
  };

  renderAppointmentDates() {
    return this.getAppointments().map((appointment) => {
      let appointmentDate = getAppointmentDropdownTitle(
        appointment,
        this.getAppointments(),
      );
      return (
        <div
          onClick={this.setSelectedApppointment.bind(this, appointment)}
          key={`sbs-appointment-date-dropdown-${appointmentDate}`}>
          {appointmentDate}
        </div>
      );
    });
  }

  getAppointments() {
    return getAppointmentFilesWithImagesOrVideos(this.props.kase.appointments);
  }

  setSelectedFile = (selectedFile) => {
    let slideIndex = this.getSliderFiles().findIndex(
      (file) =>
        selectedFile.id === file.id &&
        selectedFile.__typename === file.__typename,
    );
    this.setState({ selectedFile });
    this.state.sliderRef.mainSlider &&
      this.state.sliderRef.mainSlider.innerSlider &&
      this.state.sliderRef.mainSlider.slickGoTo(slideIndex);
    this.props.notifyParentContainer(selectedFile, this.props.appointment);
  };

  getSliderFiles() {
    return getAppointmentFiles(this.props.appointment);
  }

  renderGroupFiles(groupFiles) {
    return groupFiles.map((file) => {
      let { selectedFile } = this.state,
        selected =
          selectedFile.id === file.id &&
          selectedFile.__typename === file.__typename;
      return (
        <div
          onClick={this.setSelectedFile.bind(this, file)}
          key={`sbs-view-file-dropdown-${file.name}`}
          selected={selected}>
          {file.name}
        </div>
      );
    });
  }

  getSelectedFileName() {
    return this.state.selectedFile.name;
  }

  renderGroupHeader(group) {
    return (
      <li
        parentClassName='dropdown-group-header'
        onClick={() => {}}
        key={group}>
        {group}
      </li>
    );
  }

  renderFileNamesInGroups() {
    let options = [],
      allFiles = this.getSliderFiles(),
      groupFiles = getFilesBasedOnRecordType(allFiles, this.props.selectedMode);

    if (groupFiles.length) {
      options = [
        ...options,
        this.renderGroupHeader(this.props.selectedMode),
        this.renderGroupFiles(groupFiles),
      ];
    }
    return options;
  }

  renderFileNamesDropdown() {
    return (
      <CustomDropdown
        chevronIcon={
          this.props.device.mobileDevice ? 'chevron-caret' : 'chevron'
        }
        name='appointments_dates'
        dropdownInfo={{ title: this.getSelectedFileName() }}
        className='cs-dropdown mw-88 cs-file-dropdown'>
        {this.renderFileNamesInGroups()}
      </CustomDropdown>
    );
  }

  onSlideChange = (index) => {
    let selectedFile = this.getSliderFiles()[index];
    selectedFile && this.setSelectedFile(selectedFile);
    this.focusSlider('mainSlider');
  };

  isAppointmentFilesEmpty() {
    return getAppointmentFiles(this.props.appointment).length === 0;
  }

  render() {
    if (isEmpty(this.props.appointment)) return <div>No appointments</div>;

    if (this.isAppointmentFilesEmpty()) {
      return <NoAppointmentDataView extraClass='single-col' />;
    }

    return (
      <>
        <div className='sbs-dropdown'>
          <CaseSortButtons
            kase={this.props.kase}
            selectedAppointment={this.props.appointment}
          />
          {this.renderFileNamesDropdown()}
        </div>
        <div className='sbs-img new-os-slider'>
          {this.renderSlider('mainSlider')}
        </div>
        <div className='case-thumbnail-slider'>
          {this.renderSlider('subSlider')}
        </div>
      </>
    );
  }
}

ApppointmentView = connect(
  ({ caseDetailInfo, device, spin }) => ({ caseDetailInfo, device, spin }),
  null,
)(ApppointmentView);
ApppointmentView.defaultProps = {
  appointment: {},
  kase: {},
  notifyParentOnFileChange: () => {},
  selectedFile: {},
};
export default ApppointmentView;
