import React from 'react';
import { useDraggable } from '@dnd-kit/core';

export function Draggable(props) {
  let { attributes, isDragging, listeners, setNodeRef, transform } =
      useDraggable({
        disabled: props.disabled,
        id: props.id,
      }),
    style = transform
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
      : undefined,
    className = props.className || '';

  className += isDragging ? ' drag-active ' : '';
  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...(props.customDragHandle ? {} : listeners)}
      {...attributes}
      role='div'>
      {props.customDragHandle
        ? React.cloneElement(props.children, { dragListenerProps: listeners })
        : props.children}
    </div>
  );
}
