import ActionCable from 'app/actioncable/actioncable';
import { isUndefined, map } from 'app/utils/osLodash';

import StoreUpdater from 'app/services/StoreUpdater';
import store from 'app/store';

class ConversationChannel {
  setup({ id, web }) {
    if (!isUndefined(ActionCable.conversationChannel))
      return ActionCable.conversationChannel;
    ActionCable.conversationChannel = ActionCable.consumer.subscriptions.create(
      { channel: 'ConversationChannel', id, web },
      {
        initialized() {},
        connected() {},
        disconnected() {},
        received(data) {
          this.eventChannelReceive(data);
        },
        seen(conversationId) {
          this.perform('seen', { conversation_id: conversationId });
        },
        viewConversationMessage(conversation_message_id) {
          this.perform('view_conversation_message', {
            conversation_message_id,
          });
        },

        //Additional Custom Event handlers
        eventChannelReceive(message) {
          const currentUser = store.getState().currentUser;
          if (message.action === 'add_emoji') {
            StoreUpdater.addEmojiInEntity({
              emojiName: message.entity.emoji_name,
              entityType:
                message.entity.type === 'AttachmentEntity'
                  ? 'MessageFile'
                  : message.entity.type,
              entityId: message.entity.entity_id,
              user: message.entity.user,
              reactionId: message.entity.reaction_id,
              reactedByCurrentUser:
                message.entity.user.id.toString() ===
                currentUser.graph.id.toString(),
            });
          } else if (message.action === 'remove_emoji') {
            StoreUpdater.removeEmojiFromEntity({
              emojiName: message.entity.emoji_name,
              entityType:
                message.entity.type === 'AttachmentEntity'
                  ? 'MessageFile'
                  : message.entity.type,
              entityId: message.entity.entity_id,
              user: message.entity.user,
              reactionId: message.entity.reaction_id,
            });
          } else if (message.type === 'update_message') {
            StoreUpdater.updateEntityThroughWrapper(
              {
                entity: {
                  ...message.entity,
                  __typename: 'ConversationMessage',
                },
                attributeName: 'viewed_by_user_ids',
              },
              { customAction: 'assign' },
            );
          } else {
            let relevantPush =
              message.author?.id?.toString() !==
              currentUser.graph.id.toString();
            if (relevantPush) {
              StoreUpdater.addMessageInConversationMessagesListing(
                { ...message, pinnable: null, pinned: false },
                id,
                { fullView: true },
              );
            }
          }
        },
      },
    );
    return ActionCable.conversationChannel;
  }

  delete() {
    if (ActionCable.conversationChannel) {
      ActionCable.consumer.subscriptions.remove(
        ActionCable.conversationChannel,
      );
      delete ActionCable.conversationChannel;
    }
  }
}

export default new ConversationChannel();
