import React, { Component } from 'react';
import CommentFeed from './CommentFeed';
import ConnectedActivityLogView from 'app/components/FeedView/ActivityLogView';

import { ConnectedAbstractFeed } from './AbstractFeed';
import TaskLogView from 'app/components/FeedView/TaskLogView';

const LOG_VIEW_SOURCE_TYPE = [
  'CareSpaceContactAddedActivity',
  'CareSpacePatientAddedActivity',
  'CareSpaceRequestSharedActivity',
  'CareSpaceSharedActivity',
  'CareSpaceUnlockedActivity',
  'LabelAddedActivity',
  'GenericActivity',
];
const GENERIC_ACTIVITY_LOG_VIEW_ACTION = ['task_assignee_updated'];

export default class FeedView extends Component {
  metaInfo() {
    return this.props.feed.meta_info
      ? JSON.parse(this.props.feed.meta_info)
      : {};
  }

  isConnectedActivityLogViewRequired() {
    let { source_type, action } = this.props.feed;
    if (
      source_type === 'GenericActivity' &&
      GENERIC_ACTIVITY_LOG_VIEW_ACTION.includes(action)
    ) {
      return false;
    }
    return LOG_VIEW_SOURCE_TYPE.includes(source_type);
  }

  render() {
    let { source_type } = this.props.feed,
      props = {
        ...this.props,
        metaInfo: this.metaInfo(),
      };

    if (this.isConnectedActivityLogViewRequired()) {
      return <ConnectedActivityLogView {...props} />;
    }

    switch (source_type) {
      case 'CaseCommentActivity':
      case 'SpaceCommentActivity':
        return <CommentFeed {...props} />;
      case 'TaskCompletedActivity':
      case 'TaskAddedActivity':
      case 'GenericActivity':
        return <TaskLogView {...props} />;
      default:
        return <ConnectedAbstractFeed {...props} />;
    }
  }
}
