import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { flashSuccess } from 'app/actions/flashMessage';
import { openInfoModal } from 'app/actions/infoModal';
import defaultUploadThumb from 'app/images/svg-thumbnail/folder-empty.png';
import useLocalFileUpload from 'app/hooks/useLocalFileUpload';
import { closeFolderModal } from 'app/actions/folderModal';
import { FOLDER_ADDED_SUCCESSFULLY } from 'app/components/FolderModal/constants';

const UPDATE_FOLDER_MUTATION = queryLoader('app/graphql/UpdateFolder.gql');

const useFolderModal = (props) => {
  const navigate = useNavigate();
  const { data: workspace } = useSelector((state) => state.workspace);
  const folderModal = useSelector((state) => state.folderModal);
  const currentUser = useSelector((state) => state.currentUser);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [folderAttachmentId, setFolderAttachmentId] = useState(null);
  const [selectedFolderImageUrl, setSelectedFolderImageUrl] =
    useState(defaultUploadThumb);
  const {
    uploadFile,
    assetDetails,
    isLoading: isUploading,
    percentageCompleted: uploadPercentage,
    resetPercentage,
  } = useLocalFileUpload();

  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  // Mutation hooks
  const [updateFolderMutation] = useMutation(UPDATE_FOLDER_MUTATION);

  // File upload status
  useEffect(() => {
    setIsSubmitDisabled(isUploading);
  }, [isUploading]);

  // Asset details after file upload
  useEffect(() => {
    if (assetDetails) {
      setFolderAttachmentId(assetDetails.id);
      setSelectedFolderImageUrl(assetDetails.preview_url);
    }
  }, [assetDetails]);

  const username = currentUser.graph.name;

  const closeModal = () => {
    setIsSubmitting(false);
    setIsSubmitDisabled(false);
    setFolderAttachmentId(null);
    setSelectedFolderImageUrl(defaultUploadThumb);
    setInitialValues({});
    resetPercentage();
    dispatch(closeFolderModal({ type: folderModal.type }));
  };

  // Handle image selected from form
  const onFolderBannerChange = (localFile) => {
    setIsSubmitDisabled(true);
    uploadFile(localFile);
  };

  const updateFolder = async (values) => {
    if (values.category === 'open') {
      values.inheritCollaboratorsFromOwner = true;
    } else {
      values.inheritCollaboratorsFromOwner = false;
    }
    if (folderAttachmentId) {
      values.attachment_id = folderAttachmentId;
    }
    values.name = values.folderName;
    delete values.folderName;
    if (!isSubmitting) {
      setIsSubmitting(true);
      const response = await updateFolderMutation({
        variables: {
          ...values,
          owner_id: workspace?.id,
          owner_type: workspace?.__typename,
        },
      });

      const data = response.data;
      let folderSpace = data.createFolder,
        errors = {};

      if (folderSpace.errors?.length > 0) {
        folderSpace.errors.forEach(
          (error) => (errors[error.field] = error.message),
        );
        dispatch(openInfoModal('general', 'error_message', {}));
        return errors;
      } else {
        const flashMessage = FOLDER_ADDED_SUCCESSFULLY;
        closeModal();
        flashSuccess(flashMessage, false)(dispatch);

        navigate(
          `/${workspace.identifier}/folders/${folderSpace.entity.nice_id}`,
        );
      }
    }
  };

  return {
    initialValues,
    folderAttachmentId,
    selectedFolderImageUrl,
    onFolderBannerChange,
    folderModal,
    username,
    isSubmitDisabled,
    uploadPercentage,
    isSubmitting,
    closeModal,
    updateFolder,
  };
};

export default useFolderModal;
