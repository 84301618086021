import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { sortFilesAndGetAttachment } from 'app/utils/entitiesHelper';
import {
  add,
  currentTimeWithUserTimeZone,
  isCurrentDay,
} from 'app/utils/timeHelper';
import {
  getKeyValueFromLocalStorage,
  removeKeyFromLocalStorage,
} from 'app/components/SwitchProfile/quickSwitcherHelper';
import scrollToElement from 'scroll-to-element';
import { getExplorePageHeaderOffset } from 'app/utils/domHelper';

const DELETE_POST = queryLoader(
  'app/graphql/mutations/SchedulePost/DeletePost.gql',
);
const UPDATE_POST = queryLoader(
  'app/graphql/mutations/Comments/AddScheduleMessage.gql',
);
function useSchedulePost({
  post,
  deleteScheduleMessage,
  updateScheduledMessage,
}) {
  const [deleteScheduledPost] = useMutation(DELETE_POST);
  const [updateSchedulePost] = useMutation(UPDATE_POST);
  const device = useSelector((state) => state.device);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [postContent, setPostContent] = useState(post.content);
  const [templateFiles, setTemplateFiles] = useState(
    sortFilesAndGetAttachment(post.files),
  );
  const [templateObjects, setTemplateObjects] = useState(post.links);
  let updatedPostContent = useRef(post.content);

  let date = dayjs(post.time).format('LLL');
  let isInternalNote = post?.visible_to_group_ids?.length === 0;

  const showEditor = () => setIsEdit(true);
  const hideEditor = () => setIsEdit(false);

  const isPostEditable = () => {
    return currentUser.graph.id === post.created_by.id;
  };

  const handleContent = (content) => {
    updatedPostContent.current = content;
  };

  const handleSubmit = () => {
    updatePost();
    setPostContent(updatedPostContent.current);
    setIsEdit(false);
  };

  const getLeftDays = () => {
    let days = dayjs(date).diff(dayjs(), 'day');
    if (days === 0) {
      if (dayjs().isSame(date, 'day')) {
        return 'Today';
      } else {
        return 'Tomorrow';
      }
    } else if (days === 1) {
      return 'Tomorrow';
    } else {
      return `In ${days} days`;
    }
  };

  const getMentionGroups = () => {
    if (isInternalNote) return [];
    return post.entity.mention_groups || [];
  };

  const getFormattedDate = (days, hour) => {
    const currentTime = currentTimeWithUserTimeZone();
    return add(currentTime, days, 'days')
      .hour(hour)
      .minute(0)
      .second(0)
      .format();
  };

  const convertSelectedTimeIntoRequiredDateFormat = (selectedDate) => {
    const dateMappings = {
      'Tomorrow at 8:00am': [1, 8],
      'Tomorrow at 6:00pm': [1, 18],
      '1 week at 8:00am': [7, 8],
    };

    const dateArgs = dateMappings[selectedDate];

    if (dateArgs) {
      return getFormattedDate(dateArgs[0], dateArgs[1]);
    } else {
      return dayjs(selectedDate).format();
    }
  };

  const rescheduleMessage = (time) => {
    const selectedDate = convertSelectedTimeIntoRequiredDateFormat(time);
    updatePost(selectedDate);
  };

  const updatePost = async (time = '') => {
    let fileArray = templateFiles.map((file) => {
      return file.id;
    });
    let templateArray = templateObjects.map((link) => {
      return `Board:${link.id}`;
    });

    try {
      const { data } = await updateSchedulePost({
        variables: {
          id: post.id,
          entityId: post.entity.nice_id,
          entityType: 'Space::Base',
          content: updatedPostContent.current,
          files: fileArray,
          links: templateArray,
          time: time || post.time,
          visibleToGroupIds: post.visible_to_group_ids,
        },
      });
      updateScheduledMessage(data.addScheduleMessage.entity);
    } catch (error) {
      console.error(error);
    }
  };

  const deletePost = async () => {
    try {
      const data = await deleteScheduledPost({
        variables: {
          id: post.id,
        },
      });
      deleteScheduleMessage(post.id);
    } catch (error) {
      console.error(error);
    }
  };

  const scrollToPost = (messageId) => {
    let elementId = 'schedule-feed-' + messageId;
    let postElement = document.getElementById(elementId);
    postElement &&
      scrollToElement(postElement, {
        ease: 'linear',
        offset: getExplorePageHeaderOffset(device.mobileDevice),
        duration: 500,
      });

    removeKeyFromLocalStorage('messageId');
  };

  useEffect(() => {
    let messageId = getKeyValueFromLocalStorage('messageId');
    if (messageId) {
      setTimeout(scrollToPost(messageId), 5000);
    }
  }, []);

  return {
    device,
    date,
    isInternalNote,
    isEdit,
    postContent,
    templateFiles,
    templateObjects,
    isPostEditable,
    showEditor,
    hideEditor,
    setPostContent,
    setTemplateFiles,
    setTemplateObjects,
    handleContent,
    handleSubmit,
    dispatch,
    getLeftDays,
    getMentionGroups,
    rescheduleMessage,
    updatePost,
    deletePost,
  };
}

export default useSchedulePost;
