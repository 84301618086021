import React from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import NotificationCardLayout from './NotificationCardLayout';
import OsLink from 'app/components/OsLink';
import OsBtn from 'app/components/OsBtn';
import ParseText from 'app/components/ParseText';
import PostMessageField from 'app/components/Shared/PostMessageField';

import { openPoliciesModal } from 'app/actions/policiesModal';

import { canSpaceHaveDiscussions } from 'app/utils/spaceHelper';
import { includes } from 'app/utils/osLodash';
import { entityIsDeleted } from 'app/utils/entitiesHelper';
import { replaceCurrentDomain } from 'app/utils/urlHelper';
import { entityCommentUrl } from 'app/utils/entitiesHelper';

import { RESTRICTED_OBJECT_TYPES } from 'app/constants';

const POST_REPLY_MUTATION = queryLoader('app/graphql/WriteComment.gql');
const POST_COMMENT_ACTION_VERB = [
  'Commented',
  'Mentioned',
  'New Reply',
  'Edited',
];
const RESTRICTED_NOTIFICATION_NAMES = ['Mentioned on Pulse'];

class NotificationCommentCard extends NotificationCardLayout {
  state = {
    replyState: 'reply',
  };

  onClick = (event) => {
    this.props.onClick(event, this.props);
  };

  renderCommentText() {
    return this.props.objects.length > 1
      ? this.renderGroupedTemplate()
      : this.renderSingleTemplate();
  }

  renderGroupedTemplate() {
    return null;
  }

  renderSingleTemplate() {
    let object = this.props.objects[0];
    let linkClassName = this.props.isSmallNotification
      ? 'a-link fs-12'
      : 'a-link';

    if (object && object.content) {
      let readMoreLinkUrl =
        this.props.isSmallNotification &&
        replaceCurrentDomain(object.commentable_path);
      return (
        <ParseText
          obj={object}
          className={'nf-text-info'}
          onClick={this.readNotification}
          linkClassName={linkClassName}
          readMoreLinkUrl={readMoreLinkUrl}
          readMoreRequired={this.props.isSmallNotification}
          truncateWordsUpto={65}
        />
      );
    }
  }

  isCommentSectionRestricted() {
    let card_object = this.props.card_objects[0],
      isCommentSectionRestricted = includes(
        RESTRICTED_OBJECT_TYPES,
        card_object.__typename,
      );

    if (['Case', 'Comparison'].includes(card_object.__typename)) {
      isCommentSectionRestricted = true;
    }

    if (
      !isCommentSectionRestricted &&
      card_object.__typename === 'Board' &&
      (!canSpaceHaveDiscussions(card_object) ||
        !card_object.is_author_or_collaborator)
    ) {
      isCommentSectionRestricted = true;
    }

    return isCommentSectionRestricted;
  }

  isPostCommentRequired() {
    return !RESTRICTED_NOTIFICATION_NAMES.includes(this.props.name);
  }

  actionRowRequired() {
    let object = this.props.objects[0];
    return (
      POST_COMMENT_ACTION_VERB.includes(this.props.verb) &&
      !this.isCommentSectionRestricted() &&
      this.isPostCommentRequired() &&
      object &&
      !entityIsDeleted(this.props.objects[0])
    );
  }

  actionRow() {
    if (this.props.isSmallNotification) {
      return <span className='n-d-action'>{this.renderReplyContainer()}</span>;
    } else {
      return <div className='nf-action-bar'>{this.renderReplyContainer()}</div>;
    }
  }

  isCTARequiredOnSmallNotification() {
    return this.actionRowRequired() && this.state.replyState === 'reply';
  }

  renderReplyContainer() {
    switch (this.state.replyState) {
      case 'reply':
        return this.renderReplyAction();
      case 'replyField':
        return this.rendeReplyField();
      case 'replyPosted':
        return this.renderReplyPostedMessage();
      default:
        return null;
    }
  }

  showReplyField = (e) => {
    e.preventDefault();
    this.readNotification();
    this.setState({ replyState: 'replyField' });
  };

  requestOptions(values) {
    let object = this.props.objects[0];
    return {
      commentableId: object.commentable_id,
      commentableType: object.commentable_type,
      commentCategoryIds: [],
      content: values.content,
      parentId: object.parent_id || object.id,
      type: 'Reply',
      publicFeedRequired: false,
    };
  }

  onSubmit = (values) => {
    this.props
      .postReply({ variables: this.requestOptions(values) })
      .then(({ data }) => {
        let comment = data.writeComment;

        if (comment.id) {
          this.setState({
            replyState: 'replyPosted',
            commentLink: entityCommentUrl(comment, comment.id),
          });
        }
      });
  };

  rendeReplyField() {
    // `do-not-close-quick-view` Class added to skip closing of quick view.
    return (
      <div className='os-post-message-block do-not-close-quick-view'>
        <PostMessageField
          onSubmit={this.onSubmit}
          form={'notification-' + this.props.id}
        />
      </div>
    );
  }

  getActionText() {
    return this.props.isSmallNotification ? 'Reply' : 'Reply';
  }

  renderReplyAction() {
    // `do-not-close-quick-view` Class added to skip closing of quick view.
    return (
      <OsBtn
        name='BtnIcon'
        icon='reply-fill-left'
        extraClass=''
        linkHelperClass={'do-not-close-quick-view'}
        onClick={this.showReplyField}
        text={this.getActionText()}
      />
    );
  }

  renderReplyPostedMessage() {
    return (
      <div
        className='pointer full-width dropdown-reply-bar'
        onClick={this.readNotification}>
        <span>Your reply was posted.</span>
        <OsLink
          href={this.state.commentLink}
          className='a-link pointer ms-1'
          text='View it here'
          openInNewTab={true}
        />
      </div>
    );
  }

  renderBody() {
    return (
      <div>
        {!this.isCommentSectionRestricted() && (
          <div className='pointer' onClick={this.onClick}>
            {this.renderCommentText()}
          </div>
        )}
      </div>
    );
  }
}

NotificationCommentCard = graphql(POST_REPLY_MUTATION, { name: 'postReply' })(
  NotificationCommentCard,
);
NotificationCommentCard = connect(({ currentUser }) => ({ currentUser }), {
  openPoliciesModal,
})(NotificationCommentCard);

export default NotificationCommentCard;
