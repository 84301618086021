import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import ActionCable from 'app/actioncable/actioncable';
import RecordsView from 'app/components/RecordsView';

import { entityUrl } from 'app/utils/entitiesHelper';
import { getTotalUnreadNotificationCount } from 'app/utils/userHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

class NavigationNotificationDropdown extends Component {
  markAllNotificationsRead = (e) => {
    ActionCable.notifications.read_all();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.refetchData && this.props.refetchData)
      this.container.scrollTop = 0;
  }

  renderHeading() {
    let count = getTotalUnreadNotificationCount(this.props.currentUser.graph);
    if (count) {
      return (
        <>
          <div className='heading'>
            Unread notifications{' '}
            <span className='action-link-info unread-notification-count'>
              {getTotalUnreadNotificationCount(this.props.currentUser.graph)}
            </span>
          </div>
          <OsLink
            className='btn-link-32'
            onClick={this.markAllNotificationsRead}
            text='Mark as Read'
          />
        </>
      );
    } else {
      return <div className='heading'>Notifications</div>;
    }
  }

  render() {
    return (
      <div>
        <div
          className={`notification-dropdown-heading ${
            isCareWorkspaceView() ? 'without-gear' : ''
          }`}>
          {this.renderHeading()}
          <OsBtn
            name='BtnIcon'
            icon='setting'
            extraClass={`${
              isCareWorkspaceView() ? 'd-none' : ''
            } no-text p-8 notifications-gear`}>
            <Link
              to={withWorkspaceIdentifier(
                '/account_settings/notification_preferences',
              )}
              className='btn-link-helper'
            />
          </OsBtn>
        </div>
        <div
          className='dropdown-menu__notifications'
          ref={(container) => (this.container = container)}>
          <RecordsView
            type='Notification'
            source='dropdown'
            windowNotRequired={true}
            threshold={200}
            perPage={10}
            refetchData={this.props.refetchData}
            parentContainer={this.container}
          />
        </div>
        <div className='notification-btn-group justify-content-center mb-4'>
          <OsBtn name='BtnSecondary' text='View all' extraClass='with-border'>
            <Link
              to={`${entityUrl(this.props.currentUser.graph)}/notifications`}
              className='btn-link-helper'
              onClick={this.props.closeDropdown}
            />
          </OsBtn>
        </div>
      </div>
    );
  }
}

NavigationNotificationDropdown = connect(({ currentUser }) => ({
  currentUser,
}))(NavigationNotificationDropdown);
export default NavigationNotificationDropdown;
