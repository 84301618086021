import { CLOSE_FOLDER_MODAL, OPEN_FOLDER_MODAL } from 'app/actions/types';

export default function folderModal(
  state = { open: false, options: {} },
  action,
) {
  switch (action.type) {
    case OPEN_FOLDER_MODAL:
      return { open: true, ...action.options };
    case CLOSE_FOLDER_MODAL:
      return { open: false, ...action.options };
    default:
      return state;
  }
}
