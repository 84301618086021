import React, { useRef, useState } from 'react';
import GroupChatMenuModal from 'app/components/GroupMessenger/GroupChatMenuModal';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import {
  openArchiveTeamSpaceModal,
  openCreateTeamSpaceModal,
  openEditTeamSpaceModal,
  openInfoTeamSpaceModal,
  openLeaveTeamSpaceModal,
} from 'app/actions/teamSpaceModal';
import { useDispatch, useSelector } from 'react-redux';
import { relativeEntityUrl } from 'app/utils/entitiesHelper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { flashSuccess } from 'app/actions/flashMessage';
import InviteMember from 'app/components/InviteMember';
import { openShareModal } from 'app/actions/shareModal';
import useGroupModal from 'app/components/GroupMessenger/GroupChatModal/useGroupModal';
import { isSpaceArchived } from 'app/utils/spaceHelper';

const GroupChatMenu = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const workspace = useSelector((state) => state.workspace);
  const { archiveSpace, followGroup, unfollowGroup } = useGroupModal();
  const dispatch = useDispatch();
  const [isMenuActive, setisMenuActive] = useState(false);
  const [state, setState] = useState({
    visible: false,
    width: props.width || 174,
    space: props.space || 8,
    openInviteModal: false,
    showShareModal: false,
  });
  const parentRef = useRef(null);
  const menuRef = useRef(null);

  const hideMenuModal = () => {
    setState((prev) => ({ ...prev, visible: false }));
    setisMenuActive(false);
  };

  const showMenuModal = () => {
    setState((prev) => ({
      ...prev,
      visible: true,
    }));
    setisMenuActive(true);
  };

  const toggleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (state.visible) {
      hideMenuModal();
      return true;
    }
    const style = { width: state.width };
    const dimensions = parentRef.current.getBoundingClientRect();
    style.position = 'absolute';
    style.zIndex = '9999';
    if (props.position === 'right') {
      style.left = dimensions.left;
      style.left = Math.min(
        style.left,
        document.body.clientWidth - state.space,
      );
    } else {
      style.left = dimensions.left + dimensions.width - state.width;
      style.left = Math.max(state.space, style.left);
      style.left = Math.min(
        style.left,
        document.body.clientWidth - state.width - state.space,
      );
    }
    if (dimensions.top < window.innerHeight / 2) {
      style.top = dimensions.top + dimensions.height + state.space;
    } else {
      style.bottom = window.innerHeight - dimensions.top + state.space;
    }

    setState((prev) => ({
      ...prev,
      style,
    }));
    showMenuModal();
  };

  const openInfoModal = (e) => {
    e.preventDefault();
    hideMenuModal();
    return dispatch(openInfoTeamSpaceModal(props.spaceId));
  };

  const copyAction = (e) => {
    e.preventDefault();
    hideMenuModal();
    dispatch(flashSuccess('Copied to Clipboard', false));
  };

  const toggleInviteModal = (e) => {
    hideMenuModal();
    setState((prev) => ({ ...prev, openInviteModal: !state.openInviteModal }));
  };

  const openEditModal = (e) => {
    e.preventDefault();
    hideMenuModal();
    return dispatch(
      openEditTeamSpaceModal(props.spaceId, {
        parentSpace: workspace.data,
        canUpdateGroup: true,
      }),
    );
  };

  const toggleArchiveModal = (e) => {
    e.preventDefault();
    hideMenuModal();
    return dispatch(
      openArchiveTeamSpaceModal(props.spaceId, {
        image: props.image,
        entity: props.entity,
      }),
    );
  };

  const toggleMuteStatus = (e) => {
    e.preventDefault();
    hideMenuModal();
    if (entity.followed_by_current_user) {
      unfollowGroup(entity.id);
    } else {
      followGroup(entity.id);
    }
  };

  const unarchiveGroup = (e) => {
    e.preventDefault();
    hideMenuModal();
    archiveSpace(props.entity);
  };

  const closeShareModal = () => {
    setState((prev) => ({ ...prev, showShareModal: false }));
  };

  const openGroupShareModal = () => {
    let shareableProps = {
      show: state.showShareModal,
      closeModal: closeShareModal,
      type: 'Board',
      engagementNotReq: true,
      object: props.entity,
    };
    dispatch(openShareModal(shareableProps));
    setState((prev) => ({ ...prev, showShareModal: true }));
  };

  const leaveGroup = async (e) => {
    e.preventDefault();
    hideMenuModal();
    return dispatch(
      openLeaveTeamSpaceModal(props.spaceId, {
        entity: props.entity,
      }),
    );
  };

  const isMember = () => {
    return props.entity.is_author_or_collaborator;
  };

  const canLeaveGroup = () => {
    return props.entity.user_request && isMember() && !isOwner();
  };

  const isOwner = () => {
    return parseInt(entity.user.id) === parseInt(currentUser.graph.id);
  };

  const isEditor = () => {
    return props.entity.is_author_or_editor;
  };

  const canEdit = () => {
    return isOwner() || isEditor();
  };

  const openCreateGroupModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    hideMenuModal();
    return dispatch(
      openCreateTeamSpaceModal({
        canUpdateGroup: true,
      }),
    );
  };

  const renderSingleGroupMenu = () => {
    return (
      <ul className={sideMenu}>
        {canEdit() && (
          <li onClick={openEditModal}>
            <OrthoIcon name={'edit'} />
            Edit Group
          </li>
        )}
        <li onClick={openInfoModal}>
          <OrthoIcon name={'info'} />
          Information
        </li>
        {/* <li onClick={openGroupShareModal}>
        <OrthoIcon name={'share-fill'} />
        Share Group
      </li> */}
        <CopyToClipboard text={relativeEntityUrl(props.entity, true)}>
          <li onClick={copyAction}>
            <OrthoIcon name={'link-copy'} />
            Copy link
          </li>
        </CopyToClipboard>
        {canEdit() && (
          <li onClick={toggleInviteModal}>
            <OrthoIcon name={'invite'} />
            Invite members
          </li>
        )}
        {/* <li className='have-submenu'>
          <OrthoIcon name={'mute'} />
          Mute notifications
          <OrthoIcon name={'chevron-right'} />
          <ul>
            <li>Mute for 24 hours</li>
            <li>Mute for 7 days</li>
          </ul>
        </li> */}
        {!isSpaceArchived(entity) && (
          <li className='have-submenu' onClick={toggleMuteStatus}>
            {entity.followed_by_current_user ? (
              <>
                <OrthoIcon name={'mute'} />
                Mute notifications
              </>
            ) : (
              <>
                <OrthoIcon name={'unmute'} />
                Unmute notifications
              </>
            )}
          </li>
        )}
        {isOwner() && !isSpaceArchived(entity) && (
          <li onClick={toggleArchiveModal}>
            <OrthoIcon name={'delete'} />
            Archive Group
          </li>
        )}
        {isOwner() && isSpaceArchived(entity) && (
          <li onClick={unarchiveGroup}>
            <OrthoIcon name={'delete'} />
            Unarchive Group
          </li>
        )}
        {canLeaveGroup() && (
          <li onClick={leaveGroup}>
            <OrthoIcon name={'logout'} />
            Leave Group
          </li>
        )}
      </ul>
    );
  };

  const renderGroupAccordionMenu = () => {
    return (
      <ul className={sideMenu}>
        <li onClick={openCreateGroupModal}>
          <OrthoIcon name={'add'} />
          Create new Group
        </li>
      </ul>
    );
  };

  const { entity, extraClass } = props;
  let parentClass = 'actions';
  parentClass += isMenuActive ? ' active' : '';

  let sideMenu = 'side-menu';
  sideMenu += props.position === 'left' ? ' left' : ' right';
  return (
    <div className={parentClass} ref={parentRef}>
      <OrthoIcon
        onClick={toggleModal}
        name={'more'}
        defaultClass={extraClass}
      />
      {state.openInviteModal && (
        <InviteMember
          showModal={true}
          modalClosed={toggleInviteModal}
          board={props.entity}
          viewType='modal'
        />
      )}
      {state.visible && (
        <GroupChatMenuModal hideModal={hideMenuModal}>
          <div className='side-menubox' ref={menuRef} style={state.style}>
            {props.spaceId
              ? renderSingleGroupMenu()
              : renderGroupAccordionMenu()}
          </div>
        </GroupChatMenuModal>
      )}
    </div>
  );
};

export default GroupChatMenu;
