import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  nice_id,
  name,
  user,
  category,
  categoryType,
  onClick,
  trackOnClick,
}) => {
  let onClickParams = { category: category, categoryType: categoryType },
    link;

  if (category === 'cases') {
    link = `/comparison/${id}`;
    onClickParams = { ...onClickParams, id: id };
  } else {
    link = `/search/${category}/cases/id/${id}`;
    onClickParams = { ...onClickParams, query_by: category, id_query: nice_id };
  }

  return (
    <SearchResult
      key={`Comparison:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>{user.name}</span>
        {name}
      </div>
    </SearchResult>
  );
};
