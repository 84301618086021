import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import { URL_REGEX } from 'app/constants';
import { translate } from 'app/actions/flashMessage';
import { updateBannerValues } from 'app/actions/universalBanner';
import { isNaN, isNumber } from 'app/utils/osLodash';
import {
  timeDifferenceFromThenInMilliseconds,
  timeWithFormat,
  secondsToDhms,
} from 'app/utils/timeHelper';

class UniversalBanner extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.setTimerInterval();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expires_at !== this.props.expires_at) {
      this.clearInterval();
      this.setState(this.getDefaultState(), this.setTimerInterval);
    }

    if (this.state.expiresIn <= 0) {
      this.setState(this.getDefaultState());
      this.props.updateBannerValues(this.props.bannerKey, { active: false });
    }
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  getDefaultState() {
    return {
      expiresIn: timeDifferenceFromThenInMilliseconds(
        this.props.expires_at / 1000,
      ),
    };
  }

  clearInterval() {
    clearInterval(this.interval);
  }

  setTimerInterval() {
    if (isNumber(this.state.expiresIn) && !isNaN(this.state.expiresIn)) {
      this.interval = setInterval(() => {
        this.setState({ expiresIn: this.state.expiresIn - 1000 });
      }, 1000);
    }
  }

  close = () => {
    const { bannerKey, hideUniversalBanner, onClose } = this.props;

    hideUniversalBanner({ bannerKey });
    onClose && onClose(bannerKey);
  };

  getCtaComponent = () => {
    const {
      ctaTextDesktop = 'More',
      ctaText = 'More',
      ctaUrl,
      ctaOpenInNewTab,
      onCTAClick,
      device: { desktop },
    } = this.props;
    let ctaProps;
    if (!ctaUrl && !onCTAClick) return null;
    if (ctaUrl) {
      const UrlType = ctaUrl.match(URL_REGEX) ? 'href' : 'to';
      ctaProps = {
        [UrlType]: ctaUrl,
      };
    } else {
      ctaProps = {
        onClick: onCTAClick,
      };
    }

    const text = desktop ? ctaTextDesktop : ctaText;

    return (
      <OsLink {...ctaProps} className='a-link' openInNewTab={ctaOpenInNewTab}>
        {text}
      </OsLink>
    );
  };

  renderCloseBtn() {
    if (this.props.allowUserDismiss) {
      return (
        <OsBtn
          name='BtnIcon'
          icon='close'
          extraClass='universalBannerClose no-text web-view-btn'
          onClick={this.close}
        />
      );
    }
  }

  renderContent() {
    let { htmlContent, message, messageKey, interpolations = {} } = this.props,
      content = translate(messageKey, interpolations, false) || message;
    if (htmlContent) {
      return (
        <span
          className='universalBannerMessage'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    } else {
      return <span className='universalBannerMessage'>{content}</span>;
    }
  }

  getTimerValue() {
    if (this.state.expiresIn > 24 * 3600000) {
      return `Ends on ${timeWithFormat(
        this.props.expires_at / 1000,
        'Do MMM, h:mm a',
      )}`;
    } else if (this.state.expiresIn > 0) {
      return `Ends in ${secondsToDhms(this.state.expiresIn / 1000)}`;
    }
  }

  renderTimer() {
    if (this.props.timer)
      return (
        <>
          <span className='universalBannerTimer'>{this.getTimerValue()}</span>
        </>
      );
  }

  render() {
    const { bannerKey } = this.props;

    if (!bannerKey) return null;
    let extraClass = this.props.device.mobileDevice
      ? 'text-left'
      : 'text-center';
    return (
      <section
        key={bannerKey}
        className={`universalBannerWrapper ${bannerKey}`}>
        <div className='container'>
          <div className={`m-auto ${extraClass}`}>
            {this.renderContent()}
            {this.getCtaComponent()}
            {this.renderTimer()}
          </div>
          {this.renderCloseBtn()}
        </div>
      </section>
    );
  }
}

UniversalBanner = connect(({ device }) => ({ device }), {
  updateBannerValues,
})(UniversalBanner);

export default UniversalBanner;
