import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import RecordsView from 'app/components/RecordsView';
import InboxSection from 'app/components/Inbox/InboxSection';

import 'app/components/Inbox/inbox-common.scss';

const ConversationsInboxComponent = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { workspace } = props;

  const updateUnreadCount = (count) => {
    setUnreadCount(count);
  };

  const renderUnreadDM = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        updateCount={updateUnreadCount}
        type={'MyUnreadConversations'}
        queryType='INBOX_CONVERSATIONS'
        sectionTitle={'Unread'}
        key={workspace?.data?.unread_conversation_inbox_count}
      />
    );
  };

  const renderReadDM = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        type={'MyReadConversations'}
        queryType='INBOX_CONVERSATIONS'
        sectionTitle={'Previously Read'}
        key={workspace?.data?.unread_conversation_inbox_count}
      />
    );
  };
  return (
    <div className='inbox-wrapper'>
      <div className='inbox'>
        <InboxSection
          title={'Unread • Direct Messages'}
          type={'conversation_inbox'}
          hideSection={unreadCount === 0}
          markReadButton={true}>
          {(showBody) => {
            return <>{renderUnreadDM(showBody)}</>;
          }}
        </InboxSection>
        <InboxSection title={'Previously Read'} expandable={unreadCount > 0}>
          {(showBody) => {
            return <>{showBody && renderReadDM()}</>;
          }}
        </InboxSection>
      </div>
    </div>
  );
};

const ConversationsInboxConnect = connect(({ workspace }) => ({ workspace }))(
  ConversationsInboxComponent,
);
const ConversationsInbox = withRouter(ConversationsInboxConnect);

export default ConversationsInbox;
