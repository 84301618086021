import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';

import withAuthorize from 'app/components/HOC/withAuthorize';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import EventTracker from 'app/services/EventTracker';

import TeamChatWindow from 'app/components/TeamSpaceCommunicationsView/TeamChatWindow';
import NewConversationView from 'app/components/TeamSpaceCommunicationsView/NewConversationView';

// View to hold the conversations
const TeamSpaceCommunicationsView = (props) => {
  useEffect(() => {
    EventTracker.track('Navigate', { page: 'Messages' });
  }, []);

  const renderActiveConversation = () => {
    const { id } = props.match.params;
    switch (id) {
      case 'new':
        return <NewConversationView />;
      case 'sidebar':
        return '';
      default:
        return (
          <TeamChatWindow
            key={id}
            id={id}
            entity_type={props.match.params.entity_type}
            nice_id={props.match.params?.filter}
            currentUser={props.currentUser}
            redirectPath={getRedirectPath()}
          />
        );
    }
  };

  const renderContent = () => {
    let leftPanelVisible = isLeftPanelVisible();

    return (
      <div className='row'>
        <div
          className={
            'conversation-center-panel ' +
            (leftPanelVisible ? 'left-panel-hidden' : 'show')
          }>
          {renderActiveConversation()}
        </div>
      </div>
    );
  };

  const isLeftPanelVisible = () => {
    return props.match.params.id === 'sidebar';
  };

  const getRedirectPath = () => {
    if (props.device.mobile) {
      return '/team';
    } else if (props.currentUser.graph) {
      return `/dm/recent`;
    } else {
      return '/team/dm/new';
    }
  };

  let className = 'team-conversation dm-conversation';
  className += isLeftPanelVisible() ? ' chat-listing-page' : '';

  return (
    <div className={className}>
      <div className='container conversation-content-container'>
        {renderContent()}
      </div>
    </div>
  );
};

const ApolloTeamSpaceCommunicationsView = withApollo(
  TeamSpaceCommunicationsView,
);
const TeamSpaceCommunicationsViewRoute = withRouter(
  ApolloTeamSpaceCommunicationsView,
);
const TeamSpaceCommunicationsViewAuthorize = withAuthorize(
  TeamSpaceCommunicationsViewRoute,
);

const TeamSpaceCommunicationsViewWrapper = connect(
  ({ currentUser, device }) => ({
    currentUser,
    device,
  }),
)(TeamSpaceCommunicationsViewAuthorize);

export default TeamSpaceCommunicationsViewWrapper;
