import React, { Component } from 'react';
import client from 'app/graphql/client';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import FileIcon from 'app/components/Shared/FileIcon';
import OsBtn from 'app/components/OsBtn';

import { translate } from 'app/actions/flashMessage';
import { isDownloadable } from 'app/utils/fileHelper';
import { viewObject } from 'app/actions/viewObject';

const ATTACHMENT_QUERY = queryLoader('app/graphql/Attachment.gql');

class CustomErrorFileView extends Component {
  openDownloadUrl = (file) => {
    client
      .query({
        query: ATTACHMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: { id: file.id },
      })
      .then(({ data }) => {
        window.location.href = data.attachment.download_url;
        if (this.props.viewObjectRequired) {
          let link_obj = {
            id: this.props.previewObj.id,
            __typename: this.props.previewObj.__typename,
            view_count: 0,
          };
          this.props.viewObject({ obj: link_obj });
        }
      });
  };

  renderDownload(file) {
    return (
      <OsBtn
        name='BtnPrimary'
        text='Download File'
        extraClass='mt-3'
        onClick={this.openDownloadUrl.bind(this, file)}
      />
    );
  }

  render() {
    if (this.props.source === 'LecturePreview') {
      let file = this.props.previewObj;
      return (
        <div className='no-preview-text text-center'>
          <div>
            There is no preview available for this file. <br />
            You can download it to read.
          </div>
          {isDownloadable(file.linkable || file) &&
            this.renderDownload(file.linkable || file)}
        </div>
      );
    }
    return (
      <div className='attachments-content-holder'>
        <div className='conversation-attachment-icon-holder'>
          <FileIcon fileType={this.props.fileType} />
          {this.props.processingOverlayRequired && (
            <div className='os-text py-2 process-text'>
              {translate('FILE_PROCESSING_TEXT')}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CustomErrorFileView.defaultProps = {
  source: '',
};
CustomErrorFileView = connect(null, { viewObject })(CustomErrorFileView);

export default CustomErrorFileView;
