import React, { Component } from 'react';
import OsField from './';

export default class OsHiddenField extends Component {
  render() {
    return (
      <>
        <input type='hidden' {...this.props.input} />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
      </>
    );
  }
}
