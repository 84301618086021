import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { entityConversationUrl, entityUrl } from 'app/utils/entitiesHelper.js';

class ConnectedUserActions extends Component {
  onSendMessageButtonClick = (e) => {
    this.props.navigate(entityConversationUrl(this.props.user));
    this.readNotification();
  };

  onViewProfileClick = () => {
    this.props.navigate(entityUrl(this.props.user));
    this.readNotification();
  };

  readNotification() {
    this.props.unread && this.props.readNotification(this.props.id);
  }

  renderSmallCardLink() {
    return (
      <span className='n-d-action'>
        <OsLink
          className='a-link fs-12 pointer text-uppercase abs-link'
          onClick={this.onSendMessageButtonClick}
          text='Message'
        />
      </span>
    );
  }

  renderActions() {
    return (
      <div className='nf-action-bar'>
        <OsBtn
          text='Send a Message'
          name='BtnPrimary'
          onClick={this.onSendMessageButtonClick}
          associatedEntity={this.props.user}
        />
        <OsBtn
          name='BtnSecondary'
          extraClass='with-border'
          text='View Profile'
          onClick={this.onViewProfileClick}
          associatedEntity={this.props.user}
        />
      </div>
    );
  }

  render() {
    if (this.props.objects[0].status === 'accepted') {
      return this.props.isSmallNotification
        ? this.renderSmallCardLink()
        : this.renderActions();
    } else {
      return <div />;
    }
  }
}

ConnectedUserActions = withRouter(ConnectedUserActions);

export default ConnectedUserActions;
