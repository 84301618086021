import React from 'react';
import {
  isGroupNotificationMuted,
  isPrivateSpace,
} from 'app/utils/spaceHelper';
import GroupChatMenu from 'app/components/GroupMessenger/GroupChatMenu';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import 'app/components/SideBarTeamSpaceList/team-group-list.scss';

const GroupListRow = ({ teamspace, activeResourceId }) => {
  const renderContent = (teamspace) => {
    return (
      <div className='ac-user-name'>
        <span className='text'>{teamspace?.name} </span>
        <span className='status'>
          {isPrivateSpace(teamspace) && <OrthoIcon name={'locked'} />}
          {isGroupNotificationMuted(teamspace) && <OrthoIcon name={'mute'} />}
        </span>

        {teamspace.user_unread_notifications_count > 0 && (
          <span className='lt-count pull-right'>
            {teamspace.user_unread_notifications_count}
          </span>
        )}
      </div>
    );
  };

  const renderTeamSpaceImage = (teamspace) => {
    let { image } = teamspace;
    return (
      <>
        <img src={image} alt='' className='space-hero-image' />
      </>
    );
  };

  let className = teamspace.unseen_comments_present ? 'unread' : '';
  className += teamspace.id === activeResourceId ? ' active' : '';

  return (
    <li className={className} key={teamspace.id}>
      <OsLink
        convertRouteToUnifiedView={true}
        to={`/team/group/${teamspace.nice_id}`}>
        {renderTeamSpaceImage(teamspace)}
        {renderContent(teamspace)}
      </OsLink>
      <GroupChatMenu
        position={'right'}
        spaceId={teamspace.nice_id}
        entity={teamspace}
      />
    </li>
  );
};

export default GroupListRow;
