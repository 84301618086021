import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { loader as queryLoader } from 'graphql.macro';

import Avatar from 'app/components/Shared/Avatar';
import ShareWithPatientButton from 'app/components/Shared/ShareWithPatientButton';
import ImageEdit from 'app/components/AccountView/imageEdit';
import OsBtn from 'app/components/OsBtn';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import {
  getCareAvatarIconProps,
  shareWithPatientCommonProps,
} from 'app/utils/spaces/careHelper';
import CarespaceEditor from 'app/components/SpaceInformationView/CarespaceEditor';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class CareSpaceInformationView extends PureComponent {
  state = {
    showModalEdit: false,
    showEditButton: false,
  };

  getSpace() {
    return this.props.space;
  }

  openModalEdit = () => {
    this.setState({ showModalEdit: true });
  };

  closeModalEdit = () => {
    this.setState({ showModalEdit: false });
  };

  goBack = () => {
    if (this.props.showEditor) {
      this.props.hideEditor();
    } else {
      this.props.goBack();
    }
  };

  renderSpaceNameAndDetails() {
    let space = this.getSpace();
    return (
      <div className='space-header-name pe-3'>
        <span className='span'>{space.owner.patient.name}</span>
      </div>
    );
  }

  renderAuthorName() {
    const space = this.getSpace();
    return (
      <div className='pe-2 semibold lh-1 me-4'>
        <UserQualificationInfo
          user={space.user}
          osLinkClass='a-link white-link'
          sliceUpto={2}
          linkRequired={false}
        />
      </div>
    );
  }

  renderPrimaryActions() {
    return (
      <div style={this.props.device.mobileDevice ? { marginTop: '8px' } : {}}>
        <ShareWithPatientButton
          {...shareWithPatientCommonProps(this.getSpace())}
          source='care-space-detail'
          as='primary'
          patient={this.props.space.owner.patient}
        />
      </div>
    );
  }

  renderImageEditAction() {
    let btnClasses =
      'user-image-edit-btn test position-absolute web-view-btn no-text';
    btnClasses += !this.props.device.mobileDevice ? ' p-0' : ' ';

    return (
      <OsBtn
        name='BtnIcon'
        extraClass={btnClasses}
        icon='selfie'
        onClick={this.openModalEdit}
      />
    );
  }

  renderImageEditButton() {
    this.setState((prevState) => ({
      showEditButton: !prevState.showEditButton,
    }));
  }

  render() {
    let mobile = this.props.device.mobileDevice,
      subHeaderClass = 'space-sub-header without-extra-info ',
      avatarClass = 'avatar avatar-40',
      { patient } = this.props.space.owner,
      avatarProps = getCareAvatarIconProps(
        this.props.space,
        { id: patient.user_id },
        { isPatientOrGuardian: true },
      ),
      allAvatarAttributes = avatarAttributes(patient, {
        largeAvatarRequired: true,
        ...avatarProps,
      }),
      isEditModeRequired =
        !allAvatarAttributes?.src?.length || this.state.showEditButton;

    subHeaderClass +=
      !this.getSpace().is_author_or_collaborator &&
      !this.props.device.mobileDevice
        ? 'with-join-request '
        : 'already-joined ';
    return (
      <>
        <article className={subHeaderClass}>
          <div
            className='d-flex header-title-row'
            style={
              mobile ? { maxWidth: '100%' } : { maxWidth: 'calc(100% - 168px)' }
            }>
            {(this.props.match.params.filter && this.props.device.mobile) ||
            this.props.showEditor ? (
              <div className='avatar-edit' onClick={() => this.goBack()}>
                <span className='back-btn'>
                  <OrthoIcon name='chevron-left' />
                </span>
              </div>
            ) : (
              <div
                className={'avatar-edit'}
                onMouseEnter={this.renderImageEditButton.bind(this)}
                onMouseLeave={this.renderImageEditButton.bind(this)}>
                <Avatar className={avatarClass} {...allAvatarAttributes} />
                {isEditModeRequired && this.renderImageEditAction()}
              </div>
            )}
            <div className='ms-3' style={{ width: '100%' }}>
              {this.renderSpaceNameAndDetails()}
              <div
                className={`d-flex align-items-center btn-with-title-bar ${
                  this.props.device.mobileDevice ? 'only-mobile-bar' : ''
                } `}>
                {this.renderAuthorName()}
              </div>
            </div>
            {!mobile && this.renderPrimaryActions()}
          </div>
          {!mobile && !isCareWorkspaceView() && (
            <CarespaceEditor props={this.props} />
          )}
          {mobile &&
            !this.props.showEditor &&
            !this.props.match.params.filter &&
            this.renderPrimaryActions()}
        </article>

        <Modal
          show={this.state.showModalEdit}
          onHide={this.closeModalEdit}
          dialogClassName='modal-xl profile-image-modal'
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <ImageEdit closeParentModal={this.closeModalEdit} obj={patient} />
        </Modal>
      </>
    );
  }
}

CareSpaceInformationView = connect(
  ({ device }) => ({ device }),
  {},
)(CareSpaceInformationView);
CareSpaceInformationView = withRouter(CareSpaceInformationView);

export default CareSpaceInformationView;
