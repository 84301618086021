import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OsBtn from 'app/components/OsBtn';
import Icon from 'app/components/Svg';

import { updateCurrentUserGraph } from 'app/actions/authentication';
import { openInfoModal } from 'app/actions/infoModal';

import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import {
  first,
  groupBy,
  keys,
  pick,
  startCase,
  values,
} from 'app/utils/osLodash';
import { translate } from 'app/actions/flashMessage';

const INFO_CHANGE_REQUEST_RESPONSE_MUTATION = queryLoader(
  'app/graphql/mutations/Care/InfoChangeRequestResponse.gql',
);

class ProfileDetailsConfirmationView extends Component {
  state = {
    requestInProgress: false,
  };

  sendRequest = (accept) => {
    this.setState({ requestInProgress: true });
    let requests = this.getRequests(),
      clinic = requests[0].clinic;

    this.props
      .infoChangeRequestResponse({
        variables: {
          requestIds: requests.map((req) => req.id),
          accept,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        let response = data.infoChangeRequestResponse;
        if (response.success) {
          let userData = pick(
            response.entity,
            'info_change_requests',
            'number_update_required',
            'first_name',
            'last_name',
            'name',
            'full_name',
          );
          this.props.updateCurrentUserGraph(userData);
          if (accept) {
            if (response.entity.number_update_required) {
              this.props.navigate('/update_number');
            } else {
              this.props.openInfoModal(
                'care_space',
                'patient_info_confirmed_success',
              );
            }
          } else if (clinic?.email) {
            window.location.href = `mailto:${clinic.email}`;
          }
        }
      });
  };

  isOpen() {
    let { graph } = this.props.currentUser;
    return graph && !!graph.info_change_requests.length;
  }

  modifyRequests() {
    let { graph } = this.props.currentUser;
    if (graph?.info_change_requests) {
      return groupBy(graph.info_change_requests, (i) => i.clinic.id);
    } else {
      return [];
    }
  }

  getRequests() {
    return first(values(this.modifyRequests()));
  }

  renderProfileInfoUpdates(requests) {
    let request = first(requests.filter((req) => req.profile_detail_change));
    if (request) {
      let payload = JSON.parse(request.payload);
      return keys(payload).map((key) => {
        return (
          <div className='modal-update--profile-listing'>
            <b className='me-1'>{startCase(key)}:</b> {payload[key]}
          </div>
        );
      });
    }
  }

  renderRelationshipUpdates(allRequests) {
    let requests = allRequests.filter((req) => !req.profile_detail_change);
    return requests.map((request) => {
      let payload = JSON.parse(request.payload),
        patient = request.target.person;
      return (
        <div className='modal-update--profile-listing'>
          <b className='me-1'>Relationship to ({patient.first_name}):</b>{' '}
          {payload['relation_name']}
        </div>
      );
    });
  }

  renderContent() {
    let requests = this.getRequests(),
      { requestInProgress } = this.state;

    return (
      <>
        <Modal.Title className='border-0'>Confirm profile updates</Modal.Title>
        <Icon name='information' />
        <Modal.Body className='px-0'>
          <div className='modal-update--profile-listing'>
            The {requests[0].clinic.name} has updated the following information:
          </div>
          {this.renderProfileInfoUpdates(requests)}
          {this.renderRelationshipUpdates(requests)}
          <div className='modal-update--profile-listing'>
            {translate('INFO_CHANGE_REQUESTS_TEXT')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-center'>
            <OsBtn
              name='BtnSecondary'
              extraClass='with-border'
              text='Decline'
              onClick={this.sendRequest.bind(this, false)}
              disabled={requestInProgress}
            />
            <OsBtn
              name='BtnPrimary'
              text='Accept'
              onClick={this.sendRequest.bind(this, true)}
              disabled={requestInProgress}
              loaderRequired={requestInProgress}
            />
          </div>
        </Modal.Footer>
      </>
    );
  }

  render() {
    if (isCareWorkspaceView()) {
      return (
        <Modal
          show={this.isOpen()}
          animation={false}
          dialogClassName='os-grid-modal confirm-profile-modal'
          backdropClassName='modal-backdrop-custom'>
          {this.getRequests() && this.renderContent()}
        </Modal>
      );
    } else {
      return null;
    }
  }
}

ProfileDetailsConfirmationView = withRouter(ProfileDetailsConfirmationView);
ProfileDetailsConfirmationView = graphql(
  INFO_CHANGE_REQUEST_RESPONSE_MUTATION,
  { name: 'infoChangeRequestResponse' },
)(ProfileDetailsConfirmationView);
ProfileDetailsConfirmationView = connect(
  ({ currentUser, source }) => ({ currentUser, source }),
  { openInfoModal, updateCurrentUserGraph },
)(ProfileDetailsConfirmationView);
export default ProfileDetailsConfirmationView;
