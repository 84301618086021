import React, { Component } from 'react';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { isImage, isVideo } from 'app/utils/fileHelper';

class DefaultThumbnailCard extends Component {
  renderSelectBox() {
    let isSelected = this.props.selected,
      icon = isSelected ? 'roundCheck' : 'roundCheckDefault';
    return (
      <OsBtn
        name='BtnIcon'
        icon={icon}
        extraClass={`selectable-item  ${isSelected ? 'selected' : ''} `}
        onClick={this.props.onSelectBoxClick}
      />
    );
  }

  getTrimmedTitle(title, fileType) {
    let maxL = this.isSizeBig() ? 24 : 12;
    if (title.length > maxL) {
      return `${title.slice(0, maxL - 4)}...${title.substr(-1)}.${fileType}`;
    } else {
      return `${title}.${fileType}`;
    }
  }

  getTitle() {
    const { title, subtitle, fileType, size } = this.props;
    if (fileType) return this.getTrimmedTitle(title, fileType);
    else {
      if (size === 'thumbnail') return subtitle;
      else return title;
    }
  }

  getCaseTitle() {
    const { title, subtitle, size } = this.props;
    if (size === 'thumbnail') {
      return (
        <div classname='multi-line'>
          {title}_{subtitle}
        </div>
      );
    } else {
      return (
        <>
          <div>{title}</div>
          <div>{subtitle}</div>
        </>
      );
    }
  }

  isSizeBig() {
    return this.props.size === 'big';
  }

  getIcon(file) {
    let { type } = file;
    if (isImage(file)) return 'images';
    else if (isVideo(file)) return 'video';
    else if (type === 'Case') return 'case';
    else if (type === 'Link') return 'additionallink';
    else return 'documents';
  }

  render() {
    const { image, subtitle, contentChildren, onClick, type } = this.props;
    let className = 'thumbnail-image';
    // className += !image ? ' no-thumbnail' : ''
    return (
      <div className='os-card-thumbnail' onClick={onClick}>
        <div className={className}>
          <div className='img-parent'>
            {image && <img src={image} alt='' className='card-attach-image' />}
            {!image && contentChildren}
            {!image && !contentChildren && (
              <OrthoIcon
                name={this.getIcon(this.props)}
                className='card-link-svg'
                defaultClass='image-icon'
              />
            )}
          </div>
        </div>
        <div className='thumbnail-title'>
          {type === 'Case' ? (
            this.getCaseTitle()
          ) : (
            <>
              <div
                className={`title-text ${
                  !this.isSizeBig() ? 'multi-line' : ''
                }`}>
                {this.getTitle()}
              </div>
              {this.isSizeBig() && (
                <div className='title-text subtitle-text'>
                  {subtitle.replace(' ', '')}
                </div>
              )}
            </>
          )}
        </div>
        {this.props.multiSelectMode && this.renderSelectBox()}
        {this.props.dropDownContent}
        {this.props.renderEnityRenameForm}
      </div>
    );
  }
}

export default DefaultThumbnailCard;
