import React, { PureComponent } from 'react';

import { SVG_ICONS } from 'app/components/Svg';
import Icon from 'app/components/Svg';

import { keys } from 'app/utils/osLodash';
import { humanize } from 'app/utils/stringHelper';

class SvgList extends PureComponent {
  renderSvg = (key) => {
    return (
      <tr key={key}>
        <td className='td-svg-name'>
          <label>{humanize(key)}</label>
          <Icon name={key} />
        </td>
      </tr>
    );
  };

  render() {
    return (
      <table className='svg-table'>
        <tbody>{keys(SVG_ICONS).map(this.renderSvg)}</tbody>
      </table>
    );
  }
}

export default SvgList;
