import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
  let { isOver, setNodeRef } = useDroppable({
    disabled: props.disabled,
    id: props.id,
  });

  let className = props.className || '';
  className += isOver ? ' drop-active ' : '';

  return (
    <div className={className} ref={setNodeRef} onClick={props.onClick}>
      {props.children}
    </div>
  );
}
