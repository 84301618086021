import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M19.87 14.113c-.546-2.638-2.962-4.566-3.432-7.215-.107-.599-.17-1.32-.695-1.607-.449-.244-1.026.01-1.315.432-.288.421-.363.964-.384 1.485-.032.676.042 1.386.299 1.995l-.235-.177c-1.497-1.064-3.004-2.117-4.5-3.181-.577-.388-1.197-.81-1.892-.842-.353-.023-.748.1-.909.42-.107.211-.085.477-.043.71.032.166.086.333.15.477l-.021.033-.011-.011c-.395-.344-.812-.687-1.315-.82-.502-.133-1.1-.011-1.4.42-.235.356-.246.821-.15 1.242.15.665.513 1.23.973 1.718l-.15-.066a1.785 1.785 0 0 0-.588-.111c-.33-.011-.673.1-.887.366-.213.266-.256.631-.181.964.074.332.245.632.449.898.213.31.47.587.737.853h-.01l-.428.044c-.577.067-.962.72-.93 1.32.032.598.385 1.13.79 1.562.546.588 1.198 1.064 1.85 1.519 1.603 1.141 3.218 2.283 4.746 3.535.545.455 1.08.92 1.678 1.286.599.366 1.272.62 1.967.61.94.11 2.149-.1 3.175-.688a5.25 5.25 0 0 0 2.298-2.637c.256-.654.363-1.353.438-2.062.075-.82.096-1.652-.075-2.472z'
      fill='#FA5F2E'
    />
    <path
      d='M20.869 14.104c-.548-2.637-2.964-4.557-3.434-7.206-.1-.602-.168-1.319-.693-1.608-.448-.243-1.03 0-1.32.428-.291.428-.37.972-.38 1.492-.023.671.044 1.388.29 2.001l-.235-.173a451.091 451.091 0 0 0-4.496-3.17c-.57-.416-1.197-.833-1.89-.867-.347-.012-.738.104-.906.416-.112.208-.09.474-.034.706.034.173.09.335.157.486-.392-.336-.861-.66-1.365-.798-.503-.128-1.096-.012-1.398.428-.235.347-.246.82-.145 1.237.156.66.514 1.226.973 1.724l-.157-.07c-.19-.07-.38-.104-.581-.115-.325-.012-.671.104-.884.37-.224.254-.268.624-.19.96.078.335.246.625.447.902.224.3.47.579.739.845h-.012l-.425.034c-.581.07-.961.73-.928 1.319.034.59.392 1.134.794 1.562.548.59 1.197 1.064 1.846 1.527 1.61 1.145 3.22 2.278 4.742 3.54.537.45 1.074.913 1.678 1.283.604.359 1.275.625 1.968.613.94.116 2.148-.104 3.177-.682A5.162 5.162 0 0 0 20.5 18.65c.257-.647.369-1.353.436-2.059.09-.833.1-1.677-.067-2.487z'
      fill='#FFC933'
    />
    <path
      d='M7.953 6.585c.521.28 1.019.572 1.494.901.498.329.961.682 1.436 1.023.44.365.915.719 1.343 1.108.44.378.869.792 1.274 1.218.012 0 .012.012 0 .024 0 .013-.012.013-.023 0A559.687 559.687 0 0 1 7.94 6.61c-.011 0-.011-.012 0-.024h.012zM5.979 9.187c.485.25.959.536 1.422.834.464.3.906.648 1.36.96.431.348.874.684 1.295 1.057.432.361.843.76 1.233 1.183.01 0 .01.013 0 .025 0 .013-.01.013-.021 0a564.42 564.42 0 0 1-5.3-4.034c-.01 0-.01-.012 0-.025h.011zM5.385 12.088c.458.211.893.446 1.317.715.424.258.826.55 1.25.82.39.305.803.598 1.183.926.39.317.758.668 1.115 1.031.011 0 .011.012 0 .024 0 .011-.01.011-.022 0a340.575 340.575 0 0 1-4.865-3.492c-.011 0-.011-.012 0-.024 0-.011.011-.011.022 0z'
      fill='#C2350A'
    />
    <path
      d='m8.996 4.132-.62-1.863a.072.072 0 0 0-.117-.027L7.02 3.484c-.034.034-.02.095.027.116l1.861.62c.055.014.109-.033.089-.088zM11.291 3.971l1.26-1.559c.029-.04.007-.1-.044-.108l-1.86-.303c-.051-.007-.095.04-.08.087l.593 1.863c.022.054.095.067.131.02zM13.103 5.001l1.87-.008c.046 0 .079-.064.059-.112l-.754-1.837c-.019-.056-.084-.056-.11-.008l-1.117 1.845c-.033.04 0 .12.052.12z'
      fill='#4781F5'
    />
  </svg>
);

export default SVG;
