import React from 'react';
import { Modal } from 'react-bootstrap';
import OsBtn from 'app/components/OsBtn';

import WorkspaceInviteForm from 'app/components/WorkspaceInviteModal/WorkspaceInviteForm';
import useWorkspaceInvite from 'app/components/WorkspaceInviteModal/useWorkspaceInvite';

import 'app/components/WorkspaceInviteModal/workspace-invite-modal.scss';

const WorkspaceInviteModal = ({ show, hideModal }) => {
  const { onSubmit, isSubmitting, setIsSubmitting } = useWorkspaceInvite({
    hideModal,
  });

  const closeModal = () => {
    setIsSubmitting(false);
    hideModal();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      dialogClassName={'workspace-invite-modal'}>
      <Modal.Header className={'p-0'}>
        <div className='invite-header'>
          <h4 className={'heading'}>Invite Team Member</h4>
        </div>

        <OsBtn
          name='BtnIcon'
          extraClass='no-text os-header-btn web-view-btn'
          icon='close'
          label='Close share modal'
          onClick={closeModal}
        />
      </Modal.Header>
      <Modal.Body className={'p-0'}>
        <div className='invite-body'>
          <WorkspaceInviteForm
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WorkspaceInviteModal;
