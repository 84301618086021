import React from 'react';
import OsBtn from 'app/components/OsBtn';
import useInbox from 'app/hooks/useInbox';

const MarkRead = ({ entity, entityType, entityId, type, queryType }) => {
  const { markAsRead } = useInbox();

  const handleReadClick = () => {
    let data = { entity, entityId, entityType, type, queryType };
    markAsRead(data);
  };

  return (
    <OsBtn
      key='edit'
      name='BtnIcon'
      text='Mark As Read'
      extraClass='list-button'
      icon='doubleTick'
      onClick={handleReadClick}
    />
  );
};

export default MarkRead;
