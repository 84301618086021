import {
  SET_ENTITY_IS_LOADING,
  SET_LECTURE_ENTITY,
  SET_RIGHT_SECTION_VISIBILITY,
  SET_CLV_MOVEMENT_DIRECTION,
} from 'app/actions/types';

export default function (
  state = {
    entity: null,
    loading: false,
    rightSectionVisible: true,
    activeContentTabId: null,
    metaInfo: {},
  },
  action,
) {
  switch (action.type) {
    case SET_LECTURE_ENTITY:
      return {
        entity: action.entity,
        loading: false,
        rightSectionVisible: action.rightSectionVisible,
        activeContentTabId: action.activeContentTabId,
        metaInfo: action.metaInfo,
      };
    case SET_ENTITY_IS_LOADING:
      return { ...state, loading: true, rightSectionVisible: true };
    case SET_RIGHT_SECTION_VISIBILITY:
      return {
        ...state,
        rightSectionVisible: action.rightSectionVisible,
        metaInfo: action.metaInfo,
      };
    case SET_CLV_MOVEMENT_DIRECTION:
      return { ...state, moveDirection: action.moveDirection };
    default:
      return state;
  }
}
