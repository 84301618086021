import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Icon from 'app/components/Svg';
import OsGrid from 'app/components/OsGrid';
import OsBtn from 'app/components/OsBtn';

import { entityUrl } from 'app/utils/entitiesHelper';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { translate } from 'app/actions/flashMessage';

class WorkspaceInformationWidget extends PureComponent {
  onLearnMoreClick = () => {
    this.props.navigate(`${entityUrl(this.props.workspace.data)}information`);
  };

  render() {
    if (isWorkspaceView()) {
      return (
        <OsGrid identifier='DetailRightSidebar:InformationBlock'>
          <div className='sidebar-container no-card-effect information-widget-section'>
            <div className='widget-title'>Workspace details</div>
            <div className='workspace-blocks'>
              <div className='img-block'>
                <Icon name='workspace' />
              </div>
              <div className='workspace-blocks-info'>
                {translate('INFORMATION_TEXT')}.
                <OsBtn
                  name='BtnSecondary'
                  extraClass='with-border mt-3'
                  text='Learn More'
                  onClick={this.onLearnMoreClick}
                />
              </div>
            </div>
          </div>
        </OsGrid>
      );
    } else {
      return null;
    }
  }
}

WorkspaceInformationWidget = withRouter(WorkspaceInformationWidget);
WorkspaceInformationWidget = connect(
  ({ workspace }) => ({ workspace }),
  {},
)(WorkspaceInformationWidget);
export default WorkspaceInformationWidget;
