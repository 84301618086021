import { CLOSE_EDIT_IMAGE_MODAL, OPEN_EDIT_IMAGE_MODAL } from './types';

export function openEditImageModal(options) {
  return {
    type: OPEN_EDIT_IMAGE_MODAL,
    options,
  };
}

export function closeEditImageModal() {
  return { type: CLOSE_EDIT_IMAGE_MODAL };
}
