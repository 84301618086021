import { OPEN_PATIENT_MODAL, CLOSE_PATIENT_MODAL } from 'app/actions/types';

export default function patientModal(
  state = { open: false, careSpaceId: null, options: {} },
  action,
) {
  switch (action.type) {
    case OPEN_PATIENT_MODAL:
      return { open: true, careSpaceId: action.careSpaceId, ...action.options };
    case CLOSE_PATIENT_MODAL:
      return { open: false, careSpaceId: null, source: null };
    default:
      return state;
  }
}
