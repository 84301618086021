import React, { useState } from 'react';
import TemplateDataRow from 'app/components/Templates/TemplateDataRow';
import OsBtn from 'app/components/OsBtn';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import storeUpdater from 'app/services/StoreUpdater';
import { commentShareAssign } from 'app/actions/commentShare';
import { TemplateContext } from 'app/context/TemplateContext';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import { translate } from 'app/actions/flashMessage';

const GET_ALL_TEMPLATES = queryLoader('app/graphql/Template/GetTemplates.gql');
const HEADER_ROW_ATTRIBUTES = {
  care: ['name', 'status', 'latest_activity'],
  clinic: ['name', 'featured', 'status', 'latest_activity'],
};
const TEMPLATES_TYPES_INFO = {
  clinic: {
    heading: 'TEMPLATE_CLINIC_HEADING',
    message: 'TEMPLATE_CLINIC_MESSAGE',
  },
  care: {
    heading: 'TEMPLATE_CARE_HEADING',
    message: 'TEMPLATE_CARE_MESSAGE',
  },
};

const TemplatesView = ({ kind }) => {
  const [newTemplate, setNewTemplate] = useState(null);
  const dispatch = useDispatch();
  const [sortFilter, setSortFilter] = useState({
    name: null,
    featured: null,
    status: null,
    latest_activity: null,
  });
  const currentUser = useSelector((state) => state.currentUser);

  const sortApplied = () => {
    // return the key from the sortFilter whose value is not null and if its true then postfix the key with _asc else _desc
    const key = Object.keys(sortFilter).find((key) => sortFilter[key] !== null);
    let sortKey = key;
    switch (key) {
      case 'name':
        sortKey = 'title';
        break;
      case 'status':
        sortKey = 'enabled';
        break;
      case 'latest_activity':
        sortKey = 'updated_at';
        break;
      default:
        break;
    }
    if (key) {
      return sortKey + (sortFilter[key] ? '_asc' : '_desc');
    }
    return null;
  };

  const { data, variables: allTemplateVariables } = useQuery(
    GET_ALL_TEMPLATES,
    {
      variables: {
        page: 0,
        per_page: 100,
        type: 'AllTemplates',
        sortQuery: sortApplied(),
        id_query: kind,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const addNewTemplate = async () => {
    const emptyTemplate = {
      id: '-1',
      title: '',
      enabled: false,
      featured: false,
      content: '',
      updated_by: {
        id: currentUser.graph.id,
        name: '---',
      },
    };
    if (!newTemplate) {
      commentShareAssign({
        files: [],
        objects: [],
      })(dispatch);
      setNewTemplate(emptyTemplate);
    }
  };

  const handleNewTemplate = async (record) => {
    setNewTemplate(null);
    // Updating records in cache.
    storeUpdater.addDataInRecords(record, allTemplateVariables, {
      query: GET_ALL_TEMPLATES,
    });
  };

  const handleSorting = (attribute) => {
    setSortFilter((prevState) => {
      const defaultState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {});
      const currentFilterValue = prevState[attribute];
      if (currentFilterValue === null) {
        return {
          ...defaultState,
          [attribute]: true,
        };
      } else {
        // set all the keys of prevState to null
        return {
          ...prevState,
          [attribute]: !currentFilterValue,
        };
      }
    });
  };

  return (
    <TemplateContext.Provider value={{ allTemplateVariables }}>
      <div className='container configuration-view mt-4 p-0'>
        <SetPageTitle
          source={'worksapce_settings'}
          interpolations={'Templates'}
        />

        <div className='row-fluid'>
          <div className='col-14 template-section basic-info-section'>
            <div>
              <div className={'template-header-section'}>
                <h3>{translate(TEMPLATES_TYPES_INFO[kind].heading)}</h3>
                <OsBtn
                  type='button'
                  name='BtnPrimary'
                  extraClass='web-view-btn btn-save-info me-auto'
                  text={`New ${kind === 'clinic' ? 'Template' : 'Preset'}`}
                  onClick={addNewTemplate}
                  htmlTag='button'
                />
              </div>
              <div
                className='heading-description'
                dangerouslySetInnerHTML={{
                  __html: translate(TEMPLATES_TYPES_INFO[kind].message),
                }}
              />
            </div>
            {/* Header row */}
            <div className={'data-row-with-editor'}>
              <div className={`template-header-row`}>
                {HEADER_ROW_ATTRIBUTES[kind].map((item, index) => {
                  return (
                    <>
                      <div
                        style={{ display: 'flex' }}
                        key={index}
                        onClick={() => handleSorting(item)}
                        className={`template-header-${item}`}>
                        <div>{item}</div>
                        <div className='sorting-block'>
                          {sortFilter[item] !== false && (
                            <OrthoIcon
                              name={`chevron-caret-up`}
                              defaultClass='ms-1'
                            />
                          )}
                          {sortFilter[item] !== true && (
                            <OrthoIcon
                              name={`chevron-caret-down`}
                              defaultClass='ms-1'
                            />
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {/*<div>Follow Up</div>*/}
                {/* Used for action but has no title */}
                <div></div>
              </div>
              {/*  Data Row */}
              <div>
                {newTemplate && (
                  <TemplateDataRow
                    template={{ ...newTemplate, kind }}
                    setNewTemplate={handleNewTemplate}
                  />
                )}
                {data?.records?.results.map((template) => (
                  <TemplateDataRow
                    key={template.id}
                    template={{ ...template, kind }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplateContext.Provider>
  );
};

export default TemplatesView;
