import useLabelSelector from 'app/components/Partners/LabelSelector/useLabelSelector';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import Avatar from 'app/components/Shared/Avatar';
import React from 'react';

const LabelSelector = (props) => {
  const {
    label,
    handleLabelValue,
    labelOptions,
    handleSearchText,
    patientsLoading,
    handleNewLabel,
  } = useLabelSelector(props);

  return (
    <div className={`label-selector`}>
      <BootstrapDropdown
        async={false}
        title={
          label ? (
            <>
              <div className={'person-option-container'}>
                {label.__typename === 'Person' && (
                  <Avatar
                    className='avatar avatar-40 partner-space-listing-avatar me-1'
                    name={label.large_avatar}
                    src={label.large_avatar}
                  />
                )}
                <span className='result-text-data'>{label.label}</span>
              </div>
            </>
          ) : (
            'PICK A LABEL OR PATIENT'
          )
        }
        labelBackground={label?.color}
        items={labelOptions}
        allowToCreateLabel={true}
        itemKey={'label'}
        handleNewLabel={handleNewLabel}
        onClick={handleLabelValue}
        inputFieldRequired={true}
        inputFieldProps={{
          placeholder: 'Search Label or Patient',
        }}
        optionsLoading={patientsLoading}
        onChange={(event, textValue) => handleSearchText(textValue)}
        subLabelOptionsRequired={true}
      />
    </div>
  );
};

export default LabelSelector;
