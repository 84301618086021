const allSidebarCareNotifications = (
  state = { isNotificationAvailable: false },
  action,
) => {
  switch (action.type) {
    case 'NOTIFICATION_AVAILABLE':
      return {
        ...state,
        isNotificationAvailable: action.payload.isNotificationAvailable,
      };
    case 'NOTIFICATION_UNAVAILABLE':
      return {
        ...state,
        isNotificationAvailable: action.payload.isNotificationAvailable,
      };
    default:
      return state;
  }
};

export default allSidebarCareNotifications;
