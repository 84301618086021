import React, { PureComponent } from 'react';

import InterpolationTextParser from 'app/services/InterpolationTextParser';

class Tag extends PureComponent {
  render() {
    return (
      <span
        className={`badge badge-${this.props.color} ${this.props.className}`}>
        {InterpolationTextParser.parseText(this.props.text, {
          space: this.props.space,
        })}
      </span>
    );
  }
}

export default Tag;
