import dayjs from 'dayjs';
import { isBeforeTodayDate, isCurrentDay } from 'app/utils/timeHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TaskContext } from 'app/context/TaskContext';

const KanbanTaskHeading = ({ isWeek, tasks }) => {
  const [columnCollapse, setColumnCollapse] = useState(false);
  const { updateCache, updateComponent, user } = useContext(TaskContext);

  const dispatch = useDispatch();
  const updateColumnHeight = () => {
    setColumnCollapse((prevState) => !prevState);
  };

  const addTask = (date) => {
    dispatch({
      type: 'OPEN_TASK_MODAL',
      options: {
        updateCache: updateCache,
        updateComponent: updateComponent,
        dueDate: dayjs.unix(date).format('MM/DD/YYYY'),
        userId: user?.id,
      },
    });
  };

  if (!isWeek) {
    let isAddIconVisible =
      isCurrentDay(tasks[0].date) || isCurrentDay(tasks[1].date);
    let isPastDate =
      isBeforeTodayDate(tasks[0].date) || isBeforeTodayDate(tasks[1].date);
    return (
      <div className={`kanban-column-heading`}>
        <div
          className={`kanban-add-icon ${
            isPastDate ? 'kanban-past-task' : 'kanban-future-task'
          } ${isAddIconVisible && 'kanban-same-day'}`}>
          <OrthoIcon
            name={'kanban-add-task-icon'}
            defaultClass={'ms-0'}
            onClick={() => addTask(tasks[0]?.date)}
          />
        </div>
        <div className={'kanban-board-heading'}>
          <span className={'primary'}>
            {tasks[0].key.slice(0, 3)}/{tasks[1].key.slice(0, 3)}
          </span>
          <br />
          <span className={'secondary'}>
            {dayjs.unix(tasks[0].date).format('D')},
            {dayjs.unix(tasks[1].date).format('D')}
          </span>
        </div>
        <div
          className={`${isAddIconVisible && 'kanban-same-day'} ${
            columnCollapse ? 'kanban-column-collapse' : 'kanban-column-expand'
          }`}>
          {columnCollapse ? (
            <OrthoIcon
              name='chevron-right'
              defaultClass='ms-1'
              onClick={updateColumnHeight}
            />
          ) : (
            <OrthoIcon
              name='chevron-down'
              defaultClass='ms-1'
              onClick={updateColumnHeight}
            />
          )}
        </div>
      </div>
    );
  } else {
    let isAddIconVisible = isCurrentDay(tasks?.date);
    let isPastDate = isBeforeTodayDate(tasks?.date);
    return (
      <div className={'kanban-column-heading'}>
        <div
          className={`kanban-add-icon ${
            isAddIconVisible && 'kanban-same-day'
          } ${isPastDate ? 'kanban-past-task' : 'kanban-future-task'}`}>
          <OrthoIcon
            name={'kanban-add-task-icon'}
            defaultClass={'ms-0'}
            onClick={() => addTask(tasks?.date)}
          />
        </div>
        <div className={'kanban-board-heading'}>
          <span className={'primary'}>{tasks?.key.slice(0, 3)}</span>
          <br />
          <span className={'secondary'}>
            {dayjs.unix(tasks?.date).format('D')}
          </span>
          <OrthoIcon name={'ifill-os-teams'} />
        </div>
        <div
          className={`${isAddIconVisible && 'kanban-same-day'} ${
            columnCollapse ? 'kanban-column-collapse' : 'kanban-column-expand'
          }`}>
          {columnCollapse ? (
            <OrthoIcon
              name='chevron-right'
              defaultClass='ms-1'
              onClick={updateColumnHeight}
            />
          ) : (
            <OrthoIcon
              name='chevron-down'
              defaultClass='ms-1'
              onClick={updateColumnHeight}
            />
          )}
        </div>
      </div>
    );
  }
};

export default KanbanTaskHeading;
