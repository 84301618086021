import { OPEN_UPGRADE_MODAL, CLOSE_UPGRADE_MODAL } from 'app/actions/types';

export default function upgrade(
  state = {
    open: false,
  },
  action,
) {
  switch (action.type) {
    case OPEN_UPGRADE_MODAL:
      let meta = action.meta || {};
      meta.href = window.location.href.replace(window.location.origin, '');
      return { open: true, source: action.source, meta: meta };
    case CLOSE_UPGRADE_MODAL:
      return {
        open: false,
        source: action.reset ? '' : state.source,
        meta: action.reset ? {} : state.meta,
      };
    default:
      return state;
  }
}
