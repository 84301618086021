import { EditorContent, useEditor } from '@tiptap/react';
import MenuBar from 'app/components/TipTapEditor/MenuBar';
import StarterKit from '@tiptap/starter-kit';
import { Underline } from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import Dropzone from 'react-dropzone';
import OsTiptapOptions from 'app/components/OsField/OsTiptapOptions';
import { useDispatch } from 'react-redux';
import { commentShareAssign, commentShareOpen } from 'app/actions/commentShare';
import { Link } from '@tiptap/extension-link';
import mentionSuggestion from 'app/components/TipTapEditor/mentionSuggestion';
import CustomMention from 'app/components/TipTapEditor/mention';
import { useSelector } from 'react-redux';
import hashtagSuggestion from 'app/components/TipTapEditor/hashtagSuggestion';
import NewHashtag from 'app/components/TipTapEditor/hashtag';

import { CustomSubmit } from 'app/components/TipTapEditor/CustomSubmit';
import patientSuggestion from 'app/components/Extensions/Suggestions/PatientSuggestion';
import templateSuggestion from 'app/components/TipTapEditor/templateSuggestion';
import Patients from 'app/components/Extensions/EditorNode/Patients';
import CustomTemplate from 'app/components/TipTapEditor/TemplateExtension';
import TokenMention from 'app/components/Extensions/EditorNode/TokenMention';
import tokenSuggestion from 'app/components/Extensions/Suggestions/TokenSuggestion';
import CustomShortcutMenu from 'app/components/TipTapEditor/CustomShortcutMenu';
import {
  BASE_SUGGESTION_OPTIONS,
  NODE_SUGGESTION_OPTIONS_LIST,
  PARTNER_SPACES_EDITOR_EXTENSION_SUGGESTION_OPTIONS,
} from 'app/components/TipTapEditor/constant';
import customShortcutMenuSuggestion from 'app/components/TipTapEditor/CustomShortcutMenuSuggestion';

const ESCAPE_ELEMENT_EVENT = ['tip-tap-editor-field'];

const customShortcutReducer = (state, action) => {
  switch (action.type) {
    case 'set_selected_option':
      return {
        ...state,
        selectionOption: action.option,
      };
    default:
      return state;
  }
};

const OsTiptapEditorField = forwardRef((props, ref) => {
  const {
    rowId,
    placeholder,
    autofocus,
    updateContent,
    initialContent,
    textType,
    isSubmitClicked,
    dragAndDrop,
    isSubmitButtonRequired,
    isCancelButtonRequired,
    submitButtonProps,
    cancelButtonProps,
    extensions,
    onFocus,
    onBlur,
    onDrop,
    files,
    handleFiles,
    objects,
    handlePatient,
    handleTemplate,
    onKeyDown,
    handleObjects,
    manualDragAndDrop,
    isMenuBarRequired = true,
    entity = {},
    mentionGroups = [],
    generalUsersRequired = true,
    isInternalNote = false,
  } = props;

  useImperativeHandle(ref, () => {
    return {
      editorFunction: handleEditorFunctions,
      focusEditor,
    };
  });

  const outputContent = (editor) => {
    switch (textType) {
      case 'html':
        updateContent(editor.getHTML());
        return;
      case 'json':
        updateContent(editor.getJSON());
        return;
      default:
        updateContent(editor.getText());
        return;
    }
  };
  const [isDragOver, setIsDragOver] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const workspace = useSelector((state) => state.workspace);
  const source = useSelector((state) => state.source);
  const [customShortcutState, dispatchShortcut] = useReducer(
    customShortcutReducer,
    {
      selectionOption: null,
    },
  );
  const dispatch = useDispatch();

  const getExtensions = () => {
    const extension = [];
    extensions.forEach((item) => {
      switch (item) {
        case 'StarterKit':
          extension.push(StarterKit);
          break;
        case 'Placeholder':
          extension.push(
            Placeholder.configure({
              placeholder: placeholder,
            }),
          );
          break;
        case 'CustomSubmit':
          extension.push(
            CustomSubmit.configure({
              onKeyDown: onKeyDown,
            }),
          );
          break;
        case 'Underline':
          extension.push(Underline.configure({}));
          break;
        case 'Link':
          extension.push(
            Link.configure({
              autolink: true,
              openOnClick: false,
            }),
          );
          break;
        case 'Template':
          extension.push(
            CustomTemplate.configure({
              HTMLAttributes: {
                className: 'template',
              },
              suggestion: templateSuggestion(),
            }),
          );
          break;
        case 'CustomMention':
          extension.push(
            CustomMention.configure({
              HTMLAttributes: {
                className: 'mention',
              },
              suggestion: mentionSuggestion(),
            }),
          );
          break;
        case 'NewHashtag':
          extension.push(
            NewHashtag.configure({
              HTMLAttributes: {
                className: 'hashtag',
              },
              renderLabel: ({ options, node }) => {
                return `#${node.attrs.label}`;
              },
              suggestion: hashtagSuggestion(),
            }),
          );
          break;
        case 'Patients':
          extension.push(
            Patients.configure({
              renderLabel: ({ options, node }) => {
                return `+${node.attrs.label}`;
              },
              suggestion: patientSuggestion,
            }),
          );
          break;
        case 'CustomShortcutMenu':
          extension.push(
            CustomShortcutMenu.configure({
              HTMLAttributes: {},
              options: PARTNER_SPACES_EDITOR_EXTENSION_SUGGESTION_OPTIONS,
              suggestion: customShortcutMenuSuggestion,
            }),
          );
          break;
        case 'Token':
          extension.push(
            TokenMention.configure({
              HTMLAttributes: {
                'data-type': 'tokenNode',
              },
              renderLabel: ({ node }) => {
                let label = node.attrs.label.split('}').join('').toUpperCase();
                return `{{${label}}}`;
              },
              suggestion: tokenSuggestion,
            }),
          );
          break;
        default:
          break;
      }
    });
    return extension;
  };

  const editor = useEditor({
    extensions: extensions ? getExtensions() : [StarterKit],
    autofocus: autofocus,
    content: initialContent,
    onUpdate: ({ editor }) => {
      outputContent(editor);
    },
    onCreate: (props) => {
      // This is just a workaround and not a permanent solution.
      // Probably a better solution would be to have some priority attribute that works on prose mirror plugins as well.
      // For now, we don't have such thing in tip-tap, so we have to do this.
      const { state, view } = props.editor;

      if (state.plugins.length > 0) {
        const restOfPlugins = [];
        const suggestionPlugins = [];

        state.plugins.forEach((plugin) => {
          if (
            plugin.key.toString().includes('hashtag') ||
            plugin.key.toString().includes('patient')
          ) {
            suggestionPlugins.push(plugin);
          } else {
            restOfPlugins.push(plugin);
          }
        });
        view.updateState(
          state.reconfigure({
            plugins: [...suggestionPlugins, ...restOfPlugins],
          }),
        );
      }
    },
    onFocus: ({ editor, event }) => {
      if (onFocus) {
        onFocus(editor, event);
      }
    },
    onBlur: ({ editor, event }) => {
      if (onBlur) {
        onBlur(editor, event);
      }
    },
  });

  const handleDragEnter = (event) => {
    event.stopPropagation();
    setIsDragOver(true);
  };
  const handleDragLeave = (event) => {
    event.stopPropagation();
    setIsDragOver(false);
  };

  const assignObject = (type, acceptedFiles = []) => {
    commentShareOpen(type, 'Comment', acceptedFiles, [...objects, ...files], {
      restrictModalOpening: type === 'video',
      rowId,
    })(dispatch);
  };

  const removeAssignObject = (file) => {
    commentShareAssign({
      files: files.filter((f) => f.id !== file.id),
      objects: objects.filter((o) => o.id !== file.id),
    })(dispatch);
  };

  const handleDrop = (acceptedFiles) => {
    setIsDragOver(false);
    if (acceptedFiles.length > 0) {
      if (dragAndDrop) {
        assignObject('file', acceptedFiles);
      } else if (manualDragAndDrop) {
        // handle files and the view.
        onDrop(acceptedFiles);
      }
    }
  };

  const handleShortcutOptions = (editor, range, option) => {
    // props?.assigningObject('video');
    // editor.commands.insertContentAt(range.from, '@');
    // Check if the option includes in the BASE_SUGGESTION_OPTIONS as key
    if (NODE_SUGGESTION_OPTIONS_LIST.includes(option) || option === null) {
      dispatchShortcut({ type: 'set_selected_option', option: option });
    } else if (option === BASE_SUGGESTION_OPTIONS.RECORD_A_VIDEO) {
      assignObject('video');
    } else if (option === BASE_SUGGESTION_OPTIONS.UPLOAD_A_FILE) {
      assignObject('file');
    } else if (option === BASE_SUGGESTION_OPTIONS.ADD_EMOJI) {
      setShowEmoji(true);
    }
  };

  const handleEditorFunctions = (type, options) => {
    switch (type) {
      case 'template':
        const template = options.template;
        if (editor && template) {
          editor.commands.insertContent(template.content);
          focusEditor('end');
        }
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    // clear editor content
    if (editor && isSubmitClicked) {
      editor.commands.clearContent();
    }
  }, [editor, isSubmitClicked]);

  // Technique to set the initial Content of the editor.
  // Works fine for async and sync content.
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(initialContent);
    }
  }, [editor, initialContent]);

  const focusEditor = (pos = null) => {
    if (editor) editor.commands.focus(pos);
  };

  useEffect(() => {
    if (editor !== null) {
      editor.setOptions({
        editorProps: {
          entity: entity,
          mentionGroups: mentionGroups,
          handlePatient: handlePatient,
          handleTemplate: handleTemplate,
          handleShortcutOptions: handleShortcutOptions,
          customShortcut: customShortcutState,
          generalUsersRequired: generalUsersRequired,
          isWorkspace: source.isWorkspace,
          isInternalNote: isInternalNote,
          workspaceId: workspace?.data?.id,
        },
      });
    }
  }, [editor, mentionGroups]);

  return (
    <Dropzone
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      noClick={true}
      onDragLeave={handleDragLeave}>
      {({ getRootProps, getInputProps }) => {
        return (
          <>
            <div
              {...getRootProps()}
              onClick={(e) => {
                const element = e.target;
                let elementClassname = element.className.split(' ');
                if (ESCAPE_ELEMENT_EVENT.includes(elementClassname[0])) {
                  e.stopPropagation();
                  focusEditor();
                }
              }}>
              <input {...getInputProps()} />
              {props.errorMessage && props.error.tiptapField && (
                <p className={'tip-tap-editor-field-error-message'}>
                  {props.errorMessage}
                </p>
              )}
              <div
                className={`tip-tap-editor-field ${
                  isDragOver && 'drag-overlay'
                }`}>
                {isMenuBarRequired && (
                  <MenuBar
                    editor={editor}
                    showEmoji={showEmoji}
                    setShowEmoji={setShowEmoji}
                  />
                )}
                <EditorContent editor={editor} />
                {props.children}
              </div>
            </div>
            {dragAndDrop && (
              <OsTiptapOptions
                rowId={rowId}
                files={files}
                isSubmitButtonRequired={isSubmitButtonRequired}
                isCancelButtonRequired={isCancelButtonRequired}
                cancelButtonProps={cancelButtonProps}
                submitButtonProps={submitButtonProps}
                handleFiles={handleFiles}
                objects={objects}
                handleObjects={handleObjects}
                assignObject={assignObject}
                removeAssignObject={removeAssignObject}
              />
            )}
          </>
        );
      }}
    </Dropzone>
  );
});
export default OsTiptapEditorField;
