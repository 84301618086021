import React, { Component } from 'react';
import { connect } from 'react-redux';

import { humanizeCount } from 'app/utils/textFormatting';
import { viewObject } from 'app/actions/viewObject';
import OsBtn from 'app/components/OsBtn';

import EventTracker from 'app/services/EventTracker';

class View extends Component {
  componentDidMount() {
    if (!this.props.viewRequestNotRequired) this.viewObject(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.obj.id.toString() !== nextProps.obj.id.toString() ||
      this.props.obj.__typename !== nextProps.obj.__typename
    ) {
      this.viewObject(nextProps);
    }
  }

  isOfTypeUserAndCurrentUser(receivedProps) {
    return (
      receivedProps.obj?.__typename === 'User' &&
      receivedProps.obj.id === this.props.currentUser.graph.id
    );
  }

  viewObject = (receivedProps) => {
    if (!this.isOfTypeUserAndCurrentUser(receivedProps)) {
      this.props.currentUser.graph && this.props.viewObject(receivedProps);
    } else {
      EventTracker.trackForEntity('show', this.props.obj);
    }
  };

  render() {
    return (
      <OsBtn
        name='BtnIcon'
        extraClass={
          'px-8 pointer-none osbtn-6 ' + (this.props.extraClass || '')
        }
        icon='view'
        text={humanizeCount(this.props.obj?.view_count || 0)}
        associatedEntity={this.props.obj}
      />
    );
  }
}

View = connect(({ currentUser }) => ({ currentUser }), { viewObject })(View);

export default View;
