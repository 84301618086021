import {
  CLOSE_ONBOARDING_VERIFICATION_MODALS,
  OPEN_ONBOARDING_VERIFICATION_MODALS,
  OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
  RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN,
  SET_ONBOARDING_TIP_REF,
  SET_ONBOARDING_TIP_VISIBILITY,
  UNSET_ONBOARDING_TIP,
} from 'app/actions/types';

export default function onboardingTips(
  state = {
    tooltipRef: null,
    visible: false,
    openVerificationModals: false,
    hideCloseOptions: false,
  },
  action,
) {
  switch (action.type) {
    case RESET_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN:
      return { ...state, screen: null };
    case SET_ONBOARDING_TIP_REF:
      return { ...state, tooltipRef: action.tooltipRef };
    case SET_ONBOARDING_TIP_VISIBILITY:
      return { ...state, visible: action.visible };
    case UNSET_ONBOARDING_TIP:
      return { tooltipRef: null, visible: false };
    case OPEN_ONBOARDING_VERIFICATION_MODALS:
      return {
        ...state,
        openVerificationModals: action.openVerificationModals,
        hideCloseOptions: action.hideCloseOptions,
      };
    case OPEN_ONBOARDING_VERIFICATION_MODALS_WITH_SCREEN:
      return { ...state, openVerificationModals: true, screen: action.screen };
    case CLOSE_ONBOARDING_VERIFICATION_MODALS:
      return { ...state, openVerificationModals: false, screen: null };
    default:
      return state;
  }
}
