import React from 'react';
import { connect } from 'react-redux';
import OpengraphReactComponent from 'opengraph-react';

import AbstractCard from './AbstractCard';
import AdditionalLinkCard from './AdditionalLinkCard';
import OsCards from './';

import { addProtocolIfNotPresent } from 'app/utils/urlHelper';
import { omit } from 'app/utils/osLodash';

class AdditionalLinkWrapper extends AbstractCard {
  renderContent() {
    if (this.props.obj.entity && this.props.obj.entity.__typename !== 'Null') {
      // will have to check for onCardClick required for addtion link *comment share modal;*
      // after fix remove cardClickRequired prop from everywhere.
      let props = this.props.cardClickRequired
        ? this.props
        : omit(this.props, 'onCardClick');
      return (
        <OsCards
          {...{
            ...props,
            obj: { link: this.props.obj.link, ...this.props.obj.entity },
          }}
          wrapperObject={this.props.obj}
        />
      );
    } else {
      let setting =
        this.props.systemConfig.configs['enable_open_graph_url_fetching'];
      if (setting && setting.value === 'true' && !this.props.obj.processed) {
        return this.renderOpenGraphCard();
      } else {
        return this.renderAdditionalLinkCard();
      }
    }
  }

  renderAdditionalLinkCard() {
    return <AdditionalLinkCard {...this.props} />;
  }

  renderOpenGraphCard() {
    return (
      <OpengraphReactComponent
        site={addProtocolIfNotPresent(this.props.obj.url)}
        appId={process.env.REACT_APP_OPEN_GRAPH_API_KEY}
        loader={
          <span className='loader'>{this.renderAdditionalLinkCard()}</span>
        }
        onlyFetch={true}>
        {this.renderAdditionalLinkCard()}
      </OpengraphReactComponent>
    );
  }

  render() {
    return this.renderContent();
  }
}

AdditionalLinkWrapper = connect(({ systemConfig }) => ({ systemConfig }))(
  AdditionalLinkWrapper,
);
export default AdditionalLinkWrapper;
