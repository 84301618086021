import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { login } from 'app/actions/authentication';

import { isGuestUser } from 'app/utils/userHelper';
import { openInfoModal } from 'app/actions/infoModal';
import { actionByGuestUser } from 'app/actions/guestUser';

const VERIFY_MAGIC_LINK_MUTATION = queryLoader(
  'app/graphql/mutations/VerifyMagicLinkMutation.gql',
);

class MagicLinkLogin extends Component {
  state = {
    requestInProgress: true,
  };

  getRedirectPath() {
    let { state = {} } = this.props.location;
    return state?.redirect_path || '/default';
  }

  componentDidMount() {
    this.setState({ requestInProgress: true });
    if (isGuestUser()) {
      this.props
        .verifyMagicLinkMutation({
          variables: { token: this.props.match.params.token },
        })
        .then(({ data }) => {
          this.setState({ requestInProgress: false });
          if (data.verifyMagicLink.success) {
            this.props.actionByGuestUser('magic_link_redirect', {
              url: this.getRedirectPath(),
            });
            this.props.login({ token: data.verifyMagicLink.token });
          } else {
            this.props.openInfoModal('general', 'invalid_magic_link');
          }
        });
    }
  }

  render() {
    if (this.state.requestInProgress) {
      return <Loader type='ball-triangle-path' active />;
    } else {
      return <Navigate to='/' skip={true} />;
    }
  }
}

MagicLinkLogin = graphql(VERIFY_MAGIC_LINK_MUTATION, {
  name: 'verifyMagicLinkMutation',
})(MagicLinkLogin);
MagicLinkLogin = withRouter(MagicLinkLogin);
MagicLinkLogin = connect(null, { actionByGuestUser, login, openInfoModal })(
  MagicLinkLogin,
);
export default MagicLinkLogin;
