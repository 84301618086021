import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import InfiniteScroll from 'react-infinite-scroller';
import { loader as queryLoader } from 'graphql.macro';
import { cardSize } from 'app/utils/osCardHelper';

import { setNavSearchTotal } from 'app/actions/navSearch';
import RenderLoadingCards from 'app/components/Cards/RenderLoadingCards';
import RenderCards from 'app/components/Cards/RenderCards';
import NoEntitiesFound from 'app/components/Shared/NoEntitiesFound';
import OsBtn from 'app/components/OsBtn';

import { floor, isUndefined, uniqBy } from 'app/utils/osLodash';

const COLLECTION_QUERY = queryLoader('app/graphql/Collection.gql');

// Main object that all the index and search results view use to render objects
class CollectionView extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.setCollectionTotal();
  }

  setCollectionTotal() {
    if (
      this.props.setCollectionTotal &&
      this.props.collection &&
      this.props.collection.total
    ) {
      this.props.setCollectionTotal(this.props.collection.total);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.setSearchTotalNotRequired &&
      nextProps.collection &&
      nextProps.collection.total !== this.props.navSearch.total
    ) {
      this.props.setNavSearchTotal(nextProps.collection.total);
    }

    if (
      this.props.setCollectionTotal &&
      nextProps.collection &&
      nextProps.collection.total !==
        (this.props.collection && this.props.collection.total)
    ) {
      if (this.props.objectsNotToBeListed) {
        let collectionCount =
          nextProps.collection.total -
          (nextProps.collection.results.length -
            this.filterResults(nextProps).length);
        this.props.setCollectionTotal(collectionCount);
      } else {
        this.props.setCollectionTotal(nextProps.collection.total);
      }
    }
  }

  componentWillUnmount() {
    this.props.setNavSearchTotal(0);
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    var previousResultsAppended;
    if (prevProps.collection) {
      previousResultsAppended =
        this.props.collection &&
        prevProps.collection.results.length !==
          this.props.collection.results.length;
      if (previousResultsAppended && this.props.updateTags) {
        this.props.updateTags(this.props.collection.results);
      }
    } else if (this.props.collection && this.props.updateTags) {
      this.props.updateTags(this.props.collection.results);
    }

    if (this.props.hasMore && !this.props.loading) {
      // Wait for the DOM to render and then check scroll possible to avoid extra query.
      setTimeout(() => {
        this.mounted && this.isScrollNotPossible() && this.loadNextPage();
      }, 1000);
    }
  }

  currentPage() {
    if (this.props.collection) {
      let {
        collection: { results },
        per_page,
      } = this.props;
      return floor(results.length / per_page);
    } else {
      return -1;
    }
  }

  loadNextPage = () => {
    this.loadMoreEntries(this.currentPage() + 1);
  };

  isScrollNotPossible() {
    if (this.props.windowNotRequired) {
      return (
        this.props.parentContainer &&
        this.containerRef &&
        this.props.parentContainer.offsetHeight > 0 &&
        this.props.parentContainer.offsetHeight >=
          this.containerRef.scrollComponent.offsetHeight
      );
    } else {
      return window.innerHeight >= document.body.scrollHeight;
    }
  }

  fetchCollectionType() {
    switch (this.props.index_for || this.props.types[0]) {
      case 'tools':
      case 'tool':
        return 'Tool';
      case 'users':
      case 'user':
        return 'User';
      case 'companies':
      case 'company':
        return 'Company';
      default:
        return 'Case';
    }
  }

  loadingCards() {
    return <RenderLoadingCards {...this.getLoadingCardsProps()} />;
  }

  displayNoResultFound() {
    if (this.props.collection && this.props.collection.results)
      return (
        <div className='no-result-block'>
          <p className='no-results-text'>No Result Found</p>
          {this.props.clearAllFilter && (
            <OsBtn
              name='BtnSecondary'
              text='Clear All Filters'
              onClick={this.props.clearAllFilter}
              extraClass='with-border'
            />
          )}
        </div>
      );
  }

  filterResults(props) {
    if (props.objectsNotToBeListed) {
      return props.collection.results.filter((result) => {
        return !props.objectsNotToBeListed.find(
          (object) =>
            object.__typename === result.__typename && object.id === result.id,
        );
      });
    } else {
      return props.collection;
    }
  }

  applyFiltersWith(results) {
    return results.filter(this.props.filterWith);
  }

  fourPerRow() {
    return this.props.size === 'small-min';
  }

  getCardClass() {
    return this.fourPerRow()
      ? 'col-12 col-md-6 col-lg-4 mb-4'
      : 'col-12 col-md-6 col-lg-4 mb-4 col-sm-6 ';
  }

  getLoadingCardsProps() {
    return {
      className: this.getCardClass(),
      cardGridIdentifier: this.props.cardGridIdentifier,
      initialLoad: this.props.collection && this.props.collection.results === 0,
      size: cardSize({
        size: this.props.size,
        width: this.props.device.width,
        mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
      }),
    };
  }

  loadMoreEntries = (page) => {
    // Incorrect page due to hasMore true while loading
    // NOTE:GP hasMore check added as plugin is sending one extra next page request even hasMore is false.
    if (this.props.hasMore && !this.props.loading)
      return this.props.loadMoreEntries(this.currentPage() + 1);
  };

  render() {
    let results = [],
      prioritizedCaseIds = [],
      featuredBoardIds = [],
      threshold = this.props.threshold || 500;

    if (this.props.device.mobile) threshold = threshold * 0.6;

    if (
      this.props.objectsNotToBeListed &&
      this.props.collection &&
      this.props.collection.results.length > 0
    ) {
      results = this.filterResults(this.props);
    } else if (this.props.collection) {
      results = this.props.collection.results;
    }

    if (this.props.collection) {
      prioritizedCaseIds = this.props.collection.prioritized_case_ids || [];
      featuredBoardIds = this.props.collection.featured_board_ids || [];
    }

    if (this.props.filterWith) {
      let resultsLengthWithoutFlilter = results.length;
      results = this.applyFiltersWith(results);

      if (
        this.props.filterCount &&
        resultsLengthWithoutFlilter !== results.length &&
        this.props.filterCount !== results.length
      )
        this.props.setCollectionTotal(results.length);
    }

    let displayNoResultFound =
      this.props.showNoResultFound &&
      !this.props.loading &&
      !results.length &&
      !this.props.hasMore;
    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={this.loadMoreEntries}
        hasMore={this.props.hasMore}
        initialLoad={false}
        loader={this.loadingCards()}
        threshold={threshold}
        key={`${this.props.filter}:${this.props.sort}:${this.props.types}`}
        useWindow={!this.props.windowNotRequired}
        ref={(el) => (this.containerRef = el)}>
        {displayNoResultFound && this.displayNoResultFound()}
        {!this.props.loading &&
          this.props.noEntitiesFoundVisible &&
          this.props.collection &&
          (this.props.selectionMode
            ? results.length === 0
            : this.props.collection.results.length === 0) && (
            <NoEntitiesFound
              type={!this.props.selectionMode && this.props.types[0]}
              filter={this.props.filter}
              user={this.props.user}
              actionNotRequired={this.props.selectionMode}
            />
          )}
        <RenderCards
          addNewLinkAvailable={this.props.addNewLinkAvailable}
          newLinkOptions={this.props.newLinkOptions}
          objects={results}
          prioritizedIds={prioritizedCaseIds}
          featuredIds={featuredBoardIds}
          orderRowWise={this.props.orderRowWise}
          onCardClick={this.props.onCardClick}
          size={this.props.size}
          notStoreClickedCards={this.props.notStoreClickedCards}
          goToPage={this.props.goToPage}
          featureNotAllowedModal={this.props.featureNotAllowedModal}
          caseSelectionPossible={this.props.caseSelectionPossible}
          toggleCaseSelection={this.props.toggleCaseSelection}
          caseSelectionActivated={this.props.caseSelectionActivated}
          selectionMode={this.props.selectionMode}
          multiSelectMode={this.props.multiSelectMode}
          cardGridIdentifier={this.props.cardGridIdentifier}
        />
      </InfiniteScroll>
    );
  }
}

CollectionView = compose(
  graphql(COLLECTION_QUERY, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        source: props.source,
        sort: props.sort,
        sortOrder: props.sortOrder,
        filter: props.filter,
        additional_filters: JSON.stringify(
          !!props.additional_filters ? props.additional_filters : {},
        ),
        types: props.types,
        by_user_id: props.by_user_id && String(props.by_user_id),
        page: 0,
        per_page: props.per_page,
        query: props.query,
        query_by: props.query_by,
        query_by2: props.query_by2,
        id_query2:
          props.id_query2 && props.id_query2.length
            ? String(props.id_query2)
            : props.id_query2,
        id_query:
          props.id_query && props.id_query.length
            ? String(props.id_query).split(',')
            : props.id_query,
        range_query: props.range_query || [],
        index_for: props.index_for,
        prioritizedCaseIdsRequired: false,
        featuredBoardIdsRequired:
          props.index_for === 'boards' && props.sort === 'featured',
        object_filter: props.object_filter,
      },
    }),
    props({ data: { loading, collection, fetchMore, ...rest } }) {
      return {
        loading,
        collection,
        // The items per page is hard coded at 12. If the returned result is not a multiple of 12 we are at the end
        hasMore:
          isUndefined(collection) ||
          collection.results.length < collection.total
            ? true
            : false,
        loadMoreEntries: (page) => {
          try {
            fetchMore({
              variables: {
                page: page,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                return {
                  collection: {
                    total: fetchMoreResult.collection.total,
                    results: uniqBy(
                      [
                        ...previousResult.collection.results,
                        ...fetchMoreResult.collection.results,
                      ],
                      (obj) => `${obj.__typename}:${obj.id}`,
                    ),
                    __typename: 'Collection',
                  },
                };
              },
            });
          } catch (e) {
            console.log(e);
          }
        },
      };
    },
  }),
)(CollectionView);

CollectionView = connect(
  ({ currentUser, navSearch, device }) => ({ currentUser, navSearch, device }),
  {
    setNavSearchTotal,
  },
)(CollectionView);

CollectionView.defaultProps = {
  per_page: 24,
  source: null,
};
export default CollectionView;
