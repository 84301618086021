import {
  SET_CURRENT_USER_SESSION_VALID,
  OPEN_PASSWORD_UPDATE_MODAL,
  CLOSE_PASSWORD_UPDATE_MODAL,
} from './types';

export function setCurrentUserSessionValidity(sessionValid) {
  return {
    type: SET_CURRENT_USER_SESSION_VALID,
    valid: sessionValid === 'true',
  };
}

export function openPasswordUpdateModal() {
  return {
    type: OPEN_PASSWORD_UPDATE_MODAL,
  };
}

export function closePasswordUpdateModal() {
  return {
    type: CLOSE_PASSWORD_UPDATE_MODAL,
  };
}
