import { isEmpty } from 'app/utils/osLodash';
import store from 'app/store';
import { CASE_DETAIL_FEAUTURE_IDENTIFIER } from 'app/components/FeatureFlagging/constants';
import { isCaseVideo } from 'app/components/CaseLeftSection/helpers';
import { isFeaturePermitted } from 'app/utils/userHelper';
import { groupBy } from 'app/utils/osLodash';

const TOOTH_HASH = {
  permanentUpper: [
    'UL8',
    'UL7',
    'UL6',
    'UL5',
    'UL4',
    'UL3',
    'UL2',
    'UL1',
    'UR1',
    'UR2',
    'UR3',
    'UR4',
    'UR5',
    'UR6',
    'UR7',
    'UR8',
  ],
  permanentLower: [
    'LL8',
    'LL7',
    'LL6',
    'LL5',
    'LL4',
    'LL3',
    'LL2',
    'LL1',
    'LR1',
    'LR2',
    'LR3',
    'LR4',
    'LR5',
    'LR6',
    'LR7',
    'LR8',
  ],
  primaryUpper: [
    'ULE',
    'ULD',
    'ULC',
    'ULB',
    'ULA',
    'URA',
    'URB',
    'URC',
    'URD',
    'URE',
  ],
  primaryLower: [
    'LLE',
    'LLD',
    'LLC',
    'LLB',
    'LLA',
    'LRA',
    'LRB',
    'LRC',
    'LRD',
    'LRE',
  ],
};

export function hasAppointmentInfo(appointment) {
  return !isEmpty(appointment);
}

export function haveImages(appointment) {
  return (
    hasAppointmentInfo(appointment) && (appointment.images || []).length > 0
  );
}

export function haveVideos(appointment) {
  return (
    hasAppointmentInfo(appointment) && (appointment.videos || []).length > 0
  );
}

export function isScrubberModeSelected() {
  return isCaseVideo(store.getState().caseDetailInfo.currentSlide);
}

export function isSBSViewTypeSelected() {
  return store.getState().caseDetailInfo.viewType === 'SBS';
}

export function areSameAppointmentsSelected() {
  let leftAppointment = store.getState().caseDetailInfo.selectedAppointment,
    rightAppointment =
      store.getState().caseDetailInfo.selectedRightSideAppointment;
  if (!isEmpty(leftAppointment) && !isEmpty(rightAppointment))
    return leftAppointment.id === rightAppointment.id;
}

function processTeethInformation(hash, key, hashKeys) {
  let allTeeth = [...TOOTH_HASH[key]],
    addedTeeth = [];
  addedTeeth = addedTeeth
    .concat(hash[hashKeys[0]] || [])
    .concat(hash[hashKeys[1]] || [])
    .map((t) => t.identifier);
  allTeeth = allTeeth.map((t) => (addedTeeth.includes(t) ? t : '@'));
  return allTeeth
    .join(',')
    .split(/@,*/)
    .filter((t) => t)
    .map((t) => t.split(',').filter((t) => t))
    .map((data) =>
      data.length > 1 ? `${data[0]}-${data[data.length - 1]}` : data[0],
    )
    .join(', ');
}

export function getModifiedAppliance(variantLog) {
  let applianceCategories;
  if (variantLog.__typename === 'VariantLog' && variantLog.actions.length > 0) {
    let individualTeeth = [],
      connectedTeeth = [],
      inBetweenTeeth = [];

    variantLog.actions.forEach((action) => {
      const { appliance_usage_type } = action;
      if (appliance_usage_type) {
        switch (appliance_usage_type.kind) {
          case 'individual':
            individualTeeth.push(...appliance_usage_type.teeth);
            break;
          case 'connected':
            connectedTeeth.push(appliance_usage_type.teeth);
            break;
          case 'in_between':
            inBetweenTeeth.push(appliance_usage_type.teeth);
            break;
          default:
        }
      }
    });
    const individualTeethHash = groupBy(
      individualTeeth,
      (w) => `${w.kind}_${w.quadrant}`,
    );

    applianceCategories = {
      individual: {
        teeth: individualTeeth,
        text: [
          processTeethInformation(individualTeethHash, 'permanentUpper', [
            'permanent_upper_left',
            'permanent_upper_right',
          ]),
          processTeethInformation(individualTeethHash, 'permanentLower', [
            'permanent_lower_left',
            'permanent_lower_right',
          ]),
          processTeethInformation(individualTeethHash, 'primaryUpper', [
            'primary_upper_left',
            'primary_upper_right',
          ]),
          processTeethInformation(individualTeethHash, 'primaryLower', [
            'primary_lower_left',
            'primary_lower_right',
          ]),
        ]
          .filter((t) => t)
          .join(', '),
      },
      inBetween: {
        teeth: inBetweenTeeth,
        text: inBetweenTeeth
          .map((a) => a.map((w) => w.identifier).join('/'))
          .join(', '),
      },
      connected: {
        teeth: connectedTeeth,
        text: connectedTeeth
          .map((a) => a.map((w) => w.identifier).join('+'))
          .join(', '),
      },
    };
  } else {
    applianceCategories = {};
  }

  return {
    ...variantLog,
    applianceCategories,
  };
}

export function getModifiedAppliances(appliances) {
  return appliances.map(getModifiedAppliance);
}

export function isPrivate(kase) {
  return kase.status === 'Private';
}

export function isNewCaseCard() {
  return isFeaturePermitted(CASE_DETAIL_FEAUTURE_IDENTIFIER);
}
