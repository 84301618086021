import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "app/components/Svg";
import OsBtn from "app/components/OsBtn";

const SetProfileSwitcherPin = () => {
  const navigate = useNavigate();
  const workspace = useSelector((state) => state.workspace)

  const redirectTo = () => {
    if (workspace.data.user_request.password_present) {
      navigate(`/${workspace.identifier}/profile/update_pin`);
    } else {
      navigate(`/${workspace.identifier}/profile/set_password`)
    }
  }

  const renderSetProfileSwitcherButton = () => {
    return <OsBtn name="BtnSecondary" extraClass="with-border" onClick={() => redirectTo()} text={workspace.data.user_request.password_present ? "Update PIN" : "Set PIN"} />
  }

  return (
    <>
      <div className='security-container'>
        {
          <div className='security-box security-password-box'>
            <Icon name="verification" />
            <div className="mb-4 text-left">Set up your PIN Login</div>
            <div>
              {renderSetProfileSwitcherButton()}
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default SetProfileSwitcherPin;
