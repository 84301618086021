import React, { Component } from 'react';
import OsGrid from 'app/components/OsGrid';
import { translate } from 'app/actions/flashMessage';
import Slider from 'react-slick';

import { canSpaceHaveDiscussions } from 'app/utils/spaceHelper';
import { possibleBroadcastMentionGroups } from 'app/utils/widgetHelper';

const SOURCE_TIP_MESSAGE = {
  teamDirectMessenger: [
    {
      id: 0,
      widget_description:
        'You can create a task from any direct message so you never forget to get something done.',
      display_name: '',
    },
    {
      id: 1,
      widget_description:
        'You can share files directly from your computer or from your folders in any of the direct conversations with your team.',
      display_name: '',
    },
  ],
  teamGroupMessenger: [
    {
      id: 0,
      widget_description:
        'You can create a task from any group message so you never forget to get something done.',
      display_name: '',
    },
    {
      id: 1,
      widget_description:
        'You can share files directly from your computer or from your folders in any of the group conversations with your team.',
      display_name: '',
    },
    {
      id: 2,
      widget_description:
        'You can use @all to mention everyone present in the group.',
      display_name: '',
    },
  ],
  unifiedConversations: [
    {
      id: 0,
      widget_description:
        'You can create a task from any direct message so you never forget to get something done.',
      display_name: '',
    },
    {
      id: 1,
      widget_description:
        'You can share files directly from your computer or from your folders in any of the direct conversations with your team.',
      display_name: '',
    },
  ],
  unifiedGroup: [
    {
      id: 0,
      widget_description:
        'You can create a task from any group message so you never forget to get something done.',
      display_name: '',
    },
    {
      id: 1,
      widget_description:
        'You can share files directly from your computer or from your folders in any of the group conversations with your team.',
      display_name: '',
    },
    {
      id: 2,
      widget_description:
        'You can use @all to mention everyone present in the group.',
      display_name: '',
    },
  ],
};

export default class TipOfTheDay extends Component {
  renderAllTips() {
    let mentionGroups = possibleBroadcastMentionGroups(
        this.props.widgetSourceObject?.mention_groups,
      ),
      settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'photo-display__picker ',
        swipeToSlide: true,
        draggable: true,
        swipe: true,
        pauseOnHover: true,
      };
    return (
      <Slider ref={(slider) => (this.slider = slider)} {...settings}>
        {mentionGroups.map(this.renderTip)}
      </Slider>
    );
  }

  renderTip = (tip) => {
    return (
      <div className='tip-day-content' key={tip.id}>
        <div className='tip-text'>
          {translate(
            tip.widget_description,
            { identifier: tip.display_name.trim() },
            true,
            { connector: '' },
          )}
        </div>
      </div>
    );
  };

  renderSourceTip = (tipMessages) => {
    const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'photo-display__picker ',
      swipeToSlide: true,
      draggable: true,
      swipe: true,
      pauseOnHover: true,
    };
    return (
      <Slider ref={(slider) => (this.slider = slider)} {...settings}>
        {tipMessages.map(this.renderTip)}
      </Slider>
    );
  };

  render() {
    const sourceHaveTipMessage = SOURCE_TIP_MESSAGE[this.props.source];
    if (
      canSpaceHaveDiscussions(this.props.widgetSourceObject) ||
      sourceHaveTipMessage
    ) {
      return (
        <OsGrid identifier='DetailRightSidebar:TipSideBarBlock'>
          <div className='sidebar-container tip-sidebar-container no-card-effect'>
            <div className='sidebar-widget'>
              <div className='widget-title'>
                {translate('TIP_OF_THE_DAY_TITLE')}
              </div>
              {sourceHaveTipMessage
                ? this.renderSourceTip(SOURCE_TIP_MESSAGE[this.props.source])
                : this.renderAllTips()}
            </div>
          </div>
        </OsGrid>
      );
    } else {
      return null;
    }
  }
}
