import React from 'react';
import store from 'app/store';
import { entityUrl } from 'app/utils/entitiesHelper';
import { some } from 'app/utils/osLodash';
import {
  getWorkspaceData,
  isWorkspaceView,
  isCareWorkspaceView,
} from 'app/utils/Workspace/generalHelper';

export function currentUser() {
  return store.getState().currentUser.graph || {};
}

export function isSpecialUser() {
  return +currentUser().id === 17;
}

export function currentUserEmail() {
  return currentUser().email;
}

export function myProfileTabUrl(tab) {
  return `${entityUrl(currentUser())}/${tab}`;
}

export function profileLink(user, text = 'profile') {
  return <a href={entityUrl(user)}>{text}</a>;
}

export function currentUserVerifiedOrthodontist() {
  return currentUser().role_type === 'doctor_verified';
}

export function currentUserVerifiedResident() {
  return currentUser().role_type === 'studnet_doctor_verified';
}

export function isFeaturePermitted(identifier, options = {}) {
  let { flaggedFeatures } = store.getState().systemConfig,
    featureObject =
      flaggedFeatures &&
      flaggedFeatures.find((feature) => feature.identifier === identifier);
  if (featureObject) {
    return isPermittedForAudiences(featureObject.audiences);
  } else {
    return options.default || false;
  }
}

export function isPermittedForAudiences(audiences) {
  const user = currentUser();
  return some(audiences, (audience) => {
    if (audience.target === 'admin') {
      return user.admin;
    } else if (audience.target === 'all_users') {
      return true;
    } else if (audience.target === 'general_users') {
      return user.is_general_user;
    } else if (audience.target === 'verified_orthodontists') {
      return currentUserVerifiedOrthodontist();
    } else if (audience.target === 'verified_residents') {
      return currentUserVerifiedResident();
    } else if (audience.target === 'custom_users') {
      return audience.custom_audience_ids.includes(user.id.toString());
    }
  });
}

export function isCurrentUser(user) {
  return +currentUser()?.id === +user?.id;
}

export function isGuestUser() {
  return !currentUser().id;
}

export function isCurrentUserSystemAdmin() {
  return currentUser().admin;
}

export function isCurrentUserProfileIncomplete() {
  return !currentUser().account_verified;
}

export function canAccessCases() {
  return true;
}

export function userVerificationDataReceived() {
  return !currentUser().next_verification_step;
}

export function getUnreadConversationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return workspace.unread_conversations_count || 0;
  } else {
    return user?.unread_conversations_count || 0;
  }
}

export function getUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return workspace.unread_notifications_count || 0;
  } else {
    return user?.unread_notifications_count || 0;
  }
}

export function getTotalUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return workspace.total_unread_notifications_count || 0;
  } else {
    return user?.total_unread_notifications_count || 0;
  }
}

export function getFolderUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return workspace.unread_folder_spaces_notifications_count || 0;
  } else {
    return user?.unread_folder_spaces_notifications_count || 0;
  }
}

export function getTaskUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return (
      workspace.unread_task_notifications_count +
        workspace.unread_task_mentions_count || 0
    );
  } else {
    return (
      user?.unread_task_notifications_count +
        user?.unread_task_mentions_count || 0
    );
  }
}

export function getTeamUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return (
      workspace.unread_team_spaces_notifications_count +
        workspace.unread_conversations_count || 0
    );
  } else {
    return (
      user?.unread_team_spaces_notifications_count +
        user?.unread_conversations_count || 0
    );
  }
}

export function getPatientUnreadNotificationCount(user) {
  if (isWorkspaceView() && user) {
    let workspace = getWorkspaceData() || {};
    return workspace.unread_care_notifications_count || 0;
  } else {
    return user?.unread_care_notifications_count || 0;
  }
}

export function isGeneralUser() {
  return currentUser().is_general_user;
}

export function isCareWithRecords(user) {
  return isCareWorkspaceView() && !!user.patient_profile;
}

export function getNotificationUrl() {
  return `${entityUrl(currentUser())}/notifications`;
}
