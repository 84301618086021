import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';

export default class GeneralModal extends Component {
  componentWillUnmount() {
    this.props.closeModal();
  }

  renderContent() {
    if (this.props.content) {
      return this.props.content;
    } else if (this.props.htmlContent) {
      return <p dangerouslySetInnerHTML={{ __html: this.props.htmlContent }} />;
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        className={this.props.className}
        onHide={this.props.closeModal}
        animation={false}
        backdropClassName='modal-backdrop-custom modal-backdrop-general'>
        <div className='modal-header'>
          {this.props.headingText && (
            <h3 className='modal-title'>{this.props.headingText}</h3>
          )}
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-general-modal  p-0'
            icon='close'
            label='Close modal'
            onClick={this.props.closeModal}
          />
        </div>
        <div className='modal-body'>
          {this.props.svgName && (
            <div className='svg-wrap text-center'>
              {' '}
              <Icon name={this.props.svgName} />
            </div>
          )}
          <p className={this.props.contentClassName || 'modal-text'}>
            {this.renderContent()}
          </p>
        </div>
        <div className='modal-footer'>
          <OsBtn
            name='BtnPrimary'
            text='Ok'
            extraClass='m-btn-80'
            onClick={this.props.closeModal}
          />
        </div>
      </Modal>
    );
  }
}
