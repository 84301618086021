import React from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';

import { iCompanyTypeSpace, isOpenSpace } from 'app/utils/spaceHelper';
import { entityUrl } from 'app/utils/entitiesHelper';

import { openInfoModal } from 'app/actions/infoModal';
import { flashError } from 'app/actions/flashMessage';

const INVITE_QUERY = queryLoader('app/graphql/JoinSpaceRequest.gql');

const withSpaceRestriction = (WrappedComponent) => {
  class HOC extends React.Component {
    openSubscriptionModalIfNotSubscribed = (space, callback = () => {}) => {
      if (false) {
        callback();
      } else {
        this.goToInfoTab(space);
      }
    };

    isSpaceForNonAccessingUser(space) {
      return (
        !(isOpenSpace(space) && iCompanyTypeSpace(space)) &&
        !space.is_author_or_collaborator
      );
    }

    openRestrictedModalIfNotAMember = (space, callback = () => {}) => {
      if (
        this.isSpaceForNonAccessingUser(space) &&
        space.member_request_status !== 'pending'
      ) {
        this.props.openInfoModal('space', 'group_non_member', {
          contentInterpolations: {
            entity_type: space.__typename.toLowerCase(),
          },
          onSuccess: this.sendRequestToJoin.bind(this, space),
        });
      } else if (
        this.isSpaceForNonAccessingUser(space) &&
        space.member_request_status === 'pending' &&
        !space.member_requested_by_author
      ) {
        this.props.openInfoModal('space', 'group_pending_request', {
          contentInterpolations: {
            entity_type: space.__typename.toLowerCase(),
          },
        });
      } else if (
        this.isSpaceForNonAccessingUser(space) &&
        space.member_request_status === 'pending' &&
        space.member_requested_by_author
      ) {
        this.props.openInfoModal(
          'space',
          'group_pending_request_requested_by_author',
          {
            contentInterpolations: {
              entity_type: space.__typename.toLowerCase(),
            },
          },
        );
      } else {
        callback();
      }
    };

    sendRequestToJoin = (space) => {
      let member = [`Member:${this.props.currentUser.graph.id}`];
      this.props
        .inviteQuery({
          variables: {
            spaceId: space.nice_id,
            userIds: member,
          },
        })
        .then(({ data }) => {
          if (!data.joinSpaceRequest.success) {
            this.props.flashError(data.joinSpaceRequest.error, false);
          }
        });
    };

    goToInfoTab = (space) => {
      let isOnInfoTab = this.props.match.params.filter === 'information';
      !isOnInfoTab && this.props.navigate(`${entityUrl(space)}/information`);
    };

    render() {
      return (
        <>
          <WrappedComponent
            openRestrictedModalIfNotAMember={
              this.openRestrictedModalIfNotAMember
            }
            openSubscriptionModalIfNotSubscribed={
              this.openSubscriptionModalIfNotSubscribed
            }
            {...this.props}
          />
        </>
      );
    }
  }

  HOC = graphql(INVITE_QUERY, { name: 'inviteQuery' })(HOC);
  HOC = withRouter(HOC);
  HOC = connect(
    ({ currentUser, device, systemConfig }) => ({
      currentUser,
      device,
      systemConfig,
    }),
    { openInfoModal, flashError },
  )(HOC);
  return HOC;
};

export default withSpaceRestriction;
