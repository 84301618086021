import React, { Component } from 'react';
import { connect } from 'react-redux';

import withAuthorize from 'app/components/HOC/withAuthorize';

import OsBtn from 'app/components/OsBtn';

class AddLinkCard extends Component {
  getCardClass() {
    return `col-12 col-md-6 col-lg-4 mb-4 col-sm-6 ${
      this.props.cardsCustomMarginRequired ? 'pe-0 space-new-col' : ''
    } ${this.props.options.small ? 'mt-4' : ''}`;
  }

  buttonText() {
    return this.props.options.linkType || this.props.device.mobileDevice
      ? 'Add New'
      : 'Add New Item';
  }

  onClick = () => {
    this.props.openUpgradeModalIfUnauthorized(
      this.props.options.onAddNewLinkClick,
    );
  };

  render() {
    let extraClass = this.props.options.small && 'new-item-160';
    return (
      <div className={this.getCardClass()}>
        <div className={`new-item-card ${extraClass}`}>
          <OsBtn
            name='BtnPrimary'
            disabled={!!this.props.options.disabled}
            text={this.props.options.text || this.buttonText()}
            onClick={this.onClick}
          />
        </div>
      </div>
    );
  }
}
AddLinkCard = connect(({ device }) => ({ device }), {})(AddLinkCard);
AddLinkCard = withAuthorize(AddLinkCard);
export default AddLinkCard;
