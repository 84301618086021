import React, { useContext, useState } from 'react';
import ClinicTeamWithUsersListing from 'app/components/Widgets/ClinicTeamWithUsersListing';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import WorkspaceInviteModal from 'app/components/WorkspaceInviteModal';

const PartnerSpaceWithMembers = (props) => {
  const { all_members } = useContext(WorkspaceContext);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleInviteModal = () => {
    setShowInviteModal((prevState) => !prevState);
  };
  return (
    <>
      <ClinicTeamWithUsersListing
        {...props}
        title='Invite Your Team'
        results={all_members}
        inviteModalButtonRequired={true}
        inviteModal={handleInviteModal}
      />
      <WorkspaceInviteModal
        show={showInviteModal}
        hideModal={() => setShowInviteModal(false)}
      />
    </>
  );
};

export default PartnerSpaceWithMembers;
