import React, { useEffect, useRef } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { createPortal } from 'react-dom';
import { predefinedTimes } from 'app/components/Comments/constant';
import { useSelector } from 'react-redux';

function ScheduleDropdown({ onSelect, closeModal, elementId }) {
  const dropdownRef = useRef(null);
  const clickListener = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))
      closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', clickListener);
    }, 100);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  return createPortal(
    <div className='schedule-dropdown-container' ref={dropdownRef}>
      {predefinedTimes.map((time) => {
        if (time.label !== 'Custom') {
          return (
            <div
              className='time-block'
              onClick={(e) => onSelect(e, time.value)}>
              <span>{time.label}</span>
            </div>
          );
        } else {
          return (
            <>
              <div
                className='time-block custom'
                onClick={(e) => onSelect(e, time.value)}>
                <span>
                  <OrthoIcon name='Calendar' dataHoverNotRequired={true} />
                  {time.label}
                </span>
              </div>
            </>
          );
        }
      })}
    </div>,
    document.getElementById(elementId),
  );
}

export default ScheduleDropdown;
