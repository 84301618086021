import immutableUpdate from 'immutability-helper';
import gql from 'graphql-tag';
import client from 'app/graphql/client';
import store from 'app/store';
import { loader as queryLoader } from 'graphql.macro';

import {
  ACTIVITY_FEEDS_BATCH_SIZE,
  CONVERSATION_MESSAGES_BATCH_SIZE,
  CONVERSATIONS_BATCH_SIZE,
  HIGHLIGHT_NEW_POST_TIME_LIMIT_IN_MILLISECONDS,
  MY_POSTS_PER_PAGE_WEB,
} from 'app/constants';
import { getEntityUpdateFragment } from 'app/utils/fragmentHelper';
import { currentTime } from 'app/utils/timeHelper';
import {
  SPACE_DETAIL_RECORDS_PER_PAGE,
  SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
} from 'app/components/BoardView/constants';
import {
  cloneDeep,
  has,
  indexOf,
  isArray,
  isNumber,
  map,
  omit,
  orderBy,
  pick,
  sortBy,
  uniqBy,
} from 'app/utils/osLodash';
import { arrayMove, moveDown, moveUp } from 'app/utils/arrayHelper';
import { resetNewPost, setNewPost } from 'app/actions/postForm';
import { setLectureEntity } from 'app/actions/lectureView';
import {
  setRightSideAppointment,
  setSelectedAppointment,
} from 'app/actions/caseDetailInfo';
import { getVariablesForTabAndSections } from 'app/utils/tabHelper';
import { CONVERSATIONS_MESSAGE_PER_PAGE_COUNT } from 'app/components/Widgets/AttachmentsInDiscussion';
import { isTaskCommentActivity } from 'app/utils/taskHelper';
import { PARTNERS_SPACE_LISTING } from 'app/components/Partners/usePartnersListing';

const CASE_QUERY = queryLoader('app/graphql/Case.gql');
const COMMENTS_LISTING = queryLoader('app/graphql/queries/CommentsListing.gql');
const COMMENTS_QUERY = queryLoader('app/graphql/queries/Comments.gql');
const SPACE_COMMENTS_QUERY = queryLoader(
  'app/graphql/queries/TeamSpaceComments.gql',
);
const COMMENT_SUMMARY = queryLoader(
  'app/graphql/fragments/comments/ParentSummary.gql',
);
const CONVERSATION_FRAGMENT = queryLoader(
  'app/graphql/fragments/ConversationSummary.gql',
);
const CONVERSATION_MESSAGE_FRAGMENT = queryLoader(
  'app/graphql/fragments/ConversationMessageSummary.gql',
);
const EMOJI_REACTION_DETAIL_FRAGMENT = queryLoader(
  'app/graphql/fragments/EmojiReactionDetailSummary.gql',
);
const EXPLORE_FEEDS_QUERY = queryLoader('app/graphql/queries/ExploreFeeds.gql');
const RECORDS_QUERY = queryLoader('app/graphql/Records.gql');
const USER_DETAIL_QUERY = queryLoader('app/graphql/queries/UserDetail.gql');
const RECORDS = queryLoader('app/graphql/Records.gql');
const SPACE_DETAILS_QUERY = queryLoader(
  'app/graphql/SpaceDetailCollection.gql',
);
const SPACE_DETAILS_PAGER_QUERY = queryLoader(
  'app/graphql/queries/Spaces/SpaceDetailsPager.gql',
);
const TAB_DETAILS_QUERY = queryLoader('app/graphql/queries/NavTabsDetails.gql');
const CASE_DETAILS_QUERY = queryLoader(
  'app/graphql/Care/Queries/Cases/Detail.gql',
);
const COMMENT_VIEWED_BY_SUMMARY = queryLoader(
  'app/graphql/fragments/CommentViewedBySummary.gql',
);
const TASKS_QUERY = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const RELATED_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');
const DM_ATTACHMENTS_QUERY = queryLoader(
  'app/graphql/queries/Conversations/ConversationSharedFiles.gql',
);
const ALL_TEMPLATES = queryLoader('app/graphql/Template/GetTemplates.gql');
const GET_ALL_DOMAINS = queryLoader(
  'app/graphql/Integration/GetAllClinicDomains.gql',
);
const COMMENTS_ACTIVITY_QUERY = queryLoader(
  'app/graphql/queries/Comments/ActivityListingPager.gql',
);
const WORKSPACE_ADDITIONAL_DETAILS_QUERY = queryLoader(
  'app/graphql/queries/Workspace/AdditionalDetails.gql',
);
export const PARTNER_COMMENTS_ACTIVITY_LISTING = queryLoader(
  'app/graphql/queries/Comments/PartnerActivityListing.gql',
);
const TASK_ACTIVITY_LISTING = queryLoader(
  'app/graphql/queries/Comments/ActivityListing.gql',
);

const TASK_FEED_LISTING = queryLoader(
  'app/graphql/queries/Task/TaskFeedListing.gql',
);
const TASK_INBOX_FEED_LISTING = queryLoader(
  'app/graphql/queries/Task/TaskInboxListing.gql',
);
const CURRENT_USER_QUERY = queryLoader('app/graphql/CurrentUser.gql');

const SPACE_SCHEDULE_MESSAGES_QUERY = queryLoader(
  'app/graphql/queries/Comments/ScheduledPosts.gql',
);
const RECORDS_PAGER_QUERY_MAPPER = {
  COMMENTS: COMMENTS_QUERY,
  SPACE_DETAILS: SPACE_DETAILS_PAGER_QUERY,
  SPACE_MESSAGES: SPACE_COMMENTS_QUERY,
  COMMENTS_ACTIVITY_LISTING: COMMENTS_ACTIVITY_QUERY,
  PARTNER_COMMENTS_ACTIVITY_LISTING: PARTNER_COMMENTS_ACTIVITY_LISTING,
};

const CONVERSATION_READ_FRAGMENT = gql`
  fragment ConversationReadSummary on Conversation {
    id
    unread
  }
`;

const UPDATE_NOTIFICATION_CARD_OBJECT_FRAGMENT = gql`
  fragment UpdateNotificationCardFragment on Notification {
    id
    card_objects {
      ... on PrivateObject {
        id
      }
    }
  }
`;

// const WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MAPPER = {
//   course_subscribed: 'active_subscribed_user_ids',
//   followed_by_current_user: 'followed_by_doctor_ids',
//   in_toolbox: 'toolbox_user_ids',
//   is_author_or_collaborator: 'author_or_collaborator_ids',
//   is_author_or_editor: 'author_or_editor_ids',
//   is_author_or_member: 'author_or_member_ids',
//   liked: 'liked_by_doctor_ids',
//   member_requested_by_author: 'author_requested_to_join_user_ids',
//   reported_by_current_user: 'reported_by_doctor_ids',
// }

// const WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MULTIPLE_KEYS_SINGLE_VALUE_HASH = {
//   accepted_connection_ids: { attributeName: 'connection_status', truthyValue: 'accepted', falsyValue: 'not-connected' },
//   accepted_space_member_ids: { attributeName: 'member_request_status', truthyValue: 'accepted', falsyValue: null },
//   pending_space_member_ids: { attributeName: 'member_request_status', truthyValue: 'pending', falsyValue: null },
//   request_received_user_ids: { attributeName: 'connection_status', truthyValue: 'request-received', falsyValue: 'not-connected' },
//   request_sent_user_ids: { attributeName: 'connection_status', truthyValue: 'request-sent', falsyValue: 'not-connected' }
// }

// const WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_USER_ID_AND_VALUE_MAPPER = {
// }

const QUERY_MAPPER = {
  CaseDetails: CASE_DETAILS_QUERY,
  Comment: COMMENTS_QUERY,
  BoardRecentDiscussions: COMMENTS_LISTING,
  CareSpaceFeed: EXPLORE_FEEDS_QUERY,
  DefaultFeed: EXPLORE_FEEDS_QUERY,
  SpaceLinks: SPACE_DETAILS_QUERY,
  SpaceSectionLinks: SPACE_DETAILS_QUERY,
  SubSpaces: SPACE_DETAILS_QUERY,
  TabSectionLinks: TAB_DETAILS_QUERY,
  UserNotes: USER_DETAIL_QUERY,
  CompletedTasks: TASKS_QUERY,
  ScheduledTasks: TASKS_QUERY,
  OverDueTasks: TASKS_QUERY,
  EntityRelatedTasks: RELATED_TASKS,
  AllTemplates: ALL_TEMPLATES,
  AllClinicDomains: GET_ALL_DOMAINS,
};

class StoreUpdater {
  updateCurrentUser(payload) {
    let variables = { token: store.getState().currentUser.token };
    this.wrapper('updateCurrentUserInStore', payload, variables);
  }

  addEmojiInEntity(data) {
    this.wrapper('addEmojiInStore', data);
  }

  addViewedByInComment(data) {
    this.wrapper('addViewedByInCommentInStore', data);
  }

  removeEmojiFromEntity(data) {
    this.wrapper('removeEmojiInStore', data);
  }

  markConversationAsRead(conversationId, meta = {}) {
    this.wrapper('markConversationAsReadInStore', conversationId, {}, meta);
  }

  updateNoteOnNoteable(noteable, meta = {}) {
    this.wrapper('updateNoteOnNoteableInStore', noteable, meta);
  }

  removeRecordsFromRecordPager(record, metaInfo = {}) {
    let variables = {
      afterId: null,
      aroundId: null,
      beforeId: metaInfo.beforeId,
      limit: metaInfo.limit,
      recordId: metaInfo.recordId,
      recordType: metaInfo.recordType,
      textQuery: null,
      sortQuery: metaInfo.sortQuery,
      type: metaInfo.type,
      withRecord: true,
    };
    this.wrapper('removeDataFromRecordsPager', record, variables, metaInfo);
  }

  addRecordInRecordPager(record, metaInfo) {
    this.wrapper('addDataInRecordPager', record, metaInfo);
  }

  addRecord(record, variables, meta = {}) {
    this.wrapper('addDataInRecords', record, variables, meta);
  }

  replaceRecord(record, variables, meta = {}) {
    this.wrapper('replaceRecordInRecords', record, variables, meta);
  }

  removeRecord(record, variables, meta = {}) {
    this.wrapper('removeDataFromRecords', record, variables, meta);
  }

  addFollowee(result) {
    this.wrapper('addUserFollowee', result);
  }

  removeFollowee(result) {
    this.wrapper('removeUserFollowee', result);
  }

  updateConnection(result) {
    this.wrapper('addUserConnection', result);
  }

  updateConversationMessage(conversationMessage, key = null) {
    this.wrapper('updateConversationMessageInStore', conversationMessage, key);
  }

  updateRecommendations(result, meta = {}) {
    this.wrapper('removeFromRecommendations', result, meta);
  }

  removeSuggestedMemberFromSuggestion(result, variables) {
    this.wrapper('removeMemberSuggestion', result, variables);
  }

  addSuggestedMemberFromSuggestion(result, variables) {
    this.wrapper('addMemberSuggestion', result, variables);
  }

  updateEntityThroughWrapper(meta, options = {}) {
    this.wrapper('updateEntity', meta, options);
  }

  addCommentInFeed(comment, options = {}) {
    this.wrapper('addCommentInFeedQuery', comment, options);
  }

  wrapper(functionName, result, variables = {}, meta = {}) {
    return setTimeout(() => {
      try {
        this[functionName](result, variables, meta);
      } catch (e) {
        console.log(e);
      }
    });
  }

  updateConversationMessageInStore(conversationMessage, key = null) {
    let id = 'ConversationMessage:' + (key || conversationMessage.id),
      data = {
        ...client.readFragment({
          fragment: CONVERSATION_MESSAGE_FRAGMENT,
          id,
          fragmentName: 'ConversationMessageSummary',
        }),
      };

    if (data) {
      data = { ...conversationMessage };
      client.writeFragment({
        fragment: CONVERSATION_MESSAGE_FRAGMENT,
        id,
        data,
        fragmentName: 'ConversationMessageSummary',
      });
    }
  }

  updateConversationInStore(conversation, meta = {}) {
    let id = 'Conversation:' + conversation.id,
      data = {
        ...client.readFragment({
          fragment: CONVERSATION_FRAGMENT,
          id,
          fragmentName: 'ConversationSummary',
        }),
      };

    if (data) {
      data = { ...conversation };
      client.writeFragment({
        fragment: CONVERSATION_FRAGMENT,
        id,
        data,
        fragmentName: 'ConversationSummary',
      });
    }
  }

  updateNoteOnNoteableInStore(noteable) {
    const id = noteable.__typename + ':' + noteable.id,
      fragment = gql`
        fragment updateNoteOnDelete on ${noteable.__typename} {
          notes {
            id
            content
            updated_at
            attachments {
              id
              preview
            }
          }
        }
      `;

    const data = cloneDeep(client.readFragment({ fragment, id }));
    data.notes = [];
    client.writeFragment({ fragment, id, data });
  }

  updateCurrentUserInStore(payload, variables) {
    let query = CURRENT_USER_QUERY,
      data = client.readQuery({ query: CURRENT_USER_QUERY, variables });

    let updatedData = cloneDeep(data);
    updatedData.user = { ...data.user, ...payload };

    client.writeQuery({ query, variables, updatedData });
  }

  markConversationAsReadInStore(conversationId, variables = {}, meta = {}) {
    let id = 'Conversation:' + conversationId,
      data = cloneDeep(
        client.readFragment({
          fragment: CONVERSATION_READ_FRAGMENT,
          id,
          fragmentName: 'ConversationReadSummary',
        }),
      );
    if (data) {
      data.unread = false;
      client.writeFragment({
        fragment: CONVERSATION_READ_FRAGMENT,
        id,
        data,
        fragmentName: 'ConversationReadSummary',
      });
    }
  }

  addCommentInRecentDiscussions(comment, meta = {}) {
    this.addRecord(
      comment,
      {
        type: `${meta.commentableType}RecentDiscussions`,
        perPage: 10,
        page: 0,
        id_query: meta.commentableId,
      },
      {
        isReverse: true,
        query: COMMENTS_LISTING,
      },
    );
  }

  addConversationInConversationsListing(conversation, meta = {}) {
    let perPage = meta.fullView
      ? CONVERSATIONS_BATCH_SIZE.fullView
      : CONVERSATIONS_BATCH_SIZE.floater;
    conversation = {
      ...conversation,
      recently_updated_at: currentTime().valueOf(),
    };
    this.addRecord(
      conversation,
      {
        type: 'ConversationsListing',
        perPage: perPage,
        page: 0,
        text_query: '',
      },
      {
        isReverse: true,
        fallbackFunctionName: 'updateConversationInStore',
      },
    );
  }

  addViewedByInCommentInStore(data) {
    const id = `${data.viewable_type}:${data.viewable_id}`,
      object = cloneDeep(
        client.readFragment({
          fragment: COMMENT_VIEWED_BY_SUMMARY,
          id,
          fragmentName: 'CommentViewedBySummary',
        }),
      );
    if (object) {
      object.viewed_by.unshift({
        __typename: 'View',
        id: data.id,
        user: data.user,
      });
      client.writeFragment({
        fragment: COMMENT_VIEWED_BY_SUMMARY,
        id,
        data: object,
        fragmentName: 'CommentViewedBySummary',
      });
      this.updateEntity(
        {
          attributeName: 'view_count',
          entity: {
            id: data.viewable_id,
            __typename: data.viewable_type,
            view_count: data.viewable_view_count,
          },
        },
        {
          customAction: 'assign',
        },
      );
    }
  }

  addEmojiInStore(data) {
    let id = `EmojiReactionDetail:${data.reactionId}`,
      object = cloneDeep(
        client.readFragment({
          fragment: EMOJI_REACTION_DETAIL_FRAGMENT,
          id,
          fragmentName: 'EmojiReactionDetailSummary',
        }),
      );
    if (object && object.id) {
      if (
        !object.emoji_reaction_users.some(
          (d) =>
            d.identifier === data.emojiName && +d.user.id === +data.user.id,
        )
      ) {
        object.emoji_reaction_users.push({
          __typename: 'EmojiReactionUser',
          id: Date.now(),
          reacted_by_current_user: data.reactedByCurrentUser,
          identifier: data.emojiName,
          user: data.user,
        });
        client.writeFragment({
          fragment: EMOJI_REACTION_DETAIL_FRAGMENT,
          id,
          data: object,
          fragmentName: 'EmojiReactionDetailSummary',
        });
      }
    } else {
      this.updateEntity(
        {
          attributeName: 'emoji_reaction_detail',
          entity: {
            id: data.entityId,
            __typename: data.entityType,
            emoji_reaction_detail: {
              __typename: 'EmojiReactionDetail',
              id: data.reactionId || Date.now(),
              emoji_reaction_users: [
                {
                  __typename: 'EmojiReactionUser',
                  id: Date.now(),
                  reacted_by_current_user: data.reactedByCurrentUser,
                  identifier: data.emojiName,
                  user: data.user,
                },
              ],
            },
          },
        },
        {
          customAction: 'assign',
        },
      );
    }
  }

  removeEmojiInStore(data) {
    let id = `EmojiReactionDetail:${data.reactionId}`,
      object = cloneDeep(
        client.readFragment({
          fragment: EMOJI_REACTION_DETAIL_FRAGMENT,
          id,
          fragmentName: 'EmojiReactionDetailSummary',
        }),
      );
    if (object) {
      object.emoji_reaction_users = object.emoji_reaction_users.filter(
        (obj) =>
          !(
            obj.identifier === data.emojiName && +obj.user.id === +data.user.id
          ),
      );
      client.writeFragment({
        fragment: EMOJI_REACTION_DETAIL_FRAGMENT,
        id,
        data: object,
        fragmentName: 'EmojiReactionDetailSummary',
      });
    }
  }

  addFeedInListing(feed, meta = {}) {
    this.addRecord(
      feed,
      {
        type: 'DefaultFeed',
        text_query: meta.textQuery,
        perPage: ACTIVITY_FEEDS_BATCH_SIZE,
        page: 0,
      },
      {
        isReverse: true,
      },
    );

    if (meta.highlightFeed) {
      clearTimeout(this.postHightlightTimeout);
      store.dispatch(resetNewPost());
      store.dispatch(setNewPost(feed.id));
      this.postHightlightTimeout = setTimeout(() => {
        store.dispatch(resetNewPost());
      }, HIGHLIGHT_NEW_POST_TIME_LIMIT_IN_MILLISECONDS);
    }
  }

  addNotificationInRecords(notification) {
    let metaOptions = { isReverse: true };

    this.addRecord(
      notification,
      { page: 0, type: 'Notification', perPage: 25 },
      metaOptions,
    );
    this.addRecord(
      notification,
      { page: 0, type: 'Notification', perPage: 10 },
      metaOptions,
    );
  }

  removePostFromMyPostListing(pulseId, meta = {}) {
    this.removeRecord(pulseId, {
      type: 'Pulses',
      perPage: MY_POSTS_PER_PAGE_WEB,
      page: 0,
    });
  }

  removeSpaceLinkFromListing(record, meta = {}) {
    this.removeRecord(record, {
      page: 0,
      perPage: SPACE_DETAIL_RECORDS_PER_PAGE,
      type: 'TabLinks',
      ...meta,
    });
  }

  removeActivityFromCareSpaceActivities(record, meta = {}) {
    this.removeRecord(record, {
      page: 0,
      perPage: 25,
      type: 'CareSpaceActivities',
      ...meta,
    });
  }

  addTabSectionLinksInListing(links, variables = {}, meta = {}) {
    this.addRecord(
      links,
      {
        page: 0,
        perPage: SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
        type: 'TabSectionLinks',
        additional_filters: '{}',
        ...variables,
        ...getVariablesForTabAndSections(variables),
      },
      {
        query: QUERY_MAPPER['TabSectionLinks'],
        ...meta,
      },
    );
  }

  removeTabSectionLinkFromListing(record, meta = {}) {
    this.removeRecord(record, {
      page: 0,
      perPage: SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
      additional_filters: '{}',
      type: 'TabSectionLinks',
      ...meta,
      ...getVariablesForTabAndSections(meta),
    });
  }

  readTabSectionLinksQuery(additionalVariables = {}) {
    const query = QUERY_MAPPER['TabSectionLinks'],
      variables = {
        additional_filters: '{}',
        ...additionalVariables,
        ...getVariablesForTabAndSections(additionalVariables),
        page: 0,
        perPage: SPACE_SECTION_LINKS_REQUIRED_PER_PAGE,
        type: 'TabSectionLinks',
      };

    return { ...client.readQuery({ query, variables }) };
  }

  // Parag marked it as improvement of pagination
  // updateSectionLinksCount(record, meta={}, sectionId) {
  //   this.updateLinksCountForSpaceSection(record, {
  //     id_query: meta.nice_id,
  //     page: 0,
  //     parent_id: meta.nice_id,
  //     perPage: 12,
  //     type: "SpaceLinks"
  //   }, sectionId)
  // }

  removeNoteFromListing(note, meta = {}) {
    this.removeRecord(note, {
      type: 'UserNotes',
      perPage: 24,
      page: 0,
      additional_filters: '{}',
    });
  }

  addLabelFromListing(label, meta = {}) {
    this.addRecord(label, {
      type: 'AllLabels',
      perPage: 12,
      page: 0,
      text_query: undefined,
      id_query: undefined,
      id_type: undefined,
    });
  }

  removeLabelFromListing(label, meta = {}) {
    this.removeRecord(label, {
      type: 'AllLabels',
      perPage: 12,
      page: 0,
      text_query: undefined,
      id_query: undefined,
      id_type: undefined,
    });
  }

  removeFeedFromListing(feed, meta = {}) {
    this.removeRecord(feed, {
      type: meta.type,
      text_query: meta.textQuery,
      perPage: ACTIVITY_FEEDS_BATCH_SIZE,
      page: 0,
    });
  }

  addUserToAcceptedConnections(user, meta = {}) {
    this.addRecord(user, {
      type: 'AcceptedConnections',
      perPage: 12,
      id_query: meta.idQuery,
      page: 0,
      additional_filters: '{}',
    });
  }

  addCommentInCareActivityListing(activity, meta = {}) {
    this.addRecord(
      activity,
      {
        type: 'CareSpaceActivities',
        perPage: 25,
        id_query: meta.idQuery,
        page: 0,
        id_type: meta.idType,
        text_query: meta.textQuery,
      },
      {
        isReverse: true,
        recordIdToRemove: meta.recordIdToRemove,
        query: TASK_ACTIVITY_LISTING,
      },
    );
  }

  addCommentInTaskFeed = (activity, meta = {}) => {
    const query = TASK_FEED_LISTING;
    let currentLocation = window.location.pathname;
    const variables = {
      type: currentLocation.includes('/tasks/me')
        ? 'MyTasksFeed'
        : 'TeamTasksFeed',
      perPage: 10,
      page: 0,
      additional_filters: '{"label_ids":[]}',
      filterId: null,
      filterType: null,
    };
    let updatedData = cloneDeep(client.readQuery({ query, variables }));

    if (updatedData) {
      const payload = { records: {} };
      const activityRecord = updatedData.records.results.find(
        (record) => record.id.toString() === meta.taskActivityId.toString(),
      );

      if (activityRecord) {
        updatedData.records.results = updatedData.records.results.filter(
          (record) => record.id.toString() !== meta.taskActivityId.toString(),
        );
        activityRecord.additional_entities.unshift(activity);
        payload.records.results = { $push: [activityRecord] };
        updatedData = immutableUpdate(updatedData, payload);
        client.writeQuery({ query, variables, data: updatedData });
      }
    }
  };

  addCommentsInTaskInboxListing(comment, meta = {}) {
    const query = TASK_INBOX_FEED_LISTING;
    const variables = {
      page: 0,
      ...meta.queryVariables,
    };
    let updatedData = cloneDeep(client.readQuery({ query, variables }));

    if (updatedData) {
      const task = updatedData.records.results.find(
        (record) => record.id.toString() === meta.task.id.toString(),
      );

      if (task) {
        task.recent_mentioned_comments.push(comment);
        client.writeQuery({ query, variables, data: updatedData });
      }
    }
  }

  addCommentInTaskActivityListing(activity, meta = {}) {
    if (meta.updateActivities) {
      this.addRecord(
        activity,
        {
          type: 'TaskActivities',
          perPage: meta.limit,
          id_query: meta.idQuery.toString(),
          page: 0,
        },
        {
          isReverse: true,
          recordIdToRemove: meta.recordIdToRemove,
          query: TASK_ACTIVITY_LISTING,
        },
      );
    }

    // Add comment/activity in task feed's additional_entities
    if (meta?.taskActivityId) {
      this.addCommentInTaskFeed(activity, meta);
    }

    if (isTaskCommentActivity(activity)) {
      // Increment comment count in the task
      this.updateEntity(
        {
          attributeName: 'comments_count',
          entity: {
            id: activity.objects[0]?.commentable_id,
            __typename: 'Task',
          },
        },
        {
          customAction: 'increment',
        },
      );
    }
  }

  addCommentInGroupActivityListing(activity, meta = {}) {
    this.addRecordInRecordPager(activity, {
      afterId: meta.afterId || null,
      aroundId: meta.aroundId || null,
      beforeId: meta.beforeId || null,
      limit: meta.limit || 10,
      recordId: meta.recordId || null,
      recordType: meta.recordType || null,
      sortQuery: meta.sortQuery || null,
      textQuery: meta.textQuery || null,
      type: 'GroupSpaceActivities',
      queryType: meta.queryType,
      idToBeRemoved: meta.recordIdToRemove,
    });
  }

  removeUserFromPendingConnections(user, meta = {}) {
    this.removeRecord(user, {
      type: 'PendingConnections',
      perPage: 48,
      page: 0,
      additional_filters: '{}',
    });
  }

  removeUserFromAcceptedConnections(user, meta = {}) {
    this.removeRecord(user, {
      type: 'AcceptedConnections',
      id_query: meta.idQuery,
      perPage: 12,
      page: 0,
      additional_filters: '{}',
    });
  }

  addMessageInConversationMessagesListing(message, conversationId, meta = {}) {
    let perPage = meta.fullView
      ? CONVERSATION_MESSAGES_BATCH_SIZE.fullView
      : CONVERSATION_MESSAGES_BATCH_SIZE.floater;
    this.addRecord(
      this.processedMessage(message),
      {
        type: 'ConversationMessagesListing',
        perPage: perPage,
        id_query: String(conversationId),
        page: 0,
      },
      {
        isReverse: true,
        fallbackFunctionName: 'updateConversationMessageInStore',
        recordIdToRemove: meta.recordIdToRemove,
      },
    );
  }

  addDMSharedFileInSharedFilesListingWidget(messageFiles, meta = {}) {
    const { messageId, conversationId } = meta;

    const query = DM_ATTACHMENTS_QUERY;
    const variables = {
      type: 'ConversationMessagesHavingAttachments',
      perPage: CONVERSATIONS_MESSAGE_PER_PAGE_COUNT,
      page: 0,
      id_query: conversationId.toString(),
    };
    let updatedData = cloneDeep(client.readQuery({ query, variables }));

    const payload = { records: {} };
    const sharedFiles = messageFiles.map((files) => {
      const { file_asset, id } = files;
      const file = {
        __typename: 'MessageFile',
        id,
        entity_id: messageId,
        file_asset,
      };
      return file;
    });

    payload.records.results = { $push: sharedFiles };
    updatedData = immutableUpdate(updatedData, payload);
    updatedData.records.total += sharedFiles.length;
    client.writeQuery({ query, variables, data: updatedData });
  }

  privateObject(entity) {
    return {
      __typename: 'PrivateObject',
      id: entity.id,
      type: entity.klass_display_name,
    };
  }

  processedMessage(message) {
    // const currentUserId = +(store.getState().currentUser.graph.id);
    // message.author = this.processEntity(message.author, currentUserId);
    // message.links = message.links.map(link => this.processEntity(link, currentUserId));
    return omit(message, 'current_user_id');
  }

  // processEntity(entity, currentUserId) {
  //   // Note: This handles the case when the entity is private for current user
  //   if(Array.isArray(entity.private_entity_for_user_ids) && entity.private_entity_for_user_ids.includes(currentUserId)) {
  //     return this.privateObject(entity)
  //   }

  //   keys(WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MAPPER).forEach(attribute => {
  //     let mappedAttribute = WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MAPPER[attribute];
  //     if(has(entity, attribute) && has(entity, mappedAttribute)) {
  //       entity[attribute] = entity[mappedAttribute].includes(currentUserId)
  //     }
  //   })

  //   values(WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MULTIPLE_KEYS_SINGLE_VALUE_HASH).map(data => (
  //     entity[data.attributeName] = data.defaultValue
  //   ))
  //   keys(WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MULTIPLE_KEYS_SINGLE_VALUE_HASH).forEach(attribute => {
  //     let data = WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_MULTIPLE_KEYS_SINGLE_VALUE_HASH[attribute],
  //         mappedAttribute = data.attributeName;
  //     if(has(entity, attribute) && has(entity, mappedAttribute)) {
  //       if(!entity[mappedAttribute] || entity[mappedAttribute] === data.falsyValue)
  //         entity[mappedAttribute] = entity[attribute].includes(currentUserId) ? data.truthyValue : data.falsyValue;
  //     }
  //   })

  //   keys(WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_USER_ID_AND_VALUE_MAPPER).forEach(attribute => {
  //     let mappedAttribute = WEB_PUSH_CURRENT_USER_SPECIAL_ATTRIBUTES_USER_ID_AND_VALUE_MAPPER[attribute];
  //     if(has(entity, attribute) && has(entity, mappedAttribute)) {
  //       entity[attribute] = entity[mappedAttribute][currentUserId]
  //     }
  //   })

  //   return entity
  // }

  addAdditionalRepliesInComment(parentComment, comment) {
    let entityUpdateFragment = COMMENT_SUMMARY,
      id = `${parentComment.__typename}:${parentComment.id}`,
      fragmentName = 'ParentCommentSummary',
      data = cloneDeep(
        client.readFragment({
          fragment: entityUpdateFragment,
          id,
          fragmentName,
        }),
      );
    if (data) {
      data.additional_replies = [{ ...comment, additional_replies: [] }];
      data.replies_count = 1;
      client.writeFragment({
        fragment: entityUpdateFragment,
        id,
        data,
        fragmentName,
      });
    }
  }

  removeAdditionalRepliesInComment(parentComment, comment) {
    let entityUpdateFragment = COMMENT_SUMMARY,
      id = `${parentComment.__typename}:${parentComment.id}`,
      fragmentName = 'ParentCommentSummary',
      data = cloneDeep(
        client.readFragment({
          fragment: entityUpdateFragment,
          id,
          fragmentName,
        }),
      );
    if (data) {
      data.additional_replies = data.additional_replies.filter(
        (c) => +c.id !== +comment.id,
      );
      data.replies_count = data.replies_count - 1;
      client.writeFragment({
        fragment: entityUpdateFragment,
        id,
        data,
        fragmentName,
      });
    }
  }

  removeDataFromRecordsPager(record, variables) {
    const query = QUERY_MAPPER[record.__typename];
    const data = cloneDeep(client.readQuery({ query, variables }));
    data.records_pager.results = data.records_pager.results.filter(
      (obj) => obj.id.toString() !== record.id.toString(),
    );
    data.records_pager.total = data.records_pager.total - 1;
    client.writeQuery({ query, variables, data });
  }

  replaceRecordInRecords(record, variables) {
    const query = QUERY_MAPPER[variables.type];
    const data = cloneDeep(client.readQuery({ query, variables }));
    data.records.results = [record];
    data.records.total = data.records.total + 1;
    client.writeQuery({ query, variables, data: { ...data } });
  }

  removeDataFromRecords(record, variables, meta = {}) {
    const query = meta.query || QUERY_MAPPER[variables.type] || RECORDS_QUERY;
    const data = cloneDeep(client.readQuery({ query, variables }));
    let ids = isArray(record)
      ? map(record, (item) => item.id.toString())
      : [record.id.toString()];
    data.records.results = data.records.results.filter(
      (obj) => !ids.includes(obj.id.toString()),
    );
    data.records.total = data.records.total - ids.length;
    client.writeQuery({ query, variables, data });
  }

  // Parag marked it as improvement of pagination
  // updateLinksCountForSpaceSection(record, variables, sectionId) {
  //   const query = QUERY_MAPPER[variables.type],
  //     data = client.readQuery({ query, variables});
  //   data.records.results = data.records.results.map(section => {
  //     if(section.id === sectionId) {
  //       section.links_count = record.total
  //     }
  //     return section
  //   })
  //   client.writeQuery({ query, variables, data })
  // }

  addDataInRecords(record, variables, meta = {}) {
    const query = meta.query || RECORDS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));

    let records = isArray(record) ? record : [record],
      ids = map(records, (item) => item.id.toString()),
      idsToRemove = isArray(meta.recordIdToRemove)
        ? meta.recordIdToRemove.map((id) => id.toString())
        : meta.recordIdToRemove
        ? [meta.recordIdToRemove.toString()]
        : [],
      payload = { records: {} },
      updatedData = data;
    idsToRemove = idsToRemove.filter((id) => !ids.includes(id));
    if (
      meta.fallbackFunctionNotRequired ||
      !updatedData.records.results.some((obj) =>
        ids.includes(obj.id.toString()),
      )
    ) {
      updatedData.records.results = updatedData.records.results.filter(
        (obj) => !ids.includes(obj.id.toString()),
      );
      if (meta.insertBeforeRecordId) {
        updatedData.records.results.splice(
          map(updatedData.records.results, 'id').indexOf(
            meta.insertBeforeRecordId,
          ),
          0,
          ...records,
        );
      } else {
        payload.records.results = meta.isReverse
          ? { $unshift: records }
          : { $push: records };
        updatedData = immutableUpdate(updatedData, payload);
      }

      if (idsToRemove.length)
        updatedData.records.results = updatedData.records.results.filter(
          (obj) => !idsToRemove.includes(obj.id.toString()),
        );

      updatedData.records.total =
        updatedData.records.total + ids.length - idsToRemove.length;

      client.writeQuery({ query, variables, data: updatedData });
    } else {
      meta.fallbackFunctionName &&
        this[meta.fallbackFunctionName](record, meta);
    }
  }

  addTaskInRecord(record, variables, meta = {}) {
    const query = meta.query,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      const updatedActiveTask = data.kanban_tasks.map((day) => {
        if (day.date.toString() === record.due_date.toString()) {
          return {
            ...day,
            records: [...day.records, record],
          };
        } else {
          return day;
        }
      });
      client.writeQuery({
        query,
        variables,
        data: { kanban_tasks: updatedActiveTask },
      });
    }
  }

  moveTaskToAnotherDay(record, variables, meta = {}) {
    const query = meta.query,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      const updatedActiveRecords = data.kanban_tasks.map((days) => {
        if (days.date.toString() === record.due_date.toString()) {
          return {
            ...days,
            records: [record, ...days.records],
          };
        } else if (days.date.toString() === meta.fromDate?.toString()) {
          return {
            ...days,
            records: days.records.filter((task) => task.id !== record.id),
          };
        } else {
          return days;
        }
      });
      client.writeQuery({
        query,
        variables,
        data: { kanban_tasks: updatedActiveRecords },
      });
    }
  }

  moveTaskInActiveRecords(record, variables, meta = {}) {
    const query = meta.query,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      const { toColumnId, fromColumnId } = meta;
      const updatedKanbanTasks = data.kanban_tasks.map((days) => {
        if (days.key === toColumnId) {
          return {
            ...days,
            records: [record, ...days.records],
          };
        } else if (days.key === fromColumnId) {
          return {
            ...days,
            records: days.records.filter((task) => task.id !== record.id),
          };
        } else if (toColumnId === 'weekend' && days.key === 'saturday') {
          // Handling weekend cases.
          return {
            ...days,
            records: [record, ...days.records],
          };
        } else if (
          fromColumnId === 'weekend' &&
          (days.key === 'saturday' || days.key === 'sunday')
        ) {
          return {
            ...days,
            records: days.records.filter((task) => task.id !== record.id),
          };
        }
        return {
          ...days,
          records: days.records.filter((task) => task.id !== record.id),
        };
      });
      client.writeQuery({
        query,
        variables,
        data: { kanban_tasks: updatedKanbanTasks },
      });
    }
  }

  removeTaskInRecords(record, variables, meta = {}) {
    for (let i = variables.page; i >= 0; i--) {
      const query = meta.query,
        data = cloneDeep(
          client.readQuery({ query, variables: { ...variables, page: i } }),
        );
      if (data) {
        const updatedResults = data.records.results.filter(
          (task) => task.id !== record.id,
        );
        if (updatedResults.length !== data.records.results.length) {
          client.writeQuery({
            query,
            variables: { ...variables, page: i },
            data: {
              records: {
                ...data.records,
                results: updatedResults,
                total: data.records.total - 1,
              },
            },
          });
          break;
        }
      }
    }
  }

  removeTaskInOverdueRecords(record, variables, meta = {}) {
    for (let i = variables.page; i >= 0; i--) {
      const query = meta.query,
        data = cloneDeep(
          client.readQuery({ query, variables: { ...variables, page: i } }),
        );
      if (data) {
        const updatedResults = data.records.results.filter(
          (task) => task.id !== record.id,
        );
        if (updatedResults.length !== data.records.results.length) {
          client.writeQuery({
            query,
            variables: { ...variables, page: i },
            data: {
              records: {
                ...data.records,
                results: updatedResults,
                total: data.records.total - 1,
              },
            },
          });
          break;
        }
      }
    }
  }

  removeTaskFromUnscheduledRecords(record, variables, meta = {}) {
    for (let i = variables.page; i >= 0; i--) {
      const query = meta.query,
        data = cloneDeep(
          client.readQuery({ query, variables: { ...variables, page: i } }),
        );
      if (data) {
        const updatedResults = data.records.results.filter(
          (task) => task.id !== record.id,
        );
        if (updatedResults.length !== data.records.results.length) {
          client.writeQuery({
            query,
            variables: { ...variables, page: i },
            data: {
              records: {
                ...data.records,
                results: updatedResults,
                total: data.records.total - 1,
              },
            },
          });
          break;
        }
      }
    }
  }

  updateLinkedEntitiesInPartnerSpace = (linkedEntity, variables, meta) => {
    const query = meta.query;
    const data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      const partnerSpaceClinics = data.records_pager.results;
      const clinicResults = partnerSpaceClinics.map((clinic, index) => {
        if (clinic.nice_id === meta.id) {
          return {
            ...clinic,
            mapped_entities: uniqBy(
              [
                ...clinic.mapped_entities,
                {
                  entity: {
                    ...pick(linkedEntity, [
                      'id',
                      'name',
                      'color',
                      'type',
                      '__typename',
                    ]),
                  },
                  id: linkedEntity.id,
                  notifications_count: 0,
                },
              ],
              'id',
            ),
          };
        } else {
          return clinic;
        }
      });
      client.writeQuery({
        query: query,
        variables: variables,
        data: {
          records_pager: {
            ...data.records_pager,
            results: clinicResults,
          },
        },
      });
    }
  };

  removeTaskFromRecords(record, variables, meta = {}) {
    const query = meta.query,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      const updatedActiveTasks = data.kanban_tasks.map((day) => {
        return {
          ...day,
          records: day.records.filter((task) => task.id !== record.id),
        };
      });
      client.writeQuery({
        query,
        variables,
        data: { kanban_tasks: updatedActiveTasks },
      });
    }
  }

  addNewLabelToList(record, variables, meta = {}) {
    const query = meta.query,
      data = cloneDeep(client.readQuery({ query, variables }));

    if (data) data.records.results.push(record);

    client.writeQuery({
      query,
      variables,
      data: data,
    });
  }

  addSchedulePostInList(record, variables, meta = {}) {
    let query = SPACE_SCHEDULE_MESSAGES_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      data = {
        ...data,
        records_pager: {
          ...data.records_pager,
          total: data.records_pager.total + 1,
          results: [...data.records_pager.results, record],
        },
      };
    }

    client.writeQuery({
      query,
      variables,
      data: data,
    });
  }

  removeLabelFromList(record, variables, meta = {}) {
    const query = WORKSPACE_ADDITIONAL_DETAILS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));

    let updatedData;
    if (data) {
      updatedData = {
        ...data,
        workspace: {
          ...data.workspace,
          all_labels: data.workspace.all_labels.filter(
            (label) => label.id !== record.id,
          ),
        },
      };
    }

    client.writeQuery({
      query,
      variables,
      data: updatedData,
    });
  }

  addLabelInList(record, variables, meta = {}) {
    const query = WORKSPACE_ADDITIONAL_DETAILS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    let updatedData;
    if (data) {
      updatedData = {
        ...data,
        workspace: {
          ...data.workspace,
          all_labels: [...data.workspace.all_labels, record],
        },
      };
    }

    client.writeQuery({
      query,
      variables,
      data: updatedData,
    });
  }

  addDataInRecordPager(result, metaInfo = {}) {
    let repliesQuery = metaInfo.type === 'Replies',
      query = RECORDS_PAGER_QUERY_MAPPER[metaInfo.queryType] || '',
      variables = {
        afterId: metaInfo.afterId || null,
        aroundId: metaInfo.aroundId || null,
        beforeId: metaInfo.beforeId || null,
        limit: metaInfo.limit,
        recordId: metaInfo.recordId || null,
        recordType: metaInfo.recordType || null,
        sortQuery: metaInfo.sortQuery || null,
        textQuery: has(metaInfo, 'forcedTextQuery')
          ? metaInfo.forcedTextQuery
          : metaInfo.textQuery || null,
        type: metaInfo.type,
      },
      data = cloneDeep(client.readQuery({ query, variables }));

    if (data) {
      let { record, results, secondary_results } = data.records_pager;
      if (!secondary_results) {
        secondary_results = [];
      }

      // Finds the comment with the same id.
      const resultExists = results.find(
        (object) =>
          object.id.toString() === result.id.toString() &&
          result.__typename === object.__typename,
      );
      if (!resultExists)
        repliesQuery || metaInfo.insertAtLast
          ? results.push(result)
          : results.unshift(result);
      if (metaInfo.idToBeRemoved)
        results = results.filter(
          (result) => +result.id !== +metaInfo.idToBeRemoved,
        );

      let secondaryRecord = {};
      let allResults = results;
      let resultData = result;

      if (result.__typename === 'Activity') {
        // If activity then extract objects as comment
        allResults = results
          .map((s) => s.objects[0])
          .filter((r) => r.__typename === 'Comment');
        resultData = result.objects[0];
      }

      if (resultData.parent_id) {
        secondaryRecord =
          metaInfo.secondaryRecord ||
          allResults?.find(
            (obj) => obj.id?.toString() === resultData.parent_id.toString(),
          );
        secondary_results = [...secondary_results, secondaryRecord];
      }

      if (result.id < 0 && record) {
        if (repliesQuery) {
          record.replies_count += 1;
        } else {
          record.comments_count += 1;
        }
      }

      data.records_pager.record = record;
      data.records_pager.results = results;
      if (secondary_results) {
        data.records_pager.secondary_results = secondary_results;
      }
      data.records_pager.total = data.records_pager.total + 1;
      client.writeQuery({ query, variables, data });
      if (
        resultData.id.toString() !== '-1' &&
        !repliesQuery &&
        resultData.parent_id &&
        secondaryRecord
      ) {
        this.updateEntity(
          {
            entity: secondaryRecord,
            attributeName: 'replies_count',
          },
          { customAction: 'increment' },
        );
      }
    }
  }

  addUserFollowee(result) {
    let variables = {
        page: 0,
        type: 'UserFollowees',
        perPage: 24,
        additional_filters: '{}',
        sortQuery: null,
      },
      query = USER_DETAIL_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (
      !data.records.results.filter(
        (obj) => obj.id === result.id && obj.__typename === result.__typename,
      ).length
    ) {
      data.records.results.push(result);
      client.writeQuery({ query, variables, data });
    }
  }

  removeUserFollowee(result) {
    let variables = {
        page: 0,
        type: 'UserFollowees',
        perPage: 24,
        additional_filters: '{}',
        sortQuery: null,
      },
      query = USER_DETAIL_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    data.records.results = data.records.results.filter(
      (obj) => obj.id !== result.id && obj.__typename === result.__typename,
    );
    client.writeQuery({ query, variables, data });
  }

  addUserConnection(result) {
    let variables = {
        page: 0,
        type: 'PendingConnections',
        perPage: 48,
        additional_filters: '{}',
      },
      query = RECORDS,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (
      !data.records.results.filter(
        (obj) => obj.id === result.id && obj.__typename === result.__typename,
      ).length
    ) {
      data.records.results.push(result);
      data.records.total += 1;
      client.writeQuery({ query, variables, data });
    }
  }

  removeFromRecommendations(result, perPage) {
    let variables = {
        page: 0,
        type: 'RecommendedDoctorsToConnect',
        perPage: perPage,
      },
      query = RECORDS,
      data = cloneDeep(client.readQuery({ query, variables }));
    data.records.results = data.records.results.filter(
      (user) => user.id !== result.id,
    );
    client.writeQuery({ query, variables, data });
  }

  removeMemberSuggestion(result, variables) {
    const query = SPACE_DETAILS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    data.records.results = data.records.results.filter(
      (obj) => obj.id.toString() !== result.id.toString(),
    );
    client.writeQuery({ query, variables, data });
  }

  setInitialDataInRecordPager(results, record, metaInfo = {}) {
    let query = RECORDS_PAGER_QUERY_MAPPER[metaInfo.queryType] || '',
      variables = {
        afterId: metaInfo.afterId || null,
        aroundId: metaInfo.aroundId || null,
        beforeId: metaInfo.beforeId || null,
        limit: metaInfo.limit,
        recordId: metaInfo.recordId || null,
        recordType: metaInfo.recordType || null,
        sortQuery: metaInfo.sortQuery || null,
        textQuery: metaInfo.textQuery || null,
        type: metaInfo.type,
        withRecord: metaInfo.withRecord,
      },
      data = {
        records_pager: {
          record,
          results,
          total: metaInfo.total,
          __typename: 'Collection',
          after_has_more: null,
          before_has_more: results.length < metaInfo.total,
          meta_info: '{}',
        },
      };
    client.writeQuery({ query, variables, data });
    return data;
  }

  updateEntity(meta, options = {}) {
    meta.fragmentName = `${meta.attributeName}updateEntity${Date.now()}`;
    let entityUpdateFragment = getEntityUpdateFragment(meta),
      id = `${meta.entity.__typename}:${meta.entity.id}`,
      data = cloneDeep(
        client.readFragment({
          fragment: entityUpdateFragment,
          id,
          fragmentName: meta.fragmentName,
        }),
      );

    meta.attributeNames =
      meta.attributeNames?.length > 0
        ? meta.attributeNames
        : [meta.attributeName];
    if (data) {
      switch (options.customAction) {
        case 'increment':
          data[meta.attributeName] += 1;
          break;
        case 'decrement':
          data[meta.attributeName] -= 1;
          break;
        case 'assign':
          if (meta.attributeNames.length > 0) {
            meta.attributeNames.forEach((attributeName) => {
              data[attributeName] = meta['entity'][attributeName];
            });
          }
          break;
        default:
          data[meta.attributeName] = meta.total;
      }
      client.writeFragment({
        fragment: entityUpdateFragment,
        id,
        data,
        fragmentName: meta.fragmentName,
      });
    }
  }

  removeFollowerFromListing(record, meta) {
    let variables = {
        page: 0,
        type: 'SpaceFollowers',
        perPage: SPACE_DETAIL_RECORDS_PER_PAGE,
        text_query: 'followers',
        id_query: meta.nice_id,
      },
      query = SPACE_DETAILS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables }));
    if (data) {
      data.records.results = data.records.results.filter(
        (follower) => follower.id !== record.id,
      );
      client.writeQuery({ query, variables, data });
    }
  }

  updatePrivateCardObjectInNotification(space, notificationId) {
    let id = 'Notification:' + notificationId,
      data = cloneDeep(
        client.readFragment({
          fragment: UPDATE_NOTIFICATION_CARD_OBJECT_FRAGMENT,
          id,
        }),
      );
    if (data) {
      data.card_objects = [space];
      client.writeFragment({
        fragment: UPDATE_NOTIFICATION_CARD_OBJECT_FRAGMENT,
        id,
        data,
      });
    }
  }

  updateTabSections(section, meta = {}) {
    meta = { ...meta, ...getVariablesForTabAndSections(meta) };
    let variables = {
        page: 0,
        perPage: SPACE_DETAIL_RECORDS_PER_PAGE,
        type: 'TabSections',
        searchQuery: meta.searchQuery,
        sortQuery: meta.sortQuery,
        additional_filters: meta.additionalFilters
          ? JSON.stringify(meta.additionalFilters)
          : '{}',
        ...meta,
        ...(meta.hasOwnProperty('textQuery')
          ? { text_query: meta.textQuery }
          : {}),
        ...(meta.hasOwnProperty('sortQuery')
          ? { sortQuery: meta.sortQuery }
          : {}),
      },
      query = TAB_DETAILS_QUERY,
      data = cloneDeep(client.readQuery({ query, variables })),
      records = data.records.results;

    let newList = [];
    if (meta.newAddition) {
      let upperSectionIndex = records.findIndex(
          (item) => item.id === meta.upperSectionId,
        ),
        moveUpLength = records.length - upperSectionIndex - 1;
      newList = moveUp(records.length, records.concat([section]), moveUpLength);
    } else if (meta.remove) {
      newList = records.filter((item) => item.id !== section.id);
    } else if (meta.movement) {
      let previousIndex = records.findIndex((item) => item.id === section.id),
        upwardMovement = meta.movement === 'up';
      newList = upwardMovement
        ? moveUp(previousIndex, records)
        : moveDown(previousIndex, records);
    } else if (meta.reorder) {
      newList = sortBy(records, (record) => {
        return meta.reorder.indexOf(record.id);
      });
    }
    data.records.results = newList;
    client.writeQuery({ query, variables, data });
  }

  addCommentInFeedQuery(comment, options) {
    let variables = {
        page: 0,
        type: options.type,
        text_query: options.textQuery,
        perPage: ACTIVITY_FEEDS_BATCH_SIZE,
      },
      query = QUERY_MAPPER[variables.type],
      data = { ...client.readQuery({ query, variables }) };

    let feed = data.records.results.find((feed) => feed.id === options.feedId);
    if (feed) {
      feed.additional_entities = isArray(feed.additional_entities)
        ? feed.additional_entities.concat(comment)
        : [comment];
      if (feed.objects[0] && isNumber(feed.objects[0].replies_count))
        feed.objects[0].replies_count += 1;
    }
    client.writeQuery({ query, variables, data });
  }

  updateSubspaceListing(spaceId, moveBeforeSpaceId, meta = {}) {
    let variables = {
        page: 0,
        type: meta.type,
        id_query: meta.idQuery,
        perPage: meta.perPage,
        searchQuery: meta.searchQuery,
      },
      query = QUERY_MAPPER[meta.type],
      data = cloneDeep(client.readQuery({ query, variables }));

    if (data) {
      let oldIndex = data.records.results.findIndex(
          (record) => record.nice_id === spaceId,
        ),
        newIndex;
      if (moveBeforeSpaceId === 'insert_at_bottom') {
        newIndex = data.records.results.length - 1;
      } else {
        newIndex = data.records.results.findIndex(
          (record) => record.nice_id === moveBeforeSpaceId,
        );
        newIndex -= oldIndex < newIndex ? 1 : 0;
      }
      data.records.results = arrayMove(
        data.records.results,
        oldIndex,
        newIndex,
      );

      client.writeQuery({ query, variables, data });
    }
  }

  removeCommentFromFeeds(comment, meta = {}) {
    let variables = {
        page: 0,
        type: 'CareSpaceFeed',
        perPage: 5,
      },
      query = QUERY_MAPPER['CareSpaceFeed'],
      data = cloneDeep(client.readQuery({ query, variables }));

    if (data) {
      if (comment.parent_id || comment.commentable_type === 'Pulse') {
        let feedIndex = data.records.results.findIndex(
          (feed) =>
            feed.additional_entities &&
            !!feed.additional_entities.find(
              (reply) =>
                reply.id === comment.id &&
                reply.__typename === comment.__typename,
            ),
        );
        if (feedIndex >= 0) {
          data.records.results[feedIndex].additional_entities =
            data.records.results[feedIndex].additional_entities.filter(
              (reply) => ![reply.id, reply.parent_id].includes(comment.id),
            );
          client.writeQuery({ query, variables, data });
        } else {
          this.removeRecordsFromRecordPager(comment, meta);
        }
      } else {
        data.records.results = data.records.results.filter((feed) => {
          return !(
            feed.objects[0] &&
            feed.objects[0].id === comment.id &&
            feed.objects[0].__typename === comment.__typename
          );
        });
        client.writeQuery({ query, variables, data });
      }
    }
  }

  updateSpaceShareLink(newLink, space) {
    let id = `Board:${space.id}`,
      fragmentName = 'SpaceShareLinkReadSummary',
      fragment = getEntityUpdateFragment({
        entity: space,
        attributeName: 'space_share_links',
        fragmentName,
      }),
      data = cloneDeep(client.readFragment({ fragment, id, fragmentName }));

    if (data) {
      let index = indexOf(map(data.share_links, 'id'), newLink.id);
      if (index >= 0) {
        if (newLink.active) {
          data.share_links[index] = newLink;
        } else {
          data.share_links.splice(index, 1);
          data.share_links.push(newLink);
        }
      } else {
        newLink.active
          ? data.share_links.unshift(newLink)
          : data.share_links.push(newLink);
      }
      client.writeFragment({ fragment, id, data, fragmentName });
    }
  }

  modifyAppointmentInCase(appointment, kaseId, remove = false) {
    let variables = { id: kaseId },
      query = CASE_QUERY,
      data;

    try {
      data = cloneDeep(client.readQuery({ query, variables }));
    } catch (e) {
      let variables = { id: kaseId.toLowerCase() };
      data = cloneDeep(client.readQuery({ query, variables }));
    }

    let existingAppointmentIndex = data.case.appointments.findIndex(
      (appt) => appt.id === appointment.id,
    );
    if (existingAppointmentIndex >= 0) {
      if (remove) {
        data.case.appointments.splice(existingAppointmentIndex, 1);
      } else {
        data.case.appointments[existingAppointmentIndex] = appointment;
        data.case.appointments_count -= 1;
      }
    } else {
      data.case.appointments = data.case.appointments.concat(appointment);
      data.case.appointments_count += 1;
    }
    data.case.appointments = orderBy(data.case.appointments, 'date');

    let activeLectureSpaceLink = store.getState().lectureView?.entity,
      activeLecture = activeLectureSpaceLink?.linkable;

    if (
      activeLecture &&
      activeLecture.__typename === 'Case' &&
      activeLecture.nice_id.toLowerCase() === kaseId.toLowerCase()
    ) {
      activeLectureSpaceLink.linkable.appointments = data.case.appointments;
      activeLectureSpaceLink.linkable.appointments_count =
        data.case.appointments_count;
      store.dispatch(
        setLectureEntity(activeLectureSpaceLink, { toggleKey: true }),
      );

      if (remove) {
        let selectedCaseDetails = store.getState().caseDetailInfo,
          isLeftSideAppointmentSelected =
            selectedCaseDetails.selectedAppointment?.id === appointment.id,
          isRightSideAppointmentSelected =
            selectedCaseDetails.selectedRightSideAppointment?.id ===
            appointment.id,
          nextAppointment =
            data.case.appointments[existingAppointmentIndex] ||
            data.case.appointments[0];

        if (isLeftSideAppointmentSelected)
          store.dispatch(setSelectedAppointment(nextAppointment));

        if (isRightSideAppointmentSelected)
          store.dispatch(setRightSideAppointment(nextAppointment));
      }
    }
    client.writeQuery({ query, variables, data });
  }
}

export default new StoreUpdater();
