import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectInput from 'app/components/Shared/SelectInput';

import { isEmpty } from 'app/utils/osLodash';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

class ShareableSpacesSelectBox extends Component {
  state = {
    selectedSpace: null,
  };

  componentDidMount() {
    if (isWorkspaceView())
      this.props.onSelectingSpace(this.props.workspace.data);
  }

  changeSelectedSpace = ({ entity }) => {
    this.setState({ selectedSpace: entity });
    this.props.onSelectingSpace(entity);
  };

  getSelectedSapce = () => {
    if (!isEmpty(this.state.selectedSpace)) {
      return {
        value: this.state.selectedSpace.id,
        label: this.state.selectedSpace.name,
      };
    } else {
      if (isWorkspaceView()) {
        let workspace = this.props.workspace.data;
        return { value: workspace.id, label: workspace.name };
      } else {
        return {};
      }
    }
  };

  render() {
    return (
      <SelectInput
        className={`react-select-ortho-2 ${this.props.className} `}
        placeholder='Start typing space name here…'
        onChange={this.changeSelectedSpace}
        async={true}
        queryType='CommentableSpaces'
        value={this.getSelectedSapce()}
      />
    );
  }
}
ShareableSpacesSelectBox.defaultProps = {
  onSelectingSpace: () => {},
  className: '',
};
ShareableSpacesSelectBox = connect(
  ({ workspace }) => ({ workspace }),
  {},
)(ShareableSpacesSelectBox);
export default ShareableSpacesSelectBox;
