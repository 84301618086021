import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import { openOnboardingModalWithScreen } from 'app/actions/onboardingTips';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';

export default class SecurityView extends PureComponent {
  renderUpdatePasswordCTAIfRequired(user) {
    if (!user.next_verification_step)
      return (
        <div className='col-lg-4 col-md-6'>
          <div className='contact-action'>
            <OsBtn
              name='BtnSecondary'
              extraClass='with-border'
              onClick={this.props.openPasswordUpdateModal}
              text='Change Password'
              associatedEntity={user}
            />
          </div>
        </div>
      );
  }

  render() {
    const { graph: user } = this.props.currentUser,
      countryCode = user.country_code ? `+${user.country_code}` : '',
      phoneNumber = user.phone_number || '';

    return (
      <div className='email-no-box'>
        <div className='row'>
          <div className='col-lg-8 col-md-12 email-field'>
            <label className='form-label' htmlFor='email'>
              Email*
            </label>
            <div className='email-input-text'>{user.email}</div>
          </div>
          {this.renderUpdatePasswordCTAIfRequired(user)}
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-12 email-field'>
            <label className='form-label'>
              <span class='heading'>Mobile phone number*</span>
            </label>
            <div className='email-input-text'>{`${countryCode}${phoneNumber}`}</div>
          </div>
          <div className='col-lg-4 col-md-6'>
            <div className='contact-action'>
              <OsBtn
                name='BtnSecondary'
                extraClass='with-border'
                onClick={this.props.openOnboardingModalWithScreen.bind(
                  this,
                  'mobile_verification',
                )}
                text='Change Number'
                associatedEntity={user}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SecurityView = connect(({ currentUser }) => ({ currentUser }), {
  openOnboardingModalWithScreen,
  openPasswordUpdateModal,
})(SecurityView);
