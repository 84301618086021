import {
  MOVE_SPACE_LINKS_TO_NEW_SECTION,
  RESET_ACTIVE_DRAG_DETAILS,
  RESET_SELECTED_SPACE_LINKS,
  SET_ACTIVE_DRAG_DETAILS,
  SET_NEW_SECTION_ID,
  SET_SPACE_LINKS_DROP_OPTIONS,
  TOGGLE_SELECTED_SPACE_LINK,
  TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS,
} from './types';

export function moveLinksToNewSection(moveLinksToNewSectionOptions = {}) {
  return {
    type: MOVE_SPACE_LINKS_TO_NEW_SECTION,
    moveLinksToNewSectionOptions,
  };
}

export function resetActiveDragDetails() {
  return { type: RESET_ACTIVE_DRAG_DETAILS };
}

export function resetSpaceLinks() {
  return { type: RESET_SELECTED_SPACE_LINKS };
}

export function setActiveDragDetails(options) {
  return { type: SET_ACTIVE_DRAG_DETAILS, options };
}

export function setNewSectionId(newSectionId) {
  return {
    type: SET_NEW_SECTION_ID,
    newSectionId: newSectionId && newSectionId.toString(),
  };
}

export function setSpaceLinksDropOptions(linksDropOptions = {}) {
  return { type: SET_SPACE_LINKS_DROP_OPTIONS, linksDropOptions };
}

export function toggleSpaceLink(object) {
  return { type: TOGGLE_SELECTED_SPACE_LINK, object };
}

export function toggleSpaceLinksRequestInProgress() {
  return { type: TOGGLE_SPACE_LINKS_REQUEST_IN_PROGRESS };
}
