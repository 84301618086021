import React from 'react';

import AbstractField from './AbstractField';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from './';

class OsCheckboxField extends AbstractField {
  render() {
    let { input, selected, showError } = this.props;
    return (
      <label
        className={`${this.props.className} ${
          this.props.disabled ? 'disabled' : ''
        }`}
        htmlFor={this.props.id}
        selected={selected}>
        <input
          type='checkbox'
          className='check-icon-input'
          onChange={this.onChange}
          key={this.props.isChecked || input.checked}
          checked={this.props.isChecked || input.checked}
          data-hover={this.props.dataHoverRequired}
          disabled={this.props.disabled}
          name={this.props.name}
          id={this.props.id}
          validate={this.props.validate}
        />
        {!this.props.slider && (
          <>
            <OrthoIcon
              name='checkbox-default'
              dataHoverNotRequired={true}
              defaultClass='check-icon'
            />
            <OrthoIcon
              name='checkbox'
              dataHoverNotRequired={true}
              defaultClass='check-icon'
            />
          </>
        )}
        {this.props.slider && (
          <span className={`slider ${this.props.sliderType}`}></span>
        )}
        <span className='cs-slider-after'></span>
        <span className='label'>{this.props.label}</span>
        {this.props.children}
        {showError && (
          <OsField {...this.props} osType='error' isWrapperRequired={false} />
        )}
      </label>
    );
  }
}

OsCheckboxField.defaultProps = {
  input: {},
  dataHoverRequired: false,
  slider: false,
  sliderType: 'round',
  showError: true,
};

export default OsCheckboxField;
