import DatePicker from 'react-date-picker';
import React, { useEffect } from 'react';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertDayJsDateInStringFormatToJsDate } from 'app/utils/timeHelper';
import OsField from 'app/components/OsField/index';
const OsReactDateField = (props) => {
  const datePicker = React.useRef();

  // Fix for selecting date on safari
  useEffect(() => {
    if (!datePicker.current) {
      return;
    }
    // Replace DatePicker's onOutsideAction with our own that takes Gatsby's focus wrapper into account
    const _this = datePicker.current;
    _this.onOutsideAction = (event) => {};
  }, []);

  return (
    <>
      <OsField {...props} osType='label' isWrapperRequired={false} />
      <DatePicker
        ref={datePicker}
        maxDate={
          props.maxDate && convertDayJsDateInStringFormatToJsDate(props.maxDate)
        }
        minDate={
          props.minDate && convertDayJsDateInStringFormatToJsDate(props.minDate)
        }
        className={`${props.className} cool-date-picker`}
        calendarClassName={`${props.calendarClassName} cool-date-calendar react-calendar`}
        format={props.format || 'MM/dd/y'}
        maxDetail={props.maxDetail || 'month'} // show only month or year view. Possible value ("year", "decade")
        onChange={props.onChange}
        disabled={props.disabled}
        disableCalendar={props.disableCalendar}
        clearIcon={props.clearIcon}
        calendarIcon={props.calendarIcon}
        locale='en-US'
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        yearPlaceholder='YYYY'
        onKeyDown={(e) => {
          if (props?.isTypingDisabled) {
            e.preventDefault();
          }
        }}
        // todo: change prop from formValues to date.
        value={
          props.formValues
            ? convertDayJsDateInStringFormatToJsDate(props.formValues)
            : null
        }
      />
      <OsField {...props} osType='error' isWrapperRequired={false} />
    </>
  );
};
export default OsReactDateField;
