import React from 'react';
import { Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Loader from 'react-loaders';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import DropzoneFileUploader from 'app/components/Shared/DropzoneFileUploader';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { closeEditImageModal } from 'app/actions/editImage';

import { translate } from 'app/actions/flashMessage';
import { each, isEmpty, map, some, values } from 'app/utils/osLodash';

const FILE_MAX_SIZE_ALLOWED = 8 * 1024 * 1024;
const FILE_TYPES_ALLOWED = '.jpg, .jpeg, .png, .bmp';
const DEFAULT_SPACE_IMAGE_QUERY = queryLoader(
  'app/graphql/queries/Spaces/DefaultSpaceImage.gql',
);
const ADD_FILE_ASSET_MUTATION = queryLoader('app/graphql/AddFileAsset.gql');
const UPDATE_SPACE_IMAGE = queryLoader(
  'app/graphql/mutations/UpdateSpaceImage.gql',
);
const VALIDATIONS_REQUIRED = [
  { attribute: 'Size', value: FILE_MAX_SIZE_ALLOWED },
  { attribute: 'Type', value: FILE_TYPES_ALLOWED },
];

class EditImage extends DropzoneFileUploader {
  constructor(props) {
    super(props);
    this.dropzoneSource = 'space-image-edit';
    this.state = this.defaultState();
  }

  attachmentObject() {
    return this.getSpace()?.space_banner?.image_attachment;
  }

  defaultState() {
    return {
      attachmentId: this.attachmentObject()?.id,
      dropzoneActive: false,
      errors: {},
      files: [],
      progressMapper: {},
      imageUploading: false,
      selectedImageUrl: this.attachmentObject()?.preview_url,
      showImageLoader: true,
      disableClose: false,
    };
  }

  componentDidMount() {
    window.$('.slick-slide').click((event) => {
      this.handleSlideClasses(window.$(event.currentTarget), 'click');
    });
    document.body.scrollIntoView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      isEmpty(prevProps.editImage.options) &&
      !isEmpty(this.props.editImage.options)
    ) {
      this.setState(this.defaultState());
    }
    if (prevState.selectedImageUrl !== this.state.selectedImageUrl)
      this.setState({ showImageLoader: true });
  }

  getSpace() {
    return this.props.editImage.options && this.props.editImage.options.space;
  }

  renderModalHeader() {
    let headerName = this.isFolder() ? 'folder' : 'space';
    return (
      <Modal.Header className='border-0 p-0 mb-2'>
        <h4 className='modal-title '>Edit {headerName} image</h4>

        <OsBtn
          name='BtnIcon'
          extraClass='no-text os-header-btn web-view-btn close-event-modal r--8 y-center'
          icon='close'
          label='Close edit image modal'
          onClick={this.props.closeEditImageModal}
        />
      </Modal.Header>
    );
  }

  maxFileSizeInMb() {
    return FILE_MAX_SIZE_ALLOWED / (1024 * 1024);
  }

  openFileFinder = () => {
    this.dropzoneRef.open();
  };

  onImageLoaded = (e) => {
    this.setState({ showImageLoader: false });
  };

  updateSelectedImageUrl = (url) => {
    this.setState({ selectedImageUrl: url });
  };

  slidesToShow() {
    let { ipadPortrait, mobileDevice, width } = this.props.device,
      slidesToShow = 3;

    if (width <= 1439 && width > 1023) {
      slidesToShow = 3;
    } else if (ipadPortrait) {
      slidesToShow = 2;
    } else if (mobileDevice) {
      slidesToShow = 2;
    }

    return slidesToShow;
  }

  handleSlideClasses(slide, eventType = 'click') {
    if (slide && slide.length) {
      let listLeftOffset = Math.floor(window.$('.slick-list').offset().left),
        slideLeftOffset = Math.floor(slide.offset().left),
        className = 'first-visible',
        slideContainer =
          eventType === 'change' ? slide.closest('.slick-slide') : slide,
        isLastSlide =
          slideLeftOffset - this.slidesToShow() * slide.width() ===
          listLeftOffset;

      if (eventType === 'click' && isLastSlide) this.slider.slickNext();

      if (slideLeftOffset === listLeftOffset) {
        window.$('.' + className).removeClass(className);
        slideContainer.addClass(className);
      }
    }
  }

  updateAttachmentId(imageId) {
    this.setState({ attachmentId: imageId });
  }

  onImageSelection = (image) => {
    this.setState({ disableClose: false });
    this.updateAttachmentId(image.id);
    this.updateSelectedImageUrl(image.preview_url);
  };

  sliderSettings() {
    // Refer: https://codepen.io/ehsantl/pen/QKGroV for Partial slide hack
    return {
      arrows: true,
      centerMode: true,
      centerPadding: this.props.device.mobileDevice ? '15%' : '60px',
      dots: false,
      draggable: true,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: this.slidesToShow(),
      swipe: true,
      swipeToSlide: true,
      afterChange: (current, slide) => {
        this.handleSlideClasses(
          window.$(window.$('.slick-list .active')[0]),
          'change',
        );
      },
    };
  }

  isImageSelected(id) {
    return +id === +this.state.attachmentId;
  }

  renderSlide = (object) => {
    let className = this.isImageSelected(object.id) ? 'active' : '';

    return (
      <div
        className={className}
        onClick={(e) => {
          this.onImageSelection(object);
        }}
        key={`slide-${object.id}`}>
        <img src={object.thumb_url} alt={object.name} className='' />
      </div>
    );
  };

  renderSlider() {
    return (
      <Slider
        ref={(ref) => (this.slider = ref)}
        {...this.sliderSettings()}
        className='create-space-slider'>
        {this.props.data.space_default_images.map(this.renderSlide)}
      </Slider>
    );
  }

  onDropRejected = (files) => {
    each(VALIDATIONS_REQUIRED, (validation) => {
      this[`is${validation.attribute}Valid`](files, validation.value);
    });
  };

  onDropCallback(files) {
    if (files.length) {
      this.setState({ selectedImageUrl: files[0].preview });
      this.uploadFilesToS3(files);
    }
  }

  anyDefaultImageSelected(imageId) {
    return map(this.props.data.space_default_images, 'id').includes(imageId);
  }

  uploadUserAsset(fileUrl, file) {
    this.props.client
      .mutate({
        mutation: ADD_FILE_ASSET_MUTATION,
        variables: {
          fileUrl,
          fileName: file.originalName,
          fileSize: file.size,
          source: 'space-image',
        },
      })
      .then(({ data }) => {
        if (
          data.addFileAsset.success &&
          !this.anyDefaultImageSelected(data.addFileAsset.id)
        ) {
          this.updateAttachmentId(data.addFileAsset.id);
        }
      });
  }

  isMobileOrIpadPotrait() {
    return this.props.device.mobileDevice || this.props.device.ipadPortrait;
  }

  isImageLoading() {
    let attachmentsLoading = some(values(this.state.attachmentsLoader));
    return (
      this.state.attachmentId &&
      this.state.selectedImageUrl &&
      (this.state.showImageLoader || attachmentsLoading)
    );
  }

  isFolder() {
    return this.props.data.variables.type === 'folder';
  }

  renderImage() {
    let containerClassName = 'modal-dropzone-area space-create-dropzone ',
      dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ',
      selectedImageUrl = this.state.selectedImageUrl,
      imageClassName = 'space-dropzone-hero ',
      browseButtonText = this.isMobileOrIpadPotrait()
        ? 'Browse your files'
        : 'browse your files';

    dropzoneInputContainerClassName += this.state.dropzoneActive
      ? 'dropzone-drag-active'
      : '';
    imageClassName += this.state.showImageLoader ? 'hidden' : '';
    let percentage = values(this.state.progressMapper)[0] || 0;
    return (
      <div className='row'>
        <div className='col-12'>
          <div
            className={`uploaded-image-block-holder ${
              this.state.dropzoneActive ? 'dropzone-active' : ''
            }`}>
            <Dropzone
              disableClick
              className={'modal-dropzone-area'}
              onDrop={this.onDrop.bind(this)}
              onDragEnter={this.onDragEnter.bind(this)}
              onDragLeave={this.onDragLeave.bind(this)}
              onDropRejected={this.onDropRejected}
              ref={(node) => {
                this.dropzoneRef = node;
              }}
              multiple={this.multipleFilesAllowed()}
              maxSize={FILE_MAX_SIZE_ALLOWED}
              accept={FILE_TYPES_ALLOWED}>
              {(props) => (
                <div className={containerClassName}>
                  <div
                    {...props.getRootProps({
                      onClick: this.onDialogInputClick,
                    })}
                    className={dropzoneInputContainerClassName}>
                    <input {...props.getInputProps()} />
                  </div>
                  {this.isImageLoading() && (
                    <p className='position-center loading-text'>
                      <i className='fa fa-spinner fa-spin'></i>&nbsp;
                      {`${percentage}%`}
                    </p>
                  )}
                  <div
                    className={`${
                      selectedImageUrl ? ' banner-selected-image' : ' d-none'
                    }`}>
                    <img
                      src={selectedImageUrl}
                      className={imageClassName}
                      onLoad={this.onImageLoaded}
                    />
                  </div>
                  {(!selectedImageUrl || this.isImageLoading()) && (
                    <div className='image-upload-banner'>
                      <div className='browse-image-col'>
                        <Icon name='dropFiles' />
                      </div>
                      <div className='upload-banner-text py-2'>
                        Drag and drop your image here or{' '}
                        <OsLink
                          text='browse your files '
                          className='semibold os-5f7 pointer'
                          onClick={this.openFileFinder}
                          associatedEntity={this.getSpace()}
                        />
                        <p>
                          The size of the image file must not exceed 2 MB. The
                          banner’s aspect ratio is 4*1, we recommend using
                          images that are at least 640*160 pixels for the best
                          display on high resolution devices.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>

            {this.renderErrorModal(
              FILE_TYPES_ALLOWED,
              FILE_MAX_SIZE_ALLOWED,
              'Image',
            )}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    if (!this.props.data.space_default_images) {
      return <Loader type='ball-triangle-path' active />;
    }
    return (
      <>
        {this.renderImage()}
        {this.renderSlider()}
      </>
    );
  }

  renderSaveButton() {
    const isDisabled = !(
      this.state.attachmentId || this.state.selectedImageUrl
    );
    return (
      <OsBtn
        name='BtnPrimary'
        text='Save Banner'
        disabled={isDisabled}
        extraClass='mx-auto web-view-btn mt-0'
        associatedEntity={this.getSpace()}
        onClick={this.updateImage}
      />
    );
  }

  deleteImage = () => {
    this.setState({ attachmentId: null, selectedImageUrl: null });
  };

  updateImage = () => {
    let variables = {
      id: this.getSpace().nice_id,
      attachment_id: this.state.attachmentId,
    };
    this.props.client
      .mutate({
        mutation: UPDATE_SPACE_IMAGE,
        variables: variables,
        optimisticResponse: () => {
          return {
            updateSpaceImage: {
              id: this.getSpace().id,
              space_banner: {
                id: this.getSpace().space_banner.id,
                image_attachment: {
                  id: this.state.attachmentId,
                  preview_url: this.state.selectedImageUrl,
                  __typename: 'Attachment',
                },
                __typename: 'SpaceBanner',
              },
              __typename: 'Board',
            },
          };
        },
      })
      .then((data) => {
        this.props.closeEditImageModal();
      });
  };

  renderRemoveImageButton() {
    if (this.attachmentObject()?.id)
      return (
        <OsBtn
          name='BtnIcon'
          text='Remove Image from Space'
          extraClass='me-auto web-view-btn  px-16'
          associatedEntity={this.getSpace()}
          onClick={this.deleteImage}
        />
      );
  }

  renderCancelButton() {
    if (this.attachmentObject()?.id)
      return (
        <OsBtn
          name='BtnIcon'
          text='Cancel'
          extraClass='web-view-btn me-3 px-16'
          associatedEntity={this.getSpace()}
          onClick={this.props.closeEditImageModal}
        />
      );
  }

  renderFooter() {
    return (
      <Modal.Footer className='edit-space-modal-footer py-3'>
        <div className='save-cancel-btn m-auto'>{this.renderSaveButton()}</div>
      </Modal.Footer>
    );
  }

  render() {
    if (!this.props.editImage.open) return null;

    return (
      <Modal
        show={this.props.editImage.open}
        onHide={this.props.closeEditImageModal}
        animation={false}
        dialogClassName={'os-grid-modal drag-drop-modal'}
        backdropClassName='modal-backdrop-custom upcoming-event-backdrop'>
        {this.renderModalHeader()}
        <Modal.Body className='px-0'>{this.renderContent()}</Modal.Body>
        {this.renderFooter()}
      </Modal>
    );
  }
}

EditImage = graphql(DEFAULT_SPACE_IMAGE_QUERY, {
  skip: (props) => !props.editImage.open,
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      type: props.editImage.options.space.category_type,
    },
  }),
})(EditImage);

EditImage = connect(({ device, editImage }) => ({ device, editImage }), {
  closeEditImageModal,
})(EditImage);
EditImage = withApollo(EditImage);

export default EditImage;
