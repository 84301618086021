import React from 'react';
import { Link } from 'react-router-dom';

import Authorize from 'app/components/Authorize';
import Follow from 'app/components/Shared/Follow';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn/';
import SpaceActions from './SpaceActions';
import SpaceHeader from './SpaceHeader';

import { entityConversationUrl, getEntityIcon } from 'app/utils/entitiesHelper';
import {
  iCompanyTypeSpace,
  isCompanySpace,
  isContentTypeSpace,
  isSubSpace,
  isFolderSpace,
} from 'app/utils/spaceHelper';
import { isEmpty } from 'app/utils/osLodash';

class AbstractSpaceView extends Authorize {
  isCompanySpace(space) {
    return isCompanySpace(space);
  }

  sendMessageCTAAllowed(space) {
    return iCompanyTypeSpace(space) && false;
  }

  onSendMessage = () => {};

  renderSendMessageCTA() {
    let space = this.getSpace(),
      messageBtnClass = 'web-view-btn ';
    messageBtnClass += this.props.device.mobileDevice ? 'd-block ' : '';

    return (
      <OsBtn
        name='BtnPrimary'
        text='Send Message'
        associatedEntity={space}
        extraClass={messageBtnClass}>
        <Link
          to={entityConversationUrl(space)}
          className='btn-link-helper'
          onClick={this.onSendMessage}></Link>
      </OsBtn>
    );
  }

  needSpaceActionsOnImage() {
    return this.props.isGuestUser || !this.isAuthorOrEditor();
  }

  mobileSpaceType() {
    return (
      <div className='m-header-action d-flex justify-content-between align-items-center'>
        {this.spaceTitle()}
        {!this.props.isGuestUser && <SpaceActions space={this.getSpace()} />}
        {this.props.isGuestUser && (
          <OsBtn
            name='BtnIcon'
            extraClass='no-text v-align-top card-btn-more '
            onClick={this.props.redirectToDefault}
            icon='more'
          />
        )}
      </div>
    );
  }

  spaceTitle() {
    return isContentTypeSpace(this.getSpace())
      ? this.renderSpaceCategory()
      : this.renderSpaceType();
  }

  shouldMuteOrFollowButtonRender() {
    return !this.isOwnedByUser() && this.getSpace().is_author_or_collaborator;
  }

  renderEditImageButton(source = '') {
    let isUnderDropdown = source === 'dropdown',
      extraClass =
        this.props.device.mobileDevice || isUnderDropdown
          ? ' list-button '
          : ' web-view-btn back-nav-btn ';
    return (
      <OsBtn
        name='BtnIcon'
        icon='image'
        text='Edit Image'
        extraClass={extraClass}
        onClick={this.props.openEditImageModal.bind(this, {
          space: this.getSpace(),
        })}
      />
    );
  }

  spaceEditImage() {
    return <div className='edit-btn'>{this.renderEditImageButton()}</div>;
  }

  getSpaceTitle() {
    let space = this.getSpace(),
      contentTypeSpace = isContentTypeSpace(space),
      title = isSubSpace(this.getSpace())
        ? 'subspace'
        : contentTypeSpace
        ? 'Space'
        : space.type;

    return `${title} ${space.nice_id}`;
  }

  renderSpaceType() {
    let space = this.getSpace(),
      title = this.getSpaceTitle(),
      mobile = this.props.device.mobileDevice,
      titleClass = 'space-heading-title fs-12 ';
    titleClass += mobile
      ? ' m-heading-tile '
      : !isEmpty(space.space_banner.image_attachment)
      ? ' web-heading-title'
      : '';
    return (
      <div className={titleClass}>
        <OrthoIcon
          name={getEntityIcon(this.getSpace())}
          defaultClass='me-1'
          dataHoverNotRequired={true}
        />
        <span className='text-uppercase fs-12'> {title}</span>
      </div>
    );
  }

  renderSpaceCategory() {
    let space = this.getSpace(),
      title = this.getSpaceTitle(),
      titleClass = 'space-heading-title fs-12';
    titleClass += this.props.device.mobileDevice
      ? ' m-heading-tile '
      : !isEmpty(space.space_banner.image_attachment)
      ? ' web-heading-title'
      : '';

    return (
      <div className={titleClass}>
        <OrthoIcon
          name={getEntityIcon(this.getSpace())}
          defaultClass='v-align-middle me-1'
          dataHoverNotRequired={true}
        />
        <span className='text-uppercase fs-12'> {title}</span>
      </div>
    );
  }

  getExtraClassForFollowButton(iconNotRequired, viewType) {
    let mobile = this.props.device.mobileDevice,
      extraClass = mobile ? 'd-block' : '';
    if (mobile && !iconNotRequired && viewType === 'customDropdown') {
      extraClass += ' list-button ';
    }
    if (!mobile && viewType !== 'customDropdown') {
      extraClass += ' px-8 v-align-middle';
    }

    return extraClass;
  }

  renderFollowButton(viewType = '') {
    let name = this.props.device.mobileDevice ? 'BtnPrimary' : '',
      iconNotRequired =
        this.props.device.mobileDevice && viewType !== 'customDropdown',
      extraClass = this.getExtraClassForFollowButton(iconNotRequired, viewType);

    return (
      <Follow
        propagateClick={true}
        muteRequired={true}
        isNotFromDropdown={viewType !== 'customDropdown'}
        key={this.getSpace().followed_by_current_user ? 'unfollow' : 'follow'}
        extraClass={extraClass}
        type='board'
        obj={this.getSpace()}
        name={name}
        iconNotRequired={iconNotRequired}
        labelRequired={true}
      />
    );
  }

  getDiscussionsCount() {
    let count;
    if (this.getUnreadDiscussionsCount()) {
      count = this.getUnreadDiscussionsCount();
      count = '+' + count;
    } else {
      count = this.getSpace().comments_count;
    }
    return count;
  }

  renderMobileMainHeader() {
    return (
      <div className='back-nav-bar mx-0'>
        <OsBtn
          name='BtnIcon'
          icon='chevron-left'
          extraClass='web-view-btn back-nav-btn'
          onClick={this.props.goBack}>
          <span className='fs-12 back-text'>Back</span>
        </OsBtn>
      </div>
    );
  }

  isOwnedByUser() {
    return +this.getSpace().user.id === +this.props.currentUser.graph.id;
  }

  renderHeader() {
    let groupClassName = 'space-header-info-group',
      space = this.getSpace();

    groupClassName += isEmpty(this.getSpace().space_banner?.image_attachment)
      ? ' without-hero-image'
      : '';

    return (
      !isFolderSpace(space) && (
        <div className={groupClassName}>
          <SpaceHeader space={space} />
        </div>
      )
    );
  }
}

export default AbstractSpaceView;
