import { PureComponent } from 'react';

import Authenticate from 'app/services/Authenticate.js';
import UserAppearanceChannel from 'app/channels/UserAppearanceChannel';
// import bugsnag from '@bugsnag/js'
import LocalStorageManager from 'app/services/LocalStorageManager';

import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { systemConfigSet, setFlaggedFeatures } from 'app/actions/systemConfig';
import { isEqual, toLower } from 'app/utils/osLodash';
import { setDeviceToken } from 'app/actions/device';

const SETTINGS_CONFIG = queryLoader('app/graphql/SystemConfig.gql');

class InitialRequestContainer extends PureComponent {
  componentDidMount() {
    this.settingConfigQuery();
  }

  componentWillUnmount() {
    !this.isUnauthorizedSession() &&
      this.isOnlineTrackingEnabled() &&
      UserAppearanceChannel.delete();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.systemConfig.requestServed &&
      this.props.systemConfig.requestServed
    ) {
      !this.isUnauthorizedSession() && this.setupUserAppearanceChannel();
    }

    if (!prevProps.currentUser.graph && this.props.currentUser.graph) {
      this.settingConfigQuery();
    }
  }

  isUnauthorizedSession() {
    return !(this.props.currentUser && this.props.currentUser.token);
  }

  setupUserAppearanceChannel = () => {
    setTimeout(() => {
      this.isOnlineTrackingEnabled() && UserAppearanceChannel.setup();
    }, 100);
  };

  isOnlineTrackingEnabled() {
    let setting =
      this.props.systemConfig.configs.doctor_online_tracking_enabled;
    return setting && toLower(setting.value) === 'true';
  }

  settingConfigQuery() {
    if (!this.props.systemConfig.flaggedFeaturesFetched)
      return this.props.client
        .query({
          query: SETTINGS_CONFIG,
          fetchPolicy: 'network-only',
          variables: {
            fetchFlaggedFeatures: !this.isUnauthorizedSession(),
          },
        })
        .then(({ data }) => {
          data && this.props.systemConfigSet(data.system_config);
          data &&
            data.flaggedFeatures &&
            this.props.setFlaggedFeatures &&
            this.props.setFlaggedFeatures(data.flaggedFeatures);
        });
  }

  handleSettingConfigsIfChanged(nextProps) {
    if (
      this.props.data &&
      nextProps.data &&
      !isEqual(this.props.data.system_config, nextProps.data.system_config)
    ) {
      this.props.systemConfigSet(nextProps.data.system_config);
    }
  }

  render() {
    return this.props.children;
  }
}

InitialRequestContainer = connect(
  ({ currentUser, systemConfig }) => ({ currentUser, systemConfig }),
  {
    setDeviceToken,
    setFlaggedFeatures,
    systemConfigSet,
  },
)(InitialRequestContainer);
InitialRequestContainer = withApollo(InitialRequestContainer);
export default InitialRequestContainer;
