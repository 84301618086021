import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import AbstractInviteMember from './AbstractInviteMember';
import JoinSpace from 'app/components/SpaceRequests/JoinSpace';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import RecordsView from 'app/components/RecordsView';

import { clone, debounce } from 'app/utils/osLodash';
import {
  isGroupSpace,
  isSubSpace,
  isWorkspaceType,
} from 'app/utils/spaceHelper';
import MembersToInvite from './MembersToInvite';
import { emailMustBeValid } from 'app/utils/validationHelper';

class InviteMember extends AbstractInviteMember {
  state = {
    selectedMembers: [],
    searchText: '',
    inputActive: false,
    refetch: false,
    requestInProgress: false,
    noMemberSelected: false,
    showInviteUsersHeading: !this.props.selectAllMembers,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectAllMembers &&
      this.state.showInviteUsersHeading &&
      this.state.selectedMembers.length > prevState.selectedMembers.length
    ) {
      this.setState({ showInviteUsersHeading: false });
    }
  }

  getSpace() {
    return this.props.board;
  }

  isCreatingOrEditingSpace() {
    return this.props.source === 'createOrEditSpace';
  }

  isOwner() {
    return (
      this.getSpace() &&
      +this.getSpace().user.id === +this.props.currentUser.graph.id
    );
  }

  canEditRole() {
    return this.isCreatingOrEditingSpace() || this.isOwner();
  }

  componentDidMount() {
    if (this.props.membersToInvite.length) {
      this.setSelectedMembers(this.props.membersToInvite[0]);
    }
  }

  closeModal = () => {
    this.state.searchText && this.setState({ searchText: '' });
    this.props.modalClosed && this.props.modalClosed();
  };

  setSelectedMembers = (selectedMember) => {
    let selectedMembers = clone(this.state.selectedMembers);
    selectedMember.role = this.memberRole();
    selectedMembers.push(selectedMember);
    this.setState({ selectedMembers, noMemberSelected: false }, () => {
      this.props.setInvitedMembers(this.state.selectedMembers);
    });
  };

  getRoleFromParentSpace(user) {
    if (this.isSubSpace()) {
      let parent = this.getSpace().owner;
      return parent.editor_ids.includes(user.id)
        ? this.editorRole()
        : this.memberRole();
    } else {
      return this.memberRole();
    }
  }

  onSelectAllMembers = (members) => {
    members = members.map((user) => ({
      ...user,
      role: this.getRoleFromParentSpace(user),
    }));
    let selectedMembers = this.state.selectedMembers.concat(members);
    this.setState({ selectedMembers, noMemberSelected: false }, () => {
      this.props.setInvitedMembers(this.state.selectedMembers);
    });
  };

  clearSelectedMembers = () => {
    this.props.membersToInvite &&
      this.setState({ selectedMembers: this.props.membersToInvite });
    this.closeModal();
  };

  renderModalHeader() {
    return (
      <Modal.Header className='border-0 p-0 with-abs-close mb-0'>
        <h4 className='modal-title'>Invite Team Member</h4>

        <OsBtn
          name='BtnIcon'
          extraClass='no-text os-header-btn web-view-btn close-subscription'
          icon='close'
          label='Close invite members modal'
          onClick={this.clearSelectedMembers}
        />
      </Modal.Header>
    );
  }

  linksOptions() {
    let space = this.getSpace(),
      additional_filters =
        isSubSpace(space) || isGroupSpace(space)
          ? { parent_space_id: space.owner.nice_id }
          : {},
      idQuery = space.nice_id || '';
    return {
      type: 'UsersToInviteInSpace',
      queryType: 'USER_DETAIL',
      additional_filters: additional_filters,
      idQuery: idQuery,
      perPage: 25,
      board: space,
      textQuery: this.state.searchText.trim(),
      windowNotRequired: true,
      filterRecords: true,
    };
  }

  renderMembersRecord() {
    return (
      <RecordsView
        parentContainer={this.refs.container}
        setSelectedMembers={this.setSelectedMembers}
        recordsNotToBeIncludes={this.state.selectedMembers.map(
          (member) => member,
        )}
        board={this.props.board}
        {...this.linksOptions()}
        key={this.state.searchText}
        selectAllMembers={this.props.selectAllMembers}
        onSelectAllMembers={this.onSelectAllMembers}
      />
    );
  }

  renderOnlyEmail() {
    return (
      <MembersToInvite
        parentContainer={this.refs.container}
        setSelectedMembers={this.setSelectedMembers}
        board={this.props.board}
        results={[]}
        textQuery={this.state.searchText}
        source={this.props.board.type}
        selectedMembers={this.state.selectedMembers.map((member) => member)}
      />
    );
  }

  checkForHeader() {
    if (isWorkspaceType(this.props.board)) {
      return !emailMustBeValid(this.state.searchText);
    }
    return true;
  }

  renderUsersToInvite() {
    return (
      <div className='col-bottom'>
        {this.state.showInviteUsersHeading && this.checkForHeader() && (
          <span className='member-sugest-label'> Suggested members </span>
        )}
        <div className='suggested-members' ref='container'>
          {isWorkspaceType(this.props.board)
            ? this.renderOnlyEmail()
            : this.renderMembersRecord()}
        </div>
      </div>
    );
  }

  setSearchText = debounce((searchText) => {
    this.setState({ searchText });
  }, 200);

  onChange = (e) => {
    this.setSearchText(e.target.value);
  };

  setInputActive = () => {
    this.setState({ inputActive: true });
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  renderSearch() {
    return (
      <div className='input-wrapper nav-search-holder input-focus'>
        <div
          className={`nav-search-container ${
            this.state.inputActive ? 'input-focus-active' : ''
          }`}
          onFocus={this.setInputActive}
          onBlur={this.unsetInputActive}>
          <label className='position-relative m-0 w-100'>
            <OrthoIcon name='search' dataHoverNotRequired={true} />
            <OsField
              osType='input'
              type='text'
              placeholder='Click here to search for the members'
              className={'nav-search-input'}
              name='search'
              onChange={this.onChange}
              autoFocus={this.isModalRequired()}
            />
          </label>
        </div>
      </div>
    );
  }

  getInvitationMemberList(list) {
    if (this.state.searchText) {
      list = this.getFilteredMembers(list);
    }
    list = this.getSortedList(list);
    return list;
  }

  removeFromSelectedList = (member, selectedMember) => {
    if (member.id) {
      return member.id !== selectedMember.id;
    } else {
      return member.name !== selectedMember.name;
    }
  };

  getSelectedMembers = (selectedMember) => {
    const isGroup = isGroupSpace(this.getSpace());
    return (
      <div className='member-list-item'>
        <label className='list-type-label'>
          {this.renderMembers(selectedMember, true, isGroup)}
        </label>
      </div>
    );
  };

  renderInvitations() {
    let orderedInvitationList = this.getInvitationMemberList(
      this.state.selectedMembers,
    );
    return (
      <>
        <div className='selected-member-group invited-members'>
          <span className='selected-member-label next'>{`Invitations (${this.state.selectedMembers.length})`}</span>
          <div className='member-list-group'>
            {orderedInvitationList.map(this.getSelectedMembers)}
          </div>
        </div>
      </>
    );
  }

  renderContent() {
    let selectAllMembersLength = this.state.selectedMembers.length,
      className = 'member-selected-list-group ';
    className += selectAllMembersLength
      ? 'member-selected'
      : 'list-group-wrapper';
    className += this.state.showInviteUsersHeading ? '' : ' all-select-members';
    return (
      <>
        {this.renderSearch()}
        <div className={className}>
          {this.state.selectedMembers.length > 0 && this.renderInvitations()}
          {this.renderUsersToInvite()}
          {this.state.noMemberSelected && (
            <div className='not-selected'>
              <OrthoIcon name='error' dataHoverNotRequired={true} />
              <span>You haven’t selected anyone</span>
            </div>
          )}
        </div>
      </>
    );
  }

  warningForNoUser = () => {
    this.setState({ noMemberSelected: true });
  };

  renderModalFooter() {
    return (
      <Modal.Footer className=''>
        <div className='p-0 m-0'>
          {!this.state.requestInProgress && (
            <OsBtn
              name='BtnIcon'
              text='Cancel'
              extraClass='skip-btn web-view-btn me-3 px-16'
              onClick={this.clearSelectedMembers}
            />
          )}
          <JoinSpace
            viewType='modal'
            setInvitedMembers={this.props.setInvitedMembers}
            source={this.props.source}
            board={this.props.board}
            closeModal={this.closeModal}
            onSuccess={this.props.remountComponent}
            requestingUsers={this.state.selectedMembers}
            warningForNoUser={this.warningForNoUser}
          />
        </div>
      </Modal.Footer>
    );
  }

  isModalRequired() {
    return this.props.viewType === 'modal';
  }

  isSubSpace() {
    return isSubSpace(this.getSpace());
  }

  renderInvitingMembersBlock() {
    let isSubSpace = this.isSubSpace(),
      headerText = isSubSpace ? 'Members' : 'Invite members (optional)';

    return (
      <div className='invite-member-modal invite-widget'>
        <div className='d-flex'>
          <span className='widget-title ps-2 pe-1'>{headerText}</span>
          {isSubSpace && (
            <div className='ms-auto lh-1 pt-1'>
              <OrthoIcon
                name={'checkbox'}
                defaultClass='disabled v-align-middle mr-2'
              />
              <span className='fs-12'>
                Invite all members of the main space
              </span>
            </div>
          )}
        </div>
        <div className='px-0'>{this.renderContent()}</div>
      </div>
    );
  }

  render() {
    if (this.isModalRequired()) {
      return (
        <Modal
          show={this.props.showModal}
          onHide={this.clearSelectedMembers}
          animation={false}
          dialogClassName={'invite-member-modal'}
          backdropClassName='modal-backdrop-custom'>
          {this.renderModalHeader()}
          <Modal.Body className='px-0'>{this.renderContent()}</Modal.Body>
          {this.renderModalFooter()}
        </Modal>
      );
    } else {
      return this.renderInvitingMembersBlock();
    }
  }
}

InviteMember = connect(({ device, currentUser }) => ({ device, currentUser }))(
  InviteMember,
);
InviteMember.defaultProps = {
  membersToInvite: [],
  setInvitedMembers: () => {},
  viewType: 'block',
};
export default InviteMember;
