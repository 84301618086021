import { isImage, isVideo } from './fileHelper';

const VALID_SIZES = [
  'big',
  'thumbnail',
  'tall',
  'tall-min',
  'small',
  'small-min',
  'extra-small',
  'list',
];
const WIDTH_BASED_CARD_SIZE_THRESHOLDS = {
  desktop: 1200,
  smallDesktop: 768,
};

export function cardSize({ size, width, mobileDeviceLandscape }) {
  let calculatedSize = '';

  if (VALID_SIZES.includes(size)) {
    calculatedSize = size;
  } else if (width >= WIDTH_BASED_CARD_SIZE_THRESHOLDS.desktop) {
    calculatedSize = 'tall';
  } else if (
    width < WIDTH_BASED_CARD_SIZE_THRESHOLDS.desktop &&
    width >= WIDTH_BASED_CARD_SIZE_THRESHOLDS.smallDesktop &&
    !mobileDeviceLandscape
  ) {
    calculatedSize = 'tall-min';
  } else {
    calculatedSize = 'small';
  }
  return calculatedSize;
}

export function cardBackgroundColor(obj) {
  let imageExist = obj.thumb_url || obj.image_url,
    typename = obj.__typename,
    generic = '';
  if (imageExist) {
    return generic;
  } else if (typename === 'Attachment') {
    if (isVideo(obj)) {
      generic += ' video-card ';
    } else if (isImage(obj)) {
      generic += ' image-card ';
    } else {
      generic += ' doc-card ';
    }
  } else if (typename === 'AdditionalLink') {
    generic += ' link-card ';
  } else if (typename === 'Case') {
    generic += ' case-card ';
  } else if (typename === 'Comment') {
    generic += ' post-card ';
  } else if (typename === 'Tool') {
    generic += ' tool-card ';
  }
  return generic;
}

export function getSpaceCardGridIdentifier(size, type = 'FolderDetail') {
  switch (size) {
    case 'big':
      return `${type}:BigCard`;
    case 'extra-small':
      return `${type}:ExtraSmall`;
    case 'thumbnail':
      return `${type}:Thumbnail`;
    case 'list':
      return `${type}:TableView`;
    default:
      return type === 'FolderDetail' ? 'SpaceDetail:Card' : `${type}:Card`;
  }
}
