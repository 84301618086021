import React, { Component } from 'react';

import NewAppointmentButton from 'app/components/Shared/NewAppointmentButton';
import img from 'app/images/svg-thumbnail/no_appointments.png';

import { translate } from 'app/actions/flashMessage.js';

export default class ComingSoonSection extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <img src={img} className='comingsoon-image' />
        <p>{translate('NO_APPOINTMENTS_FILES_PRESENT')}</p>
      </div>
    );
  }
}
