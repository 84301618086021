import {
  RESET_STATE,
  SET_ACTIVE_APPLIANCE_LOG_CARD,
  SET_CURRENT_SLIDE,
  SET_DEFAULT_STATE,
  SET_MODE_TYPE,
  SET_RIGHT_SIDE_SELECTED_APPOINTMENT,
  SET_SELECTED_APPOINTMENT,
  SET_VIEW_TYPE,
} from 'app/actions/types';

import {
  ALL_APPOINTMENTS_FILTER_TEXT,
  ALL_RECORDS_TEXT,
  VIEWING_MODES_MAPPER,
} from 'app/components/CaseLeftSection/constants';
import { keys } from 'app/utils/osLodash';

const DEFAULT_STATE = {
  mode: ALL_RECORDS_TEXT,
  viewType: ALL_APPOINTMENTS_FILTER_TEXT,
  currentSlide: {},
  selectedAppointment: null,
  selectedRightSideAppointment: null,
};

function getViewModeFromSlide(slide) {
  return (
    keys(VIEWING_MODES_MAPPER).find((mode) => {
      if (
        slide.__typename === 'CaseImage' &&
        VIEWING_MODES_MAPPER[mode]['type'] === 'Image' &&
        !(VIEWING_MODES_MAPPER['X-Ray'].names || []).includes(slide.name)
      ) {
        return mode;
      } else if (
        VIEWING_MODES_MAPPER[mode]['names'] &&
        VIEWING_MODES_MAPPER[mode]['names'].includes(slide.name)
      ) {
        return mode;
      } else {
        return null;
      }
    }) || ALL_RECORDS_TEXT
  );
}

export default function (state = { ...DEFAULT_STATE }, action) {
  switch (action.type) {
    case SET_MODE_TYPE:
      return {
        ...state,
        mode: action.modeType,
        modeSelectedByUser: true,
        activeCardId: null,
      };
    case SET_VIEW_TYPE:
      return { ...state, viewType: action.viewType, activeCardId: null };
    case SET_CURRENT_SLIDE:
      const values =
        state.mode === ALL_RECORDS_TEXT && state.modeSelectedByUser
          ? { mode: state.mode }
          : {
              mode: getViewModeFromSlide(action.currentSlide) || state.mode,
              modeSelectedByUser: false,
            };
      return {
        ...state,
        currentSlide: action.currentSlide,
        ...values,
        activeCardId: null,
      };
    case SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.selectedAppointment,
        activeCardId: null,
      };
    case SET_RIGHT_SIDE_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedRightSideAppointment: action.selectedAppointment,
        activeCardId: null,
      };
    case SET_DEFAULT_STATE:
      return { ...state, ...DEFAULT_STATE, activeCardId: null };
    case RESET_STATE:
      return {
        ...state,
        ...DEFAULT_STATE,
        viewType: 'SBS',
        selectedAppointment: action.initialAppointment,
        selectedRightSideAppointment: action.finalAppointment,
        activeCardId: null,
      };
    case SET_ACTIVE_APPLIANCE_LOG_CARD:
      return { ...state, activeCardId: action.cardId };
    default:
      return state;
  }
}
