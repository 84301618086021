import React, { Component } from 'react';

import { isPatientInfoAvailable } from 'app/utils/entitiesHelper';

export default class CasePatientInfo extends Component {
  getPatientInfo() {
    let kase = this.props.kase,
      patientSexText = kase.patient_sex ? kase.patient_sex : '',
      patientAgeInYear = kase.t1_age ? kase.t1_age.years : '',
      patientAgeInMonth = kase.t1_age ? kase.t1_age.months : '';
    return (
      <>
        {' '}
        {patientSexText}
        {patientAgeInYear || patientAgeInMonth ? (
          <>
            {patientSexText && ', '}
            {patientAgeInYear}
            <span className='details-time'>yr </span>
            {patientAgeInMonth}
            <span className='details-time'>mo</span>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  render() {
    let kase = this.props.kase,
      classes = this.props.avoidCommonClasses ? '' : 'detail-subheader ';
    if (this.props.extraClass) classes += this.props.extraClass;
    return (
      <div className={classes}>
        {isPatientInfoAvailable(kase) ? (
          this.getPatientInfo()
        ) : (
          <span className='no-patient-info text-truncate'>
            No Patient Info Available
          </span>
        )}
      </div>
    );
  }
}
