import OsGrid from 'app/components/OsGrid';
import { useEffect } from 'react';

const ImageFormLayout = (props) => {
  useEffect(() => {
    document.body.classList.add('image-layout-open');
    return () => {
      document.body.classList.remove('image-layout-open');
    };
  });

  return (
    <article className='container image-form-container'>
      <div className={`row ws_auth_row ${props.source || ''}-row`}>
        <OsGrid
          identifier='ResetPassword:ImgCol'
          extraClass='left-bar mobile-hide'>
          <div className={`ws-auth-info-area ${props.source || ''}-leftBar`}>
            {props.leftBar}
          </div>
        </OsGrid>
        <OsGrid identifier='ResetPassword:FormCol' extraClass='ws-user-form'>
          <div className={`cmn-form-box ${props.source || ''}-rightBar`}>
            {props.rightBar}
          </div>
        </OsGrid>
      </div>
    </article>
  );
};

export default ImageFormLayout;
