import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Avatar from 'app/components/Shared/Avatar';
import OsLink from 'app/components/OsLink';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import UsersInfoModal from 'app/components/Shared/UsersInfoModal';

import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import { pluralize } from 'app/utils/generalHelper';
import { timeWithFormat, timestampOnHover } from 'app/utils/timeHelper';
import { map, slice } from 'app/utils/osLodash';

import EventTracker from 'app/services/EventTracker';

class AuthorDetail extends Component {
  state = {
    openModal: false,
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  onLinkClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      e.preventDefault();
      e.stopPropagation();
      EventTracker.trackLinkClicked(e.target.text);
      this.props.navigate(e.target.getAttribute('href'));
    }
  };

  renderAvatarLink(author) {
    return (
      <Link to={entityUrl(author)} key={author.id} className='act-avatar-item'>
        <Avatar className='avatar avatar-48' {...avatarAttributes(author)} />
      </Link>
    );
  }

  renderNameLink(author) {
    return (
      <OsLink
        to={entityUrl(author)}
        className='feed-author-info user-link'
        name={author.name}>
        <UserQualificationInfo
          degreeNotRequired={this.props.multiple}
          sliceUpto={2}
          user={author}
          titleRequired={true}
        />
      </OsLink>
    );
  }

  renderOtherAuthorsInfo() {
    let count = this.props.authors.length - 1;

    return (
      <span>
        {' '}
        &{' '}
        <a
          onClick={this.toggleModal}
          href='javascript:void(0)'
          className='a-link font-weight-normal'>
          {count} other{' '}
          {pluralize(count, { singular: 'doctor', plural: 'doctors' })}
        </a>
      </span>
    );
  }

  renderFeedTimeDetails() {
    let { timestamp } = this.props;

    return (
      <div className='activity-heading-block' onClick={this.onLinkClick}>
        <div className='time-day' title={timestampOnHover(timestamp)}>
          {timeWithFormat(timestamp, 'll', true, {
            todayWithTimeRequired: true,
          })}
        </div>
      </div>
    );
  }

  render() {
    let { author, authors, multiple } = this.props,
      authorsWithAvatar = multiple ? slice(authors, 0, 2) : [author],
      authorWithName = multiple ? authors[0] : author;

    return (
      <>
        <div
          className={`avatar-listing ${multiple ? 'multiple' : ''}`}
          key='feeds-actor-avatars'>
          {map(authorsWithAvatar, this.renderAvatarLink)}
        </div>

        <aside className='user-name-block' key='feeds-actor-info'>
          {this.renderNameLink(authorWithName)}
          {multiple && this.renderOtherAuthorsInfo()}
          {this.renderFeedTimeDetails()}
        </aside>

        {multiple && (
          <UsersInfoModal
            show={this.state.openModal}
            users={authors}
            heading={this.props.modalHeading}
            closeModal={this.toggleModal}
          />
        )}
      </>
    );
  }
}
AuthorDetail = withRouter(AuthorDetail);

export default AuthorDetail;
