import { SHOW_ERROR_MESSAGE, SHOW_SUCCESS_MESSAGE } from 'app/actions/types';

export default function flashMessage(state = {}, action) {
  switch (action.type) {
    case SHOW_SUCCESS_MESSAGE:
      return { ...state, message: action.message };

    case SHOW_ERROR_MESSAGE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
