import React, { Component } from 'react';
import { connect } from 'react-redux';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import SpaceActions from './SpaceActions';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import { getEntityIcon } from 'app/utils/entitiesHelper';
import {
  isCareSpace,
  isContentTypeSpace,
  isSubSpace,
} from 'app/utils/spaceHelper';
import { isEmpty } from 'app/utils/osLodash';
import { isGuestUser } from 'app/utils/userHelper';

import { openEditImageModal } from 'app/actions/editImage';

class SpaceHeroImage extends Component {
  getSpace() {
    return this.props.space;
  }

  getSpaceTitle() {
    let space = this.getSpace(),
      contentTypeSpace = isContentTypeSpace(space),
      title = isSubSpace(this.getSpace())
        ? 'subspace'
        : contentTypeSpace
        ? 'Space'
        : space.type;

    return `${title} ${space.nice_id}`;
  }

  renderSpaceCategory() {
    let title = this.getSpaceTitle(),
      titleClass = 'space-heading-title fs-12';
    titleClass += this.props.device.mobileDevice
      ? ' m-heading-tile '
      : ' web-heading-title';

    return (
      <div className={titleClass}>
        <OrthoIcon
          name={getEntityIcon(this.getSpace())}
          defaultClass='v-align-middle me-1'
          dataHoverNotRequired={true}
        />
        <span className='text-uppercase fs-12'> {title}</span>
      </div>
    );
  }

  isAuthorOrEditor() {
    return this.props.space.is_author_or_editor;
  }

  renderSpaceType() {
    let space = this.getSpace(),
      title = this.getSpaceTitle(),
      mobile = this.props.device.mobileDevice,
      titleClass = 'space-heading-title fs-12 ';
    titleClass += mobile ? ' m-heading-tile ' : ' web-heading-title';
    return (
      <div className={titleClass}>
        <OrthoIcon
          name={getEntityIcon(space)}
          defaultClass='me-1'
          dataHoverNotRequired={true}
        />
        <span className='text-uppercase fs-12'> {title}</span>
      </div>
    );
  }

  spaceEditImage() {
    return <div className='edit-btn'>{this.renderEditImageButton()}</div>;
  }

  renderEditImageButton(source = '') {
    let isUnderDropdown = source === 'dropdown',
      extraClass =
        this.props.device.mobileDevice || isUnderDropdown
          ? ' list-button '
          : ' web-view-btn back-nav-btn ';
    return (
      <OsBtn
        name='BtnIcon'
        icon='image'
        text='Edit Image'
        extraClass={extraClass}
        onClick={this.props.openEditImageModal.bind(this, {
          space: this.getSpace(),
        })}
      />
    );
  }

  spaceTitle() {
    return isContentTypeSpace(this.getSpace())
      ? this.renderSpaceCategory()
      : this.renderSpaceType();
  }

  needSpaceActionsOnImage() {
    return isGuestUser() || !this.isAuthorOrEditor();
  }

  mobileSpaceType() {
    return (
      <div className='m-header-action d-flex justify-content-between align-items-center'>
        {this.spaceTitle()}
        {/* { !isGuestUser() && <SpaceActions space={this.getSpace()} /> }
      { isGuestUser() && <OsBtn name='BtnIcon' extraClass="no-text v-align-top card-btn-more " onClick={this.props.redirectToDefault} icon="more" /> */}
      </div>
    );
  }

  renderHeroImage() {
    let { image_attachment } = this.getSpace().space_banner || {},
      articleClass = isEmpty(image_attachment) ? 'no-hero-image' : '',
      mobile = this.props.device.mobileDevice;

    return (
      <article className={`space-header ${articleClass}`}>
        {!isEmpty(image_attachment) && (
          <div className='space-header-hero-parent'>
            <img src={image_attachment.preview_url} alt='' className='img' />
            <span className='header-mask'></span>
            {!mobile && this.spaceTitle()}
            {!mobile &&
              !isGuestUser() &&
              this.isAuthorOrEditor() &&
              this.spaceEditImage()}
            {this.needSpaceActionsOnImage() && !mobile && (
              <SpaceActions
                space={this.getSpace()}
                extraClass='unsubscribed-space-actions'
              />
            )}
          </div>
        )}
        {mobile && !isCareSpace(this.getSpace()) && this.mobileSpaceType()}
      </article>
    );
  }

  render() {
    return this.renderHeroImage();
  }
}

SpaceHeroImage = connect(
  ({ currentUser, device, guestUser, systemConfig }) => ({
    currentUser,
    device,
    reducerGuestUser: guestUser,
    systemConfig,
  }),
  { openEditImageModal },
)(SpaceHeroImage);
SpaceHeroImage = withAuthenticate(SpaceHeroImage);
export default SpaceHeroImage;
