import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import RecordsView from 'app/components/RecordsView';
import InboxSection from 'app/components/Inbox/InboxSection';

const GroupsInboxComponent = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { workspace } = props;

  const updateUnreadCount = (count) => {
    setUnreadCount(count);
  };

  const renderUnreadGroups = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        updateCount={updateUnreadCount}
        type={'MyUnreadGroups'}
        queryType={'GROUP_INBOX_LIST'}
        sectionTitle={'Unread'}
        actionType={'MyUnreadGroups'}
        key={workspace?.data?.unread_group_inbox_count}
      />
    );
  };

  const renderReadGroups = () => {
    return (
      <RecordsView
        page={0}
        perPage={25}
        type={'MyReadGroups'}
        queryType={'GROUP_INBOX_LIST'}
        sectionTitle={'Previously Read'}
        actionType={'MyReadGroups'}
        key={workspace?.data?.unread_group_inbox_count}
      />
    );
  };
  return (
    <div className='inbox-wrapper'>
      <div className='inbox'>
        <InboxSection
          title={'Unread • Groups'}
          type={'group_inbox'}
          hideSection={unreadCount === 0}
          markReadButton={true}>
          {(showBody) => {
            return <>{renderUnreadGroups()}</>;
          }}
        </InboxSection>
        <InboxSection title={'Previously Read'} expandable={unreadCount > 0}>
          {(showBody) => {
            return <>{showBody && renderReadGroups()}</>;
          }}
        </InboxSection>
      </div>
    </div>
  );
};
const GroupsInboxConnect = connect(({ workspace }) => ({ workspace }))(
  GroupsInboxComponent,
);
const GroupsInbox = withRouter(GroupsInboxConnect);

export default GroupsInbox;
