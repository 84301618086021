import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { FORM_ERROR } from 'final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import calendar from 'app/images/task-manager/calendar.svg';

import RecordsView from 'app/components/RecordsView';
import CountrySelect from 'app/components/Shared/CountrySelect';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import PersonHeaderInModal from 'app/components/PatientForm/PersonHeaderInModal';
import StateSelect from 'app/components/Shared/StateSelect';
import { closePatientModal, openPatientModal } from 'app/actions/patientModal';
import {
  closeInfoModal,
  openInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';
import { capitalize, isPresent } from 'app/utils/stringHelper';
import {
  composeValidators,
  emailMustBeValid,
  emailMustNotEqualToUserEmail,
  fieldRequired,
  removeSpecialCharactersFromPhoneNumber,
} from 'app/utils/validationHelper.js';
import {
  camelCase,
  cloneDeep,
  debounce,
  filter,
  isEmpty,
  keys,
} from 'app/utils/osLodash';
import { isMinorAge, stringToDate } from 'app/utils/timeHelper';
import { fillForm } from './FormHelper';
import AssociateLabelDropdown from 'app/components/Shared/AssociateLabelDropdown';
import StoreUpdater from 'app/services/StoreUpdater';
import { flashSuccess, translate } from 'app/actions/flashMessage';
import { hexToRgb } from 'app/utils/colorHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import {
  PATIENT_CREATED_SUCCESSFULLY,
  PATIENT_UPDATED_SUCCESSFULLY,
} from 'app/components/PatientForm/constants';
import { setActiveResourcePatient } from 'app/actions/activeResource';

const GENDER_MAPPER = {
  male: 'Male',
  female: 'Female',
  non_binary: 'Non-binary',
};
const ADD_CONTACT_TAB_IDENTIFIER = 'add_patient';
const GUARDIAN_TAB_IDENTIFIER_PREFIX = 'guardian_';
const PATIENT_TAB_IDENTIFIER = 'patient';
const PATIENT_DETAILS_QUERY = queryLoader(
  'app/graphql/queries/Care/PatientDetailsQuery.gql',
);
const PERSON_DETAILS = queryLoader(
  'app/graphql/Care/Queries/People/PersonDetail.gql',
);
const SHARE_WITH_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithPatient.gql',
);
const UPDATE_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/UpdatePatient.gql',
);
const CONVERT_CONTACT_INTO_PATIENT = queryLoader(
  'app/graphql/mutations/Care/ConvertContactIntoPatient.gql',
);

const PATIENT_LIST = queryLoader('app/graphql/queries/VisitorDirectory.gql');

class PatientForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.returnErrors = {};
    this.debouncedSearchQuery = debounce(() => {
      this.setState({ debouncedSearchText: this.getCurrentTabSearchText() });
    }, 500);
  }

  defaultState() {
    return {
      guardianCount: 0,
      requestInProgress: false,
      minorPatient: false,
      activeTab: PATIENT_TAB_IDENTIFIER,
      tabStatus: [{ autoFill: true, searchQuery: '' }],
      initialForm: this.getIntialValues(),
      addedContacts: [],
      patient: {},
      associatedLabel: {},
      debouncedSearchText: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.patientModal.open && this.isOpen()) {
      if (!!this.careSpaceId()) this.fetchPatientData();

      if (this.props.patientModal.newPersonId)
        this.fetchContactDetails(this.props.patientModal.newPersonId);
    }

    if (prevProps.patientModal.open && !this.isOpen()) {
      this.setState({
        guardianCount: 0,
        tabStatus: [{ autoFill: false, searchQuery: '' }],
      });
    }
  }

  careSpaceId() {
    return this.props.patientModal.careSpaceId;
  }

  emailMustNotEqualToCurrentUser = (value) => {
    if (!this.isResponseParty())
      return emailMustNotEqualToUserEmail(value, this.props.currentUser.graph);
  };

  guardianIdentifier(index) {
    return `guardians.${index}`;
  }

  isGuardianRequired() {
    return !this.props.patientModal.guardiansNotRequired;
  }

  fetchPatientData() {
    this.setState({ fetchingPatientData: true });
    this.props.client
      .query({
        query: PATIENT_DETAILS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          id: this.careSpaceId(),
          guardiansNotRequired: !this.isGuardianRequired(),
        },
      })
      .then(({ data }) => {
        let patient = data.space.owner.patient,
          guardianCount = patient?.person_connections?.length || 0,
          dobAsRequired =
            patient.date_of_birth && stringToDate(patient.date_of_birth),
          formData = {};

        this.setState({ guardianCount, patient }, () => {
          if (patient) {
            formData['id'] = patient.id;
            formData = fillForm(formData, patient);
            formData['phoneNumber'] = patient.phone_number;
            formData['doctorId'] = data.space.owner.doctor_id;
            formData['guardians'] = [];
            (patient.person_connections || []).forEach((connection, index) => {
              let guardian = connection.related_person;

              formData['guardians'][index] = {
                person_id: guardian.id,
                firstName: guardian.first_name,
                lastName: guardian.last_name,
                preferredName: guardian.preferred_name,
                email: guardian.email,
                countryCode: guardian.country_code || '1',
                phoneNumber: guardian.phone_number,
                relationshipStatus: connection.relation,
                id: connection.id,
                address: {
                  addressLine1: guardian.address_object.address_line_1,
                  addressLine2: guardian.address_object.address_line_2,
                  city: guardian.address_object.city,
                  countryId: guardian.address_object.country_id,
                  stateId: guardian.address_object.state_id,
                  zipcode: guardian.address_object.zipcode,
                },
              };
            });
            setTimeout(() => {
              this.form.initialize(formData);
              dobAsRequired && this.onDateOfBirthChange(dobAsRequired);
            }, 100);
          }
        });
        this.setState({
          fetchingPatientData: false,
          tabStatus: Array(guardianCount + 1).fill({
            autoFill: false,
            searchQuery: '',
          }),
          initialForm: formData,
        });
      });
  }

  isOnPatientTab() {
    return this.getGuardianIndex() === -1;
  }

  fetchContactDetails(personId) {
    this.setState({ requestInProgress: true, fetchingPatientData: true });
    this.props.client
      .query({
        query: PERSON_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          id: personId,
        },
      })
      .then(({ data }) => {
        let person = data.person,
          formData = this.state.initialForm;
        if (this.isOnPatientTab() && person.care_space) {
          this.closeModal();
          setTimeout(() => {
            this.props.openPatientModal(person.care_space.nice_id);
          }, 50);
        } else {
          if (this.isOnPatientTab() && person) {
            formData['person_id'] = person.id;
            formData = fillForm(formData, person);
            formData['phoneNumber'] = person.phone_contact_number;
            this.form.initialize({ ...formData });
          } else if (person) {
            formData['guardians'][this.getGuardianIndex()] = {
              add_contact: person.id,
              person_id: person.id,
              firstName: person.first_name,
              lastName: person.last_name,
              email: person.email,
              countryCode: person.country_code || '1',
              phoneNumber: person.phone_contact_number,
              address: {
                addressLine1: person.address_object.address_line_1,
                addressLine2: person.address_object.address_line_2,
                city: person.address_object.city,
                countryId: person.address_object.country_id,
                stateId: person.address_object.state_id,
                zipcode: person.address_object.zipcode,
              },
            };
            const patientData = this.getAllPatientFieldValue();
            this.form.initialize({ ...formData, ...patientData });
          }
          this.setState({
            tabStatus: this.modifyAutoFill('addDirectly'),
            requestInProgress: false,
            fetchingPatientData: false,
          });
        }
      });
  }

  closeModal = () => {
    this.setState(this.defaultState());
    this.props.closePatientModal();
  };

  sanitizeParams(values) {
    let params = cloneDeep(values);
    params.firstName = capitalize(values.firstName);
    params.lastName = capitalize(values.lastName);
    params.preferredName = capitalize(values.preferredName);
    params.dob = values.dob.toString();
    params.phoneNumber = removeSpecialCharactersFromPhoneNumber(
      values.phoneNumber,
    );
    params.id = params.person_id;
    params.labelIds = this.state.associatedLabel?.id
      ? [parseInt(this.state.associatedLabel?.id, 10)]
      : [];
    if (!params.address) params.address = {};
    delete params.addressRequired;
    delete params.person_id;
    if (values.guardians) {
      values.guardians.forEach((guardian, index) => {
        params.guardians[index].firstName = capitalize(
          values.guardians[index].firstName,
        );
        params.guardians[index].lastName = capitalize(
          values.guardians[index].lastName,
        );
        params.guardians[index].preferredName = capitalize(
          values.guardians[index].preferredName,
        );
        params.guardians[index].phoneNumber =
          removeSpecialCharactersFromPhoneNumber(
            values.guardians[index].phoneNumber,
          );
        if (params.guardians[index].addressSameAsPatient)
          params.guardians[index].address = params.address;
        delete params.guardians[index].addressSameAsPatient;
        delete params.guardians[index].person_id;
        delete params.guardians[index].add_contact;
      });
    }
    if (this.isEditView()) delete params.labelIds;
    params.careSpaceId = this.careSpaceId();
    return params;
  }

  isRedirectRequired() {
    return this.props.patientModal.source !== 'care-space-detail';
  }

  shareWithPatient = (careSpaceId) => {
    this.props.updateInfoModalOptions({ primaryCtaRequestInProgress: true });
    this.props
      .shareWithPatient({
        variables: {
          careSpaceId,
          reshare: false,
        },
      })
      .then(({ data }) => {
        this.props.closeInfoModal();
      });
  };

  showInfoCopyModalIfRequired(updatedEmail) {
    let { patient } = this.state,
      dirtyFields = this.form.getState().dirtyFields || {},
      fieldsChanged = keys(dirtyFields),
      onlyIdentificationNumberChanged =
        fieldsChanged.length === 1 &&
        fieldsChanged[0] === 'identificationNumber';

    if (
      !this.isResponseParty() &&
      !onlyIdentificationNumberChanged &&
      !!fieldsChanged.length
    ) {
      if (patient.user_onboarded) {
        this.props.openInfoModal('care_space', 'person_changes_success', {
          contentInterpolations: { name: patient.name },
        });
      } else if (this.isEmailChanged(updatedEmail)) {
        this.props.openInfoModal('care_space', 'email_changed_successfully', {
          contentInterpolations: { name: patient.name },
          onSuccess: this.shareWithPatient.bind(this, this.careSpaceId()),
          closeModalNotRequiredOnPrimaryClick: true,
        });
      }
    }
  }

  addRecordToPatientList(patient) {
    StoreUpdater.addDataInRecords(patient, this.props.patientModal.variables, {
      query: PATIENT_LIST,
    });
  }

  onSubmit = async (values) => {
    console.log('onSubmit');
    this.setState({ requestInProgress: true });
    let handler = this.props.patientModal.newPersonId
      ? 'convertContactIntoPatient'
      : 'updatePatient';
    const response = await this[handler](values);
    const data = await response.data;
    this.setState({ requestInProgress: false });
    if (data.updatePatient.success) {
      if (
        this.props.patientModal.variables &&
        this.props.patientModal.careSpaceId === null
      )
        this.addRecordToPatientList(data.updatePatient.entity.owner.patient);
      this.isEditView() && this.showInfoCopyModalIfRequired(values.email);
      if (this.isEditView()) {
        this.props.flashSuccess(PATIENT_UPDATED_SUCCESSFULLY, false);
      } else {
        this.props.flashSuccess(PATIENT_CREATED_SUCCESSFULLY, false);
      }
      this.closeModal();
      this.props.setActiveResourcePatient(
        data.updatePatient.entity.owner.patient,
      );
    } else {
      data.updatePatient.errors.forEach((obj) => {
        if (
          [
            'first_name',
            'last_name',
            'preferred_name',
            'phone_number',
            'email',
            'identification_number',
          ].includes(obj.field)
        ) {
          this.returnErrors[camelCase(obj.field)] = obj.message;
        } else if (obj.field.startsWith('guardians.')) {
          this.returnErrors['guardians'] = this.returnErrors['guardians'] || [];
          let [index, field] = obj.field.split('.'),
            fieldError = {};
          fieldError[field] = obj.message;
          this.returnErrors['guardians'][index] = {
            ...(this.returnErrors['guardians'][index] || {}),
            ...fieldError,
          };
        }
      });
      if (Object.keys(this.returnErrors).length) {
        return this.returnErrors;
      } else {
        return { [FORM_ERROR]: translate('CREATE_ERROR') };
      }
    }
  };

  updatePatient = (values) => {
    return this.props.updatePatient({
      variables: {
        ...this.sanitizeParams(values),
        labelIds: [parseInt(this.state.associatedLabel?.id)],
        guardiansModificationsNotRequired: !this.isGuardianRequired(),
      },
    });
  };

  convertContactIntoPatient = (values) => {
    return this.props.convertContactIntoPatient({
      variables: {
        ...this.sanitizeParams(values),
        labelIds: [parseInt(this.state.associatedLabel?.id)],
        guardiansModificationsNotRequired: !this.isGuardianRequired(),
      },
    });
  };

  isCurrentTabNotFilled() {
    return this.getCurrentTabStatus()?.autoFill;
  }

  getCurrentTabSearchText() {
    return this.getCurrentTabStatus()?.searchQuery;
  }

  onLabelClick(label) {
    if (label?.sub_labels?.length > 0) return;
    this.setState({ associatedLabel: label });
  }

  getAssociatedLabel() {
    if (isEmpty(this.state.associatedLabel)) {
      return {
        entity_label: null,
      };
    }
    return {
      entity_label: {
        label: this.state.associatedLabel,
      },
    };
  }

  getCurrentTabStatus() {
    return this.state.tabStatus[this.getGuardianIndex() + 1];
  }

  fillDataFromSearch() {
    let text = this.getCurrentTabSearchText(),
      email = '',
      name = '',
      formData = this.state.initialForm;
    !emailMustBeValid(text) ? (email = text) : (name = text.split(' '));

    if (this.isOnPatientTab()) {
      formData['firstName'] = name[0];
      formData['lastName'] = name[1];
      formData['email'] = email;
    } else {
      formData['guardians'][this.getGuardianIndex()] = {
        firstName: name[0],
        lastName: name[1],
        email: email,
      };
    }
    this.form.initialize(formData);
    this.setState({ tabStatus: this.modifyAutoFill('addDirectly') });
  }

  modifyAutoFill(flag) {
    let curr_tabStatus = this.state.tabStatus;
    let index = this.getGuardianIndex() + 1;
    if (flag === 'addContact') {
      curr_tabStatus.push({ autoFill: true, searchQuery: '' });
    } else if (flag === 'removeContact') {
      curr_tabStatus.splice(index, 1);
    } else if (flag === 'addDirectly') {
      curr_tabStatus[index].autoFill = false;
    }
    return curr_tabStatus;
  }

  isOpen() {
    return this.props.patientModal.open;
  }

  addGuardian = () => {
    this.setState(
      {
        guardianCount: this.state.guardianCount + 1,
        tabStatus: this.modifyAutoFill('addContact'),
      },
      () => {
        this.setState({
          activeTab: this.guardianTabIdentifier(this.state.guardianCount - 1),
        });
        this.setFieldValue(
          `${this.guardianIdentifier(
            this.state.guardianCount - 1,
          )}.addressSameAsPatient`,
          true,
        );
      },
    );
  };

  removeGuardian = (index) => {
    let { guardians } = this.form.getState().values;
    guardians.splice(index, 1);
    this.form.change('guardians', guardians);
    this.setState({
      guardianCount: this.state.guardianCount - 1,
      activeTab: PATIENT_TAB_IDENTIFIER,
      tabStatus: this.modifyAutoFill('removeContact'),
    });
  };

  onCountryChange = (phoneNumberFieldName, field, value) => {
    this.setFieldValue(field, value);
    this.setFieldValue(phoneNumberFieldName, '');
  };

  setFieldValue = (field, value) => {
    this.form.change(field, value);
  };

  getAllPatientFieldValue = () => {
    const values = this.form.getState().values;
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      preferredName: values.preferredName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      gender: values.gender,
      identificationNumber: values.identificationNumber,
      address: values.address,
      dob: values.dob,
      countryCode: values.countryCode,
      doctorId: values.doctorId,
    };
  };

  getPatientFieldValue(fieldName) {
    return this.form.getState().values[fieldName];
  }

  getGuardiansFieldValue(nestedFieldName) {
    let [field, index, property] = nestedFieldName.split('.'),
      guardians = this.form.getState().values[field];

    return guardians && guardians[index] && guardians[index][property];
  }

  onSameAsPatientCheckboxChange = (identifier, e) => {
    this.setFieldValue(
      `${identifier.split('.').slice(0, 2).join('.')}.address`,
      { countryId: this.getClinicCountryId() },
    );
  };

  getCountryCodeFieldProps() {
    if (!this.isEditView()) return { setFormInitialValue: this.setFieldValue };
  }

  onChange = (event) => {
    let curr_tabStatus = this.state.tabStatus;
    curr_tabStatus[this.getGuardianIndex() + 1].searchQuery =
      event.target.value;
    this.setFieldValue('add_contacts', '');
    this.setState({ tabStatus: curr_tabStatus });
  };

  getAttachContactId = (index) => {
    const contacts = this.form.getState().values;
    return (
      contacts.guardians[index]?.add_contact ||
      contacts.guardians[index]?.person_id
    );
  };

  showExistingContacts(index) {
    let searchText = this.state.debouncedSearchText,
      contacts = this.form.getState().values,
      removeContacts = [];
    if (contacts.person_id) {
      removeContacts.push({ id: contacts.person_id });
    }
    contacts.guardians.forEach((person, loopIndex) => {
      if (loopIndex !== index) {
        removeContacts.push({ id: person.person_id });
      }
    });
    // Greater than 1 because there is a space between firstName and lastName.
    if (searchText?.length > 1) {
      return (
        <RecordsView
          type='AllUsersAddedByClinic'
          textQuery={searchText.trim()}
          queryType='USERS_BY_CLINIC'
          cardProps={{
            selectionMode: this.isOnGuardianTab(),
            size: 'small',
            isPatient: !this.isOnGuardianTab(),
            onPatientClick: this.onAddToContact,
            notMandatory: true,
            name: this.isOnGuardianTab()
              ? `${this.guardianIdentifier(index)}.add_contact`
              : 'add_contact',
            contact: this.isOnGuardianTab()
              ? this.getAttachContactId(index)
              : contacts.add_contact,
          }}
          cardGridIdentifier={'PatientModal:Perosn'}
          recordsNotToBeIncludes={removeContacts}
        />
      );
    }
  }

  getPlaceholderText() {
    if (this.isOnGuardianTab()) {
      return "Contact's full name or email";
    } else {
      return "Patient's full name or email";
    }
  }

  getSearchLabelText() {
    if (this.isOnGuardianTab()) {
      return 'Search for existing contacts';
    } else {
      return 'Search for existing patients';
    }
  }

  renderSearch() {
    return (
      <div className='nav-search-container'>
        <label className='search-label-group not-mandatory'>
          <div className='mb-2'>
            {this.getSearchLabelText()}
            <span className='cl-l9'>*</span>
          </div>
          <OsField
            osType='input'
            type='text'
            placeholder={this.getPlaceholderText()}
            className={'w-100'}
            name='search'
            onChange={this.onChange}></OsField>
        </label>
      </div>
    );
  }

  updateQueryFirstName = (event) => {
    const { value } = event.target;
    let guardians = this.form.getState().values.guardians;
    let firstName = value ? value : '';
    let lastName = guardians[this.getGuardianIndex()].lastName
      ? guardians[this.getGuardianIndex()].lastName
      : '';
    this.updateSearchQuery(firstName + ' ' + lastName);
    this.debouncedSearchQuery();
  };

  updateQueryLastName = (event) => {
    const { value } = event.target;
    let guardians = this.form.getState().values.guardians;
    let firstName = guardians[this.getGuardianIndex()].firstName
      ? guardians[this.getGuardianIndex()].firstName
      : '';
    let lastName = value ? value : '';
    this.updateSearchQuery(firstName + ' ' + lastName);
    this.debouncedSearchQuery();
  };

  linkPatientToGuardianForm = (index) => {
    let guardianIdentifier = this.guardianIdentifier(index);
    const formValues = this.formValues;
    if (formValues && formValues.guardians[index]?.add_contact) {
      if (
        formValues.guardians[index].person_id !==
        formValues.guardians[index]?.add_contact
      ) {
        let value = formValues.guardians[index]?.add_contact;
        this.setFieldValue(`${guardianIdentifier}.add_contact`, '');
        this.onAddToContact(value);
      }
    }
  };

  renderGuardianForm = (index) => {
    let guardianIdentifier = this.guardianIdentifier(index);
    this.linkPatientToGuardianForm(index);
    return (
      <>
        <div className='guardian-form'>
          <Field
            name={`${guardianIdentifier}.person_id`}
            component={OsField}
            osType='input'
            hidden
          />
          <div className='guardian-firstname-field patient-name-field'>
            <Field
              name={`${guardianIdentifier}.firstName`}
              component={OsField}
              osType='input'
              label='Contact First name'
              autoComplete='off'
              placeholder='e.g. Jane'
              onChange={this.updateQueryFirstName.bind(this)}
              validate={fieldRequired}
            />
          </div>
          <div className='guardian-lastname-field patient-name-field'>
            <Field
              name={`${guardianIdentifier}.lastName`}
              component={OsField}
              osType='input'
              label='Contact Last name'
              autoComplete='off'
              placeholder='e.g. Jones'
              onChange={this.updateQueryLastName.bind(this)}
              validate={fieldRequired}
            />
          </div>
          {/*<div className='guardian-preferredname-field patient-name-field'>*/}
          {/*  <label className='not-mandatory'>Preferred name</label>*/}
          {/*  <Field name={`${guardianIdentifier}.preferredName`} component={OsField} osType='input' placeholder='e.g. Jones'/>*/}
          {/*</div>*/}
          <div className='guardian-email-field'>
            <Field
              name={`${guardianIdentifier}.email`}
              component={OsField}
              autoComplete='off'
              osType='input'
              label='Contact Email'
              labelClassName='not-mandatory'
              placeholder='name@gmail.com'
              validate={composeValidators(
                emailMustBeValid,
                this.emailMustNotEqualToCurrentUser,
              )}
            />
          </div>
          <div className='guardian-phone-field os-menu-drop'>
            <label className='not-mandatory'>Contact phone number</label>
            <div className='cell-row'>
              <Field
                name={`${guardianIdentifier}.countryCode`}
                osType='country_code_select'
                component={OsField}
                {...this.getCountryCodeFieldProps()}
                onChange={this.onCountryChange.bind(
                  this,
                  `${guardianIdentifier}.phoneNumber`,
                )}
              />
              <Field
                name={`${guardianIdentifier}.phoneNumber`}
                component={OsField}
                autoComplete='off'
                {...this.getPhoneNumberProps(
                  `${guardianIdentifier}.countryCode`,
                )}
              />
            </div>
          </div>
          <div className='guardian-relationship-field os-menu-drop with-top-position'>
            <Field
              osType='selectInput'
              component={OsField}
              label='Relationship to patient'
              name={`${guardianIdentifier}.relationshipStatus`}
              onChange={this.setFieldValue}
              validate={fieldRequired}
              async={true}
              queryType='RelatedPersonTypes'
              selectedValueNameRequired={true}
              placeholder='Select'
            />
          </div>
          <div className='guardian-address notify-with-label'>
            <label className='not-mandatory'>Address</label>
            <Field
              component={OsField}
              className='os-custom-checkbox'
              label='Same as patient'
              osType='checkbox'
              type='checkbox'
              name={`${guardianIdentifier}.addressSameAsPatient`}
              isChecked={this.getGuardiansFieldValue(
                `${guardianIdentifier}.addressSameAsPatient`,
              )}
              onChange={this.onSameAsPatientCheckboxChange.bind(
                this,
                `${guardianIdentifier}.addressSameAsPatient`,
              )}
            />
          </div>
          {!this.getGuardiansFieldValue(
            `${guardianIdentifier}.addressSameAsPatient`,
          ) && this.renderAddressFields(`${guardianIdentifier}.address`)}
        </div>
      </>
    );
  };

  isEditView() {
    return !!this.props.patientModal.careSpaceId;
  }

  onDateOfBirthChange = (date) => {
    if (!date) return null;
    if (this.isGuardianRequired()) {
      if (isMinorAge(date)) {
        let { guardianCount, tabStatus } = this.state;
        this.setState({
          minorPatient: true,
          guardianCount: guardianCount === 0 ? 1 : guardianCount,
          tabStatus:
            guardianCount === 0 ? this.modifyAutoFill('addContact') : tabStatus,
        });
      } else {
        this.setState({ minorPatient: false });
      }
    } else {
      this.setState({ minorPatient: isMinorAge(date) });
    }
    this.form.change('dob', date);
  };

  emailFieldValidationBasedOnAge() {
    let disabled =
      isPresent(this.formValues.email) && isPresent(this.formValues.person_id);
    if (this.paitentDetailsNotMandatory()) {
      return { disabled };
    } else {
      return {
        disabled,
        validate: composeValidators(
          emailMustBeValid,
          this.emailMustNotEqualToCurrentUser,
        ),
      };
    }
  }

  getPhoneNumberProps(countryCodeFieldName) {
    let countryCodeValue = countryCodeFieldName.startsWith('guardians.')
      ? this.getGuardiansFieldValue(countryCodeFieldName)
      : this.getPatientFieldValue(countryCodeFieldName);
    if (countryCodeValue === '1') {
      return { osType: 'formatted_input' };
    } else {
      return { osType: 'input' };
    }
  }

  getGenderOptions() {
    let options = [];
    keys(GENDER_MAPPER).forEach((key) => {
      options.push({ label: GENDER_MAPPER[key], value: key });
    });
    return options;
  }

  getPatientCountryId() {
    return this.form.getState().values?.address?.countryId;
  }

  getGuardianCountryId(addressIdentifier) {
    let [field, index, property] = addressIdentifier.split('.'),
      guardians = this.form.getState().values[field];

    return (
      guardians && guardians[index] && guardians[index][property]?.countryId
    );
  }

  renderAddressFields(identifier) {
    let commonProps = { labelClassName: 'not-mandatory' },
      countryId =
        identifier === 'address'
          ? this.getPatientCountryId()
          : this.getGuardianCountryId(identifier);

    return (
      <>
        <div className='patient-form-field patient-street-field'>
          <Field
            name={`${identifier}.addressLine1`}
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Street address'
            placeholder='e.g. 100 Broadway st.'
            {...commonProps}
          />
        </div>
        <div className='patient-form-field patient-apt-field'>
          <Field
            name={`${identifier}.addressLine2`}
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Apt, suite etc. (optional)'
            placeholder='e.g. Apt 100'
            {...commonProps}
          />
        </div>
        <div className='patient-form-field patient-city-field'>
          <Field
            name={`${identifier}.city`}
            osType='input'
            autoComplete='off'
            component={OsField}
            label='City'
            placeholder='e.g. Minneapolis'
            {...commonProps}
          />
        </div>
        <div className='patient-form-field patient-state-field os-menu-drop only-option-text'>
          <Field
            name={`${identifier}.stateId`}
            component={StateSelect}
            label='State'
            countryId={countryId}
            placeholder='Select'
            {...commonProps}
          />
        </div>
        <div className='patient-form-field patient-zip-field '>
          <Field
            name={`${identifier}.zipcode`}
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Zip code'
            placeholder='e.g. 55408'
            {...commonProps}
          />
        </div>
        <div className='patient-form-field patient-country-field  os-menu-drop only-option-text countrycode-drop with-top-position'>
          <Field
            name={`${identifier}.countryId`}
            component={CountrySelect}
            label='Country'
            placeholder='Select'
            {...commonProps}
          />
        </div>
      </>
    );
  }

  paitentDetailsNotMandatory() {
    return this.state.minorPatient || this.state.guardianCount > 0;
  }

  getClinicCountryId() {
    return this.props.workspace.data?.owner?.owner?.address_object?.country_id;
  }

  getIntialValues() {
    if (this.isEditView()) {
      return { guardians: [] };
    } else {
      return {
        address: { countryId: this.getClinicCountryId() },
        guardians: [],
      };
    }
  }

  updateSearchQuery(query) {
    let curr_tabStatus = this.state.tabStatus;
    curr_tabStatus[this.getGuardianIndex() + 1].searchQuery = query;
    this.setFieldValue('add_contacts', '');
    this.setState({ tabStatus: curr_tabStatus });
  }

  searchFirstName(event) {
    const { value } = event.target;
    let firstName = value ? value : '';
    let lastName = this.form.getState().values['lastName']
      ? this.form.getState().values['lastName']
      : '';
    this.updateSearchQuery(firstName + ' ' + lastName);
    this.debouncedSearchQuery();
  }

  searchLastName(event) {
    const { value } = event.target;
    let firstName = this.form.getState().values['firstName']
      ? this.form.getState().values['firstName']
      : '';
    let lastName = value ? value : '';
    this.updateSearchQuery(firstName + ' ' + lastName);
    this.debouncedSearchQuery();
  }

  renderForm() {
    let isResponseParty = this.isResponseParty();

    return (
      <div className='add-patient-form'>
        <Field name={'person_id'} component={OsField} osType='hidden' />
        <Field
          component={OsField}
          osType='hidden'
          name='id'
          formGroupExtraClass='d-none'
        />
        <div className='patient-form-field patient-firstname-field'>
          <Field
            name='firstName'
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Patient First name'
            placeholder='e.g. Jane'
            onChange={this.searchFirstName.bind(this)}
            validate={fieldRequired}
          />
        </div>
        <div className='patient-form-field patient-lastname-field'>
          <Field
            name='lastName'
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Patient Last name'
            placeholder='e.g. Jones'
            onChange={this.searchLastName.bind(this)}
            validate={fieldRequired}
          />
        </div>
        <div className='patient-form-field patient-preferredname-field'>
          <label className='not-mandatory'>Patient Preferred name</label>
          <Field
            name='preferredName'
            component={OsField}
            osType='input'
            autoComplete='off'
            placeholder='e.g. Jones'
          />
        </div>
        <div className='patient-form-field patient-dob-field date-calendar'>
          <Field
            name={'dob'}
            osType={'newDate'}
            label='DOB'
            maxDate={new Date()}
            validate={fieldRequired}
            clearIcon={null}
            disabled={isResponseParty}
            onChange={this.onDateOfBirthChange}
            formValues={this.form.getState().values.dob}
            calendarIcon={
              <div>
                <img src={calendar} alt={'calendar-icon'} />
              </div>
            }
            component={OsField}
          />
        </div>

        <div className='patient-form-field patient-gender-field os-menu-drop only-option-text'>
          <Field
            name='gender'
            osType='selectInput'
            component={OsField}
            label='Gender'
            validate={fieldRequired}
            onChange={this.setFieldValue}
            options={this.getGenderOptions()}
            placeholder='Select'
          />
        </div>

        <div className='patient-form-field patient-id-field'>
          <Field
            name='identificationNumber'
            osType='input'
            autoComplete='off'
            component={OsField}
            label='Patient I.D number'
            placeholder='ID from current PMS'
            validate={fieldRequired}
            disabled={isResponseParty}
          />
        </div>
        <div className='patient-form-field patient-doctor-field os-menu-drop with-top-position'>
          <Field
            osType='selectInput'
            component={OsField}
            label='Primary doctor'
            name='doctorId'
            onChange={this.setFieldValue}
            validate={fieldRequired}
            async={true}
            queryType='WorkspaceOrthodontists'
            placeholder='Select'
            isDisabled={this.isEditView()}
          />
        </div>

        <div className='patient-form-field patient-cell-field os-menu-drop'>
          <label className='not-mandatory'>Patient phone number</label>
          <div className='cell-row'>
            <Field
              name='countryCode'
              osType='country_code_select'
              component={OsField}
              {...this.getCountryCodeFieldProps()}
              onChange={this.onCountryChange.bind(this, 'phoneNumber')}
              isDisabled={isResponseParty}
            />
            <Field
              name='phoneNumber'
              component={OsField}
              autoComplete='off'
              type='number'
              {...this.getPhoneNumberProps('countryCode')}
              disabled={isResponseParty}
            />
          </div>
        </div>
        <div className='patient-form-field patient-email-field'>
          <Field
            name='email'
            osType='input'
            component={OsField}
            label='Patient Email'
            autoComplete='off'
            placeholder='name@gmail.com'
            {...this.emailFieldValidationBasedOnAge()}
            labelClassName={'not-mandatory'}
            key={this.paitentDetailsNotMandatory()}
            disabled={isResponseParty}
          />
        </div>
        {/*<div className='col-3-30 switch-box notify-with-label'>*/}
        {/*  <label className='not-mandatory'>Address</label>*/}
        {/*  <Field component={OsField} className='os-custom-checkbox' label='Add patient address' type='checkbox' osType='checkbox' name='addressRequired' isChecked={this.getPatientFieldValue('addressRequired')}/>*/}
        {/*</div>*/}
        {/*{this.getPatientFieldValue("addressRequired") &&*/}
        {this.renderAddressFields('address')}
      </div>
    );
  }

  renderHeader() {
    const { space, patient } = this.props.patientModal;
    if (!this.isGuardianRequired() && space) {
      return <PersonHeaderInModal obj={space.owner.patient} space={space} />;
    } else {
      const headingText = this.isEditView()
        ? 'Edit patient'
        : 'Add new patient';
      return (
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <h4 className='modal-title' style={{ marginTop: '4px' }}>
            {headingText}
          </h4>
          <div
            className='v-tb-block cd-label hidden-mobile patient-label'
            style={{
              backgroundColor: this.state.associatedLabel.color
                ? hexToRgb(this.state.associatedLabel.color)
                : hexToRgb(patient?.entity_label?.label.color),
            }}>
            <AssociateLabelDropdown
              onLabelClick={this.onLabelClick.bind(this)}
              entity={
                !isEmpty(this.state.associatedLabel)
                  ? this.getAssociatedLabel()
                  : patient
              }
              position='right-start'
            />
          </div>
        </div>
      );
    }
  }

  renderPatientTabSubHeader() {
    if (!this.isResponseParty()) {
      return (
        <>
          <p>
            Enter <strong>Patient Information Only</strong>, NOT Parent/Guardian
            Information
          </p>
          <p>
            (for example, when creating patient “John Smith”, enter only John’s
            contact details here).
          </p>
        </>
      );
    }
  }

  renderPatientTab() {
    return (
      <Tab eventKey={PATIENT_TAB_IDENTIFIER} title='Patient information'>
        {this.renderPatientTabSubHeader()}
        {this.renderForm()}
        {!this.isResponseParty() &&
          this.isCurrentTab(-1) &&
          this.showExistingContacts()}
      </Tab>
    );
  }

  getActiveTab() {
    return this.state.activeTab;
  }

  handleTabSelect = (activeTab) => {
    if (activeTab === ADD_CONTACT_TAB_IDENTIFIER) {
      this.addGuardian();
    } else {
      this.setState({ activeTab });
    }
  };

  isCurrentTab(index) {
    return this.getGuardianIndex() === index;
  }

  guardianTabIdentifier(index) {
    return `${GUARDIAN_TAB_IDENTIFIER_PREFIX}${index}`;
  }

  renderGuardianTab(index) {
    return (
      <Tab
        eventKey={this.guardianTabIdentifier(index)}
        title={`Contact ${index + 1}`}>
        {
          <p>
            Enter <strong>Family Member/Guardian Information Only</strong>, NOT
            Patient Information
          </p>
        }
        <p>
          (for example, when creating patient “John Smith”, enter John’s
          guardian details here).
        </p>
        {this.renderGuardianForm(index)}
        {this.showExistingContacts(index)}
      </Tab>
    );
  }

  renderTabs() {
    return (
      <Tabs
        activeKey={this.getActiveTab()}
        id='patient_form_tabs'
        className='mb-3'
        onSelect={this.handleTabSelect}>
        {this.renderPatientTab()}
        {Array(this.state.guardianCount)
          .fill(0)
          .map((_, index) => {
            return this.renderGuardianTab(index);
          })}
        {/* For the add contact button */}
        {this.isGuardianRequired() && this.renderContactBtnTab()}
      </Tabs>
    );
  }

  renderContactBtnTab() {
    return (
      <Tab
        eventKey={ADD_CONTACT_TAB_IDENTIFIER}
        title='Add Guardian/Family Member'
        onClick={this.addGuardian}
        disabled={this.state.requestInProgress}
      />
    );
  }

  isOnGuardianTab() {
    return this.state.activeTab !== PATIENT_TAB_IDENTIFIER;
  }

  getGuardianIndex() {
    let index = this.state.activeTab?.replace(
      GUARDIAN_TAB_IDENTIFIER_PREFIX,
      '',
    );
    if (index === 'patient') {
      return -1;
    } else {
      return parseInt(index);
    }
  }

  renderRemoveGuardianButton() {
    let guardianIndex = this.getGuardianIndex(),
      guardianMandatory = this.state.minorPatient && guardianIndex === 0;

    if (!guardianMandatory)
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='web-view-btn r-0 with-border'
          icon='clear'
          text='Remove Contact'
          onClick={this.removeGuardian.bind(this, guardianIndex)}
        />
      );
  }

  switchToTabIfErrorsPresent() {
    let formState = this.form.getState();
    if (!formState.valid || !this.isAllAutoFillDone()) {
      let errors = formState.errors,
        tab = this.state.activeTab;

      if (
        filter(
          keys(errors),
          (key) => !['guardians', 'add_contacts'].includes(key),
        ).length > 0
      ) {
        tab = PATIENT_TAB_IDENTIFIER;
      } else if (
        filter(keys(errors), (key) => key !== 'add_contacts').length > 0
      ) {
        let errorGuardianIndex = errors['guardians'].findIndex((val) => !!val);
        tab = this.guardianTabIdentifier(errorGuardianIndex);
      } else {
        tab = this.switchToEmptyTab();
      }
      this.setState({ activeTab: tab });
      return true;
    }
    return false;
  }

  onSubmitClick = (event) => {
    const isErrorPresent = this.switchToTabIfErrorsPresent();
    if (!isErrorPresent) {
      this.submitForm(event);
    }
  };

  addDirectly = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.fillDataFromSearch();
  };

  onAddToContact = (value) => {
    this.fetchContactDetails(value);
  };

  isAllAutoFillDone() {
    // Now we are not using autofill thing anymore
    // return this.state.tabStatus.every(contact => {
    //   return !contact.autoFill
    // })
    return true;
  }

  directAddBtnText() {
    if (this.isOnGuardianTab()) {
      return 'Add Contact Directly';
    } else {
      return 'Add Patient Manually';
    }
  }

  afterSelectBtnText() {
    if (this.isOnGuardianTab()) {
      return 'Confirm Selection';
    } else {
      return 'Review Patient Details';
    }
  }

  isResponseParty() {
    let space = this.props.patientModal.space;
    return (
      isCareWorkspaceView() &&
      space &&
      !space.is_author_or_editor &&
      space.is_author_or_member
    );
  }

  renderSubmitButtons() {
    let commonProps = {
      htmlTag: 'button',
      extraClass: 'web-view-btn btn-save-info',
      disabled: this.state.requestInProgress,
      loaderRequired: this.state.requestInProgress,
    };
    return (
      <OsBtn
        type='submit'
        name='BtnPrimary'
        text='Save Information'
        onClick={this.onSubmitClick}
        {...commonProps}
      />
    );
  }

  switchToEmptyTab = () => {
    let emptyIndex = this.state.tabStatus.findIndex(
        (person) => person.autoFill,
      ),
      tab;
    if (emptyIndex != -1) {
      if (emptyIndex === 0) {
        tab = PATIENT_TAB_IDENTIFIER;
      } else {
        tab = this.guardianTabIdentifier(emptyIndex - 1);
      }
      return tab;
    }
  };

  isEmailChanged(updatedEmail) {
    return updatedEmail && this.state.patient.email !== updatedEmail;
  }

  isPatientEmailOrPhoneNumberPresent(values) {
    return values.email || values.phoneNumber;
  }

  isGuardiansEmailOrPhoneNumberPresent(guardians) {
    // check if any guardian has either an email or a phone number
    return guardians.some((guardian) => {
      // if the guardian has an email or a phone number, return true
      return guardian.email || guardian.phoneNumber;
    });
  }

  relatedPersonHasContactInfo() {
    let { person_connections } = this.state.patient;
    return person_connections.some((person) => {
      let { related_person } = person;
      return related_person.email || related_person.phoneNumber;
    });
  }

  initialContactValueHasChanged(values) {
    return (
      this.state.initialForm.email !== values.email ||
      this.state.initialForm.phoneNumber !== values.phoneNumber
    );
  }

  openContactInfoWarningModal() {
    this.props.openInfoModal(
      'general',
      'patient_form_warning_on_email_or_phone_not_present',
    );
  }

  submitConfirmation = async (values) => {
    if (this.isEditView()) {
      if (
        this.initialContactValueHasChanged(values) &&
        !this.isPatientEmailOrPhoneNumberPresent(values) &&
        !this.relatedPersonHasContactInfo()
      ) {
        this.openContactInfoWarningModal();
        return;
      }
    } else {
      if (
        !this.isPatientEmailOrPhoneNumberPresent(values) &&
        !this.isGuardiansEmailOrPhoneNumberPresent(values.guardians)
      ) {
        this.openContactInfoWarningModal();
        return;
      }
    }
    if (this.isEditView()) {
      let { patient } = this.state,
        emailChanged = this.isEmailChanged(values.email),
        commonProps = {
          contentInterpolations: { name: patient.name },
          onSuccess: this.onSubmit.bind(this, values),
        };
      if (patient.user_onboarded || this.isResponseParty()) {
        return this.onSubmit(values);
      } else {
        if (emailChanged) {
          this.props.openInfoModal('care_space', 'email_change_confirmation', {
            ...commonProps,
          });
        } else {
          this.props.openInfoModal(
            'care_space',
            'patient_detail_change_confirmation',
            { ...commonProps },
          );
        }
      }
    } else {
      if (this.isAllAutoFillDone()) {
        return await this.onSubmit(values);
      } else if (
        this.isCurrentTabNotFilled() &&
        this.getCurrentTabSearchText()
      ) {
        this.fillDataFromSearch();
      }
    }
  };

  render() {
    let open = this.isOpen(),
      className = this.isGuardianRequired() ? '' : 'only-patient-tab';
    return (
      <Modal
        show={open}
        animation={false}
        fullscreen={true}
        dialogClassName={`add-new-patient-modal ${className}`}
        backdropClassName='modal-backdrop-custom backdrop-message-modal'>
        <Modal.Header className='border-0 modal-header py-0 m-0 d-flex align-items-center'>
          {this.renderHeader()}
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn r-0'
            icon='close'
            label='Close modal'
            onClick={this.closeModal}
          />
        </Modal.Header>
        {this.state.fetchingPatientData && (
          <div className='overlay-modal'>
            <i className='fa fa-spinner fa-spin' />
          </div>
        )}
        <Form
          onSubmit={this.submitConfirmation}
          initialValues={this.state.initialForm}
          keepDirtyOnReinitialize={false}
          render={(props) => {
            this.form = props.form;
            this.formValues = props.values;
            this.submitForm = props.handleSubmit;
            return (
              <form
                name='addNewPatient'
                className='add_new_patient_form'
                onSubmit={this.submitForm}>
                <Modal.Body className=''>
                  {props.submitError && (
                    <div className='form-error error'>{props.submitError}</div>
                  )}
                  {this.renderTabs()}
                </Modal.Body>
                <Modal.Footer
                  style={{
                    position: 'absolute',
                    bottom: '40px',
                    right: '24px',
                  }}>
                  {this.isOnGuardianTab() && this.renderRemoveGuardianButton()}
                  {this.renderSubmitButtons()}
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal>
    );
  }
}

PatientForm = graphql(SHARE_WITH_PATIENT_MUTATION, {
  name: 'shareWithPatient',
})(PatientForm);
PatientForm = graphql(UPDATE_PATIENT_MUTATION, { name: 'updatePatient' })(
  PatientForm,
);
PatientForm = graphql(CONVERT_CONTACT_INTO_PATIENT, {
  name: 'convertContactIntoPatient',
})(PatientForm);
PatientForm = connect(
  ({ currentUser, patientModal, workspace }) => ({
    currentUser,
    patientModal,
    workspace,
  }),
  {
    closeInfoModal,
    closePatientModal,
    openInfoModal,
    openPatientModal,
    updateInfoModalOptions,
    flashSuccess,
    setActiveResourcePatient,
  },
)(PatientForm);
PatientForm = withApollo(PatientForm);
PatientForm = withRouter(PatientForm);
export default PatientForm;
