import React, { Component } from 'react';
import { Field } from 'react-final-form';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { fieldRequired } from 'app/utils/validationHelper';

import { isUndefined } from 'app/utils/osLodash';

class OsRadioField extends Component {
  render() {
    let { isDisabled, name, value, label, checked, notMandatory } = this.props,
      additionalAttributes = isUndefined(checked)
        ? {}
        : { checked: checked ? 'checked' : '' };

    additionalAttributes = notMandatory
      ? additionalAttributes
      : { ...additionalAttributes, validate: fieldRequired };

    return (
      <label
        className={
          'os-custom-radio ' + (isDisabled ? 'pointer-not-allowed' : '')
        }>
        <Field
          component='input'
          type='radio'
          name={name}
          id={value}
          value={value}
          disabled={isDisabled}
          {...additionalAttributes}
        />
        <OrthoIcon
          name='roundCheckDefault'
          dataHoverNotRequired={true}
          defaultClass='icon-helper default-uncheck'
        />
        <OrthoIcon
          name='radiobutton-selected'
          dataHoverNotRequired={true}
          defaultClass='icon-helper default-check'
        />
        <span className='label-helper'>{label}</span>
      </label>
    );
  }
}

OsRadioField.defaultProps = {
  isDisabled: false,
  name: '',
  value: '',
};

export default OsRadioField;
