import { ALPHABET_NUMBERS_REGEX } from 'app/constants';

export function keysPressedNonAlphabeticNonNumeric(keyPressed, key) {
  if (!keyPressed.length) {
    if (!ALPHABET_NUMBERS_REGEX.test(key)) {
      key !== 'Unidentified' && keyPressed.push(key);
    }
  } else {
    key !== 'Unidentified' && keyPressed.push(key);
  }
  return keyPressed;
}

export function isTabClicked(key) {
  return key === 'Tab';
}
