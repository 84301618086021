import {
  ADD_UNIVERSAL_BANNER,
  REMOVE_UNIVERSAL_BANNER,
  UNIVERSAL_BANNER_HIDE,
  UNIVERSAL_BANNER_SHOW,
  UPDATE_BANNER_VALUES,
} from './types';

export function showUniversalBanner({ bannerKey }) {
  return {
    type: UNIVERSAL_BANNER_SHOW,
    bannerKey,
  };
}

export function hideUniversalBanner({ bannerKey }) {
  return {
    type: UNIVERSAL_BANNER_HIDE,
    bannerKey,
  };
}

export function updateBannerValues(bannerKey, options = {}) {
  return {
    type: UPDATE_BANNER_VALUES,
    bannerKey,
    options,
  };
}

export function removeUniversalBanner(bannerKey) {
  return {
    type: REMOVE_UNIVERSAL_BANNER,
    bannerKey,
  };
}

export function addUniversalBanner(bannerProps, options = {}) {
  return {
    type: ADD_UNIVERSAL_BANNER,
    bannerProps,
    options,
  };
}
