import {
  CLOSE_COMMAND_MENU,
  OPEN_COMMAND_MENU,
} from 'app/components/DnD/CmdMenu/constants';

export const enableCommandMenu = () => {
  return {
    type: OPEN_COMMAND_MENU,
  };
};

export const disableCommandMenu = () => {
  return {
    type: CLOSE_COMMAND_MENU,
  };
};
