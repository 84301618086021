import { connect } from 'react-redux';

import NewAction from './NewAction';

import { assignCreate } from 'app/actions/assign';

class Comparison extends NewAction {
  constructor(props) {
    super(props);
    this.newButtonText = 'Add Comparison';
  }

  addNewAction = () => {
    this.props.assignCreate('Comparison');
  };

  render() {
    return this.renderAddNewButton();
  }
}

Comparison = connect(null, { assignCreate })(Comparison);

export default Comparison;
