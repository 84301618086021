import React from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractListing from './AbstractListing';
import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';
import RenderCards from 'app/components/Cards/RenderCards';

import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';
import { flashError, translate } from 'app/actions/flashMessage';
import {
  moveLinksToNewSection,
  setActiveDragDetails,
} from 'app/actions/spaceLinks';
import {
  openInfoModal,
  closeInfoModal,
  updateInfoModalOptions,
} from 'app/actions/infoModal';

import { indexOf, map, omit, pick } from 'app/utils/osLodash';
import {
  isCareSpace,
  isContentTypeSpace,
  isFolderSpace,
  isDynamicTemplate,
} from 'app/utils/spaceHelper';
import { getSpaceCardGridIdentifier } from 'app/utils/osCardHelper';

import StoreUpdater from 'app/services/StoreUpdater';

import { CARD_ACTION_CONTEXT } from 'app/components/OsCards/AbstractCard';

import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

const REMOVE_TAB_LINK_MUTATION = queryLoader('app/graphql/RemoveTabLink.gql');

class LinksListing extends AbstractListing {
  state = {
    assigningObject: false,
    movingLinksCount: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.spaceLinks.dragId &&
      nextProps.spaceLinks.dragId &&
      nextProps.spaceLinks.activeDragType === 'link'
    ) {
      let object = this.objectsToRender().find(
        (object) => object.link.id === nextProps.spaceLinks.dragId,
      );
      object && this.props.setActiveDragDetails({ dragLink: object });
    }

    let { destinationSectionId } = this.props.spaceLinks.linksDropOptions,
      nextPropsDestinationSectionId =
        nextProps.spaceLinks.linksDropOptions.destinationSectionId;
    if (
      !destinationSectionId &&
      nextPropsDestinationSectionId &&
      this.props.tabSectionId
    ) {
      // Note: Remove dragging cards from there original position AND
      // Add dragging cards to the destination position
      // before sending the drop request
      this.updateSpaceSectionLinks(nextProps.spaceLinks.linksDropOptions);
    }

    if (
      this.props.spaceLinks.requestInProgress &&
      !nextProps.spaceLinks.requestInProgress
    )
      this.setState({ movingLinksCount: 0 });

    super.componentWillReceiveProps(nextProps);
  }

  isMovingLinksToNewSection() {
    return (
      this.props.tabSectionId &&
      this.props.tabSectionId ===
        this.props.spaceLinks.moveLinksToNewSectionOptions.sourceSectionId
    );
  }

  moveCardsIntoNewSection = (card) => {
    let index = indexOf(map(this.props.results, 'id'), card.link.id),
      cards = this.props.results.slice(index).map(this.processLinkToObject),
      autofillCardsUpto = this.props.totalRecords - index;

    this.props.moveLinksToNewSection({
      sourceSectionId: this.props.tabSectionId,
      startFromLinkId: card.link.id,
      cards,
      autofillCardsUpto,
    });
  };

  processLinkToObject(result) {
    return {
      ...result.linkable,
      link: pick(
        result,
        'id',
        '__typename',
        'viewed_space_content',
        'description',
        'duration',
      ),
    };
  }

  processObjectToLink(result) {
    return { ...result.link, linkable: omit(result, 'link') };
  }

  splittedResults() {
    let index = indexOf(
      map(this.props.results, 'id'),
      this.props.spaceLinks.moveLinksToNewSectionOptions.startFromLinkId,
    );
    return this.props.results.slice(0, index).map(this.processLinkToObject);
  }

  objectsToRender() {
    let firstRecord = this.props.results[0];
    if (
      firstRecord &&
      !['RestrictedSpaceLink', 'BoardLink'].includes(firstRecord.__typename)
    ) {
      return this.props.results;
    } else if (this.isMovingLinksToNewSection()) {
      return this.splittedResults();
    } else {
      return this.props.results.map(this.processLinkToObject);
    }
  }

  autofillCardsUpto() {
    let autofillCardsUpto = this.isMovingLinksToNewSection()
      ? 0
      : this.props.autofillCardsUpto - this.state.movingLinksCount;
    return autofillCardsUpto < 0 ? 0 : autofillCardsUpto;
  }

  isAuthorOrEditor() {
    return this.props.board.is_author_or_editor;
  }

  updateSpaceSectionLinks(options) {
    let variables = {
        idQuery: this.props.tabSectionId,
        searchQuery: this.props.searchQuery,
        sortQuery: this.props.sortQuery,
        additionalFilters: this.props.additional_filters,
      },
      spaceLinksToRemove = this.props.results.filter(
        (link) =>
          this.props.spaceLinks.selectedLinkIds.includes(link.id) ||
          this.props.spaceLinks.dragId === link.id,
      ),
      spaceLinksToAdd = [];
    if (options.destinationSectionId === this.props.tabSectionId) {
      let selectedObjects =
        this.props.spaceLinks.selectedLinks.length === 0
          ? [this.props.spaceLinks.dragLink]
          : this.props.spaceLinks.selectedLinks;
      spaceLinksToAdd = selectedObjects.map(this.processObjectToLink);
    }

    if (spaceLinksToAdd.length) {
      let meta = options.lowerLinkId
        ? { insertBeforeRecordId: options.lowerLinkId }
        : { isReverse: !options.insertAtBottom };
      if (spaceLinksToRemove.length)
        meta.recordIdToRemove = map(spaceLinksToRemove, 'id');
      StoreUpdater.addTabSectionLinksInListing(spaceLinksToAdd, variables, {
        ...meta,
        fallbackFunctionNotRequired: true,
      });
    } else if (spaceLinksToRemove.length) {
      this.setState({ movingLinksCount: spaceLinksToRemove.length });
      StoreUpdater.removeTabSectionLinkFromListing(
        spaceLinksToRemove,
        variables,
      );
    }
  }

  removeTabLinkMutation = (link) => {
    this.setState({ deleteLinkInProgress: true }, () => {
      this.props.updateInfoModalOptions({
        primaryCtaRequestInProgress: this.state.deleteLinkInProgress,
      });
    });
    this.props.onRemoveLink && this.props.onRemoveLink();
    this.props
      .removeTabLink({
        variables: {
          id: this.props.tabSectionId,
          linkableId: +link.id,
          linkableType: link.__typename,
          searchQuery: this.props.searchQuery,
          additional_filters: JSON.stringify(
            this.props.additional_filters || {},
          ),
        },
      })
      .then(({ data }) => {
        this.setState({ deleteLinkInProgress: false });
        if (data.remove_tab_link.success) {
          let variables = {
              sortQuery: this.props.sortQuery,
              searchQuery: this.props.searchQuery,
              additionalFilters: this.props.additional_filters,
            },
            tabLink = this.props.results.find(
              (boardLink) => boardLink.linkable.id === link.id,
            ),
            tabSectionId = this.props.tabSectionId;
          if (tabLink) {
            if (tabSectionId) {
              variables.idQuery = tabSectionId;
              StoreUpdater.removeTabSectionLinkFromListing(tabLink, variables);
              setTimeout(() => {
                this.props.afterLinkRemove && this.props.afterLinkRemove();
              }, 500);
            } else {
              // TODO: Function not defined in the app
              // StoreUpdater.removeTabLinkFromListing(tabLink, variables)
            }
          } else {
            this.props.flashError(data.remove_tab_link.error, false);
          }
          setTimeout(this.props.closeInfoModal, 1000);
        }
      });
  };

  removeFromSpace = (link) => {
    this.props.openInfoModal('general', 'delete_confirmation', {
      contentInterpolations: { entity_type: 'item' },
      onSuccess: this.removeTabLinkMutation.bind(this, link),
      closeModalNotRequiredOnPrimaryClick: true,
      primaryCtaRequestInProgress: this.state.deleteLinkInProgress,
    });
  };

  toggleCardSelection = (object) => {
    this.props.onToggleCardSelection(object);
  };

  isRemoveFromSpaceRequired(removeFromSpaceButtonProps) {
    return {
      removeFromSpace: {
        buttonProps: removeFromSpaceButtonProps,
        onClick: this.removeFromSpace,
      },
    };
  }

  getRemoveText(space) {
    let text = 'Remove from ';
    if (isCareSpace(space)) {
      text += 'PatientSpace';
    } else if (isFolderSpace(space)) {
      text += 'Folder';
    } else {
      text += 'Space';
    }
    return text;
  }

  getContextProps() {
    let extraClass = 'list-button ms-0 brt-top web-view-btn',
      isAttachmentRestricted = false,
      commonAttributes = {
        source: 'space-detail',
        isAttachmentRestricted,
        sourceObject: this.props.board,
        contentPreviewListing: this.props.navTab?.content_preview_listing,
        navTabId: this.props.navTab?.id,
      };

    if (this.isAuthorOrEditor()) {
      let commonProps = { name: 'BtnIcon', associatedEntity: this.props.board },
        text = this.getRemoveText(this.props.board),
        removeFromSpaceButtonProps = {
          text,
          icon: 'clear',
          extraClass,
          ...commonProps,
        };

      return {
        ...commonAttributes,
        ...this.isRemoveFromSpaceRequired(removeFromSpaceButtonProps),
        onCardSelectClick:
          this.props.onToggleCardSelection && this.toggleCardSelection,
      };
    } else {
      return {
        ...commonAttributes,
      };
    }
  }

  cardsSelectionModeProps() {
    return {
      cardClickRequired: true,
      cardsSelectionModeEnabled: true,
      multiSelectMode: true,
      onCardClick: () => {},
      selectionMode: true,
    };
  }

  isContentAdditionAllowed() {
    const { content_addition_allowed } = this.props.navTab,
      { content_addition_allowed: tabSectionAdditionAllowed } =
        this.props.tabSection;
    return content_addition_allowed && tabSectionAdditionAllowed;
  }

  renderNoContentContainer() {
    let { owner: event } = this.props.board;
    if (this.props.selectedFilter === 'lectures' && event) {
      return (
        <div className='no-cases-block'>
          <div className='no-cases-image-block'>
            <Icon name='cannotOpen' />
          </div>
          <div className='no-cases-message-block no-lectures'>
            {translate('NO_LECTURES_MESSAGE', { eventName: event.name })}
          </div>
        </div>
      );
    } else {
      return (
        <div className='no-cases-block empty-section'>
          <div className='no-cases-message-block empty-new-section'>
            <span>
              <OrthoIcon
                name='info'
                defaultClass='info-icon'
                dataHoverNotRequired={true}
              />
              <span className='ps-2 pe-1'>
                There is no added content for this section.
              </span>
            </span>
            {this.isContentAdditionAllowed() && (
              <OsLink
                text='Add new file'
                onClick={this.addNewLink.bind(this, 'file')}
              />
            )}
          </div>
        </div>
      );
    }
  }

  isMobileOrIpad() {
    return this.props.device.mobileDevice || this.props.device.ipad;
  }

  isGridRequired() {
    return (
      isCareSpace(this.props.board) ||
      isDynamicTemplate(this.props.board) ||
      isFolderSpace(this.props.board) ||
      isContentTypeSpace(this.props.board)
    );
  }

  render() {
    // TODO: Logic needs to be updated
    let additionalProps = this.props.cardsSelectionModeEnabled
        ? this.cardsSelectionModeProps()
        : {},
      containerClassName = `space-links-container all ${this.props.size}`,
      extraCardProps = isWorkspaceView()
        ? { size: this.props.size }
        : { size: 'extra-small' },
      source = this.isGridRequired() ? 'CareSpaceDetail' : '';

    if (this.props.totalRecords > 0) {
      return (
        <>
          <div className={containerClassName}>
            <CARD_ACTION_CONTEXT.Provider value={this.getContextProps()}>
              <RenderCards
                attachments={this.objectsToRender()}
                sliderPreview={true}
                objects={this.objectsToRender()}
                orderRowWise={true}
                showEntityInModal={false}
                autofillCardsUpto={this.autofillCardsUpto()}
                tabSectionId={this.props.tabSectionId}
                cardsCustomMarginRequired={this.props.cardsCustomMarginRequired}
                moveCardsIntoNewSection={this.moveCardsIntoNewSection}
                {...additionalProps}
                space={this.props.board}
                canArrangeContent={this.props.canArrangeContent}
                contentNotAccessible={this.props.contentNotAccessible}
                {...extraCardProps}
                cardGridIdentifier={getSpaceCardGridIdentifier(
                  this.props.size,
                  source,
                )}
              />
            </CARD_ACTION_CONTEXT.Provider>
          </div>
        </>
      );
    } else {
      return this.renderNoContentContainer();
    }
  }
}

LinksListing.defaultProps = {
  tabSection: {},
};

LinksListing = graphql(REMOVE_TAB_LINK_MUTATION, { name: 'removeTabLink' })(
  LinksListing,
);
LinksListing = connect(
  ({ commentShare, currentUser, device, spaceLinks, source }) => ({
    commentShare,
    currentUser,
    device,
    spaceLinks,
    source,
  }),
  {
    closeInfoModal,
    commentShareOpen,
    commentShareConsume,
    flashError,
    moveLinksToNewSection,
    openInfoModal,
    setActiveDragDetails,
    updateInfoModalOptions,
  },
)(LinksListing);
LinksListing = withRouter(LinksListing);
export default LinksListing;
