import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import WorkspaceToggleDropdown from 'app/components/WorkspaceView/WorkspaceToggleDropdown';

import EventTracker from 'app/services/EventTracker';

import Logo from 'app/images/cloudberry-logo@2x.png';
import LogoIcon from 'app/images/cloudberry-logo.png';

import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import OrthoIcon from './OrthoIcon';
import Authenticate from 'app/services/Authenticate';
import { getKeyValueFromLocalStorage, setKeyValueInLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';

export default class SiteLogo extends Component {
  onLogoClick = () => {
    EventTracker.track('qlark - Logo Clicked');
  };

  renderDropdown() {
    return (
      <WorkspaceToggleDropdown navLink={true} renderLogo={this.renderLogo} />
    );
  }

  renderCombinedLogo() {
    return (
      <>
        {this.renderWorkspaceLogo()}
        <OrthoIcon name='close' dataHoverNotRequired={true} />
        {this.renderLogoContent()}
      </>
    );
  }

  renderWorkspaceLogo() {
    const { guestOwnerData } = this.props.workspace;
    return (
      <div className='logo'>
        {guestOwnerData.owner.logo_small && (
          <img
            src={guestOwnerData.owner.logo_small}
            alt=''
            className='logo-workspace'
          />
        )}
        <span className='clinic-team-logo-text'>{guestOwnerData.name}</span>
      </div>
    );
  }

  renderLogoContent() {
    let props;
    if (this.props.device.mobileDevice || this.props.currentUser.graph) {
      props = { src: LogoIcon, className: 'logo-primary' };
    } else {
      props = { src: Logo, className: 'logo-primary with-text' };
    }
    return (
      <div className='logo'>
        <img {...props} alt='' />
      </div>
    );
  }

  isGuestWorkspaceOwnerPresent() {
    return this.props.workspace.guestOwnerData;
  }

  isGuestOwnerPresentOnCare() {
    return isCareWorkspaceView() && this.isGuestWorkspaceOwnerPresent();
  }

  getLogoBasedOnIdentifier() {
    if (this.isGuestOwnerPresentOnCare()) {
      return this.renderCombinedLogo();
    } else if (
      !this.props.currentUser.graph &&
      this.isGuestWorkspaceOwnerPresent()
    ) {
      return this.renderWorkspaceLogo();
    } else {
      return this.renderLogoContent();
    }
  }

  isLaunchScreenAccessible() {
    let primaryToken = Authenticate.getItem("token") || Authenticate.getUserAuthValueFromCookie();
    let secondryToken = getKeyValueFromLocalStorage('HTTP_SESSION_TOKEN')

    if (secondryToken == null && primaryToken) setKeyValueInLocalStorage('HTTP_SESSION_TOKEN', primaryToken);
    return primaryToken !== secondryToken || this.props.lockNavbar.lockNav
  }

  renderLogo = () => {
    let to =
      this.props.currentUser.graph?.accessible_workspaces_count > 1
        ? '/launch'
        : '/',
      props = this.isGuestOwnerPresentOnCare()
        ? {}
        : { onClick: this.onLogoClick, to },
      Element = this.isGuestOwnerPresentOnCare() ? 'div' : Link;

    return (
      <Element className='navbar-brand' {...props}>
        {this.getLogoBasedOnIdentifier()}
      </Element>
    );
  };

  render() {
    return this.isLaunchScreenAccessible() ?
      <div className='navbar-brand' {...this.props}>
        {this.getLogoBasedOnIdentifier()}
      </div>
      : this.renderLogo()
  }
}

SiteLogo = connect(({ currentUser, device, workspace, lockNavbar }) => ({
  currentUser,
  device,
  workspace,
  lockNavbar
}))(SiteLogo);

SiteLogo.defaultProps = {
  workspaceDropdownNotRequired: false,
};
