import { OPEN_PLAYER_MODAL, CLOSE_PLAYER_MODAL } from './types';

export function openPlayerModal(entity, options = {}) {
  return {
    type: OPEN_PLAYER_MODAL,
    entity: entity,
    options: options,
  };
}

export function closePlayerModal() {
  return {
    type: CLOSE_PLAYER_MODAL,
  };
}
