import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import ProfileImage from 'app/components/Shared/ProfileImage';

import { fieldRequired } from 'app/utils/validationHelper';
import { capitalizeEveryWord, humanize } from 'app/utils/stringHelper';
import { keys, sortBy } from 'app/utils/osLodash';

const USER_ROLE = queryLoader('app/graphql/queries/Users/UserRoles.gql');
const STATUS_ICON = {
  doctor: 'doctors',
  student_doctor: 'course',
  other: 'user',
};

class ProfileInfo extends Component {
  getUserRoles() {
    return (
      this.props.data &&
      sortBy(this.props.data.user_roles, (role) =>
        keys(STATUS_ICON).indexOf(role.name),
      )
    );
  }

  onSubmit = (values) => {
    let variables = {
      avatar: typeof values.avatar === 'object' ? values.avatar : null,
      currentRole: values.role,
      avatar_w: values.avatar_w,
      avatar_h: values.avatar_h,
      avatar_x: values.avatar_x,
      avatar_y: values.avatar_y,
    };
    this.props.onSubmit(variables);
  };

  onChange = ({ target }) => {
    this.props.setRole(target.value);
    this.change('role', target.value);
  };

  renderStatus = (role) => {
    let name = role.name,
      canUpdateRole = this.props.currentUser.graph.can_update_role,
      labelClass = `cp_label m-0 ${canUpdateRole ? '' : 'disable'}`;

    return (
      <label className={labelClass}>
        <Field
          component='input'
          type='radio'
          name='role'
          validate={fieldRequired}
          id={name}
          value={name}
          onChange={this.onChange}
          disabled={!canUpdateRole}
        />
        <div className='cp_label_helper'>
          <span className='m-auto'>
            <OrthoIcon name={STATUS_ICON[name]} />
            <br />
            {capitalizeEveryWord(humanize(name))}
          </span>
        </div>
      </label>
    );
  };

  goBack = () => {
    this.props.goBack();
  };

  onCrop = (dimensions, originalDimensions) => {
    this.change('avatar_x', dimensions.x / originalDimensions.naturalWidth);
    this.change('avatar_y', dimensions.y / originalDimensions.naturalHeight);
    this.change('avatar_w', dimensions.width / originalDimensions.naturalWidth);
    this.change(
      'avatar_h',
      dimensions.height / originalDimensions.naturalHeight,
    );
  };

  renderContent() {
    let defaultImageUrl = this.props.currentUser.graph.large_avatar,
      isSubmitBtnDisabled =
        (this.formValues && !this.formValues.role) || this.props.disableCTA;
    return (
      <Form
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        destroyOnUnmount={false}
        render={(props) => {
          this.change = props.form.change;
          this.formValues = props.values;
          return (
            <form name='profileInfo' onSubmit={props.handleSubmit}>
              <ProfileImage
                defaultImageUrl={defaultImageUrl}
                onCrop={this.onCrop}
                obj={this.props.currentUser.graph}
              />

              <div className='current_profile_status'>
                Your current status*
                <div className='c_p_list'>
                  {this.getUserRoles().map(this.renderStatus)}
                </div>
              </div>

              <div className='text-right profile-action-btn'>
                <OsBtn
                  name='BtnSecondary'
                  onClick={this.goBack}
                  text='back'
                  disabled={this.props.disableCTA}
                  extraClass='back_btn with-border web-view-btn'
                />
                <OsBtn
                  name='BtnPrimary'
                  type='submit'
                  text='next step'
                  disabledView={isSubmitBtnDisabled}
                  extraClass='next_btn web-view-btn'
                  disabled={this.props.disableCTA}
                  loaderRequired={this.props.disableCTA}
                  htmlTag='button'
                />
              </div>
            </form>
          );
        }}
      />
    );
  }

  render() {
    if (!this.getUserRoles() || !this.props.currentUser.graph) {
      return <Loader type='ball-triangle-path' active />;
    }
    return this.renderContent();
  }
}

ProfileInfo = graphql(USER_ROLE, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
  }),
})(ProfileInfo);

ProfileInfo = connect(({ currentUser, device }) => ({ currentUser, device }))(
  ProfileInfo,
);

export default ProfileInfo;
