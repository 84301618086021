import { isCareSpace } from 'app/utils/spaceHelper';
import { isEqual, isUndefined } from 'app/utils/osLodash';
import { LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR } from 'app/utils/colorHelper';
import { currentUser } from 'app/utils/userHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

export function getCareAvatarIconProps(space, user, options = {}) {
  if (
    space &&
    isCareSpace(space) &&
    (isPatientOrGuardian(space, user) || options['isPatientOrGuardian'])
  ) {
    let iconWithImage,
      props = {};

    if (
      isSharedWithParty(space, user, { id: user.patient_profile_id }) &&
      !isUndefined(space.notification_enabled_for_patient_or_guardian_ids)
    )
      iconWithImage =
        space.notification_enabled_for_patient_or_guardian_ids.includes(user.id)
          ? ''
          : 'mute';

    props['iconWithImage'] = iconWithImage;

    return props;
  } else {
    return {};
  }
}

export function getCareSpaceCardAvatarIconProps(space, user) {
  if (isCareSpace(space)) {
    return {
      subscriptionColorCode: space.patient_or_guardian_viewed
        ? LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR
        : '',
    };
  } else {
    return {};
  }
}

export function isPatientOrGuardian(space, user) {
  return space.patient_or_guardian_user_ids?.includes(user.id);
}

export function isUserAuthenticated(space, user) {
  return space?.authenticated_user_ids?.includes(user.id);
}

export function isUserOrGuardianAuthenticated(space) {
  return space?.authenticated_user_ids?.length > 0;
}

export function isSharedWithUserOrGuardian(space) {
  const shared_user_ids = [...space?.shared_with_user_ids];
  return shared_user_ids.some(
    (id) => !space?.access_decline_user_ids?.includes(id),
  );
}

export function isUserAndGuardianDeclined(space) {
  return (
    space?.shared_with_user_ids.length > 0 &&
    isEqual(space?.access_declined_user_ids, space?.shared_with_user_ids)
  );
}

export function isPersonActuallyAPatient(space, person) {
  return space.owner?.patient?.id === person.id;
}
export function isSharedWithUser(space, user) {
  return space?.shared_with_user_ids?.includes(user.id);
}
export function isSharedWithParty(space, user, person) {
  return (
    space?.shared_with_user_ids?.includes(user.id) ||
    space.shared_with_person_ids?.includes(person.id)
  );
}

export function isSharedWithAnyParty(space) {
  return (
    space?.shared_with_user_ids?.length > 0 ||
    space?.shared_with_person_ids?.length > 0
  );
}

export function shareWithPatientCommonProps(space) {
  space = space || {};
  return {
    accessible: space.is_author_or_editor && !space.shared_with_any_patient,
    objId: space.nice_id,
    dataVerificationRequired: space.data_verification_required,
  };
}

export function getPersonConnection(user, space) {
  return (
    space.owner?.patient.person_connections.find(
      (pc) => +pc.related_person?.user_id === +user.id,
    ) || {}
  );
}

export function getRelationName(user, space) {
  return getPersonConnection(user, space)?.relation;
}

export function getNotifiablePersonConnection(patient) {
  return (patient.person_connections || []).find((pc) => pc.notify);
}

export function isDeclined(space, user) {
  return space.access_declined_user_ids?.includes(user.id);
}

export function isPersonCurrentUser(person) {
  return +person?.user_id == +currentUser()?.id;
}

export function canEditPersonInfo(person) {
  return (
    isCareWorkspaceView() &&
    (!person.user_onboarded || isPersonCurrentUser(person))
  );
}

export function isPatientOfCareSpace(person, careSpace) {
  return +person.id === +careSpace?.owner?.patient?.id;
}

export function findPersonConnection(person, careSpace) {
  return careSpace?.owner?.patient?.person_connections.find(
    (pc) => +pc.related_person?.id === +person.id,
  );
}
