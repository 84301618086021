import { OPEN_TASK_MODAL, CLOSE_TASK_MODAL } from './types';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

const TASK_COMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskProcessed.gql',
);

export function openTaskModal(taskId, options = {}) {
  return {
    type: OPEN_TASK_MODAL,
    taskId,
    options,
  };
}
export function openEmptyTaskModal(updateCache, updateComponent, userId) {
  return {
    type: OPEN_TASK_MODAL,
    options: { updateCache, updateComponent, userId },
  };
}
export function closeTaskModal() {
  return {
    type: CLOSE_TASK_MODAL,
  };
}
export function openTaskModalWithEntity(
  entity,
  updateComponent,
  backendDataRequired,
) {
  return (dispatch) => {
    dispatch({
      type: OPEN_TASK_MODAL,
      options: { entity, updateComponent, backendDataRequired },
    });
  };
}
