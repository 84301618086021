import React from 'react';
import { Link } from 'react-router-dom';

import AbstractCard from 'app/components/OsCards/AbstractCard';
import Avatar from 'app/components/Shared/Avatar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import ParseText from 'app/components/ParseText';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { timeWithFormat } from 'app/utils/timeHelper';
import { lowerCase, truncate, upperCase } from 'app/utils/osLodash';

import { ENTITY_NAME_MAPPER } from 'app/constants';
import { FORMAT_START_WITH_MONTH_SLASH } from 'app/components/Task/taskConstant';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';

class ConversationMessageCard extends AbstractCard {
  onConversationMessageCardClick = (e) => {
    this.onCardClick(e);
  };

  onCardClick = (e, options = {}) => {
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  renderTitle = () => {
    let conversationMessage = this.props.obj,
      isTall = ['tall', 'tall-min'].includes(this.props.size),
      { first_name, last_name } = conversationMessage.author || {},
      name = (first_name || '') + ' ' + (last_name || '');

    return (
      <div className='comment-card-head'>
        <div className='left-comment-col'>
          {isTall && (
            <>
              <OrthoIcon
                name='comments'
                dataHoverNotRequired={true}
                defaultClass='pe-2'
              />
              <span>
                {ENTITY_NAME_MAPPER[
                  lowerCase(conversationMessage.commentable_type)
                ] || conversationMessage.commentable_type}{' '}
                Comment
              </span>
            </>
          )}
          {!isTall && (
            <>
              <Avatar
                className='avatar avatar-32 me-2'
                {...avatarAttributes(conversationMessage.author)}
              />
              <span className='cm-user-name'>
                {truncate(name, { length: 18 })}&nbsp;&nbsp;
              </span>
              {this.props.size === 'small' && (
                <span className='time-format text-truncate'>
                  |&nbsp;&nbsp;
                  {timeWithFormat(conversationMessage.created_at, 'll', true, {
                    todayWithTimeRequired: true,
                  })}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  renderContent = () => {
    let conversationMessage = this.props.obj;

    return (
      <>
        <div className='cm-card-body'>
          {this.props.dateBlockRequired && (
            <p className={'comment-type'}>
              {upperCase(conversationMessage?.commentable_type)}
            </p>
          )}
          {conversationMessage.content && (
            <ParseText
              obj={conversationMessage}
              className='comment-text-helper card-text'
            />
          )}
        </div>
        {this.props.dateBlockRequired && (
          <div className='commentDate'>
            {conversationMessage.author?.full_name} •{' '}
            {timeWithFormat(
              conversationMessage.created_at,
              `${FORMAT_START_WITH_MONTH_SLASH} hh:mm A`,
            )}
          </div>
        )}
      </>
    );
  };
  render() {
    return (
      <div className={this.props.containerClasses}>
        {!this.props.closeRequired && (
          <Link
            className='pulse-card-overlay'
            to='javascript:void()'
            onClick={this.onConversationMessageCardClick}
          />
        )}
        {this.renderTitle()}
        {this.renderContent()}
      </div>
    );
  }
}

ConversationMessageCard = withApollo(ConversationMessageCard);
ConversationMessageCard = withRouter(ConversationMessageCard);
export default ConversationMessageCard;
