import DefaultSpaceCardImage from 'app/images/gradeint_cloudberry.png';
import { MY_FOLDER_FILES, MY_FOLDER_LINKS } from 'app/routes/constants/folders';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export function getDynamicObjFromKey(key, user) {
  let props = {
    __typename: 'DynamicTemplate',
    id: key,
    category_name: 'Content Space',
    type: 'Folder',
    nice_id: key,
    user: user,
    image: DefaultSpaceCardImage,
  };
  switch (key) {
    case MY_FOLDER_FILES:
      return {
        ...props,
        name: 'My Uploaded Files',
        modifiedUrl: withWorkspaceIdentifier(
          `/folders/${MY_FOLDER_FILES}`,
          true,
        ),
        queryType: 'MyAttachments',
      };
    case 'my_cases':
      return {
        ...props,
        name: 'All Clinic Cases',
        modifiedUrl: withWorkspaceIdentifier('/folders/my/cases', true),
        queryType: 'ClinicCases',
      };
    case MY_FOLDER_LINKS:
      return {
        ...props,
        name: 'My Uploaded Links',
        modifiedUrl: withWorkspaceIdentifier(
          `/folders/${MY_FOLDER_LINKS}`,
          true,
        ),
        queryType: 'AllAdditionalLinks',
      };
    default:
      return {};
  }
}
