import { openEmptyTaskModal } from 'app/actions/taskModal';
import { TaskContext } from 'app/context/TaskContext';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import calendar from 'app/images/svg-thumbnail/calendar.png';
import { PaywallContext } from 'app/context/PaywallContext';

function EmptyState() {
  const dispatch = useDispatch();
  const { updateCache, updateComponent, user } = useContext(TaskContext);
  const { setOpenPaywall, shouldOpenPaywall } = useContext(PaywallContext);

  const openNewTaskModal = () => {
    if (shouldOpenPaywall()) {
      setOpenPaywall(true);
    } else {
      dispatch(openEmptyTaskModal(updateCache, updateComponent, user.id));
    }
  };

  return (
    <div className='emptyStateWrapper'>
      <img
        src={calendar}
        alt='emptyStatecalendar'
        style={{ width: '80px', height: '80px' }}
      />
      <span className='Captiondefault'>No tasks here yet</span>
      <button onClick={openNewTaskModal} className='openTaskBtn'>
        <span className='openTaskBtnText'>CREATE NEW TASK</span>
      </button>
    </div>
  );
}

export default EmptyState;
