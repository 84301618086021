import React from 'react';

let SVG = ({
  style = {},
  fill = '#000',
  width = '277',
  height = '160',
  className = '',
  viewBox = '0 0 277 160',
  device,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon space-svg space-collaboration ${className || ''}`}>
    <defs>
      <linearGradient
        id='c6ljqt8aja'
        x1='0%'
        x2='100%'
        y1='50.001%'
        y2='50.001%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='j0a9zglm1b' x1='0%' x2='100%' y1='50%' y2='50%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='2py2x92z8c' x1='0%' x2='100%' y1='50%' y2='50%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient
        id='m5wrja7v4d'
        x1='50.002%'
        x2='50.002%'
        y1='100%'
        y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='j6ui1b0g0e' x1='50%' x2='50%' y1='100%' y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient
        id='e3hfajdu9f'
        x1='50%'
        x2='50%'
        y1='100.042%'
        y2='.042%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='w1sgjgme3g' x1='50%' x2='50%' y1='100%' y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='t27eq53e5h' x1='50%' x2='50%' y1='100%' y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='q9uehi4dyi' x1='50%' x2='50%' y1='100%' y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient id='vbw887wvsj' x1='50%' x2='50%' y1='100%' y2='0%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient
        id='6m3f2kcdhk'
        x1='49.996%'
        x2='49.996%'
        y1='100%'
        y2='.81%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
      <linearGradient
        id='bt7v2weu5l'
        x1='49.961%'
        x2='49.961%'
        y1='100%'
        y2='.105%'>
        <stop offset='0%' stopColor='gray' stopOpacity='.25' />
        <stop offset='54%' stopColor='gray' stopOpacity='.12' />
        <stop offset='100%' stopColor='gray' stopOpacity='.1' />
      </linearGradient>
    </defs>

    <g fill='none'>
      <path
        fill='#F5F5F5'
        d='M197.32 -43.267H203.44899999999998V101.75300000000001H197.32z'
        transform='rotate(-81.36 200.384 29.243)'
      />
      <path
        fill='url(#c6ljqt8aja)'
        d='M134.737 5.959H250.652V157.108H134.737z'
        transform='rotate(-81.36 192.695 81.533)'
      />
      <path
        fill='#F5F5F5'
        d='M139.13 12.012H244.832V157.032H139.13z'
        transform='rotate(-81.36 191.98 84.522)'
      />
      <circle
        cx='132.197'
        cy='18.881'
        r='1.498'
        fill='#FF5252'
        transform='rotate(-81.36 132.197 18.881)'
      />
      <circle
        cx='136.27'
        cy='19.499'
        r='1.498'
        fill='#FF0'
        transform='rotate(-81.36 136.27 19.499)'
      />
      <circle
        cx='140.343'
        cy='20.119'
        r='1.498'
        fill='#69F0AE'
        transform='rotate(-81.36 140.343 20.119)'
      />
      <path
        fill='url(#j0a9zglm1b)'
        d='M175.216 -4.754H213.514V125.45899999999999H175.216z'
        transform='rotate(-81.36 194.365 60.352)'
      />
      <path
        fill='#81D4FA'
        d='M130.778 42.753H258.183V78.243H130.778z'
        transform='rotate(8.64 194.48 60.498)'
      />
      <path
        fill='url(#2py2x92z8c)'
        d='M146.89 87.449H154.295V96.13H146.89z'
        transform='rotate(-81.36 150.593 91.79)'
      />
      <path
        fill='#FCB947'
        d='M146.662 88.62H154.832V94.74900000000001H146.662z'
        transform='rotate(8.64 150.746 91.684)'
      />
      <path
        fill='#FF5252'
        d='M145.085 98.969H153.255V105.098H145.085z'
        transform='rotate(8.64 149.17 102.034)'
      />
      <path
        fill='#FF9800'
        d='M143.514 109.319H151.684V115.44800000000001H143.514z'
        transform='rotate(8.64 147.599 112.384)'
      />
      <path
        fill='#FCB947'
        d='M167.788 97.49H233.659V101.06299999999999H167.788z'
        opacity='.4'
        transform='rotate(8.64 200.724 99.277)'
      />
      <path
        fill='#FCB947'
        d='M166.214 107.84H232.08499999999998V111.413H166.214z'
        opacity='.4'
        transform='rotate(8.64 199.15 109.627)'
      />
      <path
        fill='#FCB947'
        d='M164.644 118.19H230.515V121.76299999999999H164.644z'
        opacity='.4'
        transform='rotate(8.64 197.58 119.977)'
      />
      <path
        fill='#F5F5F5'
        d='M2.155 19.807H156.557V25.837H2.155z'
        transform='rotate(-12.6 79.355 22.822)'
      />
      <path
        fill='url(#m5wrja7v4d)'
        d='M10.502 16.485H171.42700000000002V130.551H10.502z'
        transform='rotate(-12.6 90.964 73.518)'
      />
      <path
        fill='#F5F5F5'
        d='M14.156 24.516H168.558V128.531H14.156z'
        transform='rotate(-12.6 91.357 76.523)'
      />
      <ellipse
        cx='7.692'
        cy='38.84'
        fill='#FF5252'
        rx='1.594'
        ry='1.474'
        transform='rotate(-12.6 7.692 38.84)'
      />
      <ellipse
        cx='11.974'
        cy='37.886'
        fill='#FF0'
        rx='1.594'
        ry='1.474'
        transform='rotate(-12.6 11.974 37.886)'
      />
      <ellipse
        cx='16.253'
        cy='36.929'
        fill='#69F0AE'
        rx='1.594'
        ry='1.474'
        transform='rotate(-12.6 16.253 36.93)'
      />
      <path
        fill='url(#j6ui1b0g0e)'
        d='M15.506 34.683H154.142V72.37100000000001H15.506z'
        transform='rotate(-12.6 84.824 53.527)'
      />
      <path
        fill='#81D4FA'
        d='M17.162 36.156H152.80700000000002V71.07900000000001H17.162z'
        transform='rotate(-12.6 84.985 53.617)'
      />
      <path
        fill='url(#e3hfajdu9f)'
        d='M48.232 95.001H57.474000000000004V102.286H48.232z'
        transform='rotate(-12.6 52.853 98.644)'
      />
      <path
        fill='#FCB947'
        d='M49.286 95.918H58.024V101.97500000000001H49.286z'
        transform='rotate(-12.653 53.655 98.947)'
      />
      <path
        fill='#FF5252'
        d='M50.856 105.53H59.556V111.56H50.856z'
        transform='rotate(-12.6 55.206 108.545)'
      />
      <path
        fill='#FF9800'
        d='M53.839 116.098H62.577V122.155H53.839z'
        transform='rotate(-12.653 58.208 119.126)'
      />
      <path
        fill='#FCB947'
        d='M70.417 84.991H140.549V88.509H70.417z'
        opacity='.4'
        transform='rotate(-12.6 105.482 86.75)'
      />
      <path
        fill='#FCB947'
        d='M72.663 95.046H142.79500000000002V98.56400000000001H72.663z'
        opacity='.4'
        transform='rotate(-12.6 107.73 96.804)'
      />
      <path
        fill='#FCB947'
        d='M74.91 105.097H145.042V108.615H74.91z'
        opacity='.4'
        transform='rotate(-12.6 109.976 106.856)'
      />
      <path fill='#F5F5F5' d='M52.43 6.005H227.536V13.404H52.43z' />
      <path
        fill='url(#w1sgjgme3g)'
        d='M49.039 3.539H231.54399999999998V143.5H49.039z'
      />
      <path fill='#FFF' d='M52.43 13.404H227.536V141.034H52.43z' />
      <circle cx='56.706' cy='9.705' r='1.81' fill='#FF5252' />
      <circle cx='61.679' cy='9.705' r='1.81' fill='#FF0' />
      <circle cx='66.651' cy='9.705' r='1.81' fill='#69F0AE' />
      <path fill='url(#t27eq53e5h)' d='M59.829 24.811H217.054V71.054H59.829z' />
      <path fill='#0288D1' d='M61.679 26.661H215.513V69.513H61.679z' />
      <path fill='url(#q9uehi4dyi)' d='M86.65 88.934H97.132V97.874H86.65z' />
      <path fill='#FCB947' d='M87.112 89.551H96.97699999999999V96.95H87.112z' />
      <path
        fill='#FF5252'
        d='M87.112 102.19H96.97699999999999V109.589H87.112z'
      />
      <path
        fill='#FF9800'
        d='M87.112 114.83H96.97699999999999V122.229H87.112z'
      />
      <path
        fill='#FCB947'
        d='M113.316 91.092H192.85399999999998V95.408H113.316zM113.316 103.732H192.85399999999998V108.048H113.316zM113.316 116.371H192.85399999999998V120.687H113.316z'
        opacity='.4'
      />
      <path
        fill='url(#vbw887wvsj)'
        d='M112.237 112.672H169.578V159.84H112.237z'
      />
      <path
        fill='url(#6m3f2kcdhk)'
        d='M125.469 105.171c0-8.868 7.189-16.057 16.057-16.057 8.868 0 16.057 7.19 16.057 16.057v10.276h4.495V105.17c.09-7.402-3.807-14.281-10.203-18.009-6.396-3.728-14.302-3.728-20.698 0-6.396 3.728-10.293 10.607-10.203 18.01v10.275h4.495V105.17z'
      />
      <path
        fill='#FFF'
        d='M126.11 104.965c0-8.513 6.901-15.414 15.414-15.414 8.514 0 15.415 6.9 15.415 15.414v9.865h4.316v-9.865c0-10.897-8.834-19.73-19.73-19.73-10.897 0-19.73 8.833-19.73 19.73v9.865h4.315v-9.865z'
      />
      <path fill='#FCB947' d='M114.704 114.213H168.346V157.989H114.704z' />
      <path fill='#FFF' d='M114.704 114.213H168.346V157.989H114.704z' />
      <path fill='#FCB947' d='M114.704 122.845H168.346V149.358H114.704z' />
      <path
        fill='url(#bt7v2weu5l)'
        d='M145.461 131.095c.009-1.77-1.167-3.329-2.873-3.806-1.705-.478-3.519.243-4.431 1.76-.913 1.518-.7 3.458.522 4.74v8.392c0 1.58 1.28 2.861 2.86 2.861 1.58 0 2.862-1.28 2.862-2.86v-8.392c.685-.73 1.064-1.694 1.06-2.695z'
      />
      <path
        fill='#000'
        d='M144.916 131.786c.002-1.524-1.011-2.862-2.478-3.272-1.468-.41-3.028.208-3.816 1.512-.788 1.304-.61 2.973.436 4.081v7.236c0 1.362 1.104 2.466 2.466 2.466 1.363 0 2.467-1.104 2.467-2.466v-7.236c.592-.628.923-1.458.925-2.321z'
        opacity='.2'
      />
    </g>
  </svg>
);

export default SVG;
