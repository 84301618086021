import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { ceil, orderBy } from 'app/utils/osLodash';
import KanbanTaskHeading from 'app/components/Kanban/KanbanTaskHeading';
import { TaskContext } from 'app/context/TaskContext';
import SelectInput from 'app/components/Shared/SelectInput';
import useCardMovement from './useCardMovement';
import { add, firstDayOfGivenDateWeek } from 'app/utils/timeHelper';
import storeUpdater from 'app/services/StoreUpdater';
import { days } from 'app/components/Task/taskConstant';

const ACTIVE_TASKS = queryLoader('app/graphql/queries/Task/TaskByWeek.gql');
const UPDATE_DUEDATE = queryLoader(
  'app/graphql/mutations/Task/TaskDueDateChange.gql',
);
const OVERDUE_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');

const useKanban = (activeTasks) => {
  const [kBoard, setKBoard] = useState(null);
  const [kColumns, setKColumns] = useState(null);
  const [updateDueDate] = useMutation(UPDATE_DUEDATE);
  const {
    date,
    activeTaskVariables,
    overdueTaskVariables,
    overdueTasks,
    unscheduledTasks,
    textQuery,
    fetchNextOverdueRecords,
  } = useContext(TaskContext);
  const [isOverdueSelected, setIsOverdueSelected] = useState(true);

  const { isCardMovementAllowed } = useCardMovement();

  const changeDueDate = (taskId, dueDate) => {
    updateDueDate({
      mutation: UPDATE_DUEDATE,
      variables: {
        id: taskId,
        dueDate: dueDate,
      },
    }).then(({ data }) => {});
  };

  const moveCardToAnotherColumn = (fromColumnId, toColumnId, card) => {
    // Is card movement allowed.
    const weekStartDate = firstDayOfGivenDateWeek(date);
    const dueDate = add(
      weekStartDate.format('YYYY-MM-DD'),
      days.indexOf(toColumnId),
      'day',
    );
    const updatedDueDate = dueDate.format('DD-MM-YYYY');
    if (!isCardMovementAllowed(fromColumnId, toColumnId, card, dueDate)) {
      return null;
    }
    if (fromColumnId === 'overdue') {
      storeUpdater.removeTaskInOverdueRecords(
        { ...card, id: card.id.split('_')[0] },
        overdueTaskVariables,
        {
          query: OVERDUE_TASKS,
        },
      );
      storeUpdater.moveTaskInActiveRecords(
        { ...card, id: card.id.split('_')[0] },
        activeTaskVariables,
        {
          query: ACTIVE_TASKS,
          toColumnId: toColumnId,
        },
      );
    } else {
      storeUpdater.moveTaskInActiveRecords(card, activeTaskVariables, {
        query: ACTIVE_TASKS,
        toColumnId,
        fromColumnId,
      });
    }
    changeDueDate(card.id.split('_')[0], updatedDueDate);
  };

  const overdueCardWithModifiedId = () => {
    if (!!overdueTasks) {
      return overdueTasks?.records.results.map((task) => ({
        ...task,
        id: task.id + '_overdue',
      }));
    }
    return [];
  };

  const updateKanbanBoard = (tasks) => {
    // sort tasks on the basis of date using lodash
    let sortedTasks = orderBy(tasks, 'date', 'asc');
    // if (textQuery) sortedTasks = filterForActiveTask(sortedTasks);

    const newBoard = {},
      column = [];
    // New Logic to insert the task in the column only for week days.
    for (let i = 0, j = 0; i < days.length; i++) {
      const currentDay = days[i];
      if (currentDay === sortedTasks[j].key) {
        column.push({
          id: currentDay,
          cards: [...sortedTasks[j].records],
          title: <KanbanTaskHeading isWeek={true} tasks={sortedTasks[j]} />,
        });
        j++;
      } else if (currentDay === 'weekend') {
        const weekendTasks = sortedTasks.filter(
          (task) => task.key === 'saturday' || task.key === 'sunday',
        );
        column.push({
          id: currentDay,
          cards: [...weekendTasks?.[0].records, ...weekendTasks?.[1].records],
          title: (
            <KanbanTaskHeading
              isWeek={false}
              tasks={[weekendTasks?.[0], weekendTasks?.[1]]}
            />
          ),
        });
      } else {
        column.push({
          id: currentDay,
          cards: [],
          title: <KanbanTaskHeading isWeek={true} />,
        });
      }
    }

    // Overdue and Unscheduled Section
    column.push({
      id: 'overdue',
      cards: isOverdueSelected
        ? overdueCardWithModifiedId()
        : unscheduledTasks?.records.results,
      title: (
        <div className={'kanban-column-heading'}>
          <div className={'kanban-board-heading overdue-section'}>
            <SelectInput
              isMulti={false}
              async={false}
              isSearchable={false}
              formValue={isOverdueSelected ? 'OVERDUE' : 'UNSCHEDULED'}
              onChange={(value) =>
                value.value === 'OVERDUE'
                  ? setIsOverdueSelected(true)
                  : setIsOverdueSelected(false)
              }
              className={'overdue-dropdown react-select-ortho-2 os-menu-drop'}
              options={[
                {
                  value: 'OVERDUE',
                  label: `OVERDUE (${overdueTasks?.records.total || 0})`,
                },
                {
                  value: 'UNSCHEDULED',
                  label: `UNSCHEDULED (${
                    unscheduledTasks?.records.total || 0
                  })`,
                },
              ]}
              hideSelectedOptions={true}
              cursorPointer={true}
              extraClass={'overdue-dropdown'}
            />
            {/*<span className={"primary"}>OVERDUE/UNSCHEDULED</span><br/>*/}
            <span className={'secondary'}></span>
          </div>
        </div>
      ),
    });
    newBoard.columns = column;
    setKBoard(newBoard);
  };

  useEffect(() => {
    const tasks = activeTasks?.kanban_tasks;
    tasks && tasks.length > 0 && updateKanbanBoard(tasks);
  }, [isOverdueSelected, activeTasks, overdueTasks, textQuery]);

  useEffect(() => {
    let e = null;
    if (kColumns !== null) {
      e = kColumns[kColumns.length - 1];
      e.addEventListener('scroll', () => {
        if (e.scrollHeight - (ceil(e.scrollTop) + 5) <= e.clientHeight) {
          fetchNextOverdueRecords();
        }
      });
    }

    return () => {
      if (kColumns !== null) {
        e = kColumns[kColumns.length - 1];
        if (e) {
          e.removeEventListener('scroll', () => {
            if (e.scrollHeight - (ceil(e.scrollTop) + 5) <= e.clientHeight) {
              fetchNextOverdueRecords();
            }
          });
        }
      }
    };
  }, [kColumns, fetchNextOverdueRecords]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-kanban-column');
    if (elements !== undefined && elements.length !== 0) {
      setKColumns(elements);
    }
  });

  return {
    kBoard,
    setKBoard,
    activeTasks,
    updateKanbanBoard,
    moveCardToAnotherColumn,
  };
};

export default useKanban;
