import { useMemo } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { getUserToken } from 'app/selectors/user';
const CURRENT_USER_WORKSPACES_QUERY = queryLoader(
  'app/graphql/queries/Users/BasicAccessibleWorkspaces.gql',
);

const useWorkspaceOperation = ({ fullSummaryRequired = false } = {}) => {
  const token = useSelector(getUserToken);
  const { data, loading } = useQuery(CURRENT_USER_WORKSPACES_QUERY, {
    variables: {
      token: token.toString(),
      fullSummaryRequired,
    },
  });

  const workspaces = useMemo(
    () => (data ? data.user.accessible_workspaces || [] : []),
    [data],
  );

  return {
    workspaces,
    loading,
  };
};

export default useWorkspaceOperation;
