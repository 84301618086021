import React, { Component } from 'react';
import OrthoS3 from 'app/vendors/react-s3/OrthoS3';
import Dropzone from 'react-dropzone';
import { loader as queryLoader } from 'graphql.macro';

import ConnectToSupport from 'app/components/ConnectToSupport';
import GeneralModal from 'app/components/GeneralModal';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { decryptCredentials } from 'app/utils/decryptionHelper';
import {
  cloneFileWithRandomName,
  cloneFileWithSameName,
  isFileSizeInvalid,
  isFileTypeInvalid,
  fetchFileExtension,
} from 'app/utils/fileHelper';
import { translate } from 'app/actions/flashMessage';
import { pluralize } from 'app/utils/generalHelper';
import { toSentence } from 'app/utils/stringHelper';
import {
  each,
  filter,
  find,
  last,
  map,
  reject,
  some,
  uniq,
} from 'app/utils/osLodash';

const SECRET_CREDENTIALS_QUERY = queryLoader(
  'app/graphql/SecretCredentials.gql',
);
const ADD_FILE_ASSET_MUTATION = queryLoader('app/graphql/AddFileAsset.gql');
const DROPZONE_SOURCES = [
  'attachment-modal',
  'case-form',
  'note',
  'space-image-edit',
  'new-appointment-files',
];

export default class DropzoneFileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    this.removeAttachment = this.removeAttachment.bind(this);
    this.folderName = 'uploads/user_asset_files/';
  }

  uploadUserAssetRequired() {
    return DROPZONE_SOURCES.includes(this.dropzoneSource);
  }

  uploadUserAsset(fileUrl, file) {
    this.props.client
      .mutate({
        mutation: ADD_FILE_ASSET_MUTATION,
        variables: {
          fileUrl,
          fileName: file.originalName,
          fileSize: file.size,
        },
      })
      .then(({ data }) => {
        if (data.addFileAsset.success) {
          let files = map(this.state.files, (file) => {
            if (fileUrl.includes(file.name)) {
              let newFile = cloneFileWithSameName(file);
              newFile.id = data.addFileAsset.id;
              newFile.__typename = 'Attachment';
              this.onFileAssetUpload && this.onFileAssetUpload(newFile);
              return newFile;
            }
            return file;
          });
          this.setState({ files });
        }
      });
  }

  defaultState() {
    return {
      active: false,
      dropzoneActive: false,
      files: this.props.files || [],
      tags: [],
      attachmentsLoader: {},
      progressMapper: {},
      attachment: {},
      rejectedFiles: [],
    };
  }

  onDragEnter() {
    this.setState({
      dropzoneActive: true,
    });
  }

  onDragLeave() {
    this.setState({
      dropzoneActive: false,
    });
  }

  onDropCallback(files) {
    this.uploadFilesToS3(files);
  }

  setRef = (node) => {
    this.selfRef = node;
  };

  isSizeValid(files, FILE_SIZE_ALLOWED) {
    let invalidFiles = filter(files, (file) =>
      isFileSizeInvalid(file, FILE_SIZE_ALLOWED),
    );
    each(invalidFiles, (file) => {
      file.rejected = true;
    });
    invalidFiles.length > 0 && this.setState({ sizeError: true });
  }

  filesWithoutExtension(files) {
    return filter(files, (file) => !fetchFileExtension(file.name));
  }

  invalidateFilesWithoutExtension(files) {
    let filesWithoutExtension = this.filesWithoutExtension(files);
    each(filesWithoutExtension, (file) => {
      file.rejected = true;
    });
    filesWithoutExtension.length > 0 && this.setState({ noTypeError: true });
  }

  isTypeValid(files, FILE_TYPE_ALLOWED) {
    let invalidFiles = filter(files, (file) =>
      isFileTypeInvalid(file, FILE_TYPE_ALLOWED),
    );
    each(invalidFiles, (file) => {
      file.rejected = true;
    });
    invalidFiles.length > 0 &&
      this.setState({
        typeError: true,
        noTypeError: this.filesWithoutExtension(invalidFiles).length > 0,
        rejectedFiles: invalidFiles,
      });
  }

  typeBasedMaxSizeLimit() {
    return {};
  }

  closeErrorModal = () => {
    this.setState({
      noTypeError: false,
      sizeError: false,
      typeError: false,
      previewError: false,
    });
  };

  getRejectedFilesTypes() {
    let types = uniq(
      map(
        this.state.rejectedFiles,
        (file) => `.${fetchFileExtension(file.name)}`,
      ),
    );
    return `"${toSentence(types)}" ${pluralize(types.length, {
      singular: 'file',
      plural: 'files',
    })}`;
  }

  renderErrorModal(FILE_TYPE_ALLOWED, FILE_SIZE_ALLOWED, fileType) {
    let content = '',
      contentClassName = 'break-word',
      headingText,
      size;
    if (FILE_SIZE_ALLOWED) {
      size = FILE_SIZE_ALLOWED / (1024 * 1024);
    }

    if (this.state.previewError) {
      content = translate('SAVE_NOTE_FOR_PREVIEW');
    } else if (this.state.noTypeError) {
      headingText = translate('FILE_WITHOUT_EXTENSION_ERROR');
      content = translate(
        'FILE_WITHOUT_EXTENSION_CONTENT',
        {
          support_link: (
            <ConnectToSupport
              text='support team'
              onRedirectToSupport={this.onRedirectToSupport}
            />
          ),
        },
        false,
      );
    } else if (this.state.typeError && this.state.sizeError) {
      content = translate('FILE_NOT_SUPPORTED_AND_SIZE_EXCEEDED', {
        type: FILE_TYPE_ALLOWED,
        size,
      });
    } else if (this.state.typeError) {
      headingText = translate('FILE_NOT_SUPPORTED_HEADER', {
        types_text: this.getRejectedFilesTypes(),
      });
      content = translate(
        'FILE_NOT_SUPPORTED',
        {
          type: FILE_TYPE_ALLOWED,
          support_link: (
            <ConnectToSupport
              text='support team'
              onRedirectToSupport={this.onRedirectToSupport}
            />
          ),
        },
        false,
      );
    } else if (this.state.sizeError) {
      content = translate('FILE_SIZE_EXCEEDED', {
        size: `(${size} MB).`,
        file: fileType || 'File',
      });
    }

    if (content)
      return (
        <GeneralModal
          headingText={headingText}
          svgName='cannotEdit'
          content={content}
          showModal={true}
          closeModal={this.closeErrorModal}
          className='modal-general'
          contentClassName={contentClassName}
        />
      );
  }

  multipleFilesAllowed() {
    return true;
  }

  onDrop(files) {
    let { files: filesCollection, attachmentsLoader } = this.state,
      acceptedFiles,
      renamedAcceptedFiles;

    attachmentsLoader = attachmentsLoader || {};
    this.invalidateFilesWithoutExtension(files);

    acceptedFiles = filter(files, (file) => !file.rejected);
    renamedAcceptedFiles = acceptedFiles.map(cloneFileWithRandomName);
    renamedAcceptedFiles.forEach(
      (file) => (attachmentsLoader[file.name] = true),
    );

    filesCollection = !this.multipleFilesAllowed()
      ? [renamedAcceptedFiles[0]]
      : filesCollection.concat(renamedAcceptedFiles);
    this.props.onFileDrop && this.props.onFileDrop(filesCollection);
    this.setParentAttachmentLoader(attachmentsLoader);
    this.setState(
      {
        files: filesCollection,
        dropzoneActive: false,
        attachmentsLoader,
      },
      this.onDropCallback.bind(this, renamedAcceptedFiles),
    );
  }

  uploadFilesToS3(files) {
    files.length > 0 && this.setState({ disableClose: true });
    this.props.client
      .query({
        query: SECRET_CREDENTIALS_QUERY,
      })
      .then(({ data }) => {
        this.uploadFiles(data.secret_credentials, files);
      });
  }

  decryptedKeys(credentials, folderName) {
    let dirName = this.props.s3FolderName || this.folderName;
    return {
      bucketName: decryptCredentials(credentials.bucket_name),
      dirName: dirName + folderName,
      region: decryptCredentials(credentials.region),
      accessKeyId: decryptCredentials(credentials.access_key_id),
      secretAccessKey: decryptCredentials(credentials.secret_access_key),
      acl: this.aclType || 'private',
    };
  }

  // Function to delete asset from s3. Currently, not being used!
  deleteFile(credentials, file) {
    var folderName = file.preview.split('/')[5],
      fileName = file.preview.split('/')[6];

    var config = this.decryptedKeys(credentials, folderName);
    OrthoS3.deleteFile(fileName, config).then((data) => {
      console.log('successfully deleted');
    });
  }

  getUploadCallbacks() {
    return {
      onUploadProgress: this.onUploadProgress,
      onUploadComplete: this.onUploadComplete,
    };
  }

  onUploadProgress = (file, percentageCompleted) => {
    let { progressMapper } = this.state;
    progressMapper[file.name] = percentageCompleted.toFixed(0);
    this.setState({ progressMapper });
  };

  setParentAttachmentLoader(attachmentsLoader) {
    this.props.setAttachmentUploader &&
      this.props.setAttachmentUploader(attachmentsLoader);
  }

  onUploadComplete = (data, file) => {
    let attachmentsLoader = this.state.attachmentsLoader,
      fileName = last(data.location.split('/'));

    attachmentsLoader[fileName] = false;

    let allFilesUploaded = !some(attachmentsLoader, (val, key) => val);
    this.setState({ disableClose: !allFilesUploaded, attachmentsLoader });
    this.setParentAttachmentLoader(attachmentsLoader);
    if (find(this.state.files, (file) => file.name === fileName)) {
      this.onUploadCallback && this.onUploadCallback();
      this.uploadUserAssetRequired() &&
        this.uploadUserAsset(data.location, file);
    }
  };

  uploadFiles(credentials, files) {
    var folderName = Math.floor(Date.now() / 1000);
    var config = this.decryptedKeys(credentials, folderName);

    each(files, (file) => {
      this.props.disableSave && this.props.disableSave(file.name);
      OrthoS3.uploadFile(file, config, this.getUploadCallbacks());
    });
  }

  renderAttachment(file) {
    if (file.type.startsWith('image') && !file.type.includes('photoshop')) {
      return this.renderAttachmentImage(file);
    } else {
      return this.renderFileCard(file);
    }
  }

  handleExternalEntityShare = () => {
    this.dropzoneRef.open();
  };

  removeAttachment(file) {
    let { files, rejectedFiles } = this.state,
      newFiles,
      newRejectedFiles;

    if (file.id) {
      newFiles = reject(files, { id: file.id });
      newRejectedFiles = reject(rejectedFiles, { id: file.id });
    } else {
      newFiles = reject(files, { name: file.name });
      newRejectedFiles = reject(rejectedFiles, { name: file.name });
    }

    if (this.state.attachmentsLoader)
      delete this.state.attachmentsLoader[file.name];

    this.onRemoveAttachment && this.onRemoveAttachment(file);

    let allFilesUploaded = !some(
      this.state.attachmentsLoader,
      (val, key) => val,
    );
    this.setState({
      files: newFiles,
      rejectedFiles: newRejectedFiles,
      disableClose: !allFilesUploaded,
    });
  }

  renderAttachmentImage(file) {
    return (
      <div
        className='conversation-attachment-file-holder attached-image'
        key={`${this.props.fieldName}:${file.name}`}>
        <OrthoIcon
          name='close'
          onClick={this.removeAttachment.bind(this, file)}
        />
        <div className='uploaded-file test'>
          <img src={file.preview} alt={file.name} />
        </div>
      </div>
    );
  }

  renderFileCard(file) {
    return (
      <div
        className='conversation-attachment-file-holder attached-file'
        key={`${this.props.fieldName}:${file.name}`}>
        <span
          className=''
          onClick={this.removeAttachment.bind(this, file)}></span>
        <div className='conversation-attachment-file-content'>
          <div className='conversation-attachment-icon-holder'>
            <i className='conversation-attachement-icon' />
            <span className='conversation-attachment-ext-name'>
              .{fetchFileExtension(file.name)}
            </span>
          </div>
          <div
            key={`${this.props.fieldName}:${file.name}`}
            className='uploaded-file test'>
            {file.name}
          </div>
        </div>
      </div>
    );
  }

  renderChildren() {
    const { active, files } = this.state;
    let filesCount = files.length;
    if (this.props.children) {
      return this.props.children;
    } else {
      return (
        <div className='form-group messenger-input-container'>
          <div ref={this.setRef} className='media'>
            <div className={`media-body ${active ? 'active' : ''}`}>
              {filesCount > 0 && (
                <div className='conversation-attachment-row'>
                  <div className='conversation-attachment-file-block'>
                    <div className='conversation-attachment-files-count'>
                      {filesCount}{' '}
                      {pluralize(filesCount, {
                        singular: 'Attachment',
                        plural: 'Attachments',
                      })}
                    </div>
                    {files.map((file) => this.renderAttachment(file))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { dropzoneActive } = this.state;
    let dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ';
    dropzoneInputContainerClassName += this.state.dropzoneActive
      ? 'dropzone-drag-active'
      : '';

    return (
      <div>
        <div className='uploaded-image-holder-header'>
          <span>{this.props.fieldName}</span>
          <span
            className='attachment-icon-block'
            onClick={this.handleExternalEntityShare}>
            <OrthoIcon name='attach' defaultClass='chat-content-icon' />
            Click here to upload images
          </span>
        </div>
        <Dropzone
          disableClick
          style={{ position: 'relative' }}
          className={this.props.className || 'image-attachment-holder'}
          multiple={this.multipleFilesAllowed()}
          onDrop={this.onDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          ref={(node) => {
            this.dropzoneRef = node;
          }}
          {...(this.props.accept && { accept: this.props.accept })}>
          {(props) => (
            <>
              <div
                {...props.getRootProps({
                  onClick: (evt) => evt.preventDefault(),
                })}
                className={dropzoneInputContainerClassName}>
                <input {...props.getInputProps()} />
              </div>
              <div>
                {dropzoneActive && <div className=''>Drop files...</div>}
                {!this.props.noRender && this.renderChildren()}
              </div>
            </>
          )}
        </Dropzone>
      </div>
    );
  }
}
