import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import usePartnerSpaceEditor from 'app/components/Partners/usePartnerSpaceEditor';
import ShareWithClinicButton from 'app/components/Partners/ShareWithClinicButton';

import Avatar from 'app/components/Shared/Avatar';
import { loader as queryLoader } from 'graphql.macro';
import { useParams } from 'react-router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import {
  getPartnerClinicOrRequest,
  getPartnerClinicLogo,
  convertTextToTitleCase,
} from 'app/utils/spaces/partnerHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import { NO_TEMPLATES_ENABLED } from 'app/components/Templates/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveResource } from 'app/actions/activeResource';
import { useSearchParams } from 'react-router-dom';
import Loader from 'react-loaders';
import BootstrapDropdown from 'app/components/Shared/BootstrapDropdown/BootstrapDropdown';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import useFetechAllPatients from 'app/hooks/patients/useFetchAllPatients';
import { getMappedSelectInputValue } from 'app/utils/select-input';
import { isEditorContentEmpty } from 'app/utils/TiptapEditor/editorHelper';
import { hexToRgb } from 'app/utils/colorHelper';
import { partnerSpace } from 'app/selectors/partner';
import RecordsPager from 'app/components/RecordsPager';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { SET_PARTNER_SPACE_LABEL } from 'app/actions/types';
const GET_PARTNER_SPACE = queryLoader(
  'app/graphql/queries/Spaces/Types/Partner.gql',
);

const TIPTAP_EXTENSION_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'Underline',
  'CustomSubmit',
  'CustomMention',
  'CustomShortcutMenu',
];

const EDITOR_PLACEHOLDER =
  "Add new posts here.  Use '@' to mention clinic team member.";

const ESCAPE_ELEMENT_EVENT = ['dropdown-toggle'];

const PartnerSpaceFeed = ({ space }) => {
  const [searchParams] = useSearchParams();
  const [filterType, filterId] = searchParams.get('id')?.split(':') || [];
  const [commentId, setCommentId] = useState(null);

  useEffect(() => {
    if (searchParams.get('comment')) {
      setCommentId(searchParams.get('comment'));
    } else {
      setCommentId(null);
    }
  }, [searchParams]);

  return (
    <div className='xl-container container-fluid px-hd-40 px-sm-32 px-m-12'>
      <PartnerSpaceHeader space={space} />
      <React.Fragment>
        <RecordsPager
          type='PartnerSpaceActivities'
          limit={10}
          aroundId={commentId}
          recordId={space.id}
          recordType={'Board'}
          queryType='PARTNER_COMMENTS_ACTIVITY_LISTING'
          filterType={filterType}
          filterId={filterId}
          infiniteScroll={true}
        />
      </React.Fragment>
    </div>
  );
};

const PartnerSpaceHeader = ({ space }) => {
  const clinicOrRequest = getPartnerClinicOrRequest(space);
  const logo = getPartnerClinicLogo(space);
  const partnerSpaceState = useSelector(partnerSpace);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const removeSelectedLabel = () => {
    dispatch({
      type: SET_PARTNER_SPACE_LABEL,
      payload: { selectedLabel: null },
    });
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className='partner-space-header'>
        <Link
          to={withWorkspaceIdentifier('/partners')}
          className='dm-back-btn visible-mobile'>
          <OrthoIcon name='chevron-left' dataHoverNotRequired={true} />
        </Link>
        <div className='d-flex align-items-center'>
          <Avatar
            className='avatar avatar-40'
            name={clinicOrRequest.name}
            src={logo}
          />
          <h3>{convertTextToTitleCase(clinicOrRequest.name)}</h3>
          {partnerSpaceState.selectedLabel && (
            <>
              <div className='selected-label'>
                {partnerSpaceState.selectedLabel.__typename === 'Person' && (
                  <div className={'person-option-container'}>
                    <Avatar
                      className='avatar avatar-40 partner-space-listing-avatar me-1'
                      name={partnerSpaceState.selectedLabel?.large_avatar}
                      src={partnerSpaceState.selectedLabel?.large_avatar}
                    />
                  </div>
                )}
                {partnerSpaceState.selectedLabel.__typename === 'Label' && (
                  <span
                    className={'label-option-color'}
                    style={{
                      backgroundColor: `${hexToRgb(
                        `#${
                          partnerSpaceState.selectedLabel?.color || '354554'
                        }`,
                      )}`,
                    }}></span>
                )}
                <h2>{partnerSpaceState.selectedLabel.name}</h2>
                <span className={'clear-icon'}>
                  <OrthoIcon
                    name={'clear'}
                    onClick={removeSelectedLabel}
                    dataHoverNotRequired={false}
                  />
                </span>
              </div>
            </>
          )}
        </div>
        <ShareWithClinicButton partnerSpace={space} />
      </div>
      <PartnerSpaceEditor space={space} />
    </>
  );
};

const PartnerSpaceEditor = ({ space }) => {
  const placeholderValue = 'Add a new post here...';
  const {
    files,
    handleFiles,
    objects,
    handleObjects,
    isEditorVisible,
    editorDataRef,
    handleEditorVisibility,
    updateContent,
    postComment,
    labelDropdownEntity,
    handleLabelDropdown,
    isRequestProcessing,
    handleTemplate,
    editorRef,
  } = usePartnerSpaceEditor(space);

  const { partner_labels, enabled_templates } = useContext(WorkspaceContext);
  const { patients, setQuery, patientsLoading } = useFetechAllPatients();

  const labelOptions = useMemo(() => {
    return [...patients, ...partner_labels].map(getMappedSelectInputValue);
  }, [partner_labels, patients]);

  useEffect(() => {
    const handleClick = (e) => {
      let eventFiredFromObjectsClassName = e.target.className.split(' ');
      if (
        isEditorContentEmpty(editorDataRef.current.content) &&
        !ESCAPE_ELEMENT_EVENT.includes(eventFiredFromObjectsClassName[0])
      ) {
        handleEditorVisibility();
      }
    };
    if (isEditorVisible) {
      document.addEventListener('click', handleClick);
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [editorDataRef, handleEditorVisibility, isEditorVisible]);
  return (
    <>
      <div id={'editor-box'} className='partner-space-editor'>
        {isEditorVisible ? (
          <OsTiptapEditorField
            entity={space}
            rowId={'-1'}
            placeholder={EDITOR_PLACEHOLDER}
            autofocus={true}
            textType={'html'}
            dragAndDrop={true}
            initialContent={editorDataRef.current.initialContent}
            files={files}
            handleFiles={handleFiles}
            objects={objects}
            handleObjects={handleObjects}
            extensions={TIPTAP_EXTENSION_REQUIRED}
            isSubmitButtonRequired={true}
            updateContent={updateContent}
            handleTemplate={handleTemplate}
            onKeyDown={postComment}
            ref={editorRef}
            submitButtonProps={{
              disabled: isRequestProcessing,
              text: 'Post',
              onClick: postComment,
            }}>
            <div className={'editor-template-selector'}>
              <BootstrapDropdown
                async={false}
                title={'SELECT TEMPLATE'}
                inputFieldRequired={true}
                extraClassName={'editor-dropdown'}
                itemKey={'title'}
                inputFieldProps={{
                  placeholder: 'Type here to filter',
                }}
                onClick={(e, template) => {
                  handleTemplate(template);
                }}
                items={enabled_templates}
                emptyRecordsMessage={NO_TEMPLATES_ENABLED}
              />
            </div>
            <div className={'editor-label-selector'}>
              <span>
                <BootstrapDropdown
                  title={
                    labelDropdownEntity ? (
                      <>
                        <div className={'person-option-container'}>
                          {labelDropdownEntity.__typename === 'Person' && (
                            <Avatar
                              className='avatar avatar-40 partner-space-listing-avatar me-1'
                              name={labelDropdownEntity.large_avatar}
                              src={labelDropdownEntity.large_avatar}
                            />
                          )}
                          <span className='result-text-data'>
                            {labelDropdownEntity.value}
                          </span>
                        </div>
                      </>
                    ) : (
                      'PICK A LABEL OR A PATIENT'
                    )
                  }
                  inputFieldRequired={true}
                  inputFieldProps={{
                    placeholder: 'Type here to filter',
                  }}
                  labelBackground={labelDropdownEntity?.color}
                  itemKey={'label'}
                  extraClassName={'editor-dropdown'}
                  async={false}
                  items={labelOptions}
                  onClick={handleLabelDropdown}
                  optionsLoading={patientsLoading}
                  onChange={(e, textValue) => setQuery(textValue)}
                />
              </span>
            </div>
          </OsTiptapEditorField>
        ) : (
          <div
            className='field-placeholder'
            onClick={(e) => {
              e.stopPropagation();
              handleEditorVisibility(true);
            }}>
            <span>{placeholderValue}</span>
          </div>
        )}
      </div>
    </>
  );
};

const PartnerSpace = (props) => {
  const { id } = useParams();
  // const defaultPartnerSpaceId = useRef(null);
  const dispatch = useDispatch();
  // const [activePartnerSpace, setActivePartnerSpace] = useState(null);

  const { data, loading } = useQuery(GET_PARTNER_SPACE, {
    variables: { id: id ? id : 'recent' },
    fetchPolicy: 'network-only',
    // skip: id === defaultPartnerSpaceId.current,
  });

  // set Active Resource
  useEffect(() => {
    if (data) {
      // if (data.space?.nice_id !== activePartnerSpace?.nice_id) {
      // setActivePartnerSpace(() => data.space);
      // }
      dispatch(setActiveResource(data.space));
    }
    return () => {
      dispatch(setActiveResource(null));
    };
  }, [data, dispatch]);

  // useEffect(() => {
  //   if (data && !id) {
  //     defaultPartnerSpaceId.current = data.space?.nice_id;
  //     navigate(`${defaultPartnerSpaceId.current}`);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (loading) {
  //     setActivePartnerSpace(null);
  //   }
  // }, [loading]);

  if (loading) return <Loader type='ball-triangle-path' active />;
  if (!data.space) return <Navigate to='/no-partners' />;
  return <PartnerSpaceFeed space={data.space} />;
};

export default PartnerSpace;
