import React from 'react';
import OsLink from 'app/components/OsLink';
import { SOCIAL_LINKS_ACTIVE } from 'app/constants';

const SocialLink = ({ platform, openInNewTab = true }) => {
  if (!platform) return null;

  return (
    <OsLink
      href={SOCIAL_LINKS_ACTIVE[platform]}
      openInNewTab={openInNewTab}
      name={platform}
      iconName={platform}
    />
  );
};

export default SocialLink;
