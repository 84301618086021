import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { hexToRgb } from 'app/utils/colorHelper';
import AssociateLabelDropdown from 'app/components/Shared/AssociateLabelDropdown';

function StatusWidget({ widgetSourceObject }) {
  const [patient, setPatient] = useState(widgetSourceObject?.owner?.patient);

  const handleLabelClick = (label) => {
    setPatient({
      ...patient,
      entity_label: { ...patient.entity_label, label: label },
    });
  };

  useEffect(() => {
    setPatient(widgetSourceObject?.owner?.patient);
  }, [widgetSourceObject?.owner?.patient?.entity_label]);

  return (
    <div className='status-widget'>
      <Accordion className={`right-ac sidebar-container container`}>
        <Accordion.Item>
          <Accordion.Header className='rt-ac-header widget-title header status-widget-title'>
            <span>Patient Status</span>
          </Accordion.Header>
          <Accordion.Body>
            <div
              className='widget-body'
              style={{
                background: `${hexToRgb(patient?.entity_label?.label?.color)}`,
              }}>
              <AssociateLabelDropdown
                entity={patient}
                afterLabelClick={handleLabelClick}
                position='bottom-end'
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default StatusWidget;
