import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import AbstractPersonCard from './AbstractCard';
import Avatar from 'app/components/Shared/Avatar';
import CareSpaceDisableAccessBtn from 'app/components/Shared/CareSpace/DisableAccessBtn';
import CareSpaceDisableAccessModal from 'app/components/AllModalsContainer/CareSpace/DisableAccessModal';
import CareSpacePartyShareButton from 'app/components/Shared/CareSpace/ContactPartyShareButton';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import EmailLink from 'app/components/Shared/EmailLink';
import ImageEdit from 'app/components/AccountView/imageEdit';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import PhoneLink from 'app/components/Shared/PhoneLink';
import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import { closePatientModal } from 'app/actions/patientModal';
import { has, isUndefined } from 'app/utils/osLodash';
import {
  canEditPersonInfo,
  getCareAvatarIconProps,
  isUserAuthenticated,
} from 'app/utils/spaces/careHelper';
import OsRadioField from 'app/components/OsField/OsRadioField';
import OsBtn from 'app/components/OsBtn';

const genderMapper = {
  non_binary: 'Non-binary',
  male: 'Male',
  female: 'Female',
};
class PersonCard extends AbstractPersonCard {
  state = {
    showModalEdit: false,
    showEditButton: false,
    cardExpanded: this.getCardExpandedInitialValue(),
  };

  onCardClick = (e, options = {}) => {
    // this.props.onCardClick && this.props.onCardClick(e, options)
  };

  getCardExpandedInitialValue() {
    return !this.props.obj.email && !this.props.obj.phone_number;
  }

  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  renderEditButton() {
    if (this.isContactParty()) {
      return this.renderEditGuardianButton();
    } else {
      return this.renderEditPatientButton();
    }
  }

  source() {
    return 'care-space-detail';
  }

  isUserLoggedIn() {
    return isUserAuthenticated(this.props.sourceObject, {
      id: this.props.obj.user_id,
    });
  }

  renderShareWithPartyButton() {
    return (
      <CareSpacePartyShareButton
        space={this.props.sourceObject}
        contactParty={this.props.obj}
      />
    );
  }

  reenderDisableAccessButton() {
    return (
      <CareSpaceDisableAccessBtn
        space={this.props.sourceObject}
        obj={this.props.obj}
      />
    );
  }

  openModalEdit = () => {
    this.setState({ showModalEdit: true });
  };

  renderEditImageButton() {
    return (
      <OsBtn
        name='BtnIcon'
        text='Edit Profile Photo'
        icon='edit'
        onClick={this.openModalEdit}
      />
    );
  }

  closeModalEdit = () => {
    this.setState({ showModalEdit: false });
  };

  renderDropdown() {
    let isAuthorOrEditor = this.props.sourceObject?.is_author_or_editor,
      canEditInfo = isAuthorOrEditor || canEditPersonInfo(this.props.obj);
    if (!this.props.selectionMode)
      return (
        <CustomDropdown
          name='person_action'
          className='card-dropdown-menu care-space-card-dropdown top-18'
          onDropDownToggle={this.props.onDropDownToggle}>
          {canEditInfo && this.renderEditButton()}
          {canEditInfo && this.renderEditImageButton()}
          {isAuthorOrEditor && this.renderShareWithPartyButton()}
          {isAuthorOrEditor && this.reenderDisableAccessButton()}
        </CustomDropdown>
      );
  }

  isContactParty() {
    return has(this.props.obj, 'person_connection_id');
  }

  renderEmailAndPhone(sourceObject) {
    return (
      <>
        {sourceObject.phone_number && (
          <PhoneLink phone={sourceObject.phone_number} />
        )}
        {sourceObject.email && <EmailLink email={sourceObject.email} />}
      </>
    );
  }

  renderContactInformation(obj) {
    let sourceObject = obj,
      relation,
      relationType,
      relatedPerson,
      relationWithPerson;
    if (sourceObject.recent_patient_person_connection) {
      relationWithPerson =
        sourceObject.recent_patient_person_connection.relation;
      relatedPerson = sourceObject.recent_patient_person_connection.person.name;
    }
    if (obj.person_connections?.length > 0) {
      sourceObject = obj.person_connections[0].related_person;
      relation = obj.person_connections[0].relation;
    }
    relationType = obj.relation;
    return (
      <>
        {relationType && (
          <div
            className={`relation ${
              this.props.selectionMode ? 'person-card-tr' : ''
            }`}>
            {relationType}
          </div>
        )}
        {relation && (
          <div
            className={`relation ${
              this.props.selectionMode ? 'person-card-tr' : ''
            }`}>{`${relation} : ${sourceObject.name}`}</div>
        )}
        <div
          className={`pt-2 mt-1 ${
            this.props.selectionMode ? 'person-card-tr' : ''
          }`}>
          {relatedPerson && (
            <div className='mb-2 font-weight-semibold'>
              {relationWithPerson + ' of ' + relatedPerson}
            </div>
          )}
          {this.renderEmailAndPhone(sourceObject)}
        </div>
      </>
    );
  }

  renderPatientMailInfo(obj) {
    const { date_of_birth, gender } = obj;
    const info =
      gender && date_of_birth
        ? `${genderMapper[gender]}, ${date_of_birth}`
        : date_of_birth;
    return (
      <>
        <div className='dob person-card-tr'>{info}</div>
        {this.renderContactInformation(obj)}
      </>
    );
  }

  isDisableAccessModalOpen() {
    return this.props.disableCareSpaceModal.obj.id === this.props.obj.id;
  }

  radioBtnText() {
    if (this.props.isPatient || this.props.isSelect) {
      return 'Select';
    } else {
      return 'Link to Current Patient';
    }
  }

  space() {
    return this.props.sourceObject;
  }

  onShowCare = (careSpace) => {
    this.props.closePatientModal();
    this.props.navigate(entityUrl(careSpace));
  };

  onCreateCare = (patientId) => {
    this.props.onPatientClick(patientId);
  };

  renderSelectType() {
    let {
      isPatient,
      obj: { care_space, ...patient },
      selectionMode,
      name,
      contact,
      notMandatory,
    } = this.props;
    if (isPatient) {
      if (care_space) {
        return (
          <div className='create-show-carespace'>
            <OsBtn
              name='BtnSecondary'
              extraClass='with-border'
              text='Show PatientSpace'
              onClick={() => this.onShowCare(care_space)}
            />
          </div>
        );
      } else {
        return (
          <div className='create-show-carespace'>
            <OsBtn
              name='BtnSecondary'
              extraClass='with-border'
              text='Create PatientSpace'
              onClick={() => this.onCreateCare(patient.id)}
            />
          </div>
        );
      }
    } else if (selectionMode) {
      let additionalAttributes = isUndefined(contact)
        ? {}
        : { checked: patient?.id === contact };
      return (
        <OsRadioField
          name={name}
          value={patient.id}
          label={this.radioBtnText()}
          notMandatory={true}
          {...additionalAttributes}
        />
      );
    }
  }

  renderImageEditAction() {
    let btnClasses = 'user-image-edit-btn position-absolute';
    btnClasses += !this.props.device.mobileDevice
      ? 'p-0 no-text'
      : 'p-0 no-text';

    return (
      <OsBtn
        name='BtnIcon'
        extraClass={btnClasses}
        icon='selfie'
        onClick={this.openModalEdit}
      />
    );
  }

  renderImageEditButton() {
    this.setState((prevState) => ({
      showEditButton: !prevState.showEditButton,
    }));
  }

  hideExpandAction() {
    return !!this.props.hideExpandAction;
  }

  hideSelectionAction() {
    return !!this.props.hideSelectionAction;
  }

  toggleExpandState() {
    this.setState((prevState) => ({
      cardExpanded: !prevState.cardExpanded,
    }));
  }

  renderExpandIcon() {
    return (
      <div
        className={'widget-chevron-icon'}
        onClick={this.toggleExpandState.bind(this)}>
        {this.state.cardExpanded ? (
          <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
        ) : (
          <OrthoIcon name='chevron-caret-right' defaultClass='ms-1' />
        )}
      </div>
    );
  }

  renderExtraInfo(patient) {
    let { email, phone_number } = patient;
    return (
      <div className='patient-extra-info'>
        <span className='patient-email'>{email ? email : 'No Email'}</span>
        <span className='patient-phone'>
          {phone_number ? phone_number : 'No Phone Number'}
        </span>
      </div>
    );
  }

  render() {
    let isTall = ['tall', 'tall-min'].includes(this.props.size),
      avatarClass = `avatar avatar-${isTall ? 64 : 32}`,
      patient = this.props.obj,
      avatarProps = getCareAvatarIconProps(
        this.props.sourceObject,
        { id: patient.user_id },
        { isPatientOrGuardian: true },
      ),
      allAvatarAttributes = avatarAttributes(patient, {
        largeAvatarRequired: isTall,
        ...avatarProps,
      }),
      isEditModeRequired =
        this.props.sourceObject?.is_author_or_editor &&
        (!allAvatarAttributes?.src?.length || this.state.showEditButton);

    return (
      <div
        key={this.props.key}
        className={this.props.containerClasses}
        onClick={this.onCardClick}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            defaultClass='os-close-link'
            onClick={this.afterClose}
          />
        )}
        <div className='patient-card-body-wrapper'>
          <div className='patient-card-body'>
            <div className='patient-card-info'>
              <div
                onMouseEnter={this.renderImageEditButton.bind(this)}
                onMouseLeave={this.renderImageEditButton.bind(this)}>
                <Avatar className={avatarClass} {...allAvatarAttributes} />
                {isEditModeRequired && this.renderImageEditAction()}
              </div>
              <div
                className={`patient-cd-name ${
                  this.isDeclined() ? 'no-access' : ' '
                }`}>
                <span className='pt-name'>{patient.name}</span>
                {this.renderSharedWithPatientIcon()}
                {avatarProps.iconWithImage &&
                  this.renderIcon(avatarProps.iconWithImage)}
                {this.renderDeclinedIconAndReason()}
                {this.renderTags()}
              </div>
              <div className='patient-cd-address'>
                {this.renderPatientMailInfo(patient)}
                {this.state.cardExpanded && this.renderExtraInfo(patient)}
              </div>
            </div>
            {this.renderDropdown()}
            {!this.hideExpandAction() && this.renderExpandIcon()}
            {this.isDisableAccessModalOpen() && (
              <CareSpaceDisableAccessModal
                space={this.props.sourceObject}
                obj={this.props.obj}
              />
            )}
          </div>
        </div>
        {!this.hideSelectionAction() && this.renderSelectType()}
        {this.isDisableAccessModalOpen() && (
          <CareSpaceDisableAccessModal
            space={this.props.sourceObject}
            obj={this.props.obj}
          />
        )}
        <Modal
          show={this.state.showModalEdit}
          onHide={this.closeModalEdit}
          dialogClassName='modal-xl profile-image-modal'
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <ImageEdit
            closeParentModal={this.closeModalEdit}
            obj={this.props.obj}
          />
        </Modal>
      </div>
    );
  }
}

PersonCard = withRouter(PersonCard);

PersonCard = connect(
  ({ currentUser, device, disableCareSpaceModal }) => ({
    currentUser,
    device,
    disableCareSpaceModal,
  }),
  { closePatientModal },
)(PersonCard);
export default PersonCard;
