import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { loader as queryLoader } from 'graphql.macro';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import { openInfoModal } from 'app/actions/infoModal';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { translate } from 'app/actions/flashMessage';
import { fieldRequired } from 'app/utils/validationHelper';
import { entityUrl } from 'app/utils/entitiesHelper';

const DECLINE_CARE_SPACE_MUTATION = queryLoader(
  'app/graphql/mutations/Care/DeclineCareSpace.gql',
);

class DeclineCareSpace extends Component {
  state = {
    formOpen: false,
    requestInProgress: false,
    inputFieldKey: false,
  };

  toggleForm = () => {
    this.setState({ formOpen: !this.state.formOpen });
  };

  toggleRequest() {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  }

  onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.toggleForm();
  };

  isOtherReasonSelected() {
    return this.form?.getState()?.values?.rejectionReason === 'other';
  }

  rejectionDescriptionFieldValidations() {
    return this.isOtherReasonSelected()
      ? { validate: fieldRequired, autoFocusRequired: true }
      : {};
  }

  onCancelClick = () => {
    this.toggleForm();
    this.props.navigate(entityUrl(this.props.space));
  };

  sendRequest = (values) => {
    let { space } = this.props;
    this.toggleRequest();
    this.props
      .declineCareSpace({
        variables: {
          spaceId: space.nice_id,
          ...values,
        },
      })
      .then(({ data }) => {
        if (data.declineSpaceRequest.success) {
          this.props.navigate(entityUrl(this.props.space));
        } else {
          this.props.openInfoModal('care_space', 'decline_access_error');
        }
      });
  };

  toggleFieldValidations() {
    this.setState({ inputFieldKey: !this.state.inputFieldKey });
  }

  onOtherDescriptionFieldClick = (e) => {
    this.form.change('rejectionReason', 'other');
    this.toggleFieldValidations();
  };

  onOptionChange = (e) => {
    if (this.isOtherReasonSelected() || e.target.value === 'other')
      this.toggleFieldValidations();
  };

  renderForm() {
    let { requestInProgress } = this.state;
    return (
      <Form
        onSubmit={this.sendRequest}
        initialValues={{ rejectionReason: 'invalid_request' }}
        render={({ handleSubmit, values, form }) => {
          this.form = form;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <label
                  className='radio-label d-block'
                  htmlFor='invalid_request'>
                  I don’t know this person. I want to decline access and remove
                  myself as their contact.
                  <Field
                    component='input'
                    type='radio'
                    className='custom-control-input form-control'
                    name='rejectionReason'
                    id='invalid_request'
                    value='invalid_request'
                    onClick={this.onOptionChange}
                  />
                  <span className='checkmark top0'></span>
                </label>

                <label
                  className='radio-label d-block'
                  htmlFor='do_not_want_to_join'>
                  I know this person, but I don’t want to have access to this
                  CareSpace.
                  <Field
                    component='input'
                    type='radio'
                    className='custom-control-input form-control'
                    name='rejectionReason'
                    id='do_not_want_to_join'
                    value='do_not_want_to_join'
                    onClick={this.onOptionChange}
                  />
                  <span className='checkmark top0'></span>
                </label>

                <label className='radio-label d-block' htmlFor='other'>
                  Other. Please, specify below:
                  <Field
                    component='input'
                    type='radio'
                    className='custom-control-input form-control'
                    name='rejectionReason'
                    id='other'
                    value='other'
                    onClick={this.onOptionChange}
                  />
                  <span className='checkmark top0'></span>
                </label>
                <Field
                  name='rejectionDescription'
                  component={OsField}
                  osType='input'
                  key={this.state.inputFieldKey}
                  {...this.rejectionDescriptionFieldValidations()}
                  onClick={this.onOtherDescriptionFieldClick}
                />
              </div>
              <div className='modal-footer'>
                <OsBtn
                  name='BtnSecondary'
                  text='Cancel, Keep access'
                  extraClass='with-border web-view-btn'
                  onClick={this.onCancelClick}
                />
                <OsBtn
                  name='BtnPrimary'
                  type='submit'
                  text='Decline access'
                  htmlTag='button'
                  extraClass='web-view-btn'
                  associatedEntity={this.props.space}
                  loaderRequired={requestInProgress}
                  disabled={requestInProgress}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }

  renderModal() {
    return (
      <Modal
        show={true}
        animation={false}
        dialogClassName='modal-decline-access'
        backdropClassName='modal-backdrop-custom'
        onClick={(e) => e.stopPropagation()}>
        <div className='modal-header'>
          <h4 className='modal-title'>Decline access to PatientSpace</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn rel-pos top-right-0'
            icon='close'
            label='Close modal'
            onClick={this.toggleForm}
          />
        </div>
        <div className='modal-body'>
          <div className='can-edit-image'>
            <Icon name='cannotEdit' />
          </div>
          <div className='pb-3'>
            {translate('DECLINE_CARE_SPACE_ACCESS_TEXT')}
          </div>
          {this.renderForm()}
        </div>
      </Modal>
    );
  }

  render() {
    let { btnType, extraClass, icon, text } = this.props;
    return (
      <>
        <OsBtn
          name={btnType}
          text={text}
          icon={icon}
          extraClass={extraClass}
          onClick={this.onClick}
        />
        {this.state.formOpen && this.renderModal()}
      </>
    );
  }
}

DeclineCareSpace = connect(null, { openInfoModal })(DeclineCareSpace);
DeclineCareSpace = withRouter(DeclineCareSpace);
DeclineCareSpace = graphql(DECLINE_CARE_SPACE_MUTATION, {
  name: 'declineCareSpace',
})(DeclineCareSpace);
DeclineCareSpace.defaultProps = {
  btnType: 'BtnIcon',
  extraClass: '',
  icon: 'clear',
  space: {},
  text: 'Decline',
};
export default DeclineCareSpace;
