import React from 'react';

import NotificationCardLayout from './NotificationCardLayout';
import ParseText from 'app/components/ParseText';

export default class NotificationMessageCard extends NotificationCardLayout {
  onClick = (event) => {
    this.props.onClick(event, this.props);
  };

  renderContent() {
    return this.props.targets.length > 1
      ? this.renderGroupedTemplate()
      : this.renderSingleTemplate();
  }

  renderGroupedTemplate() {
    return null;
  }

  renderSingleTemplate() {
    let target = this.props.targets[0];
    let linkClassName = this.props.isSmallNotification
      ? 'a-link fs-12'
      : 'a-link';
    if (target && target.content) {
      let readMoreLinkUrl =
        this.props.isSmallNotification &&
        `/conversations/${target.conversation_id}`;
      return (
        <ParseText
          obj={target}
          className={'nf-text-info'}
          onClick={this.readNotification}
          linkClassName={linkClassName}
          readMoreLinkUrl={readMoreLinkUrl}
          readMoreRequired={this.props.isSmallNotification}
          truncateWordsUpto={65}
        />
      );
    }
  }

  renderBody() {
    return (
      <div className='pointer' onClick={this.onClick}>
        {this.renderContent()}
      </div>
    );
  }
}
