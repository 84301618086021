import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NAV_TABS, TAB_DETAILS_MAPPER } from 'app/utils/Workspace/navHelper';
import { getWorkspaceNavTabs } from 'app/utils/Workspace/navHelper';

import ExploreFeed from 'app/components/ExploreFeed';
import RedirectToDefault from 'app/components/RedirectToDefault';
import SpaceMembersView from 'app/components/SpaceTabs/MembersView';
import SpaceContentListing from 'app/components/WorkspaceView/SpaceTabs/ContentListing';

import { entityUrl } from 'app/utils/entitiesHelper';
import { userDefaultPage } from 'app/utils/userRoleHelper';

import { cloneDeep, difference, isEmpty, keys } from 'app/utils/osLodash';
export const FEEDS_ROUTES = {
  rootPulsesView: {
    exact: true,
    path: '/',
    component: ExploreFeed,
    titleMetaInfo: {
      text: 'Cloudberry Feed',
    },
  },
  explore: {
    exact: true,
    path: '/explore',
    component: ExploreFeed,
    titleMetaInfo: {
      text: 'Cloudberry Feed',
    },
  },
  filterByExplore: {
    path: '/explore/:feedType/:filterBy',
    component: ExploreFeed,
    titleMetaInfo: {
      text: 'Cloudberry Feed',
    },
  },
  feedTypeExplore: {
    path: '/explore/:feedType',
    component: ExploreFeed,
    titleMetaInfo: {
      text: 'Cloudberry Feed',
    },
  },
};
const DISCUSSION_ROUTES = { ...cloneDeep(FEEDS_ROUTES) };

keys(DISCUSSION_ROUTES).map(
  (key) => (DISCUSSION_ROUTES[key].titleMetaInfo.text = 'Discussion'),
);

const ROUTE_INFO = {
  members: {
    path: '/members',
    component: SpaceContentListing,
    componentProps: { componentToWrap: SpaceMembersView },
    titleMetaInfo: {
      text: 'Members',
    },
  },
  partners: {
    path: '/partners',
    component: SpaceContentListing,
    titleMetaInfo: {
      text: 'Partners',
    },
  },
  team_spaces: {
    path: '/team_spaces',
    component: SpaceContentListing,
    titleMetaInfo: {
      text: 'Collaboration Spaces',
    },
  },
  carespaces: {
    path: '/patient/activity',
    component: SpaceContentListing,
    titleMetaInfo: {
      text: 'CareSpaces',
    },
  },
  folders: {
    path: '/folders',
    component: SpaceContentListing,
    titleMetaInfo: {
      text: 'Folders',
    },
  },
  ...DISCUSSION_ROUTES,
};

const DEFINED_ROUTES = keys(TAB_DETAILS_MAPPER);

class WorkspaceRoutes extends Component {
  renderRoute = (route, key, options = {}) => {
    if (key === 'explore' && !options.additionalRoutesNotRequired) {
      return keys(DISCUSSION_ROUTES).map((route) =>
        this.renderRoute(ROUTE_INFO[route], route, {
          additionalRoutesNotRequired: true,
        }),
      );
    } else if (route) {
      let {
        path,
        component: Component,
        exact = false,
        componentProps = {},
        componentHash = {},
        action,
      } = route;
      Component =
        Component ||
        (this.props.currentUser.token
          ? componentHash.authenticated
          : componentHash.guest);
      const element = (
        <div className='container-workspace container-fluid px-0'>
          <Component
            filter={key}
            {...componentProps}
            key={key}
            guestUser={!this.props.currentUser.graph}
            action={action}
            tabDetails={options.tabDetails}
          />
        </div>
      );
      return <Route path={path} exact={exact} element={element} />;
    }
  };

  redirectWorkspaceRouteToRoot(space) {
    if (!isEmpty(space))
      return (
        <Route
          path={entityUrl(space, false, { skipWorkspace: true })}
          exact={true}
          element={<RedirectToDefault />}
        />
      );
  }

  render() {
    const space = this.props.workspace.data,
      user = this.props.currentUser.graph,
      { navTabs } = getWorkspaceNavTabs(),
      tabs = difference(
        DEFINED_ROUTES,
        difference(
          NAV_TABS.map((k) => k.key),
          navTabs.map((k) => k.key),
        ),
      );
    if (!space)
      return (
        <Routes>
          <Route path='*' element={<Navigate to='/no_records' />} />
        </Routes>
      );

    return (
      <Routes>
        {this.redirectWorkspaceRouteToRoot(space)}
        {tabs.map((route) =>
          this.renderRoute(ROUTE_INFO[route], route, {
            tabDetails: TAB_DETAILS_MAPPER[route],
          }),
        )}
        <Route path='*' element={<Navigate to={userDefaultPage(user)} />} />
      </Routes>
    );
  }
}

WorkspaceRoutes = connect(
  ({ currentUser, workspace }) => ({ currentUser, workspace }),
  {},
)(WorkspaceRoutes);
export default WorkspaceRoutes;
