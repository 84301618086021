import React, { Component } from 'react';

import RecordsView from 'app/components/RecordsView';
import OsGrid from 'app/components/OsGrid';
import {
  canSpaceHaveDiscussions,
  iCompanyTypeSpace,
  isOpenSpace,
} from 'app/utils/spaceHelper';

class RecentDiscussions extends Component {
  getRequestType() {
    return `${this.props.widgetSourceObject.__typename}RecentDiscussions`;
  }

  renderContent() {
    return (
      <OsGrid identifier='DetailRightSidebar:RecentDiscussionsCol'>
        <div
          className={`sidebar-container ${
            this.props.sideBarClass || 'recent-discussion-bar no-card-effect'
          } ${this.sourceType}`}>
          <div className='sidebar-widget'>
            <div className='widget-title'>Recent discussions</div>
            <div ref={(el) => (this.parentContainer = el)}>
              <RecordsView
                className='collection'
                type={this.getRequestType()}
                queryType='COMMENTS_LISTING'
                idQuery={this.props.widgetSourceObject.id}
                perPage={10}
                infiniteScroll={true}
                showMoreNotRequired={true}
                size='small'
                viewType='List'
                cardGridIdentifier={'RecentDiscussions:CardCols'}
                windowNotRequired={true}
                isReverse={true}
                threshold={100}
                trackScrolling={true}
                parentContainer={this.parentContainer}
              />
            </div>
          </div>
        </div>
      </OsGrid>
    );
  }

  isCommentableAccessible() {
    let { widgetSourceObject } = this.props;
    switch (widgetSourceObject.__typename) {
      case 'Board':
        return (
          canSpaceHaveDiscussions(widgetSourceObject) &&
          ((isOpenSpace(widgetSourceObject) &&
            iCompanyTypeSpace(widgetSourceObject)) ||
            widgetSourceObject.is_author_or_collaborator)
        );
      default:
        return true;
    }
  }

  isCommentSectionVisible() {
    return (
      this.props.widgetSourceObject.comments_count > 0 &&
      this.isCommentableAccessible()
    );
  }

  render() {
    if (this.isCommentSectionVisible()) {
      return this.renderContent();
    } else {
      return null;
    }
  }
}

export default RecentDiscussions;
