import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { keys } from 'app/utils/osLodash';
import { Navigate } from 'react-router';

class RouteReplacer extends Component {
  state = {
    pathname: null,
  };

  componentDidMount() {
    let {
      match: { params },
      location: { pathname },
      from,
      to,
    } = this.props;
    keys(params).forEach((key) => {
      from = from.replace(`:${key}`, params[key]);
    });
    pathname = pathname.replace(from, to);
    this.setState({ pathname });
  }

  render() {
    let { pathname } = this.state;
    if (pathname) {
      return <Navigate to={this.state.pathname} />;
    } else {
      return <div />;
    }
  }
}

RouteReplacer = withRouter(RouteReplacer);

export default RouteReplacer;
