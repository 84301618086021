import React, { Component } from 'react';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import MarkEntityAsViewedOrUnViewed from 'app/components/Shared/MarkEntityAsViewedOrUnViewed';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RenameEntity from 'app/components/RenameEntity';
import DefaultThumbnailCard from './Thumbnail/Default';
import TableViewCard from './TableViewCard';

import {
  entityUrl,
  getAuthorizeObject,
  isCardViewed,
  isDoubleTickOverlayRequired,
} from 'app/utils/entitiesHelper';
import { isCareSpace, isViewedOverlayRequired } from 'app/utils/spaceHelper';
import { isEmpty } from 'app/utils/osLodash';

export const CARD_ACTION_CONTEXT = React.createContext({ source: null });

class AbstractCard extends Component {
  renderRemoveButton() {
    if (this.isSourceSpaceDetail() && this.context.removeFromSpace) {
      let { buttonProps, onClick } = this.context.removeFromSpace,
        obj = this.props.wrapperObject || this.props.obj;
      buttonProps.extraClass = `${buttonProps.extraClass || ''} brt-top`;
      return <OsBtn {...buttonProps} onClick={onClick.bind(this, obj)} />;
    }
  }

  renderSelectButton(isPatient) {
    let text = isPatient ? 'Select PatientSpace' : 'Select';
    if (
      this.isSourceSpaceDetail() &&
      this.context.onCardSelectClick &&
      this.selectButtonPreferrableCardSize()
    )
      return (
        <OsBtn
          name='BtnIcon'
          icon='round-check'
          text={this.getLabel(text)}
          extraClass='list-button ms-0'
          onClick={this.onSelectBoxClick}
        />
      );
  }

  renameEntityPreferrableCardSize() {
    return [
      'tall',
      'tall-min',
      'extra-small',
      'small',
      'big',
      'list',
      'thumbnail',
    ].includes(this.props.size);
  }

  selectButtonPreferrableCardSize() {
    return [
      'tall',
      'tall-min',
      'extra-small',
      'small',
      'big',
      'list',
      'thumbnail',
    ].includes(this.props.size);
  }

  isTall() {
    return ['tall', 'tall-min'].includes(this.props.size);
  }

  isExtraSmall() {
    return this.props.size === 'extra-small';
  }

  isSourceSpaceDetail() {
    return this.context.source === 'space-detail';
  }

  getExtraProps(text, icon, btnName) {
    let props = {
      text: this.isExtraSmall() ? '' : text,
      icon: this.isExtraSmall() ? icon : '',
      name: this.isExtraSmall() ? 'BtnIcon' : btnName,
      extraClass: this.isExtraSmall() ? ' no-text ' : '',
    };
    return props;
  }

  onSelectBoxClick = (e) => {
    if (this.isSourceSpaceDetail() && this.context.onCardSelectClick) {
      e.preventDefault();
      e.stopPropagation();
      this.context.onCardSelectClick(this.props.obj);
    }
  };

  getRenameEntityObj() {
    let { obj } = this.props;
    return obj.link || obj;
  }

  onRename = (newEntity) => {
    this.context.onRename && this.context.onRename(newEntity, this.props.obj);
  };

  getLabel(name) {
    if (isCareSpace(this.context.sourceObject)) {
      if (name === 'Select') {
        return name.concat(' Multiple Files');
      } else {
        return name.concat(' File');
      }
    }
    return name;
  }

  renderEntityRenameButton() {
    if (this.renameBtnRequired()) {
      let { obj } = this.props,
        renameEntityObj = this.getRenameEntityObj();

      return (
        <RenameEntity
          obj={obj}
          entityId={renameEntityObj.id}
          entityType={renameEntityObj.__typename}
          formNode={this.getRenameFormUniqueIdentifier()}
          onClick={this.setRenameFormVisibility}
          onRename={this.onRename}
          label={this.getLabel('Rename')}
        />
      );
    }
  }

  isViewedOverlayRequired() {
    return isViewedOverlayRequired(this);
  }

  viewObject = (link = this.props.obj.link) => {
    if (!isEmpty(link)) {
      let linkObj = {
        id: link.id,
        __typename: 'BoardLink',
        view_count: 0,
      };
      this.props.viewObject({ obj: linkObj });
    }
  };

  markAsFinished() {
    if (
      isDoubleTickOverlayRequired(this.context.sourceObject) &&
      this.isViewedOverlayRequired()
    )
      return (
        <MarkEntityAsViewedOrUnViewed
          viewed={this.props.obj.link.viewed_space_content}
          obj={this.props.obj.link}
        />
      );
  }

  isSmallCard() {
    return ['small', 'small-min'].includes(this.props.size);
  }

  setRenameFormVisibility = (renameFormVisibile) => {
    this.setState({ renameFormVisibile });
  };

  getRenameFormUniqueIdentifier() {
    return `${this.props.obj.__typename}-${this.getRenameEntityObj().id}`;
  }

  isCardType(type) {
    return this.props.obj.__typename === type;
  }

  openRelatedCases = (e) => {
    this.onCardClick(e, { url: entityUrl(this.props.obj) + '/related_cases' });
  };

  renderPlayIcon() {
    return (
      <OrthoIcon
        name='play'
        defaultClass='icon-space-overlay'
        onClick={this.openPreview}
      />
    );
  }

  isAuthorOrEditor() {
    return this.props.obj.is_author_or_editor;
  }

  isAuthorOrEditorOfSourceObject() {
    return (
      this.context.sourceObject && this.context.sourceObject.is_author_or_editor
    );
  }

  isPartOfSourceObject() {
    return (
      this.context.sourceObject &&
      this.context.sourceObject.is_author_or_collaborator
    );
  }

  isContextCourseObj() {
    return (
      this.context &&
      this.context.sourceObject &&
      this.context.contentPreviewListing
    );
  }

  renderDoubleTickIconIfRequired(link) {
    link = link || this.props.obj.link;
    if (isCardViewed(link, this.context.sourceObject))
      return (
        <>
          <span className='blurspan'></span>
          {this.renderDoubleTickIcon()}
        </>
      );
  }

  renderDoubleTickIcon() {
    return (
      <OrthoIcon
        name='roundCheck'
        defaultClass='icon-space-overlay'
        dataHoverNotRequired={true}
      />
    );
  }

  renderReleateCasesIcon() {
    if (this.isCardType('Tool') && this.props.obj.cases_count > 0) {
      return (
        <AuthorizeComponent
          onClick={this.openRelatedCases}
          abilityObject={getAuthorizeObject('index', 'case')}>
          <OsBtn
            name='BtnIcon'
            extraClass='osbtn-tl-case px-8'
            icon='cases'
            text={this.props.obj.cases_count}
            label='related_cases'
            associatedEntity={this.props.obj}
            title='Related cases'
          />
        </AuthorizeComponent>
      );
    }
  }

  renameBtnRequired() {
    return this.isSourceSpaceDetail();
  }

  renderEnityRenameForm() {
    //Form content will be rendered through Rename Entity Portal
    if (this.renameBtnRequired()) {
      return (
        <div
          className={
            'card-attachment-body' +
            (this.state.renameFormVisibile ? ' with-rename' : ' d-none')
          }>
          <div id={this.getRenameFormUniqueIdentifier()}></div>
        </div>
      );
    }
  }

  renderSelectBox() {
    let isSelected = this.props.selected,
      icon = isSelected ? 'roundCheck' : 'roundCheckDefault';
    if (this.isExtraSmall()) {
      return (
        <OsBtn
          name='BtnIcon'
          icon={icon}
          extraClass={`selectable-item ${isSelected ? 'selected' : ''} no-text`}
          onClick={this.onSelectBoxClick}
        />
      );
    }
    return (
      <OsBtn
        name='BtnIcon'
        text={isSelected ? 'Selected' : 'Select'}
        icon={icon}
        extraClass={`selectable-item ${isSelected ? 'selected' : ''} `}
        onClick={this.onSelectBoxClick}
      />
    );
  }

  isDynamicWidthCard() {
    return this.props.size === 'thumbnail' || this.props.size === 'big';
  }

  dateAdded() {
    return this.props.obj.created_at;
  }

  entityType() {}
  getEntityImageUrl() {}
  getEntityTitle() {}
  getEntitySubTitle() {}
  getEntityImageChildren() {}
  getFileExtension() {}
  lastUsedTitle() {}
  lastUsedSubtitle() {}
  renderDropDown() {}

  renderThumbnail() {
    const props = {
      image: this.getEntityImageUrl(),
      contentChildren: this.getEntityImageChildren(),
      title: this.getEntityTitle(),
      subtitle: this.getEntitySubTitle(),
      onClick: this.onCardClick,
      dropDownContent: this.renderDropDown(),
      fileType: this.getFileExtension(),
      multiSelectMode: this.props.multiSelectMode,
      selected: this.props.selected,
      type: this.entityType(),
      size: this.props.size,
      onSelectBoxClick: this.onSelectBoxClick,
      renderEnityRenameForm: this.renderEnityRenameForm(),
    };
    return <DefaultThumbnailCard {...props} />;
  }

  renderTableCard() {
    const props = {
      image: this.getEntityImageUrl(),
      contentChildren: this.getEntityImageChildren(),
      title: this.getEntityTitle(),
      subtitle: this.getEntitySubTitle(),
      onClick: this.onCardClick,
      dateAdded: this.dateAdded(),
      type: this.entityType(),
      lastUsedTitle: this.lastUsedTitle(),
      lastUsedSubtitle: this.lastUsedSubtitle(),
      dropDownContent: this.renderDropDown(),
      multiSelectMode: this.props.multiSelectMode,
      selected: this.props.selected,
      onSelectBoxClick: this.onSelectBoxClick,
      renderEnityRenameForm: this.renderEnityRenameForm(),
    };
    return <TableViewCard {...props} />;
  }

  renderFooterOrSelectBox() {
    if (this.props.multiSelectMode) {
      return this.renderSelectBox();
    } else {
      return this.renderFooter();
    }
  }

  render() {
    if (this.isDynamicWidthCard()) {
      return this.renderThumbnail();
    } else if (this.props.size === 'list') {
      return this.renderTableCard();
    } else {
      return this.renderCard();
    }
  }
}

AbstractCard.contextType = CARD_ACTION_CONTEXT;
export default AbstractCard;
