import CommentableChannel from 'app/channels/CommentableChannel';
import React, { useEffect } from 'react';
import CursorCollaboration from 'app/components/Collaboration/CursorCollaboration';
import { isFeaturePermitted } from 'app/utils/userHelper';

const CommentableView = (props) => {
  useEffect(() => {
    CommentableChannel.setup(props.entity);
    return () => {
      CommentableChannel.delete();
    };
  }, [props.entity?.id]);
  return (
    <>
      {isFeaturePermitted('cursor-collaboration') && <CursorCollaboration />}
      {props.children}
    </>
  );
};

export default CommentableView;
