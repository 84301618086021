import React, { Component } from 'react';

import SpaceContentListing from 'app/components/NavTabs/ContentListing';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';
import gql from 'graphql-tag';

import { NAV_LIST_PATHNAME_DETAILS_MAPPER } from 'app/components/BoardView/constants';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import { PATIENT_OPTIONS_MAPPING } from 'app/components/ContentWithSidebars/PatientDirectoryList';
import PayWallView from 'app/components/NonSubscribedView/PayWallView';

NAV_LIST_PATHNAME_DETAILS_MAPPER['members']['additionalAttributes'] = [
  'members_listing_layout',
  'admin_and_editors_count',
  'non_connected_members_count',
];
NAV_LIST_PATHNAME_DETAILS_MAPPER['lectures']['additionalAttributes'] = [
  'lectures_available',
];

export function getWorkSpaceContentQuery(filter, guestUser) {
  if (guestUser) {
    return gql`
      {
        space: guestWorkspace {
          id
          nice_id
          name
          type
          ${
            NAV_LIST_PATHNAME_DETAILS_MAPPER[filter]
              ? NAV_LIST_PATHNAME_DETAILS_MAPPER[filter].attribute || ''
              : ''
          }
          user {
            id
            nice_id
          }
        }
      }
    `;
  } else {
    return gql`
      {
        space: workspace {
          id
          nice_id
          name
          type
          is_author_or_editor
          ${
            NAV_LIST_PATHNAME_DETAILS_MAPPER[filter]
              ? NAV_LIST_PATHNAME_DETAILS_MAPPER[filter].attribute || ''
              : ''
          }
          ${
            filter === 'members'
              ? NAV_LIST_PATHNAME_DETAILS_MAPPER['members'][
                  'additionalAttributes'
                ].join('\n')
              : ''
          }
          ${
            filter === 'lectures'
              ? NAV_LIST_PATHNAME_DETAILS_MAPPER['lectures'][
                  'additionalAttributes'
                ].join('\n')
              : ''
          }
          user {
            id
            nice_id
          }
        }
      }
    `;
  }
}

class WithSpaceListingWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: withGraphqlRequests(
        getWorkSpaceContentQuery(props.filter, props.guestUser),
        {
          options: (props) => ({
            fetchPolicy: 'network-only',
          }),
        },
        {
          objectIdentifier: 'space',
          loaderType: 'cards',
        },
      )(props.componentToWrap),
    };
  }

  render() {
    const Container = this.state.component;
    let source = '',
      filter = '';
    if (this.props.filter === 'team_spaces') {
      source = 'team_spaces';
    } else if (this.props.filter === 'folders') {
      source = 'folders';
    } else if (this.props.type === 'care_feed') {
      source = 'care_feed';
      filter = PATIENT_OPTIONS_MAPPING[this.props.filter].label;
    } else if (this.props.filter === 'partners') {
      source = 'partners';
    }
    return (
      <>
        <PayWallView source={source}>
          <SetPageTitle source={source} interpolations={filter} />
          <div className={this.props.containerClass}>
            <Container {...this.props} />
          </div>
        </PayWallView>
      </>
    );
  }
}

WithSpaceListingWrapper.defaultProps = {
  containerClass: 'container pt-24',
  componentToWrap: SpaceContentListing,
};

export default WithSpaceListingWrapper;
