import {
  cloneFileWithRandomName,
  fetchFileExtension,
} from 'app/utils/fileHelper';
import OrthoS3 from 'app/vendors/react-s3/OrthoS3';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import { decryptCredentials } from 'app/utils/decryptionHelper';
import { useState } from 'react';
import { useMutation } from '@apollo/client';

const SECRET_CREDENTIALS_QUERY = queryLoader(
  'app/graphql/SecretCredentials.gql',
);
const ADD_FILE_ASSET_MUTATION = queryLoader('app/graphql/AddFileAsset.gql');

const useLocalFileUpload = () => {
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [assetDetails, setAssetDetails] = useState(null);
  const [error, setError] = useState(null);
  const [userAssetSaveMutation] = useMutation(ADD_FILE_ASSET_MUTATION);

  const decryptedKeys = (credentials, folderName, acl) => {
    let dirName = 'uploads/user_asset_files/';
    return {
      bucketName: decryptCredentials(credentials.bucket_name),
      dirName: dirName + folderName,
      region: decryptCredentials(credentials.region),
      accessKeyId: decryptCredentials(credentials.access_key_id),
      secretAccessKey: decryptCredentials(credentials.secret_access_key),
      acl,
    };
  };

  const resetPercentage = () => {
    setPercentageCompleted(0);
  };

  const onUploadProgress = (file, percentageCompleted) => {
    let progress = percentageCompleted.toFixed(0);
    setPercentageCompleted(progress);
  };

  const onUploadComplete = (data, file) => {
    uploadUserAsset(data.location, file);
  };

  // Upload file as user asset
  const uploadUserAsset = async (fileUrl, file) => {
    const { data } = await userAssetSaveMutation({
      variables: {
        fileUrl,
        fileName: file.name,
        fileSize: file.size,
        entityRequired: true,
      },
    });

    if (data.addFileAsset.success) {
      const { entity } = data.addFileAsset;
      setAssetDetails(entity);
    }
    setIsLoading(false);
  };

  const uploadFile = async (localFile) => {
    if (!localFile) {
      setError('No file selected');
      return;
    }
    if (!fetchFileExtension(localFile.name)) {
      setError('Invalid file extension');
      return;
    }
    setIsLoading(true);
    const file = cloneFileWithRandomName(localFile);
    const { data = {} } =
      (await client.query({ query: SECRET_CREDENTIALS_QUERY })) || {};
    const { secret_credentials: credentials } = data;
    const folderName = Math.floor(Date.now() / 1000);
    const acl = 'public-read';
    const config = decryptedKeys(credentials, folderName, acl);
    OrthoS3.uploadFile(file, config, { onUploadProgress, onUploadComplete });
  };

  return {
    error,
    assetDetails,
    isLoading,
    percentageCompleted,
    resetPercentage,
    uploadFile,
  };
};

export default useLocalFileUpload;
