import store from 'app/store';
import { camelCase, has, isEmpty, isUndefined } from 'app/utils/osLodash';
import { isPresent, parseNonHtmlContent } from 'app/utils/stringHelper';
import { currentUser, isCurrentUserSystemAdmin } from './userHelper';
import { clvViewRequired } from 'app/components/NavTabs/helper';
import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';

import LocalStorageManager from 'app/services/LocalStorageManager';

export function isAdminOrPartOfSpace(space) {
  let currentUser = store.getState().currentUser.graph;
  return (currentUser && currentUser.admin) || space.is_author_or_collaborator;
}

export function canSpaceHaveDiscussions(space) {
  return space.discussions_included && isTabVisible('discussions', space);
}

export function canSpaceBeArchived(space) {
  return CanSpaceArchivedOrUnarchived(space) && !isSpaceArchived(space);
}

export function canSpaceBeUnArchived(space) {
  return CanSpaceArchivedOrUnarchived(space) && isSpaceArchived(space);
}

function CanSpaceArchivedOrUnarchived(space) {
  return isSpaceOwner(space) && !isCareSpace(space);
}

export function getSpaceIconType(space, options = {}) {
  if (isContentTypeSpace(space)) {
    return isOpenSpace(space) || options.forcePublished ? 'boards' : 'locked';
  } else {
    return camelCase(`${space.type} Space`);
  }
}

export function hasGuestUserAccessibleTabs(space) {
  return isCompanySpace(space);
}

export function iCompanyTypeSpace(space) {
  return isCompanySpace(space);
}

export function isCareSpace(space) {
  return space?.type === 'Care';
}

export function isFolderSpace(space) {
  return space.__typename === 'FolderSpace';
}

export function isOfPartnerTypeSpace(space) {
  return space.__typename === 'PartnerSpace';
}

export function isTeamFolderSpace(space) {
  return isFolderSpace(space) && isOpenSpace(space);
}

export function isClinicSpace(space) {
  return space?.type === 'Clinic';
}

export function isPartnerSpace(space) {
  return space?.type === 'Partner';
}

export function isCompanySpace(space) {
  return space?.type === 'Company';
}

export function isContentTypeSpace(space) {
  return space?.type === 'Content';
}

export function isDefaultSpace(space) {
  return isReadLaterSpace(space) || isFirstSpace(space);
}

export function isDeletableSpace(space) {
  return space.id && space.can_be_deleted;
}

export function isFirstSpace(space) {
  return space.objective === 'first_board';
}

export function isOpenSpace(space) {
  return space.category === 'open';
}

export function isPrivateSpace(space) {
  return space.category === 'close';
}

export function isReadLaterSpace(space) {
  return space.objective === 'read_later';
}

export function isWorkspaceType(space) {
  return space.type === 'Workspace';
}

export function isSpaceArchived(space) {
  return space.workflow_state === 'archived';
}

export function isSpaceOwner(space) {
  let currentUser = store.getState().currentUser.graph;
  return (
    currentUser && space && space.user && +currentUser.id === +space.user.id
  );
}

export function isViewedOverlayRequired(_this) {
  return (
    !isEmpty(_this.context.sourceObject) &&
    _this.context.contentPreviewListing &&
    false
  );
}

export function isSpace(obj) {
  return obj && obj.__typename === 'Board';
}

export function isSubSpace(space) {
  return space && space.owner && isSpace(space.owner);
}

export function isGroupSpace(space) {
  return (
    space && space.type === 'Content' && space.category_type === 'community'
  );
}

export function isNewSubSpaceAllowed(space) {
  return space && space.can_have_subspaces;
}

export function isTabVisible(tab, space) {
  if (isCompanySpace(space)) {
    let databaseValue;
    switch (tab) {
      case 'kol':
        databaseValue = space.key_opinion_leaders_visible;
        break;
      case 'products':
        databaseValue = space.featured_tools_visible;
        break;
      default:
        databaseValue = space[`${tab}_visible`];
    }
    return isUndefined(databaseValue) || databaseValue;
  } else {
    return true;
  }
}

export function isDynamicTemplate(entity) {
  return entity.__typename === 'DynamicTemplate';
}

export function isInfoSectionAvailable(space = {}) {
  return (
    isTabVisible('information', space) &&
    (space.is_author_or_editor ||
      isPresent(parseNonHtmlContent(space.information)))
  );
}

export function isNonOrthoPresent(space = {}) {
  return space.non_orthodontist_present;
}

export function isTouNotAccepted(space) {
  return space.user_request && !space.user_request.tou_accepted;
}

export function addAttachmentBannerKey(space = {}) {
  return isNonOrthoPresent(space) && !isCareSpace(space) && !isWorkspaceView()
    ? 'GENERAL_USER_PRESENT_IN_SPACE'
    : '';
}

export function getSpaceTabSourceForGuestUser(space) {
  if (isCompanySpace(space)) {
    return 'space-detail-information-guest-company';
  } else if (isClinicSpace(space)) {
    return 'space-detail-information-guest-clinic';
  } else {
    return 'space-detail-information-guest';
  }
}

export function getSpaceTabSource(space, tab, isGuestUser) {
  if (isGuestUser) {
    return getSpaceTabSourceForGuestUser(space);
  }

  if (isContentTypeSpace(space)) {
    return `space-detail-${space.category}-${tab}`;
  } else {
    if (isCareSpace(space) && isCareWorkspaceView()) {
      return `space-detail-${tab}-${space.type.toLowerCase()}-patientview`;
    } else {
      return `space-detail-${tab}-${space.type.toLowerCase()}`;
    }
  }
}

export function setSpaceToken(token) {
  // Hack: Space tab name cannot be greater than 20
  if (token && token.length === 32) {
    LocalStorageManager.set('space_invite_token', token);
  }
}

export function canEditInlineInformation(space) {
  return space && (space.is_author_or_editor || isCurrentUserSystemAdmin());
}

export function tabNotAvailableAsUserNotVerified(space, type) {
  return false;
}

export function isCurrentUserOwnedSpace(space) {
  return space && +space.user.id === +currentUser().id;
}

export function isSpaceBannerContentPresent(space) {
  const spaceBanner = space.space_banner;
  return (
    (space.lecture_preview_tabs || []).length > 0 ||
    !isEmpty(spaceBanner.case) ||
    !isEmpty(spaceBanner.video_attachment) ||
    !isEmpty(spaceBanner.image_attachment)
  );
}

export function caseAsSpaceTitle(space) {
  let instructorOrAuthorName = space.user.full_name,
    spaceBannerTitle = `${space.name} by ${instructorOrAuthorName}`;

  return spaceBannerTitle;
}

export function isOfTypeSpace(entity) {
  return entity && entity.__typename === 'Board';
}

export function isLecturesAvailable(space) {
  return space.lectures_available;
}

export function anyTopBannerPresent(space) {
  const spaceBanner = space?.space_banner || {};
  return (
    space &&
    (clvViewRequired(space) ||
      !isEmpty(spaceBanner.case) ||
      !isEmpty(spaceBanner.video_attachment) ||
      !isEmpty(spaceBanner.image_attachment))
  );
}

export function lockedSpacePath(space) {
  return `/locked_space/${space.nice_id}`;
}

export function isLockedSpace(space) {
  return (
    space.user_request &&
    has(space.user_request, 'authenticated') &&
    !space.user_request.authenticated
  );
}

export function isGroupNotificationMuted(space) {
  return !space.followed_by_current_user;
}

export function editorToggleColumnRequired(space) {
  return !['Workspace', 'Content'].includes(space.type);
}
