import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import immutableUpdate from 'immutability-helper';

import { loader as queryLoader } from 'graphql.macro';
import { timestamp, timeWithFormat } from 'app/utils/timeHelper';
import { isEmpty, cloneDeep, map } from 'app/utils/osLodash';
import { pluralize } from 'app/utils/generalHelper';
import CommentableChannel from 'app/channels/CommentableChannel';
import usePreviousValue from 'app/hooks/usePreviousValue';
import MessageBlock from 'app/components/MessageBlock';

const ENTITY_TYPE_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/EntityTypeSubscription.gql',
);

const SpaceMessageListing = (props) => {
  const [state, setState] = useState({
    currentTimestamp: Date.now(),
  });
  const currentUser = useSelector((state) => state.currentUser);
  const [eventSubscription, setEventSubscription] = useState(null);
  const timeIntervalRef = useRef(null);
  const messagesEndRef = useRef(null);
  const prevProps = usePreviousValue(props);
  const prevState = usePreviousValue(state);
  const { spaceId, recordType } = props;

  useEffect(() => {
    scrollToBottom(100);
    // eventChannelConnect();
    props.setListComponentRef && props.setListComponentRef(this);
    timeIntervalRef.current = setInterval(
      () => setState({ currentTimestamp: Date.now() }),
      59000,
    );

    return () => {
      // eventChannelDisconnect();
      clearInterval(timeIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    let unsubscribe = () => {},
      scrollToBottomMethod = scrollToBottom;
    if (spaceId && recordType) {
      unsubscribe = props.subscribeToMore({
        document: ENTITY_TYPE_SUBSCRIPTION,
        variables: { entityType: recordType, entityId: spaceId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } = subscriptionData.data.entity_subscription;

          if (entity.__typename != 'Activity') return prev;

          let updatedData = cloneDeep(prev);
          let parentActivityId = entity.parent_activity_id || entity.id;
          const alreadyPresentActivity = updatedData.records_pager.results.find(
            (obj) => +obj.id === +parentActivityId,
          );
          if (alreadyPresentActivity) {
            updatedData.records_pager.results =
              updatedData.records_pager.results.filter(
                (obj) => +obj.id !== +parentActivityId,
              );
            if (entity.parent_activity_id) {
              alreadyPresentActivity.objects[0].additional_replies = [
                entity.objects[0],
              ];
            }
          } else {
            updatedData.records_pager.total += 1;
          }
          let payload = { records_pager: {} };
          payload.records_pager.results = {
            $unshift: alreadyPresentActivity
              ? [alreadyPresentActivity]
              : [entity],
          };
          updatedData = immutableUpdate(updatedData, payload);
          scrollToBottomMethod(4000);
          return Object.assign({}, prev, updatedData);
        },
      });
    }
    return () => unsubscribe();
  }, [spaceId, recordType]);

  // useEffect(() => {
  //   if (!eventSubscription) eventChannelConnect();

  //   if (prevProps) {
  //     handleNewMessages(prevProps.results, props.results);
  //   }
  // }, [prevProps, prevState]);

  const handleNewMessages = (prevResults, newResults) => {
    if (prevResults.length > 0 && prevResults.length < newResults.length) {
      let messageElement,
        newMessagePresent =
          newResults.length - prevResults.length === 1 &&
          !map(prevResults, 'id').includes(
            newResults[newResults.length - 1].id,
          );

      if (newMessagePresent) {
        const isGenericActivity =
          newResults[newResults.length - 1].source_type === 'GenericActivity';
        let newMessage = newResults[newResults.length - 1]?.objects[0];
        messageElement = getMessageElementById(newMessage.id);

        if (isGenericActivity) {
          messageElement = getMessageElementById(
            newResults[newResults.length - 1].id,
          );
          scrollToElement(messageElement, 100);
        } else if (isOwnMessage(newMessage)) {
          // Message send: scroll to message
          scrollToElement(messageElement, 100);
        } else {
          scrollToElement(messageElement, 100);
          // Message received and it is already in viewport: mark as read
          setTimeout(() => {
            props.distanceFromBottom === 0 && markConversationAsRead();
          }, 0);
        }
      } else {
        // New batch loaded: scroll to first message of the new batch
        let scrollToMessageId =
          newResults[newResults.length - prevResults.length - 1]?.objects?.[0]
            .id;
        messageElement = getMessageElementById(scrollToMessageId);
        scrollToElement(messageElement, 100);
      }
    }
  };

  const getMessageElementById = (messageId) => {
    return document.getElementById('comment-' + messageId);
  };

  const markConversationAsRead = () => {
    eventSubscription && eventSubscription.read_all();
  };

  const eventChannelConnect = () => {
    const { entity } = props;
    eventChannelDisconnect();
    const subscription = CommentableChannel.setup(entity);
    setEventSubscription(subscription);
  };

  const eventChannelDisconnect = () => {
    eventSubscription && CommentableChannel.delete();
  };

  const isOwnMessage = (message) => {
    return message.author.id === currentUser.graph.id;
  };

  const scrollToBottom = (timeout = 0) => {
    setTimeout(() => {
      messagesEndRef.current &&
        messagesEndRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, timeout);
  };

  const scrollToElement = (element, timeout = 0) => {
    setTimeout(() => {
      element &&
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, timeout);
  };

  const unreadMessageCount = () => {
    // Fetch Unread Message count here
    return 0;
  };

  const renderUnreadMessageCounter = () => {
    let count = unreadMessageCount();
    if (count > 0 && props.conversation.can_participate) {
      return (
        <span
          className='tooltip-copy without-arrow '
          onClick={scrollToBottom}>{`${count} new ${pluralize(count, {
          singular: 'message',
          plural: 'messages',
        })}`}</span>
      );
    }
  };

  const isPreviousMessageIsSameAsAuthor = (activityList, activity, index) => {
    const previousComment = activityList[index - 1]?.objects[0];
    if (isGroupActivity(activityList[index - 1])) {
      // If activity ignore the check
      return false;
    }
    let message = activity.objects?.[0];
    return previousComment && previousComment.author.id === message?.author.id;
  };

  const isGroupActivity = (activity) => {
    const { objects } = activity;
    if (objects[0]?.__typename !== 'Comment') return true;
    return false;
  };

  const renderRow = (activity, index) => {
    if (isGroupActivity(activity)) {
      return (
        <div
          key={activity?.id}
          className='activity-text'
          id={'comment-' + activity?.id}>
          {activity.template_text}
          {activity.visible_at && (
            <span className='timestamp'>
              {' '}
              &nbsp;•&nbsp;{timestamp(activity.visible_at)}{' '}
            </span>
          )}
        </div>
      );
    } else {
      return (
        <MessageBlock
          type='comment'
          key={activity?.objects?.[0].id}
          entity={activity?.objects?.[0]}
          actionType={'group'}
          previousMessageIsSameAsAuthor={isPreviousMessageIsSameAsAuthor(
            activityWithSections,
            activity,
            index,
          )}
          space={props.entity}
          parentResults={props.secondary_results}
        />
      );
    }
  };

  const activityWithSections = useMemo(() => {
    const groupActivitySections = [];
    let currentDate = null;
    for (let i = 0; i < props.results.length; i++) {
      const activity = props.results[i];
      if (!isGroupActivity(activity)) {
        const comment = activity.objects?.[0];
        const formattedDate = timeWithFormat(
          comment.created_at,
          'MM/DD/YYYY',
          true,
          {
            todayFormatRequired: true,
            dayNameNotRequired: true,
          },
        );
        if (formattedDate !== currentDate) {
          let sectionData = {
            id: comment.created_at,
            objects: [{ __typename: 'TimeSection' }],
            template_text: formattedDate,
          };
          groupActivitySections.push(sectionData);
          currentDate = formattedDate;
        }
      }

      groupActivitySections.push(activity);
    }
    return groupActivitySections;
  }, [props.results]);

  return (
    <div>
      {activityWithSections.length > 0 &&
        activityWithSections.map((activity, index) =>
          renderRow(activity, index),
        )}

      <div className='message-end' ref={messagesEndRef}></div>
      {renderUnreadMessageCounter()}
    </div>
  );
};

export default SpaceMessageListing;
