import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { getFixedDateFromDateAndMonthAndYear } from 'app/utils/timeHelper';
import { flashSuccess, flashError } from 'app/actions/flashMessage';
import { useDispatch } from 'react-redux';

import MonthDropdown from 'app/components/TreatmentProgress/Dropdowns/MonthDropdown';
import YearDropdown from 'app/components/TreatmentProgress/Dropdowns/YearDropdown';
import StatusDropdown from 'app/components/TreatmentProgress/Dropdowns/StatusDropdown';
import {
  CASE_PROGRESS_STATUS_LIST,
  MONTHS,
  TREATEMENT_PROGRESS_UPDATED,
  TREATEMENT_PROGRESS_FAILURE,
} from 'app/constant/caseProgressBar';
import { getNextFiveYears } from 'app/utils/generalHelper';

const UPDATE_TREATMENT_PROGRESS = queryLoader(
  'app/graphql/mutations/TreatmentProgress/UpdateTreatmentProgress.gql',
);

function TreatmentProgress({ widgetSourceObject }) {
  const dispatch = useDispatch();
  const source = useSelector((state) => state.source);
  const isDisabled = !source.isWorkspace;
  const { status, end_date } = widgetSourceObject.owner.progress_bar || {};

  const [date, setDate] = useState({});
  const [statusValue, setStatusValue] = useState({});
  const [updateProgress] = useMutation(UPDATE_TREATMENT_PROGRESS);

  useEffect(() => {
    setStatusValue(
      CASE_PROGRESS_STATUS_LIST.find((data) => data.value === status),
    );
  }, [status]);

  useEffect(() => {
    if (end_date) {
      const endDate = dayjs.unix(end_date);
      const endDateMonth = dayjs(endDate).month();
      const endDateYear = dayjs(endDate).year();
      setDate({
        month: MONTHS.find((month) => +month.value === endDateMonth),
        year: getNextFiveYears().find((year) => +year.value === endDateYear),
      });
    }
  }, [end_date]);

  const getVariables = useCallback(() => {
    return {
      caseId: +widgetSourceObject.owner?.id,
      status: statusValue.value,
      percentage: 0,
      endDate:
        date?.month?.value && date?.month?.value
          ? getFixedDateFromDateAndMonthAndYear(
              15,
              date?.month?.value,
              date?.year?.value,
            )
          : '',
    };
  }, [widgetSourceObject.owner?.id, statusValue, date]);

  const updateCaseProgress = useCallback(
    (payload = {}) => {
      updateProgress({
        variables: {
          ...getVariables(),
          ...payload,
        },
      }).then(({ data }) => {
        if (data.updateCaseProgressBar.success) {
          flashSuccess(TREATEMENT_PROGRESS_UPDATED, false)(dispatch);
        } else {
          flashError(TREATEMENT_PROGRESS_FAILURE, false)(dispatch);
        }
      });
    },
    [getVariables, updateProgress, dispatch],
  );

  const changeStatus = (data) => {
    setStatusValue(data);
    updateCaseProgress({
      status: data.value,
    });
  };

  const handleMonthChange = (data) => {
    setDate({ ...date, month: data });
    if (data?.value && date.year?.value)
      updateCaseProgress({
        endDate: getFixedDateFromDateAndMonthAndYear(
          15,
          data?.value,
          date.year?.value,
        ),
      });
  };

  const handleYearChange = (data) => {
    setDate({ ...date, year: data });
    if (date.month?.value && data?.value)
      updateCaseProgress({
        endDate: getFixedDateFromDateAndMonthAndYear(
          15,
          date.month?.value,
          data?.value,
        ),
      });
  };

  if (!source.isWorkspace) {
    if (
      status === undefined ||
      status === null ||
      date.month === undefined ||
      date.month === null ||
      date.year === undefined ||
      date.year === null
    ) {
      return null;
    }
  }

  return (
    <div className='treatment-progress'>
      <Accordion className={`right-ac sidebar-container container`}>
        <Accordion.Item>
          <Accordion.Header className='rt-ac-header widget-title header treatment-header'>
            <div className='headerTitle'>Treatment Progress</div>
          </Accordion.Header>
          <Accordion.Body>
            <div className='end-date-and-status'>
              <div className='end-date-container'>
                <span className='end-date-text'>Target end date:</span>
                <div className='end-date-dropdown'>
                  <MonthDropdown
                    isDisabled={isDisabled}
                    date={date}
                    handleDateChange={handleMonthChange}
                  />
                  <YearDropdown
                    isDisabled={isDisabled}
                    date={date}
                    handleDateChange={handleYearChange}
                  />
                </div>
              </div>
            </div>
            <div className='status-dropdown'>
              <span className='status'>Status:</span>
              <div className='status-value'>
                <StatusDropdown
                  isDisabled={isDisabled}
                  statusValue={statusValue}
                  changeStatus={changeStatus}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default TreatmentProgress;
