import { Outlet } from 'react-router-dom';
import ContentWithSidebars from 'app/components/ContentWithSidebars';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { useSelector } from 'react-redux';

const LAYOUR_GRID_MAPPER = {
  exploreCareWorkspace: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  easyRX: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  userDirectory: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  careSpaceDetail: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  teamWindow: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  teamGroupMessenger: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  teamDirectMessenger: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  partners: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  noPartners: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  partnersMobile: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  tasks: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  tasksManager: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  myTasksManager: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  teamTasksManager: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  boardTasksManager: {
    childGridIdentifier: 'ThreeCol:MiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedFolders: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedInbox: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedMyTasksInbox: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedTeamInbox: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedTeamPartnersInbox: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
  unifiedInboxPartner: {
    childGridIdentifier: 'ThreeCol:RightWithOutMiddleBar',
    leftSidebarGridIndentifier: 'ThreeCol:LeftSidebar',
    rightSidebarGridIndentifier: 'ThreeCol:RightSidebar',
  },
};

function getWidgetSourceObject(source, object) {
  const { workspace, activeConversation, activeGroup, activeResource } = object;
  switch (source) {
    case 'exploreCareWorkspace':
      return workspace.data;
    case 'partners':
    case 'careSpaceDetail':
      return activeResource.resource;
    case 'teamGroupMessenger':
      return activeGroup;
    case 'teamDirectMessenger':
      return activeConversation.payload;
    case 'teamWindow':
      return activeConversation.payload || activeGroup;
    case 'myTasksManager':
    case 'teamTasksManager':
    case 'tasksManager':
    case 'boardTasksManager':
      return activeResource.resource;
    default:
      return null;
  }
}

function getSource({ location, match }, device) {
  const path = location.pathname;
  if (path.includes('explore')) {
    return 'exploreCareWorkspace';
  } else if (path.includes('inbox/my/tasks')) {
    return 'unifiedMyTasksInbox';
  } else if (path.includes('inbox/my/partners')) {
    return 'unifiedInboxPartner';
  } else if (path.includes('inbox/my')) {
    return 'unifiedInbox';
  } else if (path.includes('inbox/team/patients')) {
    return 'unifiedTeamInbox';
  } else if (path.includes('inbox/team/partners')) {
    return 'unifiedTeamPartnersInbox';
  } else if (path.includes('folders')) {
    return 'unifiedFolders';
  } else if (path.includes('team/group')) {
    return 'teamGroupMessenger';
  } else if (path.includes('team/dm')) {
    return 'teamDirectMessenger';
  } else if (path.includes('no-partners')) {
    return 'noPartners';
  } else if (path.includes('partners')) {
    return path.endsWith('partners') && device.mobileDevice
      ? 'partnersMobile'
      : 'partners';
  } else if (
    path.includes('carespaces') ||
    path.includes('patient/directory')
  ) {
    return 'userDirectory';
  } else if (match.params.id) {
    return 'careSpaceDetail';
  } else if (path.includes('/tasks/me/activity')) {
    return 'myTasksManager';
  } else if (path.includes('/tasks/team/activity')) {
    return 'teamTasksManager';
  } else if (
    (path.includes('/tasks/team') && path.includes('/board')) ||
    (path.includes('/tasks/me') && path.includes('/board'))
  ) {
    return 'boardTasksManager';
  } else if (path.includes('/tasks/team') || path.includes('/tasks/me')) {
    return 'tasksManager';
  } else if (path.includes('/tasks')) {
    return 'tasks';
  } else if (path.includes('/team')) {
    return 'teamWindow';
  } else if (path.includes('/easy-rx')) {
    return 'easyRX';
  } else {
    return 'exploreCareWorkspace';
  }
}

const ThreeColumnLayout = (props) => {
  const workspace = useSelector((state) => state.workspace);

  const device = useSelector((state) => state.device);
  const activeConversation = useSelector((state) => state.activeConversation);
  const activeGroup = useSelector((state) => state.activeGroup);
  const activeResource = useSelector((state) => state.activeResource);
  const source = getSource(props, device),
    widgetSourceObject = getWidgetSourceObject(source, {
      workspace,
      activeConversation,
      activeGroup,
      activeResource,
    });

  return (
    <ContentWithSidebars
      {...LAYOUR_GRID_MAPPER[source]}
      source={source}
      extraClass={'three-col-wrapper containerClass'}
      options={{ widgetSourceObject }}>
      <Outlet />
    </ContentWithSidebars>
  );
};

const ThreeColumnLayoutContainer = withRouter(ThreeColumnLayout);
export default ThreeColumnLayoutContainer;
