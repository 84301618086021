import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withApollo } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import ConversationMessageOptions from './ConversationMessageOptions';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import EventTracker from 'app/services/EventTracker';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsField from 'app/components/OsField';
import ShareableSpacesSelectBox from 'app/components/Shared/ShareableSpacesSelectBox';
import WriteComment from 'app/components/Comments/WriteComment';

import { translate } from 'app/actions/flashMessage';
import { closeShareModal } from 'app/actions/shareModal';
import { openInfoModal } from 'app/actions/infoModal';
import {
  indexOf,
  isEmpty,
  keys,
  lowerCase,
  map,
  omit,
  upperCase,
  values,
} from 'app/utils/osLodash';
import {
  entitySocialSharingUrl,
  isEntityPublished,
  entityCommentUrl,
} from 'app/utils/entitiesHelper';
import { isInViewport } from 'app/utils/domHelper';
import { humanizeContentWithMentionees } from 'app/utils/textParseHelper';
import { ENTITY_NAME_MAPPER } from 'app/constants';
import { updateCurrentUserGraph } from 'app/actions/authentication';

import { isUrlOfSameDomain, replaceCurrentDomain } from 'app/utils/urlHelper';

const SHARE_ENTITY_IN_CONVERSATION = queryLoader(
  'app/graphql/ShareEntityInConversation.gql',
);
const SHARING_OPTIONS = {
  discussion: { title: 'Group Discussion', icon: 'spaces' },
  message: { title: 'Direct Messages', icon: 'messages' },
};

class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEmpty(this.props.shareModal.options) &&
      !this.props.shareModal.options.show &&
      !isEmpty(nextProps.shareModal.options) &&
      nextProps.shareModal.options.show
    )
      this.setState(this.defaultState(nextProps));
  }

  defaultState(props) {
    return {
      content: '',
      internalShareActive: false,
      requestInProgress: false,
      messageShareSelectedOptions: [],
      userConnectionList: [],
      selectedOption: '',
      showError: false,
      selectedSpace: {},
    };
  }

  getObject() {
    let shareableObj =
      this.props.shareModal.options.object || this.props.data.share_object;
    return omit(shareableObj, 'link');
  }

  handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.toggleRequestInProgress();
    if (this.sharingViaMessage()) {
      this.sendMessage();
    }
  };

  getShareableViaMessageIds(type) {
    return map(
      this.state.messageShareSelectedOptions.filter(
        (option) => option.__typename === type,
      ),
      'id',
    );
  }

  sendMessage = () => {
    let shareObject = this.getObject();
    this.props
      .shareEntityInConversation({
        variables: {
          content: this.state.content,
          shareable_id: shareObject.id,
          shareable_type: this.props.shareModal.options.type,
          user_ids: this.getShareableViaMessageIds('User'),
          conversation_ids: this.getShareableViaMessageIds('Conversation'),
        },
      })
      .then(({ data }) => {
        let successEntity = { ...data.shareEntityInConversation },
          entityType = this.getEntityType(shareObject);

        this.sendShareTrackEvent('Cloudberry');
        this.closeModal();
        if (successEntity.error) {
          this.props.openInfoModal('general', 'entity_not_found', {
            contentInterpolations: { entity_type: entityType },
          });
        } else {
          if (successEntity.current_user)
            this.props.updateCurrentUserGraph(successEntity.current_user);
          this.props.openInfoModal('general', 'message_shared_successfully', {
            contentInterpolations: {
              entity_name: entityType,
              emails: successEntity.user_names.join(', '),
            },
            onSuccess: this.goToPage.bind(this, `/conversations/recent`),
          });
        }
      });
  };

  sendShareTrackEvent = (source) => {
    let object = this.props.object || this.state.shareableObject;
    EventTracker.trackForEntity('share', { ...object, network: source });
  };

  closeModal = () => {
    this.setState(this.defaultState());
    this.props.closeShareModal();
  };

  activateInternalShare = () => {
    if (this.state.selectedOption) {
      if (this.props.device.mobileDevice) {
        window.setTimeout(() => {
          if (!isInViewport(this.refs.modalHeader))
            this.refs.modalHeader.scrollIntoView();
        }, 500);
      }
      this.toggleInternalShare();
    } else {
      this.setState({ showError: true });
    }
  };

  toggleInternalShare = (e) => {
    this.setState({
      internalShareActive: !this.state.internalShareActive,
      selectedSpace: {},
      messageShareSelectedOptions: [],
    });
  };

  toggleRequestInProgress = () => {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  };

  isPulseOrCommentShare(object) {
    return ['Pulse', 'Comment'].includes(object.__typename);
  }

  entityTitle(object) {
    if (this.isPulseOrCommentShare(object)) {
      return translate(`SHARE_${upperCase(object.__typename)}_TITLE`, {
        doctor_name: object.author.full_name,
        entity_name: object.commentable_name,
      });
    } else {
      return this.entityName(object);
    }
  }

  entityDescription(object) {
    if (this.isPulseOrCommentShare(object)) {
      return humanizeContentWithMentionees(object.mentionees, object.content);
    } else {
      return object.description || object.bio || object.content;
    }
  }

  entityName(object) {
    return object.name || object.title || object.product_name;
  }

  entityEmailSubject(object) {
    if (this.isPulseOrCommentShare(object)) {
      return translate(`SHARE_${upperCase(object.__typename)}_EMAIL_SUBJECT`, {
        doctor_name: object.author.full_name,
        entity_name: object.commentable_name,
      });
    } else {
      return this.entityName(object);
    }
  }

  openGraphAttributes() {
    let object = this.getObject();
    return {
      url: entitySocialSharingUrl(object),
      title: this.entityTitle(object),
      description: this.entityDescription(object),
      subject: this.entityEmailSubject(object),
    };
  }

  changeContent = (event) => {
    this.setState({ content: event.target.value });
  };

  isSharingAttachment() {
    return this.getObject() && this.getObject().__typename === 'Attachment';
  }

  onSelectingSpace = (entity) => {
    this.setState({ selectedSpace: entity });
  };

  getObjectTypename() {
    let typename = this.getObject() && this.getObject().__typename;
    return lowerCase(ENTITY_NAME_MAPPER[lowerCase(typename)] || typename);
  }

  goToPage = (path) => {
    if (isUrlOfSameDomain(path)) {
      this.props.navigate(replaceCurrentDomain(path));
    } else {
      window.location.href = path;
    }
  };

  getEntityType(entity) {
    return lowerCase(
      ENTITY_NAME_MAPPER[lowerCase(entity.__typename)] || entity.__typename,
    );
  }

  openSuccessModal = (entity, sharedVia, sharableEntity) => {
    let entityType = this.getEntityType(entity);
    if (sharedVia === 'Comment') {
      this.props.openInfoModal('general', 'shared_in_discussion_successfully', {
        contentInterpolations: { entity_type: entityType },
        onSuccess: this.goToPage.bind(
          this,
          entityCommentUrl(sharableEntity, sharableEntity.id),
        ),
      });
    }
  };

  renderSpaceWriteComment() {
    let links =
        this.getObject() && !this.isSharingAttachment()
          ? [this.getObject()]
          : [],
      files =
        this.getObject() && this.isSharingAttachment()
          ? [this.getObject()]
          : [];

    return (
      <div>
        <ShareableSpacesSelectBox onSelectingSpace={this.onSelectingSpace} />
        <label className='share-label mt-3'>Add a message</label>
        <WriteComment
          entity={this.state.selectedSpace}
          commentableId={
            !isEmpty(this.state.selectedSpace) &&
            this.state.selectedSpace.nice_id
          }
          commentableType='Board'
          commentableEntityOwner={
            !isEmpty(this.state.selectedSpace) && this.state.selectedSpace.user
          }
          author={this.props.currentUser.graph}
          newComment={true}
          sharing={true}
          links={links}
          files={files}
          toggleSharing={this.toggleInternalShare}
          closeShareModal={this.closeModal}
          disabled={isEmpty(this.state.selectedSpace)}
          placeholder={
            !isEmpty(this.state.selectedSpace) &&
            this.state.selectedSpace.nice_id
              ? 'Write your message here'
              : 'Please select a space'
          }
          openSuccessModal={this.openSuccessModal.bind(
            this,
            this.getObject(),
            'Comment',
          )}
          size={this.cardSize()}
          disableCard={true}
          source='share-modal'
        />
      </div>
    );
  }

  onUserOrConversationSelect = (selectedOptions) => {
    let messageShareSelectedOptions = map(selectedOptions, 'entity');
    this.setState({ messageShareSelectedOptions });
  };

  messageShareSelectedValues() {
    return this.state.messageShareSelectedOptions.map((selectedOption) => {
      return {
        value: selectedOption.id,
        label: selectedOption.name,
        entity: selectedOption,
      };
    });
  }

  renderInternalShareFormDirectMessage() {
    return (
      <>
        <div className='select-form-group'>
          <ConversationMessageOptions
            onChange={this.onUserOrConversationSelect}
            selectedValues={this.messageShareSelectedValues()}
          />
        </div>
        <div className='select-form-group'>
          <label className='share-label'>Add a message</label>
          <OsField
            type='textarea'
            className='form-control'
            placeholder='Write your message here'
            value={this.state.content}
            onChange={this.changeContent}
            name='shared_message'
          />
        </div>
      </>
    );
  }

  isSubmitDisabled() {
    if (this.sharingViaMessage()) {
      return (
        this.isShareDisabled() ||
        this.state.messageShareSelectedOptions.length === 0 ||
        this.state.requestInProgress
      );
    }
  }

  sharingViaMessage() {
    return (
      this.state.selectedOption &&
      this.state.selectedOption.title === SHARING_OPTIONS.message.title
    );
  }

  isSharingOnSpaceDiscussion() {
    return (
      this.state.selectedOption &&
      this.state.selectedOption.title === SHARING_OPTIONS.discussion.title
    );
  }

  isShareDisabled() {
    return !isEntityPublished(this.getObject());
  }

  renderInternalShareFormActions() {
    let onCancelClickCallback = this.props.shareModal.options.internalShareOnly
        ? this.closeModal
        : this.toggleInternalShare,
      disabled = this.isSubmitDisabled(),
      loaderRequired = this.state.requestInProgress;
    return (
      <div className='share-modal-footer'>
        <OsBtn
          name='BtnIcon'
          extraClass='px-3'
          onClick={onCancelClickCallback}
          disabled={this.state.requestInProgress}
          text='Back'
          associatedEntity={this.getObject()}
        />
        <OsBtn
          name='BtnPrimary'
          onClick={this.handleOnSubmit}
          disabled={disabled}
          loaderRequired={loaderRequired}
          text='Send'
          associatedEntity={this.getObject()}
        />
      </div>
    );
  }

  cardSize() {
    return 'extra-small';
  }

  onDetailPage() {
    let possibelUrlIds = [this.getObject().nice_id, this.getObject().nice_url];
    return (
      this.props.match.params.id &&
      possibelUrlIds.includes(this.props.match.params.id)
    );
  }

  actionOnCardClick() {
    if (this.onDetailPage()) {
      return { onCardClick: () => {} };
    }
  }

  renderObjectCard() {
    return (
      <OsCards
        size={this.cardSize()}
        disableCard={true}
        obj={this.getObject()}
        avoidPreviewModal={true}
        onReadMoreClick={this.closeModal}
        selectionMode={true}
        {...this.actionOnCardClick()}
      />
    );
  }

  renderCard() {
    return (
      <div className='share-modal-content-row card-row'>
        <div className='share-modal-card-holder'>{this.renderObjectCard()}</div>
      </div>
    );
  }

  renderNextStep() {
    return (
      <div className='share-modal-footer'>
        <div className='w-100 d-flex justify-content-end flex-column'>
          <div className='share-error mt-2'>
            {this.state.showError && this.renderError()}
          </div>
          <OsBtn
            name='BtnPrimary'
            extraClass='ms-auto'
            onClick={this.activateInternalShare}
            text='next step'
            associatedEntity={this.getObject()}
          />
        </div>
      </div>
    );
  }

  getDisplayText() {
    switch (this.props.shareModal.options.type) {
      case 'Pulse':
        return 'post';
      case 'Board':
        return 'space';
      case 'Attachment':
      case 'FileAsset':
        return 'file';
      default:
        return lowerCase(this.props.shareModal.options.type);
    }
  }

  renderError() {
    return (
      <>
        <OrthoIcon
          name='error'
          dataHoverNotRequired={true}
          defaultClass='me-2'
        />
        <span>Please, select one of the sharing options above.</span>
      </>
    );
  }

  selectSharingOption = (selectedOption) => {
    this.setState({ selectedOption, showError: false });
  };

  renderSharingOption = (sharingOption) => {
    let { action, entity, options } = sharingOption.abilityObject || {},
      isUserPermitted =
        !sharingOption.abilityObject ||
        this.props.currentUser.ability.can(action, entity, options);
    return (
      isUserPermitted && (
        <div onClick={this.selectSharingOption.bind(this, sharingOption)}>
          <OrthoIcon
            name={sharingOption.icon}
            defaultClass='me-2'
            dataHoverNotRequired={true}
          />
          <span>{sharingOption.title}</span>
        </div>
      )
    );
  };

  getDropdownInfo() {
    let defaultTitle = 'Select sharing option';
    return this.state.selectedOption
      ? {
          title: this.state.selectedOption.title,
          icon: this.state.selectedOption.icon,
        }
      : { title: defaultTitle };
  }

  shareTitle() {
    if (this.isSharingOnSpaceDiscussion()) {
      return '2. Share in Space (2/2)';
    } else {
      return '2. Share in Messages (2/2)';
    }
  }

  getActiveElementIndex() {
    return indexOf(values(SHARING_OPTIONS), this.state.selectedOption);
  }

  renderContent() {
    return (
      <>
        <div className='order-0 p-0 with-abs-close mb-0'>
          <div className='modal-header position-relative' ref='modalHeader'>
            <h4 className='modal-title pe-2'>
              {this.state.internalShareActive
                ? this.shareTitle()
                : '1. Select sharing option (1/2)'}
            </h4>

            <OsBtn
              name='BtnIcon'
              extraClass='no-text os-header-btn web-view-btn'
              icon='close'
              label='Close share modal'
              onClick={this.closeModal}
            />
          </div>
        </div>

        <div className='modal-body-share'>
          {!this.state.internalShareActive && (
            <CustomDropdown
              className='share-option-dropdown'
              activeElementIndex={this.getActiveElementIndex()}
              dropdownClass='os-dropdown select-box-dropdown'
              dropdownInfo={this.getDropdownInfo()}>
              {values(SHARING_OPTIONS).map(this.renderSharingOption)}
            </CustomDropdown>
          )}
          <div
            className={`${
              this.state.internalShareActive ? 'form-animate' : ''
            } slide-animation`}>
            {this.state.internalShareActive &&
              this.sharingViaMessage() &&
              this.renderInternalShareFormDirectMessage()}
            {this.state.internalShareActive &&
              this.isSharingOnSpaceDiscussion() &&
              this.renderSpaceWriteComment()}
          </div>
          {(this.sharingViaMessage() || !this.state.internalShareActive) &&
            this.renderCard()}
          {this.state.internalShareActive
            ? this.sharingViaMessage() && this.renderInternalShareFormActions()
            : this.renderNextStep()}
        </div>
      </>
    );
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  getSelectedOptionKey() {
    let key = keys(SHARING_OPTIONS).filter(
      (key) => SHARING_OPTIONS[key] === this.state.selectedOption,
    );
    return key.length ? key[0] : '';
  }

  render() {
    let renderModal = this.props.shareModal && this.props.shareModal.open;
    if (renderModal) {
      let errorClass = this.state.showError ? ' error-animation-active ' : '',
        internalShareOnlyClass = this.props.shareModal.options.internalShareOnly
          ? ' internal-share-only '
          : '';
      return (
        <div onClick={this.stopPropagation}>
          <Modal
            show={this.props.shareModal.open}
            onHide={this.closeModal}
            dialogClassName={
              'modal-xl modal-share modal-share-entity ' +
              (this.state.internalShareActive
                ? 'share-animation-active '
                : errorClass) +
              `share-${this.getSelectedOptionKey()}` +
              internalShareOnlyClass
            }
            animation={false}
            backdropClassName='modal-backdrop-custom'>
            {this.renderContent()}
          </Modal>
        </div>
      );
    } else {
      return '';
    }
  }
}

ShareModal = compose(
  graphql(SHARE_ENTITY_IN_CONVERSATION, { name: 'shareEntityInConversation' }),
)(ShareModal);

ShareModal = connect(
  ({ currentUser, device, shareModal }) => ({
    currentUser,
    device,
    shareModal,
  }),
  { closeShareModal, openInfoModal, updateCurrentUserGraph },
)(ShareModal);

ShareModal = withApollo(ShareModal);
ShareModal = withRouter(ShareModal);

export default ShareModal;
