import React, { Component } from 'react';

import NoEntitiesFound from 'app/components/Shared/NoEntitiesFound';
import RenderCards from 'app/components/Cards/RenderCards';

import { findKey } from 'app/utils/osLodash';

const TYPE_MAPPER = {
  board: [
    'UserSpaces',
    'AllBoards',
    'FollowingBoards',
    'MyBoards',
    'SubSpaces',
  ],
  case: ['ClinicCases', 'UserCases'],
  comparison: ['UserComparisons'],
  comment: ['AllDiscussions'],
  contacts: [
    'AllUsersAddedByClinic',
    'AllPatientsOfClinic',
    'OnlyPatientsOfClinic',
    'NoResponseByPatientInCareSpaces',
    'NotAcceptedSpaces',
    'WaitingForResponseInClinic',
  ],
  file: [
    'AllAccessibleFiles',
    'AllShareableFiles',
    'MyAttachments',
    'ClinicAccessibleFiles',
    'FolderLinks',
    'AllFolderLinks',
  ],
  link: ['AllAdditionalLinks'],
  note: ['UserNotes'],
  post: ['Pulses'],
  tool: ['UserTools'],
  user: [
    'AllMembers',
    'AcceptedConnections',
    'AllUsers',
    'FollowedUsers',
    'MyFollowers',
    'CompanyKOL',
  ],
  member: ['CompanyTeam'],
  carespaces: ['AllCareSpaces', 'AllSidebarCareSpaces'],
};

const ACTION_REQUIRED_ON_NO_RESULTS = [
  'UserSpaces',
  'UserCases',
  'UserComparisons',
  'UserCourses',
  'UserTools',
  'Pulses',
  'SubSpaces',
];

export default class RecordsCollectionView extends Component {
  scrollToBottom = true;

  componentDidMount() {
    this.scrollToBottomIfRequired();
  }

  componentWillUpdate(nextProps) {
    if (this.props.results.length + 1 === nextProps.results.length) {
      this.scrollToBottom = true;
    }
  }

  componentDidUpdate() {
    this.scrollToBottomIfRequired();
  }

  getFilter() {
    return this.props.filter;
  }

  getType() {
    return findKey(TYPE_MAPPER, (value) => value.includes(this.props.listType));
  }

  scrollToBottomIfRequired() {
    if (this.scrollToBottom) {
      if (
        this.props.scrollToBottom &&
        this.lastRowElement &&
        this.props.parentContainer
      ) {
        this.props.parentContainer.scrollTop =
          this.props.parentContainer.scrollHeight;
        this.scrollToBottom = false;
      }
    }
  }

  actionRequiredWhenNoResults() {
    return !ACTION_REQUIRED_ON_NO_RESULTS.includes(this.props.listType);
  }

  render() {
    let type = this.getType();
    if (this.props.results.length > 0) {
      return (
        <>
          <RenderCards
            objects={this.props.results}
            selectedRecords={this.props.selectedRecords}
            orderRowWise={true}
            size={this.props.size}
            {...this.props.cardProps}
            sortQuery={this.props.sortQuery}
            listType={this.props.listType}
            cardGridIdentifier={this.props.cardGridIdentifier}
            viewType={this.props.viewType}
            sliderProps={this.props.sliderProps}
          />
          {this.props.scrollToBottom && (
            <div ref={(el) => (this.lastRowElement = el)}></div>
          )}
        </>
      );
    } else if (type) {
      return (
        <NoEntitiesFound
          filter={this.getFilter()}
          type={type}
          noActionRequired={this.actionRequiredWhenNoResults()}
          user={this.props.user}
          searchText={this.props.textQuery || this.props.searchQuery}
          selectionMode={this.props.selectionMode}
        />
      );
    } else {
      return null;
    }
  }
}
