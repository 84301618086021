import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import Loader from 'react-loaders';
import OsBtn from 'app/components/OsBtn';
import UnreadIndicatorIcon from 'app/components/Shared/UnreadIndicatorIcon';
import UserAvatar from 'app/components/Shared/UserAvatar';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import WorkspaceCardLogo from 'app/components/WorkspaceView/CardLogo';
import { isUnifiedEnabled } from 'app/utils/Workspace/generalHelper';

const CURRENT_USER_QUERY = queryLoader(
  'app/graphql/queries/Users/AccessibleWorkspaces.gql',
);

class WorkspaceLaunchScreen extends PureComponent {
  renderUnreadIndicatorIfAnyPresent(workspace) {
    // const count = workspace.total_unread_notifications_count;
    const count =
      workspace.unread_care_notifications_count +
      workspace.unread_conversations_count +
      workspace.unread_team_spaces_notifications_count;

    if (count > 0) return <UnreadIndicatorIcon count={count} />;
  }

  getAccessibleWorkspaces() {
    return this.props.data.user.accessible_workspaces || [];
  }

  getWorkspaceLogo(workspace) {
    return <WorkspaceCardLogo workspace={workspace} />;
  }

  renderAvatar() {
    const user = this.props.currentUser.graph;
    return (
      <UserAvatar
        user={user}
        size='80'
        largeAvatarRequired={true}
        key='currentUser'
        url={'/care'}
      />
    );
  }

  renderCareSpaceProfileIfAny() {
    const user = this.props.currentUser.graph;
    if (user.patient_profile_id)
      return (
        <div
          key='care'
          className='col-12 col-xl-3 col-lg-4 col-md-6 mb-4 ws-hero-box'>
          <div className='ws-enter-box'>
            <aside className='ws_logo_notificaiton'>
              <div className='ws-logo'>{this.renderAvatar()}</div>
              {!this.props.device.mobileDevice &&
                this.renderUnreadIndicatorIfAnyPresent(user)}
            </aside>
            <article className='ws-box-detail'>
              <div className='ws-small-title'>
                Workspace
                {this.props.device.mobileDevice &&
                  this.renderUnreadIndicatorIfAnyPresent(user)}
              </div>
              <div className='ws-title'>Personal Workspace</div>
            </article>
            <OsBtn
              name='BtnSecondary'
              extraClass='with-border web-view-btn ws-enter-link'
              htmlTag='a'
              href={`/care`}
              text='Enter Workspace'
            />
          </div>
        </div>
      );
  }

  renderAcceptedWorkspace = (workspace) => {
    let workspaceLandingPage = isUnifiedEnabled()
      ? `/${workspace.identifier}/inbox/my/tasks`
      : `/${workspace.identifier}/patient/activity`;
    return (
      <div
        key={workspace.id}
        className='col-12 col-xl-3 col-lg-4 col-md-6 mb-4 ws-hero-box'>
        <div className='ws-enter-box'>
          <aside className='ws_logo_notificaiton'>
            <div
              className={`ws-logo ${
                workspace.owner.owner.logo_big ? 'with-img' : 'with-bg'
              }`}>
              {this.getWorkspaceLogo(workspace)}
            </div>
            {!this.props.device.mobileDevice &&
              this.renderUnreadIndicatorIfAnyPresent(workspace)}
          </aside>

          <article className='ws-box-detail'>
            <div className='ws-small-title'>
              Clinic Workspace
              {this.props.device.mobileDevice &&
                this.renderUnreadIndicatorIfAnyPresent(workspace)}
            </div>
            <div className='ws-title'>{workspace.name}</div>
          </article>
          <OsBtn
            name='BtnSecondary'
            extraClass='with-border web-view-btn ws-enter-link'
            htmlTag='a'
            href={workspaceLandingPage}
            text='Enter Workspace'
          />
        </div>
      </div>
    );
  };

  renderPendingWorkspace = (workspace) => {
    const { mobileDevice } = this.props.device,
      className = mobileDevice
        ? 'no-text px-8 web-view-btn with-border'
        : 'no-icon',
      declineText = mobileDevice ? null : 'Decline',
      acceptText = mobileDevice ? null : 'Accept';
    let btnProps = {
      extraClass: `${className} px-16`,
      requestingUser: this.props.currentUser.graph,
      checkAccess: true,
      onAction: this.props.onCardActionClick,
      space: workspace,
    };
    return (
      <div
        key={workspace.id}
        className='col-12 col-xl-3 col-lg-4 col-md-6 mb-4 ws-hero-box'>
        <div className='ws-enter-box'>
          <aside className='ws_logo_notificaiton'>
            <div
              className={`ws-logo ${
                workspace.owner.owner.logo_big ? 'with-img' : 'with-bg'
              }`}>
              {this.getWorkspaceLogo(workspace)}
            </div>
            {!this.props.device.mobileDevice &&
              this.renderUnreadIndicatorIfAnyPresent(workspace)}
          </aside>

          <article className='ws-box-detail'>
            <div className='ws-small-title'>
              Clinic Workspace
              {this.props.device.mobileDevice &&
                this.renderUnreadIndicatorIfAnyPresent(workspace)}
            </div>
            <div className='ws-title'>{workspace.name}</div>
          </article>
          <DeclineRequest {...btnProps} text={declineText} />
          <AcceptRequest {...btnProps} btnType='BtnPrimary' text={acceptText} />
        </div>
      </div>
    );
  };

  renderPendingWorkspaces = (pending) => {
    let className = pending.length > 3 ? '' : 'justify-content-center';

    return (
      <div className={className}>
        <div className='ws-section-heading'>
          Invitation Pending {`(${pending.length})`}
        </div>
        <div className='row'>{pending.map(this.renderPendingWorkspace)}</div>
      </div>
    );
  };

  countOfAcceptedWorkspace = (accepted) => {
    const user = this.props.currentUser.graph;
    return user.patient_profile_id ? accepted.length + 1 : accepted.length;
  };

  renderAcceptedWorkspaces = (accepted, pendingWorkspacesPresent) => {
    let className =
      pendingWorkspacesPresent || this.countOfAcceptedWorkspace(accepted) > 3
        ? ''
        : 'justify-content-center';
    return (
      <>
        {pendingWorkspacesPresent && (
          <div className='ws-section-heading'>
            My Workspaces {`(${this.countOfAcceptedWorkspace(accepted)})`}
          </div>
        )}
        <div className={`row ${className}`}>
          {this.renderCareSpaceProfileIfAny()}
          {accepted.map(this.renderAcceptedWorkspace)}
        </div>
      </>
    );
  };

  renderWorkspaces() {
    let acceptedWorkspaces = [],
      pendingWorkspaces = [],
      className =
        !!pendingWorkspaces.length && !!acceptedWorkspaces.length
          ? 'with-ws-heading'
          : 'without-ws-heading';
    this.getAccessibleWorkspaces().forEach((workspace) => {
      if (workspace.member_request_status === 'pending') {
        pendingWorkspaces.push(workspace);
      } else if (workspace.member_request_status === 'accepted') {
        acceptedWorkspaces.push(workspace);
      }
    });
    return (
      <>
        {!!pendingWorkspaces.length &&
          this.renderPendingWorkspaces(pendingWorkspaces)}
        <div className={className}>
          {this.renderAcceptedWorkspaces(
            acceptedWorkspaces,
            !!pendingWorkspaces.length,
          )}
        </div>
      </>
    );
  }

  isWorkspaceExist() {
    return this.getAccessibleWorkspaces().some((workspace) =>
      ['accepted', 'pending'].includes(workspace.member_request_status),
    );
  }

  render() {
    if (!this.props.currentUser.graph || this.props.data.loading)
      return <Loader type='ball-spin-fade-loader' />;

    if (!this.isWorkspaceExist()) return <Navigate to='/care' />;
    return (
      <section className='container'>
        <SetPageTitle source={'launch'} />
        <div className='ws-info'>
          <h1>
            {this.props.device.mobileDevice
              ? 'Select a workspace'
              : 'Select a workspace to begin'}
          </h1>
          <div className=''>
            Please select a workspace from the list. You can always switch to
            another from the top menu bar.
          </div>
        </div>
        <article>{this.renderWorkspaces()}</article>
      </section>
    );
  }
}

WorkspaceLaunchScreen.defaultProps = {
  navLink: false,
};

WorkspaceLaunchScreen = graphql(CURRENT_USER_QUERY, {
  skip: (props) => !props.currentUser.token,
  options: (props) => {
    const { token } = props.currentUser;
    return {
      fetchPolicy: 'network-only',
      variables: {
        token: token.toString(),
      },
    };
  },
})(WorkspaceLaunchScreen);
WorkspaceLaunchScreen = connect(
  ({ currentUser, device }) => ({
    currentUser,
    device,
    communityUnreadConversationCount:
      currentUser.graph?.unread_conversations_count,
    communityUnreadNotificationCount:
      currentUser.graph?.unread_notifications_count,
  }),
  {},
)(WorkspaceLaunchScreen);

export default WorkspaceLaunchScreen;
