import React from 'react';

import AbstractField from './AbstractField';
import OsField from './';

export default class OsInputField extends AbstractField {
  render() {
    return (
      <>
        <OsField
          {...this.props}
          osType='label'
          labelClass=''
          isWrapperRequired={false}
        />
        <input {...this.getFieldProps()} />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsInputField.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
};
