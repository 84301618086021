import React, { Component } from 'react';
import { connect } from 'react-redux';
import ThreeSixty from 'app/vendors/threesixty';
import { setSpinCurrentIndex } from 'app/actions/spin';

import { intersection, isEqual, isNull, isUndefined } from 'app/utils/osLodash';

const TYPES_FOR_SPIN_INDEX_RETAIN_IS_NOT_REQUIRED = [
  'Section/Axial',
  'Section/Coronal',
  'Section/Sagittal',
];

class SpinModel extends Component {
  componentDidMount() {
    this.initializeThreeSixty();
    this.moveToDefaultPosition();
  }

  componentWillUnmount() {
    if (this.props.resetOnUnmount) this.props.setSpinCurrentIndex(null, '');
  }

  moveToDefaultPosition() {
    let index;
    if (
      isNull(this.props.currentIndex) ||
      !this.shouldRetainSpinIndex({
        currentVideoType: this.props.previousVideoType,
      })
    ) {
      if (this.props.videoData && this.props.videoData.horizontal_axis_info) {
        index = this.props.videoData.horizontal_axis_info.default;
      } else {
        index = 0;
      }
    } else {
      index = this.props.currentIndex;
    }
    this.threesixty.goTo(+index);
  }

  threeSixtyOptions() {
    const { videoData } = this.props;
    const isBothAxisInfoEqual =
      videoData &&
      isEqual(videoData.vertical_axis_info, videoData.horizontal_axis_info);

    return {
      image: this.props.url,
      width: this.props.metaInfo['width'],
      height: this.props.metaInfo['height'],
      count: this.props.metaInfo['total_images'],
      perRow: this.props.metaInfo['per_row'],
      speed: 300,
      inverted: true,
      keys: false,
      onIndexUpdate: this.onIndexUpdate,
      loop: this.props.loop || false,
      prev: this.prevElement,
      next: this.nextElement,
      original: this.props.original,
      dragTolerance: this.props.dragTolerance,
      vertical:
        !isBothAxisInfoEqual &&
        videoData &&
        videoData.vertical_axis_info &&
        videoData.vertical_axis_info.min != null,
      verticalAxisInfo: isBothAxisInfoEqual
        ? null
        : videoData && videoData.vertical_axis_info,
      horizontalAxisInfo:
        this.props.videoData && this.props.videoData.horizontal_axis_info,
    };
  }

  onIndexUpdate = (index) => {
    if (this.props.visible) {
      let videoType = this.props.videoData && this.props.videoData.video_type;
      this.props.setSpinCurrentIndex(index, videoType);
    }
  };

  initializeThreeSixty() {
    this.threesixty = new ThreeSixty(
      this.threesixtyElement,
      this.threeSixtyOptions(),
    );
  }

  setPrevRef = (div) => {
    this.prevElement = div;
  };

  setNextRef = (div) => {
    this.nextElement = div;
  };

  getIntersectedTypes(prevProps) {
    return intersection(
      [this.props.videoType, prevProps.currentVideoType],
      TYPES_FOR_SPIN_INDEX_RETAIN_IS_NOT_REQUIRED,
    );
  }

  shouldRetainSpinIndex(prevProps) {
    const intersectedTypes = this.getIntersectedTypes(prevProps);
    return (
      intersectedTypes.length === 0 ||
      intersectedTypes.length === 2 ||
      (intersectedTypes.length === 1 &&
        this.props.videoType === prevProps.currentVideoType)
    );
  }

  updateSpinIndex(prevProps) {
    if (
      (!isUndefined(prevProps.currentVideoType) &&
        this.shouldRetainSpinIndex(prevProps)) ||
      prevProps.currentVideoType === this.props.videoType
    ) {
      this.threesixty.goTo(+this.props.currentIndex);
    } else {
      this.moveToDefaultPosition();
    }
  }

  sameVideoType() {
    return this.props.videoType === this.props.currentVideoType;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.url !== prevProps.url) {
      this.threesixty.destroy();
      this.initializeThreeSixty();
      this.updateSpinIndex(prevProps);
    }
    if (this.sameVideoType()) this.updateSpinIndex(prevProps);
  }

  render() {
    let className = 'threesixty-spin';
    className += this.props.size === 'small' ? ' comparison' : '';
    // Tocheck this.
    // className += this.props.visible ? ' ' : ' visibility-hidden';

    return (
      <div
        className={className}
        ref={(div) => (this.threesixtyElement = div)}></div>
    );
  }
}

SpinModel = connect(null, { setSpinCurrentIndex })(SpinModel);
SpinModel.defaultProps = {
  resetOnUnmount: true,
};
export default SpinModel;
