import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'
      fill='#FFC933'
    />
    <path
      d='M12.097 2C6.949 2 2 6 2 12h20c0-5.5-4.464-10-9.903-10z'
      fill='#6495F7'
    />
    <path
      d='M15.114 7.483a.5.5 0 0 0-.774.634l.774-.634zm2.499 4.634a.5.5 0 0 0 .774-.634l-.774.634zm.774-4a.5.5 0 0 0-.774-.634l.774.634zm-4.047 3.366a.5.5 0 1 0 .774.634l-.774-.634zm-7.953-4a.5.5 0 0 0-.774.634l.774-.634zm2.499 4.634a.5.5 0 0 0 .774-.634l-.774.634zm.774-4a.5.5 0 0 0-.774-.634l.774.634zm-4.047 3.366a.5.5 0 0 0 .774.634l-.774-.634zm8.727-3.366 1.637 2 .774-.634-1.637-2-.774.634zm1.637 2 1.636 2 .774-.634-1.636-2-.774.634zm1.636-2.634-1.636 2 .774.634 1.636-2-.774-.634zm-1.636 2-1.637 2 .774.634 1.637-2-.774-.634zM5.613 8.117l1.636 2 .774-.634-1.636-2-.774.634zm1.636 2 1.637 2 .774-.634-1.637-2-.774.634zm1.637-2.634-1.637 2 .774.634 1.637-2-.774-.634zm-1.637 2-1.636 2 .774.634 1.636-2-.774-.634zM8.2 2a.2.2 0 1 0-.4 0h.4zm-.4 3.556a.2.2 0 0 0 .4 0h-.4zM9.2 2a.2.2 0 1 0-.4 0h.4zm-.4 3.111a.2.2 0 1 0 .4 0h-.4zM7.2 2.89a.2.2 0 1 0-.4 0h.4zM6.8 6a.2.2 0 1 0 .4 0h-.4zm1-4v3.556h.4V2h-.4zm1 0v3.111h.4V2h-.4zm-2 .889V6h.4V2.889h-.4zM7.524 19.54a2.383 2.383 0 0 1-.336-.229c.108.09.216.17.336.229zM17 18.756c.523-.756 1-1.256 1-1.778C18 15.33 15.312 14 12 14c-4.476 0-6.012 1.519-6 2.978.012.656.624 1.281 1.008 1.777C12 13 16.523 18.5 17 18.756zM15.624 19.986c-.036 0-.06 0-.096.01.024 0 .06 0 .096-.01z'
      fill='#222226'
    />
    <path
      d='M17 18.745H7c.033.05.066.08.1.119.077.109.166.208.265.298l.166.149c.1.079.2.158.31.228.067.04.122.09.189.12.121.059.243.108.376.158.067.02.122.04.188.06.2.05.399.079.61.079.918 0 1.848-.993 2.767-.993.797 0 2.026.794 2.768.993.166.04.321.05.487.04.033 0 .055 0 .089-.01.155-.02.299-.06.443-.12.022-.01.044-.03.077-.04a2.52 2.52 0 0 0 .41-.257l.01-.01c.134-.11.256-.239.366-.368.022-.03.045-.05.067-.079.1-.12.235-.238.312-.367z'
      fill='#ED5A8B'
    />
    <path d='M17 18.745c-.089-.12-5.051-6.116-9.989 0H17z' fill='#ED5A8B' />
  </svg>
);

export default SVG;
