import React, { Component } from 'react';
import { connect } from 'react-redux';

import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { timeWithFormatForSeconds } from 'app/utils/timeHelper';
import { translate } from 'app/actions/flashMessage';

export default class FeedGroupedDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFixed: false,
    };
    this.scrollTop = props.isTimelineView ? 48 : 0;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const mobileTop = this.props.device.mobileDevice ? 64 : 0;
    // console.log(window.$(this.div).offset().top - window.scrollY, window.$(this.div).offset().top, window.scrollY, this.props.date)
    this.setState({
      isFixed:
        window.$(this.div).offset().top - window.scrollY <=
        this.scrollTop + mobileTop,
    });
  };

  renderInfoIconIfRequried() {
    const { index, infoIconText } = this.props;
    if ((this.state.isFixed || index === 0) && infoIconText)
      return (
        <span className='pateint-access-tooltip'>
          <OrthoIcon name='info' defaultClass=' etc-icon' />
          <div className='tab-link-tooltip'>
            <OrthoIcon name='sort-down' defaultClass='tooltip-arrow-top' />
            <div className='tooltip-container'>
              <OrthoIcon name='info' dataHoverNotRequired={true} />
              {translate(infoIconText)}
            </div>
          </div>
        </span>
      );
  }

  render() {
    const { date, suffix, index } = this.props;
    let title = timeWithFormatForSeconds(date, 'll', true, {
      todayFormatRequired: true,
    });
    let className = 'day-dropdown-with-date';
    title = suffix ? `${title} ${suffix}` : title;
    className += this.state.isFixed ? ' sticky' : '';
    return (
      <div className={className} key={date} ref={(div) => (this.div = div)}>
        <div className='day_with_caret'>
          {title}
          {this.renderInfoIconIfRequried()}
          <OrthoIcon
            name='chevron-caret'
            dataHoverNotRequired={true}
            defaultClass='d-none'
          />
        </div>
        <div className='gr_with-date os-caf d-none'>Group by date</div>
      </div>
    );
  }
}

FeedGroupedDate = connect(({ device }) => ({ device }))(FeedGroupedDate);
