import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';

import { translate } from 'app/actions/flashMessage';
import { humanize } from 'app/utils/stringHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { isEmpty, keys, lowerCase, values } from 'app/utils/osLodash';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const NOTIFICATION_TYPES_MAPPER = {
  email: 'Email',
  in_app: 'In-app',
  browser: 'Browser',
};

const CURRENT_USER_NOTIFICATION_PREFERENCES = queryLoader(
  'app/graphql/queries/Users/CurrentUserNotificationPreferences.gql',
);
const UPDATE_NOTIFICATION_CONFIGURATION = queryLoader(
  'app/graphql/UpdateNotificationConfiguration.gql',
);
const IGNORE_PREFERENCES_KEYS = ['description', 'display_name'];

class NotificationPreferences extends Component {
  initializePreferencesForm() {
    let preferences = this.getNotificationPreferences(),
      initData = {};
    if (!isEmpty(preferences)) {
      keys(preferences).forEach((entity) => {
        keys(preferences[entity]).forEach((type) => {
          let fieldName = `${lowerCase(entity)}_${type}`;
          initData[fieldName] = preferences[entity][type];
        });
      });
      return initData;
    }
  }

  getNotificationPreferences() {
    let { user } = this.props.data;
    return user ? JSON.parse(user.notification_configuration.preferences) : {};
  }

  onChange = (e) => {
    this.props.updateNotificationConfiguration({
      variables: {
        type: e.currentTarget.name,
        value: e.target.checked,
      },
    });
  };

  renderNotificationPreference(entity, label, value) {
    let fieldName = `${lowerCase(entity)}_${label}`;

    return (
      <td>
        <li className='notify-prefer-row'>
          <span className='notify-prefer-value'>
            <label className='switch' data-hover={!isTouchSupported()}>
              <Field
                name={fieldName}
                id={fieldName}
                component='input'
                type='checkbox'
                onChange={this.onChange}
              />
              <span className='slider round'></span>
              <span className='cs-slider-after'></span>
            </label>
          </span>
        </li>
      </td>
    );
  }

  renderNotificationPreferenceForEntity(entity, preferences) {
    return (
      <tr>
        <td>
          {preferences['display_name']}
          <span>{preferences['description']}</span>
        </td>
        {keys(preferences)
          .filter((type) => !IGNORE_PREFERENCES_KEYS.includes(type))
          .map((type) =>
            this.renderNotificationPreference(entity, type, preferences[type]),
          )}
      </tr>
    );
  }

  renderPreferences() {
    let preferences = this.getNotificationPreferences();
    return keys(preferences).map((entity) =>
      this.renderNotificationPreferenceForEntity(entity, preferences[entity]),
    );
  }

  renderNotificationType = (type) => {
    return <th>{NOTIFICATION_TYPES_MAPPER[type] || humanize(type)}</th>;
  };

  renderTableHead(preferences) {
    let types = values(preferences)[0] || {};
    return (
      <thead>
        <tr>
          <th>Notifications</th>
          {keys(types)
            .filter((type) => !IGNORE_PREFERENCES_KEYS.includes(type))
            .map(this.renderNotificationType)}
        </tr>
      </thead>
    );
  }

  renderTableBody(preferences) {
    return (
      <tbody>
        {keys(preferences).map((entity) =>
          this.renderNotificationPreferenceForEntity(
            entity,
            preferences[entity],
          ),
        )}
      </tbody>
    );
  }

  render() {
    if (this.props.data.loading)
      return (
        <div className='notifications-preferences'>
          <Loader type='ball-triangle-path' active />
        </div>
      );
    let preferences = this.getNotificationPreferences();
    return (
      <div className='notifications-preferences' key={this.props.location.key}>
        <SetPageTitle
          source='account_setting'
          interpolations={'Notifications Preferences'}
        />
        <p className='notification_subheading mx-0'>
          {translate('NOTIFICATION_PREFERENCE_INFO_MESSAGE')}
        </p>
        <Form
          onSubmit
          initialValues={this.initializePreferencesForm()}
          destroyOnUnmount={true}
          forceUnregisterOnUnmount={true}
          enableReinitialize={true}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className=''>
              <div className='col-12 table-notification'>
                <table className='table table-collapsed  '>
                  {this.renderTableHead(preferences)}
                  {this.renderTableBody(preferences)}
                </table>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

NotificationPreferences = compose(
  graphql(CURRENT_USER_NOTIFICATION_PREFERENCES, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        token: props.currentUser.token.toString(),
      },
    }),
  }),
  graphql(UPDATE_NOTIFICATION_CONFIGURATION, {
    name: 'updateNotificationConfiguration',
  }),
)(NotificationPreferences);
NotificationPreferences = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(NotificationPreferences);

NotificationPreferences = withRouter(NotificationPreferences);
NotificationPreferences = withApollo(NotificationPreferences);
export default NotificationPreferences;
