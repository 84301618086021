import {
  SET_ENTITY_IS_LOADING,
  SET_LECTURE_ENTITY,
  SET_RIGHT_SECTION_VISIBILITY,
  SET_CLV_MOVEMENT_DIRECTION,
} from './types';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import store from 'app/store';

import { viewObject } from 'app/actions/viewObject';

import { entityIsCase } from 'app/utils/entitiesHelper';

const CASE_QUERY = queryLoader('app/graphql/Case.gql');
const VIEW_NOT_REQUIRED_TYPENAMES = ['IntroductoryVideo'];

export function setLectureEntity(spaceLink, metaInfo = {}) {
  let entity = spaceLink && spaceLink.linkable;
  return (dispatch, getState) => {
    spaceLink &&
      !VIEW_NOT_REQUIRED_TYPENAMES.includes(spaceLink.linkable?.__typename) &&
      store.dispatch(viewObject({ obj: spaceLink }));
    let storeLectureValue = getState().lectureView;
    if (entity && entityIsCase(entity)) {
      if (storeLectureValue.entity?.linkable?.nice_id !== entity.nice_id) {
        dispatch({
          type: SET_ENTITY_IS_LOADING,
        });
        getCaseDetailInfo(
          spaceLink,
          !!metaInfo.initialPreview,
          metaInfo.activeContentTabId,
        );
      } else {
        dispatch(setRightSectionVisibility(false, metaInfo));
      }
    } else {
      let metaInfo = storeLectureValue.metaInfo;
      metaInfo.watchQuery && metaInfo.watchQuery.unsubscribe();
      dispatch({
        type: SET_LECTURE_ENTITY,
        entity: spaceLink,
        rightSectionVisible: !(entity && entityIsCase(entity)),
        activeContentTabId: metaInfo.activeContentTabId,
        metaInfo: {},
      });
    }
  };
}

export function setCareCaseAsLecture(kase) {
  let dummySpaceLink = { id: -1, __typename: 'BoardLink', linkable: kase };
  return (dispatch, getState) => {
    dispatch({
      type: SET_LECTURE_ENTITY,
      entity: dummySpaceLink,
      rightSectionVisible: true,
      metaInfo: {},
    });
  };
}

export function getCaseDetailInfo(
  spaceLink,
  rightSectionVisible,
  activeContentTabId,
) {
  const clvCaseQueryWatch = client
    .watchQuery({
      query: CASE_QUERY,
      variables: { id: spaceLink.linkable.nice_id },
      fetchPolicy: 'cache-and-network',
    })
    .subscribe({
      next: ({ data }) => {
        if (data) {
          spaceLink.linkable = data.case;
          store.dispatch({
            type: SET_LECTURE_ENTITY,
            entity: spaceLink,
            rightSectionVisible,
            activeContentTabId,
            metaInfo: { watchQuery: clvCaseQueryWatch },
          });
        }
      },
      error: (e) => console.error(e),
    });
}

export function setRightSectionVisibility(rightSectionVisible, metaInfo = {}) {
  return {
    type: SET_RIGHT_SECTION_VISIBILITY,
    metaInfo,
    rightSectionVisible,
  };
}

export function setCLVMovementDirection(moveDirection) {
  return {
    type: SET_CLV_MOVEMENT_DIRECTION,
    moveDirection,
  };
}
