import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import { viewObject } from 'app/actions/viewObject';

import OsBtn from 'app/components/OsBtn';

const MARK_SPACE_CONTENT_AS_UNVIEWED = queryLoader(
  'app/graphql/mutations/MarkSpaceContentAsUnviewed.gql',
);

class MarkEntityAsViewedOrUnViewed extends Component {
  unviewObject = () => {
    this.props.markSpaceContentAsUnviewed({
      variables: { spaceLinkId: +this.props.obj.id },
      optimisticResponse: () => {
        return {
          markSpaceContentAsUnviewed: {
            __typename: 'Response',
            success: true,
            error: null,
            entity: {
              __typename: 'BoardLink',
              id: this.props.obj.id,
              viewed_space_content: null,
            },
          },
        };
      },
    });
  };

  viewObject = () => {
    this.props.viewObject({ obj: this.props.obj });
  };

  commonProps() {
    return {
      name: 'BtnIcon',
      extraClass: 'ml-0',
      icon: 'doubleTick',
      associatedEntity: this.props.obj,
    };
  }

  getProps() {
    let props = this.commonProps();
    if (this.props.viewed) {
      return {
        ...props,
        text: 'Mark as unfinished',
        onClick: this.unviewObject,
        icon: 'undo',
      };
    } else {
      return {
        ...props,
        text: 'Mark as finished',
        onClick: this.viewObject,
        icon: 'doubleTick',
      };
    }
  }

  render() {
    return <OsBtn {...this.getProps()} />;
  }
}

MarkEntityAsViewedOrUnViewed = graphql(MARK_SPACE_CONTENT_AS_UNVIEWED, {
  name: 'markSpaceContentAsUnviewed',
})(MarkEntityAsViewedOrUnViewed);
MarkEntityAsViewedOrUnViewed = connect(null, { viewObject })(
  MarkEntityAsViewedOrUnViewed,
);

export default MarkEntityAsViewedOrUnViewed;
