import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PopularTopics from 'app/components/Shared/PopularTopics';
import OsBtn from 'app/components/OsBtn';

export default class PopularHashTagsModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        animation={false}
        backdrop='static'
        dialogClassName={'os-grid-modal popular-topic-modal'}
        backdropClassName='modal-backdrop-custom'>
        <Modal.Header className='semibold'>
          <span className='modal-title'>Popular Tags</span>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-general-modal  p-0'
            icon='close'
            label='Close modal'
            onClick={this.props.closeModal}
          />
        </Modal.Header>
        <PopularTopics
          source={'modal'}
          pageSource={this.props.pageSource}
          setCollectionTotal={this.props.setCollectionTotal}
          modalBodyClassName={this.props.modalBodyClassName}
          containerClass={this.props.containerClass}
          entity={this.props.entity}
        />
      </Modal>
    );
  }
}
