import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'app/utils/osLodash';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import RecordsPager from 'app/components/RecordsPager';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import OsBtn from 'app/components/OsBtn';
import WorkspaceInviteModal from 'app/components/WorkspaceInviteModal';

const MembersView = () => {
  const workspace = useSelector((state) => state.workspace);
  const [searchText, setSearchText] = useState('');
  const [inputActive, setInputActive] = useState(false);
  const [remountKey, setRemountKey] = useState(0);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const onSearchChange = debounce((e) => {
    let searchText = e.target.value;
    searchText = (searchText && searchText.trim()) || '';
    setSearchText(searchText);
  }, 200);

  const debouncedChangeHandler = useCallback(
    (e) => onSearchChange(e),
    [onSearchChange],
  );

  const renderSearch = () => {
    return (
      <div className='input-wrapper nav-search-holder input-focus space-search-container'>
        <div
          className={`nav-search-container ${
            inputActive ? 'input-focus-active' : ''
          }`}
          onFocus={() => setInputActive(true)}
          onBlur={() => setInputActive(false)}>
          <label className='position-relative m-0 w-100'>
            <OrthoIcon name='search' />
            <OsField
              osType='input'
              type='text'
              placeholder='Search here'
              className='nav-search-input'
              name='search'
              onChange={debouncedChangeHandler}
            />
          </label>
        </div>
      </div>
    );
  };

  const hideInviteMemberModal = () => {
    setShowInviteModal(false);
  };

  const showInviteMemberModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowInviteModal(true);
  };

  return (
    <div className='container-fluid px-0 workspace-page-head member-head'>
      <SetPageTitle source='worksapce_settings' interpolations={'Members'} />
      <div className='row d-flex justify-content-between'>
        <div className='workspace-space-page-heading col'></div>
        <div className='page-heading-right col'>
          <div className='container-fluid px-0'>{renderSearch()}</div>
          <OsBtn
            name='BtnPrimary'
            extraClass='me-3'
            onClick={showInviteMemberModal}
            text='Invite New Members'
          />
          <WorkspaceInviteModal
            show={showInviteModal}
            hideModal={hideInviteMemberModal}
          />
        </div>
      </div>
      <React.Fragment key={remountKey}>
        <RecordsPager
          queryType='SPACE_DETAILS'
          recordId={workspace.data.id}
          recordType='Board'
          space={workspace.data}
          type='SpaceAcceptedOrPendingCollaborators'
          textQuery={searchText}
        />
      </React.Fragment>
    </div>
  );
};
export default MembersView;
