import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { openAppointmentInfoModal } from 'app/actions/appointmentInfoModal';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

class NoAppointmentDataView extends PureComponent {
  render() {
    return (
      <div className={`no-data-appt-info ${this.props.extraClass}`}>
        <OrthoIcon name='unavailable' dataHoverNotRequired={true} />
        <p>
          There are no patient records available for{' '}
          {this.props.bothAppointmentsEmpty ? 'these' : 'this'}{' '}
          {this.props.bothAppointmentsEmpty ? 'appointments' : 'appointment'}
        </p>
        <OsLink
          className='appt-type-btn'
          text='Learn more about qlark case appointments'
          onClick={this.props.openAppointmentInfoModal}
        />
      </div>
    );
  }
}

NoAppointmentDataView.defaultProps = {
  bothAppointmentsEmpty: false,
  extraClass: '',
};

NoAppointmentDataView = connect(null, { openAppointmentInfoModal })(
  NoAppointmentDataView,
);

export default NoAppointmentDataView;
