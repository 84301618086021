import React, { Component } from 'react';
import { connect } from 'react-redux';

import GuardianForm from 'app/components/PatientForm/GuardianForm';
import LegendModal from 'app/components/Shared/LegendModal';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import RecordsView from 'app/components/RecordsView';
import { openPatientModal } from 'app/actions/patientModal';
import withSubscribedAccess from 'app/components/HOC/withSubscribedAccess';

import { debounce, difference, isEmpty } from 'app/utils/osLodash';
import { numberSeparator } from 'app/utils/textFormatting';
import {
  getWorkspaceData,
  isWorkspaceAuthorOrEditor,
} from 'app/utils/Workspace/generalHelper';

import NonSubscribedView from 'app/components/NonSubscribedView';
import SmallScreenWidget from 'app/components/Task/SmallScreenWidget';
import SmallLabel from 'app/components/LabelsModal/smallLabel';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { PATIENT_OPTIONS_MAPPING } from 'app/components/ContentWithSidebars/PatientDirectoryList';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';

// const SORT_HEADERS = ['name'];
const SORT_HEADERS = ['latest_activity'];
const MIN_CHARACTERS_REQUIRED_FOR_SEARCH = 3;

const headersMapping = {
  name: 'NAME',
  label: 'LABEL',
  latest_activity: 'LATEST ACTIVITY',
};

class VisitorsListing extends Component {
  state = {
    searchText: '',
    labelModalOpen: false,
    // nameSorting: 'name_asc',
    latest_activitySorting: 'latest_activity_desc',
    // statusSorting: 'status_asc',
    primarySort: 'latest_activity',
    // primarySort: 'name',
    showWidget: false,
    label: '',
    parentLabel: null,
  };
  static contextType = WorkspaceContext;

  openPatientModal = () => {
    let sortQuery = this.getSortValue();
    let variables = this.getVariables();

    if (sortQuery === 'latest_activity_desc') {
      this.props.openPatientModal(null, {
        source: 'visitors-listing',
        variables,
      });
    } else {
      this.props.openPatientModal(null, { source: 'visitors-listing' });
    }
  };

  getVariables = () => {
    let variables = {
      page: 0,
      perPage: 25,
      searchQuery: this.state.label?.name || '',
      text_query: this.getTextQueryValue(),
      type: 'AllPatientsOfClinic',
      sortQuery: this.getSortValue(),
      additional_filters: JSON.stringify({ label_ids: [] }),
    };

    return variables;
  };

  setSearchText = debounce((searchText) => {
    this.setState({ searchText });
  }, 500);

  onChange = ({ target }) => {
    let searchText = target.value;
    searchText = (searchText && searchText.trim()) || '';
    this.setSearchText(searchText);
  };

  addNewPatientHandler = () => {
    this.props.openSubscriptionModalIfNotSubscribed(this.openPatientModal);
  };

  renderNewPatientButton() {
    return (
      <>
        <OsBtn
          text='Add New Patient'
          name='BtnPrimary'
          extraClass='web-view-btn add-new-btn'
          associatedEntity={getWorkspaceData()}
          onClick={this.addNewPatientHandler}
        />
      </>
    );
  }

  hideWidget = (isVisible) => {
    this.setState({ showWidget: isVisible });
  };

  handleLabelClick = (label) => {
    this.setState({ label: label });
  };

  removeLabelFilter = () => {
    this.setState({ label: '' });
  };

  onSearchChangeHandler = (e) => {
    this.props.openSubscriptionModalIfNotSubscribed(
      this.onChange.bind(this, e),
    );
  };

  renderSearchBarWrapper() {
    return (
      <div
        className='input-wrapper nav-search-holder input-focus space-search-container '
        onMouseEnter={() => this.hideWidget(true)}
        onMouseLeave={() => this.hideWidget(false)}>
        <div
          className={`nav-search-container ${
            this.state.inputActive ? 'input-focus-active' : ''
          }`}
          onFocus={this.setInputActive}
          onBlur={this.unsetInputActive}>
          <label className='position-relative m-0 w-100'>
            <OrthoIcon name='search' />
            <OsField
              osType='input'
              type='text'
              placeholder='Search by typing a name, email, labels, phone or identification number to filter'
              className='nav-search-input'
              name='search'
              autoComplete='off'
              onChange={this.onSearchChangeHandler}
            />
            <OrthoIcon
              name='clear'
              defaultClass='visible-mobile clear-btn'
              onClick={this.toggleMobileSearchBar}
            />
          </label>
        </div>
        <SmallScreenWidget
          showUncategorizedLabel
          show={this.state.showWidget}
          setShow={this.hideWidget}
          showMembers={false}
          labelsData={this.context.person_labels}
          handleLabelClick={this.handleLabelClick}
        />
      </div>
    );
  }

  toggleMobileSearchBar = () => {
    this.setState({ mobileSearchOpen: !this.state.mobileSearchOpen });
  };

  renderSearchBar() {
    if (!this.props.device.mobileDevice || this.state.mobileSearchOpen) {
      return this.renderSearchBarWrapper();
    } else {
      return (
        <OsBtn
          text=''
          name='BtnIcon'
          icon='search'
          extraClass='px-8 web-view-btn'
          onClick={this.toggleMobileSearchBar}
        />
      );
    }
  }

  renderSearch() {
    let writeAccess = isWorkspaceAuthorOrEditor();
    return (
      <div className='page-heading-right col'>
        {this.renderSearchBar()}
        {writeAccess && this.renderNewPatientButton()}
        {writeAccess && this.renderLabelsSettingsIcon()}
      </div>
    );
  }

  isSearchEnabled() {
    return this.state.searchText.length >= MIN_CHARACTERS_REQUIRED_FOR_SEARCH;
  }

  onHeaderClick = (header) => {
    let stateName = `${header}Sorting`,
      currentSortAsc = this.isAscSorting(header),
      currentState = this.state,
      newSort = `${header}_${currentSortAsc ? 'desc' : 'asc'}`;

    currentState[stateName] = newSort;
    currentState['primarySort'] = header;
    this.setState(currentState);
  };

  isAscSorting(header) {
    return this.state[`${header}Sorting`] === `${header}_asc`;
  }

  renderSortableHeader(attr) {
    let isAsc = this.isAscSorting(attr);
    return (
      <div
        className={`v-tb-block ${attr}-block ${
          isAsc ? 'down-sorting' : 'up-sorting'
        }`}
        style={!this.props.device.mobileDevice ? { position: 'sticky' } : {}}>
        <span
          className='cursor-pointer'
          onClick={this.onHeaderClick.bind(this, attr)}
          style={
            attr !== this.state.primarySort
              ? { position: 'relative', color: '#5d7893' }
              : { position: 'relative', color: '#000' }
          }>
          {headersMapping[attr]}
          {this.state.primary}
          {attr === this.state.primarySort ? (
            <OrthoIcon
              name={`chevron-caret-${isAsc ? 'up' : 'down'}`}
              defaultClass='ms-1'
            />
          ) : (
            <div className='noSorting'>
              <OrthoIcon name={`chevron-caret-up`} defaultClass='ms-1' />
              <OrthoIcon name={`chevron-caret-down`} defaultClass='ms-1' />
            </div>
          )}
        </span>
      </div>
    );
  }

  renderHeaderRow() {
    return (
      <div className='visitors-table-row header-row'>
        {this.renderSortableHeader('name')}
        <div className='v-tb-block cd-pid'>PID</div>
        <div className='v-tb-block cd-information'>Information</div>
        <div className='v-tb-block cd-status'>Status</div>
        <div className='v-tb-block cd-contacts'>Contacts</div>
        {this.renderSortableHeader('label')}
        {this.renderSortableHeader('latest_activity')}
      </div>
    );
  }

  getSortValue() {
    // sortQuery in form `name_asc:status_asc`
    let state = this.state,
      sortValue = state[`${this.state.primarySort}Sorting`];

    difference(SORT_HEADERS, [state.primarySort]).forEach((header) => {
      sortValue += `:${state[`${header}Sorting`]}`;
    });

    return sortValue;
  }

  setTotalCareSpaces = (careSpacesCount) => {
    this.setState({ careSpacesCount });
  };

  getTextQueryValue = () => {
    return this.isSearchEnabled() ? this.state.searchText.trim() : '';
  };

  getFilterValues = () => {
    return (
      PATIENT_OPTIONS_MAPPING[this.props.filter] || PATIENT_OPTIONS_MAPPING.all
    ).queryType;
  };

  getLabelValue = () => {
    if (this.state.label?.id) {
      return [parseInt(this.state.label.id)];
    } else {
      return [];
    }
  };

  renderList() {
    return (
      <RecordsView
        type={this.getFilterValues()}
        textQuery={this.getTextQueryValue()}
        searchQuery={
          this.state.label?.name === 'uncategorized' ? 'uncategorized' : ''
        }
        queryType='VISTIOR_DIRECTORY'
        cardProps={{ size: 'list', contactsOpen: false, isListItem: true }}
        sortQuery={this.getSortValue()}
        setCollectionTotal={this.setTotalCareSpaces}
        collectionTotal={this.state.careSpacesCount}
        perPage={25}
        additional_filters={{
          label_ids: this.getLabelValue(),
        }}
      />
    );
  }

  renderAppliedLabel() {
    if (this.state.label) {
      return (
        <SmallLabel
          name={this.state.label.displayName}
          color={this.state.label.color}
          handleClick={this.removeLabelFilter}
        />
      );
    }
  }

  openLabelsModal = () => {
    this.props.navigate('/settings/labels');
  };

  getFilterLabel = () => {
    return `Patient Directory • ${
      PATIENT_OPTIONS_MAPPING[this.props.filter].label
    }`;
  };

  getLegendClassName = () => {
    return `${this.props.filter}-legend`;
  };

  renderHeading() {
    let { careSpacesCount } = this.state;
    return (
      <div className='workspace-space-page-heading col legend-access-tooltip'>
        <div
          className={`d-inline-flex align-items-center ${this.getLegendClassName()}`}>
          <span className='mr-1'>{this.getFilterLabel()}</span>
          <OrthoIcon
            defaultClass='ms-2 info-hover with-arrow-icon'
            name='info'
            onClick={this.onInfoClick}>
            <span></span>
          </OrthoIcon>

          <div className='tab-link-tooltip'>
            <OrthoIcon name='sort-down' defaultClass='tooltip-arrow-top' />
            <div className='tooltip-container'>
              <LegendModal />
            </div>
          </div>
        </div>

        <div className='space-small-total-pateint'>
          {!!careSpacesCount
            ? `${numberSeparator(careSpacesCount)} added patients`
            : 'No Patients Found'}
        </div>
      </div>
    );
  }

  renderLabelsSettingsIcon() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='label'
        onClick={this.openLabelsModal}
        extraClass='px-8 web-view-btn'
      />
    );
  }

  renderHeader = ({ disabled = true }) => {
    return (
      <div
        className={
          'container-fluid px-0 workspace-page-head vs-heading pt-3' +
          (disabled ? ' pointer-not-allowed' : '')
        }>
        <div className='row d-flex justify-content-between'>
          {this.renderHeading()}
          {this.renderSearch()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className='main-container'>
        <NonSubscribedView header={this.renderHeader}>
          {this.renderHeader({ disabled: false })}
          {this.renderAppliedLabel()}
          <div className='mt-4 vs-list'>
            <div className='table-wrap'>
              {this.renderHeaderRow()}
              {this.renderList()}
            </div>
          </div>
          <GuardianForm />
        </NonSubscribedView>
      </div>
    );
  }
}

VisitorsListing = connect(
  ({ currentUser, device, workspace }) => ({
    currentUser,
    device,
    isFeatured: workspace.featured,
  }),
  { openPatientModal },
)(VisitorsListing);
VisitorsListing = withRouter(VisitorsListing);
VisitorsListing = withSubscribedAccess(VisitorsListing);
export default VisitorsListing;
