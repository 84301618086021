import OsBtn from 'app/components/OsBtn';
import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import React from 'react';

function ReassigneTo({ assigneeList, changeAssignee, isSliceRequired }) {
  return (
    <div className='assigneeContainer'>
      <OsBtn
        key='reassignto'
        name='BtnIcon'
        text='Reassign to'
        extraClass='list-button'
        icon='user-referral'
        isRightIcon={true}
        rightIcon='chevron-right'
      />
      <div className='assigneeList'>
        {assigneeList
          ?.filter((assigne) => assigne.full_name)
          .map((assignee, index) => (
            <Assignee
              key={index}
              assignee={assignee}
              changeAssignee={changeAssignee}
            />
          ))}
      </div>
    </div>
  );
}

const Assignee = ({ assignee, changeAssignee }) => {
  return (
    <div className='assigneeWrapper' onClick={() => changeAssignee(assignee)}>
      <div className='assigneeAvatar'>
        <Avatar
          className='avatar avatar-24 comments-secondary-avatar'
          {...avatarAttributes(assignee)}
        />
      </div>
      <div className='assigneeName'>{assignee.full_name}</div>
    </div>
  );
};

export default ReassigneTo;
