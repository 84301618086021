import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import CareNavLinksList from 'app/components/CareSpace/NavLinks/List';
import MobileNavItem from 'app/components/CareSpace/NavLinks/MobileNavItem';
import MobileNavSearch from 'app/components/NavSearch/MobileNavSearch';
import SiteLogo from 'app/components/Shared/SiteLogo';
import EventTracker from 'app/services/EventTracker';
import { getNotificationUrl } from 'app/utils/userHelper';
import OsLink from 'app/components/OsLink';
import { setNavSearchClose } from 'app/actions/navSearch';
import { getNavSearchProps } from 'app/utils/Workspace/generalHelper';

import { CARE_NAV_LINKS_MAPPER } from './constants';

const MOBILE_MORE_EXPANDED_PROFILE_LINKS = [
  'twoFactorAuthentication',
  'updatePassword',
  'support',
  'logout',
];
const MOBILE_CARE_TOP_HEADER_NAV_ITEMS = ['home', 'notifications', 'more'];
const CARE_TOP_HEADER_NAV_ITEMS = ['home'];

class CareSpaceNavLinks extends PureComponent {
  state = {
    navDropdownActive: false,
  };

  toggleNavDropdown = () => {
    !this.state.navDropdownActive &&
      EventTracker.trackDropdownOpen('mobile_nav_more');
    this.setState({ navDropdownActive: !this.state.navDropdownActive }, () => {
      if (this.state.navDropdownActive) {
        window.$('html')[0].classList.add('hidden-more');
      } else {
        window.$('html')[0].classList.remove('hidden-more');
      }
    });
  };

  toggleActiveDropdown = () => {
    this.state.navDropdownActive && this.toggleNavDropdown();
  };

  renderLinks() {
    let patientCount = this.props.currentUser.graph?.care_spaces_count,
      nav_list = [];
    if (patientCount > 1) {
      nav_list = CARE_TOP_HEADER_NAV_ITEMS;
    }
    return <CareNavLinksList list={nav_list} />;
  }

  renderProfileActions() {
    return MOBILE_MORE_EXPANDED_PROFILE_LINKS.map(this.renderMobileNavLink);
  }

  renderMobileNavLink = (link) => {
    return (
      <MobileNavItem
        {...CARE_NAV_LINKS_MAPPER[link]}
        onClick={this.toggleActiveDropdown}
        toggleNavDropdown={this.toggleNavDropdown}
        navDropdownActive={this.state.navDropdownActive}
      />
    );
  };

  setSearchBoxState = () => {
    this.props.setNavSearchClose();
  };

  renderMobileLinks() {
    return MOBILE_CARE_TOP_HEADER_NAV_ITEMS.map(this.renderMobileNavLink);
  }

  renderMobileNavSearchField() {
    return (
      <div className='mobile-header-search'>
        <MobileNavSearch
          {...getNavSearchProps(this.props.location.pathname)}
          notifyParentComponent={this.setSearchBoxState}
        />
      </div>
    );
  }

  renderMobileNavLinks() {
    return (
      <div className='mobile-nav-bar os-main-nav'>
        <ul className='navbar-nav site-nav'>{this.renderMobileLinks()}</ul>
        {this.props.navSearch.showSearchContainer &&
          this.renderMobileNavSearchField()}
        {this.state.navDropdownActive && this.renderMobileNavDropdownMenu()}
      </div>
    );
  }

  renderDropdownHeader(user) {
    if (user)
      return (
        <OsLink
          to={getNotificationUrl()}
          className='nav-profile__user'
          onParentClick={this.toggleActiveDropdown}>
          <div className='nav-profile__user-info' key='user_name'>
            <div className='user-name'>{user.name}</div>
          </div>
        </OsLink>
      );
  }

  renderMobileNavDropdownMenu() {
    const user = this.props.currentUser.graph;
    return (
      <div className='nav-more-dropdown'>
        <ul className='more-dropdown-list mt-72'>
          {this.renderDropdownHeader(user)}
          {this.renderProfileActions()}
        </ul>
      </div>
    );
  }

  renderWebNavLink() {
    return (
      <>
        <SiteLogo />
        {this.renderLinks()}
      </>
    );
  }

  render() {
    return this.props.device.mobileDevice
      ? this.renderMobileNavLinks()
      : this.renderWebNavLink();
  }
}
CareSpaceNavLinks = withRouter(CareSpaceNavLinks);
CareSpaceNavLinks = connect(
  ({ currentUser, device, navSearch }) => ({ currentUser, device, navSearch }),
  { setNavSearchClose },
)(CareSpaceNavLinks);
export default CareSpaceNavLinks;
