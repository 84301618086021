import { CASE_PROGRESS_STATUS_LIST } from 'app/constant/caseProgressBar';
import React from 'react';
import Select from 'react-select';

function StatusDropdown({ isDisabled, statusValue, changeStatus }) {
  return (
    <Select
      placeholder='Select status'
      value={statusValue}
      onChange={changeStatus}
      options={CASE_PROGRESS_STATUS_LIST}
      hideSelectedOptions={true}
      isSearchable={false}
      isDisabled={isDisabled}
      styles={{
        container: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: '0px',
          width: '150px',
          border: 'none',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: 'none',
          borderRadius: '0px',
          minHeight: '12px',
          minWidth: '150px',
          width: '150px',
          padding: '0px',
          margin: '0px',
          background: state.isDisabled ? 'none' : 'none',
          boxShadow: 'none',
          border: state.menuIsOpen ? '1px solid #5CA4F6' : 'none',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          padding: '0px',
          paddingLeft: '4px',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          padding: '0px',
          margin: '0px',
          flexGrow: '0px',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.5',
          letterSpacing: '0.2px',
          color: 'rgb(129,141,153)',
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          padding: '0px',
          margin: '0px',
          flexGrow: '0px',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.5',
          letterSpacing: '0.2px',
          color: 'rgb(129,141,153)',
        }),
        indicatorsContainer: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          width: '120px',
          zIndex: '9',
          position: 'absolute',
          left: '-40px',
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          background: state.isSelected ? '#eff1f4' : '',
          backgroundColor: state.isFocused ? '#f8f8f8' : '',
          height: '40px',
          maxHeight: '40px',
          padding: '0px 12px',
          flexGrow: '0px',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: '400',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: '1.5',
          letterSpacing: '0.2px',
          marginBottom: '0px',
          color: '#5d7893',
          verticalAlign: 'middle',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }),
      }}
    />
  );
}

export default StatusDropdown;
