export const CASE_PROGRESS_STATUS_LIST = [
  { value: 'early_stage', label: 'Early Stage', color: '' },
  { value: 'on_schedule', label: 'On schedule', color: '' },
  { value: 'delayed', label: 'Delayed', color: '' },
  { value: 'complete', label: 'Completed', color: '' },
];

export const MONTHS = [
  { value: '0', label: 'January' },
  { value: '1', label: 'February' },
  { value: '2', label: 'March' },
  { value: '3', label: 'April' },
  { value: '4', label: 'May' },
  { value: '5', label: 'June' },
  { value: '6', label: 'July' },
  { value: '7', label: 'August' },
  { value: '8', label: 'September' },
  { value: '9', label: 'October' },
  { value: '10', label: 'November' },
  { value: '11', label: 'December' },
];

export const TREATEMENT_PROGRESS_FAILURE = 'Something went wrong';
export const TREATEMENT_PROGRESS_UPDATED = 'Treatment Progress updated';
