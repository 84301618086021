import {
  isPresent,
  toSentence,
  isHTMLContentPresent,
} from 'app/utils/stringHelper';
import { currentYear } from 'app/utils/timeHelper';

import {
  PASSWORD_VALIDITY_REGEX,
  URL_REGEX,
  EMAIL_REGEX,
  PHONE_NUMBER_VALIDITY_REGEX,
} from 'app/constants';
const STRING_CONTAINS_BRACKETS_REGEX = /[{[^}\]]/;
const PHONE_NUMBER_SPECIAL_CHARACTERS_REGEX = /[\)\(-\s]/g;
const GRADUATION_YEAR_MIN_VALUE = 1950;

const validate = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (!isPresent(values[key])) {
      errors[key] = 'Required';
    }
  });
  return errors;
};

export const htmlFieldRequiredForNonCollectionSpace = (
  information,
  formValues,
) => {
  return formValues.category !== 'collection' && htmlFieldRequired(information);
};

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined,
    );

export const htmlFieldRequired = (string) => {
  if (!isHTMLContentPresent(string)) return 'Required';
};

export const fieldRequired = (string) => {
  if (!isPresent(string)) return 'Required';
};

export const passwordMustMatch = (value, allValues) => {
  if (value !== allValues.password) return "Passwords don't match";
};

export const passwordMustMatchValidityRules = (value) => {
  if (value && !PASSWORD_VALIDITY_REGEX.test(value)) {
    let errors = [],
      error = 'At least ';

    if (value.length < 8) errors.push('8 characters');

    if (!/[a-z]/.test(value)) errors.push('1 lowercase character');

    if (!/[A-Z]/.test(value)) errors.push('1 uppercase character');

    if (!/(?=.*[^a-zA-Z0-9 ])/.test(value)) errors.push('1 special character');

    return error + toSentence(errors);
  }
};

export const urlMustBeValid = (value) => {
  if (!value.match(URL_REGEX)) return 'Invalid Url';
};

export const checkboxCheckRequired = (value) => {
  if (!value) {
    return 'Required';
  }
};

export const emailMustBeValid = (value) => {
  if (value && !isEmailValid(value)) return 'Invalid email address';
};

export const emailMustNotEqualToUserEmail = (value, user) => {
  if(value === user?.email) return "Can't enter your own email"
}

export const validPhoneNumberInput = (value) => {
  if (!isPhoneNumberValid(value)) {
    return 'Phone number is invalid';
  }
};

const removeFormattedCharacters = (string) => {
  return string && string.replace(PHONE_NUMBER_SPECIAL_CHARACTERS_REGEX, '');
};

export const validPhoneNumber = (value) => {
  if (
    !PHONE_NUMBER_VALIDITY_REGEX.test(
      removeSpecialCharactersFromPhoneNumber(value),
    )
  )
    return 'Phone number is invalid';
};

export const removeSpecialCharactersFromPhoneNumber = (phoneNumber) => {
  return (phoneNumber && phoneNumber.replace(/[^0-9]/g, '')) || '';
};

export const graduationYearValidity = (value) => {
  if (!value) return 'Required';

  if (value < currentYear()) {
    return `Program Year should be greater than ${currentYear()}`;
  }

  if (value < GRADUATION_YEAR_MIN_VALUE)
    return `Program Year should be greater than ${GRADUATION_YEAR_MIN_VALUE}`;

  if (value > 9999) return 'Invalid Year';
};

export const nameFieldValidation = (value) => {
  if (STRING_CONTAINS_BRACKETS_REGEX.test(value))
    return 'Should not contain curly or square brackets';
};

export const isEmailValid = (email) => {
  return EMAIL_REGEX.test(email)
};

export function isPhoneNumberValid(number) {
  return PHONE_NUMBER_VALIDITY_REGEX.test(removeFormattedCharacters(number))
};

export const validEmailOrPhoneNumberInput = (value) => {
  if (!(isEmailValid(value) || isPhoneNumberValid(value))) {
    return 'Invalid email address or phone number';
  }
};

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Must be at least ${min} characters long.`
    : undefined;
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Must not be ${max} characters long.`
    : undefined;

export default validate;
