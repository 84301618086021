import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AddTo from 'app/components/Shared/AddTo';
import ActivityBar from 'app/components/FeedView/ActivityBar';
import AuthorDetail from 'app/components/Shared/AuthorDetail';
import Avatar from 'app/components/Shared/Avatar';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeleteObject from 'app/components/Shared/DeleteObject';
import Follow from 'app/components/Shared/Follow';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import Pin from 'app/components/Shared/Pin';
import Share from 'app/components/Shared/Share';

import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { getExplorePageHeaderOffset } from 'app/utils/domHelper';
import {
  avatarAttributes,
  entityUrl,
  isTimelineView,
} from 'app/utils/entitiesHelper';

import { translate } from 'app/actions/flashMessage';
import { timeWithFormat, timestampOnHover } from 'app/utils/timeHelper';

import EventTracker from 'app/services/EventTracker';

const SPACE_IN_ENTITY_ACTIVITIES = ['SpaceCommentActivity'];
const SPACE_IN_OBJECT_ACTIVITIES = [
  'EntityFollowActivity',
  'SpaceContentAddedActivity',
  'SpaceContentRemovedActivity',
  'SpaceMemberAddedActivity',
  'SpaceAsMemberAddedActivity',
];

class FeedDetails extends Component {
  getObject() {
    return this.props.feed.objects[0];
  }

  getEntity() {
    return this.props.feed.entities[0];
  }

  getSourceType() {
    return this.props.feed.source_type;
  }

  isCommentObject() {
    return this.getObject().__typename === 'Comment';
  }

  isPinnable() {
    let { listType, feed } = this.props;
    return !isTimelineView(listType) && feed.pinnable;
  }

  isPinned() {
    let { listType, feed } = this.props;
    return !isTimelineView(listType) && feed.pinned;
  }

  getTargetObjectForMute() {
    let sourceType = this.getSourceType();
    if (SPACE_IN_ENTITY_ACTIVITIES.includes(sourceType)) {
      return this.getEntity();
    } else if (SPACE_IN_OBJECT_ACTIVITIES.includes(sourceType)) {
      return this.getObject();
    }
  }

  actorsModalHeading() {
    switch (this.props.feed.source_type) {
      case 'EntityFollowActivity':
        return 'New space followers';
      case 'SpaceMemberAddedActivity':
        return 'New space members';
      default:
        return 'Participants';
    }
  }

  effectActivity() {
    return this.props.feed.effect_activity;
  }

  renderAddTo() {
    let extraProps = { iconName: 'boards' };
    return (
      <AddTo obj={this.getObject()} extraClass='list-button' {...extraProps} />
    );
  }

  renderShareButton() {
    return (
      <Share
        key='share'
        iconName='share-fill'
        object={this.getObject()}
        label='Share Post'
        extraClass='list-button'
      />
    );
  }

  renderPin() {
    if (this.isPinnable())
      return (
        <Pin
          obj={this.props.feed}
          extraClass='list-button'
          containerRef={this.refs.containerRef}
          offset={getExplorePageHeaderOffset(this.props.device.mobileDevice)}
        />
      );
  }

  renderMuteButton() {
    let object = this.getTargetObjectForMute();
    if (object && object.user) {
      let muteAllowed =
        object.__typename === 'Board' && !isTimelineView(this.props.listType);
      if (muteAllowed)
        return (
          <Follow
            obj={object}
            labelRequired={true}
            extraClass='list-button ms-0'
            muteRequired={true}
            type='board'
          />
        );
    }
  }

  renderDeleteButton() {
    let entity = this.getTargetObjectForMute() || this.getEntity(),
      source = {
        id: entity.id,
        type: entity.__typename,
        comments_count: entity.comments_count,
      };
    return (
      <DeleteObject
        key='delete'
        object={this.getObject()}
        label='Delete Post'
        extraClass='list-button'
        source={source}
        listType='feeds-view'
      />
    );
  }

  renderEditButton() {
    return (
      <OsBtn
        key='edit'
        name='BtnIcon'
        text='Edit Post'
        extraClass='list-button'
        icon='edit'
        onClick={this.props.toggleEdit}
        associatedEntity={this.getObject()}
      />
    );
  }

  renderCustomDropdown() {
    const isCommentObject = this.isCommentObject();
    return (
      <div className='d-inline-block comment-edit-cancel pointer a-link fs-12 ms-auto'>
        <CustomDropdown
          name='feed_actions'
          className='comment-more position-relative'>
          {this.renderPin()}
          {this.props.editObject && this.renderEditButton()}
          {isCommentObject && this.renderAddTo()}
          {isCommentObject && this.renderShareButton()}
          {this.props.deleteObject && this.renderDeleteButton()}
          {this.renderMuteButton()}
        </CustomDropdown>
      </div>
    );
  }

  renderContent() {
    let object = this.getObject();
    if (object.rejected && object.__typename === 'Comment') {
      return (
        <div className='report-comment-message'>
          {translate('REPORTED_ENTITY_TEXT', { entity: 'comment' })}
        </div>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }

  getFeedTimeStamp() {
    return this.getObject()?.created_at || this.props.feed.visible_at;
  }

  renderFeedActors() {
    let { authors } = this.props.feed,
      multiple = authors.length > 1,
      props = {
        author: authors[0],
        authors,
        modalHeading: this.actorsModalHeading(),
        multiple,
        timestamp: this.getFeedTimeStamp(),
      };

    return (
      <>
        <AuthorDetail {...props} />
        {this.renderCustomDropdown()}
      </>
    );
  }

  renderPinnedIcon() {
    return (
      <div className='pinned-post-block'>
        <OrthoIcon name='pin' dataHoverNotRequired={true} />
        <span>Pinned Post</span>
      </div>
    );
  }

  isFeedHighlighted() {
    return +this.props.postForm.newPostId === +this.props.feed.id;
  }

  onLinkClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      e.preventDefault();
      e.stopPropagation();
      EventTracker.trackLinkClicked(e.target.text);
      this.props.navigate(e.target.getAttribute('href'));
    }
  };

  renderFeedTimeDetails() {
    let timestamp = this.getFeedTimeStamp();

    return (
      <div className='activity-heading-block' onClick={this.onLinkClick}>
        <div className='time-day' title={timestampOnHover(timestamp)}>
          {timeWithFormat(timestamp, 'll', true, {
            todayWithTimeRequired: true,
          })}
        </div>
      </div>
    );
  }

  renderFeedAvatars() {
    let { authors, targets } = this.props.feed;
    if (authors.length) {
      return this.renderFeedActors();
    } else {
      let space = this.getEntity();
      return (
        <>
          <div className='avatar-listing'>
            <Link
              to={entityUrl(space)}
              key={space.id}
              className='act-avatar-item'>
              <Avatar
                className='avatar avatar-48'
                {...avatarAttributes(space)}
              />
            </Link>
          </div>

          <aside className='user-name-block' key='feeds-actor-info'>
            <OsLink
              to={entityUrl(space)}
              className='feed-author-info user-link'
              text={space.name}
            />
            {this.renderFeedTimeDetails()}
          </aside>
        </>
      );
    }
  }

  isSpaceActivity() {
    return ['SpaceCommentActivity', 'CaseCommentActivity'].includes(
      this.props.feed.source_type,
    );
  }

  isProcessedClassRequired() {
    return isWorkspaceView() && this.getObject()?.processed;
  }

  render() {
    let isPinned = this.isPinned(),
      className = isPinned ? 'pinned-post' : '';

    className += this.isFeedHighlighted() ? ' active-post' : '';
    className += this.isProcessedClassRequired() ? ' processed' : '';

    let isInternalNote =
        this.getObject().visible_audience_ids?.length === 0 &&
        !isCareWorkspaceView(),
      labelSelectorVisible = false;
    if (this.props.listType === 'PartnerSpaceActivities') {
      isInternalNote = false;
      labelSelectorVisible = true;
    }
    let extraClass;
    if (isInternalNote) {
      extraClass = 'internal-note';
    }

    return (
      <div className='position-relative'>
        <article
          className={`activity-listing-item w-100 mobile-no-style d-block ${className}`}
          id={`activity-feed-${this.props.feed.id}`}
          ref='containerRef'>
          <ActivityBar
            {...this.props}
            labelSelectorVisible={labelSelectorVisible}
            isInternalNote={isInternalNote}
            extraClass={extraClass}
          />
          <div className='activity-listing-item-content'>
            {isPinned && this.renderPinnedIcon()}
            <div className='user-info-row d-flex'>
              {!this.isSpaceActivity() && this.renderFeedAvatars()}
            </div>
            {this.renderContent()}
          </div>
        </article>
      </div>
    );
  }
}

FeedDetails = withRouter(FeedDetails);
FeedDetails.defaultProps = {
  toggleEdit: () => {},
};
FeedDetails = connect(
  ({ currentUser, device, postForm }) => ({ currentUser, device, postForm }),
  {},
)(FeedDetails);
export default FeedDetails;
