import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import RedirectToDefault from 'app/components/RedirectToDefault';

import LocalStorageManager from 'app/services/LocalStorageManager';
import {
  canAccessRestrictedEntity,
  entityConversationUrl,
  entityUrl,
} from 'app/utils/entitiesHelper';

import { authModal } from 'app/actions/authentication';
import { actionByGuestUser } from 'app/actions/guestUser';
import { RESTRICTED_TO_NONRESTRICTED_ENTITY_TYPENAME } from 'app/constants';

const withAuthenticate = (WrappedComponent) => {
  class HOC extends React.Component {
    state = {
      redirect: false,
    };

    getInviteToken() {
      return LocalStorageManager.get('space_invite_token');
    }

    redirectToDefaultIfUnauthenticated = (callBack, action) => {
      if (!this.props.currentUser.graph) {
        this.redirectToDefault(action);
      } else {
        callBack();
      }
    };

    redirectToDefault = (action) => {
      if (this.props.loginModalRequired && !this.getInviteToken()) {
        this.props.authModal({ authModal: 'signup' });
      } else {
        this.setState({ redirect: true });
      }
      this.registerGuestAction(action);
    };

    registerGuestAction = (action) => {
      this.props.actionByGuestUser(action, this.metaInfo(action));
    };

    getObject() {
      return (
        this.props.space ||
        this.props.board ||
        this.props.obj ||
        (this.props.data && this.props.data.guest_space) ||
        this.props
      );
    }

    metaInfo(action) {
      return {}
    }

    render() {
      return this.state.redirect ? (
        <RedirectToDefault />
      ) : (
        <WrappedComponent
          redirectToDefault={this.redirectToDefault}
          redirectToDefaultIfUnauthenticated={
            this.redirectToDefaultIfUnauthenticated
          }
          registerGuestAction={this.registerGuestAction}
          {...this.props}
        />
      );
    }
  }

  HOC.defaultProps = {
    loginModalRequired: true,
  };
  HOC = withRouter(HOC);
  HOC = connect(({ currentUser }) => ({ currentUser }), {
    actionByGuestUser,
    authModal,
  })(HOC);
  return HOC;
};

export default withAuthenticate;
