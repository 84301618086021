export const LABELS_POSSIBLE_COLORS = {
  Pink: 'ff9aca',
  Red: 'e05257',
  Orange: 'fd9771',
  Yellow: 'fec452',
  Green: '4a9e6f',
  Teal: '3e9399',
  Blue: '4c89e5',
  Purple: '9b85cb',
};
