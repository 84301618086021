import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CareSpaceNavLinks from 'app/components/CareSpace/NavLinks';
import NotificationBar from 'app/components/Shared/NotificationBar';
import WorkspaceNavLinks from 'app/components/WorkspaceView/NavLinks';

import {
  isCareWorkspaceView,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { getWorkspaceNavTabs } from 'app/utils/Workspace/navHelper';

class LeftNavLinks extends PureComponent {
  isWorkspaceLinksRequired() {
    const { workspace } = this.props;
    return workspace && workspace.identifier;
  }

  updateNavTabsOnNotification(navTabs) {
    if (this.props.workspace?.data?.unread_task_notifications_count > 0) {
      return navTabs.map((tab) => {
        if (tab.key === 'my_tasks') {
          return {
            ...tab,
            path: 'tasks/me/inbox',
          };
        } else {
          return tab;
        }
      });
    }
    return navTabs;
  }

  renderWorkspaceNavLinks() {
    const { workspace } = this.props;
    let { rootTab, navTabs } = getWorkspaceNavTabs();
    navTabs = this.updateNavTabsOnNotification(navTabs);
    return (
      <WorkspaceNavLinks
        rootTab={rootTab}
        navTabs={navTabs}
        workspace={workspace}
      />
    );
  }

  renderEssentialComponents() {
    return (
      <>
        <NotificationBar />
      </>
    );
  }

  renderWebRoutes() {
    if (isCareWorkspaceView()) {
      return <CareSpaceNavLinks />;
    } else if (isWorkspaceView()) {
      return this.renderWorkspaceNavLinks();
    } else {
      return null;
    }
  }

  renderMobileRoutes() {
    if (isCareWorkspaceView()) {
      return (
        <nav className='mobile-nav-bar os-main-nav'>
          <CareSpaceNavLinks />
          <NotificationBar />
        </nav>
      );
    } else if (isWorkspaceView()) {
      if (this.isWorkspaceLinksRequired()) {
        return (
          <>
            {this.renderWorkspaceNavLinks()}
            {this.renderEssentialComponents()}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render() {
    const { mobileDevice } = this.props.device;
    return mobileDevice ? this.renderMobileRoutes() : this.renderWebRoutes();
  }
}

LeftNavLinks = connect(
  ({ device, workspace }) => ({ device, workspace }),
  {},
)(LeftNavLinks);

export default LeftNavLinks;
