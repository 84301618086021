import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';

import RecordsView from 'app/components/RecordsView';
import withAuthorize from 'app/components/HOC/withAuthorize';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';
import CareListingWrapper from 'app/components/SideBarListing/CareListingWrapper';
import { GROUPS_BATCH_SIZE } from 'app/constants';

class SpaceList extends Component {
  state = {
    query: '',
    isSearchFocussed: false,
    inputActive: false,
    keyToggler: false,
  };

  getTeamSpacesProps(displayType) {
    const workspace = getWorkspaceData();
    return {
      type: 'SubSpaces',
      displayType,
      board: workspace,
      idQuery: workspace.nice_id,
    };
  }

  getCareSpaceProps() {
    return {
      type: 'AllSidebarCareSpaces',
      windowNotRequired: true,
      threshold: 200,
      size: 'full-view-listing',
    };
  }

  getProps() {
    switch (this.props.type) {
      case 'SubSpaces':
        return (
          <RecordsView
            {...this.getTeamSpacesProps()}
            queryType='SIDEBAR_SPACE_DETAILS'
            textQuery={this.state.query}
            parentContainer={this.parentContainer}
            perPage={30}
            loaderNotRequired={true}
          />
        );
      case 'AllSidebarTeamSpaces':
        return (
          <RecordsView
            {...this.getTeamSpacesProps('sidebarList')}
            queryType='SIDEBAR_GROUP_LIST'
            textQuery={this.state.query}
            loadingCards={false}
            windowNotRequired={true}
            threshold={100}
            parentContainer={this.parentContainer}
            perPage={GROUPS_BATCH_SIZE}
            loaderNotRequired={true}
          />
        );
      case 'AllSidebarCareSpaces':
        return <CareListingWrapper type={'AllSidebarCareSpaces'} />;
      default:
        return null;
    }
  }

  renderContent() {
    return (
      <div
        data-id={'group'}
        className='spacelist-section'
        ref={(el) => (this.parentContainer = el)}>
        {this.getProps()}
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

SpaceList = withApollo(SpaceList);
SpaceList = withRouter(SpaceList);
SpaceList = withAuthorize(SpaceList);

SpaceList = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(SpaceList);

export default SpaceList;
