import React from 'react';
import { connect } from 'react-redux';
import { Routes } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import { Route } from 'react-router';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Authorize from 'app/components/Authorize';
import Layout from './Layout';
import RecordsView from 'app/components/RecordsView';
import Timeline from 'app/components/UserViewTabs/Timeline';

import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';

import EventTracker from 'app/services/EventTracker';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

import { getCurrentFilter, getTabListPathkeys } from './helper';
import { isCurrentUser } from 'app/utils/userHelper';

const USER_QUERY = queryLoader('app/graphql/User.gql');

const TAB_ROUTES_MAPPER = {
  timeline: {
    Component: Timeline,
  },
  notifications: {
    Component: RecordsView,
    componentProps: { type: 'Notification', perPage: 25 },
  },
};

class UserView extends Authorize {
  constructor(props) {
    super(props);
    this.resourceKey = 'user';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.loading && !this.props.data.loading) {
      if (this.props.data.user) {
        if (isCurrentUser(this.props.data.user)) {
          EventTracker.track('Navigate', {
            page: 'Public Profile',
            id: this.props.currentUser.graph.id,
          });
        } else if (this.props.source.isCare) {
          this.props.navigate('/');
        }
      }
    }
  }

  getSource() {
    return isCareWorkspaceView() ? 'care' : '';
  }

  renderRoute = (path, user, source, filter) => {
    const { Component, componentProps = {} } = TAB_ROUTES_MAPPER[path];
    return (
      <Route
        key={path}
        path={`${path}`}
        element={<Component user={user} filter={filter} {...componentProps} />}
      />
    );
  };

  render() {
    let content = this.authorize();
    if (content) return content;

    let { user } = this.props.data,
      filter = getCurrentFilter(this.props.location.pathname, user.nice_id),
      source = this.getSource(),
      paths = getTabListPathkeys(user, source);

    return (
      <Routes>
        <Route element={<Layout user={user} filter={filter} source={source} />}>
          {paths.map((path) => this.renderRoute(path, user, source, filter))}
        </Route>
        <Route
          path='*'
          element={<Navigate to={paths[0]} replace={true} skip={true} />}
        />
      </Routes>
    );
  }
}

UserView = withGraphqlRequests(
  USER_QUERY,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.match.params.id,
        toolsRequired: true,
      },
    }),
  },
  {
    objectIdentifier: 'user',
  },
)(UserView);
UserView = withRouter(UserView);
UserView = connect(
  ({ currentUser, device, source }) => ({ currentUser, device, source }),
  {},
)(UserView);

export default UserView;
