import React, { PureComponent } from 'react';

import CareSpaceInformation from './CareSpaceInformation';
import SubHeader from 'app/components/BoardView/SubHeader';

export default class SpaceInformationView extends PureComponent {
  render() {
    const { type } = this.props.space;
    switch (type) {
      case 'Care':
        return (
          <CareSpaceInformation
            space={this.props.space}
            hideEditor={this.props.hideEditor}
            showEditor={this.props.showEditor}
          />
        );
      default:
        return (
          <SubHeader
            space={this.props.space}
            isGuestUser={this.props.isGuestUser}
          />
        );
    }
  }
}
