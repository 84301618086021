import React from 'react';
import AbstractCard from 'app/components/OsCards/AbstractCard';

import CareSpaceEditPatientBtn from 'app/components/Shared/CareSpace/EditPatientBtn';
import CareSpaceEditGuardianBtn from 'app/components/Shared/CareSpace/EditGuardianBtn';
import MarketingTags from 'app/components/Shared/MarketingTags';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import {
  isUserAuthenticated,
  isSharedWithParty,
} from 'app/utils/spaces/careHelper';
import { translate } from 'app/actions/flashMessage';
import { isMinorAge, stringToDate } from 'app/utils/timeHelper';

const REJECTION_REASON_TEXT = {
  invalid_request: 'Invalid Request',
  do_not_want_to_join: 'Do not want to join',
  other: 'Other',
};

class AbstractPersonCard extends AbstractCard {
  isDeclined() {
    return !!this.props.obj.last_request?.rejection_reason;
  }

  renderIcon(icon) {
    return (
      <OrthoIcon
        name={icon}
        dataHoverNotRequired={true}
        defaultClass='etc-icon lt-red'
      />
    );
  }

  renderEditGuardianButton() {
    return (
      <CareSpaceEditGuardianBtn
        space={this.space()}
        contactParty={this.props.obj}
      />
    );
  }

  renderEditPatientButton() {
    return (
      <CareSpaceEditPatientBtn
        space={this.space()}
        source={this.source()}
        guardiansNotRequired={
          !isMinorAge(stringToDate(this.props.obj.date_of_birth))
        }
      />
    );
  }

  tagsClass() {
    return '';
  }

  renderTags() {
    let { tags } = this.props.obj;
    return (
      <MarketingTags
        tags={tags}
        limit={1}
        source='person'
        className={this.tagsClass()}
      />
    );
  }

  isUserLoggedIn() {
    return isUserAuthenticated(this.space(), {
      id: this.props.obj.user_id,
    });
  }

  renderAccessedIcon() {
    return (
      <OrthoIcon
        name='checkmark-round'
        defaultClass='big-status-icon green'
        dataHoverNotRequired={true}
        title='Accepted'
      />
    );
  }

  renderErrorRedIcon() {
    return (
      <OrthoIcon
        name='checkmark-round'
        defaultClass='big-status-icon red'
        dataHoverNotRequired={true}
        title='Declined'
      />
    );
  }

  renderNotSharedIcon() {
    return (
      <OrthoIcon
        name='checkmark-round-default'
        defaultClass='big-status-icon'
        dataHoverNotRequired={true}
        title='Not Shared'
      />
    );
  }

  renderSharedButNotAccessedIcon() {
    return (
      <OrthoIcon
        name='checkmark-round'
        defaultClass='big-status-icon'
        dataHoverNotRequired={true}
        title='Shared'
      />
    );
  }

  renderSharedWithPatientIcon() {
    const space = this.space(),
      person = this.props.obj,
      user = { id: person.user_id };

    if (!this.isDeclined() && space && (person.email || person.phone_number)) {
      if (isSharedWithParty(space, user, person)) {
        if (isUserAuthenticated(space, user)) {
          return this.renderAccessedIcon();
        } else {
          return this.renderSharedButNotAccessedIcon();
        }
      } else {
        return this.renderNotSharedIcon();
      }
    }
  }

  renderDeclinedIconAndReason() {
    if (this.isDeclined()) {
      let { rejection_reason } = this.props.obj.last_request,
        reason = REJECTION_REASON_TEXT[rejection_reason] || rejection_reason;
      return (
        <span className='pateint-access-tooltip'>
          <OrthoIcon name='clear' defaultClass=' etc-icon lt-red' />
          <div className='tab-link-tooltip'>
            <OrthoIcon name='sort-down' defaultClass='tooltip-arrow-top' />
            <div className='tooltip-container'>
              <OrthoIcon name='info' dataHoverNotRequired={true} />
              {translate('CARE_SPACE_ACCESS_DECLINED_REASON', {
                reason: reason ? `Reason: ${reason}.` : '',
              })}
            </div>
          </div>
        </span>
      );
    }
  }
}
export default AbstractPersonCard;
