export const LATEST_PATIENT_ACTIVITY = 'Latest Patient Activity';
export const PATIENT_DIRECTORY = 'Patient Directory';
export const MY_TASKS = 'My Tasks';
export const ALL_TASKS = 'All Tasks';
export const SHOW_CALENDAR_VIEW = 'Show Calendar View';
export const SHOW_LIST_VIEW = 'Show List View';
export const SHOW_INBOX_VIEW = 'Show Inbox View';
export const ADD_NEW_PATIENT = 'Add a New Patient';
export const ADD_NEW_TASK = 'Create New Task';
export const DIRECT_CONVERSATION = 'Direct Messages';
export const DIRECT_CONVERSATION_NAVIGATION = 'Direct Conversation Navigation';
export const DIRECT_CONVERSATION_PLACEHOLDER = 'Search for a Team Member';
export const SWITCH_WORKSPACE = 'Switch Workspace';
export const WORKSPACE_PLACEHOLDER =
  'Enter workspace name to switch workspace...';
export const GROUPS = 'Groups';
export const GROUPS_PLACEHOLDER = 'Enter group name to search for group...';
export const LABELS = 'Labels';
export const CLINIC_INFORMATION = 'Clinic Information';
export const ALL_TEAM_MEMBERS = 'All Team Members';
export const TEMPLATES = 'Templates';
export const PATIENT_CARESPACE = 'Patient Carespace';
export const GROUPS_NAVIGATION = 'Groups Navigation';
export const SEARCH_FOR_PATIENT = 'Search for Patient';
export const PATIENT_PLACEHOLDER =
  'Enter patient name to search for patient...';
export const CREATE_NEW_GROUP = 'Create New Group';
export const SEARCH_FOR_GROUPS = 'Search for Groups';
export const OPEN_COMMAND_MENU = 'OPEN_COMMAND_MENU';
export const CLOSE_COMMAND_MENU = 'CLOSE_COMMAND_MENU';

export const PARTNERS = 'Partners';
export const ALL_PARTNERS = 'All Partners';
export const INVITE_PARTNER = 'Invite Partner Clinic';

export const MY_INBOX = 'My Inbox';
export const MY_INBOX_TASKS = 'Show Tasks Mentions';
export const MY_INBOX_GROUPS = 'Show Group Mentions';
export const MY_INBOX_DM = 'Show DM Mentions';
export const MY_INBOX_PATIENTS = 'Show Patient Activity Mentions';
export const MY_INBOX_PARTNERS = 'Show Partner Activity Mentions';
export const SHARED_OFFICE_INBOX = 'SHARED OFFICE INBOX';
export const SHARED_OFFICE_INBOX_PATIENTS = 'Show Patients Comments';
export const SHARED_OFFICE_INBOX_PARTNERS = 'Show Partner Comments';

export const FILTER_VALUES = {
  DEFAULT: 'default',
  PATIENTS: 'patients',
  GROUPS: 'groups',
  WORKSPACES: 'workspaces',
  CONVERSATION: 'conversation',
};
export const LAUNCHER_HASH_FRAGMENT = '#launcher';
export const RECENT_COMMANDS = 'recent_commands';
