import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import withAuthorize from 'app/components/HOC/withAuthorize';

import EventTracker from 'app/services/EventTracker';
import { setNavSearchOpen } from 'app/actions/navSearch';
import { logout } from 'app/actions/authentication';
import { toggleWorkspaceDropdown } from 'app/actions/workspace';
import UnreadIndicatorIcon from 'app/components/Shared/UnreadIndicatorIcon';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import {
  getFolderUnreadNotificationCount,
  getNotificationUrl,
  getPatientUnreadNotificationCount,
  getTaskUnreadNotificationCount,
  getTeamUnreadNotificationCount,
  getUnreadConversationCount,
  getUnreadNotificationCount,
} from 'app/utils/userHelper';
import { entityUrl } from 'app/utils/entitiesHelper';
import { parameterize } from 'app/utils/stringHelper';
import { snakeCase } from 'app/utils/osLodash';
import {
  MOBILE_NAV_LINKS,
  MOBILE_NAV_LINKS_WITH_SEARCH,
  NAV_LINKS,
} from './constants';
import { NAV_LINKS_FOR_WORKSPACE } from 'app/components/WorkspaceView/NavLinks/Constants';
import { customCount } from 'app/utils/textFormatting';
import { MAIL_TO_SUPPORT } from 'app/constants';
import { isWorkspaceSpace } from 'app/utils/Workspace/generalHelper';
import {
  navDropdownOptions,
  unreadCountPresentFor,
} from 'app/utils/Workspace/navHelper';

class MobileNav extends Component {
  onSpanContentClick = (methodName) => {
    this.props.onClick();
    this.props.openUpgradeModalIfUnauthorized(this[methodName], {});
  };

  redirectToSupportChat = () => {
    EventTracker.track('Navigate', { page: 'Support' });
    this.trackDropdownClick('support');
    let support_conversation_id = this.getCurrentUser().support_conversation_id;
    if (support_conversation_id) {
      this.props.navigate(`/conversations/${support_conversation_id}`);
    } else {
      window.location.href = MAIL_TO_SUPPORT;
    }
  };

  redirectToWorkspaces = () => {
    EventTracker.track('Navigate', { page: 'Launch Page' });
    this.trackDropdownClick('Switch Workspaces');
    this.props.navigate('/launch', { skip: true });
  };

  onCommunicationLinkClick = () => {
    this.props.navigate('/team');
  };

  getNavLinks() {
    return this.props.workspace ? NAV_LINKS_FOR_WORKSPACE : NAV_LINKS;
  }

  trackDropdownClick(name) {
    EventTracker.trackDropdownClick('mobile_nav_more', snakeCase(name));
  }

  toggleNavDropdown = () => {
    this.props.toggleNavDropdown();
  };

  logout = () => {
    this.trackDropdownClick('logout');
    this.props.logout();
  };

  getCurrentUser() {
    return this.props.currentUser.graph;
  }

  getCurrentUserProfileUrl() {
    return entityUrl(this.getCurrentUser());
  }

  openNotificationPage = () => {
    this.trackDropdownClick('notifications');
    this.props.navigate(getNotificationUrl());
  };

  getMyCasesUrl = () => {
    return `${this.getCurrentUserProfileUrl()}/cases`;
  };

  openMyCases = () => {
    this.trackDropdownClick('my_cases');
    this.props.navigate(this.getMyCasesUrl());
  };

  openProfilePage = () => {
    this.trackDropdownClick('my_profile');
    this.props.navigate(this.getCurrentUserProfileUrl());
  };

  currentEventSpacePresent() {
    let { current_event_space } = this.props.systemConfig.configs;
    return current_event_space && current_event_space.show_link_at_top;
  }

  isCurrentUserProfile() {
    let paramsId = this.props.match.params.id;
    if (!paramsId && this.props.workspace) {
      paramsId = this.props.location.pathname.split('/')[2];
    }
    return paramsId === this.getCurrentUser().nice_id;
  }

  isSpacesTabActive() {
    let currentPath = this.props.location.pathname,
      activeResource = this.props.activeResource.resource || {};
    if (this.props.workspace) {
      return (
        currentPath.includes('/subspaces') ||
        isWorkspaceSpace(activeResource.owner || {})
      );
    } else {
      return (
        currentPath === '/spaces' ||
        (activeResource &&
          activeResource.nice_id !== this.currentEventInfo().nice_id)
      );
    }
  }

  isTabActive() {
    let currentPath = this.props.location.pathname,
      link = this.props.link,
      navLinks =
        this.props.workspaceLinks ||
        (this.currentEventSpacePresent()
          ? MOBILE_NAV_LINKS
          : MOBILE_NAV_LINKS_WITH_SEARCH),
      { urls, exactUrls } = this.getNavLinks()[link];
    exactUrls = exactUrls || [];
    urls = urls || [];

    if (
      navLinks.includes(link) &&
      this.props.navDropdownActive &&
      link !== 'More'
    ) {
      return false;
    } else {
      switch (link) {
        case 'Patient':
          return (
            currentPath.includes('/patient') ||
            currentPath.includes('/spaces') ||
            currentPath === withWorkspaceIdentifier('/explore')
          );
        case 'Team':
          return currentPath === withWorkspaceIdentifier('/team');
        case 'More':
          return this.props.navDropdownActive;
        case 'Account Settings':
          return currentPath.startsWith(
            withWorkspaceIdentifier('/account_settings'),
          );
        case 'My Profile':
          return (
            currentPath.startsWith(withWorkspaceIdentifier('/users')) &&
            this.isCurrentUserProfile() &&
            currentPath !== getNotificationUrl()
          );
        case 'Members':
          return (
            (this.props.workspace &&
              currentPath.startsWith(withWorkspaceIdentifier('/members'))) ||
            (currentPath.startsWith(withWorkspaceIdentifier('/users')) &&
              !this.isCurrentUserProfile())
          );
        case 'Notifications':
          return currentPath === getNotificationUrl();
        case 'Spaces':
          return this.isSpacesTabActive();
        case 'Information':
          return currentPath === withWorkspaceIdentifier(urls[0]);
        default:
          return (
            exactUrls.some(
              (url) => withWorkspaceIdentifier(url) === currentPath,
            ) ||
            urls.some((url) =>
              currentPath.startsWith(withWorkspaceIdentifier(url)),
            )
          );
      }
    }
  }

  onLinkClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.navDropdownActive && this.toggleNavDropdown();
    this.props.openUpgradeModalIfUnauthorized(this.onNavLinkClick);
  };

  onNavLinkClick = () => {
    let { link } = this.props,
      { urls } = this.getNavLinks()[link];

    this.props.navigate(urls[0]);
    this.props.onClick();
    this.props.navDropdownActive && this.trackDropdownClick(link);
  };

  countInfo() {
    let { link } = this.props;
    if (this.getNavLinks()[link].countRequired) {
      let notificationsCount = getUnreadNotificationCount(
          this.getCurrentUser(),
        ),
        messagesCount = getUnreadConversationCount(this.getCurrentUser()),
        tasksCount = getTaskUnreadNotificationCount(this.getCurrentUser()),
        patientCount = getPatientUnreadNotificationCount(this.getCurrentUser()),
        teamCount = getTeamUnreadNotificationCount(this.getCurrentUser()),
        folderCount = getFolderUnreadNotificationCount(this.getCurrentUser());

      if (['More', 'Notifications'].includes(link) && folderCount > 0) {
        return { topNavCount: link === 'More', count: folderCount };
      } else if (link === 'Messages' && messagesCount > 0) {
        return { topNavCount: true, count: messagesCount };
      } else if (link === 'Tasks' && tasksCount > 0) {
        return { topNavCount: true, count: tasksCount };
      } else if (link === 'Patient' && patientCount > 0) {
        return { topNavCount: true, count: patientCount };
      } else if (link === 'Team' && teamCount > 0) {
        return { topNavCount: true, count: teamCount };
      }
    }
  }

  renderCount() {
    let countInfo = this.countInfo();
    if (countInfo) {
      let { topNavCount, count } = countInfo;
      return (
        <span
          className={
            topNavCount ? 'm-notifications-count' : 'mobile-notification-count'
          }
          style={this.props.link === 'Tasks' ? { right: '0px' } : {}}>
          {topNavCount ? customCount(count) : count}
        </span>
      );
    }
  }

  getLinkDisplayName(title) {
    return title || this.props.link;
  }

  isNavLinkVisible() {
    const { link } = this.props,
      data = NAV_LINKS_FOR_WORKSPACE[link];

    return !data.shouldBeVisible || data.shouldBeVisible();
  }

  renderUnreadIndicatorIfAnyPresent() {
    const user = this.props.currentUser.graph;
    if (
      navDropdownOptions().some((option) =>
        unreadCountPresentFor(option, {
          communityUnreadConversationCount: user.unread_conversations_count,
          communityUnreadNotificationCount: user.unread_notifications_count,
        }),
      )
    )
      return <UnreadIndicatorIcon />;
  }

  renderLinkContent() {
    let { urls, mobileIcon, iconName, expandIconRequired, title } =
      this.getNavLinks()[this.props.link];

    return (
      <Link
        to={withWorkspaceIdentifier(urls[0])}
        className={`${this.isTabActive() ? 'active' : ''} nav-link`}
        onClick={this.onLinkClick}>
        <OrthoIcon
          name={mobileIcon || iconName}
          defaultClass='icon-nav'
          dataHoverNotRequired={true}
        />
        <span className='site-nav-text'>{this.getLinkDisplayName(title)}</span>
        {this.renderCount()}
        {expandIconRequired && (
          <OrthoIcon
            name={'chevron-right'}
            defaultClass='ml-auto fs-12'
            dataHoverNotRequired={true}
          />
        )}
      </Link>
    );
  }

  renderSpanContent() {
    let { expandIconRequired, iconName, onClickMethodName, title } =
      this.getNavLinks()[this.props.link];

    return (
      <span
        className={`${this.isTabActive() ? 'active' : ''} nav-link`}
        onClick={this.onSpanContentClick.bind(this, onClickMethodName)}>
        <i className={`icon-nav ${iconName}`} />
        <OrthoIcon
          name={iconName}
          defaultClass='icon-nav'
          dataHoverNotRequired={true}
        />
        <span className='site-nav-text'>{this.getLinkDisplayName(title)}</span>
        {this.renderCount()}
        {expandIconRequired && (
          <OrthoIcon
            name={'chevron-right'}
            defaultClass='ml-auto fs-12'
            dataHoverNotRequired={true}
          />
        )}
      </span>
    );
  }

  openSearchField = () => {
    EventTracker.track('Navigate - Search Box Clicked');
    this.props.openUpgradeModalIfUnauthorized(this.props.setNavSearchOpen, {});
  };

  renderContent() {
    let link = this.props.link,
      { onClickMethodName } = this.getNavLinks()[link];

    return onClickMethodName
      ? this.renderSpanContent()
      : this.renderLinkContent();
  }

  render() {
    let link = this.props.link;
    if (this.isNavLinkVisible()) {
      return (
        <li
          className={`nav-item  ${parameterize(link.toLocaleLowerCase())}`}
          style={
            parameterize(link.toLocaleLowerCase()) === 'switch-workspaces'
              ? {
                  marginTop: '72px',
                }
              : {}
          }
          key={link}>
          {this.renderContent()}
        </li>
      );
    } else {
      return null;
    }
  }
}

MobileNav.defaultProps = {
  onClick: () => {},
  toggleNavDropdown: () => {},
};
MobileNav = connect(
  ({ activeResource, currentUser, navSearch, workspace, systemConfig }) => ({
    activeResource,
    currentUser,
    navSearch,
    workspaceDropdownOpen: workspace.dropdownOpen,
    systemConfig,
  }),
  { logout, toggleWorkspaceDropdown, setNavSearchOpen },
)(MobileNav);
MobileNav = withAuthorize(MobileNav);
MobileNav = withRouter(MobileNav);

export default MobileNav;
