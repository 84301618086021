export const LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR = '#5d7893';
export const NON_LOGGED_IN_AVATAR_USER_AVATAR_RING_COLOR = '#B9C4CF';

export const AVATAR_CSS = {
  '#5d7893_without_image': {
    borderColor: '#5d7893',
    backgroundColor: '#7e91a5',
    border: '3px solid #5d7893',
  },
  '#5d7893_with_image': {
    borderColor: '#5d7893',
    backgroundColor: '#7e91a5',
    border: '3px solid #5d7893',
  },
  '#B9C4CF_without_image': {
    borderColor: '#B9C4CF',
    backgroundColor: '#B9C4CF',
    border: '3px solid #B9C4CF',
  },
  '#B9C4CF_with_image': { borderColor: '#B9C4CF', backgroundColor: '#B9C4CF' },
};

export function hexToRgb(hex="#ffffff") {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  // Convert the expanded hex to RGB
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  // Return the RGB value with alpha set to 0.3
  return `rgba(${r}, ${g}, ${b}, 0.4)`;
}
