export const SHARE_INSIGHT = 'SHARE_INSIGHT';

export const CASE_VIEW_OPTIONS = {
  Reset: {
    icon: '',
    text: 'Reset',
    btnText: 'Reset',
    extraClass: 'reset-btn',
  },
  'All Appointments': {
    icon: 'case-single',
    text: 'Individual',
  },
  GALLERY_UPDATED: {
    icon: 'case-gallery',
    text: 'Gallery View',
  },
  SBS: {
    icon: 'case-sbs',
    text: 'SBS',
  },
  'SUP-ACB': {
    icon: 'case-acb',
    text: 'SUP-ACB',
  },
  'SUP-MND': {
    icon: 'case-mnd',
    text: 'SUP-MND',
  },
  ZOOM: {
    icon: 'zoom-in',
    text: 'Zoom',
    extraClass: 'd-none',
  },
};

export const UPDATED_GRID_VIEW_IMAGES_INFO_HASH = {
  profile_relaxed: {
    class: 'pic-1 object-position-right',
  },
  frontal_relaxed: {
    class: 'pic-2',
  },
  frontal_smiling: {
    class: 'pic-3',
  },
  occlusal: {
    class: 'cs-grid-col-9-3 pic-4',
    childClass: ['cs-grid-pic-4a', 'cs-grid-pic-4b'],
    name: ['upper_occlusal', 'lower_occlusal'],
    inColumn: true,
  },
  right_buccal: {
    class: 'cs-grid-col-3 pic-6',
  },
  closed_anterior: {
    class: 'cs-grid-col-3 pic-7',
  },
  left_buccal: {
    class: 'cs-grid-col-3 pic-8',
  },
};

export const CASE_IMAGE_HASH = {
  closed_anterior: 'Closed Anterior',
  frontal_relaxed: 'Frontal Relaxed',
  frontal_smiling: 'Frontal Smiling',
  left_buccal: 'Left Buccal',
  lower_occlusal: 'Lower Occlusal',
  profile_relaxed: 'Profile Relaxed',
  right_buccal: 'Right Buccal',
  upper_occlusal: 'Upper Occlusal',
};

export const CLASSIC_GRID_VIEW_IMAGES_INFO_HASH = {
  profile_relaxed: {
    class: 'pic-1',
  },
  frontal_relaxed: {
    class: 'pic-2',
  },
  frontal_smiling: {
    class: 'pic-3',
  },
  upper_occlusal: {
    class: 'cs-grid-col-9-3 pic-4',
  },
  empty: {
    class: 'cs-grid-col-9-3 pic-5',
  },
  lower_occlusal: {
    class: 'cs-grid-col-9-3 pic-6-1 pic-6',
  },
  right_buccal: {
    class: 'cs-grid-col-9-3 pic-6',
  },
  closed_anterior: {
    class: 'cs-grid-col-9-3 pic-6',
  },
  left_buccal: {
    class: 'cs-grid-col-9-3 pic-6',
  },
};
