import React, { Component } from 'react';
import { connect } from 'react-redux';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';

import { openAppointmentInfoModal } from 'app/actions/appointmentInfoModal';
import {
  setCaseDetailValuesToDefault,
  setRightSideAppointment,
  setSelectedAppointment,
} from 'app/actions/caseDetailInfo';
import { timeWithFormat } from 'app/utils/timeHelper';

import {
  APPOINTMENT_DATE_FORMAT,
  VIDEO_TYPES,
} from 'app/components/CaseLeftSection/constants';
import {
  isIntitalCategoryAppointment,
  isFinalCategoryAppointment,
  getTimeLineDetails,
  getAppointmentIcon,
} from 'app/components/CaseLeftSection/helpers';

import { areSameAppointmentsSelected } from 'app/utils/caseHelper';
import { indexOf, map } from 'app/utils/osLodash';

const BUTTON_CSS_HASH = {
  desktop: {
    width: 32,
    margin: 4,
  },
  ipadPortrait: {
    width: 40,
    margin: 4,
  },
  mobile: {
    width: 48,
    margin: 4,
  },
};
class CaseAppointmentsButtons extends Component {
  state = {
    hoverIndex: null,
    lockedAppointmentId: null,
    // shrinkAppointments: this.props.device.mobileDevice && this.isSBSViewTypeSelected() && this.differenceBetweenAppointmentsSelected() >= 4
  };

  componentDidUpdate(prevProps) {
    // if(this.props.device.mobileDevice && this.isSBSViewTypeSelected() && (!isEqual(prevProps.caseDetailInfo.selectedRightSideAppointment, this.props.caseDetailInfo.selectedRightSideAppointment) || !isEqual(prevProps.caseDetailInfo.selectedAppointment, this.props.caseDetailInfo.selectedAppointment))) {
    //   if(this.differenceBetweenAppointmentsSelected() >= 4) {
    //     this.setState({ shrinkAppointments: true })
    //   } else {
    //     this.setState({ shrinkAppointments: false })
    //   }
    // }
    // if(prevProps.caseDetailInfo.viewType === "SBS" && !this.isSBSViewTypeSelected() && this.state.shrinkAppointments) {
    //   this.setState({ shrinkAppointments: false })
    // }
  }

  indexOfLeftSideAppointment() {
    return indexOf(
      map(this.props.appointments, 'id'),
      this.props.caseDetailInfo.selectedAppointment.id,
    );
  }

  indexOfRightSideAppointment() {
    return indexOf(
      map(this.props.appointments, 'id'),
      this.props.caseDetailInfo.selectedRightSideAppointment.id,
    );
  }

  differenceBetweenAppointmentsSelected() {
    return (
      this.indexOfRightSideAppointment() - this.indexOfLeftSideAppointment()
    );
  }

  isCurrentAppointmentSelected(appointment) {
    let { caseDetailInfo, appointments } = this.props;
    if (VIDEO_TYPES.includes(caseDetailInfo.viewType)) {
      return (
        isIntitalCategoryAppointment(appointment, appointments) ||
        isFinalCategoryAppointment(appointment, appointments)
      );
    } else {
      return caseDetailInfo.selectedAppointment?.id === appointment.id;
    }
  }

  isRightSideAppointmentSelected(appointment) {
    let selectedRightSideAppointment =
      this.props.caseDetailInfo.selectedRightSideAppointment;
    return (
      selectedRightSideAppointment &&
      selectedRightSideAppointment.id === appointment.id
    );
  }

  isInBetweenSelectedAppointment(appointment) {
    const { selectedRightSideAppointment, selectedAppointment } =
      this.props.caseDetailInfo;
    return (
      selectedRightSideAppointment &&
      selectedRightSideAppointment.date > appointment.date &&
      selectedAppointment.date < appointment.date
    );
  }

  isSBSViewTypeSelected() {
    return this.props.caseDetailInfo.viewType === 'SBS';
  }

  isAppoinmentSelected(appointment) {
    return (
      this.isCurrentAppointmentSelected(appointment) ||
      this.isRightSideAppointmentSelected(appointment)
    );
  }

  getExtraClass(appointment, source = 'button') {
    let extraClass = '';
    if (this.isSBSViewTypeSelected()) {
      if (
        source === 'index' &&
        this.isInBetweenSelectedAppointment(appointment)
      ) {
        extraClass += ' invisible';
      } else if (
        areSameAppointmentsSelected() &&
        this.isCurrentAppointmentSelected(appointment)
      ) {
        extraClass += ' active ';
      } else if (this.isRightSideAppointmentSelected(appointment)) {
        extraClass += ' timeline-active ';
      } else if (this.isCurrentAppointmentSelected(appointment)) {
        extraClass += ' timeline-active ';
      }
    } else if (this.isCurrentAppointmentSelected(appointment)) {
      extraClass += ' active ';
    }
    return extraClass;
  }

  onMouseEnter = (hoverIndex, appointment) => {
    if (this.isSBSViewTypeSelected()) {
      this.setState({ hoverIndex });
    }
    this.props.setAppointmentHoveredState(true);
  };

  onMouseLeave = () => {
    this.state.hoverIndex !== null && this.setState({ hoverIndex: null });
    this.props.setAppointmentHoveredState(false);
  };

  getAppointmentTitle(appointment) {
    return [
      timeWithFormat(appointment.date, APPOINTMENT_DATE_FORMAT),
      appointment.label,
    ]
      .filter((text) => text)
      .join(' - ');
  }

  renderAppointmentButton = (appointment, index) => {
    const { appointmentIdsWithActions } = this.props;
    let extraClass = this.getExtraClass(appointment),
      icon = getAppointmentIcon(appointment);

    return (
      <OsLink
        onMouseEnter={this.onMouseEnter.bind(this, index, appointment)}
        onMouseLeave={this.onMouseLeave}
        key={appointment.id}
        tooltipText={this.getAppointmentTitle(appointment)}
        className={`cs-record-btn ${extraClass}`}
        onClick={this.setSelectedAppointment.bind(this, appointment)}>
        <OrthoIcon name={icon} dataHoverNotRequired={true} />
      </OsLink>
    );
  };

  isAppointmentLocked(appointment) {
    return +this.state.lockedAppointmentId === +appointment?.id;
  }

  unlockAppointment() {
    this.setState({ lockedAppointmentId: null });
  }

  lockAppointment(appointment) {
    this.setState({ lockedAppointmentId: appointment?.id });
  }

  renderSbsAppointmentIcons(appointment, index) {
    if (this.isSBSViewTypeSelected() && this.state.hoverIndex === index) {
      let iconName = this.isAppointmentLocked(appointment)
        ? 'locked-fill'
        : 'unlocked-fill';
      return <OrthoIcon name={iconName} />;
    }
  }

  renderAppointmentIndex = (appointment, index) => {
    let extraClass = this.getExtraClass(appointment, 'index'),
      text =
        this.isSBSViewTypeSelected() && this.state.hoverIndex === index
          ? ''
          : index + 1;
    return (
      <OsLink
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={appointment.id}
        tooltipText={this.getAppointmentTitle(appointment)}
        className={`cs-record-btn cs-index-count  ${extraClass}`}
        text={text}
        onClick={this.setSelectedAppointment.bind(this, appointment)}>
        {this.renderSbsAppointmentIcons(appointment, index)}
      </OsLink>
    );
  };
  //
  // expandAppointments = () => {
  //   this.setState({ shrinkAppointments: false })
  // }

  renderAppointmentLink(appointment, index) {
    let extraClass = this.getExtraClass(appointment);
    return (
      <OsLink
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={appointment.id}
        tooltipText={this.getAppointmentTitle(appointment)}
        className={`cs-record-btn ${extraClass}`}
        text={`T${index + 1}`}
        onClick={this.setSelectedAppointment.bind(this, appointment)}
      />
    );
  }

  // renderAppointmentButton = (appointment, index) => {
  //   let extraClass = this.getExtraClass(appointment);
  //   if(this.state.shrinkAppointments) {
  //     if(appointment.id === this.props.caseDetailInfo.selectedAppointment.id) {
  //       return <>
  //         {this.renderAppointmentLink(appointment, index)}
  //         <OsLink onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} key="expandable" tooltipText="expand appointments" className={`cs-record-btn ${extraClass}`} text="..." onClick={this.expandAppointments} />
  //       </>
  //     } else if(this.props.caseDetailInfo.selectedRightSideAppointment && (appointment.id === this.props.caseDetailInfo.selectedRightSideAppointment.id || this.indexOfLeftSideAppointment() > index || this.indexOfRightSideAppointment() < index)) {
  //       return this.renderAppointmentLink(appointment, index)
  //     }
  //   } else {
  //     return this.renderAppointmentLink(appointment, index)
  //   }
  // }

  getLockedAppointment() {
    return (this.props.appointments || []).find(
      (appointment) => +appointment.id === +this.state.lockedAppointmentId,
    );
  }

  setSelectedAppointment = (appointment) => {
    if (this.isSBSViewTypeSelected()) {
      let selectedRightSideAppointment =
          this.props.caseDetailInfo.selectedRightSideAppointment,
        selectedLeftSideppointment =
          this.props.caseDetailInfo.selectedAppointment,
        appointmentIds = map(this.props.appointments || [], 'id'),
        currentAppointmentIndex = indexOf(appointmentIds, appointment.id);

      if (this.state.hoverIndex !== null) {
        this.setState({ hoverIndex: null });
      }

      if (this.isAppoinmentSelected(appointment)) {
        this.isAppointmentLocked(appointment)
          ? this.unlockAppointment()
          : this.lockAppointment(appointment);
      } else if (this.state.lockedAppointmentId) {
        let lockedAppointmentIndex = indexOf(
            appointmentIds,
            this.state.lockedAppointmentId,
          ),
          lockedAppointment = this.getLockedAppointment(),
          isLockedAppointmentLeftSide =
            +selectedLeftSideppointment.id === +this.state.lockedAppointmentId;

        if (lockedAppointmentIndex > currentAppointmentIndex) {
          isLockedAppointmentLeftSide &&
            this.props.setRightSideAppointment(lockedAppointment);
          this.props.setSelectedAppointment(appointment);
        } else {
          !isLockedAppointmentLeftSide &&
            this.props.setSelectedAppointment(lockedAppointment);
          this.props.setRightSideAppointment(appointment);
        }
      } else {
        if (
          selectedRightSideAppointment &&
          selectedRightSideAppointment.id === selectedLeftSideppointment.id
        ) {
          let prevIndex = indexOf(
            appointmentIds,
            selectedLeftSideppointment.id,
          );

          if (currentAppointmentIndex > prevIndex) {
            this.props.setRightSideAppointment(appointment);
          } else {
            this.props.setSelectedAppointment(appointment);
          }
        } else {
          this.props.setRightSideAppointment(appointment);
          this.props.setSelectedAppointment(appointment);
        }
      }
    } else if (VIDEO_TYPES.includes(this.props.caseDetailInfo.viewType)) {
      this.props.setCaseDetailValuesToDefault();
      this.props.setSelectedAppointment(appointment);
    } else {
      this.props.setSelectedAppointment(appointment);
      this.props.setRightSideAppointment(appointment);
    }
  };

  getAppointments() {
    return this.props.appointments || [];
  }

  getButtonCssHash() {
    if (this.props.device.mobileDevice) {
      return BUTTON_CSS_HASH['mobile'];
    } else if (this.props.device.ipadPortrait) {
      return BUTTON_CSS_HASH['ipadPortrait'];
    } else {
      return BUTTON_CSS_HASH['desktop'];
    }
  }

  getStripWidthAndMargin(leftAppointment, rightAppointment) {
    const appointmentIds = this.getAppointments().map((a) => a.id),
      leftAppointmentIndex = appointmentIds.indexOf(leftAppointment.id),
      rightAppointmentIndex = appointmentIds.indexOf(rightAppointment.id),
      difference = rightAppointmentIndex - leftAppointmentIndex,
      buttonCssHash = this.getButtonCssHash();
    return {
      width:
        (difference + 1) * buttonCssHash['width'] +
        difference * buttonCssHash['margin'] +
        'px',
      marginLeft:
        leftAppointmentIndex *
          (buttonCssHash['width'] + buttonCssHash['margin']) +
        'px',
    };
  }

  renderDurationStrip() {
    const selectedRightSideAppointment =
        this.props.caseDetailInfo.selectedRightSideAppointment,
      selectedLeftSideppointment =
        this.props.caseDetailInfo.selectedAppointment;
    if (
      this.isSBSViewTypeSelected() &&
      selectedRightSideAppointment &&
      selectedRightSideAppointment?.id !== selectedLeftSideppointment?.id
    ) {
      const style = this.getStripWidthAndMargin(
        selectedLeftSideppointment,
        selectedRightSideAppointment,
      );
      const { duration } = getTimeLineDetails(
        selectedLeftSideppointment.patient_age,
        selectedRightSideAppointment.patient_age,
      );
      return (
        <div className='appt-blue-hover' style={style}>
          {`${duration}mo`}
        </div>
      );
    }
  }

  render() {
    const appointments = this.getAppointments();
    let className = 'cs-records-btn-groups';
    className += appointments.length > 5 ? ' overflow-x' : '';

    return (
      <div className={className} ref={this.props.setRef}>
        <div className='cs-records-btn-group'>
          {appointments.map(this.renderAppointmentButton)}
          <OrthoIcon
            name='info'
            onClick={this.props.openAppointmentInfoModal}
          />
        </div>
        <div className='cs-records-btn-group count-btn'>
          {appointments.map(this.renderAppointmentIndex)}
        </div>
        {this.renderDurationStrip()}
      </div>
    );
  }
}

CaseAppointmentsButtons = connect(
  ({ caseDetailInfo, device }) => ({ caseDetailInfo, device }),
  {
    openAppointmentInfoModal,
    setCaseDetailValuesToDefault,
    setRightSideAppointment,
    setSelectedAppointment,
  },
)(CaseAppointmentsButtons);

export default CaseAppointmentsButtons;
