import { Command } from 'cmdk';
import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import React from 'react';

const handleWorkspaceNavigation = (identifier) => {
  window.location.pathname = `${identifier}/patient/activity`;
};

const WorkspaceCmdMenu = (props) => {
  const { handleCommandMenu, workspaces } = props;

  return (
    <>
      <Command.List>
        {workspaces.map((workspace) => {
          return (
            <Command.Item
              className={'command-item'}
              key={workspace.id}
              value={workspace.name}
              onSelect={() => {
                handleWorkspaceNavigation(workspace.identifier);
                handleCommandMenu();
              }}>
              <DisplayOption iconRequired={false} label={workspace.name} />
            </Command.Item>
          );
        })}
      </Command.List>
    </>
  );
};

export default WorkspaceCmdMenu;
