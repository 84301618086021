import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M18.567 12.452c.834.147 1.484.943 1.43 1.76-.044.618-.488 1.183-1.083 1.414-.184.094-.39.136-.596.146l-4.582.126h-.01c-.13 0-.26.01-.39.01-.737.021-1.452-.565-1.538-1.288-.076-.722.585-1.655 1.321-1.78.357.052.726.052 1.083.052 1.213-.031 2.437-.084 3.64-.304.249-.052.487-.104.714-.178l.011.042z'
      fill='#FFC933'
    />
    <path
      d='m11.798 10.031 1.289-.647c.39.146.91.125 1.343.083 1.321-.125 2.697-.125 3.942.336.64.22 1.31.68 1.31 1.34.012.419-.27.806-.627 1.037a2.169 2.169 0 0 1-.499.24 3.736 3.736 0 0 1-.715.179c-1.202.21-2.426.262-3.639.293-.357 0-.726 0-1.083-.052a2.947 2.947 0 0 1-.617-.157c-.542-.2-1.287-.692-1.33-1.247-.043-.586.03-1.352.626-1.405zM18.946 15.71c.628.376.986 1.13.877 1.843a1.892 1.892 0 0 1-.91 1.309 2.613 2.613 0 0 1-.497.21c-1.441.376-2.968.46-4.463.261a14.497 14.497 0 0 1-.66-.115c-.77-.147-1.354-.9-1.311-1.665.054-.754.953-1.592 1.744-1.655h.01c1.538-.053 3.055-.084 4.582-.126.206-.01.412-.052.596-.146l.032.083z'
      fill='#FFC933'
    />
    <path
      d='M18.914 18.862c.346.46.4 1.047.227 1.592-.173.544-.628.984-1.18 1.173a7.596 7.596 0 0 1-4.799-.032c-.563-.188-.92-.796-.823-1.361.13-.566 1.018-.911 1.614-.901 1.495.199 3.152-.094 4.603-.471.163-.042.206.084.358 0z'
      fill='#FFC933'
    />
    <path
      d='M13.336 15.908c.13 0 .26-.01.39-.01-.791.063-1.69.9-1.744 1.665-.043.765.541 1.508 1.31 1.665.228.042.444.074.661.105-.596-.01-1.484.335-1.603.9-.108.566.26 1.174.823 1.362-1.484.335-3.52.461-5.015.189-.53-.095-1.061-.23-1.527-.503-.552-.325-1.083-1.005-1.43-1.54-.92-1.382-1.267-2.838-1.19-4.492a7.385 7.385 0 0 1 1.819-4.504c.433-.492.899-.963 1.343-1.435 1.44-1.497 1.473-3.037.985-4.817C7.79 3.163 8.862 2 9.783 2c1.094-.01 2.556.691 2.675 2.147.098 1.131.098.628.098 1.697 0 .806-.098 1.623-.098 2.44.011.24.033.482.141.691.12.2.293.325.488.398l-.542.89c-.596.042-1.126.597-1.083 1.173.032.566.498 1.037 1.04 1.236.195.084.4.126.617.168-.736.136-1.397 1.068-1.321 1.78.086.723.801 1.31 1.538 1.288z'
      fill='#FFC933'
    />
    <path
      d='M18.645 12.43c-.866.252-1.767.322-2.657.463-.445.1-.89.141-1.346.171-.444.04-.9.04-1.38 0-.467-.04-.969-.12-1.437-.382-.444-.252-.764-.705-.81-1.169-.08-.453.149-.967.571-1.218a1.91 1.91 0 0 1 1.357-.262c.012 0 .023.01.023.02s-.011.02-.023.02c-.433-.03-.89.1-1.22.353a1.04 1.04 0 0 0-.41 1.047c.056.373.353.705.73.906.376.202.82.272 1.265.303.878.03 1.78 0 2.669-.05.89-.03 1.79-.101 2.657-.242h.011c.012 0 .023.01.023.02s-.011.02-.023.02z'
      fill='#C2350A'
    />
    <path
      d='M18.966 15.6c-.844.202-1.7.323-2.566.444-.844.15-1.745.191-2.623.16-.24-.01-.388-.02-.696-.07a1.989 1.989 0 0 1-.73-.302c-.422-.282-.718-.685-.798-1.138-.171-.866.467-1.843 1.425-1.903.012 0 .023.01.023.02s-.011.02-.011.02c-.856.191-1.278 1.088-1.084 1.803.092.362.342.685.685.906.342.242.775.252 1.209.272.866.06 1.722.04 2.588.01.856-.01 1.711-.14 2.566-.262.012 0 .023.01.023.02 0 0 0 .01-.011.02z'
      fill='#C2350A'
    />
    <path
      d='M18.977 18.787c-.844.262-1.722.343-2.6.463-.89.111-1.768.091-2.669.02-.228-.01-.444-.06-.673-.1a1.92 1.92 0 0 1-.74-.282c-.434-.282-.754-.695-.81-1.169-.149-.977.855-1.782 1.767-1.692.011 0 .023.01.023.02s-.011.02-.023.02c-.855.061-1.597.837-1.414 1.602.08.373.342.705.684.917.354.221.81.231 1.243.292.867.1 1.734.12 2.612.12.867-.01 1.745-.07 2.589-.251.011 0 .023 0 .034.01 0 .01-.011.02-.023.03z'
      fill='#C2350A'
    />
    <path
      d='M18.802 21.008a2.26 2.26 0 0 1-.957.644 5.546 5.546 0 0 1-1.164.282c-.798.121-1.608.06-2.395-.06a6.536 6.536 0 0 1-1.14-.302c-.48-.172-.833-.534-.924-.957a1.156 1.156 0 0 1 .023-.625c.068-.191.148-.362.262-.523.228-.293.616-.615 1.05-.524.01 0 .022.01.022.02s-.011.02-.023.02c-.387 0-.672.292-.832.584-.16.302-.274.645-.16.957.103.312.377.594.719.705.365.11.73.201 1.106.262a8.638 8.638 0 0 0 2.28.141c.742-.06 1.552-.171 2.11-.655.012-.01.023-.01.035 0 0 .01 0 .02-.012.03z'
      fill='#C2350A'
    />
  </svg>
);

export default SVG;
