import { OPEN_AUTH_MODAL } from 'app/actions/types';

const authentication = (state = { authModal: '' }, action) => {
  switch (action.type) {
    case OPEN_AUTH_MODAL:
      return { authModal: action.authModal, ...action };
    default:
      return state;
  }
};

export default authentication;
