import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { composeValidators } from 'app/utils/validationHelper';
import asyncValidate from './asyncValidate';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import PolicyAcceptCheckbox from 'app/components/PoliciesView/PolicyAcceptCheckbox';

import { emailMustBeValid, fieldRequired } from 'app/utils/validationHelper';

class UserInfo extends Component {
  isOnSignupFullScreen() {
    return this.props.location.pathname === '/signup';
  }

  getHeadingText() {
    return this.isOnSignupFullScreen() ? 'Sign up' : 'Sign up for cloudberry';
  }

  emailExistence(values) {
    let error = asyncValidate({ email: values }, 'email');
    return error;
  }

  render() {
    let { formProps } = this.props,
      formGroupExtraClass = this.props.emailDisabled
        ? ' has-disabled-input'
        : ' ';

    return (
      <>
        {this.props.headingRequired && (
          <div className='text-center form-heading'>
            {this.getHeadingText()}
          </div>
        )}
        <div className='row'>
          <div className='col-12'>
            <Field
              name='email'
              type='email'
              osType='input'
              component={OsField}
              label='Email*'
              validate={composeValidators(
                fieldRequired,
                emailMustBeValid,
                this.emailExistence,
              )}
              disabled={this.props.emailDisabled}
              formGroupExtraClass={formGroupExtraClass}
            />
          </div>
          <div className='col-12'>
            <Field
              name='firstName'
              type='text'
              osType='input'
              component={OsField}
              label='First Name'
              formGroupExtraClass='required-label'
              validate={fieldRequired}
            />
          </div>

          <div className='col-12'>
            <Field
              name='lastName'
              type='text'
              osType='input'
              component={OsField}
              label='Last Name'
              formGroupExtraClass='last-name-signup required-label'
              validate={fieldRequired}
            />
          </div>
          <div className={this.props.extraClass || 'px-0'}>
            <PolicyAcceptCheckbox
              name='policy'
              togglePoliciesAccepted={this.props.togglePoliciesAccepted}
              validate={fieldRequired}>
              {(this.props.showPolicyCheckboxError ||
                (formProps.touched.policy && formProps.errors.policy)) && (
                <div>
                  <span className='form-error'>
                    <OrthoIcon
                      name='error'
                      dataHoverNotRequired='true'
                      defaultClass='me-1 cl-red'
                    />{' '}
                    Must be checked
                  </span>
                </div>
              )}
            </PolicyAcceptCheckbox>
          </div>
        </div>

        {this.props.submitButtonRequired && (
          <div className='text-center'>
            <OsBtn
              name='BtnPrimary'
              htmlTag='button'
              text='sign up'
              extraClass='btn__signup'
              type='submit'
              disabled={this.props.requestInProgress}
              loaderRequired={this.props.requestInProgress}
            />
          </div>
        )}
      </>
    );
  }
}

UserInfo.defaultProps = {
  emailDisabled: false,
  headingRequired: true,
  submitButtonRequired: true,
};
UserInfo = withRouter(UserInfo);

export default UserInfo;
