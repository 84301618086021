import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='m14.681 12.02 7.054-7.226a.893.893 0 0 0-.073-1.335l-1.457-1.214a1.075 1.075 0 0 0-1.443.068l-6.777 6.943L5.238 2.34a1.075 1.075 0 0 0-1.443-.068L2.338 3.486a.893.893 0 0 0-.073 1.335l7.024 7.2-6.98 7.145a.893.893 0 0 0 .073 1.335l1.457 1.213c.423.35 1.064.324 1.443-.067l6.703-6.863 6.733 6.903c.379.391 1.02.418 1.443.067l1.457-1.213a.893.893 0 0 0 .073-1.335l-7.01-7.186z'
      fill='#E51919'
    />
  </svg>
);

export default SVG;
