import { OPEN_PATIENT_MODAL, CLOSE_PATIENT_MODAL } from './types';

export function openPatientModal(careSpaceId, options = {}) {
  return {
    type: OPEN_PATIENT_MODAL,
    careSpaceId,
    options,
  };
}

export function closePatientModal() {
  return {
    type: CLOSE_PATIENT_MODAL,
  };
}
