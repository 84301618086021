import React, { PureComponent } from 'react';

class CaseTag extends PureComponent {
  render() {
    return (
      <span className={`indicator-badge ${this.props.color}`}>
        {this.props.iconName && (
          <i
            className={`icon-indicators me-1 ifill-os-${this.props.iconName}`}></i>
        )}
        <span>{this.props.text}</span>
      </span>
    );
  }
}

export default CaseTag;
