import React, { PureComponent } from 'react';

import { ORTHO_ICON_CLASS_MAPPER } from 'app/components/Shared/OrthoIcon';

import { keys } from 'app/utils/osLodash';
import { humanize } from 'app/utils/stringHelper';

class IconsList extends PureComponent {
  renderIcon = (key) => {
    return (
      <tr key={key}>
        <td>
          <i className={ORTHO_ICON_CLASS_MAPPER[key]}></i>
          <label>{humanize(key)}</label>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <table className='icon-name-table'>
        <tbody>{keys(ORTHO_ICON_CLASS_MAPPER).map(this.renderIcon)}</tbody>
      </table>
    );
  }
}

export default IconsList;
