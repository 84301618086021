import React, { Component } from 'react';

import EmojiBtn from 'app/components/EmojiIconBar/EmojiBtn';
import EmojiUsers from 'app/components/EmojiIconBar/EmojiUsers';
import { isInViewport, isInContainerViewPort } from 'app/utils/domHelper';

class EmojiMobileUserList extends Component {
  state = {
    activeEmoji: null,
  };
  listRef = {};

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.selectActiveEmoji(this.props.activeEmoji);
    }
  }

  selectActiveEmoji = (activeEmoji) => {
    this.setState({ activeEmoji }, () => {
      let tab = this.listRef[activeEmoji];
      if (
        tab &&
        (!isInViewport(tab) || isInContainerViewPort(this.container, tab))
      )
        tab.scrollIntoView({ block: 'end', inline: 'start' });
    });
  };

  renderEmojiBtn = (data) => {
    let active = this.state.activeEmoji === data.emojiName;
    return (
      <li
        onClick={this.selectActiveEmoji.bind(this, data.emojiName)}
        ref={(li) => (this.listRef[data.emojiName] = li)}>
        <EmojiBtn
          emojiName={data.emojiName}
          count={data.users.length}
          active={active}
        />
      </li>
    );
  };

  renderEmojiIconsInTab() {
    return this.props.emojiReactionList.map(this.renderEmojiBtn);
  }

  renderEmojiUserListInfo() {
    if (this.state.activeEmoji) {
      let obj = this.props.emojiReactionList.find(
        (d) => d.emojiName === this.state.activeEmoji,
      ) || { users: [] };
      return <EmojiUsers users={obj.users} />;
    }
  }

  render() {
    let className = 'emoji-m-user-list';
    className += this.props.visible ? ' active' : '';
    return (
      <>
        <div className={className}>
          <ul
            className='emoji-btn-list-group'
            ref={(div) => (this.container = div)}>
            {this.renderEmojiIconsInTab()}
          </ul>
          {this.renderEmojiUserListInfo()}
        </div>
        {this.props.visible && (
          <div
            className='workspace-dropdown-overlay'
            onClick={this.props.onClose}></div>
        )}
      </>
    );
  }
}

export default EmojiMobileUserList;
