import { hexToRgb } from 'app/utils/colorHelper';
import React from 'react';
import Avatar from 'app/components/Shared/Avatar';

const PersonOption = ({ option }) => {
  return (
    <div className={'person-option-container'}>
      <Avatar
        className='avatar avatar-40 partner-space-listing-avatar me-1'
        name={option.large_avatar}
        src={option.large_avatar}
      />
      <span className='result-text-data'>{option.name}</span>
    </div>
  );
};

export default PersonOption;
