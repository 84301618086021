import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import CanvasDraw from 'app/components/Recorder/CanvasDraw';

const UPPER_BOUND_COUNTDOWN_LIMIT = 3;
const BROWSER = ['Chrome', 'Firefox'];

const UserMediaRecorder = (props) => {
  const { onRecordingComplete } = props;

  const webcamRef = useRef(null);
  const mediaRef = useRef(null);
  const canvasStreamRef = useRef(null);
  const isOnlyAudioRequired = useRef(props.isOnlyAudioRecording);
  const recordingStartTime = useRef(new Date().getTime());
  const countdownInterval = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [blob, setBlob] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [duration, setDuration] = useState('');
  const [countdown, setCountdown] = useState(-1);
  // State to hold all the available camera.
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const device = useSelector((state) => state.device);

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  };

  const handlePadding = (timeString) => {
    return timeString.toString().length === 1 ? '0' + timeString : timeString;
  };

  const handleStartRecording = () => {
    setCountdown(UPPER_BOUND_COUNTDOWN_LIMIT);
    countdownInterval.current = setInterval(() => {
      setCountdown((prevState) => prevState - 1);
    }, 1000);
    setTimeout(() => startRecording(), 3200);
  };

  const startRecording = async () => {
    if (countdownInterval.current !== null) {
      clearInterval(countdownInterval.current);
    }
    setIsRecording(true);
    if (isOnlyAudioRequired.current) {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      // create stream from the canvas ref element
      const videoStream = canvasStreamRef.current.captureStream(25);
      videoStream.addTrack(audioStream.getAudioTracks()[0]);
      mediaRef.current = new MediaRecorder(videoStream);
    } else {
      mediaRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: BROWSER.includes(device.browserName)
          ? 'video/webm'
          : 'video/mp4',
      });
    }
    // setting up a data available listener.
    mediaRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRef.current.start();
    recordingStartTime.current = new Date().getTime();
    countdownInterval.current = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = currentTime - recordingStartTime.current;
      const timeString = dayjs.duration(difference);
      setDuration(
        handlePadding(timeString.minutes()) +
          ':' +
          handlePadding(timeString.seconds()),
      );
    }, 1000);
  };

  const handleChangeCamera = async (deviceId) => {
    setSelectedDeviceId(deviceId);
    setIsMenuVisible((prevState) => !prevState);
  };

  const handleStopRecording = () => {
    mediaRef.current.stop();
    setIsRecording(false);
    if (countdownInterval.current !== null)
      clearInterval(countdownInterval.current);
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: BROWSER.includes(device.browserName) ? 'video/webm' : 'video/mp4',
      });
      setBlob(URL.createObjectURL(blob));
      onRecordingComplete(blob);
    }
  }, [recordedChunks]);

  useEffect(() => {
    const getAllMediaDevices = async () => {
      const deviceList = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = deviceList.filter(
        (device) => device.kind === 'videoinput',
      );
      setDevices(videoDevices);
    };
    getAllMediaDevices();
  }, []);

  return (
    <>
      <div>
        {!blob && (
          <div>
            {isOnlyAudioRequired.current ? (
              // draw a canvas to show the user's avatar
              <CanvasDraw ref={canvasStreamRef} isRecording={isRecording} />
            ) : (
              <Webcam
                className={'preview-recorder-height'}
                width={'100%'}
                ref={webcamRef}
                audio={true}
                muted={true}
                videoConstraints={{ deviceId: selectedDeviceId }}
              />
            )}

            <p className={'duration-timer'}>{duration}</p>
            <h1 className={'countdown'}>{countdown > 0 ? countdown : ''}</h1>
          </div>
        )}
        {!isRecording && blob && (
          <div className={'recorded-player-container'}>
            <video
              className={'recorded-video'}
              src={blob}
              width={'100%'}
              height={'500px'}
              autoPlay={true}
              controls={true}
              loop={true}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            marginTop: '10px',
          }}>
          {!isRecording && !blob && (
            <OsBtn name={'BtnPrimary'} onClick={handleStartRecording}>
              Start Recording
            </OsBtn>
          )}
          {isRecording && (
            <OsBtn name={'BtnPrimary'} onClick={handleStopRecording}>
              Stop Recording
            </OsBtn>
          )}
          {!isRecording && !blob && !isOnlyAudioRequired.current && (
            <div className={'media-device-container'}>
              <div
                className={'kebab-menu'}
                style={{
                  display: isMenuVisible ? 'block' : 'none',
                }}>
                <div className={'kebab-menu-heading'}>Select a camera</div>
                {devices.map((device, index) => {
                  return (
                    <div
                      className={'kebab-menu-item'}
                      key={device.deviceId}
                      onClick={() => handleChangeCamera(device.deviceId)}>
                      {device?.label}
                    </div>
                  );
                })}
              </div>
              <div className={'kebab-menu-icon-modified'}>
                <OrthoIcon
                  name={'more'}
                  onClick={() => setIsMenuVisible((prevState) => !prevState)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserMediaRecorder;
