import {
  differenceWith,
  first,
  flattenDeep,
  isEmpty,
  isEqual,
  keys,
  last,
  reverse,
  snakeCase,
} from 'app/utils/osLodash';
import { timeWithFormat } from 'app/utils/timeHelper';
import { APPOINTMENT_DATE_FORMAT, VIEWING_MODES_MAPPER } from './constants';
import { CLASSIC_GRID_VIEW_IMAGES_INFO_HASH } from 'app/components/CaseView/Constant';
import {
  APPOINTMENT_TYPE_MAPPER,
  APPOINTMENT_TYPE_OTHER_ICON,
} from 'app/components/CaseRightSection/constants.js';

export function caseVideosPresent(viewingMode, kase) {
  if (viewingMode === 'SBS') {
    return !!kase.appointments.length;
  } else {
    return kase.videos.some((video) => video.viewing_mode === viewingMode);
  }
}

export function getAppointmentDropdownTitle(
  selectedAppointment,
  appointments,
  dateSuffixRequired = true,
) {
  let title = timeWithFormat(selectedAppointment.date, APPOINTMENT_DATE_FORMAT);

  if (selectedAppointment.label) {
    title = dateSuffixRequired
      ? `${selectedAppointment.label} - ${title}`
      : selectedAppointment.label;
  } else {
    //not using for now
    //title = this.showProgressAppointment(selectedAppointment, appointments, dateSuffixRequired, title)
  }
  return title;
}

export function showProgressAppointment(
  selectedAppointment,
  appointments,
  dateSuffixRequired,
  title,
) {
  let initialAppointment = getInitialAppointment(appointments),
    finalAppointment = getFinalAppointment(appointments);

  if (
    initialAppointment &&
    selectedAppointment.date > initialAppointment.date &&
    finalAppointment &&
    selectedAppointment.date < finalAppointment.date
  ) {
    let initialAppointmentIndex = appointments.findIndex(
        (appointment) => appointment.id === initialAppointment.id,
      ),
      index =
        appointments.findIndex(
          (appointment) => appointment.id === selectedAppointment.id,
        ) - initialAppointmentIndex;
    return dateSuffixRequired
      ? `Progress ${index} -` + title
      : `Progress ${index}`;
  }
}

export function getInitialAppointment(appointments) {
  return appointments.find((appointment) =>
    isInitialAppointment(appointment, appointments),
  );
}

export function getFinalAppointment(appointments) {
  return appointments.find((appointment) =>
    isFinalAppointment(appointment, appointments),
  );
}

export function getAppointmentFilesWithImagesOrVideos(appointments) {
  return (
    (appointments || []).filter(
      (appointment) => appointment.images.length || appointment.videos.length,
    ) || []
  );
}

export function getAppointmentFilesForGalleryView(appointment) {
  return getAppointmentFiles(appointment).filter((file) =>
    keys(CLASSIC_GRID_VIEW_IMAGES_INFO_HASH).includes(snakeCase(file.name)),
  );
}

export function appointmentHintText(selectedAppointment, appointments) {
  let title = '';

  if (false && isInitialAppointment(selectedAppointment, appointments)) {
    title = 'Initial';
  } else if (false && isFinalAppointment(selectedAppointment, appointments)) {
    title = 'Final';
  } else {
    title = '';
  }
  return (
    `${title ? `${title} ` : ''}` +
    'Appointment - ' +
    timeWithFormat(selectedAppointment.date, APPOINTMENT_DATE_FORMAT)
  );
}

export function isInitialAppointment(appointment, appointments) {
  return (
    appointment.category === 'initial' ||
    first(appointments).id === appointment.id
  );
}

export function isFinalAppointment(appointment, appointments) {
  return (
    appointment.category === 'final' ||
    last(getAppointmentFilesWithImagesOrVideos(appointments)).id ===
      appointment.id
  );
}

export function getAppointmentFiles(appointment) {
  return appointment ? appointment.images.concat(appointment.videos) : [];
}

export function isCaseVideo(file) {
  return file && file.__typename === 'CaseVideo';
}

export function getVideoTypeName(type, suffixRequired = true) {
  if (type === 'SBS') {
    return 'Side by side';
  } else {
    return false && suffixRequired ? `${type} - Initial w/ Final` : type;
  }
}

export function allAppointmentFiles(kase) {
  return flattenDeep(
    kase.appointments.map((appointment) => getAppointmentFiles(appointment)),
  );
}

export function filterCaseVideos(viewingMode, kase) {
  return kase.videos.filter((video) => video.viewing_mode === viewingMode);
}

export function getFirstAppointmentWithFile(file, appointments) {
  let appointment, searchedFile;
  appointment = appointments.find(
    (appt) =>
      getAppointmentFiles(appt).findIndex((data) => {
        searchedFile = data;
        return data.name === file.name;
      }) >= 0,
  );
  return { appointment, file: searchedFile };
}

export function getFileIndex(file, appointment) {
  return getAppointmentFiles(appointment).findIndex(
    (data) => data.id === file.id && data.__typename === file.__typename,
  );
}

export function getFileIndexThrougName(file, appointment) {
  file = file || {};
  return getAppointmentFiles(appointment).findIndex(
    (data) => data.name === file.name && data.__typename === file.__typename,
  );
}

export function getSameFileInAppointment(file, appointment) {
  return (
    file &&
    getAppointmentFiles(appointment).find((data) => file.name === data.name)
  );
}

export function getFilesBasedOnRecordType(files, selectedRecordType) {
  switch (selectedRecordType) {
    case 'All Records':
      return files;
    case 'Photos':
      return files.filter(
        (file) =>
          file.__typename === 'CaseImage' &&
          !VIEWING_MODES_MAPPER['X-Ray'].names.includes(file.name),
      );
    default:
      return files.filter((file) =>
        VIEWING_MODES_MAPPER[selectedRecordType].names.includes(file.name),
      );
  }
}

export function getAppointmentThroughFile(file, appointments) {
  return appointments.find((appointment) =>
    getAppointmentFiles(appointment).some(
      (item) => item.__typename === file.__typename && item.id === file.id,
    ),
  );
}

export function getFirstApplianceAddedAppointment(
  appointments,
  appointmentHash,
) {
  return appointments.find((appointment) => {
    let hash = appointmentHash[appointment.id];
    return hash && hash.appliancesHash.addedAppliances.length > 0;
  });
}

export function getLastApplianceRemovedAppointment(
  appointments,
  appointmentHash,
) {
  return reverse([...appointments]).find((appointment) => {
    let hash = appointmentHash[appointment.id];
    return hash && hash.appliancesHash.removedAppliances.length > 0;
  });
}

export function getAppointmentIcon(appointment) {
  return appointment.type === 'other'
    ? APPOINTMENT_TYPE_OTHER_ICON
    : APPOINTMENT_TYPE_MAPPER[appointment.type]?.icon;
}

export function getTimeLineDetails(age1, age2) {
  let defaultAgeParameters = { years: 0, months: 0 };

  age1 = !isEmpty(age1) ? age1 : defaultAgeParameters;
  age2 = !isEmpty(age2) ? age2 : defaultAgeParameters;

  let startMonth = age1.years * 12 + age1.months,
    endMonth = age2.years * 12 + age2.months,
    duration = endMonth - startMonth;

  return { startMonth, duration };
}

export function getAddedAppliancesTillDate(appointment, appointments) {
  let addedAppliancesTillDate = [];
  getPreviousAppointments(appointment, appointments).forEach((app) => {
    addedAppliancesTillDate = [
      ...addedAppliancesTillDate.concat(app.added_appliances),
    ];
  });
  return addedAppliancesTillDate;
}

export function getRemovedAppliancesTillDate(appointment, appointments) {
  let removedAppliancesTillDate = [];
  getPreviousAppointments(appointment, appointments).map((app) => {
    removedAppliancesTillDate = [
      ...removedAppliancesTillDate.concat(app.removed_appliances),
    ];
  });
  return removedAppliancesTillDate;
}

export function getPreviousAppointments(appointment, appointments) {
  return appointments.filter((app) => app.date <= appointment.date);
}

export function isIntitalCategoryAppointment(appointment, appointments) {
  return appointment.category
    ? appointment.category === 'initial'
    : first(appointments).id === appointment.id;
}

export function isFinalCategoryAppointment(appointment, appointments) {
  return appointment.category
    ? appointment.category === 'final'
    : last(getAppointmentFilesWithImagesOrVideos(appointments)).id ===
        appointment.id;
}
