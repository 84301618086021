import { Route, Routes, useLocation } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { useSelector } from 'react-redux';
import RedirectToDefault from 'app/components/RedirectToDefault';
import BillingInfo from 'app/components/WorkspaceView/SettingView/BillingInfo';
import Layout from 'app/components/WorkspaceView/SettingView/Layout';
import MembersView from 'app/components/WorkspaceView/SettingView/MembersView';
import SystemFolders from 'app/components/WorkspaceView/SettingView/SystemFolders';

import { DEFAULT_FILTER } from 'app/components/WorkspaceView/SettingView/tabsHelper';
import { isWorkspaceSettingViewAllowed } from 'app/utils/featureHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import BasicInformation from 'app/components/WorkspaceView/SettingView/BasicInformation';
import CarespaceConfig from 'app/components/WorkspaceView/SettingView/CarespaceConfig';
import LabelsView from 'app/components/WorkspaceView/SettingView/LabelsView';
import TemplatesView from 'app/components/WorkspaceView/SettingView/TemplatesView';
import IntegrationConfig from 'app/components/WorkspaceView/SettingView/IntegrationConfig';

const WorkspaceSettingView = () => {
  const location = useLocation();
  const workspace = useSelector((state) => state.workspace);

  const noFilterPresent = () => {
    return location.pathname === withWorkspaceIdentifier('/settings');
  };

  const getDefaultUrl = () => {
    return `/settings/${DEFAULT_FILTER}`;
  };

  if (!isWorkspaceSettingViewAllowed(workspace.data))
    return <RedirectToDefault />;
  if (noFilterPresent()) return <Navigate to={getDefaultUrl()} />;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='basic-info' element={<BasicInformation />} />
        <Route path='members' element={<MembersView />} />
        <Route path='patientspaces' element={<CarespaceConfig />} />
        <Route path='templates' element={<TemplatesView kind='clinic' />} />
        <Route path='presets' element={<TemplatesView kind={'care'} />} />
        <Route path='system-folders' element={<SystemFolders />} />
        <Route path='billing-info' element={<BillingInfo />} />
        <Route path='labels' element={<LabelsView />} />
        <Route path='integration' element={<IntegrationConfig />} />
      </Route>
      <Route
        path='*'
        element={<Navigate to={getDefaultUrl()} replace={true} />}
      />
    </Routes>
  );
};

export default WorkspaceSettingView;
