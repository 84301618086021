import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import InboxSection from 'app/components/Inbox/InboxSection';
import RecordsView from 'app/components/RecordsView';
import StoreUpdater from 'app/services/StoreUpdater';

const TeamPatientsInboxComponent = (props) => {
  const { workspace } = props;
  const [processedCareSpaceCommentsCount, setProcessedCareSpaceCommentsCount] =
    useState();
  const [
    unprocessedCareSpaceCommentsCount,
    setUnprocessedCareSpaceCommentsCount,
  ] = useState();

  const markReadAndSetUnprocessedCareSpaceCommentsCount = (count) => {
    setUnprocessedCareSpaceCommentsCount(count);
    StoreUpdater.updateEntityThroughWrapper(
      {
        entity: { ...workspace.data, new_care_activities_present: false },
        attributeName: 'new_care_activities_present',
      },
      { customAction: 'assign' },
    );
  };

  const renderDefaultView = () => {
    return (
      <>
        {unprocessedCareSpaceCommentsCount !== 0 &&
          renderFeed({
            title: 'Pending • Patient Comments',
            recordType: 'UnprocessedCareSpaceComments',
            updateCount: markReadAndSetUnprocessedCareSpaceCommentsCount,
            markReadButton: workspace.data.is_care_member,
          })}
        {processedCareSpaceCommentsCount !== 0 &&
          renderFeed({
            title: 'Processed • Patient Comments',
            recordType: 'ProcessedCareSpaceComments',
            markReadButton: false,
            updateCount: setProcessedCareSpaceCommentsCount,
          })}
      </>
    );
  };

  const renderFeed = ({
    title,
    recordType,
    hideSection = false,
    markReadButton = true,
    updateCount,
  }) => {
    return (
      <InboxSection
        title={title}
        type={'team_patient_inbox'}
        hideSection={hideSection}
        markReadButton={markReadButton}
        markReadButtonText='Mark All as Done'
        showPendingFilter={!hideSection}>
        {(showBody) => {
          return (
            <RecordsView
              type={recordType}
              page={0}
              perPage={10}
              updateCount={updateCount}
              queryType={'COMMENTS_ACTIVITY_LISTING'}
              key={workspace?.data?.unprocessed_patient_actionable_items_count}
            />
          );
        }}
      </InboxSection>
    );
  };

  return (
    <>
      <div className='inbox-wrapper'>
        <div className='inbox'>{renderDefaultView()}</div>
      </div>
    </>
  );
};

const TeamPatientsInboxConnect = connect(({ workspace }) => ({ workspace }))(
  TeamPatientsInboxComponent,
);
const TeamPatientsInbox = withRouter(TeamPatientsInboxConnect);

export default TeamPatientsInbox;
