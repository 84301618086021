import React, { Component } from 'react';

import OsGrid from 'app/components/OsGrid';
import RecordsPager from 'app/components/RecordsPager';
import RecordsView from 'app/components/RecordsView';
import { COMMENT_ATTACHMENT_BATCH_SIZE } from 'app/constants';
import source from 'app/reducers/source';

export const CONVERSATIONS_MESSAGE_PER_PAGE_COUNT = 5;

export default class AttachmentsInDiscussion extends Component {
  state = {};
  setCollectionTotal = (count) => {
    this.setState({ count });
    this.props.onContentLoad();
  };

  render() {
    const sourceObject = this.props.widgetSourceObject,
      type = ['exploreCareWorkspace', 'teamGroupMessenger'].includes(
        this.props.source,
      )
        ? 'AttachmentsInCareDiscussions'
        : 'AttachmentsInDiscussions';
    if (sourceObject && (sourceObject.id || sourceObject.nice_id)) {
      return (
        <OsGrid identifier='DetailRightSidebar:CaseDocumentsList'>
          <div
            className='related-cards-block case-documents col-12'
            ref={(el) => (this.parentContainer = el)}>
            {sourceObject.__typename === 'Conversation' ? (
              <RecordsView
                type='ConversationMessagesHavingAttachments'
                queryType='CONVERSATION_ATTACHMENTS'
                conversation={sourceObject}
                key={sourceObject.id}
                idQuery={sourceObject.id?.toString()}
                perPage={CONVERSATIONS_MESSAGE_PER_PAGE_COUNT}
                isReverse={true}
                loaderNotRequired={true}
                setCollectionTotal={this.setCollectionTotal}
                threshold={1}
                size='full-view-listing'
                className='on-demand-content-container'
                trackScrolling={false}
                parentContainer={this.parentContainer}
              />
            ) : (
              <RecordsPager
                limit={COMMENT_ATTACHMENT_BATCH_SIZE}
                queryType='COMMENTS'
                recordId={sourceObject.nice_id}
                recordType='Board'
                type={type}
                nextPageLoadRequired={false}
                setCollectionTotal={this.setCollectionTotal}
                collectionTotal={this.state.count}
                infiniteScroll={false}
                showMoreNotRequired={true}
              />
            )}
          </div>
        </OsGrid>
      );
    } else {
      return <></>;
    }
  }
}

AttachmentsInDiscussion.defaultProps = {
  onContentLoad: () => {},
};
