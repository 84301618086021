import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';

import Avatar from 'app/components/Shared/Avatar';
import {
  avatarAttributes,
  entityUrl,
  isPinnedSupported,
} from 'app/utils/entitiesHelper';
import ParseText from 'app/components/ParseText';
import OsCards from 'app/components/OsCards';
import { orderBy, take } from 'app/utils/osLodash';

import 'app/components/MessageBlock/chat-message-block.scss';
import { timestamp, timestampOnHover } from 'app/utils/timeHelper';
import { useEmoji } from 'app/hooks/useEmoji';
import { pluralizeWithNumber } from 'app/utils/generalHelper';

import EmojiWrapper, {
  EmojiPicker,
} from 'app/components/EmojiIconBar/EmojiWrapper';
import withAuthenticate from 'app/components/HOC/withAuthenticate';
import EmojiWithUserList from 'app/components/EmojiIconBar/EmojiWithUserList';
import EmojiIconMobileList from 'app/components/EmojiIconBar/MobileUserList';
import EmojiUsers from 'app/components/EmojiIconBar/EmojiUsers';
import OrthoIcon from '../Shared/OrthoIcon';
import PinConversationMessage from '../Shared/PinConversationMessage';
import { openTaskModalWithEntity } from 'app/actions/taskModal';
import { PinnedMessageContext } from 'app/context/PinnedMessageContext';
import { isInViewport } from 'app/utils/domHelper';
import ActionCable from 'app/actioncable/actioncable';
import Emitter from 'app/services/Emitter';
import RecordsPager from 'app/components/RecordsPager';
import { REPLIES_BATCH_SIZE } from 'app/components/CommentSection/constants';
import useOnScreen from 'app/components/Task/useOnScreen';

const ALLOWED_ACTIONS = {
  reply: ['emojiPicker'],
  task: ['emoji'],
  group: ['emojiPicker', 'createTask', 'pinMessage', 'groupCommentReply'],
  dm: ['emojiPicker', 'createTask', 'pinMessage'],
};

// type - comment/message
const MessageBlock = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const device = useSelector((state) => state.device);
  const context = useContext(PinnedMessageContext);
  const dispatch = useDispatch();
  const [activeEmoji, setActiveEmoji] = useState(null);
  const [alreadyMarkedAsViewed, setAlreadyMarkedAsViewed] = useState(false);
  const [showReply, setShowReply] = useState({});
  const seenContainerRef = useRef(null);
  const isOnScreen = useOnScreen(seenContainerRef);
  const doNotViewComment = props.doNotViewComment;

  useEffect(() => {
    if (isOnScreen && !doNotViewComment) {
      setTimeout(() => {
        if (isComment()) {
          markCommentAsViewed();
        } else {
          markMessageAsViewed();
        }
      }, 1000);
    }
  }, [isOnScreen]);

  const isComment = () => {
    return props.type === 'comment';
  };

  const openReply = (parent_id) => {
    setShowReply((prev) => ({
      ...prev,
      [parent_id]: !showReply[parent_id],
    }));
  };

  const notAlreadyViewed = () => {
    if (isComment()) {
      return (
        !props.entity.viewed_by.some(
          (s) => s.user.id.toString() === props.currentUser.graph.id.toString(),
        ) && !isOwnMessage()
      );
    } else {
      return (
        !props.entity.viewed_by_user_ids.includes(
          +props.currentUser.graph.id,
        ) && !isOwnMessage()
      );
    }
  };

  const markMessageAsViewed = () => {
    if (
      !alreadyMarkedAsViewed &&
      seenContainerRef.current &&
      isInViewport(seenContainerRef.current) &&
      notAlreadyViewed() &&
      ActionCable?.conversationChannel
    ) {
      setAlreadyMarkedAsViewed(true);
      ActionCable?.conversationChannel?.viewConversationMessage(
        props.entity.id,
      );
    }
  };

  const markCommentAsViewed = () => {
    if (
      !alreadyMarkedAsViewed &&
      seenContainerRef.current &&
      isInViewport(seenContainerRef.current) &&
      notAlreadyViewed() &&
      ActionCable?.notifications
    ) {
      setAlreadyMarkedAsViewed(true);
      ActionCable?.notifications?.viewComment(props.entity.id);
    }
  };

  const initializeEmojiHook = {
    entityId: props.entity.id,
    entityType: props.entity.__typename,
    emojiReactionDetail: props.entity.emoji_reaction_detail,
  };
  const { addEmoji, removeEmoji, getModifiedEmojiReactionDetail } =
    useEmoji(initializeEmojiHook);

  const isMobileOrIpad = () => {
    const { ipad, mobileDevice } = device;
    return ipad || mobileDevice;
  };

  const getContainerId = () => {
    return isComment()
      ? `comment-${props.entity.id}`
      : `conversation-message-${props.entity.id}`;
  };

  const renderAvatar = () => {
    let { author } = props.entity;
    return (
      <div className='msg-avatar'>
        {!props.previousMessageIsSameAsAuthor && (
          <Link to={entityUrl(author)}>
            <Avatar
              className='avatar avatar-40'
              {...avatarAttributes(author)}
            />
          </Link>
        )}
      </div>
    );
  };

  // -- EMOJI PICKER --
  const renderMobileUserList = () => {
    if (isMobileOrIpad())
      return (
        <EmojiIconMobileList
          visible={!!activeEmoji}
          onClose={() => setActiveEmoji(null)}
          emojiReactionList={getModifiedEmojiReactionDetail().list}
          activeEmoji={activeEmoji}
        />
      );
  };

  const isCurrentUserEmojiSelectionPresent = () => {
    return getModifiedEmojiReactionDetail()?.list?.some((r) => r.active);
  };

  const addEmojiIfAllowed = (name) => {
    if (!isCurrentUserEmojiSelectionPresent())
      props.redirectToDefaultIfUnauthenticated(addEmoji.bind(this, name));
  };

  const removeEmojiIfAllowed = (name) => {
    props.redirectToDefaultIfUnauthenticated(removeEmoji.bind(this, name));
  };

  const renderEmojiButtonIfRequired = () => {
    let selectedReactions = props.entity.emojiReactionDetail?.list
        ?.filter((r) => r.active)
        ?.map((r) => r.emojiName),
      mobileDeviceOrIpad = isMobileOrIpad();
    if (!isCurrentUserEmojiSelectionPresent()) {
      return (
        <EmojiWrapper
          mobileDeviceOrIpad={mobileDeviceOrIpad}
          entityType={'Comment'}
          entityId={props.entity.id}
          selectedReactions={selectedReactions}
          addEmoji={addEmojiIfAllowed}
          removeEmoji={removeEmojiIfAllowed}
        />
      );
    } else {
      return <div className='blank-emoji-holder osbtn d-none'></div>;
    }
  };

  function renderEmojiPicker() {
    return (
      <>
        <div className='emoji-bar'>
          <ul className='list-unstyled emoji-btns-group'>
            {renderEmojiButtonIfRequired()}
          </ul>
          {renderMobileUserList()}
        </div>
        <div className='d-none'>
          <EmojiPicker set='native' previewPosition='none' />
        </div>
      </>
    );
  }

  const isSourceMessenger = () => {
    return props.entityType !== 'Comment';
  };

  const getEmojiVisibleLimit = () => {
    return isSourceMessenger()
      ? 2
      : isCurrentUserEmojiSelectionPresent()
      ? 4
      : 3;
  };

  const renderEmojiWithUserList = (data) => {
    const { emojiReactionUsers } = getModifiedEmojiReactionDetail();
    return (
      <EmojiWithUserList
        {...data}
        key={data.emojiName}
        users={emojiReactionUsers}
        removeEmoji={removeEmojiIfAllowed}
        addEmoji={addEmojiIfAllowed}
        setActiveEmoji={setActiveEmoji}
        userlistClass={'emoji-user-listing'}
        popoverClass={`messanger-popover ${
          props.isOwn ? 'with-own-message' : ' '
        }`}
        isSourceMessenger={isSourceMessenger()}
      />
    );
  };

  const renderPopover = () => {
    let className = props.popoverClass,
      users = getModifiedEmojiReactionDetail().emojiReactionUsers;

    className += users.length > 1 ? ' with-multiple-user' : ' with-single-user';

    return (
      <Popover className={className}>
        <Popover.Header>Reactios</Popover.Header>
        <Popover.Body>
          <EmojiUsers
            users={users}
            source={'feed'}
            userlistClass={props.userlistClass}
          />
        </Popover.Body>
      </Popover>
    );
  };
  const getUserName = (user) => {
    let currentUser = props.currentUser.graph;
    return +currentUser.id === +user.id ? 'You' : user.name;
  };

  const getEmojiListText = () => {
    const { emojiReactionUsers } = getModifiedEmojiReactionDetail(),
      total = emojiReactionUsers.length;
    if (total > 0) {
      let text = getUserName(emojiReactionUsers[0].user);
      text +=
        total > 1
          ? ` and ${pluralizeWithNumber(total - 1, {
              singular: 'other',
              plural: 'others',
            })}`
          : '';
      return text;
    }
  };
  const renderEmojiCountDetails = (emojiList) => {
    if (isSourceMessenger()) {
      let count = emojiList.length - 2;
      return (
        <>{count > 0 && <span className='emoji-counter'>+{count}</span>}</>
      );
    } else {
      let text = getEmojiListText();
      return <>{text && <span className='emoji-counter'>{text}</span>}</>;
    }
  };
  const renderEmojiCountText = (emojiList) => {
    if (isSourceMessenger()) {
      return renderEmojiCountDetails(emojiList);
    } else {
      return (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          delay='250'
          placement={'auto'}
          overlay={renderPopover()}>
          <span>{renderEmojiCountDetails(emojiList)}</span>
        </OverlayTrigger>
      );
    }
  };

  const renderSelectedEmojis = () => {
    const modifiedEmojiReactionDetail = getModifiedEmojiReactionDetail();
    if (modifiedEmojiReactionDetail) {
      const { list: emojiList } = modifiedEmojiReactionDetail;
      const list = take(emojiList, getEmojiVisibleLimit());
      const selectedEmojis = (
        <>
          {list.map(renderEmojiWithUserList)}
          {renderEmojiCountText(emojiList)}
        </>
      );
      return selectedEmojis;
    }
  };
  // -- EMOJI PICKER END --

  const renderOsCard = (object) => {
    let fileAsset;
    if (object.__typename === 'MessageFile') {
      fileAsset = { ...object.file_asset, author: props.entity.author };
    }
    let wrapper = 'attachment-wrapper';
    wrapper +=
      props.entity.emoji_reaction_detail.emoji_reaction_users.length > 0
        ? ' have-emoji'
        : '';

    let fileClass = 'm-files ';
    fileClass += !props.previousMessageIsSameAsAuthor ? 'have-author ' : '';

    return (
      <div key={`${object.__typename}:${object.id}`} className={wrapper}>
        <div className='creator'>{renderMessageCreatorInfo()}</div>
        <div className='action-wrapper'>
          <div className={fileClass}>
            <OsCards
              size={'extra-small'}
              obj={object.shareable || fileAsset || object}
              source='Conversation'
              urlPushState={{ source: 'Conversation' }}
            />
            <span className='user-emoji-list'>{renderSelectedEmojis()}</span>
          </div>
          {renderActions()}
        </div>
      </div>
    );
  };

  const renderList = (objects) => {
    return objects?.map(renderOsCard);
  };

  const sortAndGetFiles = () => {
    return orderBy(props.entity.files, 'position');
  };

  const isOwnMessage = () => {
    return +props.entity.author.id === +currentUser.graph.id;
  };

  const renderPinnedStatus = () => {
    if (isPinned()) {
      return (
        <PinConversationMessage
          obj={props.entity}
          extraClass='conversation-pin'
          updatePin={updatePinnedStatus}
          pinnedStatus={isPinned()}
        />
      );
    }
  };

  const isMessageViewed = () => {
    if (isComment()) {
      return props.entity.viewed_by.some(
        (s) => s.user.id.toString() !== props.currentUser.graph.id.toString(),
      );
    } else {
      return props.entity.viewed_by_user_ids.some(
        (user_id) =>
          user_id.toString() !== props.currentUser.graph.id.toString(),
      );
    }
  };

  const renderMessageCreatorInfo = () => {
    const { entity } = props;
    const iconName = isMessageViewed() ? 'doubleTick' : 'tick';
    return (
      <div className='creator-info' title={timestampOnHover(entity.created_at)}>
        {isPinnedSupported(entity) && renderPinnedStatus()}{' '}
        {entity.author?.name} • {timestamp(entity.created_at)}
        {isOwnMessage() && (
          <OrthoIcon
            name={iconName}
            dataHoverNotRequired={true}
            defaultClass='ms-1 v-align-middle fs-14'
          />
        )}
      </div>
    );
  };

  const isPinned = () => {
    return context.pinnedMessages?.some((p) => p.id === props.entity.id);
  };

  const updatePinnedStatus = (isPinned) => {
    const {
      author,
      content,
      created_at,
      emoji_reaction_detail,
      files,
      id,
      mentionees,
      message_links,
      pinnable,
      pinned,
      __typename,
    } = props.entity;

    const message = {
      author,
      content,
      created_at,
      emoji_reaction_detail,
      files,
      id,
      mentionees,
      message_links,
      pinnable,
      pinned,
      __typename,
    };
    const { pinnedMessages, pinnedMessageCount } = context;
    if (isPinned) {
      context.setPinnedMessages(
        [...pinnedMessages, message],
        pinnedMessageCount + 1,
      );
    } else {
      context.setPinnedMessages(
        pinnedMessages.filter((p) => p.id !== props.entity.id),
        pinnedMessageCount - 1,
      );
    }
  };

  const renderMessageActions = (action) => {
    switch (action) {
      case 'emojiPicker':
        return <>{renderEmojiPicker()}</>;
      case 'createTask':
        return (
          <div className='icon add-emoji-btn'>
            <OrthoIcon
              onClick={() =>
                dispatch(
                  openTaskModalWithEntity({
                    ...props.entity,
                  }),
                )
              }
              name='task'
              dataHoverNotRequired={true}
            />
          </div>
        );
      case 'pinMessage':
        let pinClass = 'icon add-emoji-btn';
        pinClass += isPinned() ? ' pin-active' : '';
        return (
          <>
            {props.entity.pinnable && (
              <div className={pinClass}>
                <PinConversationMessage
                  obj={props.entity}
                  extraClass='conversation-pin'
                  updatePin={updatePinnedStatus}
                  pinnedStatus={isPinned()}
                />
              </div>
            )}
          </>
        );
      case 'groupCommentReply':
        return (
          <div className='icon add-emoji-btn'>
            <OrthoIcon
              onClick={replyToGroupComment}
              name='reply-fill-left'
              dataHoverNotRequired={true}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const replyToGroupComment = () => {
    const {
      id,
      author,
      mentionees,
      mention_groups,
      commentable_id,
      commentable_type,
      commentableEntityOwner,
      parent_id,
    } = props.entity;
    const data = {
      mentionees,
      author,
      replyingToCommentId: id,
      mentionGroups: mention_groups,
      parentId: id,
      entity: props.space,
      message: props.entity,
      setScrollToCommentId: id,
      commentableId: commentable_id,
      commentableType: commentable_type,
      commentableEntityOwner: commentableEntityOwner,
    };

    if (parent_id) {
      const parentData = props.parentResults.find(
        (s) => s.id.toString() === parent_id.toString(),
      );
      if (parentData) {
        data.parentId = parentData.id;
        data.replyingToCommentId = parentData.id;
        data.message = parentData;
        data.mentionGroups = parentData.mention_groups;
      }
    }

    Emitter.emit('REPLY_TEAMSPACE', data);
  };

  const renderActions = () => {
    const messageActions = ALLOWED_ACTIONS[props.actionType] || [];

    return (
      <div className='m-actions'>
        {messageActions.map((action) => (
          <div key={action}>{renderMessageActions(action)}</div>
        ))}
      </div>
    );
  };

  const renderReplies = (parent_id) => {
    const { commentableEntityOwner, commentable_id, commentable_type } =
      props.entity;
    return (
      <div>
        <CSSTransition
          in={true}
          classNames='replies-fade'
          timeout={{ enter: 500, exit: 200 }}
          unmountOnExit>
          <RecordsPager
            commentableEntityOwner={commentableEntityOwner}
            commentableId={commentable_id}
            commentableType={commentable_type}
            entity={props.entity}
            infiniteScroll={false}
            limit={REPLIES_BATCH_SIZE}
            openParentReply={openReply}
            queryType='SPACE_MESSAGES'
            readOnly={props.readOnly}
            recordId={parent_id}
            recordType='Comment'
            type='Replies'
            fetchPolicy={'cache-and-network'}
            parentEntity={props.entity}
            withRecord={true}
          />
        </CSSTransition>
      </div>
    );
  };

  const renderReplyContainer = () => {
    const { replies_count, id } = props.entity;
    if (replies_count > 0) {
      return (
        <>
          <div className='reply-info' onClick={() => openReply(id)}>
            <OrthoIcon name='reply-fill-left' dataHoverNotRequired={true} />{' '}
            {`${pluralizeWithNumber(replies_count, {
              singular: 'reply',
              plural: 'replies',
            })}`}
          </div>
        </>
      );
    }
  };

  const isSubReply = () => {
    return !!props.isSubReply;
  };

  const isParentNotRequired = () => {
    return !!props.isParentNotRequired;
  };

  const renderBlockDescription = () => {
    let contentBlockClass = 'content-block';
    contentBlockClass +=
      props.entity.emoji_reaction_detail.emoji_reaction_users.length > 0
        ? ' have-emoji'
        : '';
    let contentDescClass = 'description ';
    contentDescClass += !props.previousMessageIsSameAsAuthor
      ? 'have-author '
      : '';
    let haveParent = false;
    let parentData = {};

    const { parent_id, replies_count, id } = props.entity;
    let parentClass = 'parent-msg-box ';
    if (!isParentNotRequired() && !isSubReply() && parent_id) {
      haveParent = true;
      parentData = props.parentResults.find(
        (s) => s.id.toString() === parent_id.toString(),
      );
      parentClass += isOwnMessage() ? 'own ' : '';
    }
    return (
      <>
        {props.entity.content && (
          <>
            <div className={contentBlockClass}>
              <div className='creator'>{renderMessageCreatorInfo()}</div>
              <div className='action-wrapper'>
                <div className={contentDescClass}>
                  {haveParent && (
                    <>
                      <div className={parentClass}>
                        <div className='c-author'>{parentData.author.name}</div>
                        <div className='c-text'>
                          {' '}
                          <ParseText
                            obj={parentData}
                            className='conversation-message-text'
                            linkClassName={isOwnMessage() ? 'white-link' : ''}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <ParseText obj={props.entity} className='' />
                  <span className='user-emoji-list'>
                    {renderSelectedEmojis()}
                  </span>
                </div>

                {renderActions()}
              </div>
              {renderReplyContainer()}
            </div>
            {replies_count > 0 && showReply[id] && <>{renderReplies(id)}</>}
          </>
        )}
      </>
    );
  };

  let messageRowClass = 'message-row ';
  messageRowClass += isOwnMessage() ? 'own-message' : '';

  return (
    <div
      id={getContainerId()}
      ref={seenContainerRef}
      className={'chat-message-block'}
      data-created={props.entity.created_at}>
      <div className={messageRowClass}>
        <div className='row-content'>
          <div className='avatar-container'>{renderAvatar()}</div>
          <div className='block-container'>
            <div className='main-message'>{renderBlockDescription()}</div>
            <div className='block-attachments'>
              {renderList(sortAndGetFiles())}
              {renderList(props.entity.links)}
              {renderList(props.entity.message_links)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticate(MessageBlock);
