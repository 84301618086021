import ActionCable from 'app/actioncable/actioncable';

import { isUndefined } from 'app/utils/osLodash';

class UserAppearanceChannel {
  setup() {
    if (
      !isUndefined(ActionCable.appearance) ||
      process.env.REACT_APP_ONLINE_TRACKING_NOT_REQUIRED
    )
      return;

    ActionCable.appearance = ActionCable.consumer.subscriptions.create(
      { channel: 'AppearanceChannel' },
      {
        initialized() {
          this.update = this.update.bind(this);
        },
        connected() {
          this.install();
          this.update();
        },
        disconnected() {
          this.uninstall();
        },
        rejected() {
          this.uninstall();
        },
        update() {
          this.documentIsActive() && this.appear();
        },
        appear() {
          this.perform('appear');
        },
        install() {
          window.addEventListener('focus', this.update);
          window.addEventListener('blur', this.update);
          document.addEventListener('visibilitychange', this.update);
          this.interval = window.setInterval(this.update, 60000);
        },
        uninstall() {
          window.removeEventListener('focus', this.update);
          window.removeEventListener('blur', this.update);
          document.removeEventListener('visibilitychange', this.update);
          window.clearInterval(this.interval);
        },
        documentIsActive() {
          return document.visibilityState === 'visible' && document.hasFocus();
        },
      },
    );
  }

  delete() {
    if (ActionCable.appearance) {
      ActionCable.consumer.subscriptions.remove(ActionCable.appearance);
      delete ActionCable.appearance;
    }
  }
}

export default new UserAppearanceChannel();
