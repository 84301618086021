import OrthoIcon from 'app/components/Shared/OrthoIcon';
import React, { useEffect, useRef } from 'react';
import 'app/components/Shared/RenderDropdown/renderDropdown.scss';
function RenderDropdown(props) {
  const {
    selectedOption,
    options,
    closeModal,
    isLeftIconRequired,
    leftIcon,
    isRightIconRequired,
    rightIcon,
    changeFeedFilter,
  } = props;
  const dropdownRef = useRef(null);

  const clickListener = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))
      closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', clickListener);
    }, 100);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, []);

  return (
    <div className='dropdown-container' ref={dropdownRef}>
      {options.map((option) => {
        return (
          <div
            key={option.label}
            className='dropdown-option'
            style={{
              background: `${option.value === selectedOption ? '#edeff3' : ''}`,
            }}
            onClick={() => changeFeedFilter(option)}>
            {isLeftIconRequired && <OrthoIcon name={leftIcon} />}
            <span className='option-label'>{option.label}</span>
            {isRightIconRequired && <OrthoIcon name={rightIcon} />}
          </div>
        );
      })}
    </div>
  );
}

RenderDropdown.defaultProps = {
  options: [],
  isLeftIconRequired: false,
  isRightIconRequired: false,
  leftIcon: '',
  rightIcon: '',
};

export default RenderDropdown;
