import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { translate } from 'app/actions/flashMessage';
import { getIconClass } from 'app/utils/entitiesHelper';

class UnavailableCard extends Component {
  afterClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  render() {
    return (
      <div className={this.props.containerClasses}>
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            onClick={this.afterClose}
            defaultClass='os-close-link'
          />
        )}

        <div className='m-auto text-center white-spc-normal'>
          <div className='mb-2'>
            <i className={getIconClass('unavailable')} />
          </div>
          {translate('UNAVAILABLE_CARD')}
        </div>
      </div>
    );
  }
}

export default UnavailableCard;
