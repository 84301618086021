import React from 'react';
import { graphql } from "@apollo/client/react/hoc";
import * as compose from 'lodash.flowright';
import { connect } from "react-redux";
import { loader as queryLoader } from 'graphql.macro';
import OrthoIcon from "app/components/Shared/OrthoIcon";
import { ceil, now } from 'app/utils/osLodash';
import { flashError } from 'app/actions/flashMessage';

const PIN_OBJECT_MUTATION = queryLoader("app/graphql/mutations/PinObject.gql");
const UNPIN_OBJECT_MUTATION = queryLoader("app/graphql/mutations/UnpinObject.gql");

const PinConversationMessageWrapper = (props) => {

  const getOptimisticResponseProps = (pinned) => {
    let data = {
      __typename: 'Response',
      success: true,
      error: '',
      entity: {
        ...props.obj,
        pinned,
        pinned_at: pinned ? ceil(now()/1000) : null
      }
    }
    return data;
  }

  const handlePinObject = () => {
    props.pinObjectMutation({
      variables: { entityId: props.obj.id, entityType: props.obj.__typename },
      optimisticResponse: () => {
        return { pinObject: getOptimisticResponseProps(true) };
      },
    }).then(({ data }) => {
      props.updatePin(true);
      if(!data.pinObject.success) {
        // Open general modal here.
        let error = data.pinObject.error;
        props.flashError(error || 'CREATE_ERROR', !error.length);
      }
    });
  };

  const handleUnpinObject = () => {
    props.unpinObjectMutation({
      variables: { entityId: props.obj.id, entityType: props.obj.__typename },
      optimisticResponse: () => {
        return { unpinObject: getOptimisticResponseProps(false) };
      },
    }).then(({ data }) => {
      props.updatePin(false);
      if(!data.unpinObject.success) {
        // Open general modal here.
        let error = data.unpinObject.error;
        props.flashError(error || 'CREATE_ERROR', !error.length);
      }
    });
  };

  let {pinnedStatus} = props,
      extraClass = props.extraClass || '';
      extraClass += pinnedStatus ? ' active ' : '';
  if(props.pinnedStatus)
    return <OrthoIcon name="pin" defaultClass={extraClass} dataHoverNotRequired={true} onClick={handleUnpinObject} associatedEntity={props.obj}/>
  else
    return <OrthoIcon name="pin" defaultClass={extraClass} dataHoverNotRequired={true} onClick={handlePinObject} associatedEntity={props.obj}/>
}

const PinConversationMessageConnect = connect(({ currentUser }) => ({ currentUser }), { flashError })(PinConversationMessageWrapper)
const PinConversationMessage = compose(
  graphql(PIN_OBJECT_MUTATION, { name: "pinObjectMutation" }),
  graphql(UNPIN_OBJECT_MUTATION, { name: "unpinObjectMutation" })
)(PinConversationMessageConnect)
export default PinConversationMessage;
