import { Field, Form } from 'react-final-form';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import OsField from 'app/components/OsField';
import OsBtn from 'app/components/OsBtn';
import Loader from 'react-loaders';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const WORKSPACE_SETTING_QUERY = queryLoader(
  'app/graphql/queries/Workspace/Configuration.gql',
);
const UPDATE_CARE_SPACE_CONFIGURATION_MUTATION = queryLoader(
  'app/graphql/mutations/Workspace/UpdateCareSpaceConfiguration.gql',
);

const TIPTAP_EXTENSION_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'CustomSubmit',
  'Underline',
  'Link',
  'NewHashtag',
];

const CareSpaceConfig = () => {
  const [files, setFiles] = useState([]);
  const [objects, setObjects] = useState([]);
  const [updateCareSpaceConfiguration] = useMutation(
    UPDATE_CARE_SPACE_CONFIGURATION_MUTATION,
  );
  const workspace = useSelector((state) => state.workspace);
  const [submitting, setSubmitting] = useState(false);
  const [editorContent, setEditorContent] = useState(() => '');
  const { data: configurationData, isLoading } = useQuery(
    WORKSPACE_SETTING_QUERY,
    {
      variables: { id: workspace.identifier },
      fetchPolicy: 'cache-and-network',
    },
  );

  const initialValues = () => {
    const workspaceSetting = configurationData?.workspace?.workspace_setting;
    return {
      welcome_message_enabled:
        workspaceSetting?.welcome_message_enabled || false,
      sms_notification_for_patient:
        workspaceSetting?.sms_notification_for_patient || false,
      auto_reshare_space_enabled:
        workspaceSetting?.auto_reshare_space_enabled || false,
      patient_welcome_message: workspaceSetting?.patient_welcome_message || '',
      welcome_message_author_id:
        workspaceSetting?.welcome_message_author_id || '',
    };
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    updateCareSpaceConfiguration({
      variables: {
        welcome_message_enabled: values.welcome_message_enabled,
        sms_notification_for_patient: values.sms_notification_for_patient,
        auto_reshare_space_enabled: values.auto_reshare_space_enabled,
        patient_welcome_message: editorContent,
        welcome_message_author_id: String(values.welcome_message_author_id),
        files: files.map((file) => file.id),
        links: objects.map((object) => `${object.__typename}:${object.id}`),
      },
    }).then((response) => {
      setSubmitting(false);
    });
  };

  const updateEditorContent = (content) => {
    setEditorContent(content);
  };

  useEffect(() => {
    if (configurationData) {
      setEditorContent(
        configurationData.workspace.workspace_setting?.patient_welcome_message,
      );
      setFiles(
        configurationData.workspace.workspace_setting?.files.map(
          (f) => f.file_asset,
        ) || [],
      );
      setObjects(configurationData.workspace.workspace_setting?.links || []);
    }
  }, [configurationData]);

  if (isLoading) return <Loader type='ball-triangle-path' active />;

  return (
    <>
      <SetPageTitle
        source='worksapce_settings'
        interpolations={'Patient Spaces'}
      />
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={'form-care-space-container'}>
                <div className={'welcome-message'}>
                  <h2>Welcome Message for Patients</h2>
                  <p>
                    This is an automated message which is sent on the Doctor’s
                    behalf to the Patient and their family members as soon as
                    the PatientSpace is shared with them.
                  </p>
                  <p>Would you like to enable this default message?</p>
                  <span className={'toggle-field'}>
                    NO{' '}
                    <span className='notify-prefer-value'>
                      <label
                        className='switch'
                        data-hover={!isTouchSupported()}>
                        <Field
                          name={'welcome_message_enabled'}
                          component='input'
                          type='checkbox'
                        />
                        <span className='slider round'></span>
                        <span className='cs-slider-after'></span>
                      </label>
                    </span>{' '}
                    YES
                  </span>
                </div>
                {/* Enable tip-tap editor */}
                <div>
                  <OsField
                    name={'patient_welcome_message'}
                    osType={'tiptap-editor'}
                    textType={'html'}
                    dragAndDrop={true}
                    files={files}
                    handleFiles={setFiles}
                    extensions={TIPTAP_EXTENSION_REQUIRED}
                    objects={objects}
                    handleObjects={setObjects}
                    updateContent={updateEditorContent}
                    initialContent={values.patient_welcome_message}
                    placeholder={'Write a message...'}
                  />
                </div>

                {/* Primary doctor */}
                <div className={'form-field field-2'}>
                  <Field
                    osType='selectInput'
                    component={OsField}
                    label='Primary doctor'
                    name='welcome_message_author_id'
                    async={true}
                    queryType='WorkspaceOrthodontists'
                    placeholder='Select'
                    className='react-select-ortho-2 os-menu-drop'
                    onChange={(field, val) => form.change(field, val)}
                  />
                </div>

                <div className={'patient-notification'}>
                  <h2>Notifications for Patients</h2>
                  <p>
                    Would you like to send an SMS notification to patients as
                    soon as there is new activity in their PatientSpace?
                  </p>
                  <span className={'toggle-field'}>
                    NO{' '}
                    <span className='notify-prefer-value'>
                      <label
                        className='switch'
                        data-hover={!isTouchSupported()}>
                        <Field
                          name={'sms_notification_for_patient'}
                          component='input'
                          type='checkbox'
                        />
                        <span className='slider round'></span>
                        <span className='cs-slider-after'></span>
                      </label>
                    </span>{' '}
                    YES
                  </span>
                </div>
                <div className={'patient-notification'}>
                  <h2>Access Reminder for Patients</h2>
                  <p>
                    This will automatically remind patients to access their
                    spaces if they have been shared with them. This will send a
                    notification to them after 1 week, 2 weeks, 4 weeks and 12
                    weeks of the initial invite if not accepted.
                  </p>
                  <span className={'toggle-field'}>
                    NO{' '}
                    <span className='notify-prefer-value'>
                      <label
                        className='switch'
                        data-hover={!isTouchSupported()}>
                        <Field
                          name={'auto_reshare_space_enabled'}
                          component='input'
                          type='checkbox'
                        />
                        <span className='slider round'></span>
                        <span className='cs-slider-after'></span>
                      </label>
                    </span>{' '}
                    YES
                  </span>
                </div>

                <OsBtn
                  extraClass='m-auto'
                  type='submit'
                  disabled={submitting}
                  name='BtnPrimary'
                  value={submitting ? 'Saving Changes' : 'Save Changes'}
                  onClick={() => {}}
                />
              </div>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export default CareSpaceConfig;
