import React from 'react';

export function nl2p(text) {
  if (!text) {
    return;
  }
  return text.split('\n').map((chunk, key) => {
    return <p key={key}>{chunk}</p>;
  });
}

export function nl2br(text) {
  if (!text) {
    return;
  }
  return text.split('\n').map((chunk, key) => (
    <span key={key}>
      {chunk}
      <br />
    </span>
  ));
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function humanizeCount(count) {
  var suffix = ['', 'k', 'M', 'G', 'T', 'P'],
    { number, logIndex } = logScaleHelper(count);

  if (count === 0 || logIndex === 0) return count;

  return number.toFixed(1) + suffix[logIndex];
}

export function customCount(count) {
  if (count >= 1000 && count < 100000) {
    return Math.floor(logScaleHelper(count).number) + 'k';
  } else if (count >= 100000) {
    return '99k+';
  } else {
    return count;
  }
}

export function logScaleHelper(count) {
  let logIndex = Math.floor(Math.log(count) / Math.log(1000)),
    number = count / Math.pow(1000, logIndex);
  return { number, logIndex };
}

export function numberSeparator(num) {
  var str = num.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
}
