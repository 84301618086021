import { openTaskModal } from 'app/actions/taskModal';
import OsBtn from 'app/components/OsBtn';
import React from 'react';
import { useDispatch } from 'react-redux';

import { closeTaskPreviewModal } from 'app/actions/taskPreviewModal';

function Edit({
  taskId,
  listType,
  updateCache,
  refreshComponent,
  backendDataRequired,
  updateOverdueTasksOnEdit,
}) {
  const dispatch = useDispatch();

  return (
    <OsBtn
      key='edit'
      name='BtnIcon'
      text='Edit task'
      extraClass='list-button'
      icon='edit'
      onClick={() => {
        dispatch(
          openTaskModal(taskId.split('_')[0], {
            taskType: listType,
            updateCache,
            updateComponent: refreshComponent,
            backendDataRequired,
            updateOverdueTasksOnEdit,
          }),
        );
        dispatch(closeTaskPreviewModal());
      }}
    />
  );
}

export default Edit;
