import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Avatar from 'app/components/Shared/Avatar';
import OsCards from 'app/components/OsCards';

import EventTracker from 'app/services/EventTracker';
import InterpolationTextParser from 'app/services/InterpolationTextParser';

import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import {
  timeWithFormat,
  isCurrentDay,
  timestamp,
  timestampOnHover,
} from 'app/utils/timeHelper';
import { replace, snakeCase } from 'app/utils/osLodash';
import { isSpaceArchived } from 'app/utils/spaceHelper';

import { translate } from 'app/actions/flashMessage';

export default class NotificationCardLayout extends Component {
  getEventName() {
    return replace(this.props.name, new RegExp('Pulse', 'gi'), 'Post');
  }

  onNotificationTextClick = (event) => {
    let eventName = `notification_${snakeCase(this.getEventName())}`;
    EventTracker.trackLinkClicked(eventName);
    this.readNotification();
    this.props.onClick(event, this.props);
  };

  readNotification = () => {
    this.props.unread && this.props.readNotification(this.props.id);
  };

  renderAvatarSection() {
    return (
      <div className='nf-avatar-block'>
        <div className='nf-avatar-info'>{this.renderAvatarCollection()}</div>
      </div>
    );
  }

  renderAvatar = (entity) => {
    return (
      <Link
        to={entityUrl(entity)}
        className='act-avatar-item pointer'
        key={entity.id}
        onClick={this.readNotification}>
        <Avatar className='avatar avatar-40' {...avatarAttributes(entity)} />
      </Link>
    );
  };

  renderAvatarCollection() {
    let { actors } = this.props;
    return actors.map(this.renderAvatar);
  }

  renderCreatedTimeStamp() {
    let visibleAt = this.props.visible_at;
    return (
      <small className='fs-12' title={timestampOnHover(visibleAt)}>
        {isCurrentDay(visibleAt)
          ? timestamp(visibleAt)
          : timeWithFormat(visibleAt, 'll', true, {
              todayFormatRequired: true,
            })}
      </small>
    );
  }

  isCTARequiredOnSmallNotification() {
    return false;
  }

  getContainerClassName() {
    let className = 'nf-content-block ';
    if (this.props.isSmallNotification) {
      className += this.isCTARequiredOnSmallNotification()
        ? 'not-full-width'
        : 'with-full-width';
    }
    return className;
  }

  renderNotificationContent() {
    let { actors, objects, targets } = this.props,
      htmlContent = InterpolationTextParser.parseText(this.props.text, {
        actors,
        objects,
        targets,
      }),
      { unread } = this.props;
    return (
      <div className={this.getContainerClassName()}>
        <p
          className='nf-title-bar pointer'
          onClick={this.onNotificationTextClick}>
          <div className='nf-left'>
            <span
              className='nf-title '
              dangerouslySetInnerHTML={{ __html: htmlContent }}></span>
            {unread && <span className='nf-unread-icon'></span>}
          </div>
          {this.renderCreatedTimeStamp()}
        </p>
        {this.renderContentOrBody()}
      </div>
    );
  }

  renderNonAccessableBanner(name, entityType) {
    return (
      <div className='show-report-comment fs-12 my-2 text-left'>
        {translate(name, { entity: entityType })}
      </div>
    );
  }

  renderContentOrBody() {
    let object = this.props.objects[0];
    if (object && object.rejected && object.__typename === 'Comment') {
      return this.renderNonAccessableBanner('REPORTED_ENTITY_TEXT', 'comment');
    } else if (object && object.board && isSpaceArchived(object.board)) {
      return this.renderNonAccessableBanner('ARCHIVED_ENTITY_TEXT', 'space');
    } else if (this.renderBody) {
      return this.renderBody();
    }
  }

  renderOsCard(object) {
    return (
      <OsCards
        size='extra-small'
        obj={object}
        onCardActionClick={this.readNotification}
        onEntityShow={this.readNotification}
      />
    );
  }

  renderCard() {
    let { card_objects } = this.props;
    if (card_objects.length === 1) {
      return (
        <div className='nf-cards-area ms-auto'>
          {this.renderOsCard(card_objects[0])}
        </div>
      );
    } else {
      return (
        <div className='col-md-12'>
          {card_objects.map((obj) => this.renderOsCard)}
        </div>
      );
    }
  }

  renderGroupingDateInfo() {
    if (this.props.groupingDateVisible)
      return (
        <div className='semibold lh-1 nf-day-heading'>
          {timeWithFormat(this.props.visible_at, 'll', true, {
            todayFormatRequired: true,
          })}
        </div>
      );
  }

  render() {
    return (
      <div className='nf-card-details-row'>
        {this.renderGroupingDateInfo()}
        <div className='nf-card-info'>
          {this.renderAvatarSection()}
          {this.renderNotificationContent()}
          {!this.props.isSmallNotification && this.renderCard()}
        </div>
      </div>
    );
  }
}
