import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import RecordsPager from 'app/components/RecordsPager';
import RenderCards from 'app/components/Cards/RenderCards';
import { getSearchParamValue } from 'app/utils/urlHelper';
import { getDynamicObjFromKey } from 'app/utils/dynamicTemplateHelper';
import { isClinicTypeWorkspace } from 'app/utils/Workspace/generalHelper';
import { MY_FOLDER_FILES, MY_FOLDER_LINKS } from 'app/routes/constants/folders';

export const CREATE_FOLDER_CONTEXT = React.createContext({
  createFolder: null,
  toggleCreate: null,
});
const DYNAMIC_TEMPLATES = {
  clinic: [MY_FOLDER_FILES, MY_FOLDER_LINKS],
  company: [MY_FOLDER_FILES, MY_FOLDER_LINKS],
};

class FoldersListing extends PureComponent {
  constructor(props) {
    super(props);
  }

  getCurrentSortFilterKey() {
    return getSearchParamValue('sort') || 'recently_updated';
  }

  isTabSource() {
    return this.props.source === 'tab';
  }

  getDynamicTemplates() {
    const { searchQuery } = this.props,
      list = isClinicTypeWorkspace()
        ? DYNAMIC_TEMPLATES['clinic']
        : DYNAMIC_TEMPLATES['company'];
    if (searchQuery) {
      return list.filter(
        (key) =>
          getDynamicObjFromKey(key)
            .name.toLowerCase()
            .search(searchQuery.toLowerCase()) >= 0,
      );
    } else {
      return list;
    }
  }

  getViewType() {
    switch (this.props.viewType) {
      case 'big':
        return 'Card';
      case 'thumbnail':
        return 'Thumbnail';
      case 'list':
        return 'Thumbnail';
      default:
        return 'Card';
    }
  }

  render() {
    let sortFilter = this.getCurrentSortFilterKey(),
      newFolder = {
        createFolder: this.props.folderCreationEnabled,
        toggleCreate: this.props.toggleCreateFolderButton,
        space: this.props.space,
      },
      additionalProps = {
        seeAllNotRequired: true,
        cardProps: {
          orderRowWise: false,
          cardGridIdentifier: this.props.cardGridIdentifier,
          onCardClick: this.props.onClick,
          viewType: this.getViewType(),
          noEntityRequired: true,
        },
        ...(!this.isTabSource() && {
          dynamicTemplates: this.getDynamicTemplates(),
        }),
      };
    // TODO: Size logic needs to be updated
    return (
      <div>
        {this.isTabSource() && (
          <>
            <RenderCards
              objects={[]}
              orderRowWise={true}
              size={this.props.cardViewType}
              twoPerRow={false}
              cardGridIdentifier={this.props.cardGridIdentifier}
              dynamicTemplates={this.getDynamicTemplates()}
              {...additionalProps.cardProps}
            />
            <RecordsPager
              headerText={'Invitation to Join'}
              queryType='SPACE_DETAILS'
              loadingCards={true}
              sortQuery={this.props.sortQuery || sortFilter}
              type={'PendingFolders'}
              infiniteScroll={true}
              cardSize={this.props.cardViewType}
              twoPerRow={false}
              textQuery={this.props.searchQuery}
              {...additionalProps}
              noResultFound={true}
              fetchPolicy='cache-and-network'
            />
            <div className='workspace-page-head'>
              <span className='workspace-space-page-heading'>My Folders</span>
            </div>
          </>
        )}
        <CREATE_FOLDER_CONTEXT.Provider value={newFolder}>
          <RecordsPager
            queryType='SPACE_DETAILS'
            loadingCards={true}
            type={this.props.type || 'CollaboratoringFolders'}
            infiniteScroll={true}
            sortQuery={this.props.sortQuery || sortFilter}
            cardSize={this.props.cardViewType}
            twoPerRow={false}
            textQuery={this.props.searchQuery}
            {...additionalProps}
            noResultFound={true}
            fetchPolicy='cache-and-network'
          />
        </CREATE_FOLDER_CONTEXT.Provider>
      </div>
    );
  }
}

FoldersListing.defaultProps = {
  cardGridIdentifier: 'FolderIndex:FolderList',
  source: 'tab',
};
FoldersListing = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(FoldersListing);
export default FoldersListing;
