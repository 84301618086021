import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import PodcastPlayer from 'app/components/FileViewer/PodcastPlayer';
import VideoPlayer from 'app/components/VideoPlayer';

import EventTracker from 'app/services/EventTracker';
import { addProtocolIfNotPresent } from 'app/utils/urlHelper';
import { isPodcastURL } from 'app/utils/fileHelper';

import { closePlayerModal } from 'app/actions/playerModal';
import { translate } from 'app/actions/flashMessage';

import {
  GETTING_STARTED_VIDEO_TITLE,
  SPOTIFY_PODCAST_REGEX,
} from 'app/constants';

class PlayerModal extends Component {
  state = {
    error: false,
  };

  onError = () => {
    this.setState({ error: true });
  };

  closePlayerModal = () => {
    if (this.props.playerModal.entity.title === GETTING_STARTED_VIDEO_TITLE)
      EventTracker.track('Get Started Video - Close');
    this.setState({ error: false });
    this.props.closePlayerModal();
  };

  openVideoInNewTab = () => {
    this.closePlayerModal();
    window.open(addProtocolIfNotPresent(this.url()), '_blank');
  };

  url() {
    let video = this.props.playerModal.entity;
    return video.url || video.mp4_video_url;
  }

  renderOpenVideoButton() {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <OsBtn
          name='BtnPrimary'
          text='Open Video'
          extraClass='mt-3'
          onClick={this.openVideoInNewTab}
        />
        <p className='mt-3 text-center fs-14 col-9'>
          {translate('SOMETHING_WENT_WRONG_WHILE_PLAYING_VIDEO')}
        </p>
      </div>
    );
  }

  renderContent() {
    let url = this.url(),
      { entity } = this.props.playerModal;

    if (url && isPodcastURL(entity)) {
      return <PodcastPlayer url={url} title={entity.title} />;
    } else {
      return (
        <VideoPlayer
          video={{
            url: url,
            onError: this.onError,
            ...this.props.playerModal.options,
          }}
        />
      );
    }
  }

  render() {
    let entity = this.props.playerModal.entity,
      className = 'modal-xl modal-search modal-get-started modal-height-80';

    className += this.props.playerModal.options.audio ? ' with-audio' : '';
    return (
      <Modal
        show={this.props.playerModal.open}
        onHide={this.closePlayerModal}
        dialogClassName={className}
        animation={false}
        backdropClassName='modal-backdrop-custom dark'>
        <div className='modal-header position-relative'>
          <h4 className='card-title'>{entity.title}</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-video-modal-btn y-center'
            icon='close'
            label='Close video modal'
            onClick={this.closePlayerModal}
          />
        </div>
        <div className='modal-body'>
          {this.state.error && this.renderOpenVideoButton()}
          {!this.state.error && this.renderContent()}
        </div>
      </Modal>
    );
  }
}

PlayerModal = connect(({ playerModal }) => ({ playerModal }), {
  closePlayerModal,
})(PlayerModal);

export default PlayerModal;
