import OsBtn from 'app/components/OsBtn/index.js';
import React from 'react';
import partnerImage from 'app/images/card-images/partner-space-logo.png';
import {
  getPartnerClinicLogo,
  getPartnerClinicName,
} from 'app/utils/spaces/partnerHelper';

const PartnerSpaceCard = (props) => {
  const getInfoTitle = () => {
    return getPartnerClinicName(props.obj);
  };

  const getClinicImage = () => {
    return getPartnerClinicLogo(props.obj) || partnerImage;
  };
  const onCardClick = (e, options = {}) => {
    props.onCardClick && props.onCardClick(e, options);
  };

  return (
    <>
      <div className='os-card-container'>
        <div className='partner-space-card p-12' onClick={onCardClick}>
          <div className='card-image'>
            <img
              src={getClinicImage()}
              alt={'Partner Space'}
              loading={'lazy'}
            />
          </div>
          <div className='partner-card-description'>
            <span className={'card-type'}>Partner</span>
            <div className={'info'}>
              <span className={'info-title'}>{getInfoTitle()}</span>
            </div>
          </div>
          <div className='card-action'>
            <OsBtn
              name='BtnIcon'
              icon={'more'}
              extraClass={'no-text v-align-top card-btn-more '}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerSpaceCard;
