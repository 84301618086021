import {
  MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION,
} from 'app/actions/types';
import LocalStorageManager from 'app/services/LocalStorageManager';
import { isEmpty } from 'app/utils/osLodash';

export default function activeConversation(state = { payload: {} }, action) {
  switch (action.type) {
    case MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION:
      let conversation = { ...state.payload, ...action.payload };
      if (!isEmpty(state.payload)) {
        LocalStorageManager.setActiveConversation(conversation);
        return { payload: conversation };
      } else {
        return { payload: state.payload };
      }
    case SET_ACTIVE_CONVERSATION:
      return { payload: action.payload };
    default:
      return state;
  }
}
