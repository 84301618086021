import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { verifyUserEmailWithToken } from 'app/actions/authentication';

import { closeOnboardingVerificationModals } from 'app/actions/onboardingTips';

class ConfirmEmail extends Component {
  componentDidMount() {
    this.props.closeOnboardingVerificationModals();
    this.props.verifyUserEmailWithToken(this.props.match.params.token);
    setTimeout(() => {
      this.props.navigate('/default');
    }, 1000);
  }

  render() {
    return null;
  }
}

ConfirmEmail = withRouter(ConfirmEmail);
ConfirmEmail = connect(null, {
  closeOnboardingVerificationModals,
  verifyUserEmailWithToken,
})(ConfirmEmail);
export default ConfirmEmail;
