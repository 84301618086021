import { includes, isEmpty } from 'app/utils/osLodash';

export function isChromeBrowser() {
  return (
    navigator.userAgent.match('CriOS') ||
    (navigator.userAgent.includes('Safari') &&
      navigator.userAgent.includes('Chrome'))
  );
}

export function isTouchSupported() {
  return 'ontouchstart' in document.documentElement;
}

export function keyboardUIAdditionalBarSupported() {
  let htmlClassList = window.$('html')[0].classList;
  return (
    isChromeBrowser() &&
    (includes(htmlClassList, 'iphone') || includes(htmlClassList, 'ipad'))
  );
}

export function platformClasses() {
  return navigator.platform.toLocaleLowerCase().split(' ');
}

export function isIpadDevice() {
  // Used to detect ipad in iOS 13.
  // https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
  return (
    includes(platformClasses(), 'ipad') ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    !isEmpty(window.navigator.userAgent.match(/ipad/i))
  );
}

export function isLandscape() {
  return !isPortrait();
}

export function isPortrait() {
  return (
    window.orientation === 180 ||
    window.orientation === 0 ||
    isSiteOpenedInIframe()
  );
}

export function isMobileDevice() {
  let width = window.$(window).width(),
    iPad = isIpadDevice();
  return (
    (isTouchSupported() && ((!iPad && width < 900) || (iPad && width < 767))) ||
    isSiteOpenedInIframe()
  );
}

export function isSiteOpenedInIframe() {
  return window.location !== window.parent.location;
}
