import { stringToDate } from 'app/utils/timeHelper';

export function fillForm(formData, person) {
  formData['firstName'] = person.first_name;
  formData['lastName'] = person.last_name;
  formData['preferredName'] = person.preferred_name;
  formData['dob'] = person.date_of_birth && stringToDate(person.date_of_birth);
  formData['email'] = person.email;
  formData['countryCode'] = person.country_code || '1';
  formData['identificationNumber'] = person.identification_number;
  formData['gender'] = person.gender;
  formData['addressRequired'] = true;
  formData['address'] = {};
  formData['address']['addressLine1'] = person.address_object?.address_line_1;
  formData['address']['addressLine2'] = person.address_object?.address_line_2;
  formData['address']['city'] = person.address_object?.city;
  formData['address']['countryId'] = person.address_object?.country_id;
  formData['address']['stateId'] = person.address_object?.state_id;
  formData['address']['zipcode'] = person.address_object?.zipcode;
  return formData;
}
