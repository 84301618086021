import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logout } from 'app/actions/authentication';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
class Logout extends Component {
  logout = () => {
    if (window.location.hash) this.removeHashFromUrl();
    if (this.props.currentUser.token) setTimeout(this.props.logout, 100);
  };

  removeHashFromUrl() {
    this.props.navigate(window.location.pathname);
  }

  render() {
    return (
      <div className='nav-profile__links'>
        <a
          href='javascript:void(0)'
          className='nav-profile__link os-btn-link'
          onClick={this.logout}>
          <OrthoIcon name='logout' /> Logout
        </a>
      </div>
    );
  }
}
Logout = withRouter(Logout);

Logout = connect(({ currentUser }) => ({ currentUser }), { logout })(Logout);

export default Logout;
