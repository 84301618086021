import React, { useEffect } from 'react';
import { timeWithFormat } from 'app/utils/timeHelper';
import OsBtn from 'app/components/OsBtn';

const SPACE_CREATED_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
const GroupModalFormButtons = (props) => {
  const {
    handleSubmit,
    initialValues,
    isEditView,
    user,
    closeModal,
    isSubmitting,
    isDisabled,
  } = props;
  useEffect(() => {
    // Add event listener for ctrl + enter or cmd + enter to submit the form
    const handleKeyDown = (event) => {
      event.stopImmediatePropagation();
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
        handleSubmit();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const commonProps = {
    htmlTag: 'button',
    extraClass: 'web-view-btn btn-save-info',
  };

  return (
    <div className={'modal-footer p-0'}>
      {isEditView && user && (
        <div className='group-timestamp'>
          Created by {user?.full_name} •{' '}
          {timeWithFormat(initialValues.created_at, SPACE_CREATED_TIME_FORMAT)}
        </div>
      )}
      <div className={'task-modal-buttons'}>
        <OsBtn
          type='button'
          name='BtnSecondary'
          text='Cancel'
          onClick={closeModal}
          {...commonProps}
        />
        <OsBtn
          id={'task-modal-submit-button'}
          type='button'
          name='BtnPrimary'
          onClick={handleSubmit}
          disabled={isSubmitting || isDisabled}
          text={isSubmitting ? 'Saving' : 'Save'}
          {...commonProps}
        />
      </div>
    </div>
  );
};

export default GroupModalFormButtons;
