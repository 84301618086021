import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const OPTIONS = [
  { name: 'Name (A-Z)', icon: 'name-a-z', key: 'name_asc' },
  { name: 'Name (Z-A)', icon: 'name-z-a', key: 'name_desc' },
  { name: 'Newest First', icon: 'calendar-desc', key: 'date_added_desc' },
  { name: 'Oldest First', icon: 'calendar-asc', key: 'date_added_asc' },
  { name: 'Last Updated', icon: 'calendar-desc', key: 'recently_updated' },
];

const DEFAULT_OPTION = 'date_added_desc';

class SpaceContentSortDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: this.defaultValue() };
    this.props.onChange(this.defaultValue());
  }

  componentDidUpdate(nextProps) {
    if (this.props.disabled != nextProps.disabled) {
      this.resetSort();
    }
  }

  resetSort = () => {
    this.setState({ sort: this.defaultValue() });
  };

  defaultValue() {
    return this.props.selected || DEFAULT_OPTION;
  }

  optionSelected(key) {
    return this.state.sort === key;
  }

  selectedOption() {
    return OPTIONS.find((option) => option.key === this.state.sort);
  }

  selectedOptionIcon() {
    return this.selectedOption().icon;
  }

  selectedOptionName() {
    return this.selectedOption()?.name || 'Sort';
  }

  setOption = (sort) => {
    this.setState({ sort });
    this.props.onChange(sort);
  };

  renderOption = (option) => {
    let selected = this.optionSelected(option.key);
    return (
      <div
        onClick={this.setOption.bind(this, option.key)}
        selected={selected}
        className='space-sort-option'>
        <OrthoIcon name={option.icon} />
        <span>{option.name}</span>
      </div>
    );
  };

  render() {
    let {
      disabled,
      device: { mobileDevice },
    } = this.props;
    return (
      <CustomDropdown
        name='space_links_sort'
        chevronIcon='chevron-caret'
        dropdownInfo={{
          title: `${mobileDevice ? 'Sort: ' : ''}${this.selectedOptionName()}`,
          icon: this.selectedOptionIcon(),
        }}
        className={`cs-dropdown cs-file-dropdown filter-dropdown with-sorting ${
          disabled ? ' disabled' : ''
        }`}
        onDropDownToggle={this.props.onDropDownToggle}>
        {OPTIONS.map(this.renderOption)}
      </CustomDropdown>
    );
  }
}

SpaceContentSortDropdown.defaultProps = {
  onChange: () => {},
  onDropDownToggle: () => {},
  disabled: false,
};

SpaceContentSortDropdown = connect(
  ({ device }) => ({ device }),
  {},
)(SpaceContentSortDropdown);
export default SpaceContentSortDropdown;
