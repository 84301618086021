import store from 'app/store';
import { currentUser, isCurrentUser } from 'app/utils/userHelper';
import {
  CARE_MY_NAV_LIST,
  CARE_USER_NAV_LIST,
  GUEST_USER_NAV_LIST,
  MY_NAV_LIST,
  USER_NAV_LIST,
} from './constants';
import { filter, isArray, map } from 'app/utils/osLodash';
import { isTabAuthorized } from 'app/utils/entitiesHelper';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export function getNavList(user, source) {
  if (source === 'care') {
    return isCurrentUser(user) ? CARE_MY_NAV_LIST : CARE_USER_NAV_LIST;
  } else {
    return isCurrentUser(user) ? MY_NAV_LIST : USER_NAV_LIST;
  }
}

export function getTabList(user, source) {
  if (currentUser().id) {
    let navList = map(getNavList(user, source), (option) =>
      getRequiredNavList(option, user),
    );
    return filter(navList, (navOption) => navOption && navOption.length);
  } else {
    return GUEST_USER_NAV_LIST;
  }
}

export function getTabListPathkeys(user, source) {
  return getTabList(user, source)
    .map((item) => (isArray(item[0]) ? item.map((item) => item[2]) : item[1]))
    .flat()
    .filter((option) => option);
}

function getRequiredNavList(option, user) {
  if (isArray(option[0])) {
    return filter(option, (dropdownOption) =>
      isOptionRequired(dropdownOption, user),
    );
  } else {
    return isOptionRequired(option, user) ? option : null;
  }
}

function isOptionRequired(option, user) {
  return isCurrentFilterAvailable(option);
}

function isCurrentFilterAvailable(option) {
  let filter = option[2] || option[1];
  return isTabAuthorized('user_detail', filter, store.getState().currentUser);
}

export function getCurrentFilter(pathname, niceId) {
  return pathname.replace(withWorkspaceIdentifier(`/users/${niceId}/`), '');
}
