import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  fieldRequired,
  composeValidators,
  validEmailOrPhoneNumberInput,
} from 'app/utils/validationHelper';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { loader as queryLoader } from 'graphql.macro';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import PhoneAuthentication from './PhoneAuthentication';

import {
  authModal,
  login,
  refreshCurrentUser,
} from 'app/actions/authentication';

import EventTracker from 'app/services/EventTracker';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { setKeyValueInLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';

const AUTHENTICATE_MUTATION = queryLoader('app/graphql/Authenticate.gql');
const SEND_OTP_MUTATION = queryLoader('app/graphql/SendOtp.gql');

const WORKSPACE_AUTHENTICATE_MUTATION = queryLoader(
  'app/graphql/QuickSwitcher/WorkSpaceAuthenticate.gql',
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'credentials',
      showSignupModal: false,
      error: '',
      requestInProgress: false,
    };
    this.formSyncErrors = {};
    this.emailOrPhoneNumber = '';
  }

  componentWillUnmount() {
    this.props.refreshCurrentUser();
  }

  onSuccessullCallback = (token) => {
    let variables = {
      token: token,
      remember: this.state.remember,
      email: this.emailOrPhoneNumber,
      user: this.state.user,
    };
    this.props.login(variables);
    this.props.navigate('/', { skip: true });
  };

  toggleRequestInProgress() {
    this.setState({ requestInProgress: !this.state.requestInProgress });
  }

  toggleSignupModal = () => {
    this.setState({ showSignupModal: !this.state.showSignupModal });
  };

  clearError = () => {
    this.setState({ error: null, emailError: null });
  };

  onSubmit = (values) => {
    this.clearError();
    this.toggleRequestInProgress();
    if (isWorkspaceView()) {
      this.props
        .workSpaceAuthenticateMutation({
          variables: values,
        })
        .then(({ data }) => {
          if (data.authenticateWorkspace.errors) {
            let { errors } = data.authenticateWorkspace,
              emailError = errors.find(
                (error) => error.field === 'emailOrPhoneNumber',
              );
            if (emailError) {
              this.setState(
                { emailError: emailError.message },
                EventTracker.trackFailure('login', {
                  error: data.authenticateWorkspace.errors[0].message,
                }),
              );
            } else {
              this.setState(
                { error: data.authenticateWorkspace.errors[0].message },
                EventTracker.trackFailure('login', {
                  error: data.authenticateWorkspace.errors[0].message,
                }),
              );
            }
          } else if (data.authenticateWorkspace.value) {
            setKeyValueInLocalStorage(
              'HTTP_SESSION_TOKEN',
              data.authenticateWorkspace.value,
            );
            setKeyValueInLocalStorage('lockNav', false);
            let state = {
              remember: data.authenticateWorkspace.remember,
              emailOrPhoneNumber: values.emailOrPhoneNumber,
              user: data.authenticateWorkspace.user,
            };
            this.setState(
              state,
              this.onSuccessullCallback.bind(
                this,
                data.authenticateWorkspace.value,
              ),
            );
          } else if (data.authenticate.phone_number) {
            let state = {
              countryCode: data.authenticate.country_code,
              phoneNumber: data.authenticate.phone_number,
              remember: data.authenticate.remember,
              step: '2FA',
              emailOrPhoneNumber: values.emailOrPhoneNumber,
              user: data.authenticate.user,
            };
            this.setState(state);
          }
          this.toggleRequestInProgress();
        });
    } else {
      this.props
        .authenticateMutation({
          variables: values,
        })
        .then(({ data }) => {
          if (data.authenticate.errors) {
            let { errors } = data.authenticate,
              emailError = errors.find(
                (error) => error.field === 'emailOrPhoneNumber',
              );
            if (emailError) {
              this.setState(
                { emailError: emailError.message },
                EventTracker.trackFailure('login', {
                  error: data.authenticate.errors[0].message,
                }),
              );
            } else {
              this.setState(
                { error: data.authenticate.errors[0].message },
                EventTracker.trackFailure('login', {
                  error: data.authenticate.errors[0].message,
                }),
              );
            }
          } else if (data.authenticate.value) {
            setKeyValueInLocalStorage(
              'HTTP_SESSION_TOKEN',
              data.authenticate.value,
            );
            setKeyValueInLocalStorage('lockNav', false);
            let state = {
              remember: data.authenticate.remember,
              emailOrPhoneNumber: values.emailOrPhoneNumber,
              user: data.authenticate.user,
            };
            this.setState(
              state,
              this.onSuccessullCallback.bind(this, data.authenticate.value),
            );
          } else if (data.authenticate.phone_number) {
            let state = {
              countryCode: data.authenticate.country_code,
              phoneNumber: data.authenticate.phone_number,
              remember: data.authenticate.remember,
              step: '2FA',
              emailOrPhoneNumber: values.emailOrPhoneNumber,
              user: data.authenticate.user,
            };
            this.setState(state);
          }
          this.toggleRequestInProgress();
        });
    }
  };

  sendOtp = ({ channel }) => {
    return this.props.sendOtpMutation({
      variables: {
        phone_number: this.state.phoneNumber,
        country_code: this.state.countryCode,
        check_user_existence: false,
        channel,
      },
    });
  };

  actionAfterClosingModal() {
    this.props.navigate('/');
  }

  closeLoginModal() {
    this.props.authModal({ authModal: '' });
  }

  closeModal = () => {
    this.closeLoginModal();
    this.props.authentication.options &&
      this.props.navigate(this.props.authentication.options.onCloseRedirectTo);
    this.setState({ step: 'credentials' });
    this.props.authentication.actionAfterClosingModal &&
      this.actionAfterClosingModal();
  };

  isOnLoginView() {
    return this.props.location.pathname === '/login';
  }

  openSignupView = () => {
    if (this.props.device.mobileDevice && this.isOnLoginView()) {
      this.props.navigate('/signup');
    } else {
      this.props.authModal({ authModal: 'signup' });
    }
  };

  isEmailFieldValid() {
    return !this.formSyncErrors.email;
  }

  renderForgotPasswordHint() {
    if (this.state.error) {
      if (this.formSyncErrors && !this.formSyncErrors.password)
        return (
          <div className='form-error'>
            <OrthoIcon
              name='error'
              defaultClass='pe-1 v-align-middle ms-3'
              dataHoverNotRequired={true}
            />
            {this.state.error}{' '}
            <OsLink
              to='/forgot_password'
              skipWorkspace={!this.props.isWorkspace}
              text='Reset your password here.'
              className='with-underline  reset-button'
            />
          </div>
        );
    } else {
      return (
        <div className='login-form-hint pt-2'>
          Don’t have a password or simply forgot it? Please{' '}
          <OsLink
            text='click here'
            to='/forgot_password'
            skipWorkspace={!this.props.isWorkspace}
            className=''
          />{' '}
          to reset your password and login.
        </div>
      );
    }
  }

  renderEmailOrPhoneFieldError() {
    if (this.state.emailError && this.isEmailOrPhoneFieldValid()) {
      return (
        <div className='form-error email-error'>
          <OrthoIcon name='error' defaultClass='pe-1 ms-3 ' />
          {this.state.emailError}
        </div>
      );
    }
  }

  renderCredentialsForm() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={(props) => {
          this.emailOrPhoneNumber = props.values.emailOrPhoneNumber;
          this.formSyncErrors = props.errors;
          return (
            <form name='login' onSubmit={props.handleSubmit}>
              <div className='text-center form-heading login-form-heading'>
                Login
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Field
                    name='emailOrPhoneNumber'
                    autoComplete='email'
                    type='text'
                    osType='input'
                    component={OsField}
                    label='Email or Phone Number'
                    saveInputRef={(ref) => (this.emailRef = ref)}
                    validate={composeValidators(
                      fieldRequired,
                      validEmailOrPhoneNumberInput,
                    )}
                    formGroupExtraClass='login-email-group'
                    labelClassName='mnd-label'
                    className={this.state.emailError ? 'error' : ''}
                  />
                  {this.renderEmailOrPhoneFieldError()}
                </div>

                <div className='col-md-12'>
                  <Field
                    name='password'
                    osType='password'
                    component={OsField}
                    label='Password'
                    validate={fieldRequired}
                    formGroupExtraClass={'mb-2 login_password'}
                    className={this.state.error ? 'error' : ''}
                    labelClassName='mnd-label'
                  />
                  {this.renderForgotPasswordHint()}
                </div>
              </div>

              <div className='text-center'>
                <OsBtn
                  name='BtnPrimary'
                  htmlTag='button'
                  text='login'
                  extraClass='btn__login'
                  type='submit'
                  loaderRequired={this.state.requestInProgress}
                  disabled={this.state.requestInProgress}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }

  renderForm() {
    if (this.state.step === 'credentials') {
      return this.renderCredentialsForm();
    } else if (this.state.step === '2FA') {
      let email =
        this.state.phoneNumber === this.emailOrPhoneNumber
          ? null
          : this.emailOrPhoneNumber;
      return (
        <PhoneAuthentication
          phoneNumber={this.state.phoneNumber}
          onSuccess={this.onSuccessullCallback}
          countryCode={this.state.countryCode}
          resendOtp={this.sendOtp}
          onClose={this.closeModal}
          email={email}
        />
      );
    }
  }

  render() {
    if (this.props.currentUser.token) {
      let locationState = this.props.location.state,
        redirectPath = locationState ? locationState.from.pathname : '/';

      return <Navigate to={redirectPath} />;
    }

    return this.renderForm();
  }
}

Login = compose(
  graphql(AUTHENTICATE_MUTATION, { name: 'authenticateMutation' }),
  graphql(WORKSPACE_AUTHENTICATE_MUTATION, {
    name: 'workSpaceAuthenticateMutation',
  }),
  graphql(SEND_OTP_MUTATION, { name: 'sendOtpMutation' }),
)(Login);
Login = withRouter(Login);
Login = connect(
  ({ authentication, currentUser, device, systemConfig }) => ({
    authentication,
    currentUser,
    device,
    systemConfig,
  }),
  { authModal, login, refreshCurrentUser },
)(Login);

export default Login;
