import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEntityReported } from 'app/utils/entitiesHelper';
import { canSpaceHaveDiscussions } from 'app/utils/spaceHelper';

import CommentIcon from 'app/components/Shared/CommentIcon';
import EmojiIconBar from 'app/components/EmojiIconBar';
import OsBtn from 'app/components/OsBtn';
import { pluralize } from 'app/utils/generalHelper';

class EntityEngagementFooter extends Component {
  commentIconRequired() {
    switch (this.props.source) {
      case 'comment-section':
        return !this.props.obj.parent_id && this.props.obj.replies_count > 0;
      case 'space-card':
      case 'space-card-min':
        return canSpaceHaveDiscussions(this.props.obj);
      default:
        return true;
    }
  }

  commentIconLabelRequired() {
    switch (this.props.source) {
      case 'space-card':
      case 'space-card-min':
        return false;
      default:
        return true;
    }
  }

  likeLabelRequired() {
    return this.commentIconLabelRequired();
  }

  isEmojiBarRequired() {
    return this.isSourceCommentSetion() || this.isSourceExplore();
  }

  isReplyButtonRequired() {
    let { obj } = this.props;
    return obj.parent_id || obj.replies_count === 0;
  }

  isRepliesButtonRequired() {
    return this.props.obj.replies_count > 0;
  }

  isSourceCommentSetion() {
    return this.isSource('comment-section');
  }

  isSourceExplore() {
    return this.isSource('explore');
  }

  likeToolTipRequired() {
    return this.isSourceCommentSetion();
  }

  isSource(src) {
    return this.props.source === src;
  }

  repliesText() {
    return { singular: 'reply', plural: 'replies' };
  }

  updatedIconRequired() {
    return this.isSourceCommentSetion() || this.isSourceExplore();
  }

  renderCommentIcon(obj) {
    if (this.commentIconRequired()) {
      let extraProps = this.updatedIconRequired()
        ? { iconName: 'comment-fill' }
        : {};
      return (
        <CommentIcon
          obj={obj}
          showLabelIfZero={this.commentIconLabelRequired()}
          onClick={this.props.onRepliesLinkClick}
          disabled={this.props.commentIconDisabled}
          {...extraProps}
        />
      );
    }
  }

  renderReplyButton(obj) {
    return (
      <OsBtn
        key='reply'
        name='BtnIcon'
        icon='reply-fill-left'
        disabled={this.props.disabled}
        extraClass='pull-right osbtn-reply osbtn-tertiary'
        text='Reply'
        onClick={this.props.onReplyLinkClick}
        associatedEntity={obj}
      />
    );
  }

  renderRepliesButton(obj) {
    let label =
      obj.replies_count +
      ' ' +
      pluralize(obj.replies_count, this.repliesText());
    return (
      <OsBtn
        key='replies'
        name='BtnIcon'
        icon='comment-fill'
        disabled={this.props.disabled}
        extraClass='pull-right osbtn-reply osbtn-tertiary'
        text={label}
        onClick={this.props.onRepliesLinkClick}
        associatedEntity={obj}
      />
    );
  }

  renderReplyOrRepliesButton(obj) {
    if (
      (this.isSourceCommentSetion() || this.isSourceExplore()) &&
      !isEntityReported(obj)
    ) {
      if (this.isReplyButtonRequired()) {
        return this.renderReplyButton(obj);
      } else if (this.isRepliesButtonRequired()) {
        return this.renderRepliesButton(obj);
      }
    }
  }

  renderPostFeedCommentView() {
    let { obj } = this.props;
    return (
      <>
        {this.renderEmojiBar()}
        <div className='right-col'>{this.renderCommentIcon(obj)}</div>
      </>
    );
  }

  renderEmojiBar() {
    const { obj } = this.props;

    if (this.isEmojiBarRequired())
      return (
        <div className='col-12'>
          <EmojiIconBar
            entityType={obj.__typename}
            entityId={obj.id}
            emojiReactionDetail={obj.emoji_reaction_detail}
            userlistClass={'emoji-user-listing'}
            popoverClass={'feed-emoji-popover'}
          />
        </div>
      );
  }

  renderDefaultView() {
    let { obj } = this.props;
    return (
      <>
        <div className='row'>
          {this.renderEmojiBar()}
          {this.renderReplyOrRepliesButton(obj)}
        </div>
      </>
    );
  }

  render() {
    let { source } = this.props;
    return (
      <div className={`activity-footer-block ${source || ''}`}>
        {false && this.props.postFeedCommentView
          ? this.renderPostFeedCommentView()
          : this.renderDefaultView()}
      </div>
    );
  }
}

EntityEngagementFooter.defaultProps = {
  disabled: false,
  onCommentIconClick: null,
  commentIconDisabled: false,
  onRepliesLinkClick: () => {},
};

EntityEngagementFooter = connect(({ device }) => ({ device }))(
  EntityEngagementFooter,
);
export default EntityEngagementFooter;
