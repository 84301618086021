import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

const DOWNLOAD_ASSET_QUERY = queryLoader('app/graphql/DownloadAsset.gql');

class DownloadAsset extends Component {
  state = {
    assetNotFound: false,
  };

  componentDidMount() {
    if (!this.props.data.loading) this.openAttachmentInNewTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading)
      this.openAttachmentInNewTab();
  }

  openAttachmentInNewTab() {
    let { asset_download_url } = this.props.data;
    if (asset_download_url) {
      setTimeout(() => {
        window.location.href = asset_download_url;
      }, 1500);
    } else {
      this.setState({ assetNotFound: true });
    }
  }

  refetch = () => {
    this.props.data.refetch().then(({ data }) => {
      window.location.href = data.asset_download_url;
    });
  };

  render() {
    return (
      <div className='download-page'>
        <div className='download-text'>
          <p>
            Your download should begin automatically. If not, please{' '}
            <span onClick={this.refetch}>click here</span>.
          </p>
        </div>
      </div>
    );
  }
}

DownloadAsset = connect(({ currentUser }) => ({ currentUser }))(DownloadAsset);

DownloadAsset = graphql(DOWNLOAD_ASSET_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: {
      query_by: props.match.params.query_by,
      id: props.match.params.id,
    },
  }),
})(DownloadAsset);

export default DownloadAsset;
