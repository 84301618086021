import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import Slider from 'react-slick';
import Loader from 'react-loaders';

import CustomErrorFileView from 'app/components/CustomErrorFileView';
import PodcastPlayer from 'app/components/FileViewer/PodcastPlayer';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import VideoPlayer from 'app/components/VideoPlayer';

import { openAttachmentPreview } from 'app/actions/attachmentPreview';

import { addProtocolIfNotPresent } from 'app/utils/urlHelper';
import { entityIsAdditionalLink } from 'app/utils/entitiesHelper';
import {
  getFileExtensionFromUrl,
  isAudio,
  isDownloadable,
  isPodcastURL,
  isSTL,
  isSvg,
  isVideo,
  isVideoProcessed,
  isSupportedByFileViewer,
  rejectVideoFiles,
  isLoomUrl,
  getLoomEmbedUrl,
  isYoutubeUrl,
  isLoomOrYoutubeUrl,
} from 'app/utils/fileHelper';
import { indexOf, isEmpty, map, some } from 'app/utils/osLodash';
import { isInViewport } from 'app/utils/domHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { openPlayerModal } from 'app/actions/playerModal';
const FileViewer = lazy(() => import('react-file-viewer-extended'));
const OSStlViewer = lazy(() => import('app/components/FileViewer/StlViewer'));

const renderLoader = () => <Loader type='ball-beat' />;

class AttachmentPreview extends Component {
  state = {
    sliderRef: { mainSlider: null, subSlider: null },
    topScrollval: 0,
  };

  componentDidUpdate(prevProps) {
    let { mainSlider } = this.state.sliderRef;
    if (
      this.props.sliderFocusRequired &&
      mainSlider &&
      isInViewport(mainSlider.innerSlider.list)
    ) {
      mainSlider.innerSlider.list.setAttribute('tabindex', 0);
      mainSlider.innerSlider.list.focus();
    }

    if (
      this.props.device.touchSupport &&
      prevProps.attachmentPreview.open &&
      !this.props.attachmentPreview.open &&
      this.state.topScrollval
    )
      window.$(window).scrollTop(this.state.topScrollval);
  }

  getFileExtension(object) {
    return getFileExtensionFromUrl(object.preview_url);
  }

  renderDefaultIcon(object, processingOverlayRequired) {
    let overlayRequired =
      processingOverlayRequired && this.processingOverlayRequired(object);
    return (
      <CustomErrorFileView
        fileType={this.getFileExtension(object)}
        processingOverlayRequired={overlayRequired}
      />
    );
  }

  onSlideChange = (index) => {
    let object = this.props.objects[index];
    this.props.notifyParentComponentOnChange &&
      this.props.notifyParentComponentOnChange(object);
  };

  isVideo(object) {
    return object.kind === 'video';
  }

  processingOverlayRequired(object) {
    return this.isVideo(object) && !isVideoProcessed(object);
  }

  discardRightClickEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  checkIframeLoaded = (link, event) => {
    // let isLoaded = event.target.contentWindow.window.length;
    this.props.checkIframeLoaded(link, true);
  };

  checkElementActiveInMainSlider(ref) {
    // On explore page with multiple slider present
    let { mainSlider } = this.state.sliderRef,
      currentSlides;

    if (mainSlider && mainSlider.innerSlider) {
      currentSlides =
        mainSlider.innerSlider.list.getElementsByClassName('slick-current');
    } else {
      currentSlides = window.$('.main-slider .slick-current');
    }
    return some(currentSlides || [], (slide) => slide.contains(ref));
  }

  setPlayerRef = (ref) => {
    if (ref && this.checkElementActiveInMainSlider(ref)) this.playerRef = ref;
  };

  renderSlide = (object) => {
    let commonProps = { key: `slide-${object.id}` },
      isVideoVisible =
        (this.isVideo(object) && isVideoProcessed(object)) ||
        isLoomUrl(object?.url) ||
        isYoutubeUrl(object?.url);

    if (!this.isFullScreen() && !isVideoVisible)
      commonProps['onClick'] = this.openModal;
    if (isSvg(object)) {
      return (
        <div className='slide-img-holder'>
          <svg className='m-auto'>
            <image href={object.preview_url} />
          </svg>
        </div>
      );
    } else if (object.kind === 'image') {
      return (
        <div className='slide-img-holder' {...commonProps}>
          <span className='imgloader'></span>
          <img
            src={object.preview_url}
            alt={object.name}
            className='attachment-content-img'
          />
        </div>
      );
    } else if (isVideoVisible || isAudio(object)) {
      let videoProps = {
        url: object.preview_url || object.url,
        nodownload: !isDownloadable(object),
        autoplay: !this.props.autoplayNotRequired,
        poster: object.thumb_url,
        unmuted: this.props.unmuted,
        playIconRequired: this.props.playIconRequired,
        audio: isAudio(object),
      };

      if (isLoomUrl(videoProps?.url)) {
        let embedUrl = getLoomEmbedUrl(videoProps?.url);
        return (
          <div class='lo-emb-vid'>
            <iframe
              src={embedUrl}
              allowFullScreen
              title={object.title}></iframe>
          </div>
        );
      }

      return (
        <div className='slide-video-holder' {...commonProps}>
          <VideoPlayer
            video={videoProps}
            hlsOptions={{ url: object.adaptive_streaming_preview_url }}
            setPlayerRef={this.setPlayerRef}
            key={object.id}
          />
        </div>
      );
    } else if (isPodcastURL(object)) {
      return <PodcastPlayer url={object.url} title={object.title} />;
    } else if (entityIsAdditionalLink(object)) {
      return (
        <Iframe
          url={addProtocolIfNotPresent(object.url)}
          width='100%'
          height='100%'
          onLoad={this.checkIframeLoaded.bind(this, object)}
          allowFullScreen={true}
          display='initial'
          position='relative'
        />
      );
    } else if (isSupportedByFileViewer(object)) {
      return (
        <Suspense fallback={renderLoader()}>
          <FileViewer
            fileType={this.getFileExtension(object)}
            filePath={object.preview_url}
            {...commonProps}
          />
        </Suspense>
      );
    } else if (isSTL(object)) {
      return (
        <Suspense fallback={renderLoader()}>
          <OSStlViewer url={object.preview_url} />
        </Suspense>
      );
    } else if (!isEmpty(object)) {
      return (
        <div className='icon-text-holder' {...commonProps}>
          {this.renderDefaultIcon(object, true)}
        </div>
      );
    }
  };

  renderSubSlide = (object, index) => {
    let commonProps = { key: `sub-slide-${object.id}` };
    if (object.kind === 'image' || isVideoProcessed(object)) {
      return (
        <img
          src={object.thumb_url}
          alt={object.name}
          className='attachment-content-img'
          {...commonProps}
        />
      );
    } else if (!isEmpty(object)) {
      return (
        <div {...commonProps}>{this.renderDefaultIcon(object, false)}</div>
      );
    }
  };

  renderSlides(slider) {
    if (slider === 'mainSlider') {
      return this.props.objects.map(this.renderSlide);
    } else {
      return this.props.objects.map(this.renderSubSlide);
    }
  }

  getInitialSlideIndex() {
    if (this.props.activeObject) {
      return indexOf(map(this.props.objects, 'id'), this.props.activeObject.id);
    } else {
      return 0;
    }
  }

  getDefaultSettings() {
    return {
      arrows: true,
      dots: false,
      draggable: true,
      initialSlide: this.getInitialSlideIndex(),
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      swipe: true,
    };
  }

  getMainSliderSettings() {
    let defaultSettings = this.getDefaultSettings(),
      customSettings = this.props.navigationSettings || {},
      isChrome = this.props.device.browserName === 'Chrome',
      className = 'main-slider ';
    className += isChrome ? 'chrome-pip-dropdown-fix-slider' : '';

    return {
      ...defaultSettings,
      ...customSettings,
      lazyLoad: true,
      asNavFor: this.state.sliderRef.subSlider,
      afterChange: (i) => {
        this.onSlideChange(i);
      },
      className: className,
      useTransform: !isChrome,
    };
  }

  getVisibleSubSlidesCountAndSetWidth() {
    let elementFetchClassName = this.isFullScreen()
        ? 'fullscreen-thumb-slider'
        : 'thumb-slider',
      thumbSliderContainer = document.getElementsByClassName(
        elementFetchClassName,
      )[0];

    if (thumbSliderContainer) {
      let objectsCount = this.props.objects.length,
        subSlidesWebMaxCount = this.isFullScreen() ? 8 : 6,
        maxObjectsAllowed = this.props.device.mobileDevice
          ? 4
          : subSlidesWebMaxCount,
        thumbSlider =
          thumbSliderContainer.getElementsByClassName('slick-list')[0],
        thumbSlide = thumbSlider.getElementsByClassName('slick-slide')[0],
        sliderActualWidth =
          thumbSliderContainer.offsetWidth - thumbSlider.offsetWidth,
        thumbSliderWidth =
          sliderActualWidth + maxObjectsAllowed * thumbSlide.offsetWidth;
      thumbSliderContainer.style.maxWidth = `${thumbSliderWidth}px`;

      return objectsCount > maxObjectsAllowed
        ? maxObjectsAllowed
        : objectsCount;
    }
  }

  getSubSliderSettings() {
    let defaultSettings = this.getDefaultSettings(),
      customSettings = this.props.subNavigationSettings || {},
      className = 'thumb-slider ';
    className += this.isFullScreen() ? 'fullscreen-thumb-slider' : '';

    return {
      ...defaultSettings,
      ...customSettings,
      asNavFor: this.state.sliderRef.mainSlider,
      slidesToShow: this.getVisibleSubSlidesCountAndSetWidth(),
      variableWidth: true,
      focusOnSelect: true,
      className: className,
    };
  }

  subSliderRequired() {
    return this.props.objects.length > 1 && !this.props.subSliderNotRequired;
  }

  setRef(slider, refName) {
    if (!this.state.sliderRef[refName]) {
      let sliderRef = this.state.sliderRef;
      sliderRef[refName] = slider;
      this.setState({ sliderRef });
    }
  }

  renderSlider(sliderName) {
    let settings =
      sliderName === 'mainSlider'
        ? this.getMainSliderSettings()
        : this.getSubSliderSettings();
    return (
      <Slider ref={(slider) => this.setRef(slider, sliderName)} {...settings}>
        {this.renderSlides(sliderName)}
      </Slider>
    );
  }

  openVideoPlayerModal(file) {
    let playedTime = this.playerRef && this.playerRef.currentTime;
    this.props.openPlayerModal(
      { url: file.preview_url, title: file.name },
      {
        autoplay: true,
        unmuted: true,
        audio: isAudio(file),
        seekTo: playedTime,
      },
    );
  }

  openAttachmentPreviewModal(file, options) {
    this.props.openAttachmentPreview(
      this.props.source,
      rejectVideoFiles(this.props.objects),
      file,
      this.props.author,
      options,
    );
  }

  isVideoModalRequrired(file) {
    return isVideoProcessed(file);
  }

  openModal = (options = {}) => {
    this.setState({ topScrollval: window.$(window).scrollTop() });
    let activeObject = this.currentObject();

    this.isVideoModalRequrired(activeObject)
      ? this.openVideoPlayerModal(activeObject)
      : this.openAttachmentPreviewModal(activeObject, options);
    this.playerRef && this.playerRef.pause();
    document.pictureInPictureElement &&
      document.exitPictureInPicture &&
      document.exitPictureInPicture();
  };

  isFullScreen() {
    return this.props.attachmentPreview.open;
  }

  currentObject() {
    return (
      this.state.sliderRef.mainSlider &&
      this.props.objects[
        this.state.sliderRef.mainSlider.innerSlider.state.currentSlide
      ]
    );
  }

  isFullScreenDisabled() {
    let currentObject = this.currentObject();
    return (
      currentObject &&
      ((isVideo(currentObject) && !isVideoProcessed(currentObject)) ||
        isLoomOrYoutubeUrl(currentObject.url))
    );
  }

  render() {
    if (this.props.objects.length) {
      let containerClassName = `create-slider ${
        this.props.containerClassName || ''
      }`;
      containerClassName += this.isFullScreen() ? ' full-ss-active' : '';
      containerClassName +=
        !this.subSliderRequired() && this.isFullScreen()
          ? ' single-slider'
          : '';
      return (
        <div
          className={containerClassName}
          data-hover={!isTouchSupported()}
          onContextMenu={this.discardRightClickEvent}>
          {this.renderSlider('mainSlider')}
          {this.subSliderRequired() && this.renderSlider('subSlider')}
          {!this.isFullScreen() && !this.isFullScreenDisabled() && (
            <span className='fullscreen-button' onClick={this.openModal}>
              {' '}
              <OrthoIcon name='expand-2' dataHoverNotRequired={true} />
            </span>
          )}
        </div>
      );
    } else {
      return <div />;
    }
  }
}

AttachmentPreview = connect(
  ({ attachmentPreview, device }) => ({ attachmentPreview, device }),
  { openAttachmentPreview, openPlayerModal },
)(AttachmentPreview);
AttachmentPreview.defaultProps = {
  checkIframeLoaded: () => {},
};
export default AttachmentPreview;
