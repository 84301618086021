import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import NotificationCardLayout from './NotificationCardLayout';
import OsBtn from 'app/components/OsBtn';

import { entityUrl, isObjectedDeleted } from 'app/utils/entitiesHelper.js';
import { translate } from 'app/actions/flashMessage';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { isUrlOfSameDomain, replaceCurrentDomain } from 'app/utils/urlHelper';

import StoreUpdater from 'app/services/StoreUpdater';

class SpaceCollaboratorRequestNotification extends NotificationCardLayout {
  space() {
    return this.props.targets[0];
  }

  request() {
    return this.props.objects[0];
  }

  ignoreRequestActionNotRequired() {
    return this.props.isSmallNotification && this.isInvitedNotification();
  }

  isInvitedNotification() {
    return this.props.name === 'Invite Sent to Collaborate on Space';
  }

  isCTAVisible() {
    if (isObjectedDeleted(this.request())) return false;

    let request = this.request(),
      space = this.space(),
      currentUserId = +this.props.currentUser.graph.id,
      requestedEntity = request.collaborator,
      requestedEntityUser = requestedEntity.__typename === 'User',
      requestedEntityAccess = requestedEntityUser
        ? +requestedEntity.id === currentUserId
        : requestedEntity.is_author_or_editor;

    return this.isInvitedNotification()
      ? requestedEntityAccess
      : space.is_author_or_editor;
  }

  onViewSpace = () => {
    this.readNotification();
    let url = entityUrl(this.space());
    if (isUrlOfSameDomain(url)) {
      this.props.navigate(replaceCurrentDomain(url));
    } else {
      window.location.href = url;
    }
  };

  updateCardObject = (space) => {
    if (this.isPrivateCard()) {
      StoreUpdater.updatePrivateCardObjectInNotification(space, this.props.id);
    }
  };

  renderCTAs() {
    let request = this.request(),
      commonButtonProps = {
        requestingUser: request.user,
        onAction: this.readNotification,
        space: this.space(),
      };

    if (!this.isSpaceDeleted()) {
      if (this.props.isSmallNotification) {
        return (
          <>
            {!this.ignoreRequestActionNotRequired() && (
              <DeclineRequest
                {...commonButtonProps}
                source='notification'
                extraClass='px-3 '
              />
            )}
            <AcceptRequest
              {...commonButtonProps}
              source='notification'
              onSuccess={this.updateCardObject}
              onlyTextRequired={true}
            />
          </>
        );
      } else {
        return (
          <>
            <AcceptRequest
              {...commonButtonProps}
              onSuccess={this.updateCardObject}
              source='notification'
            />
            <DeclineRequest {...commonButtonProps} source='notification' />
          </>
        );
      }
    }
  }

  isSpaceDeleted() {
    let { card_objects } = this.props;
    return (
      card_objects &&
      card_objects[0] &&
      card_objects[0].__typename === 'DeletedObject'
    );
  }

  isPrivateCard() {
    let { card_objects } = this.props;
    return (
      card_objects &&
      card_objects[0] &&
      card_objects[0].__typename === 'PrivateObject'
    );
  }

  renderViewSpace() {
    if (!this.isPrivateCard()) {
      let space = this.space(),
        text = this.props.isSmallNotification ? 'View' : `View ${space.type}`;
      return (
        <OsBtn
          name='BtnPrimary'
          text={text}
          associatedEntity={this.space()}
          onClick={this.onViewSpace}
          data-hover={!isTouchSupported()}
        />
      );
    }
  }

  isCTARequiredOnSmallNotification() {
    return (
      this.isInvitedNotification() &&
      ['pending', 'accepted'].includes(this.request().status)
    );
  }

  renderActions() {
    if (this.isCTAVisible()) {
      switch (this.request().status) {
        case 'pending':
          return this.renderCTAs();
        case 'rejected':
          return (
            <div className='helping-text-info'>
              {translate('SPACE_REQUEST_IGNORED_TEXT')}
            </div>
          );
        case 'accepted':
          return this.renderViewSpace();
        case 'canceled':
          return (
            <div className='helping-text-info'>
              {translate('SPACE_REQUEST_NOT_AVAILABLE')}
            </div>
          );
        default:
          return null;
      }
    }
  }

  renderBody() {
    let actionBaseClass = 'space-notification-action ';
    actionBaseClass += this.ignoreRequestActionNotRequired()
      ? 'no-ignore-cta '
      : '';
    return <div className={actionBaseClass}>{this.renderActions()}</div>;
  }
}

SpaceCollaboratorRequestNotification = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(SpaceCollaboratorRequestNotification);
SpaceCollaboratorRequestNotification = withRouter(
  SpaceCollaboratorRequestNotification,
);
export default SpaceCollaboratorRequestNotification;
