import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CaseAppointmentFooter from 'app/components/CaseView/CaseAppointmentFooter';
import CaseLeftSection from 'app/components/CaseLeftSection';
import CaseRightSectionContainer from 'app/components/CaseRightSection/Container';

class CaseCardDetailView extends PureComponent {
  render() {
    let { kase } = this.props,
      { ipadPortrait } = this.props.device;

    if (!kase) return <div />;

    return (
      <div className='row no-margin case-detail-height cd-detail-conatiner new-cs-card'>
        <CaseLeftSection
          kase={kase}
          spaceBannerView={this.props.spaceBannerView}
          spaceBannerTitle={this.props.spaceBannerTitle}
        />
        <CaseRightSectionContainer
          kase={kase}
          guestCase={this.props.guestCase}
        />
        {!ipadPortrait && <CaseAppointmentFooter kase={kase} />}
      </div>
    );
  }
}

CaseCardDetailView = connect(
  ({ device }) => ({ device }),
  {},
)(CaseCardDetailView);
export default CaseCardDetailView;
