import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M5.433 11.548c-.834-.147-1.484-.943-1.43-1.76.043-.618.488-1.183 1.083-1.414.184-.094.39-.136.596-.146l4.582-.126h.01c.13 0 .26-.01.39-.01.737-.021 1.452.565 1.538 1.288.076.722-.585 1.655-1.321 1.78-.357-.052-.726-.052-1.083-.052-1.213.031-2.437.084-3.64.304-.249.052-.487.104-.714.178l-.011-.042z'
      fill='#FFC933'
    />
    <path
      d='m12.202 13.969-1.289.647c-.39-.146-.91-.125-1.343-.083-1.321.125-2.697.125-3.942-.336-.64-.22-1.31-.68-1.31-1.34-.012-.419.27-.806.627-1.037a2.17 2.17 0 0 1 .499-.24c.216-.085.465-.137.715-.179 1.202-.21 2.426-.262 3.639-.293.357 0 .726 0 1.083.052.216.032.422.084.617.157.542.2 1.287.692 1.33 1.247.043.586-.03 1.352-.626 1.405zM5.054 8.29a1.869 1.869 0 0 1-.878-1.843 1.892 1.892 0 0 1 .91-1.309c.152-.084.325-.157.498-.21 1.441-.376 2.968-.46 4.463-.261.216.031.444.073.66.115.77.147 1.354.9 1.311 1.665-.054.754-.953 1.592-1.744 1.655h-.01c-1.538.052-3.055.084-4.582.126-.206.01-.412.052-.596.146l-.032-.083z'
      fill='#FFC933'
    />
    <path
      d='M5.086 5.138c-.346-.46-.4-1.047-.227-1.592.173-.544.628-.984 1.18-1.173a7.596 7.596 0 0 1 4.799.032c.563.188.92.796.823 1.361-.13.566-1.018.911-1.614.9-1.495-.198-3.152.095-4.603.472-.163.042-.206-.084-.358 0z'
      fill='#FFC933'
    />
    <path
      d='M10.664 8.092c-.13 0-.26.01-.39.01.791-.063 1.69-.9 1.744-1.665.043-.765-.541-1.508-1.31-1.665-.228-.042-.444-.074-.661-.105.596.01 1.484-.335 1.603-.9.108-.566-.26-1.174-.823-1.362 1.484-.335 3.52-.461 5.015-.189.53.095 1.061.23 1.527.503.552.325 1.083 1.005 1.43 1.54.92 1.382 1.267 2.838 1.19 4.492a7.386 7.386 0 0 1-1.819 4.504c-.433.492-.899.963-1.343 1.435-1.44 1.497-1.473 3.037-.985 4.817.368 1.33-.704 2.493-1.625 2.493-1.094.01-2.556-.691-2.675-2.147-.098-1.131-.098-.629-.098-1.697 0-.806.098-1.623.098-2.44-.011-.24-.033-.482-.141-.691a.935.935 0 0 0-.488-.398l.542-.89c.596-.042 1.126-.597 1.083-1.173-.032-.566-.498-1.037-1.04-1.236a2.962 2.962 0 0 0-.617-.168c.736-.136 1.397-1.068 1.321-1.78-.086-.723-.801-1.31-1.538-1.288z'
      fill='#FFC933'
    />
    <path
      d='M5.355 11.57c.866-.252 1.767-.322 2.657-.463.445-.1.89-.141 1.346-.171.444-.04.9-.04 1.38 0 .467.04.969.12 1.437.382.444.252.764.705.81 1.169.08.453-.149.967-.571 1.218a1.91 1.91 0 0 1-1.357.262c-.012 0-.023-.01-.023-.02s.011-.02.023-.02c.433.03.89-.1 1.22-.353a1.04 1.04 0 0 0 .41-1.047c-.056-.373-.353-.705-.73-.906-.376-.202-.82-.272-1.265-.303a29.6 29.6 0 0 0-2.669.05c-.89.03-1.79.101-2.657.242h-.011c-.012 0-.023-.01-.023-.02s.011-.02.023-.02z'
      fill='#C2350A'
    />
    <path
      d='M5.034 8.4c.844-.202 1.7-.323 2.566-.444.844-.15 1.745-.191 2.623-.16.24.01.388.02.696.07.274.05.513.16.73.302.422.282.718.685.798 1.138.171.866-.467 1.843-1.425 1.903-.012 0-.023-.01-.023-.02s.011-.02.011-.02c.856-.191 1.278-1.088 1.084-1.803-.092-.362-.342-.685-.685-.906-.342-.242-.775-.252-1.209-.272-.866-.06-1.722-.04-2.588-.01-.856.01-1.711.14-2.566.262-.012 0-.023-.01-.023-.02 0 0 0-.01.011-.02z'
      fill='#C2350A'
    />
    <path
      d='M5.023 5.213c.844-.262 1.722-.343 2.6-.463.89-.111 1.768-.091 2.669-.02.228.01.444.06.673.1.25.04.536.141.74.282.434.282.754.695.81 1.168.149.977-.855 1.783-1.767 1.693-.011 0-.023-.01-.023-.02s.011-.02.023-.02c.855-.061 1.597-.837 1.414-1.602-.08-.373-.342-.705-.684-.917-.354-.221-.81-.231-1.243-.292-.867-.1-1.734-.12-2.612-.12-.867.01-1.745.07-2.589.251-.011 0-.023 0-.034-.01 0-.01.011-.02.023-.03z'
      fill='#C2350A'
    />
    <path
      d='M5.197 2.992a2.26 2.26 0 0 1 .958-.644 5.545 5.545 0 0 1 1.164-.282c.798-.121 1.608-.06 2.395.06.387.06.764.172 1.14.302.48.172.833.534.924.957.045.212.045.433-.023.625a2.113 2.113 0 0 1-.262.523c-.228.293-.616.615-1.05.524-.01 0-.022-.01-.022-.02s.011-.02.023-.02c.387 0 .672-.292.832-.584.16-.302.274-.645.16-.957a1.142 1.142 0 0 0-.719-.705 8.355 8.355 0 0 0-1.106-.262c-.741-.141-1.517-.201-2.28-.141-.742.06-1.552.171-2.11.655-.012.01-.024.01-.035 0 0-.01 0-.02.011-.03z'
      fill='#C2350A'
    />
  </svg>
);

export default SVG;
