import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import AbstractCard from 'app/components/OsCards/AbstractCard';
import DeclineCareSpace from 'app/components/Shared/CareSpace/DeclineCareSpace';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';

import { translate } from 'app/actions/flashMessage';

class LockedCareSpace extends AbstractCard {
  onCardClick = (e, options = {}) => {
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  render() {
    let { obj } = this.props,
      rejected = !!obj.user_request?.rejection_reason,
      { patient, clinic } = obj.owner;

    return (
      <div
        key={this.props.key}
        className={`locked-care-card ${this.props.containerClasses}`}
        onClick={this.onCardClick}>
        <div className='patient-card-body'>
          <div className='patient-cd-name'>
            <span className='name'>{patient.name}</span>
            <OrthoIcon name='locked' />
          </div>
          <div className='locked-care-text'>
            {rejected
              ? translate('CARE_SPACE_ACCESS_REQUEST')
              : translate('LOCKED_CARE_SPACE_TEXT', {
                  clinicName: clinic.name,
                })}
          </div>
          {!rejected && (
            <div className='action-buttons'>
              <DeclineCareSpace space={obj} />
              <OsBtn
                name='BtnIcon'
                extraClass='accept-btn'
                icon='add'
                text='Accept'
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

LockedCareSpace = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(LockedCareSpace);
LockedCareSpace = withRouter(LockedCareSpace);
export default LockedCareSpace;
