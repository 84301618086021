import React from 'react';
import { connect } from 'react-redux';

import AbstractField from './AbstractField';
import OsField from './';

import { clone } from 'app/utils/osLodash';

// Refer: https://ckeditor.com/latest/samples/toolbarconfigurator/index.html#advanced
const CONFIG = {
  toolbar: [
    { name: 'styles', items: ['Format'] },
    {
      name: 'basicstyles',
      items: [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        'Subscript',
        'Superscript',
        'CopyFormatting',
        'RemoveFormat',
      ],
    },
    {
      name: 'paragraph',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
    },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
    { name: 'links', items: ['Link', 'Unlink'] },
    // Debugging required
    // { name: 'insert', items: ['Image'] },
  ],
  removeButtons: 'Strike,Subscript,Superscript,CopyFormatting,RemoveFormat',
  extraPlugins: 'justify,autogrow',
  removePlugins: 'magicline,contextmenu,tabletools,tableselection',
  format_tags: 'p;h1;h2',
  uiColor: '#303036',
  autoGrow_onStartup: true,
  resize_enabled: false,
  contentsCss: '/os_editor.css',
};

class OsEditorField extends AbstractField {
  handleCustomEvent() {
    return true;
  }

  targetName(e) {
    return this.props.input.name;
  }

  targetValue(e) {
    return e.editor.getData();
  }

  onBeforeLoad = (e) => {
    e.on('dialogDefinition', this.onDialogDefinition);
  };

  onDialogDefinition = (e) => {
    if (e.data.name === 'link') {
      let infoTab = e.data.definition.getContents('info'),
        textField = infoTab.get('linkDisplayText'),
        urlOptions = infoTab.get('urlOptions'),
        linkField = urlOptions.children[0].children[1];

      textField.label = 'Text';
      linkField.label = 'Link';
    }
  };

  onKey = (e) => {
    let event = e.data.domEvent.$;
    this.onKeyDown(event);
    this.onKeyUp(event);
  };

  config() {
    let config = clone(CONFIG),
      { mobileDevice, ipadPortrait } = this.props.device,
      height = {};

    if (mobileDevice) {
      height.min = 96;
      height.max = 270;
    } else if (ipadPortrait) {
      height.min = 96;
      height.max = 300;
    } else {
      height.min = 96;
      height.max = 300;
    }

    config.autoGrow_minHeight = height.min;
    config.autoGrow_maxHeight = height.max;
    return config;
  }

  render() {
    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        {/*<CKEditor
        config={this.config()}
        data={this.props.input.value}
        onKey={this.onKey}
        onBeforeLoad={this.onBeforeLoad}
        {...this.getFieldProps()}
      /> */}
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
      </>
    );
  }
}

OsEditorField.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
};

OsEditorField = connect(({ device }) => ({ device }))(OsEditorField);
export default OsEditorField;
