import { useSearchParams } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import WorkspaceSelectionScreen from 'app/components/Shared/Workspaces/SelectionScreen';

const SelectClinicView = () => {
  const [searcParams] = useSearchParams();
  const code = searcParams.get('code');

  if (!code) return <Navigate to='/launch' skip />;

  const confirmSelection = ({ id, identifier, name }) => {
    window.location.href = `/${identifier}/easy-rx/prescriptions/all?token=${code}`;
  };

  const subHeading = `EasyRX Integration will allow you to view all your patients`;
  return (
    <>
      <WorkspaceSelectionScreen
        heading='Select Clinic for EasyRX Integration'
        subHeading={subHeading}
        onConfirmSelection={confirmSelection}
        smartSelection
      />
    </>
  );
};

export default SelectClinicView;
