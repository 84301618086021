import React, { Component } from 'react';
import OsField from './';

export default class OsContentEditableField extends Component {
  render() {
    return (
      <>
        <pre
          className='contentEditable'
          dangerouslySetInnerHTML={{ __html: this.props.input.value }}></pre>
        <OsField {...this.props} osType='textarea' isWrapperRequired={false} />
      </>
    );
  }
}
