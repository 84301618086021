import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';
import { orderBy } from 'app/utils/osLodash';

const POPULAR_TOPICS_QUERY = queryLoader('app/graphql/PopularTopics.gql');

const hashtagSuggestion = () => {
  const queryFunction = async ({ query, editor }) => {
    const { entity } = editor.options?.editorProps;
    const { data } = await client.query({
      query: POPULAR_TOPICS_QUERY,
      variables: {
        topicsLimit: 5,
        entityId: entity && entity.id,
        entityType: entity && entity.__typename,
        search_query: query,
      },
    });
    const sortedTagList = orderBy(data.popular_topics, ['name']);
    return {
      mention: sortedTagList,
      entity: entity,
    };
  };

  return {
    items: (query) => queryFunction(query),
    ...suggestionConfig,
  };
};

export default hashtagSuggestion;
