import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import CaseAppointmentsButtons from 'app/components/CaseView/CaseAppointmentsButtons';
import OsBtn from 'app/components/OsBtn';

import { indexOf, map } from 'app/utils/osLodash';
import { entityUrl } from 'app/utils/entitiesHelper';

export default class CaseAppointmentFooter extends Component {
  state = {
    appointmentHovered: false,
  };

  setAppointmentHoveredState = (appointmentHovered) => {
    this.setState({ appointmentHovered });
  };

  isSBSViewTypeSelected() {
    return this.props.caseDetailInfo.viewType === 'SBS';
  }

  isTreatmentPhaseButtonPresent() {
    return this.getOrderedCaseNiceId() || this.getOrderedCaseNiceId(3);
  }

  setAppointmentButtonRef = (appointmentButtonRef) => {
    this.setState({ appointmentButtonRef });
  };

  getListOfTreatmentPhases() {
    return (
      (this.props.kase.ordered_case_phases || []).length > 0 &&
      this.props.kase.ordered_case_phases
    );
  }

  getTreatMentPhaseCurrentIndex() {
    let listOfPhases = this.getListOfTreatmentPhases();
    return (
      listOfPhases &&
      indexOf(map(listOfPhases, 'nice_id'), this.props.kase.nice_id)
    );
  }

  renderFirstPhase() {
    let text = `${this.getTreatMentPhaseCurrentIndex()}`,
      buttonTooltipText = `${text}`,
      fullWidth = this.isFullWidthRequired();

    text = !fullWidth ? buttonTooltipText : text;

    return (
      <OsBtn
        name='BtnIcon'
        icon='chevron-left'
        text={text}
        extraClass='text-required px-8 phase-btn osbtn-tertiary '
        onClick={this.redirectToCase.bind(this, 1)}
      />
    );
  }

  renderThirdPhase() {
    let text = `${this.getTreatMentPhaseCurrentIndex() + 2}`,
      buttonTooltipText = `${text}`,
      fullWidth = this.isFullWidthRequired();

    text = !fullWidth ? buttonTooltipText : text;
    return (
      <OsBtn
        name='BtnIcon'
        icon='chevron-right'
        leftIcon={false}
        text={text}
        extraClass='text-required px-8 righticon phase-btn osbtn-tertiary'
        onClick={this.redirectToCase.bind(this, 3)}
      />
    );
  }

  getOrderedCaseNiceId(order = 1) {
    let listOfPhases = this.getListOfTreatmentPhases(),
      currentIndex = this.getTreatMentPhaseCurrentIndex(),
      requiredIndex = null;
    if (order === 1) {
      requiredIndex = currentIndex >= 1 && currentIndex - 1;
    } else {
      requiredIndex =
        currentIndex < listOfPhases.length - 1 && currentIndex + 1;
    }
    return listOfPhases[requiredIndex] && listOfPhases[requiredIndex].nice_id;
  }

  renderAppointmentButtons(kase) {
    if (
      this.isCaseUnlocked() &&
      !this.isInProgress(kase) &&
      (kase.appointments || []).length > 0
    )
      return (
        <CaseAppointmentsButtons
          appointmentIdsWithActions={kase.appliance_teeth_actions.map(
            (action) => action.appointment_id,
          )}
          appointments={kase.appointments}
          setAppointmentHoveredState={this.setAppointmentHoveredState}
          setRef={this.setAppointmentButtonRef}
          appointmentIdsWithActions={kase.appliance_teeth_actions.map(
            (action) => action.appointment_id,
          )}
        />
      );
  }

  isFullWidthRequired() {
    let { device } = this.props,
      { appointmentButtonRef } = this.state;
    return (
      appointmentButtonRef &&
      appointmentButtonRef.offsetWidth / device.width > 0.68
    );
  }

  isCaseUnlocked() {
    return true;
  }

  isInProgress(kase) {
    return kase.is_in_progress && kase.workflow_state !== 'user_draft';
  }

  getFooterClass() {
    let { appointmentHovered } = this.state;

    let footerExtraClass = this.isSBSViewTypeSelected() ? ' sbs-footer ' : '';
    footerExtraClass += appointmentHovered ? ' appointment-hovered' : '';
    footerExtraClass += this.isFullWidthRequired() ? ' full-width' : '';
    footerExtraClass += this.isTreatmentPhaseButtonPresent()
      ? ''
      : ' no-phase-btn';
    return footerExtraClass;
  }

  redirectToCase = (order) => {
    let kase = {
      __typename: 'Case',
      nice_id: this.getOrderedCaseNiceId(order),
    };
    this.props.navigate(entityUrl(kase));
  };

  render() {
    const { kase } = this.props,
      inProgress = kase.is_in_progress && kase.workflow_state !== 'user_draft';

    if (
      (!inProgress && kase.appointments?.length > 0) ||
      (inProgress && this.isCaseUnlocked()) ||
      this.isTreatmentPhaseButtonPresent()
    ) {
      const footerExtraClass = this.getFooterClass();
      return (
        <div className={`col-12 cs-footer   ${footerExtraClass}`}>
          {this.renderAppointmentButtons(kase)}
          {this.getOrderedCaseNiceId() && this.renderFirstPhase()}
          {this.getOrderedCaseNiceId(3) && this.renderThirdPhase()}
        </div>
      );
    } else {
      return null;
    }
  }
}

CaseAppointmentFooter = withRouter(CaseAppointmentFooter);

CaseAppointmentFooter = connect(
  ({ caseDetailInfo, device }) => ({ caseDetailInfo, device }),
  {},
)(CaseAppointmentFooter);
