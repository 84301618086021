import { openTaskModal } from 'app/actions/taskModal';
import OsBtn from 'app/components/OsBtn';
import React from 'react';
import { useDispatch } from 'react-redux';

function Duplicate({
  taskId,
  updateCache,
  refreshComponent,
  backendDataRequired,
}) {
  const dispatch = useDispatch();

  return (
    <OsBtn
      key='duplicatetask'
      name='BtnIcon'
      text='Duplicate task'
      extraClass='list-button'
      icon='add'
      onClick={() =>
        dispatch(
          openTaskModal(taskId, {
            duplicateTask: true,
            updateCache,
            updateComponent: refreshComponent,
            backendDataRequired,
          }),
        )
      }
    />
  );
}

export default Duplicate;
