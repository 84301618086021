import React, { Component } from 'react';
import { connect } from 'react-redux';

import FolderSpaceCard from 'app/components/OsCards/SpaceCard/FolderSpaceCard';

class DynamicCard extends Component {
  render() {
    return (
      <FolderSpaceCard
        {...this.props}
        dropDownRequired={false}
        dynamic={true}
      />
    );
  }
}
DynamicCard = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(DynamicCard);

export default DynamicCard;
