import {
  VIDEO_CHANGE_STATE,
  VIDEO_PROGRESS,
  VIDEO_CHANGE_TYPE,
  VIDEO_CHANGE_MODE,
  VIDEO_CHANGE_IMAGE,
  VIDEO_HASH_CHANGE,
  RESET_VIDEO_DATA,
} from 'app/actions/types';

import {
  CASE_VIDEO_AND_IMAGES_TYPES_ORDERED,
  CASE_VIEWING_MODES_ORDERED,
} from 'app/components/VideoSection/constants';
import { reduce } from 'app/utils/osLodash';

export default function video(
  state = {
    played: 0.5,
    triggeredForm: '',
    playing: false,
    currentVideoType: 'Photos',
    currentViewingMode: 'SBS',
    currentViewImage: 0,
    imageHash: {},
    videoData: reduce(
      CASE_VIDEO_AND_IMAGES_TYPES_ORDERED,
      (hash, type) => {
        hash[type] = {};
        return hash;
      },
      {},
    ),
    images: [],
    videoHash: reduce(
      CASE_VIDEO_AND_IMAGES_TYPES_ORDERED,
      (hash, type) => {
        hash[type] = {};
        return hash;
      },
      {},
    ),
  },
  action,
) {
  switch (action.type) {
    case VIDEO_CHANGE_STATE:
      return { ...state, playing: action.payload.playing };

    case VIDEO_PROGRESS:
      return {
        ...state,
        played: action.payload.played || state.played,
        triggeredForm: action.payload.identifier,
      };

    case VIDEO_CHANGE_TYPE:
      // If you are going to Photos find the earliest photo that matches the current viewing mode and display that
      if (action.payload === 'Photos') {
        if (
          state.currentViewingMode !== 'T1' &&
          state.currentViewingMode !== 'T2'
        ) {
          return {
            ...state,
            currentVideoType: action.payload,
            currentViewingMode: 'SBS',
          };
        } else {
          return { ...state, currentVideoType: action.payload };
        }
      }

      if (state.videoHash[action.payload][state.currentViewingMode]) {
        return { ...state, currentVideoType: action.payload };
      } else {
        let viewingMode = CASE_VIEWING_MODES_ORDERED.find(
          (mode) => state.videoHash[action.payload][mode],
        );
        return {
          ...state,
          currentVideoType: action.payload,
          currentViewingMode: viewingMode,
        };
      }

    case VIDEO_CHANGE_MODE:
      if (state.currentVideoType === 'Photos') {
        return {
          ...state,
          currentViewingMode: action.payload,
          currentViewImage: action.nextImageIndex,
        };
      }
      return { ...state, currentViewingMode: action.payload };

    case VIDEO_CHANGE_IMAGE:
      let currentImage = state.images[action.payload];
      if (currentImage) {
        return { ...state, currentViewImage: action.payload };
      } else {
        return state;
      }

    case VIDEO_HASH_CHANGE:
      // Check current display and reset if it would be blank
      let viewMode = 'SBS';
      let videoType = 'Photos';
      CASE_VIDEO_AND_IMAGES_TYPES_ORDERED.some(function (type) {
        return CASE_VIEWING_MODES_ORDERED.some(function (mode) {
          if (action.payload.videos[type][mode] != null) {
            viewMode = mode;
            videoType = type;
            return true;
          }
          return false;
        }, this);
      }, action.payload);
      return {
        ...state,
        videoHash: action.payload.videos,
        images: action.payload.images,
        imageHash: action.payload.imageHash,
        videoData: action.payload.videoData,
        currentViewingMode: viewMode,
        currentVideoType: videoType,
      };

    case RESET_VIDEO_DATA:
      return { ...state, imageHash: {}, currentViewImage: 0, images: [] };
    default:
      return state;
  }
}
