import { useContext } from 'react';
import { TaskContext } from 'app/context/TaskContext';
import TaskListView from 'app/components/Task/TaskListView';

const UserRelatedTask = ({ initializeDemoHook }) => {
  const {
    viewedTasks,
    fetchNextViewedRecords,
    notViewedTasks,
    fetchNextNotViewedRecords,
    completedAndAssignedToOtherTasks,
    notCompletedAndAssignedToOtherTasks,
    fetchNextCompletedAssignedToOtherRecords,
    fetchNextNotCompletedAssignedToOtherRecords,
  } = useContext(TaskContext);
  const userRelatedTasksView = (type) => {
    switch (type) {
      case 'delegated':
        return (
          <>
            <TaskListView
              idType={'User'}
              results={completedAndAssignedToOtherTasks?.records.results || []}
              listType={'CompletedUserCreatedTasksAssignedToOther'}
              sectionTitle={`Completed (${
                completedAndAssignedToOtherTasks?.records.total || 0
              })`}
              loadMore={fetchNextCompletedAssignedToOtherRecords}
            />
            <TaskListView
              idType={'User'}
              results={
                notCompletedAndAssignedToOtherTasks?.records.results || []
              }
              listType={'NotCompletedUserCreatedTasksAssignedToOther'}
              sectionTitle={`In-Progress (${
                notCompletedAndAssignedToOtherTasks?.records.total || 0
              })`}
              loadMore={fetchNextNotCompletedAssignedToOtherRecords}
            />
          </>
        );
      case 'inbox':
        return (
          <>
            <TaskListView
              idType={'User'}
              results={notViewedTasks?.records.results || []}
              listType={'NotViewedTasks'}
              sectionTitle={`Not Viewed (${
                notViewedTasks?.records.total || 0
              })`}
              loadMore={fetchNextNotViewedRecords}
            />
            <TaskListView
              idType={'User'}
              results={viewedTasks?.records.results || []}
              listType={'ViewedTasks'}
              sectionTitle={`Viewed (${viewedTasks?.records.total || 0})`}
              loadMore={fetchNextViewedRecords}
            />
          </>
        );
      default:
        return null;
    }
  };

  return <>{userRelatedTasksView(initializeDemoHook.user?.value)}</>;
};

export default UserRelatedTask;
