import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import EventTracker from 'app/services/EventTracker';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import parse from 'html-react-parser';

import {
  BLACKLISTED_URL_PATTERNS,
  HASH_TAG_REGEX,
  URL_REGEX,
} from 'app/constants';
import {
  humanizeContentWithMentionees,
  generateMentioneeRegex,
  getFormattedMentionTextFromContent,
  getMentionGroupsAndIdentifierFromContent,
} from 'app/utils/textParseHelper';
import { hashTagUrl } from 'app/utils/entitiesHelper';
import { addProtocolIfNotPresent } from 'app/utils/urlHelper';
import { keys, map } from 'app/utils/osLodash';

class ParseText extends Component {
  state = {
    allContentVisible: false,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  handleCopy = (event) => {
    if (!this.target.contains(event.target) || !event.clipboardData) return;
    event.preventDefault();
    this.saveSelectionToClipboard(event);
  };

  saveSelectionToClipboard(event) {
    const selectedValue = window.getSelection().toString();
    event.clipboardData.setData('text/plain', selectedValue);
    event.clipboardData.setData(
      'text/react-mentions',
      getFormattedMentionTextFromContent(this.props.obj, selectedValue),
    );
  }

  createLinkReplaceObject(index, href, link, newTab = false) {
    let replaceObject = [];
    replaceObject.push(this.getLinkInString(href, link, newTab));
    return replaceObject;
  }

  getLinkInString(href, link, newTab = true) {
    return `<a
      href='${href}'
      class='${
        this.props.linkClassName || 'a-link'
      } position-relative break-word'
      ${newTab ? "target='_blank'" : "onclick='event.preventDefault();'"}
      rel='noopener noreferrer'
    >${link}</a>`;
  }

  convertLinkTextToLink(content) {
    if (!this.props.linkConversionNotRequired) {
      let { content: contents, replaceTexts } =
        this.getReplaceableLink(content);
      return this.getContentWithReplaceableLinks(contents, replaceTexts);
    }
  }

  getContentWithReplaceableLinks(content, replaceTexts) {
    replaceTexts.forEach((replaceText, index) => {
      content = content.replace(`[[{${index}}]]`, replaceText);
    });
    return content;
  }

  getReplaceableLink(content, extractLink = false) {
    let links = content.trim().match(URL_REGEX) || [],
      replaceTexts = [],
      filteredLinks = [];
    links = links.filter(
      (link) =>
        link &&
        !BLACKLISTED_URL_PATTERNS.some((pattern) => link.includes(pattern)),
    );
    links.forEach((link, index) => {
      let href = addProtocolIfNotPresent(link);
      content = content.replace(link, `[[{${index}}]]`);
      filteredLinks.push(link);
      replaceTexts.push(this.createLinkReplaceObject(index, href, link, true));
    });
    return { content, replaceTexts, filteredLinks };
  }

  getExtractedLinks(replaceTexts) {
    let uniqueLinks = [],
      result = '';

    replaceTexts.forEach((replaceObject) => {
      let key = keys(replaceObject)[0];
      if (!uniqueLinks.includes(replaceObject[key])) {
        uniqueLinks.push(replaceObject[key]);
        result += this.getLinkElement(replaceObject[key]);
      }
    });
    return result;
  }

  getLinkElement(link) {
    return `<div class='a-link'>
      <OrthoIcon name="linked" />&nbsp;${link}
    </div>`;
  }

  getParsedMentioneeInfo(content) {
    let mentioneeTagRegex,
      { mentionees, mention_groups } = this.props.obj,
      { parentEntity = { mention_groups: [] } } = this.props;
    const mapper = getMentionGroupsAndIdentifierFromContent(
      content,
      mention_groups || parentEntity.mention_groups,
    );
    keys(mapper).forEach((matchedGroup) => {
      content = content.replaceAll(
        matchedGroup,
        `<span class="font-semibold cl-l10">@${mapper[matchedGroup]}</span>`,
      );
    });

    if (this.props.linkConversionNotRequired) {
      return humanizeContentWithMentionees(mentionees, content);
    }

    if (mentionees && mentionees.length) {
      map(mentionees, (mentionee) => {
        mentioneeTagRegex = generateMentioneeRegex(mentionee.id);
        content = content.replace(
          mentioneeTagRegex,
          this.getLinkInString(
            withWorkspaceIdentifier(`/users/${mentionee.nice_id}`),
            `@${mentionee.full_name}`,
            false,
          ),
        );
      });
    }
    return content;
  }

  // Deprecated method Remove it after some time.
  parsedHashTags(content) {
    let hashTags = map(content.match(HASH_TAG_REGEX), (hashTag) =>
        hashTag.trim(),
      ),
      replaceTexts = [];

    if (hashTags && hashTags.length) {
      hashTags.forEach((text, index) => {
        content = content.replace(text, `[[{${index}}]]`);
        replaceTexts.push(
          `<a
            href='${hashTagUrl(text.slice(1), this.props.parentEntity)}'
            class='${
              this.props.linkClassName || 'a-link'
            } link-text position-relative'
            onclick='event.preventDefault();'
          >${text}</a>`,
        );
      });
      content = this.getContentWithReplaceableLinks(content, replaceTexts);
    }
    return content;
  }

  getQuotedTextIfRequired(content) {
    return this.props.quoteText ? `"${content}"` : content;
  }

  getReadMoreLink() {
    if (this.props.readMoreLinkUrl) {
      return this.getLinkInString(
        this.props.readMoreLinkUrl,
        'Read more',
        true,
      );
    } else {
      let text = this.state.allContentVisible ? 'Read Less' : 'Read More';
      return `<span class='a-link cursor-pointer show-more-hook'>${text}</span>`;
    }
  }

  toggleReadMore = () => {
    this.setState({ allContentVisible: !this.state.allContentVisible });
  };

  truncateTextIfRequired(content) {
    const { truncateWordsUpto } = this.props;
    if (this.isTruncateRequired() && truncateWordsUpto)
      content = this.allWords().slice(0, truncateWordsUpto).join(' ');
    return content;
  }

  allWords() {
    return this.getContent().split(' ');
  }

  isTruncateRequired() {
    return (
      !this.state.allContentVisible &&
      this.isTruncatedWordsLessThaAllWords() &&
      this.props.readMoreRequired
    );
  }

  isTruncatedWordsLessThaAllWords() {
    return (
      this.props.truncateWordsUpto &&
      this.allWords().length > this.props.truncateWordsUpto
    );
  }

  addReadMoreIfRequired(content) {
    if (this.isTruncateRequired()) content += '... ' + this.getReadMoreLink();
    return content;
  }

  getContent() {
    return (this.props.obj.content || '').trim();
  }

  updatedParseHashTags(content) {
    return content.replace(
      /<span\b[^>]*\bdata-hashtag\b="([^"]*)"[^>]*>(.*?)<\/span>/gi,
      (match, hashtag, text) => {
        return `<a href='${hashTagUrl(
          text.slice(1),
          this.props.parentEntity,
        )}' class='${
          this.props.linkClassName || 'a-link'
        } link-text position-relative'>${text}</a>`;
      },
    );
  }

  parseCommentContent() {
    let content = this.getContent();
    content = this.truncateTextIfRequired(content);
    // Not Required anymore with this new text editor.
    // content = this.convertLinkTextToLink(content);
    content = this.getParsedMentioneeInfo(content);
    content = this.updatedParseHashTags(content);
    content = this.addReadMoreIfRequired(content);
    return this.getQuotedTextIfRequired(content);
  }

  parseMessageContent() {
    let content = this.getContent();
    content = this.truncateTextIfRequired(content);
    // Not Required anymore with this new text editor.
    // content = this.convertLinkTextToLink(content);
    content = this.getParsedMentioneeInfo(content);
    content = this.parsedHashTags(content);
    content = this.addReadMoreIfRequired(content);
    return this.getQuotedTextIfRequired(content);
  }

  parseLink() {
    switch (this.props.obj.__typename) {
      case 'ConversationMessage':
        return this.parseMessageContent();
      case 'Comment':
      case 'Note':
      case 'Pulse':
      case 'SchedulePost':
        return this.parseCommentContent();
      default:
        return null;
    }
  }

  onClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      e.stopPropagation();
      EventTracker.trackLinkClicked(e.target.text);
      this.props.onClick && this.props.onClick();
      e.target.target !== '_blank' &&
        this.props.navigate(e.target.getAttribute('href'));
    } else if (e.target.classList.contains('show-more-hook')) {
      this.toggleReadMore();
    }
  };

  render() {
    return (
      <div
        className={`parse-comment-text ${this.props.className}`}
        ref={(el) => (this.target = el)}
        style={{ whiteSpace: 'pre-wrap' }}
        onClick={this.onClick}>
        {parse(this.parseLink())}
      </div>
    );
  }
}

ParseText.defaultProps = {
  className: '',
};
ParseText = withRouter(ParseText);
export default ParseText;
