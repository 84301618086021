import React from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import AbstractDropdown from './AbstractDropdown';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import DeleteConfirmationModal from 'app/components/Shared/DeleteConfirmationModal';
import OsBtn from 'app/components/OsBtn';
import RenameEntity from 'app/components/RenameEntity';

import {
  getRenameSpaceFormUniqueIdentifier,
  isArchiveSpaceRequired,
} from 'app/utils/featureHelper';
import { isSpaceArchived } from 'app/utils/spaceHelper';

const DELETE_FOLDER_MUTATION = queryLoader('app/graphql/DeleteBoard.gql');

class FolderSpaceDropdown extends AbstractDropdown {
  state = {
    deleteInitiated: false,
  };

  getSpace() {
    return this.props.space;
  }

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  toggleDelete = () => {
    this.setState({ deleteInitiated: !this.state.deleteInitiated });
  };

  deleteFolder = () => {
    this.props
      .deleteFolder({
        variables: {
          boardId: this.getSpace().id,
        },
      })
      .then(({ data }) => {
        if (data.deleteBoard.deleted) this.props.navigate('/folders');
      });
  };

  renderRenameButton() {
    let space = this.getSpace();
    if (this.isAuthorOrEditor())
      return (
        <RenameEntity
          obj={space}
          entityId={space.id}
          entityType='Board'
          formNode={getRenameSpaceFormUniqueIdentifier(space)}
          onClick={this.props.onRenameClick}
        />
      );
  }

  renderDeleteButton() {
    let space = this.getSpace();
    if (this.isAuthorOrEditor())
      return (
        <OsBtn
          name='BtnIcon'
          extraClass='list-button'
          icon='delete'
          text='Delete'
          associatedEntity={space}
          onClick={this.toggleDelete}
        />
      );
  }

  renderCustomDropdown() {
    const space = this.getSpace();
    if (!isSpaceArchived(space)) {
      return (
        <>
          <CustomDropdown name='folder_actions'>
            {this.renderRenameButton()}
            {this.renderDeleteButton()}
          </CustomDropdown>
          {this.state.deleteInitiated && (
            <DeleteConfirmationModal
              entityType='folder'
              onClose={this.toggleDelete}
              deleteEntity={this.deleteFolder}
              entity={space}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  }
}

FolderSpaceDropdown.defaultProps = {
  onRenameClick: () => {},
};
FolderSpaceDropdown = graphql(DELETE_FOLDER_MUTATION, { name: 'deleteFolder' })(
  FolderSpaceDropdown,
);
export default FolderSpaceDropdown;
