import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { loader as queryLoader } from 'graphql.macro';

const CLINIC_SHARE_REQUEST_TOKEN_QUERY = queryLoader(
  'app/graphql/Clinic/Queries/Partner/ShareRequest.gql',
);

const useClinicShareRequest = () => {
  const { token } = useParams();
  const { data, loading } = useQuery(CLINIC_SHARE_REQUEST_TOKEN_QUERY, {
    variables: { token },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    token,
  };
};

export default useClinicShareRequest;
