import { MOBILE_HEADER_HEIGHT } from 'app/constants';
import {
  DETAIL_PAGE_NAVLIST_HEIGHT,
  PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
} from 'app/components/SimpleNavList/constants';
import { reduce } from 'app/utils/osLodash';

export const COMMENTABLE_TARGETS = ['Board', 'Case', 'Comparison', 'Pulse'];
export const COMMENT_CATEGORIES = [
  { value: 'diagnosis', representation: '#diagnosis' },
  { value: 'treatment', representation: '#treatment' },
  { value: 'appliances', representation: '#appliances' },
  { value: 'outcome', representation: '#outcome' },
  { value: 'patient_management', representation: '#PatientManagement' },
];

export const COMMENT_CATEGORY_REPRESENTATION = reduce(
  COMMENT_CATEGORIES,
  (hash, category) => {
    hash[category.value] = category.representation;
    return hash;
  },
  {},
);

export const COMMENTS_BATCH_SIZE = 10;
export const REPLIES_BATCH_SIZE = 1000;
export const SORTING_PARAMETERS = [
  { icon: 'popular', name: 'Popular', value: 'popular' },
  { icon: 'recent', name: 'Recently Updated', value: 'recent' },
  { icon: 'calendar-asc', name: 'Oldest', value: 'date-added' },
  { icon: 'calendar-desc', name: 'Newest', value: 'date-added-desc' },
  {
    icon: 'like',
    name: 'Liked by you',
    value: 'liked-by-me',
    guestUserNotAllowed: true,
  },
  {
    icon: 'mentioned',
    name: 'Only You are Mentioned',
    value: 'only-user-mentioned',
    guestUserNotAllowed: true,
  },
];

export const COMMENT_URL_HASH_FORMAT = '#comment-';
export const COMMENT_WITH_NEW_REPLY_URL_HASH_FORMAT = '#reply-to-';
export const EDIT_COMMENT_URL_HASH_FORMAT = '#comment-edit-';
export const SPACE_COMMENT_ORDER_DROPDOWN_OFFSET_TOP = {
  mobile:
    MOBILE_HEADER_HEIGHT +
    DETAIL_PAGE_NAVLIST_HEIGHT.mobile +
    PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
  nonMobile:
    DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
    PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
};

export const SPACE_COMMENT_OFFSET_TOP = {
  mobile:
    MOBILE_HEADER_HEIGHT +
    DETAIL_PAGE_NAVLIST_HEIGHT.mobile +
    PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
  nonMobile:
    DETAIL_PAGE_NAVLIST_HEIGHT.nonMobile +
    PADDING_OF_STICKY_CONTENT_FROM_NAVLIST,
};

export const TASK_ENTITY_NOT_ALLOWED = ['Board', 'PartnerSpace'];
