import React, { Component } from 'react';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { loader as queryLoader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import Avatar from 'app/components/Shared/Avatar';

import { translate } from 'app/actions/flashMessage';

import {
  avatarAttributes,
  getOtherParticipants,
} from 'app/utils/entitiesHelper';
import {
  isTabClicked,
  keysPressedNonAlphabeticNonNumeric,
} from 'app/utils/mixpanelKeyboardHelper';
import { isEmpty } from 'app/utils/osLodash';

import EventTracker from 'app/services/EventTracker';
import DisplayContentPortal from './DisplayContentPortal';
import { hexToRgb } from 'app/utils/colorHelper';

const ASYNC_SEARCH_QUERY = queryLoader(
  'app/graphql/queries/SelectAsyncSearch.gql',
);

class SelectInput extends Component {
  state = {
    input: '',
    keyPressed: [],
    asyncRecords: [],
  };

  onChange = (obj) => {
    if (this.props.input) {
      if (obj) {
        this.props.input.onChange(this.props.isMulti ? obj : obj.value);
      } else {
        this.props.input.onChange(null);
      }
    }

    this.props.onChange && this.props.onChange(obj);
  };

  renderConversationParticipantsAvatar(otherParticipants) {
    return (
      <>
        <Avatar
          className='avatar'
          {...avatarAttributes(otherParticipants[0])}
        />
        {otherParticipants.length > 1 && (
          <Avatar
            className='avatar'
            {...avatarAttributes(otherParticipants[1])}
          />
        )}
      </>
    );
  }

  renderConversationAvatar(conversation) {
    let otherParticipants = getOtherParticipants(conversation);
    return this.renderConversationParticipantsAvatar(otherParticipants);
  }

  renderUserName(result) {
    if (this.props.modifiedResultText) {
      return this.props.modifiedResultText(result);
    } else if (
      result.related_workspace?.id &&
      +result.related_workspace?.id !== +this.props.workspace?.data?.id
    ) {
      return `${result.name || result.full_name} (${
        result.related_workspace.name
      })`;
    } else {
      return result.name || result.full_name;
    }
  }

  optionToDisplay(result) {
    if (result.__typename === 'Conversation') {
      return (
        <div>
          <div className='avatar-combo'>
            {this.renderConversationAvatar(result)}
          </div>
          <div className='result-text-data'>{result.name}</div>
        </div>
      );
    } else if (result.__typename === 'Board') {
      return (
        <div>
          <Avatar
            className='avatar'
            {...avatarAttributes(result, { entityImage: true })}
          />
          <div className='result-text-data'>{result.name}</div>
        </div>
      );
    } else if (result.__typename === 'User') {
      return (
        <div>
          <Avatar className='avatar' {...avatarAttributes(result)} />
          <div className='result-text-data'>{this.renderUserName(result)}</div>
        </div>
      );
    } else if (result.__typename === 'Label') {
      return (
        <div className='labelContainer'>
          <span
            className='labelColor'
            style={{ backgroundColor: `${hexToRgb(result.color)}` }}></span>
          <span className='result-text-data'>{result.name}</span>
        </div>
      );
    } else if (result.__typename === 'Template') {
      return <DisplayContentPortal option={result} />;
    } else {
      return (
        <div>
          <div className='result-text-data'>{result.name}</div>
        </div>
      );
    }
  }

  setOption = (result) => {
    return {
      value: result.id,
      label: this.optionToDisplay(result),
      entity: result,
    };
  };

  loadOptions = (search, loadedOptions, { page }) => {
    let variables = {
      page: page - 1,
      perPage: this.props.perPage || 12,
      type: this.props.queryType,
      text_query: search || this.props.defaultSearchedValue,
      additional_filters: JSON.stringify({
        selectedValue: this.props.formValue,
      }),
      id_type: this.props.id_type,
      id_query: this.props.idQuery,
    };

    return this.props.client
      .query({
        query: ASYNC_SEARCH_QUERY,
        fetchPolicy: 'network-only',
        variables,
      })
      .then(({ data }) => {
        if (data) {
          let hasMore =
              data.records.total >
              loadedOptions.length + data.records.results.length,
            asyncRecords = data.records.results.concat(this.state.asyncRecords),
            results = this.props.filterRecords
              ? this.props.filterRecords(data.records.results)
              : data.records.results;

          this.setState({ asyncRecords });
          if (
            this.props.defaultSearchedValue &&
            (!this.props.value || this.props.value.length === 0)
          ) {
            if (results[0]) {
              const obj = this.setOption(results[0]);
              this.onChange(this.props.isMulti ? [obj] : obj);
            }
          }
          return {
            options: results.map(this.setOption),
            hasMore: hasMore,
            additional: {
              page: page + 1,
            },
          };
        }
      });
  };

  onKeyUp = (e) => {
    EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
    this.setState({ keyPressed: [] });
    this.props.onKeyUp && this.props.onKeyUp(e);
  };

  onKeyDown = (e) => {
    let key = e.key === 'Meta' ? 'cmd' : e.key,
      keyPressed = keysPressedNonAlphabeticNonNumeric(
        this.state.keyPressed,
        key,
      );
    this.setState({ keyPressed });
    if (isTabClicked(key)) {
      EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
      this.setState({ keyPressed: [] });
    }
    this.props.onKeyDown && this.props.onKeyDown(e);
  };

  onBlur = (obj) => {
    if (obj && this.props.input)
      this.props.input.onBlur(
        this.props.isMulti ? this.props.input.value : obj.value,
      );

    this.props.onBlur && this.props.onBlur(obj);
  };

  selectedValue() {
    let attributeToSearch = this.props.selectedValueNameRequired
      ? 'name'
      : 'id';
    if (this.props.formValue) {
      if (this.props.async) {
        if (this.props.setValueDirectly) {
          return this.props.formValue;
        }

        // Following added to compare integer and string in some scenario
        // eslint-disable-next-line eqeqeq
        let selectedValue = this.state.asyncRecords.find(
          (record) => record[attributeToSearch] == this.props.formValue,
        );

        return selectedValue && this.setOption(selectedValue);
      } else {
        if (this.props.isMulti) {
          if (this.props.setValueDirectly) {
            return this.props.formValue;
          }
          return this.props.options.filter((record) =>
            this.props.formValue.includes(record.value),
          );
        } else {
          const option = this.props.options.find(
            (record) =>
              record.value === this.props.formValue ||
              record[attributeToSearch] == this.props.formValue,
          );
          return this.props.optionProcessingRequired && option
            ? this.setOption(option)
            : option;
        }
      }
    } else {
      let value =
        this.props.value || (this.props.input && this.props.input.value);
      return !isEmpty(value) && value;
    }
  }

  onInputChange = (input) => {
    this.setState({ input }, () => {
      this.props.onInputChange(input);
    });
  };

  getOptions = () => {
    if (this.props.optionProcessingRequired) {
      return this.props.options.map(this.setOption);
    } else {
      return this.props.options;
    }
  };

  render() {
    let noOptionsMessage = this.state.input
        ? translate('RESULTS_NOT_FOUND', { searchInput: this.state.input })
        : this.state.asyncRecords.length === 0
        ? translate(this.props.emptyRecordsMessage)
        : null,
      Element = this.props.async ? AsyncPaginate : Select,
      asyncProps = this.props.async
        ? { defaultOptions: true, debounceTimeout: 200 }
        : {},
      extraClass = this.props.isMulti ? 'multi-select' : 'single-select';
    return (
      <div className={`col px-0 ${extraClass}`} onKeyUp={this.onKeyUp}>
        <Element
          {...this.props}
          {...asyncProps}
          loadOptions={this.loadOptions.bind(this)}
          additional={{ page: 1 }}
          value={this.selectedValue()}
          placeholder={this.props.placeholder || null}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          className={`${
            this.props.meta && this.props.meta.touched && this.props.meta.error
              ? 'error'
              : ''
          } ${this.props.className}`}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          options={this.getOptions()}
          closeMenuOnSelect={
            !this.props.isMulti || this.props.closeMenuOnSelect
          }
          isSearchable={this.props.isSearchable}
          isDisabled={this.props.isDisabled}
          blurInputOnSelect={false}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={() =>
            this.props.noOptionsMessage || noOptionsMessage
          }
          classNamePrefix='os'
          controlShouldRenderValue={
            this.props.controlShouldRenderValue ||
            this.props.controlShouldRenderValue
          }
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral0: '#303036',
              primary50: '#303030',
              primary25: '#48acfd33',
              primary: '#48acfd33',
              neutral80: 'white',
            },
          })}
          menuPortalTarget={this.props.menuPortalTarget && document.body}
          menuPlacement='auto'
          styles={
            this.props.style || {
              control: (provided, state) => {
                return {
                  ...provided,
                  color: '#ffffffe6',
                  backgroundColor: '#303036',
                  borderColor: '#303036',
                  boxShadow: state.isFocused ? '0 0 0 0 #cae7fe' : 'none',
                  minHeight: '40px',
                  '&:hover': { borderColor: '#303036' },
                  '&:focus': { borderColor: '#48acfd' },
                };
              },
              multiValueRemove: (base) => ({
                ...base,
                fontSize: '20px',
                lineHeight: '18px',
                color: 'rgba(255,255,255,.9)',
                backgroundColor: 'transparent',
                width: '18px',
                height: '18px',
                alignItems: 'center',
                padding: '0 2px',
                textAlign: 'center',
                cursor: 'pointer',
                marginLeft: '5px',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'rgba(255,255,255,.9)',
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#48acfd',
                borderRadius: '4px',
                fontSize: '16px',
                lineHeight: '25px',
                alignItems: 'center',
                padding: '0 5px',
                '&:hover': { color: '#48acfd' },
                '&:focus': { color: 'rgba(255,255,255,.9)' },
              }),

              menu: (base) => ({
                ...base,
                margin: '0',
                padding: '0',
                className: 'invit-select',
              }),

              menuList: (base) => ({
                ...base,
                padding: '0',
                maxHeight: '320px',
                className: 'invit-select-list',
              }),

              singleValue: (base) => ({
                ...base,
                color: 'rgba(255,255,255,.9)',
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: '1em',
                color: 'rgba(255,255,255,.6)',
                fontWeight: 400,
                padding: 0,
              }),
              option: (provided, state) => ({
                ...provided,
                color: 'rgba(255,255,255,.9)',
                '&:hover': { background: '#48acfd33', color: '#48acfd' },
                '&:focus': { color: '#0077c3' },
              }),
            }
          }
        />
      </div>
    );
  }
}

SelectInput = connect(({ workspace }) => ({ workspace }))(SelectInput);

SelectInput = withApollo(SelectInput);
SelectInput.defaultProps = {
  onInputChange: () => {},
  controlShouldRenderValue: true,
};
export default SelectInput;
