import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import Dropzone from 'react-dropzone';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import { FORM_ERROR } from 'final-form';

import CustomDropdown from 'app/components/Shared/CustomDropdown';
import FilesTab from './FilesTab';
import ImageBlock from './ImageBlock';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import { translate } from 'app/actions/flashMessage';
import { has, keys, map, omit, some, values } from 'app/utils/osLodash';
import { fieldRequired } from 'app/utils/validationHelper.js';
import { timeToDate, timeWithFormat } from 'app/utils/timeHelper.js';
import {
  APPOINTMENT_TYPE_MAPPER,
  APPOINTMENT_TYPE_OTHER_ICON,
} from 'app/components/CaseRightSection/constants.js';

import StoreUpdater from 'app/services/StoreUpdater';

const APPOINTMENT_KIND_MAPPER = {
  in_office: 'In-Office',
  virtual: 'Virtual',
};

const NORMAL_IMAGE_NAMES = [
  'Frontal relaxed',
  'Frontal smiling',
  '3/4 smiling',
  'Closed anterior',
  'Right buccal',
  'Left buccal',
  'Upper occlusal',
  'Lower occlusal',
];
const INFO_TAB_IDENTIFIER = 'appointment_info';
const XRAY_IMAGE_NAMES = ['PAN', 'CEPH'];

const UPDATE_CASE_APPOINTMENT_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateCaseAppointment.gql',
);
const DROPZONE_SOURCE_NAME = 'new-appointment-files';
const NEXT_TAB_MAPPER = {
  appointment_info: 'images',
  images: 'x-rays',
  'x-rays': 'files',
};

class AppointmentForm extends Component {
  constructor(props) {
    super(props);
    this.folderName = `uploads/cases/${props.kase.nice_id}/`;
    this.state = {
      show: true,
      activeTab: INFO_TAB_IDENTIFIER,
      attachmentsLoader: {},
    };
    this.imagesHash = {};
    this.attachmentIds = this.getAttachmentIds();
    this.removeImages = [];
  }

  getAttachmentIds() {
    return this.isNew() ? [] : map(this.props.appointment.attachments, 'id');
  }

  isNew() {
    return !this.props.appointment;
  }

  setAttachmentUploader = (attachmentsLoaderHash) => {
    this.setState({
      attachmentsLoader: {
        ...this.state.attachmentsLoader,
        ...attachmentsLoaderHash,
      },
    });
  };

  updateImageHash = (newEntry) => {
    this.imagesHash = { ...this.imagesHash, ...newEntry };
  };

  addAttachmentIds = (fileId) => {
    this.attachmentIds = this.attachmentIds.concat(fileId);
  };

  removeAttachmentIds = (fileId) => {
    this.attachmentIds = this.attachmentIds.filter((id) => id !== fileId);
  };

  closeModal = () => {
    this.setState({ show: false });
    this.props.onClose();
  };

  onSubmit = (values) => {
    let caseId = this.props.kase.nice_id;
    this.setState({ requestInProgress: true });
    return this.props
      .updateCaseAppointment({
        variables: {
          id: this.props.appointment?.id,
          caseId,
          ...values,
          date: values.date.toString(),
          imagesHash: JSON.stringify(this.imagesHash),
          attachmentIds: this.attachmentIds,
          removeImages: this.removeImages,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        if (data.updateCaseAppointment.success) {
          StoreUpdater.modifyAppointmentInCase(
            data.updateCaseAppointment.entity,
            caseId,
          );
          this.closeModal();
        } else {
          return { [FORM_ERROR]: data.updateCaseAppointment.error };
        }
      });
  };

  changeFormValue = (attribute, value) => {
    this.form.change(attribute, value);
  };

  setKind = (kind) => {
    this.changeFormValue('kind', kind);
  };

  renderKindDropdownItem = (key) => {
    return (
      <div
        className='type-dropdown-item'
        key={key}
        onClick={this.setKind.bind(this, key)}>
        {APPOINTMENT_KIND_MAPPER[key]}
      </div>
    );
  };

  setType = (type) => {
    this.changeFormValue('type', type);
  };

  renderTypeDropdownItem = (key) => {
    let details = APPOINTMENT_TYPE_MAPPER[key];
    return (
      <div
        className='type-dropdown-item'
        key={key}
        onClick={this.setType.bind(this, key)}>
        <OrthoIcon name={details.icon} />
        {details.name}
      </div>
    );
  };

  getKindTitle() {
    return APPOINTMENT_KIND_MAPPER[this.formValues.kind];
  }

  getTypeTitleProps() {
    let { type } = this.formValues;
    if (type && type !== 'other') {
      let details = APPOINTMENT_TYPE_MAPPER[this.formValues.type];
      return { title: details.name, icon: details.icon };
    } else {
      return { title: 'Appointment Type', icon: APPOINTMENT_TYPE_OTHER_ICON };
    }
  }

  switchToFormTab = () => {
    this.setState({ activeTab: INFO_TAB_IDENTIFIER });
  };

  renderActiveTreatmentFields() {
    let { treatment_start_date } = this.props.kase;

    if (treatment_start_date) {
      return (
        <>
          <span className='started-text me-2'>
            Started on: {timeWithFormat(treatment_start_date, 'MMMM D, YYYY')}
          </span>
          <Field
            component={OsField}
            osType='checkbox'
            name='endedToday'
            className='os-custom-checkbox'
            label='Ended today'
          />
        </>
      );
    } else {
      return (
        <Field
          component={OsField}
          osType='checkbox'
          name='startedToday'
          className='os-custom-checkbox'
          label='Started today'
        />
      );
    }
  }

  getInitialValues() {
    if (this.isNew()) {
      return { kind: keys(APPOINTMENT_KIND_MAPPER)[0] };
    } else {
      let { date, kind, label, notes, type } = this.props.appointment;
      return { date: timeToDate(date), kind, label, notes, type };
    }
  }

  renderDetailsFields() {
    return (
      <Tab eventKey={INFO_TAB_IDENTIFIER} title='Appointment info'>
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.getInitialValues()}
          keepDirtyOnReinitialize={true}
          render={(props) => {
            this.submitForm = props.handleSubmit;
            this.formValues = props.values;
            this.form = props.form;
            return (
              <form className='appointment-info' onSubmit={this.submitForm}>
                {props.submitError && (
                  <div className='error'>{props.submitError}</div>
                )}
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-5 col-12'>
                    <div className='row'>
                      <div className='col-sm-6 date-calendar'>
                        <Field
                          name='date'
                          type='date'
                          osType='date'
                          component={OsField}
                          label='Select Date'
                          autoComplete='off'
                          pastDateSelectable={true}
                          validate={fieldRequired}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label>Select location</label>
                          <Field
                            component={CustomDropdown}
                            className='appointment-type-dropdown'
                            dropdownClass='os-dropdown'
                            name='kind'
                            dropdownInfo={{ title: this.getKindTitle() }}
                            chevronIcon='chevron-caret'>
                            {keys(APPOINTMENT_KIND_MAPPER).map(
                              this.renderKindDropdownItem,
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <label className='not-mandatory'>
                            Select appointment type
                          </label>
                          <Field
                            component={CustomDropdown}
                            className='appointment-type-dropdown'
                            dropdownClass='os-dropdown'
                            name='type'
                            dropdownInfo={this.getTypeTitleProps()}
                            chevronIcon='chevron-caret'>
                            {keys(APPOINTMENT_TYPE_MAPPER).map(
                              this.renderTypeDropdownItem,
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Field
                          osType='input'
                          component={OsField}
                          placeholder='Appointment Label'
                          name='label'
                          label='Enter category'
                          validate={fieldRequired}
                        />
                      </div>
                    </div>
                    <div className='row d-none'>
                      <div className='col-sm-12'>
                        <label className='label-referral'> Referral to</label>
                        <OsField
                          type='input'
                          placeholder='Click here to search members'
                          className={''}
                          name='search'
                          label=''
                        />
                      </div>
                    </div>
                    <div className='row '>
                      <label className='label-treatment'>
                        Active Treatment
                      </label>
                      <div className='checkbox-group'>
                        {this.renderActiveTreatmentFields()}
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-7 col-12 textarea-note'>
                    <Field
                      osType='textarea'
                      component={OsField}
                      className='add-notes'
                      name='notes'
                      label='Add notes'
                      validate={fieldRequired}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Tab>
    );
  }

  getActiveTab() {
    return this.state.activeTab;
  }

  handleTabSelect = (activeTab) => {
    this.setState({ activeTab });
  };

  isOnInfoTab() {
    return this.state.activeTab === INFO_TAB_IDENTIFIER;
  }

  isOnFileTab() {
    return this.state.activeTab === 'files';
  }

  getTabHint() {
    if (!this.isOnInfoTab()) {
      let textKey = this.isOnFileTab()
        ? 'NEW_APPOINTMENT_OPTIONAL_FILES_HINT'
        : 'NEW_APPOINTMENT_DRAG_FILES_HINT';
      return (
        <div className='col-12 col-md-5 col-lg-6 col-xl-7 ps-0'>
          <span className='drag-drop-text'>{translate(textKey)}</span>
        </div>
      );
    }
  }

  onSubmitClick = (event) => {
    this.submitForm(event);
    !this.form.getState().valid &&
      !this.isOnInfoTab() &&
      this.switchToFormTab();
  };

  imageBlockCommonProps() {
    return {
      folderName: this.folderName,
      onSuccessFullAssetUpload: this.updateImageHash,
      setAttachmentUploader: this.setAttachmentUploader,
      onRemoveImageClick: this.onRemoveImageClick,
    };
  }

  onRemoveImageClick = (fileName) => {
    if (has(this.imagesHash, fileName)) {
      this.imagesHash = omit(this.imagesHash, fileName);
    } else {
      this.removeImages.push(fileName);
    }
  };

  renderImageBlock = (name) => {
    let file = this.isNew()
      ? null
      : this.props.appointment.images.find((img) => img.name === name);
    if (file) file.preview = file.photo;
    return (
      <ImageBlock
        fileName={name}
        {...this.imageBlockCommonProps()}
        dropzoneSource={DROPZONE_SOURCE_NAME}
        files={file ? [file] : []}
      />
    );
  };

  renderImagesTab() {
    return (
      <Tab eventKey='images' title='Images'>
        <article className='appointment-modal-image'>
          <div className='row appoint-image-row'>
            {NORMAL_IMAGE_NAMES.map(this.renderImageBlock)}
          </div>
        </article>
      </Tab>
    );
  }

  renderXrayImagesTab() {
    return (
      <Tab eventKey='x-rays' title='X-Rays'>
        <article className='appointment-modal-image appointment-xray-image'>
          <div className='row appoint-image-row'>
            {XRAY_IMAGE_NAMES.map(this.renderImageBlock)}
          </div>
        </article>
      </Tab>
    );
  }

  saveButtonDisabled() {
    return (
      this.state.requestInProgress || some(values(this.state.attachmentsLoader))
    );
  }

  renderFilesTab() {
    let files = this.isNew() ? [] : this.props.appointment.attachments;
    return (
      <Tab eventKey='files' title='Files'>
        <FilesTab
          onAddAttachment={this.addAttachmentIds}
          onRemoveAttachment={this.removeAttachmentIds}
          setAttachmentUploader={this.setAttachmentUploader}
          dropzoneSource={DROPZONE_SOURCE_NAME}
          files={files}
        />
      </Tab>
    );
  }

  goToNextTab = () => {
    let nextTab = NEXT_TAB_MAPPER[this.state.activeTab];
    if (nextTab) this.setState({ activeTab: nextTab });
  };

  renderPrimaryButton() {
    let submitButtonRequired = !this.isNew() || this.isOnFileTab(),
      saveButtonDisabled = this.saveButtonDisabled(),
      commonProps = {
        name: 'BtnPrimary',
        extraClass: 'web-view-btn',
        disabled: saveButtonDisabled,
        loaderRequired: saveButtonDisabled,
        associatedEntity: this.props.kase,
      };

    if (submitButtonRequired) {
      return (
        <OsBtn
          {...commonProps}
          type='submit'
          htmlTag='button'
          text='Save appointment'
          onClick={this.onSubmitClick}
        />
      );
    } else {
      return <OsBtn {...commonProps} text='Next' onClick={this.goToNextTab} />;
    }
  }

  render() {
    let saveButtonDisabled = this.saveButtonDisabled();

    return (
      <Modal
        show={this.state.show}
        animation={false}
        dialogClassName='appointment-modal'
        backdropClassName='modal-backdrop-custom backdrop-message-modal'>
        <Modal.Header className='border-0 p-0'>
          <h4 className='modal-title'>
            {this.isNew() ? 'New' : 'Edit'} appointment
          </h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-edit-address d-none'
            icon='close'
            label='Close new appointment modal'
            onClick={this.closeModal}
          />
        </Modal.Header>
        <Modal.Body className=''>
          <Tabs
            activeKey={this.getActiveTab()}
            id='appointment_tabs'
            className='mb-3 appointment_nav'
            onSelect={this.handleTabSelect}>
            {this.renderDetailsFields()}
            {this.renderImagesTab()}
            {this.renderXrayImagesTab()}
            {this.renderFilesTab()}
          </Tabs>
        </Modal.Body>
        <Modal.Footer className='new-appoint-footer px-0 mx-0'>
          <div className='row d-flex align-items-center justify-content-'>
            {this.getTabHint()}
            <div className='col text-right pe-0'>
              <OsBtn
                name='BtnIcon'
                extraClass='with-border me-2 px-3 web-view-btn'
                disabled={saveButtonDisabled}
                onClick={this.closeModal}
                text='Cancel Entry'
                associatedEntity={this.props.kase}
              />
              {this.renderPrimaryButton()}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

AppointmentForm.defaultProps = {
  onClose: () => {},
};
AppointmentForm = graphql(UPDATE_CASE_APPOINTMENT_MUTATION, {
  name: 'updateCaseAppointment',
})(AppointmentForm);
AppointmentForm = withApollo(AppointmentForm);
export default AppointmentForm;
