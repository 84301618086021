import { OPEN_POLICIES_MODAL, CLOSE_POLICIES_MODAL } from './types';

export function openPoliciesModal(options = {}) {
  return {
    type: OPEN_POLICIES_MODAL,
    options,
  };
}

export function closePoliciesModal() {
  return {
    type: CLOSE_POLICIES_MODAL,
  };
}
