import React, { Component } from 'react';
import OsBtn from 'app/components/OsBtn';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { timeWithFormat } from 'app/utils/timeHelper';
import { isImage, isVideo } from 'app/utils/fileHelper';
const DATE_FORMAT = 'MM/DD/YYYY';

class TableViewCard extends Component {
  getIcon(file) {
    let { type } = file;
    if (isImage(file)) return 'image';
    else if (isVideo(file)) return 'video';
    else if (type === 'Link') return 'additionallink';
    else return 'document';
  }

  renderSelectBox() {
    let isSelected = this.props.selected,
      icon = isSelected ? 'roundCheck' : 'roundCheckDefault';
    return (
      <OsBtn
        name='BtnIcon'
        icon={icon}
        extraClass={`selectable-item ${isSelected ? 'selected' : ''} `}
        onClick={this.props.onSelectBoxClick}
      />
    );
  }

  getIcon(file) {
    let { type } = file;
    if (isImage(file)) return 'image';
    else if (isVideo(file)) return 'video';
    else if (type === 'Link') return 'additionallink';
    else return 'document';
  }

  render() {
    const {
      image,
      contentChildren,
      title,
      subtitle,
      onClick,
      dateAdded,
      type,
      lastUsedTitle,
      lastUsedSubtitle,
    } = this.props;
    return (
      <div className='table-card-row ' onClick={onClick}>
        <div className='folder-table-block name-block'>
          <div className='cd-list-img'>
            {image && <img src={image} alt={title} className='img-parent' />}
            {!image && contentChildren}
            {!image && !contentChildren && (
              <OrthoIcon
                name={this.getIcon(this.props)}
                className='card-link-svg'
              />
            )}
          </div>
          <div className='cd-list-info'>
            <span className='cd-file-name'>{title}</span>
            <span className='cd-extra-info'>{subtitle}</span>
          </div>
        </div>
        <div className='folder-table-block cd-date-added'>
          {timeWithFormat(dateAdded, DATE_FORMAT)}
        </div>
        <div className='folder-table-block file-type'>{type}</div>
        <div className='folder-table-block cd-last-used d-none'>
          <div className='date-used'>
            {timeWithFormat(lastUsedTitle, DATE_FORMAT)}
          </div>
          <div className='carespace-name'>{lastUsedSubtitle}</div>
        </div>
        <div className='folder-table-block cd-list-select '>
          {this.props.dropDownContent}
        </div>
        {this.props.multiSelectMode && (
          <div className='folder-table-block cd-list-select select-box'>
            {this.renderSelectBox()}
          </div>
        )}
        {this.props.renderEnityRenameForm}
      </div>
    );
  }
}

export default TableViewCard;
