import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import immutableUpdate from 'immutability-helper';
import { useDispatch } from 'react-redux';

import MessageBlock from 'app/components/MessageBlock';
import { map } from 'app/utils/osLodash';
import usePreviousValue from 'app/hooks/usePreviousValue';
import {
  timestamp,
  timestampOnHover,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { cloneDeep, isEmpty } from 'app/utils/osLodash';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { isTaskCommentActivity } from 'app/utils/taskHelper';
import { mergeWorkspaceData } from 'app/actions/workspace';

const WORKSPACE_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/WorkspaceSubscription.gql',
);

const TaskActivityListing = (props) => {
  const dispatch = useDispatch();
  const messageEndRef = useRef(null);
  const currentUser = useSelector((state) => state.currentUser);
  const { id: workspaceId } = useContext(WorkspaceContext);
  const isPreviousMessageIsSameAsAuthor = (activityList, activity, index) => {
    const previousComment = activityList[index - 1]?.objects[0];
    if (isTaskActivity(activityList[index - 1])) {
      // If activity ignore the check
      return false;
    }
    let message = activity.objects?.[0];
    return previousComment && previousComment.author.id === message?.author.id;
  };
  const previousComments = usePreviousValue(props.results);

  useEffect(() => {
    // received new message
    const prevResultLength = previousComments?.length;
    const newResults = props.results;
    const newResultLength = newResults.length;
    props.updateActivityCount(props.totalRecords);
    if (prevResultLength > 0 && prevResultLength < newResultLength) {
      let messageElement,
        newMessagePresent =
          newResultLength - prevResultLength === 1 &&
          !map(previousComments, 'id').includes(
            newResults[newResultLength - 1].id,
          );

      if (newMessagePresent) {
        let newMessage = newResults[newResultLength - 1]?.objects[0];
        messageElement = getMessageElementById(newMessage.id);

        if (isOwnMessage(newMessage)) {
          // Message send: scroll to message
          scrollToElement(messageElement, 100);
        } else {
          scrollToElement(messageElement, 100);
        }
      } else {
        // New batch loaded: scroll to first message of the new batch
        let scrollToMessageId =
          newResults[newResultLength - prevResultLength - 1].id;
        messageElement = getMessageElementById(scrollToMessageId);
        scrollToElement(messageElement, 100);
      }
    }
  }, [props.results]);

  const getActivityObject = (activity) => {
    if (!activity) {
      return null;
    }

    const object = activity.objects && activity.objects[0];
    if (object) return object;

    return null;
  };

  useEffect(() => {
    let unsubscribe = () => {};
    if (workspaceId && props.subscribeToMore) {
      unsubscribe = props.subscribeToMore({
        document: WORKSPACE_SUBSCRIPTION,
        variables: { workspaceId: workspaceId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity: activity } =
            subscriptionData.data.workspace_entity_update_subscription;
          if (!isTaskCommentActivity(activity)) return prev;
          let updatedData = cloneDeep(prev);

          if (
            activity?.entities[0]?.id !=
            updatedData.records.results[0]?.entities[0]?.id
          )
            return prev;

          if (
            updatedData.records.results.some(
              (obj) =>
                getActivityObject(activity).id.toString() ===
                getActivityObject(obj).id.toString(),
            )
          ) {
            updatedData.records.results = updatedData.records.results.filter(
              (obj) =>
                getActivityObject(activity).id.toString() !==
                getActivityObject(obj).id.toString(),
            );
          }
          let payload = { records: {} };
          payload.records.results = { $unshift: [activity] };
          updatedData = immutableUpdate(updatedData, payload);
          return Object.assign({}, prev, updatedData);
        },
      });
    }
    return () => unsubscribe();
  }, [workspaceId]);

  useEffect(() => {
    props.fullViewListing && scrollToBottom(500);
  }, []);

  const scrollToBottom = (timeout) => {
    setTimeout(() => {
      messageEndRef.current &&
        messageEndRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, timeout);
  };

  const getMessageElementById = (messageId) => {
    return document.getElementById('comment-' + messageId);
  };

  const isOwnMessage = (message) => {
    return message.author.id === currentUser.graph.id;
  };
  const scrollToElement = (element, timeout = 0) => {
    setTimeout(() => {
      element &&
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, timeout);
  };

  const isTaskActivity = (activity) => {
    const { objects } = activity;
    if (objects[0]?.__typename !== 'Comment') return true;
    return false;
  };

  const renderRow = (activity, index) => {
    if (isTaskActivity(activity)) {
      return (
        <div
          key={activity?.id}
          className='activity-text'
          title={timestampOnHover(activity.visible_at)}>
          {activity.template_text}
          {activity.objects[0]?.__typename !== 'TimeSection' &&
            activity.visible_at && (
              <span className='timestamp'>
                {' '}
                &nbsp;•&nbsp;{timestamp(activity.visible_at)}{' '}
              </span>
            )}
        </div>
      );
    } else {
      return (
        <MessageBlock
          type='comment'
          key={activity?.objects?.[0].id}
          entity={activity?.objects?.[0]}
          actionType={'task'}
          parentRef={props.parentRef}
          previousMessageIsSameAsAuthor={isPreviousMessageIsSameAsAuthor(
            activityWithSections,
            activity,
            index,
          )}
        />
      );
    }
  };

  const activityWithSections = useMemo(() => {
    const taskActivitySections = [];
    let currentDate = null;
    for (let i = 0; i < props.results.length; i++) {
      const activity = props.results[i];
      if (!isTaskActivity(activity)) {
        const comment = activity.objects?.[0];
        const formattedDate = timeWithFormat(
          comment.created_at,
          'MM/DD/YYYY',
          true,
          {
            todayFormatRequired: true,
            dayNameNotRequired: true,
          },
        );
        if (formattedDate !== currentDate) {
          let sectionData = {
            __typename: 'TimeSection',
            id: comment.created_at,
            objects: [{ __typename: 'TimeSection' }],
            template_text: formattedDate,
            visible_at: comment.created_at,
          };
          taskActivitySections.push(sectionData);
          currentDate = formattedDate;
        }
      }

      taskActivitySections.push(activity);
    }
    return taskActivitySections;
  }, [props.results]);

  if (props.loading) return <Loader type='ball-triangle-path' active />;

  return (
    <>
      {!props.loading &&
        activityWithSections.length > 0 &&
        activityWithSections.map((activity, index) =>
          renderRow(activity, index),
        )}

      <div ref={messageEndRef}></div>
    </>
  );
};

export default TaskActivityListing;
