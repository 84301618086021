import React, { Component } from 'react';
import { connect } from 'react-redux';

import EmojiList from 'app/components/EmojiIconBar/EmojiList';
import { EmojiPicker } from 'app/components/EmojiIconBar/EmojiWrapper';

import { groupBy, keys, uniqBy } from 'app/utils/osLodash';

class EmojiIconBar extends Component {
  getModifiedEmojiReactionDetail() {
    const { emojiReactionDetail } = this.props;
    if (emojiReactionDetail) {
      const uniqReactions = uniqBy(
          emojiReactionDetail.emoji_reaction_users,
          'id',
        ),
        list = [],
        groupedReactions = groupBy(uniqReactions, 'identifier');
      keys(groupedReactions).forEach((emojiName) => {
        list.push({
          emojiName,
          users: groupedReactions[emojiName],
          active: groupedReactions[emojiName].some(
            (s) => s.reacted_by_current_user,
          ),
        });
      });
      return {
        id: emojiReactionDetail.id,
        list,
        emojiReactionUsers: emojiReactionDetail.emoji_reaction_users,
      };
    } else {
      return null;
    }
  }
  getProps() {
    return {
      entityType: this.props.entityType,
      entityId: this.props.entityId,
      emojiReactionDetail: this.getModifiedEmojiReactionDetail(),
      user: this.props.currentUser.graph,
      userlistClass: this.props.userlistClass,
      popoverClass: this.props.popoverClass,
      renderSelectedEmojisCallback: this.props.renderSelectedEmojisCallback,
      hideDefaultSelectedEmoji: this.props.hideDefaultSelectedEmoji,
    };
  }

  render() {
    return (
      <>
        <EmojiList {...this.getProps()} />
        <div className='d-none'>
          <EmojiPicker set='native' previewPosition='none' />
        </div>
      </>
    );
  }
}

EmojiIconBar = connect(({ currentUser }) => ({ currentUser }))(EmojiIconBar);
export default EmojiIconBar;
