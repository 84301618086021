import React, { Component } from 'react';

import AddTo from 'app/components/Shared/AddTo';
import Follow from 'app/components/Shared/Follow';
import Share from 'app/components/Shared/Share';

class AbstractDropdown extends Component {
  getEntity() {
    return this.props.entity;
  }

  renderFollowButton(entity) {
    return (
      <Follow
        propagateClick={true}
        key={entity.followed_by_current_user ? 'unfollow' : 'follow'}
        type='case'
        obj={entity}
        labelRequired={true}
        extraClass='list-button'
      />
    );
  }

  renderAddTo(entity) {
    return <AddTo key='add_to_space' obj={entity} extraClass='list-button' />;
  }

  renderShareButton(entity) {
    return (
      <Share
        type='Case'
        object={entity}
        label={'SHARE'}
        extraClass='list-button'
        inDropdown={true}
      />
    );
  }

  renderRequired() {
    return true;
  }

  render() {
    const entity = this.getEntity();
    if (this.renderRequired(entity)) {
      return (
        <div className='header-dropdown-buttons cs-right-dropdown'>
          {this.renderCustomDropdown()}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AbstractDropdown;
