import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OsGrid from 'app/components/OsGrid';
import withAuthorize from 'app/components/HOC/withAuthorize';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';

class PopularTopicsCollection extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.entity &&
      this.props.entity &&
      prevProps.entity.comments_count !== this.props.entity.comments_count
    ) {
      // Dev Note: Delay added because comments_count is increased by optimistic response only
      setTimeout(this.props.refetch, 1000);
    }
  }

  selectedHashTag() {
    return this.props.entity
      ? this.props.match.params.additionalFilter
      : this.props.match.params.filterBy;
  }

  isTagActive(tag) {
    return (
      this.selectedHashTag() &&
      this.selectedHashTag().toLowerCase() === tag.name.toLowerCase()
    );
  }

  setSelectedTag(tag) {
    const searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set('filter', tag.name);

    this.props.navigate(
      `${this.props.location.pathname}?${searchParams.toString()}`,
    );
  }

  renderTopic = (tag) => {
    return (
      <span
        className={'hashtags ' + (this.isTagActive(tag) ? 'active' : '')}
        data-hover={!isTouchSupported()}
        key={tag.id}
        onClick={() => this.setSelectedTag(tag)}>
        #{tag.name}
      </span>
    );
  };

  isWidget() {
    return this.props.source === 'widget';
  }

  renderTopics() {
    if (this.isWidget()) {
      return this.props.results.slice(0, 5).map(this.renderTopic);
    }
    return this.props.results.map(this.renderTopic);
  }

  renderContent() {
    return (
      <div className={this.isWidget() ? ' ' : ' popular-topics'}>
        <div className='hashtag-blocks'>{this.renderTopics()}</div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

PopularTopicsCollection = connect(({ currentUser, workspace }) => ({
  currentUser,
  workspace,
}))(PopularTopicsCollection);
PopularTopicsCollection = withAuthorize(PopularTopicsCollection);
PopularTopicsCollection = withRouter(PopularTopicsCollection);
PopularTopicsCollection.defaultProps = {
  abilityObject: getAuthorizeObject('index', 'explore'),
};
export default PopularTopicsCollection;
