import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  COMMENTS_BATCH_SIZE,
  REPLIES_BATCH_SIZE,
} from 'app/components/CommentSection/constants';

import OsBtn from 'app/components/OsBtn';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';
import OsLink from 'app/components/OsLink';
import ExplorePostInitiator from 'app/components/PulsesView/ExplorePostInitiator';
import Avatar from 'app/components/Shared/Avatar';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import Share from 'app/components/Shared/Share';
import VideoRecordButton from 'app/components/Shared/VideoRecordButton';
import {
  commentShareConsume,
  commentShareOpen,
} from 'app/actions/commentShare';
import { clearDraft, updateCommentDraft } from 'app/actions/drafts';
import { translate } from 'app/actions/flashMessage';
import { openPoliciesModal } from 'app/actions/policiesModal';
import { getDraftKey } from 'app/reducers/drafts';

import {
  isCareWorkspaceView,
  isWorkspaceMemberOrEditor,
  isWorkspaceSpace,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { getPlaceholderText } from 'app/utils/commentHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import {
  avatarAttributes,
  buildFormattedIds,
  entityUrl,
  mapLocalFileWithAttachmentObject,
} from 'app/utils/entitiesHelper';
import { isBlob } from 'app/utils/fileHelper';
import {
  ceil,
  find,
  isEmpty,
  isEqual,
  lowerCase,
  map,
  now,
  pull,
  reject,
} from 'app/utils/osLodash';
import {
  addAttachmentBannerKey,
  isCareSpace,
  isNonOrthoPresent,
  isOpenSpace,
} from 'app/utils/spaceHelper';
import {
  parseContentWithTag,
  removeHashMarkup,
  removeMentioneeName,
} from 'app/utils/textParseHelper';

import CustomCalendar from 'app/components/Comments/CustomCalendar';
import ScheduleDropdown from 'app/components/Comments/ScheduleDropdown';
import { setKeyValueInLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';
import crossCircle from 'app/images/task-manager/crossCircle.svg';
import EventTracker from 'app/services/EventTracker';
import StoreUpdater from 'app/services/StoreUpdater';
import {
  changeMentioneeNameWithId,
  tokenNodePresent,
} from 'app/utils/TiptapEditor/editorHelper';
import {
  add,
  addTimezoneOffset,
  currentTimeWithUserTimeZone,
  isTimeInRange,
} from 'app/utils/timeHelper';
import dayjs from 'dayjs';
import { ALL_DAYS } from 'app/components/Task/taskConstant';
import AttachmentDropdown from '../Shared/AttachmentDropdown';
import TipTapEditor from 'app/components/TipTapEditor/Editor';
import { openInfoModal } from 'app/actions/infoModal';
import { openTaskModalWithEntity } from 'app/actions/taskModal';
import Tippy from '@tippyjs/react';
import { schedulePostOptimisticResponse } from 'app/utils/spaces/optimisticResponseForSchedulePost';
import { SPACE_ACTIVITY_FEED_FILTERS } from 'app/components/BoardView/constants';
import DisplayEmojiPicker from 'app/components/TipTapEditor/DisplayEmojiPicker';

const EDIT_COMMENT_MUTATION = queryLoader('app/graphql/EditComment.gql');
const WRITE_COMMENT_MUTATION = queryLoader('app/graphql/WriteComment.gql');
const MENTIONEE_ACCESS_QUERY = queryLoader(
  'app/graphql/queries/MentioneeAccess.gql',
);
const SHARE_WITH_PATIENT_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ShareWithPatientAndResponseParties.gql',
);

const ADD_SCHEDULE_MESSAGE = queryLoader(
  'app/graphql/mutations/Comments/AddScheduleMessage.gql',
);

const RELATED_TASKS = queryLoader(
  'app/graphql/queries/Task/RelatedTaskWidget.gql',
);
// Previously we were using the below mention constant.
const CARE_TEAM_MENTION_GROUP_IDENTIFIER = 'care_members';
// const CARE_TEAM_MENTION_GROUP_IDENTIFIER = 'care_team';
const PATIENT_CONTACT_MENTION_GROUP_IDENTIFIER = 'guardians';
const CARE_TEAM_DEFAULT_GROUP_IDENTIFIER =
  '<p><span data-id="care_members" data-label="caremembers" data-typename="User" data-type="mention">@caremembers</span> </p>';
const PATIENT_CONTACT_DEFAULT_GROUP_IDENTIFIER =
  '<p><span data-id="guardians" data-label="Patient Contacts" data-typename="User" data-type="mention">@Patient Contacts</span> </p>';

const DEFAULT_TIMEZONE = 'GMT-08:00 Pacific Time (US & Canada)';

// NOTE:GP Private comment logic is deprecated now needs to be removed.
class WriteComment extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultValues();
    this.tipTapEditorInputField = React.createRef();
    this.mentioneesRef = React.createRef([]);
    this.isDirectPaste = React.createRef();
    this.editorClickRef = React.createRef();
    this.dataRef = React.createRef();
    this.isDirectPaste.current = false;
    this.editorClickRef.current = null;
    this.dataRef.current = {
      outsideClickAllowed: true,
    };
  }

  componentDidMount() {
    if (
      this.props.author &&
      !this.props.newComment &&
      !this.props.editComment &&
      this.authorAndCurrentUserAreNotSame()
    ) {
      this.addMentionee(this.props.author);
    }

    this.props.setRef(this);
    if (this.props.editComment) {
      let content = parseContentWithTag(this.props);
      this.setState({ content: content || '' }, () => {
        this.focusInputField();
      });
    }

    if (this.props.prefilledContent) {
      this.setState({ content: this.props.prefilledContent });
    }

    if (this.props.preSelectedObjects) {
      this.setState({ objects: this.props.preSelectedObjects });
    }

    if (this.props.preSelectedFiles) {
      this.setState({ files: this.props.preSelectedFiles });
    }

    if (this.props.secured !== undefined) {
      this.setState({ isMessageSecure: this.props.secured });
    }

    if (!this.isReply()) {
      setTimeout(() => {
        document.addEventListener(
          'click',
          this.editorOutsideClickHandler,
          true,
        );
      }, 100);
    }
  }

  editorOutsideClickHandler = (e) => {
    if (
      this.editorClickRef.current &&
      !this.editorClickRef.current.contains(e.target) &&
      this.dataRef.current.outsideClickAllowed &&
      this.state.assigningObject !== true
    ) {
      this.unsetHeadComment();
    } else {
      this.dataRef.current = {
        outsideClickAllowed: true,
      };
    }
  };

  componentDidUpdate(prevProps) {
    this.saveDraft();

    const { replyingToCommentId } = prevProps;
    if (
      replyingToCommentId !== this.props.replyingToCommentId &&
      this.props.author &&
      !this.props.editComment &&
      this.authorAndCurrentUserAreNotSame()
    ) {
      this.addMentionee(this.props.author);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.assigningObject &&
      this.props.commentShare.status === 'open' &&
      nextProps.commentShare.status === 'closed'
    ) {
      this.setState({ assigningObject: false });
      this.tipTapEditorInputField?.current?.focusTiptapInputField();
    }

    if (
      this.state.assigningObject &&
      !isEqual(this.props.commentShare.cards, nextProps.commentShare.cards)
    ) {
      let files = this.state.files,
        objects = this.state.objects;
      files = files.concat(nextProps.commentShare.cards.files);
      objects = objects.concat(nextProps.commentShare.cards.objects);

      this.setState({ files, objects, assigningObject: false });
      this.props.onFilesUpdate(files);
      this.props.onObjectsUpdate(objects);
      this.props.commentShareConsume();
    }

    if (
      !isEmpty(nextProps.author) &&
      this.props.author &&
      +(+this.props.author.id !== +nextProps.author.id)
    ) {
      if (+nextProps.author.id === +this.props.currentUser.graph.id) {
        this.setState({ content: '' });
      } else {
        this.addMentionee(nextProps.author);
      }
    }

    if (
      this.isSharing() &&
      this.props.commentableId !== nextProps.commentableId
    ) {
      this.setState({ mentioneesNotPermitted: [] }, this.resetMentionees);
    }
  }

  addMentionee(author) {
    let mentionees = this.state.mentionees;
    mentionees.push(author);
    this.setState({ mentionees }, () => {
      // Hack for mentionable field to focus at the end.
      this.inputField.blur();
      this.focusInputField();
    });
  }

  authorAndCurrentUserAreNotSame() {
    return (
      !!this.props.author &&
      this.props.author.id !== this.props.currentUser.graph.id
    );
  }

  disallowDraftSupport() {
    return this.props.editComment || this.isSharing();
  }

  getDraft() {
    if (this.disallowDraftSupport()) return {};

    const draftLookUpFields = {
      userId: this.props.currentUser.graph && this.props.currentUser.graph.id,
      commentableType: this.props.commentableType,
      commentableId: this.props.commentableId,
      parentId: this.props.parentId,
    };

    this.draftKey = this.draftKey || getDraftKey.comment(draftLookUpFields);
    return this.props.drafts[this.draftKey] || {};
  }

  defaultValues() {
    return {
      showEmojiPicker: false,
      submitButtonToggled: false,
      assigningObject: false,
      suggestions: [],
      newCommentOpen: false,
      hasError: false,
      tagError: false,
      mentioneesNotPermitted: [],
      requestInProgress: false,
      inputActive: false,
      editorType: this.getDraft().editorType || null,
      toggleEditor: true,
      isScheduledDropdownOpen: false,
      selectedTime: null,
      showCalendarDropdown: false,
      isMessageSecure: true,
      workspaceSetting: null,
      headCommentClass: this.getDraft().content ? 'comment-collapse' : '',
      ...this.initializeContentFields(),
    };
  }

  initializeFromProps() {
    const {
      type,
      content = '',
      links: objects = [],
      mentionees = [],
      files = [],
      categories = [],
      commentCategories = [],
    } = this.props;

    const commentCategoryIds = categories.map(
      (category) => commentCategories.find((obj) => obj.name === category).id,
    );

    return {
      type,
      content,
      mentionees,
      files,
      objects,
      commentCategoryIds,
    };
  }

  initializeFromDraft() {
    const {
      content = '',
      files = [],
      objects = [],
      mentionees = [],
      commentCategoryIds = [],
      commentType: type = '',
      editorType = null,
    } = this.getDraft();

    return {
      content,
      files,
      objects,
      mentionees,
      commentCategoryIds,
      type,
      editorType,
    };
  }

  initializeContentFields() {
    if (
      this.props.editComment === true ||
      this.props.source === 'share-modal'
    ) {
      return this.initializeFromProps();
    }

    return this.initializeFromDraft();
  }

  focusInputField() {
    this.inputField?.focus();
  }

  isInternalNote() {
    return (
      this.state.editorType === 'internalNote' || this.props?.isInternalNote
    );
  }

  mentionGroups() {
    if (this.isInternalNote()) return [];
    return this.props.entity?.mention_groups || [];
  }

  getCareTeamMentionGroup() {
    return this.mentionGroups().find(
      (group) => group.identifier === CARE_TEAM_MENTION_GROUP_IDENTIFIER,
    );
  }

  getPatientContactMentionGroup() {
    return this.mentionGroups().find(
      (group) => group.identifier === PATIENT_CONTACT_MENTION_GROUP_IDENTIFIER,
    );
  }

  onInputFocus = (event) => {
    if (
      (this.props.parentId || !this.isCommentable('Board')) &&
      this.props.newComment &&
      this.authorAndCurrentUserAreNotSame() &&
      !isCareWorkspaceView()
    ) {
      this.addMentionee(this.props.author);
      let allMentionee = this.addAllMentionees(
        this.mentioneesRef.current || [],
      );
      return (
        allMentionee +
        `<span data-type='mention' data-id='${this.props.author.id}' data-label='${this.props.author.name}' data-typename='User''>${this.props.author.name}</span> `
      );
    }

    let careTeamMentionGroup = this.getCareTeamMentionGroup();
    if (
      isCareWorkspaceView() &&
      isCareSpace(this.props.entity) &&
      careTeamMentionGroup
    ) {
      this.addMentioneeGroup(careTeamMentionGroup);
      return `<span data-type='mention' data-id='${careTeamMentionGroup.identifier}' data-label='${careTeamMentionGroup.display_name}' data-typename='User''>${careTeamMentionGroup.display_name}</span> `;
    }

    let guardianMentionGroup = this.getPatientContactMentionGroup();
    if (
      isWorkspaceMemberOrEditor() &&
      isCareSpace(this.props.entity) &&
      guardianMentionGroup
    ) {
      this.addMentioneeGroup(guardianMentionGroup);
      let allMentionee = this.addAllMentionees(
        this.mentioneesRef.current || [],
      );
      return (
        allMentionee +
        `<span data-type='mention' data-id='${guardianMentionGroup.identifier}' data-label='${guardianMentionGroup.display_name}' data-typename='User''>${guardianMentionGroup.display_name}</span> `
      );
    }

    this.setState({ inputFieldFocus: true, hasError: false });
  };

  addAllMentionees(mentionees) {
    let mentioneeString = '';
    mentionees.forEach((mentionee) => {
      mentioneeString += `<span data-type='mention' data-id='${mentionee.id}' data-label='${mentionee.name}' data-typename='User''>${mentionee.name}</span> `;
    });
    return mentioneeString;
  }

  addMentioneeGroup(mentioneeGroup) {
    this.setState({}, () => {
      // Hack for mentionable field to focus at the end.
      this.inputField.blur();
      this.focusInputField();
    });
  }

  onInputBlur = (e) => {
    if (this.isCommentable('Board') && this.props.device.mobileDevice) {
      this.inputField.scrollIntoView({
        behaviour: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
    this.setState({ inputFieldFocus: false });
  };

  setRef = (node) => {
    this.selfRef = node;
  };

  editComment = (data) => {
    let { content, objects, type } = data;
    this.props.closeEdit();

    let variables = {
      commentId: this.props.id,
      content,
      files: this.state.files.map((file) => file.id),
      links: buildFormattedIds(objects),
      type,
      visibleAudienceGroupIds: this.props.visible_audience_ids,
    };

    this.props.client.mutate({
      mutation: EDIT_COMMENT_MUTATION,
      variables: variables,
      optimisticResponse: ({ content }) => {
        return {
          editComment: {
            ...this.optimisticResponseCommonAttributes(),
            categories: this.props.categories,
            content,
            created_at: this.props.created_at,
            files: this.getOptimisticResponseForFiles(),
            hash_tags: this.props.hash_tags,
            id: this.props.id,
            liked: this.props.liked,
            likes_by_users: this.props.likes_by_users,
            likes_count: this.props.likes_count,
            nice_id: '',
            links: objects,
            pinnable: this.props.pinnable,
            pinned: this.props.pinned,
            pinned_at: this.props.pinned_at,
            replies_count: this.props.replies_count || 0,
            total_likes: this.props.total_likes,
            type,
            updated_at: this.props.updated_at,
            emoji_reaction_detail: this.props.emoji_reaction_detail,
            visible_audience_ids: this.props.visible_audience_ids,
          },
        };
      },
    });
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  onDropRejected = (files) => {
    files.forEach((file) => (file.rejected = true));
    files.length && this.assigningObject('file', files);
  };

  onDrop = (files) => {
    files.length && this.assigningObject('file', files);
    this.setState({ dropzoneActive: false });
  };

  onDirectPaste = (files) => {
    this.isDirectPaste.current = true;
    this.onDrop(files);
  };

  onClickOverlay(event) {
    this.inputField.focus();
    event.stopPropagation();
  }

  getOptimisticResponseForFiles() {
    return this.state.files.map((file, index) => {
      let file_asset = isBlob(file)
        ? mapLocalFileWithAttachmentObject(file)
        : file;
      return {
        __typename: 'MessageFile',
        id: 'MessageFile:' + file.id,
        file_asset,
        position: index + 1,
      };
    });
  }

  isReply() {
    return !!this.props.parentId;
  }

  optimisticResponseCommonAttributes() {
    return {
      __typename: 'Comment',
      author: this.props.currentUser.graph,
      commentable_id: this.props.commentableId,
      commentable_name: this.props.commentable_name || '',
      commentable_path: 'commentable_path', // Dev ToDo
      commentable_type: this.props.commentableType,
      mentionees: this.state.mentionees,
      parent_id: this.props.parentId || null,
      reported_by_current_user: false,
      mention_groups: this.mentionGroups(),
      emoji_reaction_detail: {
        __typename: 'EmojiReactionDetail',
        id: now(),
        emoji_reaction_users: [],
      },
      viewed_by: [],
      view_count: 0,
    };
  }

  getCommentOptimisticResponse(content, data) {
    let { objects, type } = data,
      isInternalType = this.isInternalNote();

    return {
      writeComment: {
        __typename: 'Response',
        success: true,
        errors: null,
        entity: {
          ...this.optimisticResponseCommonAttributes(),
          commentable: {
            __typename: this.props.commentableType,
            id: this.props.commentableId,
            followed_by_current_user: true,
            comments_count: this.props.entity.comments_count + 1,
          },
          content,
          created_at: ceil(now() / 1000),
          files: this.getOptimisticResponseForFiles(),
          frontend_url: '',
          hash_tags: [],
          id: '-1',
          liked: false,
          likes_by_users: [],
          likes_count: 0,
          links: objects,
          nice_id: '',
          pinnable: false,
          pinned: false,
          pinned_at: null,
          processed: true,
          linked_entities: [],
          entity_path: '',
          replies_count: 0,
          secured: isInternalType ? false : this.state.isMessageSecure,
          total_likes: 0,
          type,
          errors: [],
          updated_at: ceil(now() / 1000),
          visible_audience_ids: isInternalType
            ? []
            : [this.getPatientContactMentionGroup()?.id],
        },
      },
    };
  }

  createActivityEntityObject() {
    let { owner } = this.props.entity;
    return {
      ...this.props.entity,
      feed_name: `${owner.patient.name}'s Space`,
      owner: {
        ...owner,
        clinic: null,
        doctor: null,
        patient: { ...owner.patient, entity_label: [], person_connections: [] },
      },
    };
  }

  createActivityObject(content, data) {
    let comment = this.getCommentOptimisticResponse(content, data).writeComment
      .entity;
    return {
      ...comment,
      titleize_verb: 'Commented',
      nice_id: '',
      images_and_videos: [],
      additional_replies: [],
      linked_entities: [],
    };
  }

  getActivityOptimisticResponse(content, data) {
    let template_text = this.isReply()
      ? 'You replied to a post in %{[link:nice_id]entity.feed_name}'
      : 'You posted in %{[link:nice_id]entity.feed_name}';
    return {
      writeComment: {
        __typename: 'Response',
        success: true,
        errors: null,
        entity: {
          __typename: 'Activity',
          id: '-1',
          source_type: 'SpaceCommentActivity',
          template_text,
          action: null,
          visible_at: ceil(now() / 1000),
          authors: [this.props.currentUser.graph],
          effect_activity: null,
          entities: [this.createActivityEntityObject()],
          objects: [this.createActivityObject(content, data)],
        },
      },
    };
  }

  getAdditionalProps(data) {
    let props = {};
    props.optimisticResponse = ({ content }) => {
      if (!this.isReply() && isCareSpace(this.props.entity)) {
        return this.getActivityOptimisticResponse(content, data);
      } else {
        return this.getCommentOptimisticResponse(content, data);
      }
    };
    return props;
  }

  updateCommentQueryListing(comment) {
    let idToBeRemoved = comment.id === '-1' ? null : '-1',
      isReply = this.isReply();

    StoreUpdater.addRecordInRecordPager(
      { public_feed: null, additional_replies: [], ...comment },
      {
        afterId: null,
        aroundId: this.props.aroundId,
        beforeId: null,
        idToBeRemoved,
        limit: isReply ? REPLIES_BATCH_SIZE : COMMENTS_BATCH_SIZE,
        recordId: isReply ? this.props.parentId : this.props.commentableId,
        recordType: isReply ? 'Comment' : this.props.commentableType,
        sortQuery: this.props.sortQuery,
        textQuery: this.props.textQuery,
        queryType: 'COMMENTS',
        type: isReply ? 'Replies' : 'Comments',
      },
    );
  }

  getPersonDetail() {
    let person = [];
    let { patient } = this.props.entity.owner,
      connections = patient.person_connections;
    if (patient.email) {
      person.push({
        id: patient.id,
        name: patient.name,
        relation: 'Patient',
      });
    }
    connections.forEach((p) => {
      person.push({
        id: p.related_person.id,
        name: p.related_person.name,
        relation: p.relation,
      });
    });
    return person;
  }

  shareCareSpace() {
    let persons = this.getPersonDetail(),
      getIds = [];
    persons.forEach((person) => {
      getIds.push(person.id);
    });
    this.props.client.mutate({
      mutation: SHARE_WITH_PATIENT_MUTATION,
      variables: {
        careSpaceId: this.props.entity.id,
        shareWithPersonIds: getIds,
      },
    });
  }

  updateCareSpaceAcitvityListing(activity) {
    let recordIdToRemove = activity.id === '-1' ? null : '-1';

    let textQuery = this.props.location.search
      ? this.props.location.search.split('=')[1]
      : null;

    let idType =
      SPACE_ACTIVITY_FEED_FILTERS.feed_type_url_mapper[
        this.props.match.params.feedType
      ];

    StoreUpdater.addCommentInCareActivityListing(activity, {
      recordIdToRemove,
      idQuery: this.props.commentableId,
      textQuery,
      idType,
    });
  }

  updateCareCommentAdditionalReplies(comment) {
    let updatedComment = { ...comment, additional_replies: [] };
    StoreUpdater.updateEntityThroughWrapper(
      {
        entity: {
          id: this.props.parentId,
          __typename: 'Comment',
          additional_replies: [updatedComment],
        },
        attributeName: 'additional_replies',
      },
      { customAction: 'assign' },
    );
  }

  isMessagePostedInsideClinicHours = (workspaceSetting) => {
    const clinicCurrentTime = addTimezoneOffset(
      workspaceSetting.time_zone || DEFAULT_TIMEZONE,
    );
    if (
      workspaceSetting.working_days.includes(
        ALL_DAYS[clinicCurrentTime.now.day()],
      )
    ) {
      const isInRange = isTimeInRange(
        clinicCurrentTime.now,
        workspaceSetting.start_time,
        workspaceSetting.end_time,
      );
      return isInRange;
    }

    return false;
  };

  getClinicTimingString = (workspaceSetting) => {
    let clinicTimingString = '';

    workspaceSetting.working_days.forEach((day, index) => {
      let formattedDay = day.charAt(0).toUpperCase() + day.slice(1);
      clinicTimingString += formattedDay.slice(0, 3);
      if (index !== workspaceSetting.working_days.length - 1) {
        clinicTimingString += ', ';
      } else {
        clinicTimingString += ' ';
      }
    });

    clinicTimingString += `from ${workspaceSetting.start_time.toUpperCase()} to ${workspaceSetting.end_time.toUpperCase()}`;
    return clinicTimingString;
    // `${
    //   workspaceSetting.working_days[0]
    // } from ${workspaceSetting.start_time.toUpperCase()} to ${workspaceSetting.end_time.toUpperCase()}`;
  };

  newComment = (data) => {
    let { content, objects, type } = data,
      additionalProps = this.getAdditionalProps(data),
      isInternalType = this.isInternalNote();

    let variables = {
      commentableId: this.props.commentableId,
      commentableType: this.props.commentableType,
      parentId: this.props.parentId,
      content,
      type: type,
      links: buildFormattedIds(objects),
      files: map(this.state.files, (file) => file.id),
      secured: isInternalType ? true : this.state.isMessageSecure,
    };

    if (isInternalType) {
      variables.visibleAudienceGroupIds = [];
    }

    this.props.client
      .mutate({
        mutation: WRITE_COMMENT_MUTATION,
        variables: variables,
        ...additionalProps,
        update: (proxy, { data: { writeComment } }) => {
          if (isCareSpace(this.props.entity)) {
            if (this.isReply()) {
              if (this.props.replies_count == 0) {
                this.updateCareCommentAdditionalReplies(writeComment.entity);
              } else {
                this.updateCommentQueryListing(writeComment.entity);
              }
            } else {
              this.updateCareSpaceAcitvityListing(writeComment.entity);
            }
          } else {
            this.updateCommentQueryListing(writeComment.entity);
          }
        },
      })
      .then((data) => {
        // Setting the secure bit to true again
        this.setState({ isMessageSecure: true });
        if (
          !this.props.entity.shared_with_any_patient &&
          isCareSpace(this.props.entity) &&
          isWorkspaceView() &&
          !isInternalType &&
          !this.props.isInternalNote &&
          !isCareWorkspaceView()
        ) {
          this.props.openInfoModal('general', 'care_space_not_shared_warning', {
            onSuccess: () => {
              this.shareCareSpace();
            },
          });
        }

        // check is Patient or not if patient check clinic hours and if outside the clininc hours show popup
        if (isCareWorkspaceView()) {
          let workspaceSetting =
            this.props.activeResource.resource.clinic_workspace
              .workspace_setting;
          if (!this.isMessagePostedInsideClinicHours(workspaceSetting)) {
            this.props.openInfoModal('general', 'outside_working_hours', {
              contentInterpolations: {
                clinic_timing: this.getClinicTimingString(workspaceSetting),
              },
              dangerouslySetInnerHTML: true,
            });
          }
        }

        let comment = data.data.writeComment;
        !isEmpty(this.props.entity) && this.sendAddCommentEvent(comment);
        this.props.closeReply();
        this.clearDraft();

        this.closeModal();
        this.props.workspaceComment && this.resetValues();
        // this.props.refetchOnCreate && this.props.refetchOnCreate(comment);
        if (this.props.source === 'feeds-view') {
          this.props.onSuccess({
            public_feed: null,
            additional_replies: [],
            ...comment,
          });
        } else if (this.isSharing()) {
          this.props.closeShareModal();
          this.resetValues();
          this.props.openSuccessModal(comment);
        } else if (this.isReply()) {
          this.props.setScrollToCommentId(comment.id);
        }
      });
  };

  commentType(type) {
    return Boolean(this.props.parentId)
      ? 'Reply'
      : type === 'Discussion'
      ? 'Comment'
      : 'Question';
  }

  sendAddCommentEvent(data) {
    let payload = {
      ...this.props.entity,
      type: this.commentType(data.type),
      threaded: Boolean(this.props.parentId) ? 'Yes' : 'No',
    };

    EventTracker.trackForEntity('add_comment', payload);
  }

  trackCommentFailed(reason) {
    EventTracker.trackFailure('add_comment', { reason });
  }

  contentPresent() {
    return !!this.state.content.trim().length;
  }

  filesOrLinksPresent() {
    return !!this.state.objects?.length || !!this.state.files?.length;
  }

  isCommentValid(type) {
    let isValid = true;
    if (!(this.contentPresent() || this.filesOrLinksPresent())) {
      this.setState({ hasError: lowerCase(type) });
      this.trackCommentFailed('No text/files added');
      isValid = false;
    }

    return isValid;
  }

  isCommentable(type) {
    return this.props.commentableType === type;
  }

  objectUserId() {
    if (this.isCommentable('Pulse')) {
      return this.props.commentableEntityOwner.id;
    }
  }

  abilityOptions() {
    let userId = this.objectUserId();
    return userId ? { userId } : {};
  }

  postComment = (type) => {
    let data = {
      content: removeHashMarkup(
        removeMentioneeName(
          changeMentioneeNameWithId(this.state.content.toString()),
        ),
      ),
      objects: this.state.objects,
      type,
    };
    this.setState({ requestInProgress: true });
    !this.isSharing() && !this.props.workspaceComment && this.resetValues();
    if (this.state.selectedTime !== null) {
      this.scheduleMessage(data);
    } else if (this.props.content || this.props.editComment) {
      this.editComment(data);
    } else {
      this.newComment(data);
    }
  };

  getFormattedDate(days, hour) {
    const currentTime = currentTimeWithUserTimeZone();
    return add(currentTime, days, 'days')
      .hour(hour)
      .minute(0)
      .second(0)
      .format();
  }

  convertSelectedTimeIntoRequiredDateFormat(selectedDate) {
    const dateMappings = {
      'Tomorrow at 8:00am': [1, 8],
      'Tomorrow at 6:00pm': [1, 18],
      '1 week at 8:00am': [7, 8],
    };

    const dateArgs = dateMappings[selectedDate];

    if (dateArgs) {
      return this.getFormattedDate(dateArgs[0], dateArgs[1]);
    } else {
      return dayjs(selectedDate).format();
    }
  }

  scheduleMessage = (data) => {
    let isInternalType = this.isInternalNote();
    let { content, objects } = data;

    let time = this.convertSelectedTimeIntoRequiredDateFormat(
      this.state.selectedTime,
    );

    let variables = {
      content: content,
      entityId: this.props.entity.nice_id,
      entityType: this.props.entity.__typename,
      files: map(this.state.files, (file) => file.id),
      links: buildFormattedIds(objects),
      time: time,
      visibleToGroupIds: isInternalType
        ? []
        : [parseInt(this.getPatientContactMentionGroup()?.id)],
    };

    this.setState({ selectedTime: null });

    let optimisticResponse = schedulePostOptimisticResponse(
      this.props.entity,
      variables,
      this.props.currentUser.graph,
    );

    this.props.client
      .mutate({
        mutation: ADD_SCHEDULE_MESSAGE,
        variables: variables,
      })
      .then((data) => {
        setKeyValueInLocalStorage(
          'messageId',
          data.data.addScheduleMessage.entity.id,
        );
        this.props.navigate(
          `/spaces/${this.props.entity.nice_id}/scheduled_posts`,
        );
        this.triggerLocalStorageChange();
      });
  };

  triggerLocalStorageChange() {
    const storageChangeEvent = new Event('refetchScheduleList');
    window.dispatchEvent(storageChangeEvent);
  }

  resetValues() {
    this.clearDraft();
    this.setState({
      newCommentOpen: false,
      content: '',
      files: [],
      objects: [],
      mentioneesNotPermitted: [],
      mentionees: [],
      requestInProgress: false,
      hasError: '',
    });
  }

  clearDraft() {
    if (this.draftKey) {
      this.props.clearDraft(this.draftKey);
    }
  }

  isTokenPresent() {
    return tokenNodePresent(this.state.content.toString());
  }

  createComment = (type) => {
    type = type || (this.isQuestionActive() ? 'Question' : 'Discussion');
    this.setState({ type });
    if (this.isCommentValid(type)) {
      if (this.props.commentableId) {
        this.postComment(type);
        this.setState({ submitButtonToggled: !this.state.submitButtonToggled });
      }
    } else {
      if (this.refs.commentModalHeading)
        this.refs.commentModalHeading.scrollIntoView({
          behaviour: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
    }
  };

  handleCommentSubmit = (type) => {
    this.props.onSubmitClick();
    this.createComment(type);
    this.setState({ editorType: null, headCommentClass: '' });
  };

  handleSubmit = (type) => {
    if (this.isTokenPresent()) {
      this.tipTapEditorInputField.current?.focusTokenNode();
      this.setState({ hasError: 'token' });
      return false;
    }
    if (
      !this.isReply() &&
      !this.state.isMessageSecure &&
      !this.isInternalNote() &&
      !this.props.content
    ) {
      this.props.openInfoModal('general', 'secure_notification_visibility', {
        dangerouslySetInnerHTML: true,
        onSecondaryActionClick: () => {
          this.setState({ isMessageSecure: true }, () => {
            this.handleCommentSubmit(type);
          });
        },
        onSuccess: () => {
          this.handleCommentSubmit(type);
        },
      });
    } else {
      this.handleCommentSubmit(type);
    }
  };

  assigningObject = (type, files = [], options = {}) => {
    this.assignObject(type, files, options);
  };

  assignObject = (type, files, options) => {
    this.dataRef.current = {
      outsideClickAllowed: false,
    };
    this.setState({ assigningObject: true });
    this.props.commentShareOpen(
      type,
      'Comment',
      files,
      this.state.objects.concat(this.state.files),
      {
        commentableType: this.props.commentableType,
        bannerKey: addAttachmentBannerKey(this.props.entity),
        restrictModalOpening: type === 'video',
        isDirectPaste: this.isDirectPaste.current,
        isOnlyAudioRecording: options?.isOnlyAudioRecording,
      },
    );
    this.isDirectPaste.current = false;
  };

  assignObjectWithoutOpeningModal = (files, objects) => {
    this.setState({ files, objects });
  };

  saveDraft() {
    if (this.disallowDraftSupport()) return;

    const {
      content,
      files,
      objects,
      mentionees,
      commentCategoryIds,
      type,
      editorType,
    } = this.state;

    const { commentableType, commentableId, parentId } = this.props;

    const payload = {
      content,
      files,
      objects,
      commentableType,
      commentableId,
      parentId,
      mentionees,
      commentCategoryIds,
      type,
      editorType,
    };
    if (!this.state.submitButtonToggled) this.props.updateCommentDraft(payload);
  }

  componentWillUnmount() {
    this.saveDraft();
    document.removeEventListener('click', this.editorOutsideClickHandler);
  }

  resetMentionees = () => {
    this.state.mentionees.map((mentionee) => this.onMentioneeAdd(mentionee));
  };

  removeObject = (object) => {
    let objects = this.state.objects,
      newObjects = reject(objects, {
        __typename: object.__typename,
        id: object.id,
      });

    this.setState({ objects: newObjects }, () => {
      this.props.onObjectsUpdate(newObjects);
    });
  };

  attachmentTextNotRequired() {
    return this.props.parentId || this.props.useReplyLayout;
  }

  getAttachmentLinkText() {
    let titleText = !this.props.device.desktopHd ? '' : 'Attachment';
    return this.attachmentTextNotRequired() ? '' : titleText;
  }

  renderAddContent(options) {
    const isReplyField = this.isReply();
    return (
      <>
        {options.emojiPickerRequired && (
          <DisplayEmojiPicker
            isClassComponent={true}
            showEmoji={this.state.showEmojiPicker}
            handleEmojiSelect={(emoji) => {
              this.tipTapEditorInputField.current?.insertEmoji(emoji);
              this.tipTapEditorInputField?.current?.focusTiptapInputField();
              this.handleEmojiPicker();
            }}
          />
        )}
        <AttachmentDropdown
          assignObject={this.assignObject}
          textRequired={!this.isReply()}
          leftAligned
        />
        {isReplyField && (
          <OsBtn
            name='BtnIcon'
            extraClass='no-text'
            icon='video-filled'
            onClick={() => this.assigningObject('video')}
          />
        )}
      </>
    );
  }

  renderOnlyAddContent() {
    if (!this.isReply())
      return (
        <OsBtn
          name='BtnIcon'
          extraClass={` no-text with-border web-view-btn`}
          icon='attach'
          onClick={() => this.assigningObject('file')}
        />
      );
    else
      return (
        <OsBtn
          name='BtnIcon'
          extraClass={` no-text web-view-btn attach-icon`}
          icon='attach'
          onClick={() => this.assigningObject('file')}
        />
      );
  }

  renderAttachmentLink = (options = {}) => {
    let recordvideoBtnClass = ' no-text with-border web-view-btn';
    return (
      <div className='btn-group-left'>
        {isCareWorkspaceView()
          ? this.renderOnlyAddContent()
          : this.renderAddContent(options)}
        {!this.isReply() && (
          <>
            <VideoRecordButton
              onClick={() => this.assigningObject('video')}
              extraClass={recordvideoBtnClass}
            />
            <VideoRecordButton
              onClick={() =>
                this.assigningObject('video', [], {
                  isOnlyAudioRecording: true,
                })
              }
              icon={'mic'}
              extraClass={recordvideoBtnClass}
            />
          </>
        )}
      </div>
    );
  };

  isSharing = () => {
    return this.props.sharing;
  };

  isLoaderRequired() {
    return (
      this.state.requestInProgress &&
      (this.isSharing() || isWorkspaceSpace(this.props.entity))
    );
  }

  renderCommentSendButton() {
    let disabled =
        (this.isSharing() && !this.props.commentableId) ||
        this.state.requestInProgress,
      loaderRequired = this.isLoaderRequired();
    let text;
    if (this.state.selectedTime !== null) {
      if (this.isInternalNote()) {
        text = 'SCHEDULE FOR INTERNAL';
      } else {
        text = 'SCHEDULE FOR PATIENT';
      }
    } else if (this.isInternalNote()) {
      text = 'Add Internal Note';
    } else if (this.state.editorType === 'patient') {
      text = 'Send to Patient';
    } else {
      if (!this.newButtonRequired()) {
        text = 'Post';
      } else {
        text = 'Send to Patient';
      }
    }
    return (
      <>
        {this.state.selectedTime !== null && (
          <div className='time-pill'>
            <span>{this.state.selectedTime}</span>
            <img
              src={crossCircle}
              alt='crossCircle'
              width='16'
              height='16'
              onClick={() => this.setState({ selectedTime: null })}
            />
          </div>
        )}
        <div className='submit-btn'>
          <OsBtn
            name='BtnPrimary'
            disabled={disabled}
            text={text}
            extraClass={`web-view-btn osbtn-16 ${
              this.newButtonRequired() && 'hide-border-radius'
            }`}
            loaderRequired={loaderRequired}
            onClick={this.handleSubmit.bind(this, null)}
            data-hover={!isTouchSupported()}
          />
          {this.newButtonRequired() && (
            <div
              className={`scheduler-btn ${
                this.state.isScheduledDropdownOpen && 'dropdown-active'
              }`}
              onClick={() =>
                this.setState({
                  isScheduledDropdownOpen: !this.state.isScheduledDropdownOpen,
                  showCalendarDropdown: false,
                })
              }>
              <OrthoIcon name='calendar' dataHoverNotRequired={true} />
            </div>
          )}
          {this.renderScheduleDropdwon()}
          {this.renderCalendar()}
        </div>
      </>
    );
  }

  selectCustomeTime = (e, time) => {
    if (time === 'custom') {
      this.setState({ showCalendarDropdown: true });
    } else {
      this.setState({ selectedTime: time });
    }
  };

  toggleScheduleDropdown = () => {
    this.setState({
      isScheduledDropdownOpen: !this.state.isScheduledDropdownOpen,
    });
  };

  handleDateChange = (date) => {
    this.closeCalendarModal();
    this.setState({ selectedTime: dayjs(date).format('LLL') });
  };

  closeCalendarModal = () => {
    this.setState({ showCalendarDropdown: false });
  };

  openScheduledDropdown = () => {
    this.setState({ isScheduledDropdownOpen: true });
  };

  closeScheduledDropdown = () => {
    this.setState({ isScheduledDropdownOpen: false });
  };

  renderCalendar() {
    return (
      this.state.showCalendarDropdown && (
        <CustomCalendar
          selectCustomDate={this.handleDateChange}
          closeModal={this.closeCalendarModal}
          elementId='tiptap-editor'
        />
      )
    );
  }

  renderScheduleDropdwon() {
    return (
      <div onClick={() => this.toggleScheduleDropdown()}>
        {this.state.isScheduledDropdownOpen && (
          <ScheduleDropdown
            onSelect={this.selectCustomeTime}
            closeModal={this.closeScheduledDropdown}
            elementId='tiptap-editor'
          />
        )}
      </div>
    );
  }

  renderSubmitButton = () => {
    if (this.props.parentId) {
      return (
        <div className='mt-3 post-comment-footer-action'>
          {this.renderAttachmentLink()}
          <div className='btn-group-right'>
            <OsBtn
              name='BtnIcon'
              text='Cancel'
              extraClass='px-16 web-view-btn'
              onClick={this.props.closeReply}
              eventAttributes={{ parentCommentId: this.props.parentId }}
            />
            <OsBtn
              name='BtnPrimary'
              text='Reply'
              onClick={this.handleSubmit.bind(this, 'Reply')}
              eventAttributes={{ parentCommentId: this.props.parentId }}
            />
          </div>
        </div>
      );
    } else if (this.props.content || this.props.editComment) {
      let primaryButtonText = this.props.device.mobileDevice
        ? 'Save'
        : 'Save Comment';
      return (
        <div className='mt-3 media-actions post-comment-footer-action'>
          {this.renderAttachmentLink()}
          <div className='btn-group-right'>
            <OsBtn
              name='BtnIcon'
              text='Cancel'
              extraClass='px-16 web-view-btn'
              onClick={this.props.closeEdit}
              eventAttributes={{ commentId: this.props.id }}
            />
            <OsBtn
              name='BtnPrimary'
              extraClass='web-view-btn'
              text={primaryButtonText}
              onClick={this.handleSubmit.bind(this, null)}
              eventAttributes={{ commentId: this.props.id }}
            />
          </div>
        </div>
      );
    } else if (this.state.newCommentOpen) {
      return (
        <div className='media-actions-buttons w-100'>
          {this.renderCommentSendButton()}
        </div>
      );
    } else {
      return <>{this.renderCommentSendButton()}</>;
    }
  };

  renderQuestionSubmitButton() {
    return (
      <OsBtn
        {...this.getBtnProps('question')}
        text='Ask your question'
        onClick={this.handleSubmit.bind(this, 'Question')}
        data-hover={!isTouchSupported()}
      />
    );
  }

  onMouseEnter(type) {
    if (!this.props.device.mobileDevice) this.setState({ hoverActive: type });
  }

  onMouseLeave(type) {
    if (!this.props.device.mobileDevice) this.setState({ hoverActive: null });
  }

  getBtnProps(type) {
    let isQuestionActive = this.isQuestionActive(),
      isHoverActive = this.state.hoverActive === type,
      isDiscussionPrimary =
        type === 'discussion' &&
        (isHoverActive || (!isHoverActive && !isQuestionActive)),
      isQuestionPrimary =
        type === 'question' &&
        (isHoverActive ||
          (this.state.hoverActive !== 'discussion' && isQuestionActive)),
      props = {
        onMouseEnter: this.onMouseEnter.bind(this, type),
        onMouseLeave: this.onMouseLeave.bind(this, type),
        extraClass: 'with-border',
      };

    switch (type) {
      case 'discussion':
        return {
          ...props,
          name:
            this.props.device.mobileDevice || isDiscussionPrimary
              ? 'BtnPrimary'
              : 'BtnOutline',
        };
      case 'question':
        return {
          ...props,
          name:
            this.props.device.mobileDevice || isQuestionPrimary
              ? 'BtnPrimary'
              : 'BtnOutline',
        };
      default:
        return {};
    }
  }

  renderDiscussionSubmitButton() {
    return (
      <OsBtn
        {...this.getBtnProps('discussion')}
        text='post your Comment'
        onClick={this.handleSubmit.bind(this, 'Discussion')}
        data-hover={!isTouchSupported()}
      />
    );
  }

  removeAttachment = (file) => {
    let { files } = this.state,
      newFiles = reject(files, (currentFile) => currentFile.id === file.id);

    this.setState({ files: newFiles }, () => {
      this.props.onFilesUpdate(newFiles);
    });
  };

  renderList(objects, afterClose) {
    return objects.map((object) => this.renderCard(object, afterClose));
  }

  cardSize() {
    return 'extra-small';
  }

  getCardGridIdentifier() {
    return this.isCommentable('Pulse') && this.props.parentId
      ? 'PulseDiscussionCard:Cardcols'
      : 'DiscussionCard:Cardcols';
  }

  renderCard = (object, afterClose) => {
    return (
      <OsGrid
        identifier={this.getCardGridIdentifier()}
        key={`${object.__typename}:${object.id}`}>
        <OsCards
          size={this.props.size || this.cardSize()}
          disableCard={this.props.disableCard}
          selectionMode={this.isSharing()}
          obj={object}
          closeRequired={!this.isSharing()}
          afterClose={afterClose}
          avoidPreviewModal={true}
        />
      </OsGrid>
    );
  };

  getCommentTypeValue(value) {
    return lowerCase(value) === 'discussion' ? 'comment' : lowerCase(value);
  }

  renderErrors() {
    let error = '',
      errorType = this.state.hasError;
    if (errorType) {
      switch (errorType) {
        case 'token':
          error = translate('TOKEN_ERROR_MESSAGE');
          break;
        default:
          error = translate('ADD_COMMENT_ERROR_MESSAGE', {
            type: this.getCommentTypeValue(this.state.hasError),
          });
      }
    }

    if (error)
      return (
        <span className='comment-error'>
          <OrthoIcon
            name='error'
            dataHoverNotRequired={true}
            defaultClass='error-icon'
          />{' '}
          {error}
        </span>
      );
  }

  renderCategoryError() {
    let error = '';
    if (this.state.tagError) error = translate('ADD_CATEGORY_ERROR_MESSAGE');

    if (error)
      return (
        <span className='comment-error'>
          <OrthoIcon
            name='error'
            dataHoverNotRequired={true}
            defaultClass='error-icon'
          />{' '}
          {error}
        </span>
      );
  }

  renderCardsAndAttachments = () => {
    const { objects, files } = this.state;
    if (objects.length > 0 || files.length > 0) {
      return (
        <div className='row comment-attachment-block row-col-space-16'>
          {this.renderList(
            files.map((file) =>
              isBlob(file) ? mapLocalFileWithAttachmentObject(file) : file,
            ),
            this.removeAttachment,
          )}
          {this.renderList(objects, this.removeObject)}
        </div>
      );
    }
  };

  toggleableCommentType(type) {
    return pull(['Discussion', 'Question'], type)[0];
  }

  toogleCommentType = () => {
    this.setState({ type: this.toggleableCommentType(this.state.type) });
  };

  onMentioneeAdd = (mentionee) => {
    let mentionees = this.state.mentionees,
      alreadyAdded = mentionees.find(
        (suggestion) => suggestion.id === mentionee.id,
      );

    if (!alreadyAdded) {
      mentionees.push(mentionee);
      this.setState({ mentionees });
    }
    this.isCommentable('Board') && this.checkMentioneeHasPermission(mentionee);
  };

  checkMentioneeHasPermission(mentionee) {
    let boardComment = this.isCommentable('Board'),
      alreadyAdded =
        boardComment &&
        this.state.mentioneesNotPermitted.find(
          (mention) => mention.id === mentionee.id,
        );

    if (!alreadyAdded) {
      this.props.client
        .query({
          query: MENTIONEE_ACCESS_QUERY,
          variables: {
            commentableId: this.props.commentableId,
            commentableType: this.props.commentableType,
            mentioneeId: mentionee.id,
          },
        })
        .then(({ data }) => {
          if (
            this.state.mentionees.find((user) => +user.id === +mentionee.id)
          ) {
            let { mentioneesNotPermitted } = this.state;
            if (
              !data.mentionee_access.success &&
              mentionee.__typename === 'User'
            ) {
              mentioneesNotPermitted.push(mentionee);
            }
            this.setState({ mentioneesNotPermitted });
          }
        });
    }
  }

  renderCurrentUserAvatar() {
    if (this.editOrReplyContainer()) {
      let avatarClassSize = this.props.device.mobileDevice ? 32 : 40;
      return (
        <>
          <Link
            className='comments-primary-avatar'
            to={entityUrl(this.props.currentUser.graph)}>
            <Avatar
              className={`align-top avatar avatar-${avatarClassSize}`}
              {...avatarAttributes(this.props.currentUser.graph)}
            />
          </Link>
          {this.state.headCommentClass === '' && (
            <div className={'editor-modal-placeholder'}>
              <span className={'placeholder-text'}>Add new comment</span>
            </div>
          )}
        </>
      );
    }
  }

  changeContent = (content) => {
    this.setState({ content });
    this.props.onChangeContent(content);
  };

  onMentioneeRemove = (mention) => {
    let { mentioneesNotPermitted } = this.state,
      isMentionAddedInNotPermitted = find(
        mentioneesNotPermitted,
        (user) => user.id === mention.id,
      ),
      newMentionees = reject(
        this.state.mentionees,
        (user) => user.id === mention.id,
      );

    if (isMentionAddedInNotPermitted) {
      mentioneesNotPermitted = reject(
        mentioneesNotPermitted,
        (user) => user.id === mention.id,
      );
      this.setState({ mentioneesNotPermitted });
    }

    this.setState({ mentionees: newMentionees });
  };

  editOrReplyContainer = () => {
    return (
      !this.props.parentId &&
      !this.props.useReplyLayout &&
      !this.props.content &&
      !this.isSharing() &&
      !this.props.editComment
    );
  };

  isFeedView() {
    return this.props.source === 'feeds-view';
  }

  getPlaceholderText() {
    if (this.isInternalNote()) {
      return 'Mention your team members with @ to bring their attention directly to an internal note.';
    }
    if (this.props.editComment) {
      return '';
    }
    const { placeholder, entity } = this.props,
      isReply = !!this.props.parentId,
      isEdit = !!this.props.content;
    return getPlaceholderText({ placeholder, isReply, entity, isEdit });
  }

  commentNotVisibleText(users) {
    if (this.isCommentable('Board')) {
      let space = this.props.entity;
      if (isOpenSpace(space)) {
        return translate(
          'MENTIONEE_WITHOUT_ACCESS_WARNING_IN_OPEN_SPACE',
          {
            users,
            share:
              this.isSharing() || isWorkspaceView() ? (
                'share'
              ) : (
                <Share
                  viewType='link'
                  object={space}
                  extraClass='a-link mentionee-link cursor-pointer'
                  text='share'
                />
              ),
          },
          false,
        );
      } else {
        return translate(
          'MENTIONEE_WITHOUT_ACCESS_WARNING_IN_PRIVATE_SPACE',
          { users },
          false,
        );
      }
    }
  }

  renderMentioneesWithoutAccess = () => {
    if (
      this.isCommentable('Board') &&
      this.state.mentioneesNotPermitted.length > 0
    ) {
      let users = this.state.mentioneesNotPermitted.map((mentionee) => [
          <OsLink
            to={entityUrl(mentionee)}
            className='mentionee-link a-link'
            text={`@${mentionee.name}`}
          />,
          ', ',
        ]),
        text = this.commentNotVisibleText(users);

      return <div className='private-space-cmt'>{text}</div>;
    }
  };

  renderNonOrthodontistPresentHint() {
    if (
      !!this.state.content.length &&
      this.isCommentable('Board') &&
      isNonOrthoPresent(this.props.entity) &&
      !isCareSpace(this.props.entity) &&
      !isWorkspaceView()
    ) {
      return (
        <div className='private-space-cmt mt-2'>
          {translate('GENERAL_USER_PRESENT_IN_SPACE')}
        </div>
      );
    }
  }

  isDropzoneActive() {
    return this.state.dropzoneActive && !this.isSharing();
  }

  onKeyDown = (keyPressed) => {
    if (
      keyPressed &&
      keyPressed.length === 2 &&
      (keyPressed[0] === 'cmd' || keyPressed[0] === 'Control') &&
      keyPressed[1] === 'Enter'
    ) {
      const action = this.props.parentId ? 'Reply' : null;
      this.handleSubmit(action);
    }
  };

  setInputActive = (replyingToComment) => {
    this.mentioneesRef.current = replyingToComment.mentionees;
    this.setState({ inputActive: true });
    this.tipTapEditorInputField?.current?.focusTiptapInputField();
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  setHeadComment = () => {
    this.setState({ headCommentClass: 'comment-collapse' });
  };

  unsetHeadComment = () => {
    const { content } = this.state;
    if (
      !content ||
      content === CARE_TEAM_DEFAULT_GROUP_IDENTIFIER ||
      content === PATIENT_CONTACT_DEFAULT_GROUP_IDENTIFIER
    ) {
      this.setState({
        headCommentClass: '',
        editorType: null,
      });
    }
  };

  displayData = (editorData) => {
    this.changeContent(editorData);
  };

  openMessageSection = (type) => {
    if (this.state.editorType === type) {
      this.unsetHeadComment();
      return;
    }
    this.setHeadComment();
    this.setState({
      editorType: type,
      toggle: !this.state.toggle,
      isScheduledDropdownOpen: false,
    });
  };

  newButtonRequired() {
    return isCareSpace(this.props.entity) && !isCareWorkspaceView();
  }

  handleEmojiPicker = () => {
    this.setState({
      showEmojiPicker: !this.state.showEmojiPicker,
    });
  };

  changeSecureStatus = () => {
    const value = this.state.isMessageSecure;
    this.setState({ isMessageSecure: !value });
  };

  openTaskModal = () => {
    this.setState({
      headCommentClass: '',
      editorType: null,
    });
    this.props.openTaskModalWithEntity(
      this.props.entity,
      this.updateContextualWidgetList,
      true,
    );
  };

  updateContextualWidgetList = (task) => {
    let entity = `Space::Base-${this.props.space.id}`;
    StoreUpdater.addRecord(
      task,
      {
        page: 0,
        perPage: 10,
        sortQuery: 'due_date_asc',
        type: 'EntityRelatedTasks',
        additional_filters: JSON.stringify({ entity }),
      },
      {
        query: RELATED_TASKS,
        isReverse: true,
      },
    );
  };

  renderForm(className = '') {
    let dropzoneInputContainerClassName = 'dropzone-drag-uploader-container ',
      currentUser = this.props.currentUser.graph,
      userNameRequired = this.editOrReplyContainer();

    className += !this.isReply() ? ` ${this.state.headCommentClass}` : '';
    dropzoneInputContainerClassName += this.isDropzoneActive()
      ? 'dropzone-drag-active'
      : '';

    let avatarClassSize = this.props.device.mobileDevice ? 32 : 40;

    return (
      <div
        id='editor-box'
        className={`tiptap-editor-patient-space-wrapper ${
          this.isReply() || this.props.editComment ? 'hide-box-shadow' : ''
        }`}
        ref={this.editorClickRef}>
        {/* team editor header */}
        {this.newButtonRequired() &&
          !this.isReply() &&
          this.editOrReplyContainer() &&
          !this.props.editComment && (
            <div className='btn-container'>
              <Tippy
                placement={'bottom'}
                content={'Message your Patient'}
                delay={20}
                animation={'fade'}>
                <div>
                  <OsBtn
                    key='message-to-patient'
                    name='BtnIcon'
                    extraClass={this.state.editorType == 'patient' && 'active'}
                    text={
                      this.props.editorButtonTextRequired
                        ? 'Message to patient'
                        : ''
                    }
                    leftIcon={true}
                    icon='send'
                    onClick={() => this.openMessageSection('patient')}
                  />
                </div>
              </Tippy>
              <Tippy
                placement={'bottom'}
                content={'Internal Note'}
                delay={20}
                animation={'fade'}>
                <div>
                  <OsBtn
                    key='add-internal-note'
                    name='BtnIcon'
                    title={'Internal Note'}
                    text={
                      this.props.editorButtonTextRequired
                        ? 'Add Internal Note'
                        : ''
                    }
                    extraClass={this.isInternalNote() && 'active'}
                    leftIcon={true}
                    icon='locked'
                    onClick={() => this.openMessageSection('internalNote')}
                  />
                </div>
              </Tippy>
              <Tippy
                placement={'bottom'}
                content={'Create new Task'}
                delay={20}
                animation={'fade'}>
                <div>
                  <OsBtn
                    key='add-task-btn'
                    name='BtnIcon'
                    text={''}
                    leftIcon={true}
                    icon='task'
                    onClick={() => this.openTaskModal()}
                  />
                </div>
              </Tippy>
            </div>
          )}
        {/* patient editor header */}
        {!this.newButtonRequired() &&
          !this.isReply() &&
          !this.props.editComment && (
            <div className='media-comment-head' onClick={this.setHeadComment}>
              <>
                <Link className='' to={entityUrl(this.props.currentUser.graph)}>
                  <Avatar
                    className={`align-top avatar avatar-${avatarClassSize}`}
                    {...avatarAttributes(this.props.currentUser.graph)}
                  />
                </Link>
                {this.state.headCommentClass === '' ? (
                  <div className={'editor-modal-placeholder'}>
                    <span className={'placeholder-text'}>
                      Send a message to the care team
                    </span>
                  </div>
                ) : (
                  <OsLink
                    to={entityUrl(currentUser)}
                    className='feed-author-info hover-underline user-link'
                    text={currentUser.name}
                  />
                )}
              </>
            </div>
          )}
        {/* Editor */}
        {this.isReply() ||
        this.state.headCommentClass !== '' ||
        this.props.editComment ? (
          <div
            className={`${className} ${
              this.state.headCommentClass !== '' ? 'tiptap-editor' : ''
            }`}
            id='tiptap-editor'
            tabIndex={100}
            onFocus={this.setHeadComment}>
            <div className='form-group mb-0'>
              <div
                ref={this.setRef}
                className='media detail-comments-top-block'>
                <div
                  className={`media-body input-with-btn ${
                    this.state.inputActive ? 'input-focus-active' : ''
                  }`}
                  onBlur={this.unsetInputActive}>
                  <div
                    className='position-relative input-parent-box'
                    key={this.state.toggle}>
                    <TipTapEditor
                      display={this.displayData}
                      placeholder={this.getPlaceholderText()}
                      autoFocus={!this.isReply() ? 'end' : false}
                      content={this.state.content}
                      disabled={this.props.disabled}
                      inputActive={this.state.inputActive}
                      renderCardsAndAttachments={this.renderCardsAndAttachments}
                      assigningObject={this.assigningObject}
                      editOrReplyContainer={this.editOrReplyContainer}
                      onDirectPaste={this.onDirectPaste}
                      onDrop={this.onDrop}
                      editorType={this.state.editorType}
                      isMessageSecure={this.state.isMessageSecure}
                      changeSecureStatus={this.changeSecureStatus}
                      assignObjectWithoutOpeningModal={
                        this.assignObjectWithoutOpeningModal
                      }
                      handleEmojiPicker={this.handleEmojiPicker}
                      onDragLeave={this.onDragLeave}
                      onDragEnter={this.onDragEnter}
                      onInputFocus={this.onInputFocus}
                      onDropRejected={this.onDropRejected}
                      generalUsersRequired={this.isCommentable('Board')}
                      extraProps={{
                        isSharing: this.props.sharing,
                        entity: this.props.entity,
                      }}
                      toggleScheduleDropdown={this.toggleScheduleDropdown}
                      mentionGroups={this.mentionGroups()}
                      onKeyDown={this.onKeyDown}
                      submitButtonToggled={this.state.submitButtonToggled}
                      headCommentClass={this.state.headCommentClass}
                      isDropzoneActive={this.state.dropzoneActive}
                      setRef={(ref) => (this.inputField = ref.current)}
                      ref={this.tipTapEditorInputField}
                    />
                  </div>
                  {this.renderErrors()}

                  <div className='reply-buttons'>
                    <div className='reply-button-left-container'>
                      {this.renderAttachmentLink({ emojiPickerRequired: true })}
                    </div>
                    <OsBtn
                      name='BtnIcon'
                      extraClass='no-text message-send-btn'
                      icon='send'
                      onClick={this.handleSubmit.bind(
                        this,
                        this.props.useReplyLayout ? null : 'Reply',
                      )}
                      eventAttributes={{
                        parentCommentId: this.props.parentId,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.renderMentioneesWithoutAccess()}
            {this.renderNonOrthodontistPresentHint()}
            <div
              className='form-group mb-0 cta-wrap'
              onClick={(e) => e.stopPropagation()}>
              <div className='card-attachments'>
                {this.renderCardsAndAttachments()}
              </div>

              <div
                onClick={(e) => e.stopPropagation()}
                className={`mt-3 post-comment-footer-action ${
                  this.props.editComment ? '' : 'media-actions'
                }`}>
                {this.editOrReplyContainer() &&
                  !this.isSharing() &&
                  this.renderAttachmentLink()}
                <div
                  className={
                    this.props.editComment
                      ? 'reply-edit-group w-100'
                      : 'btn-group-right'
                  }>
                  {this.props.children}
                  {this.isSharing() && (
                    <OsBtn
                      name='BtnIcon'
                      text='Back'
                      disabled={this.state.requestInProgress}
                      onClick={this.props.toggleSharing}
                      extraClass='px-3 web-view-btn'
                    />
                  )}
                  {this.renderSubmitButton()}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  openMobileCommentScreen = (newCommentOpen) => {
    this.setState({ newCommentOpen }, () => {
      if (!this.state.newCommentOpen) this.setState(this.defaultValues());
    });
  };

  isQuestionActive() {
    return (
      (!!this.state.content && this.state.content.indexOf('?') > -1) ||
      this.state.hoverActive === 'question'
    );
  }

  renderAvatar() {
    return (
      <div className='comment-avatar'>
        <Avatar
          {...avatarAttributes(this.props.currentUser.graph)}
          className='avatar avatar-24'
        />
      </div>
    );
  }

  modalApproachRequired() {
    return (
      this.props.modalApproachRequired &&
      this.props.device.mobileDevice &&
      this.props.device.portrait &&
      this.props.workspaceComment
    );
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  closeModal() {
    if (this.state.isModalOpen) this.setState({ isModalOpen: false });
  }

  renderModalIntitiator() {
    if (this.state.isModalOpen) {
      return (
        <Modal
          show={true}
          onHide={this.toggleModal}
          animation={true}
          dialogClassName='pulse-report-modal pulse-add-new'
          backdropClassName='modal-backdrop-custom add-pulse-backdrop'>
          <div class='new-post-heading d-flex'>Add a new comment</div>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn close-add-new-post'
            icon='close'
            label='Close add a new comment modal'
            onClick={this.toggleModal}
          />
          {this.renderForm()}
        </Modal>
      );
    } else {
      return (
        <ExplorePostInitiator
          onClick={this.toggleModal}
          source={this.props.commentableType}
        />
      );
    }
  }

  render() {
    if (
      this.props.device.mobileDevice &&
      !this.isCommentable('Board') &&
      this.props.modalApproachRequired
    ) {
      if (this.props.content || this.props.editComment) {
        return this.renderForm('comment-edit-container');
      } else if (this.props.parentId || this.props.useReplyLayout) {
        return this.renderForm();
      } else if (this.state.newCommentOpen) {
        return (
          <Modal
            show={true}
            animation={false}
            dialogClassName='modal-comment-mobile'
            backdropClassName='modal-backdrop-custom'>
            <div className='comment-mobile-heading' ref='commentModalHeading'>
              <OrthoIcon
                name='chevron-left'
                dataHoverNotRequired={true}
                onClick={this.openMobileCommentScreen.bind(this, null)}
              />
              <span>
                Add new {this.getCommentTypeValue(this.state.newCommentOpen)}
              </span>
            </div>
            {this.renderForm()}
          </Modal>
        );
      } else {
        return (
          <div className='media-actions-buttons w-100'>
            <div
              className='add-comment-button '
              onClick={this.openMobileCommentScreen.bind(this, 'Discussion')}>
              {this.renderAvatar()}
              <span className='placeholder'>Add new comment</span>
            </div>
          </div>
        );
      }
    } else {
      return this.modalApproachRequired()
        ? this.renderModalIntitiator()
        : this.renderForm(
            this.props.content || this.props.editComment
              ? 'comment-edit-container'
              : '',
          );
    }
  }
}

WriteComment = withApollo(WriteComment);
WriteComment = connect(
  ({
    currentUser,
    commentShare,
    device,
    drafts,
    workspace,
    activeResource,
    scheduleMessageCount,
  }) => {
    return {
      currentUser,
      commentShare,
      device,
      drafts,
      workspace,
      activeResource,
      scheduleMessageCount,
    };
  },
  {
    commentShareOpen,
    commentShareConsume,
    openPoliciesModal,
    updateCommentDraft,
    clearDraft,
    openInfoModal,
    openTaskModalWithEntity,
  },
)(WriteComment);

WriteComment.defaultProps = {
  closeReply: () => {},
  useReplyLayout: false,
  modalApproachRequired: true,
  setRef: () => {},
  setScrollToCommentId: () => {},
  workspaceComment: false,
  onSubmitClick: () => {},
  onChangeContent: () => {},
  onFilesUpdate: () => {},
  onObjectsUpdate: () => {},
  mentionGroups: [],
  onSuccess: () => {},
  editorButtonTextRequired: true,
};
WriteComment = withRouter(WriteComment);
export default WriteComment;
