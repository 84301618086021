import {
  ASSIGN_ADD_OBJECTS,
  ASSIGN_CONSUME,
  ASSIGN_MESSAGE,
  ASSIGN_TYPE,
  ASSIGN_CREATE,
  ASSIGN_REMOVE_OBJECTS,
  ASSIGN_OBJETS_AND_CREATE,
} from './types';

// Add a single object to make overlay showup
export function assignAddObject(object, type) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_ADD_OBJECTS,
      payload: { objects: [object], type },
    });
  };
}

// Add many objects to make overlay showup
export function assignAddObjects(objects, type) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_ADD_OBJECTS,
      payload: { objects, type },
    });
  };
}

// Remove objects from added assign objects
export function assignRemoveObjects(objects, type) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_REMOVE_OBJECTS,
      payload: { objects, type },
    });
  };
}

// reset list and remove overlay
export function assignConsume() {
  window.$('html').removeClass('non-bs-modal-open');
  return (dispatch) => {
    dispatch({
      type: ASSIGN_CONSUME,
    });
  };
}

// reset list and display message instead
export function assignMessage(message) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_MESSAGE,
      payload: message,
    });
  };
}

// set the type to be either Space/Comparison that it is being assigned to
export function setAssignType(type) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_TYPE,
      payload: type,
    });
  };
}

// Make the create modal appear for the objectType so that it can be done on the same page
export function assignCreate(
  objectType,
  objects = [],
  status = 'none',
  id = null,
) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_CREATE,
      payload: { objectType, objects, status, id },
    });
  };
}

// Add Objects and create modal appear for objectType
export function assignObjectAndCreate(objects, type, objectType, id = null) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_OBJETS_AND_CREATE,
      payload: { objects, type, objectType, id },
    });
  };
}
