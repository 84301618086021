import React from 'react';
import { Field, Form } from 'react-final-form';

import OsField from 'app/components/OsField';
import { fieldRequired } from 'app/utils/validationHelper';
import OsBtn from 'app/components/OsBtn';

const WorkspaceInviteForm = ({ onSubmit, isSubmitting }) => {
  const initialValues = {};
  return (
    <>
      <Form
        onSubmit={onSubmit}
        disableOnEnter={true}
        destroyOnUnregister={true}
        keepDirtyOnReinitialize={true}
        initialValues={initialValues}>
        {
          // Form
          ({ handleSubmit, values, form }) => {
            return (
              <form className={'invite-modal-form'} onSubmit={handleSubmit}>
                <div className={'form-container'}>
                  {/* Title Field of the form */}

                  <div className='field-group'>
                    <div className='group-field'>
                      <label>First Name*</label>
                      <Field
                        name={'firstName'}
                        type={'text'}
                        component={OsField}
                        osType={'input'}
                        placeholder={'Jane'}
                        validate={fieldRequired}
                        autoFocus={true}
                      />
                    </div>

                    <div className='group-field'>
                      <label>Last Name*</label>
                      <Field
                        name={'lastName'}
                        type={'text'}
                        component={OsField}
                        osType={'input'}
                        placeholder={'Cross'}
                        validate={fieldRequired}
                        autoFocus={true}
                      />
                    </div>
                  </div>
                  <div className='field-group'>
                    <div className='group-field'>
                      <label>Email*</label>
                      <Field
                        name={'email'}
                        type={'text'}
                        component={OsField}
                        osType={'input'}
                        placeholder={'email@gmail.com'}
                        validate={fieldRequired}
                        autoFocus={true}
                      />
                    </div>
                    <div className='group-field'>
                      <label>Cell phone number</label>
                      <div className='phone-field'>
                        <Field
                          name={'countryCode'}
                          osType={'country_code_select'}
                          component={OsField}
                          onChange={(field, value) => form.change(field, value)}
                        />
                        <Field
                          name={'phoneNumber'}
                          placeholder={'(000) 000-0000'}
                          osType={'input'}
                          component={OsField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='field-title'>
                    <label>Title</label>
                    <Field
                      name={'designation'}
                      type={'text'}
                      component={OsField}
                      osType={'input'}
                      placeholder={'Care Co-ordinator'}
                      autoFocus={true}
                    />
                  </div>
                  {/* Render Submit buttons */}
                  <div className={'modal-footer p-0'}>
                    <div className={'invite-btn'}>
                      <OsBtn
                        id={'invite-button'}
                        type='button'
                        name='BtnPrimary'
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        text={'Invite'}
                        htmlTag='button'
                        extraClass='web-view-btn btn-save-info'
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }
        }
      </Form>
    </>
  );
};

export default WorkspaceInviteForm;
