export const getLabelNameWithParent = (label, allLabels) => {
  let parentLabel = getParentLabel(label, allLabels);
  if (parentLabel?.sub_labels.length > 0) {
    return `${parentLabel.name}/${label.name}`;
  } else {
    return label.name;
  }
};

export const getSiblingLabels = (label, allLabels) => {
  for (let i = 0; i < allLabels.length; i++) {
    const sub_labels = allLabels[i].sub_labels;
    let subLabel = sub_labels.find((subLabel) => subLabel.id === label.id);
    if (subLabel) return sub_labels;
  }
  return [];
};
export const getParentLabel = (label, allLabels) => {
  return allLabels.find((currentLabel) => {
    if (label.id === currentLabel.id) {
      return true;
    } else {
      return currentLabel.sub_labels.some((subLabel) => {
        return subLabel.id === label.id;
      });
    }
  });
};
