export const taskPaywallVideo =
  'https://www.loom.com/share/188cfd58358c483394c64c11af6f2020';
export const activityDashboardPaywallVideo =
  'https://www.loom.com/share/e66273b2c85a41deaaa9b82ca5dc6e2d';
export const patientPaywallVideo =
  'https://www.loom.com/share/18b495b05de841c696d5e223ebf69872';
export const foldersPaywallVideo =
  'https://www.loom.com/share/d36e95d48bb24321acf15b43fbd6bcd0';
export const conversationPaywallVideo =
  'https://www.loom.com/share/e2da2ced18a047dab317550c0bab8b72';
export const groupPaywallVideo =
  'https://www.loom.com/share/2d8b59d2f546410a94c4eaa84ca196de';
export const teamInboxPaywallVideo =
  'https://www.loom.com/share/b8721e36d94f40a4af21e205756e560c';
export const myInboxPaywallVideo =
  'https://www.loom.com/share/6fcc4d08561444f09412862cd8f47c11';
export const partnerDetailIntroVideo =
  'https://www.loom.com/share/920c9233283c4d11ba55c14a330fe057';
