import {
  SET_CONNECT_MODAL_OPEN,
  SET_CONNECT_MODAL_CLOSE,
} from 'app/actions/types';

export default function connectUser(
  state = {
    open: false,
    user: null,
  },
  action,
) {
  switch (action.type) {
    case SET_CONNECT_MODAL_OPEN:
      return {
        user: action.user,
        afterConnectionRequestCallback: action.afterConnectionRequestCallback,
        open: true,
      };

    case SET_CONNECT_MODAL_CLOSE:
      return { user: null, open: false };
    default:
      return state;
  }
}
