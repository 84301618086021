export const FOLDER_ADDED_SUCCESSFULLY = 'Folder Added';
export const FOLDER_UPDATED_SUCCESSFULLY = 'Folder Updated';
export const FOLDER_CATEGORIES = [
  {
    name: 'Private',
    value: 'close',
    description:
      'You will have to invite individual team members if you want them to have access.',
  },
  {
    name: 'Team',
    value: 'open',
    description: 'Automatically have the folder available to the entire team.',
  },
];
