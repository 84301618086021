import React, { useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { isArray } from 'app/utils/osLodash';

const TeamAccordion = ({
  list,
  defaultActiveKey,
  children,
  className,
  style,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const device = useSelector((state) => state.device);
  const accordionContainerRef = useRef(null);
  const listRef = useRef([]);
  const activeKeyRef = useRef([]);

  useEffect(() => {
    setActiveKeys(defaultActiveKey);
    activeKeyRef.current = defaultActiveKey;
    listRef.current = list;
    accordionItemHeight(defaultActiveKey);

    window.addEventListener('resize', resizeAccordion);
    return () => {
      // Removing the listener when the component is unmounted
      window.removeEventListener('resize', resizeAccordion);
    };
  }, []);

  useEffect(() => {
    if (list?.length > 0) {
      listRef.current = list;
      activeKeyRef.current = activeKeys;
      accordionItemHeight(activeKeys);
    }
  }, [activeKeys, list]);

  const resizeAccordion = () => {
    const accordionHeight = accordionContainerRef.current?.clientHeight;
    if (accordionHeight) accordionItemHeight(activeKeyRef.current);
  };

  const accordionItemHeight = (activeAccordionKeys) => {
    const accordionHeight = accordionContainerRef.current.clientHeight;
    const childNodes = accordionContainerRef.current.childNodes;
    const list = listRef.current;
    let itemWithHeight = 0; // counter with items with no height
    let inactiveItemHeight = 0;
    for (let index = 0; index < childNodes.length; index++) {
      const element = childNodes[index];
      const dataKey = childNodes[index]?.getAttribute('data-key');

      if (
        !activeAccordionKeys.includes(dataKey) ||
        !(list[index]?.text || list[index]?.component) // Consider item with content or text as noHeight
      ) {
        inactiveItemHeight += element.firstChild.clientHeight;
      } else {
        itemWithHeight += 1;
      }
    }

    for (let index = 0; index < childNodes.length; index++) {
      const element = childNodes[index];
      const dataKey = childNodes[index]?.getAttribute('data-key');
      const dataElement = element.querySelector(
        `[data-id="${list[index].name}"]`,
      );
      if (activeAccordionKeys.includes(dataKey)) {
        // allcate equal height only if have text or component
        if (list[index]?.text || list[index]?.component) {
          let elementHeight =
            (accordionHeight - inactiveItemHeight) / itemWithHeight;
          if (index > 0) {
            // other child nodes have border of 4px, so subtract 5 here, to maintain height
            elementHeight -= 5;
          }
          element.style.height = `${elementHeight}px`;
          const lastChildHeight =
            elementHeight - element.firstChild.clientHeight;
          element.lastChild.style.height = `${lastChildHeight}px`;
          if (dataElement) {
            dataElement.style.height = `${lastChildHeight}px`;
          }
        }
      } else {
        element.style.height = 'auto';
        element.lastChild.style.height = 'auto';
        if (dataElement) {
          dataElement.style.height = 'auto';
        }
      }
    }
  };

  const onSelectionUpdate = (keys) => {
    const currentSelectedKeys = isArray(keys) ? keys : [keys];
    setActiveKeys(currentSelectedKeys);
    activeKeyRef.current = currentSelectedKeys;
    accordionItemHeight(currentSelectedKeys);
  };
  let accordionClass = 'left-ac ';
  accordionClass += device.mobileDevice ? 'left-ac-mobile' : 'flexi-ac-wrapper';
  return (
    <article id={'left_side_bar'} className={className} style={style}>
      {list?.length > 0 && (
        <Accordion
          defaultActiveKey={defaultActiveKey}
          activeKey={activeKeys}
          alwaysOpen
          className={accordionClass}
          onSelect={onSelectionUpdate}
          ref={accordionContainerRef}>
          {children(activeKeys)}
        </Accordion>
      )}
    </article>
  );
};

export default TeamAccordion;
