import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import PhoneLink from 'app/components/Shared/PhoneLink';
import EmailLink from 'app/components/Shared/EmailLink';
import AddressLink from 'app/components/Shared/AddressLink';

class OrganizationVisitingCard extends Component {
  renderPhoneNumber(record) {
    return <PhoneLink phone={record.phone} icon={true} />;
  }

  renderEmail(record) {
    return <EmailLink email={record.email} icon={true} />;
  }

  renderAddress(record) {
    return <AddressLink address={record.address} icon={true} />;
  }

  renderClinicPhoneEmail(record) {
    if (record.phone || record.email)
      return (
        <div className='info-text-col'>
          {record.phone && this.renderPhoneNumber(record)}
          {record.email && this.renderEmail(record)}
        </div>
      );
  }

  renderClinicImage(record) {
    if (record.logo_big) {
      return <img src={record.logo_big} className='' alt='' />;
    } else {
      return this.renderNonImageBlock(record);
    }
  }

  renderNonImageBlock(record) {
    return (
      <div className='non-image-wrap'>
        <OrthoIcon name='workspaceSpace' dataHoverNotRequired={true} />
        <span className='non-image-text'>{record.name}</span>
      </div>
    );
  }

  renderClinicAdditionalDetails(record) {
    if (record.address || record.phone || record.email)
      return (
        <div className='care-team-info'>
          {this.renderClinicPhoneEmail(record)}
          {record.address && this.renderAddress(record)}
        </div>
      );
  }

  render() {
    const record = this.props.obj;
    return (
      <>
        <div className='team-header-image' onClick={this.props.onCardClick}>
          {this.renderClinicImage(record)}
        </div>

        {this.renderClinicAdditionalDetails(record)}
      </>
    );
  }
}

export default OrganizationVisitingCard;
