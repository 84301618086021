import * as dayjs from 'dayjs';
import { TIMEZONE } from 'app/constants.js';
import { FORMAT_START_WITH_MONTH_SLASH } from 'app/components/Task/taskConstant';

var utc = require('dayjs/plugin/utc'); // dependent on utc plugin
var timezone = require('dayjs/plugin/timezone');
var localizedFormat = require('dayjs/plugin/localizedFormat'); // for few extra formats
var calendar = require('dayjs/plugin/calendar'); // for timestampOnHover
var duration = require('dayjs/plugin/duration'); // for timeDifferenceFromNowInMilliseconds
var relativeTime = require('dayjs/plugin/relativeTime'); // for fromNow
var advancedFormat = require('dayjs/plugin/advancedFormat');
var isBetween = require('dayjs/plugin/isBetween');
var weekday = require('dayjs/plugin/weekday');
var isoWeek = require('dayjs/plugin/isoWeek');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
var customParseFormat = require('dayjs/plugin/customParseFormat');
const weekDiff = require('dayjs/plugin/weekOfYear');

dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekDiff);

export function getDatesBetween(
  startDate,
  stopDate,
  startDateIncluding = true,
) {
  var clonedStartDate = startDate.clone(),
    dateArray = [],
    currentDate = clonedStartDate.startOf('day');
  stopDate = stopDate.startOf('day');
  while (currentDate <= stopDate) {
    if (!startDateIncluding) {
      currentDate = currentDate.add(1, 'd');
    }
    dateArray.push(currentDate.clone());
    if (startDateIncluding) {
      currentDate = currentDate.add(1, 'd');
    }
  }
  return dateArray;
}

export function timeDifferenceFromNowInMilliseconds(time) {
  var end = dayjs(time * 1000),
    now = dayjs(new Date()),
    duration = dayjs.duration(now.diff(end)),
    differenceInMilli = duration.asMilliseconds();
  return differenceInMilli;
}

export function timeDifferenceFromThenInMilliseconds(time) {
  return Math.abs(timeDifferenceFromNowInMilliseconds(time));
}

export function timeWithDefaultZone(time) {
  return dayjs(time * 1000).tz(TIMEZONE);
}

export function currentTime() {
  return dayjs().tz(TIMEZONE);
}

export function currentTimeWithUserTimeZone() {
  return dayjs();
}

export function currentTimeWithUserTimeZoneInSeconds() {
  return currentTimeWithUserTimeZone().unix();
}

export function timeWithUserTimeZone(time) {
  return dayjs(time * 1000);
}

export function currentYear() {
  return new Date().getFullYear();
}

export function isSameYear(date1, date2) {
  return dayjs(date1 * 1000).year() === dayjs(date2 * 1000).year();
}

export function timeWithFormatForSeconds(date, format, specialFormat, options) {
  return timeWithFormat(dayjs(date).unix(), format, specialFormat, options);
}

export function convertToUnix(date) {
  return dayjs(date).unix();
}

export function timestampOnHoverForSeconds(date) {
  return timestampOnHover(dayjs(date).unix());
}

export function secondsToTimeConverter(time) {
  return dayjs(time);
}

export function startOfDay(time) {
  return secondsToTimeConverter(time * 1000).startOf('day');
}

export function timeWithFormat(time, format, specialFormat, options = {}) {
  if (specialFormat) {
    // Used on Case Survey analytics modal & Activity Feed
    var timeValue = dayjs.isDayjs(time) ? time : dayjs(time * 1000),
      clonedTime = timeValue.clone(),
      dateDiff = dayjs().startOf('day').diff(clonedTime.startOf('day'), 'days');
    switch (true) {
      case dateDiff < 1 && dateDiff >= 0:
        if (options.todayFormatRequired) {
          return 'Today';
        } else if (options.todayWithTimeRequired) {
          return 'Today, ' + timeValue.format('LT');
        } else {
          return timeValue.format('HH:mm');
        }
      case dateDiff === 1:
        return 'Yesterday';
      case dateDiff === -1:
        return options.tomorrowWithTimeRequired
          ? 'Tomorrow, ' + timeValue.format('LT')
          : 'Tomorrow';
      case Math.abs(dateDiff) < 7:
        if (options.dayNameNotRequired) {
          return timeValue.format(format);
        } else {
          format = options.dayNameFormat || 'dddd';
          return timeValue.format(format);
        }
      default:
        return timeValue.format(format);
    }
  } else {
    return timeWithUserTimeZone(time).format(format);
  }
}

export function futureDate(options = {}) {
  let now = dayjs();

  if (options.months) now = now.add(options.months, 'months');
  if (options.years) now = now.add(options.years, 'years');

  return now.format(options.format || 'DD/MM/YY');
}

export function isSameDay(firstTime, secondTime) {
  let firstDate = dayjs(firstTime * 1000).startOf('day'),
    secondDate = dayjs(secondTime * 1000).startOf('day');

  return secondDate.diff(firstDate, 'days') === 0;
}

export function timestampOnHover(time) {
  return dayjs(time * 1000).calendar(null, {
    sameDay: 'dddd, MMMM D, YYYY [at] h:mm A',
    lastDay: 'dddd, MMMM D, YYYY [at] h:mm A',
    lastWeek: 'dddd, MMMM D, YYYY [at] h:mm A',
    sameElse: 'dddd, MMMM D, YYYY [at] h:mm A',
  });
}

export function timestamp(time, currentTime) {
  time -= 5;
  if (currentTime) {
    return dayjs(time * 1000).from(currentTime);
  }
  return dayjs(time * 1000).fromNow();
}

export function editTimestamp(time) {
  return dayjs(time * 1000).calendar(null, {
    sameDay: 'h:mma',
    lastDay: 'MM/D/YY',
    lastWeek: 'MM/D/YY',
    sameElse: 'MM/D/YY',
  });
}

export function isCurrentDay(time) {
  let today = dayjs().startOf('day'),
    timeDate = dayjs(time * 1000).startOf('day');

  return today.diff(timeDate, 'days') === 0;
}

export function isTomorrowDay(time) {
  let today = dayjs().add(1, 'days').startOf('day'),
    timeDate = dayjs(time * 1000).startOf('day');

  return today.diff(timeDate, 'days') === 0;
}

export function isYesterdayDay(time) {
  let today = dayjs().subtract(1, 'days').startOf('day'),
    timeDate = dayjs(time * 1000).startOf('day');

  return today.diff(timeDate, 'days') === 0;
}

export function isYesterday(time) {
  let today = dayjs().startOf('day'),
    timeDate = dayjs(time * 1000).startOf('day');

  return today.diff(timeDate, 'days') === 1;
}

export function yesterday() {
  return currentTime().subtract(1, 'days');
}

export function tomorrow() {
  return currentTime().subtract(1, 'days');
}

export function getDateAndTime(time) {
  return {
    date: timeWithFormat(time, 'MMMM D, YYYY'),
    time: timeWithFormat(time, 'HH:mm a'),
  };
}

// day @ time
export function displayDayWithTime(time) {
  let details = getDateAndTime(time);
  return `${details.date} @ ${details.time}`;
}

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24)),
    h = Math.floor((seconds % (3600 * 24)) / 3600),
    m = Math.floor((seconds % 3600) / 60),
    s = Math.floor(seconds % 60),
    dDisplay = d > 0 ? d + (d === 1 ? ' day' : ' days') : '',
    hDisplay = h > 0 ? `${h}h` : '',
    mDisplay = m > 0 ? `${m}m` : '',
    sDisplay = s > 0 ? `${s}s` : '';
  return [dDisplay, hDisplay, mDisplay, sDisplay].filter((d) => d).join(' ');
}

export function timeGreaterThanCurrentTime(time) {
  return timeDifferenceFromNowInMilliseconds(time) < 0;
}

export function timeDifferenceFromNowInDetails(time) {
  var end = dayjs(time * 1000),
    now = dayjs(new Date());

  return dayjs.duration(now.diff(end)).$d;
}

export function stringToDate(string) {
  return dayjs(string).toDate();
}

export function dateToString(date, format) {
  return dayjs(date).format(format);
}

export function isMinorAge(dob) {
  return dayjs().diff(dob, 'years') < 18;
}

export function timeToDate(time) {
  return dayjs(time * 1000).toDate();
}

export function getCurrentDateTime() {
  return dayjs().format('YYYY-MM-DD-HHmm');
}

// Method to provide the unix timestamp of the start of the current day.
export function startOfCurrentDay() {
  return dayjs().startOf('day').unix();
}

export function isDateInThisWeek(date) {
  return dayjs().isoWeek() === dayjs(date).isoWeek();
}

export function getDateFromStringWithGivenFormat(date, format) {
  return dayjs(date).format(format);
}

export function firstDayOfCurrentWeek() {
  return dayjs().day(0);
}

export function lastDayOfCurrentWeek() {
  return dayjs().day(6);
}

export function firstDayOfGivenDateWeek(date) {
  return dayjs(date).day(1);
}

export function lastDayOfGivenDateWeek(date) {
  return dayjs(date).day(7);
}

export function add(date, number, unit) {
  return dayjs(date).add(number, unit);
}

export function subtract(date, number, unit) {
  return dayjs(date).subtract(number, unit);
}

export function getUnixFromDateString(date) {
  return dayjs(date).unix();
}

export function isDateGreaterThanCurrentDate(date) {
  return date.diff(dayjs());
}

export function isGivenDayGreaterThanCurrentDate(dayNumber) {
  return (
    dayjs().day(dayNumber).unix() >=
    getUnixFromDateString(
      getDateFromStringWithGivenFormat(
        currentTimeWithUserTimeZone(),
        'YYYY-MM-DD',
      ),
    )
  );
}

export function getCurrentDayName() {
  return dayjs().format('dddd');
}

export function isDateInPreviousWeek(date) {
  // Using sunday as the first day of the week.
  const previousWeekDate = dayjs().weekday(-6);
  return dayjs(previousWeekDate).isoWeek() === dayjs(date).isoWeek();
}

export function isBeforeTodayDate(date) {
  return !isCurrentDay(date) && !dayjs().isBefore(dayjs.unix(date), 'date');
}

export function isBeforeToday(date, format) {
  return dayjs().isBefore(dateToString(dayjs.unix(date), format), 'date');
}

export function convertDayJsDateInStringFormatToJsDate(date) {
  // Date should be in string format.
  return dayjs(date).toDate();
}

export function convertStringToDayJsObject(date, format) {
  return dayjs(date, format);
}

export function getFixedDateFromDateAndMonthAndYear(date, month, year) {
  return dayjs(dayjs(dayjs().date(date).month(month)).year(year))
    .utc()
    .format();
}

export function getCurrentYear() {
  return dayjs().year();
}

export function getDifferenceInDays(date) {
  return dayjs().diff(dayjs.unix(date), 'day');
}

export function isTimeInRange(date, startTimeString, endTimeString) {
  let startTime = dayjs(startTimeString, 'h:mma').format('YYYY-MM-DD HH:mm');
  let endTime = dayjs(endTimeString, 'h:mma').format('YYYY-MM-DD HH:mm');
  date = date.format('YYYY-MM-DD HH:mm');

  if (startTimeString.split(' ')[1] === 'PM')
    startTime = addHrsToGivenDate(startTime, 12, 'YYYY-MM-DD HH:mm');

  if (endTimeString.split(' ')[1] === 'PM')
    endTime = addHrsToGivenDate(endTime, 12, 'YYYY-MM-DD HH:mm');

  return dayjs(date).isBetween(startTime, endTime, 'minute');
}

export function addHrsToGivenDate(date, hour, format) {
  let givenDateHrs = dayjs(date).hour();
  let updatedDate = dayjs(date)
    .hour(hour + givenDateHrs)
    .format(format);

  return updatedDate;
}

export function addTimezoneOffset(timezone) {
  const offsetMatch = timezone.match(/GMT([-+]\d{2}:\d{2})/);
  const offset = offsetMatch ? offsetMatch[1] : null;
  const now = dayjs().utcOffset(offset);
  return { now, offset };
}
export function jumpWeekFromCurrentDate(week) {
  return dayjs().add(week, 'week');
}

export const checkWeek = (date) => {
  const givenDate = dayjs.unix(date);

  const diff = givenDate.diff(
    firstDayOfGivenDateWeek(dayjs()).startOf('day'),
    'week',
  );
  return diff;
};

export const getDateInFormatFromUnix = (date, format) => {
  return dayjs.unix(date).format(format);
};
