import React, { Component } from 'react';
import InviteMember from 'app/components/InviteMember';
import OsBtn from 'app/components/OsBtn';
import { connect } from 'react-redux';

import { isWorkspaceAuthorOrEditor } from 'app/utils/Workspace/generalHelper';

class InviteMemberBtn extends Component {
  state = {
    openInviteModal: false,
  };

  toggleInviteModal = () => {
    this.setState({ openInviteModal: !this.state.openInviteModal });
  };

  inviteModal() {
    return (
      this.state.openInviteModal && (
        <InviteMember
          showModal={true}
          modalClosed={this.toggleInviteModal}
          board={this.props.space}
          viewType='modal'
          remountComponent={this.props.remountComponent}
        />
      )
    );
  }

  inviteBtn() {
    return (
      <div className='detail-block action-block text-right'>
        <OsBtn
          name='BtnPrimary'
          extraClass='me-3'
          onClick={this.toggleInviteModal}
          text='Invite New Members'
        />
      </div>
    );
  }

  render() {
    if (!this.props.device.mobileDevice && isWorkspaceAuthorOrEditor()) {
      return (
        <>
          {this.inviteBtn()}
          {this.inviteModal()}
        </>
      );
    } else {
      return null;
    }
  }
}

InviteMemberBtn = connect(({ device }) => ({ device }))(InviteMemberBtn);

export default InviteMemberBtn;
