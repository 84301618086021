import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Form, Field } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import Loader from 'react-loaders';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import * as compose from 'lodash.flowright';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';

import StoreUpdater from 'app/services/StoreUpdater';

import { translate } from 'app/actions/flashMessage';

import {
  timeWithFormat,
  timestamp,
  timeWithUserTimeZone,
} from 'app/utils/timeHelper';
import { fieldRequired } from 'app/utils/validationHelper';
import { isEmpty } from 'app/utils/osLodash';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { pluralize } from 'app/utils/generalHelper';

const DEACTIVATE_SHARE_LINK_MUTATION = queryLoader(
  'app/graphql/mutations/Spaces/DeactivateSpaceShareLink.gql',
);
const UPDATE_SHARE_LINK_MUTATION = queryLoader(
  'app/graphql/mutations/Spaces/UpdateSpaceShareLink.gql',
);
const SPACE_SHARE_LINKS_QUERY = queryLoader(
  'app/graphql/queries/Spaces/ShareLinks.gql',
);

class SpaceShareLinks extends Component {
  state = { editLink: {}, newLinkCreate: false, newLink: {} };

  shareLinks() {
    return this.props.data.space.share_links;
  }

  editLinkPermissions = (editLink) => {
    this.change &&
      this.change('expiration', timeWithUserTimeZone(editLink.expired_at));
    this.setState({ editLink, passwordRequired: !editLink.open_link });
  };

  onLinkCopy = (copiedLinkId) => {
    clearTimeout(this.clearCopyMessage);
    this.setState({ copiedLinkId });
    this.clearCopyMessage = setTimeout(() => {
      this.setState({ copiedLinkId: null });
    }, 1500);
  };

  toggleNewLinkForm = () => {
    this.setState({ newLinkCreate: !this.state.newLinkCreate });
  };

  onSubmit = (values) => {
    this.setState({ requestInProgress: true });
    let space = this.props.entity;
    return this.props
      .updateSpaceShareLinkMutation({
        variables: {
          name: values.name,
          id: this.state.editLink.id,
          spaceId: space.nice_id,
          password: this.state.passwordRequired ? values.password : null,
          expiration: values.expiration?.toString(),
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        if (data.updateSpaceShareLink.success) {
          this.reset();
          let newLink = data.updateSpaceShareLink.entity;
          if (isEmpty(this.state.editLink)) {
            this.setState({ newLink, newLinkCreate: false });
          } else {
            if (!isEmpty(this.state.newLink)) this.setState({ newLink });
            this.closeEditLink();
          }
          StoreUpdater.updateSpaceShareLink(newLink, space);
        } else {
          let errors = {};
          data.updateSpaceShareLink.errors.forEach(
            (error) => (errors[error.field] = error.message),
          );
          return errors;
        }
      });
  };

  linkCopied(link) {
    return +link.id === +this.state.copiedLinkId;
  }

  renderLink = (link) => {
    let linkViewedAtHint = link.last_viewed_at
      ? `Last Viewed at ${timeWithFormat(link.last_viewed_at, 'LLL')}`
      : '';

    return (
      <div className='expire-url-div' key={link.id}>
        <div className='d-flex align-items-center justify-content-between'>
          <span className='link-name'>{link.name} </span>
          <span className='link-shared'>
            Shared {timestamp(link.created_at)}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between my-3 position-relative '>
          <CopyToClipboard
            text={link.url}
            onCopy={this.onLinkCopy.bind(this, link.id)}>
            <OsBtn
              text={link.url}
              extraClass={`a-link-url ${link.active ? '' : ' disabled'}`}>
              <OrthoIcon name='linked' />
            </OsBtn>
          </CopyToClipboard>
          {this.linkCopied(link) && (
            <span className='tooltip-copy'>Copied to clipboard!</span>
          )}
          <CopyToClipboard
            text={link.url}
            onCopy={this.onLinkCopy.bind(this, link.id)}>
            <OsBtn
              name='BtnIcon'
              icon='notes-fill'
              extraClass='no-text icon-note ms-3 '
            />
          </CopyToClipboard>
        </div>

        <div className='d-flex edit-permission-div'>
          <OsBtn
            name='BtnIcon'
            icon='setting'
            text=''
            onClick={this.editLinkPermissions.bind(this, link)}
            extraClass='edit-permission-btn no-text me-3'
          />
          <span className='expire-text'>
            {link.active
              ? `Expires on ${timeWithFormat(link.expired_at, 'MM/DD/YY')}`
              : 'No longer active'}
            {link.open_link && <small className=''>No password added</small>}
          </span>

          <span className='link-views ms-auto' title={linkViewedAtHint}>
            <span className='os-4d9'>{link.view_count}</span> <br />
            {pluralize(link.view_count, { singular: 'View', plural: 'Views' })}
          </span>
        </div>
      </div>
    );
  };

  renderLinks() {
    let links = this.shareLinks(),
      permissionModalClassName = isEmpty(this.state.editLink) ? '' : ' show';
    return (
      <>
        <Modal.Header className='border-0 modal-header py-0 m-0 d-flex align-items-center'>
          <h4 className='modal-title fw-300'>Links created for this space</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn r-0'
            icon='close'
            label='Close modal'
            onClick={this.props.onClose}
          />
        </Modal.Header>
        <Modal.Body className='modal-body py-0'>
          <div className='link-list'>{links.map(this.renderLink)}</div>
        </Modal.Body>
        <Modal.Footer
          className={`share-link-modal-footer p-0 ${permissionModalClassName}`}>
          <OsBtn
            name='BtnPrimary'
            text='Create new link'
            onClick={this.toggleNewLinkForm}
          />
        </Modal.Footer>
      </>
    );
  }

  renderFormOrLinkDetails() {
    if (this.isNewLinkFormOpen()) {
      return (
        <div className='name-link'>
          <label>Name your link</label>
          <p className='new-space-link-hint'>
            {translate('NEW_SPACE_SHARE_LINK_HINT')}
          </p>
          <Form
            onSubmit={this.onSubmit}
            destroyOnUnmount={true}
            render={({ handleSubmit, form }) => {
              this.change = form.change;
              this.reset = form.reset;
              return (
                <form onSubmit={handleSubmit} id='share-link-form'>
                  <div className='col-md-12 px-0'>
                    <Field
                      name='name'
                      type='input'
                      osType='input'
                      component={OsField}
                      validate={fieldRequired}
                      placeholder='Add link name'
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      );
    } else {
      let link = this.state.newLink;
      return (
        <div className='expire-url-div only-div position-relative'>
          <div className='link-name'>{link.name}</div>
          {this.linkCopied(link) && (
            <span className='tooltip-copy'>Copied to clipboard!</span>
          )}
          <CopyToClipboard
            text={link.url}
            onCopy={this.onLinkCopy.bind(this, link.id)}>
            <OsBtn
              text={link.url}
              extraClass={`a-link-url ${link.active ? '' : ' disabled'}`}>
              <OrthoIcon name='linked' />
            </OsBtn>
          </CopyToClipboard>
          <div className='d-flex edit-permission-div'>
            <span className='expire-text'>
              {link.active
                ? `Expires on ${timeWithFormat(link.expired_at, 'MM/DD/YY')}`
                : 'No longer active'}
              {link.open_link && <small className=''>No password added</small>}
            </span>

            <OsBtn
              name='BtnIcon'
              icon='setting'
              text='Edit Permissions'
              onClick={this.editLinkPermissions.bind(this, link)}
              extraClass='edit-permission-btn'
            />
          </div>
        </div>
      );
    }
  }

  isNewLinkFormOpen() {
    return isEmpty(this.state.newLink) || this.state.newLinkCreate;
  }

  onSeeAllLinksCick = () => {
    this.setState({ newLinkCreate: false, newLink: {} });
  };

  renderModalFooterCTA() {
    if (this.isNewLinkFormOpen()) {
      return (
        <OsBtn
          name='BtnPrimary'
          htmlTag='button'
          text='Next'
          value='Next'
          form='share-link-form'
          type='submit'
          loaderRequired={this.state.requestInProgress}
          disabled={this.state.requestInProgress}
        />
      );
    } else {
      let link = this.state.newLink;
      return (
        <>
          <OsBtn
            name='BtnGhost'
            text='See All links'
            onClick={this.onSeeAllLinksCick}
          />
          <CopyToClipboard
            text={link.url}
            onCopy={this.onLinkCopy.bind(this, link.id)}>
            <OsBtn name='BtnPrimary' text='Copy Link' />
          </CopyToClipboard>
        </>
      );
    }
  }

  renderNewLinkForm() {
    return (
      <>
        <Modal.Header className='border-0 modal-header py-0 m-0 d-flex align-items-center'>
          <h4 className='modal-title fw-300'>Share a link to this space</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn r-0'
            icon='close'
            label='Close modal'
            onClick={this.props.onClose}
          />
        </Modal.Header>
        <Modal.Body className='py-0'>
          <div className='modal-info-helper-text'>
            {translate('SHARE_LINK_MODAL_CONTENT_HINT')}
          </div>
          {this.renderFormOrLinkDetails()}
        </Modal.Body>
        <Modal.Footer className='share-link-modal-footer p-0'>
          {this.renderModalFooterCTA()}
        </Modal.Footer>
      </>
    );
  }

  deactivateLink = () => {
    this.setState({ deleteRequestInProgress: true });
    this.props
      .deactivateSpaceShareLinkMutation({
        variables: { id: this.state.editLink.id },
      })
      .then(({ data }) => {
        this.setState({ deleteRequestInProgress: false });
        if (data.deactivateSpaceShareLink.success) {
          let newLink = data.deactivateSpaceShareLink.entity;
          this.closeEditLink();
          StoreUpdater.updateSpaceShareLink(newLink, this.props.entity);
          if (!isEmpty(this.state.newLink)) this.setState({ newLink });
        }
      });
  };

  closeEditLink = () => {
    if (this.change) {
      this.change('password', null);
      this.change('expiration', null);
    }
    this.setState({ editLink: {} });
  };

  togglePasswordRequired = () => {
    this.setState({ passwordRequired: !this.state.passwordRequired });
  };

  renderEditForm() {
    let link = this.state.editLink,
      passwordFieldContainerClassName = this.state.passwordRequired
        ? ''
        : ' hide-password-field',
      disableCTAs =
        this.state.requestInProgress || this.state.deleteRequestInProgress,
      submitButtonText = link.active ? 'Save Settings' : 'Reactivate link';

    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='col-md-12 px-0'>
              <Field
                name='expiration'
                type='date'
                osType='date'
                component={OsField}
                label='Set new expiration'
                autoComplete='off'
                validate={fieldRequired}
                selected={new Date(link.expired_at * 1000)}
              />
            </div>
            <div className='col-md-12 px-0 password-field-group'>
              <Field
                name='password'
                osType='password'
                component={OsField}
                label='Set new password'
                placeholder='Add Password'
                formGroupExtraClass={passwordFieldContainerClassName}
                autoComplete='off'
              />
              <OsField
                osType='checkbox'
                isChecked={this.state.passwordRequired}
                onChange={this.togglePasswordRequired}
                className='switch'
                slider={true}
                sliderType='square'
                dataHoverRequired={!isTouchSupported()}
              />
            </div>

            <div className='text-center share-link-modal-footer'>
              <OsBtn
                name='BtnIcon'
                extraClass='with-border righticon'
                leftIcon={false}
                icon='delete'
                text='Deactivate link'
                onClick={this.deactivateLink}
                loaderRequired={this.state.deleteRequestInProgress}
                disabled={disableCTAs || !link.active}
              />
              <OsBtn
                name='BtnPrimary'
                htmlTag='button'
                text={submitButtonText}
                type='submit'
                loaderRequired={this.state.requestInProgress}
                disabled={disableCTAs}
              />
            </div>
          </form>
        )}
      />
    );
  }

  renderEditLinkContainer() {
    let link = this.state.editLink,
      permissionModalClassName = isEmpty(link) ? '' : ' show';

    return (
      <div className={`share-permission-modal ${permissionModalClassName}`}>
        <div className='share-modal-header'>
          <h4 className='modal-title'>{link.name}</h4>
          <OsBtn
            name='BtnIcon'
            extraClass='no-text os-header-btn web-view-btn r-0'
            icon='close'
            label='Close modal'
            onClick={this.closeEditLink}
          />
        </div>
        <div className='permission-setting-form'>
          {!isEmpty(link) && this.renderEditForm()}
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.props.data.loading) {
      return <Loader type='ball-triangle-path' active />;
    } else {
      let links = this.shareLinks();
      if (
        this.state.newLinkCreate ||
        !links.length ||
        !isEmpty(this.state.newLink)
      ) {
        return this.renderNewLinkForm();
      } else {
        return this.renderLinks();
      }
    }
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={this.props.onClose}
        animation={false}
        dialogClassName={'share-link-modal'}
        backdropClassName='modal-backdrop-custom'>
        {this.renderContent()}
        {this.renderEditLinkContainer()}
      </Modal>
    );
  }
}

SpaceShareLinks = compose(
  graphql(DEACTIVATE_SHARE_LINK_MUTATION, {
    name: 'deactivateSpaceShareLinkMutation',
  }),
  graphql(UPDATE_SHARE_LINK_MUTATION, { name: 'updateSpaceShareLinkMutation' }),
  graphql(SPACE_SHARE_LINKS_QUERY, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: { id: props.match.params.id },
    }),
  }),
)(SpaceShareLinks);
SpaceShareLinks.defaultProps = {
  onClose: () => {},
};
SpaceShareLinks = withRouter(SpaceShareLinks);
export default SpaceShareLinks;
