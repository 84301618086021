import React, { Component } from 'react';

import Label from './Label';
import NewLabelForm from 'app/components/Shared/NewLabelForm';
import OsBtn from 'app/components/OsBtn';

import { connect } from 'react-redux';
import StoreUpdater from 'app/services/StoreUpdater';

class LabelListing extends Component {
  state = {
    newForm: false,
  };

  addNewForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ newForm: true });
  };

  renderAddNewButton() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='add'
        text='Add New'
        onClick={this.addNewForm}
        extraClass='lb-add-new web-view-btn osbtn-tertiary'
      />
    );
  }

  onNewLabelCreation = (label) => {
    this.props.onNewLabelSuccess(label);
    StoreUpdater.addLabelFromListing(label);
    this.setState({ newForm: false });
    setTimeout(
      () =>
        this.lastElement.scrollIntoView({ behavior: 'smooth', block: 'end' }),
      500,
    );
  };

  onCloseModal = () => {
    this.setState({ newForm: false });
  };

  renderNewForm() {
    if (this.state.newForm) {
      return (
        <div className='label-list-item form new-label-container'>
          <NewLabelForm
            onSuccess={this.onNewLabelCreation}
            closeModal={() => this.onCloseModal()}
          />
        </div>
      );
    }
  }

  labelSelected(label) {
    return (
      this.props.selectedLabel && +this.props.selectedLabel.id === +label.id
    );
  }

  render() {
    return (
      <div className='header-dropdown-list-item'>
        <div className='lb-li-body' id='labels-modal-body'>
          {this.props.labels.map((label) => (
            <Label
              obj={label}
              onLabelClick={this.props.onLabelClick}
              onSubLabelClick={this.props.onSubLabelClick}
              selected={this.labelSelected(label)}
              position={this.props.position}
            />
          ))}
          <div ref={(ref) => (this.lastElement = ref)}></div>
        </div>
      </div>
    );
  }
}

LabelListing.defaultProps = {
  labels: [],
  onNewLabelSuccess: () => {},
};
export default LabelListing;
