import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import useTaskPreview from 'app/components/TaskPreviewModal/useTaskPreview';
import AttachmentDropdown from 'app/components/Shared/AttachmentDropdown';
import { pluralize } from 'app/utils/generalHelper';
import OsCards from 'app/components/OsCards';
import { isEqual, reject } from 'app/utils/osLodash';
import usePreviousValue from 'app/hooks/usePreviousValue';
import { commentShareConsume } from 'app/actions/commentShare';
import OsBtn from 'app/components/OsBtn';

const TIPTAP_EXTENSION_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'CustomSubmit',
  'Underline',
  'Link',
  'NewHashtag',
  'Patients',
  'Template',
  'CustomMention',
];

const AddTaskComment = (props) => {
  const {
    assignCommentObject,
    handleTaskContent,
    handleCommentSubmit,
    isCommentSubmitting,
    setCommentState,
    commentState,
    onCommentFileDrop,
  } = useTaskPreview({ task: props?.task });
  const commentShare = useSelector((state) => state.commentShare);
  const { data: workspace } = useSelector((state) => state.workspace);
  const prevCommentShare = usePreviousValue(commentShare);
  const dispatch = useDispatch();
  const submitButtonRef = useRef(null);
  useEffect(() => {
    if (
      commentState.assigningObject &&
      !isEqual(prevCommentShare.cards, commentShare.cards)
    ) {
      setTimeout(() => {
        setCommentState((prev) => ({
          ...prev,
          files: [...prev.files, ...commentShare.cards.files],
          objects: [...prev.objects, ...commentShare.cards.objects],
          assigningObject: false,
        }));
        dispatch(commentShareConsume());
      }, 1000);
    }
  }, [commentShare]);

  const initialContentRef = useRef('');
  const handleSubmit = (e) => {
    initialContentRef.current = '';
    handleCommentSubmit({
      limit: props.limit,
      updateActivities: props.updateActivities,
      queryVariables: props.queryVariables || {},
    });
    props.onCommentSuccess && props.onCommentSuccess();
  };

  const setTaskCommentFiles = (files) => {
    setCommentState((prev) => ({ ...prev, files }));
  };
  const setTaskCommentObjects = (objects) => {
    setCommentState((prev) => ({ ...prev, objects }));
  };

  const removeAttachment = (file) => {
    let { files } = commentState,
      newFiles = reject(files, (currentFile) => currentFile.id === file.id);

    setCommentState((prev) => ({ ...prev, files: newFiles }));
  };

  const removeObject = (object) => {
    let { objects } = commentState,
      newObjects = reject(objects, {
        __typename: object.__typename,
        id: object.id,
      });

    setCommentState((prev) => ({ ...prev, objects: newObjects }));
  };

  const renderCard = (object, afterClose) => {
    return (
      <div
        key={`${object.__typename}:${object.id}`}
        className='file-holder attached-card'>
        <OsCards
          size='extra-small'
          obj={object}
          closeRequired={true}
          afterClose={afterClose}
          avoidPreviewModal={true}
        />
      </div>
    );
  };

  const renderList = (objects, afterClose) => {
    return objects.map((object) => renderCard(object, afterClose));
  };

  const { objects, files } = commentState;
  let objectsLength = objects.length;
  let filesLength = files.length;
  let totalItems = objectsLength + filesLength;

  useEffect(() => {
    initialContentRef.current = commentState.content;
  }, []);

  const onKeyDown = () => {
    submitButtonRef.current.onClick();
  };

  return (
    <>
      {totalItems > 0 && (
        <div className='attachment-file-block'>
          <div className='files-count'>
            {totalItems}{' '}
            {pluralize(totalItems, {
              singular: 'Attachment',
              plural: 'Attachments',
            })}
          </div>
          {renderList(files, removeAttachment)}
          {renderList(objects, removeObject)}
        </div>
      )}
      <div id={'editor-box'} className='tiptap-wrapper'>
        <OsTiptapEditorField
          placeholder={'Write your reply here…'}
          initialContent={initialContentRef.current}
          textType={'html'}
          dragAndDrop={false}
          extensions={TIPTAP_EXTENSION_REQUIRED}
          autofocus={false}
          onKeyDown={onKeyDown}
          updateContent={handleTaskContent}
          manualDragAndDrop={true}
          isSubmitClicked={isCommentSubmitting}
          files={files}
          handleFiles={setTaskCommentFiles}
          objects={objects}
          handleObjects={setTaskCommentObjects}
          isMenuBarRequired={false}
          onDrop={onCommentFileDrop}
          mentionGroups={[]}
          entity={props.task}
        />
        <div className='action-wrapper'>
          <AttachmentDropdown
            textRequired={false}
            assignObject={assignCommentObject}
          />
          <OsBtn
            name='BtnIcon'
            extraClass='no-text web-view-btn post-commment-btn'
            icon='send'
            onClick={handleSubmit}
            ref={submitButtonRef}
          />
        </div>
      </div>
    </>
  );
};

export default AddTaskComment;
