import { useContext, useMemo, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { getMappedSelectInputValue } from 'app/utils/select-input';
import useFetechAllPatients from 'app/hooks/patients/useFetchAllPatients';
import debounce from 'lodash/debounce';
import { flashSuccess } from 'app/actions/flashMessage';
import { useDispatch } from 'react-redux';
import { ENTITY_ATTACHED_SUCCESSFULLY } from 'app/components/Partners/constant';
import { PARTNERS_SPACE_LISTING } from 'app/components/Partners/usePartnersListing';
import { useParams } from 'react-router-dom';
import storeUpdater from 'app/services/StoreUpdater';
import useAddLabel from 'app/components/WorkspaceView/SettingView/LabelsView/useAddLabel';

const ATTACH_ENTITY_TO_COMMENT = queryLoader(
  'app/graphql/mutations/Comments/AttachEntityToComment.gql',
);

const useLabelSelector = (props) => {
  const { partner_labels } = useContext(WorkspaceContext);
  const [partnerLabels, setPartnerLabels] = useState(partner_labels);
  const { patients, setQuery, patientsLoading } = useFetechAllPatients();
  const dispatch = useDispatch();
  const params = useParams();
  let linkedEntityWithComment = props.comment.linked_entities[0];
  const [label, setLabel] = useState(() => {
    if (linkedEntityWithComment) {
      return {
        ...linkedEntityWithComment,
        value: linkedEntityWithComment.name,
        label: linkedEntityWithComment.name,
      };
    }
    return null;
  });
  useEffect(() => {
    if (linkedEntityWithComment) {
      setLabel({
        ...linkedEntityWithComment,
        value: linkedEntityWithComment.name,
        label: linkedEntityWithComment.name,
      });
    }
  }, [linkedEntityWithComment]);
  const [attachEntityToComment] = useMutation(ATTACH_ENTITY_TO_COMMENT);
  const { addNewLabel } = useAddLabel();

  const handleLabelValue = async (event, value) => {
    setLabel(value);
    handleSearchText('');
    await attachEntity(value);
  };

  const attachEntity = async (label) => {
    const { comment } = props;
    try {
      await attachEntityToComment({
        variables: {
          commentId: comment.id,
          entityId: label.id,
          entityType: label.__typename,
        },
        update: async (cache, { data }) => {
          let variables = {
            afterId: null,
            beforeId: null,
            aroundId: null,
            limit: 50,
            recordId: null,
            recordType: null,
            sortQuery: 'recent',
            textQuery: null,
            type: 'PartnerSpacesListing',
          };
          storeUpdater.updateLinkedEntitiesInPartnerSpace(label, variables, {
            query: PARTNERS_SPACE_LISTING,
            id: params.id, // needs to be nice_id of the space.
          });
        },
      });
      flashSuccess(ENTITY_ATTACHED_SUCCESSFULLY, false)(dispatch);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchText = debounce((text) => {
    setQuery(text);
    setPartnerLabels(
      partner_labels.filter((label) =>
        label.name.toLowerCase().includes(text.toLowerCase()),
      ),
    );
  }, 300);

  const handleNewLabel = (newLabelText) => {
    if (newLabelText) {
      addNewLabel({
        name: newLabelText,
        color: null,
        type: 'partner',
        subLabels: [],
      }).then((response) => {
        handleLabelValue(null, {
          ...response.data.updateLabel.entity,
          label: response.data.updateLabel.entity.name,
        }).then((r) => r);
      });
    }
  };

  const labelOptions = useMemo(() => {
    return [...patients, ...partnerLabels].map(getMappedSelectInputValue);
  }, [partner_labels, patients]);

  return {
    label,
    handleLabelValue,
    attachEntityToComment,
    attachEntity,
    handleNewLabel,
    labelOptions,
    patientsLoading,
    handleSearchText,
  };
};

export default useLabelSelector;
