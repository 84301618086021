import React, { PureComponent } from 'react';
import OsLink from 'app/components/OsLink';
import { lowerCase } from 'app/utils/osLodash';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
class NavItem extends PureComponent {
  isTabActive(link) {
    if (this.props.isTabActive) {
      return this.props.isTabActive(this.props.identifier);
    } else {
      let { pathname } = this.props.location,
        { url } = this.props;
      return pathname === url;
    }
  }

  onLinkClick(link, url) {
    if (this.props.location.pathname !== '/cloudberry-uat/profile/switcher') {
      this.props.onLinkClick.bind(this, link, url);
    }
  }

  render() {
    const { iconName, link, url, title, count } = this.props;
    return (
      <li
        className={`nav-item ${lowerCase(link)}-tab`}
        key={link}
        style={{ position: 'relative' }}>
        {!this.isTabActive(link) && count > 0 && (
          <span className='notification-count'>{count}</span>
        )}
        <OsLink
          to={!this.props.lockNavbar.lockNav && url}
          className={`${
            this.isTabActive(link) && this.props.globalSearchQuery?.query === ''
              ? 'active'
              : ''
          } nav-link`}
          onClick={() => this.onLinkClick(link, url)}
          iconName={iconName}
          iconDefaultClass={`icon-nav ${
            count > 0 && 'notification-available'
          }`}>
          <span className='nav-tooltip' data-tooltip={title || link}></span>
        </OsLink>
      </li>
    );
  }
}
NavItem = withRouter(NavItem);
NavItem = connect(
  ({ lockNavbar, globalSearchQuery }) => ({ lockNavbar, globalSearchQuery }),
  {},
)(NavItem);
NavItem.defaultProps = {
  onLinkClick: () => {},
};
export default NavItem;
