import React from 'react';
import OrthoIcon from '../Shared/OrthoIcon';
import { openCreateTeamSpaceModal } from 'app/actions/teamSpaceModal';
import { useDispatch } from 'react-redux';

const NewGroupButton = () => {
  const dispatch = useDispatch();
  const openCreateGroupModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return dispatch(
      openCreateTeamSpaceModal({
        canUpdateGroup: true,
      }),
    );
  };
  return <OrthoIcon name={'add'} onClick={openCreateGroupModal} />;
};

export default NewGroupButton;
