import React, { useEffect, useState } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import useInbox from 'app/hooks/useInbox';

const InboxSection = ({
  title,
  expandable = false,
  markReadButton = false,
  type,
  children,
  hideSection,
  markReadButtonText = 'Mark all as read',
}) => {
  const [showBody, setShowBody] = useState(false);
  const { markAllAsRead } = useInbox();

  const openBody = () => {
    setShowBody((prev) => !prev);
  };

  const handleMarkAsRead = () => {
    markAllAsRead(type);
  };

  useEffect(() => {
    setShowBody(!expandable);
  }, [expandable]);

  let containerClass = 'inbox-section';
  containerClass += hideSection ? ' is-hidden' : '';
  return (
    <div className={containerClass}>
      <div className='inbox-header'>
        <div className='label'>
          {title}{' '}
          {expandable && (
            <div className='i-action'>
              <OrthoIcon
                name={showBody ? 'chevron-up' : 'chevron-right'}
                onClick={openBody}
              />
            </div>
          )}
        </div>
        {markReadButton && (
          <div className='header-action'>
            <OrthoIcon name='view' />
            <span className='text' onClick={handleMarkAsRead}>
              {markReadButtonText}
            </span>
          </div>
        )}
      </div>
      {children(showBody)}
    </div>
  );
};

export default InboxSection;
