import { TaskContext } from 'app/context/TaskContext';
import TaskHeader from './TaskHeader';
import { useParams } from 'react-router';
import { useState } from 'react';
import useTask from 'app/hooks/useTask';
import TaskWeekView from 'app/components/Task/TaskWeekView';
import TaskListWeekView from 'app/components/Task/TaskListWeekView';
import { getDateFromUnixString } from 'app/utils/taskHelper';
import { useSearchParams } from 'react-router-dom';
import TaskListView from 'app/components/Task/TaskListView';
import UserRelatedTask from 'app/components/Task/UserRelatedTask';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import {
  LIST,
  UNIFIED_BOARD,
  UNIFIED_LIST,
} from 'app/components/Task/taskConstant';
import UpcomingSection from 'app/components/Task/UpcomingSection';
import UnifiedListView from 'app/components/Task/UnifiedListView';
import UnifiedBoardView from 'app/components/Task/UnifiedBoard/UnifiedBoardView';
import GroupLabelView from 'app/components/Task/GroupLabelView/GroupLabelView';

const TaskManager = (props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const initializeDemoHook = {
    user: props.user || { value: params.user, id: params.user } || null,
    date: props.date || getDateFromUnixString(params.date) || null,
    label: searchParams.get('label') || '',
    query: searchParams.get('filter'),
    person: searchParams.get('user'),
    labelGroupId: searchParams.get('group'),
    isUserView: props.isUserView,
    heading: props.heading,
    isOverdueView: props.isOverdueView,
    isUpcoming: props.isUpcomingView,
    isUnifiedView: props.isUnifiedView,
    view: props.view,
  };

  const {
    device,
    currentView,
    personFilter,
    showWidget,
    showSearchBar,
    setPersonFilter,
    textQuery,
    activeTasks,
    nextWeekactiveTasks,
    dueInTwoWeekactiveTasks,
    dueInThreeWeekactiveTasks,
    setShowWidget,
    setShowSearchBar,
    query,
    labelsData,
    unscheduledTasks,
    overdueTasks,
    completedTasks,
    inProgressTasks,
    teamMembersData,
    allUserOptions,
    activeTaskVariables,
    overdueTaskVariables,
    unscheduledTaskVariables,
    completedTaskVariables,
    inprogressTaskVariables,
    pageTitle,
    updateComponent,
    fetchNextCompletedRecords,
    updateCache,
    fetchNextOverdueRecords,
    fetchNextUnscheduledRecords,
    fetchNextInprogressRecords,
    changeInputSearchRoute,
    changeUserRoute,
    openNewTaskModal,
    backToToday,
    changeWeekHandler,
    changeLabel,
    changeView,
    viewedTasks,
    fetchNextViewedRecords,
    notViewedTasks,
    fetchNextNotViewedRecords,
    completedAndAssignedToOtherTasks,
    notCompletedAndAssignedToOtherTasks,
    fetchNextCompletedAssignedToOtherRecords,
    fetchNextNotCompletedAssignedToOtherRecords,
    labelGroup,
    handleLableGroupChange,
    groupLabelResponse,
    setGroupLabelResponse,
    updateTaskInGroupLabelRecords,
    removeTaskFromGroupLabelRecords,
  } = useTask(initializeDemoHook);

  const contextValue = {
    device,
    currentView,
    personFilter,
    showWidget,
    showSearchBar,
    setPersonFilter,
    initializeDemoHook,
    heading: initializeDemoHook.heading,
    user: initializeDemoHook.user,
    date: initializeDemoHook.date,
    isUserView: props.isUserView,
    overdueTasks,
    completedTasks,
    teamMembersData,
    unscheduledTasks,
    inProgressTasks,
    activeTaskVariables,
    overdueTaskVariables,
    unscheduledTaskVariables,
    completedTaskVariables,
    inprogressTaskVariables,
    textQuery,
    activeTasks,
    nextWeekactiveTasks,
    dueInTwoWeekactiveTasks,
    dueInThreeWeekactiveTasks,
    labelsData,
    setShowWidget,
    setShowSearchBar,
    query,
    allUserOptions,
    updateComponent,
    fetchNextCompletedRecords,
    updateCache,
    fetchNextOverdueRecords,
    fetchNextUnscheduledRecords,
    fetchNextInprogressRecords,
    changeInputSearchRoute,
    changeUserRoute,
    openNewTaskModal,
    backToToday,
    changeWeekHandler,
    changeLabel,
    changeView,
    viewedTasks,
    fetchNextViewedRecords,
    notViewedTasks,
    fetchNextNotViewedRecords,
    completedAndAssignedToOtherTasks,
    notCompletedAndAssignedToOtherTasks,
    fetchNextCompletedAssignedToOtherRecords,
    fetchNextNotCompletedAssignedToOtherRecords,
    labelGroup,
    handleLableGroupChange,
    groupLabelResponse,
    setGroupLabelResponse,
    updateTaskInGroupLabelRecords,
    removeTaskFromGroupLabelRecords,
  };

  const [allTaskOpen, setAllTaskOpen] = useState(false);
  const toggleTaskOpen = () => {
    setAllTaskOpen(!allTaskOpen);
  };

  const handleSetAllTaskOpen = () => {
    setAllTaskOpen(false);
  };

  const renderTaskManagerView = () => {
    switch (true) {
      case labelGroup !== null:
        return (
          <div className='taskManagerView'>
            <GroupLabelView
              allTaskOpen={allTaskOpen}
              setAllTaskOpen={handleSetAllTaskOpen}
            />
          </div>
        );
      case initializeDemoHook.isOverdueView:
        return (
          <div className='taskManagerView'>
            <TaskListView
              idType={'User'}
              results={overdueTasks?.records.results || []}
              listType={'OverDueTasks'}
              sectionTitle={`Overdue (${overdueTasks?.records.total || 0})`}
              loadMore={fetchNextOverdueRecords}
              isListOpen={true}
            />
          </div>
        );
      case initializeDemoHook.isUpcoming:
        return (
          <div className='taskManagerView'>
            <UpcomingSection />
          </div>
        );
      case currentView === UNIFIED_LIST:
        return (
          <div className='taskManagerView'>
            <UnifiedListView />
          </div>
        );
      case currentView === UNIFIED_BOARD:
        return (
          <div className='taskManagerView'>
            <UnifiedBoardView
              allTaskOpen={allTaskOpen}
              setAllTaskOpen={handleSetAllTaskOpen}
            />
          </div>
        );
      case props.isUserView:
        return (
          <div className='taskManagerView'>
            <UserRelatedTask initializeDemoHook={initializeDemoHook} />
          </div>
        );
      case currentView !== LIST:
        return (
          <div className='taskManagerView'>
            <TaskWeekView
              activeTasks={activeTasks}
              allTaskOpen={allTaskOpen}
              setAllTaskOpen={handleSetAllTaskOpen}
            />
          </div>
        );
      default:
        return (
          <div className='taskManagerView'>
            {query && (
              <TaskListView
                idType={'User'}
                results={completedTasks?.records.results || []}
                listType={'CompletedTasks'}
                sectionTitle={`Done (${completedTasks?.records.total || 0})`}
                loadMore={fetchNextCompletedRecords}
              />
            )}
            <TaskListView
              idType={'User'}
              results={unscheduledTasks?.records.results || []}
              listType={'UnscheduledTasks'}
              sectionTitle={`Unscheduled (${
                unscheduledTasks?.records.total || 0
              })`}
              loadMore={fetchNextUnscheduledRecords}
            />
            <TaskListView
              idType={'User'}
              results={overdueTasks?.records.results || []}
              listType={'OverDueTasks'}
              sectionTitle={`Overdue (${overdueTasks?.records.total || 0})`}
              loadMore={fetchNextOverdueRecords}
            />
            <TaskListWeekView
              user={initializeDemoHook.user}
              date={initializeDemoHook.date}
              searchQuery={query}
              textQuery={textQuery}
              activeTasks={activeTasks}
              variables={activeTaskVariables}
            />
          </div>
        );
    }
  };

  return (
    <TaskContext.Provider value={contextValue}>
      <div
        className={`taskManagerWrapper ${
          device.mobile ? 'mobile-device' : ''
        } `}>
        <SetPageTitle source='tasks' interpolations={pageTitle} />
        <TaskHeader allTaskOpen={allTaskOpen} toggleTaskOpen={toggleTaskOpen} />
        {renderTaskManagerView()}
      </div>
    </TaskContext.Provider>
  );
};

export default TaskManager;
