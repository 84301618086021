import { withRouter } from 'app/components/HOC/Router/withRouter';

import NewAction from './NewAction';

class Post extends NewAction {
  constructor(props) {
    super(props);
    this.newButtonText = 'Add new Post';
  }

  addNewAction = () => {
    this.props.navigate('/#writePost');
  };

  render() {
    return this.renderAddNewButton();
  }
}

Post = withRouter(Post);
export default Post;
