export const CARE_NAV_LINKS_MAPPER = {
  home: {
    iconName: 'home',
    name: 'home',
    path: '/spaces',
    title: 'Home',
  },
  care_team: {
    iconName: 'user-referral',
    name: 'care_team',
    path: '/care_team',
    title: 'Care team',
  },
  clinics: {
    iconName: 'software-fill',
    name: 'clinics',
    path: '/clinics',
    title: 'Clinics',
  },
  patients: {
    iconName: 'user_network',
    name: 'patients',
    path: '/patients',
    title: 'Patients',
  },
  carespaces: {
    iconName: 'user_network',
    name: 'carespaces',
    path: '/carespaces',
    title: 'PatientSpaces',
  },
  response_parties: {
    iconName: 'user_network',
    name: 'response_parties',
    path: '/response_parties',
    title: 'My Response Party',
  },
  my_profile: {
    iconName: 'account',
    name: 'my_profile',
    title: 'My Profile',
    onClickMethodName: 'openProfilePage',
  },
  notifications: {
    iconName: 'notification',
    name: 'notifications',
    path: '/notifications',
    title: 'Notifications',
    onClickMethodName: 'openNotificationPage',
    countRequired: true,
  },
  support: {
    iconName: 'support',
    name: 'support',
    path: '/support',
    title: 'Support',
    onClickMethodName: 'redirectToSupportChat',
  },
  logout: {
    iconName: 'logout',
    name: 'logout',
    title: 'Logout',
    onClickMethodName: 'logout',
  },
  messages: {
    iconName: 'messages',
    name: 'messages',
    path: '/communications',
    title: 'Messages',
    onClickMethodName: 'onCommunicationLinkClick',
    countRequired: true,
    abilityObject: { action: 'create', entity: 'conversation', options: {} },
  },
  more: {
    iconName: 'more',
    name: 'more',
    path: '/more',
    title: 'More',
    onClickMethodName: 'toggleNavDropdown',
  },
  updatePassword: {
    iconName: 'locked',
    name: 'Update Password',
    title: 'Update Password',
    onClickMethodName: 'openUpdatePasswordModal',
  },
  twoFactorAuthentication: {
    iconName: 'phone',
    name: 'Update Phone Number',
    title: 'Update Phone Number',
    onClickMethodName: 'renderTwoFactorAuthentication',
  },
};
