import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';
import OsCards from 'app/components/OsCards';

import EventTracker from 'app/services/EventTracker';
import RefetchQueriesManager from 'app/services/RefetchQueriesManager';
import StoreUpdater from 'app/services/StoreUpdater';
import VideoRecordButton from 'app/components/Shared/VideoRecordButton';

import {
  commentShareOpen,
  commentShareConsume,
} from 'app/actions/commentShare';
import { disableActiveConversation } from 'app/actions/activeConversation';
import { openPoliciesModal } from 'app/actions/policiesModal';
import { openUpgradeModal } from 'app/actions/upgrade';
import { translate } from 'app/actions/flashMessage';
import { updateMessageDraft, clearDraft } from 'app/actions/drafts';
import { getDraftKey } from 'app/reducers/drafts';

import { currentTimeWithUserTimeZone } from 'app/utils/timeHelper';
import {
  ceil,
  isEmpty,
  isEqual,
  map,
  now,
  reject,
  uniq,
} from 'app/utils/osLodash';
import {
  parseMentionees,
  removeMentioneeName,
} from 'app/utils/textParseHelper';
import AttachmentDropdown from '../Shared/AttachmentDropdown';
import NewMessageEditor from './NewMessageEditor';
import OsGrid from 'app/components/OsGrid';
import { keyboardUIAdditionalBarSupported } from 'app/utils/deviceHelper';

const SEND_CONVERSATION_MESSAGE_MUTATION = queryLoader(
  'app/graphql/SendConversationMessage.gql',
);

// Create a Conversation message
class NewMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assigningObject: false,
      processingRequest: false,
      ...this.initializeFromDraft(),
    };
    this.removeObject = this.removeObject.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.initialContentRef = React.createRef();
    this.initialContentRef.current = '';
  }

  initializeFromDraft() {
    const {
      content: draftContent = '',
      files = [],
      objects = [],
    } = this.getDraft();
    const state = this.props.location.state;

    const content = !isEmpty(state)
      ? translate(state.prefilledTextKey, state.prefilledTextProps)
      : draftContent;

    return {
      files,
      objects,
      content,
    };
  }

  getDraft() {
    const draftLookUpFields = {
      conversationId: this.props.conversationId,
      userId: this.props.currentUser.graph && this.props.currentUser.graph.id,
    };

    this.draftKey = this.draftKey || getDraftKey.message(draftLookUpFields);

    return this.props.drafts[this.draftKey] || {};
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.scrollTextAreaIntoView);
    this.initialContentRef.current = this.state.content;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.assigningObject &&
      this.props.commentShare.status === 'open' &&
      nextProps.commentShare.status === 'closed'
    ) {
      this.setState({ assigningObject: false });
    }

    if (
      this.state.assigningObject &&
      !isEqual(this.props.commentShare.cards, nextProps.commentShare.cards)
    ) {
      let files = this.state.files,
        objects = this.state.objects;
      files = files.concat(nextProps.commentShare.cards.files);
      objects = objects.concat(nextProps.commentShare.cards.objects);

      this.setState({ files, objects, assigningObject: false });
      this.props.commentShareConsume();
    }

    if (
      this.props.conversationId.toString() !==
      nextProps.conversationId.toString()
    ) {
      this.saveDraft();
      this.draftKey = '';
      this.setState({ files: [], objects: [], content: '' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (+prevProps.conversationId !== +this.props.conversationId) {
      this.shouldAutoFocus() && this.input.focus();

      const {
        content: existingContent,
        objects: existingObjects = [],
        links: existingLinks = [],
      } = this.state;

      if (existingContent || existingObjects.length || existingLinks.length)
        return;

      const { content = '', links = [], objects = [] } = this.getDraft();

      this.setState({ content, links, objects });
    }
  }

  saveDraft() {
    const { content, files, objects } = this.state;

    const { conversationId } = this.props;
    const payload = {
      content,
      files,
      objects,
      conversationId,
    };

    this.props.updateMessageDraft(payload);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'orientationchange',
      this.scrollTextAreaIntoView,
    );
    this.saveDraft();
  }

  scrollTextAreaIntoView() {
    if (document.activeElement === this.input) {
      window.setTimeout(() => {
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        );
      }, 500);
    }
  }

  changeContent = (content) => {
    this.setState({ content }, () => this.saveDraft());
  };

  onDrop = (files) => {
    files.length && this.handleExternalEntityShare(files);
  };

  sendMessageData() {
    return {
      conversation_id: this.props.conversationId,
      has_attachment: this.state.files.length > 0 ? 'YES' : 'NO',
      os_entity:
        this.state.objects.length > 0
          ? uniq(this.state.objects.map((obj) => obj.__typename)).join(', ')
          : 'None',
    };
  }

  handleSubmit = (event) => {
    if (
      this.props.currentUser.ability.can('update', 'conversation', {
        userId: this.props.currentUser.graph.id,
      })
    ) {
      this.sendRequest();
    } else {
      this.props.openUpgradeModal();
    }
  };

  getOptimisticResponseForFiles() {
    return this.state.files.map((file, index) => {
      return {
        __typename: 'MessageFile',
        id: 'MessageFile:' + file.id,
        file_asset: file,
        position: index + 1,
        emoji_reaction_detail: {
          __typename: 'EmojiReactionDetail',
          id: now(),
          emoji_reaction_users: [],
        },
      };
    });
  }

  getOptimisticResponseForLinks() {
    const object = this.state.objects.map((object, index) => {
      let shareableObj = object;
      if (object.__typename === 'Person') {
        shareableObj = {
          ...object.care_space,
          id: object.care_space.id,
          __typename: 'Board',
        };
      }
      return {
        __typename: 'MessageLink',
        id: 'MessageLink:' + now(),
        shareable: shareableObj,
        emoji_reaction_detail: {
          __typename: 'EmojiReactionDetail',
          id: now(),
          emoji_reaction_users: [],
        },
      };
    });
    return object;
  }

  sendRequest = () => {
    let { content, files, objects } = this.state,
      optimisticResponseForFiles = this.getOptimisticResponseForFiles(),
      optimisticResponseForLinks = this.getOptimisticResponseForLinks(),
      messageCurrentTimeId = ceil(
        currentTimeWithUserTimeZone().valueOf() / 1000,
      );

    // converting only person object to board object.
    objects = map(objects, (object) => {
      if (object.__typename === 'Person') {
        return {
          ...object,
          id: object.care_space.id,
          __typename: 'Board',
        };
      }
      return object;
    });

    content = removeMentioneeName(content);
    this.setState({
      processingRequest: true,
      content: '',
      objects: [],
      files: [],
    });
    EventTracker.track('send_message', this.sendMessageData());

    this.props
      .sendConversationMessage({
        variables: {
          conversationId: this.props.conversationId,
          content,
          links: map(objects, (ob) => ob.__typename + ':' + ob.id),
          files: map(files, (file) => file.id),
        },
        optimisticResponse: (data) => {
          return {
            sendConversationMessage: {
              __typename: 'ConversationMessageResponseType',
              message: {
                viewed_by_user_ids: [],
                commentable_name: 'Message',
                commentable_path: this.props.location?.pathname,
                commentable_type: 'Message',
                isOptimisticResponse: true,
                __typename: 'ConversationMessage',
                id: messageCurrentTimeId,
                created_at: messageCurrentTimeId,
                content: data.content,
                author: this.props.currentUser.graph,
                message_links: optimisticResponseForLinks,
                files: optimisticResponseForFiles,
                mentionees: parseMentionees(
                  content,
                  this.props.otherParticipants,
                ),
                emoji_reaction_detail: {
                  __typename: 'EmojiReactionDetail',
                  id: now(),
                  emoji_reaction_users: [],
                },
                pinnable: true,
                pinned: false,
              },
              conversation: null,
              isOptimistic: true,
            },
          };
        },
        update: (proxy, { data: { sendConversationMessage } }) => {
          const { message } = sendConversationMessage;
          if (message) {
            this.clearDraft();
            this.setState({ objects: [], files: [] });
            StoreUpdater.addMessageInConversationMessagesListing(
              message,
              this.props.conversationId,
              {
                fullView: this.props.isFullPageListing,
                recordIdToRemove:
                  !message.isOptimisticResponse && messageCurrentTimeId,
              },
            );
            if (message.files.length > 0) {
              StoreUpdater.addDMSharedFileInSharedFilesListingWidget(
                message.files,
                {
                  conversationId: this.props.conversationId,
                  messageId: message.id,
                },
              );
            }
          }
        },
      })
      .then(({ data }) => {
        this.postRequestOperations();
      });
  };

  clearDraft() {
    this.props.clearDraft(this.draftKey);
  }

  postRequestOperations() {
    this.setState({ processingRequest: false });
    if (this.state.objects.length > 0 || this.state.files.length > 0)
      RefetchQueriesManager.refetch('currentUser');
  }

  canShare() {
    return (
      this.props.currentUser.ability.can('external_share', 'conversation') ||
      this.props.currentUser.ability.can('internal_share', 'conversation')
    );
  }

  openCommentShare(type, files = [], options = {}) {
    if (this.canShare()) {
      this.setState({ assigningObject: true });
      this.props.commentShareOpen(
        type,
        'Message',
        files,
        this.state.objects.concat(this.state.files),
        {
          restrictModalOpening: type === 'video',
          ...options,
        },
      );
    } else {
      this.props.openUpgradeModal();
    }
  }

  assigningObject = (type) => {
    this.openCommentShare(type);
  };

  handleExternalEntityShare = (files) => {
    this.openCommentShare('file', files);
  };

  removeObject(object) {
    let objects = this.state.objects,
      newObjects = reject(objects, {
        __typename: object.__typename,
        id: object.id,
      });

    this.setState({ objects: newObjects }, () => this.saveDraft());
  }

  removeAttachment(file) {
    let files = this.state.files,
      newFiles = reject(files, { id: file.id });

    this.setState({ files: newFiles }, () => this.saveDraft());
  }

  shouldAutoFocus() {
    return !this.props.disabled && !this.props.device.touchSupport;
  }

  isBtnDisabled() {
    const { processingRequest, content, objects, files } = this.state;
    return (
      processingRequest ||
      !(
        (content && content.trim() && content !== '<p></p>') ||
        files.length > 0 ||
        objects.length > 0
      )
    );
  }

  onFieldFocus = () => {
    if (keyboardUIAdditionalBarSupported()) {
      window.$('html')[0].classList.add('keyboard-ui-additional-bar-visible');
      if (this.props.isFullPageListing && this.props.device.ipad) {
        let messagesContainer =
          document.getElementsByClassName('list-messages')[0];
        messagesContainer &&
          setTimeout(() => {
            messagesContainer.scrollBy(0, 40);
          }, 200);
      }
    }
    !this.props.isFullPageListing &&
      window.$('html')[0].classList.add('floater-focus');
  };

  addEntity = (entity) => {
    if (entity) {
      let objects = this.state.objects;
      objects.push(entity);
      this.setState({ objects });
    }
  };

  addTemplate = (template, editor) => {
    let lastPosition = editor.state.doc.nodeSize - 3;
    editor?.commands.insertContentAt(lastPosition, template.content);
    if (template.files) {
      this.setState({ files: template.files });
    }
    if (template.links) {
      this.setState({ objects: template.links });
    }
    editor?.chain().focus('end').run();
  };

  render() {
    return (
      <div id={'new-message-editor'} ref={(ref) => (this.input = ref)}>
        <div id={'editor-box'} className={'editor-attachments'}>
          {this.state.files.map((object) => {
            if (object) {
              return (
                <OsGrid
                  identifier={`DiscussionCard:Cardcols`}
                  key={`${object.__typename}:${object.id}`}>
                  <OsCards
                    size={'extra-small'}
                    disableCard={false}
                    obj={object}
                    selectionMode={false}
                    closeRequired={true}
                    afterClose={this.removeAttachment}
                    avoidPreviewModal={false}
                  />
                </OsGrid>
              );
            }
          })}
          {this.state.objects.map((object) => {
            if (object) {
              return (
                <OsGrid
                  identifier={`DiscussionCard:Cardcols`}
                  key={`${object.__typename}:${object.id}`}>
                  <OsCards
                    size={'extra-small'}
                    disableCard={false}
                    obj={object}
                    source='Comment'
                    selectionMode={false}
                    closeRequired={true}
                    afterClose={this.removeObject}
                    avoidPreviewModal={false}
                    isTaskModal={true}
                  />
                </OsGrid>
              );
            }
          })}
        </div>
        <div className={'options'} style={{}}>
          <div style={{ display: 'flex' }}>
            <AttachmentDropdown
              leftAligned={true}
              textRequired={false}
              assignObject={this.assigningObject}
            />
            <VideoRecordButton
              onClick={(event) => {
                event.stopPropagation();
                this.assigningObject('video');
              }}
              extraClass={`no-text with-border web-view-btn`}
            />
            <VideoRecordButton
              onClick={(event) => {
                event.stopPropagation();
                this.openCommentShare('video', [], {
                  isOnlyAudioRecording: true,
                });
              }}
              icon={'mic'}
              extraClass={`no-text with-border web-view-btn`}
            />
          </div>
        </div>
        <NewMessageEditor
          placeholder={'Write a message...'}
          updateContent={this.changeContent}
          isSubmitting={this.state.processingRequest}
          handleSubmit={this.handleSubmit}
          initialContent={this.initialContentRef.current}
          files={this.state.files}
          onFieldFocus={this.onFieldFocus}
          isSubmitButtonDisabled={this.isBtnDisabled()}
          objects={this.state.objects}
          isFocused={false}
          addPatientEntity={this.addEntity}
          handleTemplate={this.addTemplate}
          onDrop={this.onDrop}
        />
      </div>
    );
  }
}

NewMessage = connect(
  ({ currentUser, commentShare, device, drafts }) => {
    return { currentUser, commentShare, device, drafts };
  },
  {
    commentShareOpen,
    commentShareConsume,
    openPoliciesModal,
    openUpgradeModal,
    disableActiveConversation,
    updateMessageDraft,
    clearDraft,
  },
)(NewMessage);
NewMessage = withRouter(NewMessage);
NewMessage = graphql(SEND_CONVERSATION_MESSAGE_MUTATION, {
  name: 'sendConversationMessage',
})(NewMessage);
export default NewMessage;
