import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import OsBtn from 'app/components/OsBtn';

import {
  isPersonActuallyAPatient,
  isSharedWithParty,
} from 'app/utils/spaces/careHelper';
import { openDisableCareSpaceModal } from 'app/actions/disableCareSpaceModal';

class DisableAccessBtn extends PureComponent {
  openConfirmationModal = () => {
    this.props.openDisableCareSpaceModal(this.props.obj);
  };

  getProps() {
    return {
      name: 'BtnIcon',
      icon: 'delete',
    };
  }

  getText() {
    const alreadyShared = isSharedWithParty(
      this.props.space,
      {
        id: this.props.obj.user_id,
      },
      this.props.obj,
    );
    return alreadyShared ? 'Disable Access' : 'Remove as Contact';
  }

  render() {
    if (!isPersonActuallyAPatient(this.props.space, this.props.obj)) {
      return (
        <>
          <OsBtn
            {...this.getProps()}
            text={this.getText()}
            onClick={this.openConfirmationModal}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

DisableAccessBtn = connect(null, { openDisableCareSpaceModal })(
  DisableAccessBtn,
);

export default DisableAccessBtn;
