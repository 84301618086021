import {
  MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION,
} from './types';
import LocalStorageManager from 'app/services/LocalStorageManager';

export function mergeAttributesInActiveConversation(attributes) {
  return (dispatch) => {
    dispatch({
      type: MERGE_ATTRIBUTES_IN_ACTIVE_CONVERSATION,
      payload: attributes,
    });
  };
}

export function setActiveConversation(conversation) {
  return (dispatch) => {
    LocalStorageManager.setActiveConversation(conversation);
    dispatch({
      type: SET_ACTIVE_CONVERSATION,
      payload: conversation,
    });
  };
}

export function disableActiveConversation() {
  return (dispatch) => {
    let conversation = LocalStorageManager.getActiveConversation();
    conversation.can_participate = false;
    LocalStorageManager.setActiveConversation(conversation);
    dispatch({
      type: SET_ACTIVE_CONVERSATION,
      payload: conversation,
    });
  };
}
