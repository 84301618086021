import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'app/actions/authentication';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Avatar from 'app/components/Shared/Avatar';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Logout from 'app/components/Shared/Logout';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { isGuestUser } from 'app/utils/userHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class LoginOrLogoutLink extends Component {
  isCurrentUserPresent() {
    return this.props.currentUser.token;
  }

  renderLogoutButton(user) {
    return (
      <CustomDropdown
        className='nav-profile'
        dropdownClass='os-dropdown user-profile__dropdown only-logout'
        name='user_profile_navigation'
        viewType='navProfile'
        user={user}>
        {[<Logout key='logout' />]}
      </CustomDropdown>
    );
  }

  isOnLandingPage = () => {
    return window.location.pathname === '/' && isGuestUser();
  };

  isLoginOrSignupPage = () => {
    return (this.isOnLoginView() || this.isOnSignupView()) && isGuestUser();
  };

  renderLoginLink() {
    return (
      <OsLink text='Log In' htmlTag='Link' to='/login' className='semibold' />
    );
  }

  renderLoginOption() {
    return (
      <div
        className='text-nowrap navbar-unauth-text hidden-mobile'
        id='loginSignUp'>
        <span>Already have an account? </span>
        {this.renderLoginLink()}
      </div>
    );
  }

  renderUserAvatar() {
    return (
      <Avatar
        className='avatar avatar-32'
        {...avatarAttributes(this.props.guestUser.data)}
      />
    );
  }

  isOnLoginView() {
    return this.props.location.pathname.endsWith('/login');
  }

  isOnSignupView() {
    return this.props.location.pathname.includes('/signup');
  }

  isGuestWorkspaceOwnerPresent() {
    return this.props.workspace.guestOwnerData;
  }

  isGuestPresentOnCare() {
    return isCareWorkspaceView() && this.isGuestWorkspaceOwnerPresent();
  }

  renderLoginAndSignupOptions() {
    let text = 'Log In';
    return (
      <div id='loginSignUp'>
        <OsBtn
          name='BtnPrimary'
          text={text}
          htmlTag='Link'
          to={'/login'}
          className='semibold fs-12 text-uppercase '
        />
      </div>
    );
  }

  render() {
    if (this.isCurrentUserPresent()) {
      return this.renderLogoutButton(this.props.currentUser.graph);
    } else if (this.props.guestUser.data) {
      return this.renderUserAvatar();
    } else if (this.isOnLoginView()) {
      return null;
    } else if (this.isOnSignupView()) {
      return this.renderLoginOption();
    } else {
      return this.renderLoginOption();
    }
  }
}

LoginOrLogoutLink = connect(
  ({ currentUser, guestUser, workspace }) => ({
    currentUser,
    guestUser,
    workspace,
  }),
  { logout },
)(LoginOrLogoutLink);
LoginOrLogoutLink = withRouter(LoginOrLogoutLink);
export default LoginOrLogoutLink;
