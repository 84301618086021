import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

import NavProfile from 'app/components/Shared/NavProfile';
import NavSearchBox from 'app/components/Shared/NavSearchBox';
import NotificationBar from 'app/components/Shared/NotificationBar';
import OsLink from 'app/components/OsLink';
import { isWorkspaceSettingViewAllowed } from 'app/utils/featureHelper';

import { disablePresentationMode } from 'app/actions/presentationMode';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

import { isPresentationModeOn } from 'app/utils/settingHelper';
import { isSearchFeatureEnabled } from 'app/utils/featureHelper';
import LogoutButton from 'app/components/NavLinks/LogoutButton';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { enableCommandMenu } from 'app/actions/commandMenu';
import withSubscribedAccess from 'app/components/HOC/withSubscribedAccess';

class RightNavBar extends Component {
  disablePresentationMode = () => {
    this.props.disablePresentationMode();
  };

  renderDisablePresentaionModeOption() {
    return (
      <span className='fs-12 os-text-6 switch-back'>
        <span>You’re in a presentation mode.</span>
        <OsLink
          className='ps-1 cursor-pointer '
          onClick={this.disablePresentationMode}
          text='Switch back to normal view.'
        />
      </span>
    );
  }

  isSettingTabActive = () => {
    return (
      this.props.location.pathname ===
      withWorkspaceIdentifier(this.getSettingLink())
    );
  };

  getSettingLink = () => {
    return isWorkspaceSettingViewAllowed(this.props.workspace.data)
      ? '/settings/basic-info'
      : '/members';
  };

  quickLauncherHandler = () => {
    this.props.openSubscriptionModalIfNotSubscribed(
      this.props.enableCommandMenu,
    );
  };

  render() {
    return !isPresentationModeOn() ? (
      <>
        {this.props.lockNavbar.lockNav === true ? (
          <LogoutButton />
        ) : (
          <>
            <ul className='navbar-nav header-notifications'>
              {isSearchFeatureEnabled() && <NavSearchBox />}
              {isWorkspaceView() && (
                <li
                  onClick={this.quickLauncherHandler}
                  className='nav-cmd-launcher-icon'>
                  <OrthoIcon
                    name='get-started-light-mode'
                    dataHoverNotRequired={false}
                  />
                  <span
                    className='nav-tooltip'
                    data-tooltip={'LAUNCHER'}></span>
                </li>
              )}
              {isWorkspaceSettingViewAllowed(this.props.workspace.data) && (
                <li
                  className={
                    'nav-setting-block hidden-mobile ' +
                    (this.isSettingTabActive() ? 'active' : '')
                  }>
                  <OsLink iconName='setting' to={this.getSettingLink()} />
                  <span
                    className='nav-tooltip'
                    data-tooltip={'CLINIC SETTINGS'}></span>
                </li>
              )}
              <li
                className={
                  'notification-list-item' +
                  (this.props.source.isCare ? '' : ' d-none')
                }>
                <NotificationBar />
              </li>
            </ul>
            <NavProfile currentUser={this.props.currentUser} />
          </>
        )}
      </>
    ) : (
      this.renderDisablePresentaionModeOption()
    );
  }
}

RightNavBar = withRouter(RightNavBar);
RightNavBar = connect(
  ({ currentUser, presentationMode, workspace, source, lockNavbar }) => ({
    currentUser,
    presentationMode,
    workspace,
    source,
    lockNavbar,
  }),
  { disablePresentationMode, enableCommandMenu },
)(RightNavBar);
RightNavBar = withSubscribedAccess(RightNavBar);

export default RightNavBar;
