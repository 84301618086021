import React, { Component } from 'react';
import { connect } from 'react-redux';

import AutoSuggestSearchInput from 'app/components/Shared/AutoSuggestSearchInput';

import EventTracker from 'app/services/EventTracker';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { startCase } from 'app/utils/osLodash';

import { MAIN_NAV_SEARCH_ENTITIES } from 'app/constants.js';

class MobileNavSearch extends Component {
  state = {
    categoryOpen: false,
    viewMore: null,
    category: this.props.default,
  };

  renderOptions() {
    return this.props.list.map(this.renderSingleOption);
  }

  setCategory(category) {
    EventTracker.trackDropdownClick('nav_search', category);
    this.setState({ categoryOpen: false, category });
  }

  categoryName(category) {
    let currentUser = this.props.currentUser.graph;
    return currentUser && currentUser.is_general_user && category === 'doctors'
      ? 'Members'
      : startCase(category);
  }

  renderSingleOption = (category, index) => {
    return (
      <div
        className='search-category-option'
        onClick={() => this.setCategory(category)}>
        {this.categoryName(category)}
      </div>
    );
  };

  toggleDropdown = () => {
    this.setState({ categoryOpen: !this.state.categoryOpen }, () => {
      this.state.categoryOpen && EventTracker.trackDropdownOpen('nav_search');
    });
  };

  resetSearch = ({ isFocused }) => {
    !isFocused && this.props.notifyParentComponent();
  };

  renderCategoryDropdown() {
    if (this.props.list.length > 1) {
      return (
        <div className='search-category' onClick={this.toggleDropdown}>
          {this.categoryName(this.state.category)}
          <OrthoIcon name='chevron-down' />
        </div>
      );
    }
  }

  render() {
    return (
      <div className='nav-search'>
        <div className='input-wrapper'>
          <div className='nav-search-container'>
            <AutoSuggestSearchInput
              source='Nav Search'
              type={this.state.category}
              placeholder='Search'
              onSearchReset={this.resetSearch}
            />
            {this.renderCategoryDropdown()}
            <div
              className={
                'search-category-options ' +
                (this.state.categoryOpen ? '' : 'd-none')
              }>
              {this.renderOptions()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MobileNavSearch.defaultProps = {
  list: MAIN_NAV_SEARCH_ENTITIES,
  default: MAIN_NAV_SEARCH_ENTITIES[0],
};

MobileNavSearch = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(MobileNavSearch);
export default MobileNavSearch;
