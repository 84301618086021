import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  product_name,
  nice_url,
  nice_id,
  company,
  category,
  categoryType,
  rightLabel,
  onClick,
  trackOnClick,
}) => {
  let onClickParams = {
      category: category,
      id_query: nice_url,
      categoryType: categoryType,
    },
    link;

  if (category === 'tools') {
    link = `/tools/${nice_url}`;
    onClickParams = { ...onClickParams, query_by: 'tool' };
  } else {
    link = `/search/${category}/tools/id/${nice_url}`;
    onClickParams = { ...onClickParams, query_by: 'tools' };
  }

  return (
    <SearchResult
      key={`Tool:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>{rightLabel}</span>
        {product_name}
      </div>
    </SearchResult>
  );
};
