import React, { Component } from 'react';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { upperFirst } from 'app/utils/osLodash';

export default class CasePatientInfo extends Component {
  renderResponseParty = (connection) => {
    const { name, email, phone_number, address } = connection.related_person;
    let shareIconRequired = this.isSharedWithPerson(connection.related_person);

    return (
      <ul className='patient-info-list gaurdian-list list-unstyled'>
        <li className={`with${shareIconRequired ? '' : 'out'}-icon`}>
          <span className='cs-pt-extra'>Parent/Guardian :</span>
          {shareIconRequired && this.renderSharedIcon()}
          <span className='fs-16'>
            {name} ({connection.relation})
          </span>
        </li>

        <li className='without-icon'>
          <span className='phone-text'>{phone_number || 'NA'}</span>
        </li>

        <li className='without-icon'>
          <span className='mail-text'>{email || 'NA'}</span>
        </li>

        <li className='without-icon mt-2 os-49e d-none'>
          <address>{address || 'NA'}</address>
        </li>
      </ul>
    );
  };

  renderResponseParties() {
    return this.props.patient.person_connections.map(this.renderResponseParty);
  }

  isSharedWithPerson(person) {
    return (
      person.user_id && this.props.sharedWithUserIds.includes(person.user_id)
    );
  }

  renderSharedIcon() {
    return (
      <OrthoIcon
        name='shareConnection'
        defaultClass=''
        dataHoverNotRequired={true}
      />
    );
  }

  render() {
    const { patient } = this.props;
    if (!patient)
      return <span className='no-patient-info'>No Patient Info Available</span>;

    let shareIconRequired = this.isSharedWithPerson(patient);

    return (
      <div className='cs-pataint-card'>
        <ul className='patient-info-list list-unstyled'>
          <li className={shareIconRequired ? 'with-icon' : ''}>
            <span className='fs-16'>{patient.name}</span>
            {shareIconRequired && this.renderSharedIcon()}
            <span className='cs-pt-extra'>
              {upperFirst(patient.gender) || 'No Gender Info'},{' '}
              {patient.date_of_birth || 'DOB - NA'}
            </span>
          </li>

          <li className='with-icon'>
            <OrthoIcon
              name='phone'
              defaultClass=''
              dataHoverNotRequired={true}
            />
            <span className='phone-text'>{patient.phone_number || 'NA'}</span>
          </li>

          <li className='with-icon'>
            <OrthoIcon
              name='mail'
              defaultClass=''
              dataHoverNotRequired={true}
            />
            <span className='mail-text'>{patient.email || 'NA'}</span>
          </li>

          <li className='with-icon'>
            <OrthoIcon
              name='location'
              defaultClass=''
              dataHoverNotRequired={true}
            />
            <address>{patient.address || 'NA'}</address>
          </li>

          <li className='d-none'>Insurance #000000000</li>
          {this.renderResponseParties()}
        </ul>
      </div>
    );
  }
}
