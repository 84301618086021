import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import OrthoIcon from './OrthoIcon';
import { translate } from 'app/actions/flashMessage';

const LEGENDS_LIST = [
  {
    iconName: 'checkmark-round-default',
    title: 'Not Shared',
    text: translate('LEGEND_NOT_SHARED'),
    defaultClass: 'big-status-icon',
  },
  {
    iconName: 'checkmark-round',
    title: 'Shared',
    text: translate('LEGEND_SHARED'),
    defaultClass: 'big-status-icon',
  },
  {
    iconName: 'checkmark-round',
    title: 'Accepted',
    text: translate('LEGEND_ACCESSED'),
    defaultClass: 'big-status-icon green',
  },
  {
    iconName: 'clear',
    title: 'Declined',
    text: translate('LEGEND_DECLINED'),
    defaultClass: 'header-list-icon lt-red',
  },
  {
    iconName: 'mute',
    title: 'Muted',
    text: translate('LEGEND_MUTED'),
    defaultClass: 'header-list-icon lt-red',
  },
  {
    iconName: 'user',
    title: 'Not Verified',
    text: translate('LEGEND_NOT_VERIFIED'),
    defaultClass: 'header-list-icon uv-user',
  },
  {
    iconName: 'user',
    title: 'Verified',
    text: translate('LEGEND_VERIFIED'),
    defaultClass: 'header-list-icon v-user',
  },
  {
    iconName: '',
    title: 'Info Pending',
    text: translate('LEGEND_INFO_PENDING'),
    defaultClass: 'header-list-icon info-badge',
  },
];

export default class LegendModal extends Component {
  renderList(legend) {
    return (
      <li className='cs-legend-list-item'>
        <OrthoIcon name={legend.iconName} defaultClass={legend.defaultClass} />
        <span>
          <b>{legend.title}</b>. {legend.text}
        </span>
      </li>
    );
  }

  renderHeader() {
    return <div>PatientSpace Legend</div>;
  }

  render() {
    return (
      <div className='cr-space-modal'>
        <div className='legend-heading'>{this.renderHeader()}</div>
        <ul className='list-unstyled cs-legend-list'>
          {LEGENDS_LIST.map((legend) => this.renderList(legend))}
        </ul>
      </div>
    );
  }
}
