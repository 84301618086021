import { CLEAR_DRAFT, UPDATE_DRAFT } from 'app/actions/types';
import LocalStorageManager from 'app/services/LocalStorageManager';
import { DRAFT_CONTEXTS } from 'app/constants';

const getDraftsFromLocalStorage = () => LocalStorageManager.get('drafts') || {};

const saveDraftsToLocalStorage = (drafts) =>
  LocalStorageManager.set('drafts', drafts);

export const getDraftKey = {
  comment: ({ userId, commentableType, commentableId, parentId = '' }) => {
    if (!userId || !commentableType || !commentableId) return;

    return `${userId}_${DRAFT_CONTEXTS.comment}_${commentableType}_${commentableId}_${parentId}`;
  },
  post: ({ userId, listType }) => {
    if (!userId || !listType) return;

    return `${userId}_${DRAFT_CONTEXTS.post}_${listType}`;
  },
  message: ({ userId, conversationId }) => {
    if (!userId || !conversationId) return;

    return `${userId}_${DRAFT_CONTEXTS.message}_${conversationId}`;
  },
};

const clearDraft = (draftKey, state) => {
  if (!state[draftKey]) return state;

  const newState = { ...state };
  delete newState[draftKey];
  saveDraftsToLocalStorage(newState);
  return newState;
};

const updateDraft = (state = getDraftsFromLocalStorage(), action) => {
  switch (action.type) {
    case UPDATE_DRAFT:
      const {
        context,
        userId,
        content,
        objects = [],
        files = [],
        mentionees = [],
        commentCategoryIds = [],
        commentType = '',
        postVisibility,
        editorType,
      } = action;

      if (!userId) return state;
      if (!context) return state;

      const draftKey = getDraftKey[context](action);

      if (!content && !files.length && !objects.length) {
        return clearDraft(draftKey, state);
      }

      const draft = {};
      draft.content = content;
      draft.objects = objects;
      draft.files = files;
      draft.mentionees = mentionees;
      draft.commentCategoryIds = commentCategoryIds;
      draft.commentType = commentType;
      draft.postVisibility = postVisibility;
      draft.editorType = editorType;

      state[draftKey] = draft;
      saveDraftsToLocalStorage(state);
      return state;

    case CLEAR_DRAFT:
      if (!action.key) return state;
      return clearDraft(action.key, state);

    default:
      return state;
  }
};

export default updateDraft;
