import ActionCable from 'app/actioncable/actioncable';
import { isUndefined } from 'app/utils/osLodash';

import store from 'app/store';
import StoreUpdater from 'app/services/StoreUpdater';
import { HASH_TAG_REGEX } from 'app/constants';
import { isInteralTeamComment } from 'app/utils/commentHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { isGroupSpace } from 'app/utils/spaceHelper';
import {
  COMMENTS_BATCH_SIZE,
  REPLIES_BATCH_SIZE,
} from 'app/components/CommentSection/constants';

const TYPENAME_HASH = {
  AttachmentEntity: 'MessageFile',
  Discussion: 'Comment',
  Reply: 'Comment',
};
class CommentableChannel {
  setup(entity = {}, callbacks = {}) {
    if (!isUndefined(ActionCable.commentable)) return ActionCable.commentable;
    const { id: commentableId, __typename: commentableType } = entity;
    ActionCable.commentable = ActionCable.consumer.subscriptions.create(
      { channel: 'CommentableChannel', commentableType, commentableId },
      {
        initialized() {},
        connected() {},
        disconnected() {},
        received(data) {
          if (['new_comment', 'new_activity'].includes(data['type'])) {
            this.newCommentPushReceivedCallback(data);
          } else if (data['action'] === 'add_emoji') {
            let currentUser = store.getState().currentUser;
            StoreUpdater.addEmojiInEntity({
              emojiName: data.entity.emoji_name,
              entityType: TYPENAME_HASH[data.entity.type] || data.entity.type,
              entityId: data.entity.entity_id,
              user: data.entity.user,
              reactionId: data.entity.reaction_id,
              reactedByCurrentUser:
                data.entity.user.id.toString() ===
                currentUser.graph.id.toString(),
            });
          } else if (data['action'] === 'remove_emoji') {
            let currentUser = store.getState().currentUser;
            StoreUpdater.removeEmojiFromEntity({
              emojiName: data.entity.emoji_name,
              entityType: TYPENAME_HASH[data.entity.type] || data.entity.type,
              entityId: data.entity.entity_id,
              user: data.entity.user,
              reactionId: data.entity.reaction_id,
              reactedByCurrentUser:
                data.entity.user.id.toString() ===
                currentUser.graph.id.toString(),
            });
          }
        },
        rejected() {},
        read_all() {
          this.perform('read_all');
        },
        //Additional Custom Event handlers
        newCommentPushReceivedCallback(data) {
          let currentUser = store.getState().currentUser,
            isCurrentUserComment = false;
          if (data.object.__typename === 'Activity') {
            isCurrentUserComment =
              +data.object.authors[0]?.id === +currentUser.graph.id;
            const comment = data.object.objects[0] || {};
            let isGenericActivity =
              data.object.source_type === 'GenericActivity';
            let isGenericGroupActivity =
              isGenericActivity && isGroupSpace(data.object.entities[0]);
            if (
              data.object.source_type === 'SpaceCommentActivity' ||
              isGenericGroupActivity
            ) {
              const shouldAddActivity = isGenericGroupActivity
                ? true
                : !isCurrentUserComment;
              if (shouldAddActivity) {
                StoreUpdater.addCommentInGroupActivityListing(data['object'], {
                  recordId: entity.nice_id,
                  recordType: commentableType,
                  sortQuery: 'recent',
                  textQuery: null,
                  queryType: 'COMMENTS_ACTIVITY_LISTING',
                  limit: COMMENTS_BATCH_SIZE,
                });
              }
              return;
            }
            if (isCareWorkspaceView() && isInteralTeamComment(comment)) return;
            StoreUpdater.addCommentInCareActivityListing(data.object, {
              idQuery: entity.nice_id,
            });
          } else if (isGroupSpace(entity)) {
            const isCommentReply = !!data['object']?.parent_id;
            const updatedComment = this.getUpdatedCommentRecord(data);
            isCurrentUserComment =
              +data.object.author.id === +currentUser.graph.id;

            if (!isCurrentUserComment) {
              if (isCommentReply) {
                // Push reply in records_pager
                StoreUpdater.addRecordInRecordPager(
                  updatedComment,
                  this.getUpdatedGroupReplyListingProps(data),
                );
              }
              StoreUpdater.addRecordInRecordPager(
                updatedComment,
                this.getUpdatedGroupCommentListingProps(data),
              );
            }
            return;
          } else {
            isCurrentUserComment =
              +data.object.author.id === +currentUser.graph.id;
            const updatedData = this.getUpdatedCommentRecord(data);
            if (isCareWorkspaceView() && isInteralTeamComment(updatedData))
              return;
            const commentProps = this.getUpdatedCommentListingProps(data);
            StoreUpdater.addRecordInRecordPager(updatedData, commentProps);
            let hashTags = data.object.content?.match(HASH_TAG_REGEX);
            if (hashTags && hashTags.includes('#caresummary')) {
              StoreUpdater.replaceRecordInRecords(updatedData, {
                id_query: commentableId,
                id_type: 'caresummary',
                page: 0,
                perPage: 1,
                text_query: 'caresummary',
                type: 'BoardRecentDiscussions',
              });
            }
          }
          // ToDiscuss: HM: I think it's not working.
          StoreUpdater.addCommentInRecentDiscussions(data['object'], {
            commentableId,
            commentableType,
          });
          !isCurrentUserComment &&
            StoreUpdater.updateEntity(
              { entity, attributeName: 'comments_count' },
              { customAction: 'increment' },
            );
        },
        getUpdatedCommentRecord(data) {
          return {
            public_feed: null,
            additional_replies: [],
            files: [],
            links: [],
            emoji_reaction_detail: {
              emoji_reaction_users: [],
              id: 'Comment:' + data['object'].id,
              __typename: 'EmojiReactionDetail',
            },
            nice_id: data['object'].id,
            pinned_at: null,
            pinned: false,
            pinnable: false,
            ...data['object'],
          };
        },
        getUpdatedCommentListingProps(data) {
          const commonProps = {
            afterId: null,
            aroundId: null,
            beforeId: null,
            withRecord: true,
            textQuery: null,
            queryType: 'COMMENTS',
          };
          return data['object'].parent_id
            ? {
                ...commonProps,
                limit: 2,
                recordId: data['object'].parent_id,
                recordType: 'Comment',
                type: 'Replies',
              }
            : {
                ...commonProps,
                limit: 7,
                sortQuery: 'recent',
                recordId: entity.nice_id,
                recordType: commentableType,
                type: 'Comments',
              };
        },
        getUpdatedGroupCommentListingProps(data) {
          const commonProps = {
            afterId: null,
            aroundId: null,
            beforeId: null,
            withRecord: true,
            textQuery: null,
            queryType: 'SPACE_MESSAGES',
          };
          return {
            ...commonProps,
            limit: COMMENTS_BATCH_SIZE,
            sortQuery: 'recent',
            recordId: entity.nice_id,
            recordType: commentableType,
            type: 'TeamSpace',
          };
        },
        getUpdatedGroupReplyListingProps(data) {
          const commonProps = {
            afterId: null,
            aroundId: null,
            beforeId: null,
            withRecord: true,
            textQuery: null,
            queryType: 'SPACE_MESSAGES',
          };
          return {
            ...commonProps,
            limit: REPLIES_BATCH_SIZE,
            recordId: data['object'].parent_id.toString(),
            recordType: 'Comment',
            type: 'Replies',
          };
        },
      },
    );
    return ActionCable.commentable;
  }

  delete() {
    if (ActionCable.commentable) {
      ActionCable.consumer.subscriptions.remove(ActionCable.commentable);
      delete ActionCable.commentable;
    }
  }
}

export default new CommentableChannel();
