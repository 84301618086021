import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import scrollToElement from 'scroll-to-element';
import { Accordion, Card } from 'react-bootstrap';

import AppointmentListing from './AppointmentListing';
import CaseTreatmentSection from 'app/components/CaseTreatmentSection';
import ConnectToSupport from 'app/components/ConnectToSupport';
import EntityDropdown from 'app/components/EntityDropdown';
import NewAppointmentButton from 'app/components/Shared/NewAppointmentButton';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RelatedTagsSection from 'app/components/CaseView/RelatedTagsSection';

import EventTracker from 'app/services/EventTracker';

import { setRightSectionVisibility } from 'app/actions/lectureView';
import {
  getFirstApplianceAddedAppointment,
  getLastApplianceRemovedAppointment,
} from 'app/components/CaseLeftSection/helpers';
import { getDefaultAppointmentDetailHash } from 'app/utils/appointmentHelper';
import { isInViewport } from 'app/utils/domHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import {
  activeTreatmentDuration,
  entityUrl,
  entityIsCase,
} from 'app/utils/entitiesHelper';
import { MOBILE_HEADER_HEIGHT } from 'app/constants';
import { openUpgradeModal } from 'app/actions/upgrade';
import { keys, groupBy, uniqBy } from 'app/utils/osLodash';
import { RIGHT_SECTION_BLOCKS } from './constants';

import { setCaseViewType } from 'app/actions/caseDetailInfo';
import { setLectureEntity } from 'app/actions/lectureView';

class CaseRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: 'treatment',
      openSkipSurvey: false,
      answeredCount: 0,
      openActiveTreatment: false,
      activeKey: this.getDefaultBlockKey(),
      showCurricullum: false,
      ...this.setAppointmentData(),
    };
  }

  getDefaultBlockKey() {
    if (this.props.oldVersion) {
      return 'tools';
    } else if (this.props.careSpace) {
      return 'activeTreatment';
    } else {
      return '';
    }
  }

  setAppointmentData() {
    const { appliance_teeth_actions, variant_logs } = this.props.case;

    let appointmentsDetailHash = {},
      groupedVariantLogs = groupBy(variant_logs, 'id'),
      groupedActions = groupBy(appliance_teeth_actions, 'id'),
      groupedActionsByVariantLog = groupBy(
        appliance_teeth_actions,
        'variant_log_id',
      );

    appliance_teeth_actions.forEach((action, i) => {
      if (!appointmentsDetailHash[action.appointment_id])
        appointmentsDetailHash[action.appointment_id] =
          getDefaultAppointmentDetailHash();

      appointmentsDetailHash[action.appointment_id].recordsPresent = true;
      appointmentsDetailHash[action.appointment_id]['appliancesHash'][
        `${action.kind}Appliances`
      ].push(action);
    });
    return {
      appointmentsDetailHash,
      groupedVariantLogs,
      groupedActions,
      groupedActionsByVariantLog,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.screen !== prevState.screen &&
      !isInViewport(this.containerRef) &&
      this.props.device.mobile
    )
      this.scrollToContainerRef();
    const { selectedAppointment, activeCardId } = this.props.caseDetailInfo;
    const {
      selectedAppointment: prevSelectedAppointment,
      activeCardId: prevActiveCardId,
    } = prevProps.caseDetailInfo;

    if (
      this.isOnTreatmemntScreen() &&
      selectedAppointment?.id &&
      !['activeTreatment', 'tools'].includes(this.state.activeKey) &&
      selectedAppointment?.id !== prevSelectedAppointment?.id
    ) {
      this.setState({ activeKey: 'activeTreatment' });
    }

    if (
      this.isOnTreatmemntScreen() &&
      activeCardId &&
      prevActiveCardId !== activeCardId
    ) {
      let activeKey =
        this.state.activeKey === 'tools' ? 'activeTreatment' : 'tools';
      this.setState({ activeKey });
    }
  }

  canUseAdditionalFeatures() {
    return this.props.currentUser.ability.can('additional_feature', 'case');
  }

  scrollToContainerRef() {
    scrollToElement(this.containerRef, {
      ease: 'linear',
      offset: -1 * MOBILE_HEADER_HEIGHT,
      duration: 500,
    });
  }

  renderHeader(kase) {
    if (this.state.screen) {
      if (this.isCareSpaceView()) {
        return null;
      } else if (
        this.state.screen === 'results' ||
        this.state.screen === 'treatment'
      ) {
        return this.renderResultsAndTreatmentHeader();
      }
    }
  }

  renderCTA() {
    const { case: kase, device } = this.props;
    if (!this.props.careSpace && (device.desktop || device.ipadLandscape))
      return <EntityDropdown entity={kase} careSpace={this.props.careSpace} />;
  }

  onChangeTab = (screen, callback, selectedTab) => {
    EventTracker.trackSelectTabEvent(selectedTab);
    this.changeScreenTo(screen, callback);
  };

  renderGetInTouchButton() {
    if (this.props.case.is_in_progress)
      return (
        <ConnectToSupport
          viewType='button'
          buttonType='BtnIcon'
          extraClass='ms-auto'
          text='Get In Touch'
          associatedEntity={this.props.case}
          icon='support'
        />
      );
  }

  renderResultsAndTreatmentHeader() {
    let resultsHeaderClass = 'insight-tab-text insights';
    resultsHeaderClass += this.state.screen === 'results' ? ' active ' : '';

    return (
      <div className='case-right-header'>
        <div className='insight-tab-header'>
          <a
            href='javascript:'
            className={
              'insight-tab-text treatment' +
              (this.state.screen === 'treatment' ? ' active' : '')
            }
            onClick={this.onChangeTab.bind(
              this,
              'treatment',
              false,
              'treatment',
            )}
            data-hover={!isTouchSupported()}>
            Treatment
          </a>
          {this.renderGetInTouchButton()}
        </div>
        {this.renderCTA()}
      </div>
    );
  }

  toggleActiveTreatment = () => {
    this.setState({ openActiveTreatment: !this.state.openActiveTreatment });
  };

  renderTreatmentSection() {
    const { case: kase, oldVersion } = this.props,
      { appointmentsDetailHash, groupedActions, groupedActionsByVariantLog } =
        this.state;

    return (
      <CaseTreatmentSection
        case={kase}
        groupedActions={groupedActions}
        groupedActionsByVariantLog={groupedActionsByVariantLog}
        firstApplianceAddedAppointment={getFirstApplianceAddedAppointment(
          kase.appointments,
          appointmentsDetailHash,
        )}
        lastApplianceRemovedAppointment={getLastApplianceRemovedAppointment(
          kase.appointments,
          appointmentsDetailHash,
        )}
      />
    );
  }

  renderPhase = (phase, index) => {
    if (phase.id !== this.props.case.id)
      return (
        <li key={phase.id} className='phase-list-item'>
          <span className='phase-text'>{`Phase ${index + 1}: `}</span>
          <OsLink to={entityUrl(phase)} text={`Case ${phase.nice_id}`} />
        </li>
      );
  };

  renderPhases() {
    const phases = this.props.case.ordered_case_phases;
    return (
      <ul className='list-unstyled phase-list'>
        {phases.map(this.renderPhase)}
      </ul>
    );
  }

  renderSummaryAndRealtedTags() {
    let kase = this.props.case,
      className = 'cs-summary_tags';

    className += this.state.activeKey ? ' hide-summary' : '';
    return (
      <div className={className}>
        {kase.description && (
          <div className='detail-text'>
            <strong>Summary:</strong> {kase.description}
          </div>
        )}
        {this.renderPhases()}
        <RelatedTagsSection
          case={kase}
          headingRequired={false}
          viewAllLinkRequired={false}
          hashTagRequired={true}
        />
      </div>
    );
  }

  changeScreenTo(screen, callback) {
    if (screen === 'treatment' && !this.canUseAdditionalFeatures()) {
      this.props.openUpgradeModal();
    } else {
      this.setState({ screen }, callback || (() => {}));
    }
  }

  isCurrentUserAuthorOrAdmin() {
    return (
      +this.props.currentUser.graph.id === +this.props.case.doctor.id ||
      this.props.currentUser.graph.admin
    );
  }

  renderBody(kase) {
    return this.renderSummaryAndRealtedTags(kase);
  }

  updateSpaceLectureEntityIfRequired(kase) {
    if (this.isLectureEntityCase()) {
      let spaceLink = this.props.lectureView.entity;
      if (+spaceLink.linkable.id === +kase.id) {
        spaceLink.linkable = { ...spaceLink.linkable, ...kase };
        this.props.setLectureEntity(spaceLink);
      }
    }
  }

  onAnsweringParentQuestion = (answeredCount) => {
    this.setState({ answeredCount });
  };

  setActiveKey = (key) => {
    if (key === 'activeTreatment' && !this.canUseAdditionalFeatures()) {
      this.props.openUpgradeModal();
    } else if (this.state.activeKey === key) {
      this.setState({ activeKey: '' });
    } else {
      this.setState({ activeKey: key });
    }
  };

  renderBlockBody() {
    switch (this.state.activeKey) {
      case 'activeTreatment':
        return this.renderAppointments();
      case 'tools':
        return this.renderTreatmentSection();
      default:
        return <span></span>;
    }
  }

  getCareSpacePatient() {
    return this.props.careSpace?.owner?.patient;
  }

  allAppliances() {
    let kase = this.props.case;
    return uniqBy(
      kase.variant_logs.map((log) => log.tool),
      'id',
    );
  }

  renderAppointments() {
    const { appointments } = this.props.case;
    const { appointmentsDetailHash, groupedVariantLogs, groupedActions } =
      this.state;
    return (
      <AppointmentListing
        appointments={appointments}
        appointmentsDetailHash={appointmentsDetailHash}
        key={this.props.case.id}
        groupedVariantLogs={groupedVariantLogs}
        groupedActions={groupedActions}
        kase={this.props.case}
        isCareSpaceView={this.isCareSpaceView()}
      />
    );
  }

  renderHeading(key) {
    let kase = this.props.case;
    switch (key) {
      case 'activeTreatment':
        return activeTreatmentDuration(kase);
      case 'tools':
        return `${this.allAppliances().length} tools`;
      default:
        return '';
    }
  }

  renderBlocks = (key) => {
    let isSelected = key === this.state.activeKey,
      selectedClass = isSelected ? 'chevron-down' : 'chevron-right',
      activeClass = isSelected ? 'active' : '',
      kase = this.props.case,
      parentHeading = this.renderHeading(key),
      childHeading =
        RIGHT_SECTION_BLOCKS[key]['text'] ||
        RIGHT_SECTION_BLOCKS[key]['textFunction'](kase);
    if (this.blockNotVisible(key)) return null;
    return (
      <Card
        className={`cs-right-cards-block ${key} ${RIGHT_SECTION_BLOCKS[key]['extraClass']} ${activeClass}`}
        key={key}>
        <Accordion.Button
          as={Card.Header}
          key={key}
          eventKey={key}
          onClick={this.setActiveKey.bind(this, key)}>
          <OrthoIcon
            name={RIGHT_SECTION_BLOCKS[key]['icon']}
            dataHoverNotRequired={true}
            defaultClass='cs-rt-icon'
          />
          <span className='cs-rt-head'>
            <div>{parentHeading || childHeading}</div>
            {parentHeading && <small className=''>{childHeading}</small>}
          </span>
          <OrthoIcon
            defaultClass='cs-rt-icon-right ms-auto'
            name={selectedClass}
          />
        </Accordion.Button>
        <Accordion.Collapse eventKey={key}>
          <Card.Body className='cs-rt-40'>{this.renderBlockBody()}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  blockNotVisible(key) {
    let isCareSpaceView = this.isCareSpaceView();
    return (
      (key === 'activeTreatment' && this.props.oldVersion) ||
      (key === 'tools' && isCareSpaceView)
    );
  }

  anyBlockVisible() {
    return keys(RIGHT_SECTION_BLOCKS).some((key) => !this.blockNotVisible(key));
  }

  renderRightTreatmentLinkBlock() {
    let accordionClass = 'cs-accordion-treatment';
    accordionClass += this.state.activeKey ? ' active-accordion' : '';

    if (this.anyBlockVisible())
      return (
        <Accordion
          activeKey={this.state.activeKey}
          className={accordionClass}
          id='appointment-scroll-container-ipad'>
          {keys(RIGHT_SECTION_BLOCKS).map(this.renderBlocks)}
        </Accordion>
      );
  }

  hideCaseRightSection = () => {
    this.props.hideCaseRightSection();
    this.setState({ showCurricullum: true });
  };

  isLectureEntityCase() {
    return (
      this.props.lectureView.entity &&
      entityIsCase(this.props.lectureView.entity.linkable)
    );
  }

  isCareSpaceView() {
    return !!this.props.careSpace?.id;
  }

  backToCurriculumButton() {
    if (
      !this.isCareSpaceView() &&
      this.isLectureEntityCase() &&
      !this.props.device.mobileDevice
    )
      return (
        <OsBtn
          name='BtnIcon'
          text='back to content'
          extraClass='back-to-curriculum'
          onClick={this.props.setRightSectionVisibility.bind(this, true)}
          icon='chevron-left'
        />
      );
  }

  isOnTreatmemntScreen() {
    return this.state.screen === 'treatment';
  }

  render() {
    let kase = this.props.case;
    let extraClass = 'case-timeline-right-section';
    extraClass += this.props.visible ? '' : ' d-none';

    return (
      <OsGrid identifier='CaseDetail:CaseRightSection' extraClass={extraClass}>
        {this.backToCurriculumButton()}
        {this.props.children}
        <div
          className='submit-right-container cs-treatment-d-flex'
          ref={(div) => (this.containerRef = div)}>
          {!this.isCareSpaceView() && this.renderHeader(kase)}
          <div
            className='cs-treatment-sec'
            id='appointment-scroll-container-web'>
            {this.isOnTreatmemntScreen() &&
              this.renderRightTreatmentLinkBlock()}
            {this.renderBody(kase)}
          </div>
          <NewAppointmentButton kase={this.props.case} name='BtnIcon' />
        </div>
      </OsGrid>
    );
  }
}

CaseRightSection.defaultProps = {
  careSpace: {},
  onSkipSurveyClick: () => {},
  oldVersion: false,
  visible: true,
};
CaseRightSection = withRouter(CaseRightSection);
CaseRightSection = connect(
  ({ caseDetailInfo, currentUser, device, lectureView }) => ({
    caseDetailInfo,
    currentUser,
    device,
    lectureView,
  }),
  {
    openUpgradeModal,
    setCaseViewType,
    setLectureEntity,
    setRightSectionVisibility,
  },
)(CaseRightSection);
export default CaseRightSection;
