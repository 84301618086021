import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  assignAddObject,
  assignAddObjects,
  assignCreate,
} from 'app/actions/assign';
import OsBtn from 'app/components/OsBtn';
import withAuthorize from 'app/components/HOC/withAuthorize';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { isAddToSpaceRequired } from 'app/utils/featureHelper';

// Adds entities to spaces as either link or collaborator.
class AddTo extends Component {
  preventScroll = () => {
    setTimeout(() => {
      var assignBackground = document.getElementsByClassName('navbar');
      if (assignBackground[0]) {
        assignBackground[0].parentElement.className =
          assignBackground[0].parentElement.className + ' no-scroll';
        assignBackground[0].parentElement.ontouchmove = function (e) {
          // e.preventDefault();
        };
      }
    }, 200);
  };

  onClickAddTo = () => {
    if (this.props.selectedObjects && this.props.selectedObjects.length > 0) {
      this.props.assignAddObjects(this.props.selectedObjects, this.props.type);
    } else {
      this.props.assignAddObject({ ...this.props.obj }, this.props.type);
    }
    this.preventScroll();
  };

  getLabel() {
    return this.props.label || 'Add to';
  }

  render() {
    if (isAddToSpaceRequired(this.props.obj)) {
      let extraClass = `add-to-space-button  ${this.props.extraClass || ''}`;
      return (
        <OsBtn
          name={this.props.name}
          extraClass={extraClass}
          icon={this.props.iconName}
          text={this.getLabel()}
          onClick={this.onClickAddTo}
          associatedEntity={this.props.obj}
          dataAttribute={this.props.dataAttribute}
        />
      );
    }
  }
}

AddTo = connect(({ currentUser }) => ({ currentUser }), {
  assignAddObject,
  assignAddObjects,
  assignCreate,
})(AddTo);
AddTo = withAuthorize(AddTo);
AddTo.defaultProps = {
  type: 'link',
  label: 'Save to Folder',
  abilityObject: getAuthorizeObject('create', 'board'),
  iconName: 'boards',
  name: 'BtnIcon',
};
export default AddTo;
