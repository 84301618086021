import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';

const GET_ALL_DOMAINS = queryLoader(
  'app/graphql/Integration/GetAllClinicDomains.gql',
);

const useGetAllDomains = () => {
  const {
    data: allDomains,
    loading: domainsLoading,
    variables: allDomainsVariable,
  } = useQuery(GET_ALL_DOMAINS, {
    variables: {
      page: 0,
      per_page: 100,
      type: 'AllClinicDomains',
    },
    fetchPolicy: 'network-only',
  });

  return {
    allDomains,
    domainsLoading,
    allDomainsVariable,
  };
};

export default useGetAllDomains;
