import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePoliciesModal } from 'app/actions/policiesModal';

import PoliciesView from 'app/components/PoliciesView';

class PoliciesModal extends Component {
  onClose = (forceClosed = false) => {
    const { options } = this.props.policiesModal;
    if (!options.forceUpdate || forceClosed) this.props.closePoliciesModal();
  };

  onSubmit = () => {
    this.props.closePoliciesModal();
  };

  getPoliciesProps() {
    const { options = {} } = this.props.policiesModal;
    return {
      onClose: this.onClose,
      onSubmit: this.onSubmit,
      forceToAccept: !!options.forceUpdate,
      kind: options.kind,
      entityType: options.entityType,
      entityId: options.entityId,
      hideCloseLink: !!options.forceUpdate,
      goBackRequired: options.goBackRequired,
    };
  }

  render() {
    if (!this.props.policiesModal.open) return null;

    return <PoliciesView {...this.getPoliciesProps()} />;
  }
}

PoliciesModal = connect(({ policiesModal }) => ({ policiesModal }), {
  closePoliciesModal,
})(PoliciesModal);

export default PoliciesModal;
