import React from 'react';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

// Note: In future, while upgrading @dnd-kit/core to v3.0.0 or above, make sure the following issues no longer exist:
// 1. `event.draggingRect` is not supported
// 2. JS related to `PointerSensor` is breaking while dragging

export function DragAndDropContext(props) {
  let sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={props.onDragStart}
      onDragMove={props.onDragMove}
      onDragEnd={props.onDragEnd}
      autoScroll={false}>
      {props.children}
    </DndContext>
  );
}
