import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import ClickPopupWrapper from 'app/components/Shared/ClickPopupWrapper';
import checkedCircle from 'app/images/task-manager/checkedCircle.svg';
import dueDate from 'app/images/task-manager/dueDate.svg';
import { isBeforeTodayDate, timeWithFormat } from 'app/utils/timeHelper';
import {
  FORMAT_START_WITH_MONTH_SLASH,
  FORMAT_START_WITH_YEAR,
  TIME_FORMAT,
} from 'app/components/Task/taskConstant';
import RenderTaskActionButton from 'app/components/Task/RenderTaskActionButton';
import SelectInput from 'app/components/Shared/SelectInput';
import { getRGBValue, getTaskDueDate } from 'app/utils/taskHelper';
import OsGrid from 'app/components/OsGrid';
import OsCards from 'app/components/OsCards';
import useTaskPreview from 'app/components/TaskPreviewModal/useTaskPreview';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import AddTaskComment from 'app/components/TaskPreviewModal/AddTaskComment';
import RecordsView from 'app/components/RecordsView';
import ActionCable from 'app/actioncable/actioncable';
import useOnScreen from 'app/components/Task/useOnScreen';
import { openTaskPreviewModal } from 'app/actions/taskPreviewModal';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { TASK_ACTIVITY_BATCH_SIZE } from 'app/constant/tasks';
import TaskActivityListing from './TaskActivityListing';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';
import MessageBlock from 'app/components/MessageBlock';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import MarkRead from 'app/components/Inbox/MarkRead';

import 'app/components/TaskPreviewModal/task-preview.scss';
import RenderLabel from 'app/components/Task/RenderLabel';
import { isPartnerSpace, isSpace } from 'app/utils/spaceHelper';
import { getPartnerSpaceData } from 'app/utils/Workspace/generalHelper';

const TaskPreview = (props) => {
  const params = useParams();
  const parentContainerRef = useRef(null);
  const [loadTaskActivity, setLoadTaskActivity] = useState(false);
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);

  const initializeTaskHook = {
    taskId: params?.taskId || props?.taskId,
    task: props?.task,
  };
  const {
    changeAssignee,
    changeDueDate,
    changeLabel,
    closeModal,
    deleteTask,
    getCardType,
    isTaskDetailsLoading,
    markAsDone,
    markAsNotDone,
    refreshComponent,
    updateCache,
    taskDetails,
  } = useTaskPreview(initializeTaskHook);
  let task = props?.task || taskDetails?.task;
  const [activityCount, setActivityCount] = useState(task?.comments_count || 0);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (props.isModal) IntercomTracker.hideWidgetLauncher();

    return () => {
      if (props.isModal) IntercomTracker.showWidgetLauncher();
    };
  }, []);

  useEffect(() => {
    if (
      !isTaskDetailsLoading &&
      !props.isModal &&
      !task.unread_mentions_present
    ) {
      setIsCollapsed(!!task.completed);
    }
  }, [isTaskDetailsLoading]);

  useEffect(() => {
    if (isOnScreen) {
      setLoadTaskActivity(true);
      if (!task?.is_viewed) {
        ActionCable?.notifications?.viewTask(task?.id);
      }
    }
  }, [isOnScreen, task?.is_viewed]);

  const onTaskChecked = (e, taskId) => {
    e.stopPropagation();
    markAsNotDone(taskId);
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    markAsDone(task.id);
  };

  const renderModalHeader = () => {
    return (
      <div className='taskInformation'>
        <div className='taskTitleAndDueDate'>
          {task.completed ? (
            <div
              onClick={(e) => onTaskChecked(e, task.id)}
              className={'checked-image-container'}>
              <img
                src={checkedCircle}
                alt='checkedCircle'
                width='20'
                height='20'
              />
            </div>
          ) : (
            <ClickPopupWrapper onClick={onClickHandler}>
              <div className='emptyCircle click-popup-wrapper'></div>
            </ClickPopupWrapper>
          )}
          <div className='title-conatiner'>
            <div className={`title ${task.completed && 'line-through'}`}>
              <span className={'task-card-type'}>{getCardType()}</span>{' '}
              {task.title}
            </div>
            <div className='dueDate'>
              {getTaskDueDate(task.due_date)}
              {isBeforeTodayDate(task.due_date, FORMAT_START_WITH_YEAR) &&
                !task.completed && (
                  <img src={dueDate} alt='dueDate' width='16' height='16' />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const openPreviewModal = (taskId) => {
    if (!props.isModal) dispatch(openTaskPreviewModal(taskId, {}));
  };

  const renderTaskDetails = () => {
    return (
      <>
        <div className={`description ${task.completed && 'line-through'}`}>
          {parse(task.description || '')}
        </div>
        <div className={'additional-links'} onClick={closeModal}>
          {task.links &&
            task.links.map((linkObject) => (
              <>
                <OsGrid
                  key={linkObject.id}
                  identifier={'TimelineSpaceCommentCardColumn:Cardcols'}>
                  <OsCards
                    size={'extra-small'}
                    obj={linkObject}
                    allowDefaultBehavior={true}
                  />
                </OsGrid>
              </>
            ))}
        </div>
        {task?.entity && !props.taskNotRequired && (
          <div className='card-entity-post' onClick={closeModal}>
            <OsCards
              size={'extra-small'}
              obj={task.entity}
              sliderPreview={true}
              source='Comment'
              shareRequired={false}
              dateBlockRequired={true}
              allowDefaultBehavior={true}
              onCardClick={props.onCardClick}
            />
          </div>
        )}
      </>
    );
  };

  const isPreviousCommentIsSameAsAuthor = (comments, comment, index) => {
    const previousComment = comments[index - 1];
    return previousComment && previousComment.author.id === comment?.author?.id;
  };

  const renderComments = () => {
    if (props.activities) {
      return (
        <TaskActivityListing
          results={props.activities.slice().reverse()}
          totalRecords={props.activities.length}
          parentRef={parentContainerRef}
          loaderNotRequired={true}
          loading={false}
          entity={task}
          updateActivityCount={setActivityCount}
          fullViewListing={false}
        />
      );
    } else if (props.comments) {
      return (
        <>
          {props.comments.map((comment, index) => (
            <MessageBlock
              type='comment'
              key={comment.id}
              entity={comment}
              actionType={'task'}
              parentRef={props.parentRef}
              previousMessageIsSameAsAuthor={isPreviousCommentIsSameAsAuthor(
                props.comments,
                comment,
                index,
              )}
            />
          ))}
        </>
      );
    } else if (loadTaskActivity) {
      return (
        <RecordsView
          type='TaskActivities'
          queryType='COMMENTS_ACTIVITY_LISTING'
          perPage={TASK_ACTIVITY_BATCH_SIZE}
          idQuery={task?.original_task_or_self_id?.toString()}
          infiniteScroll={true}
          entity={task}
          updateActivityCount={setActivityCount}
          key={task?.original_task_or_self_id}
          isReverse={true}
          threshold={1}
          fetchPolicy='network-only'
          windowNotRequired={true}
          fullViewListing={true}
          parentContainer={parentContainerRef}
          nextPageLoadRequired={props.nextPageLoadRequired}
        />
      );
    }
  };

  const onCommentSuccess = () => {
    if (parentContainerRef.current) {
      setTimeout(() => {
        parentContainerRef.current.lastChild?.scrollIntoViewIfNeeded();
      }, 5000);
    }
  };

  const renderTaskActivity = () => {
    let taskActivityWindow = 'task-preview-activity ';
    taskActivityWindow += activityCount === 0 ? 'no-activity ' : '';

    return (
      <>
        {activityCount > 3 && props.showSeeMoreButton && (
          <div className='show-more-activities'>
            <div className='modal-btn-wrapper'>
              <div
                className='more_btn_container'
                onClick={() => openPreviewModal(task?.id)}>
                <span className='btn_text'>See more</span>
              </div>
            </div>
          </div>
        )}
        <div className={taskActivityWindow} ref={parentContainerRef}>
          {renderComments()}
        </div>
        <div className='task-preview-input'>
          <AddTaskComment
            task={task}
            updateActivities={!props.comments}
            taskId={task.id}
            limit={TASK_ACTIVITY_BATCH_SIZE}
            queryVariables={props.queryVariables}
            onCommentSuccess={onCommentSuccess}
          />
        </div>
      </>
    );
  };

  const renderExpandIcon = () => {
    return (
      <div
        className={'task-chevron'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsCollapsed(!isCollapsed);
        }}>
        {!isCollapsed ? (
          <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
        ) : (
          <OrthoIcon name='chevron-caret-right' defaultClass='ms-1' />
        )}
      </div>
    );
  };

  const renderTaskInboxMenu = () => {
    return (
      <div className='d-inline-block ms-auto a-link fs-12'>
        <CustomDropdown
          name='discussion_actions'
          className='comment-more position-relative'
          flipTheDropdown={260}>
          <MarkRead
            entityId={task.nice_id}
            entityType={task.__typename}
            type={'my_inbox'}
            queryType={props.listType}
            entity={task}
          />
          <div></div>
        </CustomDropdown>
      </div>
    );
  };

  const getSpace = () => {
    if (isPartnerSpace(task.parent_entity) || isSpace(task.parent_entity)) {
      return task.parent_entity;
    } else if (isPartnerSpace(task.entity) || isSpace(task.entity)) {
      return task.entity;
    } else {
      getPartnerSpaceData();
    }
  };

  const renderModalBody = () => {
    let iconClass = task.unread_mentions_present ? 'red ' : '';
    let taskEntityWrapper = 'task-preview-entity ';
    taskEntityWrapper += activityCount === 0 ? 'no-activity ' : '';
    taskEntityWrapper += isCollapsed ? 'is-collapsed ' : '';
    taskEntityWrapper += props.isModal ? 'is-modal ' : '';
    return (
      <>
        <div
          className={taskEntityWrapper}
          onClick={() => openPreviewModal(task?.id)}>
          <div className='entity-header'>
            {renderModalHeader()}
            <div className='task-preview-action'>
              {['MyUnreadTasks'].includes(props.listType) ? (
                renderTaskInboxMenu()
              ) : (
                <RenderTaskActionButton
                  task={task}
                  // listType={props.listType}
                  assigneeList={all_members || []}
                  changeDueDate={changeDueDate}
                  markAsDone={markAsDone}
                  markAsNotDone={markAsNotDone}
                  deleteTask={deleteTask}
                  changeAssignee={changeAssignee}
                  updateCache={updateCache}
                  refreshComponent={refreshComponent}
                  backendDataRequired={false}
                  isListView={true}
                />
              )}

              {!props.isModal && renderExpandIcon()}
              {!props.hideCloseBtn && !initializeTaskHook?.taskId && (
                <OsBtn
                  name='BtnIcon'
                  extraClass='no-text web-view-btn'
                  icon='close'
                  label='Close share modal'
                  onClick={closeModal}
                />
              )}
            </div>
          </div>
          <div className='entity-body'>
            {!isCollapsed && renderTaskDetails()}

            <div className='task-description-footer'>
              <div
                className='task-label-group'
                onClick={(e) => e.stopPropagation()}>
                <div
                  className='task-comment-count'
                  onClick={() => openPreviewModal(task?.id)}>
                  <OrthoIcon name='comments' defaultClass={iconClass} />
                  {task.comments_count}
                </div>
                <div className='teamMembersDropdown'>
                  <SelectInput
                    isMulti={false}
                    name='assignee'
                    className='react-select-ortho-2'
                    formValue={task.assignee.id}
                    onChange={changeAssignee}
                    async={true}
                    isSearchable={false}
                    perPage={50}
                    idQuery={getSpace()?.nice_id}
                    queryType={
                      isPartnerSpace(getSpace())
                        ? 'AllSpaceMembers'
                        : `PossibleConversationUsers`
                    }
                    selectedValueNameRequired={false}
                  />
                </div>
                {task.entity_labels.map((label, index) => {
                  return (
                    <div
                      key={label.id}
                      className='taskLabelContainer'
                      style={{
                        backgroundColor: `${getRGBValue(label.label.color)}`,
                      }}
                      onClick={() => changeLabel(label.label)}>
                      <div className='singleTaskLabel'>
                        <RenderLabel
                          task={task}
                          label={label.label}
                          allLabels={task_labels}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className='task-date'>
                {task.completed && (
                  <>
                    Completed by {task?.resolved_marked_by?.name} •{' '}
                    {timeWithFormat(
                      task?.completed_at,
                      `${FORMAT_START_WITH_MONTH_SLASH} ${TIME_FORMAT}`,
                    )}{' '}
                    &nbsp; | &nbsp;
                  </>
                )}
                by {task.created_by.full_name} •{' '}
                {timeWithFormat(
                  task.created_at,
                  `${FORMAT_START_WITH_MONTH_SLASH} ${TIME_FORMAT}`,
                )}
              </div>
            </div>
          </div>
        </div>

        {!isCollapsed && !task.deleted_at && renderTaskActivity()}
      </>
    );
  };

  let containerClass = 'task-peview-container ';
  containerClass +=
    initializeTaskHook?.taskId || initializeTaskHook.task?.id
      ? 'not-modal '
      : '';
  containerClass += task?.completed ? ' is-completed' : '';
  return (
    <div className={containerClass} ref={ref}>
      {task && renderModalBody()}
    </div>
  );
};

export default TaskPreview;
