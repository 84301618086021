import React from 'react';
import SearchResult from './SearchResult';

export default ({
  id,
  nice_url,
  name,
  nice_id,
  rightLabel,
  category,
  categoryType,
  onClick,
  trackOnClick,
}) => {
  let onClickParams = { category: category, categoryType: categoryType },
    link;

  if (category === 'doctors') {
    link = `/users/${nice_url}`;
    onClickParams = {
      ...onClickParams,
      query_by: 'doctor',
      id_query: nice_url,
    };
  } else {
    link = `/search/${category}/doctors/${nice_url}`;
    onClickParams = {
      ...onClickParams,
      query_by: 'doctors',
      id_query: nice_url,
    };
  }

  return (
    <SearchResult
      key={`User:${id}`}
      to={link}
      onClick={onClick}
      onClickParams={onClickParams}
      trackOnClick={trackOnClick}>
      <div>
        <span className='float-right'>{rightLabel}</span>
        {name}
      </div>
    </SearchResult>
  );
};
