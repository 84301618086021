import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import * as compose from 'lodash.flowright';
import EventTracker from 'app/services/EventTracker';
import scrollToElement from 'scroll-to-element';

import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import OsBtn from 'app/components/OsBtn';
import RenderCards from 'app/components/Cards/RenderCards';

import StoreUpdater from 'app/services/StoreUpdater';
import { getSpaceSectionOffset } from 'app/utils/domHelper';
import { getSpaceCardGridIdentifier } from 'app/utils/osCardHelper';

import {
  moveLinksToNewSection,
  setNewSectionId,
  toggleSpaceLinksRequestInProgress,
} from 'app/actions/spaceLinks';

const CREATE_TAB_SECTION_MUTATION = queryLoader(
  'app/graphql/mutations/CreateTabSection.gql',
);
const UPDATE_TAB_SECTION_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateTabSection.gql',
);

const ENTER_KEY_CODE = 13;

class TabSectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      name: this.isNew() ? '' : this.section().name,
      requestInProgress: false,
    };
  }

  componentWillUnmount() {
    this.props.isMovingLinksToNewSection && this.props.moveLinksToNewSection();
  }

  isNew() {
    return !this.section();
  }

  sendEvent() {
    let eventName = this.isNew() ? 'created' : 'renamed';
    EventTracker.sectionAction(eventName, this.state.name, this.props.space);
  }

  updateSection = () => {
    if (this.state.requestInProgress) return;

    let variables = { name: this.state.name },
      query = this.isNew()
        ? 'createTabSectionMutation'
        : 'updateTabSectionMutation';

    if (this.isNew()) {
      variables['upperSectionId'] =
        this.props.upperSection && this.props.upperSection.id;
      variables['startFromLinkId'] = this.props.startFromLinkId;
      variables['textQuery'] = this.props.textQuery;
      variables['searchQuery'] = this.props.searchQuery;
      variables['additional_filters'] = JSON.stringify(
        this.props.additionalFilters || {},
      );
      this.sectionViewFormContainer &&
        scrollToElement(this.sectionViewFormContainer, {
          ease: 'linear',
          offset: getSpaceSectionOffset(),
          duration: 500,
        });
    } else {
      variables['id'] = this.section().id;
    }

    this.setState({ requestInProgress: true });
    variables.startFromLinkId && this.props.toggleSpaceLinksRequestInProgress();
    this.props[query]({
      variables,
    }).then(({ data }) => {
      this.setState({ requestInProgress: false });
      let response = data[query.replace('Mutation', '')];
      if (response.success) {
        this.props.onSuccess();
        this.sendEvent();
        if (this.isNew()) {
          this.props.setNewSectionId(response.entity.id);
          StoreUpdater.updateTabSections(response.entity, {
            newAddition: true,
            upperSectionId: variables['upperSectionId'],
            searchQuery: this.props.searchQuery,
            idQuery: this.props.tabId,
            additionalFilters: this.props.additionalFilters,
            sortQuery: this.props.sortQuery,
          });
        }
      } else {
        this.setState({ error: response.error });
      }
      variables.startFromLinkId &&
        this.props.toggleSpaceLinksRequestInProgress();
    });
  };

  section() {
    return this.props.section;
  }

  onChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onClick = (e) => {
    // To prevent section from toggling.
    e.stopPropagation();
  };

  onClose = (e) => {
    this.requestTimer && clearTimeout(this.requestTimer);
    this.props.onClose();
  };

  onBlur = (e) => {
    // Note: Hack to avoid sending request on clicking the close icon
    // Clicking on the close icon, fires onBlur and then onClose
    this.requestTimer = setTimeout(this.updateSection, 200);
  };

  onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.updateSection();
    }
  };

  renderCards() {
    return (
      <div className={`space-links-container ${this.props.size}`}>
        <RenderCards
          autofillCardsUpto={this.props.autofillCardsUpto}
          cardsCustomMarginRequired={true}
          goToPage={true}
          newLinkOptions={{ disabled: true }}
          objects={this.props.newSectionFormLinks}
          orderRowWise={true}
          showEntityInModal={false}
          tabSectionId={this.props.tabSectionId}
          source='space-section-form'
          size={this.props.size}
          cardGridIdentifier={getSpaceCardGridIdentifier(this.props.size)}
        />
      </div>
    );
  }

  renderSectionViewForm() {
    return (
      <article
        className='os-toggle-category d-flex full-width cursor-pointer align-items-center'
        ref={(el) => (this.sectionViewFormContainer = el)}>
        <div className='os-categogry-heading full-width'>
          <span className='os-category-block'>
            <OrthoIcon name='more' defaultClass='vertical-more' />
            {this.renderNameField()}
          </span>
        </div>
        <aside className='aside-toggle-action ms-auto'>
          <OsBtn
            name='BtnIcon'
            icon='chevron-right'
            extraClass='toggle-click-icon'
          />
        </aside>
        <OrthoIcon name='more' />
      </article>
    );
  }

  renderNameField() {
    return (
      <div className='space-input-field'>
        <OsField
          name='space_section_field'
          onChange={this.onChange}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          osType='input'
          value={this.state.name}
          className={this.state.error ? 'error-field' : ''}
          autoFocus={true}
          placeholder='Enter Name'
        />
        {this.state.error && (
          <span className='form-error'>{this.state.error}</span>
        )}
        {this.isNew() && (
          <OsBtn
            name='BtnIcon'
            extraClass='no-text web-view-btn input-close-btn'
            icon='close'
            label='Close new section form'
            onClick={this.onClose}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.props.isMovingLinksToNewSection
          ? this.renderSectionViewForm()
          : this.renderNameField()}
        {this.props.newSectionFormLinks.length > 0 && this.renderCards()}
      </>
    );
  }
}

TabSectionForm = compose(
  graphql(CREATE_TAB_SECTION_MUTATION, { name: 'createTabSectionMutation' }),
  graphql(UPDATE_TAB_SECTION_MUTATION, { name: 'updateTabSectionMutation' }),
)(TabSectionForm);
TabSectionForm = connect(null, {
  moveLinksToNewSection,
  setNewSectionId,
  toggleSpaceLinksRequestInProgress,
})(TabSectionForm);
TabSectionForm.defaultProps = {
  newSectionFormLinks: [],
  onClose: () => {},
  onSuccess: () => {},
};
export default TabSectionForm;
