import React, { useState } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import GroupListRow from 'app/components/SideBarTeamSpaceList/GroupListRow';

const SectionGroupList = (props) => {
  const [isListVisible, setIsListVisible] = useState(false);

  const switchListVisibility = () => {
    setIsListVisible((prevState) => !prevState);
  };

  let iconName = isListVisible ? 'chevron-down' : 'chevron-right';
  return (
    <>
      <div className={'container-section'}>
        <div
          onClick={switchListVisibility}
          className={'container-section__header'}>
          <h1 className={'title'}>{props.title || 'Archived Groups'}</h1>
          <h1 className={'alternate-title'}>{props.title || 'Archived Groups'}</h1>
          <div className={'dropdown-icon'}>
            <OrthoIcon
              defaultClass='sidebar-arrow-icon'
              name={iconName}
              dataHoverNotRequired={true}
            />
          </div>
        </div>
        {isListVisible &&
          props.spaces.map((c) => (
            <GroupListRow
              key={c.id}
              teamspace={c}
              activeResourceId={props.activeResourceId}
            />
          ))}
      </div>
    </>
  );
};

export default SectionGroupList;
