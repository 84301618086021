import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import EventTracker from 'app/services/EventTracker';

import { translate } from 'app/actions/flashMessage';
import { MAIL_TO_SUPPORT } from 'app/constants';
import { isWorkspaceViewLoggedIn } from 'app/utils/Workspace/generalHelper';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';

class ConnectToSupport extends Component {
  supportTabClick = () => {
    EventTracker.track('Navigate', { page: 'Support' });
    let supportConversationId =
      this.props.currentUser.graph &&
      this.props.currentUser.graph.support_conversation_id;
    this.props.onRedirectToSupport();
    if (supportConversationId || isWorkspaceViewLoggedIn()) {
      IntercomTracker.openWidget();
      // Don't need to redirect them to support chat anymore.
      // let url = withWorkspaceIdentifier('/conversations/support');
      // this.props.openInNewTab ? this.openNewTab(url) : this.openInSameTab(url);
    } else {
      this.openMail();
    }
  };

  openInSameTab(url) {
    let state = this.props.prefilledTextKey && {
      prefilledTextKey: this.props.prefilledTextKey,
      prefilledTextProps: this.props.prefilledTextProps,
    };
    this.props.navigate({ pathname: url, state: { ...state } }, { skip: true });
  }

  supportEmail() {
    return this.props.supportEmail;
  }

  openMail() {
    if (this.props.openInNewTab) {
      this.openNewTab(this.supportEmail());
    } else {
      window.location.href = this.supportEmail();
    }
  }

  openNewTab(url) {
    window.open(url, '_blank');
  }

  getLinkText() {
    return this.props.text || translate('CONNECT_WITH_SUPPORT');
  }

  onSupportBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.supportTabClick();
  };

  renderSupportLink() {
    let className = this.props.className || 'a-link cursor-pointer ',
      emailTextClass = this.props.emailTextClass || 'a-link',
      text = !this.props.textNotRequired && this.getLinkText();
    if (!this.props.emailSupport) {
      switch (this.props.viewType) {
        case 'link':
          return (
            <OsLink
              className={className}
              onClick={this.supportTabClick}
              text={text}
              iconName={this.props.iconName}
            />
          );
        case 'button':
          return (
            <OsBtn
              text={this.props.text}
              onClick={this.onSupportBtnClick}
              icon={this.props.icon}
              name={this.props.buttonType}
              extraClass={this.props.extraClass}
              associatedEntity={this.props.associatedEntity}
            />
          );
        default:
          return (
            <span
              className={`a-link cursor-pointer ${this.props.extraClass}`}
              onClick={this.onSupportBtnClick}>
              {' '}
              {this.getLinkText()}
            </span>
          );
      }
    } else {
      return (
        <OsLink
          className={emailTextClass}
          href={this.supportEmail()}
          openInNewTab={this.props.openInNewTab}
          text={text}
          name={this.props.name}>
          {this.props.children}
        </OsLink>
      );
    }
  }

  render() {
    return this.renderSupportLink();
  }
}

ConnectToSupport.defaultProps = {
  onRedirectToSupport: () => {},
  prefilledTextKey: '',
  text: 'Send Message',
  icon: 'message-outline',
  name: 'BtnPrimary',
  prefilledTextProps: {},
  supportEmail: MAIL_TO_SUPPORT,
  extraClass: '',
};

ConnectToSupport = withRouter(ConnectToSupport);

ConnectToSupport = connect(({ currentUser }) => ({ currentUser }))(
  ConnectToSupport,
);
export default ConnectToSupport;
