//This file won't be used till we have default board feature.
import { withRouter } from 'app/components/HOC/Router/withRouter';

import NewAction from './NewAction';

class Board extends NewAction {
  constructor(props) {
    super(props);
    this.newButtonText = 'Submit my new board';
  }

  addNewAction = () => {
    this.props.navigate(`/boards/new`);
  };

  render() {
    return this.renderAddNewButton();
  }
}

Board = withRouter(Board);

export default Board;
