import React from 'react';

const SVG = ({
  style = {},
  width = '24',
  height = '24',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      d='M15.384 8.622c2.92 2.778 4.32 5.92 3.142 7.041l-.91.374c-1.532.182-4.153-1.122-6.507-3.355-2.199-2.084-3.531-4.37-3.553-5.866l.422-1.175c1.177-1.122 4.497.213 7.406 2.98z'
      fill='#FF9270'
    />
    <path
      d='m18.526 15.663-.91.374c.378-.032.688-.16.91-.374zM7.556 6.816l.422-1.175c-.29.277-.422.683-.422 1.175z'
      fill='#FF9270'
    />
    <path
      d='M12.548 14.171c.152-.298.386-.556.692-.7a1.575 1.575 0 0 1 1.771.32.84.84 0 0 0 .926.184.86.86 0 0 0 .5-.813c-.042-.772.57-1.523 1.322-1.626.754-.103 1.548.463 1.7 1.215.112.524.693.895 1.202.772.519-.124.865-.71.946-1.37.214.772-.274 1.668-1.038 1.894-.763.227-1.66-.267-1.883-1.029a.82.82 0 0 0-.865-.556.842.842 0 0 0-.672.783 1.615 1.615 0 0 1-.937 1.4 1.564 1.564 0 0 1-1.649-.278 1.23 1.23 0 0 0-1.313-.217 1.447 1.447 0 0 0-.417.289'
      fill='#ED5A8B'
    />
    <path
      d='M11 11.73c1.9-.962 3.37-2.493 4.079-4.247A6.295 6.295 0 0 0 14.749 2c.57 3.507-.861 7.205-3.75 9.73z'
      fill='#14B874'
    />
    <path
      d='M14.416 12.811c1.52-2.408 4.176-3.84 6.832-3.653a4.259 4.259 0 0 0-4.29-.174c-1.343.72-2.346 2.21-2.542 3.827zM9.778 11.443c.206-.16.355-.604.47-.825.299-.62.103-1.403-.47-1.864a.823.823 0 0 1-.253-.972c.15-.33.54-.56.953-.55.642.01 1.262-.412 1.411-.963.15-.551-.183-1.162-.769-1.413-.516-.22-.826-.762-.734-1.253.092-.49.597-.912 1.25-.992-.791-.24-1.778.16-2.065.852-.275.681.184 1.543.964 1.794.402.13.505.631.287.952-.195.33-.608.511-.998.671-.39.15-.804.301-1.08.592-.275.28-.343.761-.022 1.012.505.38 1.242 1.043 1.139 1.625-.035.2-.382.709-.485.9'
      fill='#0C52DF'
    />
    <path
      d='M18.789 17.038a.356.356 0 0 0 .364-.346.356.356 0 0 0-.364-.346.356.356 0 0 0-.365.346c0 .191.163.346.365.346z'
      fill='#6495F7'
    />
    <path
      d='M21.43 11.6c.315 0 .57-.242.57-.54 0-.3-.255-.541-.57-.541-.314 0-.569.242-.569.54 0 .299.255.541.57.541zM7.014 5.243c.314 0 .57-.242.57-.54 0-.299-.256-.54-.57-.54-.315 0-.57.241-.57.54 0 .298.255.54.57.54z'
      fill='#14B874'
    />
    <path
      d='M18.401 6.325c.315 0 .57-.242.57-.54 0-.299-.255-.541-.57-.541-.314 0-.569.242-.569.54 0 .299.255.541.57.541zM13.847 4.163c.314 0 .569-.242.569-.54 0-.3-.255-.541-.57-.541-.314 0-.569.242-.569.54 0 .299.255.54.57.54zM17.556 16.073 2.224 21.99c-.123.041-.267-.063-.212-.178L7.556 6.865c.033 1.45 1.275 3.665 3.47 5.7 2.362 2.181 4.863 3.508 6.53 3.508z'
      fill='#FFC933'
    />
    <path
      d='m12 18.216-5.556 2.162c-1.11 0-2.488-1.047-3.141-2.034l2.03-5.533C6.165 16.523 9.8 17.538 12 18.216z'
      fill='#0C52DF'
    />
  </svg>
);

export default SVG;
