import React, { useState } from 'react';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const SpaceDropdownListing = (props) => {
  const { day, section, sectionOpen } = props;
  const [isListVisible, setIsListVisible] = useState(() => sectionOpen);

  const switchListVisibility = () => {
    setIsListVisible((prevState) => !prevState);
  };

  const alternateTitle = (title) => {
    switch (title) {
      case 'Earlier this week':
        return 'This week';
      case 'More than 2 weeks ago':
        return 'Earlier';
      default:
        return title;
    }
  };

  if (day) {
    let iconName = isListVisible ? 'chevron-down' : 'chevron-right';
    return (
      <>
        <div className={'container-section'}>
          <div
            onClick={switchListVisibility}
            className={'container-section__header'}>
            <h1 className={'title'}>{day}</h1>
            <h1 className={'alternate-title'}>{alternateTitle(day)}</h1>
            <div className={'carespace-section-count'}>
              {section.carespaceCount > 0 && (
                <span class='lt-count pull-right'>
                  {section.carespaceCount}
                </span>
              )}
            </div>
            <div className={'dropdown-icon'}>
              <OrthoIcon
                defaultClass='sidebar-arrow-icon'
                name={iconName}
                dataHoverNotRequired={true}
              />
            </div>
          </div>
          {isListVisible && props.children}
        </div>
      </>
    );
  } else return <></>;
};

export default SpaceDropdownListing;
