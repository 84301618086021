import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import ChildButton from 'app/components/Cards/ChildButton';

import { assignConsume } from 'app/actions/assign';
import {
  entityConversationUrl,
  getAuthorizeObject,
} from 'app/utils/entitiesHelper';
import { activateScroll } from 'app/utils/generalHelper';

class UserMessageCTA extends PureComponent {
  goToConversation = () => {
    this.props.assignConsume();
    this.props.onCardActionClick && this.props.onCardActionClick();
    activateScroll();
    this.props.navigate(entityConversationUrl(this.props.user));
  };

  render() {
    if (this.props.user?.connectionable_for_current_user) {
      return (
        <AuthorizeComponent
          onClick={this.goToConversation}
          abilityObject={getAuthorizeObject('create', 'conversation')}>
          <ChildButton
            text={!this.props.textNotRequired && (this.props.text || 'Message')}
            buttonType={this.props.buttonType || 'BtnPrimary'}
            extraClass={this.props.extraClass}
            associatedEntity={this.props.user}
            icon={this.props.icon}
            childButtonClassNotRequired={this.props.childButtonClassNotRequired}
          />
        </AuthorizeComponent>
      );
    } else {
      return null;
    }
  }
}

UserMessageCTA.defaultProps = {
  icon: 'message',
};
UserMessageCTA = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { assignConsume },
)(UserMessageCTA);
UserMessageCTA = withRouter(UserMessageCTA);

export default UserMessageCTA;
