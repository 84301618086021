import React, { Component } from 'react';
import { connect } from 'react-redux';

import Occurance from 'app/components/Timeline/Occurance';
import { getTimeLineDetails } from 'app/components/CaseLeftSection/helpers';

import { timeWithFormat } from 'app/utils/timeHelper';

class OverallTimeline extends Component {
  state = {
    showActiveTreatmentDuration: true,
  };

  treatmentText() {
    if (this.props.isInprogressAndNotUserDraft) {
      return 'In progress';
    } else {
      return 'Active treatment';
    }
  }

  totalTreatmentDuration() {
    return this.timelineDetails().duration + ' mo';
  }

  timelineDetails() {
    let { t1Age, t2Age } = this.props;

    return getTimeLineDetails(t1Age, t2Age);
  }

  renderHoveredLog() {
    const { startMonth, duration } = this.timelineDetails(),
      { obj: hoveredLog } = this.props.caseApplianceLogHovered;

    if (hoveredLog && hoveredLog.addedAppointment) {
      return (
        <Occurance
          className='case-treatment-progress-lightblue-bar'
          timelineStartMonth={startMonth}
          timelineDuration={duration}
          start_age={hoveredLog.addedAppointment?.patient_age}
          end_age={
            hoveredLog.removedAppointment?.patient_age || this.props.t2Age
          }
          status={false}
        />
      );
    }
  }

  render() {
    let { startMonth, duration } = this.timelineDetails(),
      treatmentDuration = this.totalTreatmentDuration(),
      { firstApplianceAddedAppointment, lastApplianceRemovedAppointment } =
        this.props;

    return (
      <div className='case-active-treatment-block'>
        <div className='case-treatment-text-block'>
          <span className='case-treatment-text-small'>
            {this.treatmentText()}
          </span>
          <span className='case-treatment-time-block'>{treatmentDuration}</span>
        </div>
        <div className='case-treatment-progress-block'>
          <div className='case-treatment-progress-grey-bar'>
            <div className='timeline'>
              <div className='appliance-log'>
                <div className='duration'>
                  <div className='base-line' />
                  <Occurance
                    className={'case-treatment-progress-blue-bar '}
                    timelineStartMonth={startMonth}
                    timelineDuration={duration}
                    start_age={firstApplianceAddedAppointment.patient_age}
                    end_age={
                      lastApplianceRemovedAppointment
                        ? lastApplianceRemovedAppointment.patient_age
                        : this.props.t2Age
                    }
                    status={false}
                  />
                  {this.renderHoveredLog()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
OverallTimeline = connect(
  ({ caseApplianceLogHovered }) => ({ caseApplianceLogHovered }),
  {},
)(OverallTimeline);

export default OverallTimeline;
