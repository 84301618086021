import React, { useEffect, useMemo, useRef, useState } from 'react';
import { displayEntityName } from 'app/utils/entitiesHelper';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { Accordion } from 'react-bootstrap';

import { sortBy, uniqBy } from 'app/utils/osLodash';
import OsCards from 'app/components/OsCards';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';

import { pluralizeString } from 'app/utils/stringHelper';

const AttachmentsInConversationListingModule = (props) => {
  const parentRef = useRef(null);
  const [results, setResults] = useState(props.results || []);
  const onSeeAllLink = () => {
    let { recordId, recordType } = props;
    recordType = displayEntityName({ __typename: recordType });
    let seeAllLink = `/records/${pluralizeString(
      recordType,
    )}/${recordId}/discussions/attachments`;
    props.navigate(seeAllLink);
  };

  const sortedSharedFiles = useMemo(() => {
    let sortedResult = sortBy(results, ['id']).reverse();

    return sortedResult;
  }, [results]);

  useEffect(() => {
    setResults(
      uniqBy(
        [...results, ...props.results],
        (obj) => `${obj.__typename}:${obj.id}`,
      ),
    );
  }, [props.results]);

  const renderSeeAllLink = () => {
    if (props.afterHasMore)
      return (
        <OsBtn
          name='BtnIcon'
          icon='expand-2'
          extraClass='no-text tag-view-more pointer fs-12'
          onClick={onSeeAllLink}
        />
      );
  };

  const renderAttachment = (attachment) => {
    if (props.source === 'widget') {
      return (
        <div className='col-12 mb-2' key={attachment.id}>
          <OsCards size='extra-small' obj={attachment.file_asset} />
        </div>
      );
    } else {
      return (
        <OsGrid identifier='CardColumn:Card' key={attachment.id}>
          <OsCards size='extra-small' obj={attachment} />
        </OsGrid>
      );
    }
  };

  const handleScroll = () => {
    if (
      parentRef.current.scrollHeight - parentRef.current.scrollTop ===
      parentRef.current.clientHeight
    ) {
      if (results.length < props.totalRecords) {
        props.refetch({ page: props.variables.page + 1 });
      }
    }
  };
  const renderWidgetHeader = () => {
    if (props.source === 'widget') {
      return (
        <Accordion.Header
          className='rt-ac-header related case-doc-title'
          key='related-documents'>
          <h5>Shared Files</h5>
          {renderSeeAllLink()}
        </Accordion.Header>
      );
    }
  };

  if (props.results.length > 0) {
    return (
      <Accordion defaultActiveKey='0' className='right-ac'>
        <Accordion.Item eventKey='0'>
          {renderWidgetHeader()}
          <Accordion.Body>
            <div
              className='row document-list chat-shared-files'
              key='documents'
              ref={parentRef}
              onScroll={handleScroll}>
              {sortedSharedFiles.map(renderAttachment)}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  } else {
    return null;
  }
};

AttachmentsInConversationListingModule.defaultProps = {
  source: 'widget',
};

const AttachmentsInConversationListing = withRouter(
  AttachmentsInConversationListingModule,
);

export default AttachmentsInConversationListing;
