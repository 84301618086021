import { SET_FLAGGED_FEATURES, SET_SYSTEM_CONFIG } from 'app/actions/types';

export function systemConfigSet(data) {
  return (dispatch) => {
    dispatch({
      type: SET_SYSTEM_CONFIG,
      configs: data,
    });
  };
}

export function setFlaggedFeatures(data) {
  return (dispatch) => {
    dispatch({
      type: SET_FLAGGED_FEATURES,
      flaggedFeatures: data,
    });
  };
}
