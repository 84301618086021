import React from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import {
  setWorkspaceData,
  setGuestWorkspaceOwnerData,
} from 'app/actions/workspace';
import { setSource } from 'app/actions/source';

const CURRENT_USER_QUERY = queryLoader(
  'app/graphql/queries/GuestUserWorkspace.gql',
);
const GUEST_WORKSPACE_OWNER_QUERY = queryLoader(
  'app/graphql/Workspace/Queries/guestWorkspaceOwner.gql',
);

const withWorkspaceRequest = (WrappedComponent) => {
  class HOC extends React.Component {
    componentDidMount() {
      this.props.setSource('care');
      if(this.props.getWorkspace.workspace)
        this.props.setWorkspaceData(this.props.getWorkspace.workspace);
      if(this.props.getWorkspaceOwner.guestWorkspaceOwner)
        this.props.setGuestWorkspaceOwnerData(
          this.props.getWorkspaceOwner.guestWorkspaceOwner,
        );
      addClassToHTML('patient-layout');
    }

    componentDidUpdate(prevProps) {
      if (prevProps.getWorkspace.loading && !this.props.getWorkspace.loading) {
        this.props.setWorkspaceData(this.props.getWorkspace.workspace);
      }
      if (
        prevProps.getWorkspaceOwner.loading &&
        !this.props.getWorkspaceOwner.loading
      ) {
        this.props.setGuestWorkspaceOwnerData(
          this.props.getWorkspaceOwner.guestWorkspaceOwner,
        );
      }
    }

    componentWillUnmount() {
      removeClassFromHTML('patient-layout');
      this.props.setSource('');
    }

    render() {
      let { workspace } = this.props;
      return (
        <>{workspace && workspace.guestOwnerData && <WrappedComponent />}</>
      );
    }
  }

  HOC = compose(
    graphql(CURRENT_USER_QUERY, {
      name: 'getWorkspace',
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {
          id: props.match.params.identifier,
        },
      }),
    }),
    graphql(GUEST_WORKSPACE_OWNER_QUERY, {
      name: 'getWorkspaceOwner',
      options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {
          id: props.match.params.identifier,
        },
      }),
    }),
  )(HOC);
  HOC = connect(
    ({ currentUser, workspace, device }) => ({
      currentUser,
      workspace,
      device,
    }),
    { setWorkspaceData, setGuestWorkspaceOwnerData, setSource },
  )(HOC);
  HOC = withRouter(HOC);
  return HOC;
};

export default withWorkspaceRequest;
