import {
  EASY_RX_BASE_API_URL,
  EASY_RX_CLIENT_ID,
  EASY_RX_CALLBACK_URL,
} from 'app/constant/easyRX';

export const getEasyRXAuthorizeURL = () => {
  const params = {
    response_type: 'code',
    client_id: EASY_RX_CLIENT_ID,
    redirect_uri: EASY_RX_CALLBACK_URL,
  };
  const stringifiedParams = new URLSearchParams(params).toString();
  return `${EASY_RX_BASE_API_URL}/sso/m.php/oauth2/authorize.php?${stringifiedParams}`;
};
