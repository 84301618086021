import React from 'react';

import AbstractField from './AbstractField';
import OsField from './';
import OsBtn from 'app/components/OsBtn';

import { isEmpty } from 'app/utils/osLodash';

export default class OsTextAreaField extends AbstractField {
  renderInlineSubmit() {
    let { inlineSubmitProps } = this.props;
    if (!isEmpty(inlineSubmitProps)) {
      let className = inlineSubmitProps.className;
      className += inlineSubmitProps.disabled
        ? ' disabled-only pointer-allow'
        : ' ';

      return (
        <OsBtn
          extraClass={className}
          type='submit'
          disabled={inlineSubmitProps.disabled}
          htmlTag='button'
          label={inlineSubmitProps.label}
          icon={inlineSubmitProps.iconName}
          name={inlineSubmitProps.btnType}
          iconDefaultClass={inlineSubmitProps.iconDefaultClass}
        />
      );
    }
  }

  renderField() {
    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        <textarea {...this.getFieldProps()} />
        {this.renderInlineSubmit()}
      </>
    );
  }

  renderFieldWithWrapper() {
    return (
      <div className={`${this.props.textareaWrapperClass}`}>
        {this.renderField()}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.props.textareaWrapperClass
          ? this.renderFieldWithWrapper()
          : this.renderField()}
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsTextAreaField.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
};
