import {
  CLOSE_COMMAND_MENU,
  OPEN_COMMAND_MENU,
} from 'app/components/DnD/CmdMenu/constants';

const initialState = {
  isCmdOpen: false,
};
const commandMenu = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COMMAND_MENU:
      return {
        ...state,
        isCmdOpen: true,
      };
    case CLOSE_COMMAND_MENU:
      return {
        ...state,
        isCmdOpen: false,
      };
    default:
      return state;
  }
};

export default commandMenu;
