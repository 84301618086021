import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

export function withRouter(Component) {
  const WithRouter = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const useCustomNavigate = (url, options = {}) => {
      options.skip
        ? navigate(url, options)
        : navigate(withWorkspaceIdentifier(url), options);
    };

    return (
      <Component
        {...props}
        location={location}
        navigate={useCustomNavigate}
        match={{ params }}
        goBack={() => navigate(-1)}
      />
    );
  };
  return WithRouter;
}
