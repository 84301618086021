import { SET_NEW_POST, RESET_NEW_POST } from 'app/actions/types';

export default function postForm(
  state = {
    newPostId: null,
  },
  action,
) {
  switch (action.type) {
    case SET_NEW_POST:
      return { newPostId: action.newPostId };
    case RESET_NEW_POST:
      return { newPostId: null };
    default:
      return state;
  }
}
