import { PAGE_TITLE_MAP } from 'app/routes/Shared/Constant';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { EXTRACT_TRANSLATION_KEY_REGEX } from 'app/actions/flashMessage';
import { useSelector } from 'react-redux';

const BLANK_FAVICON_URL = "data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAF0lEQVRIx2NgGAWjYBSMglEwCkbBSAcACBAAAeaR9cIAAAAASUVORK5CYII="
const DEFAULT_FAVICON_URL = "/assets/favicon.png"

function SetPageTitle({ source, interpolations }) {
  const [title, setTitle] = useState('');
  const workspace = useSelector((state) => state.workspace);
  const translate = (source, interpolations) => {
    var translatedMessage = PAGE_TITLE_MAP[source];
    if (interpolations !== undefined) {
      let interpolationKeys =
        translatedMessage.match(EXTRACT_TRANSLATION_KEY_REGEX) || [];
      translatedMessage =
        translatedMessage.split(interpolationKeys)[0] + interpolations;
    }
    return translatedMessage;
  };

  useEffect(() => {
    let notificationInterval = null,
        faviconStatus = "default"
    const setFavicon = (status) => {
      const url = status === "default" ? DEFAULT_FAVICON_URL : BLANK_FAVICON_URL
      document.getElementById('light-scheme-icon').href = url;
    }
    if (workspace.data?.total_unread_notifications_count > 0) {
      if(!notificationInterval) {
        notificationInterval = setInterval(() => {
          faviconStatus = faviconStatus === "default" ? "blank" : "default"
          setFavicon(faviconStatus)
        }, 700);
      }
    } else {
      if (notificationInterval)
        clearInterval(notificationInterval);
      setFavicon("default")
    }

    return () => {
      if (notificationInterval) {
        clearInterval(notificationInterval);
      }
    };
  }, [workspace.data?.total_unread_notifications_count]);

  useEffect(() => {
    setTitle(translate(source, interpolations));
  }, [source, interpolations]);

  return (
    <div>
      <Helmet encodeSpecialCharacters={true}>
        <title>{parse(title)}</title>
      </Helmet>
    </div>
  );
}

export default SetPageTitle;
