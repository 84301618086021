import React from 'react';

import NotificationCardLayout from './NotificationCardLayout';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { entityUrl } from 'app/utils/entitiesHelper';

class CourseStartedNotification extends NotificationCardLayout {
  isCTARequiredOnSmallNotification() {
    return true;
  }

  coursePath() {
    return entityUrl(this.props.card_objects[0]);
  }

  commonBtnProps() {
    return {
      to: this.coursePath(),
      associatedEntity: this.props.card_objects[0],
    };
  }

  renderSmallNotiLink() {
    return (
      <span className='n-d-action'>
        <OsLink
          className='a-link fs-12 pointer text-uppercase abs-link'
          {...this.commonBtnProps()}
          text='Continue'
        />
      </span>
    );
  }

  renderNotificationButton() {
    return (
      <div className='nf-action-bar'>
        <OsBtn
          htmlTag='Link'
          name='BtnPrimary'
          {...this.commonBtnProps()}
          text='Continue Learning'
        />
      </div>
    );
  }

  renderBody() {
    return this.props.isSmallNotification
      ? this.renderSmallNotiLink()
      : this.renderNotificationButton();
  }
}

export default CourseStartedNotification;
