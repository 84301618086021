import store from 'app/store';
import { compact, keys, startsWith } from 'app/utils/osLodash';
import {
  SPACE_MUTE_BANNER_KEY_PREFIX,
  SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX,
} from 'app/components/UniversalBanner/constants';

const DRAFT_KEY_PREFIX = 'draft_';
const OrthoStorage = window.localStorage;
const USER_SPECIFIC_DATA_KEYS = [
  'activeConversation',
  'hideConnectionModal',
  'drafts',
];
const USER_SPECIFIC_DATA_KEY_PREFIXS = [
  `ub_${SPACE_MUTE_BANNER_KEY_PREFIX}`,
  `ub_${SPACE_PRIORITY_GROUP_BANNER_KEY_PREFIX}`,
];

class LocalStorageManager {
  draftSurveyKey(resourceId) {
    return `${DRAFT_KEY_PREFIX}_user_${this.currentUserId()}_survey_${resourceId}`;
  }

  // ############## SETTERS ##############
  saveQuizResponse(resourceId, options) {
    keys(options).forEach((key) => {
      let draftKey = `${this.draftSurveyKey(resourceId)}_${key}`;
      this.set(draftKey, options[key]);
    });
  }

  setActiveConversation(conversation) {
    this.set('activeConversation', conversation);
  }

  tabSectionStateKey(sectionId) {
    return `${DRAFT_KEY_PREFIX}_user_${this.currentUserId()}_section_${sectionId}`;
  }

  setTabSectionState(sectionId, open) {
    this.set(this.tabSectionStateKey(sectionId), open);
  }

  setDraftValue(type, typeId, userId, value) {
    let key = this.getDraftKey(type, typeId, userId);
    let timestampKey = this.getDraftTimestampKey(key);

    this.set(key, value);
    this.set(timestampKey, Date.now());
  }

  set(key, value) {
    if (this.localStorageAvailable()) {
      OrthoStorage.setItem(key, JSON.stringify(value));
    } else {
      window.Cookies.set(key, value);
    }
  }

  // ##### Check whether local storage is available or not #####
  localStorageAvailable() {
    var test = 'test';
    try {
      OrthoStorage.setItem(test, test);
      OrthoStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  // ############## GETTERS ##############
  getActiveConversation() {
    return this.get('activeConversation');
  }

  getSurveyResponseKey(resourceId, key) {
    return this.get(`${this.draftSurveyKey(resourceId)}_${key}`);
  }

  getDraftTimestampKey(key) {
    return `${key}_timestamp`;
  }

  getDraftKey(type, typeId, userId) {
    return `${DRAFT_KEY_PREFIX}user_${userId}_${type}_${typeId}`;
  }

  getDraftValue(type, typeId, userId) {
    let key = this.getDraftKey(type, typeId, userId);
    return this.get(key);
  }

  getDraftTimestamp(type, typeId, userId) {
    let key = this.getDraftKey(type, typeId, userId);
    return this.get(this.getDraftTimestampKey(key));
  }

  get(key) {
    if (this.localStorageAvailable()) {
      return JSON.parse(OrthoStorage.getItem(key));
    } else {
      return window.Cookies.get(key);
    }
  }

  getTabSectionState(sectionId) {
    return this.get(this.tabSectionStateKey(sectionId));
  }

  getValuesWithPrefix(prefix) {
    return compact(
      keys(OrthoStorage)
        .filter((key) => startsWith(key, prefix))
        .map((key) => {
          return { key, value: this.get(key) };
        }),
    );
  }

  // ############## CLEAR STORAGE ##############
  clear(key) {
    if (this.localStorageAvailable()) {
      OrthoStorage.removeItem(key);
      sessionStorage.removeItem(key);
    } else {
      window.Cookies.remove(key);
    }
  }

  clearAll() {
    OrthoStorage.clear();
  }

  clearAllDraftKeys() {
    keys(OrthoStorage)
      .filter((key) => startsWith(key, DRAFT_KEY_PREFIX))
      .forEach((key) => this.clear(key));
  }

  clearQuizResponse(resourceId) {
    keys(OrthoStorage)
      .filter((key) => startsWith(key, this.draftSurveyKey(resourceId)))
      .forEach((key) => this.clear(key));
  }

  clearUserSpecificValues() {
    USER_SPECIFIC_DATA_KEYS.forEach((key) => this.clear(key));
    USER_SPECIFIC_DATA_KEY_PREFIXS.forEach((prefix) => {
      this.getValuesWithPrefix(prefix).forEach((keyPair) =>
        this.clear(keyPair.key),
      );
    });
  }

  clearApolloCachePersist() {
    this.clear('apollo-cache-persist');
  }

  currentUserId() {
    let { graph } = store.getState().currentUser;
    return graph && graph.id.toString();
  }

  anyQuizResponseKeysPresent(resourceId) {
    return keys(OrthoStorage).some((key) =>
      startsWith(key, this.draftSurveyKey(resourceId)),
    );
  }
}

export default new LocalStorageManager();
