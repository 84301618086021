import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import OsBtn from 'app/components/OsBtn';

const CHANGE_APPOINTMENT_PATIENT_VISIBILITY = queryLoader(
  'app/graphql/mutations/Care/ChangeAppointmentPatientVisibility.gql',
);

class AppointmentVisibilityButton extends Component {
  state = {
    requestInProgress: false,
  };

  appointment() {
    return this.props.appointment;
  }

  getOptimisticResponse() {
    let appointment = this.appointment();
    this.setState({ requestInProgress: false });
    return {
      __typename: 'Response',
      success: true,
      entity: {
        __typename: appointment.__typename,
        id: appointment.id,
        visible_to_patient: !this.visibleToPatient(),
      },
    };
  }

  sendRequest = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ requestInProgress: true });
    this.props.changeAppointmentPatientVisibility({
      variables: { id: this.appointment().id },
      optimisticResponse: () => {
        return { changeEntityPrivacy: this.getOptimisticResponse() };
      },
    });
  };

  visibleToPatient() {
    return this.appointment().visible_to_patient;
  }

  getButtonProps() {
    if (this.visibleToPatient()) {
      return {
        icon: 'hide-password',
        text: 'Hide from patient',
      };
    } else {
      return {
        icon: 'view',
        text: 'Make visible to patient',
      };
    }
  }

  render() {
    return (
      <OsBtn
        key='patient_visibility'
        name='BtnIcon'
        extraClass='list-button'
        onClick={this.sendRequest}
        associatedEntity={this.appointment()}
        disabled={this.state.requestInProgress}
        {...this.getButtonProps()}
      />
    );
  }
}

AppointmentVisibilityButton = graphql(CHANGE_APPOINTMENT_PATIENT_VISIBILITY, {
  name: 'changeAppointmentPatientVisibility',
})(AppointmentVisibilityButton);
export default AppointmentVisibilityButton;
