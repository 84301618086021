import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import AbstractUserView from './AbstractUserView';
import AccountView from 'app/components/AccountView';
import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import ImageEdit from 'app/components/AccountView/imageEdit';
import Share from 'app/components/Shared/Share';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import UserMessageCTA from 'app/components/Shared/UserMessageCTA';

import withAuthorize from 'app/components/HOC/withAuthorize';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { entityUrl } from 'app/utils/entitiesHelper';
import { map, pick } from 'app/utils/osLodash';
import { assignAddObject } from 'app/actions/assign';
import { isCommunicationFeatureEnabled } from 'app/utils/featureHelper';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';

class Header extends AbstractUserView {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalEdit: false,
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModalEdit = () => {
    this.setState({ showModalEdit: false });
  };

  openModalEdit = () => {
    this.setState({ showModalEdit: true });
  };

  isCurrentUser() {
    return this.props.user?.id === this.props.currentUser.graph.id;
  }

  getEditAbilityObject() {
    return getAuthorizeObject('update', 'user', { userId: this.props.user.id });
  }

  editProfile() {
    if (this.isCurrentUser() && !this.isMobileDevice())
      return (
        <AuthorizeComponent
          onClick={this.openModal}
          abilityObject={this.getEditAbilityObject()}>
          <OsLink
            iconName='edit'
            associatedEntity={this.props.user}
            key={'edit-profile'}
            className='osbtn'
            text='Edit Profile'
            to={`${entityUrl(this.props.user)}/edit`}
          />
        </AuthorizeComponent>
      );
  }

  renderImageEditAction(props = {}) {
    let btnClasses = 'user-image-edit-btn ';
    btnClasses += !this.props.device.mobileDevice ? 'p-0 no-text' : ' ';
    if (this.imageEditActionRequired())
      return (
        <AuthorizeComponent
          onClick={this.openModalEdit}
          abilityObject={this.getEditAbilityObject()}>
          <OsBtn
            name='BtnIcon'
            extraClass={btnClasses}
            icon='image'
            label='Edit user profile image'
            associatedEntity={this.props.user}
            {...props}
          />
        </AuthorizeComponent>
      );
  }

  getInitialValues() {
    return {
      ...pick(this.props.user, [
        'id',
        'first_name',
        'middle_initial',
        'last_name',
        'email',
        'bio',
      ]),
      clinic: map(this.props.user?.clinics, 'name').join(', ') || 'NA',
      degree_list: this.props.user?.degree_list.length
        ? this.props.user.degree_list.join(' ')
        : 'NA',
    };
  }

  displayActions() {
    const { mobileDevice } = this.props.device;
    return (
      <div className='detail-btn-row'>
        <CustomDropdown name='user_action'>
          {this.renderShareAction()}
          {this.editProfile()}
          {mobileDevice &&
            this.renderImageEditAction({ isBigbtn: false, text: 'Edit Image' })}
        </CustomDropdown>
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName='modal-xl modal-edit-profile'
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <AccountView
            closeParentModal={this.closeModal}
            initialValues={this.getInitialValues()}
            user={this.props.user}
          />
        </Modal>
        <Modal
          show={this.state.showModalEdit}
          onHide={this.closeModalEdit}
          dialogClassName='modal-xl profile-image-modal'
          animation={false}
          backdropClassName='modal-backdrop-custom'>
          <ImageEdit
            closeParentModal={this.closeModalEdit}
            obj={this.props.currentUser.graph}
          />
        </Modal>
      </div>
    );
  }

  isGuestUser() {
    return false;
  }

  isSupportUser() {
    return this.props.user?.is_support_user;
  }

  renderShareAction() {
    return (
      <Share key='share' type='User' object={this.props.user} label={'Share'} />
    );
  }

  displayOwnProfileActionsMobile() {
    return <>{this.renderShareAction()}</>;
  }

  displayOtherProfileActionsMobile() {
    return [];
  }

  renderMobileActionsDropdown() {
    let actions = this.isCurrentUser()
      ? this.displayOwnProfileActionsMobile()
      : this.displayOtherProfileActionsMobile();
    return <CustomDropdown name='tool_action'>{actions}</CustomDropdown>;
  }

  shouldAvoidConnectionRemoval() {
    return !this.isConnectionRequestReceived();
  }

  isConnectionRequestReceived() {
    return this.props.user?.connection_status === 'request-received';
  }

  getButtonText() {
    return isWorkspaceView() ? 'Send Message' : '';
  }

  getButtonType() {
    return this.isConnectionRequestReceived() ? '' : 'BtnPrimary';
  }

  isConnectionRequestNotInitiated() {
    return false;
  }

  showConnections() {
    let buttonRequired =
      isCommunicationFeatureEnabled() &&
      (this.props.user.connectionable_for_current_user ||
        !this.isConnectionRequestNotInitiated());
    return !this.isCurrentUser() && !this.isSupportUser() && buttonRequired;
  }

  getConnectionButtonProps() {
    let { mobileDevice } = this.props.device,
      props = {
        user: this.props.user,
        acceptConnectectionText: 'Accept Request',
        declineConnectionText: 'Ignore',
        text: this.getButtonText(),
        buttonType: this.getButtonType(),
        acceptedButtonType: 'BtnPrimary',
        avoidConnectionRemoval: this.shouldAvoidConnectionRemoval(),
        extraClass: 'mb-3',
      };
    props.extraClass += mobileDevice ? ' mt-3' : ' mt-4';
    if (mobileDevice && !this.isConnectionRequestReceived())
      props.extraClass += ' mx-auto';
    return props;
  }

  renderConnectionButton() {
    if (isWorkspaceView()) {
      return <UserMessageCTA {...this.getConnectionButtonProps()} />;
    }
  }

  render() {
    return this.renderHeader();
  }
}

Header = connect(({ device, currentUser }) => ({ device, currentUser }), {
  assignAddObject,
})(Header);
Header = withAuthorize(Header);
Header = withRouter(Header);

export default Header;
