import { OPEN_TEAMSPACE_MODAL, CLOSE_TEAMSPACE_MODAL } from 'app/actions/types';

export default function teamSpaceModal(
  state = { open: false, spaceId: null, options: {} },
  action,
) {
  switch (action.type) {
    case OPEN_TEAMSPACE_MODAL:
      return { open: true, spaceId: action.spaceId, ...action.options };
    case CLOSE_TEAMSPACE_MODAL:
      return { open: false, spaceId: null, categories: [], ...action.options };
    default:
      return state;
  }
}
