export const messageHelper = (activity, person) => {
  if (person) {
    const firstName = (person && person.split(' ')[0]) || person;
    switch (activity) {
      case 'care_space_added':
        return `Patient Added by ${firstName}`;
      case 'comment_added':
        return `${firstName} posted`;
      case 'emoji_added':
        return `${firstName} reacted`;
      case 'space_unlocked':
        return `${firstName} unlocked`;
      case 'space_shared':
        return `Shared by ${firstName}`;
      case 'space_reshared':
        return `Reshared by ${firstName}`;
      default:
        return ``;
    }
  } else {
    return '';
  }
};
