import { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

class ScrollToTop extends Component {
  state = { lastScroll: 0 };
  constructor(props) {
    super(props);

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.noElementIsSticky()
    ) {
      window.scrollTo(0, 0);
      window.$('html')[0] &&
        window.$('html').removeClass('scroll-up scroll-down');
    }
  }

  componentDidMount() {
    if (!this.props.device.mobileDevice) {
      this.scrollTopNav();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setScrollUpandDownClass);
  }

  setScrollUpandDownClass = () => {
    const html = window.$('html')[0];
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    const topZero = 'top-zero';
    const currentScroll = window.pageYOffset;
    const { lastScroll } = this.state;

    if (currentScroll <= 0) {
      html.classList.remove(scrollUp);
      html.classList.remove(scrollDown);
      html.classList.add(topZero);
      return;
    } else {
      html.classList.remove(topZero);
    }

    if (currentScroll > lastScroll && !html.classList.contains(scrollDown)) {
      // down
      html.classList.remove(scrollUp);
      html.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      html.classList.contains(scrollDown)
    ) {
      // up
      html.classList.remove(scrollDown);
      html.classList.add(scrollUp);
    }

    this.setState({ lastScroll: currentScroll });
  };

  scrollTopNav = () => {
    window.addEventListener('scroll', this.setScrollUpandDownClass);
  };

  noElementIsSticky() {
    // NOTE: Temporary fixed added
    return window.$('.head-sticky.fixed').length === 0;
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop = connect(({ device }) => ({ device }))(ScrollToTop);
ScrollToTop = withRouter(ScrollToTop);
export default ScrollToTop;
