import React from 'react';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';

import { withRouter } from 'app/components/HOC/Router/withRouter';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { loader as queryLoader } from 'graphql.macro';
import scrollToElement from 'scroll-to-element';

import AbstractSpaceView from './AbstractSpaceView';
import ChooseProfileModal from 'app/components/CareSpace/Modals/ChooseProfile';
import CommentableView from 'app/components/CommentableView';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';
import SpaceNavTabContent from 'app/components/NavTabs/SpaceNavTabContent';
import SpaceNavTabs from 'app/components/NavTabs/SpaceNavTabs';
import OsBtn from 'app/components/OsBtn/';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import View from 'app/components/Shared/View';
import SpaceInformationView from 'app/components/SpaceInformationView';
import Header from './Header';

import { openPoliciesModal } from 'app/actions/policiesModal';
import {
  anyTopBannerPresent,
  isCareSpace,
  isFolderSpace,
  isSpaceBannerContentPresent,
  lockedSpacePath,
} from 'app/utils/spaceHelper';
import {
  isCareWorkspaceView,
  isWorkspaceSpace,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';
import {
  isNavNotListRequired,
  clvViewRequired,
  contentAccessibleTabs,
} from 'app/components/NavTabs/helper';
import { isEmpty, isEqual, keys } from 'app/utils/osLodash';

import { setActiveResource } from 'app/actions/activeResource';
import { addGuardianInPriorityGroup } from 'app/actions/careSpaceActions';
import { followObject } from 'app/actions/followObject';
import { openInfoModal } from 'app/actions/infoModal';
import {
  addUniversalBanner,
  removeUniversalBanner,
  updateBannerValues,
} from 'app/actions/universalBanner';
import { isMobileExpandedNavTabEnabled } from 'app/utils/featureHelper';

import { editContactParty } from 'app/actions/contactPartyModal';
import { openPatientModal } from 'app/actions/patientModal';
import { openTaskModalWithEntity } from 'app/actions/taskModal';
import TipTapEditorForMobile from 'app/components/BoardView/Editor/TipTapEditorForMobile';
import { POLICIES_KIND_HASH_MAPPER } from 'app/components/PoliciesView/constants';
import {
  SPACE_MUTE_BANNER_TEMPLATE,
  SPACE_PRIORITY_GROUP_BANNER_TEMPLATE,
} from 'app/components/UniversalBanner/constants';
import {
  getSpaceMutedBannerKey,
  getSpacePriorityGroupBannerKey,
} from 'app/components/UniversalBanner/helper';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';
import {
  isPatientOfCareSpace,
  findPersonConnection,
} from 'app/utils/spaces/careHelper';
import {
  SPACE_NAV_LIST_FOR_PATIENT_MOBILE,
  SPACE_NAV_LIST_FOR_TEAM_MOBILE,
} from 'app/components/BoardView/constants';

const SPACE_QUERIES = {
  '/spaces': queryLoader('app/graphql/queries/Spaces/Types/Base.gql'),
  '/cares': queryLoader('app/graphql/queries/Spaces/Types/Care.gql'),
  '/clinics': queryLoader('app/graphql/queries/Spaces/Types/Clinic.gql'),
  '/companies': queryLoader('app/graphql/Company.gql'),
  '/communities': queryLoader('app/graphql/queries/Spaces/Types/Content.gql'),
  '/team/spaces': queryLoader('app/graphql/queries/Spaces/Types/Content.gql'),
  '/folders': queryLoader('app/graphql/queries/Spaces/Types/Folder.gql'),
  // '/partners': queryLoader('app/graphql/queries/Spaces/Types/Partner.gql'),
  '/workspaces': queryLoader('app/graphql/queries/Spaces/Types/Workspace.gql'),
};

// Board detail view
class BoardView extends AbstractSpaceView {
  constructor(props) {
    super(props);
    this.resourceKey = 'board';
    this.carespaceBanner = React.createRef();
    this.carespaceBanner.current = new Map();
  }

  state = {
    unreadDiscussionPresent: false,
    changeKey: false,
    isHeadSticky: false,
    cardViewType: this.setCardViewType(),
    searchText: '',
    filterValues: [],
    editor: {
      isVisible: false,
      type: 'patient',
    },
  };

  setCardViewType() {
    return 'list';
    // return this.props.device.mobileDevice ? 'list' : 'big'
  }

  getSpace() {
    let space = this.props.data.board;
    return space;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.board &&
      !this.state.unreadDiscussionPresent &&
      nextProps.data.board.unseen_comments_count
    ) {
      this.setState({ unreadDiscussionPresent: true });
    }

    if (!isEqual(nextProps.data.board, this.props.data.board)) {
      this.props.setActiveResource(nextProps.data.board);
    }
    if (this.props.match.params.filter !== nextProps.match.params.filter) {
      if (this.props.match.params.filter) {
        this.scrollIfNeeded();
      } else {
        (nextProps.data.board?.lecture_preview_tabs || []).length === 0 &&
          this.scrollIfNeeded();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const space = this.getSpace();
    if (!this.props.data.loading && prevProps.data.loading && space) {
      if ((space.lecture_preview_tabs || []).length === 0)
        this.scrollIfNeeded();

      if (
        this.isAuthorOrCollaborator() &&
        space.user_request?.kind === 'member' &&
        !space.user_request?.tou_accepted
      ) {
        this.props.openPoliciesModal({
          forceUpdate: true,
          goBackRequired: true,
          kind: POLICIES_KIND_HASH_MAPPER['subscriber_policy'],
          entityType: 'Board',
          entityId: space.nice_id,
        });
      }
    }

    if (space) {
      if (space.is_author_or_collaborator) {
        if (!space.followed_by_current_user) {
          if (!this.carespaceBanner.current.has(space.nice_id)) {
            this.addMutedBanner();
            this.carespaceBanner.current.set(space.nice_id, true);
          }
        } else {
          // Iterate through all map keys and remove the banner for all of them.
          this.carespaceBanner.current.forEach((value, key) => {
            const bannerKey = getSpaceMutedBannerKey(key);
            this.props.removeUniversalBanner(bannerKey);
          });
          this.carespaceBanner.current.clear();
        }
      }

      if (isCareWorkspaceView() && isCareSpace(space)) {
        this.isGuardianAndNotPriorityGroup()
          ? this.addPriorityContactBanner()
          : this.markSpacePriorityGroupBannerInactive();

        if (false && space.user_request?.verification_required) {
          let currentUserPatientProfile =
            this.props.currentUser.graph.patient_profile;
          if (isPatientOfCareSpace(currentUserPatientProfile, space)) {
            this.props.openPatientModal(space.nice_id, {
              space,
              source: 'care-space-detail',
            });
          } else {
            let personConnection = findPersonConnection(
              currentUserPatientProfile,
              space,
            );
            if (personConnection) {
              this.props.editContactParty({
                space,
                contactParty: {
                  ...currentUserPatientProfile,
                  person_connection_id: personConnection?.id,
                },
              });
            }
          }
        }
      }
    }
  }

  isGuardianAndNotPriorityGroup() {
    return !!this.guardianAndNotPriorityGroupObject();
  }

  guardianAndNotPriorityGroupObject() {
    let space = this.getSpace(),
      { graph } = this.props.currentUser,
      { person_connections } = space.owner.patient;

    return person_connections.find(
      (pc) => !pc.notify && +pc.related_person?.user_id === +graph.id,
    );
  }

  getPriorityGroupBannerKey() {
    return getSpacePriorityGroupBannerKey(this.getSpace().nice_id);
  }

  addGuardianInPriorityGroup = () => {
    this.props.addGuardianInPriorityGroup(
      this.guardianAndNotPriorityGroupObject().id,
      { onSuccess: this.markSpacePriorityGroupBannerInactive },
    );
  };

  addInPriorityGroupConfirmation = () => {
    this.props.openInfoModal('care_space', 'add_in_proirity_group', {
      onSuccess: this.addGuardianInPriorityGroup,
      closeIconNotRequired: true,
    });
  };

  markSpacePriorityGroupBannerInactive = () => {
    this.props.updateBannerValues(this.getPriorityGroupBannerKey(), {
      active: false,
    });
  };

  addPriorityContactBanner() {
    let bannerProps = {
      ...SPACE_PRIORITY_GROUP_BANNER_TEMPLATE,
      bannerKey: this.getPriorityGroupBannerKey(),
      onCTAClick: this.addInPriorityGroupConfirmation,
      active: true,
      messageKey: 'SPACE_PRIORITY_GROUP_BANNER_TEXT',
      onClose: this.markSpacePriorityGroupBannerInactive,
      interpolations: {
        icon: <OrthoIcon name='info' />,
        bold_text: <b>Priority Contact Group</b>,
      },
    };

    this.props.addUniversalBanner(bannerProps, { prepend: true });
  }

  followSpace = () => {
    this.props.followObject(
      { obj: this.getSpace() },
      { onSuccess: this.markSpaceMuteBannerInactive },
    );
  };

  getMutedBannerKey() {
    return getSpaceMutedBannerKey(this.getSpace().nice_id);
  }

  markSpaceMuteBannerInactive = () => {
    this.props.updateBannerValues(this.getMutedBannerKey(), { active: false });
  };

  addMutedBanner() {
    let space = this.getSpace(),
      { mobileDevice } = this.props.device,
      bannerProps = {
        ...SPACE_MUTE_BANNER_TEMPLATE,
        bannerKey: this.getMutedBannerKey(),
        onCTAClick: this.followSpace,
        active: true,
        messageKey: 'SPACE_MUTE_BANNER_TEXT',
        onClose: this.markSpaceMuteBannerInactive,
        interpolations: {
          space_type: isCareSpace(space) ? 'PatientSpace' : 'Space',
          icon: !mobileDevice ? (
            <OrthoIcon name='info' dataHoverNotRequired={true} />
          ) : null,
          mute_text: <b>Mute</b>,
        },
      };

    this.props.addUniversalBanner(bannerProps, { prepend: true });
  }

  componentWillUnmount() {
    this.props.setActiveResource(null);
    if (this.getSpace()) {
      this.markSpaceMuteBannerInactive();
      this.markSpacePriorityGroupBannerInactive();
    }
  }

  setSticky() {
    this.setState({ isHeadSticky: true });
  }

  scrollIfNeeded = () => {
    if (
      this.isAuthorOrCollaborator() &&
      !this.props.device.mobileDevice &&
      anyTopBannerPresent(this.getSpace())
    ) {
      this.hideHeroImage();
      this.setSticky();
    }
  };

  getSrollOffset() {
    if (this.props.device.mobileDevice) {
      return -64;
    } else if (isSpaceBannerContentPresent(this.getSpace())) {
      return (-1 * this.props.device.height) / 3;
    } else {
      return 0;
    }
  }

  hideHeroImage() {
    let offset = this.getSrollOffset();
    this.refs.scroll &&
      scrollToElement(this.refs.scroll, {
        ease: 'linear',
        offset,
        duration: 800,
      });
  }

  isAuthorOrCollaborator() {
    return this.props.data.board.is_author_or_collaborator;
  }

  isAuthorOrEditor() {
    return this.props.data.board.is_author_or_editor;
  }

  selectedFilter() {
    return this.props.match.params.filter;
  }

  isHeadSticky = (isHeadSticky) => {
    return this.setState({ isHeadSticky });
  };

  onSearchChange = (searchText) => {
    this.setState({ searchText });
  };

  onCardViewSelect = (cardViewType) => {
    this.setState({ cardViewType });
  };

  onFilterValueChange = (filterValues) => {
    this.setState({ filterValues });
  };

  getCardViewType() {
    return this.state.cardViewType;
  }

  onSortChange = (sortQuery) => {
    this.setState({ sortQuery });
  };

  getSearchAndFilterOptions() {
    return {
      onSearchChange: this.onSearchChange,
      onCardViewSelect: this.onCardViewSelect,
      cardViewType: this.getCardViewType(),
      onFilterValueChange: this.onFilterValueChange,
      searchText: this.state.searchText,
      filterValues: this.state.filterValues,
      onSortChange: this.onSortChange,
      sortQuery: this.state.sortQuery,
    };
  }

  isFolderSpaceWithListingTab() {
    return (
      isFolderSpace(this.getSpace()) && this.selectedTab().kind === 'content'
    );
  }

  renderSpaceNavListAndHeader() {
    let space = this.getSpace(),
      { mobileDevice } = this.props.device,
      stickExtraClass =
        'board-tab-style ' +
        (mobileDevice ? 'tab-sticky' : 'board-listing-section'),
      searchAndFilterRequired = this.isFolderSpaceWithListingTab();

    return (
      <SpaceNavTabs
        space={space}
        unreadDiscussionPresent={this.state.unreadDiscussionPresent}
        stickExtraClass={stickExtraClass}
        isHeadSticky={this.isHeadSticky}
        searchAndFilterRequired={searchAndFilterRequired}
        searchAndFilterOptions={this.getSearchAndFilterOptions()}
      />
    );
  }

  hideEditor = () => {
    this.setState({
      editor: {
        isVisible: false,
        type: 'patient',
      },
    });
  };

  redirectTo = (filter) => {
    let space = this.getSpace();
    if (this.isUrlFilter(filter))
      this.props.navigate(`/spaces/${space.nice_id}/${filter}`);
    else {
      if (filter == 'new task') {
        this.props.openTaskModalWithEntity(space);
      } else {
        if (
          filter === 'message_your_patient' ||
          filter === 'message_the_clinic'
        ) {
          this.setState({
            editor: {
              isVisible: true,
              type: 'patient',
            },
          });
        } else {
          this.setState({
            editor: {
              isVisible: true,
              type: 'team',
            },
          });
        }
      }
    }
  };

  isUrlFilter(filter) {
    let filterArray = [
      'activity/all',
      'files',
      'scheduled_posts',
      'patient_details',
      'related_tasks',
      'clinic_details',
    ];
    return filterArray.includes(filter);
  }

  getCount = (key) => {
    switch (key) {
      case 'activity/all':
        return isCareWorkspaceView()
          ? this.props.currentUser.graph.unread_notifications_count
          : this.props.data.board.user_unread_notifications_count;
      case 'files':
        return (
          this.props.data.board.nav_tabs &&
          this.props.data.board.nav_tabs[1].content_count
        );
      case 'scheduled_posts':
        return this.props.data.board.schedule_message_count;
      default:
        return 0;
    }
  };

  selectedTab() {
    return (
      contentAccessibleTabs(this.getSpace(), 'nav_tabs').find(
        (tab) => tab.path === this.selectedFilter(),
      ) || {}
    );
  }

  renderContent() {
    if (!this.isFolderSpaceWithListingTab() || this.state.sortQuery) {
      if (
        this.selectedFilter() ||
        !isMobileExpandedNavTabEnabled(this.getSpace())
      )
        return (
          <SpaceNavTabContent
            space={this.getSpace()}
            scrollIfNeeded={this.scrollIfNeeded}
            searchRequired={!isFolderSpace(this.getSpace())}
            searchText={this.state.searchText}
            cardViewType={this.state.cardViewType}
            filterValues={this.state.filterValues}
            sortQuery={this.state.sortQuery}
          />
        );
    }
  }

  spaceExtraInfoRequired() {
    return this.selectedFilter() === 'information';
  }

  sortingOptionBar() {
    return (
      <div className='sorting-options-bar'>
        <span className='sorting-option'>
          Name{' '}
          <OrthoIcon name='chevron-caret-down' dataHoverNotRequired={true} />
        </span>
        <span className='sorting-option'>
          Date Added{' '}
          <OrthoIcon name='chevron-caret-down' dataHoverNotRequired={true} />
        </span>
        <span className='sorting-option'>
          Date last used{' '}
          <OrthoIcon name='chevron-caret-down' dataHoverNotRequired={true} />
        </span>
      </div>
    );
  }

  renderLegacyHeader() {
    let space = this.getSpace(),
      lecturePreviewTabsAvailable = clvViewRequired(space);

    return (
      <>
        <View obj={this.props.data.board} extraClass='d-none' />
        {lecturePreviewTabsAvailable && (
          <div className='header-with-clv'>
            <div className='container-fluid'>
              <SpaceInformationView space={space} isGuestUser={false} />
            </div>
            <Header obj={this.props.data.board} isGuestUser={false} />
          </div>
        )}

        {!lecturePreviewTabsAvailable && (
          <div className='non-clv-header'>
            <Header obj={this.props.data.board} isGuestUser={false} />
            {isEmpty(space?.space_banner?.case) && (
              <div className='container-fluid xl-container mb-4 px-hd-40 px-sm-32 px-m-12 '>
                <SpaceInformationView
                  space={space}
                  isGuestUser={false}
                  hideEditor={this.hideEditor}
                  showEditor={this.state.editor.isVisible}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  goBack = () => {
    this.props.goBack();
  };

  renderTabPageHeader() {
    const space = this.getSpace();
    return (
      <div className='tab-page-header'>
        <OsBtn
          name='BtnIcon'
          text='back'
          onClick={this.goBack}
          extraClass='back-btn web-view-btn'
          icon='back'
        />
        <div className='folder-space-name'>
          {space.name}
          <div className='seleted-tab-name'>{this.selectedTab().name}</div>
        </div>
      </div>
    );
  }

  renderTopHeader() {
    if (
      !isMobileExpandedNavTabEnabled(this.getSpace()) ||
      !this.selectedFilter()
    ) {
      return this.renderLegacyHeader();
    } else if (this.selectedFilter()) {
      return this.renderTabPageHeader();
    }
  }

  renderChooseProfileModal() {
    if (isCareSpace(this.getSpace()))
      return <ChooseProfileModal space={this.getSpace()} />;
  }

  renderEditor() {
    return (
      <TipTapEditorForMobile
        editor={this.state.editor}
        entity={this.getSpace()}
        hideEditor={this.hideEditor}
      />
    );
  }

  renderSpaceNavListForMobile() {
    let navList = isCareWorkspaceView()
      ? SPACE_NAV_LIST_FOR_PATIENT_MOBILE
      : SPACE_NAV_LIST_FOR_TEAM_MOBILE;
    return (
      <div className='space-nav-list-mobile'>
        {navList.map((item) => {
          let count = this.getCount(item.filter);
          if (count === 0 && item.filter === 'scheduled_posts') return null;
          return (
            <div
              onClick={() => this.redirectTo(item.filter)}
              className='space-nav-list-item'>
              <OrthoIcon name={item.icon} />
              <span>{item.label}</span>
              {item.countRequired && count > 0 && <span>({count})</span>}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let content = this.authorize(),
      space = this.getSpace();
    if (content) return content;
    if (!space) return <div></div>;

    let parentClassName = 'detail detail-board px-0  ',
      sectionExtraClass = this.state.isHeadSticky ? 'hide-extra-action' : '';

    if (!this.props.device.mobileDevice)
      parentClassName += 'position-unset-imp ';
    if (!this.props.data.board.image) parentClassName += 'no-image ';
    if (isNavNotListRequired(this.getSpace()))
      parentClassName += ' course-unsubscribed ';

    parentClassName += this.selectedFilter() || '';
    if (isWorkspaceSpace(this.props.data.board)) {
      return <Navigate to='/' />;
    }

    if (
      space.user_request &&
      !space.user_request.authenticated &&
      isCareSpace(space)
    )
      return <Navigate to={lockedSpacePath(space)} />;

    let ownerName;
    if (space?.owner?.patient?.name)
      ownerName = `${space?.owner?.patient?.name}'s Space`;
    else if (space?.display_name) ownerName = space?.display_name;

    return (
      <>
        <SetPageTitle source={'patient_carespace'} interpolations={ownerName} />
        <CommentableView entity={this.props.data.board}>
          <div className={parentClassName}>
            {this.renderTopHeader()}
            {this.renderChooseProfileModal()}
            <section
              className={`sticky-tab-with-dropdown tab-min-height ${sectionExtraClass}`}
              ref='scroll'>
              {this.props.device.mobile && isCareSpace(space)
                ? null
                : this.renderSpaceNavListAndHeader()}
              {this.props.device.mobile &&
                isCareSpace(space) &&
                this.selectedFilter() === undefined &&
                !this.state.editor.isVisible &&
                this.renderSpaceNavListForMobile()}
              {this.renderContent()}
              {this.state.editor.isVisible && this.renderEditor()}
            </section>
          </div>
        </CommentableView>
      </>
    );
  }
}

BoardView = connect(({ currentUser, device }) => ({ currentUser, device }), {
  addGuardianInPriorityGroup,
  addUniversalBanner,
  removeUniversalBanner,
  editContactParty,
  followObject,
  openInfoModal,
  openPatientModal,
  openTaskModalWithEntity,
  openPoliciesModal,
  setActiveResource,
  updateBannerValues,
})(BoardView);
BoardView = compose(
  ...keys(SPACE_QUERIES).map((path) =>
    withGraphqlRequests(
      SPACE_QUERIES[path],
      {
        skip: (props) =>
          !props.location.pathname.startsWith(withWorkspaceIdentifier(path)),
        options: (props) => ({
          fetchPolicy: 'network-only',
          variables: {
            id: props.match.params.id,
          },
        }),
      },
      {
        objectIdentifier: 'board',
      },
    ),
  ),
)(BoardView);
BoardView = withSpaceRestriction(BoardView);
BoardView = withRouter(BoardView);

export default BoardView;
