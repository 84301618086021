import { Command } from 'cmdk';
import DisplayHeading from 'app/components/DnD/CmdMenu/DisplayHeading';
import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import React from 'react';
import { useQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { getWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import DisplayMetricCard from 'app/components/DnD/CmdMenu/DisplayMetricCard';
import { isEmpty } from 'app/utils/osLodash';

const GET_RECENTS = queryLoader(
  'app/graphql/queries/CmdMenu/RecentMenuItem.gql',
);

const RecentCmdMenu = (props) => {
  const { recentCommands, handleNavigation, inputValue } = props;
  const { data, loading, error } = useQuery(GET_RECENTS, {
    variables: { id: getWorkspaceIdentifier() },
    fetchPolicy: 'network-only',
  });

  const renderInsightsSection = () => {
    return (
      <Command.Group
        heading={<DisplayHeading heading={'insights'} iconName={'insight'} />}>
        <div className='metric-card-holder'>
          <DisplayMetricCard
            cardHeading={'Tasks completed streak'}
            metric={data?.workspace?.tasks_completed_streak_count || 0}
            unit={'days'}
          />
          <DisplayMetricCard
            cardHeading={'Messages sent this week'}
            cardKey={'messages'}
            lastWeek={data?.workspace?.message_sent_in_last_week_by_user || 0}
            metric={data?.workspace?.messages_sent_in_current_week_by_user || 0}
          />
          <DisplayMetricCard
            cardHeading={'Tasks completed this week'}
            cardKey={'tasks'}
            totalOverdueTasks={data?.workspace?.total_overdue_tasks_count || 0}
            taskToComplete={
              data?.workspace?.total_tasks_assigned_in_this_week -
                data?.workspace?.tasks_completed_in_this_week_by_user || 0
            }
            metric={data?.workspace?.tasks_completed_in_this_week_by_user || 0}
          />
        </div>
      </Command.Group>
    );
  };

  return (
    <>
      <Command.List>
        {renderInsightsSection()}

        {!isEmpty(recentCommands) && !inputValue && (
          <>
            <Command.Group
              heading={
                <DisplayHeading heading={'recents'} iconName={'clock'} />
              }>
              {recentCommands.map((commandObject) => {
                return (
                  <Command.Item
                    key={commandObject.command + '_recent'}
                    value={commandObject.command + '_recent'}
                    onSelect={(selectedOption) =>
                      handleNavigation(selectedOption.split('_')[0])
                    }>
                    <DisplayOption
                      iconRequired={false}
                      label={commandObject.command}
                      action={commandObject.action ? 'command' : 'shortcut'}
                    />
                  </Command.Item>
                );
              })}
            </Command.Group>
            <Command.Separator
              className={'input-option-separator'}
              alwaysRender={true}
            />
          </>
        )}
      </Command.List>
    </>
  );
};

export default RecentCmdMenu;
