export const setKeyValueInLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

export const getKeyValueFromLocalStorage = (key) => {
  return window.localStorage.getItem(key)
}

export const removeKeyFromLocalStorage = (key) => {
  localStorage.removeItem(key);
}
