import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import RedirectToDefault from 'app/components/RedirectToDefault';
import RecordsView from 'app/components/RecordsView';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import EventTracker from 'app/services/EventTracker';

class UserView extends PureComponent {
  constructor(props) {
    super(props);
    this.resourceKey = 'user';
  }

  componentDidMount() {
    EventTracker.track('Navigate', {
      page: 'Notifications',
      id: this.props.currentUser.graph.id,
    });
  }

  isCurrentUser() {
    return this.props.match.params.id === this.props.currentUser.graph.nice_id;
  }

  renderBreadCrumbs() {
    let { graph } = this.props.currentUser;
    if (graph) {
      let text =
        graph.care_spaces_count > 1
          ? 'PatientSpaces'
          : 'PatientSpace';
      return (
        <div className='breadcrumb-header'>
          <OsLink to='/' text={text} className='a-link' />
          <OrthoIcon name='chevron-right' />
          <span>Notifications</span>
        </div>
      );
    } else {
      return <div className='breadcrumb-header'>Notifications</div>;
    }
  }

  render() {
    if (!this.isCurrentUser()) return <RedirectToDefault />;
    return (
      <div className='container-xl px-52 '>
        {this.renderBreadCrumbs()}
        <RecordsView type='Notification' perPage={25} />
      </div>
    );
  }
}

UserView = withRouter(UserView);
UserView = connect(({ currentUser }) => ({ currentUser }), {})(UserView);

export default UserView;
