import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';
import { setWorkspaceData } from 'app/actions/workspace';

const WORKSPACE_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/WorkspaceSubscription.gql',
);

const WorkspaceSubscription = ({ workspaceId, updateSubscriptionData }) => {
  const dispatch = useDispatch();
  const { data: subscriptionData, loading } = useSubscription(
    WORKSPACE_SUBSCRIPTION,
    {
      variables: { workspaceId: workspaceId },
    },
  );

  useEffect(() => {
    if (!loading && subscriptionData) {
      const { workspace_entity_update_subscription } = subscriptionData;
      if (workspace_entity_update_subscription?.entity) {
        let workspaceData = { ...getWorkspaceData() };
        let dataEntity = workspace_entity_update_subscription.entity;

        if((+dataEntity.id === +workspaceData.id) && dataEntity.__typename === 'Board') {
          workspaceData = {
            ...workspaceData,
            ...workspace_entity_update_subscription.entity,
          };
          dispatch(setWorkspaceData(workspaceData));
          updateSubscriptionData(workspace_entity_update_subscription?.entity);
        }
      }
    }
  }, [subscriptionData, loading]);
};

export default WorkspaceSubscription;
