import DisplayOption from 'app/components/DnD/CmdMenu/DisplayOption';
import { DIRECT_CONVERSATION_NAVIGATION } from 'app/components/DnD/CmdMenu/constants';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { Command } from 'cmdk';
import { useContext } from 'react';

const ConversationCmdMenu = (props) => {
  const { handleNavigation } = props;

  const { all_members: members } = useContext(WorkspaceContext);

  const handleConversationNavigation = (conversationId, typename) => {
    handleNavigation(DIRECT_CONVERSATION_NAVIGATION, {
      conversationId,
      typename,
    });
  };

  return (
    <Command.List>
      {members.map((member) => {
        return (
          <Command.Item
            className={'command-item'}
            key={member.id}
            value={member.full_name}
            onSelect={() =>
              handleConversationNavigation(member.nice_id, member.__typename)
            }>
            <DisplayOption
              avatarRequired={true}
              label={member.full_name}
              member={member}
            />
          </Command.Item>
        );
      })}
    </Command.List>
  );
};

export default ConversationCmdMenu;
