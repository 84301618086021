import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import Loader from 'react-loaders';
import scrollToElement from 'scroll-to-element';
import withAuthenticate from 'app/components/HOC/withAuthenticate';

import AttachmentPreview from 'app/components/AttachmentPreview';
import CareSpaceClvRightView from './CareSpaceClvRightView';
import CaseAppointmentFooter from 'app/components/CaseView/CaseAppointmentFooter';
import CaseLeftSection from 'app/components/CaseLeftSection';
import CasePatientInfo from 'app/components/CaseView/CasePatientInfo';
import CourseLectureNotAvailableInfo from 'app/components/SpaceTabs/CourseLectureNotAvailableInfo';
import CaseRightSectionContainer from 'app/components/CaseRightSection/Container';
import CustomErrorFileView from 'app/components/CustomErrorFileView';
import Icon from 'app/components/Svg';
import LectureViewHeaderListing from './LectureViewHeaderListing';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import SpaceActions from './SpaceActions';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import VideoPlayer from 'app/components/VideoPlayer';

import { viewObject } from 'app/actions/viewObject';
import { openAttachmentPreview } from 'app/actions/attachmentPreview';
import { setLectureEntity } from 'app/actions/lectureView';
import { openInfoModal } from 'app/actions/infoModal';

import { translate } from 'app/actions/flashMessage';

import transGif from 'app/images/trans-gif-1440x744.gif';
import PermissonDenied from 'app/images/permission-denied.png';

import { addProtocolIfNotPresent } from 'app/utils/urlHelper';
import { has, isEmpty, isEqual, isNull } from 'app/utils/osLodash';
import { isImage, isVideo, isViewable, isZoomable } from 'app/utils/fileHelper';
import {
  displayEntityName,
  entityIsAdditionalLinkOrAttachment,
  entityIsAdditionalLink,
  entityIsAttachment,
  entityIsCase,
  entityUrl,
  getEntityName,
} from 'app/utils/entitiesHelper';
import { isCareSpace, isLecturesAvailable } from 'app/utils/spaceHelper';
import { isPrivate } from 'app/utils/caseHelper';

const SPOTIFY_PODCAST_REGEX = /((open|play)\.spotify\.com\/)/;

class DisplayLectureContent extends Component {
  state = {
    iFrameLoaded: {},
    totalCollection: null,
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.lectureView.entity, this.props.lectureView.entity) &&
      !isNull(prevProps.lectureView.entity) &&
      this.isReaderViewPrimaryCTAAvailable()
    ) {
      this.openAttachmentPreview();
    }

    if (
      this.props.lectureView.entity &&
      this.clvContainer &&
      !isEqual(prevProps.lectureView.entity, this.props.lectureView.entity)
    ) {
      scrollToElement(this.clvContainer, {
        ease: 'linear',
        offset: -2000,
        duration: 1000,
      });
    }
  }

  componentWillUnmount() {
    this.props.setLectureEntity(null);
  }

  getEntity() {
    return (
      this.getLectureObject() &&
      (this.getLectureObject().linkable || this.getLectureObject())
    );
  }

  getLectureObject() {
    return this.props.lectureView.entity;
  }

  renderVideoPlayer() {
    let previewObj = this.getEntity(),
      url = previewObj.url;
    return (
      <VideoPlayer
        key={previewObj.id}
        video={{
          url: url,
          containerClassName: 'full-width',
          autoplay: previewObj.autoplay,
          nodownload: true,
          unmuted: true,
          playIconRequired: true,
        }}
      />
    );
  }

  openCase = () => {
    this.props.navigate(entityUrl(this.getEntity()));
  };

  hasKeyInIframeLoadedHash() {
    return has(this.state.iFrameLoaded, this.getEntity().id);
  }

  checkIframeLoaded = (link, loaded) => {
    let { iFrameLoaded } = this.state;
    iFrameLoaded[link.id] = loaded;
    loaded && this.viewObject();
    this.setState({ iFrameLoaded });
  };

  isPreviewObjectDocument() {
    return entityIsAttachment(this.getEntity());
  }

  previewObjPresent() {
    return !isEmpty(this.getEntity());
  }

  isObjectViewable() {
    let previewObj = this.getEntity();
    return (
      this.previewObjPresent() &&
      this.isPreviewObjectDocument() &&
      isViewable(previewObj)
    );
  }

  viewObject = () => {
    if (!isEmpty(this.getEntity())) {
      let link_obj = {
        id: this.getLectureObject().id,
        __typename: this.getLectureObject().__typename,
        view_count: 0,
      };
      this.props.viewObject({ obj: link_obj });
    }
  };

  linkLoadedInIframe() {
    return this.state.iFrameLoaded[this.getEntity().id];
  }

  linkCanBeLoadedInIframe() {
    return (
      this.isALink() &&
      (!this.hasKeyInIframeLoadedHash() || this.linkLoadedInIframe())
    );
  }

  renderViewCaseButton() {
    return (
      <OsBtn
        name='BtnPrimary'
        text='View Case'
        extraClass='mt-3'
        onClick={this.openCase}
        associatedEntity={this.getEntity()}
      />
    );
  }

  isALink() {
    return entityIsAdditionalLink(this.getEntity());
  }

  renderAttachmentPreviewContainer() {
    let previewObj = this.getEntity();
    return (
      <AttachmentPreview
        autoplayNotRequired={!previewObj.autoplay}
        unmuted={true}
        checkIframeLoaded={this.checkIframeLoaded}
        key={previewObj.id}
        objects={[previewObj]}
        playIconRequired={true}
      />
    );
  }

  openLink = (obj) => {
    let url = obj.url;
    this.viewObject();
    window.open(addProtocolIfNotPresent(url), '_blank');
  };

  isViewEntityButtonRequired() {
    return (
      this.previewObjPresent() &&
      !entityIsAdditionalLinkOrAttachment(this.getEntity())
    );
  }

  redirectToEntity = () => {
    this.props.navigate(entityUrl(this.getEntity()));
  };

  redirectToEntityIfAllowed = () => {
    this.props.redirectToDefaultIfUnauthenticated(this.redirectToEntity);
  };

  renderOpenLinkButton(type = 'link') {
    let previewObj = this.getEntity();
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <OsBtn
          name='BtnPrimary'
          text={'Open ' + type}
          extraClass='mt-3'
          onClick={this.openLink.bind(this, previewObj)}
          associatedEntity={previewObj}
        />
        <p className='mt-3 text-center fs-14 col-9'>
          {translate(
            type === 'video'
              ? 'SOMETHING_WENT_WRONG_WHILE_PLAYING_VIDEO'
              : 'SOMETHING_WENT_WRONG_WHILE_OPENING_LINK',
          )}
        </p>
      </div>
    );
  }

  isReaderViewPrimaryCTAAvailable() {
    let { desktop, ipadLandscape } = this.props.device;
    return this.isReaderViewAvailable() && !desktop && !ipadLandscape;
  }

  isReaderViewAvailable() {
    return this.isDocument() && isZoomable(this.getEntity());
  }

  renderReaderViewButton() {
    return (
      <div className='btn-view-only'>
        <OsBtn
          name='BtnPrimary'
          text='View File'
          extraClass='mt-3'
          onClick={this.openAttachmentPreview}
          associatedEntity={this.getEntity()}
        />
      </div>
    );
  }

  isDocument() {
    let previewObj = this.getEntity();
    return (
      this.previewObjPresent() &&
      this.isPreviewObjectDocument() &&
      !isImage(previewObj) &&
      !isVideo(previewObj)
    );
  }

  openAttachmentPreview = () => {
    let { source, author } = this.getEntity();

    this.props.openAttachmentPreview(
      source,
      [this.getEntity()],
      this.getEntity(),
      author,
      { readerView: true },
    );
  };

  renderViewEntityButton() {
    let obj = this.getEntity();
    return (
      <div className='btn-view-only'>
        <OsBtn
          name='BtnPrimary'
          text={`View ${displayEntityName(obj)}`}
          extraClass='mt-3'
          onClick={this.redirectToEntityIfAllowed}
          associatedEntity={this.state.previewObj}
        />
      </div>
    );
  }

  renderNoContent() {
    return (
      <div className='col-12 p-0'>
        <div className='no-preview-text text-center'>
          <Icon name='cannotOpen' />
          <br />
          <p className='pt-3'>No Items Present</p>
        </div>
      </div>
    );
  }

  isIntroductoryVideo() {
    return this.getEntity().__typename === 'IntroductoryVideo';
  }

  renderContent() {
    let previewObj = this.getEntity();
    if (!previewObj) {
      return this.isCareSpace() ? '' : this.renderNoPermissionView();
    } else if (this.isIntroductoryVideo()) {
      return this.renderVideoPlayer();
    } else if (this.isReaderViewPrimaryCTAAvailable()) {
      return this.renderReaderViewButton();
    } else if (this.isALink() && previewObj.playable) {
      return this.renderVideoPlayer();
    } else if (
      this.isALink() ? this.linkCanBeLoadedInIframe() : this.isObjectViewable()
    ) {
      return this.renderAttachmentPreviewContainer();
    } else if (this.isALink()) {
      return this.renderOpenLinkButton();
    } else if (entityIsCase(previewObj)) {
      return (
        <CaseLeftSection
          kase={previewObj}
          careSpaceView={isCareSpace(this.props.space)}
          key={this.props.lectureView?.metaInfo?.toggleKey}
        />
      );
    } else if (this.isViewEntityButtonRequired()) {
      return this.renderViewEntityButton();
    } else if (this.previewObjPresent()) {
      return (
        <CustomErrorFileView
          source='LecturePreview'
          previewObj={this.getLectureObject()}
          viewObjectRequired={true}
        />
      );
    }
  }

  setCollectionTotal = (total) => {
    this.setState({ totalCollection: total });
  };

  noContent() {
    return this.state.totalCollection === 0;
  }

  renderLectureListing() {
    const key = this.courseSubscribed() ? 'subscribed' : 'not-subscribed';
    let extraClass =
      !this.noContent() &&
      this.props.lectureView.rightSectionVisible &&
      (this.props.space.lecture_preview_tabs || []).length > 0
        ? ''
        : 'd-none';
    return (
      <div className={`col-md-4 pe-0 ${extraClass}`} key={key}>
        <LectureViewHeaderListing
          space={this.props.space}
          setCollectionTotal={this.setCollectionTotal}
        />
      </div>
    );
  }

  renderCaseAuthorInfo(kase) {
    let { doctor } = kase;
    return (
      <>
        <span className='visible-mobile or'>|</span>
        <span className=' do cate fs-12'>
          <UserQualificationInfo
            titleRequired={true}
            user={doctor}
            sliceUpto={2}
            linkRequired={true}
            osLinkClass=''
            className='mx-50'
          />
        </span>
      </>
    );
  }

  renderPatientInformation() {
    let { owner: kase } = this.props.space;
    if (isCareSpace(this.props.space)) {
      return (
        <div className='patient-name'>
          {kase.patient.first_name} {kase.patient.last_name}
        </div>
      );
    } else {
      return (
        <>
          <div className='patient-name'>
            {kase.patient.first_name} {kase.patient.last_name} -{' '}
          </div>
          <CasePatientInfo kase={kase} extraClass='d-inline os-text' />
        </>
      );
    }
  }

  renderCareSpaceDetails() {
    let { owner: kase } = this.props.space;
    return (
      <OsGrid identifier='SpaceClvCase:PatientInfo'>
        <div className='cs-user-info'>{this.renderPatientInformation()}</div>
        <div className='cs-id-user-name'>
          <span className='cs-id os-btn-link'>{`${
            isPrivate(kase) ? 'Private ' : ''
          }Case ${kase.nice_id}`}</span>
          {this.renderCaseAuthorInfo(kase)}
        </div>
        <SpaceActions space={this.props.space} />
      </OsGrid>
    );
  }

  isCareSpace() {
    return isCareSpace(this.props.space);
  }

  renderContentWithName() {
    const careSpaceHeaderRequired = this.isCareSpace(),
      entity = this.getEntity();
    return (
      <>
        {careSpaceHeaderRequired && this.renderCareSpaceDetails()}
        {entity && !entityIsCase(entity) && (
          <div className='clv-attachement-title'>{getEntityName(entity)}</div>
        )}
        {this.renderContent()}
      </>
    );
  }

  renderNoPermissionView() {
    return (
      <div className='permission-denied-container'>
        <img src={PermissonDenied} alt='' className='permission-denied-image' />
        <p className='text-center'>You don’t have access to this space yet.</p>
      </div>
    );
  }

  renderLeftSectionContent(entity) {
    let { space } = this.props;
    if (isLecturesAvailable(space)) {
      return this.renderContentWithName();
    } else {
      return <CourseLectureNotAvailableInfo space={space} />;
    }
  }

  renderLeftSection() {
    const { entity, loading } = this.props.lectureView;
    let wrapperClass = 'col-md-8 ps-0 clv-left-sec';

    if (loading) {
      return (
        <div className={wrapperClass}>
          <Loader type='ball-triangle-path' active />
        </div>
      );
    } else {
      return (
        <div className={wrapperClass}>
          {this.renderLeftSectionContent(entity)}
        </div>
      );
    }
  }

  getSpace() {
    return this.props.space;
  }

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  returnCareSpaceRightSection() {
    let { space } = this.props;
    return <CareSpaceClvRightView space={space} />;
  }

  renderRightSectionForOtherSpaces() {
    let clvRightSectionClass =
      'col-md-4 clv-right-section pe-0 withtab-slider ';

    clvRightSectionClass += this.props.lectureView.rightSectionVisible
      ? 'clv-slide-0 '
      : 'clv-slide-1 ';

    return (
      <article className={clvRightSectionClass}>
        <div className='clv-right-slide'>
          {this.renderLectureListing()}
          <div className='clv-right-tabs-section'>
            {this.getEntity() && entityIsCase(this.getEntity()) && (
              <CaseRightSectionContainer
                key={this.getEntity()?.id}
                kase={this.getEntity()}
                visible={!this.props.lectureView.rightSectionVisible}
              />
            )}
          </div>
        </div>
      </article>
    );
  }

  renderRightSection() {
    return isCareSpace(this.props.space)
      ? this.returnCareSpaceRightSection()
      : this.renderRightSectionForOtherSpaces();
  }

  renderFooter() {
    const { ipadPortrait } = this.props.device;

    if (!ipadPortrait) {
      let kase = this.getEntity();
      return (
        <CaseAppointmentFooter
          kase={kase}
          key={kase.appointments.length}
          careSpaceView={isCareSpace(this.props.space)}
        />
      );
    }
  }

  renderBody() {
    let parentRowClass = `${
        this.state.hideTabListing ? '' : 'no-margin clv-row row'
      }`,
      previewObj = this.getEntity(),
      isCase = previewObj && entityIsCase(previewObj);

    parentRowClass += isCase
      ? ' with-clv case-detail-height cd-detail-conatiner new-cs-card case'
      : ' lecture';

    if (this.noContent()) {
      return (
        <div className={parentRowClass}>
          {this.renderNoContent()}
          {this.renderLectureListing()}
        </div>
      );
    } else {
      return (
        <div
          className={parentRowClass}
          ref={(container) => (this.clvContainer = container)}
          id='space-clv'>
          {this.renderLeftSection()}
          {this.renderRightSection()}
          {isCase && this.renderFooter()}
          {isCase &&
            !this.props.device.mobileDevice &&
            !this.props.device.ipadPortrait && (
              <span className='footer-not-available'></span>
            )}
        </div>
      );
    }
  }

  render() {
    let aspectRatioClass = this.state.hideTabListing ? '' : 'clv-case-ratio ';
    aspectRatioClass += this.courseSubscribed()
      ? 'clv-subscribed'
      : 'clv-non-subscribed';
    aspectRatioClass += isCareSpace(this.props.space) ? ' care-clv' : '';

    return (
      <article className={aspectRatioClass}>
        <img src={transGif} className='clvtransgif' alt='' />
        {this.renderBody()}
      </article>
    );
  }
}

DisplayLectureContent = connect(
  ({ device, lectureView }) => ({ device, lectureView }),
  { openAttachmentPreview, openInfoModal, setLectureEntity, viewObject },
)(DisplayLectureContent);
DisplayLectureContent = withAuthenticate(DisplayLectureContent);
DisplayLectureContent = withRouter(DisplayLectureContent);
export default DisplayLectureContent;
