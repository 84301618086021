import React, { Component } from 'react';
import Loader from 'react-loaders';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';

import CommentsListing from 'app/components/CommentSection/CommentsListing';
import ContentWithSidebars from 'app/components/ContentWithSidebars';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';

import { getSpaceTabSource } from 'app/utils/spaceHelper';
import { getWorkspaceData } from 'app/utils/Workspace/generalHelper';

const COMMENT_QUERY = queryLoader('app/graphql/queries/Comment.gql');

class CommentView extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.data.loading &&
      !nextProps.data.loading &&
      !nextProps.data.comment
    )
      this.props.navigate('/not_found', { replace: true });
  }

  repliesThreadRequired() {
    return this.props.match.params.id !== this.props.data.comment.id;
  }

  aroundId() {
    return this.repliesThreadRequired() ? this.props.match.params.id : null;
  }

  metaInfo() {
    return this.repliesThreadRequired()
      ? { parent_comment_id: this.props.data.comment.id }
      : {};
  }

  commentable() {
    return getWorkspaceData();
  }

  comments() {
    return [this.props.data.comment];
  }

  commentsListingProps() {
    let commentable = this.commentable();
    return {
      aroundId: this.aroundId(),
      commentable: commentable,
      commentableId: commentable.id,
      commentableType: commentable.__typename,
      entity: commentable,
      metaInfo: this.metaInfo(),
      results: this.comments(),
      source: 'comment-detail-view',
      scrollToCommentId: this.aroundId(),
    };
  }

  renderCommentsListing() {
    return <CommentsListing {...this.commentsListingProps()} />;
  }

  render() {
    if (!this.props.data.comment)
      return <Loader type='ball-triangle-path' active />;

    const commentable = this.commentable();
    return (
      <div className='container detail'>
        <ContentWithSidebars
          childGridIdentifier='BoardDetail:Timeline'
          extraClass='board-dis-sec'
          isAuthorOrCollaborator={commentable.is_author_or_collaborator}
          options={{ widgetSourceObject: commentable }}
          parentClass='activity-container'
          rightSidebarGridIndentifier='BoardDetail:TimelineRightSidebar'
          source={getSpaceTabSource(commentable, 'information')}
          widgetsRequiredOnMobile={true}>
          <div className='discussion-thread-section'>
            {this.renderCommentsListing()}
          </div>
        </ContentWithSidebars>
      </div>
    );
  }
}

CommentView = withGraphqlRequests(
  COMMENT_QUERY,
  {
    options: (props) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: props.match?.params.id,
        },
      };
    },
  },
  {
    objectIdentifier: 'comment',
  },
)(CommentView);
CommentView = withRouter(CommentView);

export default CommentView;
