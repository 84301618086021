import {
  isWorkspaceAccessible,
  getWorkspaceData,
} from './Workspace/generalHelper';

export function isOrthodontistUser(user) {
  return user.current_role === 'doctor';
}

export function isResidentUser(user) {
  return user.current_role === 'student_doctor';
}

export function userDefaultPage(user, systemConfig = {}) {
  return isWorkspaceAccessible()
    ? `/${getWorkspaceData().identifier}/explore`
    : '/';
}
