import { Component } from 'react';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { isEqual, map, merge } from 'app/utils/osLodash';
import {
  entityEditUrl,
  findFirstPrivateObject,
} from 'app/utils/entitiesHelper';
import {
  addAttachmentBannerKey,
  iCompanyTypeSpace,
} from 'app/utils/spaceHelper';

const UPDATE_BOARD_MUTATION = queryLoader('app/graphql/UpdateBoard.gql');

class AbstractListing extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      this.state.assigningObject &&
      this.props.commentShare.status === 'open' &&
      nextProps.commentShare.status === 'closed'
    ) {
      this.setState({ assigningObject: false });
    }

    if (
      this.state.assigningObject &&
      !isEqual(this.props.commentShare.cards, nextProps.commentShare.cards)
    ) {
      let files = nextProps.commentShare.cards.files,
        objects = nextProps.commentShare.cards.objects;

      if (files.length + objects.length > 0) {
        this.sendRequestToAddLinks(files, objects);
        this.showWarningIfRequired(objects);
      } else {
        this.props.commentShareConsume();
      }
    }
  }

  showWarningIfRequired(objects) {
    let privateObject = findFirstPrivateObject(objects);
    if (
      this.props.currentUser.graph.show_private_content_warning &&
      privateObject
    ) {
      this.props.commentShareConsume();
      this.props.openInfoModal('space', 'private_content_added', {
        contentInterpolations: {
          entity_type: privateObject.__typename.toLowerCase(),
        },
      });
    }
  }

  reloadListIfRequired() {
    this.props.reloadRecords && this.props.reloadRecords();
  }

  sendRequestToAddLinks = (files, objects) => {
    let fileIds = map(files, (file) => 'Attachment:' + file.id),
      objectIds = map(objects, (object) => object.__typename + ':' + object.id),
      newLinks = fileIds.concat(objectIds);

    client
      .mutate({
        mutation: UPDATE_BOARD_MUTATION,
        variables: {
          id: this.getSpace().nice_id,
          new_links: newLinks,
          tab_section_id: this.props.tabSection?.id,
          searchQuery: this.props.searchQuery,
          additional_filters: JSON.stringify(
            this.props.additional_filters || {},
          ),
        },
      })
      .then(({ data }) => {
        if (data.updateBoard.errors.length > 0)
          this.props.navigate(entityEditUrl(this.getSpace()));
        this.props.commentShareConsume();
        this.reloadListIfRequired();
      });
  };

  getSpace() {
    return this.props.board || this.props.space || this.getEntity() || {};
  }

  getEntity() {
    return this.props.entity;
  }

  addNewLink = (type = 'file') => {
    let space = this.getSpace(),
      options = {
        excludeLinksOfTabId: this.props.navTabId,
        customRequestOnClose: true,
        only_published: false,
        bannerKey: addAttachmentBannerKey(space),
      };

    if (this.props.addNewLinkOptions)
      merge(options, this.props.addNewLinkOptions);

    if (this.getAddNewTabsNotRequired)
      options['tabsNotRequired'] = this.getAddNewTabsNotRequired();

    if (iCompanyTypeSpace(space))
      options['object_filter'] = `company:${space.owner.id}`;

    this.setState({ assigningObject: true });
    this.props.commentShareOpen(type, 'Space', [], null, options);
  };
}

export default AbstractListing;
