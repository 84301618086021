import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import AcceptRequest from 'app/components/SpaceRequests/AcceptRequest';
import Avatar from 'app/components/Shared/Avatar';
import DeclineRequest from 'app/components/SpaceRequests/DeclineRequest';
import Icon from 'app/components/Svg';
import OsField from 'app/components/OsField';
import OsLink from 'app/components/OsLink';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';

import { avatarAttributes, entityUrl } from 'app/utils/entitiesHelper';
import {
  isAdminOrPartOfSpace,
  isCurrentUserOwnedSpace,
  isOpenSpace,
  isTeamFolderSpace,
} from 'app/utils/spaceHelper';
import {
  timeDifferenceFromNowInMilliseconds,
  timeWithFormat,
} from 'app/utils/timeHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { translate } from 'app/actions/flashMessage';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

const CHANGE_SPACE_COLLABORATOR_TYPE = queryLoader(
  'app/graphql/mutations/ChangeSpaceCollaboratorType.gql',
);
const DATE_TIME_FORMAT = 'ddd, DD MMM YYYY hh:mm A';

class SpaceMembersView extends Component {
  renderEmailInfo(request, mobileWidth) {
    let avatarClass =
        this.props.device.ipadPortrait || mobileWidth
          ? 'avatar-24'
          : 'avatar-40',
      email = request.email,
      emailObject = {
        avatarIcon: 'user',
        name: email,
        __typename: 'User',
      };

    return (
      <aside className='detail-block invite-block'>
        <div className='invite-block-group d-flex align-items-center'>
          <Avatar
            className={`avatar ${avatarClass}`}
            {...avatarAttributes(emailObject)}
          />
          <div className='sp-user-name text-truncate'>{email}</div>
          {mobileWidth && (
            <div className='invite-status'>{this.getStatus(request)}</div>
          )}
        </div>
      </aside>
    );
  }

  renderMemberInfo(request, mobileWidth) {
    let avatarClass =
        this.props.device.ipadPortrait || mobileWidth
          ? 'avatar-24'
          : 'avatar-40',
      member = request.collaborator,
      userMember = member.__typename === 'User';

    return (
      <aside className='detail-block invite-block'>
        <div className='invite-block-group d-flex align-items-center'>
          <OsLink
            to={entityUrl(member, false, { skipDomainCheck: true })}
            name={member.name}
            className='mh-40'>
            <Avatar
              className={`avatar ${avatarClass}`}
              {...avatarAttributes(member, { entityImage: true })}
            />
          </OsLink>
          <div className='sp-user-name'>
            {userMember && (
              <UserQualificationInfo
                user={member}
                linkRequired={true}
                sliceUpto={2}
                titleRequired={true}
                className='span-helper text-truncate'
              />
            )}
            {!userMember && (
              <OsLink
                to={entityUrl(member, false, { skipDomainCheck: true })}
                text={member.name}
                className='mh-40 hover-underline white-link '
              />
            )}
          </div>
          {mobileWidth && (
            <div className='invite-status'>{this.getStatus(request)}</div>
          )}
        </div>
      </aside>
    );
  }

  getStatus(request) {
    if (request.status === 'pending') {
      if (this.isJoinSpaceRequest(request)) {
        return 'Request to join';
      } else {
        return 'Pending invitation';
      }
    } else if (this.isRequestAccepted(request)) {
      return 'Joined';
    }
  }

  getTimeGap(lastTime) {
    let timeDiff = timeDifferenceFromNowInMilliseconds(lastTime) / 60000;
    if (timeDiff <= 1) {
      return (
        <span>
          <OrthoIcon name='checkmark-dot' defaultClass='lt-green me-1' />
          Online
        </span>
      );
    } else {
      return timeWithFormat(lastTime, DATE_TIME_FORMAT);
    }
  }

  renderDateInfo(request, mobileWidth) {
    return (
      !mobileWidth && (
        <>
          <div className='detail-block status-block'>
            {this.getStatus(request)}
          </div>
          <div className='detail-block date-block'>
            {request.user?.last_active_at
              ? this.getTimeGap(request.user?.last_active_at)
              : 'Never'}
          </div>
        </>
      )
    );
  }

  isJoinSpaceRequest(request) {
    return request.user && request.invited_by_id === +request.user.id;
  }

  isRequestAccepted(request) {
    return request.status === 'accepted';
  }

  isRequestPending(request) {
    return request.status === 'pending';
  }

  renderDeclineRequest(request, props) {
    let editorRequest = request.kind === 'editor',
      adminRequest = request.kind === 'admin';

    if (this.isGroupListing() && isOpenSpace(this.props.space)) return;

    if (
      !adminRequest &&
      (!editorRequest || isCurrentUserOwnedSpace(this.props.space))
    )
      return <DeclineRequest {...props} />;
  }

  renderActionButtons(request) {
    let extraProps = {},
      btnCommonProps = {
        space: this.props.space,
        requestingEmail: request.email,
        requestingUser: request.user,
        onlyIconRequired: this.props.device.mobileDevice,
      };
    if (this.props.device.mobileDevice) {
      extraProps.extraClass = 'px-8 no-text web-view-btn';
      extraProps.icon = 'delete';
    } else {
      extraProps.extraClass = ' px-16 no-icon ';
      extraProps.icon = '';
    }

    btnCommonProps.extraClass = extraProps.extraClass;

    if (this.isRequestAccepted(request)) {
      let removeAccessText =
        this.isGroupListing() && this.isCurrentUserRequest(request)
          ? 'Leave Group'
          : 'Remove Access';
      let props = { ...btnCommonProps, text: removeAccessText, ...extraProps };
      return <>{this.renderDeclineRequest(request, props)}</>;
    } else if (this.isJoinSpaceRequest(request)) {
      return (
        <>
          <AcceptRequest {...btnCommonProps} btnType='BtnSecondary' />
          {this.renderDeclineRequest(request, btnCommonProps)}
        </>
      );
    } else {
      let btnText = this.props.device.ipadPortrait ? 'cancel' : 'cancel invite';
      let props = { ...btnCommonProps, text: btnText, ...extraProps };
      return (
        <>
          {request.user &&
            !this.isRequestPending(request) &&
            this.renderMessageLinkOrIcon(request.user)}
          {this.renderDeclineRequest(request, props)}
        </>
      );
    }
  }

  isGroupListing() {
    return this.props.displayType === 'GROUP_MEMBERS';
  }

  shouldRenderActions() {
    if (this.isGroupListing() && !isAdminOrPartOfSpace(this.props.space)) {
      return false;
    }
    if (isTeamFolderSpace(this.props.space)) {
      // Hide actions for team folder
      return false;
    }
    return true;
  }

  renderActions(request) {
    return (
      <div className='detail-block action-block ps-2'>
        {this.renderActionButtons(request)}
      </div>
    );
  }

  renderMembersAndEmailInfo(request, mobileWidth) {
    return request.collaborator
      ? this.renderMemberInfo(request, mobileWidth)
      : this.renderEmailInfo(request, mobileWidth);
  }

  setkind = (request, e) => {
    let isEditor = e.target.checked,
      kind = isEditor ? 'editor' : 'member',
      attributes = {
        newType: kind,
        spaceId: this.props.space.nice_id,
      };
    if (request.collaborator) {
      attributes.collaboratorId = +request.collaborator.id;
      attributes.collaboratorType = request.collaborator.__typename;
    } else {
      attributes.email = request.email;
    }
    this.props
      .changeSpaceCollaboratorType({
        variables: attributes,
        optimisticResponse: () => {
          return {
            changeSpaceCollaboratorType: {
              id: request.id,
              kind: kind,
              __typename: 'BoardCollaborator',
            },
          };
        },
      })
      .then(({ data }) => {});
  };

  isAuthorOrEditor() {
    return this.props.space.is_author_or_editor;
  }

  isCurrentUserRequest(request) {
    return request.user?.id === this.props.currentUser.graph.id;
  }

  renderPermission(request) {
    let isAnEditor = request.kind === 'editor',
      isAnAdmin = request.kind === 'admin',
      disabled =
        isAnAdmin ||
        this.isCurrentUserRequest(request) ||
        (!isCurrentUserOwnedSpace(this.props.space) &&
          !this.isAuthorOrEditor()),
      isSupportUser = request.user?.is_support_user;
    return (
      <div className={`detail-block editor-block ${isAnAdmin ? '' : ''}`}>
        {isAnAdmin && <span className='os-caf'>Owner</span>}
        {!isAnAdmin && isSupportUser && <span className='os-caf'>Support</span>}
        {!isAnAdmin && !isSupportUser && (
          <OsField
            osType='checkbox'
            onChange={this.setkind.bind(this, request)}
            slider={true}
            sliderType='square'
            dataHoverRequired={!isTouchSupported()}
            className='switch'
            isChecked={isAnEditor}
            disabled={disabled}
          />
        )}
      </div>
    );
  }

  renderRow = (request) => {
    let device = this.props.device,
      mobileWidth = device.mobileDevice || device.mobile;
    return (
      <article className='space-details-table-row' key={request.id}>
        {this.renderMembersAndEmailInfo(request, mobileWidth)}
        {this.renderDateInfo(request, mobileWidth)}
        {!this.isGroupListing() && this.renderPermission(request)}
        {this.shouldRenderActions() && this.renderActions(request)}
      </article>
    );
  };

  renderNoContent() {
    return (
      <div className='no-cases-block'>
        <div className='no-cases-image-block'>
          <Icon name='workspace' />
        </div>
        <div className='no-cases-message-block'>
          {this.props.searchText
            ? translate(
                'RESULTS_NOT_FOUND',
                {
                  searchInput: this.props.searchText,
                },
                true,
                { connector: '' },
              )
            : 'No members were added'}
        </div>
      </div>
    );
  }

  renderHeader() {
    let { device } = this.props,
      mobileWidth = device.mobileDevice || device.mobile;
    return (
      <article className='space-details-table-header'>
        <div className='detail-block invite-block'>Invited members</div>
        {!mobileWidth && (
          <>
            <div className='detail-block status-block'>Status</div>
            <div className='detail-block date-block'>Last Active At</div>
            {!this.isGroupListing() && (
              <div className='detail-block editor-block'>Editor</div>
            )}
          </>
        )}
      </article>
    );
  }

  render() {
    const { space } = this.props;
    let acceptedOrPendingRequests = this.props.results.filter((request) =>
      ['accepted', 'pending'].includes(request.status),
    );

    let spaceTableClass = this.isGroupListing()
      ? 'group-pending-table'
      : 'space-pending-table';

    if (this.isGroupListing() && space.all_members_count === 0) {
      // Push admin as default member
      const defaultUser = {
        collaborator: space.user,
        user: space.user,
        kind: 'admin',
        status: 'accepted',
      };
      acceptedOrPendingRequests.push(defaultUser);
    }

    return (
      <div className={spaceTableClass}>
        {this.renderHeader()}
        {acceptedOrPendingRequests.length === 0 && this.renderNoContent()}
        {acceptedOrPendingRequests.map(this.renderRow)}
      </div>
    );
  }
}
SpaceMembersView = withRouter(SpaceMembersView);
SpaceMembersView = graphql(CHANGE_SPACE_COLLABORATOR_TYPE, {
  name: 'changeSpaceCollaboratorType',
})(SpaceMembersView);
SpaceMembersView = connect(({ currentUser, device }) => ({
  currentUser,
  device,
}))(SpaceMembersView);
export default SpaceMembersView;
