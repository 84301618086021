import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';
import Icon from 'app/components/Svg';

import { translate } from 'app/actions/flashMessage';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';

class PasswordUpdateNotification extends Component {
  render() {
    return (
      <article className='change-password mt-2 d-flex mb-4'>
        <div className='image-col'>
          <Icon name='changePassword' />
        </div>
        <div className='protect-info'>
          <p className='semibold change-password-heading'>
            {translate('CHANGE_PASSWORD_HEADING')}
          </p>
          <div>{translate('CHANGE_PASSWORD_CONTENT')}</div>
        </div>
        <div className='action-col'>
          <OsBtn
            name='BtnPrimary'
            text='Change password'
            extraClass='ls-05'
            onClick={this.props.openPasswordUpdateModal}
          />
        </div>
      </article>
    );
  }
}

PasswordUpdateNotification = connect(null, { openPasswordUpdateModal })(
  PasswordUpdateNotification,
);

export default PasswordUpdateNotification;
