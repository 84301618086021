import React, { Component } from 'react';
import { connect } from 'react-redux';

import FeedDetails from './FeedDetails';
import OsCards from 'app/components/OsCards';
import OsGrid from 'app/components/OsGrid';

import { map } from 'app/utils/osLodash';

export default class AbstractFeed extends Component {
  getObject() {
    return this.props.feed.objects[0];
  }

  getEntity() {
    return this.props.feed.entities[0];
  }

  getActor() {
    return this.props.feed.authors[0];
  }

  cardSize() {
    // let size = this.props.device.mobileDevice ? 'small' : 'small-min'
    //
    // if(['MyActivities', 'UserActivities'].includes(this.props.listType) && !this.props.device.mobileDevice)
    //   size = 'small'
    return 'extra-small';
  }

  isExploreFeed() {
    return ['CareSpaceFeed', 'DefaultFeed'].includes(this.props.listType);
  }

  getGridIdentifier() {
    return ['MyActivities', 'NewUserActivities', 'UserActivities'].includes(
      this.props.listType,
    )
      ? 'TimelineSpaceCommentCardColumn:Cardcols'
      : 'FeedCardColumn:Cardcols';
  }

  interpolationOptions() {
    return {
      extraLinkClass: 'white-link',
    };
  }

  interpolationDataInfo() {
    let entity = this.getEntity(),
      object = this.getObject();
    return {
      entity,
      object,
      authors: this.props.feed.authors,
      ...this.props.metaInfo,
    };
  }

  effectInterpolationDataInfo() {
    let effectActivity = this.props.feed.effect_activity || {},
      // entity = (effectActivity.entities || [])[0] || {},
      entity = this.getEntity(),
      object = (effectActivity.objects || [])[0] || {},
      authors =
        effectActivity.authors?.length > 0
          ? effectActivity.authors
          : this.props.feed.authors;
    return {
      entity,
      object,
      authors,
      ...this.props.metaInfo,
    };
  }

  feedDetailsProps() {
    return {
      effectInterpolationDataInfo: this.effectInterpolationDataInfo(),
      feed: this.props.feed,
      interpolationDataInfo: this.interpolationDataInfo(),
      interpolationOptions: this.interpolationOptions(),
      listType: this.props.listType,
    };
  }

  cardsContainerClasses() {
    return 'os-card-listing row row-col-space-16';
  }

  getModifiedEntity(entity) {
    return entity;
  }

  renderCard = (entity) => {
    return (
      <OsGrid identifier={this.getGridIdentifier()}>
        <OsCards
          size={this.cardSize()}
          obj={this.getModifiedEntity(entity)}
          key={`${entity.__typename}:${entity.id}`}
        />
      </OsGrid>
    );
  };

  renderCards() {
    return map(this.props.feed.entities, this.renderCard);
  }

  render() {
    return (
      <>
        <FeedDetails {...this.feedDetailsProps()}>
          <div className={this.cardsContainerClasses()}>
            {this.renderCards()}
          </div>
        </FeedDetails>
        {this.props.children}
      </>
    );
  }
}

const ConnectedAbstractFeed = connect(({ device }) => ({ device }))(
  AbstractFeed,
);
export { ConnectedAbstractFeed };
