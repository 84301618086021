import { OPEN_PLAYER_MODAL, CLOSE_PLAYER_MODAL } from 'app/actions/types';

export default function playerModal(
  state = { open: false, entity: {}, options: {} },
  action,
) {
  switch (action.type) {
    case OPEN_PLAYER_MODAL:
      return { open: true, entity: action.entity, options: action.options };
    case CLOSE_PLAYER_MODAL:
      return { entity: {}, options: {}, open: false };
    default:
      return state;
  }
}
