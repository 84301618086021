import React from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { Outlet } from 'react-router-dom';

import AbstractLayout from './AbstractLayout';
import Footer from 'app/components/Shared/Footer';
import LoginOrLogoutLink from 'app/components/Shared/LoginOrLogoutLink';
import SiteLogo from 'app/components/Shared/SiteLogo';

import { bindMobileFilter } from 'app/actions/device';
import { flashError } from 'app/actions/flashMessage';
import UniversalBanner from 'app/components/HOC/UniversalBanner';

class UnauthenticatedLayout extends AbstractLayout {
  customComponentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (!this.props.currentUser.error && nextProps.currentUser.error) {
      nextProps.flashError(nextProps.currentUser.errorMessage, false);
    }
  }

  customComponentWillUnmount() {
    if (this.isUnauthorizedSession()) window.$('body').removeClass();
  }

  hideHeaderIfRequired() {}

  render() {
    return (
      <div className='layout'>
        <UniversalBanner />
        <nav
          className={
            'navbar navbar-expand-lg os-main-nav navbar-expand-xl navbar-unauth ' +
            this.hideHeaderIfRequired()
          }>
          <div className='os-main-nav-inner'>
            <SiteLogo />
            <LoginOrLogoutLink />
          </div>
        </nav>

        <Outlet />
        {!this.isUnauthorizedSession() && <Footer usePortal={true} />}
      </div>
    );
  }
}

UnauthenticatedLayout = connect(
  ({ currentUser, background, device }) => ({
    currentUser,
    background,
    device,
  }),
  {
    bindMobileFilter,
    flashError,
  },
)(UnauthenticatedLayout);
UnauthenticatedLayout = withRouter(UnauthenticatedLayout);
UnauthenticatedLayout = withApollo(UnauthenticatedLayout);

export default UnauthenticatedLayout;
