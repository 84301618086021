import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Routes, Route } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import NotificationPreferences from './NotificationPreferences';
import SecurityView from 'app/components/Shared/SecurityView';
import TabLayout from './TabLayout';

import EventTracker from 'app/services/EventTracker';

import { getCurrentFilter, WORKSPACE_NAV_LIST } from './tabsHelper';

const DEFAULT_FILTER = WORKSPACE_NAV_LIST[0][1];

class AccountSettingView extends Component {
  componentDidMount() {
    EventTracker.track('Navigate', { page: 'Account Settings' });
  }

  noFilterPresent() {
    return this.props.location.pathname === '/account_settings';
  }

  getDefaultUrl() {
    return `/account_settings/${DEFAULT_FILTER}`;
  }

  render() {
    if (this.noFilterPresent()) return <Navigate to={this.getDefaultUrl()} />;
    return (
      <Routes>
        <Route element={<TabLayout />}>
          <Route
            path='notification_preferences'
            element={<NotificationPreferences />}
          />
          <Route path='security' element={<SecurityView />} />
        </Route>
        <Route
          path='*'
          element={<Navigate to={this.getDefaultUrl()} replace={true} />}
        />
      </Routes>
    );
  }
}
AccountSettingView = withRouter(AccountSettingView);
AccountSettingView = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(AccountSettingView);

export default AccountSettingView;
