import React from 'react';

const SVG = ({
  style = {},
  fill = '#000',
  width = '214',
  height = '172',
  className = '',
  viewBox = '0 0 214 172',
}) => (
  <svg
    version='1.1'
    className='svg-loader'
    id='L9'
    x='0px'
    y='0px'
    viewBox='0 0 100 100'
    enable-background='new 0 0 0 0'>
    <path
      fill='#050505'
      d='M2.994,49.834C3.158,23.966,24.248,2.97,50.154,2.97c25.905,0,46.993,20.996,47.158,46.864h2.971
    C100.118,22.328,77.698,0,50.154,0C22.61,0,0.188,22.328,0.023,49.834H2.994z'>
      <animateTransform
        attributeName='transform'
        attributeType='XML'
        type='rotate'
        dur='1s'
        from='0 50 50'
        to='360 50 50'
        repeatCount='indefinite'
      />
    </path>
  </svg>
);

export default SVG;
