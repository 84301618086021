import React, { Component, useMemo, createContext } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import * as compose from 'lodash.flowright';
import { loader as queryLoader } from 'graphql.macro';
import { connect } from 'react-redux';

import Loader from 'react-loaders';
import InfiniteScroll from 'react-infinite-scroller';

import ClinicTeamWithUsersListing from 'app/components/Widgets/ClinicTeamWithUsersListing';
import ClvTabSectionsListing from 'app/components/BoardView/ClvTabSectionsListing';
import ClvTabListing from 'app/components/BoardView/ClvTabListing';
import ConnectionDataListing from 'app/components/Shared/ConnectionDataListing';
import ConversationMessagesListing from 'app/components/ConversationMessagesListing';
import FeedsView from 'app/components/FeedsView';
import LabelListing from 'app/components/LabelsModal/LabelListing';
import NotificationsView from 'app/components/NotificationsView';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import RecordsCollectionView from 'app/components/RecordsCollectionView';
import RenderCards from 'app/components/Cards/RenderCards';
import RenderLoadingCards from 'app/components/Cards/RenderLoadingCards';
import SpaceLinksListing from 'app/components/BoardView/LinksListing';
import TabSectionsListing from 'app/components/NavTabs/SectionsListing';
import SpaceListing from 'app/components/SideBarListing/SpaceListing';
import UnreadNotifications from 'app/components/NotificationsView/UnreadNotifications';
import UserRowsInWidget from 'app/components/Shared/UserRowsInWidget';
import MembersToInvite from 'app/components/InviteMember/MembersToInvite';
import PopularTopicsCollection from 'app/components/Shared/PopularTopicsCollection';
import TaskCollectionView from 'app/components/TaskCollectionView';

import { cardSize } from 'app/utils/osCardHelper';
import {
  difference,
  floor,
  isNumber,
  keys,
  pick,
  uniqBy,
} from 'app/utils/osLodash';
import { isCenterInViewport } from 'app/utils/domHelper';

import withCachedData, {
  ADDITIONAL_QUERIES_PARAMETERS_AND_PROPS,
} from 'app/components/HOC/withCachedData';
import RelatedTaskWidget from 'app/components/Task/RelatedTaskWidget';
import SideBarTeamSpaceList from 'app/components/SideBarTeamSpaceList/SideBarTeamSpaceList';
import DirectMessageListing from 'app/components/DirectMessageListing';
import PinnedMessageListing from 'app/components/TeamSpaceCommunicationsView/PinnedMessageListing';
import AttachmentsInConversationListing from 'app/components/Widgets/AttachmentsInConversationListing';
import TaskListView from 'app/components/Task/TaskListView';
import TaskActivityListing from 'app/components/TaskPreviewModal/TaskActivityListing';
import TaskFeedView from 'app/components/Task/TaskFeedView';
import GroupsInboxList from 'app/components/Inbox/GroupsInboxList';
import ConversationsInboxList from 'app/components/Inbox/ConversationsInboxList';
import TasksInboxList from 'app/components/Inbox/TasksInboxList';
import PatientSpaceInboxFeed from 'app/components/Inbox/PatientSpaceInboxFeed';
import { CANNOT_VIEW_COMMENTS_QUERIES } from 'app/constant/feed';

let QUERY_MAPPER_WITH_PAGE = {};
QUERY_MAPPER_WITH_PAGE['ACCOUNT_SETTINGS'] = queryLoader(
  'app/graphql/queries/AccountSettings.gql',
);
QUERY_MAPPER_WITH_PAGE['CASE_LISTING'] = queryLoader(
  'app/graphql/queries/CaseListing.gql',
);

QUERY_MAPPER_WITH_PAGE['CLINIC_DETAILS'] = queryLoader(
  'app/graphql/queries/Clinics/InfoListing.gql',
);
QUERY_MAPPER_WITH_PAGE['CLINIC_LISTING'] = queryLoader(
  'app/graphql/queries/Clinics/Listing.gql',
);
QUERY_MAPPER_WITH_PAGE['COMMENTS_LISTING'] = queryLoader(
  'app/graphql/queries/CommentsListing.gql',
);
QUERY_MAPPER_WITH_PAGE['COMMENTS_ACTIVITY_LISTING'] = queryLoader(
  'app/graphql/queries/Comments/ActivityListing.gql',
);
QUERY_MAPPER_WITH_PAGE['PARTNER_COMMENTS_ACTIVITY_LISTING'] = queryLoader(
  'app/graphql/queries/Comments/PartnerActivityListing.gql',
);
QUERY_MAPPER_WITH_PAGE['DEFAULT'] = queryLoader('app/graphql/Records.gql');
QUERY_MAPPER_WITH_PAGE['EXPLORE'] = queryLoader(
  'app/graphql/queries/ExploreFeeds.gql',
);
QUERY_MAPPER_WITH_PAGE['GUEST_CLINIC_DETAILS'] = queryLoader(
  'app/graphql/queries/Clinics/GuestInfoListing.gql',
);
QUERY_MAPPER_WITH_PAGE['GUEST_LISTING'] = queryLoader(
  'app/graphql/queries/GuestListing.gql',
);
QUERY_MAPPER_WITH_PAGE['PEOPLE_INDEX'] = queryLoader(
  'app/graphql/queries/PeopleCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['POPULAR_TOPICS'] = queryLoader(
  'app/graphql/queries/PopularTopicsCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['SEEN_BY_LISTING'] = queryLoader(
  'app/graphql/queries/SeenByListing.gql',
);
QUERY_MAPPER_WITH_PAGE['SPACES_INDEX'] = queryLoader(
  'app/graphql/queries/SpacesCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['SPACE_DETAILS'] = queryLoader(
  'app/graphql/SpaceDetailCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['SIDEBAR_SPACE_DETAILS'] = queryLoader(
  'app/graphql/queries/Spaces/LeftSidebarCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['SIDEBAR_GROUP_LIST'] = queryLoader(
  'app/graphql/queries/Spaces/GroupSidebarCollection.gql',
);
QUERY_MAPPER_WITH_PAGE['GROUP_INBOX_LIST'] = queryLoader(
  'app/graphql/queries/Spaces/GroupInboxListing.gql',
);
QUERY_MAPPER_WITH_PAGE['TAB_DETAILS'] = queryLoader(
  'app/graphql/queries/NavTabsDetails.gql',
);
QUERY_MAPPER_WITH_PAGE['USERS_BY_CLINIC'] = queryLoader(
  'app/graphql/queries/UsersAddedByClinic.gql',
);
QUERY_MAPPER_WITH_PAGE['USER_DETAIL'] = queryLoader(
  'app/graphql/queries/UserDetail.gql',
);
QUERY_MAPPER_WITH_PAGE['USERS_INDEX'] = queryLoader(
  'app/graphql/queries/UserIndex.gql',
);
QUERY_MAPPER_WITH_PAGE['VISTIOR_DIRECTORY'] = queryLoader(
  'app/graphql/queries/VisitorDirectory.gql',
);
QUERY_MAPPER_WITH_PAGE['TaskDirectory'] = queryLoader(
  'app/graphql/queries/Task/TaskListing.gql',
);
QUERY_MAPPER_WITH_PAGE['TASK_INBOX_LIST'] = queryLoader(
  'app/graphql/queries/Task/TaskInboxListing.gql',
);
QUERY_MAPPER_WITH_PAGE['PINNED_CONVERSATION'] = queryLoader(
  'app/graphql/queries/Conversations/ConversationPinnedChat.gql',
);
QUERY_MAPPER_WITH_PAGE['PINNED_COMMENTS'] = queryLoader(
  'app/graphql/queries/PinnedComments.gql',
);
QUERY_MAPPER_WITH_PAGE['CONVERSATION_ATTACHMENTS'] = queryLoader(
  'app/graphql/queries/Conversations/ConversationSharedFiles.gql',
);
QUERY_MAPPER_WITH_PAGE['TASK_FEED_LISTING'] = queryLoader(
  'app/graphql/queries/Task/TaskFeedListing.gql',
);
QUERY_MAPPER_WITH_PAGE['INBOX_CONVERSATIONS'] = queryLoader(
  'app/graphql/ConversationsInboxListing.gql',
);
QUERY_MAPPER_WITH_PAGE['CONVERSATIONS_SEARCH'] = queryLoader(
  'app/graphql/ConversationsSearchListing.gql',
);

const VALID_QUERIES_TYPES = keys(QUERY_MAPPER_WITH_PAGE);
const LOADING_CARDS_REQUIRED = [
  'AcceptedConnections',
  'AllAdditionalLinks',
  'AllDiscussions',
  'AllMembers',
  'AllAccessibleFiles',
  'AllShareableFiles',
  'ClinicCases',
  'CommentableSpaces',
  'PendingConnections',
  'Pulses',
  'SPACES_INDEX',
  'SpaceFollowers',
  'SpaceLinks',
  'SpaceMembers',
  'SpaceSectionLinks',
  'SubSpaces',
  'UserCases',
  'UserComparisons',
  'UserFollowees',
  'UserFollowers',
  'USERS_INDEX',
  'UserNotes',
  'UserSpaces',
];
// Component to render paginated records from backend.
class RecordsView extends Component {
  state = { refetching: false, distanceFromBottom: 0 };

  componentDidMount() {
    this.props.trackScrolling && this.bindScrollEvent();
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.key !== this.props.location.key &&
      ['Notification'].includes(this.props.type)
    ) {
      this.setState({ refetching: true });
      this.props.refetch().then(() => this.setState({ refetching: false }));
    }

    if (
      nextProps.setCollectionTotal &&
      nextProps.records &&
      nextProps.records.total !== nextProps.collectionTotal
    ) {
      nextProps.setCollectionTotal(nextProps.records.total);
    }

    if (!this.props.refetchData && nextProps.refetchData) this.props.refetch();

    if (
      !this.props.loading &&
      !nextProps.loading &&
      this.props.records &&
      this.props.records.results.length === 0 &&
      nextProps.records.results.length === 1
    ) {
      nextProps.setCollectionTotal &&
        nextProps.setCollectionTotal(nextProps.records.total, {
          forceVisible: true,
        });
      this.setState({ noResults: true });
    }
    if (nextProps.type !== this.props.type) this.setState({ noResults: false });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.trackScrolling &&
      prevProps.records &&
      this.props.records &&
      prevProps.records.results.length < this.props.records.results.length
    )
      this.onScroll();

    // if(!this.props.loading && prevProps.loading && this.props.updateEntity && this.props.records && !this.isSpaceSection(this.props.records.results)) {
    //   StoreUpdater.updateEntity({entity: this.props.updateEntity.entity, attributeName: this.props.updateEntity.attributeName, total: this.props.records.total })
    // }

    // Parag marked it as improvement of pagination
    // if(!this.props.loading && prevProps.location && this.props.type === "SpaceSectionLinks") {
    //   StoreUpdater.updateSectionLinksCount(this.props.records, this.props.board, this.props.idQuery)
    // }

    if (
      this.props.hasMore &&
      !this.props.loading &&
      this.props.infiniteScroll
    ) {
      // Wait for the DOM to render and then check scroll possible to avoid extra query.
      setTimeout(() => {
        this.mounted && this.isScrollNotPossible() && this.loadNextPage();
      }, 1000);
    }

    if (prevProps.loading && !this.props.loading) {
      this.props.onLoadingComplete();
    }

    if (this.props.highlightSearchTerms) this.props.highlightSearchTerms();
  }

  isScrollNotPossible() {
    if (this.props.windowNotRequired) {
      // Used to Invite user to sapce modal when one list is selected and then modal is closed. Parent container is coming as undefined.
      let parentContainer =
        this.props.parentContainer ||
        this.containerRef.scrollComponent.parentElement;
      return (
        parentContainer &&
        this.containerRef &&
        parentContainer.offsetHeight > 0 &&
        parentContainer.offsetHeight >=
          this.containerRef.scrollComponent.firstElementChild.offsetHeight
      );
    } else {
      return (
        this.props.autofillCardsUpto ||
        window.innerHeight >= document.body.scrollHeight
      );
    }
  }

  componentWillUnmount() {
    this.props.trackScrolling && this.unbindScrollEvent();
    this.mounted = false;
  }

  allCardsWithEmptySection() {
    return (
      this.props.autofillCardsUpto || this.props.nonLodedEmptyCardsRequired
    );
  }

  scrollEventElement() {
    let ele;
    if (this.props.autofillCardsUpto) {
      ele = window;
    } else if (this.props.scrollElementContainerId) {
      ele = document.getElementById(this.props.scrollElementContainerId);
    }
    return ele || this.recordsContainer;
  }

  bindScrollEvent() {
    this.scrollEventElement().addEventListener('scroll', this.onScroll);
  }

  unbindScrollEvent() {
    this.scrollEventElement().removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (this.allCardsWithEmptySection()) {
      if (this.props.hasMore && !this.props.loading) {
        let firstLoadingCard =
            this.recordsContainer.getElementsByClassName('card--loading')[0],
          isCardScrolledUpwards =
            firstLoadingCard &&
            firstLoadingCard.getBoundingClientRect().top < 0;
        firstLoadingCard &&
          (isCardScrolledUpwards || isCenterInViewport(firstLoadingCard)) &&
          this.loadNextPage();
      }
    } else if (this.recordsContainer) {
      let distanceFromBottom =
        this.recordsContainer.scrollHeight -
        this.recordsContainer.scrollTop -
        this.recordsContainer.clientHeight;
      this.setState({ distanceFromBottom });
    }
  };

  notInitialLoad() {
    return this.props.records && this.props.records.results.length > 0;
  }

  isReverse() {
    return !!this.props.isReverse;
  }

  getFilteredRecords(props) {
    let results = props.records ? [...props.records.results] : [];
    results = results.map(this.props.processCardObject);
    if ((props.recordsNotToBeIncludes || []).length) {
      let selectedMemberIds = props.recordsNotToBeIncludes.map(
        (member) => member.id,
      );
      results = results.filter(
        (member) => !selectedMemberIds.includes(member.id),
      );
    }
    return results;
  }

  results() {
    let results = this.getFilteredRecords(this.props);
    return this.isReverse() ? results.reverse() : results;
  }

  isCurrentUser() {
    return !this.props.idQuery;
  }

  isLoadingCardsRequired() {
    return (
      this.props.loadingCards &&
      (LOADING_CARDS_REQUIRED.includes(this.props.type) ||
        LOADING_CARDS_REQUIRED.includes(this.props.queryType))
    );
  }

  fourPerRow() {
    return this.props.size === 'small-min';
  }

  getCardClass(card) {
    if (this.fourPerRow()) {
      return 'col-12 col-md-4 col-lg-3 mb-4';
    } else if (this.props.twoPerRow) {
      return 'col-12 col-md-4 mb-4 col-sm-6 col-lg-3  ';
    } else {
      return `col-12 col-md-4 col-lg-3 mb-4 col-sm-6  ${
        this.props.cardsCustomMarginRequired ? 'space-card-col' : ''
      }`;
    }
  }

  getLoadingCardsProps() {
    return {
      ...(isNumber(this.props.autofillCardsUpto) && {
        count:
          this.props.autofillCardsUpto === 0 ? 3 : this.props.autofillCardsUpto,
      }),
      className: this.getCardClass(),
      initialLoad:
        (this.props.records && this.props.records.results.length === 0) ||
        this.isSearchRequestInProgress(),
      size: cardSize({
        size: this.props.size || this.props.cardProps?.size,
        width: this.props.device.width,
        mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
      }),
      cardGridIdentifier: this.getCardGridIdentifier(),
    };
  }

  isSearchRequestInProgress() {
    return this.props.loading && this.props.searchQuery;
  }

  renderLoader() {
    if (this.isLoadingCardsRequired()) {
      return <RenderLoadingCards {...this.getLoadingCardsProps()} />;
    } else {
      return <Loader type='ball-triangle-path' active />;
    }
  }

  onResultClick = (result) => {
    this.props.onResultClick(result);
  };

  renderCollection() {
    if (
      (this.props.loading &&
        (!this.notInitialLoad() || this.state.refetching)) ||
      !this.props.records ||
      this.isSearchRequestInProgress()
    ) {
      return !this.props.loaderNotRequired && this.renderLoader();
    } else {
      const additionalPropsHash =
        ADDITIONAL_QUERIES_PARAMETERS_AND_PROPS[this.props.type];
      let props = {
        record: this.props.records.record,
        source: this.props.source,
        autofillCardsUpto: this.props.autofillCardsUpto,
        results: this.results(),
        totalRecords: this.props.records.total,
        textQuery: this.props.textQuery,
        idQuery: this.props.idQuery,
        idType: this.props.idType,
        searchQuery: this.props.searchQuery,
        size: this.props.size,
        distanceFromBottom: this.state.distanceFromBottom,
        setListComponentRef: this.props.setListComponentRef,
        listType: this.props.type,
        filter: this.props.filter,
        user: this.props.user,
        reloadRecords: this.props.reloadRecords,
        cardProps: this.props.cardProps,
        viewType: this.props.viewType,
        scrollToBottom: this.isReverse(),
        parentContainer: this.recordsContainer,
        widgetAfterListItemNumber: this.props.widgetAfterListItemNumber,
        sortQuery: this.props.sortQuery,
        skillToLearnPortalId: this.props.skillToLearnPortalId,
        hideCaseImage: this.props.hideCaseImage,
        navTab: this.props.navTab,
        entity: this.props.sourceObject,
        sliderProps: this.props.sliderProps,
        selectedRecords: this.props.selectedRecords,
        ...(additionalPropsHash &&
          pick(this.props, additionalPropsHash['additionalProps'] || [])),
        sectionTitle: this.props.sectionTitle,
        currentSelectedUser: this.props.currentSelectedUser,
        date: this.props.date,
        closeModal: this.props.closeModal,
        actionType: this.props.actionType,
        isGroupedDateRequired: this.props.isGroupedDateRequired,
      };
      switch (this.props.type) {
        case 'CareSpaceFeed':
          return (
            <FeedsView
              {...props}
              feedType={'feed-view'}
              allCaughtUpNotRequired={this.props.allCaughtUpNotRequired}
              refetch={this.props.refetch}
              subscribeToMore={this.props.subscribeToMore}
            />
          );
        case 'CareSpaceActivities':
        case 'PartnerSpaceActivities':
        case 'DefaultFeed':
        case 'NewUserActivities':
        case 'UserActivities':
        case 'UserCareTimeline':
        case 'MyActivities':
        case 'PatientActivitiesSearch':
        case 'PartnerSpaceActivitiesSearch':
          return (
            <FeedsView
              {...props}
              updateCount={this.props.updateCount}
              feedType={'carespace-feed-view'}
              subscribeToMore={this.props.subscribeToMore}
              allCaughtUpNotRequired={this.props.allCaughtUpNotRequired}
              refetch={this.props.refetch}
            />
          );

        case 'MyReadPatientComments':
        case 'MyUnreadPatientComments':
        case 'MyReadPartnerComments':
        case 'MyUnreadPartnerComments':
        case 'TeamPatientReadComments':
        case 'TeamPatientUnreadComments':
        case 'ProcessedCareSpaceComments':
        case 'UnprocessedCareSpaceComments':
        case 'ProcessedPartnerSpaceComments':
        case 'UnprocessedPartnerSpaceComments':
          return (
            <PatientSpaceInboxFeed
              {...props}
              updateCount={this.props.updateCount}
              feedType={'carespace-feed-view'}
              subscribeToMore={this.props.subscribeToMore}
              allCaughtUpNotRequired={this.props.allCaughtUpNotRequired}
              refetch={this.props.refetch}
            />
          );
        case 'MyTasksFeed':
        case 'TeamTasksFeed':
          return (
            <TaskFeedView
              {...props}
              loading={this.props.loading}
              subscribeToMore={this.props.subscribeToMore}
              isTeamTaskFeed={this.props.isTeamTaskFeed}
            />
          );
        case 'TaskActivities':
          return (
            <TaskActivityListing
              {...props}
              updateActivityCount={this.props.updateActivityCount}
              loaderNotRequired={this.props.loaderNotRequired}
              subscribeToMore={this.props.subscribeToMore}
              loading={this.props.loading}
              entity={this.props.entity}
              fullViewListing={this.props.fullViewListing}
            />
          );
        case 'Notification':
          return props.source === 'dropdown' ? (
            <UnreadNotifications {...props} />
          ) : (
            <NotificationsView {...props} />
          );
        case 'UnreadNotification':
          return <UnreadNotifications {...props} />;
        case 'ClinicTeam':
          return <ClinicTeamWithUsersListing {...props} />;
        case 'AllCareSpaces':
        case 'AllSidebarCareSpaces':
          return <SpaceListing {...props} refetch={this.props.refetch} />;
        case 'ConversationsListing':
          if (this.props.searchListing) {
            return (
              <ConversationsInboxList
                {...props}
                parentRef={this.recordsContainer}
              />
            );
          } else {
            return <DirectMessageListing {...props} />;
          }
        case 'ConversationMessagesListing':
          return (
            <ConversationMessagesListing
              {...props}
              conversation={this.props.conversation}
              isGroupChat={this.props.isGroupChat}
            />
          );
        case 'AcceptedConnections':
        case 'PendingConnections':
          if (this.props.queryType !== 'USERS_INDEX') {
            return (
              <ConnectionDataListing
                noResults={this.state.noResults}
                isCurrentUser={this.isCurrentUser()}
                {...props}
                hideNoResultsText={this.props.hideNoResultsText}
              />
            );
          } else {
            return this.renderIndexPageCollection(props);
          }
        case 'TabSectionLinks':
          return this.props.clvHeaderView
            ? this.renderTabSectionsOrLinks(props)
            : this.renderLinks(props);
        case 'RecentlyOnlineMembers':
          return (
            <UserRowsInWidget
              users={this.results()}
              designationRequired={false}
            />
          );
        case 'BoardRecentDiscussions':
        case 'MyAttachments':
        case 'ClinicAccessibleFiles':
        case 'PatientClinicTeam':
        case 'Pulses':
        case 'UserCases':
        case 'UserComparisons':
        case 'UserNotes':
        case 'ClinicCases':
        case 'UserSpaces':
        case 'CompanyKOL':
        case 'AllAdditionalLinks':
        case 'AllDiscussions':
        case 'FolderLinks':
        case 'AllMembers':
        case 'AllAccessibleFiles':
        case 'AllFolderLinks':
        case 'AllShareableFiles':
        case 'AllUsersAddedByClinic':
        case 'AllSpaceLinks':
        case 'CommentableSpaces':
        case 'RelatedCareSpaces':
        case 'UserCareSpaces':
        case 'UserLearningHubs':
        case 'UserPatients':
        case 'UserResponseParties':
          return this.renderRecordsCollectionView(props);
        case 'UsersToInviteInSpace':
          return (
            <MembersToInvite
              {...props}
              loading={this.props.loading}
              selectedMembers={this.props.recordsNotToBeIncludes}
              refetchSuggestions={this.props.refetchSuggestions}
              setSelectedMembers={this.props.setSelectedMembers}
              setSearchText={this.props.setSearchText}
              selectAllMembers={this.props.selectAllMembers}
              onSelectAllMembers={this.props.onSelectAllMembers}
            />
          );
        case 'EventSpaceSpeakers':
        case 'SpaceKOL':
        case 'SubSpaces':
          if (this.props.displayType === 'sidebarList') {
            return (
              <SideBarTeamSpaceList {...props} refetch={this.props.refetch} />
            );
          }
          return this.renderSpaceTabs(props);
        case 'SeenByUsers':
          return <UserRowsInWidget users={this.results().map((t) => t.user)} />;
        case 'TabSections':
          return this.renderTabSectionsOrLinks(props);
        case 'PopularTopics':
        case 'PopularTopicsOnCareFeed':
          return (
            <PopularTopicsCollection {...props} refetch={this.props.refetch} />
          );
        case 'AllLabels':
          return this.renderLabels();
        case 'NotViewedTasks':
        case 'ViewedTasks':
        case 'InProgressTasks':
        case 'CompletedUserCreatedTasksAssignedToOther':
        case 'NotCompletedUserCreatedTasksAssignedToOther':
          return (
            <TaskListView
              {...props}
              sectionTitle={props.sectionTitle + ` (${props.totalRecords})`}
            />
          );
        case 'AllWorkspaceTasks':
          if (this.props.searchListing) {
            return <TasksInboxList {...props} />;
          } else {
            return <TaskCollectionView {...props} />;
          }
        case 'MyReadTasks':
        case 'MyUnreadTasks':
          return (
            <TasksInboxList
              {...props}
              subscribeToMore={this.props.subscribeToMore}
              updateCount={this.props.updateCount}
              perPage={this.props.perPage}
            />
          );
        case 'MyReadGroups':
        case 'MyUnreadGroups':
        case 'JoinedTeamSpaces':
        case 'GroupSpacesSearch':
          return (
            <GroupsInboxList
              {...props}
              subscribeToMore={this.props.subscribeToMore}
              updateCount={this.props.updateCount}
            />
          );
        case 'MyUnreadConversations':
        case 'MyReadConversations':
          return (
            <ConversationsInboxList
              {...props}
              subscribeToMore={this.props.subscribeToMore}
              updateCount={this.props.updateCount}
              parentRef={this.recordsContainer}
            />
          );
        case 'EntityRelatedTasks':
          return <RelatedTaskWidget {...props} />;
        case 'PinnedComments':
        case 'ConversationPinnedMessagesListing':
          return (
            <PinnedMessageListing
              {...props}
              loading={this.props.loading}
              source={this.props.source}
            />
          );
        case 'ConversationMessagesHavingAttachments':
          return (
            <AttachmentsInConversationListing
              {...props}
              source={this.props.source}
              refetch={this.props.refetch}
              variables={this.props.variables}
            />
          );
        // case 'AllPatientsOfClinic':
        //   return <CarespaceCollectionView {...props} />;
        default:
          return this.renderIndexPageCollection(props);
      }
    }
  }

  renderLabels() {
    return (
      <LabelListing
        labels={this.results()}
        onLabelClick={this.props.onLabelClick}
        onNewLabelSuccess={this.props.onNewLabelSuccess}
        selectedLabel={this.props.selectedLabel}
        closeModal={this.props.closeModal}
      />
    );
  }

  isTabSection(results) {
    return results && results.length && results[0].__typename === 'TabSection';
  }

  renderTabSectionsOrLinks(props) {
    if (this.props.clvHeaderView) {
      let commonProps = {
        entity: this.props.space,
        tab: this.props.tab,
        openNextSectionOrTab: this.props.openNextSectionOrTab,
        openPreviousSectionOrTab: this.props.openPreviousSectionOrTab,
        openFirstContentTabId: this.props.openFirstContentTabId,
        openLastContentTabId: this.props.openLastContentTabId,
      };

      if (this.isTabSection(props.results)) {
        return (
          <ClvTabSectionsListing
            results={props.results}
            reloadRecords={this.props.reloadRecords}
            navTabId={this.props.idQuery}
            contentNotAccessible={this.props.contentNotAccessible}
            {...commonProps}
          />
        );
      } else {
        return <ClvTabListing {...props} {...commonProps} />;
      }
    } else {
      if (this.isTabSection(props.results)) {
        return (
          <TabSectionsListing
            size={props.size}
            results={props.results}
            addNewLinkNotRequired={true}
            entity={this.props.space}
            reloadRecords={this.props.reloadRecords}
            searchQuery={this.props.searchQuery}
            navTabId={this.props.idQuery}
            contentNotAccessible={this.props.contentNotAccessible}
            navTab={this.props.navTab}
            additional_filters={this.props.additional_filters}
            sortQuery={this.props.sortQuery}
          />
        );
      } else {
        return this.renderLinks(props);
      }
    }
  }

  renderSpaceTabs(props) {
    return (
      <section className='space-links-container'>
        {this.renderRecordsCollectionView(props)}
      </section>
    );
  }

  getCardGridIdentifier() {
    return (
      this.props.cardGridIdentifier || this.props.cardProps.cardGridIdentifier
    );
  }

  renderRecordsCollectionView(props) {
    return (
      <RecordsCollectionView
        {...props}
        getTotalRecords={this.props.getTotalRecords}
        cardGridIdentifier={this.getCardGridIdentifier()}
        {...props.cardProps}
      />
    );
  }

  isSpaceSection(results) {
    return (
      results && results.length && results[0].__typename === 'SpaceSection'
    );
  }

  tabSectionOptions() {
    if (this.props.tabSectionId) {
      return {
        idQuery: this.props.board.nice_id,
        tabSectionId: this.props.tabSectionId,
        tabSection: this.props.tabSection,
      };
    } else {
      return {};
    }
  }

  getNonLinkableTab() {
    if (
      this.props.nonLinkableTab &&
      this.props.records &&
      this.props.records.results[0] &&
      this.props.records.results[0].__typename === 'BoardLink'
    ) {
      return { nonLinkableTab: '' };
    }
  }

  renderLinks(props) {
    return (
      <SpaceLinksListing
        {...props}
        canArrangeContent={this.props.canArrangeContent}
        cardsCustomMarginRequired={this.props.cardsCustomMarginRequired}
        cardsSelectionModeEnabled={this.props.cardsSelectionModeEnabled}
        onToggleCardSelection={this.props.onToggleCardSelection}
        nonLinkableTab={this.props.nonLinkableTab}
        board={this.props.board || this.props.space}
        activeLinksCount={this.props.activeLinksCount}
        addNewLinkNotRequired={this.props.addNewLinkNotRequired}
        addNewLinkOptions={this.props.addNewLinkOptions}
        selectedFilter={this.props.selectedFilter}
        onRemoveLink={this.props.onRemoveLink}
        afterLinkRemove={this.props.afterLinkRemove}
        contentNotAccessible={this.props.contentNotAccessible}
        additional_filters={this.props.additional_filters}
        {...this.tabSectionOptions()}
        {...this.getNonLinkableTab()}
      />
    );
  }

  renderIndexPageCollection(props) {
    switch (this.props.queryType) {
      case 'SPACES_INDEX':
      case 'USERS_INDEX':
      case 'GUEST_LISTING':
      case 'CASE_LISTING':
      case 'PEOPLE_INDEX':
      case 'VISTIOR_DIRECTORY':
        return this.renderRecordsCollectionView(props);
      default:
        return null;
    }
  }

  renderCards(props) {
    return (
      <RenderCards {...props} objects={props.results} orderRowWise={true} />
    );
  }

  renderLoading() {
    let records = this.props.records,
      moreRecordsAvailable = !records || records.results.length < records.total;

    return (
      !this.props.loaderNotRequired &&
      !this.props.autofillCardsUpto &&
      this.props.loading &&
      this.notInitialLoad() &&
      moreRecordsAvailable && <div className='home-loader'></div>
    );
  }

  currentPage() {
    let {
      records: { results },
      perPage,
    } = this.props;
    // NOTE:GP -1 is added as Current Page is starting from 0;
    return this.props.records.page || floor(results.length / perPage) - 1;
  }

  loadNextPage = () => {
    if (
      this.props.nextPageLoadRequired &&
      this.props.hasMore &&
      !this.props.loading
    ) {
      this.props.loadMoreEntries(this.currentPage() + 1);
    }
  };

  onSeeMoreClick = () => {
    if (this.props.loadAllOnSeeMore) {
      this.props.refetch({ perPage: 200 });
    } else {
      this.loadNextPage();
    }
  };

  renderEmptySection() {
    return (
      <div className='card card--loading'>
        <div className='card-header'>
          <div className='lds-ellipsis'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  renderNonLoadedEmptyContent() {
    if (this.props.records?.total) {
      let nonLoadedCount =
        this.props.records.total - this.props.records.results.length;
      return [...Array(nonLoadedCount).keys()].map(this.renderEmptySection);
    }
  }

  infiniteScroll() {
    let shouldRenderLoader =
      !this.props.loaderNotRequired && this.props.nextPageLoadRequired;
    return (
      <>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadNextPage}
          hasMore={this.props.hasMore}
          initialLoad={false}
          threshold={this.props.threshold || 500}
          key={this.props.type}
          useWindow={!this.props.windowNotRequired}
          isReverse={this.isReverse()}
          ref={(el) => (this.containerRef = el)}
          loader={shouldRenderLoader && this.renderLoader()}>
          {this.renderCollection()}
          <span ref={(el) => (this.recordsContainerSibling = el)} />
        </InfiniteScroll>
        {this.props.nonLodedEmptyCardsRequired &&
          this.renderNonLoadedEmptyContent()}
      </>
    );
  }

  renderSeeAllLink(props = {}) {
    props.to = props.onClick ? null : this.props.seeAllLink;
    return (
      <div className='text-center pb-4'>
        <OsLink
          {...props}
          name='BtnSecondary'
          extraClass='with-border'
          text='See All'
        />
      </div>
    );
  }

  renderSeeMoreLink() {
    if (this.props.loadAllOnSeeMore)
      return this.renderSeeAllLink({ onClick: this.onSeeMoreClick });
    return (
      <div className='text-center pb-4'>
        <OsBtn
          name='BtnSecondary'
          extraClass='with-border'
          text='See more'
          onClick={this.onSeeMoreClick}
        />
      </div>
    );
  }

  renderProgressiveListWithLoadMoreButton() {
    return (
      <div>
        {this.isReverse() && this.renderLoading()}
        {this.renderCollection()}
        {this.props.seeAllLink && this.props.hasMore && this.renderSeeAllLink()}
        {!this.props.showMoreNotRequired &&
          this.props.hasMore &&
          this.renderSeeMoreLink()}
        {!this.isReverse() && this.renderLoading()}
      </div>
    );
  }

  renderList() {
    return this.props.infiniteScroll
      ? this.infiniteScroll()
      : this.renderProgressiveListWithLoadMoreButton();
  }

  render() {
    if (this.props.trackScrolling) {
      return (
        <div
          className={this.props.className || 'records-container'}
          ref={(el) => (this.recordsContainer = el)}>
          {this.renderList()}
        </div>
      );
    } else {
      return this.renderList();
    }
  }
}

const requestConfig = {
  options: (props) => ({
    fetchPolicy: props.fetchPolicy ? props.fetchPolicy : 'cache-and-network',
    nextFetchPolicy: 'network-only',
    variables: {
      ...props.variables,
      id_query: props.idQuery,
      id_type: props.idType,
      searchQuery: props.searchQuery,
      text_query: props.textQuery,
      sortQuery: props.sortQuery,
      filterId: props.filterId,
      filterType: props.filterType,
    },
  }),
  props({ data, ...rest }) {
    let { loading, fetchMore, records, refetch, variables, subscribeToMore } =
      data;
    let { cachedRecords } = rest.ownProps;
    if (cachedRecords && records)
      records.results = uniqBy(
        [...records.results, ...cachedRecords.records.results],
        (obj) => `${obj.__typename}:${obj.id}`,
      );

    const { type } = rest.ownProps,
      additionalPropsHash = ADDITIONAL_QUERIES_PARAMETERS_AND_PROPS[type];

    return {
      loading,
      refetch,
      variables,
      subscribeToMore,
      records,
      ...(additionalPropsHash &&
        additionalPropsHash['additionalPropsMethod'] &&
        additionalPropsHash['additionalPropsMethod'](data)),
      hasMore:
        !loading && records && records.results.length < records.total
          ? true
          : false,
      loadMoreEntries: (page) => {
        // Note: Additional check to avoid sending redundant requests
        if (records && records.results.length >= records.total) return;

        try {
          fetchMore({
            variables: {
              page: page,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              return {
                records: {
                  page: fetchMoreResult.records.page,
                  total: fetchMoreResult.records.total,
                  results: uniqBy(
                    [
                      ...previousResult.records.results,
                      ...fetchMoreResult.records.results,
                    ],
                    (obj) => `${obj.__typename}:${obj.id}`,
                  ),
                  __typename: 'Collection',
                },
                ...(additionalPropsHash &&
                  additionalPropsHash['additionalPropsMethod'] &&
                  additionalPropsHash['additionalPropsMethod'](
                    fetchMoreResult,
                  )),
              };
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
    };
  },
};

RecordsView = compose(
  ...keys(QUERY_MAPPER_WITH_PAGE).map((type) =>
    graphql(QUERY_MAPPER_WITH_PAGE[type], {
      skip: (props) =>
        difference(VALID_QUERIES_TYPES, [type]).some(
          (type) => type === props.queryType,
        ) || props.skipQuery,
      ...requestConfig,
    }),
  ),
)(RecordsView);
RecordsView = connect(({ device }) => ({ device }))(RecordsView);
RecordsView.defaultProps = {
  cardProps: {},
  queryType: 'DEFAULT',
  infiniteScroll: true,
  nextPageLoadRequired: true,
  perPage: 10,
  viewType: 'Card',
  onLoadingComplete: () => {},
  onResultClick: () => {},
  queryMapper: QUERY_MAPPER_WITH_PAGE,
  mergeCachedData: false,
  skillToLearnPortalId: null,
  loaderNotRequired: false,
  loadingCards: true,
  nonLodedEmptyCardsRequired: false,
  sliderProps: null,
  processCardObject: (card) => card,
  isGroupedDateRequired: true,
};
RecordsView = withRouter(RecordsView);
RecordsView = withCachedData(RecordsView);

export const RecordsViewContext = createContext({ canView: true });

const ConnectedRecordsView = (props) => {
  const payload = useMemo(
    () => ({
      canView: !CANNOT_VIEW_COMMENTS_QUERIES.includes(props.type),
    }),
    [props.type],
  );

  return (
    <RecordsViewContext.Provider value={payload}>
      <RecordsView {...props} />
    </RecordsViewContext.Provider>
  );
};
export default ConnectedRecordsView;
