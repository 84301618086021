import React, { Component } from 'react';
import Loader from 'react-loaders';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import CasePatientInfo from 'app/components/CasePatientInfo';
import CaseRightSectionContainer from 'app/components/CaseRightSection/Container';
import EventTracker from 'app/services/EventTracker';
import LectureViewHeaderListing from './LectureViewHeaderListing';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import SpaceActions from './SpaceActions';

import { isTouchSupported } from 'app/utils/deviceHelper';
import { getLecturePreviewTabs } from 'app/components/NavTabs/helper';
import { isEmpty } from 'app/utils/osLodash';
import { translate } from 'app/actions/flashMessage';

import { setCareCaseAsLecture } from 'app/actions/lectureView';

const CASE_QUERY = queryLoader('app/graphql/Care/Queries/Cases/Detail.gql');

class CareSpaceClvRightView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: this.getDefaultTab(),
    };
  }

  getDefaultTab() {
    return this.contentTabAvailable() ? 'content' : 'patient';
  }

  appointmentsAvailable() {
    let { data } = this.props;
    return data.case && data.case.appointments_count > 0;
  }

  entityLectureEmpty() {
    return isEmpty(this.props.lectureView.entity);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data.loading &&
      !this.props.data.loading &&
      !this.contentTabAvailable()
    ) {
      this.props.setCareCaseAsLecture(this.props.data.case);
    }

    if (
      prevState.screen !== this.state.screen &&
      this.state.screen === 'treatment'
    )
      this.props.setCareCaseAsLecture(this.props.data.case);
  }

  renderPatientInfo() {
    let { space } = this.props;
    return (
      <div className='quiz-right-section patient'>
        <CasePatientInfo
          patient={space?.owner?.patient}
          sharedWithUserIds={space.shared_with_user_ids}
        />
      </div>
    );
  }

  renderTreatmentSection() {
    let { case: kase } = this.props.data;
    if (this.props.data.loading) {
      return <Loader type='ball-triangle-path' active />;
    } else if (kase) {
      return (
        <CaseRightSectionContainer
          key={kase.id}
          kase={kase}
          careSpace={this.props.space}
        />
      );
    }
  }

  renderLectures() {
    return (
      <div className='col-md-4 pe-0 not-subscribed'>
        <LectureViewHeaderListing
          space={this.props.space}
          showTabName={!this.singleTabWithContent()}
        />
      </div>
    );
  }

  onChangeTab = (screen) => {
    EventTracker.trackSelectTabEvent(screen);
    this.setState({ screen });
  };

  isTabActive(tab) {
    return this.state.screen === tab;
  }

  renderBody() {
    switch (this.state.screen) {
      case 'patient':
        return this.renderPatientInfo();
      case 'treatment':
        return this.renderTreatmentSection();
      case 'content':
        return this.renderLectures();
      default:
        return null;
    }
  }

  renderCTAs() {
    let { space } = this.props;
    return <SpaceActions space={space} />;
  }

  contentTabAvailable() {
    return getLecturePreviewTabs(this.props.space).some(
      (tab) => tab.content_count > 0,
    );
  }

  treamentTabEnabled() {
    return this.props.space.is_author_or_editor || this.appointmentsAvailable();
  }

  renderTreatmentTab() {
    let className = 'insight-tab-text treatment-tab',
      isTabEnabled = this.treamentTabEnabled(),
      onClick = isTabEnabled
        ? this.onChangeTab.bind(this, 'treatment')
        : () => {};

    className += this.isTabActive('treatment') ? ' active' : '';
    className += isTabEnabled ? '' : ' tab-disabled';

    return (
      <a
        href='javascript:'
        className={className}
        onClick={onClick}
        data-hover={!isTouchSupported()}>
        <span className='tab-link-text'>Treatment</span>
        <div className='tab-link-tooltip'>
          <OrthoIcon name='sort-up' defaultClass='tooltip-arrow-top' />
          <div className='tooltip-container'>
            <OrthoIcon name='info' dataHoverNotRequired={true} />
            {translate('CARE_SPACE_TREATMENT_TAB_NOT_AVAILABLE')}
          </div>
        </div>
      </a>
    );
  }

  tabsWithContent() {
    return getLecturePreviewTabs(this.props.space).filter(
      (tab) => tab.content_count > 0,
    );
  }

  singleTabWithContent() {
    return this.tabsWithContent().length === 1;
  }

  getContentTabName() {
    let tabsWithContent = this.tabsWithContent();
    return tabsWithContent.length === 1 ? tabsWithContent[0].name : 'Content';
  }

  render() {
    return (
      <article className='col-md-4 clv-right-section pe-0'>
        <div className='case-right-header'>
          <div className='insight-tab-header clv-insight-tab-header'>
            <a
              href='javascript:'
              className={
                'insight-tab-text patient patient-info' +
                (this.isTabActive('patient') ? ' active' : '')
              }
              onClick={this.onChangeTab.bind(this, 'patient')}
              data-hover={!isTouchSupported()}>
              Patient
            </a>
            {this.renderTreatmentTab()}
            {this.contentTabAvailable() && (
              <a
                href='javascript:'
                className={
                  'insight-tab-text content-tab' +
                  (this.isTabActive('content') ? ' active' : '')
                }
                onClick={this.onChangeTab.bind(this, 'content')}
                data-hover={!isTouchSupported()}>
                {this.getContentTabName()}
              </a>
            )}
          </div>
          {this.renderCTAs()}
        </div>
        {this.renderBody()}
      </article>
    );
  }
}

CareSpaceClvRightView = connect(({ lectureView }) => ({ lectureView }), {
  setCareCaseAsLecture,
})(CareSpaceClvRightView);
CareSpaceClvRightView = graphql(CASE_QUERY, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: props.space.owner.nice_id,
    },
  }),
})(CareSpaceClvRightView);
export default CareSpaceClvRightView;
