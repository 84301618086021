import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withApollo } from '@apollo/client/react/hoc';

import AbstractSpaceView from './AbstractSpaceView';
import withSpaceRestriction from 'app/components/HOC/withSpaceRestriction';

import { flashError } from 'app/actions/flashMessage';
import { actionByGuestUser } from 'app/actions/guestUser';
import { openEditImageModal } from 'app/actions/editImage';

// Header for the Board detail view
class Header extends AbstractSpaceView {
  state = {
    deleteInitiated: false,
  };

  componentDidMount() {
    this.props.actionByGuestUser('');
  }

  getSpace() {
    return this.props.obj;
  }

  recoverSpace = () => {
    this.setState({ deleteInitiated: false });
  };

  toggleDelete = () => {
    this.setState({ deleteInitiated: !this.state.deleteInitiated });
  };

  onEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  isAuthorOrEditor() {
    return this.getSpace().is_author_or_editor;
  }

  isOwnedByUser() {
    return +this.getSpace().user.id === +this.props.currentUser.graph.id;
  }

  isMemberRequestStatusPending() {
    return this.getSpace().member_request_status === 'pending';
  }

  isMemberKindEditor() {
    return (
      this.getSpace().user_request &&
      this.getSpace().user_request.kind === 'editor'
    );
  }

  render() {
    return this.renderHeader();
  }
}

Header = connect(
  ({ currentUser, device, guestUser, systemConfig }) => ({
    currentUser,
    device,
    reducerGuestUser: guestUser,
    systemConfig,
  }),
  { actionByGuestUser, openEditImageModal, flashError },
)(Header);

Header = withApollo(Header);
Header = withRouter(Header);
Header = withSpaceRestriction(Header);
export default Header;
