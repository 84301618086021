import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CustomDropdown from 'app/components/Shared/CustomDropdown';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsLink from 'app/components/OsLink';
import UnreadIndicatorIcon from 'app/components/Shared/UnreadIndicatorIcon';

import {
  navActiveIdentifier,
  navActiveOption,
  navDropdownOptions,
  unreadCountPresentFor,
} from 'app/utils/Workspace/navHelper';
import Logo from 'app/images/qlark_logo.png';
import { APP_NAME } from 'app/constants';

class WorkspaceToggleDropdown extends PureComponent {
  dropdownInfo() {
    return {
      title:
        navActiveIdentifier() === APP_NAME
          ? this.renderLogoLink(true)
          : this.renderWorkspaceLogo(navActiveOption().name, true),
    };
  }

  renderLogoLink(unreadIndicatorRequired) {
    if (this.props.renderLogo)
      return (
        <>
          {this.props.renderLogo()}
          {unreadIndicatorRequired && this.renderUnreadIndicatorIfAnyPresent()}
        </>
      );
    return (
      <>
        <img src={Logo} alt='logo' className='' />
        {unreadIndicatorRequired && this.renderUnreadIndicatorIfAnyPresent()}
      </>
    );
  }

  renderWorkspaceLogo(name, unreadIndicatorRequired) {
    return (
      <>
        <OrthoIcon
          name='workspacelogo'
          defaultClass='workspace-icon-logo'
          dataHoverNotRequired={true}
        />
        <span className='text-truncate'>{name}</span>
        {unreadIndicatorRequired && this.renderUnreadIndicatorIfAnyPresent()}
      </>
    );
  }

  renderUnreadIndicatorIfAnyPresent() {
    const {
      communityUnreadConversationCount,
      communityUnreadNotificationCount,
    } = this.props;
    if (
      navDropdownOptions().some((option) =>
        unreadCountPresentFor(option, {
          communityUnreadConversationCount,
          communityUnreadNotificationCount,
        }),
      )
    )
      return <UnreadIndicatorIcon />;
  }

  renderWorkspaceOrLogo(option) {
    return (
      <OsLink href={`/${option.identifier}/explore`} className='nav-link'>
        {option.identifier === APP_NAME
          ? this.renderLogoLink()
          : this.renderWorkspaceLogo(option.name)}
      </OsLink>
    );
  }

  renderUnreadIndicatorIfRequired(option) {
    const {
      communityUnreadConversationCount,
      communityUnreadNotificationCount,
    } = this.props;

    if (
      unreadCountPresentFor(option, {
        communityUnreadConversationCount,
        communityUnreadNotificationCount,
      })
    )
      return <UnreadIndicatorIcon />;
  }

  renderRow = (option) => {
    const workspaceOrLogo = this.renderWorkspaceOrLogo(option);
    if (!this.props.navLink) {
      return (
        <li key={option.identifier} className='nav-item'>
          {workspaceOrLogo}
          {this.renderUnreadIndicatorIfRequired(option)}
        </li>
      );
    } else {
      return (
        <div key={option.identifier}>
          {workspaceOrLogo}
          {this.renderUnreadIndicatorIfRequired(option)}
        </div>
      );
    }
  };

  renderChildren() {
    return navDropdownOptions().map(this.renderRow);
  }

  render() {
    if (this.props.navLink) {
      let className = 'cs-dropdown wrk-drodown';
      return (
        <CustomDropdown
          name='qlark_logo_dropdown'
          chevronIcon='chevron-caret'
          className={className}
          dropdownClass='os-dropdown'
          dropdownInfo={this.dropdownInfo()}
          avoidEventManipulation={true}
          allowNonTextOption={true}>
          {this.renderChildren()}
        </CustomDropdown>
      );
    } else {
      return (
        <ul className='workspace-more-dropdown-list'>
          {this.renderChildren()}
        </ul>
      );
    }
  }
}

WorkspaceToggleDropdown.defaultProps = {
  navLink: false,
};
WorkspaceToggleDropdown = connect(
  ({ currentUser }) => ({
    communityUnreadConversationCount:
      currentUser.graph.unread_conversations_count,
    communityUnreadNotificationCount:
      currentUser.graph.unread_notifications_count,
  }),
  {},
)(WorkspaceToggleDropdown);

export default WorkspaceToggleDropdown;
