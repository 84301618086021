import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const SidebarMenuModal = ({ children, hideModal }) => {
  const portalRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', closeModal, false);
    }, 100);

    return () => {
      document.removeEventListener('click', closeModal, false);
    };
  }, []);

  const closeModal = ({ target }) => {
    if (portalRef.current && !portalRef.current.contains(target)) {
      hideModal();
    }
  };
  return createPortal(
    <div ref={portalRef} className='group-menu-modal'>
      {children}
    </div>,
    document.body,
  );
};

export default SidebarMenuModal;
