import React, { Component } from 'react';

import Avatar from 'app/components/Shared/Avatar';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';
import { avatarAttributes } from 'app/utils/entitiesHelper';

class UserRowInWidget extends Component {
  render() {
    let { user } = this.props;
    return (
      <li className='dr-connect-list-item d-flex' key={user.id}>
        <div className='avatar-link'>
          <Avatar
            className='avatar avatar-40 connect-avatar'
            {...avatarAttributes(user)}
          />
        </div>
        <div className='connection-info'>
          <div className='user-name-info d-block'>
            <UserQualificationInfo
              user={user}
              linkRequired={false}
              titleRequired={true}
              designationRequired={false}
            />
          </div>
          <small className='small'>
            <UserQualificationInfo
              user={user}
              nameNotRequired={true}
              designationRequired={this.props.designationRequired}
            />
          </small>
        </div>
      </li>
    );
  }
}

export default UserRowInWidget;
