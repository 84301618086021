import React from 'react';
import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';

import { humanize } from 'app/utils/stringHelper';
import { currentUserEmail } from 'app/utils/userHelper';

const CHECK_USER_EXISTS_QUERY = queryLoader('app/graphql/CheckUserExists.gql');

const asyncValidate = (values, field) => {
  // ToDo: Check quick scenario
  if (['email', 'phone_number'].includes(field)) {
    let value = values[field];
    return client
      .query({
        query: CHECK_USER_EXISTS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          attribute: field,
          value,
        },
      })
      .then(({ data: { user_exists } }) => {
        if (user_exists.success) {
          var returnErrors = {};
          if (field !== 'email' || currentUserEmail() !== value) {
            returnErrors[field] = `${humanize(field)} has already been taken.`;
            return returnErrors[field];
          }
          return returnErrors;
        }
      });
  } else {
    // Hack for asynvalidate called without field
    return new Promise(function (resolve) {
      setTimeout(resolve, 100);
    });
  }
};

export default asyncValidate;
