import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import withAuthorize from 'app/components/HOC/withAuthorize';

import OrthoIcon from 'app/components/Shared/OrthoIcon';

import EventTracker from 'app/services/EventTracker';
import { setNavSearchOpen } from 'app/actions/navSearch';
import { logout } from 'app/actions/authentication';

import { entityUrl } from 'app/utils/entitiesHelper';
import { getNotificationUrl } from 'app/utils/userHelper';
import { snakeCase } from 'app/utils/osLodash';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';
import { openOnboardingModalWithScreen } from 'app/actions/onboardingTips';
import { customCount } from 'app/utils/textFormatting';
import { MAIL_TO_SUPPORT } from 'app/constants';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class MobileNavItem extends Component {
  onSpanContentClick = (methodName) => {
    this.props.onClick();
    this.props.openUpgradeModalIfUnauthorized(this[methodName], {});
  };

  redirectToSupportChat = () => {
    EventTracker.track('Navigate', { page: 'Support' });
    this.trackDropdownClick('support');
    let support_conversation_id = this.getCurrentUser().support_conversation_id;
    if (support_conversation_id) {
      this.props.navigate(`/conversations/${support_conversation_id}`);
    } else {
      window.location.href = MAIL_TO_SUPPORT;
    }
  };

  openUpdatePasswordModal = () => {
    this.props.openPasswordUpdateModal();
  };

  onCommunicationLinkClick = () => {
    this.props.navigate('/communications');
  };

  trackDropdownClick(name) {
    EventTracker.trackDropdownClick('mobile_nav_more', snakeCase(name));
  }

  toggleNavDropdown = () => {
    this.props.toggleNavDropdown();
  };

  logout = () => {
    this.trackDropdownClick('logout');
    this.props.logout();
  };

  getCurrentUser() {
    return this.props.currentUser.graph;
  }

  getCurrentUserProfileUrl() {
    return entityUrl(this.getCurrentUser());
  }

  openNotificationPage = () => {
    this.trackDropdownClick('notifications');
    this.props.navigate(getNotificationUrl());
  };

  openProfilePage = () => {
    this.trackDropdownClick('my_profile');
    this.props.navigate(this.getCurrentUserProfileUrl());
  };

  isCurrentUserProfile() {
    let paramsId = this.props.match.params.id;
    return paramsId === this.getCurrentUser().nice_id;
  }

  isTabActive() {
    const { name } = this.props;
    if (isCareWorkspaceView()) {
      if (name === 'home' && !this.props.navDropdownActive) {
        const currentPath = this.props.location.pathname;
        return currentPath.startsWith(this.props.path);
      } else if (name === 'more') {
        return this.props.navDropdownActive;
      }
    }
    return false;
  }

  onLinkClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.openUpgradeModalIfUnauthorized(this.onNavLinkClick);
    this.props.navDropdownActive && this.toggleNavDropdown();
  };

  onNavLinkClick = () => {
    let { name, path } = this.props;
    this.props.navigate(path);
    this.props.onClick();
    this.props.navDropdownActive && this.trackDropdownClick(name);
  };

  countInfo() {
    let { countRequired, name: key } = this.props;
    if (countRequired) {
      let notificationsCount = this.getCurrentUser().unread_notifications_count,
        messagesCount = this.getCurrentUser().unread_conversations_count;

      if (['more', 'notifications'].includes(key) && notificationsCount > 0) {
        return { topNavCount: true, count: notificationsCount };
      } else if (key === 'messages' && messagesCount > 0) {
        return { topNavCount: true, count: messagesCount };
      }
    }
  }

  renderCount() {
    let countInfo = this.countInfo();
    console.log(countInfo);
    if (countInfo) {
      let { topNavCount, count } = countInfo;
      return (
        <span
          className={
            topNavCount ? 'm-notifications-count' : 'mobile-notification-count'
          }>
          {topNavCount ? customCount(count) : count}
        </span>
      );
    }
  }

  getLinkDisplayName() {
    return this.props.title;
  }

  renderLinkContent() {
    const { iconName, path } = this.props;
    return (
      <Link
        to={path}
        className={`${this.isTabActive() ? 'active' : ''} nav-link`}
        onClick={this.onLinkClick}>
        <OrthoIcon name={iconName} defaultClass={'icon-nav'} />
        <span className='site-nav-text'>{this.getLinkDisplayName()}</span>
      </Link>
    );
  }

  renderTwoFactorAuthentication = () => {
    return this.props.openOnboardingModalWithScreen('mobile_verification');
  };

  renderSpanContent() {
    let { iconName, onClickMethodName } = this.props;

    return (
      <span
        className={`${this.isTabActive() ? 'active' : ''} nav-link`}
        onClick={this.onSpanContentClick.bind(this, onClickMethodName)}>
        <OrthoIcon name={iconName} defaultClass={'icon-nav'} />
        <span className='site-nav-text'>{this.getLinkDisplayName()}</span>
        {this.renderCount()}
      </span>
    );
  }

  openSearchField = () => {
    EventTracker.track('Navigate - Search Box Clicked');
    this.props.openUpgradeModalIfUnauthorized(this.props.setNavSearchOpen, {});
  };

  renderContent() {
    const { onClickMethodName } = this.props;

    return onClickMethodName
      ? this.renderSpanContent()
      : this.renderLinkContent();
  }

  render() {
    let { name } = this.props;
    return (
      <li className={`nav-item  ${name.toLocaleLowerCase()}`} key={name}>
        {this.renderContent()}
      </li>
    );
  }
}

MobileNavItem.defaultProps = {
  onClick: () => {},
  toggleNavDropdown: () => {},
};
MobileNavItem = connect(
  ({ activeResource, currentUser, navSearch }) => ({
    activeResource,
    currentUser,
    navSearch,
  }),
  {
    logout,
    openPasswordUpdateModal,
    setNavSearchOpen,
    openOnboardingModalWithScreen,
  },
)(MobileNavItem);
MobileNavItem = withAuthorize(MobileNavItem);
MobileNavItem = withRouter(MobileNavItem);
export default MobileNavItem;
