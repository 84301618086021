import { useContext } from 'react';
import Avatar from 'app/components/Shared/Avatar';
import { HIDE_TEAM_WIDGET_FOR_MY_TASKS_URLS } from 'app/components/Task/taskConstant';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import { Accordion } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { useParams } from 'react-router-dom';

function TeamWidget(props) {
  const params = useParams();
  const { all_members } = useContext(WorkspaceContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnMemberClick = (item) => {
    let user = searchParams.get('user');
    if (user === item.id) return;
    searchParams.set('user', item.id);
    setSearchParams(searchParams);
  };

  return (
    <>
      {HIDE_TEAM_WIDGET_FOR_MY_TASKS_URLS.includes(params['*']) ? null : (
        <div className='labelsContainer'>
          <Accordion className={`right-ac sidebar-container container`}>
            <Accordion.Item>
              <Accordion.Header className='rt-ac-header widget-title header labelsContainerHeader'>
                <span className='headerTitle'>Filter by Team Member</span>
              </Accordion.Header>
              <Accordion.Body className='labels-container-body'>
                {all_members.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className='member'
                        onClick={() => handleOnMemberClick(item)}>
                        <Avatar
                          className='avatar'
                          {...avatarAttributes(item)}
                        />
                        <span className='memberName'>{item.full_name}</span>
                      </div>
                    </>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </>
  );
}
export default TeamWidget;
