import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion } from 'react-bootstrap';

import GuardianForm from 'app/components/PatientForm/GuardianForm';
import OsBtn from 'app/components/OsBtn';
import OsGrid from 'app/components/OsGrid';
import RecordsView from 'app/components/RecordsView';
import RenderCards from 'app/components/Cards/RenderCards';

import { omit } from 'app/utils/osLodash';
import { addContactParty } from 'app/actions/contactPartyModal';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

class PatientAndResponseParties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: (this.requiredData() || []).length,
    };
  }

  openContactParty = () => {
    this.props.addContactParty({ space: this.props.widgetSourceObject });
  };

  isAuthorOrEditor() {
    return this.props.widgetSourceObject.is_author_or_editor;
  }

  isCurrentUserIsPatient() {
    let {
      currentUser,
      widgetSourceObject: { owner },
    } = this.props;
    return currentUser.graph.id === owner.patient.user_id;
  }

  getWidgetHeader() {
    if (isCareWorkspaceView() && this.isCurrentUserIsPatient()) {
      return 'Your Info';
    } else if (isCareWorkspaceView()) {
      return 'Patient Info';
    } else {
      return 'Patient Details';
    }
  }

  renderWidgetHeader() {
    let text = this.getWidgetHeader();
    return (
      <Accordion.Header className='rt-ac-header widget-title' key=''>
        <span className='widget-title'>{text}</span>
        {this.isAuthorOrEditor() && (
          <OsBtn
            name='BtnIcon'
            extraClass='no-text px-3 web-view-btn'
            icon='add'
            label='add patient btn'
            onClick={(e) => {
              this.openContactParty();
              e.stopPropagation();
            }}
          />
        )}
      </Accordion.Header>
    );
  }

  setCollectionTotal = (count) => {
    this.setState({ count });
  };

  requiredData() {
    const sourceObject = this.props.widgetSourceObject;
    if (sourceObject && sourceObject.owner?.patient) {
      let patient = omit(sourceObject.owner.patient, 'person_connections');
      return [patient].concat(
        sourceObject.owner.patient.person_connections || [],
      );
    }
  }

  renderData() {
    let sourceObject = this.props.widgetSourceObject,
      data = this.requiredData();

    if (data) {
      return (
        <RenderCards
          objects={data}
          orderRowWise={false}
          size='extra-small'
          cardGridIdentifier='CareSpaceColumn:PatientCardRightSidebarWidget'
          sourceObject={sourceObject}
        />
      );
    } else {
      return (
        <RecordsView
          type='PatientAndResponseParties'
          queryType='PEOPLE_INDEX'
          idQuery={sourceObject.owner.nice_id}
          page={0}
          perPage={8}
          infiniteScroll={false}
          showMoreNotRequired={true}
          size='extra-small'
          cardGridIdentifier={'CareSpaceColumn:PatientCardRightSidebarWidget'}
          setCollectionTotal={this.setCollectionTotal}
          collectionTotal={this.state.count}
          cardProps={{ sourceObject }}
        />
      );
    }
  }

  render() {
    return (
      <>
        <OsGrid
          identifier='DetailRightSidebar:LearningHubBlock'
          extraClass={this.state.count ? '' : 'd-none'}>
          <Accordion
            defaultActiveKey={'1'}
            className={`right-ac sidebar-container patient-detail-sidebar  ${
              this.props.sideBarClass || ''
            }`}>
            <Accordion.Item
              eventKey={'1'}
              className={`patient-detail-widget ${
                this.props.device.mobile ? 'mobile-device' : ''
              }`}>
              {this.renderWidgetHeader()}
              <Accordion.Body>{this.renderData()}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </OsGrid>
        <GuardianForm />
      </>
    );
  }
}

PatientAndResponseParties = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {
    addContactParty,
  },
)(PatientAndResponseParties);

export default PatientAndResponseParties;
