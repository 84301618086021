import React, { PureComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import AuthenticatedLayout from 'app/components/Layouts/AuthenticatedLayout';
import TabLayout from './TabLayout';

import { keys } from 'app/utils/osLodash';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

const REACT_APP_ENVIRONMENT_PRODUCTION = 'production';

const TAB_ROUTES_MAPPER = {
  iconDesigns: {
    path: 'icons',
    component: TabLayout,
    layout: AuthenticatedLayout,
    titleMetaInfo: {
      text: 'Designs - Icon',
    },
    hidden:
      process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENT_PRODUCTION,
  },
  svgDesigns: {
    path: 'svg',
    component: TabLayout,
    layout: AuthenticatedLayout,
    titleMetaInfo: {
      text: 'Designs - SVG',
    },
    hidden:
      process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENT_PRODUCTION,
  },
};

class Designs extends PureComponent {
  getDefaultUrl() {
    let isOnProduction =
      process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENT_PRODUCTION;
    return isOnProduction
      ? '/not_found'
      : withWorkspaceIdentifier('/designs/icons');
  }

  renderRoute = (tab) => {
    const {
      Component,
      componentProps = {},
      path,
      hidden,
    } = TAB_ROUTES_MAPPER[tab];
    if (!hidden)
      return (
        <Route
          key={path}
          path={`${path}`}
          element={<Component {...componentProps} />}
        />
      );
  };

  render() {
    return (
      <Routes>
        <Route element={<TabLayout />}>
          {keys(TAB_ROUTES_MAPPER).map((tab) => this.renderRoute(tab))}
        </Route>
        <Route
          path='*'
          element={
            <Navigate to={this.getDefaultUrl()} skip={true} replace={true} />
          }
        />
      </Routes>
    );
  }
}

export default Designs;
