import React from 'react';
import Loader from 'react-loaders';
import { Accordion } from 'react-bootstrap';

import OsBtn from 'app/components/OsBtn';
import WidgetTask from 'app/components/Task/WidgetTask';
import useRelatedTaskWidget from 'app/hooks/useRelatedTaskWidget';
import Tippy from '@tippyjs/react';
import useClinicSubscribedAccess from 'app/hooks/useClinicSubscribedAccess';

const RelatedTaskWidget = ({ widgetSourceObject }) => {
  const {
    dispatch,
    loading,
    listRef,
    tasks,
    data,
    teamMembersData,
    handleScroll,
    redirectToAllTasks,
    updateList,
    handleOpenTaskModal,
    deleteTask,
    setTasks,
  } = useRelatedTaskWidget({ widgetSourceObject });

  return (
    <div className='relatedTaskWidget'>
      <Accordion className={`right-ac sidebar-container container`}>
        <Accordion.Item>
          <Accordion.Header className='rt-ac-header widget-title header'>
            <div className='headerTitle'>
              <span>Related Tasks</span>
              <span className='seeAllBtn' onClick={() => redirectToAllTasks()}>
                <OsBtn
                  name='BtnTertiary'
                  extraClass='no-text px-3 web-view-btn'
                  icon=''
                  text='See all'
                  label='add patient btn'
                />
              </span>
            </div>
            <div className='btnContainer' onClick={(e) => e.stopPropagation()}>
              <Tippy
                placement={'bottom'}
                content={'Create new Task'}
                delay={20}
                animation={'fade'}>
                <div className='plusBtn' onClick={() => handleOpenTaskModal()}>
                  <OsBtn
                    name='BtnIcon'
                    extraClass='no-text px-3 web-view-btn'
                    icon='task'
                    label='add patient btn'
                  />
                </div>
              </Tippy>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {tasks.length == 0 && loading ? (
              <Loader type='ball-triangle-path' active />
            ) : (
              <>
                <div
                  className='relatedTaskList'
                  onScroll={handleScroll}
                  ref={listRef}>
                  {tasks.map((task, index) => {
                    return (
                      <div>
                        <WidgetTask
                          task={task}
                          key={task.id}
                          assigneeList={
                            teamMembersData?.workspace?.all_members || []
                          }
                          deleteTaskFromList={deleteTask}
                          updateList={updateList}
                          allRelatedTasks={tasks}
                          setRelatedTasksList={setTasks}
                        />
                        {tasks.length > index + 1 && (
                          <div className='line'></div>
                        )}
                      </div>
                    );
                  })}
                  {/* {loading && <div className="home-loader" style={{ maxHeight: '40px' }}></div>} */}
                </div>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default RelatedTaskWidget;
