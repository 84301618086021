import client from 'app/graphql/client';
import { loader as queryLoader } from 'graphql.macro';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';
import { ENABLED_TEMPLATE_TYPE } from 'app/components/Templates/constants';

const GET_ALL_TEMPLATES = queryLoader('app/graphql/Template/GetTemplates.gql');

const templateSuggestion = () => {
  const queryFunction = async ({ query, editor }) => {
    const { entity } = editor.options?.editorProps;
    const { data } = await client.query({
      query: GET_ALL_TEMPLATES,
      variables: {
        page: 0,
        per_page: 10,
        text_query: query,
        type: ENABLED_TEMPLATE_TYPE,
        sortQuery: 'name_asc',
      },
    });
    return {
      mention: data.records.results,
      entity: entity,
    };
  };

  return {
    items: (query) => queryFunction(query),
    ...suggestionConfig,
  };
};

export default templateSuggestion;
