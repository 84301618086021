import React from 'react';
import { avatarAttributes } from 'app/utils/entitiesHelper';

import Avatar from '../Shared/Avatar';
import { currentUser } from 'app/utils/userHelper';
import { hexToRgb } from 'app/utils/colorHelper';
import RenderSubLabels from 'app/components/Shared/RenderSubLabels';

function SmallScreenWidget({
  title,
  show,
  setShow,
  labelsData,
  teamMembersData,
  handleMemberClick,
  handleLabelClick,
  showMembers,
  showUncategorizedLabel,
}) {
  const modifiedLabelData = (data) => {
    if (data && data.length > 0 && showUncategorizedLabel) {
      const uncategorizedIndex = data.findIndex(
        (e) => e.name.toLowerCase() === 'uncategorized',
      );
      if (uncategorizedIndex === -1) {
        return [
          {
            color: '808080',
            id: '',
            displayName: 'uncategorized',
            name: 'uncategorized',
            __typename: 'Label',
          },
          ...data,
        ];
      }
    }
    return data;
  };

  const changeUserRoute = (item) => {
    if (item.id === currentUser().id) {
      handleMemberClick({
        ...item,
        label: 'My Tasks',
      });
    } else {
      handleMemberClick(item);
    }
    setShow(false);
  };

  return (
    <div>
      {show && labelsData?.length !== 0 && (
        <div
          className='smallerScreenWidget'
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          <div className='labelContainer'>
            {title && <div className='heading'>{title}</div>}
            <div className='labels'>
              {modifiedLabelData(labelsData).map((item, index) => {
                return (
                  <div
                    key={index}
                    className='label'
                    style={{ backgroundColor: `${hexToRgb(item.color)}` }}
                    onClick={() => {
                      handleLabelClick(item);
                      setShow(false);
                    }}>
                    <span className='labelText'>
                      {item.displayName.toUpperCase()}
                    </span>
                  </div>
                );
              })}
              <RenderSubLabels
                labels={labelsData}
                handleLabelClick={handleLabelClick}
              />
            </div>
          </div>
          {showMembers && (
            <div className='teamContainer'>
              <div className='heading'>TEAM</div>
              <div className='members'>
                {teamMembersData?.slice(4).map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className='member'
                        onClick={() => changeUserRoute(item)}>
                        <Avatar
                          className='avatar avatar-16'
                          {...avatarAttributes(item)}
                        />
                        <span className='memberName'>{item.full_name}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SmallScreenWidget;

SmallScreenWidget.defaultProps = {
  title: 'LABELS',
  showUncategorizedLabel: false,
};
