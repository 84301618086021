import { CLOSE_TEAMSPACE_MODAL, OPEN_TEAMSPACE_MODAL } from 'app/actions/types';

export function openCreateTeamSpaceModal(options = {}) {
  return {
    type: OPEN_TEAMSPACE_MODAL,
    options: { ...options, type: 'new' },
  };
}

export function openEditTeamSpaceModal(spaceId, options = {}) {
  return {
    type: OPEN_TEAMSPACE_MODAL,
    spaceId,
    options: { ...options, type: 'update' },
  };
}

export function openInfoTeamSpaceModal(spaceId, options = {}) {
  return {
    type: OPEN_TEAMSPACE_MODAL,
    spaceId,
    options: { ...options, type: 'info' },
  };
}

export function openArchiveTeamSpaceModal(spaceId, options = {}) {
  return {
    type: OPEN_TEAMSPACE_MODAL,
    spaceId,
    options: { ...options, type: 'archive' },
  };
}

export function openLeaveTeamSpaceModal(spaceId, options = {}) {
  return {
    type: OPEN_TEAMSPACE_MODAL,
    spaceId,
    options: { ...options, type: 'leave' },
  };
}

export function closeTeamSpaceModal(options = {}) {
  return {
    type: CLOSE_TEAMSPACE_MODAL,
    options,
  };
}
