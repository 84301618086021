import { Component } from 'react';

import EventTracker from 'app/services/EventTracker';

import {
  keysPressedNonAlphabeticNonNumeric,
  isTabClicked,
} from 'app/utils/mixpanelKeyboardHelper';
import { omit, pick } from 'app/utils/osLodash';

const DEFINED_CONNECTED_EVENT_NAMES = [
  'onBlur',
  'onChange',
  'onFocus',
  'onKeyDown',
  'onKeyUp',
];
const NON_REACT_ATTRIBUTES = [
  'formGroupExtraClass',
  'isWrapperRequired',
  'osType',
  'saveInputRef',
  'showPasswordIcon',
];

export default class AbstractField extends Component {
  state = {
    keyPressed: [],
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.autoFocusRequired && this.props.autoFocusRequired)
      this.focusOnField();
  }

  focusOnField() {
    if (this.inputField) {
      this.inputField.focus();
      let tmpStr = this.inputField.value;
      this.inputField.value = '';
      this.inputField.value = tmpStr;
    }
  }

  handleCustomEvent() {
    return false;
  }

  targetName(e) {
    return e.target.name;
  }

  targetValue(e) {
    return e.target.value;
  }

  parsedEvent(e) {
    return this.handleCustomEvent() ? this.targetValue(e) : e;
  }

  saveInputRef = (ref) => {
    this.inputField = ref;
    this.props.autoFocusRequired && this.focusOnField();
    this.props.saveInputRef(ref);
  };

  onBlur = (e) => {
    EventTracker.trackInputBlurEvent(this.targetName(e), {
      charLength: this.targetValue(e).length,
    });
    let event = this.parsedEvent(e);
    this.props.onBlur(event);
    this.props.input.onBlur && this.props.input.onBlur(event);
  };

  onChange = (e) => {
    let event = this.parsedEvent(e);
    this.props.onChange && this.props.onChange(event);
    this.props.input.onChange && this.props.input.onChange(event);
  };

  onFocus = (e) => {
    EventTracker.trackInputFocusEvent(this.targetName(e));
    this.props.onFocus(e);
    this.props.input.onFocus && this.props.input.onFocus(e);
  };

  onKeyUp = (e) => {
    EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
    this.setState({ keyPressed: [] });
    this.props.onKeyUp(e);
    this.props.input.onKeyUp &&
      this.props.input.onKeyUp &&
      this.props.input.onKeyUp(e);
  };

  onKeyDown = (e) => {
    let key = e.key === 'Meta' ? 'cmd' : e.key,
      keyPressed = keysPressedNonAlphabeticNonNumeric(
        this.state.keyPressed,
        key,
      );
    this.setState({ keyPressed });
    if (isTabClicked(key)) {
      EventTracker.trackKeyPressed(this.state.keyPressed.join('_'));
      this.setState({ keyPressed: [] });
    }
    this.props.onKeyDown && this.props.onKeyDown(e);
    this.props.input.onKeyDown && this.props.input.onKeyDown(e);
  };

  getEventProps() {
    return pick(this, DEFINED_CONNECTED_EVENT_NAMES);
  }

  getClassName() {
    let {
      className,
      meta: { touched, error, submitError },
    } = this.props;
    return `${className || ''} ${
      touched && (error || submitError) ? 'error' : ''
    } form-control`;
  }

  getFieldProps() {
    let {
      autoComplete,
      input,
      placeholder,
      inputLimit,
      type,
      meta,
      ...restProps
    } = this.props;
    return {
      id: input.name || this.props.name,
      placeholder,
      type: this.props.type,
      maxLength: inputLimit,
      ref: this.saveInputRef,
      ...omit(input, DEFINED_CONNECTED_EVENT_NAMES, NON_REACT_ATTRIBUTES),
      ...omit(
        restProps,
        DEFINED_CONNECTED_EVENT_NAMES,
        NON_REACT_ATTRIBUTES,
        'children',
      ),
      className: this.getClassName(),
      autoComplete,
      ...this.getEventProps(),
    };
  }

  renderChildren() {
    let {
      hintRequired,
      children,
      type,
      meta: { touched, error, submitError, modified },
    } = this.props;

    if (
      (!(touched && (error || submitError)) && hintRequired && modified) ||
      type === 'file'
    )
      return children;
  }
}
