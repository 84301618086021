import React from 'react';
import { useLocalStorage } from 'app/hooks/useLocalStorage';

const Emoji = (props) => {
  const [skinIndex] = useLocalStorage('emoji-mart.skin', ''),
    shortcodes =
      skinIndex === 1
        ? `:${props.id}:`
        : `:${props.id}::skin-tone-${skinIndex}:`,
    data = props.active && false ? { shortcodes } : { id: props.id };
  return <em-emoji {...data} />;
};

export default Emoji;
