import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import Loader from 'react-loaders';

import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsField from 'app/components/OsField';
import RecordsPager from 'app/components/RecordsPager';
import RenderLoadingCards from 'app/components/Cards/RenderLoadingCards';

import { cardSize } from 'app/utils/osCardHelper';
import { debounce } from 'app/utils/osLodash';
import { perPageValueForDevice } from 'app/utils/requestHelper';
import { isTeamFolderSpace, isWorkspaceType } from 'app/utils/spaceHelper';

import { translate } from 'app/actions/flashMessage';
import {
  getWorkspaceData,
  isWorkspaceSpace,
} from 'app/utils/Workspace/generalHelper';
import InviteMemberBtn from 'app/components/Shared/InviteMemberBtn';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

const MEMBERS_COUNT_LIST = [
  'admin_and_editors_count',
  'non_connected_members_count',
  'collaborating_clinics_count',
];
const SEARCH_REQUIRED_MEMBER_COUNT = 5;

class MembersView extends Component {
  state = {
    keyToggler: true,
    inputActive: false,
    searchText: '',
  };

  componentWillReceiveProps(nextProps) {
    let currentSpace = this.getSpace(),
      nextSpace = nextProps.data.space || nextProps.data.company;
    if (currentSpace.members_count < nextSpace.members_count)
      this.setState({ keyToggler: !this.state.keyToggler });
  }

  componentDidMount() {
    let state = this.state;
    MEMBERS_COUNT_LIST.forEach((key) => {
      if (this.getSpace()[key] != undefined) {
        state[key] = this.getSpace()[key];
      }
    });
    this.setState(state);
  }

  getSpace() {
    return this.props.data.space || this.props.data.company;
  }

  listLayoutRequired() {
    return this.getSpace().members_listing_layout === 'list';
  }

  setSearchText = debounce((searchText) => {
    this.setState({ searchText });
  }, 200);

  onChange = (e) => {
    let searchText = e.target.value;
    searchText = (searchText && searchText.trim()) || '';
    this.setSearchText(searchText);
  };

  renderList() {
    let { space } = this.props.data,
      recordId = space.id;
    if (isTeamFolderSpace(space)) {
      return (
        <RecordsPager
        key={this.state.keyToggler}
          queryType='SPACE_MEMBERS'
          displayType='GROUP_MEMBERS'
          recordId={recordId}
          recordType='Board'
          space={space}
          type='SpaceMemberCollaborators'
          textQuery={this.state.searchText}
          scrollIfNeeded={this.props.scrollIfNeeded}
        />
      );
    } else {
      return (
        <RecordsPager
          key={this.state.keyToggler}
          queryType='SPACE_DETAILS'
          recordId={recordId}
          recordType='Board'
          space={space}
          type='SpaceAcceptedOrPendingCollaborators'
          textQuery={this.state.searchText}
          scrollIfNeeded={this.props.scrollIfNeeded}
        />
      );

    }
  }

  setCollectionTotal = (type, total) => {
    let state = this.state;
    state[type] = total;
    this.setState(state);
  };

  renderGroupedSection(type) {
    let space = this.getSpace(),
      details = {};

    switch (type) {
      case 'admin_and_editors':
        details = {
          count: space.admin_and_editors_count,
          heading: 'Admin',
          type: 'SpaceAdminAndEditors',
          collectionTotal: this.state.admin_and_editors_count,
          countType: 'admin_and_editors_count',
        };
        break;
      case 'non_connected_members':
        details = {
          count: space.non_connected_members_count,
          heading: 'Other members',
          type: 'SpaceNonConnectedMembers',
          collectionTotal: this.state.non_connected_members_count,
          countType: 'non_connected_members_count',
          infiniteScroll: isWorkspaceSpace(space),
        };
        break;
      case 'collaborating_clinics':
        details = {
          count: space.collaborating_clinics_count,
          heading: 'Communities',
          type: 'SpaceCollaboratingClinics',
          collectionTotal: this.state.collaborating_clinics_count,
          countType: 'collaborating_clinics_count',
        };
        break;
      default:
    }

    return (
      <div className='space-members-group-container'>
        {this.state[details.countType] > 0 && (
          <span className='spc-tab-heading'>
            {details.heading} ({this.state[details.countType]})
          </span>
        )}
        <RecordsPager
          infiniteScroll={!!details.infiniteScroll}
          initialLimit={perPageValueForDevice(3, this.props.device)}
          limit={12}
          loadingCards={true}
          queryType='SPACE_DETAILS'
          recordId={space.nice_id}
          recordType='Board'
          textQuery={this.state.searchText}
          type={details.type}
          withRecord={false}
          scrollIfNeeded={this.props.scrollIfNeeded}
          setCollectionTotal={this.setCollectionTotal.bind(
            this,
            details.countType,
          )}
          collectionTotal={details.collectionTotal}
          cardProps={{ size: 'extra-small' }}
          cardGridIdentifier={'SpaceDetail:Card'}
          cardSize={'extra-small'}
        />
      </div>
    );
  }

  renderGroupedLayout() {
    let space = this.getSpace(),
      adminEditorsCount = this.state.admin_and_editors_count,
      collaboratingClinicsCount = this.state.collaborating_clinics_count,
      nonConnectedMembersCount = this.state.non_connected_members_count,
      totalResults =
        adminEditorsCount +
        nonConnectedMembersCount +
        collaboratingClinicsCount,
      translateKey = this.state.searchText
        ? 'RESULTS_NOT_FOUND'
        : 'NO_MEMBERS_FOUND';

    return (
      <>
        <div>
          {this.renderGroupedSection('admin_and_editors')}
          {this.renderGroupedSection('collaborating_clinics')}
          {this.renderGroupedSection('non_connected_members')}
        </div>
        {totalResults === 0 && (
          <>
            <div className='no-cases-block'>
              <div className='no-cases-image-block'>
                <Icon name='course' />
              </div>
              <div className='no-cases-message-block'>
                {translate(
                  translateKey,
                  {
                    searchInput: this.state.searchText,
                  },
                  true,
                  { connector: '' },
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  renderCollection() {
    if (this.listLayoutRequired()) {
      return this.renderList();
    } else {
      return this.renderGroupedLayout();
    }
  }

  renderLoader() {
    if (this.listLayoutRequired()) {
      return <Loader type='ball-triangle-path' active />;
    } else {
      return (
        <RenderLoadingCards
          className='col-12 col-md-4 col-lg-3 mb-4 col-sm-6 mt-3'
          count={9}
          size={cardSize({
            width: this.props.device.width,
            mobileDeviceLandscape: this.props.device.mobileDeviceLandscape,
            size: 'extra-small',
          })}
        />
      );
    }
  }

  setInputActive = () => {
    this.setState({ inputActive: true });
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  renderSearch() {
    if (this.props.tabDetails.search_required)
      return (
        <div className='input-wrapper nav-search-holder input-focus space-search-container'>
          <SetPageTitle source='member_view' />
          <div
            className={`nav-search-container ${this.state.inputActive ? 'input-focus-active' : ''
              }`}
            onFocus={this.setInputActive}
            onBlur={this.unsetInputActive}>
            <label className='position-relative m-0 w-100'>
              <OrthoIcon name='search' />
              <OsField
                osType='input'
                type='text'
                placeholder='Search here'
                className='nav-search-input'
                name='search'
                onChange={this.onChange}
              />
            </label>
          </div>
        </div>
      );
  }

  renderTabName() {
    if (isWorkspaceType(this.getSpace()))
      return (
        <div className='workspace-space-page-heading col'>
          {this.props.tabDetails.name}
        </div>
      );
  }

  renderSearchContainer() {
    if (isWorkspaceType(this.getSpace())) {
      return <div className='container-fluid px-0'>{this.renderSearch()}</div>;
    } else {
      return this.renderSearch();
    }
  }

  renderSearchFieldContainer() {
    return <>{this.renderSearchContainer()}</>;
  }

  memberCount() {
    return this.props.tabDetails?.content_count;
  }

  searchRequired() {
    return this.memberCount() > SEARCH_REQUIRED_MEMBER_COUNT;
  }

  render() {
    let space = this.getSpace();
    return (
      <>
        <div className='container-fluid px-0 workspace-page-head member-head'>
          <div className='row d-flex justify-content-between'>
            {this.renderTabName()}
            <div className='page-heading-right col'>
              {this.searchRequired() && this.renderSearchFieldContainer()}
              {isWorkspaceType(space) && <InviteMemberBtn space={space} />}
            </div>
          </div>
          {this.props.data && this.props.data.loading
            ? this.renderLoader()
            : this.renderCollection()}
        </div>
      </>
    );
  }
}

MembersView.defaultProps = {
  tabDetails: {},
};
MembersView = withRouter(MembersView);
MembersView = connect(({ device }) => ({ device }))(MembersView);
export default MembersView;
