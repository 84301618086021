import { useContext, useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import StoreUpdater from 'app/services/StoreUpdater';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { useSelector } from 'react-redux';
import { openInfoModal } from 'app/actions/infoModal';
import { useDispatch } from 'react-redux';

const DELETE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/DeleteLabel.gql',
);
const UPDATE_LABEL_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateLabel.gql',
);
function useLabelsView() {
  const { task_labels, partner_labels, person_labels, global_labels } =
    useContext(WorkspaceContext);
  const workspaceIdentifier = useSelector(
    (state) => state.workspace.identifier,
  );
  const dispatch = useDispatch();
  const [refreshList, setRefreshList] = useState(false);
  const [labels, setLabels] = useState({
    task: task_labels.sort((a, b) => a.name.localeCompare(b.name)),
    person: person_labels.sort((a, b) => a.name.localeCompare(b.name)),
    partner: partner_labels.sort((a, b) => a.name.localeCompare(b.name)),
    global: global_labels.sort((a, b) => a.name.localeCompare(b.name)),
  });
  const [order, setOrder] = useState({
    task: true,
    partner: true,
    person: true,
    appointment: true,
    global: true,
  });
  const [visible, setVisible] = useState({
    task: true,
    partner: true,
    person: true,
    appointment: true,
    global: true,
  });
  const [newLabel, setNewLabel] = useState(null);

  const [deleteLabel] = useMutation(DELETE_LABEL_MUTATION);

  const [updateLabel] = useMutation(UPDATE_LABEL_MUTATION);

  const sortReverseAlphabetically = (labels) => {
    return labels.sort((a, b) => b.name.localeCompare(a.name));
  };

  const sortAlphabetically = (labels) => {
    return labels.sort((a, b) => a.name.localeCompare(b.name));
  };

  const setVisibleState = (key) => {
    setVisible((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const setOrderState = (key) => {
    if (order[key] === true) {
      setLabels((prev) => ({
        ...prev,
        [key]: sortReverseAlphabetically(labels[key]),
      }));
    } else {
      setLabels((prev) => ({
        ...prev,
        [key]: sortAlphabetically(labels[key]),
      }));
    }

    setOrder((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const deleteSelectedLabel = async (label, type) => {
    if (label.id === -1) {
      setNewLabel(null);
      return;
    }

    try {
      let response = await deleteLabel({
        variables: {
          id: label.id,
        },
      });
      if (!response.data.deleteLabel.success) {
        dispatch(
          openInfoModal('general', 'label_error_message', {
            contentInterpolations: {
              error: response.data.deleteLabel.error,
            },
            dangerouslySetInnerHTML: true,
            isBold: false,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getObjectsNotInSecondArray = (actualSubLabels, updatedSubLabel) => {
    return actualSubLabels
      .filter(
        (obj1) => !updatedSubLabel.some((obj2) => obj2.name === obj1.name),
      )
      .map((subLabel) => subLabel.id);
  };

  const updateSelectedLabel = async (label, type) => {
    setNewLabel(null);
    let variables = {
      color: label.color,
      name: label.name,
      type: type,
      subLabels: label.subLabels.map((subLabel) => subLabel.name),
    };

    if (label.id !== -1) {
      variables.id = label.id;
      let backendSubLabel;
      if (type === 'task') {
        backendSubLabel = task_labels.find((l) => l.id === label.id).sub_labels;
      } else if (type === 'person') {
        backendSubLabel = person_labels.find(
          (l) => l.id === label.id,
        ).sub_labels;
      } else if (type === 'partner') {
        backendSubLabel = partner_labels.find(
          (l) => l.id === label.id,
        ).sub_labels;
      } else {
        backendSubLabel = global_labels.find(
          (l) => l.id === label.id,
        ).sub_labels;
      }
      variables.deleteSubLabelsIds = getObjectsNotInSecondArray(
        backendSubLabel,
        label.subLabels,
      );
    }

    try {
      const response = await updateLabel({
        variables,
        update: (cache, { data: { updateLabel } }) => {
          if (!variables.id) {
            StoreUpdater.addLabelInList(
              updateLabel.entity,
              { id: workspaceIdentifier },
              {},
            );
            setTimeout(() => scrollToLabel(updateLabel?.entity.id), 1000);
          }
        },
      });

      if (!response.data.updateLabel.success) {
        setRefreshList(!refreshList);
        dispatch(
          openInfoModal('general', 'label_error_message', {
            contentInterpolations: {
              error: response.data.updateLabel.error,
            },
            dangerouslySetInnerHTML: true,
            isBold: false,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const scrollToLabel = (labelId) => {
    let postElement = document.getElementById('label-' + labelId);
    postElement.scrollIntoView({ behavior: 'smooth' });
  };

  const addNewLabel = (type) => {
    let label = {
      id: -1,
      name: '',
      color: 'fd9771',
      tasks_count: 0,
      patients_count: 0,
      id_type: type,
      subLabels: [],
    };

    setNewLabel(label);
  };

  useEffect(() => {
    setLabels({
      task: task_labels,
      person: person_labels,
      global: global_labels,
      partner: partner_labels,
    });
  }, [task_labels, partner_labels, person_labels, global_labels, refreshList]);

  return {
    refreshList,
    task_labels,
    person_labels,
    global_labels,
    labels,
    newLabel,
    order,
    visible,
    deleteSelectedLabel,
    updateSelectedLabel,
    addNewLabel,
    setOrderState,
    setVisibleState,
    setRefreshList,
  };
}

export default useLabelsView;
