import { Field, Form } from 'react-final-form';
import OsField from 'app/components/OsField';
import React, { useState } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

import { useMutation } from '@apollo/client';
import { translate } from 'app/actions/flashMessage';
import { useSelector, useDispatch } from 'react-redux';
import { getWorkspaceGuestOwnerData } from 'app/selectors/workspace';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';
import {
  composeValidators,
  fieldRequired,
  emailMustBeValid,
  passwordMustMatchValidityRules,
  passwordMustMatch,
} from 'app/utils/validationHelper';

import ConnectToSupport from 'app/components/ConnectToSupport';
import ImageFormLayout from 'app/components/Layouts/ImageFormLayout';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import PolicyAcceptCheckbox from 'app/components/PoliciesView/PolicyAcceptCheckbox';

import { login } from 'app/actions/authentication';

import lock from 'app/images/profile-switcher/lock.webp';

import useClinicShareRequest from 'app/hooks/partners/useClinicShareRequest';

const PARTNER_ONBOARD_MUTATION = queryLoader(
  'app/graphql/mutations/PartnerSpaces/Onboard.gql',
);

const SpaceAcceptForm = () => {
  const dispatch = useDispatch();
  const [showPolicyCheckboxError, setPolicyCheckboxError] = useState(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const guestOwnerData = useSelector(getWorkspaceGuestOwnerData);

  const [partnerOnboardMutation, { loading: requestInProgress }] = useMutation(
    PARTNER_ONBOARD_MUTATION,
  );

  const { data, loading, token } = useClinicShareRequest();

  const onSubmit = (values) => {
    return partnerOnboardMutation({
      variables: {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        token,
      },
    }).then(({ data: { clinicOnboard } }) => {
      if (clinicOnboard.errors) {
        let errors = {};
        errors[clinicOnboard.errors[0].field] = clinicOnboard.errors[0].message;
        return { ...errors };
      } else {
        let variables = {
          token: clinicOnboard.token,
          remember: false,
          email: clinicOnboard.entity.email,
          user: clinicOnboard.entity,
        };
        dispatch(login(variables));
      }
    });
  };

  const renderWorkspaceWelcomeText = () => {
    if (guestOwnerData) {
      if (loading || data.clinic_share_request) {
        return (
          <div className='ws-auth-info-area space-accept-welcome-text'>
            <div className='auth-heading'>
              Committed to Better Care, Together
            </div>
            <div
              className='patient-wel-text'
              dangerouslySetInnerHTML={{
                __html: translate('WELCOME_TEXT_PARTNERSPACE', {
                  clinic_name: guestOwnerData.name,
                }),
              }}
            />
          </div>
        );
      } else {
        return (
          <div style={{ margin: '204px 200px 0 120px' }}>
            <img
              style={{ width: '520px ', height: '520px' }}
              src={lock}
              alt={'lock'}
            />
          </div>
        );
      }
    } else {
      return translate('WELCOME_TEXT_CARESPACE');
    }
  };

  const renderLinkExpiredMessage = () => {
    return (
      <div>
        <h2
          style={{
            margin: '0 0 64px 0',
            color: 'var(--neutrals-level-11, #354554)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 900,
            lineHeight: '28px',
          }}>
          Invitation <br /> has expired
        </h2>
        <p>
          Sorry, the link you are trying to use has expired. <br />
          <br />
          Please contact the Partner Clinic to generate a new invitation
        </p>
      </div>
    );
  };

  const submitForm = (values) => {
    if (isPolicyChecked) {
      onSubmit(values);
    } else {
      setPolicyCheckboxError();
    }
  };

  const togglePoliciesAccepted = ({ target }) => {
    setIsPolicyChecked(target.checked);
    setPolicyCheckboxError(!target.checked);
  };

  const renderCredentialsForm = () => {
    if (loading) return <Loader type='ball-triangle-path' active />;
    if (!token) return <Navigate to='/login' />;
    if (!data.clinic_share_request) return renderLinkExpiredMessage();
    const { email, name } = data.clinic_share_request;
    const isEmailPresent = !!email;
    return (
      <Form
        onSubmit={submitForm}
        render={(props) => {
          return (
            <div className={'space-accept-form'}>
              <h2
                className={'form-title'}
                style={{
                  color: 'var(--neutrals-level-11, #354554)',
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 900,
                  lineHeight: '28px',
                  textAlign: 'center',
                  marginBottom: '64px',
                }}>
                Sign up
              </h2>
              <form onSubmit={props.handleSubmit}>
                <Field
                  name={'email'}
                  component={OsField}
                  label={'Owner or Admin Email'}
                  required={true}
                  osType={'input'}
                  initialValue={email}
                  disabled={true}
                  validate={composeValidators(fieldRequired, emailMustBeValid)}
                />
                {isEmailPresent && (
                  <div className='prefilled-email'>
                    <OrthoIcon
                      name='error'
                      defaultClass='me-1'
                      dataHoverNotRequired={true}
                    />
                    {translate(
                      'CARE_SPACE_PREFILLED_EMAIL_TEXT',
                      {
                        contact_team: (
                          <ConnectToSupport
                            text='contact clinic team'
                            extraClass='fs-12'
                          />
                        ),
                      },
                      false,
                    )}
                  </div>
                )}
                <Field
                  name={'clinicName'}
                  component={OsField}
                  label={'Clinic Name'}
                  required={true}
                  osType={'input'}
                  initialValue={name}
                  validate={fieldRequired}
                />
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      name={'firstName'}
                      component={OsField}
                      label={'First Name'}
                      required={true}
                      osType={'input'}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name={'lastName'}
                      component={OsField}
                      label={'Last Name'}
                      required={true}
                      osType={'input'}
                    />
                  </div>
                </div>
                <div className='col-md-12 p-0'>
                  <Field
                    name='password'
                    component={OsField}
                    osType='password'
                    label='New password'
                    hintRequired={true}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatchValidityRules,
                    )}
                    formGroupExtraClass='login_password reset_login_password required-label'>
                    <span className='reset-password-hint'>
                      {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                    </span>
                  </Field>
                </div>
                <div className='col-md-12 p-0'>
                  <Field
                    name='confirmPassword'
                    component={OsField}
                    osType='password'
                    label='Confirm Password'
                    hintRequired={false}
                    validate={composeValidators(
                      fieldRequired,
                      passwordMustMatch,
                    )}
                    formGroupExtraClass='login_password reset_login_password required-label'>
                    <span className='reset-password-hint'>
                      {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                    </span>
                  </Field>
                </div>
                <div className='col-md-12'>
                  <PolicyAcceptCheckbox
                    name='policy'
                    togglePoliciesAccepted={togglePoliciesAccepted}
                    validate={fieldRequired}>
                    {(showPolicyCheckboxError ||
                      (props.touched.policy && props.errors.policy)) && (
                      <div className='with-error'>
                        <span className='form-error'>
                          {' '}
                          <OrthoIcon
                            name='error'
                            dataHoverNotRequired='true'
                            defaultClass='ms-3 me-1 cl-red'
                          />{' '}
                          Must be checked
                        </span>
                      </div>
                    )}
                  </PolicyAcceptCheckbox>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <OsBtn
                    type={'submit'}
                    htmlTag={'button'}
                    extraClass={'web-view-btn btn-save-info'}
                    name={'BtnPrimary'}
                    text={'SIGN UP'}
                    disabled={requestInProgress}
                    loaderRequired={requestInProgress}
                  />
                </div>
              </form>
            </div>
          );
        }}
      />
    );
  };

  return (
    <ImageFormLayout
      leftBar={renderWorkspaceWelcomeText()}
      rightBar={renderCredentialsForm()}
      source='care_signup'
    />
  );
};

export default SpaceAcceptForm;
