import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AbstractCard from './AbstractCard';
import AddTo from 'app/components/Shared/AddTo';
import Avatar from 'app/components/Shared/Avatar';
import CardUserPost from 'app/components/Cards/CardUserPost';
import CommentIcon from 'app/components/Shared/CommentIcon';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Like from 'app/components/Shared/Like';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn';
import ParseText from 'app/components/ParseText';
import Share from 'app/components/Shared/Share';

import {
  avatarAttributes,
  commentEditUrl,
  getRemainingEditTime,
} from 'app/utils/entitiesHelper';
import { timeWithFormat } from 'app/utils/timeHelper';
import { lowerCase, truncate, upperCase } from 'app/utils/osLodash';
import { isGuestUser } from 'app/utils/userHelper';

import { ENTITY_NAME_MAPPER } from 'app/constants';
import { FORMAT_START_WITH_MONTH_SLASH } from '../Task/taskConstant';

class CommentCard extends AbstractCard {
  state = {
    isEditable: false,
  };

  componentDidMount() {
    if (!isGuestUser()) {
      let remainingEditTime = getRemainingEditTime(
          this.props.obj.created_at,
          this.props.systemConfig,
        ),
        isEditable = this.ownedByCurrentUser() && remainingEditTime > 0;

      this.setState({ isEditable });
      this.editableTimeout = setTimeout(this.invalidateEdit, remainingEditTime);
    }
  }

  componentWillUnmount() {
    clearInterval(this.editableTimeout);
  }

  onCardClick = (e, options = {}) => {
    this.props.onCardClick && this.props.onCardClick(e, options);
  };

  afterClose = (e) => {
    e.stopPropagation();
    this.props.afterClose && this.props.afterClose(this.props.obj);
  };

  onReadMoreClick = (e) => {
    this.props.onReadMoreClick && this.props.onReadMoreClick();
    this.onCardClick(e);
  };

  invalidateEdit = () => {
    this.setState({ isEditable: false });
  };

  openCommentEdit = (e) => {
    this.onCardClick(e, { url: commentEditUrl(this.props.obj) });
  };

  renderAddTo() {
    return <AddTo obj={this.props.obj} extraClass='list-button ms-0' />;
  }

  renderEdit() {
    return (
      <OsBtn
        name='BtnIcon'
        icon='edit'
        text='Edit'
        extraClass='list-button ms-0'
        onClick={this.openCommentEdit}
        associatedEntity={this.props.obj}
      />
    );
  }

  ownedByCurrentUser() {
    return +this.props.obj.author.id === +this.props.currentUser.graph.id;
  }

  renderActions() {
    if (!isGuestUser()) {
      return (
        <CustomDropdown
          name='comment_actions'
          className='card-dropdown-menu'
          onDropDownToggle={this.props.onDropDownToggle}>
          {this.state.isEditable && this.renderEdit()}
          {this.renderAddTo()}
          {this.markAsFinished()}
          {this.renderSelectButton()}
          {this.props.shareRequired && (
            <Share
              object={this.props.obj}
              label='Share'
              extraClass='ms-0 list-button'
            />
          )}
          {this.renderRemoveButton()}
        </CustomDropdown>
      );
    }
  }

  renderTitle() {
    let comment = this.props.obj,
      isTall = ['tall', 'tall-min'].includes(this.props.size),
      { first_name, last_name } = comment.author,
      name = (first_name || '') + ' ' + (last_name || '');

    return (
      <div className='comment-card-head'>
        <div className='left-comment-col'>
          {isTall && (
            <>
              <OrthoIcon
                name='comments'
                dataHoverNotRequired={true}
                defaultClass='pe-2'
              />
              <span>
                {ENTITY_NAME_MAPPER[lowerCase(comment.commentable_type)] ||
                  comment.commentable_type}{' '}
                Comment
              </span>
            </>
          )}
          {!isTall && (
            <>
              <Avatar
                className='avatar avatar-32 me-2'
                {...avatarAttributes(comment.author)}
              />
              <span className='cm-user-name'>
                {truncate(name, { length: 18 })}&nbsp;&nbsp;
              </span>
              {this.props.size === 'small' && (
                <span className='time-format text-truncate'>
                  |&nbsp;&nbsp;
                  {timeWithFormat(comment.created_at, 'll', true, {
                    todayWithTimeRequired: true,
                  })}
                </span>
              )}
            </>
          )}
        </div>
        <div className='right-comment-col'>
          {!this.props.modalActionsNotAllowed && this.renderActions()}
        </div>
      </div>
    );
  }

  renderContent() {
    let comment = this.props.obj,
      isTall = ['tall', 'tall-min'].includes(this.props.size);

    return (
      <>
        {isTall && (
          <div className='cm-card-user-info'>
            <CardUserPost
              {...comment.author}
              sliceDegreeUpto={2}
              avoidTruncate={true}>
              {timeWithFormat(comment.created_at, 'll', true, {
                todayWithTimeRequired: true,
              })}
            </CardUserPost>
          </div>
        )}
        <div className='cm-card-body'>
          {this.props.dateBlockRequired && (
            <p className={'comment-type'}>{upperCase(comment?.type)}</p>
          )}
          {comment.content && (
            <ParseText
              obj={comment}
              className='comment-text-helper card-text'
            />
          )}
          {isTall && comment.content.length > 220 && (
            <span
              className='share-modal-more a-link'
              onClick={this.onReadMoreClick}>
              Read more
            </span>
          )}
        </div>
        {this.props.dateBlockRequired && (
          <div className='commentDate'>
            {comment.author.full_name} •{' '}
            {timeWithFormat(
              comment.created_at,
              `${FORMAT_START_WITH_MONTH_SLASH} hh:mm A`,
            )}
          </div>
        )}
      </>
    );
  }

  renderFooter() {
    let comment = this.props.obj;

    return (
      !this.isExtraSmall() && (
        <div className='cm-card-footer'>
          <div className=''>
            {!this.props.modalActionsNotAllowed && (
              <Share object={comment} label='Share' extraClass='px-8' />
            )}
          </div>
          <div className='d-flex align-items-center ms-auto'>
            <CommentIcon obj={comment} cardView={true} />
            <Like obj={comment} extraClass='mr-right-4' />
          </div>
        </div>
      )
    );
  }

  onCommentCardClick = (e) => {
    this.onCardClick(e);
  };

  render() {
    return (
      <div className={this.props.containerClasses}>
        {!this.props.closeRequired && (
          <Link
            className='pulse-card-overlay'
            to='javascript:void()'
            onClick={this.onCommentCardClick}
          />
        )}
        {this.props.closeRequired && (
          <OrthoIcon
            name='clear'
            defaultClass='os-close-link'
            onClick={this.afterClose}
          />
        )}
        {this.renderDoubleTickIconIfRequired()}
        {this.renderTitle()}
        {this.renderContent()}
        {this.renderSelectBox()}
      </div>
    );
  }
}

CommentCard = connect(({ currentUser, systemConfig }) => ({
  currentUser,
  systemConfig,
}))(CommentCard);
export default CommentCard;
