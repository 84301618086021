import React, { Component } from 'react';
import { connect } from 'react-redux';

import StoreUpdater from 'app/services/StoreUpdater';
import { isSameDay } from 'app/utils/timeHelper';
import { map, sortBy } from 'app/utils/osLodash';
import { pluralize } from 'app/utils/generalHelper';
import { PinnedMessageContext } from 'app/context/PinnedMessageContext';
import ConversationChannel from 'app/channels/ConversationChannel';
import MessageBlock from 'app/components/MessageBlock';

const MESSAGE_SEEN_MIN_DISTANCE_FROM_BOTTOM = 40;

class ConversationMessagesListing extends Component {
  constructor(props) {
    super(props);
    this.state = { subscription: null, currentTimestamp: Date.now() };
    this.messageDateVisibleAt = null;
  }
  static contextType = PinnedMessageContext;

  componentDidMount() {
    this.scrollToLastReadMessage(100);
    this.eventChannelConnect();
    this.props.setListComponentRef && this.props.setListComponentRef(this);
    this.interval = setInterval(
      () => this.setState({ currentTimestamp: Date.now() }),
      59000,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.subscription && this.state.subscription)
      this.markConversationAsRead();

    if (
      prevProps.distanceFromBottom > MESSAGE_SEEN_MIN_DISTANCE_FROM_BOTTOM &&
      this.props.distanceFromBottom <= MESSAGE_SEEN_MIN_DISTANCE_FROM_BOTTOM
    )
      this.markConversationAsRead();

    if (!this.state.subscription) this.eventChannelConnect();

    this.handleNewMessages(prevProps.results, this.props.results);
  }

  componentWillUnmount() {
    this.eventChannelDisconnect();
    clearInterval(this.interval);
  }

  handleNewMessages(prevResults, newResults) {
    if (prevResults.length > 0 && prevResults.length < newResults.length) {
      let messageElement,
        newMessagePresent =
          newResults.length - prevResults.length === 1 &&
          !map(prevResults, 'id').includes(
            newResults[newResults.length - 1].id,
          );

      if (newMessagePresent) {
        let newMessage = newResults[newResults.length - 1];
        messageElement = this.getMessageElementById(newMessage.id);

        if (this.isOwnMessage(newMessage)) {
          // Message send: scroll to message
          setTimeout(() => {
            messageElement.scrollIntoViewIfNeeded();
          }, 0);
        } else {
          // Message received and it is already in viewport: mark as read
          setTimeout(() => {
            this.props.distanceFromBottom === 0 &&
              this.markConversationAsRead();
            if (
              this.isScrollPositionNearBottom(messageElement.scrollHeight + 150)
            ) {
              // Scroll only if user is in bottom, when new message was received
              messageElement.scrollIntoViewIfNeeded();
            }
          }, 0);
        }
      } else {
        // New batch loaded: scroll to first message of the new batch
        let scrollToMessageId =
          newResults[newResults.length - prevResults.length - 1].id;
        messageElement = this.getMessageElementById(scrollToMessageId);
        setTimeout(() => {
          messageElement && messageElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    }
  }

  getMessageElementById(messageId) {
    return document.getElementById('conversation-message-' + messageId);
  }

  isScrollPositionNearBottom = (threshold = 150) => {
    const pt = this.props.parentContainer;
    const position = pt.scrollTop + pt.offsetHeight;
    const height = pt.scrollHeight;
    return position > height - threshold;
  };

  markConversationAsRead() {
    StoreUpdater.markConversationAsRead(this.props.idQuery);
    this.state.subscription && this.state.subscription.seen(this.props.idQuery);
  }

  eventChannelConnect() {
    this.eventChannelDisconnect();
    const subscription = ConversationChannel.setup({
      id: this.props.idQuery,
      web: true,
    });
    this.setState({ subscription });
  }

  eventChannelDisconnect() {
    this.state.subscription && ConversationChannel.delete();
  }

  isOwnMessage(message) {
    return (
      message.author.id.toString() ===
      this.props.currentUser.graph.id.toString()
    );
  }

  scrollToBottom = (timeout = 0) => {
    setTimeout(() => {
      this.messagesEnd &&
        this.messagesEnd.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }, timeout);
  };

  scrollToLastReadMessage = (timeout = 0) => {
    const t = this.props.conversation.last_seen_at;
    setTimeout(() => {
      const lastReadMessageElem = document.querySelector(
        `[data-created="${t}"]`,
      );
      if (lastReadMessageElem) {
        lastReadMessageElem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      } else {
        this.scrollToBottom(100);
      }
    }, timeout);
  };

  isFullPageListing() {
    return this.props.size === 'full-view-listing';
  }

  isDateSeparatorVisible(message, index) {
    if (
      index === 0 ||
      !isSameDay(message.created_at, this.messageDateVisibleAt)
    ) {
      this.messageDateVisibleAt = message.created_at;
      return true;
    }
  }

  unreadMessageCount() {
    return this.props.results.filter(
      (c) =>
        !this.isOwnMessage(c) &&
        c.created_at > this.props.conversation.last_seen_at,
    ).length;
  }

  renderUnreadMessageCounter() {
    let count = this.unreadMessageCount();
    if (count > 0 && this.props.conversation.can_participate) {
      return (
        <span
          className='tooltip-copy without-arrow '
          onClick={this.scrollToBottom}>{`${count} new ${pluralize(count, {
          singular: 'message',
          plural: 'messages',
        })}`}</span>
      );
    }
  }

  isPreviousMessageIsSameAsAuthor(conversationMessagesList, message, index) {
    let previousMessage = conversationMessagesList[index - 1];
    return previousMessage && previousMessage.author.id === message.author.id;
  }

  render() {
    let conversationMessagesList = sortBy(this.props.results, [
      'created_at',
      'id',
    ]);

    return (
      <div>
        {conversationMessagesList.map((message, index) => (
          <MessageBlock
            type='conversation-message'
            key={message.id}
            entity={message}
            actionType={'dm'}
            previousMessageIsSameAsAuthor={this.isPreviousMessageIsSameAsAuthor(
              conversationMessagesList,
              message,
              index,
            )}
          />
        ))}
        <div ref={(el) => (this.messagesEnd = el)}></div>
        {/* {this.renderUnreadMessageCounter()} */}
      </div>
    );
  }
}

ConversationMessagesListing = connect(
  ({ currentUser }) => ({ currentUser }),
  {},
)(ConversationMessagesListing);
export default ConversationMessagesListing;
