import { PluginKey } from 'prosemirror-state';
import suggestionConfig from 'app/components/TipTapEditor/suggestionConfig';
const tokenSuggestion = {
  char: '{{',
  pluginKey: new PluginKey('tokenNode'),
  startOfTheLine: false,
  decorationTag: 'span',
  decorationClass: 'free',
  items: () => ({ mention: [] }),
  ...suggestionConfig,
};
export default tokenSuggestion;
