import {
  appendModalNameInUrl,
  removehashFromCurrentUrl,
} from 'app/utils/urlHelper';
import { CLOSE_TASK_PREVIEW_MODAL, OPEN_TASK_PREVIEW_MODAL } from './types';

export function openTaskPreviewModal(taskId, options = {}) {
  removehashFromCurrentUrl();
  appendModalNameInUrl(`task-${taskId}`);
  return {
    type: OPEN_TASK_PREVIEW_MODAL,
    taskId,
    options,
  };
}

export function closeTaskPreviewModal() {
  removehashFromCurrentUrl();
  return {
    type: CLOSE_TASK_PREVIEW_MODAL,
  };
}
