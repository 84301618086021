import React from 'react';
import Emoji from 'app/components/EmojiIconBar/Emoji';

import { EXTRACT_TRANSLATION_KEY_REGEX } from 'app/actions/flashMessage';

export function replaceEmojiReactions(string) {
  let interpolationKeys = string.match(EXTRACT_TRANSLATION_KEY_REGEX) || [];

  let textValues = [string];
  interpolationKeys.forEach((key) => {
    string = textValues.pop();
    let splittedTexts = string.split(key);
    textValues.push(splittedTexts[0]);
    textValues.push(<Emoji id={key.replace(/%{|}/g, '')} />);
    textValues.push(splittedTexts[1]);
  });
  return <>{textValues}</>;
}
