import {
  ADD_CONTACT_PARTY,
  CLOSE_CONTACT_PARTY_MODAL,
  EDIT_CONTACT_PARTY,
} from 'app/actions/types';

export function addContactParty({ space }) {
  return {
    type: ADD_CONTACT_PARTY,
    space,
  };
}

export function closeContactPartyModal() {
  return {
    type: CLOSE_CONTACT_PARTY_MODAL,
  };
}

export function editContactParty({ space, contactParty }) {
  return {
    type: EDIT_CONTACT_PARTY,
    space,
    contactParty,
  };
}
