import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import Authenticate from 'app/services/Authenticate.js';
import Icon from 'app/components/Svg';
import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import RedirectToDefault from 'app/components/RedirectToDefault';
import {
  fieldRequired,
  passwordMustMatch,
  passwordMustMatchValidityRules,
} from 'app/utils/validationHelper';
import scrollToFirstError from 'app/utils/scrollToFirstError';
import {
  updateCurrentUserGraph,
  updateCurrentUserToken,
} from 'app/actions/authentication';
import { translate } from 'app/actions/flashMessage';
import { closePasswordUpdateModal } from 'app/actions/currentUserSessionValid';
import { setCurrentUserSessionValidity } from 'app/actions/currentUserSessionValid';
import loginBackground from 'app/images/LandingImageBackground.jpg';
import { removeClassFromElement } from 'app/utils/domHelper';
import { composeValidators } from 'app/utils/validationHelper';

const UPDATE_USER_PASSWORD_MUTATION = queryLoader(
  'app/graphql/UpdatePassword.gql',
);
// View Shown when user has a weak and insecure password or password hasn't been changed in 30 days.
class UpdatePasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      passwordChanged: false,
    };
    removeClassFromElement('login', document.body);
  }

  updateUser = (attributes) => {
    return this.props
      .updateUserPasswordMutation({
        variables: { old_password: null, ...attributes },
        update: (proxy, { data }) => {
          if (data.updatePassword.status === 'Success')
            this.setState({ passwordChanged: true }, () => {
              setTimeout(() => {
                this.setToken(data.updatePassword.token);
              }, 2000);
            });
        },
      })
      .then(({ data }) => {
        if (data.updatePassword.status !== 'Success') {
          switch (data.updatePassword.status) {
            case 'Wrong Password':
              return { old_password: data.updatePassword.message };
            case 'Same Password':
              return { password: data.updatePassword.message };
            case 'Error':
              this.setState({ error: data.updatePassword.message });
              break;
            default:
              return null;
          }
        } else {
          this.props.updateCurrentUserGraph({
            update_password_notification_required: false,
            password_sticky_notification_required: false,
            password_update_required: false,
          });
        }
      });
  };

  setToken(token) {
    if (this.props.unauthenticated) {
      this.props.updateCurrentUserToken(token);
      this.props.setCurrentUserSessionValidity('true');
    } else {
      Authenticate.setUserTokenInCookie({ token });
    }
  }

  renderContent() {
    if (this.state.passwordChanged) {
      return (
        <div>
          <div className='modal-header'>
            <span></span>
          </div>
          <div className='text-center'>
            <Icon name='passwordSuccess' className='password-success-svg' />
            <div className='password-success-text'>Success!</div>
            <p className='password-success-msg'>
              {translate('PASSWORD_SUCCESSFULLY_UPDATED_MESSAGE')}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='modal-header'>
            <h5 className='card-title'>Change Password</h5>

            {!this.props.unauthenticated && (
              <OsBtn
                name='BtnIcon'
                extraClass='no-text os-header-btn change-password-close rel-pos web-view-btn'
                icon='close'
                label='Change Password Modal close'
                onClick={this.closeModal}
              />
            )}
          </div>

          <div className='error'>{this.state.error}</div>
          <div className={'modal-body'}>
            <Form
              onSubmit={this.updateUser}
              enableReinitialize={false}
              keepDirtyOnReinitialize={false}
              onSubmitFail={(errors) =>
                scrollToFirstError(errors, 'change_password')
              }
              render={(props) => {
                this.resetForm = props.form.reset;
                return (
                  <form
                    onSubmit={props.handleSubmit}
                    className='reset-password-form'>
                    <Field
                      name='password'
                      component={OsField}
                      osType='password'
                      label='New password*'
                      validate={composeValidators(
                        fieldRequired,
                        passwordMustMatchValidityRules,
                      )}
                      hintRequired={true}>
                      <span className='password-hint'>
                        {translate('PASSWORD_VALIDITY_HINT_TEXT')}
                      </span>
                    </Field>
                    <Field
                      name='confirm_password'
                      component={OsField}
                      osType='password'
                      label='Confirm new password*'
                      validate={composeValidators(
                        fieldRequired,
                        passwordMustMatch,
                      )}
                    />
                    <div className='form-action-row'>
                      {!this.props.unauthenticated && (
                        <OsBtn
                          name='BtnIcon'
                          text='Cancel'
                          onClick={this.closeModal}
                          extraClass='web-view-btn px-16'
                        />
                      )}
                      <OsBtn
                        name='BtnPrimary'
                        text='Apply changes'
                        type='submit'
                        htmlTag={'button'}
                        extraClass='web-view-btn'
                      />
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.props.currentUserSessionValid.valid && !this.state.passwordChanged)
      return <RedirectToDefault />;

    let className = 'modal-global modal-update-password login-bg-require';
    return (
      <div
        className='login-container'>
        <div
          className={className}
          animation={false}
          backdropClassName='modal-backdrop-custom modal-update-backdrop'>
          <div className='modal-content'>{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}
UpdatePasswordScreen = graphql(UPDATE_USER_PASSWORD_MUTATION, {
  name: 'updateUserPasswordMutation',
})(UpdatePasswordScreen);
UpdatePasswordScreen = connect(
  ({ currentUser, currentUserSessionValid }) => ({
    currentUser,
    currentUserSessionValid,
  }),
  {
    updateCurrentUserGraph,
    closePasswordUpdateModal,
    setCurrentUserSessionValidity,
    updateCurrentUserToken,
  },
)(UpdatePasswordScreen);
export default UpdatePasswordScreen;
