import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Modal } from 'react-bootstrap';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import Avatar from 'app/components/Shared/Avatar';
import EventTracker from 'app/services/EventTracker';
import LikesView from 'app/components/LikesView';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';
import withAuthenticate from 'app/components/HOC/withAuthenticate';
import withAuthorize from 'app/components/HOC/withAuthorize';

import { pluralize } from 'app/utils/generalHelper';
import { avatarAttributes, getAuthorizeObject } from 'app/utils/entitiesHelper';
import { isTouchSupported } from 'app/utils/deviceHelper';
import { pick } from 'app/utils/osLodash';

const ENTITIES_ALLOWED_TO_LIKE = ['Comment', 'Pulse'];
const LIKE_OBJECT_MUTATION = queryLoader('app/graphql/LikeObject.gql');
const UNLIKE_OBJECT_MUTATION = queryLoader('app/graphql/UnlikeObject.gql');
const TOOLTIP_VIEW_ALL_LIMIT = 3;

class Like extends Component {
  state = {
    showModal: false,
  };

  createOptimisiticReponseForLikeUsers(likeQuery) {
    let likes_by_users,
      obj = this.props.obj;
    if (likeQuery) {
      let currentUserObj = pick(
        this.props.currentUser.graph,
        'id',
        'name',
        'avatar',
        'initials',
        'active_subscription_color_code',
        '__typename',
      );
      obj.likes_by_users.unshift(currentUserObj);
      likes_by_users = obj.likes_by_users;
    } else {
      likes_by_users = obj.likes_by_users.filter(
        (user) => user.id !== this.props.currentUser.graph.id,
      );
    }
    return likes_by_users;
  }

  getOptimisticResponseProps(likeQuery) {
    let updatedLikesCount = likeQuery
      ? this.props.obj.likes_count + 1
      : this.props.obj.likes_count - 1;
    let props = {
      __typename: 'Like',
      id: '-1',
      likeable_id: this.props.obj.id,
      likeable_type: this.props.obj.__typename,
      likeable: {
        id: this.props.obj.id,
        likes_count: updatedLikesCount,
        __typename: this.props.obj.__typename,
      },
    };

    if (this.props.toolTipRequired) {
      let likes_by_users = this.createOptimisiticReponseForLikeUsers(likeQuery);
      props.likeable['likes_by_users'] = likes_by_users;
    }

    return props;
  }

  getFragment(likeQuery) {
    var fragment = '',
      fragmentVarName = likeQuery ? 'likedObject' : 'unlikeObject',
      { __typename: objectType } = this.props.obj;

    if (this.props.toolTipRequired) {
      fragment = gql`
        fragment ${fragmentVarName} on ${objectType} {
          id
          liked
          likes_by_users {
            id
            name
            avatar
            initials
            active_subscription_color_code
          }
          likes_count
        }
      `;
    } else {
      fragment = gql`
        fragment ${fragmentVarName} on ${objectType} {
          id
          liked
          likes_count
        }
      `;
    }
    return fragment;
  }

  likeObject = () => {
    this.props.onAction();
    this.props
      .likeObjectMutation({
        variables: {
          likeable_id: this.props.obj.id,
          likeable_type: this.props.obj.__typename,
        },
        optimisticResponse: () => {
          return { likeObject: this.getOptimisticResponseProps(true) };
        },

        update: (proxy, { data: { likeObject } }) => {
          var fragment = this.getFragment(true);

          const id = likeObject.likeable_type + ':' + likeObject.likeable_id;
          const data = proxy.readFragment({ fragment, id });

          data.liked = true;
          data.likes_count = likeObject.likeable.likes_count;
          if (this.props.toolTipRequired) {
            data.likes_by_users = likeObject.likeable.likes_by_users;
          }

          proxy.writeFragment({ fragment, id, data });
        },
      })
      .then((data) => {
        this.sendLikeEvent();
      });
  };

  sendLikeEvent() {
    EventTracker.trackForEntity('like', this.props.obj);
  }

  unlikeObject = () => {
    this.props.onAction();
    this.props.unlikeObjectMutation({
      variables: {
        likeable_id: this.props.obj.id,
        likeable_type: this.props.obj.__typename,
      },
      optimisticResponse: () => {
        return { unlikeObject: this.getOptimisticResponseProps(false) };
      },

      update: (proxy, { data: { unlikeObject } }) => {
        var fragment = this.getFragment(false);

        const id = unlikeObject.likeable_type + ':' + unlikeObject.likeable_id;
        const data = proxy.readFragment({ fragment, id });

        data.liked = false;
        data.likes_count = unlikeObject.likeable.likes_count;
        if (this.props.toolTipRequired) {
          data.likes_by_users = unlikeObject.likeable.likes_by_users;
        }
        proxy.writeFragment({ fragment, id, data });
      },
    });
  };

  handleUnlikeObject = (e) => {
    this.onClickPreventDefault(e);
    this.props.openUpgradeModalIfUnauthorized(this.unlikeObject);
  };

  handleLikeObject = (e) => {
    this.onClickPreventDefault(e);
    this.props.redirectToDefaultIfUnauthenticated(this.likeEntity);
  };

  likeEntity = (e) => {
    this.props.openUpgradeModalIfUnauthorized(this.likeObject);
  };

  onClickPreventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  openModal = (e) => {
    e.stopPropagation();
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  renderTooltipUser(user) {
    return (
      <li className='like-count-tooltip-row' key={user.id}>
        <span className='user-image me-2'>
          <Avatar className='avatar avatar-16' {...avatarAttributes(user)} />
        </span>
        <span className='user-name text-truncate'>{user.name}</span>
      </li>
    );
  }

  renderToolTipView() {
    let { likes_by_users: users, likes_count } = this.props.obj,
      usersContent =
        users &&
        users.slice(0, TOOLTIP_VIEW_ALL_LIMIT).map(this.renderTooltipUser);
    if (likes_count) {
      return (
        <div
          className='like-count-tooltip-wrap cursor-default'
          onClick={(e) => e.stopPropagation()}>
          <ul className='like-count-tooltip text-truncate'>
            {usersContent}
            {likes_count > TOOLTIP_VIEW_ALL_LIMIT && (
              <li
                className='like-count-tooltip-row text-uppercase text-center'
                onClick={this.openModal}>
                <OsLink
                  className='fs-12 a-link mx-auto cursor-pointer'
                  text='View All'
                />
              </li>
            )}
          </ul>
        </div>
      );
    }
  }

  getLikesCount(likes_count) {
    if (likes_count) {
      return !this.props.showLikesCountWithoutText
        ? likes_count +
            ' ' +
            pluralize(likes_count, { singular: 'like', plural: 'likes' })
        : likes_count;
    } else if (this.props.showLabelIfZero) {
      return 'like';
    }
  }

  renderLikeTooltip() {
    let { likes_count } = this.props.obj;
    return (
      <span className='btn-text-helper'>
        {this.getLikesCount(likes_count)}
        {!isTouchSupported() && this.renderToolTipView()}
      </span>
    );
  }

  renderCount() {
    let count = this.props.obj.likes_count,
      label;

    if (this.props.toolTipRequired) {
      return this.renderLikeTooltip();
    } else if (count) {
      if (this.props.labelRequired) {
        label = count === 1 ? `${count} LIKE` : `${count} LIKES`;
      } else {
        label = count;
      }
    } else if (this.props.showLabelIfZero) {
      label = 'like';
    }
    return <span>{label}</span>;
  }

  render() {
    let extraClass = this.props.dropDownListing ? '' : 'px-8 ',
      {
        toolTipRequired,
        obj: { liked, likes_count, __typename },
      } = this.props,
      label = this.props.obj.liked ? 'unlike' : 'like';

    extraClass += toolTipRequired ? 'like-count ' : '';
    extraClass += liked ? 'active ' : '';
    extraClass +=
      likes_count > 0 || this.props.showLabelIfZero ? '' : 'no-text ';
    extraClass += this.props.extraClass ? `${this.props.extraClass} ` : '';

    if (!ENTITIES_ALLOWED_TO_LIKE.includes(__typename)) return null;

    return (
      <>
        <OsBtn
          name='BtnIcon'
          label={label}
          disabled={this.props.disabled}
          icon={this.props.iconName}
          extraClass={extraClass}
          onClick={
            this.props.obj.liked
              ? this.handleUnlikeObject
              : this.handleLikeObject
          }
          associatedEntity={this.props.obj}>
          {this.renderCount()}
        </OsBtn>
        {toolTipRequired && (
          <Modal
            show={this.state.showModal}
            onHide={this.closeModal}
            dialogClassName='modal-xl modal-global comment-likes-modal'
            animation={false}
            backdropClassName='modal-backdrop-custom'>
            <LikesView
              likeable_id={this.props.obj.id}
              likeable_type={this.props.obj.__typename}
              totalLikes={this.props.obj.likes_count}
              closeModal={this.closeModal}
            />
          </Modal>
        )}
      </>
    );
  }
}

Like = withAuthorize(Like);
Like.defaultProps = {
  abilityObject: getAuthorizeObject('like', 'all'),
  dropDownListing: false,
  onAction: () => {},
  iconName: 'like',
};
Like = connect(({ currentUser }) => ({ currentUser }), null)(Like);
Like = compose(
  graphql(LIKE_OBJECT_MUTATION, { name: 'likeObjectMutation' }),
  graphql(UNLIKE_OBJECT_MUTATION, { name: 'unlikeObjectMutation' }),
)(Like);
Like = withAuthenticate(Like);

export default Like;
