import React, { Component } from 'react';

import ConnectToSupport from 'app/components/ConnectToSupport';
import OsBtn from 'app/components/OsBtn';

class NewAction extends Component {
  renderAddNewButton() {
    return (
      <>
        <div>
          <OsBtn
            extraClass='list-button no-osbtn'
            text={this.newButtonText}
            name='BtnPrimary'
            onClick={this.addNewAction}
          />
        </div>
        <div>{!this.connectToSupportNotRequired && <ConnectToSupport />}</div>
      </>
    );
  }
}

export default NewAction;
