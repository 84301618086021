import React from 'react';
import img from 'app/images/svg-thumbnail/privacy.png';
import { connect } from 'react-redux';
import CustomLinearGradient from 'app/components/Svg/Helpers/CustomLinearGradient';

let SVG = ({
  style = {},
  fill = '',
  width = '300',
  height = '300',
  className = '',
  viewBox = '0 0 300 300',
  device,
}) => (
  <svg
    width={width || device.mobileDevice ? '310' : '411'}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`svg-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    <image href={img} />
  </svg>
);

SVG = connect(({ device }) => ({ device }))(SVG);
export default SVG;
