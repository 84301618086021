import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { debounce } from 'app/utils/osLodash';
import { flashSuccess } from 'app/actions/flashMessage';
import { useDispatch } from 'react-redux';
import {
  TEMPLATE_SUCCESSFULLY_CREATED,
  TEMPLATE_SUCCESSFULLY_DELETED,
  TEMPLATE_SUCCESSFULLY_UPDATED,
} from 'app/components/Templates/constants';
import { TemplateContext } from 'app/context/TemplateContext';
import storeUpdater from 'app/services/StoreUpdater';

const UPDATE_TEMPLATE = queryLoader(
  'app/graphql/mutations/Template/UpdateTemplate.gql',
);

const ADD_TEMPLATE = queryLoader(
  'app/graphql/mutations/Template/AddTemplate.gql',
);
const DELETE_TEMPLATE = queryLoader(
  'app/graphql/mutations/Template/DeleteTemplate.gql',
);

const BLANK_TEMPLATE = `<p></p>`;

const useTemplateRow = (template, setNewTemplate) => {
  const [isEditorOpen, setIsEditorOpen] = useState(() => {
    return template.id === '-1';
  });
  const templateInitialContent = useRef(template.content || '');
  const [rowState, setRowState] = useState({
    isFeatured: template.featured || false,
    isStatus: template.enabled || false,
    isFollowUp: false,
  });
  const [templateName, setTemplateName] = useState(template.title || '');
  const [templateContent, setTemplateContent] = useState(
    template.content || '',
  );
  const [updateTemplate, { loading: templateUpdateLoading }] =
    useMutation(UPDATE_TEMPLATE);
  const [addTemplate] = useMutation(ADD_TEMPLATE);
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
  const [templateFiles, setTemplateFiles] = useState(template.files || []);
  const [templateObjects, setTemplateObjects] = useState([]);
  const { allTemplateVariables } = useContext(TemplateContext);
  const inputRef = useRef(null);
  const [error, setError] = useState(() => ({
    templateName: false,
    tiptapField: false,
  }));
  const dispatch = useDispatch();

  const handleEditor = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const getSubmitButtonText = () => {
    if (template.kind === 'care') {
      return 'SAVE PRESET';
    } else {
      return 'SAVE TEMPLATE';
    }
  };

  const getPlaceholderText = () => {
    if (template.kind === 'care') {
      return 'Add the preset content here. No @mentions will work in this block. Use tokens in curly braces, such as {date} or {time}, to ensure patients provide necessary details within the preset.';
    } else {
      return '';
    }
  };

  const updateTemplateData = useCallback(
    debounce(async (templateName, rowState) => {
      const { id } = template;
      const response = await updateTemplate({
        variables: {
          templateId: id,
          title: templateName,
          content: templateContent,
          featured: rowState.isFeatured,
          enabled: rowState.isStatus,
          links: templateObjects.map((link) => `${link.__typename}:${link.id}`),
          files: templateFiles.map((file) => file.id),
        },
      });

      if (response.data?.updateTemplate.success) {
        flashSuccess(TEMPLATE_SUCCESSFULLY_UPDATED, false)(dispatch);
      }
    }, 1000),
    [templateContent, templateFiles, templateObjects],
  );

  const isTemplatePersisted = () => {
    return !(template.id === '-1');
  };

  const addTemplateData = async () => {
    const response = await addTemplate({
      variables: {
        title: templateName,
        content: templateContent,
        featured: rowState.isFeatured,
        kind: template.kind,
        enabled: rowState.isStatus,
        links: templateObjects.map((link) => `${link.__typename}:${link.id}`),
        files: templateFiles.map((file) => file.id),
      },
    });
    if (response.data.addTemplate.success) {
      flashSuccess(TEMPLATE_SUCCESSFULLY_CREATED, false)(dispatch);
      const record = response.data.addTemplate.entity;
      setNewTemplate(record);
      // storeUpdater.addTemplatesInRecords()
    }
  };

  const deleteTemplateData = async () => {
    if (isTemplatePersisted()) {
      const response = await deleteTemplate({
        variables: {
          templateId: template.id,
        },
      });
      if (response.data.deleteTemplate.success) {
        storeUpdater.removeDataFromRecords(template, allTemplateVariables);
        flashSuccess(TEMPLATE_SUCCESSFULLY_DELETED, false)(dispatch);
      }
    }
  };

  const handleIsFeatured = () => {
    setRowState({ ...rowState, isFeatured: !rowState.isFeatured });
    if (isTemplatePersisted() && checkIfAllNeccessaryFieldsAreFilled()) {
      updateTemplateData(templateName, {
        ...rowState,
        isFeatured: !rowState.isFeatured,
      });
    }
  };

  const handleIsStatus = () => {
    setRowState({ ...rowState, isStatus: !rowState.isStatus });
    if (isTemplatePersisted() && checkIfAllNeccessaryFieldsAreFilled()) {
      updateTemplateData(templateName, {
        ...rowState,
        isStatus: !rowState.isStatus,
      });
    }
  };

  const handleIsFollowUp = () => {
    setRowState({ ...rowState, isFollowUp: !rowState.isFollowUp });
  };

  const handleTemplateName = (event) => {
    if (!event.target.value) {
      setError((prevState) => ({ ...prevState, templateName: true }));
    } else {
      setError((prevState) => ({ ...prevState, templateName: false }));
    }
    setTemplateName(event.target.value);
    if (isTemplatePersisted() && event.target.value)
      updateTemplateData(event.target.value, rowState);
  };

  const handleContent = (content) => {
    if (content === BLANK_TEMPLATE) {
      setTemplateContent('');
      setError((prevState) => ({ ...prevState, tiptapField: true }));
    } else {
      setTemplateContent(content);
      setError((prevState) => ({ ...prevState, tiptapField: false }));
    }
  };

  const checkIfAllNeccessaryFieldsAreFilled = () => {
    if (!templateName) {
      setError((prevState) => ({ ...prevState, templateName: true }));
    }
    if (!templateContent) {
      setError((prevState) => ({ ...prevState, tiptapField: true }));
    }
    return !!templateName && !!templateContent;
  };

  const handleSubmit = async (event) => {
    event.stopPropagation();
    try {
      if (!checkIfAllNeccessaryFieldsAreFilled()) {
        throw new Error('Please fill all the fields');
      }
      if (!isTemplatePersisted()) {
        await addTemplateData();
      } else {
        await updateTemplateData(templateName, rowState);
      }
      setIsEditorOpen(false);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (isEditorOpen) {
      inputRef.current.focus();
    }

    if (!isEditorOpen) {
      templateInitialContent.current = templateContent;
    }
  }, [isEditorOpen]);

  return {
    isEditorOpen,
    rowState,
    templateName,
    templateContent,
    templateFiles,
    templateObjects,
    templateUpdateLoading,
    inputRef,
    templateInitialContent,
    error,
    setTemplateObjects,
    setTemplateFiles,
    isTemplatePersisted,
    deleteTemplateData,
    handleSubmit,
    handleContent,
    handleIsFeatured,
    handleIsStatus,
    handleIsFollowUp,
    handleEditor,
    handleTemplateName,
    getSubmitButtonText,
    getPlaceholderText,
  };
};

export default useTemplateRow;
