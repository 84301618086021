import React, { Component } from 'react';

import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';

export const UserConnectionContext = React.createContext({
  sendRequest: false,
  refetch: '',
});
export default class AbstractLayout extends Component {
  componentDidMount() {
    this.isUnauthorizedSession() && addClassToHTML('unauthorized-session');
    this.props.bindMobileFilter();
    this.bindDragEvents();
    this.customComponentDidMount();
    this.initializeIntercom();
  }

  componentWillUnmount() {
    removeClassFromHTML('unauthorized-session');
    this.shutIntercom();
    this.customComponentWillUnmount();
  }

  customComponentWillUnmount() {}

  customComponentDidMount() {}

  shutIntercom() {
    IntercomTracker.shutdownIntercom();
  }

  initializeIntercom() {
    // Initialize Intercom only for guest users.
    this.isGuestUser() && IntercomTracker.register();
  }

  bindDragEvents() {
    let dragActiveClassName = 'element-drag-active';

    document.body.addEventListener('dragover', () => {
      addClassToHTML(dragActiveClassName);
    });

    document.body.addEventListener('dragleave', () => {
      setTimeout(() => {
        removeClassFromHTML(dragActiveClassName);
      }, 100);
    });

    document.body.addEventListener('drop', () => {
      removeClassFromHTML(dragActiveClassName);
    });
  }

  isUnauthorizedSession() {
    return !(this.props.currentUser && this.props.currentUser.token);
  }

  isGuestUser() {
    return this.props.currentUser && !this.props.currentUser.token;
  }
}
