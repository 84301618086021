import { unlockNavbar } from 'app/actions/lockNavbar';
import {
  getKeyValueFromLocalStorage,
  removeKeyFromLocalStorage,
  setKeyValueInLocalStorage,
} from 'app/components/SwitchProfile/quickSwitcherHelper';
import { useLocalStorage } from 'app/hooks/useLocalStorage';
import AuthenticatedRoutes from 'app/routes/Authenticated';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

const PROFILE_WILL_LOCKED_AFTER = '900000';

const QuickSwitcher = (props) => {
  const timer = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const identifier = props.workspace?.identifier;
  const isProfileSwitcherEnabled =
    props.workspace?.data?.profile_switcher_enabled;
  const isPasswordPresent =
    props.workspace?.data?.user_request?.password_present;
  const [lastActivity, setLastActivity] = useLocalStorage(
    `lastUserActivity_${identifier}`,
    Date.now(),
  );

  const checkActivityStatus = () => {
    const lastUserActivity = getKeyValueFromLocalStorage(
      `lastUserActivity_${identifier}`,
    );
    if (lastUserActivity) {
      const timeDifference = Date.now() - lastUserActivity;
      if (
        location.pathname !== '/' &&
        isProfileSwitcherEnabled &&
        isPasswordPresent
      ) {
        if (
          timeDifference > PROFILE_WILL_LOCKED_AFTER &&
          location.pathname !== `/${identifier}/profile/switcher` &&
          location.pathname !== '/launch'
        ) {
          if (getKeyValueFromLocalStorage('lockNav') == 'false') {
            setKeyValueInLocalStorage('identifier', identifier);
            setKeyValueInLocalStorage('lockNav', true);
            setKeyValueInLocalStorage('storage', true);
          }
          window.location.reload(true);
        }
      }
    }
  };

  const printMovement = () => {
    setLastActivity(Date.now());
    const resetTimer = () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
      checkActivityStatus();
      timer.current = setInterval(checkActivityStatus, 2000);
    };
    resetTimer();
  };

  // this function only runs for the other tabs and locked and unlocked the profile
  const updateState = () => {
    if (getKeyValueFromLocalStorage('HTTP_SESSION_TOKEN') == null)
      navigate('/logout');

    let lock = getKeyValueFromLocalStorage('lockNav');
    if (getKeyValueFromLocalStorage('storage') == 'true') {
      let localStorageIdentifier = getKeyValueFromLocalStorage('identifier');
      if (localStorageIdentifier !== null) {
        let currentLocation = window.location.pathname;
        if (
          lock == 'true' &&
          currentLocation !== `/${identifier}/profile/switcher` &&
          !currentLocation.includes('profile/switcher') &&
          !currentLocation.includes('profile/reset_password')
        ) {
          // when profile is locked on any of the screen the this code locked the profile in other tabs
          if (
            currentLocation.split('/')[1] != localStorageIdentifier ||
            currentLocation === '/launch'
          ) {
            // this code would push explore route to history if user is on launch screen
            window.history.pushState(
              {},
              '',
              `${process.env.REACT_APP_FRONTEND_APP_URL}/${localStorageIdentifier}/patient/activity`,
            );
          }
          // profile switcher
          window.location.href = `/${localStorageIdentifier}/profile/switcher`;
          setKeyValueInLocalStorage('storage', false);
        } else if (
          lock == 'false' &&
          currentLocation === `/${localStorageIdentifier}/profile/switcher`
        ) {
          // when profile is unlocked on any of the screen then this code redirect user to its previous postion
          dispatch(unlockNavbar());
          navigate(-1);
          setKeyValueInLocalStorage('storage', false);
          removeKeyFromLocalStorage('identifier');
        }
      }
    }
  };

  useEffect(() => {
    if (
      !isCareWorkspaceView() &&
      isProfileSwitcherEnabled &&
      isPasswordPresent
    ) {
      printMovement();
      document.addEventListener('mousemove', printMovement);
      document.addEventListener('mousedown', printMovement);
      document.addEventListener('keydown', printMovement);
      window.addEventListener('storage', updateState);
      return () => {
        document.removeEventListener('mousemove', printMovement);
        document.removeEventListener('mousedown', printMovement);
        document.removeEventListener('keydown', printMovement);
        window.removeEventListener('storage', updateState);
      };
    }
  }, []);

  return <AuthenticatedRoutes {...props} />;
};

export default QuickSwitcher;
