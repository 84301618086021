import { useLazyQuery } from '@apollo/client';
import { loader as queryLoader } from 'graphql.macro';
import { useContext, useEffect, useRef, useState } from 'react';
import { TaskContext } from 'app/context/TaskContext';

const SUB_LABEL_TASKS = queryLoader('app/graphql/queries/Task/TaskListing.gql');

function useGroupLabel({ labelGroup }) {
  const { labelsData, groupLabelResponse, setGroupLabelResponse } =
    useContext(TaskContext);

  const groupLabelResponseRef = useRef(null);
  const [fetchSubLabelTasks] = useLazyQuery(SUB_LABEL_TASKS);

  const updateViewAfterChangingLabel = (card, fromColumnId, toColumnId) => {
    let labelColumnInWhichCardAdd = labelGroup.sub_labels.find(
      (l) => l.id === toColumnId,
    );

    let labelColumnFromCardWillRemove = labelGroup.sub_labels.find(
      (l) => l.id === fromColumnId,
    );

    groupLabelResponseRef.current = groupLabelResponse;
    groupLabelResponseRef.current = {
      ...groupLabelResponseRef.current,
      [labelColumnInWhichCardAdd.name]: {
        ...groupLabelResponseRef.current[labelColumnInWhichCardAdd.name],
        data: {
          ...groupLabelResponseRef.current[labelColumnInWhichCardAdd.name].data,
          records: {
            ...groupLabelResponseRef.current[labelColumnInWhichCardAdd.name]
              .data.records,
            total:
              groupLabelResponseRef.current[labelColumnInWhichCardAdd.name].data
                .records.total + 1,
            results: [
              card,
              ...groupLabelResponseRef.current[labelColumnInWhichCardAdd.name]
                .data.records.results,
            ],
          },
        },
      },
      [labelColumnFromCardWillRemove.name]: {
        ...groupLabelResponseRef.current[labelColumnFromCardWillRemove.name],
        data: {
          ...groupLabelResponseRef.current[labelColumnFromCardWillRemove.name]
            .data,
          records: {
            ...groupLabelResponseRef.current[labelColumnFromCardWillRemove.name]
              .data.records,
            total:
              groupLabelResponseRef.current[labelColumnFromCardWillRemove.name]
                .data.records.total - 1,
            results: groupLabelResponseRef.current[
              labelColumnFromCardWillRemove.name
            ].data.records.results.filter((task) => task.id !== card.id),
          },
        },
      },
    };

    setGroupLabelResponse(groupLabelResponseRef.current);
  };

  const fetchTasks = async (label, pageNumber = 0) => {
    let prevResponseObj =
      groupLabelResponseRef.current &&
      groupLabelResponseRef.current[label.name];
    if (
      prevResponseObj &&
      prevResponseObj?.data?.records?.total ===
        prevResponseObj?.data?.records?.results.length
    ) {
      return;
    }
    let response = await fetchSubLabelTasks({
      variables: {
        page: pageNumber,
        perPage: 10,
        type: 'AllWorkspaceTasks',
        sortQuery: '',
        searchQuery: '',
        text_query: '',
        additional_filters: JSON.stringify({
          label_ids: [label.id],
        }),
      },
      fetchPolicy: 'cache-and-network',
    });

    setGroupLabelResponse((prevState) => {
      const updatedResponse = {
        ...prevState[label.name],
        ...response,
        data: {
          ...response.data,
          records: {
            ...response.data.records,
            results: [
              ...(prevState[label.name]?.data?.records?.results || []),
              ...response.data.records.results,
            ],
          },
        },
      };

      groupLabelResponseRef.current = {
        ...prevState,
        [label.name]: updatedResponse,
      };

      return {
        ...prevState,
        [label.name]: updatedResponse,
      };
    });
  };

  const fetchNextPageRecords = (labelId) => {
    let subLabel = labelsData
      .filter((label) => label.sub_labels.length > 0)
      .flatMap((label) => label.sub_labels)
      .find((subLabel) => subLabel.id === labelId);

    fetchTasks(
      subLabel,
      groupLabelResponseRef.current[subLabel.name]?.variables?.page + 1,
    );
  };

  useEffect(() => {
    if (labelGroup !== undefined && labelGroup !== null) {
      labelGroup.sub_labels.forEach((element) => {
        fetchTasks(element);
      });
    }
  }, [labelGroup?.id]);

  return {
    fetchNextPageRecords,
    updateViewAfterChangingLabel,
  };
}

export default useGroupLabel;
