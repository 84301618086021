import React, { Component } from 'react';
import { connect } from 'react-redux';

import SpaceMembersView from 'app/components/SpaceTabs/MembersView';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';
import { loader as queryLoader } from 'graphql.macro';

const SPACE_MEMBERS_QUERY = queryLoader(
  'app/graphql/queries/Spaces/SpaceMembersTab.gql',
);

class MemberListingWrapper extends Component {
  render() {
    return <SpaceMembersView {...this.props} />;
  }
}

MemberListingWrapper = withGraphqlRequests(
  SPACE_MEMBERS_QUERY,
  {
    skip: (props) =>
      props.customData.space.members_listing_layout !== 'grouped' ||
      !props.currentUser.graph,
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.customData.space.nice_id,
        companySpace: props.isCompanySpace,
      },
    }),
  },
  {
    objectIdentifier: 'space',
    loaderType: 'ball',
  },
)(MemberListingWrapper);

MemberListingWrapper = connect(
  ({ currentUser }) => ({ currentUser }),
  null,
)(MemberListingWrapper);

export default MemberListingWrapper;
