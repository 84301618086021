import React from 'react';

import CaseDropdown from './CaseDropdown';
import FolderSpaceDropdown from './FolderSpaceDropdown';
import SpaceDropdown from './SpaceDropdown';
import WorkspaceDropdown from './WorkspaceDropdown';
import { isWorkspaceType } from 'app/utils/spaceHelper';

const EntityDropdown = (props) => {
  switch (props.entity.__typename) {
    case 'Case':
      return <CaseDropdown {...props} />;
    case 'Space':
    case 'Board':
      const DropdownElement = isWorkspaceType(props.entity)
        ? WorkspaceDropdown
        : SpaceDropdown;
      return <DropdownElement space={props.space || props.entity} {...props} />;
    case 'FolderSpace':
      return <FolderSpaceDropdown {...props} />;
    default:
      return null;
  }
};

export default EntityDropdown;
