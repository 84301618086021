import { Extension } from '@tiptap/react';

export const CustomSubmit = Extension.create({
  name: 'customSubmit',

  addKeyboardShortcuts() {
    return {
      // ↓ your new keyboard shortcut
      'Control-Enter': async ({ editor }) => {
        await this.options.onKeyDown(['Control', 'Enter'], editor);
        editor.commands.clearContent();
      },
      'Cmd-Enter': () => {
        return this.options.onKeyDown(['cmd', 'Enter']);
      },
    };
  },
});
