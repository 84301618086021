import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import AuthorizeTab from 'app/components/Shared/AuthorizeComponent';
import OsBtn from 'app/components/OsBtn/';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';
import { openCreateFolderModal } from 'app/actions/folderModal';
import withSubscribedAccess from 'app/components/HOC/withSubscribedAccess';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

class NewFolderButton extends Component {
  onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openSubscriptionModalIfNotSubscribed(
      this.props.openCreateFolderModal,
    );
  };

  render() {
    if (this.props.device.mobileDevice) return null;
    return (
      <AuthorizeTab
        abilityObject={getAuthorizeObject('create', 'board')}
        onClick={this.onClickHandler}>
        {this.props.type === 'icon' ? (
          <OrthoIcon name='add' />
        ) : (
          <OsBtn
            name='BtnPrimary'
            extraClass='new-folder-btn'
            text='Create New Folder'
          />
        )}
      </AuthorizeTab>
    );
  }
}

NewFolderButton = connect(({ device }) => ({ device }), {
  openCreateFolderModal,
})(NewFolderButton);
NewFolderButton = withRouter(NewFolderButton);
NewFolderButton = withSubscribedAccess(NewFolderButton);
export default NewFolderButton;
