import { navWorkspacesRequired } from 'app/utils/Workspace/navHelper';

export const NAV_LINKS_FOR_WORKSPACE = {
  'Account Settings': { urls: ['/account_settings'], iconName: 'setting' },
  CareSpaces: {
    urls: ['/carespaces'],
    iconName: 'case-person-feed',
    title: 'PatientSpaces',
  },
  'Team Spaces': { urls: ['/team_spaces'], iconName: 'appliance' },
  Folders: { urls: ['/folders'], iconName: 'folder' },
  Members: {
    urls: ['/members'],
    iconName: 'members',
    abilityObject: { action: 'index', entity: 'user', options: {} },
  },
  Patient: {
    urls: ['/explore'],
    iconName: 'case-person-feed',
    countRequired: true,
    abilityObject: { action: 'index', entity: 'explore', options: {} },
  },
  Logout: { onClickMethodName: 'logout', iconName: 'logout' },
  Team: {
    onClickMethodName: 'onCommunicationLinkClick',
    iconName: 'teams',
    countRequired: true,
    abilityObject: { action: 'create', entity: 'conversation', options: {} },
  },
  Tasks: {
    urls: ['/tasks'],
    iconName: 'task',
    countRequired: true,
    abilityObject: { action: 'create', entity: 'task', options: {} },
  },
  More: {
    onClickMethodName: 'toggleNavDropdown',
    iconName: 'more',
    countRequired: true,
  },
  'My Profile': { onClickMethodName: 'openProfilePage', iconName: 'user' },
  Notifications: {
    onClickMethodName: 'openNotificationPage',
    iconName: 'notification',
    countRequired: true,
  },
  Partners: { urls: ['/partners'], iconName: 'surgeryAppointment' },
  Search: {
    onClickMethodName: 'openSearchField',
    iconName: 'search',
    abilityObject: { action: 'search', entity: 'all', options: {} },
  },
  Support: { onClickMethodName: 'redirectToSupportChat', iconName: 'support' },
  Content: {
    urls: ['/contents'],
    iconName: 'documentsFile',
    abilityEntityName: 'board',
    abilityObject: { action: 'index', entity: 'board', options: {} },
  },
  Spaces: {
    urls: ['/subspaces'],
    iconName: 'spaces',
    abilityEntityName: 'board',
    abilityObject: { action: 'index', entity: 'board', options: {} },
  },
  Information: {
    urls: ['/information'],
    iconName: 'info',
    abilityEntityName: 'board',
    abilityObject: { action: 'index', entity: 'board', options: {} },
  },
  'Switch workspaces': {
    onClickMethodName: 'redirectToWorkspaces',
    iconName: 'workspacelogo',
    shouldBeVisible: navWorkspacesRequired,
    expandIconRequired: true,
  },
};
export const FEED_LINK_NAME = 'Patient';
export const MOBILE_NAV_LINKS_FOR_WORKSPACE = [
  FEED_LINK_NAME,
  'Team',
  'Tasks',
  'Partners',
  'More',
];
