import React from 'react';
import useClinicSubscribedAccess from 'app/hooks/useClinicSubscribedAccess';

const withSubscribedAccess = (WrappedComponent) => {
  const HOC = (props) => {
    const { openSubscriptionModalIfNotSubscribed } =
      useClinicSubscribedAccess();
    return (
      <>
        <WrappedComponent
          openSubscriptionModalIfNotSubscribed={
            openSubscriptionModalIfNotSubscribed
          }
          {...props}
        />
      </>
    );
  };
  return HOC;
};

export default withSubscribedAccess;
