import OrthoIcon from 'app/components/Shared/OrthoIcon';
const WorkspaceCardLogo = ({ workspace }) => {
  if (workspace.owner.owner.logo_small) {
    return <img src={workspace.owner.owner.logo_small} alt={workspace.name} />;
  } else if (workspace.owner.owner.logo_big) {
    return <img src={workspace.owner.owner.logo_big} alt={workspace.name} />;
  } else {
    return (
      <OrthoIcon
        name='workspacelogo'
        defaultClass='workspace-icon-logo'
        dataHoverNotRequired={true}
      />
    );
  }
};

export default WorkspaceCardLogo;
