import React, { PureComponent } from 'react';

import Emoji from './Emoji';
import OsBtn from 'app/components/OsBtn';

class EmojiBtn extends PureComponent {
  render() {
    let extraClass = 'emoji-btn';
    extraClass += this.props.active ? ' active' : '';
    return (
      <OsBtn name='BtnPrimary' extraClass={extraClass}>
        <Emoji id={this.props.emojiName} active={this.props.active} />
        <span className='count'>{this.props.count}</span>
        {this.props.children}
      </OsBtn>
    );
  }
}

export default EmojiBtn;
