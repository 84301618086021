import React, { Component } from 'react';
import { connect } from 'react-redux';

import AbstractGalleryView from './AbstractGalleryView';
import {
  setCurrentSlide,
  setCaseViewType,
  setModeType,
} from 'app/actions/caseDetailInfo';
import { CLASSIC_GRID_VIEW_IMAGES_INFO_HASH } from './Constant';

export default class CaseGridClassicView extends AbstractGalleryView {
  gridViewImageInfoHash = CLASSIC_GRID_VIEW_IMAGES_INFO_HASH;
  imageContainerClass = 'cs-grid-col-9';
  parentContainerClass = 'cs-gallery-grid-row-9';
}

CaseGridClassicView = connect(null, {
  setCurrentSlide,
  setCaseViewType,
  setModeType,
})(CaseGridClassicView);
