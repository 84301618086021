import React from 'react';
import '@asseinfo/react-kanban/dist/styles.css';
import KanbanBoard from 'app/components/Kanban/KanbanBoard';
import useKanban from 'app/components/Kanban/useKanban';
import { KanbanContext } from 'app/context/TaskContext';

const TaskWeekView = ({ activeTasks, allTaskOpen, setAllTaskOpen }) => {
  const {
    kBoard,
    moveCardToAnotherColumn,
    updateOverdueOnAction,
    updateOverdueTasksOnEdit,
  } = useKanban(activeTasks);

  return kBoard ? (
    <KanbanContext.Provider
      value={{ updateOverdueOnAction, updateOverdueTasksOnEdit }}>
      <KanbanBoard
        kBoard={kBoard}
        moveCardToAnotherColumn={moveCardToAnotherColumn}
        allTaskOpen={allTaskOpen}
        setAllTaskOpen={setAllTaskOpen}
      />
    </KanbanContext.Provider>
  ) : (
    <></>
  );
};

export default TaskWeekView;
