import React, { useContext } from 'react';
import GroupLabelBoardView from 'app/components/Task/GroupLabelView/GroupLabelBoardView';
import GroupLabelListView from 'app/components/Task/GroupLabelView/GroupLabelListView';
import useGroupLabel from 'app/components/Task/GroupLabelView/useGroupLabel';
import { UNIFIED_BOARD, UNIFIED_LIST } from 'app/components/Task/taskConstant';
import { GroupLabelViewContext, TaskContext } from 'app/context/TaskContext';

function GroupLabelView({ allTaskOpen, handleSetAllTaskOpen }) {
  const { currentView, labelGroup, groupLabelResponse } =
    useContext(TaskContext);
  const { fetchNextPageRecords, updateViewAfterChangingLabel } = useGroupLabel({
    labelGroup,
  });

  const contextValue = {
    groupLabelResponse,
    fetchNextPageRecords,
    updateViewAfterChangingLabel,
  };

  const renderGroupLabelView = () => {
    switch (true) {
      case currentView === UNIFIED_LIST:
        return <GroupLabelListView />;
      case currentView === UNIFIED_BOARD:
        return (
          <GroupLabelBoardView
            allTaskOpen={allTaskOpen}
            handleSetAllTaskOpen={handleSetAllTaskOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <GroupLabelViewContext.Provider value={contextValue}>
      {renderGroupLabelView()}
    </GroupLabelViewContext.Provider>
  );
}

export default GroupLabelView;
