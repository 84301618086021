import { useMemo } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { getWorkspaceNiceId } from 'app/selectors/workspace';
const GET_EASY_RX_PRESCRIPTIONS = queryLoader(
  'app/graphql/queries/EasyRx/GetAllPrescriptions.gql',
);

const serializedPrescription = (list) => {
  return list.map((obj) => ({
    name: `${obj.patient_first_name} ${obj.patient_last_name}`,
    labName: obj.laboratory_name,
    link: obj.prescription_link,
    createdAt: obj.createdAt,
    submittedAt: obj.submitted_at,
  }));
};

const emptyArr = [];

export const useFetchPrescriptions = ({ status }) => {
  const workspaceNiceId = useSelector(getWorkspaceNiceId);

  const { data, loading } = useQuery(GET_EASY_RX_PRESCRIPTIONS, {
    variables: {
      status,
      workspaceIdentifier: workspaceNiceId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const prescriptionsList = useMemo(() => {
    return serializedPrescription(
      data?.workspace?.easy_rx_prescriptions || emptyArr,
    );
  }, [data]);

  return {
    loading,
    prescriptionsList,
  };
};

export default useFetchPrescriptions;
