import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import Avatar from 'app/components/Shared/Avatar';
import OsLink from 'app/components/OsLink';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { avatarAttributes } from 'app/utils/entitiesHelper';

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl: '',
      currentZoom: 0,
    };
    this.sliderChange = false;
    this.cropImage = this.cropImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSeekChange = this.onSeekChange.bind(this);
    this.cropperReady = this.cropperReady.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.inputRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // When you transition to save from cropping store the result
    if (nextProps.status === 'save' && this.props.status === 'cropping') {
      this.cropImage();
    }
  }

  cropperReady() {
    this.setState({ currentZoom: 0 });
    this.sliderChange = false;
  }

  handleImageUpload(file) {
    var reader = new FileReader();
    reader.onloadend = () => {
      this.props.setStatus('cropping');
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  handleChange = (delegate) => {
    return (e) => {
      this.handleImageUpload(e.target.files[0]);
      return delegate(e.target.files[0]);
    };
  };

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    if (this.props.setImageValues) {
      this.props.setImageValues(
        this.cropper.getCroppedCanvas().toDataURL(),
        this.cropper.getData(true),
        this.cropper.getImageData(),
      );
    }
  }

  onSeekChange(e) {
    let newVal = e.target.value;
    let difference = newVal - this.state.currentZoom;
    this.setState({ currentZoom: newVal });
    let mult = 1;
    if (difference < 0) {
      mult = -1;
    }

    this.sliderChange = true;
    let modifyVal = Math.pow(1.01, difference * mult);
    this.cropper.zoom((modifyVal - 1) * mult);
  }

  onZoom(e) {
    // Ignore events coming from a change to the slider
    if (this.sliderChange) {
      this.sliderChange = false;
      return;
    }

    let ratio = e.detail.ratio / e.detail.oldRatio;
    let difference = (ratio - 1) * 100;
    let zoom = parseInt(this.state.currentZoom) + Math.round(difference);

    // if overzoomed keep it within bounds
    if (zoom < 0) {
      zoom = 0;
    } else if (zoom > 100) {
      zoom = 100;
      // Don't zoom in further
      e.preventDefault();
    }

    this.setState({ currentZoom: zoom });
  }

  openFile = () => {
    this.inputRef.current.click();
  };

  onCrop = (e) => {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    if (this.props.onCrop) {
      this.props.onCrop(
        this.cropper.getCroppedCanvas().toDataURL(),
        this.cropper.getData(true),
        this.cropper.getImageData(),
      );
    }
  };

  onDrop = (e) => {
    let file = e.dataTransfer.files[0];
    if (this.props.accept.includes(file.type)) {
      this.handleImageUpload(file);
      this.props.input.onChange(file);
    } else {
      e.preventDefault();
    }
  };

  // from https://github.com/erikras/redux-form/issues/1989
  adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

  render() {
    const {
      input: { value: omitValue, onChange, onBlur, name, ...inputProps },
      meta: omitMeta,
      label,
      defaultImageUrl,
      setImageValues,
      status,
      setStatus,
      labelPosition,
      ...props
    } = this.props;
    var $imagePreview = null;
    var croppingClass = status === 'cropping' ? 'cropping' : 'preview';

    if (defaultImageUrl) {
      $imagePreview = (
        <div className='text-center signup-card-logo'>
          <Avatar
            src={defaultImageUrl}
            {...avatarAttributes(this.props.obj, {
              largeAvatarRequired: true,
            })}
            iconWithImage={false}
            className='avatar avatar-full'
          />
        </div>
      );
    }
    return (
      <div
        className={`form-group custom-file w-100 user-profile-image ${croppingClass}`}>
        <div className='user-profile-avatar-area'>
          <label htmlFor={name} className='signup-user-camera-icon'>
            {status !== 'cropping' && !$imagePreview && (
              <div className='signup-card-logo'>
                <OrthoIcon name='selfie' dataHoverNotRequired={true} />
              </div>
            )}
            {status !== 'cropping' && $imagePreview}
            <div className='a-link fs-12'>
              {(!labelPosition || labelPosition === 'below-text') &&
                (this.props.label || 'Select Image')}
            </div>
          </label>
          {status === 'cropping' && (
            <div className='image-edit-cropper-block'>
              <Cropper
                aspectRatio={1 / 1}
                style={{
                  height: this.props.height || 175,
                  width: this.props.width || 175,
                  margin: 'auto',
                }}
                viewMode={3}
                guides={false}
                highlight={false}
                dragMode={'move'}
                cropBoxMovable={false}
                cropBoxResizable={false}
                minCropBoxHeight={this.props.height || 175}
                minCropBoxWidth={this.props.width || 175}
                zoomable={true}
                scalable={true}
                toggleDragModeOnDblclick={false}
                wheelZoomRatio={0.05}
                zoom={this.onZoom}
                ready={this.cropperReady}
                autoTimestamp={false}
                crossOrigin={''}
                src={this.state.imagePreviewUrl || this.props.defaultImageUrl}
                onInitialized={(cropper) => {
                  this.cropper = cropper;
                }}
                checkCrossOrigin={false}
                crop={this.onCrop}
              />
            </div>
          )}
        </div>

        <div className='profile-file-btn' onDrop={this.onDrop}>
          <input
            ref={this.inputRef}
            className='custom-file-input'
            onChange={this.handleChange(onChange)}
            onBlur={this.adaptFileEventToValue(onBlur)}
            type='file'
            accept={this.props.accept}
            name={name}
            {...inputProps}
            {...props}
          />
        </div>

        {labelPosition && labelPosition === 'bottom-button' && (
          <div className='custom-file-control '>
            {status === 'cropping' && (
              <input
                type='range'
                min={0}
                max={100}
                step='any'
                value={this.state.currentZoom}
                onChange={this.onSeekChange}
              />
            )}
            <div className='text-center mb-3'>
              <OsLink
                onClick={this.openFile}
                text={this.props.label}
                className='os-link'
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ImageInput.defaultProps = {
  accept: 'image/*',
  label: 'Upload New Photo',
};
ImageInput = withRouter(ImageInput);

export default ImageInput;
