import { CLOSE_SHARE_MODAL, OPEN_SHARE_MODAL } from 'app/actions/types';

export default function shareModal(
  state = {
    open: false,
    options: {},
  },
  action,
) {
  switch (action.type) {
    case OPEN_SHARE_MODAL:
      return { open: true, options: action.options };
    case CLOSE_SHARE_MODAL:
      return { open: false, options: {} };
    default:
      return state;
  }
}
