import React, { Component } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import PendingView from 'app/components/Shared/PendingView';

class UserStatusView extends Component {
  redirectToRootUrl() {
    return <Navigate to='/' />;
  }

  render() {
    switch (this.props.match.params.type) {
      case 'restricted':
      case 'pending':
      case 'not-referred':
        return <PendingView />;
      default:
        return this.redirectToRootUrl();
    }
  }
}

UserStatusView = withRouter(UserStatusView);

export default UserStatusView;
