import { groupBy, keys, reverse } from 'app/utils/osLodash';

export function getDefaultAppointmentDetailHash() {
  return {
    recordsPresent: false,
    appliancesHash: {
      addedAppliances: [],
      adjustedAppliances: [],
      removedAppliances: [],
      replacedAppliances: [],
    },
  };
}

export function getProcessedAppliancesFromActions(actions, groupedVariantLogs) {
  const groupedActions = groupBy(actions, 'variant_log_id');
  return keys(groupedActions)
    .map((variant_log_id) => {
      if (groupedVariantLogs[variant_log_id]) {
        return {
          ...groupedVariantLogs[variant_log_id][0],
          actions: groupedActions[variant_log_id],
        };
      } else {
        return null;
      }
    })
    .filter((val) => val);
}

export function getProcessedAppliancesFromLogs(
  logs,
  groupedActions,
  appointments,
) {
  return logs.map((log) => {
    const actions = groupedActions[log.id] || [],
      addedActions = actions.filter((action) => action.kind === 'added');
    return {
      ...log,
      actions: addedActions,
      addedAppointment: appointments.find((appointment) =>
        addedActions.some(
          (action) => +action.appointment_id === +appointment.id,
        ),
      ),
      removedAppointment: reverse([...appointments]).find((appointment) =>
        actions.some(
          (action) =>
            +action.appointment_id === +appointment.id &&
            action.kind === 'removed',
        ),
      ),
    };
  });
}

export function getModifiedVariantLogs(kase) {
  return [
    ...kase.variant_logs,
    ...getModifiedAdditionalLogs(kase.software_appliances, 'Software'),
    ...getModifiedAdditionalLogs(kase.equipment_appliances, 'Equipment'),
  ];
}

export function getModifiedAdditionalLogs(appliances, tool_type) {
  appliances = appliances || [];
  return appliances.map((tool) => {
    return {
      __typename: 'VariantLog',
      id: tool.nice_id,
      tool_type,
      variant_FE_info: '',
      tool,
    };
  });
}
