import React, { Component } from 'react';

import { pluralize } from 'app/utils/generalHelper';

export default class Timer extends Component {
  state = {};
  componentDidMount() {
    if (typeof this.props.timerTime == 'number') {
      this.setState({ timeLeft: this.props.timerTime }, this.startTimer);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.timerTime !== this.props.timerTime && nextProps.timerTime)
      this.setState({ timeLeft: nextProps.timerTime }, this.startTimer);
  }

  startTimer() {
    var timer = setInterval(() => {
      let timeLeft = this.state.timeLeft;
      if (timeLeft) {
        this.setState({ timeLeft: timeLeft - 1 });
      } else {
        this.props.timeUp();
        clearInterval(timer);
      }
    }, 1000);
  }

  displayText() {
    let timeLeft = this.state.timeLeft,
      displayText = this.props.text || ' Try again in ',
      text = timeLeft ? displayText : '';

    return timeLeft
      ? text +
          timeLeft +
          pluralize(this.state.timeLeft, {
            singular: ' second',
            plural: ' seconds',
          })
      : '';
  }

  render() {
    return <span>{this.displayText()}</span>;
  }
}
