import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import {lowerCase} from 'app/utils/osLodash';

import CommentListCard from './CommentListCard';

import EventTracker from 'app/services/EventTracker';

import { entityUrl } from 'app/utils/entitiesHelper';
import { isVideo } from 'app/utils/fileHelper';
import { addProtocolIfNotPresent } from 'app/utils/urlHelper';

import { openUpgradeModal } from 'app/actions/upgrade';
import { setActiveResource } from 'app/actions/activeResource';

import { ENTITY_NAME_MAPPER } from 'app/constants';

class OsCards extends Component {
  onCardClick = (e, options = {}) => {
    e.preventDefault();
    e.stopPropagation();
    let obj = this.getObject();
    if (this.props.closeRequired) {
      return; // Do nothing
    } else if (obj.restricted) {
      this.props.openUpgradeModal();
    } else if (this.props.onCardClick) {
      this.props.onCardClick(obj);
    } else {
      let url = options.url || entityUrl(obj),
        openInNewTabRequired =
          obj.__typename === 'AdditionalLink' || this.props.openInNewTab;

      this.props.onEntityShow && this.props.onEntityShow();
      openInNewTabRequired
        ? window.open(addProtocolIfNotPresent(url), '_blank')
        : this.props.navigate(url, this.props.urlPushState || {});
      this.trackEntityShow();

      if (!openInNewTabRequired && obj.__typename === 'Board') {
        this.props.setActiveResource(obj);
      }
    }
  };

  getObject() {
    return this.props.obj;
  }

  trackEntityShow() {
    EventTracker.trackEntityShow(this.getObject());
  }

  getListCardSize() {
    return this.props.size;
  }

  containerClasses() {
    let obj = this.getObject(),
      typename = obj.__typename,
      generic = 'os-list-card-v2 ',
      listCardSpecific = '',
      type = lowerCase(ENTITY_NAME_MAPPER[lowerCase(typename)] || typename);

    if (typename === 'Attachment' && isVideo(obj)) {
      type = 'video';
    } else if (typename === 'User') {
      type = 'user';
    } else if (typename === 'AdditionalLink') {
      type = 'attachment';
    }

    switch (this.getListCardSize()) {
      case 'tall':
        generic += 'tall-list-card';
        listCardSpecific += `os-lg-${type}`;
        break;
      case 'tall-min':
        generic += 'tall-list-card tall-min-list-card';
        listCardSpecific += `os-lg-${type} ${type}-304`;
        break;
      case 'small':
        generic += 'small-list-card';
        listCardSpecific += `os-sm-${type}`;
        break;
      case 'small-min':
        generic += 'small-list-card small-min-list-card';
        listCardSpecific += `os-sm-${type} ${type}-240`;
        break;
      default:
    }

    generic += this.props.closeRequired ? ' with-cross ' : '';
    generic += this.props.selectionMode ? ' selection-mode ' : '';
    generic += this.props.insideModal ? ' inside-modal ' : '';
    generic += this.props.multiSelectMode ? ' multi-selection-mode ' : '';
    generic += this.props.avoidModalActions ? ' avoid-modal-actions ' : '';
    generic += this.props.disableCard ? ' pointer-none ' : '';

    return { generic, listCardSpecific };
  }

  isSmall() {
    return ['small', 'small-min'].includes(this.getListCardSize());
  }

  cardProps() {
    return {
      ...this.props,
      obj: this.getObject(),
      onCardClick: this.onCardClick,
      containerClasses: this.containerClasses().cardSpecific,
    };
  }

  renderListElement() {
    let cardProps = this.cardProps();

    switch (this.getObject().__typename) {
      case 'Comment':
        return <CommentListCard {...cardProps} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <li className={this.containerClasses().generic}>
        {this.renderListElement()}
      </li>
    );
  }
}
OsCards = withRouter(OsCards);
OsCards = connect(({ device }) => ({ device }), {
  openUpgradeModal,
  setActiveResource,
})(OsCards);
export default OsCards;
