import React, { Component, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';
import Loader from 'react-loaders';

import emptycarespace from 'app/images/emptycarespace.png';

import SelectInput from 'app/components/Shared/SelectInput';
import DeclineCareSpace from 'app/components/Shared/CareSpace/DeclineCareSpace';
import OsBtn from 'app/components/OsBtn';
import OsErrorField from 'app/components/OsField/OsErrorField';
import OsLink from 'app/components/OsLink';
import RedirectToDefault from 'app/components/RedirectToDefault';

import { openInfoModal } from 'app/actions/infoModal';

import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';

import { isCareSpace } from 'app/utils/spaceHelper';
import { translate } from 'app/actions/flashMessage';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { getDateFromStringWithGivenFormat } from 'app/utils/timeHelper';
import { FORMAT_START_WITH_MONTH } from 'app/components/Task/taskConstant';
import { updateCurrentUserGraph } from 'app/actions/authentication';

const AUTHENTICATE_SPACE_MUTATION = queryLoader(
  'app/graphql/mutations/Care/AuthenticateSpace.gql',
);
const LOCKED_SPACE_QUERY = queryLoader('app/graphql/LockedSpace.gql');

const MinimalDatePicker = (props) => {
  const [day, setDay] = useState('1');
  const [month, setMonth] = useState('1');
  const [year, setYear] = useState('1970');

  const handleDateChange = () => {
    if (day && month && year) {
      const selectedDate = new Date(year, month - 1, day);
      props.onDateChange(selectedDate, props.form);
    }
  };

  const renderDayOptions = () => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    return days.map((day) => ({ label: day, value: day }));
  };

  const monthOptions = () => {
    const months = [
      { value: '1', label: 'January' },
      { value: '2', label: 'February' },
      { value: '3', label: 'March' },
      { value: '4', label: 'April' },
      { value: '5', label: 'May' },
      { value: '6', label: 'June' },
      { value: '7', label: 'July' },
      { value: '8', label: 'August' },
      { value: '9', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ];
    return months;
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
    return years.map((year) => ({ label: year, value: year }));
  };

  const onChange = (option, key) => {
    if (key === 'Month') {
      setMonth(option.value);
    } else if (key === 'Year') {
      setYear(option.value);
    } else {
      setDay(option.value);
    }
    handleDateChange();
  };

  const renderDropdown = ({ label, options, value }) => {
    const selectedOption = options.find((option) => option.value == value);
    return (
      <div class='form-group'>
        <label htmlFor={label}>{label}:</label>
        <SelectInput
          onChange={(v) => onChange(v, label)}
          id={label}
          isSearchable
          className={`react-select-ortho-2 os-menu-drop pe-4 ${label.toLowerCase()}`}
          onBlur={handleDateChange}
          placeholder={`Select ${label}`}
          value={selectedOption}
          options={options}
        />
      </div>
    );
  };

  return (
    <>
      <div className='d-flex'>
        {renderDropdown({
          label: 'Month',
          options: monthOptions(),
          value: month,
        })}
        {renderDropdown({
          label: 'Day',
          options: renderDayOptions(),
          value: day,
        })}
        {renderDropdown({
          label: 'Year',
          options: renderYearOptions(),
          value: year,
        })}
      </div>
    </>
  );
};

class LockedSpaceView extends Component {
  state = {
    requestInProgress: false,
    dateError: false,
    date: null,
  };

  isCareSpace() {
    return isCareSpace(this.getSpace());
  }

  patientName() {
    return this.getSpace().owner.patient.name;
  }

  getSpace() {
    return this.props.data.locked_space;
  }

  onDateChange = (date, form) => {
    date
      ? this.setState({ date, dateError: false })
      : this.setState({ dateError: true });
  };

  onSubmit = (values) => {
    if (this.state.dateError) return;
    let space = this.getSpace();
    let password = getDateFromStringWithGivenFormat(
      this.state.date,
      FORMAT_START_WITH_MONTH,
    );

    return this.props
      .authenticateSpaceMutation({
        variables: {
          spaceId: space.nice_id,
          password: password,
        },
      })
      .then(({ data }) => {
        let { error, success } = data.authenticateSpace;
        if (success) {
          let { care_spaces_count, visible_care_spaces_count } =
            this.props.currentUser.graph;
          this.props.updateCurrentUserGraph({
            care_spaces_count: care_spaces_count + 1,
            visible_care_spaces_count: visible_care_spaces_count + 1,
          });
          this.props.navigate(`/spaces/${space.nice_id}`);
        } else {
          if (error === 'invalid_password') {
            this.setState({ dateError: true });
          } else {
            this.props.openInfoModal('care_space', 'unlock_space_error');
          }
        }
      });
  };

  renderPasswordHintText() {
    if (this.isCareSpace()) {
      let kase = this.getSpace().owner;
      return (
        <div className='form-text-hint'>
          {translate(
            'CARE_SPACE_PASSWORD_HINT_TEXT',
            { clinic_name: kase.clinic.name, patient_name: kase.patient.name },
            true,
            { connector: '' },
          )}
        </div>
      );
    }
  }

  clinicEmail() {
    return this.getSpace().owner.clinic.email;
  }

  renderDeclinedScreen() {
    return (
      <div className='collection-page pulses'>
        <div className='container-fluid px-52 xl-container '>
          <div className='row carenotpresent'>
            <div className='col'>
              <img src={emptycarespace} alt='WebP rules.' width='480' />
              <div className='info-text-area'>
                <p>
                  {translate(
                    'CARE_SPACE_DECLINED_TEXT',
                    { patient_name: this.patientName() },
                    true,
                    { connector: '' },
                  )}
                </p>
              </div>
              <OsLink
                href={`mailto:${this.clinicEmail()}`}
                className='with-border with-bg osbtn osbtn-secondary without-underline'
                text='Contact clinic team'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.data.loading)
      return <Loader type='ball-triangle-path' active />;

    let space = this.getSpace();
    if (!space) return <RedirectToDefault />;

    if (space.user_request.rejection_reason) return this.renderDeclinedScreen();

    let { requestInProgress } = this.state,
      unlockButtontext = this.isCareSpace() ? 'PatientSpace' : 'Space';

    let clinic = space.owner.clinic;

    return (
      <div className={`dob-password-container`}>
        <div className='image-col text-center'>
          {clinic.logo_big && <img src={clinic.logo_big} alt='' />}
          <div className='clinic-info'>
            <div className='phone-email'>
              {clinic.phone_number && (
                <span>
                  <OrthoIcon name='phone' /> {clinic.phone_number}
                </span>
              )}
              {clinic.email && (
                <span>
                  <OrthoIcon name='mail' />
                  {clinic.email}
                </span>
              )}
            </div>
            {clinic.address && (
              <div className='address'>
                <OrthoIcon name='location' />
                <span>{clinic.address}</span>
              </div>
            )}
          </div>
        </div>
        <div className='text-center form-heading'>
          Complete Verification to Unlock Patient Space
        </div>
        {this.renderPasswordHintText()}
        <Form
          onSubmit={this.onSubmit}
          keepDirtyOnReinitialize={true}
          render={(props) => {
            let initiaDate = props.form.getState().values.dob;
            if (!props.form.getState().values.dob) {
              // initiaDate = props.form.change('dob', '');
            }
            return (
              <form className='appointment-info' onSubmit={props.handleSubmit}>
                <div className='appointment-info-row'>
                  <label>{`Date of Birth for ${this.patientName()}`}</label>
                </div>
                <div className='appointment-info-row'>
                  <MinimalDatePicker
                    {...props}
                    onDateChange={this.onDateChange}
                    form={props.form}
                  />
                </div>
                <div className='appointment-info-row pt-2'>
                  {this.state.dateError && (
                    <OsErrorField touched error={'Please enter correct Date'} />
                  )}
                </div>
                <div className='text-center decline-care-row'>
                  <DeclineCareSpace space={this.getSpace()} icon='' />
                  <OsBtn
                    type='submit'
                    htmlTag='button'
                    text={`Unlock ${
                      this.props.device.mobileDevice ? '' : unlockButtontext
                    }`}
                    extraClass='lock-unlock-btn web-view-btn'
                    name='BtnPrimary'
                    disabled={requestInProgress}
                    loaderRequired={requestInProgress}
                    associatedEntity={this.getSpace()}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

LockedSpaceView = graphql(AUTHENTICATE_SPACE_MUTATION, {
  name: 'authenticateSpaceMutation',
})(LockedSpaceView);
LockedSpaceView = withGraphqlRequests(
  LOCKED_SPACE_QUERY,
  {
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        id: props.match.params.id,
      },
    }),
  },
  {
    objectIdentifier: 'locked_space',
  },
)(LockedSpaceView);
LockedSpaceView = withRouter(LockedSpaceView);
LockedSpaceView = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  { openInfoModal, updateCurrentUserGraph },
)(LockedSpaceView);
export default LockedSpaceView;
