import OsBtn from 'app/components/OsBtn';
import React from 'react';
import {
  FORMAT_START_WITH_DATE_SLASH,
  FORMAT_START_WITH_MONTH_SLASH,
  predefinedDates,
} from 'app/components/Task/taskConstant';
import {
  add,
  currentTimeWithUserTimeZone,
  dateToString,
} from 'app/utils/timeHelper';
import { useDispatch } from 'react-redux';
import { openTaskModal } from 'app/actions/taskModal';

function Reschedule({
  taskId,
  changeDueDate,
  listType,
  updateCache,
  refreshComponent,
  backendDataRequired,
}) {
  const setPredefinedDates = (text) => {
    let date;
    if (text == 'Tomorrow') {
      date = add(currentTimeWithUserTimeZone(), 1, 'days');
    } else if (text == 'In 2 days') {
      date = add(currentTimeWithUserTimeZone(), 2, 'days');
    } else if (text == '1 week from now') {
      date = add(currentTimeWithUserTimeZone(), 7, 'days');
    }
    onChange(date);
  };

  const onChange = (date) => {
    changeDueDate(dateToString(date, FORMAT_START_WITH_DATE_SLASH));
  };

  return (
    <div className='scheduledTaskContainer'>
      <OsBtn
        key='rescheduledTask'
        name='BtnIcon'
        text='Reschedule'
        extraClass='list-button'
        tooltipText='Rescheduled'
        icon='calendar'
        isRightIcon={true}
        rightIcon='chevron-right'
      />

      <div className='customPredefinedDates'>
        {predefinedDates.map((date) => {
          return (
            <CustomDate
              taskId={taskId}
              listType={listType}
              updateCache={updateCache}
              refreshComponent={refreshComponent}
              backendDataRequired={backendDataRequired}
              date={date}
              setPredefinedDates={setPredefinedDates}
            />
          );
        })}
      </div>
    </div>
  );
}

const CustomDate = ({
  date,
  setPredefinedDates,
  taskId,
  listType,
  updateCache,
  refreshComponent,
  backendDataRequired,
}) => {
  const dispatch = useDispatch();
  const handleOnClick = (text) => {
    if (text === FORMAT_START_WITH_MONTH_SLASH) {
      dispatch(
        openTaskModal(taskId, {
          taskType: listType,
          updateCache,
          updateComponent: refreshComponent,
          backendDataRequired,
        }),
      );
    } else {
      setPredefinedDates(text);
    }
  };

  return (
    <div className='predefinedDates' onClick={() => handleOnClick(date.label)}>
      {date.label !== 'MM/DD/YYYY' ? (
        <span className='date'>{date.label}</span>
      ) : (
        <div className='datePlaceholder'>
          <span className='dateplaceholderText'>{date.label}</span>
        </div>
      )}
    </div>
  );
};

export default Reschedule;
