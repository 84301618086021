import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import ContentListing from 'app/components/NavTabs/ContentListing';
import CourseLectureNotAvailableInfo from './CourseLectureNotAvailableInfo';

class LecturesView extends Component {
  getSpace() {
    return this.props.data.space;
  }

  render() {
    if (this.getSpace().lectures_available) {
      return (
        <ContentListing
          data={this.props.data}
          filter={this.props.filter}
          tabDetails={this.props.tabDetails}
        />
      );
    } else {
      return <CourseLectureNotAvailableInfo space={this.getSpace()} />;
    }
  }
}

export default LecturesView;
