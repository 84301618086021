import {
  FORMAT_START_WITH_DATE_DASH,
  FORMAT_START_WITH_MONTH,
  FORMAT_START_WITH_MONTH_SLASH,
} from 'app/components/Task/taskConstant';
import {
  add,
  currentTimeWithUserTimeZone,
  getDateFromStringWithGivenFormat,
  getDateInFormatFromUnix,
  isCurrentDay,
  isTomorrowDay,
  startOfCurrentDay,
  stringToDate,
  subtract,
  timeWithFormat,
} from './timeHelper';
import { getURLEncodedString } from 'app/utils/generalHelper';
import { getParentLabel } from 'app/utils/labelHelper';

export const USER_TASKS_VIEW = ['delegated', 'inbox'];

export const changeRoute = ({
  workspace,
  view,
  user,
  date,
  query,
  textQuery,
  entityId,
  entityType,
}) => {
  if (USER_TASKS_VIEW.includes(user.value)) {
    return getUserRelatedRoute({
      workspace,
      view,
      user,
      date,
      query,
      textQuery,
      entityId,
      entityType,
    });
  }
  let route = `/${workspace.identifier}/tasks`;

  if (user.label === 'My Tasks') route += `/me`;
  else route += `/team`;

  if (
    getDateFromStringWithGivenFormat(date, FORMAT_START_WITH_MONTH) ===
    getDateFromStringWithGivenFormat(
      currentTimeWithUserTimeZone(),
      FORMAT_START_WITH_MONTH,
    )
  )
    route += '/today';
  else
    route += `/${getDateFromStringWithGivenFormat(
      date,
      FORMAT_START_WITH_MONTH,
    )}`;

  if (view === 'list') route += '/list';
  else if (view === 'status') route += '/status';
  else route += '/board';

  let searchParams = {};
  if (user.label !== 'My Tasks' && !!user.id) searchParams.user = user.id;
  if (query) searchParams.filter = query;
  if (textQuery.length > 0)
    searchParams.label = getURLEncodedString(
      textQuery.map((item) => item.name).join(','),
    );

  // Applying all the search params
  if (Object.keys(searchParams).length > 0) {
    route += '?';
    Object.keys(searchParams).forEach((key, index) => {
      route += `${key}=${searchParams[key]}`;
      if (index !== Object.keys(searchParams).length - 1) route += '&';
    });
  }
  return route;
};

export const modifyRoute = (view) => {
  const currentRoute = window.location.pathname;
  if (view === 'board') return currentRoute.replace('list', 'board');
  return currentRoute.replace('board', 'list');
};

export const getUserRelatedRoute = ({
  workspace,
  view,
  user,
  date,
  query,
  textQuery,
  entityId,
  entityType,
}) => {
  let route = `/${workspace.identifier}/tasks/list/${user.value}`;
  const searchParams = {};
  if (query) searchParams.filter = query;
  if (textQuery.length > 0)
    searchParams.label = getURLEncodedString(
      textQuery.map((item) => item.name).join(','),
    );
  if (Object.keys(searchParams).length > 0) {
    route += '?';
    Object.keys(searchParams).forEach((key, index) => {
      route += `${key}=${searchParams[key]}`;
      if (index !== Object.keys(searchParams).length - 1) route += '&';
    });
  }
  return route;
};

export const changeWeekInRoute = ({
  workspace,
  view,
  user,
  week,
  date,
  query,
  textQuery,
  entityId,
  entityType,
}) => {
  let currentDate;
  if (week === 'prev') currentDate = subtract(date, 1, 'week');
  if (week === 'next') currentDate = add(date, 1, 'week');

  return changeRoute({
    workspace,
    view,
    user,
    date: currentDate,
    query,
    textQuery,
    entityId,
    entityType,
  });
};

export const getRGBValue = (hex) => {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return `rgba(${r}, ${g}, ${b},0.38)`;
};

export const getDateFromUnixString = (date) => {
  if (!date) return null;
  return stringToDate(
    `${date.substring(4)}-${date.substring(0, 2)}-${date.substring(2, 4)}`,
  );
};

export const checkTaskIfOverdue = (task) => {
  const dueDate = task.due_date;
  const currentDate = startOfCurrentDay();
  return dueDate && dueDate < currentDate;
};

export const checkTaskIfUnscheduled = (task) => {
  const dueDate = task.due_date;
  return !dueDate;
};

export const getTaskDueDate = (dueDate) => {
  if (isCurrentDay(dueDate)) {
    return `Due today`;
  } else if (isTomorrowDay(dueDate)) {
    return `Due tomorrow`;
  } else {
    return `Due ${timeWithFormat(dueDate, FORMAT_START_WITH_MONTH_SLASH)}`;
  }
};

export const isTaskCommentActivity = (activity) => {
  return activity.source_type === 'TaskCommentActivity';
};

export const changeSameParentLabel = (labels, parent, selectedLabel) => {
  let index;
  for (let i = 0; i < labels.length; i++) {
    for (let j = 0; j < parent.sub_labels.length; j++) {
      if (labels[i].id === parent.sub_labels[j].id) {
        index = i;
        break;
      }
    }
  }

  labels.splice(index, 1, selectedLabel);
  return labels.map((l) => parseInt(l.id));
};

export const getTaskPayloadForLabelUpdateRequest = ({ task, labels }) => {
  const payload = {
    id: task.id,
    assigneeType: 'User',
    assigneeId: task.assignee.id,
    title: task.title,
    description: task.description,
    labelIds: labels,
    dueDate:
      task.dueDate === 'Invalid Date'
        ? ''
        : getDateInFormatFromUnix(task.due_date, FORMAT_START_WITH_DATE_DASH),
    recursiveType: task.recursive_type,
    entityId: task.entity?.id || null,
    entityType: task.entity?.__typename || null,
  };
  return payload;
};

export const getAllLabelsThatHaveSubLabels = (labels) => {
  return labels.filter((label) => label.sub_labels.length > 0);
};

export const removeChildLabel = (label, task) => {
  return {
    ...task,
    entity_labels: task.entity_labels.filter(
      (l) => !label.sub_labels.find((subLabel) => subLabel.id === l.label.id),
    ),
  };
};

export const getLabelListName = (task, labelGroup, labelsData) => {
  for (const l of task.entity_labels) {
    let parentLabel = getParentLabel(l.label, labelsData);
    if (parentLabel.id === labelGroup.id) {
      return l.label.name;
    }
  }
  return null;
};
