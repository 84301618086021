import GoogleAnalyticsTracker from './Trackers/GoogleAnalyticsTracker';
import IntercomTracker from './Trackers/IntercomTracker';
import MixpanelTracker from './Trackers/MixpanelTracker';
import store from 'app/store';

import { cloneDeep, isArray, toLower } from 'app/utils/osLodash';

import { ALPHABET_NUMBERS_REGEX } from 'app/constants';

class EventTracker {
  login(email) {
    if (this.isTrackingEnabled()) MixpanelTracker.login(email);
  }

  logout(email) {
    if (this.isTrackingEnabled()) MixpanelTracker.logout(email);
  }

  activeSession(email) {
    if (this.isTrackingEnabled()) MixpanelTracker.activeSession(email);
  }

  register(user) {
    if (this.isTrackingEnabled()) {
      MixpanelTracker.register(user);
      GoogleAnalyticsTracker.register(user);
      IntercomTracker.register(user);
    }
  }

  identify(userId, initialData = {}) {
    if (this.isTrackingEnabled())
      MixpanelTracker.identify(userId, (initialData = {}));
  }

  track(eventName, data = {}) {
    if (this.isTrackingEnabled()) {
      GoogleAnalyticsTracker.track(eventName, data);
      MixpanelTracker.track(eventName, data);
    }
  }

  trackForEntity(eventName, obj) {
    if (this.isTrackingEnabled())
      MixpanelTracker.trackForEntity(eventName, obj);
  }

  trackFailure(eventName, obj = {}) {
    if (this.isTrackingEnabled()) {
      let event = `button.click.${eventName}.failure`;
      MixpanelTracker.trackFailure(event, obj);
    }
  }

  trackInputField(eventName, obj) {
    if (this.isTrackingEnabled())
      MixpanelTracker.trackInputField(eventName, obj);
  }

  trackLink(eventName, obj = {}) {
    if (this.isTrackingEnabled())
      MixpanelTracker.trackLinkClicked(eventName, obj);
  }

  trackDropdown(eventType, obj = {}) {
    if (this.isTrackingEnabled()) MixpanelTracker.trackDropdown(eventType, obj);
  }

  trackTabs(eventName, obj) {
    if (this.isTrackingEnabled()) MixpanelTracker.trackTabs(eventName);
  }

  trackInputFocusEvent(name, obj) {
    if (name) {
      this.trackInputField(`input.focus.${name}`, obj);
    }
  }

  trackKeyPressed(key, obj = {}) {
    if (!ALPHABET_NUMBERS_REGEX.test(key)) {
      if (key === ' ') {
        key = 'space';
      }
      if (key.trim()) {
        this.trackInputField(`keyboard.keyup.${key}`);
      }
    }
  }

  trackInputBlurEvent(name, obj) {
    if (name) {
      this.trackInputField(`input.defocus.${name}`, obj);
    }
  }

  trackDropdownOpen(type, obj = {}) {
    this.trackDropdown(`dropdown.show.${type}`, obj);
  }

  trackDropdownClick(type, clickedOption, obj = {}) {
    this.trackDropdown(`dropdown.select.${type}.${clickedOption}`, obj);
  }

  trackOsBtnClick(eventName, obj) {
    if (this.isTrackingEnabled()) {
      if (eventName) {
        MixpanelTracker.trackOsBtn(`button.click.${eventName}`, cloneDeep(obj));
      }
    }
  }

  trackEntityDragged(entityType, draggedEntityId, obj) {
    if (this.isTrackingEnabled()) {
      if (isArray(draggedEntityId)) {
        draggedEntityId.map((draggedItemId) =>
          MixpanelTracker.trackEntityDragged(
            `dragged.${entityType}`,
            draggedItemId,
            obj,
          ),
        );
      } else {
        MixpanelTracker.trackEntityDragged(
          `dragged.${entityType}`,
          draggedEntityId,
          obj,
        );
      }
    }
  }

  marginClick(entityType, entityId, obj) {
    if (this.isTrackingEnabled()) {
      MixpanelTracker.trackGeneralAction(
        `${entityType}.margin.click`,
        { entityId },
        obj,
      );
    }
  }

  sectionAction(eventName, name, obj) {
    if (this.isTrackingEnabled()) {
      MixpanelTracker.trackGeneralAction(`section.${eventName}`, { name }, obj);
    }
  }

  trackEntityShow(entity) {
    if (this.isTrackingEnabled()) {
      MixpanelTracker.trackEntityShow(entity);
    }
  }

  trackSelectTabEvent(tabName, obj = {}) {
    this.trackTabs(`tab.select.${tabName}`);
  }

  trackLinkClicked(name, obj = {}) {
    this.trackLink(`link.click.${name}`, obj);
  }

  isTrackingEnabled() {
    // NOTE: Mixpanel setting checks used for both google analytics and mixpanel.
    let setting =
      store.getState().systemConfig.configs.mixpanel_tracking_enabled;
    return setting && toLower(setting.value) === 'true';
  }
}

export default new EventTracker();
