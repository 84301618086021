import React from 'react';
import { Field, Form } from 'react-final-form';

import OsField from 'app/components/OsField';
import { fieldRequired } from 'app/utils/validationHelper';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import FolderModalButtons from 'app/components/FolderModal/FolderModalButtons';
import { FOLDER_CATEGORIES } from 'app/components/FolderModal/constants';

const AddFolderForm = ({
  closeModal,
  onSubmit,
  onFolderBannerChange,
  entity,
  username,
  isSubmitDisabled,
  isSubmitting,
  folderBannerUrl,
  uploadPercentage,
}) => {
  const renderType = (categoryData) => {
    const { name, value } = categoryData;

    return (
      <div key={value} className={value + '-folder'}>
        <label className={'list-type-label d-flex'}>
          <Field
            component='input'
            type='radio'
            name='category'
            validate={fieldRequired}
            id={value}
            value={value}
            defaultValue={'open'}
            className='radio-input-element'
          />
          <div className='radio-block'>
            {value === 'open' ? (
              <OrthoIcon name='members' dataHoverNotRequired={true} />
            ) : (
              <OrthoIcon name='locked' dataHoverNotRequired={true} />
            )}

            <span className='label-helper'>
              {value === 'open' ? 'Team' : name} Folder
            </span>
          </div>
        </label>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <p className={'description'}>Drag and drop your image here</p>
        <p className={'description'}>
          or <span>browse your files</span>
        </p>
        <p className={'description-limit'}>
          The size of the image file must not exceed 8MB.
        </p>
      </>
    );
  };

  const fileUploadStatusText = (file) => {
    const showProgress = uploadPercentage > 0;

    if (showProgress) {
      return (
        <div class='file-status'>
          {uploadPercentage < 100 ? (
            <span className='file-uploading'>
              <OrthoIcon
                name='update'
                dataHoverNotRequired={true}
                defaultClass='elem-rotate me-1'
              />
              {`${uploadPercentage}% uploaded`}
            </span>
          ) : (
            <span className='file-upload-successfull'>
              <OrthoIcon name='doubleTick' dataHoverNotRequired={true} />{' '}
              Successfully uploaded
            </span>
          )}
        </div>
      );
    }
  };
  const initialValues = { ...entity, initialInformation: entity.information };
  return (
    <Form
      onSubmit={onSubmit}
      disableOnEnter={true}
      destroyOnUnregister={true}
      keepDirtyOnReinitialize={true}
      initialValues={initialValues}>
      {
        // Form
        ({ handleSubmit, values, form }) => {
          return (
            <form className={'folder-modal-form'} onSubmit={handleSubmit}>
              <div className={'form-container'}>
                {/* Title Field of the form */}

                <div className='folder-name'>
                  {values.image && (
                    <img
                      src={values.image}
                      alt=''
                      className='space-hero-image'
                    />
                  )}
                  <Field
                    name={'folderName'}
                    type={'text'}
                    component={OsField}
                    osType={'input'}
                    placeholder={'Folder Name'}
                    validate={fieldRequired}
                    autoFocus={true}
                  />
                </div>

                <div className={'folder-type-list '}>
                  <label htmlFor={'folder_type'}>Folder Type</label>
                  <div className='radio-button-group'>
                    {FOLDER_CATEGORIES.map(renderType)}
                  </div>
                  <p>
                    {
                      FOLDER_CATEGORIES.find(
                        (category) => category.value === values.category,
                      )?.description
                    }
                  </p>
                </div>

                <div className={'folder-banner-field'}>
                  <Field
                    name={'image'}
                    osType={'fileUpload'}
                    label={'Folder image'}
                    previewRequired={true}
                    src={folderBannerUrl}
                    required={false}
                    description={renderDescription()}
                    onChange={onFolderBannerChange}
                    component={OsField}
                  />
                  {fileUploadStatusText()}
                </div>

                {/* Render Submit buttons */}
                <FolderModalButtons
                  initialValues={entity}
                  isSubmitting={isSubmitting}
                  username={username}
                  handleSubmit={handleSubmit}
                  closeModal={closeModal}
                  isDisabled={isSubmitDisabled}
                />
              </div>
            </form>
          );
        }
      }
    </Form>
  );
};

export default AddFolderForm;
