import React, { Component } from 'react';

import NoEntityBoard from './Board';
import NoEntityCase from './Case';
import NoEntityComparison from './Comparison';
import NoEntityPost from './Post';

class NoEntityFound extends Component {
  getType() {
    if (this.props.entity === 'following') {
      return 'MostActiveDoctorsNotFollowedByMe';
    } else if (this.props.entity === 'connection') {
      return 'RecommendedDoctorsToConnect';
    }
  }

  render() {
    let entity = this.props.entity;

    switch (entity) {
      case 'board':
        return <NoEntityBoard />;
      case 'case':
        return <NoEntityCase />;
      case 'comparison':
        return <NoEntityComparison />;
      case 'post':
        return <NoEntityPost />;
      default:
        return <div />;
    }
  }
}

export default NoEntityFound;
