import React from 'react';
import * as compose from 'lodash.flowright';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import AbstractField from './AbstractField';
import OsField from './';
import SelectInput from 'app/components/Shared/SelectInput';

import { sortBy } from 'app/utils/osLodash';
import { isPresent } from 'app/utils/stringHelper';

const COUNTRIES_QUERY = queryLoader('app/graphql/Countries.gql');

class OsCountryCodeSelect extends AbstractField {
  componentDidUpdate(prevProps) {
    if (
      prevProps.data.loading &&
      !this.props.data.loading &&
      !this.props.input.value
    ) {
      this.props.setFormInitialValue(
        this.props.input.name,
        this.sortedCountries()[0].phoneCode,
      );
    }
  }

  getCountries() {
    let { countries } = this.props.data;
    return countries ? countries : [];
  }

  getSelectArgument(country) {
    return {
      label: country.phone_code_format,
      phoneCode: country.phone_code,
      value: country.id,
    };
  }

  getValue() {
    let { countries } = this.props.data;
    if (countries) {
      let country;
      if (isPresent(this.props.input.value)) {
        country = this.getCountries().find(
          (country) => country.phone_code === this.props.input.value,
        );
      } else {
        country = countries[0];
      }
      return this.getSelectArgument(country);
    }
  }

  onChange = (country) => {
    this.props.onChange(this.props.input.name, country.phoneCode);
  };

  sortedCountries() {
    return sortBy(
      this.getCountries().map((country) => this.getSelectArgument(country)),
      'label',
    );
  }

  render() {
    return (
      <>
        <OsField {...this.props} osType='label' isWrapperRequired={false} />
        <SelectInput
          {...this.getFieldProps()}
          options={this.sortedCountries()}
          backspaceRemoves={false}
          value={this.getValue()}
          onChange={this.onChange}
        />
        <OsField {...this.props} osType='error' isWrapperRequired={false} />
        {this.renderChildren()}
      </>
    );
  }
}

OsCountryCodeSelect = graphql(COUNTRIES_QUERY, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      countriesWithPhoneCodeRequired: true,
    },
  }),
})(OsCountryCodeSelect);

OsCountryCodeSelect.defaultProps = {
  meta: {},
  input: {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  saveInputRef: () => {},
  setFormInitialValue: () => {},
};
export default OsCountryCodeSelect;
