import React, { Component } from 'react';
import OsBtn from 'app/components/OsBtn';

class BackToTop extends Component {
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <OsBtn
        name='BtnIcon'
        extraClass='backtotop'
        icon='chevron-up'
        onClick={this.scrollToTop}>
        <span className='back-text'> Back to top</span>
      </OsBtn>
    );
  }
}

export default BackToTop;
