import React, { Component } from 'react';
import { connect } from 'react-redux';

import OsBtn from 'app/components/OsBtn';

import { translate } from 'app/actions/flashMessage';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';

class PasswordUpdateBlock extends Component {
  onConfigClose = () => {
    this.props.onConfigClose(this.props.name);
  };

  render() {
    return (
      <article className='small-password-alert'>
        <OsBtn
          name='BtnIcon'
          icon='close'
          extraClass='no-text p-0 alert-close'
          onClick={this.onConfigClose}
        />
        <p className='semibold lh-1'>{translate('CHANGE_PASSWORD_HEADING')}</p>
        <div className='os-text-6'>{translate('CHANGE_PASSWORD_CONTENT')}</div>

        <div className='action-col mt-2 text-right'>
          <OsBtn
            name='BtnPrimary'
            text='Change password'
            extraClass='ls-05'
            onClick={this.props.openPasswordUpdateModal}
          />
        </div>
      </article>
    );
  }
}

PasswordUpdateBlock = connect(null, { openPasswordUpdateModal })(
  PasswordUpdateBlock,
);

export default PasswordUpdateBlock;
