export const CASE_THREE_D_VIDEO_TYPES = [
  'Hard Tissue/Hard Tissue 1',
  'Hard Tissue/Hard Tissue 2',
  'Soft Tissue/Soft Tissue 1',
  'Soft Tissue/Soft Tissue 2',
  'Soft Tissue/Airway 1',
  'Soft Tissue/Airway 2',
  'Models/Both',
  'Models/Upper Teeth',
  'Models/Lower Teeth',
  'Models/Upper',
  'Models/Lower',
  'Section/Axial',
  'Section/Coronal',
  'Section/Sagittal',
];
export const CASE_IMAGE_TYPES = ['External', 'Gallery Image', 'Internal'];
export const CASE_LOCKED_VIEWING_MODES = ['T1'];
export const CASE_PHOTOS_VIEWING_MODES = ['T1', 'T2', 'SBS'];
export const CASE_TIMELINES = ['T1', 'T2'];
export const CASE_TWO_D_VIDEO_TYPES = ['X-ray/Cephalogram', 'X-ray/Panorex'];
export const CASE_TWO_D_VIEWING_MODES = CASE_PHOTOS_VIEWING_MODES;
export const CASE_VIDEO_TYPES = [
  ...CASE_THREE_D_VIDEO_TYPES,
  ...CASE_TWO_D_VIDEO_TYPES,
];
export const CASE_VIDEO_AND_IMAGES_TYPES = [...CASE_VIDEO_TYPES, 'Photos'];
export const CASE_VIDEO_AND_IMAGES_TYPES_ORDERED = [
  'Photos',
  ...CASE_VIDEO_TYPES,
];
export const CASE_VIEWING_MODES = [
  ...CASE_TWO_D_VIEWING_MODES,
  'SUP-ACB',
  'SUP-MND',
];
export const CASE_VIEWING_MODES_ORDERED = [
  'SBS',
  'T1',
  'T2',
  'SUP-ACB',
  'SUP-MND',
];
