import React, { useContext, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';

import {
  FORMAT_START_WITH_MONTH_SLASH,
  FORMAT_START_WITH_YEAR,
} from 'app/components/Task/taskConstant';
import { getRGBValue } from 'app/utils/taskHelper';
import { isBeforeTodayDate, timeWithFormat } from 'app/utils/timeHelper';
import store from 'app/store';

import checkedCircle from 'app/images/task-manager/checkedCircle.svg';
import dueDate from 'app/images/task-manager/dueDate.svg';

import { openInfoModal } from 'app/actions/infoModal';
import ClickPopupWrapper from 'app/components/Shared/ClickPopupWrapper';
import SelectInput from 'app/components/Shared/SelectInput';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import RenderTaskActionButton from 'app/components/Task/RenderTaskActionButton';
import { useMutation } from '@apollo/client';
import OsCards from '../OsCards';
import { WorkspaceContext } from 'app/components/Layouts/WorkspaceLayout';
import { openTaskPreviewModal } from 'app/actions/taskPreviewModal';
import RenderLabel from 'app/components/Task/RenderLabel';
import { getPartnerSpaceData } from 'app/utils/Workspace/generalHelper';
import { isPartnerSpace } from 'app/utils/spaceHelper';

const TASK_COMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskProcessed.gql',
);
const TASK_UNCOMPLETED = queryLoader(
  'app/graphql/mutations/Task/MarkTaskUnprocessed.gql',
);
const UPDATE_DUEDATE = queryLoader(
  'app/graphql/mutations/Task/TaskDueDateChange.gql',
);
const UPDATE_ASSIGNEE = queryLoader(
  'app/graphql/mutations/Task/TaskAssigneeChange.gql',
);
const DELETE_TASK = queryLoader('app/graphql/mutations/Task/TaskDelete.gql');

function WidgetTask({
  allRelatedTasks,
  task,
  listType = 'EntityRelatedTasks',
  assigneeList,
  deleteTaskFromList,
  updateList,
  setRelatedTasksList,
}) {
  const { all_members, task_labels } = useContext(WorkspaceContext);
  const [collapsed, setCollapsed] = useState(true);
  const [assignee, setAssignee] = useState(task.assignee);
  const [markTaskProcessed] = useMutation(TASK_COMPLETED);
  const [markTaskUnprocessed] = useMutation(TASK_UNCOMPLETED);
  const [updateDueDate] = useMutation(UPDATE_DUEDATE);
  const [updateAssignee] = useMutation(UPDATE_ASSIGNEE);
  const [deleteCurrentTask] = useMutation(DELETE_TASK);
  const dispatch = useDispatch();

  const updateCache = () => {};

  const updateRelatedTasksList = (taskId, data) => {
    let updatedList = allRelatedTasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          ...data,
        };
      } else {
        return task;
      }
    });
    setRelatedTasksList(updatedList);
  };

  const markAsDone = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    markTaskProcessed({
      mutation: TASK_COMPLETED,
      variables: { id: task.id },
    }).then(({ data }) => {
      if (data.markTaskProcessed.success) {
        updateRelatedTasksList(task.id, data.markTaskProcessed.entity);
      }
    });
  };

  const markAsNotDone = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    markTaskUnprocessed({
      mutation: TASK_UNCOMPLETED,
      variables: { id: task.id },
    }).then(({ data }) => {
      if (data.markTaskUnprocessed.success) {
        updateRelatedTasksList(task.id, data.markTaskUnprocessed.entity);
      }
    });
  };

  const changeDueDate = (dueDate) => {
    updateDueDate({
      mutation: UPDATE_DUEDATE,
      variables: {
        id: task.id,
        dueDate: dueDate,
      },
    }).then(({ data }) => {
      if (data.changeDueDate.success) {
        updateRelatedTasksList(task.id, data.changeDueDate.entity);
      }
    });
  };

  const changeAssignee = (assignee) => {
    if (task.assignee.id === assignee.id) return;
    updateAssignee({
      variables: {
        id: task.id,
        assigneeId: assignee.id,
        assigneeType: 'User',
      },
      onCompleted() {
        setAssignee(assignee);
      },
    }).then(({ data }) => {
      if (data.changeAssignee.success) {
        updateRelatedTasksList(task.id, data.changeAssignee.entity);
      }
    });
  };

  const deleteTask = (closeDeleteConfirmationModal) => {
    deleteCurrentTask({
      mutation: DELETE_TASK,
      variables: {
        id: task.id,
      },
    }).then(({ data }) => {
      if (!data.deleteTask.success) {
        store.dispatch(openInfoModal('general', 'error_message'));
      } else {
        deleteTaskFromList(task.id);
        closeDeleteConfirmationModal();
      }
    });
  };

  const openPreviewModal = (e) => {
    e.stopPropagation();
    dispatch(openTaskPreviewModal(task.id, {}));
  };

  const onDropDownClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setCollapsed((prev) => !prev);
  };

  return (
    <div className='singleTask' key={task.id} onClick={openPreviewModal}>
      <div className='top'>
        <div className='completeCheckBox'>
          {task.completed === true ? (
            <div onClick={markAsNotDone} style={{ cursor: 'pointer' }}>
              <img
                src={checkedCircle}
                alt='checkedCircle'
                width='16'
                height='16'
              />
            </div>
          ) : (
            <ClickPopupWrapper onClick={markAsDone}>
              <OrthoIcon
                name='checkmark-round-default'
                dataHoverNotRequired={true}
              />
            </ClickPopupWrapper>
          )}
        </div>
        <div className='titleAndCreator'>
          <div
            className='title'
            style={
              task.completed
                ? { textDecoration: 'line-through' }
                : { textDecoration: '' }
            }>
            {task.title}
          </div>
          <div className='dueDate'>
            <span>Due</span>
            {timeWithFormat(task.due_date, FORMAT_START_WITH_MONTH_SLASH)}
            {isBeforeTodayDate(task.due_date, FORMAT_START_WITH_YEAR) && (
              <img src={dueDate} alt='dueDate' width='16' height='16' />
            )}
          </div>
        </div>
        <div className='btnContainer'>
          <RenderTaskActionButton
            task={task}
            listType={listType}
            assigneeList={assigneeList}
            changeDueDate={changeDueDate}
            markAsDone={markAsDone}
            markAsNotDone={markAsNotDone}
            deleteTask={deleteTask}
            changeAssignee={changeAssignee}
            updateCache={updateCache}
            refreshComponent={updateList}
            backendDataRequired={true}
            isSliceRequired={false}
          />
          <div className={'widget-chevron-icon'} onClick={onDropDownClick}>
            {collapsed ? (
              <OrthoIcon name='chevron-caret-right' defaultClass='ms-1' />
            ) : (
              <OrthoIcon name='chevron-caret-down' defaultClass='ms-1' />
            )}
          </div>
        </div>
      </div>
      {!collapsed && (
        <div className='creator'>
          by {task.created_by.full_name} •{' '}
          {timeWithFormat(
            task.created_at,
            `${FORMAT_START_WITH_MONTH_SLASH} hh:mm A`,
          )}
        </div>
      )}
      {!collapsed && (
        <>
          <div
            className='description'
            style={
              task.completed
                ? { textDecoration: 'line-through' }
                : { textDecoration: '' }
            }>
            {parse(task.description)}
          </div>
          {task.entity.hasOwnProperty('id') && (
            <div className='entityWrapper'>
              <OsCards
                size={'extra-small'}
                obj={task.entity}
                sliderPreview={true}
                shareRequired={true}
              />
            </div>
          )}
          <div
            className='assigneeAndLabels'
            onClick={(e) => e.stopPropagation()}>
            <div className='teamMembersDropdown'>
              <SelectInput
                isMulti={false}
                name='assignee'
                className='react-select-ortho-2'
                formValue={task.assignee.id}
                onChange={changeAssignee}
                async={true}
                isSearchable={false}
                perPage={50}
                idQuery={getPartnerSpaceData()?.nice_id}
                queryType={
                  isPartnerSpace(task.entity)
                    ? 'AllSpaceMembers'
                    : `AllSpaceMembers`
                }
                selectedValueNameRequired={false}
              />
            </div>
            <div className='labelsContainer'>
              {task.entity_labels.map((label, index) => {
                return (
                  <>
                    {index === 0 && (
                      <div
                        key={label.id}
                        className='taskLabelContainer'
                        style={{
                          backgroundColor: `${getRGBValue(label.label.color)}`,
                        }}>
                        <div className='singleTaskLabel'>
                          <RenderLabel
                            task={task}
                            label={label.label}
                            allLabels={task_labels}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WidgetTask;
