import Avatar from 'app/components/Shared/Avatar';
import { avatarAttributes } from 'app/utils/entitiesHelper';

const MemberLabel = ({ member }) => {
  return (
    <div>
      <Avatar
        className={'avatar'}
        style={{ borderRadius: '50% important' }}
        {...avatarAttributes(member)}
      />
      <span>{member.full_name}</span>
    </div>
  );
};

export default MemberLabel;
