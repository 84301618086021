import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { entityUrl } from 'app/utils/entitiesHelper';
import OsLink from 'app/components/OsLink';

import Avatar from 'app/components/Shared/Avatar';
import ParseText from 'app/components/ParseText';
import UserQualificationInfo from 'app/components/Shared/UserQualificationInfo';

import { avatarAttributes } from 'app/utils/entitiesHelper';
import { timestamp, timestampOnHover } from 'app/utils/timeHelper';

class CommentListCard extends Component {
  render() {
    let comment = this.props.obj;

    return (
      <>
        <div className='media-header d-flex align-items-center mb-1'>
          <Avatar
            className='avatar avatar-24 me-2'
            {...avatarAttributes(comment.author)}
          />
          <UserQualificationInfo
            user={comment.author}
            className='fs-16 me-2 text-truncate mw-130'
            osLinkClass='os-text-9 '
            degreeNotRequired={true}
            titleRequired={true}
            linkRequired={true}
          />
          <span
            className='os-text-6 fs-12'
            title={timestampOnHover(comment.created_at)}>
            {timestamp(comment.created_at)}
          </span>
        </div>
        <ParseText obj={comment} className='comment-text-helper custom-class' />
      </>
    );
  }
}

CommentListCard = withRouter(CommentListCard);
export default CommentListCard;
