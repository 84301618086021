import { loader as queryLoader } from 'graphql.macro';
import client from 'app/graphql/client';

import Authenticate from 'app/services/Authenticate.js';

import { translate } from 'app/actions/flashMessage';

import { toSentence, humanize } from 'app/utils/stringHelper';

import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from './types';
import { replaceCurrentDomain } from 'app/utils/urlHelper';

const SIGNUP_USER_MUTATION = queryLoader(
  'app/graphql/mutations/SignupUser.gql',
);

export function signup(
  attributes,
  afterSuccessActions,
  navigate,
  redirectPath,
) {
  return (dispatch) => {
    dispatch({ type: SIGNUP_REQUEST });

    return client
      .mutate({
        mutation: SIGNUP_USER_MUTATION,
        variables: attributes,
      })
      .then(({ data: { signup } }) => {
        const { token, errors, user } = signup;
        if (token) {
          Authenticate.setUserTokenInCookie({ token });
          dispatch({
            type: SIGNUP_SUCCESS,
            payload: { token, graph: user },
          });
          if (redirectPath) {
            navigate(`${replaceCurrentDomain(redirectPath)}`);
          } else {
            navigate('/default');
          }
          afterSuccessActions && afterSuccessActions();
        }

        if (errors) {
          let errorMessage = '',
            signupErrorFields = errors;
          if (signupErrorFields.length) {
            errorMessage = toSentence(
              signupErrorFields.map(
                (error) => humanize(error.field) + ' ' + error.message,
              ),
            );
          } else {
            errorMessage = translate('CREATE_ERROR');
          }
          dispatch({
            type: SIGNUP_FAILURE,
            errorMessage: errorMessage,
          });
        }
      });
  };
}
