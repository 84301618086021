import React from 'react';

import CaseResult from './CaseResult';
import UserResult from './UserResult';
import ComparisonResult from './ComparisonResult';
import BoardResult from './BoardResult';
import TagResult from './TagResult';
import ToolResult from './ToolResult';
import CompanyResult from './CompanyResult';
import NoResult from './NoResult';
import ToolTagResult from './ToolTagResult';

import { omit } from 'app/utils/osLodash';

// render the correct card for the object provided
export default function (props) {
  let customProps = {
    ...props.obj,
    ...omit(props, 'obj'),
  };

  switch (props.obj.__typename) {
    case 'User':
      return <UserResult {...customProps} />;
    case 'Case':
      return <CaseResult {...customProps} />;
    case 'Comparison':
      return <ComparisonResult {...customProps} />;
    case 'Tool':
      return <ToolResult {...customProps} />;
    case 'ToolTag':
      return <ToolTagResult {...customProps} />;
    case 'Company':
      return <CompanyResult {...customProps} />;
    case 'CaseTag':
      return <TagResult {...customProps} />;
    case 'Board':
      return <BoardResult {...customProps} />;
    case 'NoResult':
      return <NoResult {...customProps} />;
    default:
      return null;
  }
}
