import React, { useEffect, useCallback, useContext } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';

import { flashError } from 'app/actions/flashMessage';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import PinnedMessage from 'app/components/TeamSpaceCommunicationsView/PinnedMessage';
import { ceil, isEqual, now } from 'app/utils/osLodash';
import { PinnedMessageContext } from 'app/context/PinnedMessageContext';
import { pluralize } from 'app/utils/generalHelper';
import usePreviousValue from 'app/hooks/usePreviousValue';

const UNPIN_OBJECT_MUTATION = queryLoader(
  'app/graphql/mutations/UnpinObject.gql',
);

const PinnedMessageListingWrapper = (props) => {
  const previousPinnedCommentList = usePreviousValue(props.results);
  const { pinnedMessages, setPinnedMessages, pinnedMessageCount } =
    useContext(PinnedMessageContext);

  useEffect(() => {
    if (!isEqual(previousPinnedCommentList, props.results)) {
      const pinnedMessage = props.results.filter((p) => p.pinned);
      setPinnedMessages(pinnedMessage, props.totalRecords);
    }
  }, [props.results]);

  const getOptimisticResponseProps = (pinned) => {
    let data = {
      __typename: 'Response',
      success: true,
      error: '',
      entity: {
        ...props.obj,
        pinned,
        pinned_at: pinned ? ceil(now() / 1000) : null,
      },
    };
    return data;
  };

  const renderPinnedMessages = () => {
    return pinnedMessages.map((r) => (
      <PinnedMessage
        key={r.id}
        conversation={r}
        unpinObject={handleUnpinObject}
      />
    ));
  };

  const handleUnpinObject = useCallback(
    (conversation) => {
      props
        .unpinObjectMutation({
          variables: {
            entityId: conversation.id,
            entityType: conversation.__typename,
          },
          optimisticResponse: () => {
            return { unpinObject: getOptimisticResponseProps(false) };
          },
        })
        .then(({ data }) => {
          if (!data.unpinObject.success) {
            // Open general modal here.
            let error = data.unpinObject.error;
            props.flashError(error || 'CREATE_ERROR', !error.length);
          } else {
            // Filter pinned messages
            setPinnedMessages(
              pinnedMessages.filter((p) => p.id !== conversation.id),
              pinnedMessageCount - 1,
            );
          }
        });
    },
    [pinnedMessages, pinnedMessageCount],
  );

  if (props.loading) return <Loader type='ball-triangle-path' active />;

  return (
    <div className='pinned-chat-container'>
      <div className='pinned-head'>
        <OrthoIcon
          name='pin'
          defaultClass='conversation-pin active'
          dataHoverNotRequired={true}
        />
        <span>
          Pinned{' '}
          {pluralize(pinnedMessageCount, {
            singular: 'message',
            plural: 'messages',
          })}{' '}
          ({pinnedMessageCount})
        </span>
      </div>
      <div className='pinned-body'>{renderPinnedMessages()}</div>
    </div>
  );
};

const PinnedMessageListingConnect = connect(
  ({ currentUser }) => ({ currentUser }),
  { flashError },
)(PinnedMessageListingWrapper);
const PinnedMessageListing = compose(
  graphql(UNPIN_OBJECT_MUTATION, { name: 'unpinObjectMutation' }),
)(PinnedMessageListingConnect);

export default PinnedMessageListing;
