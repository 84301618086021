import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

// import DoctorNotVerifiedBlock from './DoctorNotVerifiedBlock';
import PasswordUpdateBlock from 'app/components/PasswordUpdateBlock';

import { updateCurrentUserGraph } from 'app/actions/authentication';

const UPDATE_SYSTEM_NOTIFICAION_CLOSED_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateSystemNotificationClosed.gql',
);

class SystemNotifications extends Component {
  passwordUpdateBlock() {
    let {
      update_password_notification_required,
      password_sticky_notification_required,
    } = this.props.currentUser.graph;
    if (
      update_password_notification_required &&
      password_sticky_notification_required
    )
      return (
        <PasswordUpdateBlock
          name='password_update'
          onConfigClose={this.onConfigClose}
        />
      );
  }

  getUserUpdatedValues(name) {
    switch (name) {
      case 'password_update':
        return { password_sticky_notification_required: false };
      case 'doctor_not_verified':
        return { not_verified_banner_required: false };
      default:
        return {};
    }
  }

  onConfigClose = (notificationType) => {
    this.props
      .updatSystemNotificationClosedAt({ variables: { notificationType } })
      .then(({ data }) => {
        if (data.updateSystemNotificationClosedAt.success) {
          this.props.updateCurrentUserGraph(
            this.getUserUpdatedValues(notificationType),
          );
        } else {
          console.log(data.updateSystemNotificationClosedAt.error);
        }
      });
  };

  renderNotVerifiedInformationBlock() {
    // let {not_verified_banner_required, prefered_doctor: preferredDoctor} = this.props.currentUser.graph;
    // if(not_verified_banner_required)
    //   return <DoctorNotVerifiedBlock name='doctor_not_verified' onConfigClose={this.onConfigClose} preferredUser={preferredDoctor}/>
  }

  renderBlocks() {
    if (!this.props.device.mobileDevice)
      return (
        <>
          {this.passwordUpdateBlock()}
          {this.renderNotVerifiedInformationBlock()}
        </>
      );
  }

  render() {
    return <div className='custom-fixed-banner'>{this.renderBlocks()}</div>;
  }
}

SystemNotifications = connect(
  ({ currentUser, currentUserSessionValid, device }) => ({
    currentUser,
    currentUserSessionValid,
    device,
  }),
  { updateCurrentUserGraph },
)(SystemNotifications);
SystemNotifications = graphql(UPDATE_SYSTEM_NOTIFICAION_CLOSED_MUTATION, {
  name: 'updatSystemNotificationClosedAt',
})(SystemNotifications);

export default SystemNotifications;
