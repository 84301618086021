import React, { PureComponent } from 'react';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';

class LandingPageView extends PureComponent {
  render() {
    return <Navigate to='/login' skip={true} />;
  }
}

export default LandingPageView;
