import LocalStorageManager from 'app/services/LocalStorageManager';

import { DISABLE_PRESENTATION_MODE, ENABLE_PRESENTATION_MODE } from './types';

export function enablePresentationMode() {
  LocalStorageManager.set('presentationMode', true);
  return {
    type: ENABLE_PRESENTATION_MODE,
  };
}

export function disablePresentationMode() {
  LocalStorageManager.set('presentationMode', false);
  return {
    type: DISABLE_PRESENTATION_MODE,
  };
}
