import React, { Component } from 'react';

import withAuthorize from 'app/components/HOC/withAuthorize';

import { isFeaturePermitted } from 'app/utils/userHelper';

class FeaturePermitted extends Component {
  featureAllowed() {
    return isFeaturePermitted(this.props.featureData.featureIdentifier);
  }

  render() {
    if (!this.props.featureData || this.featureAllowed()) {
      return React.cloneElement(this.props.children, {
        onClick: this.props.onClick,
      });
    } else {
      return null;
    }
  }
}

FeaturePermitted = withAuthorize(FeaturePermitted);
export default FeaturePermitted;
