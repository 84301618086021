import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { loader as queryLoader } from 'graphql.macro';

import { urlB64ToUint8Array } from 'app/utils/generalHelper.js';
import { base64EncodedString } from 'app/utils/stringHelper.js';
import { isNull } from 'app/utils/osLodash';

const CREATE_WEB_PUSH_SUBSCRIPTION = queryLoader(
  'app/graphql/CreateWebPushSubscription.gql',
);
const DELETE_WEB_PUSH_SUBSCRIPTION = queryLoader(
  'app/graphql/DeleteWebPushSubscription.gql',
);

class WebPushServiceWorker extends Component {
  componentDidMount() {
    if (typeof Notification == 'function') {
      if (Notification.permission === 'default') {
        this.askPermission();
      } else {
        this.initializeServiceWorker();
      }
    }
  }

  askPermission() {
    new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then((permissionResult) => {
      this.initializeServiceWorker();
    });
  }

  initializeServiceWorker() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          if (registration?.active?.scriptURL.includes('OrthoScience-SW.js'))
            registration.unregister();
        }
      });

      console.log('Service Worker and Push is supported');
      navigator.serviceWorker
        .register('/Cloudberry-SW.js')
        .then((swReg) => {
          console.log('Service Worker is registered');
          this.swRegistration = swReg;
          this.manageSubscription();
        })
        .catch((error) => {
          console.error('Service Worker Error', error);
        });
    } else {
      console.warn('Push messaging is not supported!');
    }
  }

  manageSubscription() {
    this.swRegistration.pushManager.getSubscription().then((subscription) => {
      let browserSubscribed = !isNull(subscription),
        userSubscribed = false;

      if (
        browserSubscribed &&
        this.props.currentUser.graph.web_push_subscription_endpoints.includes(
          subscription.endpoint,
        )
      ) {
        userSubscribed = true;
      }

      console.log('Browser already subscribed:', browserSubscribed);
      console.log('User already subscribed:', userSubscribed);

      if (!userSubscribed && Notification.permission === 'granted') {
        this.subscribeUser();
      } else if (
        !browserSubscribed &&
        this.props.currentUser.graph.web_push_subscription_endpoints.length
      ) {
        this.unSubscribeUser();
      }
    });
  }

  subscribeUser() {
    const applicationServerKey = urlB64ToUint8Array(
      this.props.systemConfig.configs['web_push_public_vapid_key'].value,
    );
    this.swRegistration.pushManager &&
      this.swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        })
        .then((subscription) => {
          this.createWebPushSubscription(subscription);
          this.setState({ isSubscribed: true });
        })
        .catch(function (err) {
          console.log('Failed to subscribe: ', err);
        });
  }

  createWebPushSubscription(subscription) {
    console.log('Subscribing browser on server');
    let variables = {
      endpoint: subscription.endpoint,
      p256dh: base64EncodedString(subscription.getKey('p256dh')),
      auth: base64EncodedString(subscription.getKey('auth')),
      browser_info: navigator.userAgent,
    };
    this.props.client.mutate({
      mutation: CREATE_WEB_PUSH_SUBSCRIPTION,
      variables,
    });
  }

  unSubscribeUser() {
    console.log('Unsubscribing all browsers on server');
    this.props.client.mutate({
      mutation: DELETE_WEB_PUSH_SUBSCRIPTION,
    });
  }

  render() {
    return <span />;
  }
}

WebPushServiceWorker = connect(({ currentUser, systemConfig }) => ({
  currentUser,
  systemConfig,
}))(WebPushServiceWorker);
WebPushServiceWorker = withApollo(WebPushServiceWorker);

export default WebPushServiceWorker;
