import React from 'react';
import OsBtn from 'app/components/OsBtn';
import useLabelsView from './useLabelsView';
import LabelRow from './LabelRow';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import SetPageTitle from 'app/routes/Shared/SetPageTitle';

function LabelsView() {
  const {
    refreshList,
    labels,
    task_labels,
    person_labels,
    global_labels,
    newLabel,
    order,
    visible,
    addNewLabel,
    updateSelectedLabel,
    deleteSelectedLabel,
    setOrderState,
    setVisibleState,
    setRefreshList,
  } = useLabelsView();
  return (
    <div className='labels-view-wrapper' key={refreshList}>
      <SetPageTitle source={'worksapce_settings'} interpolations={'Labels'} />
      <div className='labels-view-header'>
        <div className='header-text'>
          <div className='heading'>Shared Content Labels</div>
          <div className='text'>
            Here are all the content labels which are available for your team.
            These can be used to categorize patients or tasks.
          </div>
        </div>
      </div>
      <div className='labels-view-body'>
        <div className='table-wrap'>
          <TableHeading
            text={'Task Labels'}
            count={labels.task.length}
            toggelTable={() => setVisibleState('task')}
            addLabel={() => addNewLabel('task')}
            isTableVisible={visible.task}
          />
          {visible.task && (
            <div className='label-table'>
              <RenderHeaderRow
                order={order.task}
                changeOrder={() => setOrderState('task')}
              />
              <RenderList
                listType='task'
                labels={labels.task}
                newLabel={newLabel?.id_type === 'task' ? newLabel : null}
                deleteSelectedLabel={deleteSelectedLabel}
                updateSelectedLabel={updateSelectedLabel}
                reRenderList={() => setRefreshList(!refreshList)}
              />
            </div>
          )}
        </div>

        <div className='table-wrap'>
          <TableHeading
            text={'Patient Labels'}
            count={labels.person.length}
            toggelTable={() => setVisibleState('person')}
            addLabel={() => addNewLabel('person')}
            isTableVisible={visible.person}
          />
          {visible.person && (
            <div className='label-table'>
              <RenderHeaderRow
                order={order.person}
                changeOrder={() => setOrderState('person')}
              />
              <RenderList
                listType='person'
                labels={labels.person}
                newLabel={newLabel?.id_type === 'person' ? newLabel : null}
                deleteSelectedLabel={deleteSelectedLabel}
                updateSelectedLabel={updateSelectedLabel}
                reRenderList={() => setRefreshList(!refreshList)}
              />
            </div>
          )}
        </div>

        <div className='table-wrap'>
          <TableHeading
            text={'Partner Labels'}
            count={labels.partner.length}
            toggelTable={() => setVisibleState('partner')}
            addLabel={() => addNewLabel('partner')}
            isTableVisible={visible.person}
          />
          {visible.person && (
            <div className='label-table'>
              <RenderHeaderRow
                order={order.partner}
                changeOrder={() => setOrderState('partner')}
              />
              <RenderList
                listType='partner'
                labels={labels.partner}
                newLabel={newLabel?.id_type === 'partner' ? newLabel : null}
                deleteSelectedLabel={deleteSelectedLabel}
                updateSelectedLabel={updateSelectedLabel}
                reRenderList={() => setRefreshList(!refreshList)}
              />
            </div>
          )}
        </div>

        {global_labels.length > 0 && (
          <div className='table-wrap'>
            <TableHeading
              text={'Global Labels'}
              count={labels.global.length}
              toggelTable={() => setVisibleState('global')}
              addLabel={() => addNewLabel('global')}
              isTableVisible={visible.global}
            />
            {visible.global && (
              <div className='label-table'>
                <RenderHeaderRow
                  order={order.global}
                  changeOrder={() => setOrderState('global')}
                />
                <RenderList
                  listType='global'
                  labels={labels.global}
                  newLabel={newLabel?.id_type === 'global' ? newLabel : null}
                  deleteSelectedLabel={deleteSelectedLabel}
                  updateSelectedLabel={updateSelectedLabel}
                  reRenderList={() => setRefreshList(!refreshList)}
                />
              </div>
            )}
          </div>
        )}

        {/* <div className='table-wrap'>
          <TableHeading
            text={'Appointment Labels'}
            count={5}
            toggelTable={() => setVisibleState('appointment')}
            addLabel={() => addNewLabel('appointment')}
          />
          {visible.appointment && (
            <>
              <RenderHeaderRow order={order} setOrder={setOrder} />
              <RenderList
                labels={labelsData?.records?.results.slice(10, 15) || []}
                newLabel={newLabel}
                deleteSelectedLabel={deleteSelectedLabel}
                updateSelectedLabel={updateSelectedLabel}
              />
            </>
          )}
        </div> */}
      </div>
    </div>
  );
}

const TableHeading = ({
  text,
  count,
  toggelTable,
  addLabel,
  isTableVisible,
}) => {
  return (
    <div className='table-heading'>
      <div className='heading-text' onClick={toggelTable}>
        <span className='text'>
          {text} ({count})
        </span>
        {isTableVisible ? (
          <OrthoIcon name='chevron-down' />
        ) : (
          <OrthoIcon name='chevron-right' />
        )}
      </div>
      <div className='header-btn'>
        <OsBtn
          name='BtnPrimary'
          text='NEW LABEL'
          extraClass={'web-view-btn'}
          htmlTag='button'
          onClick={addLabel}
        />
      </div>
    </div>
  );
};

const RenderHeaderRow = ({ order, changeOrder }) => {
  return (
    <div className='table-head'>
      <div className='table-head-item name' onClick={changeOrder}>
        <span>NAME</span>
        {order ? (
          <OrthoIcon name='chevron-caret-down' />
        ) : (
          <OrthoIcon name='chevron-caret-up' />
        )}
      </div>
      <div className='table-head-item label-color'>LABEL COLOR</div>
      <div className='table-head-item usage-count'>USAGE COUNT</div>

      <div className='table-head-item sub-options'>SUB OPTIONS</div>
      {/* <div className='table-head-item patient-count'>Patient Count</div> */}
    </div>
  );
};

const RenderList = ({
  listType,
  labels,
  newLabel,
  deleteSelectedLabel,
  updateSelectedLabel,
  reRenderList,
}) => {
  return (
    <div className='table-body'>
      {newLabel !== null && (
        <LabelRow
          listType={listType}
          label={newLabel}
          updateSelectedLabel={updateSelectedLabel}
          deleteSelectedLabel={deleteSelectedLabel}
          isEdit={true}
          showSaveButton={true}
          reRenderList={reRenderList}
        />
      )}
      {labels.map((label) => (
        <LabelRow
          listType={listType}
          key={label.id}
          label={label}
          updateSelectedLabel={updateSelectedLabel}
          deleteSelectedLabel={deleteSelectedLabel}
          reRenderList={reRenderList}
        />
      ))}
    </div>
  );
};

export default LabelsView;
