import OsTiptapEditorField from 'app/components/OsField/OsTiptapEditorField';
import React from 'react';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import { isTouchSupported } from 'app/utils/deviceHelper';
import useTemplateRow from 'app/components/Templates/useTemplateRow';
import { timeWithFormat } from 'app/utils/timeHelper';
import { FORMAT_START_WITH_MONTH_SLASH } from 'app/components/Task/taskConstant';

const TIPTAP_EXTENSION_REQUIRED = [
  'StarterKit',
  'Placeholder',
  'CustomSubmit',
  'Underline',
  'Link',
  'NewHashtag',
  'Token',
];

const TemplateDataRow = (props) => {
  const { template, setNewTemplate } = props;
  const {
    isEditorOpen,
    rowState,
    templateName,
    templateFiles,
    error,
    setTemplateFiles,
    templateObjects,
    templateUpdateLoading,
    setTemplateObjects,
    inputRef,
    templateInitialContent,
    isTemplatePersisted,
    handleSubmit,
    handleTemplateName,
    handleContent,
    handleIsFeatured,
    handleIsStatus,
    handleIsFollowUp,
    deleteTemplateData,
    handleEditor,
    getSubmitButtonText,
    getPlaceholderText,
  } = useTemplateRow(template, setNewTemplate);

  return (
    <>
      {/*  Data Row */}
      <div className={`template-row`} onClick={handleEditor}>
        {error.templateName && (
          <p className='template-name-error-message'>
            Template Name is Required*
          </p>
        )}
        <div className={`template-data-row`}>
          <div className={'template-name-input'}>
            <input
              onClick={(e) => {
                isEditorOpen && e.stopPropagation();
              }}
              type='text'
              ref={inputRef}
              className={`form-control`}
              placeholder={
                template.kind === 'care'
                  ? 'Add Preset Name Here'
                  : 'Enter Template Name'
              }
              value={templateName}
              onChange={handleTemplateName}
            />
          </div>
          {template.kind !== 'care' && (
            <div>
              <span className={`featured-toggle-field`}>
                <label className={`switch`} data-hover={!isTouchSupported()}>
                  <input
                    type='checkbox'
                    defaultChecked={rowState.isFeatured}
                    value={rowState.isFeatured}
                    onClick={handleIsFeatured}
                  />
                  <span className={`slider round`}></span>
                  <span className={`cs-slider-after`}></span>
                </label>
              </span>
            </div>
          )}
          <div>
            <span className={`status-toggle-field`}>
              <label className={`switch`} data-hover={!isTouchSupported()}>
                <input
                  type='checkbox'
                  defaultChecked={rowState.isStatus}
                  value={rowState.isStatus}
                  onClick={handleIsStatus}
                />
                <span className={`slider round`}></span>
                <span className={`cs-slider-after`}></span>
              </label>
            </span>
          </div>
          <div className={'latest-activity-field'}>
            <div className={'last-update-date'}>
              {template.updated_at
                ? timeWithFormat(
                    template.updated_at,
                    FORMAT_START_WITH_MONTH_SLASH,
                    null,
                  )
                : `--/--/----`}
            </div>
            <div className={'last-update-author-name'}>
              {template.updated_by.full_name}
            </div>
          </div>
          <CustomDropdown
            name='discussion_actions'
            className={`comment-more position-relative ${
              !isTemplatePersisted() && 'visibility-hidden'
            }`}>
            {[
              <div className='dropdown-item' onClick={deleteTemplateData}>
                Delete
              </div>,
            ]}
          </CustomDropdown>
        </div>
        {isEditorOpen && (
          <div
            id={'editor-box'}
            className={'template-editor'}
            onClick={(e) => e.stopPropagation()}
            style={{
              paddingBottom: isEditorOpen ? '8px' : '0',
            }}>
            {
              <div>
                <OsTiptapEditorField
                  rowId={template.id}
                  initialContent={templateInitialContent.current}
                  textType={'html'}
                  dragAndDrop={true}
                  updateContent={handleContent}
                  isSubmitButtonRequired={true}
                  autofocus={false}
                  placeholder={getPlaceholderText()}
                  extensions={TIPTAP_EXTENSION_REQUIRED}
                  isCancelButtonRequired={!isTemplatePersisted()}
                  cancelButtonProps={{
                    onClick: () => setNewTemplate(null),
                  }}
                  submitButtonProps={{
                    text: getSubmitButtonText(),
                    disabled: templateUpdateLoading,
                    loading: templateUpdateLoading,
                    onClick: handleSubmit,
                  }}
                  error={error}
                  errorMessage={`content is required*`}
                  files={templateFiles}
                  handleFiles={setTemplateFiles}
                  objects={templateObjects}
                  handleObjects={setTemplateObjects}
                />
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
};
export default TemplateDataRow;
