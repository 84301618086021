import React from 'react';
import WriteComment from 'app/components/Comments/WriteComment';

function CarespaceEditor(props) {
  return (
    <div className={`detail-comments-header space-comments`} id='comments'>
      <WriteComment
        aroundId={null}
        author={props.props.space.user}
        commentableEntityOwner={props.props.space.user}
        commentableId={props.props.space.nice_id}
        commentableType={'Board'}
        entity={props.props.space}
        newComment={true}
        sortQuery={'recent'}
        textQuery={undefined}
        editorButtonTextRequired={false}
      />
    </div>
  );
}

export default CarespaceEditor;
