import React, { Component } from 'react';
import RecordsView from 'app/components/RecordsView';

class PopularTopics extends Component {
  renderContent() {
    const { entity, pageSource, source } = this.props;
    const scroller = source === 'modal',
      type =
        pageSource === 'exploreCareWorkspace'
          ? 'PopularTopicsOnCareFeed'
          : 'PopularTopics';
    return (
      <article className={this.props.modalBodyClassName || ''}>
        <section
          className={this.props.containerClass || ''}
          ref={(el) => (this.collectionContainer = el)}>
          <RecordsView
            type={type}
            queryType='POPULAR_TOPICS'
            perPage={50}
            source={this.props.source}
            idQuery={entity?.id}
            textQuery={entity?.__typename}
            infiniteScroll={scroller}
            showMoreNotRequired={!scroller}
            hasMore={scroller}
            nextPageLoadRequired={scroller}
            setCollectionTotal={this.props.setCollectionTotal}
            parentContainer={scroller ? this.collectionContainer : null}
            windowNotRequired={true}
            sourceObject={entity}
          />
        </section>
      </article>
    );
  }

  render() {
    return this.renderContent();
  }
}

PopularTopics.defaultProps = {
  source: 'widget',
};

export default PopularTopics;
