import { CLOSE_ENTITY_PREVIEW_MODAL, OPEN_ENTITY_PREVIEW_MODAL } from './types';

export function openEntityPreviewModal({ entityId, queryType }) {
  return {
    type: OPEN_ENTITY_PREVIEW_MODAL,
    entityId,
    queryType,
  };
}

export function closeEntityPreviewModal() {
  return {
    type: CLOSE_ENTITY_PREVIEW_MODAL,
  };
}
